// Core
import React, { PureComponent, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { compose, graphql } from "react-apollo";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

// Components
import TextInput from "components/Input/Material/TextInput/TextInput";
import Text from "components/Text";
import Button from "components/Button";
import Pagination from "components/Pagination";
import ContentHeader from "containers/ContentHeader";
import UsersTable from "routes/AdminPanel/UsersList/UsersTable/UsersTable";
import Loader from "components/Loader";
import InviteForm from "./InviteForm/InviteForm";

// GraphQl
import GET_ALL_USERS from "queries/AdminPanel/getAllUsers.gql";

// Other
import { capitalize } from "utils/helpers/index";
import { messages } from "routes/Courses/Search/messages";
import { Grey3 } from "components/Icon/color";
import { withAuthenticatedUser } from "containers/AuthenticatedUserProvider/withAuthenticatedUser";
import { withModal } from "containers/ModalProvider/withModal";

// Styles and Assets
import "./UsersList.scss";

const USERS_PER_PAGE_COUNT = 7;

class UsersList extends PureComponent {
  handleInvitationSubmit = () => {
    const {
      modalContext: { hideModal },
    } = this.props;
    hideModal();
  };

  handleInvite = async () => {
    const {
      userContext: {
        profile: { org: companyId },
      },
      modalContext: { showModal },
    } = this.props;

    try {
      showModal(InviteForm, {
        handleInvitationSubmit: this.handleInvitationSubmit,
        companyId,
      });
    } catch (error) {
      console.error(error);
      // setErrors(parseIncomingError(error));
    }
  };

  nextPage = async () => {
    const { userRole, skip, limit, total, refetchUsers, query } = this.props;
    const newSkip = skip + limit;
    const isCoursesExist = newSkip <= total;
    if (isCoursesExist) {
      await refetchUsers({
        input: { role: userRole, limit, skip: newSkip, query },
      });
    }
  };

  backPage = async () => {
    const { userRole, skip, limit, refetchUsers, query } = this.props;
    const newSkip = skip - limit;
    const isCoursesExist = newSkip >= 0;

    if (isCoursesExist) {
      await refetchUsers({
        input: { role: userRole, limit, skip: newSkip, query },
      });
    }
  };

  handleSort = async ({ sortBy, sortOrder }) => {
    const { refetchUsers, limit, userRole, query } = this.props;
    const input = {
      limit, // [int], value = 7
      skip: 0, // [int]
      role: userRole,
      query: {
        ...query,
        sort_by: sortBy, // default
        order: sortOrder, // desc
      },
    };

    await refetchUsers({ input });
  };

  handleSearchEnter = async (searchValue, evt) => {
    const isUserEnterClicked = evt.key === "Enter";

    if (isUserEnterClicked) {
      const { refetchUsers, limit, userRole, query } = this.props;

      console.log("props user search", this.props);

      const input = {
        limit, // [int], value = 7
        skip: 0, // [int]
        role: userRole,
        query: {
          ...query,
          search_key: "email", // search only by this field
          search_value: searchValue, // [string]
        },
      };

      await refetchUsers({ input });
    }
  };

  render() {
    const {
      error,
      loading,
      userRole,
      users,
      total,
      skip,
      limit,
      adminSuspendRestoreUser,
      query,
      refetchUsers,
    } = this.props;
    const currentPage = skip / limit;

    if (loading) return <Loader center />;

    return (
      <Fragment>
        <div className="sk-adminUsersListPanel__contentHeader">
          <div className="sk-adminUsersListPanel__contentHeader__left">
            <ContentHeader />
            <Text size="small" className="sk-adminUsersListPanel__panelName">
              {userRole ? capitalize(`${userRole}s`) : "Team"}
            </Text>
          </div>

          <div className="sk-adminUsersListPanel__contentHeader__right withButton">
            <FormattedMessage {...messages.searchUser}>
              {(msg) => (
                <div className="sk-adminUsersListPanel__contentHeader__right__search">
                  <TextInput
                    type="string"
                    defaultValue={query?.search_value}
                    placeholder={msg}
                    onKeyPress={this.handleSearchEnter}
                  />
                </div>
              )}
            </FormattedMessage>
            <Button onClick={this.handleInvite}>Invite</Button>
          </div>
        </div>

        <UsersTable
          sortBy={query?.sort_by}
          sortOrder={query?.order}
          handleSort={this.handleSort}
          error={error}
          loading={loading}
          adminListUsers={users}
          refetchUsers={refetchUsers}
          userRole={userRole}
        />
        {total > USERS_PER_PAGE_COUNT && (
          <div className="sk-adminUsersListPanel__pagination">
            <Pagination
              page={currentPage}
              count={total}
              handleChangePage={(e, selectedPage) => {
                if (currentPage > selectedPage) {
                  this.backPage();
                } else if (currentPage < selectedPage) {
                  this.nextPage();
                }
              }}
              rowsPerPage={limit}
            />
          </div>
        )}
      </Fragment>
    );
  }
}

const getUsers = graphql(GET_ALL_USERS, {
  props: ({
    data: { error, loading, adminListUsers, refetch, ...ownProps },
  }) => {
    if (error) throw Error("Something went wrong, while receiving users");
    if (loading) return { loading, users: [], total, skip, limit };

    const { users, total, skip, limit, query } = adminListUsers;

    return {
      error,
      users,
      total,
      skip,
      limit,
      query,
      refetchUsers: refetch,
      ...ownProps,
    };
  },
  options: ({
    userContext: {
      profile: { org },
    },
    userRole,
  }) => ({
    fetchPolicy: "network-only",
    variables: {
      input: {
        role: userRole || null,
        skip: 0,
        limit: USERS_PER_PAGE_COUNT,
        org,
      },
    },
  }),
});

UsersList.propTypes = {
  userRole: PropTypes.string,
  modalContext: PropTypes.object,
  match: PropTypes.object,
};

const enhancer = compose(
  withRouter,
  withModal,
  withAuthenticatedUser,
  getUsers
);

export default enhancer(UsersList);
// export default enhancer(hasLoader(UsersList, GET_ALL_USERS, true));
