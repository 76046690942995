import React from 'react';
import PropTypes from 'prop-types';
import { Red, SidebarDefault } from 'components/Icon/color';

const VideoPlay = ({ color, height, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 41 28"
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill={color}
        d="M18 9.869v8.262a1 1 0 0 0 1.555.833l6.197-4.132a1 1 0 0 0 0-1.664l-6.197-4.132A1 1 0 0 0 18 9.87z"
      />
      <rect
        width="40"
        height="27"
        x=".5"
        y=".5"
        fillRule="nonzero"
        stroke={SidebarDefault}
        rx="5"
      />
    </g>
  </svg>
);

VideoPlay.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  color: PropTypes.string,
};

VideoPlay.defaultProps = {
  color: Red,
  height: '28',
  width: '41',
};

export default VideoPlay;
