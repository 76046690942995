import React from 'react';
import PropTypes from 'prop-types';

const Google = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 32C24.8366 32 32 24.8365 32 16C32 7.16345 24.8366 0 16 0C7.16344 0 0 7.16345 0 16C0 24.8365 7.16344 32 16 32ZM21.3093 18.2H16.5V14.8H25V16.5C25 21.1869 21.1869 25 16.5 25C11.8131 25 8 21.1869 8 16.5C8 11.8131 11.8131 8 16.5 8C18.5417 8 20.5069 8.73438 22.0369 10.0672L19.8031 12.6308C18.8919 11.8369 17.7189 11.4 16.5 11.4C13.6882 11.4 11.4 13.6882 11.4 16.5C11.4 19.3118 13.6882 21.6 16.5 21.6C18.7168 21.6 20.6072 20.1788 21.3093 18.2Z"
      fill="white"
    />
  </svg>
);

export default Google;
