import React from 'react';
import { SidebarConsumer } from 'containers/SidebarProvider/SidebarConsumer';

export function withSidebar(Component) {
  return function SidebarComponent(props) {
    return (
      <SidebarConsumer>
        {contexts => <Component {...props} {...contexts} />}
      </SidebarConsumer>
    );
  };
}
