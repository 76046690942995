import React, { useState, useCallback, useEffect } from 'react';
import { compose, graphql } from 'react-apollo';
import { withFormik } from 'formik';
import { injectIntl } from 'react-intl';

// Components
import Loader from 'components/Loader';
import Button from 'components/Button';
import Text from 'components/Text';
import QuizPreviewQuestion from 'components/QuizPreview/QuizPreviewQuestion';
import { sharedMessages } from 'services/i18n/sharedMessages/messages';

// Other
import {
  LECTURE_QUIZ,
} from 'containers/UploadManagerProvider/helpers';
import { keysToCamel, keysToSnake, compareSortNumber, isBlank, isEmptyArray } from 'utils/helpers';
import { getYupSchema, } from 'services/yup';

// Styles
import './index.scss';

const getInitialQuestionsAnswers = questions => (!isBlank(questions) && !isEmptyArray(questions)) ?
  questions.map(({ id }) => ({
    questionId: id,
    answers: [],
  })) : [];

const validateQuizProgress = (questionsWithAnswers) => {
  for (let index in questionsWithAnswers) {
    const questionWithAnswers = questionsWithAnswers[index];
    if (isEmptyArray(questionWithAnswers?.answers)) {
      console.log('Not submited answers to all questions!');
      return false;
    };
  };
  return true;
}

const QuizPreview = ({
  quiz,
  readOnly,
  completeQuiz,
  isQuizCompleted,
  intl: { formatMessage }
}) => {
  const [questionsWithAnswers, setNewQuestionsAnswers] = useState(getInitialQuestionsAnswers(quiz?.questions));
  const [isValid, setQuizProgressStatus] = useState(false);

  useEffect(() => {
    setNewQuestionsAnswers(getInitialQuestionsAnswers(quiz?.questions))
  }, [quiz?.questions]);

  useEffect(() => {
    const newIsValid = validateQuizProgress(questionsWithAnswers);
    if (newIsValid) {
      setQuizProgressStatus(true);
    } else if (!newIsValid && newIsValid !== isValid) {
      setQuizProgressStatus(false);
    };
  }, [questionsWithAnswers]);

  const setAnswer = ({ questionId, answerId, multiselect = false }) => {
    const newQuestionsWithAnswers = [...questionsWithAnswers];

    const modifiedQuestion = newQuestionsWithAnswers.find(({ questionId: id }) => id === questionId);

    if (isBlank(modifiedQuestion)) return;
    const isAnswerAdded = modifiedQuestion.answers.includes(answerId);

    if (isAnswerAdded && !multiselect) {
      modifiedQuestion.answers = modifiedQuestion.answers.filter(id => id !== answerId);
    } else if (!isAnswerAdded && !multiselect) {
      modifiedQuestion.answers = [answerId];
    } else if (!isAnswerAdded && multiselect) {
      modifiedQuestion.answers = [...modifiedQuestion.answers, answerId];
    } else if (multiselect) {
      if (modifiedQuestion.answers.some(answeredId => answeredId === answerId)) {
        modifiedQuestion.answers = modifiedQuestion.answers.filter(answeredId => answeredId !== answerId);
      } else {
        modifiedQuestion.answers = [...modifiedQuestion.answers, answerId];
      }
    } else {
      return;
    };

    setNewQuestionsAnswers(newQuestionsWithAnswers);
  };

  const sortedQuestions = !isBlank(quiz?.questions) ? quiz?.questions.sort(compareSortNumber) : [];

  return (
    <div className='sk-quiz-preview'>
      {sortedQuestions.map(question => {
        const questionWithAnswer = questionsWithAnswers.find(questionWithAnswer => questionWithAnswer.questionId == question.id);
        const hasQuestionAnswers = !isBlank(questionWithAnswer) && !isEmptyArray(questionWithAnswer?.answers);
        question.selectedAnswers = hasQuestionAnswers ? questionWithAnswer.answers : [];

        return (
          <QuizPreviewQuestion
            key={question.id}
            question={question}
            setAnswer={setAnswer}
            isQuizCompleted={isQuizCompleted}
          />
        )
      })}
      {(!readOnly && !isQuizCompleted) && (
        <Button
          disabled={!isValid}
          size='small'
          variant="text"
          color="secondary"
          className='mui-override sk-quiz-preview__submit-button'
          onClick={completeQuiz(questionsWithAnswers, isValid)}
        >
          {formatMessage(sharedMessages.submitButton)}
        </Button>
      )}
    </div>
  );
};

const enhancer = compose(
  injectIntl,
);

export default enhancer(QuizPreview);
