import React from 'react';
import PropTypes from 'prop-types';
import { DarkGrey } from 'components/Icon/color';

const Document = ({ color, height, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 14 18"
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      transform="translate(1 1)"
    >
      <path
        d="M11.8759408,13.9188112 L11.8759408,4.53594927
       L7.41497825,0.143598832 L2.35155579,0.143598832
        C1.14234367,0.143598832 0.153267023,1.0692559 
        0.153267023,2.20128427 L0.153267023,10.8961529"
      />
      <path
        d="M.153175045 9.89962253L.153175045 13.8617218C.153175045 14.9931761 1.14255829
        15.9194073 2.35146381 15.9194073L9.67786667 15.9194073C10.8870788 15.9194073
         11.8758488 14.9931761 11.8758488 13.8617218M7.48000713.258466416L7.48000713
          3.7828519C7.48000713 4.14048561 7.78966845 4.43038131 8.17137968 4.43038131L11.7576257
           4.43038131 7.48000713.258466416z"
      />
    </g>
  </svg>
);

Document.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  color: PropTypes.string,
};

Document.defaultProps = {
  color: DarkGrey,
  height: '18',
  width: '14',
};

export default Document;
