// Core
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// Components
import { intlShape, injectIntl } from 'react-intl';

// Other
import { Helmet } from 'react-helmet';
import { messages } from 'components/MetaData/messages';
import { LANGUAGES } from 'utils/enums/index';

const { BASE_URL } = process.env;

const defaults = {
  suffix: 'AVDO',
  image:
    'https://skilleton-assets.s3.eu-central-1.amazonaws.com/skilleton-1x1.png',
};
/**
 * Basic helper for empty/null/undefined string validation
 */
const blank = value => {
  if (value && value !== '') {
    return false;
  }
  return true;
};

class MetaData extends PureComponent {
  getMessage(key) {
    if (messages[key]) {
      return messages[key];
    }
    return key;
  }

  getTitle() {
    const { intl, title, titleKey } = this.props;
    if (!blank(titleKey)) {
      return `${intl.formatMessage(this.getMessage(titleKey))} | ${
        defaults.suffix
      }`;
    }
    if (!blank(title)) {
      return `${title} | ${defaults.suffix}`;
    }
    return intl.formatMessage(messages.defaultTitle);
  }

  getDescription() {
    const { intl, description, descriptionKey } = this.props;
    if (!blank(descriptionKey)) {
      return intl.formatMessage(descriptionKey);
    }
    if (!blank(description)) {
      return description;
    }
    return intl.formatMessage(messages.defaultDescription);
  }

  getURL() {
    const { location } = this.props;
    if (location) {
      return `${BASE_URL}${location}`;
    }
    return BASE_URL;
  }

  getImage() {
    const { image } = this.props;
    if (!blank(image)) {
      return image;
    }
    return defaults.image;
  }

  getLocalizedUrl(locale) {
    const {location} = this.props;
    const url = [BASE_URL, locale];
    if (location) {
      url.push(location.replace(/^\/([\w]{2})\//, ''));
    }
    return url.join('/');
  }

  renderLocalizations = (locale) => {
    return LANGUAGES.map(({code, iso}) => {
      return (
        <link
          key={code}
          rel="alternate"
          href={this.getLocalizedUrl(code)}
          hrefLang={iso.replace('_', '-')}
        />
      );
    });
  }
  render() {
    const {
      intl: { locale },
      location,
    } = this.props;
    return (
      <Helmet>
        <title>{this.getTitle()}</title>
        <meta name="description" content={this.getDescription()} />
        <meta property="og:title" content={this.getTitle()} />
        <meta property="og:image" content={this.getImage()} />
        <meta property="og:description" content={this.getDescription()} />
        <meta name="theme-color" content="#7145d8" />
        {location && (<meta property="og:url" content={this.getURL()} />)}
        {this.renderLocalizations(locale)}
      </Helmet>
    );
  }
}

MetaData.propTypes = {
  intl: intlShape.isRequired,
  title: PropTypes.string,
  titleKey: PropTypes.string,
  description: PropTypes.string,
  descriptionKey: PropTypes.string,
  image: PropTypes.string,
  location: PropTypes.string,
};

export default injectIntl(MetaData);
