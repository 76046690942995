// Core
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// Components
import Loader from 'components/Loader';
import SimpleSidebarMenu from 'containers/Sidebar/SidebarContent/SimpleSidebarMenu';
import SidebarMenuItem from 'containers/Sidebar/SidebarContent/SidebarMenu/SidebarMenuItem';
import MenuNote from 'components/Icon/Svg/MenuNote';
import Pencil from 'components/Icon/Svg/Pencil';

// Other
import { FormattedMessage } from 'react-intl';
import { messages } from 'containers/Sidebar/SidebarContent/CourseSidebar/ActiveCoursesList/messages';
import { renderTrimmedText, isBlank, isArray, isEmptyArray } from 'utils/helpers/index';

class ActiveCoursesList extends PureComponent {
  getSubItemsMenu = (id, onClose, isHomeworkAvailable) => [
    {
      id: `homework-${id}`,
      path: `/experts-cabinet/course/${id}/homeworks`,
      label: <FormattedMessage {...messages.homework} />,
      icon: Pencil,
      disabled: !isHomeworkAvailable,
      // handleCustomClick: onClose,
    },
    {
      onClose,
      id: `edit-${id}`,
      path: `/experts-cabinet/course/${id}/edit`,
      label: <FormattedMessage {...messages.edit} />,
      // handleCustomClick: onClose,
      icon: Pencil,
    },
  ];

  getMenuItems = (list, onClose) =>
    list.map(({ id, title, variants }) => {
      const courseFeatures = (isArray(variants) && !isEmptyArray(variants)) ? variants[variants.length - 1]?.features : [];
      const isHomeworkAvailable = courseFeatures.includes('homeworks');

      return {
        id,
        path: `/experts-cabinet/course/${id}/edit`,
        label:  title,
        subItems: this.getSubItemsMenu(id, onClose, isHomeworkAvailable),
      }
    });

  renderSimpleSidebarMenu = (
    onClose,
    open,
    list,
    handleClick,
    handleSubMenuTitleClick,
    openedSubItemId
  ) =>
    open && list?.length ? (
      <SimpleSidebarMenu
        menuItems={this.getMenuItems(list, onClose)}
        withSubMenu
        handleClick={handleClick}
        handleSubMenuTitleClick={handleSubMenuTitleClick}
        openedSubItemId={openedSubItemId}
      />
    ) : null;

  render() {
    const {
      onClose,
      open,
      openedSubItem,
      list,
      loading,
      handleSubMenuTitleClick,
      handleClick,
      goToListPage,
    } = this.props;

    return (
      <div>
        <FormattedMessage {...messages.activeCourses}>
          {msg => (
            <SidebarMenuItem
              icon={MenuNote}
              label={msg}
              expandable={!!list?.length}
              handleClick={goToListPage}
              open={open}
              togglerProps={{ handleOnTogglerClick: handleClick }}
            >
              {loading && open ? (
                <Loader small />
              ) : (
                this.renderSimpleSidebarMenu(
                  onClose,
                  open,
                  list,
                  handleClick,
                  handleSubMenuTitleClick,
                  openedSubItem
                )
              )}
            </SidebarMenuItem>
          )}
        </FormattedMessage>
      </div>
    );
  }
}

ActiveCoursesList.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  handleClick: PropTypes.func,
  list: PropTypes.array,
  loading: PropTypes.bool,
  openedSubItem: PropTypes.string,
  handleSubMenuTitleClick: PropTypes.func,
};

export default ActiveCoursesList;
