import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  changePassword: {
    id: 'app.components.ManageAccountSidebar.changePassword',
    defaultMessage: 'Change Password',
  },
  company: {
    id: 'app.components.ManageAccountSidebar.company',
    defaultMessage: 'Company Details',
  },
  personalInfo: {
    id: 'app.components.ManageAccountSidebar.personalInfo',
    defaultMessage: 'Personal Information',
  },
  deleteAccount: {
    id: 'app.components.ManageAccountSidebar.deleteAccount',
    defaultMessage: 'Delete Account',
  },
  expertsAgreement: {
    id: 'app.components.ManageAccountSidebar.expertsAgreement',
    defaultMessage: "Expert Agreement",
  },
  bankDetails: {
    id: 'app.components.ManageAccountSidebar.bankDetails',
    defaultMessage: 'Bank Details',
  },
  manageAccount: {
    id: 'app.components.FlatDropDownMenu.manageAccount',
    defaultMessage: 'Manage Account',
  },
  accountAnalytics: {
    id: 'app.components.FlatDropDownMenu.accountAnalytics',
    defaultMessage: 'Analytics',
  },
  activeStudents: {
    id: 'app.components.FlatDropDownMenu.activeStudents',
    defaultMessage: 'Active Students',
  },
  sales: {
    id: 'app.components.FlatDropDownMenu.sales',
    defaultMessage: 'Sales',
  },
  signOut: {
    id: 'app.components.FlatDropDownMenu.signOut',
    defaultMessage: 'Sign Out',
  },
});
