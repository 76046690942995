// // Core
// import React, { PureComponent, useState, useEffect, } from 'react';
// import { withRouter, Redirect, Route } from 'react-router-dom';
// import { ADMIN, EXPERT, STUDENT } from 'utils/enums';
// import { compose } from 'react-apollo';
//
// // Other
// import { withAuthenticatedUser } from 'containers/AuthenticatedUserProvider/withAuthenticatedUser';
// import { isBlank } from 'utils/helpers/index';

// const RouteGuest = ({
//   component: Component,
//   // role,
//   userContext: { verifyFederatedIdentity, isFederatedVerified, isUserAuthorized, profile },
//   ...rest
// }) => {
//   const isAuthenticated = isFederatedVerified && isUserAuthorized && !isBlank(profile);
//   const role = profile?.role || 'student';
//
//   const [authUser, setAuthUser] = useState({
//     role,
//     isAuthenticated,
//   });
//
//   useEffect(() => {
//     const newIsAuthenticated = isFederatedVerified && isUserAuthorized && !isBlank(profile);
//     const newRole = profile?.role || 'student';
//
//     console.log('private route 0000');
//
//     console.log('role', role, newRole);
//     console.log('isAuthenticated', isAuthenticated, newIsAuthenticated);
//
//     if (role !== newRole || isAuthenticated !== newIsAuthenticated) {
//       console.log('private route 1111');
//
//       setAuthUser(prev => ({
//         role: newRole,
//         isAuthenticated: newIsAuthenticated,
//       }));
//     };
//   }, [isFederatedVerified, isUserAuthorized]);
//
//   console.log('render');
//
//   if (authUser?.role === STUDENT || authUser?.role === EXPERT) {
//     return (
//       <Redirect
//         to={{
//           pathname: '/courses',
//         }}
//       />
//     );
//   };
//
//   if (authUser?.role === ADMIN) {
//     return (
//       <Redirect
//         to={{
//           pathname: '/admin/panel',
//         }}
//       />
//     );
//   };
//
//   return (
//     <Route {...rest} render={props => <Component {...props} {...rest} />} />
//   );
// };
//
// const enhancer = compose(
//   withRouter,
//   withAuthenticatedUser
// );
//
// export default enhancer(RouteGuest);

// Core
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withRouter, Redirect, Route } from "react-router-dom";
import { ADMIN } from "utils/enums";

class RouteGuest extends PureComponent {
  render() {
    const {
      component: Component,
      isUserAuthorized,
      domain,
      subprivate,
      ...rest
    } = this.props;
    if (
      !isUserAuthorized &&
      subprivate &&
      domain &&
      window?.location?.host !== "localhost:3000" &&
      window?.location?.host !== "avdo.education" &&
      window?.location?.host !== "www.avdo.education"
    ) {
      return (
        <Redirect
          to={{
            pathname: "/en/auth/signin",
          }}
        />
      );
    }
    if (rest.role === ADMIN && rest.roles !== ADMIN) {
      return (
        <Redirect
          to={{
            pathname: "/admin/panel",
          }}
        />
      );
    }
    return (
      <Route {...rest} render={(props) => <Component {...props} {...rest} />} />
    );
  }
}

RouteGuest.propTypes = {
  component: PropTypes.func.isRequired,
  location: PropTypes.object,
};

export default withRouter(RouteGuest);

// export default RouteGuest;
