// Core
import React from 'react';
import PropTypes from 'prop-types';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';

const MuiPickerProvider = ({ children }) => (
  <MuiPickersUtilsProvider utils={MomentUtils}>
    {children}
  </MuiPickersUtilsProvider>
);

MuiPickerProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default MuiPickerProvider;
