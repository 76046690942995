// Core
import React, { memo, useCallback } from 'react';
import { compose } from 'react-apollo';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

// Components
import Button from 'components/Button';
import Text from 'components/Text';

// Other
import { messages } from 'routes/ExpertsCabinet/CourseEdit/messages';

// Styles and Assets
import './index.scss';

const PREFIX = 'sk-buy-subscription-modal'
const BuySubscriptionModal = ({
  intl: { formatMessage },
  hideModal,
  history,
  ...props
}) => {
  const handleBuySubscription = useCallback(() => {
    hideModal();
    history.push('/experts-cabinet/subscription');
  }, [history.push]);

  return (
    <div className={PREFIX}>
      <Text>{formatMessage(messages.noSubscriptionWarning)}</Text>
      <div className={`${PREFIX}__actions`}>
        <Button
          color="primary"
          onClick={handleBuySubscription}
        >
          {formatMessage(messages.subscriptionCommissionButton)}
        </Button>
        <Button
          color="secondary"
          onClick={handleBuySubscription}
        >
          {formatMessage(messages.subscriptionPlanButton)}
        </Button>
      </div>
    </div>
  );
};

const enhancer = compose(
  memo,
  injectIntl,
  withRouter
);

export default enhancer(BuySubscriptionModal);
