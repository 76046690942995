import React from 'react';
import PropTypes from 'prop-types';
import { PurpleHeart, White } from 'components/Icon/color';

const WebinarAudioOff = ({ height, width, strokeColor, fillColor, active }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx={width / 2}
      cy={height / 2}
      r={width / 2}
      fill={fillColor}
      fillOpacity={active ? '1' : '0.8'}
    />
    <path
      d="M24.835 15.232a1.262 1.262 0 0 0-1.288.019.39.39 
      0 0 0-.054.039l-5.645 4.776h-4.431a.416.416 0 0 0-.417.417v7.916a.415.415 
      0 0 0 .417.417l4.42-.002 5.643 5.183c.021.02.044.036.067.05.206.124.433.186.66.186a1.301 
      1.301 0 0 0 1.293-1.316V16.381c0-.484-.249-.913-.665-1.149zm-.168 17.685c0
       .18-.09.338-.242.423a.437.437 0 0 1-.416.01l-5.592-5.136v-1.899a.416.416 0 1
        0-.834 0v1.665l-3.75.002V20.9h3.75v1.666a.416.416 0 1 0 
        .834 0v-1.89l5.585-4.725c.192-.1.359-.029.423.008.151.085.242.243.242.423v16.536zM29.354
         21.146a.5.5 0 0 0-.708.708l.708-.708zm5.792 7.208a.5.5 0 0 0 
         .708-.708l-.708.708zm-6.5-6.5l6.5 6.5.708-.708-6.5-6.5-.708.708z"
      fill={strokeColor}
    />
    <path
      d="M35.854 21.854a.5.5 0 0 0-.708-.708l.708.708zm-7.208 5.792a.5.5 0 0 0 
      .708.708l-.708-.708zm6.5-6.5l-6.5 6.5.708.708 6.5-6.5-.708-.708z"
      fill={strokeColor}
    />
  </svg>
);

WebinarAudioOff.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  strokeColor: PropTypes.string,
  fillColor: PropTypes.string,
  active: PropTypes.bool,
};

WebinarAudioOff.defaultProps = {
  height: '50',
  width: '50',
  strokeColor: White,
  fillColor: PurpleHeart,
  active: false,
};

export default WebinarAudioOff;
