// Core
import React from 'react';

// Components
import IconWithModal from 'components/IconWithModal';

// Helpers
import ENTITY_CONTROLLER from 'routes/Course/components/LectureMaterials/StudyMaterialsList/MaterialEntity/helpers';

const CurriculumMaterialIcon = ({
    icon: Icon,
    material: {
      title,
      duration,
      id,
      filepath,
      type,
      content,
      filetype,
      courseId,
      isPublic
    }
  }) => {

  if (isPublic === true && filepath && filepath !== null) {
    return (
      <IconWithModal
        modal={ENTITY_CONTROLLER[filetype]?.preview?.component || ENTITY_CONTROLLER.null?.preview?.component}
        modalProps={{ filepath, size: 'lg', fileType: filetype, quizId: filepath }}
        icon={() => <Icon color='#610FB6' />}
      />
    );
  }
  return (<Icon />);
}

export default CurriculumMaterialIcon;
