import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  flex: {
    id: 'app.components.PriceBoard.flex',
    defaultMessage: 'Video content + supporting materials',
  },
  assist: {
    id: 'app.components.PriceBoard.assist',
    defaultMessage: 'Flex + regular Web Сlasses',
  },
  concierge: {
    id: 'app.components.PriceBoard.concierge',
    defaultMessage: 'Assist + homework',
  },
  flexLabel: {
    id: 'app.components.PriceBoard.flexLabel',
    defaultMessage: 'Flex'
  },
  assistLabel: {
    id: 'app.components.PriceBoard.assistLabel',
    defaultMessage: 'Assist'
  },
  conciergeLabel: {
    id: 'app.components.PriceBoard.conciergeLabel',
    defaultMessage: 'Concierge'
  },
});
