// Core
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';

// Components
import WebinarAudio from 'components/Icon/Svg/WebinarAudio';
import WebinarAudioOff from 'components/Icon/Svg/WebinarAudioOff';

// Other
import withAction from 'components/Icon/withAction';

export const WebinarAudioButton = ({ handleClick, action, onAir }) => (
  <button type="button" onClick={handleClick}>
    {onAir ? (
      <WebinarAudio active={action} />
    ) : (
      <WebinarAudioOff active={action} />
    )}
  </button>
);

WebinarAudioButton.propTypes = {
  handleClick: PropTypes.func,
  action: PropTypes.bool,
  onAir: PropTypes.bool,
};

const enhancer = compose(
  memo,
  withAction
);

export default enhancer(WebinarAudioButton);
