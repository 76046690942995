// Core
import React, { PureComponent, Fragment } from 'react';
import { Formik } from 'formik';
import { Auth } from 'aws-amplify';
import { compose } from 'react-apollo';
import { withAuthenticatedUser } from 'containers/AuthenticatedUserProvider/withAuthenticatedUser';

// Components
import ContentHeader from 'containers/ContentHeader';
import Card from 'components/Card';
import ChangePasswordForm from 'routes/ManageAccount/ChangePassword/ChangePasswordForm/';
import SuccessfulChangeMessage from 'routes/ManageAccount/ChangePassword/SuccessfulChangeMessage/';

// Other
import { getYupSchema } from 'services/yup';
import { parseIncomingError } from 'utils/errorHandling';

// Styles and Assets
import './ChangePassword.scss';

const FIELDS = {
  oldPassword: '',
  password: '',
  confirmPassword: '',
};

const ALLOWED_PROVIDERS = [undefined, 'email'];

class ChangePassword extends PureComponent {
  state = {
    success: false,
  };

  handlePasswordChange = async (
    { oldPassword, password },
    { setSubmitting, setErrors }
  ) => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(user, oldPassword, password);
      this.setState({ success: true });
    } catch (error) {
      setSubmitting(false);
      setErrors(parseIncomingError(error, 'Password'));
    }
  };

  isDisabled = () => {
    const { userContext: { profile } } = this.props;
    if (ALLOWED_PROVIDERS.includes(profile?.provider)) {
      return false;
    }
    return true;
  }

  render() {
    const { success } = this.state;
    const disabled = this.isDisabled();
    return (
      <Fragment>
        {success ? (
          <SuccessfulChangeMessage />
        ) : (
          <div className="sk-ma-pw-change">
            <ContentHeader />
            <Card classes="sk-ma-pw-change__inner">
              <Formik
                onSubmit={async (values, formikBag) => {
                  await this.handlePasswordChange(values, formikBag);
                }}
                render={props => <ChangePasswordForm {...props} disabled={disabled} />}
                initialValues={FIELDS}
                validationSchema={getYupSchema('changePasswordValidationSchema')}
                validateOnChange={false}
                validateOnBlur
              />
            </Card>
          </div>
        )}
      </Fragment>
    );
  }
}

const enhancer = compose(
  withAuthenticatedUser
)

export default enhancer(ChangePassword);
