import React from 'react';

const Icon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="2.41992" y="3.05957" width="11.8" height="9.83077" rx="1.5" stroke="#A9B6C8"/>
      <path d="M7.43238 5.57893L10.5219 7.60643C10.6335 7.70297 10.6335 7.8478 10.5219 7.94434L7.54405 9.8753C7.37655 10.0201 7.07877 9.92357 7.06015 9.71438L6.94849 5.75593C6.94849 5.54674 7.24627 5.4341 7.43238 5.57893Z" stroke="#A9B6C8" strokeMiterlimit="10"/>
    </svg>
  );
};

export default Icon;
