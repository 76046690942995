import React from 'react';
import PropTypes from 'prop-types';
import { PurpleHeart, White } from 'components/Icon/color';

const WebinarMic = ({ height, width, strokeColor, fillColor, active }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx={width / 2}
      cy={height / 2}
      r={width / 2}
      fill={fillColor}
      fillOpacity={active ? '1' : '0.75'}
    />
    <g clipPath="url(#micclip0)">
      <path
        d="M32.5 24C32.2 24 32 24.2 32 24.5V27.3C32 30.9
         29.1 33.8 25.5 33.8C21.9 33.8 19 30.9 19 27.3V24.5C19 
         24.2 18.8 24 18.5 24C18.2 24 18 24.2 18 24.5V27.3C18 
         31.2 21.1 34.5 25 34.7V37.1H22.7C22.4 37.1 22.2 37.3
          22.2 37.6C22.2 37.9 22.4 38.1 22.7 38.1H28.3C28.6 38.1
           28.8 37.9 28.8 37.6C28.8 37.3 28.6 37.1 28.3 37.1H26V34.7C29.9 
           34.5 33 31.2 33 27.3V24.5C32.9 24.2 32.7 24 32.5 24Z"
        fill={strokeColor}
      />
      <path
        d="M30.2002 21.5V27.4C30.2002 30 28.1002 32.1 25.5002 32.1C23.5002
         32.1 21.7002 30.8 21.1002 29C20.7002 27.1 20.8002 25 20.8002
          25V22.3V17.2C20.8002 14.6 22.9002 12.5 25.5002 12.5C28.1002 
          12.5 30.2002 14.6 30.2002 17.2V21.5Z"
        stroke={strokeColor}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="micclip0">
        <rect
          width="27"
          height="27"
          fill="white"
          transform="translate(12 11)"
        />
      </clipPath>
    </defs>
  </svg>
);

WebinarMic.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  strokeColor: PropTypes.string,
  fillColor: PropTypes.string,
  active: PropTypes.bool,
};

WebinarMic.defaultProps = {
  height: '50',
  width: '50',
  strokeColor: PurpleHeart,
  fillColor: White,
  active: false,
};

export default WebinarMic;
