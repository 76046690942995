import React from 'react';
import PropTypes from 'prop-types';

const MenuPencil = ({ height, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 24 22"
  >
    <g fill="none" fillRule="evenodd" transform="translate(0 1.063)">
      <rect
        width="17"
        height="15"
        y="5"
        fill="#FBC4BB"
        fillRule="nonzero"
        rx="2"
      />
      <g stroke="#333" transform="translate(6)">
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M16.4016,2.11388462 L14.8107923,0.520134615 C14.33185,0.0431538462
           13.5544269,0.0444615385 13.0764654,0.525038462 L1.58708077,12.9938846
            L0.185561538,17.1886346 C0.0766961538,17.5145769 0.386946154,17.8251538
             0.712888462,17.7162885 L4.9096,16.3147692 L16.3966962,3.84690385
              C16.8766192,3.36698077 16.8776,2.58988462 16.4016,2.11388462 Z"
        />
        <path d="M1.41666667,12.75 L4.95833333,16.2916667" />
      </g>
    </g>
  </svg>
);

MenuPencil.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

MenuPencil.defaultProps = {
  height: '22',
  width: '24',
};

export default MenuPencil;
