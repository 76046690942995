// Core
import React, { memo, useState, useEffect } from 'react';
import { Progress } from 'react-sweet-progress';
import { compose } from 'react-apollo';
import classnames from 'classnames';

// Other
import { withUploadingStatuses } from 'containers/UploadManagerProvider/withUploadingStatuses.js';
import { withFileUrl } from 'containers/HOCs/withFileUrl';
import { withUploadManager } from 'containers/UploadManagerProvider/withUploadManager';
import { theme } from 'containers/UploadManagerProvider/helpers';
import { isBlank } from 'utils/helpers';

// Style
import './index.scss';

const getFileUrlFromBlob = (file) => {
  return isBlank(file) ? null : URL.createObjectURL(file);
};

const ImageWithLoader = ({
  withLoader,
  fileUrl,
  isLoaded,
  file: image,
  fileUploadStatuses,
  fileUploadStatuses: { isFileInQueue, isFileUploaded, isFileInProgress, isFileProcessing, isFileUploadedAndProcessed },
  uploadManagerContext: { queue, uploaded, progress, getFileFromQueue, getFileFromUploadedFiles },
  background,
  ...rest
}) => {
  const [fileRef, setFileRef] = useState(null);

  useEffect(() => {
    if (isFileInQueue || isFileInProgress) {
      const { isFileInList, index } = getFileFromQueue(image?.id);
      const file = isFileInList ? queue[index]?.file : null;
      const fileRef = getFileUrlFromBlob(file);
      setFileRef(fileRef);
    } else if (isFileUploaded && isFileProcessing) {
      const { isFileInList, index } = getFileFromUploadedFiles(image?.id);
      const file = isFileInList ? uploaded[index]?.s3UploadingVariables?.file : null;
      const fileRef = getFileUrlFromBlob(file);
      setFileRef(fileRef);
    } else if (isFileUploadedAndProcessed && isLoaded) {
      setFileRef(fileUrl);
    }

  }, [fileUrl, isFileUploadedAndProcessed, isLoaded, isFileInQueue, isFileInProgress, isFileUploaded, queue, uploaded])

  return (
    <div className='sk-image-with-loader'>
      {(background && !image?.id) ? background : (
        <img src={fileRef} className={classnames({ 'sk-image-with-loader__visible': progress === 0 })} />
      )}
      {(withLoader && isFileInProgress) && (
        <div className='sk-image-with-loader__loader'>
          <Progress
            type="circle"
            theme={theme}
            percent={progress}
            status={'active'}
          />
        </div>
      )}
    </div>
  )
};

const enhancer = compose(
  withUploadingStatuses,
  withFileUrl,
)

export default enhancer(ImageWithLoader);
