import React from 'react';
import PropTypes from 'prop-types';
import { SidebarDefault, PurpleHeart, Red } from 'components/Icon/color';

const strokeShowe = (red, active) => {
  if (red) {
    return Red;
  }
  if (active) {
    return PurpleHeart;
  }
  return SidebarDefault;
};

const Article = ({ height, width, active, red }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 30"
    width={width}
    height={height}
  >
    <g fill="none" fillRule="evenodd">
      <g
        fillRule="nonzero"
        stroke={strokeShowe(red, active)}
        transform="translate(0 .04)"
      >
        <rect
          x="0.5"
          y="0.5"
          width="20.6667"
          height="29"
          rx="1.5"
          stroke="#767676"
        />
        <path d="M5.00033 0V30" stroke="#767676" />
      </g>
    </g>
  </svg>
);

Article.propTypes = {
  red: PropTypes.bool,
  active: PropTypes.bool,
  height: PropTypes.string,
  width: PropTypes.string,
};

Article.defaultProps = {
  red: false,
  active: false,
  height: '14',
  width: '10',
};

export default Article;
