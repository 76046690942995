// Core
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose, } from 'react-apollo';

// Components
import Loader from 'components/Loader';
import Heading from 'components/Heading';
import Text from 'components/Text';
import { withFileUrl } from 'containers/HOCs/withFileUrl';

// Styles and Assets
import './CourseModuleArticleText.scss';

const CourseModuleArticleText = ({ fileUrl, isLoaded, title }) => {
  const [text, setText] = useState('');

  useEffect(() => {
    const fetchFileLink = async () => {
      if (isLoaded) {
        const file = await fetch(fileUrl);
        const text = await file.text();
        setText(text);
      }
    };
    fetchFileLink();
  }, [fileUrl, isLoaded])

  if (!isLoaded) return <Loader />

  return (
    <div className="sk-course-module-article">
      <div className="sk-course-module-article__title">
        <Heading variant="h3">{title}</Heading>
      </div>
      <div className="sk-course-module-article__content">
        <Text newLine classes="sk-course-module-article__text">
          {text}
        </Text>
      </div>
    </div>
  )
}

CourseModuleArticleText.propTypes = {
  title: PropTypes.string,
  filepath: PropTypes.string,
};

const enhancer = compose(
  withFileUrl
)

export default enhancer(CourseModuleArticleText);
