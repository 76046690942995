// Core
import React, { PureComponent } from 'react';

// Components
import Text from 'components/Text';
import ToolTip from 'components/ToolTip';

// Styles and Assets
import './InteractionLevelToolTip.scss';

// Other
import { FormattedMessage } from 'react-intl';
import { messages } from 'routes/Course/components/InteractionLevelToolTip/messages';

class InteractionLevelToolTip extends PureComponent {
  state = {};

  render() {
    return (
      <ToolTip iconTheme='orange'>
        <div className="sk-course-intecation-level-tooltip">
          <Text classes="sk-course-intecation-level-tooltip__text" size="small">
            <FormattedMessage {...messages.flexText} />
          </Text>
          <Text classes="sk-course-intecation-level-tooltip__text" size="small">
            <FormattedMessage {...messages.assistText} />
          </Text>
          <Text classes="sk-course-intecation-level-tooltip__text" size="small">
            <FormattedMessage {...messages.conciergeText} />
          </Text>
        </div>
      </ToolTip>
    );
  }
}

InteractionLevelToolTip.propTypes = {};

export default InteractionLevelToolTip;
