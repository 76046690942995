import React from 'react';
import PropTypes from 'prop-types';

const MenuLightBulb = ({ height, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 28 31"
    width={width}
    height={height}
  >
    <g fill="none" fillRule="evenodd">
      <g transform="translate(0 1.063)">
        <rect
          width="15.552"
          height="18.621"
          y="11.315"
          fill="#FBC4BB"
          fillRule="nonzero"
          rx="2"
        />
        <path
          stroke="#333"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M20.662 11.746a9.711 9.711 0 0 1 2.047 5.98c0 5.385-4.365 9.75-9.75 9.75-5.386 
          0-9.752-4.365-9.752-9.75 0-4.713 3.343-8.644 7.786-9.554"
        />
        <path
          stroke="#333"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M14.789 12.976a5.032 5.032 0 0 1-1.83 9.718 5.03 5.03 0 0 1-1.778-9.739M13.117 18.065V0"
        />
        <path
          stroke="#333"
          strokeLinejoin="round"
          d="M21.696 8.372h-7.465a1.117 1.117 0 0 1-1.114-1.114V2.876c0-.612.5-1.114 1.114-1.114h7.465c.613 0 1.114.502 
          1.114 1.114v4.382c0 .612-.501 1.114-1.114 1.114z"
        />
        <path
          stroke="#333"
          strokeLinejoin="round"
          d="M23.03 3.084h2.632c.612 0 1.114.502 1.114 1.114V8.58c0 .612-.502 
          1.113-1.114 1.113h-5.703a1.117 1.117 0 0 1-1.114-1.113"
        />
      </g>
    </g>
  </svg>
);

MenuLightBulb.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

MenuLightBulb.defaultProps = {
  height: '31',
  width: '28',
};

export default MenuLightBulb;
