// Core
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

// Components
import TimePicker from 'components/TimePicker';
import DatePicker from 'components/DatePicker';

// Other
import { Grey } from 'components/Icon/color';

// Styles and Assets
import './DurationSelect.scss';

const DurationSelect = ({ startTime, endTime, errors, touched, handleInputChange, handleInputBlur, }) => {
  return (
    <div className="sk-discount-duration">
      <div className="sk-discount-duration__selector">
        <TimePicker
          label='Start time'
          name="startTime"
          time={moment(startTime)}
          handlePickerChange={handleInputChange}
          // onBlur={handleInputBlur('startTime')}
          helperText={touched.startTime ? errors.startTime : ''}
          error={touched.startTime && Boolean(errors.startTime)}
        />
      </div>
      <div className="sk-discount-duration__selector">
        <DatePicker
          label='Start date'
          name="startTime"
          date={moment(startTime)}
          handlePickerChange={handleInputChange}
          // onBlur={handleInputBlur('startTime')}
          helperText={touched.startTime ? errors.startTime : ''}
          error={touched.startTime && Boolean(errors.startTime)}
        />
      </div>
      <div className="sk-discount-duration__selector">
        <TimePicker
          label='End time'
          name="endTime"
          time={moment(endTime)}
          handlePickerChange={handleInputChange}
          // onBlur={handleInputBlur('endTime')}
          helperText={touched.endTime ? errors.endTime : ''}
          error={touched.endTime && Boolean(errors.endTime)}
        />
      </div>
      <div className="sk-discount-duration__selector">
        <DatePicker
          label='End date'
          name="endTime"
          date={moment(endTime)}
          handlePickerChange={handleInputChange}
          // onBlur={handleInputBlur('endTime')}
          helperText={touched.endTime ? errors.endTime : ''}
          error={touched.endTime && Boolean(errors.endTime)}
        />
      </div>
    </div>
  );
};

DurationSelect.propTypes = {
};

export default DurationSelect;
