// Core
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Collapse from '@material-ui/core/Collapse';

// Components
import Heading from 'components/Heading';
import UserAvatar from 'components/UserAvatar';
import Text from 'components/Text';

// Other
import { WEBINAR_DESC_LENGTH } from 'utils/enums';

// Styles and Assets
import './WebinarInformation.scss';

const WebinarDescriptionBlock = ({
  content,
  isContentCollapsed,
  toggleContentCollapsation,
  isContentTogglerVisible,
}) => (
  <div className="sk-web-desc-block">
    <Collapse in={!isContentCollapsed} collapsedHeight="43px">
      <Text size="small" classes="sk-web-desc-block__text">
        {content}
      </Text>
    </Collapse>
    {isContentTogglerVisible && (
      <button
        type="button"
        className="sk-web-desc-block__button"
        onClick={toggleContentCollapsation}
      >
        {!isContentCollapsed ? 'Show Less' : 'Show More'}
      </button>
    )}
  </div>
);

const WebinarInformation = ({
  webinar,
  authorFirstName,
  authorLastName,
  authorAvatar,
}) => {
  const [isContentCollapsed, setContentCollapsation] = useState(true);

  const toggleContentCollapsation = () =>
    setContentCollapsation(!isContentCollapsed);

  const isContentTogglerVisible =
    !!webinar?.description &&
    webinar?.description.length >= WEBINAR_DESC_LENGTH;

  return (
    <div className="sk-web-info">
      <div className="sk-web-info__webinar">
        <header className="sk-web-info__header">
          <Heading variant="h5">{webinar?.title}</Heading>
        </header>
        <WebinarDescriptionBlock
          content={webinar?.description}
          isContentCollapsed={isContentCollapsed}
          isContentTogglerVisible={isContentTogglerVisible}
          toggleContentCollapsation={toggleContentCollapsation}
        />
      </div>
      <div className="sk-web-info__course">
        <div className="sk-web-info__author">
          <UserAvatar
            classes="sk-web-info__avatar"
            firstName={authorFirstName}
            lastName={authorLastName}
            avatar={authorAvatar}
          />

          <Text size="small">
            {authorFirstName} {authorLastName}
          </Text>
        </div>
      </div>
    </div>
  );
};

WebinarInformation.propTypes = {
  webinar: PropTypes.object,
  authorFirstName: PropTypes.string,
  authorLastName: PropTypes.string,
  authorAvatar: PropTypes.string,
};

WebinarDescriptionBlock.propTypes = {
  content: PropTypes.string,
  isContentCollapsed: PropTypes.bool,
  toggleContentCollapsation: PropTypes.func,
  isContentTogglerVisible: PropTypes.bool,
};

export default WebinarInformation;
