import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  activeCourses: {
    id: 'app.components.StudentActiveCoursesList.activeCourses',
    defaultMessage: 'Courses',
  },
  courseStudyMats: {
    id: 'app.components.PurchasedCoursesHero.courseStudyMats',
    defaultMessage: 'Course Study Materials',
  },
  resumeCourse: {
    id: 'app.components.StudentActiveCoursesList.resumeCourse',
    defaultMessage: 'Resume Course from Lecture',
  },
  sendMessageToExpert: {
    id: 'app.components.StudentActiveCoursesList.sendMessageToExpert',
    defaultMessage: 'Send message to Expert',
  },
});
