import React, { memo } from 'react';
import { compose } from 'react-apollo';

const Icon = ({ ...props }) => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.3501 8.3294C14.4313 8.02243 13.4698 7.86184 12.5011 7.85352C7.76729 7.85352 3.98527 11.9799 3.82873 12.1553C3.74465 12.2503 3.69824 12.3728 3.69824 12.4996C3.69824 12.6265 3.74465 12.749 3.82873 12.844C4.87481 13.9513 6.07472 14.9024 7.39159 15.668M11.2863 17.0518C11.6824 17.1097 12.0821 17.139 12.4823 17.1395C17.2161 17.1395 20.9981 13.0194 21.1547 12.844C21.2387 12.749 21.2851 12.6265 21.2851 12.4996C21.2851 12.3728 21.2387 12.2503 21.1547 12.1553C20.21 11.1593 19.1384 10.2919 17.9675 9.57546" stroke="url(#paint0_linear)" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16.2944 11.1665C16.4432 11.5955 16.5194 12.0462 16.5198 12.5002C16.52 13.1473 16.3628 13.7847 16.0617 14.3574C15.7607 14.9302 15.3248 15.4211 14.7917 15.7878C14.2586 16.1545 13.6442 16.386 13.0017 16.4623C12.3592 16.5386 11.7077 16.4575 11.1035 16.2259" stroke="url(#paint1_linear)" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.96225 14.3475C8.55403 13.5583 8.42054 12.6556 8.58289 11.782C8.74524 10.9085 9.19417 10.114 9.85868 9.52421C10.5232 8.93442 11.3654 8.58298 12.252 8.52547C13.1386 8.46796 14.0191 8.70767 14.7543 9.20669" stroke="url(#paint2_linear)" stroke-linecap="round" stroke-linejoin="round"/>
<mask id="path-5-inside-1" fill="white">
<path d="M14.0028 10.7346C13.5303 10.3606 12.9306 10.1854 12.331 10.2462C11.7838 10.2894 11.2709 10.5296 10.8874 10.9222C10.504 11.3149 10.276 11.8334 10.2459 12.3814C10.2138 12.9395 10.3926 13.4893 10.7468 13.9218"/>
</mask>
<path d="M14.0028 10.7346C13.5303 10.3606 12.9306 10.1854 12.331 10.2462C11.7838 10.2894 11.2709 10.5296 10.8874 10.9222C10.504 11.3149 10.276 11.8334 10.2459 12.3814C10.2138 12.9395 10.3926 13.4893 10.7468 13.9218" fill="url(#paint3_linear)"/>
<path d="M12.331 10.2462L12.4096 11.2431L12.4208 11.2422L12.4319 11.2411L12.331 10.2462ZM10.2459 12.3814L11.2442 12.4388L11.2444 12.4364L10.2459 12.3814ZM14.6235 9.95055C13.947 9.41503 13.0884 9.16422 12.23 9.25133L12.4319 11.2411C12.7727 11.2065 13.1136 11.3061 13.3822 11.5187L14.6235 9.95055ZM12.2524 9.24931C11.4638 9.31149 10.7246 9.65766 10.172 10.2236L11.6029 11.6209C11.8172 11.4015 12.1038 11.2672 12.4096 11.2431L12.2524 9.24931ZM10.172 10.2236C9.61934 10.7895 9.29081 11.5367 9.24737 12.3265L11.2444 12.4364C11.2612 12.1301 11.3886 11.8404 11.6029 11.6209L10.172 10.2236ZM9.24751 12.3241C9.20105 13.1325 9.46004 13.9289 9.97316 14.5554L11.5204 13.2882C11.3251 13.0497 11.2265 12.7465 11.2442 12.4388L9.24751 12.3241Z" fill="url(#paint4_linear)" mask="url(#path-5-inside-1)"/>
<mask id="path-7-inside-2" fill="white">
<path d="M11.8672 14.6667C12.071 14.7242 12.2816 14.7536 12.4934 14.7543C12.855 14.7522 13.2109 14.6635 13.5313 14.4957C13.8517 14.3279 14.1273 14.0859 14.3351 13.7899C14.5428 13.4938 14.6767 13.1524 14.7256 12.794C14.7745 12.4357 14.7369 12.0708 14.616 11.73"/>
</mask>
<path d="M11.8672 14.6667C12.071 14.7242 12.2816 14.7536 12.4934 14.7543C12.855 14.7522 13.2109 14.6635 13.5313 14.4957C13.8517 14.3279 14.1273 14.0859 14.3351 13.7899C14.5428 13.4938 14.6767 13.1524 14.7256 12.794C14.7745 12.4357 14.7369 12.0708 14.616 11.73" fill="url(#paint5_linear)"/>
<path d="M12.4934 14.7543L12.49 15.7544L12.4994 15.7543L12.4934 14.7543ZM11.5958 15.6291C11.8868 15.7112 12.1876 15.7533 12.49 15.7543L12.4967 13.7544C12.3756 13.7539 12.2551 13.7371 12.1386 13.7042L11.5958 15.6291ZM12.4994 15.7543C13.0206 15.7512 13.5335 15.6234 13.9952 15.3816L13.0674 13.6098C12.8883 13.7036 12.6894 13.7531 12.4873 13.7544L12.4994 15.7543ZM13.9952 15.3816C14.457 15.1398 14.8541 14.791 15.1536 14.3644L13.5165 13.2154C13.4004 13.3808 13.2464 13.5161 13.0674 13.6098L13.9952 15.3816ZM15.1536 14.3644C15.453 13.9377 15.646 13.4456 15.7165 12.9292L13.7348 12.6589C13.7075 12.8591 13.6327 13.05 13.5165 13.2154L15.1536 14.3644ZM15.7165 12.9292C15.7869 12.4128 15.7327 11.887 15.5585 11.3957L13.6736 12.0643C13.7411 12.2547 13.7621 12.4586 13.7348 12.6589L15.7165 12.9292Z" fill="url(#paint6_linear)" mask="url(#path-7-inside-2)"/>
<path d="M12.5002 22.7943C18.1855 22.7943 22.7943 18.1855 22.7943 12.5002C22.7943 6.81489 18.1855 2.20605 12.5002 2.20605C6.81489 2.20605 2.20605 6.81489 2.20605 12.5002C2.20605 18.1855 6.81489 22.7943 12.5002 22.7943Z" stroke="url(#paint7_linear)" stroke-miterlimit="10"/>
<path d="M5.6748 20.0393L20.1266 5.59375" stroke="url(#paint8_linear)" stroke-miterlimit="10"/>
<defs>
<linearGradient id="paint0_linear" x1="22.8776" y1="17.7646" x2="1.68325" y2="12.8406" gradientUnits="userSpaceOnUse">
<stop stop-color="#8F26AF"/>
<stop offset="0.979167" stop-color="#141685"/>
</linearGradient>
<linearGradient id="paint1_linear" x1="17.0102" y1="16.8485" x2="10.2362" y2="16.0031" gradientUnits="userSpaceOnUse">
<stop stop-color="#8F26AF"/>
<stop offset="0.979167" stop-color="#141685"/>
</linearGradient>
<linearGradient id="paint2_linear" x1="15.3191" y1="14.7399" x2="7.52925" y2="13.7174" gradientUnits="userSpaceOnUse">
<stop stop-color="#8F26AF"/>
<stop offset="0.979167" stop-color="#141685"/>
</linearGradient>
<linearGradient id="paint3_linear" x1="14.3433" y1="14.17" x2="9.64031" y2="13.5816" gradientUnits="userSpaceOnUse">
<stop stop-color="#8F26AF"/>
<stop offset="0.979167" stop-color="#141685"/>
</linearGradient>
<linearGradient id="paint4_linear" x1="14.3433" y1="14.17" x2="9.64031" y2="13.5816" gradientUnits="userSpaceOnUse">
<stop stop-color="#8F26AF"/>
<stop offset="0.979167" stop-color="#141685"/>
</linearGradient>
<linearGradient id="paint5_linear" x1="15.0071" y1="14.9579" x2="11.3992" y2="14.5366" gradientUnits="userSpaceOnUse">
<stop stop-color="#8F26AF"/>
<stop offset="0.979167" stop-color="#141685"/>
</linearGradient>
<linearGradient id="paint6_linear" x1="15.0071" y1="14.9579" x2="11.3992" y2="14.5366" gradientUnits="userSpaceOnUse">
<stop stop-color="#8F26AF"/>
<stop offset="0.979167" stop-color="#141685"/>
</linearGradient>
<linearGradient id="paint7_linear" x1="24.6585" y1="24.1801" x2="-1.10433" y2="21.0198" gradientUnits="userSpaceOnUse">
<stop stop-color="#8F26AF"/>
<stop offset="0.979167" stop-color="#141685"/>
</linearGradient>
<linearGradient id="paint8_linear" x1="21.4352" y1="21.0117" x2="3.35132" y2="18.7924" gradientUnits="userSpaceOnUse">
<stop stop-color="#8F26AF"/>
<stop offset="0.979167" stop-color="#141685"/>
</linearGradient>
</defs>
</svg>

  );
};

const enhancer = compose(
  memo,
);

export default enhancer(Icon);
