// Core
import React, { useState, useEffect, useCallback } from 'react';

// Components
import Text from 'components/Text';
import QuizPreviewAnswer from 'components/QuizPreview/QuizPreviewAnswer';
import UploadedImage from 'components/UploadedImage';
import ExplanationIcon from 'components/Icon/SvgCss/Quiz/QuestionExplanation';

// Other
import { withFormattedUntitled } from 'routes/ExpertsCabinet/QuizEdit/HOCs/withFormattedUntitled.js';
import { isBlank, isEmptyArray, isTrue, hasText, compareSortNumber } from 'utils/helpers';
import { messages } from 'routes/ExpertsCabinet/QuizEdit/messages';

// Styles and Assets
import './index.scss';

const QuizPreviewQuestion = ({
  question,
  isQuizCompleted,
  setAnswer,
  formatUntitled,
  intl: { formatMessage },
}) => {
  const hasQuestionAnswers = !isEmptyArray(question?.answers);
  const isMultiselectQuestionType = question?.multiselect;

  return (
    <div className='sk-quiz-preview-question'>
      <div className='sk-quiz-preview-question__general-info'>
        <Text>{`${question.sortNumber}. ${formatUntitled(question?.title, messages.quizQuestionUntitledTitle)}`}</Text>
      </div>
      {(!isBlank(question?.materials) && !isEmptyArray(question?.materials)) && question?.materials.map(image => {
        return (
          <UploadedImage
            filepath={image?.filepath}
            image={image}
            readOnly={true}
          />
        )
      })}
      {(!isBlank(hasQuestionAnswers) && hasQuestionAnswers) && (
        <div className='sk-quiz-preview-question__answers-group'>
          {isMultiselectQuestionType && (<Text>{formatMessage(messages.selectAllThatApply)}</Text>)}
          {question?.answers?.sort(compareSortNumber).map(answer => {
            const isSelected = isQuizCompleted ? answer?.selected : question?.selectedAnswers.includes(answer.id);

            return (
              <QuizPreviewAnswer
                key={answer.id}
                multiselect={question?.multiselect}
                isSelected={isSelected}
                questionId={question?.id}
                answer={answer}
                isQuizCompleted={isQuizCompleted}
                setAnswer={setAnswer}
              />
            )
          })}
        </div>
      )}
      {(hasText(question?.explanation) && isQuizCompleted) && (
        <div className='sk-quiz-preview-question__explanation'>
          <ExplanationIcon />
          <Text>{question?.explanation}</Text>
        </div>
      )}
    </div>
  )
};

export default withFormattedUntitled(QuizPreviewQuestion);
