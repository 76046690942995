import React from 'react';
import slugify from '@sindresorhus/slugify';
import { getSeparatedFilename } from 'services/aws/amplify/helpers';

// Other
import { COURSE_PREVIEW, LECTURE_QUIZ, LECTURE_VIDEO, } from 'containers/UploadManagerProvider/helpers';
import { VIDEO_FILE, QUIZ_ATTACHMENT, } from 'utils/enums/index';

export default function useToppingMatterialsUploadMethods({ type, topping, material, uploadFile }) {
  const handleUploadPreview = async (fileList) => {
    const file = fileList[0];
    const { fileName, ext } = getSeparatedFilename(file.name);
    const beautyFilename = `${slugify(fileName)}.${ext}`;

    uploadFile({
      id: material?.id,
      courseId: topping?.id,
      filetype: VIDEO_FILE,
      title: beautyFilename,
      isPublic: true,
      file,
    });
  };
  const handleUploadLectureVideo = async (fileList) => {
    const file = fileList[0];
    const { fileName, ext } = getSeparatedFilename(file.name);
    const beautyFilename = `${slugify(fileName)}.${ext}`;
    uploadFile({
      id: material?.id,
      courseId: topping?.id,
      lectureId: topping?.lectures[0].id,
      filetype: VIDEO_FILE,
      title: beautyFilename,
      file,
    });
  };
  const handleUploadLectureQuiz = async () => {
    uploadFile({ courseId: topping?.id, lectureId: topping?.lectures[0].id, referer: 'toppings' });
  };

  const UPLOAD_METHODS = {
    [COURSE_PREVIEW]: handleUploadPreview,
    [LECTURE_VIDEO]: handleUploadLectureVideo,
    [LECTURE_QUIZ]: handleUploadLectureQuiz,
  };

  return UPLOAD_METHODS[type];
};
