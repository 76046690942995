import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  apples: {
    id: 'app.components.AuthorRating.apples',
    defaultMessage: 'apples',
  },
  tomatoes: {
    id: 'app.components.AuthorRating.tomatoes',
    defaultMessage: 'tomatoes',
  },
});
