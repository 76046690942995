import React from 'react';

import './BasketOutline.scss';

const BasketOutline = ({ isHover, disabled }) => {
  const indexHover = Math.random();
  const indexNotHover = Math.random()*2;
  return (
    <svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg" className={`BasketOutline ${disabled ? 'disable' : ''}`}>
      {
        !isHover ? (
          <g>
            <mask id={`mask10${indexNotHover}`} style={{maskType: "alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="19" height="17">
              <path fillRule="evenodd" clipRule="evenodd" d="M0.0253906 0.257812H18.4876V16.7842H0.0253906V0.257812Z" fill="white"/>
            </mask>
            <g mask={`url(#mask10${indexNotHover})`}>
              <path fillRule="evenodd" clipRule="evenodd" d="M17.9392 16.7842H8.6723C7.54536 16.7842 6.4544 15.9263 6.18909 14.8307L3.62232 4.23704C3.46332 3.58154 2.80294 2.72595 2.20895 2.40609L0.313932 1.38569C0.0472271 1.24225 -0.052584 0.909387 0.0910975 0.642915L0.143092 0.546353C0.286774 0.279649 0.6194 0.179838 0.885872 0.323519L2.78089 1.34368C3.67176 1.8237 4.55637 2.96967 4.79452 3.95316L7.36152 14.5466C7.49453 15.096 8.10732 15.5779 8.6723 15.5779H17.9392C18.2421 15.5779 18.4877 15.8233 18.4877 16.1259V16.2357C18.4877 16.5386 18.2421 16.7842 17.9392 16.7842Z" fill={`url(#paint10_linear${indexNotHover})`}/>
            </g>
            <path fillRule="evenodd" clipRule="evenodd" d="M17.61 14.2604H6.6004V13.0539H17.61C18.1982 13.0539 18.8806 12.5655 19.0705 12.0089L20.7527 7.07937C20.8202 6.8823 20.8128 6.71169 20.7323 6.59865C20.6517 6.4863 20.4925 6.42433 20.2843 6.42433H4.7019V5.21777H20.2843C20.8836 5.21777 21.4047 5.46521 21.7134 5.89695C22.0221 6.32846 22.0881 6.90133 21.8945 7.46909L20.2123 12.3986C19.856 13.4427 18.7131 14.2604 17.61 14.2604Z" fill={`url(#paint1_linear${indexNotHover})`}/>
            <mask id={`mask11${indexNotHover}`} mask-type="alpha" maskUnits="userSpaceOnUse" x="7" y="17" width="4" height="4">
              <path fillRule="evenodd" clipRule="evenodd" d="M7.2085 17.0107H10.9308V20.733H7.2085V17.0107Z" fill="white"/>
            </mask>
            <g mask={`url(#mask11${indexNotHover})`}>
              <path fillRule="evenodd" clipRule="evenodd" d="M9.06955 18.2172C8.70861 18.2172 8.41498 18.5109 8.41498 18.8716C8.41498 19.2327 8.70861 19.5264 9.06955 19.5264C9.4305 19.5264 9.72413 19.2327 9.72413 18.8716C9.72413 18.5109 9.4305 18.2172 9.06955 18.2172ZM9.06962 20.733C8.04366 20.733 7.2085 19.8978 7.2085 18.8716C7.2085 17.8457 8.04366 17.0107 9.06962 17.0107C10.0958 17.0107 10.9308 17.8457 10.9308 18.8716C10.9308 19.8978 10.0958 20.733 9.06962 20.733Z" fill={`url(#paint2_linear${indexNotHover})`}/>
            </g>
            <mask id={`mask21${indexNotHover}`} mask-type="alpha" maskUnits="userSpaceOnUse" x="14" y="17" width="5" height="4">
              <path fillRule="evenodd" clipRule="evenodd" d="M14.9646 17.0107H18.6866V20.733H14.9646V17.0107Z" fill="white"/>
            </mask>
            <g mask={`url(#mask21${indexNotHover})`}>
              <path fillRule="evenodd" clipRule="evenodd" d="M16.8257 18.2172C16.4645 18.2172 16.1709 18.5109 16.1709 18.8716C16.1709 19.2327 16.4645 19.5264 16.8257 19.5264C17.1864 19.5264 17.4803 19.2327 17.4803 18.8716C17.4803 18.5109 17.1864 18.2172 16.8257 18.2172ZM16.8257 20.733C15.7995 20.733 14.9646 19.8978 14.9646 18.8716C14.9646 17.8457 15.7995 17.0107 16.8257 17.0107C17.8517 17.0107 18.6866 17.8457 18.6866 18.8716C18.6866 19.8978 17.8517 20.733 16.8257 20.733Z" fill={`url(#paint3_linear${indexNotHover})`}/>
            </g>
            <defs>
              <linearGradient id={`paint10_linear${indexNotHover}`} x1="-1.20543" y1="12.473" x2="19.8318" y2="11.8961" gradientUnits="userSpaceOnUse">
                <stop stopColor="#30d5c8"/>
                <stop offset="1" stopColor="#55d5ca"/>
                <stop offset="1" stopColor="#3ad1c5"/>
              </linearGradient>
              <linearGradient id={`paint1_linear${indexNotHover}`} x1="3.54845" y1="11.9015" x2="23.2348" y2="10.977" gradientUnits="userSpaceOnUse">
                <stop stopColor="#30d5c8"/>
                <stop offset="1" stopColor="#55d5ca"/>
                <stop offset="1" stopColor="#3ad1c5"/>
              </linearGradient>
              <linearGradient id={`paint2_linear${indexNotHover}`} x1="6.96035" y1="19.762" x2="11.2024" y2="19.6579" gradientUnits="userSpaceOnUse">
                <stop stopColor="#30d5c8"/>
                <stop offset="1" stopColor="#55d5ca"/>
                <stop offset="1" stopColor="#3ad1c5"/>
              </linearGradient>
              <linearGradient id={`paint3_linear${indexNotHover}`} x1="14.7165" y1="19.762" x2="18.9582" y2="19.6579" gradientUnits="userSpaceOnUse">
                <stop stopColor="#30d5c8"/>
                <stop offset="1" stopColor="#55d5ca"/>
                <stop offset="1" stopColor="#3ad1c5"/>
              </linearGradient>
            </defs>
          </g>
        ) : (
          <g>
            <mask id={`mask101${indexHover}`} mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="19" height="17">
              <path fillRule="evenodd" clipRule="evenodd" d="M0.0253906 0.257812H18.4876V16.7842H0.0253906V0.257812Z" fill="white"/>
            </mask>
            <g mask={`url(#mask101${indexHover})`}>
              <path fillRule="evenodd" clipRule="evenodd" d="M17.9392 16.7842H8.6723C7.54536 16.7842 6.4544 15.9263 6.18909 14.8307L3.62232 4.23704C3.46332 3.58154 2.80294 2.72595 2.20895 2.40609L0.313932 1.38569C0.0472271 1.24225 -0.052584 0.909387 0.0910975 0.642915L0.143092 0.546353C0.286774 0.279649 0.6194 0.179838 0.885872 0.323519L2.78089 1.34368C3.67176 1.8237 4.55637 2.96967 4.79452 3.95316L7.36152 14.5466C7.49453 15.096 8.10732 15.5779 8.6723 15.5779H17.9392C18.2421 15.5779 18.4877 15.8233 18.4877 16.1259V16.2357C18.4877 16.5386 18.2421 16.7842 17.9392 16.7842Z" fill="white"/>
            </g>
            <path fillRule="evenodd" clipRule="evenodd" d="M17.61 14.2604H6.6004V13.0539H17.61C18.1982 13.0539 18.8806 12.5655 19.0705 12.0089L20.7527 7.07937C20.8202 6.8823 20.8128 6.71169 20.7323 6.59865C20.6517 6.4863 20.4925 6.42433 20.2843 6.42433H4.7019V5.21777H20.2843C20.8836 5.21777 21.4047 5.46521 21.7134 5.89695C22.0221 6.32846 22.0881 6.90133 21.8945 7.46909L20.2123 12.3986C19.856 13.4427 18.7131 14.2604 17.61 14.2604Z" fill="white"/>
            <mask id={`mask111${indexHover}`} mask-type="alpha" maskUnits="userSpaceOnUse" x="7" y="17" width="4" height="4">
              <path fillRule="evenodd" clipRule="evenodd" d="M7.2085 17.0107H10.9308V20.733H7.2085V17.0107Z" fill="white"/>
            </mask>
            <g mask={`url(#mask111${indexHover})`}>
              <path fillRule="evenodd" clipRule="evenodd" d="M9.06955 18.2172C8.70861 18.2172 8.41498 18.5109 8.41498 18.8716C8.41498 19.2327 8.70861 19.5264 9.06955 19.5264C9.4305 19.5264 9.72413 19.2327 9.72413 18.8716C9.72413 18.5109 9.4305 18.2172 9.06955 18.2172ZM9.06962 20.733C8.04366 20.733 7.2085 19.8978 7.2085 18.8716C7.2085 17.8457 8.04366 17.0107 9.06962 17.0107C10.0958 17.0107 10.9308 17.8457 10.9308 18.8716C10.9308 19.8978 10.0958 20.733 9.06962 20.733Z" fill="white"/>
            </g>
            <mask id={`mask121${indexHover}`} mask-type="alpha" maskUnits="userSpaceOnUse" x="14" y="17" width="5" height="4">
              <path fillRule="evenodd" clipRule="evenodd" d="M14.9646 17.0107H18.6866V20.733H14.9646V17.0107Z" fill="white"/>
            </mask>
            <g mask={`url(#mask121${indexHover})`}>
              <path fillRule="evenodd" clipRule="evenodd" d="M16.8257 18.2172C16.4645 18.2172 16.1709 18.5109 16.1709 18.8716C16.1709 19.2327 16.4645 19.5264 16.8257 19.5264C17.1864 19.5264 17.4803 19.2327 17.4803 18.8716C17.4803 18.5109 17.1864 18.2172 16.8257 18.2172ZM16.8257 20.733C15.7995 20.733 14.9646 19.8978 14.9646 18.8716C14.9646 17.8457 15.7995 17.0107 16.8257 17.0107C17.8517 17.0107 18.6866 17.8457 18.6866 18.8716C18.6866 19.8978 17.8517 20.733 16.8257 20.733Z" fill="white"/>
            </g>
          </g>
        )
      }
    </svg>
  );
}

export default BasketOutline;
