// Core
import React from 'react';
import cx from 'classnames';
import { compose } from 'react-apollo';
import { injectIntl } from 'react-intl';
import classnames from 'classnames';

// Compoents
import AddToCartIcon from 'components/CourseCard/AddToCartIcon';
import Button from 'components/Button';
import IconArrowDown from 'components/NetflixCarousel/modules/IconArrowDown';
import DropDownIcon from './DropDownIcon';

// Other
import {messages} from 'components/NetflixCarousel/messages.js';
import { withShoppingCart } from 'containers/ShoppingCartProvider/withShoppingCart';

// Styles
import './index.scss';

const ActionBlock = ({
  topping,
  handleClick,
  isActive,
  intl: { formatMessage },
  shoppingCartContext: {
    _isCourseAddedInShoppingCart,
    handleAddCourseToCart,
    handleBuyNowClick,
  },
  isOpen,
  boughted,
}) => {
  const variantName = topping.variants[0].name;
  const variantPrice = topping.variants[0].price;
  const isFree = !variantPrice;

  return(
    <div className={classnames("sk-topping-card_action-block", {"open": isOpen})}>
      <div className="sk-topping-card_action-block__buttons">
        <div
          className="sk-topping-card_action-block__add-to-cart"
          onClick={!boughted && handleAddCourseToCart({ interactionLevel: variantName, price: variantPrice, ...topping})}
        >
          <AddToCartIcon
            disabled={boughted || _isCourseAddedInShoppingCart({ id: topping?.id })}
            styles={{
              width: 34,
              height: 34
            }}
          />
        </div>
        <div onClick={handleClick}>
          <Button variant="outlined" transparent color="secondary">
            {formatMessage(messages.learnMore)}
          </Button>
        </div>
      </div>
    </div>
  )
}

const enhancer = compose(
  injectIntl,
  withShoppingCart
);

export default enhancer(ActionBlock);
