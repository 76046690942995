// Core
import React, { memo } from 'react';

// Components
import TextInput from 'components/Input/Material/TextInput';
import TextAreaInput from 'components/Input/Material/TextAreaInput';
import Button from 'components/Button';

// Styles
import './ContactUsForm.scss';

import { FormattedMessage } from 'react-intl';
import { messages } from 'routes/ContactUs/messages';

export const ContactUsForm = memo(props => {
  const {
    values: { email, message },
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    handleBlur,
  } = props;

  const handleInputChange = name => (inputValue, e) => {
    setFieldValue(name, inputValue);
    if (e) e.persist();
  };

  const handleInputBlur = name => (inputValue, e) => {
    if (e) {
      e.persist();
      handleBlur(e);
    }
    setFieldValue(name, inputValue);
  };

  return (
    <form
      className="sk-contact-us-form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <div className="sk-form__inner sk-contact-us-form__inner">
        <div className="sk-form__row">
          <FormattedMessage {...messages.emailAddress}>
            {msg => (
              <TextInput
                name="email"
                helperText={touched.email ? errors.email : ''}
                error={touched.email && Boolean(errors.email)}
                value={email}
                label={msg}
                onChange={handleInputChange('email')}
                onBlur={handleInputBlur('email')}
              />
            )}
          </FormattedMessage>
        </div>
        <div className="sk-form__row">
          <FormattedMessage {...messages.writeMessage}>
            {msg => (
              <TextAreaInput
                name="message"
                helperText={touched.message ? errors.message : ''}
                error={touched.message && Boolean(errors.message)}
                value={message || ''}
                inputIsControlled={false}
                label={msg}
                onChange={handleInputChange('message')}
                onBlur={handleInputBlur('message')}
              />
            )}
          </FormattedMessage>
        </div>
      </div>
      <div className="sk-form_footer sk-contact-us-form__footer">
        <Button variant="text" color="secondary" size="large" type="submit">
          <FormattedMessage {...messages.submit} />
        </Button>
      </div>
    </form>
  );
});

export default ContactUsForm;
