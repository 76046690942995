// Core
import React, { useState, useEffect } from 'react';
import { compose } from 'react-apollo';
import { FormattedMessage } from 'react-intl';
import { Progress } from 'react-sweet-progress';
import { theme } from 'containers/UploadManagerProvider/helpers';

// Components
import Image from 'components/Image';
import Loader from 'components/Loader';
import Button from 'components/Button';
import PhotoUploader from 'components/PhotoUploader';

// Other
import { DEFAULT_MAX_FILE_SIZE, SUPPORTED_FORMATS_IMAGE } from 'utils/enums/index';
import { isNull } from 'utils/helpers/index';
import { withUploadManager } from 'containers/UploadManagerProvider/withUploadManager';
import { messages } from 'routes/ExpertsCabinet/CourseEdit/messages';
import { sharedMessages } from 'services/i18n/sharedMessages/messages';
import { withSharedState } from 'containers/SharedStateProvider/withSharedState';

// Styles and Assets
import './index.scss';

const UserProfilePhoto = ({
  sharedStateContext: { sharedState },
  imageUrl, activateCourse, progress, profile, handleFileChange, loading,
}) => {
  const isPhotoUploaded = progress === 100;
  const reloadPhoto = (filesList) => handleFileChange(filesList[0]);

  return (
    <div className="sk-user-profile-photo">
      <div className='sk-user-profile-photo__photo'>
        <img src={imageUrl} style={(isPhotoUploaded && !loading) ? { opacity: 1 } : {}} />
        {(progress > 0 && progress < 100 || (loading && isPhotoUploaded)) && (
          <div className='sk-user-profile-photo__photo__loader'>
            <Progress
              type="circle"
              theme={theme}
              percent={progress}
              status={'active'}
            />
          </div>
        )}
        <div className='sk-user-profile-photo__photo__uploader'>
          <PhotoUploader
            supportedFormats={SUPPORTED_FORMATS_IMAGE}
            maxSize={DEFAULT_MAX_FILE_SIZE}
            photo={imageUrl}
            uploaderId={'uploaderId'}
            handleChange={reloadPhoto}
          />
        </div>
      </div>

      <Button
        disabled={!isPhotoUploaded || loading}
        color="secondary"
        variant="text"
        onClick={activateCourse}
        loading={sharedState?.isCourseStatusUpdating}
      >
        <FormattedMessage {...messages.activateCourse} />
      </Button>
    </div>
  );
}

const enhancer = compose(
  withSharedState
)

export default enhancer(UserProfilePhoto);
