// Core
import React from 'react';
import { FormattedMessage } from 'react-intl';

// Components
import Link from 'components/Link';
import Button from 'components/Button';

// Other
import { withFileUrl } from 'containers/HOCs/withFileUrl';

import { sharedMessages } from 'services/i18n/sharedMessages/messages';

// Styles and Assets
import './index.scss';

const HWDownloadBtn = ({
  fileUrl,
  disabled,
  ...rest
}) => {
  return (
    <Link href={fileUrl} target="_blank" download>
      <Button disabled={disabled} sidePadFree fullWidth {...rest}>
        <FormattedMessage {...sharedMessages.openButton} />
      </Button>
    </Link>
  );
};

export default withFileUrl(HWDownloadBtn);
