// Core
import React, { useState } from 'react';
import { withRouter } from 'react-router';
import queryString from 'query-string';
import { useMutation } from '@apollo/react-hooks';

// Components
import Button from 'components/Button';
import Loader from 'components/Loader';
import Markdown from 'react-markdown';

import SUGGESTIONS_MUTATION from 'mutations/LLM/suggestions.gql';

// Styles
import './InputSearch.scss';

const InputSearch = ({ location, history, buttonText, placeholderText }) => {
  const [inputValue, setInputValue] = useState('');
  const [showOverlay, setShowOverlay] = useState(false);
  const [loading, setLoading] = useState(false);
  const [suggestions, setSuggestions] = useState('');

  const [getSuggestions] = useMutation(SUGGESTIONS_MUTATION, {
    onCompleted: data => {
      setLoading(false);
      setSuggestions(data.suggestions);
    },
    onError: error => {
      setLoading(false);
      console.error('Error fetching suggestions: ', error);
    }
  });

  const selector = qs => ({
    category: qs?.category,
    subcategory: qs?.subcategory,
    query: qs?.query,
    complexity: qs?.complexity || undefined,
    interactivityLevel: qs?.interactivityLevel || undefined,
    language: qs?.language || undefined,
    durationRange: qs?.durationRange || undefined,
    page: qs?.page || 0
  });

  const parseSearch = location => {
    const qs = queryString.parse(location?.search);
    return selector(qs);
  };

  const handleEmptySearch = () => {
    history.push({
      pathname: '/courses',
      // search: ?${queryString.stringify({
      //   ...parseSearch(location),
      //   query: inputValue,
      // })},
    });
  };

  const handleInputChange = e => {
    setInputValue(e.target.value);
  };

  const handleSearch = async event => {
    event.preventDefault();
    if (!inputValue) {
      event.persist();
      return handleEmptySearch();
    }

    setShowOverlay(true);
    setLoading(true);
    // Triggering the mutation
    await getSuggestions({
      variables: {
        prompt: inputValue,
        type: 'search',
      },
    });
  };

  const handleSearchEnter = e => {
    if (e.key === 'Enter') {
      handleSearch(e);
    }
  };

  return (
    <div
      className={`input-search-container ${
        showOverlay ? 'extended' : 'normal'
      }`}
    >
      <div className='sk-hero-banner_input-search'>
        <input
          type='text'
          value={inputValue}
          onChange={handleInputChange}
          onKeyPress={handleSearchEnter}
          className='sk-hero-banner_input-search__field'
          placeholder={placeholderText}
        />
        <Button color='secondary' variant='text' onClick={handleSearch}>
          {buttonText}
        </Button>
      </div>
      {showOverlay && (
        <div className='input-search-extended'>
          <div className='search-results'>
            {loading ? <Loader /> : <Markdown>{suggestions}</Markdown>}
          </div>
        </div>
      )}
    </div>
  );
};

export default withRouter(InputSearch);
