// Core
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// Components
import Tabs from 'components/Tabs';

// Other
import { FormattedMessage } from 'react-intl';
import { messages } from 'routes/ManageAccount/ExpertSales/messages';

class TabsComponent extends PureComponent {
  handleChange = (e, value) => {
    const { handleSwitch } = this.props;
    handleSwitch(value);
  };

  render() {
    const { tabs, activeTab, disabledTab } = this.props;
    return (
      <Tabs
        tabs={tabs}
        value={activeTab}
        handleChange={this.handleChange}
        disabledTab={disabledTab}
      />
    );
  }
}

TabsComponent.propTypes = {
  tabs: PropTypes.array,
  handleSwitch: PropTypes.func,
  activeTab: PropTypes.string,
  disabledTab: PropTypes.string,
};

TabsComponent.defaultProps = {
  tabs: [
    {
      label: <FormattedMessage {...messages.students} />,
      value: 'students',
    },
    {
      label: <FormattedMessage {...messages.earnings} />,
      value: 'earnings',
    },
  ],
};

export default TabsComponent;
