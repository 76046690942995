import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  prevButtonText: {
    id: 'app.components.ContentNavigation.prevButtonText',
    defaultMessage: 'Previous',
  },
  nextButtonText: {
    id: 'app.components.ContentNavigation.nextButtonText',
    defaultMessage: 'Next',
  },
});
