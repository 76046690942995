// Core
import React, { PureComponent } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { compose } from 'react-apollo';
import moment from 'moment';

// Components
import TextInput from 'components/Input/Material/TextInput';

// Other
import { messages } from 'routes/AdminPanel/messages';

// Styles and Assets
import './UserInfo.scss';

class UserInfo extends PureComponent {
  render() {
    const { intl: { formatMessage }, profile } = this.props;

    return (
      <div className="mui-override sk-user-view-info">
        <TextInput
          disabled
          name="registrationDate"
          label={<FormattedMessage {...messages.registrationDate} />}
          placeholder={formatMessage(messages.registrationDate)}
          value={moment(profile.createdAt).format('l')}
          className="mui-override sk-user-view-info__group__item"
        />
        <div className="mui-override sk-user-view-info__group">
          <TextInput
            disabled
            name="country"
            label={<FormattedMessage {...messages.country} />}
            placeholder={formatMessage(messages.country)}
            value={profile.country?.name}
            className="mui-override sk-user-view-info__group__item"
          />
          <TextInput
            disabled
            name="city"
            label={<FormattedMessage {...messages.city} />}
            placeholder={formatMessage(messages.city)}
            value={profile.city?.name}
            className="mui-override sk-user-view-info__group__item"
          />
        </div>
        <TextInput
          disabled
          name="aboutMe"
          label={<FormattedMessage {...messages.aboutMe} />}
          placeholder={formatMessage(messages.aboutMe)}
          value={profile.aboutMe}
        />
      </div>
    )
}}

UserInfo.propTypes = {
};

const enhancer = compose(injectIntl);

export default enhancer(UserInfo);
