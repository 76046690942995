/* eslint-disable react/no-array-index-key */
import React from 'react';
import { getVisibleSelectionRect } from 'draft-js';

// Components
import CloseButton from 'components/Modal/CloseButton';
import ModalArrow from 'components/Icon/SvgCss/Editor/ModalArrow';

// Other
// import { getVisibleSelectionRect } from './helpers';
import { isNull } from 'utils/helpers/index';

// Styles
import './ToolbarInline.scss';

export default class Toolbar extends React.Component {
  static defaultProps = {
    children: externalProps => <>Init render</>,
  };

  state = {
    position: undefined,
  };

  toolbar = null;

  UNSAFE_componentWillMount() {
    this.props.store.subscribeToItem('selection', this.onSelectionChanged);
  }

  componentWillUnmount() {
    this.props.store.unsubscribeFromItem('selection', this.onSelectionChanged);
  }

  // getSelected() {
  //    let t = '';
  //    if (window.getSelection) {
  //       t = window.getSelection();
  //    } else if (document.getSelection) {
  //       t = document.getSelection();
  //    } else if (document.selection) {
  //       t = document.selection.createRange().text;
  //    }
  //    return t;
  // }

  onSelectionChanged = () => {
    // need to wait a tick for window.getSelection() to be accurate
    // when focusing editor with already present selection
    setTimeout(() => {
      if (!this.toolbar || isNull(this.toolbar)) return;

      // The editor root should be two levels above the node from
      // `getEditorRef`. In case this changes in the future, we
      // attempt to find the node dynamically by traversing upwards.
      const editorRef = this.props.store.getItem('getEditorRef')();
      if (!editorRef) return;

      // This keeps backwards compatibility with React 15
      let editorRoot =
        editorRef.refs && editorRef.refs.editor
          ? editorRef.refs.editor
          : editorRef.editor;

      while (editorRoot?.className?.indexOf('sk-editor-root') === -1) {
        editorRoot = editorRoot.parentNode;
      }

      const editorRootRect = editorRoot.getBoundingClientRect();

      const selectionRect = getVisibleSelectionRect(window);

      // const selected = this.getSelected();
      // console.log('selected', selected);
      //
      // if (selected?.rangeCount > 0) {
      //   const range = selected?.getRangeAt(0);
      //   console.log('selected range', range);
      //   console.log('selected rect', range?.getBoundingClientRect());
      // }

      if (!selectionRect) return;

      // The toolbar shouldn't be positioned directly on top of the selected text,
      // but rather with a small offset so the caret doesn't overlap with the text.
      const extraTopOffset = 10;

      const { innerHeight, innerWidth } = window;
      const {
        offsetHeight: toolbarHeight,
        offsetWidth: toolbarWidth,
      } = this.toolbar;
      const {
        left: selectionLeft,
        top: selectionTop,
        right: selectionRight,
        bottom: selectionBottom,
        width: selectionWidth,
        height: selectionHeight,
      } = selectionRect;

      // toolbar relative position: above || below; left || right
      const toolbarRelativePosition = {
        vertical: 'below',
        horizontal: 'right',
      };

      const isBelowEnoughSpace =
        innerHeight - (selectionBottom + toolbarHeight) > toolbarHeight;
      if (!isBelowEnoughSpace) {
        toolbarRelativePosition.vertical = 'above';
      }
      // if selection collapsed => selectionLeft === selectionRight, otherwise it counts from the left
      const horizontalCursorPosition =
        selectionWidth > 0
          ? selectionLeft + selectionWidth / 2
          : selectionRight;
      const isRightEnoughSpace =
        innerWidth - (horizontalCursorPosition + toolbarWidth / 2) >
        toolbarWidth / 2;
      if (!isRightEnoughSpace) {
        toolbarRelativePosition.horizontal = 'left';
      }

      // formulas to get toolbar absolute position
      const ABSOLUTE_POSITION_GETTERS = {
        left: () => horizontalCursorPosition - (toolbarWidth / 2 - 26),
        above: () => selectionTop - toolbarHeight - extraTopOffset,
        right: () => horizontalCursorPosition + (toolbarWidth / 2 - 26),
        below: () => selectionBottom + extraTopOffset,
      };

      const CURSOR_POSITION = {
        left: () => ({ right: -4 }),
        above: () => ({ bottom: -28, transform: 'rotate(180deg)' }),
        right: () => ({ left: -4 }),
        below: () => ({ top: -28 }),
      };

      const getLeft =
        ABSOLUTE_POSITION_GETTERS[toolbarRelativePosition.horizontal];
      const getTop =
        ABSOLUTE_POSITION_GETTERS[toolbarRelativePosition.vertical];
      const getArrowHorizontal =
        CURSOR_POSITION[toolbarRelativePosition.horizontal];
      const getArrowVertical =
        CURSOR_POSITION[toolbarRelativePosition.vertical];

      this.setState({
        position: {
          modal: { top: getTop(), left: getLeft() },
          arrow: { ...getArrowHorizontal(), ...getArrowVertical() },
        },
      });
    });
  };

  getStyle() {
    const { store, openOnSelection } = this.props;
    const { position } = this.state;

    const selection = store
      .getItem('getEditorState')()
      .getSelection();

    store.updateItem('selection', selection);

    const isVisible = openOnSelection;
    const style = { ...position?.modal };

    if (isVisible) {
      style.visibility = 'visible';
      style.transform = 'translate(-50%) scale(1)';
      style.transition = 'transform 0.15s cubic-bezier(.3,1.2,.2,1)';
    } else {
      style.transform = 'translate(-50%) scale(0)';
      style.visibility = 'hidden';
    }

    return style;
  }

  handleToolbarRef = node => {
    this.toolbar = node;
  };

  render() {
    const { theme, store, children, closeToolbar, isVisible } = this.props;
    const { position } = this.state;
    const childrenProps = {
      getEditorState: store.getItem('getEditorState'),
      setEditorState: store.getItem('setEditorState'),
    };

    return (
      <div
        className="sk-draft-editor-inline-toolbar"
        style={this.getStyle()}
        ref={this.handleToolbarRef}
      >
        {isVisible && (
          <>
            {children(childrenProps)}
            <CloseButton handleClick={closeToolbar} />
            <ModalArrow style={position?.arrow} />
          </>
        )}
      </div>
    );
  }
}
