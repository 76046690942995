// Core
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withApollo, compose } from "react-apollo";
import { Formik } from "formik";
import Auth from "@aws-amplify/auth";
import moment from "moment-timezone";
import { injectIntl } from "react-intl";

// Components
import SignInForm from "routes/Authenticator/SignInForm";
import SignUpForm from "routes/Authenticator/SignUpForm";
import AlertBox from "routes/Authenticator/AlertBox";
import AuthModal from "routes/Authenticator/AuthModal";
import AuthTabs from "routes/Authenticator/AuthTabs";
import Card from "components/Card";
import MetaData from "components/MetaData";

// Other
import { withModal } from "containers/ModalProvider/withModal";
import { getYupSchema } from "services/yup";
import { parseIncomingError } from "utils/errorHandling";
import { withAWS } from "containers/AWSProvider/withAWS";
import { withLanguage } from "containers/LanguageProvider/withLanguage";
import { withAuthenticatedUser } from "containers/AuthenticatedUserProvider/withAuthenticatedUser";

// Styles
import "./Authenticator.scss";

const userTimezone = moment.tz.guess();

const PUBLIC_HOSTNAMES = [
  "avdo.education.com",
  "www.avdo.education.com",
  "localhost",
  "localhost:3000",
];

const SIGN_IN_FIELDS = {
  password: "",
  email: "",
};

const SIGN_UP_FIELDS = {
  ...SIGN_IN_FIELDS,
  confirmPassword: "",
  firstName: "",
  lastName: "",
  country: "",
  city: "",
};

class Authenticator extends PureComponent {
  state = {
    activeTab: this.props.activeTab || "signUp",
    role: "student",
    error: false,
    errorDescription: null,
    isLoading: false,
  };

  // static async getDerivedStateFromProps(props, state) {
  //   const currentUser = await Auth.currentAuthenticatedUser();
  //   return null;
  // }

  handleContinueClick = () => {
    const {
      modalContext: { hideModal },
    } = this.props;
    this.handleSwitch("signIn");
    hideModal();
  };

  componentDidMount = () => {
    const {
      location,
      location: { state: locationState },
    } = this.props;

    this.hostname = window?.location?.hostname;

    const params = new URLSearchParams(location.search);
    const role = params.get("role");

    if (role) {
      this.setState({ role });
    }

    if (locationState && locationState.error) {
      this.setState({ error: true });
    }
    if (locationState && locationState.errorDescription) {
      this.setState({ errorDescription: locationState.errorDescription });
    }
  };

  componentDidUpdate = () => {
    const {
      userContext: { isAuthenticated },
      languageContext: { language },
      history,
    } = this.props;
    if (isAuthenticated) {
      history.push(`/${language}/courses`);
    }
  };

  handleSwitch = (activeTab) => {
    const { history } = this.props;
    const { role } = this.state;
    this.setState(
      {
        activeTab,
      },
      history.push(`/auth/${activeTab}?role=${role}`)
    );
  };

  handleSubmit = async (handleSubmit, values, { setSubmitting, setErrors }) => {
    try {
      this.setState((prevState) => ({ isLoading: true }));
      await handleSubmit(values);
      setSubmitting(false);
      this.setState((prevState) => ({ isLoading: false }));
    } catch (error) {
      this.setState((prevState) => ({ isLoading: false }));
      setSubmitting(false);
      setErrors(parseIncomingError(error));
    }
  };

  showAuthModal = () => {
    const {
      modalContext: { showModal },
    } = this.props;
    showModal(AuthModal, { handleClick: this.handleContinueClick });
  };

  handleSignUp = async (formData) => {
    const { password, email, firstName, lastName, country, city } = formData;
    const { role } = this.state;

    await Auth.signUp({
      username: email.toLowerCase(),
      password,
      attributes: {
        "custom:role": "student",
        "custom:future_role": role,
        "custom:first_name": firstName,
        "custom:last_name": lastName,
        "custom:country": "0",
        "custom:city": "0",
        "custom:timezone": userTimezone,
        "custom:interface_language": "en",
        "custom:interface_currency": "usd",
      },
    });

    this.showAuthModal();
  };

  handleSignIn = async ({ email: username, password }) => {
    const hostname = PUBLIC_HOSTNAMES.includes(this.hostname)
      ? this.hostname
      : null;
    try {
      const user = await Auth.signIn(username.toLowerCase(), password, {
        hostname,
      });
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        this.props.history.push("/pw-update", { user });
      } else {
        console.log("User signed in successfully");
      }
    } catch (error) {
      console.error("Sign in error:", error);
      throw error;
    }
  };
  render() {
    const { activeTab, role, isLoading, error, errorDescription } = this.state;
    const {
      userContext: { domain },
    } = this.props;
    const renderSignUpForm = activeTab === "signUp" && !domain?.id;
    const Form = renderSignUpForm ? SignUpForm : SignInForm;
    const handleSubmit = renderSignUpForm
      ? this.handleSignUp
      : this.handleSignIn;

    return (
      <div className="sk-authenticator sk-background-spot__secondary">
        {renderSignUpForm && (
          <MetaData
            titleKey={
              role === "student"
                ? "signupBecomeStudentTitle"
                : "signupTeachOnlineTitle"
            }
          />
        )}
        <Card classes="sk-authenticator_container">
          <AuthTabs
            handleSwitch={this.handleSwitch}
            activeTab={activeTab}
            isOrg={domain?.id ? true : false}
          />
          <div className="sk-authenticator_inner">
            <AlertBox error={error} errorDescription={errorDescription} />
            <div className="sk-auth-content ">
              <Formik
                onSubmit={async (values, formikBag) => {
                  await this.handleSubmit(handleSubmit, values, formikBag);
                }}
                render={(props) => <Form {...props} isLoading={isLoading} />}
                initialValues={
                  renderSignUpForm ? SIGN_UP_FIELDS : SIGN_IN_FIELDS
                }
                validateOnChange={false}
                validateOnBlur
                validationSchema={
                  renderSignUpForm
                    ? getYupSchema("signUpValidationSchema")
                    : getYupSchema("signInValidationSchema")
                }
              />
            </div>
          </div>
        </Card>
      </div>
    );
  }
}

const enhancer = compose(
  injectIntl,
  withAWS,
  withApollo,
  withRouter,
  withModal,
  withLanguage,
  withAuthenticatedUser
);

export default enhancer(Authenticator);
