// Core
import React, { memo, useEffect, useMemo } from 'react';
import { compose } from 'react-apollo';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

// Components
import ToolTip from 'components/ToolTip';
import RadioButtonGroup from 'components/RadioButtonGroup';
import Card from 'components/Card';
import ModalArrow from 'components/Icon/SvgCss/Editor/ModalArrow';

import { capitalize } from 'utils/helpers';

// Styles and Assets
import './PriceBoard.scss';

import { messages } from 'components/PriceBoard/messages';

const PriceBoard = ({
  withTick,
  withTooltip,
  options,
  boughted,
  value = 'Flex',
  handleChange,
}) => {
  const defaultOption = options.find(option => option.disabled === false);

  useEffect(() => {
    if (!!defaultOption?.value && capitalize(value) !== defaultOption?.value) {
      handleChange(defaultOption?.value);
    }
  }, []);

  const isAssistSelected = useMemo(() => value.toLowerCase() === 'assist', [
    value,
  ]);
  const isConciergeSelected = useMemo(
    () => value.toLowerCase() === 'concierge',
    [value]
  );
  const variant = options.find(option => option.disabled === false)
  return (
    <div
      className={classnames('sk-price-border', {
        'modal-tick-center': withTick && isAssistSelected,
        'modal-tick-right': withTick && isConciergeSelected,
      })}
    >
      <Card classes='sk-price-border__choice'>
        {variant.label}
        {/* <RadioButtonGroup
          disabled={boughted}
          handleChange={handleChange}
          name='price-border-choice'
          options={options.filter(option => option.disabled === false)}
          style={{ justifyContent: 'space-between' }}
          value={capitalize(value)}
          classNameRadioGroup='sk-price-border__choice__radio-button-group'
          classNameRadioButton='sk-price-border__choice__radio-button'
        /> */}
      </Card>
      {withTooltip && (
        <div className='sk-price-border__tooltip'>
          <ToolTip>
            <div>
              <p>
                <strong>
                  <FormattedMessage {...messages.flexLabel} />:{' '}
                </strong>{' '}
                <FormattedMessage {...messages.flex} />
              </p>
              <p>
                <strong>
                  <FormattedMessage {...messages.assistLabel} />:{' '}
                </strong>
                <FormattedMessage {...messages.assist} />
              </p>
              <p>
                <strong>
                  <FormattedMessage {...messages.conciergeLabel} />:{' '}
                </strong>
                <FormattedMessage {...messages.concierge} />
              </p>
            </div>
          </ToolTip>
        </div>
      )}
      {withTick && <ModalArrow />}
    </div>
  );
};

export default memo(PriceBoard);
