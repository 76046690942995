import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  students: {
    id: 'app.routes.ManageAccount.ExpertSales.Tabs.students',
    defaultMessage: 'Number of students',
  },
  earnings: {
    id: 'app.routes.ManageAccount.ExpertSales.Tabs.earnings',
    defaultMessage: 'Revenue',
  },
  queryTitle: {
    id: 'app.routes.ManageAccount.ExpertSales.Query.queryTitle',
    defaultMessage: 'Select date range',
  },
  queryFrom: {
    id: 'app.routes.ManageAccount.ExpertSales.Query.queryFrom',
    defaultMessage: 'from',
  },
  queryTo: {
    id: 'app.routes.ManageAccount.ExpertSales.Query.queryTo',
    defaultMessage: 'to',
  },
  noCourses: {
    id: 'app.routes.ManageAccount.ExpertSales.EmptyCourseList.noCourses',
    defaultMessage: 'You don\'t have active courses yet.',
  },
  createCourse: {
    id: 'app.routes.ManageAccount.ExpertSales.EmptyCourseList.createCourse',
    defaultMessage: 'Please, create and activate course to see analytics.',
  },
  courseTitle: {
    id: 'app.routes.ManageAccount.ExpertSales.Table.courseTitle',
    defaultMessage: 'COURSE',
  },
  rating: {
    id: 'app.routes.ManageAccount.ExpertSales.Table.rating',
    defaultMessage: 'RATING',
  },
  total: {
    id: 'app.routes.ManageAccount.ExpertSales.Table.total',
    defaultMessage: 'TOTAL',
  },
  flex: {
    id: "app.components.AdditionalCourseDetails.flex",
    defaultMessag: "FLEX"
  },
  assist: {
    id: "app.components.AdditionalCourseDetails.assist",
    defaultMessag: "ASSIST"
  },
  concierge: {
    id: "app.components.AdditionalCourseDetails.concierge",
    defaultMessag: "CONCIERGE"
  },
});
