// Core
import React from 'react';

// Components
import LandingPageCTAText from 'routes/LandingPage/components/LandingPageCTAText';
// import LandingPageCTAImage from 'routes/LandingPage/components/LandingPageCTAImage';
// import LandingPageGrid from 'routes/LandingPage/components/LandingPageGrid';
import Image from 'components/Image';

// Styles and Assets
import './LandingPageHero.scss';

const LandingPageHero = ({ role, ...props }) => {
  return (
    <div className="sk-landing-page-hero">
      <Image src='/assets/images/skilleton-landing-background.svg' id="continue-header-background" />
      <div className="sk-landing-page-hero_grid grid landing space-between vend">
        <div className="sk-landing-page-hero_grid__col">
          <LandingPageCTAText role={role} />
        </div>
        {/* <div className="sk-landing-page-hero_grid__col sk-landing-page-hero_grid__col--image">
          <LandingPageCTAImage />
        </div> */}
      </div>
    </div>
  );
};

export default LandingPageHero;
