// Core
import React, { useState, useEffect, useRef } from 'react';
import { compose } from 'react-apollo';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import ReactPlayer from 'react-player';

// Components
import CloseButton from 'components/Icon/Svg/CloseButton';
import DescriptionBlock from './DescriptionBlock';
import Author from './Author';
import ActionBlock from './ActionBlock';
import VideoModule from 'components/VideoModule';
import Tabs from 'components/Tabs';
import { Volume, Muted, } from 'components/Icon/SvgCss/VideoControllers';
import {PurpleDark} from 'components/Icon/color.js';

// Others
import withWidth from 'containers/HOCs/withWidth';
import { withShoppingCart } from 'containers/ShoppingCartProvider/withShoppingCart';
import {messages} from 'components/NetflixCarousel/messages.js';

// Styles
import './Content.scss';

const PREFIX = "sk-toppings-carousel_content";

const Content = ({
  topping,
  onClose,
  handleMuteVideo,
  isMuted,
  width,
  alreadyBoughtedCourses,
  shoppingCartContext: {
    handleAddCourseToCart,
    handleBuyNowClick,
    _isCourseAddedInShoppingCart
  },
  intl: { formatMessage },
}) => {
  const [activeTab, setActiveTab] = useState('description');
  const [isMobileMode, setMobileMode] = useState(false);
  const defaultTabs = [
    {
      label: formatMessage(messages.tabsGeneral),
      value: 'description',
    },
    {
      label: formatMessage(messages.tabsAuthor),
      value: 'author',
    },
  ];
  const contentBlock = useRef(null);
  const playerEl = useRef(null);

  const handleSwitch = (e, value) => {
    if (value !== activeTab) {
      setActiveTab(value);
    };
  };

  const scroll = ref => {
    ref.current.scrollIntoView({ behavior: 'smooth', block: "end", });
  }

  useEffect(()=>{
    if(width === 'xs' || width === 'sm'){
      setMobileMode(true);
    }
    else{
      scroll(contentBlock);
    }
  }, [width, setMobileMode, contentBlock, topping])

  const preview = topping.coursePreview || {};
  const variantName = topping.variants[0].name;
  const variantPrice = topping.variants[0].price;

  return(
    <div className={PREFIX} ref={contentBlock}>
      {isMobileMode &&
        <Tabs
          value={activeTab}
          handleChange={handleSwitch}
          tabs={defaultTabs}
          variant='fullwidth'
          centered={false}
        />
      }
      <div className={`${PREFIX}__background`}>
        <div className={`${PREFIX}__background__shadow`} />
          <div
            className={classNames(`${PREFIX}__background__image`, {'author': activeTab!=='description'})}
            style={{ backgroundImage: `url(${topping.coursePhoto})` }}
          >
            <ReactPlayer
              ref={playerEl}
              playing
              url={preview.url}
              width="100%"
              height="100%"
              controls={false}
              loop
              muted={isMuted}
              className="sk-video-module"
            />
            <button className={`${PREFIX}__mute`} onClick={handleMuteVideo}>
              {isMuted ? <Muted color={PurpleDark} /> : <Volume color={PurpleDark} />}
            </button>
        </div>
      </div>
      <div className={`${PREFIX}__area`}>
        {
          activeTab === 'description' ?
            <DescriptionBlock topping={topping} /> :
            <Author author={topping.author} expertLabel={formatMessage(messages.author)} />
        }
        <div className={`${PREFIX}__area_bottom`}>
          <ActionBlock
            purchasedId={topping?.userCourseId}
            isInShoppingCart={_isCourseAddedInShoppingCart({ id: topping?.id })}
            price={variantPrice}
            handleAddToCart={handleAddCourseToCart({ interactionLevel: variantName, price: variantPrice, ...topping})}
            handleBuyNow={handleBuyNowClick({ interactionLevel: variantName, price: variantPrice, ...topping})}
            isFree={!variantPrice}
          />
          {!isMobileMode &&
            <Tabs
              value={activeTab}
              handleChange={handleSwitch}
              tabs={defaultTabs}
              variant='fullwidth'
              centered={false}
            />
          }
        </div>
      </div>
      <button className={`${PREFIX}__close`} onClick={onClose}>
        <CloseButton />
      </button>
    </div>
  );
}

const enhancer = compose(
  withShoppingCart,
  injectIntl,
  withWidth()
);

export default enhancer(Content);
