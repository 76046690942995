// Core
import AWSAppSyncClient, { createAppSyncLink } from 'aws-appsync';
import { ApolloLink } from 'apollo-link';

// Other
import { AppSyncConfig } from 'services/aws/amplify/config';
import { typenameStripMiddleWare, errorLink } from 'services/apollo/links';
import { createCache } from 'services/apollo/cache';
import { stateLink } from 'services/apollo/localState';

export const getAppSyncClient = config => {
  const cache = createCache();
  return new AWSAppSyncClient(
    {
      disableOffline: true,
      complexObjectsCredentials: AppSyncConfig.complexObjectsCredentials,
      region: AppSyncConfig.region,
      ...config,
    },
    {
      ssrMode: window.isServer,
      cache,
      credentials: 'same-origin',
      link: ApolloLink.from([
        typenameStripMiddleWare,
        stateLink(cache),
        createAppSyncLink({
          url: config.url,
          region: AppSyncConfig.region,
          auth: config.auth,
          complexObjectsCredentials: AppSyncConfig.complexObjectsCredentials,
        }),
        errorLink,
      ]),
    }
  );
};
