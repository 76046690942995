import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  filter: {
    id: 'app.components.Filters.filter',
    defaultMessage: 'Filter',
  },
  allCats: {
    id: 'app.components.Filters.allCats',
    defaultMessage: 'All Categories',
  },
  allSubcats: {
    id: 'app.components.Filters.allSubcats',
    defaultMessage: 'All SubCategories',
  },
});
