import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  orderBasketEmpty: {
    id: 'app.components.ShoppingCart.orderBasketEmpty',
    defaultMessage: 'Shopping cart is empty.',
  },
  completeOrder: {
    id: 'app.containers.CheckoutMenu.completeOrder',
    defaultMessage: 'Complete order',
  },
  total: {
    id: 'app.containers.CheckoutMenu.total',
    defaultMessage: 'Total:',
  },
});
