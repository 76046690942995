// Core
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';

// Components
import TextInput from 'components/Input/Material/TextInput/TextInput';
import Button from 'components/Button';

// Other
import { getYupSchema } from 'services/yup';

import { FormattedMessage } from 'react-intl';
import { messages } from 'routes/PasswordReset/PasswordResetForm/messages';

// Styles and Assets
import '../PasswordReset.scss';
import './PasswordResetForm.scss';

const PW_REST_FIELDS = {
  email: '',
};

export const PasswordResetForm = ({ handleSendCodeClick }) => (
  <Formik
    onSubmit={async (values, formikBag) => {
      await handleSendCodeClick(values, formikBag);
    }}
    validateOnChange={false}
    validateOnBlur
    initialValues={PW_REST_FIELDS}
    validationSchema={getYupSchema('pwResetValidationSchema')}
  >
    {props => {
      const {
        values: { email },
        errors,
        touched,
        handleSubmit,
        handleChange,
        setFieldValue,
        handleBlur,
      } = props;

      const handleInputChange = name => (inputValue, e) => {
        e.persist();
        setFieldValue(name, inputValue);
        handleChange(e);
      };

      const handleInputBlur = name => (inputValue, e) => {
        e.persist();
        handleBlur(e);
        setFieldValue(name, inputValue);
      };

      return (
        <form
          className="sk-pwReset-form"
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <div className="sk-pwReset-form_inner">
            <div className="sk-pwReset-form_row">
              <FormattedMessage {...messages.email}>
                {msg => (
                  <TextInput
                    name="email"
                    helperText={touched.email ? errors.email : ''}
                    error={touched.email && Boolean(errors.email)}
                    value={email}
                    label={msg}
                    placeholder={msg}
                    onBlur={handleInputBlur('email')}
                    onChange={handleInputChange('email')}
                  />
                )}
              </FormattedMessage>
            </div>
          </div>
          <div className="sk-pwReset-form_footer">
            <div className="sk-pwReset-form_footer">
              <Button
                variant="text"
                color="secondary"
                size="large"
                onClick={handleSubmit}
              >
                <FormattedMessage {...messages.send} />
              </Button>
            </div>
          </div>
        </form>
      );
    }}
  </Formik>
);

PasswordResetForm.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.bool,
  handleSubmit: PropTypes.func,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  setFieldValue: PropTypes.func,
  handleSendCodeClick: PropTypes.func,
};

export default memo(PasswordResetForm);
