import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  searchInputPlaceholder: {
    id: 'app.containers.Header.searchInputPlaceholder',
    defaultMessage: 'Find a course',
  },
  allCoursesLabel: {
    id: 'app.containers.Header.allCoursesLabel',
    defaultMessage: 'All Сourses'
  }
});
