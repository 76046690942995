// Core
import React from 'react';
import { compose } from 'react-apollo';
import { Progress } from 'react-sweet-progress';

// Components
import StatusNotChecked from 'components/Icon/Svg/StatusNotChecked';
import StatusChecked from 'components/Icon/Svg/StatusChecked';
// Other
import { withUploadManager } from 'containers/UploadManagerProvider/withUploadManager';
import { theme } from 'containers/UploadManagerProvider/helpers';

// Styles and Assets
import './index.scss';

const HomeworkLoader = ({
  uploadManagerContext: {
    progress,
   },
   isReviewAdded,
   isReviewPending,
   isReviewUploaded,
   isReviewInQueue,
   isReviewInProgress,
   isReviewUploadedAndProcessed,
   isReviewSubmitted,
   isReviewProcessing,
}) => {
  return (
    <>
      {(!isReviewAdded
        || (isReviewPending && !isReviewInQueue && !isReviewUploaded)
        || ((isReviewUploadedAndProcessed || isReviewProcessing) && !isReviewSubmitted))
        && (
        <StatusNotChecked />
      )}
      {isReviewAdded && isReviewPending && isReviewInQueue &&  (
        <Progress
          width={32}
          type="circle"
          theme={theme}
          percent={isReviewInProgress ? progress : 0}
          status={'active'} />
      )}
      {isReviewAdded && isReviewPending && isReviewUploaded &&  (
        <Progress
          width={32}
          type="circle"
          theme={theme}
          percent={100}
          status={'success'} />
      )}
      {isReviewUploadedAndProcessed && isReviewSubmitted && (
        <StatusChecked />
      )}
    </>
  );
}

const enhancer = compose(
  withUploadManager
)

export default enhancer(HomeworkLoader);
