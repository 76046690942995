// Core
import React, { Component } from 'react';
// import { ApolloProvider as ApolloHooksProvider } from "react-apollo-hooks";
import { ApolloProvider } from 'react-apollo';
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks';
import { Rehydrated } from 'aws-appsync-react';

// Components
import Loader from 'components/Loader';

// Other
import { AppSyncConfig } from 'services/aws/amplify/config';
import { getCurrentSession } from 'services/aws/amplify/helpers';
import { AWSContext } from 'containers/AWSProvider/aws-context';
import { getAppSyncClient } from 'services/aws/appsync/client';

class AWSProvider extends Component {
  state = {
    loading: !window.isServer,
    privateSession: !window.isServer,
    client: this.props.apolloClient,
  };

  getCurrentConfig = async () => {
    try {
      await getCurrentSession();
      this.setState({ privateSession: true });
      return AppSyncConfig.private;
    } catch (error) {
      this.setState({ privateSession: false });
      return AppSyncConfig.public;
    }
  };

  componentDidMount = async () => {
    if (!window.isServer) {
      const config = await this.getCurrentConfig();
      this.getClient(config);
    }
  };

  updateAWSConfig = async () => {
    this.setState({ loading: true });
    try {
      const config = await this.getCurrentConfig();
      this.getClient(config);
    } catch (error) {
      throw Error(error);
    }
    this.setState({ loading: false });
  };

  getClient = config => {

    const client = getAppSyncClient(config);
    client.initQueryManager();
    this.setState({ client, loading: false });
  };

  render() {
    const { children } = this.props;
    const { loading, privateSession, client } = this.state;

    if (loading) return <Loader />;
    if (!client) return null;

    return (
      <AWSContext.Provider
        value={{
          awsContext: {
            updateAWSConfig: this.updateAWSConfig,
            privateSession,
            client,
          },
        }}
      >
        <ApolloProvider client={client}>
          <ApolloHooksProvider client={client}>
            {window.isServer ? children : <Rehydrated>{children}</Rehydrated>}
          </ApolloHooksProvider>
        </ApolloProvider>
      </AWSContext.Provider>
    );
  }
}

export default AWSProvider;
