// Core
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// Components
import Heading from 'components/Heading';
import Button from 'components/Button';

// Styles and Assets
import './CourseDeleteModal.scss';

// Other
import { FormattedMessage } from 'react-intl';
import { messages } from 'routes/Course/components/CourseDeleteModal/messages';

class CourseDeleteModal extends PureComponent {
  state = {};

  render() {
    const { handleConfirmation, handleCancel, title } = this.props;

    return (
      <div className="sk-course-delete-modal">
        <div className="sk-course-delete-modal__header">
          <Heading variant="h3" classes="sk-course-delete-modal__heading">
            <FormattedMessage {...messages.areUSureToDeleteCourse} /> {title}
          </Heading>
        </div>
        <div className="sk-course-delete-modal__content">
          <Button
            variant="text"
            color="primary"
            size="large"
            onClick={handleCancel}
          >
            <FormattedMessage {...messages.cancel} />
          </Button>

          <Button
            variant="text"
            color="secondary"
            size="large"
            onClick={handleConfirmation}
          >
            <FormattedMessage {...messages.delete} />
          </Button>
        </div>
      </div>
    );
  }
}

CourseDeleteModal.propTypes = {
  handleConfirmation: PropTypes.func,
  handleCancel: PropTypes.func,
  title: PropTypes.string,
};

export default CourseDeleteModal;
