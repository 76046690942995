import React from 'react';
import cx from 'classnames';

// Compoents
import { DefaultSliderContext } from 'components/NetflixCarousel/modules';

// Styles
import './index.scss';

const Item = ({ item, SliderItem, currency, containerWidth, boughted }) => (
  <DefaultSliderContext.Consumer>
    {({ onSelectSlide, currentSlide, onCloseSlide, elementRef, contentOpen }) => {
      const isActive = currentSlide && currentSlide.id === item.id;

      return (
        <SliderItem
          elementRef={elementRef}
          item={item}
          boughted={boughted}
          isActive={isActive}
          currentSlide={currentSlide}
          onSelectSlide={onSelectSlide}
          onCloseSlide={onCloseSlide}
          currency={currency}
          price={item.variants[0].price}
          containerWidth={containerWidth}
          contentOpen={contentOpen}
        />
      );
    }}
  </DefaultSliderContext.Consumer>
);

export default Item;
