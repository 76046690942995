// Core
import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Material Components
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';

// Components
import Text from 'components/Text';
import CoursesTableRow from 'routes/AdminPanel/ToppingsList/CoursesTable/CoursesTableRow/CoursesTableRow';
import ToppingTypeFilter from 'routes/AdminPanel/ToppingsList/CoursesTable/ToppingTypeFilter';
import StatusFilter from 'routes/AdminPanel/ToppingsList/CoursesTable/StatusFilter';
import { FilterIcon, ArrowToDown } from 'components/Icon/Svg';

// Other
import { Grey4, Black } from 'components/Icon/color';

// Styles and Assets
import './CoursesTable.scss';

const classes = {
  table: {
    root: 'mui-override sk-сoursesTable',
  },
};
const ACSENDING = 'asc';
const NEXT_SORT_ORDER = {
  'asc': 'desc',
  'desc': 'asc'
}
const SORT_TYPES = {
  'title': 'title',
  'variants.name': 'variants.name',
  'author': 'author',
  'status_id': 'status_id',
}

class CoursesTable extends PureComponent {
  state = {
    anchorEl: null,
  }

  renderTableBody = courses => {
    return courses.map((item, index, arr) => (
      <Fragment key={item.id}>
        <CoursesTableRow key={item.id} item={item} index={index} arr={arr} />
      </Fragment>
    ));
  };

  handleSort = type => async () => {
    const { handleSort, sortBy, sortOrder, } = this.props;

    const newSortByFieldName = type;
    const newSortOrder = sortBy === type && NEXT_SORT_ORDER[sortOrder] ? NEXT_SORT_ORDER[sortOrder] : ACSENDING;

    await handleSort({ sortBy: newSortByFieldName, sortOrder: newSortOrder });
  }

  handleSortByTitle = this.handleSort(SORT_TYPES['title'])
  handleSortByType = this.handleSort(SORT_TYPES['variants.name'])
  handleSortByStatus = this.handleSort(SORT_TYPES['status_id'])
  handleSortByAuthor = this.handleSort(SORT_TYPES['author'])

  openFilter = event =>
    this.setState({ anchorEl: this.state.anchorEl === null ? event.currentTarget : null });

  handleFilter = async (type, checked) => {
    const { handleSort, } = this.props;

    await handleSort({ filterKey: type, filterValue: checked });
  }

  render() {
    const { courses, sortBy, sortOrder, filters, } = this.props;
    const { anchorEl } = this.state;

    const headerHeight = 63;
    const rowHeight = 63;
    const rowsCount = +courses?.length || 0;

    const isSortOrderAscending = sortOrder === ACSENDING;

    const isSortedByTitle = sortBy === SORT_TYPES['title'];
    const isSortedByType = sortBy === SORT_TYPES['variants.name'];
    const isSortedByStatus = sortBy === SORT_TYPES['status_id'];
    const isSortedByAuthor = sortBy === SORT_TYPES['author'];

    return (
      <Table
        className="mui-override sk-сoursesTable"
        classes={classes.table}
        style={{ height: headerHeight + (rowHeight*rowsCount) }}
      >
        <TableHead>
          <TableRow>
            <TableCell
              classes={classes.table}
              className="mui-override sk-сoursesTable__cell"
              align="left"
            >
              <div className='mui-override sk-сoursesTable__cell__elements'>
                <Text bold align="left" onClick={this.handleSortByTitle}>
                  MASTER CLASS / PODCAST
                </Text>
                <span className={classnames('mui-override sk-сoursesTable__cell__elements__arrow', {
                  'rotate': isSortedByTitle ? isSortOrderAscending : true,
                  'show': isSortedByTitle
                })}>
                  <ArrowToDown height="12" width="13" color={isSortedByTitle ? Black : Grey4} />
                </span>
              </div>
            </TableCell>
            <TableCell
              classes={classes.table}
              className="mui-override sk-сoursesTable__cell"
            >
              <div className='mui-override sk-сoursesTable__cell__elements'>
                <Text bold align="left" onClick={this.handleSortByType}>
                  TYPE
                </Text>
                <ToppingTypeFilter searchToppings={this.handleFilter} filter={filters}>
                  <FilterIcon height="12" width="13" color={Black} />
                </ToppingTypeFilter>
                <span className={classnames('mui-override sk-сoursesTable__cell__elements__arrow', {
                  'rotate': isSortedByType ? isSortOrderAscending : true,
                  'show': isSortedByType
                })}>
                  <ArrowToDown height="12" width="13" color={isSortedByType ? Black : Grey4} />
                </span>
              </div>
            </TableCell>
            <TableCell
              classes={classes.table}
              className="mui-override sk-сoursesTable__cell"
            >
              <div className='mui-override sk-сoursesTable__cell__elements'>
                <Text bold align="left" onClick={this.handleSortByAuthor}>
                  EXPERT
                </Text>
                <span className={classnames('mui-override sk-сoursesTable__cell__elements__arrow', {
                  'rotate': isSortedByAuthor ? isSortOrderAscending : true,
                  'show': isSortedByAuthor
                })}>
                  <ArrowToDown height="12" width="13" color={isSortedByAuthor ? Black : Grey4} />
                </span>
              </div>
            </TableCell>
            <TableCell
              classes={classes.table}
              className="mui-override sk-сoursesTable__cell"
            >
              <div className='mui-override sk-сoursesTable__cell__elements'>
                <Text bold align="left" onClick={this.handleSortByStatus}>
                  STATUS
                </Text>
                <StatusFilter searchToppings={this.handleFilter} filter={filters}>
                  <FilterIcon height="12" width="13" color={Black} />
                </StatusFilter>
                <span className={classnames('mui-override sk-сoursesTable__cell__elements__arrow', {
                  'rotate': isSortedByStatus ? isSortOrderAscending : true,
                  'show': isSortedByStatus
                })}>
                  <ArrowToDown height="12" width="13" color={isSortedByStatus ? Black : Grey4} />
                </span>
              </div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <Fragment>
            {courses && courses.length > 0 ? this.renderTableBody(courses) : null}
          </Fragment>
        </TableBody>
      </Table>
    );
  }
}

// <button className='mui-override sk-сoursesTable__cell__elements__arrow show' onClick={this.openFilter}>
//   <FilterIcon height="12" width="13" color={Black} />
// </button>
// <StatusFilter anchorEl={anchorEl} handleFilter={this.handleFilter} statusFilters={statusFilters} />

CoursesTable.propTypes = {
  courses: PropTypes.array,
};

export default CoursesTable;
