// Core
import React from 'react';
import { compose, graphql } from 'react-apollo';

// Components
import Card from 'components/Card';
import QuizPreview from 'components/QuizPreview';
import QuizScore from 'components/QuizPreview/QuizScore';
import Loader from 'components/Loader';

// GraphQL
import COMPLETE_QUIZ from 'mutations/Courses/quiz/completeQuiz.gql';

// Other
import { withQuizData } from 'components/QuizPreview/withQuizData';
import { withAuthenticatedUser } from 'containers/AuthenticatedUserProvider/withAuthenticatedUser';
import { isBlank, keysToSnake } from 'utils/helpers';

import './index.scss';

const CourseModuleQuiz = ({
  loading,
  quiz,
  readOnly,
  completeQuiz,
  userContext: { profile, },
  ...props
}) => {

  if (loading) return <Loader />;

  const isQuizCompleted = !isBlank(quiz?.score);
  const completeQuizHandler = (questionsWithAnswers, isValid) => async () => {
    if (readOnly) return;

    const input = {
      quiz_id: quiz.id,
      questions: questionsWithAnswers
    };
    if (profile?.id) {
      input.user_id = profile.id;
    }

    if (isValid) {
      await completeQuiz(input);
    };
  };

  return (
    <div className="sk-course-module-quiz">
      <Card>
        <QuizPreview quiz={quiz} isQuizCompleted={isQuizCompleted} completeQuiz={completeQuizHandler} />
      </Card>
      {isQuizCompleted && (
        <Card>
          <QuizScore score={quiz?.score} />
        </Card>
      )}
    </div>
  );
};

const completeQuizMutation = graphql(COMPLETE_QUIZ, {
  props: ({ mutate }) => ({
    completeQuiz: input => {
      mutate({ variables: { input: keysToSnake(input) }});
    },
  }),
  options: (props) => ({
    fetchPolicy: 'no-cache',
    refetchQueries: ['getQuiz']
  }),
});

const enhancer = compose(
  withQuizData,
  completeQuizMutation,
  withAuthenticatedUser,
);

export default enhancer(CourseModuleQuiz);
