import { defineMessages } from "react-intl";

export const messages = defineMessages({
  success: {
    id: "app.components.SuccessfulResetMessage.success",
    defaultMessage: "Success",
  },
  youPasswordUpdated: {
    id: "app.components.SuccessfulResetMessage.youPasswordUpdated",
    defaultMessage: "Your password has been updated.",
  },
  goToLogin: {
    id: "app.components.SuccessfulResetMessage.goToLogin",
    defaultMessage: "Go to login",
  },
});
