import React, { PureComponent } from 'react';

import ErrorBoundary from 'containers/ErrorBoundary/ErrorBoundary.js'

export const withErrorBoundary = (Component) => {
  const HOC = class extends PureComponent {
    render() {
      return (
        <ErrorBoundary>
          <Component {...this.props} />
        </ErrorBoundary>
      )
    }
  }

  return HOC;
}
