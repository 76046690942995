// Core
import React, { memo } from 'react';
import PropTypes from 'prop-types';

// Components
import Heading from 'components/Heading';
import Button from 'components/Button';
import Text from 'components/Text';
import Done from 'components/Icon/Svg/Done';

// Styles and Assets
import './SendMessageSuccess.scss';

import { FormattedMessage } from 'react-intl';
import { messages } from 'containers/SendMessageExpertModal/messages';

export const SendMessageSuccess = ({ handleContinueClick }) => (
  <div className="sk-send-msg-success">
    <div className="sk-send-msg-success_header">
      <Heading variant="h4" classes="sk-send-msg-success_heading">
        <FormattedMessage {...messages.successful} />
      </Heading>
      <div className="sk-send-msg-success_icon">
        <Done width="51" height="51" />
      </div>
      <Text classes="sk-send-msg-success_subheading">
        <FormattedMessage {...messages.soonYouWillReceiveRes} />
      </Text>
    </div>
    <div className="sk-send-msg-success_footer">
      <Button
        variant="text"
        color="secondary"
        size="large"
        onClick={handleContinueClick}
      >
        <FormattedMessage {...messages.back} />
      </Button>
    </div>
  </div>
);

SendMessageSuccess.propTypes = {
  handleContinueClick: PropTypes.func,
};

export default memo(SendMessageSuccess);
