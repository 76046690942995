// Core
import React, { PureComponent } from 'react';
// import PropTypes from 'prop-types';

// Components
import Heading from 'components/Heading';
import Text from 'components/Text';

// Other
import { FormattedMessage } from 'react-intl';
import { messages } from 'routes/PageNotFound/messages';

// Styles and Assets
import './PageNotFound.scss';

class PageNotFound extends PureComponent {
  state = {};

  render() {
    return (
      <div className="sk-page-not-found">
        <div className="sk-page-not-found_inner">
          <Heading>404</Heading>
          <Text>
            <FormattedMessage {...messages.pageNotFound} />
          </Text>
        </div>
      </div>
    );
  }
}

PageNotFound.propTypes = {};

export default PageNotFound;
