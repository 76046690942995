// Core
import React, { memo } from 'react';
import PropTypes from 'prop-types';

// components
import StatusBlock from 'routes/ExpertsCabinet/components/StatusBlock';
import Text from 'components/Text';

// Styles and Assets
import './HWSummaryComponent.scss';

export const HWSummaryComponent = ({
  name,
  averageMark,
  allHomeworksChecked,
}) => (
  <div className="sk-hw-summary grid grid_gapFree grid_fullWidth">
    <div className="grid_item-xs-offset-1-24 grid_item-md-offset-2-24" />
    <div className="sk-hw-summary__name  grid_item-lg-6-24  grid_item-xs-8-24 grid_item-sm-7-24">
      <Text>{name}</Text>
    </div>
    <div className="sk-hw-summary__average  grid_item-lg-8-24  grid_item-xs-4-24 grid_item-sm-7-24">
      {<Text>{`${averageMark}/100`}</Text>}
    </div>
    <div className="sk-hw-summary__status  grid_item-lg-6-24  grid_item-xs-8-24 grid_item-sm-6-24">
      <StatusBlock allHomeworksChecked={allHomeworksChecked} />
    </div>
    <div className="grid_item-xs-offset-1-24 grid_item-md-offset-2-24" />
  </div>
);

HWSummaryComponent.propTypes = {
  name: PropTypes.string,
  averageMark: PropTypes.number,
  allHomeworksChecked: PropTypes.bool,
};

export default memo(HWSummaryComponent);
