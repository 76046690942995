import React, { PureComponent } from 'react';
import uuidv4 from 'uuid/v4';

// Components
import Modal from 'components/Modal';

// Other
import { LayerModalContext } from 'containers/LayerModalProvider/layerModal-context';
import { withLayerModal } from 'containers/LayerModalProvider/withLayerModal';
import { isBlank } from 'utils/helpers/index';

class LayerModalProvider extends PureComponent {
  state = {
    modals: [],
  };

  showModal = (component, props = {}, modalProps) => {
    const { modals } = this.state;
    const newModal =  {
      id: uuidv4(),
      level: modals.length + 1,
      component,
      props,
    };

    this.setState({ modals: [...modals, newModal], });
  };

  hideModal = modalId => {
    const { modals } = this.state;
    let newModals = modals;

    const closeAllModals = Array.isArray(modalId) && modals.length > 0;
    const closeTopModal = isBlank(modalId) && modals.length > 0;
    const closeSpecificModals = (modalId && typeof modalId === 'string')
      || (Array.isArray(modalId) &&  modalId.length > 0 && modals.length > 0);

    if (closeSpecificModals) {
      const modalsIds = Array.isArray(modalId) ? modalId : [modalId];
      newModals = modals.filter(modal => !modalsIds.includes(modal.id ));
    } else if (closeTopModal) {
      const topModalIndex = modals.length - 1;
      const modalId = modals[topModalIndex]?.id;
      newModals = modals.filter(modal => modal.id !== modalId);
    } else if (closeAllModals) {
      newModals = [];
    };

    this.setState(prevState => ({ modals: newModals, }));
  };

  getCurrentModal = (modalId) => {
    const { modals } = this.state;
    const currentModal = modals.find(modal => modal.id === modalId);
    return currentModal ? currentModal : {};
  }

  render() {
    const { children } = this.props;
    const { modals } = this.state;

    return (
      <LayerModalContext.Provider
        value={{
          layerModalContext: {
            getCurrentModal: this.getCurrentModal,
            showModal: this.showModal,
            hideModal: this.hideModal,
          },
        }}
      >
        {children}
        {modals.map(modal => {
          const { id, component, props, level } = modal;
          return (
            <Modal
              open
              modalId={id}
              level={level}
              component={component}
              hideModal={this.hideModal}
              {...props}
            />
          )
        })}
      </LayerModalContext.Provider>
    );
  }
}

export default withLayerModal(LayerModalProvider);
