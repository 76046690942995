// Core
import React from 'react';
// import PropTypes from 'prop-types';

// Components
import Card from 'components/Card';
import PrivacyPolicyText from 'containers/PrivacyPolicyText';

// Other

// Styles and Assets
import './PrivacyPolicy.scss';

const PrivacyPolicy = () => (
  <div className="sk-terms-of-use">
    <div className="sk-terms-of-use__wrapper">
      <Card classes="sk-terms-of-use__card">
        <PrivacyPolicyText />
      </Card>
    </div>
  </div>
);

PrivacyPolicy.propTypes = {};

export default PrivacyPolicy;
