// Core
import React, { useState, useRef, useEffect, useMemo } from "react";
import ReactSwipe from "react-swipe";
import { isBlank } from "utils/helpers";

// Components
import LandingPageCommentCard from "routes/LandingPage/LandingPageComments/LandingPageCommentCard/";

// Other
import { withLanguage } from "containers/LanguageProvider/withLanguage";

//styles
import "./LandingPageCommentsCarousel.scss";
const photo1 = "/assets/images/first-success-story-avatar.png";
const photo2 = "/assets/images/second-success-story-avatar.png";
const photo3 = "/assets/images/third-success-story-avatar.png";

const defaultCommentsRu = [
  {
    id: 1,
    name: "Алла Егорова",
    comment:
      "У меня была необходимость в консультации по персональным данным в связи с открытием бизнеса. Нашла курс по персональным данным на платформе AVDO Education. Во первых, понравилась сама платформа, ее дизайн, кабинет, очень все удобно и интересно. Сразу понимаешь, о чем курс, все работает на автомате. С экспертом считаю тоже очень повезло, все понятно, хотя первый раз со всем столкнулась. Мне очень понравилось!",
    facebook: "https://www.facebook.com/alla.jegorova",
    photo: photo1,
  },
  {
    id: 2,
    name: "Анна Атаманова",
    comment:
      "На работе намечался проект, и я решила подготовиться.  Проходила курс по управлению проектами.  Сразу применяла знания на практике.  Оказалось очень полезно.  Системно работать над проектом куда проще.  Платформа интересная, легко во всём разобраться.  Мне особенно понравилось обсуждения между преподавателем и студентами.  Спасибо за прекрасный опыт.  Рекомендую!",
    position: "Менеджер по отчётности Johnson & Johnson",
    country: "Москва",
    facebook: "https://facebook.com/anna.atamanova.7",
    linkedin: "https://www.linkedin.com/in/anna-atamanova-50aa374a ",
    photo: photo2,
  },
  {
    id: 3,
    name: "Антон Павлов",
    comment:
      "Я собирался в США на несколько месяцев.  Подтянуть английский надо было.  Полезный курс, много нового.  Доступ остался, смогу возвращаться к нему если что.  Крутые технологии.  Затягивает 👍",
    position: "Архитектор SMART company",
    country: "Москва",
    facebook: "https://www.facebook.com/efegora",
    photo: photo3,
  },
];

const defaultCommentsEn = [
  {
    id: 1,
    name: "Alan Stone",
    comment:
      "With a huge amount of e-learning platforms, it might be a bit difficult to pick one that would suit your wants and needs. However, AVDO Education is something unique. What’s cool about AVDO Education is that all creative skills are placed in one space, and most importantly, many courses are interactive. You have a chance to collaborate with your course’s mates and experts! On top of learning content, they have professional community that can promote you for a job! ",
    facebook: "https://www.facebook.com/alla.jegorova",
    position: "UI/UX Developer, Los Angeles",
    photo: photo1,
    className: "firstStory",
  },
  {
    id: 2,
    name: "Sarah Westwood",
    comment:
      "The platform brings its own uniques features into the mix. Even though some of them are more successful than others, one thing is still for certain - the name AVDO will be soon one of the most well-known in the e-learning industry. Another great feature that I thought I’d mention in this- AVDO Education promises that many courses are interactive and require students to work on a project as they learn the subject material. This way, you not only get the theoretical information about the subject that interests you, but also get a chance to create your very first project right as you finish the course. Students publish their work for community review and get a feedback. Amazing!",
    position: "Fashion Designer, London",
    facebook: "https://facebook.com/anna.atamanova.7",
    linkedin: "https://www.linkedin.com/in/anna-atamanova-50aa374a ",
    photo: photo2,
    className: "secondStory",
  },
  {
    id: 3,
    name: "Jason Conroy",
    comment:
      "I love the fact that AVDO Education has so many opportunities to learn new creative skills and develop your current ones! The tutors' steps are always easy to follow and take notes on and the tips aren't generic. Really talented people from different industries have created content for this site, so you can learn everything from new design skills to how to build a stunning website. Let’s say you look at 3 videos on how to start a blog. They might have the same underlying agenda, but you come away with 3 completely new and fresh ideas of how to approach the same thing. That makes it easy to figure out what kind of techniques will work best for you instead of high-level generic information in YouTube that really doesn't help anybody. ",
    position: "Photographer, New York",
    facebook: "https://www.facebook.com/efegora",
    photo: photo3,
    className: "thirdStory",
  },
];

const LandingPageCommentsCarousel = ({ languageContext: { language } }) => {
  const reactSwipeEl = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  const isRu = language === "ru";
  const defaultComments = isRu ? defaultCommentsRu : defaultCommentsEn;

  useEffect(() => {
    reactSwipeEl.current.slide(currentSlide);
  }, [reactSwipeEl.current, currentSlide, setCurrentSlide]);

  const navClick = (ind) => {
    setCurrentSlide(ind);
  };

  const swipeOptions = useMemo(
    () => ({
      transitionEnd() {
        setCurrentSlide(reactSwipeEl.current.getPos());
      },
    }),
    [reactSwipeEl]
  );

  const dots = () => {
    let buttons = [];
    for (let i = 0; i < defaultComments.length; i++) {
      if (i === currentSlide) {
        buttons.push(
          <div
            key={i}
            className="carousel_dot active"
            onClick={() => navClick(i)}
          ></div>
        );
      } else {
        buttons.push(
          <div
            key={i}
            className="carousel_dot"
            onClick={() => navClick(i)}
          ></div>
        );
      }
    }
    return buttons;
  };

  return (
    <div className="sk-landing-page__comments_carousel">
      <ReactSwipe
        childCount={defaultComments.length}
        className="sk-landing-page__comments_carousel__swipe"
        ref={reactSwipeEl}
        swipeOptions={swipeOptions}
      >
        {defaultComments.map((item) => (
          <div
            className="sk-landing-page__comments_carousel__slide"
            key={item.id}
          >
            <LandingPageCommentCard {...item} key={item.id} />
          </div>
        ))}
      </ReactSwipe>
      <div className="nav-controll">{dots()}</div>
    </div>
  );
};

export default withLanguage(LandingPageCommentsCarousel);
