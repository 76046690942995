import React from 'react';
import { PurpleLight } from 'components/Icon/color';

import classNames from 'classnames';
import {isBlank} from 'utils/helpers';

import './Upload.scss';

const Upload = ({ color=PurpleLight, activeColor, active, height=24, width=24 }) => {
  const activeIcon = isBlank(active);
  return(
    <svg width={width} height={height} viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={classNames('sk-uploadIcon', {activeIcon: 'active'})}>
      <path d="M21.3125 15.125C20.933 15.125 20.625 15.433 20.625 15.8125V20.625H1.37503V15.8125C1.37503 15.433 1.067 15.125 0.687541 15.125C0.308082 15.125 0 15.433 0 15.8125V21.3125C0 21.692 0.308025 21.9999 0.687484 21.9999H21.3125C21.692 21.9999 22 21.6919 22 21.3125V15.8125C22.0001 15.433 21.692 15.125 21.3125 15.125Z" />
      <path d="M11.4807 2.1995C11.2133 1.93479 10.7657 1.93205 10.4983 2.1995L5.68651 6.94324C5.41494 7.21207 5.41563 7.64724 5.68651 7.91538C5.95739 8.1842 6.39736 8.1842 6.66824 7.91538L10.2948 4.34035L10.2948 17.8106C10.2948 18.1901 10.6056 18.498 10.9892 18.498C11.3729 18.498 11.6836 18.19 11.6836 17.8106L11.6836 4.34035L15.3102 7.91538C15.5818 8.1842 16.0211 8.1842 16.2919 7.91538C16.5635 7.64656 16.5635 7.21138 16.2919 6.94325L11.4807 2.1995Z" />
    </svg>

  );
}

export default Upload;
