// Core
import React, { PureComponent } from 'react';
import FilledInput from '@material-ui/core/FilledInput';

// Styles and Assets
import '../FilterDropDown.scss';

const classes = {
  root: 'mui-override sk-filter-input sk-filter-input_minor',
  focused: 'sk-filter-input_focused',
};

class MinorFilterInput extends PureComponent {
  getExtraInputClasses = value => ({
    root: `mui-override sk-filter-input sk-filter-input_minor ${!value &&
      'sk-filter-input_minor__empty'}`,
  });

  render() {
    const { ...rest } = this.props;

    return (
      <FilledInput
        {...rest}
        classes={{ ...classes, ...this.getExtraInputClasses(rest.value) }}
      />
    );
  }
}

MinorFilterInput.propTypes = {};

export default MinorFilterInput;
