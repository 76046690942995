import { createMuiTheme } from '@material-ui/core/styles';
import { createContext } from 'react';

const casualTheme = createMuiTheme({
  typography: {
    fontFamily: 'Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
    useNextVariants: true,
  },
  breakpoints: {
    values: {
      lg: 1280,
      md: 1024,
      sm: 768,
      xs: 480,
    },
  },
});

export const ThemeContext = createContext({
  themeContext: {
    ...casualTheme,
  },
});
