// Core
import React, { memo, } from 'react';
import PropTypes from 'prop-types';

// Components
import ReadMore from 'components/ReadMore';

const textStyles = {
  fontSize: '16px',
  lineHeight: '24px',
  fontFamily: 'Roboto',
  color: '#6D7C90',
  margin: 0,
  padding: 0,
  display: 'flex',
  whiteSpace: 'pre-line'
};

const AboutLecture = ({ description }) => {
  return (
    <ReadMore lines={2} textStyles={textStyles} withToggle={true}>
      {description}
    </ReadMore>
  );
};

AboutLecture.propTypes = {
  description: PropTypes.string,
};

export default memo(AboutLecture);
