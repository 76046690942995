// Core
import React, { memo, useState, useEffect, useCallback } from 'react';
import { graphql, compose } from 'react-apollo';
import { withRouter } from 'react-router';
import Collapse from '@material-ui/core/Collapse';
import { injectIntl } from 'react-intl';

// Components
import Text from 'components/Text';
import Button from 'components/Button';
import Loader from 'components/Loader';
import ContentHeader from 'containers/ContentHeader';
import Breadcrumbs from 'components/Breadcrumbs';
import Tabs from 'routes/ExpertsCabinet/ExpertsFee/Tabs';
import SubscriptionSelect from 'routes/ExpertsCabinet/ExpertsFee/SubscriptionSelect';
import PlansFeatures from 'routes/ExpertsCabinet/ExpertsFee/PlansFeatures';
import SuccessCommissionSubscription from 'routes/ExpertsCabinet/ExpertsFee/SuccessCommissionSubscription';

// HOCs
import { withSidebar } from 'containers/SidebarProvider/withSidebar';
import { withLoader } from 'containers/HOCs/withLoader';
import { withModal } from 'containers/ModalProvider/withModal';

// GraphQL
import LIST_SUBSCRIPTION_PLANS from 'queries/AdminPanel/listSubscriptionPlans.gql';
import CREATE_SUBSCRIPTION from 'mutations/Subscriptions/createSubscription.gql';
import UPDATE_SUBSCRIPTION from 'mutations/Subscriptions/updateSubscription.gql';

// Other
import { isBlank, keysToCamel, keysToSnake } from 'utils/helpers';
import { sharedMessages } from 'services/i18n/sharedMessages/messages';
import { withAuthenticatedUser } from 'containers/AuthenticatedUserProvider/withAuthenticatedUser';
import { getPriceWithDiscount } from 'routes/ExpertsCabinet/ExpertsFee/SubscriptionSelect/TotalPrice';
import { messages } from 'routes/ExpertsCabinet/ExpertsFee/messages.js';

// Styles and Assets
import './index.scss';

const DEFAULT_SUBSCRIPTIONS = {
  package: {
    billingPeriod: 'monthly'
  },
  constructor: {
    maxCourses: 1,
    billingPeriod: 'monthly'
  }
};

const COLLAPSE_STYLES = {
  subscriptions: {
    wrapperInner: 'sk-subscription-collapse-wrapper-inner'
  },
  commission: {
    wrapperInner: 'sk-commission-collapse-wrapper-inner'
  }
}

const prefix = 'sk-expert-cabinet-fee';
export const ExpertsFee = ({
  items,
  userContext: { profile, },
  createSubscription,
  updateSubscription,
  history,
  intl: { formatMessage },
  modalContext: { showModal, hideModal, component }
}) => {
  const isModalOpen = component !== null;
  const [created, setCreated] = useState(false);
  const [subscription, setSubscription] = useState(DEFAULT_SUBSCRIPTIONS);
  const handleUpdateSubscription = useCallback((type) => (key, value) => {
    setSubscription({ ...subscription, [type]: { ...subscription[type], [key]: value } });
  }, [subscription, setSubscription]);

  const [activeTab, setActiveTab] = useState('subscription');
  const handleTabSwitch = useCallback((nextTab) => {
    setActiveTab(nextTab);
  }, [setActiveTab]);

  useEffect(() => {
    const validateSubscription = async (subscription) => {
      if (isBlank(subscription) && !subscription?.planId && !created) {
        try {
          setCreated(() => true);
          await createSubscription();
        } catch (e) {
          console.log('error', e);
        };
      } else if (!isBlank(subscription) && subscription?.status == 'active') {
        if(!isModalOpen && subscription?.planId) {
          history.push(`/experts-cabinet/subscription/${subscription?.planId}`);
        }
      }
    };
    validateSubscription(profile?.subscription);
  }, [profile?.subscription, createSubscription, history?.push, isModalOpen]);


  const handleSelectSubscription = useCallback((plan) => async () => {
    const { type, id: planId, price: prices, discountPerCourse, discountCoursesLimit } = plan;
    const { maxCourses, billingPeriod } = subscription[type];
    let price = prices?.find(price => price?.type == billingPeriod);
    price = billingPeriod === 'annual' ? price?.amount*12 : price?.amount;
    price = getPriceWithDiscount(price, maxCourses, discountPerCourse, discountCoursesLimit);

    await updateSubscription({ type, planId, price, autorenew: true, maxCourses, billingPeriod });
    history.push('/checkout/subscriptions');
  }, [subscription, history.push]);

  const handleSelectCommissionPlan = useCallback(async () => {
    const commission = items.find(plan => plan?.type == 'commission');
    const price = commission?.price?.find(price => price?.type == 'commission')?.amount;

    await updateSubscription({ type: 'commission', planId: commission?.id, price, autorenew: true, status: 'active' });
    showModal(SuccessCommissionSubscription, {
      handleClick: hideModal,
      size: 'md'
    });
  }, [items, history.push]);
  const plans = items.filter(plan => plan?.type == 'package' || plan?.type == 'constructor');

  return (
    <div className={prefix}>
      <ContentHeader way={<Breadcrumbs />} />
      <Tabs activeTab={activeTab} handleSwitch={handleTabSwitch} />

      <Collapse classes={COLLAPSE_STYLES.subscriptions} in={activeTab === 'subscription'} timeout="auto">
        {plans?.map(plan => (
          <SubscriptionSelect  {...subscription[plan?.type]}
              key={plan?.id}
              plan={plan}
              selectSubscription={handleSelectSubscription(plan)}
              updateSubscription={handleUpdateSubscription(plan?.type)}
            />
        ))}
        <PlansFeatures />
      </Collapse>

      <Collapse classes={COLLAPSE_STYLES.commission} in={activeTab === 'sales'} timeout="auto">
        <Text>{formatMessage(messages.commissionTabTitle)}</Text>
        <Text size='small'>{formatMessage(messages.commissionTabSecondaryTitle)}</Text>
        <PlansFeatures />
        <Button
          size='large'
          variant='text'
          color='secondary'
          onClick={handleSelectCommissionPlan}
        >
          {formatMessage(messages.selectCommissionPlanButton)}
        </Button>
      </Collapse>
    </div>
  );
};

const createSubscriptionMutation = graphql(CREATE_SUBSCRIPTION, {
  props: ({ mutate }) => ({
    createSubscription: values => mutate({ variables: { input: keysToSnake(values) } })
  }),
  options: () => ({
    refetchQueries: ['getProfile'],
  })
});

const updateSubscriptionMutation = graphql(UPDATE_SUBSCRIPTION, {
  props: ({ mutate }) => ({
    updateSubscription: values => mutate({ variables: { input: keysToSnake(values) } })
  }),
  options: () => ({
    refetchQueries: ['getProfile'],
  })
});

const listSubscriptionPlans = graphql(LIST_SUBSCRIPTION_PLANS, {
  props: ({ data: { error, loading, listSubscriptionPlans, refetch, ...ownProps } }) => {
    return {
      items: keysToCamel(listSubscriptionPlans?.items),
      loading,
      refetch,
      ...ownProps,
    }
  },
  options: () => ({
    fetchPolicy: 'network-only',
  }),
});

const enhancer = compose(
  memo,
  injectIntl,
  withSidebar,
  withRouter,
  withModal,
  withAuthenticatedUser,
  listSubscriptionPlans,
  createSubscriptionMutation,
  updateSubscriptionMutation,
  withLoader
);

export default enhancer(ExpertsFee);
