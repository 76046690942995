// Core
import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';

// Components
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Text from 'components/Text';

// Other
import { sharedMessages } from 'services/i18n/sharedMessages/messages';
import { getTableCellContent } from 'routes/ManageAccount/helpers';

// Styles and Assets
import './StudentsTableRow.scss';

const StudentsTableRow = memo(({ total = false, item, index, arr, intl: { formatMessage } }) => (
  <TableRow key={item.id} className='mui-override sk-expert-analytics-students-table-row'>
    <TableCell
      className='mui-override sk-expert-analytics-students-table-row__cell'
    >
      <Text size="basic" align="left" className="mui-override sk-expert-analytics-students-table-row__title">
        {item.title}
      </Text>
    </TableCell>
    <TableCell
      className='mui-override sk-expert-analytics-students-table-row__cell'
    >
      <Text size="basic" align="right">
        {getTableCellContent(item.flex)}
      </Text>
    </TableCell>
    <TableCell
      className='mui-override sk-expert-analytics-students-table-row__cell'
    >
      <Text size="basic" align="right">
        {getTableCellContent(item.assist)}
      </Text>
    </TableCell>
    <TableCell
      className='mui-override sk-expert-analytics-students-table-row__cell'
    >
      <Text size="basic" align="right">
        {getTableCellContent(item.concierge)}
      </Text>
    </TableCell>
    <TableCell
      className='mui-override sk-expert-analytics-students-table-row__cell'
    >
      <Text bold size="basic" align="right">{getTableCellContent(item.total)}</Text>
    </TableCell>
  </TableRow>
));

export default injectIntl(StudentsTableRow);
