import React, { PureComponent } from 'react';
import { withRouter } from 'react-router';
import { compose } from 'react-apollo';

// Other
import { withSentry } from 'containers/SentryProvider/withSentry';

class ErrorBoundary extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  // static getDerivedStateFromError(error) {
  //   // Оновити стан, щоб наступний рендеринг показав резервний інтерфейс користувача.
  //   // return { hasError: true };
  //
  //   console.log('error', error);
  //
  //   return { error, errorInfo: error };
  // }

  // componentDidUpdate(nextProps, prevState) {
  //   console.log('update boundary');
  // }

  componentDidCatch(error, errorInfo) {
    const isProd =  process.env.NODE_ENV == 'production';
    if (isProd) {
      this.throwErrorMessage(error, errorInfo);
    }
  }

  throwErrorMessage = (error, errorInfo) => {
    const {
      history,
      sentryContext: { throwError },
    } = this.props;

    throwError(error, errorInfo);
    history.replace(`/something-goes-wrong`);
  }

  render() {
    const { error, errorInfo } = this.state;

    // if (error) this.throwErrorMessage(error, errorInfo);
    return this.props.children;
  }
}

const enhancer = compose(
  withSentry,
  withRouter
);

export default enhancer(ErrorBoundary);
