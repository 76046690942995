// Core
import React, { useState, useEffect, useCallback } from 'react';
import { graphql, compose } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { withFormik } from 'formik';
import { getYupSchema, } from 'services/yup';

// Components
import TextInput from 'components/Input/Material/TextInput/TextInput';
import IconGroup from 'routes/ExpertsCabinet/QuizEdit/Components/IconGroup';
import RadioButton from 'components/RadioButton';
import MaterialCheckbox from '@material-ui/core/Checkbox';
import Checkbox from 'components/Checkbox';
import UploadedImage from 'components/UploadedImage';

// GraphQL
import DELETE_QUIZ_ANSWER from 'mutations/Courses/quiz/deleteQuizAnswer.gql';
import UPDATE_QUIZ_ANSWER from 'mutations/Courses/quiz/updateQuizAnswer.gql';

// Other
import { withFormattedUntitled } from 'routes/ExpertsCabinet/QuizEdit/HOCs/withFormattedUntitled.js';
import { withNotification } from 'containers/NotificationProvider/withNotification';
import { keysToSnake, isTrue, isBlank, isEmptyArray } from 'utils/helpers';
import { messages } from 'routes/ExpertsCabinet/QuizEdit/messages';
import { withQuizImageUpload } from 'routes/ExpertsCabinet/QuizEdit/HOCs/withQuizImageUpload';

// Styles and Assets
import './index.scss';

const INITIAL_FORM_VALUES = {
  multiselect: false,
  correct: false,
  title: '',
}

const QuizAnswer = ({
  key,
  answer,
  answersCount,
  quizId,
  multiselect,
  isValid,
  values: initialValues,
  errors,
  touched,
  handleChange,
  setFieldTouched,
  setFieldValue,
  validateForm,
  updateQuizAnswer,
  deleteQuizAnswer,
  handleUploadAnswerImage,
  handleImageDelete,
  showNotificationBar,
  intl: { formatMessage },
}) => {
  const values = { ...initialValues };
  const uploadImage = useCallback((id) => (file) => {
    handleUploadAnswerImage({
      id,
      file,
      quizId,
      questionId: values?.questionId,
      refId: values?.id,
    });
  }, [values?.id, values?.questionId, handleUploadAnswerImage]);
  const handleDeleteQuizAnswer = async () => {
    if (answersCount > 1) {
      await deleteQuizAnswer(values?.id);
    };
  };
  const handleChooseAnswer = async () => {
    const input = {
      id: values?.id,
      questionId: values?.questionId,
      correct: !values?.correct,
    };

    await updateQuizAnswer(input);
  };

  const handleInputBlur = useCallback(fieldName => async (inputValue, e) => {
    setFieldTouched(fieldName);
    setFieldValue(fieldName, inputValue, false);

    const errors = await validateForm({ ...values, [fieldName]: inputValue });
    const isValid = isBlank(errors[fieldName]);

    const isDefaultTitle = fieldName === 'title'
      && formatMessage(messages.quizAnswerUntitledTitle) === inputValue.replace(/\s+/g, '');

    if (isValid && !isDefaultTitle) {
      const input = {
        id: values.id,
        question_id: values.questionId,
        [fieldName]: inputValue
      };

      await updateQuizAnswer(input);
      showNotificationBar('saved');
    };
  }, [values]);

  return (
    <div className='sk-quiz-answer'>
      {isTrue(multiselect) ? (
        <Checkbox
          name={'answer'}
          checked={values?.correct}
          handleChange={handleChooseAnswer}
        />
      ) : (
        <RadioButton
          name={'answer'}
          checked={values?.correct}
          handleChange={handleChooseAnswer}
          classAdditional={`mui-override`}
          iconProps={{ width: 18, height: 18 }}
        />
      )}
      <div className='sk-quiz-answer__general-info'>
        <TextInput
          name="title"
          value={values?.title}
          onBlur={handleInputBlur('title')}
          placeholder={formatMessage(messages.answerTitlePlaceholder)}
          label={formatMessage(messages.quizAnswerUntitledTitle)}
          error={touched.title && Boolean(errors.title)}
          helperText={touched.title ? errors.title : ''}
        />
        <IconGroup
          uploaderId={`quiz-answer-image-${values?.id}`}
          handleImageUpload={uploadImage()}
          handleDelete={handleDeleteQuizAnswer}
          withRound={false}
        />
      </div>
      <div className='sk-quiz-answer__images-group'>
        {(!isBlank(values?.materials) && !isEmptyArray(values?.materials)) && values?.materials.map(image => {
          return (
            <UploadedImage
              uploaderId={`quiz-answer-reupload-${values?.id}`}
              fileKey='image'
              image={image}
              handleImageUpload={uploadImage(image?.id)}
              handleImageDelete={handleImageDelete}
            />
          )
        })}
      </div>
    </div>
  )
}

const deleteQuizAnswerMutation = graphql(DELETE_QUIZ_ANSWER, {
  props: ({ mutate }) => ({
    deleteQuizAnswer: id => mutate({ variables: { id } }),
  }),
  options: () => ({
    refetchQueries: ['getQuiz'],
  }),
});

const updateQuizAnswerMutation = graphql(UPDATE_QUIZ_ANSWER, {
  props: ({ mutate }) => ({
    updateQuizAnswer: input => mutate({ variables: { input: keysToSnake(input) }})
  }),
  options: () => ({
    refetchQueries: ['getQuiz'],
  }),
});

const formikStateManagement = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ answer, formatUntitled }) => {
    answer.title = formatUntitled(answer.title, messages.quizAnswerUntitledTitle);
    return { ...INITIAL_FORM_VALUES, ...answer };
  },
  displayName: 'QuizAnswerForm',
  validationSchema: () => getYupSchema('quizAnswerEditValidationSchema'),
  validateOnChange: false,
  validateOnBlur: false
})

const enhancer = compose(
  withFormattedUntitled,
  injectIntl,
  formikStateManagement,
  updateQuizAnswerMutation,
  deleteQuizAnswerMutation,
  withQuizImageUpload,
  withNotification
);

export default enhancer(QuizAnswer);
