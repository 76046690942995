// Core
import React from 'react';

// Components
import Text from 'components/Text/Text';
import RadioButtonLabel from 'components/RadioButtonLabel';
import Typography from '@material-ui/core/Typography';

// Other
import { withConvertedPrice } from 'containers/CurrencyProvider/withConvertedPrice';
import Price from './Price';
// Styles and Assets
import './index.scss';

const PriceRadioButtonLabel = ({
  withIcon,
  icon: Icon,
  price,
  name,
  disabled = false,
}) => {
  return (
    <div className='sk-radio-button-label sk-radio-button-label__price'>
      {!disabled && (
        <RadioButtonLabel
          oldText={name}
          text='Standard price'
          disabled={disabled}
        />
      )}
      {withIcon ? (
        <Icon />
      ) : (
        !disabled && (
          <Typography variant='h5'>
            <Price value={price} />
          </Typography>
        )
      )}
    </div>
  );
};

export default withConvertedPrice(PriceRadioButtonLabel);
