import React from 'react';

const Icon = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.41211 19.2612V4.34619C4.41211 3.56575 5.03646 2.94141 5.81689 2.94141H14.6271C15.0434 2.94141 15.4249 3.11484 15.7024 3.44435L19.6393 8.04024C19.8474 8.30039 19.9688 8.61256 19.9688 8.95942V19.2958C19.9688 20.7353 18.8068 21.8973 17.3673 21.8973L6.99621 21.8626C5.57409 21.8453 4.41211 20.6833 4.41211 19.2612Z" fill="white" stroke="#A9B6C8" strokeMiterlimit="10"/>
      <path d="M15.0435 3.09766V7.72823C15.0435 8.14447 15.373 8.47398 15.7892 8.47398L19.8128 8.50867" stroke="#A9B6C8" strokeMiterlimit="10"/>
      <path d="M9 13.5385L11.3158 16L15 12" stroke="#A9B6C8" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default Icon;
