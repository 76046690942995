// Core
import React, { useState, useEffect, useCallback } from 'react';
import { compose, graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';

// Components
import Text from 'components/Text';
import FilterForm from 'routes/components/Filters/FilterForm';
import ToppingCard from 'routes/components/ToppingCard';
import FlatDropDown from 'components/DropDown/FlatDropDown';
import FiltersIcon from 'components/Icon/Svg/FiltersIcon'

// Other
import { keysToCamel } from 'utils/helpers';
import { TOPPINGS_TYPES_OPTIONS } from 'routes/ExpertsCabinet/ToppingEdit/enums.js';
import { setCheckedOptions } from 'routes/components/Filters/helpers.js';
import { sharedMessages } from 'services/i18n/sharedMessages/messages';

// Styles
import './index.scss';

const COMPONENT_PREFIX = 'sk-expert-filter-type';
const ToppingTypeFilter = ({
  filter,
  searchToppings,
  intl: { formatMessage },
}) => {
  const type = 'variants.name';
  const typeFilter = filter.find(filter => filter.key == type);
  const [filters, setFilters] = useState(typeFilter?.values || []);

  const setFiltersHandler = (name) => (isTypeChecked, event) => {
    let newChecked = [...filters];

    if (isTypeChecked) {
      newChecked = [...newChecked, name];
    } else {
      newChecked = newChecked.filter(checkboxName => checkboxName !== name);
    };

    setFilters(newChecked)
  };
  const handleApply = useCallback(() => searchToppings({ key: type, values: filters}), [filters, searchToppings]);

  return(
    <div className={COMPONENT_PREFIX}>
      <FlatDropDown
        type={type}
        placement='bottom-end'
        component={FilterForm}
        options={setCheckedOptions(TOPPINGS_TYPES_OPTIONS, filters)}
        keyName='value'
        labelName='label'
        handleChange={setFiltersHandler}
        handleApply={handleApply}
        button={formatMessage(sharedMessages.applyButton)}
      >
        <FiltersIcon />
        <Text>{formatMessage(sharedMessages.toppingTypeFilter)}</Text>
      </FlatDropDown>
    </div>
  )
};

export default injectIntl(ToppingTypeFilter);
