// Core
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import queryString from 'query-string';

//Components
import Button from 'components/Button';

// Styles
import './InputSearch.scss';

const InputSearch = ({ location, history, buttonText, placeholderText }) => {
  const [inputValue, setInputValue] = useState('');

  const selector = qs => ({
    category: qs?.category,
    subcategory: qs?.subcategory,
    query: qs?.query,
    complexity: qs?.complexity || undefined,
    interactivityLevel: qs?.interactivityLevel || undefined,
    language: qs?.language || undefined,
    durationRange: qs?.durationRange || undefined,
    page: qs?.page || 0,
  });

  const parseSearch = location => {
    const qs = queryString.parse(location?.search);
    return selector(qs);
  };

  const handleInputChange = e => {
    setInputValue(e.target.value);
  };

  const handleSearch = event => {
    event.persist();
    history.push({
      pathname: '/courses',
      search: `?${queryString.stringify({
        ...parseSearch(location),
        query: inputValue,
      })}`,
    });
  };

  const handleSearchEnter = e => {
    if (e.key === 'Enter') {
      this.handleSearch(e);
    }
  };

  return(
    <div className="sk-hero-banner_input-search">
      <input
        type="text"
        onChange={e=>handleInputChange(e)}
        className="sk-hero-banner_input-search__field"
        placeholder={placeholderText}
      />
      <Button
        color="secondary"
        variant="text"
        onClick={e=>handleSearch(e)}
      >
        {buttonText}
      </Button>
    </div>
  )
};

export default withRouter(InputSearch);
