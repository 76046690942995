import Certificate from 'components/Icon/SvgCss/PublicCoursePage/Certificate.js';
import Chats from 'components/Icon/SvgCss/PublicCoursePage/Chats.js';
import Homeworks from 'components/Icon/SvgCss/PublicCoursePage/Homeworks.js';
import InterectiveTests from 'components/Icon/SvgCss/PublicCoursePage/InterectiveTests.js';
import StudyMaterials from 'components/Icon/SvgCss/PublicCoursePage/StudyMaterials.js';
import VideoLectures from 'components/Icon/SvgCss/PublicCoursePage/VideoLectures.js';
import Webinars from 'components/Icon/SvgCss/PublicCoursePage/Webinars.js';

export const FEATURES = {
  videoLecturesFeature: {
    icon: VideoLectures,
    translationMessageId: 'videoLecturesFeature',
  },
  studyMaterialsFeature: {
    icon: StudyMaterials,
    translationMessageId: 'studyMaterialsFeature',
  },
  certificatesFeature: {
    icon: Certificate,
    translationMessageId: 'certificatesFeature',
  },
  chatsFeature: {
    icon: Chats,
    translationMessageId: 'chatsFeature',
  },
  homeworksFeature: {
    id: 'homeworks',
    dependOnSettings: true,
    icon: Homeworks,
    translationMessageId: 'homeworksFeature',
  },
  interectiveTestsFeature: {
    icon: InterectiveTests,
    translationMessageId: 'interectiveTestsFeature',
  },
  webinarsFeature: {
    id: 'webinars',
    dependOnSettings: true,
    icon: Webinars,
    translationMessageId: 'webinarsFeature',
  },
};
