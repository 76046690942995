import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  noEvents: {
    id: 'app.components.StudentNearestWebinar.noEvents',
    defaultMessage: 'There are no events in this course yet.',
  },
  nearestWebinar: {
    id: 'app.components.StudentNearestWebinar.nearestWebinar',
    defaultMessage: 'Nearest Webinar.',
  },
  startWatching: {
    id: 'app.components.StudentNearestWebinar.startWatching',
    defaultMessage: 'Start Watching',
  },
});
