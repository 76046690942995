import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from './theme-context';
import { withTheme } from 'containers/ThemeProvider/withTheme';

class ThemeProvider extends PureComponent {
  state = { language: 'en' };

  render() {
    const { children } = this.props;
    const { themeContext } = this.props;

    return (
      <ThemeContext.Provider
        value={{
          themeContext: {
            ...this.state,
          },
        }}
      >
        <MuiThemeProvider theme={themeContext}>{children}</MuiThemeProvider>
      </ThemeContext.Provider>
    );
  }
}

ThemeProvider.propTypes = {
  children: PropTypes.element.isRequired,
  themeContext: PropTypes.object,
};

export default withTheme(ThemeProvider);
