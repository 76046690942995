// Core
import React from 'react';
import { injectIntl } from 'react-intl';
import { compose } from 'react-apollo';

// Components
import Duration from 'components/Duration';
import Text from 'components/Text';
import ReadMore from 'components/ReadMore';
import { ClockIcon, LanguageIcon, QuizIcon, StarIcon, TypeIcon } from 'components/Icon/Svg/NetflixCarousel';
import Clock from 'components/Icon/SvgCss/PublicCoursePage/Clock.js';
import Globe from 'components/Icon/SvgCss/PublicCoursePage/Globe.js';

// Others
import { sharedMessages } from 'services/i18n/sharedMessages/messages';
// import { messages } from 'routes/CourseDetails/BuyCourse/messages.js';
import {messages} from 'components/NetflixCarousel/messages.js';
import { isNull } from 'utils/helpers';
import { LECTURE_QUIZ } from 'containers/UploadManagerProvider/helpers';

// Styles
import './DescriptionBlock.scss';

const PREFIX = "sk-toppings-carousel_content__area";

const DescriptionBlock = ({
  topping,
  intl: { formatMessage },
}) => {
  const {
      title,
      description,
      variants,
      duration,
      language,
      attachment,
      ratingTotal,
      id,
      lectures,
  } = topping;

  const hasQuiz = lectures && lectures?.length && lectures[0]?.materials?.some(material => material.type === LECTURE_QUIZ) || false;
  const isReadMore = description?.match(/\S+/g)?.length > 35 || false;

  return(
    <div className={`${PREFIX}__container`}>
      <div className={`${PREFIX}__title`}>{title}</div>
      <div className={`${PREFIX}__features`}>
        <div className={`${PREFIX}_feature type`}>
          <TypeIcon width={23} height={23} />
          <Text>{formatMessage(messages[variants[0].name])}</Text>
        </div>
        <div className={`${PREFIX}_feature duration`}>
          <ClockIcon />
          <Duration duration={duration} />
        </div>
        <div className={`${PREFIX}_feature language`}>
          <LanguageIcon width={23} height={23} />
          <Text>{formatMessage(sharedMessages[language.title])}</Text>
        </div>
        {hasQuiz && (
          <div className={`${PREFIX}_feature quiz`}>
            <QuizIcon />
            <Text>{formatMessage(messages.quiz)}</Text>
          </div>
        )}
        {!isNull(ratingTotal) && (<div className={`${PREFIX}_feature rate`}>
          <StarIcon active={true} />
          <Text>{`${ratingTotal.averageScore} (${ratingTotal.totalVotes})`}</Text>
        </div>)}
      </div>
      <div className={`${PREFIX}__description`}>
        {isReadMore ? (
          <ReadMore lines={4} withToggle textStyles={{overflowY: "scroll", whiteSpace: 'pre-line'}} >
            {description}
          </ReadMore>
        ): (
          <div style={{overflowY: "scroll", whiteSpace: 'pre-line'}}>
            {description}
          </div>
        )}
      </div>
    </div>
  );
};


const enhancer = compose(
  injectIntl
);

export default enhancer(DescriptionBlock);
