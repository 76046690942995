// Core
import React, { memo, useCallback } from 'react';
import { compose, graphql } from 'react-apollo';
import { withFormik } from 'formik';
import { injectIntl } from 'react-intl';

// Components
import TextInput from 'components/Input/Material/TextInput/TextInput';
import Text from 'components/Text';
import Checkbox from 'components/Checkbox';
import Button from 'components/Button';
import Loader from 'components/Loader';

// GraphQL
import LIST_REASONS from 'queries/Courses/listReasons.gql';

// Other
import { isNull, keysToCamel, sortLectures } from  'utils/helpers/index';
import { withLanguage } from 'containers/LanguageProvider/withLanguage';
import { messages } from 'routes/ExpertsCabinet/CourseEdit/GeneralInfo/messages';

// Styles and Assets
import './CourseDeletionSurvey.scss';

const CourseDeletionSurvey = ({
  values,
  touched,
  isValid,
  errors,
  setFieldTouched,
  setFieldValue,
  handleChange,
  handleBlur,
  handleDelete,
  intl: { formatMessage },
  reasons,
  hideModal
}) => {
  const handleInputChange = fieldName => inputValue => {
    setFieldValue(fieldName, inputValue);
    setFieldTouched(fieldName);
  };

  const handleSubmit = useCallback(async () => {
    const parsedReasons = Object.keys(values)
      .map(id => ({
        survey_option_id: id,
        message: values[id] === true ? 'none' : values[id],
      }))
      .filter(item => item.message !== false);
    hideModal();
    await handleDelete({ reasons: parsedReasons });
  }, [values, handleDelete, hideModal]);

  return (
    <div className="sk-why-delete-course">
      <Text className="sk-why-delete-course__header">
        {formatMessage(messages.youAreAbout)}
      </Text>
      <Text className="sk-why-delete-course__sub-header">
        {formatMessage(messages.tellUsWhy)}
      </Text>
      {Object.keys(values).map(reasonId => {
        const inputValue = values[reasonId] === true ? '' : values[reasonId];
        const isChecked = !!values[reasonId] || values[reasonId] === '';

        return (
          <div className="sk-why-delete-course__checkbox-group">
            <Checkbox
              name={reasonId}
              label={reasons[reasonId].title}
              checked={isChecked}
              handleChange={handleInputChange(reasonId)}
            />
            {isChecked && !isNull(reasons[reasonId].placeholder) && (
              <TextInput
                name={reasonId}
                placeholder={reasons[reasonId].placeholder}
                value={inputValue}
                onChange={handleInputChange(reasonId)}
              />
            )}
          </div>
        );
      })}
      <Button className="sk-why-delete-course__button" onClick={handleSubmit}>
        {formatMessage(messages.submitDelete)}
      </Button>
    </div>
  );
};

const listReasons = graphql(LIST_REASONS, {
  props: ({
    data: {
      listDeletionReasons = { items: [] },
      error,
      loading,
      refetch,
      ...ownProps
    },
    ownProps: {
      languageContext: { language },
    },
  }) => {
    if (error || isNull(listDeletionReasons)) throw error;
    if (loading) return <Loader />;

    const reasons = {};
    const sortedReasonsList = sortLectures(keysToCamel(listDeletionReasons.items));
    sortedReasonsList.forEach(reason => {
      const translation = reason.translations.find(
        translation => translation.locale === language
      );
      reasons[reason.id] = translation;
    });

    return {
      reasons,
      refetchReasons: refetch,
      loading,
      error,
    };
  },
});

const formikStateManagement = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ reasons }) => {
    const initialValues = {};
    if (reasons) {
      Object.keys(reasons).forEach(reason => {
        initialValues[reason] = false;
      });
    }
    return initialValues;
  },
  displayName: 'WhyRemoveCourseModalForm',
  isInitialValid: true,
});

const enhancer = compose(
  memo,
  injectIntl,
  withLanguage,
  listReasons,
  formikStateManagement
);

export default enhancer(CourseDeletionSurvey);
