import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  buyNow: {
    id: 'app.components.masterClassCarousel.buyNow',
    defaultMessage: 'Buy now',
  },
  joinButton: {
    id: 'app.components.masterClassCarousel.joinButton',
    defaultMessage: 'Join'
  },
  learnMore: {
    id: 'app.components.masterClassCarousel.learnMore',
    defaultMessage: 'Learn More'
  },
  masterclass: {
    id: 'app.components.masterClassCarousel.masterclass',
    defaultMessage: 'Masterclass'
  },
  lecture: {
    id: 'app.components.masterClassCarousel.lecture',
    defaultMessage: 'Lecture'
  },
  podcast: {
    id: 'app.components.masterClassCarousel.podcast',
    defaultMessage: 'Podcast'
  },
  tabsGeneral: {
    id: 'app.components.masterClassCarousel.tabsGeneral',
    defaultMessage: 'General information'
  },
  tabsAuthor: {
    id: 'app.components.masterClassCarousel.tabsAuthor',
    defaultMessage: 'About expert'
  },
  quiz: {
    id: 'app.components.masterClassCarousel.quiz',
    defaultMessage: 'Quiz'
  },
  students: {
    id: 'app.components.masterClassCarousel.students',
    defaultMessage: 'students'
  },
  author: {
    id: 'app.components.Author.author',
    defaultMessage: 'Expert',
  },
});
