const DefaltIntl = {
  formatMessage: () => 'not error',
}

class IntlSingleton {
  instance;

  constructor() {
    if (this.instance) return this.instance;
    this.instance = DefaltIntl;
  }

  get() {
    return this.instance;
  }

  set(intl) {
    this.instance = intl;
  }
}

const Intl = new IntlSingleton();
export default Intl;
