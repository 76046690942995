// Core
import React, { memo } from 'react';
import { injectIntl } from 'react-intl';
import { compose } from 'react-apollo';

// Components
// import Button from 'components/Button';
import Text from 'components/Text';
import Cross from 'components/Icon/Svg/Cross';
import CloseButton from 'components/Modal/CloseButton';
import ModalArrow from 'components/Icon/SvgCss/Editor/ModalArrow';

import { messages } from './messages';

// Styles and Assets
import './index.scss';

const NewCourseNotification = ({ intl: { formatMessage } }) => (
  <div className="sk-shopping-cart-notification">
    <Text>{formatMessage(messages.newCourseNotification)}</Text>
    <ModalArrow color='#30d5c8' />
  </div>
);

const enhancer = compose(
  memo,
  injectIntl
)

export default enhancer(NewCourseNotification);
