// Core
import React, { memo } from 'react';
import { withRouter } from 'react-router-dom';
import { withFormik } from 'formik';
import { compose } from 'react-apollo';
import { FormattedMessage, injectIntl } from 'react-intl';
import Auth from '@aws-amplify/auth';
import moment from 'moment-timezone';

// Components
import TextInput from 'components/Input/Material/TextInput/TextInput';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import Text from 'components/Text';
import Link from 'components/Link';
import CheckboxLabel from 'components/CheckboxLabel';

// Other
import { getYupSchema } from 'services/yup';
import { messages } from 'routes/Authenticator/SignUpForm/messages';
import { sharedMessages } from 'services/i18n/sharedMessages/messages';

import './index.scss';

const INITIAL_FIELDS = {
  email: '',
  firstName: '',
  lastName: '',
};

const userTimezone = moment.tz.guess();

const SignUpAdditionalInfoForm = ({
  cognitoUser,
  values,
  values: { email, firstName, lastName },
  errors,
  touched,
  initialErrors,
  isLoading,
  intl: { formatMessage },
  setFieldValue,
  handleBlur,
  handleChange,
  handleRedirectAfterSignIn,
}) => {
  const handleInputChange = name => (inputValue, e) => {
    setFieldValue(name, inputValue);
    if (e) e.persist();
  };

  const handleInputBlur = name => (inputValue, e) => {
    if (e) {
      e.persist();
      handleBlur(e);
    }
    setFieldValue(name, inputValue);
  };

  const updateCognitoUserAttributes = async () => {
    try {
      const updateCognitoUser = await Auth.updateUserAttributes(cognitoUser, {
        'custom:role': 'student',
        'custom:future_role': 'student',
        'custom:first_name': firstName,
        'custom:last_name': lastName,
        'custom:country': '',
        'custom:city': '',
        'custom:timezone': userTimezone,
        'custom:interface_language': 'en',
        'custom:interface_currency': 'usd',
      });
      handleRedirectAfterSignIn();
    } catch (error) {
      console.log('error', error);
      throw error;
    };
  };

  const handleEnterPress = (params1, params2) => {
    const charCode = params1?.charCode || params2?.charCode;
    if (charCode == 13) {
      updateCognitoUserAttributes();
    };
  };

  return (
    <div className="sk-social-signup-form">
      <div className="sk-form__inner sk-auth-content_inner">
        <div className="sk-form__row">
          <TextInput
            name="firstName"
            helperText={touched.firstName ? errors.firstName : ''}
            error={touched.firstName && Boolean(errors.firstName)}
            value={firstName}
            label={formatMessage(messages.firstName)}
            placeholder={formatMessage(messages.firstName)}
            onBlur={handleInputBlur('firstName')}
            onChange={handleChange('firstName')}
            onKeyPress={handleEnterPress}
          />
          <TextInput
            name="lastName"
            helperText={touched.lastName ? errors.lastName : ''}
            error={touched.lastName && Boolean(errors.lastName)}
            value={lastName}
            label={formatMessage(messages.lastName)}
            placeholder={formatMessage(messages.lastName)}
            onBlur={handleInputBlur('lastName')}
            onChange={handleChange('lastName')}
            onKeyPress={handleEnterPress}
          />
        </div>
        <div className="sk-form__row">
          <TextInput
            name="email"
            helperText={touched.email ? errors.email : ''}
            error={touched.email && Boolean(errors.email)}
            value={email}
            label={formatMessage(messages.email)}
            placeholder={formatMessage(messages.email)}
            onBlur={handleInputBlur('email')}
            onChange={handleChange('email')}
            onKeyPress={handleEnterPress}
          />
        </div>
      </div>

      <div className="sk-form_footer sk-auth-content_footer">
        <div className="sk-auth-content_terms">
          <Text classes="sk-auth-content_text">
            <FormattedMessage {...messages.bySigningUp} />
            <span className="sk-auth-content_links">
              <Link
                target="_blank"
                to="/terms-of-use"
                classes="sk-auth-content_link"
              >
                <FormattedMessage {...messages.termsOfUse} />
              </Link>{' '}
              <FormattedMessage {...messages.and} />{' '}
              <Link
                target="_blank"
                to="/privacy-policy"
                classes="sk-auth-content_link"
              >
                <FormattedMessage {...messages.privacyPolicy} />
              </Link>
            </span>
          </Text>
        </div>
        <Button
          variant="text"
          color="secondary"
          size="large"
          type="submit"
          loading={isLoading}
          onClick={updateCognitoUserAttributes}
        >
          <FormattedMessage {...messages.createAcc} />
        </Button>
      </div>
    </div>
  );
};

const formikStateManagement = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ externalProfileData }) => {
    return {
      ...INITIAL_FIELDS,
      ...externalProfileData,
    }
  },
  displayName: 'SignUpAdditionalInfoForm',
  validationSchema: () => getYupSchema('socialLoginValidationSchema'),
  validateOnChange: true,
  validateOnBlur: true,
});

const enhancer = compose(memo, injectIntl, withRouter, formikStateManagement);

export default enhancer(SignUpAdditionalInfoForm);
