
// Core
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { graphql, compose } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { withFormik } from 'formik';
import { Droppable, Draggable } from 'react-beautiful-dnd';

// Components
import QuizAnswer from 'routes/ExpertsCabinet/QuizEdit/QuizAnswer';
import Card from 'components/Card';
import IconWithModal from 'components/IconWithModal';
import ConfirmDelete from 'routes/ExpertsCabinet/components/ConfirmDelete/ConfirmDelete';
import TextInput from 'components/Input/Material/TextInput/TextInput';
import SimpleSelect from 'components/Select/SimpleSelect';
import AddEntityButton from 'routes/Course/components/AddEntityButton';
import IconGroup from 'routes/ExpertsCabinet/QuizEdit/Components/IconGroup';
import UploadedImage from 'components/UploadedImage';

// GraphQL
import CREATE_QUIZ_ANSWER from 'mutations/Courses/quiz/createQuizAnswer.gql';
import DELETE_QUIZ_QUESTION from 'mutations/Courses/quiz/deleteQuizQuestion.gql';
import UPDATE_QUIZ_QUESTION from 'mutations/Courses/quiz/updateQuizQuestion.gql';

// Other
import { withFormattedUntitled } from 'routes/ExpertsCabinet/QuizEdit/HOCs/withFormattedUntitled.js';
import { withNotification } from 'containers/NotificationProvider/withNotification';
import { keysToSnake, isBlank, isEmptyArray, compareSortNumber, generateDraggableId } from 'utils/helpers';
import { getYupSchema, } from 'services/yup';
import { withModal } from 'containers/ModalProvider/withModal';
import { messages } from 'routes/ExpertsCabinet/QuizEdit/messages';
import { DEFAULT_MAX_FILE_SIZE, SUPPORTED_FORMATS_IMAGE } from 'utils/enums/index';
import * as materialUISelectInlineStyles from './Selector.style';
import { withQuizImageUpload } from 'routes/ExpertsCabinet/QuizEdit/HOCs/withQuizImageUpload';

// Styles and Assets
import './index.scss';

const INITIAL_FORM_VALUES = {
  sortNumber: 0,
  title: '',
  multiselect: 'false',
  explanation: ''
};

const QuizQuestion = ({
  questionsCount,
  isValid,
  values,
  errors,
  touched,
  handleChange,
  setFieldTouched,
  setFieldValue,
  validateForm,
  deleteQuizQuestion,
  createQuizAnswer,
  handleUploadQuestionImage,
  handleImageDelete,
  refetchQuiz,
  updateQuizQuestion,
  updateQuizAnswer,
  showNotificationBar,
  intl: { formatMessage },
}) => {
  const multiselectOptions = useMemo(() => [
    { value: 'true', label: formatMessage(messages.severalRightAnswer) },
    { value: 'false', label: formatMessage(messages.oneRightAnswer) },
  ], []);
  const uploadImage = (id) => (file) => handleUploadQuestionImage({
    id,
    file,
    quizId: values.quizId,
    refId: values?.id,
  });
  const handleQuestionDelete = async () => {
    if (questionsCount > 1) {
      await deleteQuizQuestion(values?.id);
    };
  }
  const handleAnswerAdd = async () => {
    const input = {
      question_id: values?.id,
      sort_number: values.answers.length + 1,
    }
    await createQuizAnswer(input);
  };

  const updateQuestionMultiSelectType = async (inputValue) => {
    const input = {
      id: values.id,
      quiz_id: values.quizId,
      multiselect: inputValue
    };
    await updateQuizQuestion(input);
  }

  const handleInputBlur = useCallback(fieldName => async (inputValue, e) => {
    setFieldTouched(fieldName);
    setFieldValue(fieldName, inputValue, false);

    const errors = await validateForm({ ...values, [fieldName]: inputValue });
    const isValid = isBlank(errors[fieldName]);

    const isDefaultTitle = fieldName === 'title'
      && formatMessage(messages.quizQuestionUntitledTitle) === inputValue.replace(/\s+/g, '');

    if (isValid && !isDefaultTitle) {
      const input = {
        id: values.id,
        quiz_id: values.quizId,
        [fieldName]: inputValue
      };
      await updateQuizQuestion(input);
      showNotificationBar('saved');
    };
  }, [values]);

  const sortedAnswers = !isBlank(values?.answers) ? values?.answers.sort(compareSortNumber) : [];
  const sortNumberLabel = values.sortNumber > 0 ? values.sortNumber : 1;

  return (
      <Card classes='sk-quiz-question'>
        <IconGroup
          uploaderId={`quiz-question-image-${values?.id}`}
          handleImageUpload={uploadImage()}
          handleDelete={handleQuestionDelete}
          withRound={true}
        />
        <div className='sk-quiz-question__general-info'>
          <label>{sortNumberLabel}.</label>
          <TextInput
            name="title"
            value={values.title}
            onBlur={handleInputBlur('title')}
            label={formatMessage(messages.quizInfoUntitledTitle)}
            placeholder={formatMessage(messages.questionTitlePlaceholder)}
            error={touched.title && Boolean(errors.title)}
            helperText={touched.title ? errors.title : ''}
          />
          <SimpleSelect
            helperText={touched.multiselect ? errors.multiselect : ''}
            error={touched.multiselect && Boolean(errors.multiselect)}
            name='multiselect'
            inlineStyles={materialUISelectInlineStyles}
            value={values.multiselect}
            valueKey="value"
            labelKey="label"
            options={multiselectOptions}
            handleChange={updateQuestionMultiSelectType}
          />
        </div>
        {(!isBlank(values?.materials) && !isEmptyArray(values?.materials)) && values?.materials.map(image => {
          return (
            <UploadedImage
              uploaderId={`quiz-question-image-reupload-${values?.id}`}
              fileKey='image'
              image={image}
              handleImageUpload={uploadImage(image?.id)}
              handleImageDelete={handleImageDelete}
            />
          )
        })}
        <div className='sk-quiz-question__answers-group'>
          <Droppable droppableId={values.id} type='answer'>
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {sortedAnswers.map((answer, index) => {
                  const { questionId, id: answerId } = answer;
                  return (
                    <Draggable
                      type='answer'
                      key={answer.id}
                      draggableId={generateDraggableId({ questionId, answerId, type: 'answer' })}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <QuizAnswer
                            key={answerId}
                            answersCount={sortedAnswers.length}
                            quizId={values?.quizId}
                            answer={answer}
                            multiselect={values?.multiselect}
                            refetchQuiz={refetchQuiz}
                          />
                        </div>
                      )}
                    </Draggable>
                  )
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <AddEntityButton
            label={formatMessage(messages.addAnswer)}
            handleAddClick={handleAnswerAdd}
          />
        </div>
        <TextInput
          name="explanation"
          value={values.explanation}
          onBlur={handleInputBlur('explanation')}
          label={formatMessage(messages.explanationToAnswer)}
          placeholder={formatMessage(messages.explanationToAnswer)}
          error={touched.explanation && Boolean(errors.explanation)}
          helperText={touched.explanation ? errors.explanation : ''}
        />
      </Card>
  )
};

const deleteQuizQuestionMutation = graphql(DELETE_QUIZ_QUESTION, {
  props: ({ mutate }) => ({
    deleteQuizQuestion: id => mutate({ variables: { id } }),
  }),
  options: () => ({
    refetchQueries: ['getQuiz'],
  }),
});

const createQuizAnswerMutation = graphql(CREATE_QUIZ_ANSWER, {
  props: ({ mutate }) => ({
    createQuizAnswer: input => mutate({ variables: { input } }),
  }),
  options: () => ({
    refetchQueries: ['getQuiz'],
  }),
});

const updateQuizQuestionMutation = graphql(UPDATE_QUIZ_QUESTION, {
  props: ({ mutate }) => ({
    updateQuizQuestion: input => {
      mutate({ variables: { input: keysToSnake(input) }});
    },
  }),
  options: () => ({
    refetchQueries: ['getQuiz'],
  }),
});

const formikStateManagement = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ question = {}, formatUntitled, }) => {
    question.title = formatUntitled(question.title, messages.quizQuestionUntitledTitle);
    return { ...INITIAL_FORM_VALUES, ...question };
  },
  displayName: 'QuizQuestionForm',
  validationSchema: () => getYupSchema('quizQuestionEditValidationSchema'),
  validateOnChange: false,
  validateOnBlur: false
});

const enhancer = compose(
  withFormattedUntitled,
  injectIntl,
  formikStateManagement,
  deleteQuizQuestionMutation,
  createQuizAnswerMutation,
  updateQuizQuestionMutation,
  withQuizImageUpload,
  withNotification
);

export default enhancer(QuizQuestion);
