import React from 'react';
import { NetflixLeft } from '../../../../components/Icon/Svg/NetflixLeft';
import { NetflixRight } from '../../../../components/Icon/Svg/NetflixRight';
import './NetflixSwipeButton.scss'

export const NetflixSwipeButton = ({ direction, handleClick }) => {
  return (
    <div className="netflix-swipe-btn" onClick={handleClick}>
      {direction === 'prev' ? <NetflixLeft /> : <NetflixRight />}
    </div>
  );
};
