import React from 'react';
import PropTypes from 'prop-types';
import { PurpleHeart, White } from 'components/Icon/color';

const ArrowPointerDown = ({ height, width, color, active, activeColor }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 10 10"
  >
    <g
      fill="none"
      fillRule="nonzero"
      stroke={active ? activeColor : color}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M1 6l4 4 4-4M5 9V.895" />
    </g>
  </svg>
);

ArrowPointerDown.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  color: PropTypes.string,
  active: PropTypes.bool,
  activeColor: PropTypes.string,
};

ArrowPointerDown.defaultProps = {
  height: '10',
  width: '10',
  color: PurpleHeart,
  activeColor: White,
};

export default ArrowPointerDown;
