// Core
import React, { memo } from 'react';
import memoize from 'memoize-one';

// Components
import ExpansionPanel from 'components/ExpansionPanel';
import HWSummaryComponent from 'routes/ExpertsCabinet/components/HWSummaryComponent';
import HWDetailsComponent from 'routes/ExpertsCabinet/components/HWDetailsComponent';

// Styles and Assets
import './index.scss';

// Other
import { parseFetchedData, getAverageValue } from 'utils/helpers';

const overrideClasses = {
  panel: {
    root: 'mui-override sk-hw-table-row__panel',
    expanded: 'mui-override sk-hw-table-row__panel_expanded'
  },
  summary: {
    root: 'mui-override sk-hw-table-row__summary',
    content: 'mui-override sk-hw-table-row__summary-content'
  },
  details: {
    root: 'mui-override sk-hw-table-row__details'
  },
  iconBtn: {
    root: 'mui-override sk-hw-table-row__btn'
  }
};

const getHWCompletionStatus = (lectures, courseLectures) => {
  return lectures
    .map((lec) => lec?.step != 3 || !courseLectures.some((y) => y.id == lec.lectureId)) //Magic number that means that expert approved homework
    .every((i) => !!i);
};
const getAverageMark = memoize((lectures, keys) => getAverageValue(lectures, keys));

const HomeworkTableRow = ({ item, refetchStudentList, courseLectures, displayLectureNameType, ...props }) => {
  const { mappingId, userId, courseId, userData, homeworksData: lectures } = parseFetchedData(item);

  const hasCourseLectures = lectures && lectures !== null && lectures.length > 0;
  const averageMark = hasCourseLectures ? getAverageMark(lectures, ['review', 'mark']) : 0;

  // if (!hasCourseLectures) {
  //   return null;
  //   // return (<div />);
  // }

  return (
    <ExpansionPanel
      id={mappingId}
      ExpansionPanelSummaryComponent={
        <HWSummaryComponent
          name={`${userData?.firstName} ${userData?.lastName}`}
          averageMark={averageMark}
          allHomeworksChecked={!hasCourseLectures || getHWCompletionStatus(lectures, courseLectures)}
        />
      }
      ExpansionDetailsComponent={
        <HWDetailsComponent
          courseLectures={courseLectures}
          lectures={lectures}
          studentId={userId}
          courseId={courseId}
          mappingId={mappingId}
          refetchStudentList={refetchStudentList}
          displayLectureNameType={displayLectureNameType}
        />
      }
      panelClasses={overrideClasses.panel}
      panelSummaryClasses={overrideClasses.summary}
      panelDetailsClasses={overrideClasses.details}
      iconButtonClasses={overrideClasses.iconBtn}
    />
  );
};

export default HomeworkTableRow;
