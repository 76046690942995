// Core
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import MaterialStepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepLabel from '@material-ui/core/StepLabel';
import StepIcon from '@material-ui/core/StepIcon';
import StepConnector from '@material-ui/core/StepConnector';

// Components
import StepperIcon from 'components/Stepper/StepperIcon';

// Other
import { isObject } from 'utils/helpers/index';

// Styles and Assets
import './Stepper.scss';

const classes = {
  button: {
    root: 'mui-override sk-stepper__btn',
  },
  stepper: {
    root: 'mui-override sk-stepper',
  },
  step: {
    root: 'mui-override sk-stepper__step',
  },
  label: {
    root: 'mui-override sk-stepper__label',
    iconContainer: 'mui-override sk-stepper__iconContainer',
  },
  connector: {
    root: 'mui-override sk-stepper__connector',
    lineHorizontal: 'mui-override sk-stepper__connector',
  },
};

const SimpleStep = ({ id, disabled, onClickHandler, isActiveStep }) => (
  <Step
    classes={classes.step}
    key={id}
    disabled={disabled}
    onClick={onClickHandler}
  >
    <StepButton classes={classes.button}>
      <StepLabel
        classes={classes.label}
        StepIconComponent={() => (
          <StepperIcon id={id} active={isActiveStep} />
        )}
      />
    </StepButton>
  </Step>
)

const StepWithCustomLabel = ({ step: { id, icon: Icon, background, color }, disabled, onClickHandler, isActiveStep }) => (
  <Step
    classes={classes.step}
    key={id}
    disabled={disabled}
    onClick={onClickHandler}
  >
    <StepButton classes={classes.button}>
      <StepLabel
        classes={classes.label}
        StepIconComponent={() => (<StepperIcon style={{ background, color }} id={Icon} active={isActiveStep} />)}
      />
    </StepButton>
  </Step>
)

const Stepper = ({ steps, activeStep, handleStepChange, disabled }) => (
  <MaterialStepper
    connector={<StepConnector classes={classes.connector} />}
    classes={classes.stepper}
    nonLinear
    activeStep={activeStep}
  >
    {steps.map((step, index) => {
      const isSimpleStep = !isObject(step);
      return isSimpleStep
        ? (<SimpleStep
            key={step}
            id={step}
            disabled={disabled}
            isActiveStep={activeStep === step && !disabled}
            onClickHandler={handleStepChange(index + 1)}
          />)
        : (<StepWithCustomLabel
            key={step}
            step={step}
            isActiveStep={activeStep === step.id && !disabled}
          />);
    })}
  </MaterialStepper>
);

Stepper.defaultProps = {
  handleStepChange: () => {},
  steps: [0],
  activeStep: 0,
};

Stepper.propTypes = {
  steps: PropTypes.array,
  handleStepChange: PropTypes.func,
  activeStep: PropTypes.number,
  disabled: PropTypes.bool,
};

export default memo(Stepper);
