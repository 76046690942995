import React, { memo } from 'react';
import PropTypes from 'prop-types';

// Components
import TextInput from '../TextInput';

const TextAreaInput = memo(({ rows, rowsMax, ...rest }) => (
  <TextInput multiline {...rest} rows={rows} rowsMax={rowsMax} />
));

TextAreaInput.defaultProps = {
  type: 'text',
  rows: 1,
  rowsMax: 10,
};

TextAreaInput.propTypes = {
  type: PropTypes.string,
  rowsMax: PropTypes.number,
  rows: PropTypes.number,
};

export default TextAreaInput;
