import React from 'react';
import PropTypes from 'prop-types';
import { PurpleHeart, Grey } from 'components/Icon/color';

const Calendar = ({ height = '18', width = '22', active = false, color = Grey }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 22 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.3158 1V3.82353M14.1579 1V3.82353M11 1V3.82353M7.84211 1V3.82353M4.68421 1V3.82353M21 10.2734V3.35294C21 2.83341 20.5284 2.41176 19.9474 2.41176H2.05263C1.47158 2.41176 1 2.83341 1 3.35294V16.0588C1 16.5784 1.47158 17 2.05263 17H4.27H13.6471L18.7382 16.9904C19.9881 16.9881 21 17.0437 21 15.952V10.2734Z"
      stroke={active ? PurpleHeart : color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M21 6.64697H1" stroke={active ? PurpleHeart : color} />
  </svg>
);

Calendar.propTypes = {
  active: PropTypes.bool,
  height: PropTypes.string,
  width: PropTypes.string,
  color: PropTypes.string,
};

export default Calendar;
