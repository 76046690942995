import React, { PureComponent, Fragment } from 'react';

const withAction = (WrappedComponent, withoutWrapper) => {
  class HOC extends PureComponent {
    state = {
      action: false,
    };

    handleHover = status => {
      this.setState(() => ({ action: status }));
    };

    render() {
      const { action } = this.state;

      return (
        <Fragment>
          {withoutWrapper ? (
            <WrappedComponent
              {...this.props}
              action={action}
              onMouseLeave={() => this.handleHover(false)}
              onMouseEnter={() => this.handleHover(true)}
            />
          ) : (
            <div
              onMouseLeave={() => this.handleHover(false)}
              onMouseEnter={() => this.handleHover(true)}
            >
              <WrappedComponent {...this.props} action={action} />
            </div>
          )}
        </Fragment>
      );
    }
  }

  return HOC;
};

export default withAction;
