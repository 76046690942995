// Core
import React, { useCallback } from "react";
import { withResizeDetector } from "react-resize-detector";
import { graphql, compose } from "react-apollo";
import { keysToCamel } from "utils/helpers";
import Button from "components/Button";

// Components
import CourseCard from "components/CourseCard";
import Heading from "components/Heading";
import Loader from "components/Loader";

// GraphQl
import LIST_COURSES_QUERY from "queries/Courses/listCourses.gql";

// Styles
import "./LandingPageAllCourses.scss";
import { withRouter } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { messages } from "../messages";

// add fake courses to fill the row
const normalizeInRow = (items, width) => {
  const lg = width > 1366;
  const md = width > 1025;
  const sm = width < 768;

  const perRow = lg ? 4 : md ? 3 : !sm ? 2 : 1;
  const missed = perRow - (items.length % perRow);
  const addition = Array(perRow > missed ? missed : 0).fill(null);

  const placeholder = items[items.length - 1];

  const result = [
    ...items,
    ...addition.map(() => ({
      ...placeholder,
      className: "sk-course-card--invisibleAdjunct",
    })),
  ];

  return result;
};

const LandingPageAllCourses = ({
  listCourses,
  title,
  history,
  alreadyBoughtedCourses,
  width,
  org
}) => {
  const courses = normalizeInRow(listCourses ? listCourses.courses : [], width);
  // const validateCoursePurchase = useCallback(
  //   id => {
  //     if (alreadyBoughtedCourses.length) {
  //       return alreadyBoughtedCourses.includes(id);
  //     }
  //     return false;
  //   },
  //   [alreadyBoughtedCourses]
  // );

  // Intercept and hide empty results
  if (listCourses?.total === 0) {
    return null
  }

  return (
    <div className="sk-landing-page__courses all">
      <div className="sk-landing-page__courses_all">
        <Heading
          variant="h3"
          classes="sk-lp-category-item__heading grid landing"
        >
          {title}
        </Heading>
        {courses?.length ? (
          <>
            <div className="sk-landing-page__courses_list">
              {courses.map((course, index) => (
                <CourseCard key={course.id} course={course} position={index} />
              ))}
            </div>
            <div className="sk-landing-page__courses_all_button-container">
              <Button
                color="secondary"
                variant="text"
                onClick={() => history.push("/courses")}
              >
                <FormattedMessage {...messages.courseCatalog} />
              </Button>
            </div>
          </>
        ) : (
          <div className="sk-landing-page__courses_loader-wrapper">
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
};

const listCoursesQuery = graphql(LIST_COURSES_QUERY,  {
  props: ({ data: { error, loading, listCourses, ...ownProps } }) => {
    if (error) throw Error(error);
    if (loading) return { loading, error };

    return {
      loading,
      listCourses,
      error,
      ...ownProps,
    };
  },
  options: () => ({
    fetchPolicy: "cache-and-network",
    variables: {
      input: {
        complexity: "",
        duration_range: "",
        interaction_level: "",
        language: "",
        search_string: "",
        subcategory: "",
        from: 0,
        size: 16,
        type: "course",
      },
    },
  }),
});

const enhancer = compose(listCoursesQuery, withRouter, withResizeDetector);

export default enhancer(LandingPageAllCourses);
