import React from 'react';

const FullScreen = ({ isFullscreen }) => (
  <svg className="sk-editor-fullScreen-icon" width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    { !isFullscreen ? (
      <g>
        <path
          d="M20.3838 7.92578L6.7959 21.5136"
          stroke="#979797"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.0624 3.69268C24.2111 3.65283 24.3472 3.78891 24.3073 3.93763L22.2024 11.7933C22.1626 11.942 21.9767 11.9918 21.8678 11.8829L16.117 6.13219C16.0082 6.02333 16.058 5.83744 16.2067 5.79759L24.0624 3.69268Z"
          fill="#979797"
        />
        <path
          d="M3.93762 24.3073C3.78891 24.3472 3.65282 24.2111 3.69267 24.0624L5.79759 16.2067C5.83744 16.058 6.02333 16.0082 6.1322 16.1171L11.883 21.8678C11.9918 21.9767 11.942 22.1626 11.7933 22.2024L3.93762 24.3073Z"
          fill="#979797"
        />
      </g>
    ) : (
      <g>
        <path
          d="M15.047 13.6159C14.8983 13.6558 14.7622 13.5197 14.802 13.371L16.907 5.51533C16.9468 5.36661 17.1327 5.3168 17.2416 5.42567L22.9923 11.1764C23.1012 11.2853 23.0514 11.4712 22.9027 11.511L15.047 13.6159Z"
          fill="#979797"
        />
        <path
          d="M25.2399 4.02679C25.4352 3.83152 25.4352 3.51494 25.2399 3.31968C25.0446 3.12442 24.7281 3.12442 24.5328 3.31968L25.2399 4.02679ZM19.583 9.68363L25.2399 4.02679L24.5328 3.31968L18.8759 8.97653L19.583 9.68363Z"
          fill="#979797"
        />
        <path
          d="M13.3729 14.8021C13.5216 14.7622 13.6577 14.8983 13.6179 15.047L11.513 22.9026C11.4731 23.0514 11.2872 23.1012 11.1783 22.9923L5.4276 17.2416C5.31873 17.1327 5.36854 16.9468 5.51726 16.907L13.3729 14.8021Z"
          fill="#979797"
        />
        <path
          d="M3.18001 24.3912C2.98475 24.5864 2.98475 24.903 3.18001 25.0983C3.37527 25.2936 3.69186 25.2936 3.88712 25.0983L3.18001 24.3912ZM8.83688 18.7343L3.18001 24.3912L3.88712 25.0983L9.54398 19.4414L8.83688 18.7343Z"
          fill="#979797"
        />
      </g>
    ) }
  </svg>
);

export default FullScreen;
