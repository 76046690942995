import React from 'react';

import './index.scss';

const BlockquoteOnHover = () => (
  <svg
    className="hover"
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.69043" width="32" height="32" rx="2" fill="white" />
    <path
      d="M23.961 23.8593C23.961 24.1138 23.7547 24.3201 23.5002 24.3201H10.3418C10.0873 24.3201 9.88104 24.1138 9.88104 23.8593C9.88104 23.6049 10.0873 23.3985 10.3418 23.3985H23.5002C23.7547 23.3985 23.961 23.6049 23.961 23.8593ZM19.9948 20.2753C19.9948 20.5298 19.7885 20.7361 19.534 20.7361H10.3418C10.0874 20.7361 9.88104 20.5298 9.88104 20.2753C9.88104 20.0209 10.0873 19.8145 10.3418 19.8145H19.534C19.7885 19.8145 19.9948 20.0209 19.9948 20.2753ZM22.2754 16.6401C22.2754 16.8664 22.092 17.0497 21.8658 17.0497H10.2906C10.0644 17.0497 9.88104 16.8664 9.88104 16.6401C9.88104 16.4139 10.0644 16.2305 10.2906 16.2305H21.8658C22.092 16.2305 22.2754 16.4139 22.2754 16.6401ZM19.9948 13.0049C19.9948 13.2594 19.7885 13.4657 19.534 13.4657H10.3418C10.0874 13.4657 9.88104 13.2594 9.88104 13.0049C9.88104 12.7505 10.0873 12.5441 10.3418 12.5441H19.534C19.7885 12.5441 19.9948 12.7505 19.9948 13.0049ZM23.961 9.36974C23.961 9.59596 23.7777 9.77934 23.5514 9.77934H10.2906C10.0644 9.77934 9.88104 9.59596 9.88104 9.36974C9.88104 9.14353 10.0644 8.96014 10.2906 8.96014H23.5514C23.7777 8.96014 23.961 9.14353 23.961 9.36974Z"
      fill="#7145D8"
    />
  </svg>
);

const DefaultBlockquote = () => (
  <svg
    className="default"
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.961 23.8594C23.961 24.1138 23.7547 24.3202 23.5002 24.3202H10.3418C10.0873 24.3202 9.88104 24.1138 9.88104 23.8594C9.88104 23.6049 10.0873 23.3986 10.3418 23.3986H23.5002C23.7547 23.3986 23.961 23.6049 23.961 23.8594ZM19.9948 20.2754C19.9948 20.5298 19.7885 20.7362 19.534 20.7362H10.3418C10.0874 20.7362 9.88104 20.5298 9.88104 20.2754C9.88104 20.0209 10.0873 19.8146 10.3418 19.8146H19.534C19.7885 19.8146 19.9948 20.0209 19.9948 20.2754ZM22.2754 16.6402C22.2754 16.8664 22.092 17.0498 21.8658 17.0498H10.2906C10.0644 17.0498 9.88104 16.8664 9.88104 16.6402C9.88104 16.4139 10.0644 16.2306 10.2906 16.2306H21.8658C22.092 16.2306 22.2754 16.4139 22.2754 16.6402ZM19.9948 13.005C19.9948 13.2594 19.7885 13.4658 19.534 13.4658H10.3418C10.0874 13.4658 9.88104 13.2594 9.88104 13.005C9.88104 12.7505 10.0873 12.5442 10.3418 12.5442H19.534C19.7885 12.5442 19.9948 12.7505 19.9948 13.005ZM23.961 9.36975C23.961 9.59597 23.7777 9.77935 23.5514 9.77935H10.2906C10.0644 9.77935 9.88104 9.59597 9.88104 9.36975C9.88104 9.14354 10.0644 8.96015 10.2906 8.96015H23.5514C23.7777 8.96015 23.961 9.14354 23.961 9.36975Z"
      fill="#6D6E71"
    />
  </svg>
);

const ActiveBlockquote = () => (
  <svg
    className="active"
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.961 23.8593C23.961 24.1138 23.7547 24.3201 23.5002 24.3201H10.3418C10.0873 24.3201 9.88104 24.1138 9.88104 23.8593C9.88104 23.6049 10.0873 23.3985 10.3418 23.3985H23.5002C23.7547 23.3985 23.961 23.6049 23.961 23.8593ZM19.9948 20.2753C19.9948 20.5298 19.7885 20.7361 19.534 20.7361H10.3418C10.0874 20.7361 9.88104 20.5298 9.88104 20.2753C9.88104 20.0209 10.0873 19.8145 10.3418 19.8145H19.534C19.7885 19.8145 19.9948 20.0209 19.9948 20.2753ZM22.2754 16.6401C22.2754 16.8664 22.092 17.0497 21.8658 17.0497H10.2906C10.0644 17.0497 9.88104 16.8664 9.88104 16.6401C9.88104 16.4139 10.0644 16.2305 10.2906 16.2305H21.8658C22.092 16.2305 22.2754 16.4139 22.2754 16.6401ZM19.9948 13.0049C19.9948 13.2594 19.7885 13.4657 19.534 13.4657H10.3418C10.0874 13.4657 9.88104 13.2594 9.88104 13.0049C9.88104 12.7505 10.0873 12.5441 10.3418 12.5441H19.534C19.7885 12.5441 19.9948 12.7505 19.9948 13.0049ZM23.961 9.36974C23.961 9.59596 23.7777 9.77934 23.5514 9.77934H10.2906C10.0644 9.77934 9.88104 9.59596 9.88104 9.36974C9.88104 9.14353 10.0644 8.96014 10.2906 8.96014H23.5514C23.7777 8.96014 23.961 9.14353 23.961 9.36974Z"
      fill="#7145D8"
    />
  </svg>
);

const Blockquote = ({ active }) => {
  const Icon = active ? ActiveBlockquote : DefaultBlockquote;

  return (
    <div className="editor-icon-svg">
      <Icon />
      <BlockquoteOnHover />
    </div>
  );
};

export default Blockquote;
