// Core
import React, { memo } from 'react';
import PropTypes from 'prop-types';

// Components
import Button from 'components/Button';
import Text from 'components/Text';
import FileUploader from 'components/FileUploader';

// Styles and Assets
import './AnswerLessStep.scss';

// Other
import { SUPPORTED_FORMATS_HOMEWORK } from 'utils/enums';

import { FormattedMessage } from 'react-intl';
import { messages } from 'routes/StudentsCabinet/components/StudentHomework/messages';

const AnswerLessStep = ({ handleAnswerFilePick, disabled, lectureId }) => (
  <div className="sk-answerless-step">
    <div className="sk-answerless-step__header">
      <Text size="caption" classes="sk-answerless-step__text" newLine>
        <FormattedMessage {...messages.uploadHwForReview} />
      </Text>
    </div>
    <div className="sk-answerless-step__btn">
      <Button variant="text" color="secondary" size="small" disabled={disabled}>
        <div>
          <div className="sk-answerless-step__file">
            <FileUploader
              absolute
              supportedFormats={SUPPORTED_FORMATS_HOMEWORK}
              maxSize={500000}
              uploaderId={`sk-st-lec-${lectureId}-hw-answer-uploader`}
              handleChange={handleAnswerFilePick}
              disabled={disabled}
            />
          </div>
          <FormattedMessage {...messages.upload} />
        </div>
      </Button>
    </div>
  </div>
);

AnswerLessStep.propTypes = {
  handleAnswerFilePick: PropTypes.func,
  disabled: PropTypes.bool,
  lectureId: PropTypes.string,
};

export default memo(AnswerLessStep);
