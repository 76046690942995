// Core
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import { Formik } from 'formik';

// Components
import ContactUsForm from 'routes/ContactUs/ContactUsForm';
import Card from 'components/Card';
import Heading from 'components/Heading';
import MetaData from 'components/MetaData';
import Text from 'components/Text';

// GraphQL
import MY_PROFILE_QUERY from 'queries/UserProfile/getProfile.gql';
import NOTIFY_CONTACT_US from 'mutations/Mailing/notifyContactUs.gql';

// Other
import { getYupSchema } from 'services/yup';
import { withNotification } from 'containers/NotificationProvider/withNotification';
import { FormattedMessage } from 'react-intl';
import { messages } from 'routes/ContactUs/messages';

// Styles and Assets
import './ContactUs.scss';

const handleSubmit = async (
  { email, message },
  { formikBag, notifyContactUs, showNotificationBar, formFields }
) => {
  const { resetForm } = formikBag;
  try {
    await notifyContactUs({ email, message });
    showNotificationBar('sended');
    resetForm({ formFields });
  } catch (error) {
    throw Error(error);
  }
};

const ContactUs = ({ getProfile, notifyContactUs, showNotificationBar }) => {
  const formFields = {
    email: getProfile?.email || '',
    message: '',
  };

  return (
    <div className="sk-contact-us sk-main-layout-wrapper">
      <MetaData titleKey="contactUsTitle" />
      <div className="sk-contact-us__wrapper  sk-background-spot__secondary">
        <Card classes="sk-contact-us__card">
          <div className="sk-contact-us__content">
            <header className="sk-contact-us__header">
              <Heading classes="sk-contact-us__heading" variant="h2">
                <FormattedMessage {...messages.contactUs} />
              </Heading>
              <Text classes="sk-contact-us__subheading">
                <FormattedMessage {...messages.inCaseOfAnyQuestions} />
              </Text>
            </header>

            <div className="sk-contact-us__form">
              <Formik
                initialValues={formFields}
                validateOnChange={false}
                validateOnBlur
                onSubmit={(values, formikBag) => {
                  handleSubmit(values, {
                    formikBag,
                    notifyContactUs,
                    showNotificationBar,
                    formFields,
                  });
                }}
                render={props => <ContactUsForm {...props} />}
                validationSchema={getYupSchema('contactUsValidationSchema')}
              />
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

const myProfileQuery = graphql(MY_PROFILE_QUERY, {
  props: ({ data: { getProfile, error, loading, ...ownProps } }) => ({
    getProfile,
    error,
    ...ownProps,
  }),
  options: () => ({
    fetchPolicy: 'cache-only',
  }),
});

const notifyContactUsMutation = graphql(NOTIFY_CONTACT_US, {
  props: ({ mutate }) => ({
    notifyContactUs: input => mutate({ variables: { input } }),
  }),
});

const enhancer = compose(
  memo,
  myProfileQuery,
  notifyContactUsMutation,
  withNotification
);

ContactUs.propTypes = {
  getProfile: PropTypes.object,
  notifyContactUs: PropTypes.func,
  showNotificationBar: PropTypes.func,
};

export default enhancer(ContactUs);
