import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom'
import MaterialModal from '@material-ui/core/Modal';
import classNames from 'classnames';

// Components
import Card from 'components/Card';
import CloseButton from 'components/Modal/CloseButton';

import './Modal.scss';

const modalClasses = {
  root: 'mui-override sk-modal-overlay',
};

const Modal = (props) => {
  const {
    modalId,
    component: Component,
    open,
    hideModal,
    size,
    cardClasses,
    shrink,
    disabledDefaultCloseButton,
    history,
    ...rest
  } = props;

  const [_inited, setInited] = useState(false);

  useEffect(() => {
    setInited(inited => {
      if(inited){
        hideModal();
      }
      return true;
    })
  }, [history.location.key]);

  return (
    <div>
      <MaterialModal open={open} onClose={hideModal} classes={modalClasses} style={{ display: 'flex' }}>
        <Card
          tabIndex="-1"
          classes={classNames(
            'sk-modal-content',
            `sk-modal-content__${size}`,
            { 'sk-card__scrollable': shrink },
            { 'sk-card__shrink': shrink },
            {
              [cardClasses]: cardClasses,
            }
          )}
        >
          <CloseButton handleClick={() => hideModal(modalId)} disabled={disabledDefaultCloseButton} />
          <div id="sk-modal-content">
            {
              <Component
                modalId={modalId}
                hideModal={hideModal}
                {...rest}
              />
            }
          </div>
        </Card>
      </MaterialModal>
    </div>
  );
}

Modal.defaultProps = {
  size: 'md',
  shrink: true,
};

Modal.propTypes = {
  open: PropTypes.bool,
  shrink: PropTypes.bool,
  hideModal: PropTypes.func,
  component: PropTypes.any,
  size: PropTypes.oneOf(['md', 'lg', 'sm', 'xlg']),
};

export default withRouter(Modal);
