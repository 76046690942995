import React from 'react';
import PropTypes from 'prop-types';

const MenuCalendar = ({ height, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 19"
    width={width}
    height={height}
  >
    <g fill="none" fillRule="evenodd">
      <g transform="translate(0 1)">
        <circle
          cx="8.448"
          cy="9.483"
          r="8.448"
          fill="#FBC4BB"
          fillRule="nonzero"
        />
        <path
          stroke="#333"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M23.44 9.37V2.376a.94.94 0 0 0-.929-.95H6.721a.94.94 0 0 0-.928.95v12.837c0 
          .525.416.951.929.951H16.952l4.492-.01a2 2 0 0 0 1.996-2V9.37zM20.19 0v2.853M17.403 0v2.853M14.617 
          0v2.853M11.83 0v2.853M9.044 0v2.853"
        />
        <path stroke="#333" d="M23.44 5.705H5.793" />
      </g>
    </g>
  </svg>
);

MenuCalendar.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

MenuCalendar.defaultProps = {
  height: '19',
  width: '24',
};

export default MenuCalendar;
