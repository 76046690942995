// Core
import React, { PureComponent, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { compose, graphql } from "react-apollo";
import { FormattedMessage } from "react-intl";
import { injectIntl } from "react-intl";

// Components
import ContentHeader from "containers/ContentHeader";
import Text from "components/Text";
import Loader from "components/Loader";
import Pagination from "components/Pagination";
import Button from "components/Button";
import CategoriesListTable from "routes/AdminPanel/Categories/CategoriesList/CategoriesListTable/";
import CategoriesForm from "../CategoriesForm";
import { withModal } from "containers/ModalProvider/withModal";
import { withAuthenticatedUser } from "containers/AuthenticatedUserProvider/withAuthenticatedUser";

// GraphQl
import LIST_CATEGORIES_QUERY from "queries/Courses/listCategories.gql";

// Other
import { Grey3 } from "components/Icon/color";
import { messages } from "routes/AdminPanel/messages";
import { keysToCamel, isEmptyObject, isArraysEqual } from "utils/helpers/index";

// Styles and Assets
import "./CategoriesList.scss";

const CATEGORIES_PAGE_COUNTER = 20;

class CategoriesList extends PureComponent {
  addCategory = () => {
    const { history } = this.props;
    history.push(`/admin/panel/category/create`);
  };

  handleAddCategorySubmit = () => {
    const {
      modalContext: { hideModal },
    } = this.props;
    hideModal();
  };

  handleAddCategory = async () => {
    const {
      userContext: {
        profile: { org: companyId },
      },
      modalContext: { showModal },
    } = this.props;

    try {
      showModal(CategoriesForm, {
        handleAddCategorySubmit: this.handleAddCategorySubmit,
        companyId,
      });
    } catch (error) {
      console.error(error);
      // setErrors(parseIncomingError(error));
    }
  };

  render() {
    const { error, fetchError, loading, listCategories, ...props } = this.props;

    // if (loading) return (<Loader center />)

    return (
      <Fragment>
        <div className="sk-admin-discounts-list__header">
          <div className="sk-admin-discounts-list__header__sidebarButton">
            <ContentHeader />
          </div>
          <Button
            className="sk-admin-discounts-list__header__addPromocodeButton"
            onClick={this.handleAddCategory}
          >
            Add Category
          </Button>
        </div>

        <CategoriesListTable
          error={error}
          loading={loading}
          categories={listCategories}
          currentPage={1}
        />
      </Fragment>
    );
  }
}

const listCategoriesQuery = graphql(LIST_CATEGORIES_QUERY, {
  props: ({
    data: { loading, error: fetchError, listCategories, ...ownProps },
  }) => ({
    loading,
    listCategories,
    fetchError,
    ...ownProps,
  }),
  options: ({ userContext }) => ({
    variables: {
      org: userContext?.profile?.org || null,
    },
    fetchPolicy: "cache-and-network",
  }),
});

const enhancer = compose(
  injectIntl,
  withRouter,
  withModal,
  withAuthenticatedUser,
  listCategoriesQuery
);

export default enhancer(CategoriesList);
