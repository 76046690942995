// Core
import React, { memo, useCallback } from 'react';
import { withRouter } from 'react-router';
import { Progress } from 'react-sweet-progress';
import { compose } from 'react-apollo';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

// Components
import IconWithModal from 'components/IconWithModal';
import Text from 'components/Text';

import DragAndDropBurger from 'components/Icon/Svg/DragAndDropBurger';
import UploadController from 'routes/Course/components/UploadController';
import Duration from 'components/Duration/Duration';

// Other
import { RECOMMENDED_VIDEO_FORMATS, FAILED_ATTACHMENT } from 'utils/enums';
import {
  LECTURE_VIDEO,
  LECTURE_AUDIO,
  LECTURE_ARTICLE,
  LECTURE_HOMEWORK_TASK,
} from 'containers/UploadManagerProvider/helpers';
import { Concrete, DarkGrey, White, NotActiveGrey, WarnRed } from 'components/Icon/color';
import { sharedMessages } from 'services/i18n/sharedMessages/messages';
import { withUploadManager } from 'containers/UploadManagerProvider/withUploadManager';
import { theme } from 'containers/UploadManagerProvider/helpers';
import { ADMIN, QUIZ_ATTACHMENT, } from 'utils/enums/index';
import { isBlank } from 'utils/helpers';
import ENTITY_CONTROLLER from 'routes/Course/components/LectureMaterials/StudyMaterialsList/MaterialEntity/helpers';
import { withAuthenticatedUser } from 'containers/AuthenticatedUserProvider/withAuthenticatedUser';

// Styles and Assets
import './index.scss';

export const MaterialEntity = props => {
  const {
    entity,
    withDragableButton,
    withDuration,
    entity: { title, duration, id, filepath, type, content, filetype, courseId },
    uploadManagerContext: {
      progress,
      getFileFromQueue,
      getFileFromUploadedFiles,
    },
    disabled,
    openFileModal,
    handleAttachmentDelete,
    disableDeletion = false,
    userContext: { profile },
    history,
  } = props;

  const handleReloadAttachment = () => {
    openFileModal({
      entityId: id,
      entityType: filetype,
      params: { withDuration: type !== LECTURE_HOMEWORK_TASK },
  })};
  const handleReloadQuiz = useCallback(() => {
    const isAdmin = profile?.role == ADMIN;
    const route = isAdmin ? 'admin/panel' : 'experts-cabinet';
    history.push(`/${route}/course/${courseId}/quiz/${filepath}`);
  }, [courseId, filepath, profile.role, history.push]);

  const ableEntityReload = id && type;
  const handleReload = filetype === QUIZ_ATTACHMENT ? handleReloadQuiz : handleReloadAttachment;

  const isFileAdded = !isBlank(entity);
  let isFileFailed = false;
  let isFilePending = false;
  let isFileUploaded = false;
  let isFileInQueue = false;
  let isFileInProgress = false;
  let isFileUploadedAndProcessed = false;
  let isFileProcessing = false;

  if (isFileAdded) {
    const { id } = entity;
    isFileFailed = entity.status === 'ERROR';
    isFilePending = entity.status === 'PENDING';
    isFileUploadedAndProcessed = entity.status === 'UPLOADED';
    isFileProcessing = entity.status === 'PROCESSING';

    const {
      isFileInList: isFileInQueueTemp,
      index: fileIndexInQueue,
    } = getFileFromQueue(id);
    const { isFileInList: isFileUploadedTemp } = getFileFromUploadedFiles(id);
    isFileInQueue = isFileInQueueTemp;
    isFileUploaded = isFileUploadedTemp;
    isFileInProgress = isFileInQueue && fileIndexInQueue === 0;
  }

  const iconColor =
    isFilePending || isFileProcessing ? NotActiveGrey : DarkGrey;
  const durationColor = (isFilePending || isFileProcessing)
      ? { color: NotActiveGrey }
      : ENTITY_CONTROLLER[filetype]?.getDurationColor(duration);

  const renderDuration = () => {
    return (
      (isFilePending || isFileProcessing ? (
          <Duration duration={0} textStyles={durationColor} />
        ) : (
          <Duration duration={duration} textStyles={durationColor} />
        )
      )
    );
  }

  return (
    <>
      {((isFilePending && (isFileInQueue || isFileUploaded)) ||
        isFileProcessing ||
        isFileUploadedAndProcessed ||
        (isFilePending && filetype == QUIZ_ATTACHMENT) ||
        isFileFailed) && (
        <div className="sk-lec-material-entity">
          <div className="sk-lec-material-entity__info">
            <div className="sk-lec-material-entity__info__left">
              <div className="sk-lec-material-entity__info__left__icon">
                <IconWithModal
                  modal={(
                    (!isFileFailed)
                    ? (ENTITY_CONTROLLER[filetype]?.preview?.component || ENTITY_CONTROLLER.null?.preview?.component)
                    : ENTITY_CONTROLLER[FAILED_ATTACHMENT]?.component?.icon
                  )}
                  modalProps={{ filepath, size: 'lg', url: entity?.url, isPublic: entity?.isPublic,  fileType: filetype, readOnly: true, quizId: entity?.filepath }}
                  icon={(
                    (!isFileFailed)
                    ? (ENTITY_CONTROLLER[filetype]?.preview?.icon || ENTITY_CONTROLLER.null?.preview?.icon)
                    : ENTITY_CONTROLLER[FAILED_ATTACHMENT]?.preview?.icon
                  )}
                />
              </div>
              <Text
                size="small"
                ariaLabel={title}
                title={title}
                classes={classNames(
                  'sk-lec-material-entity__info__left__title',
                  { inactive: isFilePending || isFileProcessing }
                )}
              >
                {title}
              </Text>
              <div className="sk-lec-material-entity__info__left__duration-wrapper">
                {withDuration && renderDuration()}
              </div>
            </div>

            <div className="sk-lec-material-entity__info__right">
              <div className="sk-lec-material-entity__info__right__controls">
                <UploadController
                  withS3Upload={false}
                  withDelete={!disableDeletion}
                  uploaderId={`sk-material-entity-upload-${id}`}
                  disabled={disabled || !ableEntityReload}
                  iconColor={iconColor}
                  supportedFormats={RECOMMENDED_VIDEO_FORMATS}
                  maxSize={500000}
                  isFilePending={isFilePending || isFileProcessing}
                  isFileInQueue={isFileInQueue}
                  handleReload={handleReload}
                  handleDelete={() => handleAttachmentDelete(id)}
                />
              </div>
              {withDragableButton && (
                <span className="sk-lec-material-entity__info__right__burger">
                  <DragAndDropBurger width={18} color={Concrete} />
                </span>
              )}
            </div>
          </div>
          {isFilePending && (isFileInQueue || isFileInProgress) && (
            <Progress
              theme={theme}
              percent={isFileInProgress ? progress : 0}
              status="active"
            />
          )}
          {isFilePending && isFileUploaded && !isFileInProgress && (
             <Text className="inactive">
              <FormattedMessage {...sharedMessages.fileProcessing} />
            </Text>
          )}
          {isFileProcessing && (
            <Text className="inactive">
              <FormattedMessage {...sharedMessages.fileProcessing} />
            </Text>
          )}
          {isFileFailed && (
            <Text className="failed">
              <FormattedMessage {...sharedMessages.fileFailed} />
            </Text>
          )}
        </div>
      )}
    </>
  );
};

const enhancer = compose(
  memo,
  withRouter,
  withAuthenticatedUser,
  withUploadManager
);

export default enhancer(MaterialEntity);
