import React from "react";
import Icon from "components/Icon/Svg/AI/AI";
import { RecourseIconButton } from "components/DraftEditor/ControlsBar/Buttons";

import "./index.scss";

const Magic = ({ handleMagic }) => (
  <RecourseIconButton
    tag="label"
    className="sk-editor-control-bar-magic"
    icon={Icon}
    type="button"
    active
    noBlurOnClick={handleMagic}
    onClickHandler={handleMagic}
    style={{ cursor: "pointer" }}
  />
);

export default Magic;
