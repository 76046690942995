// Core
import React, { PureComponent } from 'react';
import classNames from 'classnames';
import withWidth from 'containers/HOCs/withWidth';
import { graphql, compose } from 'react-apollo';
import slugify from '@sindresorhus/slugify';

// Components
// import Loader from 'components/Loader';
import Text from 'components/Text';
import ContentNavigation from 'components/ContentNavigation';
import HWDownloadBtn from 'routes/ExpertsCabinet/components/HWDownloadBtn';
import ExpHWReviewUploader from 'routes/ExpertsCabinet/components/ExpHWReviewUploader';
import HWLectureTitle from 'routes/ExpertsCabinet/components/HWLectureTitle';
// import HWLectureStatus from 'routes/ExpertsCabinet/components/HWLectureStatus';
import HWMarkForm from 'routes/ExpertsCabinet/components/HWMarkForm';
import HomeworkLoader from 'routes/ExpertsCabinet/components/HWDetailsComponent/HomeworkLoader';

// GraphQL
import SET_HW_REVIEW_MARK from 'mutations/Courses/expertHWReviewSetMark.gql';
import NOTIFY_ABOUT_HOMEWORK_STATUS_CHANGE from 'mutations/Homeworks/notifyAboutHomeworkStatusChange.gql';
import UPDATE_HOMEWORK_STEP from 'mutations/StudentCabinet/updateHomeworkStep.gql';

// Other
import { isBlank } from 'utils/helpers/index';
// import { s3UploadHomeworkReview } from 'services/aws/amplify/helpers';
import { LECTURE_HOMEWORK_REVIEW } from 'containers/UploadManagerProvider/helpers';
import { getSeparatedFilename } from 'services/aws/amplify/helpers';
import { withUploadManager } from 'containers/UploadManagerProvider/withUploadManager';

import { FormattedMessage } from 'react-intl';
import { messages } from 'routes/ExpertsCabinet/components/HWDetailsComponent/LectureHwRow/messages';

// Styles and Assets
import './index.scss';

class LectureHwRow extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      scrolledTo: 'prev',
    };
    this.setContainerRef = element => {
      this.scroller = element;
    };
    this.setRowEndRef = element => {
      this.rowEnd = element;
    };
  }

  componentDidUpdate = prevProps => {
    const { width } = this.props;

    if (prevProps.width === 'sm' && width === 'md') {
      this.handleRowScroll('prev');
    }
  };

  handleInputBlur = name => value => this.setState({ [name]: value });

  handleInputChange = name => value => this.setState({ [name]: value });

  handleAddHomeworkReview = id => async fileList => {
    const file = fileList[0];

    if(isBlank(file)) return;

    const {
      mappingId,
      lectureId,
      courseId,
      studentId,
      uploadManagerContext: { addFileToQueue },
      refetchStudentList,
      updateHomeworkStep,
      homework: { step },
    } = this.props;

    const { fileName, ext } = getSeparatedFilename(file.name);
    const beautyFilename = slugify(fileName);

    try {
      const params = {
        type: LECTURE_HOMEWORK_REVIEW,
        s3UploadingVariables: {
          filename: beautyFilename,
          file,
          courseId,
          lectureId,
          studentId,
        },
        createAttachmentMutationVariables: {
          id,
          status: 'PENDING',
          type: LECTURE_HOMEWORK_REVIEW,
          filename: `${beautyFilename}.${ext}`,
          title: beautyFilename,
          purchased_course_id: mappingId,
          lecture_id: lectureId,
          course_id: courseId,
        },
        s3PostHook: refetchStudentList,
      };

      await addFileToQueue(params);
    } catch (error) {
      throw Error(error);
    }
  };

  handleSubmit = async ({ mark: newestMark }) => {
    const {
      mappingId,
      courseId,
      studentId,
      homeworkNumber,
      notifyAboutHomeworkStatusChange,
      id: lectureId,
      updateHomeworkStep,
      homework: { review },
    } = this.props;
    const isHomeworkUploaded =
      review && review !== null && review.attachment?.status === 'UPLOADED';

    try {
      if (isHomeworkUploaded) {
        const input = {
          courseId,
          lectureId,
          prevStatus: 3,
          nextStatus: 4,
          studentId,
          homeworkNumber,
        };

        await updateHomeworkStep({
          course_id: courseId,
          lecture_id: lectureId,
          student_id: studentId,
          step: 4,
        });
        await this.handleUpdateHwMark(mappingId, lectureId, newestMark);
        await notifyAboutHomeworkStatusChange(input);
      }
    } catch (error) {
      throw Error(error);
    }
  };

  handleRowScroll = name => () => {
    this.setState({ scrolledTo: name }, () => {
      if (name === 'next') {
        this.rowEnd.scrollIntoView({ behavior: 'smooth', block: 'end' });
      } else {
        this.scroller.scrollLeft = 0;
      }
    });
  };

  handleUpdateHwMark = async (mappingId, lectureId, mark) => {
    const { setHwReviewMark, courseId } = this.props;
    try {
      const input = {
        course_id: courseId,
        mapping_id: mappingId,
        lecture_id: lectureId,
        mark,
      };

      await setHwReviewMark(input);
    } catch (error) {
      throw Error(error);
    }
  };

  removeReview = async () => {
    const {
      lectureId,
      studentId,
      courseId,
      homework: {
        review: { id },
      },
      uploadManagerContext: { removeFileFromQueue, updateStudentHomework },
    } = this.props;

    const deleteOptions = {
      action: 'remove',
      type: LECTURE_HOMEWORK_REVIEW,
      student_id: studentId,
      course_id: courseId,
      lecture_id: lectureId,
    };

    removeFileFromQueue(id);
    await updateStudentHomework(deleteOptions);
  };

  render() {
    const {
      title,
      orderNumber,
      homework,
      homework: { answer, review, step },
      mappingId,
      lectureId,
      studentId,
      uploadManagerContext: { getFileFromQueue, getFileFromUploadedFiles },
      displayLectureNameType,
    } = this.props;
    const { scrolledTo } = this.state;

    const answerFile = answer?.attachment;
    const isAnswerUploaded = answerFile?.status === 'UPLOADED';
    const isAnswerSubmitted = step >= 3;
    const isReviewSubmitted = step === 4;

    const reviewFile = review?.attachment;
    const isReviewAdded = reviewFile && reviewFile !== null;
    let isReviewPending = false;
    let isReviewUploaded = false;
    let isReviewInQueue = false;
    let isReviewInProgress = false;
    let isReviewUploadedAndProcessed = false;
    let isReviewProcessing = false;

    if (isReviewAdded) {
      const { id } = reviewFile;
      isReviewPending = reviewFile.status === 'PENDING';
      isReviewUploadedAndProcessed = reviewFile.status === 'UPLOADED';
      isReviewProcessing = reviewFile.status === 'PROCESSING';

      const {
        isFileInList: isFileInQueue,
        index: fileIndexInQueue,
      } = getFileFromQueue(id);
      const { isFileInList: isFileUploaded } = getFileFromUploadedFiles(id);
      isReviewInQueue = isFileInQueue;
      isReviewUploaded = isFileUploaded;
      isReviewInProgress = isReviewInQueue && fileIndexInQueue === 0;
    }

    // if (lectureId == 'f10dc495-75a9-4bd2-a48d-31e5ef59b4bf' && studentId == 'us-east-2:bd467ab7-4771-438c-9a07-9fb40c8b0e43') {
    //   console.log('homework', homework);
    //   console.log('review file', reviewFile);
    // }

    const hwIsDisabled = !isAnswerUploaded || !isAnswerSubmitted;
    const canSubmitReview =
      isAnswerUploaded && isAnswerSubmitted && isReviewUploadedAndProcessed;

    return (
      <div
        className={classNames('sk-hw-lecture-row', {
          'sk-hw-lecture-row_scrolled': scrolledTo === 'next',
        })}
        ref={this.setContainerRef}
      >
        <div
          className={classNames('sk-hw-lecture-row__title', {
            'sk-hw-lecture-row__title_scrolled': scrolledTo === 'next',
          })}
        >
          <HWLectureTitle
            displayLectureNameType={displayLectureNameType}
            orderNumber={orderNumber}
            title={title}
            hwSubmitDate={answer?.submittedDate}
          />
        </div>

        <div
          className={classNames('sk-hw-lecture-row__loader', {
            'sk-hw-lecture-row__loader_scrolled': scrolledTo === 'next',
          })}
        >
          <div className="sk-hw-lecture-row__download">
            {isAnswerSubmitted ? (
              <HWDownloadBtn filepath={answerFile.filepath} />
            ) : (
              <Text size="caption" classes="sk-hw-lecture-row__text">
                <FormattedMessage {...messages.notYetSubmitted} />
              </Text>
            )}
          </div>

          <div className="sk-hw-lecture-row__review">
            <ExpHWReviewUploader
              isReviewUploadedAndProcessed={isReviewUploadedAndProcessed}
              isReviewPending={isReviewPending}
              isReviewUploaded={isReviewUploaded}
              isReviewInQueue={isReviewInQueue}
              isReviewProcessing={isReviewProcessing}
              removeReview={this.removeReview}
              reviewFile={reviewFile}
              lectureId={homework?.lectureId}
              studentId={studentId}
              mappingId={mappingId}
              homework={homework}
              disabled={hwIsDisabled}
              handleAddHomeworkReview={this.handleAddHomeworkReview}
            />
          </div>
        </div>

        <div
          className={classNames('sk-hw-lecture-row__scroll-offset', {
            'sk-hw-lecture-row__scroll-offset_visible': scrolledTo === 'prev',
          })}
        />

        <HWMarkForm
          mark={review?.mark}
          disabledMark={hwIsDisabled}
          disabledSubmit={!canSubmitReview}
          isReviewSubmitted={isReviewSubmitted}
          scrolled={scrolledTo === 'next'}
          handleFormSubmit={this.handleSubmit}
        />

        <div ref={this.setRowEndRef}>
          <HomeworkLoader
            isReviewAdded={isReviewAdded}
            isReviewPending={isReviewPending}
            isReviewUploaded={isReviewUploaded}
            isReviewInQueue={isReviewInQueue}
            isReviewInProgress={isReviewInProgress}
            isReviewUploadedAndProcessed={isReviewUploadedAndProcessed}
            isReviewProcessing={isReviewProcessing}
            isReviewSubmitted={isReviewSubmitted}
            review={reviewFile}
          />
        </div>

        <div className={classNames('sk-hw-lecture-row__scroll-offset', {
            'sk-hw-lecture-row__scroll-offset_visible': scrolledTo === 'next',
          })}
        />

        <div className="sk-hw-lecture-row__scroll-btns">
          <ContentNavigation
            handleNextButtonPress={this.handleRowScroll('next')}
            handlePreviousButtonPress={this.handleRowScroll('prev')}
          />
        </div>
      </div>
    );
  }
}

const setHwReviewMarkMutation = graphql(SET_HW_REVIEW_MARK, {
  props: ({ mutate }) => ({
    setHwReviewMark: input => mutate({ variables: { input } }),
  }),
  options: {
    refetchQueries: ['expertListStudentsByCourse'],
  },
});

const mutateUpdateHomeworkStep = graphql(UPDATE_HOMEWORK_STEP, {
  props: ({ mutate }) => ({
    updateHomeworkStep: input => {
      mutate({ variables: { input } });
    },
  }),
  options: {
    refetchQueries: ['expertListStudentsByCourse'],
  },

});

const mutateNotifyAboutHomeworkStatusChange = graphql(
  NOTIFY_ABOUT_HOMEWORK_STATUS_CHANGE,
  {
    props: ({ mutate }) => ({
      notifyAboutHomeworkStatusChange: input =>
        mutate({ variables: { input } }),
    }),
  }
);

const enhancer = compose(
  withWidth(),
  setHwReviewMarkMutation,
  mutateUpdateHomeworkStep,
  mutateNotifyAboutHomeworkStatusChange,
  withUploadManager
);

export default enhancer(LectureHwRow);
