// Core
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// Components
import Tabs from 'components/Tabs';

// Other
import { FormattedMessage } from 'react-intl';
import { messages } from 'routes/AdminPanel/messages';

class DiscountsTabs extends PureComponent {
  handleChange = (e, value) => {
    const { handleSwitch } = this.props;
    handleSwitch(value);
  };

  render() {
    const { tabs, activeTab, disabledTab } = this.props;
    return (
      <Tabs
        tabs={tabs}
        value={activeTab}
        handleChange={this.handleChange}
        disabledTab='analytics'
      />
    );
  }
}

DiscountsTabs.propTypes = {
  tabs: PropTypes.array,
  handleSwitch: PropTypes.func,
  activeTab: PropTypes.string,
  disabledTab: PropTypes.string,
};

DiscountsTabs.defaultProps = {
  tabs: [
    {
      label: 'Subcategories',
      value: 'info',
    },

  ],
};

export default DiscountsTabs;
