import { EditorState, AtomicBlockUtils } from 'draft-js';
import { IMAGE } from 'components/DraftEditor/enums.js';

export default ({ editorState, src }) => {
  const contentState = editorState.getCurrentContent();
  const contentStateWithEntity = contentState.createEntity(IMAGE, 'IMMUTABLE', {
    src,
  });
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  const newEditorState = EditorState.set(
    editorState,
    { currentContent: contentStateWithEntity },
    'create-entity'
  );

  return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' ');
};
