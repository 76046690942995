// Core
import React from 'react';
import PropTypes from 'prop-types';
import { SnackbarProvider } from 'notistack';

import './NotificationProvider.scss';

const snakbarProps = {
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
  transitionDuration: { exit: 380, enter: 400 },
  preventDuplicate: true,
  maxSnack: 3,
  classes: {
    anchorOriginTopRight: 'mui-override sk-notification',
    variantSuccess: 'mui-override sk-notification__content_success',
  },
  ContentProps: {
    classes: {
      root: 'mui-override sk-notification__content',
    },
  },
};

const NotificationProvider = ({ children }) => (
  <SnackbarProvider {...snakbarProps}>{children}</SnackbarProvider>
);

NotificationProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default NotificationProvider;
