// Core
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// Components
import SimpleSelect from 'components/Select/SimpleSelect';
import TextInput from 'components/Input/Material/TextInput/TextInput';

// Other
import { Grey } from 'components/Icon/color';

// Styles and Assets
import * as materialUISelectInlineStyles from './Selector.style';
import './DiscountTypeSelect.scss';

const DiscountTypeOptions = [
  { value: 'percent', label: 'For certain percentage (%)' },
  { value: 'amount', label: 'For certain sum ($)', disabled: true },
];
const discountValueInputLabels = {
  percent: 'Percentage, %',
  amount: 'Sum, $',
}

class DicountTypeSelect extends PureComponent {
  handleOptionChange = type => value => this.props.handleInputChange(type)(value);
  handleDiscountTypeChange = this.handleOptionChange('discountType');

  render() {
    const { discountType, errors, touched, discountValue, handleInputChange, handleInputBlur } = this.props;

    return (
      <div className="sk-discount-type">
        <SimpleSelect
          helperText={touched.discountType ? errors.discountType : ''}
          error={touched.discountType && Boolean(errors.discountType)}
          name='discountType'
          label='Discount type'
          inlineStyles={materialUISelectInlineStyles}
          value={discountType}
          valueKey="value"
          labelKey="label"
          options={DiscountTypeOptions}
          handleChange={(nextType) => this.handleDiscountTypeChange(nextType)}
          handleBlur={handleInputBlur('discountType')}
        />
        {discountType && (
          <div className="sk-discount-type__input">
            <TextInput
              helperText={touched.discountValue ? errors.discountValue : ''}
              error={touched.discountValue && Boolean(errors.discountValue)}
              // placeholder="Promo Code "
              value={discountValue}
              name="discountValue"
              label={discountValueInputLabels[discountType]}
              onBlur={handleInputBlur('discountValue')}
              onChange={handleInputChange('discountValue')}
            />
          </div>
        )}
      </div>
    );
  }
}

DicountTypeSelect.propTypes = {
};

export default DicountTypeSelect;
