import React, { useState, useEffect, useRef } from "react";

import {
  Editor,
  EditorState,
  ContentState,
  SelectionState,
  ContentBlock,
  RichUtils,
  CompositeDecorator,
  getDefaultKeyBinding,
  genKey,
  convertToRaw,
  convertFromRaw,
} from "draft-js";

import createInlineToolbarPlugin from "components/DraftEditor/TollbarInline";
import classnames from "classnames";

// Components
import ControlsBar from "components/DraftEditor/ControlsBar";
import LinkText from "components/DraftEditor/ContentBlocks/Link";
import ColorText from "components/DraftEditor/ContentBlocks/ColorText";

// Other
import {
  IMAGE,
  VIDEO,
  LINK,
  COLOR,
  SUGGESTIONS,
} from "components/DraftEditor/enums";
import { isNull } from "utils/helpers";
import { fileToBase64 } from "components/DraftEditor/helpers";
import {
  extendedRenderMap,
  styleMap,
  getBlockStyle,
  blockRendererFn,
  findLinkEntities,
  INLINE_TOOLBAR_COMPONENTS,
  findColorEntities,
} from "components/DraftEditor/Editor/helpers";

import addImage from "components/DraftEditor/ContentBlocks/Image/addImage";
import addLink from "components/DraftEditor/ContentBlocks/Link/addLink";
import addVideo from "components/DraftEditor/ContentBlocks/Video/addVideo";
import addColor from "components/DraftEditor/ContentBlocks/ColorText/addColor";

// Styles
import "draft-js/dist/Draft.css";
import "./DraftEditor.scss";

const inlineToolbarPlugin = createInlineToolbarPlugin();
const {
  InlineToolbar,
  initialize: initializeInlineToolbar,
} = inlineToolbarPlugin;
const plugins = [inlineToolbarPlugin];

const compositeDecorator = new CompositeDecorator([
  {
    strategy: findLinkEntities,
    component: LinkText,
  },
  {
    strategy: findColorEntities,
    component: ColorText,
  },
]);

const defaultInitialEditorState = EditorState.createWithContent(
  ContentState.createFromText(" "),
  compositeDecorator
);

let editorStateCopy = null;

const DraftEditor = (props) => {
  const [isInitialContentSet, setInitialContentState] = useState(false);
  const [editorState, setEditorState] = useState(defaultInitialEditorState);
  const [isFullscreen, switchEditorView] = useState(false);
  const [inlineToolbarType, setInlineToolbarType] = useState(null);
  const { getSuggestions } = props;
  const editor = useRef(null);
  const focusEditor = (event) => {
    if (editor.current) {
      editor.current.focus();
    }
  };
  // const blurEditor = event => {
  //   if (editor.current) {
  //     editor.current.blur();
  //   }
  // };

  initializeInlineToolbar({
    getEditorRef: () => editor?.current,
    getEditorState: () => editorState,
    setEditorState,
  });

  useEffect(() => {
    const isNotEmpty = props?.initialContent?.length > 0;
    if (!isInitialContentSet && isNotEmpty) {
      let newRawContent = {};

      try {
        const parsedContent = JSON.parse(props.initialContent);
        newRawContent = convertFromRaw(parsedContent);
      } catch (e) {
        newRawContent = ContentState.createFromText(props.initialContent);
      }

      const newEditorState = EditorState.createWithContent(
        newRawContent,
        compositeDecorator
      );

      setInitialContentState(true);
      setEditorState(newEditorState);
      editorStateCopy = newEditorState;
    }
    // focusEditor();
  }, [props?.initialContent?.length]);

  const onChange = (nextEditorState) => {
    let newEditorState = nextEditorState;

    // Add space at position 0 if block is empty. Need to fix wrong inline toolbar position
    // let contentState = newEditorState.getCurrentContent();
    //
    //
    // const startKey = newEditorState.getSelection().getStartKey();
    // const selectedBlock = contentState.getBlockForKey(startKey);
    // const blockText = selectedBlock.getText();
    //
    // if (blockText.length === 0) {
    //   const newBlock = selectedBlock.set('text', ' ');
    //   const newBlockMap = contentState.getBlockMap().set(newBlock.key, newBlock)
    //   const newContentState = ContentState.createFromBlockArray(newBlockMap.toArray())
    //   newEditorState =  EditorState.push(
    //     editorState,
    //     newContentState
    //   );
    // };

    // const contentState = newEditorState.getCurrentContent();
    // console.log('conver to row', convertToRaw(contentState));

    editorStateCopy = newEditorState;
    setEditorState((prev) => newEditorState);
  };

  const stringifyEditorState = () => {
    const contentState = editorStateCopy.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    const stringifiedContentState = JSON.stringify(rawContentState);
    props.onChange(stringifiedContentState);
  };

  const onBlur = (event) => {
    event.preventDefault();
    stringifyEditorState();
  };

  const uploadImage = async (event) => {
    // focusEditor();
    const {
      target: { files },
    } = event;

    const image = files[0];
    const imageMimetype = image.type;
    const base64 = await fileToBase64(image);

    const editorStateWithImage = addImage({ editorState, src: base64 });
    onChange(editorStateWithImage);
  };

  const createLink = ({ href, text }) => {
    const editorStateWithLink = addLink({ editorState, nextHref: href, text });
    onChange(editorStateWithLink);
    stringifyEditorState();
  };

  const createEmbedBlock = ({ href }) => {
    const editorStateWithVideo = addVideo({ editorState, src: href });
    onChange(editorStateWithVideo);
  };

  const handleKeyCommand = (command) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      onChange(newState);
      return true;
    }
    return false;
  };

  const onTab = (evnt) => {
    evnt.preventDefault();
    const isTabClicked = evnt.keyCode === 9;

    if (isTabClicked) {
      const maxDepth = 16;
      const newEditorState = RichUtils.onTab(evnt, editorState, maxDepth);
      onChange(newEditorState);
    }
  };

  const toggleAlignType = (newAlign) => {
    const contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();

    const blockMap = contentState.getBlockMap();
    const entityMap = contentState.getEntityMap();

    const startKey = selection.getStartKey();
    const lastKey = selection.getEndKey();

    const blockMapKeys = blockMap
      .toArray()
      .map((contentBlock) => contentBlock.key);
    const startKeyIndex = blockMapKeys.indexOf(startKey);
    const endKeyIndex = blockMapKeys.indexOf(lastKey);

    const newBlockMap = blockMap.map((contentBlock, contentBlockKey) => {
      const index = blockMapKeys.indexOf(contentBlockKey);
      const willEdit = index >= startKeyIndex && index <= endKeyIndex;
      if (willEdit) {
        return contentBlock.set("type", newAlign);
      }
      return contentBlock;
    });

    const newContentState = ContentState.createFromBlockArray(
      newBlockMap.toArray(),
      entityMap
    );
    const newEditorState = EditorState.createWithContent(
      newContentState,
      compositeDecorator
    );

    onChange(EditorState.moveFocusToEnd(newEditorState));
  };

  const toggleBlockType = (blockType) => {
    onChange(RichUtils.toggleBlockType(editorState, blockType));
  };

  const setFontSize = (newFontSize) => {
    const selection = editorState.getSelection();
    const newEditorState = RichUtils.toggleBlockType(editorState, newFontSize);
    const newEditorStateWithCurrentSelection = EditorState.forceSelection(
      newEditorState,
      selection
    );
    onChange(newEditorStateWithCurrentSelection);
  };

  const handleMagic = async () => {
    const selection = editorState.getSelection();
    const anchorKey = selection.getAnchorKey();
    const currentContent = editorState.getCurrentContent();
    const currentBlock = currentContent.getBlockForKey(anchorKey);

    const start = selection.getStartOffset();
    const end = selection.getEndOffset();
    const selectedText = currentBlock.getText().slice(start, end);

    const newEditorState = RichUtils.toggleBlockType(editorState);
    const newEditorStateWithCurrentSelection = EditorState.forceSelection(
      newEditorState,
      selection
    );
    try {
      const { data } = await getSuggestions(
        selectedText ||
          newEditorStateWithCurrentSelection
            .getCurrentContent()
            .getPlainText("\n")
      );
      if (data?.suggestions) {
        const updatedDescription = data.suggestions;
        console.log("Updated Description:", updatedDescription);
        onChange(newEditorStateWithCurrentSelection);
        // handleShowSuggestionsModal(updatedDescription);
      }
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    } finally {
    }

    onChange(newEditorStateWithCurrentSelection);
  };
  const toggleInlineStyle = (inlineStyle) => {
    onChange(RichUtils.toggleInlineStyle(editorState, inlineStyle));
  };

  const changeView = () => {
    switchEditorView(!isFullscreen);
  };

  const closeInlineToolbar = (event) => {
    event.preventDefault();
    if (!isNull(inlineToolbarType)) {
      stringifyEditorState();
      setInlineToolbarType(null);
    }
  };

  const toggleInlineToolbar = (toolbarType) => {
    setInlineToolbarType(toolbarType);
  };

  const addResource = ({ type, event }) => {
    // console.log('add resource');
    if (type === IMAGE && event) {
      uploadImage(event);
    } else if (type === VIDEO) {
      INLINE_TOOLBAR_COMPONENTS[VIDEO].submitHandler = createEmbedBlock;
      toggleInlineToolbar(type);
    } else if (type === LINK) {
      INLINE_TOOLBAR_COMPONENTS[LINK].submitHandler = createLink;
      toggleInlineToolbar(type);
    } else if (type === SUGGESTIONS) {
      INLINE_TOOLBAR_COMPONENTS[SUGGESTIONS].submitHandler = createLink;
      toggleInlineToolbar(type);
    } else if (type === COLOR) {
      // console.log('add color resource');
      const newEditorState = addColor({ editorState });
      onChange(newEditorState);
    }
  };

  // If the user changes block type before entering any text, we can
  // either style the placeholder or hide it. Let's just hide it now.
  // let hidePlaceholder = false;
  // const contentState = editorState.getCurrentContent();
  // if (!contentState.hasText()) {
  //   if (contentState.getBlockMap().first().getType() !== 'unstyled') {
  //     hidePlaceholder = true;
  //   }
  // }
  const isEditingBlocked = !isNull(inlineToolbarType);

  return (
    <div
      className={classnames("sk-editor-root", {
        "sk-editor-root__full-screen": isFullscreen,
      })}
    >
      {!props.readOnly && (
        <ControlsBar
          editorState={editorState}
          setFontSize={setFontSize}
          toggleAlignType={toggleAlignType}
          toggleBlockType={toggleBlockType}
          toggleInlineStyle={toggleInlineStyle}
          addResource={addResource}
          handleMagic={handleMagic}
          switchView={changeView}
          isFullscreen={isFullscreen}
        />
      )}
      <div
        className={classnames({
          "sk-editor-editing-area": !isFullscreen,
          "sk-editor-editing-area-full-screen": isFullscreen,
          "sk-editor-editing-area-hide-placeholder": true,
          "sk-editor-editing-area-disable-text-select": isEditingBlocked,
        })}
      >
        <Editor
          readOnly={props.readOnly || isEditingBlocked}
          ref={editor}
          blockRenderMap={extendedRenderMap}
          editorState={editorState}
          customStyleMap={styleMap}
          plugins={plugins}
          blockRendererFn={blockRendererFn}
          blockStyleFn={getBlockStyle}
          handleKeyCommand={handleKeyCommand}
          onTab={onTab}
          onChange={onChange}
          onBlur={onBlur}
        />
        {!props.readOnly && (
          <InlineToolbar
            openOnSelection
            isVisible={!isNull(inlineToolbarType)}
            closeToolbar={closeInlineToolbar}
          >
            {(internalProps) => {
              const Component =
                !isNull(inlineToolbarType) &&
                INLINE_TOOLBAR_COMPONENTS[inlineToolbarType].component;
              return (
                <Component
                  closeToolbar={closeInlineToolbar}
                  {...internalProps}
                  handleContentSubmit={
                    INLINE_TOOLBAR_COMPONENTS[inlineToolbarType].submitHandler
                  }
                />
              );
            }}
          </InlineToolbar>
        )}
      </div>
    </div>
  );
};

export default DraftEditor;
