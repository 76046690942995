// Core
import React, { useMemo } from 'react';
import { useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ITEMS_PER_PAGE } from 'utils/enums';

// GraphQL
import LIST_COURSES_QUERY from 'queries/Courses/listCourses.gql';

// Components
import CourseCard from 'components/CourseCard';
import NoCourses from 'components/NoCourses';
import Pagination from 'components/Pagination';

// Other
import { hasLoader } from 'containers/HOCs/hasLoader';

// Styles and Assets
import './CoursesList.scss';

const CoursesList = ({
  searchString,
  nextPage,
  page,
  alreadyBoughtedCourses = [],
  category = '',
  complexity = '',
  durationRange = '',
  interactivityLevel = '',
  language = '',
  subcategory = '',
  org = null,
}) => {
  const { loading, error, data } = useQuery(LIST_COURSES_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        category,
        org,
        complexity,
        duration_range: durationRange,
        variant_name: interactivityLevel,
        language,
        search_string: searchString,
        subcategory,
        from: page * ITEMS_PER_PAGE,
        size: ITEMS_PER_PAGE,
        type: 'course',
      },
    },
  });

  const getCourseBoughtedStatus = id => alreadyBoughtedCourses?.includes(id);

  const listCourses = data?.listCourses;

  const sameRow = useMemo(() => listCourses?.courses.length <= 3, [listCourses]);

  if (loading || error || !listCourses?.courses) return null;

  return (
    <>
      {listCourses?.courses?.length ? (
        <div
          className={classNames('sk-courses-list', { '_one-row': sameRow })}
          id="scroll-coursesList"
        >
          {listCourses.courses.map((course, index) => (
            <CourseCard
              key={course.id}
              course={course}
              position={index}
              boughted={getCourseBoughtedStatus(course.id)}
            />
          ))}
        </div>
      ) : (
        <NoCourses searchString={searchString} />
      )}
      {listCourses.total > 6 && (
        <div className="sk-courses-list__pagination">
          <Pagination
            page={page}
            count={listCourses.total}
            handleChangePage={(e, numberOfPage) => {
              window.scrollTo(0, 0);
              nextPage(e, numberOfPage);
            }}
            rowsPerPage={6}
          />
        </div>
      )}
    </>
  );
};

CoursesList.propTypes = {
  searchString: PropTypes.string,
  nextPage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  alreadyBoughtedCourses: PropTypes.arrayOf(PropTypes.string),
  category: PropTypes.string,
  complexity: PropTypes.string,
  durationRange: PropTypes.string,
  interactivityLevel: PropTypes.string,
  language: PropTypes.string,
  subcategory: PropTypes.string,
  org: PropTypes.string,
};

export default hasLoader(CoursesList, LIST_COURSES_QUERY);
