import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  pleaseAddYourBankAccount: {
    id: 'app.components.AccountBankDetails.pleaseAddYourBankAccount',
    defaultMessage: 'Please select how you would like to get paid',
  },
  payPalEmail: {
    id: 'app.components.AccountBankDetails.payPalEmail',
    defaultMessage: 'Your PayPal account',
  },
  payoneerEmail: {
    id: 'app.components.AccountBankDetails.payoneerEmail',
    defaultMessage: 'Your Payoneer account',
  },
  businessOrPersonalAccount: {
    id: 'app.components.AccountBankDetails.businessOrPersonalAccount',
    defaultMessage: 'Business or personal account',
  },

  recipientsName: {
    id: 'app.components.AccountBankDetails.recipientsName',
    defaultMessage: 'Recipients name',
  },
  country: {
    id: 'app.components.AccountBankDetails.country',
    defaultMessage: 'Country',
  },
  city: {
    id: 'app.components.AccountBankDetails.city',
    defaultMessage: 'City',
  },
  street: {
    id: 'app.components.AccountBankDetails.street',
    defaultMessage: 'Street',
  },
  postalCode: {
    id: 'app.components.AccountBankDetails.postalCode',
    defaultMessage: 'Postal code',
  },
  paymentAccountEmail: {
    id: 'app.components.AccountBankDetails.paymentAccountEmail',
    defaultMessage: 'Email',
  },
  swift: {
    id: 'app.components.AccountBankDetails.swift',
    defaultMessage: 'SWIFT',
  },
  accountNumber: {
    id: 'app.components.AccountBankDetails.accountNumber',
    defaultMessage: 'Account number',
  },
  wireTooltip: {
    id: 'app.components.AccountBankDetails.wireTooltip',
    defaultMessage:
      'Please note there is an outbound wire transfer fee of $50 associated with every transaction. The fee will be deducted from the amount transferred to you.',
  },
});
