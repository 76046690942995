import React, { memo, useCallback } from 'react';
import { compose } from 'react-apollo';
import { injectIntl } from 'react-intl';
import * as moment from 'moment';

// Components
import { IconText } from 'routes/Checkout/CheckoutLists/Subscriptions';
import Text from 'components/Text';
import Button from 'components/Button';

// Other
import { messages } from 'routes/Checkout/messages.js';
import { sharedMessages } from 'services/i18n/sharedMessages/messages';
import { withLanguage } from 'containers/LanguageProvider/withLanguage';
import { withFormattedHtmlMessage } from 'containers/HOCs/withFormattedHtmlMessage';

//Styles
import './index.scss';

const CHARGE_PERIODS = {
  ru: {
    annual: (expiresAt) => `${moment(expiresAt).format('D MMMM')} каждого года`,
    monthly: (expiresAt) => `${moment(expiresAt).format('D')} числа каждого месяца`,
  },
  en: {
    annual: (expiresAt) => `${moment(expiresAt).format('D MMMM')} each year`,
    monthly: (expiresAt) => `day ${moment(expiresAt).format('D')} of every month`,
  }
};

const BILLING_PERIODS_ = {
  ru: {
    annual: 'года',
    monthly: 'месяца',
  },
  en: {
    annual: 'year',
    monthly: 'month',
  }
};
const BILLING_PERIODS = {
  ru: {
    annual: 'год',
    monthly: 'месяц',
  },
  en: {
    annual: 'year',
    monthly: 'month',
  }
};
const MAX_COURSES = {
  ru: {
    package: () => 'неоганиченное количество курсов',
    constructor: (maxCourses) => {
      if (maxCourses == 1) {
        return `${maxCourses} активный курс`;
      } else if (maxCourses > 1 && maxCourses <= 3) {
        return `${maxCourses} активных курса`;
      } else if (maxCourses > 3) {
        return `${maxCourses} активных курсов`;
      };
    }
  },
  en: {
    package: (maxCourses) => `Unlimited number of active courses`,
    constructor: (maxCourses) => `up to ${maxCourses} active courses`,
  }
};

const PREFIX = 'sk-checkout-subscription-success-submit';
const SuccesfulSubscriptionPayment = ({
  subscription: {
    plan,
    price,
    expiresAt,
    maxCourses,
    type,
    billingPeriod,
  },
  handleClick,
  languageContext: { language },
  intl: { formatMessage },
  formatHtmlMessage,
  ...props
}) => {
  moment.locale(language);

  const maxCoursesText = MAX_COURSES[language][type](maxCourses);

  return (
    <div className={PREFIX}>
      <Text>{formatMessage(messages.successSubscriptionTitle)}</Text>
      <Text size='small'>{formatHtmlMessage(messages.successSubscriptionText1, { maxCourses: maxCoursesText, billingPeriod: BILLING_PERIODS_[language][billingPeriod] })}</Text>
      <Text size='small'>{formatHtmlMessage(
        messages.successSubscriptionText2,
        { title: plan?.title[language], billingPeriod: BILLING_PERIODS[language][billingPeriod], chargePeriod: CHARGE_PERIODS[language][billingPeriod](expiresAt) })}
      </Text>
      <Text size='small'>{formatHtmlMessage(messages.successSubscriptionText3)}</Text>
      <Button
        size='large'
        color="secondary"
        onClick={handleClick}
      >
        {formatMessage(sharedMessages.understandButton)}
      </Button>
    </div>
  );
};

const enhancer = compose(
  memo,
  withFormattedHtmlMessage,
  withLanguage
);

export default enhancer(SuccesfulSubscriptionPayment);
