// Core
import React, { Fragment } from 'react';
import classNames from 'classnames';
import { Link as RRLink } from 'react-router-dom';
import RouteInstance from 'services/route';

// Styles and assets
import './Link.scss';

/**
 * Parse /:locale/something links
 * @param {string|object} to
 * @returns {mixed}
 */
const withLocale = (to) => {
  const { locale } = RouteInstance.get();
  if (typeof to === 'string') {
    return to.replace(':locale/', `${locale}/`);
  }
  if (typeof to === 'object' && to.pathname) {
    to.pathname = to.pathname.replace(':locale/', `${locale}/`);
  }
  return to;
}

const Link = ({ children, to, href, classes, target="_self", ...rest }) => (
  <Fragment>
    {to ? (
      <RRLink to={withLocale(to)} className={`sk-link ${classes}`} {...rest}>
        {children}
      </RRLink>
    ) : (
      <a href={href} target={target} className={classNames('sk-link', { [classes]: !!classes })} {...rest}>
        {children}
      </a>
    )}
  </Fragment>
);

export default Link;
