// Core
import React, { memo } from 'react';
import { compose } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';

// Components
import Text from 'components/Text';

// GraphQL
import GET_SUBSCRIPTION_PLAN from 'queries/AdminPanel/getSubscriptionPlan.gql';

// Other
import { keysToCamel } from  'utils/helpers/index';

// Styles and Assets
import './index.scss';

const Breadcrumb = ({
  match: { params: { id: planId }},
  ...props
}) => {
  const { data, loading, error } = useQuery(GET_SUBSCRIPTION_PLAN, {
    variables: { id: planId },
  });

  if (loading) {
    return 'Loading ...';
  };

  const plan = keysToCamel(data?.getSubscriptionPlan);
  const planType = loading ? 'Loading ...' : plan?.title?.en;

  return planType;
}

const enhancer = compose(
  memo,
  withRouter
);

export default enhancer(Breadcrumb);
