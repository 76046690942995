import React from 'react';

const Icon = ({color = '#A9B6C8'}) => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.41187 19.2621V4.34627C4.41187 3.56579 5.03625 2.94141 5.81673 2.94141H14.6275C15.0437 2.94141 15.4253 3.11485 15.7028 3.44438L19.6399 8.04054C19.848 8.3007 19.9694 8.61289 19.9694 8.95977V19.2968C19.9694 20.7363 18.8074 21.8984 17.3678 21.8984L6.99612 21.8637C5.57391 21.8464 4.41187 20.6843 4.41187 19.2621Z" stroke={color} strokeMiterlimit="10"/>
      <path d="M15.0437 3.09766V7.7285C15.0437 8.14476 15.3732 8.47429 15.7895 8.47429L19.8133 8.50898" stroke={color} strokeMiterlimit="10"/>
      <path d="M7.35303 9.79395C7.07688 9.79395 6.85303 10.0178 6.85303 10.2939C6.85303 10.5701 7.07688 10.7939 7.35303 10.7939V9.79395ZM16.9118 10.7939C17.188 10.7939 17.4118 10.5701 17.4118 10.2939C17.4118 10.0178 17.188 9.79395 16.9118 9.79395V10.7939ZM7.35303 10.7939H16.9118V9.79395H7.35303V10.7939Z" fill={color}/>
      <path d="M7.35303 12.7354C7.07688 12.7354 6.85303 12.9592 6.85303 13.2354C6.85303 13.5115 7.07688 13.7354 7.35303 13.7354V12.7354ZM16.9118 13.7354C17.188 13.7354 17.4118 13.5115 17.4118 13.2354C17.4118 12.9592 17.188 12.7354 16.9118 12.7354V13.7354ZM7.35303 13.7354H16.9118V12.7354H7.35303V13.7354Z" fill={color}/>
      <path d="M7.35303 15.6768C7.07688 15.6768 6.85303 15.9006 6.85303 16.1768C6.85303 16.4529 7.07688 16.6768 7.35303 16.6768V15.6768ZM11.0295 16.6768C11.3056 16.6768 11.5295 16.4529 11.5295 16.1768C11.5295 15.9006 11.3056 15.6768 11.0295 15.6768V16.6768ZM7.35303 16.6768H11.0295V15.6768H7.35303V16.6768Z" fill={color}/>
    </svg>

  );
};

export default Icon;
