// Core
import React from 'react';
import { StripeProvider, Elements } from 'react-stripe-elements';

// Components
import CardForm from 'routes/Checkout/components/Stripe/CardForm';

const { STRIPE_PUBLIC_KEY } = process.env;

const StripeForm = ({
  ...props
}) => {
  return (
    <StripeProvider apiKey={STRIPE_PUBLIC_KEY}>
      <Elements
        fonts={[
          { cssSrc: 'https://fonts.googleapis.com/css?family=Nunito' }
        ]}
      >
        <CardForm {...props} />
      </Elements>
    </StripeProvider>
  );
};

export default StripeForm;
