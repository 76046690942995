import React, { memo } from 'react';
import { compose } from 'react-apollo';

const Icon = ({ ...props }) => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M18.7026 3.91187C18.9787 3.91187 19.2026 4.13572 19.2026 4.41187V5.24338H20.7944C21.899 5.24338 22.7944 6.13881 22.7944 7.24338V9.79422V10.7942V19.0362C22.7944 20.1408 21.899 21.0362 20.7944 21.0362H5.67675C4.57218 21.0362 3.67676 20.1408 3.67676 19.0362V7.24338C3.67676 6.13881 4.57219 5.24338 5.67676 5.24338H6.74018V4.41187C6.74018 4.13572 6.96403 3.91187 7.24018 3.91187C7.51632 3.91187 7.74018 4.13572 7.74018 4.41187V5.24338H9.60524V4.41187C9.60524 4.13572 9.8291 3.91187 10.1052 3.91187C10.3814 3.91187 10.6052 4.13572 10.6052 4.41187V5.24338H12.471V4.41187C12.471 4.13572 12.6949 3.91187 12.971 3.91187C13.2472 3.91187 13.471 4.13572 13.471 4.41187V5.24338H15.3368V4.41187C15.3368 4.13572 15.5607 3.91187 15.8368 3.91187C16.1129 3.91187 16.3368 4.13572 16.3368 4.41187V5.24338H18.2026V4.41187C18.2026 4.13572 18.4264 3.91187 18.7026 3.91187ZM21.7944 9.79422V7.24338C21.7944 6.69109 21.3467 6.24338 20.7944 6.24338H19.2026V7.34567C19.2026 7.62181 18.9787 7.84567 18.7026 7.84567C18.4264 7.84567 18.2026 7.62181 18.2026 7.34567V6.24338H16.3368V7.34567C16.3368 7.62181 16.1129 7.84567 15.8368 7.84567C15.5607 7.84567 15.3368 7.62181 15.3368 7.34567V6.24338H13.471V7.34567C13.471 7.62181 13.2472 7.84567 12.971 7.84567C12.6949 7.84567 12.471 7.62181 12.471 7.34567V6.24338H10.6052V7.34567C10.6052 7.62181 10.3814 7.84567 10.1052 7.84567C9.8291 7.84567 9.60524 7.62181 9.60524 7.34567V6.24338H7.74018V7.34567C7.74018 7.62181 7.51632 7.84567 7.24018 7.84567C6.96403 7.84567 6.74018 7.62181 6.74018 7.34567V6.24338H5.67676C5.12447 6.24338 4.67676 6.69109 4.67676 7.24338V9.79422H21.7944ZM4.67676 10.7942H21.7944V19.0362C21.7944 19.5885 21.3467 20.0362 20.7944 20.0362H5.67675C5.12447 20.0362 4.67676 19.5885 4.67676 19.0362V10.7942Z" fill="#20292F"/>
    </svg>
  );
};

const enhancer = compose(
  memo,
);

export default enhancer(Icon);
