import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  addDiscount: {
    id: 'app.routes.AdminPanel.addDiscount',
    defaultMessage: 'Add promocode',
  },
  registrationDate: {
    id: 'app.routes.AdminPanel.registrationDate',
    defaultMessage: 'Registration date',
  },
  country: {
    id: 'app.routes.AdminPanel.registrationDate',
    defaultMessage: 'Country',
  },
  city: {
    id: 'app.routes.AdminPanel.registrationDate',
    defaultMessage: 'City',
  },
  aboutMe: {
    id: 'app.routes.AdminPanel.aboutMe',
    defaultMessage: 'About me',
  },
  expertise: {
    id: 'app.routes.AdminPanel.expertise',
    defaultMessage: 'Expertise',
  },
  aboutYou: {
    id: 'app.routes.AdminPanel.aboutYou',
    defaultMessage: 'About you (it will be a part of your course page)',
  },
  paypal: {
    id: 'app.routes.AdminPanel.paypal',
    defaultMessage: 'PayPal Account',
  },
  payoneer: {
    id: 'app.routes.AdminPanel.payoneer',
    defaultMessage: 'Payoneer Account',
  },
  userInfo: {
    id: 'app.routes.AdminPanel.userInfo',
    defaultMessage: 'User Info',
  },
  userCourses: {
    id: 'app.routes.AdminPanel.userCourses',
    defaultMessage: 'List of Courses',
  },
  discountInfo: {
    id: 'app.routes.AdminPanel.discountInfo',
    defaultMessage: 'Info',
  },
  discountAnalytics: {
    id: 'app.routes.AdminPanel.Analytics',
    defaultMessage: 'Analytics',
  },

  reasonsListTitle: {
    id: 'app.routes.AdminPanel.reasonsListTitle',
    defaultMessage: 'Expert has deleted this course due to the next reasons:',
  },
  deleteCourse: {
    id: 'app.routes.AdminPanel.deleteCourse',
    defaultMessage: 'Delete course',
  },
  masterclassesAndPodcasts: {
    id: 'app.routes.AdminPanel.masterclassesAndPodcasts',
    defaultMessage: 'Master Classes/Podcasts',
  },
  subcatagories: {
    id: 'app.routes.AdminPanel.subcategories',
    defaultMessage: 'Sub-Categories',
  },
  companySize: {
    id: 'app.routes.AdminPanel.companySize',
    defaultMessage: 'Company Size',
  },
});
