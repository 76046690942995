// Core
import React from "react";
import { Redirect } from "react-router";

// routes
// routes / ManageAccount
import PersonalInfo from "routes/ManageAccount/PersonalInfo/";
import CompanyInfo from "routes/ManageAccount/CompanyInfo/";
import ChangePassword from "routes/ManageAccount/ChangePassword/";
import DeleteAccount from "routes/ManageAccount/DeleteAccount/";
import ExpertAgreement from "routes/ExpertAgreement";
import ExpertSales from "routes/ManageAccount/ExpertSales";
import ExpertActiveStudents from "routes/ManageAccount/ExpertActiveStudents";
import AccountBankDetails from "routes/ManageAccount/AccountBankDetails";
import Editor from "routes/Editor";
import Dashboard from "routes/Dashboard";

// routes / Courses
import CourseCreation from "routes/Course/CourseCreation/";
import CourseCreateFAQ from "routes/Course/CourseCreateFAQ/";

// routes / Admin
import DeletedCoursesList from "routes/AdminPanel/DeletedCoursesList";
import DeletedCourseView from "routes/AdminPanel/DeletedCourseView";
import CoursesList from "routes/AdminPanel/CoursesList/CoursesList";
import ToppingsList from "routes/AdminPanel/ToppingsList";
import UsersList from "routes/AdminPanel/UsersList/UsersList";
import UserView from "routes/AdminPanel/UserView/UserView";
import CurrencyRates from "routes/AdminPanel/CurrencyRates/CurrencyRates";
import DiscountsList from "routes/AdminPanel/Discounts/DiscountsList";
import DiscountPage from "routes/AdminPanel/Discounts/DiscountPage";
import PlansList from "routes/AdminPanel/Plans";
import PlanEdit from "routes/AdminPanel/PlanEdit";
import CategoriesList from "routes/AdminPanel/Categories/CategoriesList";
import CategoriesPage from "routes/AdminPanel/Categories/CategoriesPage";
import CompaniesList from "routes/AdminPanel/Companies/CompaniesList";
import CompanyView from "routes/AdminPanel/CompanyView/CompanyView";

// routes ExpertCabinet
import ToppingEdit from "routes/ExpertsCabinet/ToppingEdit";
import ExpertsCourses from "routes/ExpertsCabinet/Courses/";
import ExpertsToppings from "routes/ExpertsCabinet/Toppings/";
import QuizEdit from "routes/ExpertsCabinet/QuizEdit/";
import ExpertsFee from "routes/ExpertsCabinet/ExpertsFee";
import ExpertSubscription from "routes/ExpertsCabinet/ExpertSubscription";
import ExpertPaymentDetails from "routes/ExpertsCabinet/ExpertSubscription/ExpertPaymentDetails";

// routes / ExpertsCalendar
import ExpertsCalendar from "routes/ExpertsCabinet/Calendar/";
import CourseEdit from "routes/ExpertsCabinet/CourseEdit/";
import Homeworks from "routes/ExpertsCabinet/Homeworks/";

// routes / Other
import Onboarding from "routes/Onboarding/";
import CheckoutCourses from "routes/Checkout/Courses";
import CheckoutSubscription from "routes/Checkout/Subscriptions";

// routes / Student cabinet
import PurchasedCourses from "routes/StudentsCabinet/PurchasedCourses/";
import StudentCalendar from "routes/StudentsCabinet/StudentCalendar/";
import StudentToppings from "routes/StudentsCabinet/StudentToppings/";
import StudentCourses from "routes/StudentsCabinet/StudentCourses/";
import CourseModuleStructure from "routes/StudentsCabinet/CourseModuleStructure/";
import ToppingDetails from "routes/StudentsCabinet/ToppingDetails";

// routes / Webinars
import PublisherWebinar from "routes/PublisherWebinar/";
import SubscriberWebinar from "routes/SubscriberWebinar/";

import ErrorMessage from "containers/ErrorMessage/ErrorMessage";

// Breadcrumbs
import PlanEditBreadcrumb from "routes/AdminPanel/PlanEdit/components/Breadcrumb";

// Other
import { USERS, EXPERT, ADMIN, OWNER, MANAGER, ALL } from "utils/enums";
import { FormattedMessage } from "react-intl";
import { messages } from "../messages";

const privateRoutes = [
  {
    path: "/:locale/onboarding",
    exact: true,
    breadcrumb: "onboarding",
    component: Onboarding,
    roles: USERS,
    withoutSidebar: true,
    fullWidth: true,
  },
  {
    path: "/:locale/admin/dashboard",
    exact: true,
    breadcrumb: "Dashboard",
    component: Dashboard,
    roles: [ADMIN, OWNER, MANAGER],
  },
  {
    path: "/:locale/admin/panel/dashboard",
    exact: true,
    fullWidth: true,
    breadcrumb: "Dashboard",
    component: Dashboard,
    roles: [ADMIN, OWNER, MANAGER],
  },
  {
    path: "/:locale/manage-account",
    exact: true,
    breadcrumb: "Manage Account",
    component: () => <Redirect to="/manage-account/personal-info" />,
    roles: USERS,
  },
  {
    path: "/:locale/manage-account/personal-info",
    exact: true,
    breadcrumb: "Personal Info",
    component: PersonalInfo,
    roles: USERS,
    complexContent: true,
  },
  {
    path: "/:locale/manage-account/company",
    exact: true,
    breadcrumb: "Company",
    component: CompanyInfo,
    roles: [OWNER, ADMIN, MANAGER],
    complexContent: true,
  },
  {
    path: "/:locale/manage-account/pw-change",
    exact: true,
    breadcrumb: "Password change",
    component: ChangePassword,
    roles: USERS,
  },
  {
    path: "/:locale/manage-account/account-delete",
    exact: true,
    breadcrumb: "Delete account",
    component: DeleteAccount,
    roles: USERS,
  },
  {
    path: "/:locale/manage-account/exp-agreement",
    exact: true,
    breadcrumb: "Expert's Agreement",
    component: ExpertAgreement,
    roles: EXPERT,
  },
  {
    path: "/:locale/manage-account/bank-details",
    exact: true,
    breadcrumb: "Bank Detials",
    component: AccountBankDetails,
    roles: [EXPERT, MANAGER, OWNER],
  },
  {
    path: "/:locale/expert-analytics/sales",
    exact: true,
    breadcrumb: "Sales",
    component: ExpertSales,
    roles: EXPERT,
    complexContent: true,
  },
  {
    path: "/:locale/expert-analytics/active-students",
    exact: true,
    breadcrumb: "Active students",
    component: ExpertActiveStudents,
    roles: EXPERT,
    complexContent: true,
  },
  {
    path: "/:locale/course-creation",
    exact: true,
    breadcrumb: "Course creation",
    component: CourseCreation,
    roles: EXPERT,
    withoutSidebar: true,
    withoutBg: true,
    fullWidth: true,
  },
  {
    path: "/:locale/experts-cabinet/how-to-create-course",
    exact: true,
    breadcrumb: "Course create FAQ",
    component: CourseCreateFAQ,
    roles: EXPERT,
    withoutBg: true,
  },
  {
    path: "/:locale/admin/panel",
    exact: true,
    // breadcrumb: 'Admin panel',
    fullWidth: true,
    breadcrumb: "Dashboard",
    component: Dashboard,
    // component: () => <Redirect to="/admin/panel/dashboard" />,
    roles: [ADMIN, OWNER, MANAGER],
  },
  {
    path: "/:locale/admin/panel/team",
    exact: true,
    breadcrumb: "Admin panel team",
    component: () => <UsersList />,
    roles: [ADMIN, OWNER, MANAGER],
  },
  {
    path: "/:locale/admin/panel/team/:id",
    exact: true,
    breadcrumb: "Admin panel team",
    component: () => <UserView />,
    roles: [ADMIN, OWNER, MANAGER],
  },

  {
    path: "/:locale/admin/panel/students",
    exact: true,
    breadcrumb: "Admin panel students",
    component: () => <UsersList userRole="student" />,
    roles: [ADMIN, OWNER, MANAGER],
  },
  {
    path: "/:locale/admin/panel/students/:id",
    exact: true,
    breadcrumb: "Admin panel students",
    component: () => <UserView />,
    roles: [ADMIN, OWNER, MANAGER],
  },
  {
    path: "/:locale/admin/panel/experts",
    exact: true,
    breadcrumb: "Admin panel experts",
    component: () => <UsersList userRole="expert" />,
    roles: [ADMIN, OWNER, MANAGER],
  },
  {
    path: "/:locale/admin/panel/experts/:id",
    exact: true,
    breadcrumb: "Admin panel experts",
    component: () => <UserView />,
    roles: [ADMIN, OWNER, MANAGER],
  },
  {
    path: "/:locale/admin/panel/toppings",
    exact: true,
    breadcrumb: "Admin panel toppings",
    component: () => <ToppingsList />,
    roles: [ADMIN, OWNER, MANAGER],
  },
  {
    path: "/:locale/admin/panel/toppings/:id",
    exact: true,
    breadcrumb: "Topping",
    component: () => <ToppingEdit />,
    roles: [ADMIN, OWNER, MANAGER],
  },
  {
    path: "/:locale/admin/panel/deleted-courses",
    exact: true,
    breadcrumb: "Admin panel courses",
    component: () => <DeletedCoursesList />,
    roles: ADMIN,
  },
  {
    path: "/:locale/admin/panel/deleted-courses/:id",
    exact: true,
    breadcrumb: "Admin panel courses",
    component: () => <DeletedCourseView />,
    roles: ADMIN,
  },
  {
    path: "/:locale/admin/panel/courses",
    exact: true,
    breadcrumb: "Admin panel courses",
    component: () => <CoursesList />,
    roles: [ADMIN, OWNER, MANAGER],
  },
  {
    path: "/:locale/admin/panel/course/:id",
    exact: true,
    breadcrumb: "Admin courses details",
    component: () => <CourseEdit roles="admin" />,
    roles: [ADMIN, OWNER, MANAGER],
    fullWidth: true,
  },
  {
    path:
      "/:locale/admin/panel/:quizParent(course|toppings)/:courseId/quiz/:quizId",
    exact: true,
    breadcrumb: "Admin quiz details",
    component: QuizEdit,
    roles: [ADMIN, OWNER, MANAGER],
    fullWidth: true,
  },
  {
    path: "/:locale/admin/panel/currency-rates",
    exact: true,
    breadcrumb: "Admin panel currency rates",
    component: () => <CurrencyRates />,
    roles: ADMIN,
  },
  {
    path: "/:locale/admin/panel/discounts",
    exact: true,
    component: DiscountsList,
    roles: ADMIN,
  },
  {
    path: "/:locale/admin/panel/discounts/create",
    exact: true,
    component: () => <DiscountPage isNewDiscount />,
    roles: ADMIN,
  },
  {
    path: "/:locale/admin/panel/discounts/edit/:id",
    exact: true,
    breadcrumb: "Manage Plan",
    component: DiscountPage,
    roles: ADMIN,
  },
  {
    path: "/:locale/admin/panel/plans",
    breadcrumb: "Payment plans",
    exact: true,
    component: PlansList,
    roles: ADMIN,
  },
  {
    path: "/:locale/admin/panel/categories",
    breadcrumb: "Categories",
    exact: true,
    component: CategoriesList,
    roles: [ADMIN, OWNER, MANAGER],
  },
  {
    path: "/:locale/admin/panel/companies",
    breadcrumb: "Companies",
    exact: true,
    fullWidth: true,
    component: CompaniesList,
    roles: ADMIN,
  },
  {
    path: "/:locale/admin/panel/companies/edit/:id",
    exact: true,
    breadcrumb: "Manage Company",
    component: CompanyView,
    roles: [ADMIN],
  },
  {
    path: "/:locale/admin/panel/categories/edit/:id",
    exact: true,
    breadcrumb: "Manage Category",
    component: CategoriesPage,
    roles: [ADMIN, OWNER, MANAGER],
  },
  {
    path: "/:locale/admin/panel/plans/edit/:id",
    exact: true,
    breadcrumb: PlanEditBreadcrumb,
    component: () => <PlanEdit />,
    roles: ADMIN,
  },
  {
    path: "/:locale/experts-cabinet/",
    exact: true,
    // breadcrumb: "Expert's cabinet",
    component: () => <Redirect to="/experts-cabinet/calendar" />,
    roles: [EXPERT, OWNER, MANAGER],
  },
  {
    path: "/:locale/admin/panel/calendar",
    exact: true,
    breadcrumb: "Calendar",
    component: ExpertsCalendar,
    roles: [ADMIN, EXPERT, OWNER, MANAGER],
    complexContent: true,
  },
  {
    path: "/:locale/experts-cabinet/subscription",
    exact: true,
    breadcrumb: () => (
      <FormattedMessage {...messages.expertSubscriptionsBreadcrumb} />
    ),
    component: ExpertsFee,
    // fullWidth: true,
    roles: [EXPERT, OWNER, MANAGER],
  },
  {
    path: "/:locale/experts-cabinet/subscription/:id",
    exact: true,
    breadcrumb: PlanEditBreadcrumb,
    component: ExpertSubscription,
    roles: [EXPERT, OWNER, MANAGER],
  },
  {
    path: "/:locale/experts-cabinet/subscription/:id/update-payment-details",
    exact: true,
    breadcrumb: "Payment details",
    component: ExpertPaymentDetails,
    roles: [EXPERT, OWNER, MANAGER],
    // fullWidth: true,
  },
  {
    path: "/:locale/experts-cabinet/courses",
    exact: true,
    breadcrumb: "Draft && Active",
    component: ExpertsCourses,
    roles: [EXPERT, OWNER, MANAGER],
  },
  {
    path: "/:locale/experts-cabinet/toppings",
    exact: true,
    breadcrumb: "Draft && Active",
    component: ExpertsToppings,
    roles: [EXPERT, OWNER, MANAGER],
  },
  {
    path: "/:locale/experts-cabinet/calendar",
    exact: true,
    breadcrumb: "Calendar",
    component: ExpertsCalendar,
    roles: [EXPERT, OWNER, MANAGER],
    complexContent: true,
  },
  {
    path: "/:locale/experts-cabinet/course/",
    exact: true,
    component: () => <Redirect to="/course-creation" />,
    roles: [EXPERT, OWNER, MANAGER],
  },
  {
    path: "/:locale/experts-cabinet/course/:id/edit",
    exact: true,
    breadcrumb: "Course edit",
    component: CourseEdit,
    roles: EXPERT,
    withoutBg: true,
    fullWidth: true,
    complexContent: true,
  },
  {
    path: "/:locale/experts-cabinet/toppings/:id/",
    exact: true,
    breadcrumb: "Topping edit",
    component: ToppingEdit,
    roles: [EXPERT, OWNER, MANAGER],
    withoutBg: true,
    fullWidth: true,
    complexContent: true,
  },
  {
    path:
      "/:locale/experts-cabinet/:quizParent(course|toppings)/:courseId/quiz/:quizId",
    exact: true,
    breadcrumb: "Course edit",
    component: QuizEdit,
    roles: [EXPERT, OWNER, MANAGER],
    withoutBg: true,
    fullWidth: true,
    withoutSidebar: true,
    complexContent: true,
  },
  {
    path: "/:locale/experts-cabinet/course/:id/homeworks/",
    exact: true,
    breadcrumb: "Homeworks",
    component: Homeworks,
    roles: [EXPERT, OWNER, MANAGER],
    complexContent: true,
  },
  {
    path: [
      "/:locale/student-cabinet/",
      "/:locale/student-cabinet/purchased-courses/",
      "/:locale/student-cabinet/purchased-courses/:id/",
      "/:locale/student-cabinet/purchased-courses/:id/:interactionLevel",
    ],
    exact: true,
    component: () => <Redirect to="/student-cabinet/courses/:id/materials" />,
    roles: USERS,
    withoutBg: true,
    fullWidth: true,
  },

  // Old Way
  // Should be covered with redirects
  {
    path:
      "/:locale/student-cabinet/purchased-courses/:id/:interactionLevel/materials",
    exact: true,
    component: () => (
      <Redirect to="/:locale/student-cabinet/courses/:id/materials" />
    ),
    roles: USERS,
    withoutBg: true,
    fullWidth: true,
  },
  {
    path:
      "/:locale/student-cabinet/purchased-courses/:id/:interactionLevel/lecture/:lectureId/lecture-video/:itemId",
    exact: true,
    component: () => (
      <Redirect to="/:locale/student-cabinet/courses/:id/lecture/:lectureId/lecture-video/:itemId" />
    ),
    roles: USERS,
    withoutBg: true,
  },
  {
    path:
      "/:locale/student-cabinet/purchased-courses/:id/:interactionLevel/lecture/:lectureId/lecture-article/:itemId",
    exact: true,
    component: () => (
      <Redirect to="/:locale/student-cabinet/courses/:id/lecture/:lectureId/lecture-article/:itemId" />
    ),
    roles: USERS,
    withoutBg: true,
  },
  {
    path:
      "/:locale/student-cabinet/purchased-courses/:id/:interactionLevel/lecture/:lectureId/lecture-audio/:itemId",
    exact: true,
    component: () => (
      <Redirect to="/:locale/student-cabinet/courses/:id/lecture/:lectureId/lecture-audio/:itemId" />
    ),
    roles: USERS,
    withoutBg: true,
  },
  {
    path:
      "/:locale/student-cabinet/purchased-courses/:id/:interactionLevel/lecture/:lectureId/lecture-quiz/:itemId",
    exact: true,
    component: () => (
      <Redirect to="/:locale/student-cabinet/courses/:id/lecture/:lectureId/lecture-quiz/:itemId" />
    ),
    roles: USERS,
    withoutBg: true,
  },

  // New Way
  {
    path: ["/:locale/student-cabinet/courses/:id/materials"],
    exact: false,
    breadcrumb: "Purchased Courses",
    component: PurchasedCourses,
    roles: USERS,
    withoutBg: true,
    fullWidth: true,
  },
  {
    path:
      "/:locale/student-cabinet/courses/:id/lecture/:lectureId/lecture-video/:itemId",
    exact: true,
    breadcrumb: "Lecture video",
    component: CourseModuleStructure,
    roles: USERS,
    withoutBg: true,
  },
  {
    path:
      "/:locale/student-cabinet/courses/:id/lecture/:lectureId/lecture-article/:itemId",
    exact: true,
    breadcrumb: "Lecture article",
    component: CourseModuleStructure,
    roles: USERS,
    withoutBg: true,
  },
  {
    path:
      "/:locale/student-cabinet/courses/:id/lecture/:lectureId/lecture-audio/:itemId",
    exact: true,
    breadcrumb: "Lecture audio",
    component: CourseModuleStructure,
    roles: USERS,
    withoutBg: true,
  },
  {
    path:
      "/:locale/student-cabinet/courses/:id/lecture/:lectureId/lecture-quiz/:itemId",
    exact: true,
    breadcrumb: "Lecture quiz",
    component: CourseModuleStructure,
    roles: USERS,
    withoutBg: true,
  },
  {
    path: "/:locale/student-cabinet/toppings/:id",
    exact: true,
    breadcrumb: "Toppings",
    component: ToppingDetails,
    roles: USERS,
    complexContent: true,
    withoutBg: true,
  },
  {
    path: "/:locale/student-cabinet/calendar",
    exact: true,
    breadcrumb: "Student  Calendar",
    component: StudentCalendar,
    roles: USERS,
  },
  {
    path: "/:locale/student-cabinet/toppings",
    exact: true,
    breadcrumb: "Toppings",
    component: StudentToppings,
    roles: USERS,
  },
  {
    path: "/:locale/student-cabinet/courses",
    exact: true,
    component: StudentCourses,
    roles: USERS,
  },
  {
    path: "/:locale/checkout/courses",
    exact: true,
    breadcrumb: "Checkout",
    component: CheckoutCourses,
    roles: USERS,
    withoutSidebar: true,
    withoutBg: true,
    fullWidth: true,
  },
  {
    path: "/:locale/checkout/subscriptions",
    exact: true,
    breadcrumb: "Checkout",
    component: CheckoutSubscription,
    roles: USERS,
    withoutSidebar: true,
    withoutBg: true,
    fullWidth: true,
  },
  {
    path: "/:locale/publisher-webinar/:courseId/:eventId",
    exact: true,
    breadcrumb: "Publisher webinar",
    component: PublisherWebinar,
    roles: USERS,
    withoutSidebar: true,
    withoutBg: true,
    fullWidth: true,
  },
  {
    path: "/:locale/subscriber-webinar/:courseId/:eventId",
    exact: true,
    breadcrumb: "Subcriber webinar",
    component: SubscriberWebinar,
    roles: USERS,
    withoutSidebar: true,
    withoutBg: true,
    fullWidth: true,
  },
];

export default privateRoutes;
