// Core
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// Components
import Tabs from 'components/Tabs';

// Other
import { FormattedMessage } from 'react-intl';
import { messages } from './messages';

class TabsComponent extends PureComponent {
  handleChange = (e, value) => {
    const { handleSwitch } = this.props;
    handleSwitch(value);
  };

  render() {
    const { activeTab, disabledTab } = this.props;
    const {
      tabs = [
        {
          label: <FormattedMessage {...messages.invoices} />,
          value: 'userInfo'
        },
        {
          label: <FormattedMessage {...messages.team} />,
          value: 'userCourses'
        }
      ]
    } = this.props;
    return (
      <Tabs
        tabs={tabs}
        value={activeTab}
        handleChange={this.handleChange}
        disabledTab={disabledTab}
      />
    );
  }
}

export default TabsComponent;
