import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  d: {
    id: 'app.components.CourseCard.d',
    defaultMessage: 'd',
  },
  h: {
    id: 'app.components.CourseCard.h',
    defaultMessage: 'h',
  },
  create: {
    id: 'app.components.LecturesCounter.create',
    defaultMessage: 'Create',
  },
  zerohHzeromM: {
    id: 'app.components.LecturesCounter.zerohHzeromM',
    defaultMessage: '0h 0m',
  },
  lecture: {
    id: 'app.components.CourseCard.lect',
    defaultMessage: 'lect.',
  },
  section: {
    id: 'app.components.CourseCard.sect',
    defaultMessage: 'sect.',
  },
  lectureOption: {
    id: 'app.components.LectureTypeSelectore.lecture',
    defaultMessage: 'Lecture',
  },
  sectionOption: {
    id: 'app.components.LectureTypeSelectore.section',
    defaultMessage: 'Section',
  },
  m: {
    id: 'app.components.LecturesCounter.m',
    defaultMessage: 'm',
  },
});
