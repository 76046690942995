// Core
import React, { memo } from 'react';
import { compose } from 'react-apollo';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';

// Components
import { IconText } from 'routes/Checkout/CheckoutLists/Subscriptions';
import CourseIcon from 'routes/Checkout/CheckoutLists/Courses/icons/Course.js';
import ToppingIcon from 'routes/Checkout/CheckoutLists/Courses/icons/Topping.js';
import Card from 'components/Card';
import Text from 'components/Text';
import Image from 'components/Image';
import Cross from 'components/Icon/SvgCss/Cross';

// Other
import { useConvertedPrice } from 'containers/CurrencyProvider/useConvertedPrice';
import { withShoppingCart } from 'containers/ShoppingCartProvider/withShoppingCart';
import {Grey, PurpleLight} from 'components/Icon/color';
import { messages } from 'routes/Checkout/messages.js';

// Styles and Assets
import './index.scss';

const CloseButton = ({ handleClick }) => (
  <button
    type="button"
    onClick={handleClick}
  >
    <Cross width="10" height="10" color={Grey} hoverColor={PurpleLight} />
  </button>
);

const PREFIX = 'sk-checkout-courses-list';
export const CoursesList = ({
  items,
  intl: { formatMessage },
  ...rest
}) => {
  return (
    <Card classes={`${PREFIX}`}>
      <div className={`${PREFIX}__scroll-window`}>
        {items.map(item => (<CheckoutCourse key={item.courseId} formatMessage={formatMessage} {...item} {...rest} />))}
      </div>
    </Card>
  );
};

const COURSES_TYPE = {
  course: CourseIcon,
  masterclass: ToppingIcon,
  podcast: ToppingIcon,
  lecture: ToppingIcon,
};

const COURSE_PREFIX = 'sk-checkout-course'
export const CheckoutCourse = ({
  courseId,
  interactionLevel,
  coursePhoto,
  title,
  price,
  priceWithDiscount,
  withDiscount,
  shoppingCartContext: {
    handleCartItemClick,
    handleRemoveCourseFromCart,
  },
  formatMessage,
  ...props
}) => {
  const Icon = COURSES_TYPE[interactionLevel] || COURSES_TYPE.course;

  return (
    <div className={`${COURSE_PREFIX}`} onClick={!(['podcast', 'lecture', 'masterclass'].includes(interactionLevel)) && handleCartItemClick(courseId)}>
      <Image src={coursePhoto || '/assets/images/сourse-photo-placeholder.jpg'} />
      <div className={`${COURSE_PREFIX}__details`}>
        <Text classes={`${COURSE_PREFIX}__details__title`}>{title}</Text>
        <IconText icon={Icon} className={`${COURSE_PREFIX}__details__course-type`}>{formatMessage(messages[interactionLevel])}</IconText>
        <PriceBlock withDiscount={withDiscount} price={price} priceWithDiscount={priceWithDiscount} />
      </div>
      <CloseButton handleClick={handleRemoveCourseFromCart({ courseId })} />
    </div>
  );
};

const PRICE_PREFIX = `${COURSE_PREFIX}-price`;
const PriceBlock = ({ price, priceWithDiscount, withDiscount, small }) => {
  const parsedPrice = useConvertedPrice({ price, isBeautyPrice: true });
  const parsedPriceWithDiscount = useConvertedPrice({ price: priceWithDiscount, isBeautyPrice: true });

  const hasDiscount = withDiscount && (priceWithDiscount == 0 || !!priceWithDiscount) && price !== priceWithDiscount;

  return (
    <div className={`${PRICE_PREFIX}`}>
      {hasDiscount && (
        <Text classes={`${PRICE_PREFIX}__price-with-discount`} style={{ color: '#30d5c8' }}>
          {parsedPriceWithDiscount}
        </Text>
      )}
      <Text classes={classNames(`${PRICE_PREFIX}__original-price`, {
          [`${PRICE_PREFIX}__price-crossed`]: hasDiscount,
        })}
      >
        {parsedPrice}
      </Text>
    </div>
  )
};

const enhancer = compose(
  memo,
  injectIntl,
  withShoppingCart
)

export default enhancer(CoursesList);
