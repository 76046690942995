// Core
import React, { useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { compose } from 'react-apollo';

// Components
import SidebarMenuItem from 'containers/Sidebar/SidebarContent/SidebarMenu/SidebarMenuItem';
import Image from 'components/Image';

// Icons
import Calendar from 'components/Icon/Svg/Calendar';

// Other
import { messages } from 'containers/Sidebar/SidebarContent/StudentCabinetSidebar/messages';

import './StudentWebinars.scss';

const Icon = () => (<Image className='sk-student-sidebar-webinar-icon' src='/assets/images/calendar-icon.svg' />);

const StudentWebinars = ({
  intl: { formatMessage }, history
}) => {
  const handleClick = useCallback(() => {
    history.push('/student-cabinet/calendar');
  }, []);
  const isOpen = history?.location?.pathname.includes('student-cabinet/calendar');

  return (
    <SidebarMenuItem
      open={isOpen}
      icon={Icon}
      label={formatMessage(messages.webinars)}
      expandable={false}
      handleClick={handleClick}
    />
  );
}

const enhancer = compose(
  withRouter,
  injectIntl
)

export default enhancer(StudentWebinars);
