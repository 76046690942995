import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  yourChangesAreNotSaved: {
    id: 'app.routes.ExpertsCabinet.ConfirmModalClose.yourChangesAreNotSaved',
    defaultMessage: 'Your changes are not saved',
  },
  areYouSureYouWant: {
    id: 'app.routes.ExpertsCabinet.ConfirmModalClose.areYouSureYouWant',
    defaultMessage: 'Are you sure you want to close this popup?',
  },
  backToArticle: {
    id: 'app.routes.ExpertsCabinet.ConfirmModalClose.backToArticle',
    defaultMessage: 'Back to the article',
  },
});
