import React from 'react';
import PropTypes from 'prop-types';

const Play = ({ isPlay }) => {
  if (!isPlay) {
    return (
      <svg
        className="sk-video-controllers_icon"
        width="13"
        height="18"
        viewBox="0 0 13 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className="outline"
          d="M11.7174 8.12348L11.7173 8.12363L11.7285 8.13158C12.0479 8.35972 12.1489 8.58426 12.1845 8.72037L12.6682 8.59384L12.1845 8.72037C12.2312 8.89918 12.2312 9.10072 12.1844 9.27955C12.1488 9.41569 12.0479 9.64027 11.7284 9.86846L11.7283 9.86831L11.7174 9.87654L1.74487 17.4182C1.69139 17.4551 1.61942 17.4801 1.51026 17.4919C1.41931 17.5017 1.33868 17.5005 1.23667 17.4991C1.19581 17.4985 1.15152 17.4978 1.10177 17.4978C0.908492 17.4978 0.801164 17.4525 0.739946 17.4117C0.676778 17.3697 0.627644 17.3089 0.58902 17.2261C0.503508 17.0428 0.5 16.8232 0.5 16.7124V1.28757C0.5 1.15294 0.504272 0.937368 0.587582 0.761845C0.624593 0.683869 0.671757 0.626714 0.73399 0.586524C0.79537 0.546885 0.904659 0.502194 1.10177 0.502194C1.15174 0.502194 1.19619 0.50155 1.23717 0.500956C1.339 0.49948 1.41947 0.498314 1.51033 0.508106C1.61945 0.519866 1.69138 0.544836 1.74486 0.581724L11.7174 8.12348Z"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className="filled"
          d="M11.7174 8.12348L11.7173 8.12363L11.7285 8.13158C12.0479 8.35972 12.1489 8.58426 12.1845 8.72037L12.6682 8.59384L12.1845 8.72037C12.2312 8.89918 12.2312 9.10072 12.1844 9.27955C12.1488 9.41569 12.0479 9.64027 11.7284 9.86846L11.7283 9.86831L11.7174 9.87654L1.74487 17.4182C1.69139 17.4551 1.61942 17.4801 1.51026 17.4919C1.41931 17.5017 1.33868 17.5005 1.23667 17.4991C1.19581 17.4985 1.15152 17.4978 1.10177 17.4978C0.908492 17.4978 0.801164 17.4525 0.739946 17.4117C0.676778 17.3697 0.627644 17.3089 0.58902 17.2261C0.503508 17.0428 0.5 16.8232 0.5 16.7124V1.28757C0.5 1.15294 0.504272 0.937368 0.587582 0.761845C0.624593 0.683869 0.671757 0.626714 0.73399 0.586524C0.79537 0.546885 0.904659 0.502194 1.10177 0.502194C1.15174 0.502194 1.19619 0.50155 1.23717 0.500956C1.339 0.49948 1.41947 0.498314 1.51033 0.508106C1.61945 0.519866 1.69138 0.544836 1.74486 0.581724L11.7174 8.12348Z"
          fill="white"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
  return (
    <svg
      className="sk-video-controllers_icon"
      width="7"
      height="17"
      viewBox="0 0 7 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.91833 0.520724C5.91833 0.234719 6.14565 0 6.43166 0C6.71766 0 6.95238 0.234719 6.95238 0.520724V16.4793C6.95238 16.7653 6.71766 17 6.43166 17C6.14565 17 5.91833 16.7653 5.91833 16.4793V0.520724ZM0 0.520724C0 0.234719 0.227326 0 0.513331 0C0.799337 0 1.03406 0.234719 1.03406 0.520724V16.4793C1.03406 16.7653 0.799337 17 0.513331 17C0.227326 17 0 16.7653 0 16.4793V0.520724Z"
        fill="white"
      />
    </svg>
  );
};

Play.propTypes = {
  isPlay: PropTypes.bool,
};

export default Play;
