// Core
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router';

// Components
import Image from 'components/Image';
// import MenuLightBulb from 'components/Icon/Svg/MenuLightBulb';
import Loader from 'components/Loader';
import SidebarMenuItem from 'containers/Sidebar/SidebarContent/SidebarMenu/SidebarMenuItem';
import SimpleSidebarMenu from 'containers/Sidebar/SidebarContent/SimpleSidebarMenu';

// Other
import { keysToCamel } from 'utils/helpers';
import { renderTrimmedText } from 'utils/helpers/index';
import { messages } from 'containers/Sidebar/SidebarContent/StudentCabinetSidebar/messages';

// Styles and Assets
import './index.scss';

const Icon = () => (<Image className='sk-student-sidebar-webinar-icon' src='/assets/images/topping-icon.svg' />);

const getMenuItems = list =>
  list.map(({ courseTitle, courseId, mappingId, interactionLevel }) => ({
    id: courseId,
    path: `/student-cabinet/toppings/${mappingId}`,
    state: 'closed',
    label: renderTrimmedText(courseTitle, 45),
  }));

const renderSimpleSidebarMenu = (open, list, handleSubMenuTitleClick) => {
  if (open && list?.length) {
    return (
      <SimpleSidebarMenu
        menuItems={getMenuItems(keysToCamel(list))}
        handleSubMenuTitleClick={handleSubMenuTitleClick}
      />
    );
  }
  return null;
};

const StudentToppings = ({
  history,
  open,
  handleClick,
  list,
  loading,
  handleSubMenuTitleClick,
  openedSubItem
}) => {
  const goToListPage = useCallback((path) => () => {
    history.push(path);
  }, [history]);

  return (
    <div>

      <FormattedMessage {...messages.toppings}>
        {msg => (
          <SidebarMenuItem
            icon={Icon}
            label={msg}
            expandable={!!list?.length}
            handleClick={goToListPage('/student-cabinet/toppings')}
            open={open}
            onChevronHandler={handleClick}
          >
            {loading && open ? (
              <Loader small />
            ) : (
              renderSimpleSidebarMenu(
                open,
                list,
                handleSubMenuTitleClick
              )
            )}
          </SidebarMenuItem>
        )}
      </FormattedMessage>
    </div>
  );
}

export default withRouter(StudentToppings);
