// Core
import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';

// Components
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Text from 'components/Text';

// Other
import { sharedMessages } from 'services/i18n/sharedMessages/messages';

// Styles and Assets
import './DeletedCoursesTableRow.scss';

const classes = {
  tableCell: { root: 'mui-override sk-adminCoursesListTable' },
};

const DeletedCoursesTableRow = memo(({ item, index, arr, intl: { formatMessage } }) => {
  return(
    <TableRow key={item.id}>
      <TableCell
        classes={classes.tableCell}
        className={`sk-adminCoursesListTable__tableBodyCellLeft ${index ===
          arr.length - 1 && '_last'}`}
        align="left"
      >
        <Link to={`/admin/panel/deleted-courses/${item.id}`}>
          <Text size="basic" className="sk-adminCoursesListTable__link">
            {item.title}
          </Text>
        </Link>
      </TableCell>
      <TableCell
        classes={classes.tableCell}
        className={`sk-adminCoursesListTable__tableBodyCellLeft ${index ===
          arr.length - 1 && '_last'}`}
        align="left"
      >
        <Text size="basic">
          {formatMessage(sharedMessages[item?.category?.title])}
        </Text>
      </TableCell>
      <TableCell
        classes={classes.tableCell}
        className={`sk-adminCoursesListTable__tableBodyCellLeft ${index ===
          arr.length - 1 && '_last'}`}
        align="left"
      >
        <Text size="basic">
          {formatMessage(sharedMessages[item?.subcategory?.title])}
        </Text>
      </TableCell>
      <TableCell
        classes={classes.tableCell}
        className={`sk-adminCoursesListTable__tableBodyCellLeft ${index ===
          arr.length - 1 && '_last'}`}
        align="left"
      >
        <Text size="basic">{item.author && item.author !== null ? `${item.author.first_name} ${item.author.last_name
        }` : 'DELETED'}</Text>
      </TableCell>
    </TableRow>
  );
});

export default injectIntl(DeletedCoursesTableRow);
