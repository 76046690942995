// Core
import React, { memo } from 'react';
import PropTypes from 'prop-types';

// Components
import Text from 'components/Text';
import Applause from 'components/Icon/Svg/Applause';

// Styles and Assets
import './AuthorRating.scss';

// Other
import { FormattedMessage } from 'react-intl';
import { messages } from 'routes/CourseDetails/Author/AuthorRating/messages';

export const AuthorRating = ({ rating }) => (
  <div className="sk-course-details-author-rating">
    <div className="sk-course-details-author-rating__item">
      <div className="sk-course-details-author-rating__icon">
        <Applause width="34" height="37" />
      </div>
      <div className="sk-course-details-author-rating__entity">
        <Text
          size="small"
          bold
          classes="sk-course-details-author-rating__counter"
        >
          {rating?.apples || 0}
        </Text>
      </div>
    </div>
  </div>
);

AuthorRating.propTypes = {
  rating: PropTypes.object,
};

export default memo(AuthorRating);
