import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  anyDuration: {
    id: 'app.components.DurationRangeSelect.anyDuration',
    defaultMessage: 'Duration',
  },
  short: {
    id: 'app.components.DurationRangeSelect.short',
    defaultMessage: '<2h',
  },
  average: {
    id: 'app.components.DurationRangeSelect.average',
    defaultMessage: '2h-5h',
  },
  long: {
    id: 'app.components.DurationRangeSelect.long',
    defaultMessage: '>5h',
  },
});
