// Core
import React, { memo, useState, useEffect, useCallback } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { withFormik } from 'formik';
import { compose } from 'react-apollo';

// Components
import Text from 'components/Text';
import ToolTip from 'components/ToolTip';
import TextAreaInput from 'components/Input/Material/TextAreaInput';
import Heading from 'components/Heading';

// Other
import { withSharedState } from 'containers/SharedStateProvider/withSharedState';
import { withCourseEditContext } from 'routes/ExpertsCabinet/CourseEdit/Context';
import { messages } from 'routes/ExpertsCabinet/CourseEdit/Details/CourseInformation/messages';
import { getYupSchema } from 'services/yup';

// Styles and Assets
import './index.scss';

const getIntitalState = ({
  descriptionForAdmin,
}) => {
  return {
    descriptionForAdmin: descriptionForAdmin || '',
  };
};

const CourseAdminInformationForm = ({
  isValid,
  values,
  errors,
  touched,
  validateForm,
  handleBlur,
  setFieldValue,
  setErrors,
  setTouched,
  disableAll,
  // refetchCourse,
  sharedStateContext: { updateSharedState },
  courseEditContext: {
    course,
    handleCourseUpdate,
    bindCourseMethod,
  },
  intl: { formatMessage },
}) => {

  useEffect(() => {
    let isValid = true;

    Object.keys(errors).forEach(error => {
      if (touched[error]) {
        isValid = false;
      }
    });

    const courseAdminInformation = { isValid };
    updateSharedState({ courseAdminInformation });
    return () => {
      updateSharedState({ courseAdminInformation: { isValid: true } });
    };
  }, [errors, touched]);

  const handleInputBlur = useCallback(name => async (inputValue, e) => {
    setFieldValue(name, inputValue, false);

    if (e) {
      handleBlur(e);
    }

    const errors = await validateForm({ ...values, [name]: inputValue });
    const hasError = errors[name];

    if (!hasError) handleCourseUpdate({ [name]: `${inputValue}` });
  }, [handleBlur, values, validateForm, handleCourseUpdate]);

  const handleFormValidation = useCallback(async () => {
    const errors = await validateForm(values);
    const isValid = Object.keys(errors).length == 0;

    if (!isValid) {
      const touched = {};

      for (const fieldName in errors) {
        touched[fieldName] = true;
      }

      setErrors(errors);
      setTouched(touched);
    }

    return isValid;
  }, [values, validateForm, setErrors]);
  bindCourseMethod({ method: 'validateCourseAdminInformation', handler: handleFormValidation });

  return (
    <>
      <Heading
        variant="h3"
        classes="sk-course-information-form-heading sk-course-information-form-heading__admin"
      >
        <FormattedMessage {...messages.descriptionForAdmin} />
      </Heading>
      <div className="sk-form__row">
        <div>
          <TextAreaInput
            name="descriptionForAdmin"
            value={values.descriptionForAdmin}
            onBlur={handleInputBlur('descriptionForAdmin')}
            placeholder={formatMessage(messages.courseDescForAdmin)}
            label={formatMessage(messages.courseDescForAdmin)}
            error={
              touched.descriptionForAdmin &&
              Boolean(errors.descriptionForAdmin)
            }
            disabled={disableAll}
            helperText={
              touched.descriptionForAdmin
                ? errors.descriptionForAdmin
                : ''
            }
          />
          <ToolTip
            wide
            triggerClasses="sk-course-information-form__trigger"
            iconTheme="orange"
          >
            <div className="sk-desc-for-admin">
              <Text classes="sk-desc-for-admin__heading" bold>
                <FormattedMessage {...messages.howAreUPlanning} />
              </Text>
              <Text classes="sk-desc-for-admin__subheading">
                <FormattedMessage {...messages.pleaseInclude} />
              </Text>
              <ul className="sk-desc-for-admin__list">
                <li>
                  – <FormattedMessage {...messages.plannedTotalCourse} />
                </li>
                <li>
                  – <FormattedMessage {...messages.numberOfLects} />
                </li>
                <li>
                  – <FormattedMessage {...messages.numberOfHws} />
                </li>
                <li>
                  – <FormattedMessage {...messages.freqAndLengthWebs} />
                </li>
                <li>
                  –{' '}
                  <FormattedMessage
                    {...messages.anythingElseYoudLikeToInclude}
                  />
                </li>
              </ul>
            </div>
          </ToolTip>
        </div>
      </div>
    </>
  );
};

const formikStateManagement = withFormik({
  enableReinitialize: false,
  mapPropsToValues: ({ courseEditContext: { course } }) =>  getIntitalState(course),
  displayName: 'CourseAdminInformationForm',
  validationSchema: () => getYupSchema('courseAdminInformationValidationSchema'),
  validateOnChange: false,
  validateOnBlur: false,
});

const enhancer = compose(
  memo,
  injectIntl,
  withSharedState,
  withCourseEditContext,
  formikStateManagement
);

export default enhancer(CourseAdminInformationForm);
