// Core
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// MaterialUI
import FormControl from '@material-ui/core/FormControl';
import MaterialSelect from '@material-ui/core/Select';
import MaterialMenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

// Helpers
import HelperText from 'components/Select/MaterialUI/HelperText';
import { mergeDeep } from 'utils/helpers/index';

// Components
import Toggler from 'components/Select/Toggler';
import Loader from 'components/Loader';

// Styles and Assets
import 'utils/styles/Materialized.scss';
import './MaterializedSelect.scss';

const menuItemClasses = isPlaceholderOptionHidden => ({
  gutters: 'mui-override sk-materialized-select sk-materialized-select-item',
  selected: classNames(
    'mui-override sk-materialized-select',
    'sk-materialized-select-item',
    'selected',
    {
      'sk-materialized-select-item_hidden': isPlaceholderOptionHidden,
    }
  ),
});

const defaultClasses = {
  form: { root: 'mui-override' },
  paper: '',
  selector: {},
  menuItem: {},
};
const defaultStyles = {
  form: {},
  selector: {},
  menuItem: {},
};

class MaterializedSelect extends PureComponent {
  handleInputEvents = handler => e => {
    const {
      target: { value },
    } = e;

    return handler(value);
  };

  render() {
    const {
      value,
      label,
      placeholder,
      options,
      input: InputComponent,
      iconComponent: IconComponent,
      fullWidth,
      renderValue,
      multiple,
      variant,
      displayEmpty,
      renderPlaceholder,
      helperText,
      error,
      valueKey,
      labelKey,
      handleBlur,
      handleChange,
      loading,
      disabled,
      withValidation,
      isPlaceholderOptionHidden,
      inlineStyles,
      classes,
      selectClasses,
      isSubcategoriesFilter,
    } = this.props;

    const iconComponentToRender = IconComponent && {
      IconComponent: () => <IconComponent disabled={disabled} />,
    };

    defaultClasses.menuItem = menuItemClasses(isPlaceholderOptionHidden);
    defaultClasses.selector = selectClasses;
    const paperClasses = classes.paper ? classes.paper.root : defaultClasses.paper;

    const formClasses = mergeDeep(defaultClasses.form, classes.form);
    if (!withValidation)
      formClasses.root = `${
        formClasses.root
      }sk-materialized-input-control_withoutValidation`;

    return (
      <FormControl
        style={mergeDeep(defaultStyles.form, inlineStyles.form)}
        classes={formClasses}
        fullWidth={fullWidth}
        disabled={disabled}
      >
        {label && (
          <InputLabel
            margin="dense"
            classes={{
              focused:
                'mui-override sk-materialized-input_label sk-materialized-input_focused',
              error: 'mui-override sk-materialized-input_error withError',
            }}
            error={error}
          >
            {label}
          </InputLabel>
        )}
        <MaterialSelect
          style={mergeDeep(defaultStyles.selector, inlineStyles.selector)}
          classes={mergeDeep(defaultClasses.selector, classes.selector)}
          disabled={disabled}
          displayEmpty={displayEmpty}
          variant={variant}
          multiple={multiple}
          value={value}
          onChange={this.handleInputEvents(handleChange)}
          onBlur={handleBlur ? this.handleInputEvents(handleBlur) : null}
          {...iconComponentToRender}
          fullWidth={fullWidth}
          input={InputComponent}
          renderValue={value?.length ? renderValue : renderPlaceholder}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
            classes: {
              paper: paperClasses
            }
          }}
        >
          {placeholder && (
            <MaterialMenuItem
              style={isPlaceholderOptionHidden ? { display: 'none' } : {}}
              classes={menuItemClasses(isPlaceholderOptionHidden)}
              key={`placeholder-${label}`}
              value=""
            >
              {placeholder}
            </MaterialMenuItem>
          )}
          {loading ? (
            <Loader small />
          ) : (
            options.map(
              ({ [valueKey]: optionValue, [labelKey]: optionLabel, hasCourses, disabled = false }) => {
                let menuItemDisables = disabled;
                if (isSubcategoriesFilter &&  !hasCourses) menuItemDisables = true;

                return (
                  <MaterialMenuItem
                    style={mergeDeep(
                      defaultStyles.menuItem,
                      inlineStyles.menuItem
                    )}
                    classes={mergeDeep(defaultClasses.menuItem, classes.menuItem)}
                    key={optionValue}
                    value={optionValue}
                    disabled={menuItemDisables}
                  >
                    {optionLabel}
                  </MaterialMenuItem>
                )
              }
            )
          )}
        </MaterialSelect>
        {withValidation && <HelperText error={error}>{helperText}</HelperText>}
      </FormControl>
    );
  }
}

MaterializedSelect.defaultProps = {
  options: [],
  withValidation: true,
  fullWidth: true,
  multiple: false,
  variant: 'standard',
  value: '',
  valueKey: 'value',
  labelKey: 'label',
  isPlaceholderOptionHidden: false,
  iconComponent: props => <Toggler {...props} />,
  inlineStyles: {},
  classes: {},
};

MaterializedSelect.propTypes = {
  options: PropTypes.array,
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.number,
  ]),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  handleChange: PropTypes.func,
  fullWidth: PropTypes.bool,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  displayEmpty: PropTypes.bool,
  input: PropTypes.element,
  selectClasses: PropTypes.object,
  inlineStyles: PropTypes.shape({
    form: PropTypes.object,
    selector: PropTypes.object,
    menuItem: PropTypes.object,
  }),
  classes: PropTypes.shape({
    form: PropTypes.object,
    selector: PropTypes.object,
    menuItem: PropTypes.object,
  }),
  iconComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  renderValue: PropTypes.any,
  renderPlaceholder: PropTypes.any,
  variant: PropTypes.string,
  isPlaceholderOptionHidden: PropTypes.bool,
  withValidation: PropTypes.bool,
};

export default MaterializedSelect;
