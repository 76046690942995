import React from 'react';
import PropTypes from 'prop-types';

import './Materials.scss';

const Materials = ({color = '#30d5c8'}) => {
  const index = Math.random();
  return(
    <svg class="materials-svg" width="23" height="28" viewBox="0 0 23 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g className="outlined">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.91061 1.5C2.1253 1.5 1.5 2.1253 1.5 2.91061V23.1961C1.5 24.8523 2.85551 26.2129 4.51856 26.2343L4.52068 26.2344L18.6201 26.2815H18.6206C20.302 26.2812 21.6583 24.9248 21.6583 23.2433V9.18498C21.6583 9.14584 21.6568 9.10712 21.6538 9.06882L16.4709 9.02414C15.6298 9.02289 14.959 8.35133 14.959 7.50986V1.50142C14.9372 1.50047 14.9153 1.5 14.8932 1.5H2.91061ZM15.9759 2.00932L21.1629 8.06455L16.4776 8.02416V8.02414H16.4733C16.1833 8.02414 15.959 7.79983 15.959 7.50986V1.98944L15.9732 2.00611L15.9732 2.00613L15.9759 2.00932ZM0.5 2.91061C0.5 1.57302 1.57302 0.5 2.91061 0.5H14.8932C15.6055 0.5 16.264 0.799934 16.7367 1.36024L16.7381 1.36198L22.0898 7.60954L22.0953 7.61592L22.1005 7.62247C22.4484 8.05727 22.6583 8.59022 22.6583 9.18498V23.2433C22.6583 25.4773 20.854 27.2815 18.6201 27.2815L18.6184 27.2815L4.51291 27.2343L4.50849 27.2343C2.3041 27.2074 0.5 25.4077 0.5 23.1961V2.91061ZM5 10.5C4.72386 10.5 4.5 10.7239 4.5 11C4.5 11.2761 4.72386 11.5 5 11.5H18C18.2761 11.5 18.5 11.2761 18.5 11C18.5 10.7239 18.2761 10.5 18 10.5H5ZM4.5 15C4.5 14.7239 4.72386 14.5 5 14.5H18C18.2761 14.5 18.5 14.7239 18.5 15C18.5 15.2761 18.2761 15.5 18 15.5H5C4.72386 15.5 4.5 15.2761 4.5 15ZM5 18.5C4.72386 18.5 4.5 18.7239 4.5 19C4.5 19.2761 4.72386 19.5 5 19.5H10C10.2761 19.5 10.5 19.2761 10.5 19C10.5 18.7239 10.2761 18.5 10 18.5H5Z" fill={color}/>
      </g>
      <g className="filled">
        <path d="M1 23.1949V2.9105C1 1.84911 1.84911 1 2.9105 1H14.8924C15.4585 1 15.9774 1.23586 16.3548 1.68401L21.7089 7.93442C21.9919 8.28822 22.1571 8.71277 22.1571 9.1845V23.242C22.1571 25.1997 20.5768 26.78 18.6191 26.78L4.51438 26.7328C2.58029 26.7092 1 25.1289 1 23.1949Z" fill={`url(#paint${index}0_linear)`} stroke="white" stroke-miterlimit="10"/>
        <path d="M15.4585 1.21191V7.5095C15.4585 8.07557 15.9066 8.52372 16.4727 8.52372L21.9448 8.57089" stroke="white" stroke-miterlimit="10"/>
        <path d="M5 11.5C4.72386 11.5 4.5 11.7239 4.5 12C4.5 12.2761 4.72386 12.5 5 12.5V11.5ZM18 12.5C18.2761 12.5 18.5 12.2761 18.5 12C18.5 11.7239 18.2761 11.5 18 11.5V12.5ZM5 12.5H18V11.5H5V12.5Z" fill="white"/>
        <path d="M5 15.5C4.72386 15.5 4.5 15.7239 4.5 16C4.5 16.2761 4.72386 16.5 5 16.5V15.5ZM18 16.5C18.2761 16.5 18.5 16.2761 18.5 16C18.5 15.7239 18.2761 15.5 18 15.5V16.5ZM5 16.5H18V15.5H5V16.5Z" fill="white"/>
        <path d="M5 19.5C4.72386 19.5 4.5 19.7239 4.5 20C4.5 20.2761 4.72386 20.5 5 20.5V19.5ZM10 20.5C10.2761 20.5 10.5 20.2761 10.5 20C10.5 19.7239 10.2761 19.5 10 19.5V20.5ZM5 20.5H10V19.5H5V20.5Z" fill="white"/>
      </g>
      <defs>
      <linearGradient id={`paint${index}0_linear`} x1="24.0727" y1="28.5153" x2="-2.53061" y2="25.8371" gradientUnits="userSpaceOnUse">
      <stop stop-color="#8F26AF"/>
      <stop offset="0.979167" stop-color="#141685"/>
      </linearGradient>
      </defs>
    </svg>
  )
}

export default Materials;
