import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';
import { injectIntl } from 'react-intl';

// Components
import Button from 'components/Button';
import Text from 'components/Text';

// Other
import { isBlank, } from 'utils/helpers';
import { messages } from 'routes/ExpertsCabinet/components/ConfirmDelete/messages';
import { sharedMessages } from 'services/i18n/sharedMessages/messages';

import './ConfirmDelete.scss';

const ConfirmDelete = ({ handleDelete, close, intl: { formatMessage, }, customCancelButtonKey }) => {
  const remove = async () => {
    close();
    await handleDelete({});
  };

  const cancelButtonKey = !isBlank(customCancelButtonKey) ? customCancelButtonKey : sharedMessages.cancelButton;

  return (
    <div className='mui-override sk-experts-cabinet-confirm-delete'>
      <Text className='mui-override sk-experts-cabinet-confirm-delete__text'>
        {formatMessage(messages.confirmMessage)}
      </Text>
      <div className='mui-override sk-experts-cabinet-confirm-delete__buttons'>
        <Button onClick={remove} size='small'variant="text"color="secondary" className='mui-override sk-experts-cabinet-confirm-delete__buttons__button'>
          {formatMessage(sharedMessages.deleteButton)}
        </Button>
        <Button onClick={close} size='small'variant="text"color="secondary" className='mui-override sk-experts-cabinet-confirm-delete__buttons__button'>
          {formatMessage(cancelButtonKey)}
        </Button>
      </div>
    </div>
  )
}

ConfirmDelete.propTypes = {
  handleDelete: PropTypes.func,
  close: PropTypes.func,
}

const enhancer = compose(
  injectIntl
)

export default enhancer(ConfirmDelete);
