import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  resubmit: {
    id: 'app.components.HWMarkForm.resubmit',
    defaultMessage: 'Resubmit',
  },
  submit: {
    id: 'app.components.HWMarkForm.submit',
    defaultMessage: 'Submit',
  },
  mark: {
    id: 'app.components.HWMarkForm.mark',
    defaultMessage: 'Mark',
  },
});
