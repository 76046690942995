// Core
import React, { memo } from 'react';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import { compose } from 'react-apollo';

// Components
import Text from 'components/Text';
import Card from 'components/Card';
import Image from 'components/Image';
// import CrossRounded from 'components/Icon/SvgCss/CrossRounded';

// Styles and Assets
import './index.scss';
import BillingPeriod from 'routes/Checkout/CheckoutLists/Subscriptions/icons/BillingPeriod.js';
import MaxCourses from 'routes/Checkout/CheckoutLists/Subscriptions/icons/MaxCourses.js';

// Other
import { useConvertedPrice } from 'containers/CurrencyProvider/useConvertedPrice';
import { withLanguage } from 'containers/LanguageProvider/withLanguage';
import { TRANSLATIONS as BILLING_PERIOD_TRANSLATINS } from 'routes/ExpertsCabinet/ExpertSubscription/ActiveSubsctiption'
import { messages as subscriptionMessages } from 'routes/ExpertsCabinet/ExpertSubscription/messages.js';
import { messages } from 'routes/Checkout/messages';

export const IconText = ({ icon: Icon, className, children, ...props }) => {
  return (
    <div className={className}>
      {Icon && <Icon {...props} />}
      <Text>{children}</Text>
    </div>
  )
};

const MAX_COURSES = {
  ru: {
    package: () => 'Неоганиченное количество курсов',
    constructor: (maxCourses) => `Количество курсов ${maxCourses}`,
  },
  en: {
    package: (maxCourses) => `unlimited number of courses`,
    constructor: (maxCourses) => `Number of courses ${maxCourses}`,
  }
};

const PREFIX = 'sk-checkout-subscription-item'
const Subscription = ({
  plan: { title },
  type,
  price,
  priceWithDiscount,
  withDiscount,
  billingPeriod,
  maxCourses,
  intl: { formatMessage },
  languageContext: { language },
  ...props
}) => {
  const maxCoursesText = MAX_COURSES[language][type](maxCourses)
  return (
    <Card classes={`${PREFIX}`}>
      <Image src='/assets/images/buy-subscription.svg' />
      <Text classes={`${PREFIX}__title`}>{formatMessage(subscriptionMessages.subscriptionTitle, { title: title[language] })}</Text>
      <div className={`${PREFIX}__details`}>
        <IconText icon={BillingPeriod} className={`${PREFIX}__details__billing-period`}>{BILLING_PERIOD_TRANSLATINS.billingPeriod[language][billingPeriod]}</IconText>
        <IconText icon={MaxCourses} className={`${PREFIX}__details__max-courses`}>{maxCoursesText}</IconText>
      </div>
      <PriceBlock withDiscount={withDiscount} price={price} priceWithDiscount={priceWithDiscount} />
    </Card>
  )
};

const enhancer = compose(
  memo,
  injectIntl,
  withLanguage,
);

export default enhancer(Subscription);





const PRICE_PREFIX = `${PREFIX}-price`;
const PriceBlock = ({ price, priceWithDiscount, withDiscount, small }) => {
  const parsedPrice = useConvertedPrice({ price, isBeautyPrice: true });
  const parsedPriceWithDiscount = useConvertedPrice({ price: priceWithDiscount, isBeautyPrice: true });

  const hasDiscount = withDiscount && (priceWithDiscount == 0 || !!priceWithDiscount) && price !== priceWithDiscount;

  return (
    <div className={`${PRICE_PREFIX}`}>
      {hasDiscount && (
        <Text classes={`${PRICE_PREFIX}__price-with-discount`} style={{ color: '#30d5c8' }}>
          {parsedPriceWithDiscount}
        </Text>
      )}
      <Text classes={classNames(`${PRICE_PREFIX}__original-price`, {
          [`${PRICE_PREFIX}__price-crossed`]: hasDiscount,
        })}
      >
        {parsedPrice}
      </Text>
    </div>
  )
};
