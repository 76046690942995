// Core
import React, { memo, Fragment } from 'react';
import PropTypes from 'prop-types';

// Components
import Text from 'components/Text';

// Styles and Assets
import './AddEntityButton.scss';

const Label = ({ children }) => (
  <Text size="small" classes="sk-add-entity-btn__text">
    <span className="sk-add-entity-btn__plus">+</span>
    <span>{children}</span>
  </Text>
);

const AddEntityButton = memo(({ label, handleAddClick }) => (
  <>
    {handleAddClick ? (
      <button
        className="sk-add-entity-btn"
        type="button"
        onClick={handleAddClick}
      >
        <Label>{label}</Label>
      </button>
    ) : (
      <Label>{label}</Label>
    )}
  </>
));

Label.propTypes = {
  children: PropTypes.string,
};

AddEntityButton.propTypes = {
  label: PropTypes.string,
  handleAddClick: PropTypes.func,
};

export default AddEntityButton;
