// Core
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

// Styles and Assets
import './PurchasedCoursesHero.scss';

// Components
import ContentHeader from 'containers/ContentHeader';
import Heading from 'components/Heading';
import StarRating from 'components/StarRating';
import Text from "components/Text";

// Other

import { FormattedMessage } from 'react-intl';
import { messages } from 'routes/StudentsCabinet/PurchasedCourses/PurchasedCoursesHero/messages';

class PurchasedCoursesHero extends PureComponent {
  render() {
    const {
      course: {
        title,
        id,
        ratingTotal: {
          averageScore,
          totalVotes
        }
      },
    } = this.props;
    return (
      <div className="sk-purchasedCoursesHero">
        <div className="sk-purchasedCoursesHero__wrapper sk-main-layout-wrapper">
          <div className="sk-purchasedCoursesHero__header ">
            <FormattedMessage {...messages.courseStudyMats}>
              {msg => (
                <ContentHeader way={`${title} / ${msg}`} colorWay="white" />
              )}
            </FormattedMessage>
          </div>
          <div className="sk-purchasedCoursesHero__inner">
            <div className="sk-purchasedCoursesHero__title">
              <Heading variant="h1">{title}</Heading>
              <div className="sk-purchasedCoursesHero__title__rating">
                <StarRating rate={averageScore} />
                <Text><b>{averageScore}</b>(<b>{totalVotes}</b>)</Text>
              </div>
              <Link
                className="sk-purchasedCoursesHero__title__link"
                to={`/course-details/${id}`}
              >
                <FormattedMessage {...messages.goToCoursePage} />
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PurchasedCoursesHero.propTypes = {
  course: PropTypes.object,
};

export default PurchasedCoursesHero;
