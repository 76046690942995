import { useState, useCallback } from 'react';
import { isNull } from 'utils/helpers/index';

const useSizeElement = () => {
  const [width, setElementWidth] = useState(0);
  const elementRef = useCallback(node => {
    if (!isNull(node)) {
      setElementWidth(node.getBoundingClientRect().width);
    }
  }, [setElementWidth]);

  return { width, elementRef };
}

export default useSizeElement;
