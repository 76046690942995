// Core
import React, { memo } from 'react';
import { withRouter } from 'react-router';
import { compose } from 'react-apollo';
import { FormattedMessage, injectIntl } from 'react-intl';

// Styles and Assets
import './CourseDetailsHero.scss';

// Components
import Overlay from 'components/Overlay';
import Image from 'components/Image';
import StarRating from 'components/StarRating';
import Button from 'components/Button';
import Link from 'components/Link';

// Other
import { messages } from 'routes/CourseDetails/CourseDetailsHero/messages';
import { sharedMessages } from 'services/i18n/sharedMessages/messages';
import { numberSplitter } from 'utils/helpers';

const CourseDetailsHero = ({ title, category, subcategory, rating, photo, history, maxUsers, intl: { formatMessage } }) => {
  const handleGoBackClick = () => history.goBack();
  return (
    <div>
      <div className="sk-courseDetails-hero">
        <Image src='/assets/images/skilleton-public-course-background.svg' id="public-course-background" />
        <div className="sk-courseDetails-hero__wrapper grid landing">
          <div className="sk-courseDetails-hero__inner">
            <div className="sk-courseDetails-hero__title">
              {!!title && (
                <div className="sk-courseDetails-title">
                  <span className="sk-courseDetails-title__category-subcategory">
                    <Link to={`/courses?category=${category.id}&page=0`}>{formatMessage(sharedMessages[category.title])}</Link>
                    { subcategory && (<>
                      <>/</>
                      <Link to={`/courses?category=${category.id}&subcategory=${subcategory.id}&page=0`}>{formatMessage(sharedMessages[subcategory.title])}</Link>
                    </>)}
                  </span>
                  <h3 className="sk-courseDetails-title-h3">{title}</h3>
                </div>
              )}
              <div className="sk-courseDetails-stars">
                <div className="sk-courseDetails-stars__row">
                  <StarRating rate={rating.averageScore} />
                </div>
                <div className="sk-courseDetails-stars__procent">
                  <p>
                    {rating ? rating.averageScore : 0}
                  </p>
                  <p>
                    &nbsp;
                    <span>(</span>
                    {rating ? numberSplitter(rating.totalVotes) : 0}
                    <span>)</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const enhancer = compose(memo, injectIntl, withRouter);

export default enhancer(CourseDetailsHero);
