// Core
import React, { memo } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "react-apollo";
import { FormattedMessage, injectIntl } from "react-intl";

// Components
import TextInput from "components/Input/Material/TextInput/TextInput";
import Button from "components/Button";
import CountrySelect from "routes/Authenticator/CountrySelect";
import CitySelect from "routes/Authenticator/CitySelect";
import ImageUploader from "components/ImageUploader/";
import AutoComplete from "components/Select/AutoComplete";
import { ColorPicker } from "material-ui-color";

// Styles
import "./CompanyInfoForm.scss";
import { messages } from "routes/Authenticator/SignUpForm/messages";

const COMPANY_SIZE_OPTIONS = [
  { key: "1-10", name: "From 1 to 10" },
  { key: "10-20", name: "From 10 to 20" },
  { key: "20-50", name: "From 20 to 50" },
  { key: "50-100", name: "From 50 to 100" },
  { key: "100+", name: "More than 100 members" },
];
const SUBSCRIPTION_PLANS = [
  { key: "standard", name: "Standard Plan" },
  { key: "medium", name: "Medium Size" },
  { key: "enterprise", name: "Enterprise" },
];

const CompanyInfoForm = memo((props) => {
  const {
    values: {
      country = "",
      city = "",
      companySize,
      companyName,
      companyPhone,
      primaryColour,
      secondaryColour,
      ownerId,
      fqdn,
      email,
    },
    errors,
    touched,
    handleSubmit,
    setFieldTouched,
    setFieldValue,
    handleBlur,
    intl: { formatMessage },
  } = props;

  const handleInputChange = (name) => (inputValue) => {
    if (name === "country" && !inputValue) {
      setFieldValue("city", "");
    }
    setFieldValue(name, inputValue);
    setFieldTouched(name, true, false);
  };

  const handleInputBlur = (name) => (inputValue, e) => {
    if (e) {
      e.persist();
      handleBlur(e);
    }
    setFieldValue(name, inputValue);
  };

  const handleEnterPress = (params1, params2) => {
    const charCode = params1?.charCode || params2?.charCode;
  };

  return (
    <form noValidate autoComplete="off" onSubmit={handleSubmit}>
      <div className="sk-company-form">
        <header className="sk-company-form_header">
          <div className="sk-company-form_uploader">
            <ImageUploader
              avatarClasses="sk-company-form_avatar"
              uploaderId="user-profile-photo"
            />
          </div>
        </header>
        <div className="sk-form__inner sk-company-form_inner">
          <div className="sk-form__row">
            <TextInput
              name="companyName"
              helperText={touched.firstName ? errors.companyName : ""}
              error={touched.companyName && Boolean(errors.companyName)}
              value={companyName}
              label={formatMessage(messages.companyName)}
              placeholder={formatMessage(messages.companyName)}
              onChange={handleInputChange("companyName")}
              onBlur={handleInputBlur("companyName")}
            />
            <FormattedMessage {...messages.companySize}>
              {(msg) => (
                <AutoComplete
                  options={COMPANY_SIZE_OPTIONS}
                  label={msg}
                  labelKey="name"
                  valueKey="key"
                  value={companySize}
                  placeholder={msg}
                  handleBlur={handleInputBlur("companySize")}
                  handleChange={handleInputChange("companySize")}
                  handleSearch={handleInputChange("companySize")}
                  onKeyPress={handleEnterPress}
                />
              )}
            </FormattedMessage>
          </div>
          <div className="sk-form__row">
            <TextInput
              name="email"
              helperText={touched.email ? errors.email : ""}
              error={touched.email && Boolean(errors.email)}
              value={email}
              label={formatMessage(messages.companyEmail)}
              placeholder={formatMessage(messages.email)}
              onChange={handleInputChange("email")}
              disabled
            />
          </div>
          <div className="sk-form__row">
            <TextInput
              name="companyPhone"
              helperText={touched.companyPhone ? errors.companyPhone : ""}
              error={touched.companyPhone && Boolean(errors.companyPhone)}
              value={companyPhone}
              label={formatMessage(messages.companyPhone)}
              placeholder={formatMessage(messages.companyPhone)}
              onChange={handleInputChange("companyPhone")}
            />
          </div>

          <div className="sk-form__row">
            <CountrySelect
              helperText={touched.country ? errors.country : ""}
              error={touched.country && Boolean(errors.country)}
              name="country"
              label={formatMessage(messages.country)}
              value={country}
              handleChange={handleInputChange("country")}
              handleSearch={handleInputChange("country")}
              handleBlur={handleInputBlur("country")}
            />
          </div>
          <div className="sk-form__row">
            <CitySelect
              helperText={touched.city ? errors.city : ""}
              error={touched.city && Boolean(errors.city)}
              name="city"
              label={formatMessage(messages.city)}
              value={city}
              disabled={!country}
              country={country}
              handleChange={handleInputChange("city")}
              handleSearch={handleInputChange("city")}
              handleBlur={handleInputBlur("city")}
            />
          </div>

          <div className="sk-form__row">
            <div className="sk-form__col">
              Primary Colour
              <ColorPicker
                value={primaryColour}
                onChange={handleInputChange("primaryColour")}
              />
            </div>
            <div className="sk-form__col">
              Secondary Colour
              <ColorPicker
                value={secondaryColour}
                onChange={handleInputChange("secondaryColour")}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="sk-company-form">
        <div className="sk-form__inner sk-company-form_inner">
          <div className="sk-form__row">
            <TextInput
              name="fqdn"
              helperText={touched.fqdn ? errors.fqdn : ""}
              error={touched.fqdn && Boolean(errors.fqdn)}
              value={fqdn}
              label={formatMessage(messages.fqdn)}
              placeholder={formatMessage(messages.fqdn)}
              onChange={handleInputChange("fqdn")}
            />
            <p>
              Did you know that you can set up your custom subdomain for your
              site? By configuring your subdomain CNAME record to point to
              avdo.education, you can seamlessly integrate your unique branding
              into our platform.
            </p>
          </div>

          <div className="sk-form_footer sk-company-form_footer">
            <Button variant="text" color="secondary" size="large" type="submit">
              <FormattedMessage {...messages.save} />
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
});

const enhancer = compose(withRouter, injectIntl);

export default enhancer(CompanyInfoForm);
