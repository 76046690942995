// Core
import React, { memo } from 'react';
import { compose } from 'react-apollo';
import { injectIntl } from 'react-intl';
import classnames from 'classnames';
// Components
import Text from 'components/Text';

// Other
import { addSpacesBetweenEveryGroupOfThreeNumbers } from 'utils/helpers/index';
import { messages } from 'containers/Header/CheckoutBlock/CheckoutMenu/messages.js';

//Styles
import './index.scss';

const PREFIX = 'sk-checkout-order-total';
const OrderTotal = ({
  withDiscount,
  currencySign,
  totalPrice,
  totalPriceWithDiscount,
  intl: { formatMessage },
  ...props
}) => {
  const hasDiscount = withDiscount && (totalPriceWithDiscount == 0 || !!totalPriceWithDiscount) && totalPrice !== totalPriceWithDiscount;

  return (
    <div className={`${PREFIX}`}>
      <Text classes={`${PREFIX}__title`}>{formatMessage(messages.total)}</Text>
      <Text classes={classnames({
          [`${PREFIX}__sum`]: !hasDiscount,
          [`${PREFIX}__price-crossed`]: hasDiscount,
        })}
      >
        {currencySign} {addSpacesBetweenEveryGroupOfThreeNumbers(totalPrice)}
      </Text>
      {hasDiscount && (
        <Text classes={`${PREFIX}__sum`} style={{ color: '#30d5c8' }}>
          {currencySign} {addSpacesBetweenEveryGroupOfThreeNumbers(totalPriceWithDiscount)}
        </Text>
      )}
    </div>
  );
};

const enhancer = compose(
  memo,
  injectIntl
);

export default enhancer(OrderTotal);
