// Core
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { compose, graphql } from 'react-apollo';
import { injectIntl } from 'react-intl';

// Components
import ContentHeader from 'containers/ContentHeader';
import CourseModuleArticleText from 'routes/StudentsCabinet/CourseModuleStructure/CourseModuleArticleText';
import CourseModuleArticlePDF from 'routes/StudentsCabinet/CourseModuleStructure/CourseModuleArticlePDF';
import CourseModuleVideo from 'routes/StudentsCabinet/CourseModuleStructure/CourseModuleVideo/';
import CourseModuleQuiz from 'routes/StudentsCabinet/CourseModuleStructure/CourseModuleQuiz/';
import CourseModulePagination from 'routes/StudentsCabinet/CourseModuleStructure/CourseModulePagination';
import Play from 'components/Icon/Svg/Play';
import { VideoPreview, PdfPreview, TextPreview, EmptyPreview, EditorPreview } from 'components/FilePreview';
import PageNotFound from 'routes/PageNotFound';
import LockedMaterialAlt from 'components/Icon/SvgCss/LockedMaterialAlt.js';

// GraphQL
import GET_STUDENT_COURSE from 'queries/Courses/getStudentCourse.gql';

// Other
import { parseFetchedData, findItemByKey, keysToSnake, keysToCamel, isBlank } from 'utils/helpers';
import { White } from 'components/Icon/color';
import { FormattedMessage } from 'react-intl';
import { messages } from 'routes/StudentsCabinet/PurchasedCourses/messages';
import { getVariant } from 'routes/ExpertsCabinet/CourseEdit/Details/CourseInformation/helpers.js';
import { PDF_FILE, TEXT_FILE, EDITOR_FILE } from 'utils/enums';
import {
  LECTURE_ARTICLE,
  LECTURE_VIDEO,
  LECTURE_AUDIO,
  LECTURE_QUIZ
} from 'containers/UploadManagerProvider/helpers';
import { getArticleType } from 'routes/Course/components/LecturesList/LectureItem/HOCs/helpers';
import { sharedMessages } from 'services/i18n/sharedMessages/messages';
import { getViewEditAccess } from 'utils/helpers/courseSettingHelpers';

// GraphQL
import UPDATE_RESUME from 'mutations/StudentCabinet/updateResume.gql';

// Styles and Assets
import './CourseModuleStructure.scss';

const getType = (url) => {
  const arr = url.split('/');
  return arr[arr.length - 2];
};

const validateReviewedHomework = (homework) => {
  return !isBlank(homework) && homework?.step == 4 && homework?.review?.mark >= 60;
};

const validateSubmittedHomework = (homework) => {
  return !isBlank(homework) && homework?.step >= 3;
};

class CourseModuleStructure extends PureComponent {
  getLecture = (id) => {
    const {
      course: { lectures }
    } = this.props;
    const lecture = findItemByKey(lectures, 'id', id);
    return { lecture, order: lectures.indexOf(lecture) };
  };
  getLecturesWithHomework = () => {
    const {
      course: { lectures }
    } = this.props;
    return lectures
      ?.map((lecture, index) => ({
        id: lecture?.id,
        index,
        homework: lecture?.homework,
        orderNumber: lecture?.orderNumber
      }))
      .filter((lecture) => lecture?.homework !== null);
  };

  handleSetResume = (material) => async (position) => {
    const { studentSetResume, match } = this.props;
    const { courseId, lectureId, type, id } = keysToCamel(material);
    const purchasedCourseId = match.params.id;

    const input = {
      id,
      courseId,
      lectureId,
      type,
      position,
      purchasedCourseId
    };
    try {
      await studentSetResume(input);
    } catch (error) {
      throw Error(error);
    }
  };

  getLectureMaterial = (lecture, id) =>
    lecture &&
    lecture?.materials?.find((item) => {
      if (item.id === id) {
        return true;
      }
      return false;
    });

  getVideoPosition = () => {
    const {
      location: { search }
    } = this.props;

    if (search) {
      const query = new URLSearchParams(search);
      if (query.has('position')) {
        return query.get('position');
      }
    }
    return 0;
  };

  getCourseStudyData = (purchasedCourse) => {
    return purchasedCourse;
  };

  renderAccessDenied = ({ lectureType, lectureOrder, lecture, material }) => {
    return (
      <div className="sk-c-module-structure">
        <ContentHeader
          way={`${lectureType} ${parseFloat(lectureOrder + 1)}. ${lecture?.title} / ${material?.title}`}
          colorWay="black"
        />
        <FormattedMessage {...messages.materialLockedDuePolicy}>
          {(label) => (
            <>
              <LockedMaterialAlt /> {label}
            </>
          )}
        </FormattedMessage>
      </div>
    );
  };

  render() {
    const {
      match,
      location,
      course,
      match: {
        params: { lectureId, itemId, id: userCourseId }
      },
      history,
      purchasedCourse,
      intl: { formatMessage }
    } = this.props;

    const courseStudyData = this.getCourseStudyData(purchasedCourse, course);

    // getStudentCourse may return null of course not found or student didn't
    // own this item.
    if (purchasedCourse === null) {
      return <PageNotFound />;
    }

    // Pending course, could be nice to replace with loader
    if (!course) {
      return null;
    }

    const { lecture, order: lectureOrder } = this.getLecture(lectureId);

    const material = this.getLectureMaterial(lecture, itemId);

    if (!material) {
      return null;
    }

    const { variants } = course;
    const variantName = courseStudyData?.interactionLevel;

    const purchasedCourseVariant = getVariant({ variants, variantName });
    const purchasedCoursePermissions = purchasedCourseVariant?.permissions;
    const purchasedCourseFeatures = purchasedCourseVariant?.features;

    const lecturesAccess =
      !isBlank(purchasedCoursePermissions) && !isBlank(purchasedCoursePermissions.lectures)
        ? purchasedCoursePermissions.lectures
        : 'all';
    const homeworksAccess =
      !isBlank(purchasedCoursePermissions) && !isBlank(purchasedCoursePermissions.homeworks)
        ? purchasedCoursePermissions.homeworks
        : 'all';

    const ext = getArticleType(material);
    const lectureType =
      course?.displayLectureNameType === 'section'
        ? formatMessage(sharedMessages.section)
        : formatMessage(sharedMessages.lecture);

    const lecturesWithHomework = course?.lectures
      ?.map((lecture, index) => ({
        id: lecture?.id,
        index,
        homework: lecture?.homework,
        orderNumber: lecture?.orderNumber
      }))
      .filter((lecture) => lecture?.homework !== null);

    const isLectureHomeworkSettingsDefined =
      (variantName === 'assist' && purchasedCourseFeatures.includes('homeworks')) ||
      variantName === 'concierge';

    const isDisabled =
      isLectureHomeworkSettingsDefined &&
      getViewEditAccess({
        currentLecture: lecture,
        lecturesWithHomework,
        accessSettings: lecturesAccess,
        homeworks: courseStudyData?.homeworksData
      });
    if (isDisabled) {
      return this.renderAccessDenied({ lectureType, lectureOrder, lecture, material });
    }
    return (
      <div className="sk-c-module-structure">
        <ContentHeader
          way={`${lectureType} ${parseFloat(lectureOrder + 1)}. ${lecture?.title} / ${material?.title}`}
          colorWay="black"
        />
        {}
        <div className="sk-c-module-structure__content">
          {(material.type === LECTURE_VIDEO || material.type === LECTURE_AUDIO) && (
            <CourseModuleVideo
              filepath={material?.filepath}
              filetype={material?.filetype}
              handleSetResume={this.handleSetResume(material)}
              videoStartTime={this.getVideoPosition()}
              playButton={() => (
                <div className="sk-c-module-structure__icon">
                  <Play strokeColor={White} />
                </div>
              )}
            />
          )}
          {material.type === LECTURE_ARTICLE && material.filetype === TEXT_FILE && (
            <CourseModuleArticleText filepath={material?.filepath} title={material?.title} />
          )}
          {material.type === LECTURE_ARTICLE && material.filetype === PDF_FILE && (
            <CourseModuleArticlePDF filepath={material?.filepath} />
          )}
          {material.type === LECTURE_ARTICLE && material.filetype === EDITOR_FILE && (
            <EditorPreview filepath={material?.filepath} />
          )}
          {material.type === LECTURE_QUIZ && (
            <CourseModuleQuiz quizId={material?.filepath} userCourseId={userCourseId} />
          )}
        </div>
        <div className="sk-c-module-structure__footer">
          <CourseModulePagination
            history={history}
            match={match}
            course={course}
            handleSetResume={this.handleSetResume}
            hasLectureHomeworkSettings={isLectureHomeworkSettingsDefined}
            lecturesWithHomework={this.getLecturesWithHomework()}
            accessSettings={lecturesAccess}
            homeworks={courseStudyData?.homeworksData}
            currentLecture={lecture}
          />
        </div>
      </div>
    );
  }
}

const studentUpdateResume = graphql(UPDATE_RESUME, {
  props: ({ mutate }) => ({
    studentSetResume: (input) => mutate({ variables: { input: keysToSnake(input) } })
  }),
  options: {
    refetchQueries: ['listStudentCourses']
  }
});

const getStudentCourseQuery = graphql(GET_STUDENT_COURSE, {
  props: ({ data: { getStudentCourse, loading, error, ...ownProps } }) => {
    if (error) throw new Error(error);
    if (loading) return { loading, error };

    const purchasedCourse = parseFetchedData(getStudentCourse);
    return {
      loading,
      course: purchasedCourse?.course,
      purchasedCourse,
      ...ownProps
    };
  },
  options: ({ match }) => ({
    variables: { id: match.params.id }
  })
});

const enhancer = compose(withRouter, getStudentCourseQuery, injectIntl, studentUpdateResume);

export default enhancer(CourseModuleStructure);
