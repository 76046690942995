const selector = {
  lineHeight: 'normal',
  display: 'block',
  // height: 30
};

const menuItem = {
  height: 30,
  padding: 0,
  display: 'flex',
  justifyContent: 'center',
  color: '#610FB6',
  fontFamily: 'Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
  fontSize: 14,
  lineHeight: 22
};

const form = {
  right: 1.5,
  position: 'relative',
  width: 63,
  borderRadius: 4,
  marginRight: 10,
  height: 30,
  display: 'flex',
  alignItems: 'center',
  padding: 0
};

const displayValue = {
  fontSize: 13,
  fontFamily: 'Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
  letterSpacing: 1,
  color: '#D7B6FF',
  position: 'absolute',
  left: 8.5,
  top: 8
};

export { form, selector, menuItem, displayValue };
