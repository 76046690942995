// Core
import React, { memo, useCallback } from 'react';
import { graphql, compose } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { injectIntl, } from 'react-intl';
import { withFormik } from 'formik';

// Components
import ContentHeader from 'containers/ContentHeader';
import Text from 'components/Text';
import ToppingInfo from 'routes/ExpertsCabinet/ToppingEdit/ToppingInfo';
import ToppingDetails from 'routes/ExpertsCabinet/ToppingEdit/ToppingDetails';
import AdminRecommendation from 'routes/ExpertsCabinet/ToppingEdit/AdminRecommendation';
import ActionButtons from 'routes/ExpertsCabinet/ToppingEdit/ActionButtons';
import CourseHasStudentsWarning from 'routes/ExpertsCabinet/CourseEdit/GeneralInfo/ActionPanel/DeleteCourseButton/CourseHasStudentsWarning';
import NotFoundCourse from 'routes/ExpertsCabinet/CourseEdit/NotFoundCourse';

// GraphQL
import GET_TOPPING from 'queries/Courses/getTopping.gql';
import UPDATE_COURSE from 'mutations/Courses/updateCourse.gql';
import DELETE_COURSE from 'mutations/Courses/deleteCourse.gql';

// Other
import { withNotification } from 'containers/NotificationProvider/withNotification';
import { hasLoader } from 'containers/HOCs/hasLoader';
import { keysToSnake, keysToCamel, isBlank   } from 'utils/helpers';
import { ADMIN, EXPERT } from 'utils/enums';
import { withModal } from 'containers/ModalProvider/withModal';
import { messages } from 'routes/ExpertsCabinet/ToppingEdit/messages';
import {
  DEFAULT_TOPPING, TOPPING_NEXT_STATUSES_EXPERT, TOPPING_NEXT_STATUSES_ADMIN,
  TOPPING_NEXT_REJECTED_STATUSES_ADMIN, FIRST_REVIEW, FIRST_REJECT, DRAFT,
  ACTIVE, FIELDS_MAPPING
} from 'routes/ExpertsCabinet/ToppingEdit/enums.js';
import { getInitialState } from 'routes/ExpertsCabinet/ToppingEdit/helpers.js';
import { getYupSchema, } from 'services/yup';
import { withCourseStatusManager } from 'routes/ExpertsCabinet/CourseEdit/HOCs/withCourseStatusManager';
import { withAuthenticatedUser } from 'containers/AuthenticatedUserProvider/withAuthenticatedUser';
import { withLoader } from 'containers/HOCs/withLoader';

// Styles and Assets
import './index.scss';


const getEditingMode = ({ role, status }) => {
  const modes = {
    [EXPERT]: [FIRST_REVIEW, FIRST_REJECT, ACTIVE],
    [ADMIN]: [ FIRST_REJECT],
  };
  return !!(modes[role]?.includes(status));
}

const prefix = 'sk-topping-edit';
const ToppingEdit = ({
  course,
  isValid,
  isValidating,
  values,
  errors,
  touched,
  handleChange,
  validateForm,
  setErrors,
  setTouched,
  history,
  refetchTopping,
  updateCourse,
  deleteCourse,
  showNotificationBar,
  handleCourseStatusUpdate,
  modalContext: { showModal },
  userContext: { profile },
  intl: { formatMessage },
}) => {
  if (profile?.id !== course?.author?.id && profile?.role !== 'admin') {
    return (<NotFoundCourse />);
  }

  // Support multiple fields update in the same time
  const updateTopping = useCallback(async (input) => {
    try {
      let mappedInput = {};
      for (const fieldName in input) {
        mappedInput[FIELDS_MAPPING[fieldName]] = input[fieldName];
      };
      mappedInput = keysToSnake(mappedInput);
      await updateCourse({ id: course.id, ...mappedInput });
      showNotificationBar('saved');
    } catch (error) {
      throw Error(error);
    }
  }, [updateCourse, showNotificationBar]);

  // Support multiple fields update in the same time
  const handleToppingUpdate = useCallback(async (fields) => {
    try {
      const errors = await validateForm(values);

      for (const name in fields) {
        const isValid = !errors[name];
        if (!isValid) {
          return;
        };
      };

      await updateTopping(fields);
    } catch (error) {
      throw Error(error);
    }
  }, [values]);

  const handleToppingDelete = async () => {
    try {
      const { data: { deleteCourse: deletedCourseStatus }} = await deleteCourse({ id: course.id });

      if (deletedCourseStatus == 'REFUSE') {
        showModal(CourseHasStudentsWarning, {
          size: 'md',
        });
      } else {
        history.push('/experts-cabinet/toppings');
      };
    } catch (error) {
      throw Error(error);
    }
  };

  const submitTopping = (nextStatus, type, isAdmin) => async () => {
    const errors = await validateForm(values);
    const isValid = Object.keys(errors).length == 0;

    if (!isValid) {
      const touched = {};

      for (const fieldName in errors) {
        touched[fieldName] = true;
      };

      setErrors(errors);
      setTouched(touched);
    } else {
      await handleCourseStatusUpdate(nextStatus, isAdmin, type);
      showNotificationBar('saved');
    };
  };

  const disabledEditing = getEditingMode({ status: course.statusId, role: profile.role });
  const isAdminRecommendationVisible = course.statusId != DRAFT
    && ((course.statusId != FIRST_REVIEW && values?.recommendationFromAdmin && profile.role == EXPERT) || profile.role == ADMIN);

  return (
    <div className={prefix}>
      <ContentHeader way={formatMessage(messages.breadCrumbs)} />
      <ToppingInfo
        disabledEditing={disabledEditing}
        topping={course}
        updateTopping={updateTopping}
        handleToppingUpdate={handleToppingUpdate}
        refetchTopping={refetchTopping}
      />
      <ToppingDetails
        disabledEditing={disabledEditing}
        topping={course}
        formikBag={{ values, handleChange, errors, touched }}
        handleToppingUpdate={handleToppingUpdate}
        refetchCourse={refetchTopping}
      />
      {isAdminRecommendationVisible && (
        <AdminRecommendation
          disabledEditing={profile.role == EXPERT}
          formikBag={{ values, handleChange, }}
          handleToppingUpdate={handleToppingUpdate}
        />
      )}
      <ActionButtons
        status={course.statusId}
        isAdmin={profile.role == ADMIN}
        isExpert={profile.role == EXPERT}
        // isValid={isValid}
        disabledEditing={disabledEditing}
        handleToppingDelete={handleToppingDelete}
        submitTopping={submitTopping}
      />
    </div>
  );
}

const getCourseQuery = graphql(GET_TOPPING, {
  props: ({ data: { getCourse, error, loading, refetch, ...ownProps } }) => {
    return {
      course: keysToCamel(getCourse),
      refetchTopping: refetch,
      loading,
      error,
      ...ownProps,
    };
  },
  options: ({
    match: {
      params: { id },
    },
  }) => ({
    variables: { input: { id } },
    fetchPolicy: 'network-only',
    pollInterval: 600000,
  }),
});

const updateCourseMutation = graphql(UPDATE_COURSE, {
  props: ({ mutate }) => ({
    updateCourse: input => {
      mutate({ variables: { input } });
    },
  }),
  options: () => ({
    refetchQueries: ['getCourse'],
  }),
});

const deleteCourseMutation = graphql(DELETE_COURSE, {
  props: ({ mutate }) => ({
    deleteCourse: input => mutate({ variables: { input } }),
  }),
  options: () => ({
    refetchQueries: ['listExpertCourses'],
    awaitRefetchQueries: true,
  }),
});

const formikStateManagement = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ course = {} }) => {
    return getInitialState(course);
    // return { ...DEFAULT_TOPPING, ...toppingState, };
  },
  displayName: 'EditToppingForm',
  validate: () => {},
  validationSchema: () => getYupSchema('editToppingValidationSchema'),
  // validateOnMount: true,
  validateOnChange: true,
  validateOnBlur: true
});

const enhancer = compose(
  injectIntl,
  withNotification,
  withRouter,
  withModal,
  getCourseQuery,
  withLoader,
  withAuthenticatedUser,
  formikStateManagement,
  updateCourseMutation,
  deleteCourseMutation,
  withCourseStatusManager,
);

export default enhancer(hasLoader(ToppingEdit, GET_TOPPING));
