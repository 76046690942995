// Core
import React, { memo, useState } from "react";
import PropTypes from "prop-types";

// Styles and Assets
import "./Author.scss";

// Components
import UserAvatar from "components/UserAvatar";
import AuthorRating from "routes/CourseDetails/Author/AuthorRating";
import ReadMore from "components/ReadMore";

// Other
import { FormattedMessage } from "react-intl";
import { messages } from "routes/CourseDetails/Author/messages";
import { isBlank } from "utils/helpers";

const textStyles = {
  fontSize: "16px",
  lineHeight: "24px",
  fontFamily: "Roboto",
  color: "#6D7C90",
  margin: 0,
  padding: 0,
  whiteSpace: "break-spaces",
};

const Author = ({ author }) => {
  const [isTextExpanded, setTextVisibility] = useState(false);
  const toggleTextVisibilty = () => setTextVisibility(!isTextExpanded);

  const text = !isBlank(author.aboutMe) ? author.aboutMe : author.expertBio;
  const isTextAvailible = !!text;

  if (!isTextAvailible) return;
  // {
  //   id: `${id}-send`,
  //   label: <FormattedMessage {...messages.sendMessageToExpert} />,
  //   icon: () => <Question width="14" height="14" />,
  //   handleCustomClick: this.handleSendMessageClick(id),
  //   disabled: completed === COMPLETED_PURCHASED_COURSE,
  // },

  return (
    <div className="sk-courseDetails-author">
      <div className="sk-courseDetails-author__avatar">
        <div className="sk-courseDetails-author__avatar_name">
          <div>
            {author?.photo ? (
              <UserAvatar
                avatar={author.photo}
                classes="sk-courseDetails-author__useravatar"
              />
            ) : (
              <UserAvatar
                firstName={author.firstName}
                lastName={author.lastName}
              />
            )}
          </div>
          <div className="sk-courseDetails-author__name">
            <h3 className="sk-courseDetails-author__name-title">{`${author.firstName} ${author.lastName}`}</h3>
            <span>
              <FormattedMessage {...messages.author} />
            </span>
          </div>
        </div>
        <AuthorRating rating={author.rating} />
      </div>
      <ReadMore lines={5} textStyles={textStyles} withToggle>
        {text}
      </ReadMore>
    </div>
  );
};

Author.propTypes = {
  author: PropTypes.object,
};

export default memo(Author);
