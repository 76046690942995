// Core
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose, } from 'react-apollo';

// Components
import Loader from 'components/Loader';
import Text from 'components/Text';
import Button from 'components/Button';
import DocDownload from 'components/Icon/Svg/DocDownload';

import { FormattedMessage } from 'react-intl';
import { messages } from 'routes/StudentsCabinet/components/StudentHomework/messages';
import { withFileUrl } from 'containers/HOCs/withFileUrl';

// Styles and Assets
import './Step1.scss';

const Step1 = ({
  fileUrl,
  disabled,
  handleUpdateHomeworkStep,
}) => (
  <div className="sk-step1">
    <div className="sk-step1__header">
      <div className="sk-step1__icon">
        <DocDownload disabled={disabled} />
      </div>
      <div className="sk-step1__text">
        <Text size="caption" disabled={disabled}>
          <FormattedMessage {...messages.downloadHomeworkToPerform} />
        </Text>
      </div>
    </div>
    <DownloadButton
      disabled={disabled}
      hwLink={fileUrl}
      handleClick={() => handleUpdateHomeworkStep(2)}
    />
  </div>
);

Step1.propTypes = {
  hwLink: PropTypes.string,
  hwStatusIsUpdating: PropTypes.bool,
  disabled: PropTypes.bool,
  handleUpdateHomeworkStep: PropTypes.func,
};

const enhancer = compose(
  memo,
  withFileUrl
)

export default enhancer(Step1);

const DownloadButton = ({ disabled, hwLink, handleClick }) => (
  <>
    {disabled ? (
      <div className="sk-step1__action">
        <Button variant="text" color="secondary" size="small" disabled>
          <FormattedMessage {...messages.download} />
        </Button>
      </div>
    ) : (
      <a
        href={hwLink}
        download
        className="sk-step1__action"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button
          variant="text"
          color="secondary"
          size="small"
          onClick={handleClick}
        >
          <FormattedMessage {...messages.download} />
        </Button>
      </a>
    )}
  </>
);
