import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  courseComplexity: {
    id: 'app.components.CourseInformationForm.courseComplexity',
    defaultMessage: 'Course complexity',
  },
  chooseLang: {
    id: 'app.components.CourseInformationForm.chooseLang',
    defaultMessage: 'Choose language',
  },
  courseReqs: {
    id: 'app.components.CourseInformationForm.courseReqs',
    defaultMessage: 'Course Requirements',
  },
  whatsReqToTakeCourse: {
    id: 'app.components.CourseInformationForm.whatsReqToTakeCourse',
    defaultMessage:
      'What is required to take your course? Things such as previous knowledge, equipment, software etc. If there are no requirements, just leave blank',
  },
  skillsStudentWillGain: {
    id: 'app.components.CourseInformationForm.skillsStudentWillGain',
    defaultMessage: 'Skills student will gain after the course',
  },
  courseDescription: {
    id: 'app.components.CourseInformationForm.courseDescription',
    defaultMessage: 'Course Description for students (max – 500 words)',
  },
  courseDescForAdmin: {
    id: 'app.components.CourseInformationForm.courseDescForAdmin',
    defaultMessage: 'Course Description for admin (max – 500 words)',
  },
  howAreUPlanning: {
    id: 'app.components.CourseInformationForm.howAreUPlanning',
    defaultMessage: 'How are you planning to construct your course?',
  },
  pleaseInclude: {
    id: 'app.components.CourseInformationForm.pleaseInclude',
    defaultMessage: 'Please include the following details:',
  },
  plannedTotalCourse: {
    id: 'app.components.CourseInformationForm.plannedTotalCourse',
    defaultMessage: 'Planned total course length',
  },
  numberOfLects: {
    id: 'app.components.CourseInformationForm.numberOfLects',
    defaultMessage: 'Number of lectures',
  },
  freqAndLengthWebs: {
    id: 'app.components.CourseInformationForm.freqAndLengthWebs',
    defaultMessage: 'Frequency and length of web classes',
  },
  anythingElseYoudLikeToInclude: {
    id: 'app.components.CourseInformationForm.anythingElseYoudLikeToInclude',
    defaultMessage: 'Anything else you’d like to include',
  },
  submit: {
    id: 'app.components.CourseInformationForm.submit',
    defaultMessage: 'Submit',
  },
  numberOfHws: {
    id: 'app.components.CourseInformationForm.numberOfHws',
    defaultMessage: 'Number of homework excercises',
  },
  descriptionForStudents: {
    id: 'app.components.CourseInformationForm.descriptionForStudents',
    defaultMessage: 'Course description',
  },
  descriptionForAdmin: {
    id: 'app.components.CourseInformationForm.descriptionForAdmin',
    defaultMessage: 'Description for admin',
  },
});
