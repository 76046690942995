// Core
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';

// Components
import Text from 'components/Text';
import Loader from 'components/Loader';
import ToppingCard from 'routes/components/ToppingCard';
import StatusFilter from 'routes/components/Filters/StatusFilter';
import ToppingTypeFilter from 'routes/components/Filters/ToppingTypeFilter';
import ContentHeader from 'containers/ContentHeader';

// HOCs
import { withSidebar } from 'containers/SidebarProvider/withSidebar';
import { withLoader } from 'containers/HOCs/withLoader';

// GraphQL
import LIST_EXPERT_TOPPINGS from 'queries/Courses/listExpertToppings.gql';

// Other
import { isBlank, isEmptyArray, keysToCamel, isArraysEqual } from 'utils/helpers';
import { sharedMessages } from 'services/i18n/sharedMessages/messages';
import { TOPPINGS_STATUSES_OPTIONS } from 'routes/ExpertsCabinet/ToppingEdit/enums.js';

// Styles and Assets
import './index.scss';

const prefix = 'sk-expert-cabinet-toppings';
export const Toppings = ({
  toppings,
  query,
  match: { url },
  intl: { formatMessage },
  sidebarContext,
  refetchCourses,
}) => {
  const searchToppings = ((query) => async ({ key, values }) => {
    const { filter } = query;

    const newFilters = !isBlank(filter) ? [...filter] : [];
    const oldFilter = newFilters.find(filter => filter.key === key);

    const isNewFilter = !(!!oldFilter);
    const isFilterChanged = oldFilter && values && !isArraysEqual(oldFilter.values, values);

    if (isNewFilter) {
      const newFilter = { key, values };
      newFilters.push(newFilter);
    } else if (isFilterChanged) {
      oldFilter.values = values;
    };

    await refetchCourses({ input: { query: { filter: newFilters } }});
  })(query);

  return (
    <div className={prefix}>
      <div className={`${prefix}__heading`}>
        <ContentHeader  way={formatMessage(sharedMessages.toppingsLabel)} />
        <div className={`${prefix}__heading__filters`}>
          <StatusFilter filter={query.filter} searchToppings={searchToppings} options={TOPPINGS_STATUSES_OPTIONS} />
          <ToppingTypeFilter filter={query.filter} searchToppings={searchToppings} />
        </div>
      </div>
      {!isEmptyArray(toppings) && toppings.map(topping => (
        <ToppingCard
          topping={topping}
          toppingPageLink={`${url}/${topping.id}`}
          isExpert={true}
        />
      ))}
    </div>
  );
};

const listExpertCoursesQuery = graphql(LIST_EXPERT_TOPPINGS, {
  props: ({ data: { expertListCourses, variables, refetch, ...ownProps } }) => {
    return {
      toppings: !isBlank(expertListCourses?.items) ? keysToCamel(expertListCourses?.items) : [],
      query: variables?.input?.query || {},
      refetchCourses: refetch,
      ...ownProps,
    }
  },
  options: () => ({
    // pollInterval: 60000,
    variables: { input: { query: { filter: [{key: 'type', values: ['topping'] }]}}},
    fetchPolicy: 'network-only',
  }),
});

const enhancer = compose(
  injectIntl,
  withSidebar,
  withRouter,
  listExpertCoursesQuery,
  withLoader
);

export default enhancer(Toppings);
