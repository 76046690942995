// Core
import React from 'react';
import PropTypes from 'prop-types';

// Styles and Assets
import { PurpleHeart } from 'components/Icon/color';

const FilterIcon = ({ height, width, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 20 20"
  >
    <g fill={color} fillRule="nonzero">
      <path
        d="M.702 10.71h8.939c.314 1.313 1.481 2.29 
        2.873 2.29 1.392 0 2.555-.977 2.874-2.29h3.91A.707.707 
        0 0 0 20 9.998a.704.704 0 0 0-.702-.708h-3.91C15.073 7.977
         13.906 7 12.514 7c-1.392 0-2.555.977-2.873 2.29H.702A.706.706 0
          0 0 0 9.998a.71.71 0 0 0 .702.712zm11.812-2.286c.857 0 1.555.709
           1.555 1.578 0 .87-.698 1.578-1.555 1.578s-1.559-.708-1.559-1.578c0-.87.698-1.578 
           1.56-1.578zM.702 17.71h4.29C5.306 19.023 6.473 20 7.865 20c1.392 0 2.555-.977 
           2.874-2.29h8.559a.706.706 0 0 0 .702-.708.707.707 0 0 0-.702-.712h-8.56C10.425
            14.977 9.259 14 7.866 14c-1.392 0-2.555.977-2.873 2.29H.702a.707.707 0 0
             0-.702.712c.004.39.318.708.702.708zm7.163-2.29c.857 0 1.555.708 1.555 
             1.578 0 .87-.698 1.578-1.555 1.578-.845 0-1.534-.688-1.555-1.545v-.037-.037c.02-.85.71-1.537 
             1.555-1.537zM.702 3.71h4.29C5.306 5.023 6.473 6 7.865 6c1.392 0 2.555-.977 2.874-2.29h8.559A.707.707
              0 0 0 20 2.998a.704.704 0 0 0-.702-.708h-8.56C10.425.977 9.259 0 7.866 0 6.473 0 5.31.977 4.992 
              2.29H.702A.706.706 0 0 0 0 2.998a.712.712 0 0 0 .702.712zm7.163-2.29c.857 0 1.555.708 1.555 1.578 
              0 .87-.698 1.578-1.555 1.578-.845 0-1.534-.688-1.555-1.545v-.037-.037c.02-.845.71-1.537 1.555-1.537z"
      />
    </g>
  </svg>
);

FilterIcon.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  color: PropTypes.string,
};

FilterIcon.defaultProps = {
  height: '20',
  width: '20',
  color: PurpleHeart,
};

export default FilterIcon;
