class RouteInstance {
  instance;

  constructor() {
    if (this.instance) {
      return this.instance;
    }
    this.instance = {
      location: {
        pathname: '/',
        search: '',
        hash: '',
      },
      history: () => {
        return null
      }
    }
  }

  /**
   * Getter
   * Returns current instance state
   * @return {object} instance
   */
  get = () => {
    return this.instance;
  }

  /**
   * Setter
   * @param {object} location object
   * @param {function} history function
   */
  set = ({location, history, locale}) => {
    if (location) {
      this.setLocation(location);
    }
    if (history) {
      this.setHistory(history);
    }
    if (locale) {
      this.setLocale(locale);
    }
  }

  setLocation = (location) => {
    this.instance.location = location;
  }

  setHistory(history) {
    this.instance.history = history;
  }

  setLocale(locale) {
    this.instance.locale = locale;
  }
}

const Route = new RouteInstance();

export default Route;
