// Coreobject
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose, graphql } from 'react-apollo';
import { OTSession, preloadScript } from 'opentok-react';
import moment from 'moment-timezone';

// Components
import WebinarRoomVideo from 'containers/WebinarRoom/WebinarRoomVideo';
import WebinarRoomChat from 'containers/WebinarRoom/WebinarRoomChat';

// Other
import { OPENTOK_API_KEY, OPENTOK_DEBUG } from 'services/opentok/exports';
import MY_PROFILE_QUERY from 'queries/UserProfile/getProfile.gql';
import { isEnterKeyPressed } from 'utils/helpers';
import { localStore } from 'services/localStorage';
import { withModal } from 'containers/ModalProvider/withModal';

// Styles and Assets
import './WebinarRoom.scss';

class WebinarRoom extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isConnected: false,
      publisherIsOnAir: false,
      message: '',
      messages: JSON.parse(localStore.getItem('WebinarChat')) || [],
      numberOfConnections: 0,
    };

    const {
      participantRole,
      webinarStarted,
      toggleWebinar,
      gtmTimezone,
      videoSource,
    } = this.props;

    this.session = React.createRef();

    this.sessionEventHandlers = {
    
      signal: event => {
        const senderData = event.from.data.split(',');
        const newMessage = {
          data: `${event.data}`,
          userName: `${senderData[0].split('=')[1]}`,
          photo: `${senderData[1].split('=')[1]}`,
          timestamp: moment().valueOf(),
          time: moment
            .tz(
              `${moment(event.from.creationTime).format()}`,
              gtmTimezone
            )
            .format('hh:mm A'),
        };
        this.handleMessageListUpdate(newMessage);
      },

      connectionCreated: () => {
        console.log('connection:created');
        this.setState(({ numberOfConnections }) => ({
          numberOfConnections: numberOfConnections + 1,
          publisherIsOnAir: !!this.session?.state?.streams?.length,
        }));
      },

      connectionDestroyed: () => {
        console.log('connection:disconnected');
        this.setState(({ numberOfConnections }) => ({
          numberOfConnections: numberOfConnections - 1,
        }));
      },
      sessionDisconnected: e => {
        console.log('session:disconnected', e);
        e.preventDefault();
      },
      streamCreated: (event) => {
        console.log('stream:created', event);
        this.setState({
          publisherIsOnAir: !!this.session?.state?.streams?.length,
        });
        event.preventDefault();
      },
      streamDestroyed: e => {
        console.log('stream:destroyed');
        this.setState({ publisherIsOnAir: false });
        e.preventDefault();
      },
      sessionConnected: (event) => {
        console.log('session:connected', event);
        this.setState({
          isConnected: true,
        })
        if (participantRole === 'publisher' && !webinarStarted) {
          event.target.disconnect();
        }
        this.refreshConnections();
      },
    };
  }

  componentDidMount() {
    this.session.sessionHelper.session.connect();
  };

  componentDidUpdate(prevProps) {
    const { isConnected } = this.state;
    const { webinarStarted, token } = this.props;
    if (webinarStarted && prevProps.webinarStarted === false) {
      this.session.sessionHelper.session.connect(token);
    }
  };

  componentWillUnmount() {
    localStore.removeItem('WebinarChat');
  };

  refreshConnections = async () => {
    await this.setState({ numberOfConnections: 0 });
  };

  handleMessageSend = () => {
    const { message, isConnected } = this.state;
    if (isConnected) {
      this.setState(
        {
          message: '',
        },
        () => {
          console.log('message', message);
          this.session.sessionHelper.session.signal({
            data: message,
          });
        }
      );
    } else {
      console.warn('Session has been disconnected. Operation not allowed.');
    }
  };

  handleInputChange = message => this.setState({ message });

  handleKeyPress = (message, event) => {
    if (isEnterKeyPressed(event)) {
      return this.handleMessageSend();
    }
    return this.setState({ message });
  };

  handleMessageListUpdate = newMessage => {
    const { messages } = this.state;

    const newMessages = [...messages, newMessage];
    this.setState(
      {
        messages: newMessages,
      },
      () => {
        localStore.setItem('WebinarChat', JSON.stringify(newMessages));
      }
    );
  };

  handleErrors = (err) => {
    if (err) {
      console.warn('WebinarRoom::handleErrors', err)
      const {name: errorName, message: errorMessage} = err;
      const { modalContext: { showModal, hideModal }} = this.props;
      showModal(() => (<>{errorMessage}</>), {
        size: 'md',
        hideModal,
      });
    }
  }

  render() {
    const {
      sessionId,
      token,
      publishVideo,
      publishAudio,
      handleVideoToggle,
      handleMicToggle,
      subscribeToVideo,
      subscribeToAudio,
      handleAudioToggle,
      handleScreenToggle,
      participantRole,
      handleVideoSubscription,
      webinarStarted,
      coursePhoto,
      videoSource,
      handleVideoSourceToggle,
    } = this.props;

    const {
      messages,
      message,
      numberOfConnections,
      publisherIsOnAir,
      isConnected
    } = this.state;

    const webinarRoomVideoProps = {
      publisher: {
        publishVideo,
        publishAudio,
        videoSource,
        handleVideoToggle,
        handleMicToggle,
        handleScreenToggle,
        handleVideoSourceToggle,
      },
      subscriber: {
        subscribeToVideo,
        subscribeToAudio,
        handleAudioToggle,
        handleScreenToggle,
        handleVideoSubscription,
      },
    };

    return (
      <div className="sk-webinar-room">
        <OTSession
          apiKey={OPENTOK_API_KEY}
          sessionId={sessionId}
          token={token}
          debug={OPENTOK_DEBUG}
          eventHandlers={this.sessionEventHandlers}
          ref={instance => {
            this.session = instance;
          }}
        >
          <div className="sk-webinar-room__main">
            <div className="sk-webinar-room__video">
              <WebinarRoomVideo
                coursePhoto={coursePhoto}
                publisherIsOnAir={publisherIsOnAir}
                webinarStarted={webinarStarted}
                participantRole={participantRole}
                handleErrors={this.handleErrors}
                {...webinarRoomVideoProps[participantRole]}
              />
            </div>
            <div className="sk-webinar-room__chat">
              <WebinarRoomChat
                handleInputChange={this.handleInputChange}
                handleKeyPress={this.handleKeyPress}
                handleMessageSend={this.handleMessageSend}
                message={message}
                messages={messages}
                isConnected={isConnected}
                webinarStarted={webinarStarted}
                numberOfConnections={numberOfConnections}
              />
            </div>
          </div>
        </OTSession>
      </div>
    );
  }
}

const myProfileQuery = graphql(MY_PROFILE_QUERY, {
  props: ({ data: { getProfile: profile, error, loading, ...ownProps } }) => ({
    gtmTimezone: `${profile.gmt_timezone}`,
    error,
    ...ownProps,
  }),
  options: () => ({
    fetchPolicy: 'cache-only',
  }),
});

WebinarRoom.propTypes = {
  sessionId: PropTypes.string,
  token: PropTypes.string,
  publishVideo: PropTypes.bool,
  publishAudio: PropTypes.bool,
  handleVideoToggle: PropTypes.func,
  handleMicToggle: PropTypes.func,
};

const enhancer = compose(preloadScript, myProfileQuery, withModal);

export default enhancer(WebinarRoom);
