import React, { useEffect, useState } from 'react';
import { compose, graphql } from 'react-apollo';

// Components
import ConfirmDelete from 'routes/ExpertsCabinet/components/ConfirmDelete/ConfirmDelete';

// GraphQL
// import GET_QUIZ from 'queries/Courses/Quiz/getQuiz.gql';
import DELETE_ATTACHMENT from 'mutations/Courses/deleteAttachment.gql';

// Other
import { withModal } from 'containers/ModalProvider/withModal';
import { keysToCamel, isBlank } from 'utils/helpers';
import { QUIZ_ANSWER_IMAGE, QUIZ_QUESTION_IMAGE, } from 'containers/UploadManagerProvider/helpers';
import { withUploadManager } from 'containers/UploadManagerProvider/withUploadManager';
import { IMAGE_FILE, } from 'utils/enums';

const deleteAttachmentMutation = graphql(DELETE_ATTACHMENT, {
  props: ({ mutate }) => ({
    deleteAttachment: id => mutate({ variables: { id } }),
  }),
  options: {
    refetchQueries: ['getQuiz'],
  },
});

const enhancer = compose(withModal, withUploadManager, deleteAttachmentMutation);

export const withQuizImageUpload = WrappedComponent => {
  const HOC = ({
    modalContext: { showModal, hideModal },
    uploadManagerContext: { addFileToQueue, queue, removeFileFromQueue },
    deleteAttachment,
    refetchQuiz,
    ...props
  }) => {
    const handleAddQuizImageFileToQueue = type => ({
      id,
      file: filesList,
      quizId,
      questionId,
      refId,
    }) => {
      const createAttachmentMutationVariables = {
        type,
        filetype: IMAGE_FILE,
        filename: type,
        ref_id: refId,
      };

      if (!isBlank(id)) {
        createAttachmentMutationVariables.id = id;
        createAttachmentMutationVariables.filepath = null;
      };

      const params = {
        type,
        file: filesList[0],
        s3UploadingVariables: {
          file: filesList[0],
          quizId,
          questionId,
          refId,
        },
        createAttachmentMutationVariables,
        s3PostHook: refetchQuiz,
      };

      console.log('image upload params', params);

      addFileToQueue(params);
    };

    const handleCancelMaterialAdding = id => {
      const isFileInQueue = queue.find(queueItem => queueItem.uploadId === id);

      if (isFileInQueue) removeFileFromQueue(id);
    };

    const handleAttachmentDelete = async id => {
      try {
        handleCancelMaterialAdding(id);
        await deleteAttachment(id);
      } catch (error) {
        throw Error(error);
      }
    };

    return <WrappedComponent {...props}
      handleUploadAnswerImage={handleAddQuizImageFileToQueue(QUIZ_ANSWER_IMAGE)}
      handleUploadQuestionImage={handleAddQuizImageFileToQueue(QUIZ_QUESTION_IMAGE)}
      handleImageDelete={handleAttachmentDelete}
    />;
  };

  return enhancer(HOC);
};
