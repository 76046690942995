import React, { useEffect, useState } from 'react';
import { compose, graphql } from 'react-apollo';

// GraphQL
import GET_QUIZ from 'queries/Courses/Quiz/getQuiz.gql';

// Other
import { keysToCamel, isBlank } from 'utils/helpers';

const getQuizQuery = graphql(GET_QUIZ, {
  props: (props) => {
    // console.log('props', props);
    const { data: { getQuiz, error, loading, refetch, ...ownProps }, } = props;
    const parsedQuiz = keysToCamel(getQuiz);

    // console.log('parsed quiz', parsedQuiz);

    return {
      quiz: parsedQuiz,
      refetchQuiz: refetch,
      loading,
      error,
      ...ownProps,
    };
  },
  options: (props) => {
    const { quizId, userCourseId } = props;

    const input = !isBlank(userCourseId)
      ? { id: quizId, user_course_id: userCourseId }
      : { id: quizId };

    return ({
      variables: { input },
      fetchPolicy: 'network-and-cache',
    })
  },
});

const enhancer = compose(getQuizQuery);

export const withQuizData = WrappedComponent => {
  const HOC = ({ quiz, ...props }) => {
    return <WrappedComponent quiz={quiz} {...props} />;
  };

  return enhancer(HOC);
};
