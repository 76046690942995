import React, { useEffect, useState } from 'react';
import { compose, graphql } from 'react-apollo';

// GraphQL
import GET_FILE_URL_MUTATION from 'mutations/Courses/getFileUrl.gql';

const getFileUrlMutation = graphql(GET_FILE_URL_MUTATION, {
  props: ({ mutate }) => ({
    getFileUrl: input => mutate({ variables: { input } }),
  }),
});

const enhancer = compose(getFileUrlMutation);

export const withFileUrl = WrappedComponent => {
  const WithFileUrlHOC = ({ filepath, url: publicUrl, isPublic, getFileUrl, ...props }) => {
    const [isLoaded, setLoadedStatus] = useState(false);
    const [url, setUrl] = useState('');

    useEffect(() => {
      const fetchFileLink = async () => {
        if (filepath && filepath !== null && !isPublic) {
          const graphqlResponse = await getFileUrl({ fileKey: filepath });
          const url = graphqlResponse?.data?.getPresignedFile || '';
          if (url) {
            setUrl(url);
            setLoadedStatus(true);
          }
        } else if (isPublic && publicUrl) {
          setUrl(publicUrl);
          setLoadedStatus(true);
        } else {
          setLoadedStatus(true);
      }
      };
      fetchFileLink();
    }, [filepath, isPublic, publicUrl]);

    return <WrappedComponent fileUrl={url} isPublic={isPublic} isLoaded={isLoaded} {...props} />;
  };

  return enhancer(WithFileUrlHOC);
};
