import React, { useState, useCallback, useEffect, } from 'react';
import { compose, } from 'react-apollo';
import { FormattedMessage } from 'react-intl';
import { injectIntl, } from 'react-intl';

// Components
import TextInput from 'components/Input/Material/TextInput';
import TextAreaInput from 'components/Input/Material/TextAreaInput';
import Text from 'components/Text';
import Button from 'components/Button';
import Image from 'components/Image';
import PricesDependOnCurrency from 'routes/ExpertsCabinet/ToppingEdit/ToppingDetails/PricesConversionsModal/PricesDependOnCurrency';

// Other
import { messages } from 'routes/ExpertsCabinet/CourseEdit/Details/messages';
import { withCurrency } from 'containers/CurrencyProvider/withCurrency';
import { withSharedState } from 'containers/SharedStateProvider/withSharedState';

// Styles
import './index.scss';

const PricesConversionsModal = (props) => {
  const {
    disabled,
    handlePriceChange,
    currencyContext: { currencies, changeDependOnCurrencyRate, },
    sharedStateContext: { sharedState, },
    intl: { formatMessage },
  } = props;
  const { values = {}, errors = {}, touched = {}, handleUpdateVariant } = sharedState?.toppingInformation || {};

  console.log('price conversions', errors, touched);

  return (
    <div className="sk-course-prices-conversions-modal">
      <Text classes='sk-course-prices-conversions-modal__title'><FormattedMessage {...messages.pricesConversions}/></Text>
      <Text classes='sk-course-prices-conversions-modal__subtitle'><FormattedMessage {...messages.pricesConversionsModalInfo}/></Text>
      <div className="sk-course-prices-conversions-modal__fields">
        <Image src='/assets/images/flag-usa.png' />
        <TextInput
          margin="none"
          shrink
          adornment="$"
          position="start"
          // type="number"
          name="price"
          value={(values.price || values.price == 0) ? `${values.price}` : ''}
          onChange={handlePriceChange}
          onBlur={handleUpdateVariant}
          label={'price'}
          error={Boolean(errors.price)}
          disabled={disabled}
          helperText={errors.price || ''}
        />
      </div>
      <div className='sk-course-prices-conversions-modal__line'/>
      <div className='sk-course-prices-conversions-modal__other-currencies'>
        {currencies.filter(currency => currency.currency_id !== 'usd').map(currency => (
          <PricesDependOnCurrency
          currency={currency}
          price={changeDependOnCurrencyRate(Number(values?.price), currency.currency_id)}
          />
        ))}
      </div>
    </div>
  );
};

const enhancer = compose(
  injectIntl,
  withCurrency,
  withSharedState
)

export default enhancer(PricesConversionsModal);
