import React from 'react';
import PropTypes from 'prop-types';

import './Resume.scss';

const Resume = ({color = '#30d5c8'}) => {
  const index = Math.random();
  return(
    <svg class="resume-svg" width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g className="outlined">
        <path d="M9.77862 4.36818L27.5191 15.8081C28.1603 16.3528 28.1603 17.17 27.5191 17.7147L10.4198 28.6099C9.45801 29.427 7.74809 28.8822 7.64122 27.7019L7 5.36691C7 4.1866 8.70992 3.55105 9.77862 4.36818Z" stroke={color} stroke-miterlimit="10"/>
      </g>
      <g className="filled">
        <path d="M9.77862 4.36818L27.5191 15.8081C28.1603 16.3528 28.1603 17.17 27.5191 17.7147L10.4198 28.6099C9.45801 29.427 7.74809 28.8822 7.64122 27.7019L7 5.36691C7 4.1866 8.70992 3.55105 9.77862 4.36818Z" fill={`url(#paint${index}0_linear)`} stroke={color} stroke-miterlimit="10"/>
      </g>
      <defs>
      <linearGradient id={`paint${index}0_linear`} x1="29.9015" y1="30.6828" x2="3.50822" y2="27.9631" gradientUnits="userSpaceOnUse">
      <stop stop-color="#8F26AF"/>
      <stop offset="0.979167" stop-color="#141685"/>
      </linearGradient>
      </defs>
    </svg>
  )
}

export default Resume;
