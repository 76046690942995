import React, { memo } from 'react';
import { compose } from 'react-apollo';

const Icon = ({ width = 28, height = 28, ...props}) => {
  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14 27C21.1797 27 27 21.1797 27 14C27 6.8203 21.1797 1 14 1C6.8203 1 1 6.8203 1 14C1 21.1797 6.8203 27 14 27Z" stroke="#FF375B" stroke-width="2"/>
      <path d="M15.4217 16.8628H13.0632L12.8036 5.27344H15.6925L15.4217 16.8628ZM12.7246 20.4063C12.7246 19.9774 12.8638 19.6238 13.1421 19.3455C13.4205 19.0596 13.8004 18.9167 14.2819 18.9167C14.7634 18.9167 15.1433 19.0596 15.4217 19.3455C15.7 19.6238 15.8392 19.9774 15.8392 20.4063C15.8392 20.82 15.7038 21.1661 15.4329 21.4444C15.1621 21.7228 14.7784 21.862 14.2819 21.862C13.7854 21.862 13.4017 21.7228 13.1309 21.4444C12.86 21.1661 12.7246 20.82 12.7246 20.4063Z" fill="#FF375B"/>
    </svg>
  );
};

const enhancer = compose(
  memo,
);

export default enhancer(Icon);
