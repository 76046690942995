// Core
import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { withApollo, compose } from 'react-apollo';
import { withRouter } from 'react-router';

// Components
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import SignOut from 'components/Icon/Svg/SignOut';
import Link from 'components/Link';
import UserAvatar from 'components/UserAvatar';
import Button from 'components/Button';
import ExpertPlansButton from 'components/Button/ExpertPlansButton';
import CreateToppingModal from 'routes/ExpertsCabinet/ToppingEdit/CreateToppingModal';

// Other
import { messages } from './messages';
import { signOutUser } from 'services/aws/amplify/helpers';
import { withModal } from 'containers/ModalProvider/withModal';
import { withAWS } from 'containers/AWSProvider/withAWS';
import { withSidebar } from 'containers/SidebarProvider/withSidebar';
// import { renderTrimmedText } from "utils/helpers";

// Styles and Assets
import './UserMenu.scss';

const { BASE_URL } = process.env;

const menuItemClasses = {
  gutters: 'mui-override sk-user-menu-item sk-user-menu-item__gutters'
};

const menuListClasses = {
  padding: 'mui-override sk-user-menu-padding'
};

const userMenuGroupClasses = 'mui-override sk-dropdown__group';

class UserMenu extends PureComponent {
  signOut = async () => {
    const {
      awsContext: { updateAWSConfig },
      history,
      client
    } = this.props;

    try {
      await signOutUser();
      history.push(BASE_URL);

      this.onClose();
    } catch (error) {
      throw Error(error);
    }
  };

  onClose = () => {
    const {
      sidebarContext: { hideSidebar, showSidebar }
    } = this.props;
    hideSidebar();

    const isTabletScreenWidth = window.innerWidth <= 1024;

    if (!isTabletScreenWidth) {
      showSidebar('main');
    }
  };

  goToUserCabinet = doNavigation => () => {
    const {
      history,
      sidebarContext: { showSidebar, hideSidebar, width }
    } = this.props;
    const isResponsive = ['xs', 'sm'].includes(width);

    if (doNavigation) {
      history.push('/course-creation');
    }

    if (isResponsive) {
      hideSidebar();
    } else {
      showSidebar('main');
    }
  };

  goToAdmin = doNavigation => () => {
    const {
      history,
      sidebarContext: { showSidebar, hideSidebar, width }
    } = this.props;
    const isResponsive = ['xs', 'sm'].includes(width);

    if (doNavigation) {
      history.push('/admin/panel');
    }

    if (isResponsive) {
      hideSidebar();
    } else {
      showSidebar('main');
    }
  };

  showToppingCreateModal = () => {
    const {
      modalContext: { showModal, hideModal }
    } = this.props;

    showModal(CreateToppingModal, {
      size: 'md',
      hideModal
    });
  };

  goToExpertFeeManagementPage = () => {
    const { history } = this.props;
    history.push('/experts-cabinet/subscription');
  };

  render() {
    const { user, profile } = this.props;
    const { firstName, lastName, role, ...rest } = user;
    const userName = `${firstName} ${lastName}`;

    return (
      <div className='sk-userMenu sk-user-menu'>
        <div className='sk-user-block'>
          <div className='sk-user-block__item'>
            <UserAvatar
              firstName={firstName}
              lastName={lastName}
              avatar={profile?.photo}
              {...rest}
            />
          </div>
          <div className='sk-user-block__item'>
            <span className='sk-dropdown-toggler__title'>{userName}</span>
            <br />
            <small>
              <FormattedMessage {...messages.swap} />
            </small>
          </div>
        </div>
        <MenuList classes={menuListClasses}>
          {['owner', 'manager', 'admin'].includes(role) && (
            <div className={userMenuGroupClasses}>
              <Link to='/admin/panel/dashboard'>
                <MenuItem
                  onClick={this.goToAdmin(true)}
                  classes={menuItemClasses}
                >
                  <FormattedMessage {...messages.dashboard} />
                </MenuItem>
              </Link>
            </div>
          )}
          <div className={userMenuGroupClasses}>
            <Link to='/manage-account/personal-info'>
              <MenuItem
                classes={menuItemClasses}
                onClick={this.goToUserCabinet()}
              >
                <FormattedMessage {...messages.manageAccount} />
              </MenuItem>
            </Link>
          </div>
          {role === 'student' ? (
            <div className={userMenuGroupClasses}>
              <Link to='/student-cabinet/courses'>
                <MenuItem classes={menuItemClasses} onClick={this.onClose}>
                  <FormattedMessage {...messages.myCourses} />
                </MenuItem>
              </Link>
            </div>
          ) : null}
          <div className={userMenuGroupClasses}>
            {role === 'expert' ? (
              <Fragment>
                <Link to='/experts-cabinet/courses'>
                  <MenuItem
                    onClick={this.goToUserCabinet()}
                    classes={menuItemClasses}
                  >
                    <FormattedMessage {...messages.createdCourses} />
                  </MenuItem>
                </Link>
                <Button
                  color='secondary'
                  transparent
                  style={{ marginBottom: 0 }}
                  onClick={this.goToUserCabinet(true)}
                >
                  <FormattedMessage {...messages.createNewCourse} />
                </Button>
                <Button
                  color='primary'
                  transparent
                  onClick={this.showToppingCreateModal}
                >
                  <FormattedMessage {...messages.createNewTopping} />
                </Button>

                <ExpertPlansButton />
              </Fragment>
            ) : (
              !profile.org && (
                <Link to='/onboarding'>
                  <Button color='secondary' transparent onClick={this.onClose}>
                    <FormattedMessage {...messages.becomeAnExpert} />
                  </Button>
                </Link>
              )
            )}
            <MenuItem classes={menuItemClasses} onClick={this.signOut}>
              <ListItemIcon>
                <SignOut />
              </ListItemIcon>
              <FormattedMessage {...messages.signOut} />
            </MenuItem>
          </div>
        </MenuList>
      </div>
    );
  }
}

UserMenu.propTypes = {
  userContext: PropTypes.object,
};

const enhancer = compose(
  withRouter,
  withApollo,
  withAWS,
  withSidebar,
  withModal,
  injectIntl
);

export default enhancer(UserMenu);
