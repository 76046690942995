import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'app.routes.SuccesfulMessage.title',
    defaultMessage: 'Success',
  },
  body: {
    id: 'app.routes.SuccesfulMessage.body',
    defaultMessage:
      "Thank you for your purchase. The payment has been successfully processed and your material will be available in Student’s Cabinet shortly.",
  },
  button: {
    id: 'app.routes.SuccesfulMessage.button',
    defaultMessage: 'Go to Student’s Cabinet',
  },
});
