// Core
import React, { memo, useState, useEffect } from 'react';
import { compose } from 'react-apollo';

// Components
import IconWithCounter from 'components/Icon/IconWithCounter';
import Icon from 'components/Icon/RoundIcon';
import Basket from 'components/Icon/SvgCss/Basket';
import NewCourseNotification from 'containers/Header/CheckoutBlock/NewCourseNotification';
import CheckoutMenu from 'containers/Header/CheckoutBlock/CheckoutMenu';
import FlatDropDown from 'components/DropDown/FlatDropDown';

// Other
import { withShoppingCart } from 'containers/ShoppingCartProvider/withShoppingCart';

// Styles and Assets
import { White } from 'components/Icon/color';

const CheckoutMenuToggler = memo(({ count }) => (
  <IconWithCounter icon={<Basket color={White} active={count} />} count={count} />
));

export const CheckoutBlock = ({
  shoppingCartContext: { shoppingCart },
  ...props
}) => {

  return (
    <FlatDropDown placement='bottom-end' component={CheckoutMenu} checkoutItems={shoppingCart}>
      <CheckoutMenuToggler count={shoppingCart?.length} />
    </FlatDropDown>
  );
};

const enhancer = compose(
  memo,
  withShoppingCart,
);

export default enhancer(CheckoutBlock);
