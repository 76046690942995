import React from 'react';
import PropTypes from 'prop-types';
import { SidebarDefault, PurpleHeart } from 'components/Icon/color';

const User = ({
  height,
  width,
  fillColor,
  strokeColor,
  active="#A9B6C8",
  activeColor="#A9B6C8",
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 19"
    width={width}
    height={height}
  >
    <g
      fill={active ? activeColor : fillColor}
      fillRule="evenodd"
      stroke={active ? activeColor : strokeColor}
    >
      <path
        d="M7.99 8.205c1.07 0 1.968-.372 2.725-1.128.756-.757
      1.129-1.655 1.129-2.724 0-1.07-.373-1.968-1.13-2.724C9.959.872 9.06.5
      7.992.5 6.92.5 6.023.872 5.267 1.629c-.757.756-1.13 1.655-1.13 2.724 0 1.069.373 1.967 1.13 2.724.756.756
      1.655 1.128 2.724 1.128zM15.432 14.137a11.109 11.109 0 0 0-.152-1.183 9.114 9.114 0 0 0-.285-1.165 5.333
      5.333 0 0 0-.446-1.01 3.484 3.484 0 0 0-.613-.805 2.462 2.462 0 0 0-.808-.502 2.91 2.91 0 0 0-1.08-.192c.074
      0-.053.065-.203.162-.247.161-.528.343-.848.547-.354.226-.8.424-1.34.598a5.392 5.392 0 0 1-1.667.268c-.559
      0-1.115-.09-1.666-.268-.54-.174-.986-.372-1.34-.598-.319-.203-.6-.385-.848-.546-.15-.098-.277-.163-.203-.163-.4
      0-.759.064-1.08.191a2.461 2.461 0 0 0-.808.503 3.489 3.489 0 0 0-.613.804c-.187.33-.336.666-.446 1.01A9.115
      9.115 0 0 0 .7 12.955c-.076.425-.127.82-.152 1.182-.026.37-.038.75-.038 1.139 0 .776.225 1.36.672 1.786.453.432
      1.062.65 1.854.65h9.907c.792 0 1.4-.218 1.855-.65.447-.426.672-1.01.672-1.786
      0-.39-.013-.77-.039-1.138zm.998-.07z"
      />
    </g>
  </svg>
);

export default User;
