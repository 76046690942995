// Core
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const CourseGrid = ({ children, fullWidth }) => (
  <div
    className={classNames('grid grid_gapFree', { grid_fullWidth: fullWidth })}
  >
    <div className="grid_item-xs-offset-1-24 grid_item-md-offset-2-24" />
    <div className="grid_item-xs-22-24 grid_item-md-20-24">{children}</div>
    <div className="grid_item-xs-offset-1-24 grid_item-md-offset-2-24" />
  </div>
);

CourseGrid.defaultProps = {
  fullWidth: true,
  children: () => {},
};

CourseGrid.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  fullWidth: PropTypes.bool,
};

export default CourseGrid;
