// Core
import React, { PureComponent } from 'react';
import { graphql, compose } from 'react-apollo';
import { Formik } from 'formik';

// Components
import ContentHeader from 'containers/ContentHeader';
import Card from 'components/Card';
import CompanyInfoForm from 'routes/ManageAccount/CompanyInfo/CompanyInfoForm';
import Loader from 'components/Loader';

// GraphQL
import MY_COMPANY_QUERY from 'queries/Company/getCompany.gql';
import UPDATE_COMPANY from 'mutations/Company/updateCompany.gql';

// Other
import { parseFetchedData, keysToSnake } from 'utils/helpers';
import { getYupSchema } from 'services/yup';
import { hasLoader } from 'containers/HOCs/hasLoader';
import { withNotification } from 'containers/NotificationProvider/withNotification';
import { withAuthenticatedUser } from "containers/AuthenticatedUserProvider/withAuthenticatedUser";

// Styles and Assets
import './CompanyInfo.scss';

class CompanyInfo extends PureComponent {
  handleSubmit = async (values, { setSubmitting }) => {
    const { updateProfile, showNotificationBar } = this.props;

    const { email, ...valuesToUpdate } = values;

    const {
      city: { id: city },
      country: { id: country },
      primaryColour: { hex: primaryColour } = {hex: null},
      secondaryColour: { hex: secondaryColour } = {hex: null},
      ...rest
    } = valuesToUpdate;
    try {
      const payload = { city, country, ...rest }
      if (primaryColour) {
        payload.primaryColour = `#${primaryColour}`;
      }
      if (secondaryColour) {
        payload.secondaryColour = `#${secondaryColour}`;
      }
      await updateProfile(keysToSnake(payload));
      setSubmitting(false);
      showNotificationBar('saved');
    } catch (error) {
      setSubmitting(false);
    }
  };

  render() {
    const { loading, error, company, becomeExpert } = this.props;
    if (error) throw Error(error);
    if (loading || !company) return <Loader />;

    const {
      id,
      fqdn,
      companyName,
      companyPhone,
      companySize,
      email,
      country,
      city,
      logo,
      ownerId,
      primaryColour,
      secondaryColour,
    } = parseFetchedData(company);

    const initialFields = {
      id,
      fqdn,
      companyName,
      companyPhone,
      companySize,
      country,
      primaryColour,
      secondaryColour,
      city,
      logo,
      email,
      ownerId,
    };

    const validationSchema = 'companyInfoSchema';

    return (
      <div className="sk-company-info">
        <ContentHeader />
        <Card classes="sk-company-info__inner">
          <Formik
            onSubmit={async (values, formikBag) => {
              await this.handleSubmit(values, formikBag);
            }}
            render={props => (
              <CompanyInfoForm
                becomeExpert={becomeExpert}
                isExpert
                handleAvatarUpload={this.handleAvatarUpload}
                logo={logo}
                {...props}
              />
            )}
            initialValues={initialFields}
            validationSchema={getYupSchema(validationSchema)}
            validateOnChange={false}
            validateOnBlur={false}
          />
        </Card>
      </div>
    );
  }
}

const myProfileQuery = graphql(MY_COMPANY_QUERY, {
  props: ({ data: { company, error, loading, ...ownProps } }) => ({
    company,
    error,
    ...ownProps,
  }),
  options: ({ userContext: { profile: { org } } }) => ({
    fetchPolicy: 'network-and-cache',
    variables: {
      id: org,
    },
  }),
});


const updateProfileMutation = graphql(UPDATE_COMPANY, {
  props: ({ mutate }) => ({
    updateProfile: input => mutate({ variables: { input } }),
  }),
  options: {
    refetchQueries: ['getProfile'],
  },
});

const enhancer = compose(
  withNotification,
  withAuthenticatedUser,
  updateProfileMutation,
  myProfileQuery
);

export default hasLoader(enhancer(CompanyInfo), MY_COMPANY_QUERY);
