// Core
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';

// Components
import WebinarVideo from 'components/Icon/Svg/WebinarVideo';
import WebinarVideoOff from 'components/Icon/Svg/WebinarVideoOff';

// Other
import withAction from 'components/Icon/withAction';

const WebinarVideoButton = ({ handleClick, action, onAir }) => (
  <button type="button" onClick={handleClick}>
    {onAir ? (
      <WebinarVideo active={action} />
    ) : (
      <WebinarVideoOff active={action} />
    )}
  </button>
);

WebinarVideoButton.propTypes = {
  handleClick: PropTypes.func,
  action: PropTypes.bool,
  onAir: PropTypes.bool,
};

const enhancer = compose(
  memo,
  withAction
);

export default enhancer(WebinarVideoButton);
