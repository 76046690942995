import React, { memo, useCallback } from 'react';
import { compose } from 'react-apollo';
import Switch from '@material-ui/core/Switch';
import { injectIntl } from 'react-intl';

// Components
import Text from 'components/Text';

// Other
import { messages } from 'routes/ExpertsCabinet/ExpertsFee/messages.js';

//Styles
import './index.scss';

const classes = {
  root: 'mui-override sk-switch',
  switchBase: 'mui-override sk-switch__switchBase',
  thumb: 'mui-override sk-switch__thumb',
  track: 'mui-override sk-switch__track',
  checked: 'mui-override sk-switch__checked',
};

const PREFIX = 'sk-subscription-select-billing-period';
const BillingPeriodSelect = ({ disabled, billingPeriod, updateSubscription, intl: { formatMessage }, ...props }) => {
  const checked = billingPeriod == 'monthly';
  const handleChange = useCallback((event) => updateSubscription('billingPeriod', event?.target?.checked ? 'monthly' : 'annual'), [updateSubscription]);

  return (
    <div className={PREFIX}>
    <Text>{formatMessage(messages.billingPeriodTitle)}</Text>
      <div className={`${PREFIX}__switcher`}>
        <Text size='small' className={`${PREFIX}__switcher__labels`}>{formatMessage(messages.billingPeriodYear)}</Text>
        <Switch
          classes={classes}
          disabled={disabled}
          checked={checked}
          onChange={handleChange}
        />
        <Text size='small' className={`${PREFIX}__switcher__labels`}>{formatMessage(messages.billingPeriodMonth)}</Text>
      </div>
    </div>
  );
};

const enhancer = compose(
  memo,
  injectIntl
);

export default enhancer(BillingPeriodSelect);
