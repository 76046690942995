import React, { memo } from 'react';
import { compose } from 'react-apollo';
import { injectIntl } from 'react-intl';

// Components
import Text from 'components/Text';
import Button from 'components/Button';
import Card from 'components/Card';
import MaxCoursesSelect from 'routes/ExpertsCabinet/ExpertsFee/SubscriptionSelect/MaxCoursesSelect';
import BillingPeriodSelect from 'routes/ExpertsCabinet/ExpertsFee/SubscriptionSelect/BillingPeriodSelect';
import TotalPrice from 'routes/ExpertsCabinet/ExpertsFee/SubscriptionSelect/TotalPrice';

// Other
import { withLanguage } from 'containers/LanguageProvider/withLanguage';
import { messages } from 'routes/ExpertsCabinet/ExpertsFee/messages.js';

//Styles
import './index.scss';

const TITLES = {
  package: 'Unlimited',
  constructor: 'Basic',
}

const PREFIX = 'sk-experts-cabinet-fee-subscription-select'
const SubscriptionSelect = ({
  plan: { title, price, discountPerCourse, discountCoursesLimit },
  maxCourses,
  billingPeriod,
  updateSubscription,
  selectSubscription,
  languageContext: { language },
  intl: { formatMessage },
  ...props
}) => {
  return (
    <div className={PREFIX}>
      <Text>{title[language]}</Text>
      <Card classes={`${PREFIX}__form`}>
        <MaxCoursesSelect maxCourses={maxCourses} updateSubscription={updateSubscription} />
        <BillingPeriodSelect billingPeriod={billingPeriod} updateSubscription={updateSubscription} />
        <TotalPrice
          price={price}
          billingPeriod={billingPeriod}
          discountPerCourse={discountPerCourse}
          discountCoursesLimit={discountCoursesLimit}
          maxCourses={maxCourses}
          updateSubscription={updateSubscription}
        />
        <Button onClick={selectSubscription}>{formatMessage(messages.subscribeButton)}</Button>
      </Card>
    </div>
  );
};

const enhancer = compose(
  memo,
  injectIntl,
  withLanguage,
);

export default enhancer(SubscriptionSelect);
