import React from 'react';

const Icon = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="10.2941" y="11.7647" width="4.41177" height="1.47059" fill="white"/>
      <path d="M9.56406 17.7888L6.83297 20.4642C6.55986 20.7318 6.1502 20.5311 6.1502 20.1967V17.9895C6.1502 17.8557 6.08192 17.7888 5.94536 17.7888H5.5357C4.10188 17.7888 2.94116 16.6518 2.94116 15.2472V6.95339C2.94116 5.5488 4.10188 4.41174 5.5357 4.41174H19.4643C20.8981 4.41174 22.0588 5.5488 22.0588 6.95339V15.2472C22.0588 16.6518 20.8981 17.7888 19.4643 17.7888H9.90545H9.56406Z" stroke="#A9B6C8" strokeMiterlimit="10"/>
      <path d="M7.35294 7.5882C7.07679 7.5882 6.85294 7.81205 6.85294 8.0882C6.85294 8.36434 7.07679 8.5882 7.35294 8.5882L7.35294 7.5882ZM17.6471 8.5882C17.9232 8.5882 18.1471 8.36434 18.1471 8.0882C18.1471 7.81205 17.9232 7.5882 17.6471 7.5882L17.6471 8.5882ZM7.35294 8.5882L17.6471 8.5882L17.6471 7.5882L7.35294 7.5882L7.35294 8.5882Z" fill="#A9B6C8"/>
      <path d="M7.35294 10.5294C7.07679 10.5294 6.85294 10.7533 6.85294 11.0294C6.85294 11.3056 7.07679 11.5294 7.35294 11.5294L7.35294 10.5294ZM17.6471 11.5294C17.9232 11.5294 18.1471 11.3056 18.1471 11.0294C18.1471 10.7533 17.9232 10.5294 17.6471 10.5294L17.6471 11.5294ZM7.35294 11.5294L17.6471 11.5294L17.6471 10.5294L7.35294 10.5294L7.35294 11.5294Z" fill="#A9B6C8"/>
      <path d="M7.35294 13.4706C7.07679 13.4706 6.85294 13.6944 6.85294 13.9706C6.85294 14.2467 7.07679 14.4706 7.35294 14.4706L7.35294 13.4706ZM11.0294 14.4706C11.3055 14.4706 11.5294 14.2467 11.5294 13.9706C11.5294 13.6944 11.3055 13.4706 11.0294 13.4706L11.0294 14.4706ZM7.35294 14.4706L11.0294 14.4706L11.0294 13.4706L7.35294 13.4706L7.35294 14.4706Z" fill="#A9B6C8"/>
    </svg>
  );
};

export default Icon;
