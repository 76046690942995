import React from 'react';
import { AuthenticatedUserConsumer } from 'containers/AuthenticatedUserProvider/AuthenticatedUserConsumer';

export function withAuthenticatedUser(Component) {
  return function AuthenticatedUserComponent(props) {
    return (
      <AuthenticatedUserConsumer>
        {contexts => <Component {...props} {...contexts} />}
      </AuthenticatedUserConsumer>
    );
  };
}
