import React from 'react';
import PropTypes from 'prop-types';
import { PurpleLight, White } from 'components/Icon/color';

import './Cross.scss';

const Cross = ({ height=20, width=21, color=PurpleLight, hoverColor=White, strokeWidth }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 21 20"
    className="cross-icon"
  >
    <g className="purple">
      <rect x="1" y="17.6777" width="25" height="2" rx="1" transform="rotate(-45 1 17.6777)" fill={color} />
      <rect x="2" width="25" height="2" rx="1" transform="rotate(45 2 0)" fill={color} />
    </g>
    <g className="white">
      <rect x="1" y="17.6777" width="25" height="2" rx="1" transform="rotate(-45 1 17.6777)" fill={hoverColor} />
      <rect x="2" width="25" height="2" rx="1" transform="rotate(45 2 0)" fill={hoverColor} />
    </g>
  </svg>
);


export default Cross;
