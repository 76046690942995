import React, { memo, useCallback } from 'react';
import { compose } from 'react-apollo';
import { injectIntl } from 'react-intl';

// Components
import Text from 'components/Text';
import Icon from 'routes/ExpertsCabinet/ExpertsFee/SubscriptionSelect/MaxCoursesSelect/UnlimitedCourses/Icon.js';

// Other
import { messages } from 'routes/ExpertsCabinet/ExpertsFee/messages.js';

//Styles
import './index.scss';

const PREFIX = 'sk-subscription-unlimited-courses';
const UnlimitedCourses = ({ intl: { formatMessage }, ...props }) => {
  return (
    <div className={PREFIX}>
      <Icon />
      <Text>{formatMessage(messages.unlimitedCourses)}</Text>
    </div>
  );
};

const enhancer = compose(
  memo,
  injectIntl
);

export default enhancer(UnlimitedCourses);
