export const MAPPING_INPUT = {
  id: 'id',
  title: ({ ru, en }) => ({ titleRU: ru, titleEN: en }),
  price: (prices) => {
    const annualPrice = prices.find(price => price?.type == 'annual')?.amount || '';
    const monthlyPrice = prices.find(price => price?.type == 'monthly')?.amount || '';
    return { annualPrice, monthlyPrice };
  },
  maxCourses: 'maxCourses',
};
