import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';
import { withRouter } from 'react-router';
import { injectIntl, FormattedMessage } from 'react-intl';

// Components
import ProductionMessage from 'containers/ErrorMessage/ProdctionMessage/';
import DevelopmentMessage from 'containers/ErrorMessage/DevelopmentMessage/';

// Other
import { messages } from 'containers/ErrorMessage/messages.js';
import { withSentry } from 'containers/SentryProvider/withSentry';

const ErrorMessage = (props) => {
  const { history, intl: { formatMessage }, sentryContext: { hasError, error, errorInfo, }} = props;

  if (!hasError) history.push('/auth');

  const isPoduction = true;
  // const isPoduction = process.env.NODE_ENV === 'production';
  const message = isPoduction
    ? (<ProductionMessage history={history} formatMessage={formatMessage} {...{error, errorInfo,}} />)
    : (<DevelopmentMessage {...{error, errorInfo,}} />);

  return message;
}

ErrorMessage.propTypes = {
  enviroment: PropTypes.string,
  error: PropTypes.object,
  errorInfo: PropTypes.object,
};

const enhancer = compose(
  withSentry,
  injectIntl,
  withRouter
)

export default enhancer(ErrorMessage);
