// Core
import React, { PureComponent, Fragment } from 'react';
import classnames from 'classnames';

// Material Components
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';

// Components
import Text from 'components/Text';
import DeletedCoursesTableRow from 'routes/AdminPanel/DeletedCoursesList/DeletedCoursesTable/DeletedCoursesTableRow';

// Other
import { Grey4, Black } from 'components/Icon/color';

// Styles and Assets
import './DeletedCoursesTable.scss';

const classes = {
  table: {
    root: 'mui-override sk-сoursesTable',
  },
};

class DeletedCoursesTable extends PureComponent {

  renderTableBody = courses => {
    return courses.map((item, index, arr) => (
      <DeletedCoursesTableRow key={item.id} item={item} index={index} arr={arr} />
    ));
  };

  render() {
    const { courses, } = this.props;

    const headerHeight = 63;
    const rowHeight = 63;
    const rowsCount = +courses?.length || 0;

    return (
      <Table
        className="mui-override sk-сoursesTable"
        classes={classes.table}
        style={{ height: headerHeight + (rowHeight*rowsCount) }}
      >
        <TableHead>
          <TableRow>
            <TableCell
              classes={classes.table}
              className="mui-override sk-сoursesTable__cell"
              align="left"
            >
              <Text bold align="left">
                COURSE
              </Text>
            </TableCell>
            <TableCell
              classes={classes.table}
              className="mui-override sk-сoursesTable__cell"
            >
              <Text bold align="left">
                CATEGORY
              </Text>
            </TableCell>
            <TableCell
              classes={classes.table}
              className="mui-override sk-сoursesTable__cell"
            >
              <Text bold align="left">
                SUBCATEGORY
              </Text>
            </TableCell>
            <TableCell
              classes={classes.table}
              className="mui-override sk-сoursesTable__cell"
            >
              <Text bold align="left">
                EXPERT
              </Text>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            {courses && courses.length > 0 ? this.renderTableBody(courses) : null}
        </TableBody>
      </Table>
    );
  }
}

export default DeletedCoursesTable;
