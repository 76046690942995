// Core
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import { withRouter } from 'react-router';

// Services
import { ShoppingCartContext } from './shoppingCart-context';
import { localStore } from 'services/localStorage';
import { isJsonString, deduplicate } from 'utils/helpers';
import {
  parseCoursePropertiesKeysInSnake,
  parseCoursePropertiesKeysInCamel,
} from './helpers';

// GraphQl
import GET_DRAFT_ORDER_BY_USER_ID from './query/getDraftOrderByUserId.gql';
import GET_USER_PROFILE_QUERY from 'queries/UserProfile/getProfile.gql';
import CREATE_ORDER_MUTATION from './query/createOrder.gql';
import UPDATE_ORDER_COURSES_MUTATION from './query/updateOrderCourses.gql';

const saveCoursesInLocalStore = courses => {
  localStore.setItem('ShoppingCart', JSON.stringify({ items: courses }));
};

class ShoppingCartProvider extends PureComponent {
  state = {
    shoppingCart: this._getShoppingCartFromLocalStorage(),
    initLoad: true,
    isOrderLoading: false,
  };

  _getShoppingCartFromLocalStorage() {
    const localStoredShoppingCart = localStore.getItem('ShoppingCart');
    const parsed =
      localStoredShoppingCart && isJsonString(localStoredShoppingCart)
        ? JSON.parse(localStoredShoppingCart)?.items
        : [];

    return parsed;
  }

  _isCourseAddedInShoppingCart = course => {
    const { shoppingCart } = this.state;
    const courseIndex = shoppingCart.findIndex(
      courseInShoppingCart =>
        course.courseId === courseInShoppingCart.courseId ||
        course.id === courseInShoppingCart.courseId
    );
    const isAdded = courseIndex !== -1;

    return isAdded;
  };

  _updateShoppingCart = (newShoppingCart, isInitLoad) => {
    newShoppingCart = deduplicate(newShoppingCart, 'courseId');
    const newState = { shoppingCart: newShoppingCart };

    if (isInitLoad) newState.initLoad = false;

    this.setState(() => newState);
    saveCoursesInLocalStore(newShoppingCart);
  };

  _filterShoppingCartInactiveCourses = (courses) => {}

  async componentDidUpdate(prevProps, prevState) {
    const {
      profile,
      draftOrder,
      createOrder,
      updateOrder,
      refetchDraftOrder,
    } = this.props;
    const { shoppingCart, initLoad, draftOrderId, isOrderLoading } = this.state;
    const isAutheniticated = !!profile?.id;
    // console.log('did update 0', shoppingCart);

    if (isAutheniticated && draftOrder.isLoaded) {
      const isDraftOrderFound = draftOrder.id !== null;

      // console.log('did update', initLoad);

      if (isDraftOrderFound && initLoad) {
        const mergedStateAndDbCourses = deduplicate(
          [...shoppingCart, ...draftOrder.courses],
          'courseId'
        );
        const input = {
          items: mergedStateAndDbCourses,
          user_id: profile.id,
          order_id: draftOrder.id,
        };

        const hasShoppingCartNewCourses =
          mergedStateAndDbCourses.length > draftOrder.courses.length;

        if (hasShoppingCartNewCourses) {
          await updateOrder(input);
          refetchDraftOrder();
        }

        this._updateShoppingCart(mergedStateAndDbCourses, true);
      } else if (!isOrderLoading) {
        const isStateChanged = shoppingCart.length !== prevState?.shoppingCart.length;

        // console.log('else', isStateChanged, initLoad);

        try {
          if (isDraftOrderFound && isStateChanged) {
            this.setState(() => ({ isOrderLoading: true }));

            const input = {
              items: shoppingCart,
              user_id: profile.id,
              order_id: draftOrder.id,
            };
            await updateOrder(input);
            refetchDraftOrder();

            this.setState(() => ({ isOrderLoading: false }));
          } else if ((isStateChanged || initLoad) && !isDraftOrderFound) {
          // } else if ((isStateChanged || (initLoad && shoppingCart.length > 0)) && !isDraftOrderFound) {
            // debugger;
            if (initLoad) this.setState(() => ({ initLoad: false }));
            await createOrder({ items: shoppingCart });
            refetchDraftOrder();
          }
        } catch (error) {
          throw Error(error);
        }
      }
    }

    const isDraftOrderChangedOrUpdating = draftOrderId !== draftOrder?.id;
    if (isDraftOrderChangedOrUpdating)
      this.setState(() => ({ draftOrderId: draftOrder?.id || null }));
  }

  componentWillUnmount() {
    const { profile } = this.props;
    const isAutheniticated = !!profile?.id;
    if (isAutheniticated) this.clearLocalShoppingCart();
  }

  addCourseInShoppingCart = async newCourse => {
    if (this._isCourseAddedInShoppingCart(newCourse)) {
      return;
    }
    const { shoppingCart } = this.state;

    const parsedCourse = parseCoursePropertiesKeysInCamel(
      parseCoursePropertiesKeysInSnake(newCourse)
    );

    const updatedShoppingCart = [...shoppingCart, parsedCourse];
    this._updateShoppingCart(updatedShoppingCart);
  };

  removeCourseFromShoppingCart = async courseToRemove => {
    if (!this._isCourseAddedInShoppingCart(courseToRemove)) {
      return;
    }

    const { shoppingCart } = this.state;
    const updatedShoppingCart = shoppingCart.filter(
      course => course.courseId !== courseToRemove.courseId
    );

    this._updateShoppingCart(updatedShoppingCart);
  };

  clearLocalShoppingCart = async () => {
    saveCoursesInLocalStore([]);
  };

  clearShoppingCartInStateAndLocalStorage = async () => {
    await this.clearLocalShoppingCart();
    this.setState(() => ({ shoppingCart: [] }));
  }

  handleCartItemClick = courseId => () => {
    const { history } = this.props;
    history.push(`/course-details/${courseId}`);
  };

  handleRemoveCourseFromCart = course => async (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    };

    try {
      if (!course?.courseId) {
        throw Error('Course id are missed! You can not remvoe course without mention it id.');
      };
      await this.removeCourseFromShoppingCart(course);
    } catch (error) {
      throw Error(error);
    }
  };

  handleAddCourseToCart = courseProps => async (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    };

    try {
      if (!courseProps) {
        throw Error('Course properties are missed! You can not create course with empty properties.');
      };
      await this.addCourseInShoppingCart(courseProps);
    } catch (error) {
      throw Error(error);
    }
  };

  handleProceedClick = (type) => {
    const { profile, history } = this.props;
    const isUserAuthenticated = !!profile;
    const nextStep = isUserAuthenticated ? `checkout/${type}` : 'auth';
    history.push(`/${nextStep}`);
  };

  handleBuyNowClick = courseProps => async () => {
    try {
      await this.addCourseInShoppingCart(courseProps);
      this.handleProceedClick('courses');
    } catch (error) {
      throw Error(error);
    }
  };

  render() {
    const { shoppingCart, isOrderLoading } = this.state;
    const { children, profile, draftOrder, refetchDraftOrder } = this.props;

    return (
      <ShoppingCartContext.Provider
        value={{
          shoppingCartContext: {
            draftOrder,
            isOrderLoading,
            profile,
            shoppingCart,
            _isCourseAddedInShoppingCart: this._isCourseAddedInShoppingCart,
            getCurrentShoppingCart: () => shoppingCart,
            addCourseInShoppingCart: this.addCourseInShoppingCart,
            removeCourseFromShoppingCart: this.removeCourseFromShoppingCart,
            clearLocalShoppingCart: this.clearLocalShoppingCart,
            clearShoppingCartInStateAndLocalStorage: this.clearShoppingCartInStateAndLocalStorage,
            refetchDraftOrder: refetchDraftOrder,
            handleRemoveCourseFromCart: this.handleRemoveCourseFromCart,
            handleAddCourseToCart: this.handleAddCourseToCart,
            handleBuyNowClick: this.handleBuyNowClick,
            handleProceedClick: this.handleProceedClick,
            handleCartItemClick: this.handleCartItemClick,
          },
        }}
      >
        {React.Children.only(children)}
      </ShoppingCartContext.Provider>
    );
  }
}

ShoppingCartProvider.defaultProps = {
  draftOrder: {
    isLoaded: false,
    id: null,
    courses: [],
  },
};

const userProfileQuery = graphql(GET_USER_PROFILE_QUERY, {
  props: ({ data: { getProfile, error, loading, ...ownProps } }) => ({
    profile: getProfile,
    error,
    ...ownProps,
  }),
  options: () => ({
    fetchPolicy: 'cache-only',
  }),
});

const userOrdersQuery = graphql(GET_DRAFT_ORDER_BY_USER_ID, {
  skip: ({ profile }) => !profile?.id,
  props: ({
    data: { getDraftOrderByUserId, error, loading, refetch, ...ownProps },
  }) => {
    let id;
    let courses = [];
    let totalPrice = 0;

    if (error) throw Error(error);

    if (!loading) {
      id = null;
      const isDraftOrderFound = getDraftOrderByUserId?.items.length > 0;
      if (isDraftOrderFound) {
        const draftOrder = getDraftOrderByUserId.items[0];
        id = draftOrder.id;
        totalPrice = draftOrder.courses.reduce((totalPrice, course) => totalPrice + course.price, 0);
        courses = draftOrder.courses.map(course => {
          const paresedCourse = parseCoursePropertiesKeysInCamel(course);
          delete paresedCourse.priceWithDiscount;
          return paresedCourse;
        }
        );
      }
    };

    return {
      draftOrder: {
        isLoaded: !loading,
        id,
        courses,
        totalPrice,
      },
      refetchDraftOrder: refetch,
      error,
      ...ownProps,
    };
  },
  options: ({ profile }) => ({
    variables: {
      user_id: profile?.id,
    },
  }),
});

const createOrderMutation = graphql(CREATE_ORDER_MUTATION, {
  props: ({ mutate }) => ({
    createOrder: ({ items }) => {
      const courses = items.map(parseCoursePropertiesKeysInSnake);

      const input = {
        courses,
      };

      return mutate({ variables: { input } });
    },
  }),
});

const updateOrdertMutation = graphql(UPDATE_ORDER_COURSES_MUTATION, {
  props: ({ mutate }) => ({
    updateOrder: ({ items, ...input }) => {
      const courses = items.map(parseCoursePropertiesKeysInSnake);
      input.courses = courses;

      return mutate({ variables: { input } });
    },
  }),
});

const enhancer = compose(
  withRouter,
  userProfileQuery,
  userOrdersQuery,
  createOrderMutation,
  updateOrdertMutation
);

export default enhancer(ShoppingCartProvider);
