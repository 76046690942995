// Core
import React, { memo } from 'react';
import { compose } from 'react-apollo';
import { injectIntl } from 'react-intl';

// Components
import Button from 'components/Button';
import Text from 'components/Text';

// Other
import { messages } from 'routes/ExpertsCabinet/CourseEdit/messages';
import { sharedMessages } from 'services/i18n/sharedMessages/messages';

// Styles and Assets
import './index.scss';

const PREFIX = 'sk-subscription-max-courses-limit';
const CourseHasNoStudentsWarning = ({
  maxCourses,
  intl: { formatMessage },
  formatHtmlMessage,
  hideModal,
  ...props
}) => {
  return (
    <div className={PREFIX}>
      <Text>{formatMessage(messages.attentionTitle)}</Text>
      <Text size='small'>{formatMessage(messages.courseHasNoStudentsWarning)}</Text>
      <Button
        color="primary"
        onClick={hideModal}
      >
        {formatMessage(sharedMessages.understandButton)}
      </Button>
    </div>
  );
};

const enhancer = compose(
  memo,
  injectIntl,
);

export default enhancer(CourseHasNoStudentsWarning);
