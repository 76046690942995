import React, { Fragment } from 'react';
import MaterialTabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import './Tabs.scss';

const tabClasses = {
  root: 'mui-override sk-tabs-tab',
  //label: 'mui-override sk-tabs-tab_label',
  selected: 'mui-override  sk-tabs-tab tab-selected',
  wrapper: 'mui-override  sk-tabs-tab_wrapper',
};

const tabsClasses = {
  root: 'mui-override sk-tabs',
  indicator: 'mui-override sk-tabs-tab__indicator',
  scroller: 'mui-override sk-tabs__scroller',
};

const Tabs = ({ tabs, value, handleChange, disabledTab, variant='standard', centered=true }) => (
  <Fragment>
    {tabs?.length ? (
      <MaterialTabs
        value={value}
        onChange={handleChange}
        variant={variant}
        centered={centered}
        classes={tabsClasses}
      >
        {tabs.map(({ label, value: tabValue }) => (
          <Tab
            label={label}
            value={tabValue}
            key={tabValue}
            classes={tabClasses}
            disabled={disabledTab === tabValue}
          />
        ))}
      </MaterialTabs>
    ) : null}
  </Fragment>
);

export default Tabs;
