import React from 'react';

import './index.scss';

const BlockquoteOnHover = () => (
  <svg
    className="hover"
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.691406" width="32" height="32" rx="2" fill="white" />
    <path
      d="M24.4862 8.44815H12.5822C12.3347 8.44815 12.1342 8.64873 12.1342 8.89615C12.1342 9.14357 12.3347 9.34415 12.5822 9.34415H24.4862C24.7336 9.34415 24.9342 9.14357 24.9342 8.89615C24.9342 8.64873 24.7336 8.44815 24.4862 8.44815Z"
      fill="#7145D8"
    />
    <path
      d="M24.4862 15.6164H12.5822C12.3347 15.6164 12.1342 15.817 12.1342 16.0644C12.1342 16.3118 12.3347 16.5124 12.5822 16.5124H24.4862C24.7336 16.5124 24.9342 16.3118 24.9342 16.0644C24.9342 15.817 24.7336 15.6164 24.4862 15.6164Z"
      fill="#7145D8"
    />
    <path
      d="M24.4862 21.8884H12.5822C12.3347 21.8884 12.1342 22.089 12.1342 22.3364C12.1342 22.5838 12.3347 22.7844 12.5822 22.7844H24.4862C24.7336 22.7844 24.9342 22.5838 24.9342 22.3364C24.9342 22.089 24.7336 21.8884 24.4862 21.8884Z"
      fill="#7145D8"
    />
    <path
      d="M9.15984 10.6259C9.23984 10.6259 9.29984 10.6442 9.33984 10.6809C9.37984 10.7176 9.39984 10.7692 9.39984 10.8359C9.39984 10.9792 9.31984 11.0509 9.15984 11.0509H7.48984C7.32651 11.0509 7.24484 10.9792 7.24484 10.8359C7.24484 10.7692 7.26484 10.7176 7.30484 10.6809C7.34818 10.6442 7.40984 10.6259 7.48984 10.6259H8.06484V8.1309L7.54984 8.4559C7.51651 8.4759 7.48318 8.4859 7.44984 8.4859C7.39318 8.4859 7.34318 8.4609 7.29984 8.4109C7.25984 8.3609 7.23984 8.30424 7.23984 8.2409C7.23984 8.16424 7.27317 8.10424 7.33984 8.0609L8.10984 7.5709C8.18651 7.52424 8.25984 7.5009 8.32984 7.5009C8.40318 7.5009 8.46318 7.52424 8.50984 7.5709C8.55651 7.61757 8.57984 7.6809 8.57984 7.7609V10.6259H9.15984Z"
      fill="#7145D8"
    />
    <path
      d="M9.19496 16.6769C9.27496 16.6769 9.33496 16.6953 9.37496 16.7319C9.4183 16.7686 9.43996 16.8203 9.43996 16.8869C9.43996 17.0303 9.3583 17.1019 9.19496 17.1019H7.26496C7.19163 17.1019 7.1333 17.0819 7.08996 17.0419C7.04996 16.9986 7.02996 16.9453 7.02996 16.8819C7.02996 16.8053 7.05996 16.7353 7.11996 16.6719L8.29496 15.3869C8.45163 15.2169 8.56496 15.0619 8.63496 14.9219C8.70496 14.7819 8.73996 14.6436 8.73996 14.5069C8.73996 14.3303 8.6883 14.1953 8.58496 14.1019C8.48496 14.0053 8.34163 13.9569 8.15496 13.9569C7.87163 13.9569 7.58996 14.0653 7.30996 14.2819C7.2733 14.3053 7.24163 14.3236 7.21496 14.3369C7.19163 14.3469 7.16663 14.3519 7.13996 14.3519C7.0933 14.3519 7.0533 14.3303 7.01996 14.2869C6.98663 14.2436 6.96996 14.1919 6.96996 14.1319C6.96996 14.0486 7.0033 13.9819 7.06996 13.9319C7.20996 13.8119 7.3783 13.7169 7.57496 13.6469C7.77163 13.5736 7.96996 13.5369 8.16996 13.5369C8.49996 13.5369 8.76163 13.6219 8.95496 13.7919C9.15163 13.9586 9.24996 14.1853 9.24996 14.4719C9.24996 14.6753 9.20496 14.8686 9.11496 15.0519C9.0283 15.2353 8.88163 15.4403 8.67496 15.6669L7.72996 16.6769H9.19496Z"
      fill="#7145D8"
    />
    <path
      d="M8.68496 21.3429C8.8983 21.4029 9.05996 21.5062 9.16996 21.6529C9.27996 21.7962 9.33496 21.9779 9.33496 22.1979C9.33496 22.5045 9.22663 22.7462 9.00996 22.9229C8.79663 23.0995 8.50663 23.1879 8.13996 23.1879C7.9233 23.1879 7.71496 23.1529 7.51496 23.0829C7.3183 23.0129 7.15163 22.9162 7.01496 22.7929C6.9483 22.7395 6.91496 22.6729 6.91496 22.5929C6.91496 22.5329 6.93163 22.4829 6.96496 22.4429C6.9983 22.3995 7.0383 22.3779 7.08496 22.3779C7.13496 22.3779 7.19163 22.3995 7.25496 22.4429C7.3983 22.5495 7.5383 22.6312 7.67496 22.6879C7.81496 22.7412 7.96496 22.7679 8.12496 22.7679C8.5983 22.7679 8.83496 22.5662 8.83496 22.1629C8.83496 21.9662 8.77163 21.8195 8.64496 21.7229C8.52163 21.6229 8.3333 21.5729 8.07996 21.5729H7.80496C7.74163 21.5729 7.69163 21.5529 7.65496 21.5129C7.6183 21.4695 7.59996 21.4195 7.59996 21.3629C7.59996 21.3095 7.6183 21.2629 7.65496 21.2229C7.69163 21.1795 7.74163 21.1579 7.80496 21.1579H7.99996C8.2433 21.1579 8.4283 21.1079 8.55496 21.0079C8.68496 20.9045 8.74996 20.7545 8.74996 20.5579C8.74996 20.3845 8.69663 20.2495 8.58996 20.1529C8.48663 20.0562 8.33996 20.0079 8.14996 20.0079C7.86996 20.0079 7.58996 20.1162 7.30996 20.3329C7.24663 20.3762 7.18996 20.3979 7.13996 20.3979C7.0933 20.3979 7.0533 20.3779 7.01996 20.3379C6.98663 20.2945 6.96996 20.2429 6.96996 20.1829C6.96996 20.1029 7.0033 20.0362 7.06996 19.9829C7.20663 19.8595 7.37163 19.7629 7.56496 19.6929C7.76163 19.6229 7.9633 19.5879 8.16996 19.5879C8.49996 19.5879 8.76163 19.6695 8.95496 19.8329C9.15163 19.9962 9.24996 20.2179 9.24996 20.4979C9.24996 20.6979 9.19996 20.8729 9.09996 21.0229C9.0033 21.1695 8.86496 21.2762 8.68496 21.3429Z"
      fill="#7145D8"
    />
  </svg>
);

const DefaultBlockquote = () => (
  <svg
    className="default"
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.4862 8.44816H12.5822C12.3347 8.44816 12.1342 8.64873 12.1342 8.89616C12.1342 9.14358 12.3347 9.34416 12.5822 9.34416H24.4862C24.7336 9.34416 24.9342 9.14358 24.9342 8.89616C24.9342 8.64873 24.7336 8.44816 24.4862 8.44816Z"
      fill="#6D6E71"
    />
    <path
      d="M24.4862 15.6164H12.5822C12.3347 15.6164 12.1342 15.817 12.1342 16.0644C12.1342 16.3118 12.3347 16.5124 12.5822 16.5124H24.4862C24.7336 16.5124 24.9342 16.3118 24.9342 16.0644C24.9342 15.817 24.7336 15.6164 24.4862 15.6164Z"
      fill="#6D6E71"
    />
    <path
      d="M24.4862 21.8884H12.5822C12.3347 21.8884 12.1342 22.089 12.1342 22.3364C12.1342 22.5838 12.3347 22.7844 12.5822 22.7844H24.4862C24.7336 22.7844 24.9342 22.5838 24.9342 22.3364C24.9342 22.089 24.7336 21.8884 24.4862 21.8884Z"
      fill="#6D6E71"
    />
    <path
      d="M9.15984 10.6259C9.23984 10.6259 9.29984 10.6442 9.33984 10.6809C9.37984 10.7176 9.39984 10.7692 9.39984 10.8359C9.39984 10.9792 9.31984 11.0509 9.15984 11.0509H7.48984C7.32651 11.0509 7.24484 10.9792 7.24484 10.8359C7.24484 10.7692 7.26484 10.7176 7.30484 10.6809C7.34818 10.6442 7.40984 10.6259 7.48984 10.6259H8.06484V8.13091L7.54984 8.45591C7.51651 8.47591 7.48318 8.48591 7.44984 8.48591C7.39318 8.48591 7.34318 8.46091 7.29984 8.41091C7.25984 8.36091 7.23984 8.30424 7.23984 8.24091C7.23984 8.16424 7.27317 8.10424 7.33984 8.06091L8.10984 7.57091C8.18651 7.52424 8.25984 7.50091 8.32984 7.50091C8.40318 7.50091 8.46318 7.52424 8.50984 7.57091C8.55651 7.61758 8.57984 7.68091 8.57984 7.76091V10.6259H9.15984Z"
      fill="#6D6E71"
    />
    <path
      d="M9.19496 16.6769C9.27496 16.6769 9.33496 16.6953 9.37496 16.7319C9.4183 16.7686 9.43996 16.8203 9.43996 16.8869C9.43996 17.0303 9.3583 17.1019 9.19496 17.1019H7.26496C7.19163 17.1019 7.1333 17.0819 7.08996 17.0419C7.04996 16.9986 7.02996 16.9453 7.02996 16.8819C7.02996 16.8053 7.05996 16.7353 7.11996 16.6719L8.29496 15.3869C8.45163 15.2169 8.56496 15.0619 8.63496 14.9219C8.70496 14.7819 8.73996 14.6436 8.73996 14.5069C8.73996 14.3303 8.6883 14.1953 8.58496 14.1019C8.48496 14.0053 8.34163 13.9569 8.15496 13.9569C7.87163 13.9569 7.58996 14.0653 7.30996 14.2819C7.2733 14.3053 7.24163 14.3236 7.21496 14.3369C7.19163 14.3469 7.16663 14.3519 7.13996 14.3519C7.0933 14.3519 7.0533 14.3303 7.01996 14.2869C6.98663 14.2436 6.96996 14.1919 6.96996 14.1319C6.96996 14.0486 7.0033 13.9819 7.06996 13.9319C7.20996 13.8119 7.3783 13.7169 7.57496 13.6469C7.77163 13.5736 7.96996 13.5369 8.16996 13.5369C8.49996 13.5369 8.76163 13.6219 8.95496 13.7919C9.15163 13.9586 9.24996 14.1853 9.24996 14.4719C9.24996 14.6753 9.20496 14.8686 9.11496 15.0519C9.0283 15.2353 8.88163 15.4403 8.67496 15.6669L7.72996 16.6769H9.19496Z"
      fill="#6D6E71"
    />
    <path
      d="M8.68496 21.3429C8.8983 21.4029 9.05996 21.5062 9.16996 21.6529C9.27996 21.7962 9.33496 21.9779 9.33496 22.1979C9.33496 22.5045 9.22663 22.7462 9.00996 22.9229C8.79663 23.0995 8.50663 23.1879 8.13996 23.1879C7.9233 23.1879 7.71496 23.1529 7.51496 23.0829C7.3183 23.0129 7.15163 22.9162 7.01496 22.7929C6.9483 22.7395 6.91496 22.6729 6.91496 22.5929C6.91496 22.5329 6.93163 22.4829 6.96496 22.4429C6.9983 22.3995 7.0383 22.3779 7.08496 22.3779C7.13496 22.3779 7.19163 22.3995 7.25496 22.4429C7.3983 22.5495 7.5383 22.6312 7.67496 22.6879C7.81496 22.7412 7.96496 22.7679 8.12496 22.7679C8.5983 22.7679 8.83496 22.5662 8.83496 22.1629C8.83496 21.9662 8.77163 21.8195 8.64496 21.7229C8.52163 21.6229 8.3333 21.5729 8.07996 21.5729H7.80496C7.74163 21.5729 7.69163 21.5529 7.65496 21.5129C7.6183 21.4695 7.59996 21.4195 7.59996 21.3629C7.59996 21.3095 7.6183 21.2629 7.65496 21.2229C7.69163 21.1795 7.74163 21.1579 7.80496 21.1579H7.99996C8.2433 21.1579 8.4283 21.1079 8.55496 21.0079C8.68496 20.9045 8.74996 20.7545 8.74996 20.5579C8.74996 20.3845 8.69663 20.2495 8.58996 20.1529C8.48663 20.0562 8.33996 20.0079 8.14996 20.0079C7.86996 20.0079 7.58996 20.1162 7.30996 20.3329C7.24663 20.3762 7.18996 20.3979 7.13996 20.3979C7.0933 20.3979 7.0533 20.3779 7.01996 20.3379C6.98663 20.2945 6.96996 20.2429 6.96996 20.1829C6.96996 20.1029 7.0033 20.0362 7.06996 19.9829C7.20663 19.8595 7.37163 19.7629 7.56496 19.6929C7.76163 19.6229 7.9633 19.5879 8.16996 19.5879C8.49996 19.5879 8.76163 19.6695 8.95496 19.8329C9.15163 19.9962 9.24996 20.2179 9.24996 20.4979C9.24996 20.6979 9.19996 20.8729 9.09996 21.0229C9.0033 21.1695 8.86496 21.2762 8.68496 21.3429Z"
      fill="#6D6E71"
    />
  </svg>
);

const ActiveBlockquote = () => (
  <svg
    className="active"
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.4862 8.44815H12.5822C12.3347 8.44815 12.1342 8.64873 12.1342 8.89615C12.1342 9.14357 12.3347 9.34415 12.5822 9.34415H24.4862C24.7336 9.34415 24.9342 9.14357 24.9342 8.89615C24.9342 8.64873 24.7336 8.44815 24.4862 8.44815Z"
      fill="#7145D8"
    />
    <path
      d="M24.4862 15.6164H12.5822C12.3347 15.6164 12.1342 15.817 12.1342 16.0644C12.1342 16.3118 12.3347 16.5124 12.5822 16.5124H24.4862C24.7336 16.5124 24.9342 16.3118 24.9342 16.0644C24.9342 15.817 24.7336 15.6164 24.4862 15.6164Z"
      fill="#7145D8"
    />
    <path
      d="M24.4862 21.8884H12.5822C12.3347 21.8884 12.1342 22.089 12.1342 22.3364C12.1342 22.5838 12.3347 22.7844 12.5822 22.7844H24.4862C24.7336 22.7844 24.9342 22.5838 24.9342 22.3364C24.9342 22.089 24.7336 21.8884 24.4862 21.8884Z"
      fill="#7145D8"
    />
    <path
      d="M9.15984 10.6259C9.23984 10.6259 9.29984 10.6442 9.33984 10.6809C9.37984 10.7176 9.39984 10.7692 9.39984 10.8359C9.39984 10.9792 9.31984 11.0509 9.15984 11.0509H7.48984C7.32651 11.0509 7.24484 10.9792 7.24484 10.8359C7.24484 10.7692 7.26484 10.7176 7.30484 10.6809C7.34818 10.6442 7.40984 10.6259 7.48984 10.6259H8.06484V8.1309L7.54984 8.4559C7.51651 8.4759 7.48318 8.4859 7.44984 8.4859C7.39318 8.4859 7.34318 8.4609 7.29984 8.4109C7.25984 8.3609 7.23984 8.30424 7.23984 8.2409C7.23984 8.16424 7.27317 8.10424 7.33984 8.0609L8.10984 7.5709C8.18651 7.52424 8.25984 7.5009 8.32984 7.5009C8.40318 7.5009 8.46318 7.52424 8.50984 7.5709C8.55651 7.61757 8.57984 7.6809 8.57984 7.7609V10.6259H9.15984Z"
      fill="#7145D8"
    />
    <path
      d="M9.19496 16.677C9.27496 16.677 9.33496 16.6953 9.37496 16.732C9.4183 16.7686 9.43996 16.8203 9.43996 16.887C9.43996 17.0303 9.3583 17.102 9.19496 17.102H7.26496C7.19163 17.102 7.1333 17.082 7.08996 17.042C7.04996 16.9986 7.02996 16.9453 7.02996 16.882C7.02996 16.8053 7.05996 16.7353 7.11996 16.672L8.29496 15.387C8.45163 15.217 8.56496 15.062 8.63496 14.922C8.70496 14.782 8.73996 14.6436 8.73996 14.507C8.73996 14.3303 8.6883 14.1953 8.58496 14.102C8.48496 14.0053 8.34163 13.957 8.15496 13.957C7.87163 13.957 7.58996 14.0653 7.30996 14.282C7.2733 14.3053 7.24163 14.3236 7.21496 14.337C7.19163 14.347 7.16663 14.352 7.13996 14.352C7.0933 14.352 7.0533 14.3303 7.01996 14.287C6.98663 14.2436 6.96996 14.192 6.96996 14.132C6.96996 14.0486 7.0033 13.982 7.06996 13.932C7.20996 13.812 7.3783 13.717 7.57496 13.647C7.77163 13.5736 7.96996 13.537 8.16996 13.537C8.49996 13.537 8.76163 13.622 8.95496 13.792C9.15163 13.9586 9.24996 14.1853 9.24996 14.472C9.24996 14.6753 9.20496 14.8686 9.11496 15.052C9.0283 15.2353 8.88163 15.4403 8.67496 15.667L7.72996 16.677H9.19496Z"
      fill="#7145D8"
    />
    <path
      d="M8.68496 21.3429C8.8983 21.4029 9.05996 21.5062 9.16996 21.6529C9.27996 21.7962 9.33496 21.9779 9.33496 22.1979C9.33496 22.5045 9.22663 22.7462 9.00996 22.9229C8.79663 23.0995 8.50663 23.1879 8.13996 23.1879C7.9233 23.1879 7.71496 23.1529 7.51496 23.0829C7.3183 23.0129 7.15163 22.9162 7.01496 22.7929C6.9483 22.7395 6.91496 22.6729 6.91496 22.5929C6.91496 22.5329 6.93163 22.4829 6.96496 22.4429C6.9983 22.3995 7.0383 22.3779 7.08496 22.3779C7.13496 22.3779 7.19163 22.3995 7.25496 22.4429C7.3983 22.5495 7.5383 22.6312 7.67496 22.6879C7.81496 22.7412 7.96496 22.7679 8.12496 22.7679C8.5983 22.7679 8.83496 22.5662 8.83496 22.1629C8.83496 21.9662 8.77163 21.8195 8.64496 21.7229C8.52163 21.6229 8.3333 21.5729 8.07996 21.5729H7.80496C7.74163 21.5729 7.69163 21.5529 7.65496 21.5129C7.6183 21.4695 7.59996 21.4195 7.59996 21.3629C7.59996 21.3095 7.6183 21.2629 7.65496 21.2229C7.69163 21.1795 7.74163 21.1579 7.80496 21.1579H7.99996C8.2433 21.1579 8.4283 21.1079 8.55496 21.0079C8.68496 20.9045 8.74996 20.7545 8.74996 20.5579C8.74996 20.3845 8.69663 20.2495 8.58996 20.1529C8.48663 20.0562 8.33996 20.0079 8.14996 20.0079C7.86996 20.0079 7.58996 20.1162 7.30996 20.3329C7.24663 20.3762 7.18996 20.3979 7.13996 20.3979C7.0933 20.3979 7.0533 20.3779 7.01996 20.3379C6.98663 20.2945 6.96996 20.2429 6.96996 20.1829C6.96996 20.1029 7.0033 20.0362 7.06996 19.9829C7.20663 19.8595 7.37163 19.7629 7.56496 19.6929C7.76163 19.6229 7.9633 19.5879 8.16996 19.5879C8.49996 19.5879 8.76163 19.6695 8.95496 19.8329C9.15163 19.9962 9.24996 20.2179 9.24996 20.4979C9.24996 20.6979 9.19996 20.8729 9.09996 21.0229C9.0033 21.1695 8.86496 21.2762 8.68496 21.3429Z"
      fill="#7145D8"
    />
  </svg>
);

const Blockquote = ({ active }) => {
  const Icon = active ? ActiveBlockquote : DefaultBlockquote;

  return (
    <div className="editor-icon-svg">
      <Icon />
      <BlockquoteOnHover />
    </div>
  );
};

export default Blockquote;
