import React from 'react';

import './index.scss';

const Image = ({ src }) => (
  <div className="sk-editor-contents-block-image">
    {src && <img src={src} />}
  </div>
);

export default Image;
