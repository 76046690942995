// Core
import React, { memo } from 'react';
import PropTypes from 'prop-types';

// Components
import Text from 'components/Text';
import ChatBubble from 'components/Icon/Svg/ChatBubble';

// Styles and Assets
import './WebinarRoomChatHeader.scss';

// Other
import { FormattedMessage } from 'react-intl';
import { messages } from 'containers/WebinarRoom/messages';

const WebinarRoomChatHeader = ({ numberOfConnections }) => (
  <div className="sk-webroom-chat-header">
    <div className="sk-webroom-chat-header__title">
      <div className="sk-webroom-chat-header__chat-icon">
        <ChatBubble />
      </div>
      <Text bold size="small" classes="sk-webroom-chat-header__chat-text">
        <FormattedMessage {...messages.chat} />
      </Text>
    </div>
    <div className="sk-webroom-chat-header__members">
      <Text size="caption" classes="sk-webroom-chat-header__members-text">
        {numberOfConnections} <FormattedMessage {...messages.member} />
      </Text>
    </div>
  </div>
);

WebinarRoomChatHeader.defaultProps = {
  numberOfConnections: 0,
};

WebinarRoomChatHeader.propTypes = {
  numberOfConnections: PropTypes.number,
};

export default memo(WebinarRoomChatHeader);
