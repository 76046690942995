import React from 'react';

const LectureIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="1.5" width="17" height="14.2308" rx="1.5" stroke="#A9B6C8"/>
    <path d="M7.75177 5.24508L12.0964 8.09626C12.2534 8.23203 12.2534 8.43568 12.0964 8.57145L7.9088 11.2869C7.67325 11.4905 7.25449 11.3547 7.22832 11.0606L7.07129 5.494C7.07129 5.19983 7.49005 5.04143 7.75177 5.24508Z" stroke="#A9B6C8" strokeMiterlimit="10"/>
  </svg>
);

export default LectureIcon;
