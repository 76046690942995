// Core
import React, { memo, useCallback } from 'react';
import { compose, graphql } from 'react-apollo';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';

// Components
import Button from 'components/Button';
import Card from 'components/Card';
import Text from 'components/Text';
import ContentHeader from 'containers/ContentHeader';
import Loader from 'components/Loader';

// GraphQl
import GET_DELETED_COURSE from 'queries/AdminPanel/getDeletedCourse.gql';
import DELETE_COURSE from 'mutations/AdminPanel/deleteCourse.gql';

// Other
import { parseFetchedData, keysToCamel } from 'utils/helpers';
import { capitalize, isBlank } from 'utils/helpers/index';
import { sharedMessages } from 'services/i18n/sharedMessages/messages';
import { messages } from 'routes/AdminPanel/messages';

// Styles and Assets
import './DeletedCourseView.scss';

const DeletedCourseViewDetail = ({ label, content }) => (
  <div className="sk-admin-deleted-course-detail">
    <Text>{label}</Text>
    <Text>{content}</Text>
  </div>
);

const DeletedCourseView = ({
  loading,
  error,
  title,
  author,
  subcategory,
  category,
  reasons,
  deleteCourse,
  deletedCourse,
  intl: { formatMessage },
  history
}) => {
  if (loading) return <Loader center />;

  const firstName = author?.firstName || 'Unavailable';
  const lastName = author?.lastName || 'Unavailable';

  const deleteButtonHandler = useCallback(async () => {
    await deleteCourse(deletedCourse.id);
     history.push('/admin/panel/deleted-courses');
  }, [deleteCourse, deletedCourse.id, history.push]);

  if(deletedCourse){
    const expert = `${firstName} ${lastName}`;
    const subcategoryContent = (subcategory && subcategory !== null)
      ? formatMessage(sharedMessages[subcategory.title])
      : '';
    const categoryContent = (category && category !== null)
      ? formatMessage(sharedMessages[category.title])
      : '';
    const hasReasons = Array.isArray(reasons) && ( reasons.length > 0 );

    return (
      <div className="sk-admin-deleted-course-view">
        <div className="sk-admin-deleted-course-view__content-header">
          <ContentHeader />
        </div>
        <Card>
          <div className="sk-admin-deleted-course-view__content">
            <div className="sk-admin-deleted-course-view__content__details">
              <DeletedCourseViewDetail label="Course name" content={title} />
              <DeletedCourseViewDetail label="Expert name" content={expert} />
              <DeletedCourseViewDetail
                label="Subcateogory"
                content={subcategoryContent}
              />
              <DeletedCourseViewDetail
                label="Category"
                content={categoryContent}
              />
            </div>

            <ul className="sk-admin-deleted-course-view__content__reasons">
              <Text>{formatMessage(messages.reasonsListTitle)}</Text>
              {hasReasons && reasons.map((reason, idx) => {
                const translation = reason.option.translations.find(translation => translation.locale === 'en');
                return (
                  <li
                    key={`reason-idx`}
                    className="sk-admin-deleted-course-view__content__reasons__reason"
                  >
                    <Text>{translation.title}</Text>
                    {reason.message && (
                      <Text>{reason.message}</Text>
                    )}
                  </li>
                )
              })}
            </ul>

            <Button
              color="secondary"
              variant="text"
              className="sk-admin-deleted-course-view__content__button"
              onClick={deleteButtonHandler}
            >
              {formatMessage(messages.deleteCourse)}
            </Button>
          </div>
        </Card>
      </div>
    );
  }
};

const getDeletedCourse = graphql(GET_DELETED_COURSE, {
  props: ({ data: { adminGetDeletedCourse, error, loading, ...ownProps } }) => {
    if (loading) return { loading, deletedCourse: {}, author: {}};
    if (error) throw new Error('Something went wrong while receiving adminGetUser');

    const { title, author, subcategory, category, reasons } = adminGetDeletedCourse;

    return {
      loading,
      error,
      title,
      author: keysToCamel(author),
      subcategory,
      category,
      reasons,
      deletedCourse: adminGetDeletedCourse,
      ...ownProps,
    };
  },
  options: ({
    match: {
      params: { id },
    },
  }) => ({
    fetchPolicy: 'cache-and-network',
    variables: { id },
  }),
});

const deleteCourse = graphql(DELETE_COURSE, {
  props: ({ mutate }) => ({
    deleteCourse: id => mutate({ variables: { id } }),
  }),
});

const enhancer = compose(
  memo,
  injectIntl,
  withRouter,
  getDeletedCourse,
  deleteCourse
);

export default enhancer(DeletedCourseView);
