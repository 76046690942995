// Core
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

// Components
import SimpleSelect from 'components/Select/SimpleSelect';
import FilterDropDown from 'components/FilterDropDown';

// Other
import { VARIANTS_NAMES, DEFAULT_VARIANTS } from 'routes/ExpertsCabinet/CourseEdit/enums';
import { capitalize } from 'utils/helpers';
import { messages } from 'routes/Course/components/ComplexitySelect/messages';

class InteractivityLevelSelect extends PureComponent {
  render() {
    const { error, type='simple', ...props } = this.props;

    const options = VARIANTS_NAMES.map(level => ({
      id: level,
      label: <FormattedMessage {...messages[level]} />,
    }));

    return (
      <>
        {type === 'simple' ? (
          <SimpleSelect
            inlineStyles={{ selector: { minHeight: 40 } }}
            options={options}
            error={error}
            valueKey="id"
            labelKey="label"
            {...props}
          />
        ) : (
          <FormattedMessage {...messages.interactivityLevel}>
            {msg => (
              <FilterDropDown
                minor
                options={options}
                placeholder={msg}
                valueKey="id"
                labelKey="label"
                isPlaceholderOptionHidden={true}
                {...props}
              />
            )}
          </FormattedMessage>
        )}
      </>
    );
  }
}

export default InteractivityLevelSelect;
