import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  becomeStudent: {
    id: 'app.components.LandingPageCTAText.becomeStudent',
    defaultMessage: 'Become a student',
  },
  teachOnline: {
    id: 'app.components.LandingPageCTAText.teachOnline',
    defaultMessage: 'Teach online',
  },
  theWorldIs: {
    id: 'app.components.LandingPageCTAText.theWorldIs',
    defaultMessage: 'Keep your skills up to date with online courses from leading industry experts',
  },
  skillsForFuture: {
    id: 'app.components.LandingPageCTAText.skillsForFuture',
    defaultMessage: 'A smarter way to learn online',
  },
  buttonText: {
    id: 'app.components.LandingPageCTAText.buttonText',
    defaultMessage: 'Find a course',
  },
  placeholderText: {
    id: 'app.components.LandingPageCTAText.placeholderText',
    defaultMessage: 'What would you like to learn?',
  }
});
