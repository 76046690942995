import React from 'react';
import Text from 'components/Text/Text';

const Price = ({value}) => {
  if (typeof value === 'string') {
    return (
      <Text className="price-value" size="basic`">
        <sup>{!value.slice(2).includes('-') && value[0]}</sup>
        {value.slice(2)}
      </Text>
    );
  }
  return value;
};

export default Price 