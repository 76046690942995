import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  homeworks: {
    id: 'app.components.StudentHomework.homeworks',
    defaultMessage: 'Homeworks',
  },
  homeworksInReview: {
    id: 'app.components.StudentHomework.homeworksInReview',
    defaultMessage: 'Homeworks in review',
  },
  downloadHomeworkToPerform: {
    id: 'app.components.StudentHomework.downloadHomeworkToPerform',
    defaultMessage: 'Download homework to perform',
  },
  download: {
    id: 'app.components.StudentHomework.download',
    defaultMessage: 'Open',
  },
  submit: {
    id: 'app.components.StudentHomework.submit',
    defaultMessage: 'Submit',
  },
  upload: {
    id: 'app.components.StudentHomework.upload',
    defaultMessage: 'Upload',
  },
  yourMarkIs: {
    id: 'app.components.StudentHomework.yourMarkIs',
    defaultMessage: 'Your Mark is',
  },
  yourHomeworkReviewed: {
    id: 'app.components.StudentHomework.yourHomeworkReviewed',
    defaultMessage: 'You Homework was reviewed',
  },
  uploadHwForReview: {
    id: 'app.components.StudentHomework.uploadHwForReview',
    defaultMessage: 'Upload homework for review',
  },
  disabledHomeworkExplanation1: {
    id: 'app.components.StudentHomework.disabledHomeworkExplanation1',
    defaultMessage: 'Homework will be available after the previous one is submitted',
  },
  disabledHomeworkExplanation2: {
    id: 'app.components.StudentHomework.disabledHomeworkExplanation2',
    defaultMessage: 'Homework will be available after the previous one is evaluated for more than 60%',
  },
});
