// Core
import React, { useEffect, createContext, useContext } from "react";
import { withRouter, Redirect, Route } from "react-router-dom";
import Auth from "@aws-amplify/auth";
import { ADMIN } from "utils/enums";
import Layout from "containers/Layout";

const PrivateRouteContext = createContext({});

const RoutePrivate = (props) => {
  const { history, route } = props;
  const { role, ...rest } = props;
  const [state, setState] = React.useState({
    loaded: false,
    isAuthenticated: false,
  });

  useEffect(() => {
    (async () => {
      try {
        await Auth.currentAuthenticatedUser();
        setState((prevState) => ({
          ...prevState,
          loaded: true,
          isAuthenticated: true,
        }));
      } catch (error) {
        history.push("/auth");
      }
    })();

    return () => {
      history.listen(async () => {
        try {
          await Auth.currentAuthenticatedUser();
        } catch (error) {
          setState((s) => ({ ...s, isAuthenticated: false }));
        }
      });
    };
  }, []);

  const { loaded, isAuthenticated } = state;

  return (
    <PrivateRouteContext.Provider
      value={{
        role,
        isAuthenticated,
        route,
        adminRedirect: role === ADMIN && !rest.roles.includes(ADMIN),
        loaded,
      }}
    >
      <Route {...rest} component={func} />
    </PrivateRouteContext.Provider>
  );
};

const func = (props) => {
  const { isAuthenticated, role, loaded, adminRedirect, route } = useContext(
    PrivateRouteContext
  );
  const { path, exact, roles: allowedRoles } = route;

  if (!loaded) return null;

  if (adminRedirect) {
    return (
      <Redirect
        to={{
          pathname: "/admin/panel",
        }}
      />
    );
  }
  if (!isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: "/auth",
        }}
      />
    );
  }
  if (!allowedRoles.includes(role)) {
    return <Redirect to="/" />;
  }

  return <Layout {...props} role={role} {...route} />;
};

export default withRouter(RoutePrivate);
