// Core
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { DatePicker as MuiDatePicker } from 'material-ui-pickers';

// Components
import { MaterializedTextField } from 'components/Input/Material/MaterialTextField';

// Other
import { dateSlashedMask } from 'utils/enums';

export const DatePicker = ({
  date,
  label,
  name,
  format,
  autoOk,
  handlePickerChange,
  ...rest
}) => (
  <MuiDatePicker
    label={label}
    name={name}
    customType="picker"
    format={format}
    value={date}
    keyboard
    autoOk={autoOk}
    mask={value =>
      // handle clearing outside if value can be changed outside of the component
      value ? dateSlashedMask : []
    }
    inputIsControlled={false}
    onChange={handlePickerChange(name)}
    TextFieldComponent={({ autoOk: autoOkProp, ...restProps }) => (
      <MaterializedTextField {...restProps} picker {...rest} />
    )}
  />
);

DatePicker.defaultProps = {
  format: 'DD/MM/YYYY',
  autoOk: true,
};

DatePicker.propTypes = {
  date: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  format: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  handlePickerChange: PropTypes.func,
  autoOk: PropTypes.bool,
};

export default memo(DatePicker);
