// Core
import React, { memo } from 'react';
import PropTypes from 'prop-types';

// Components
import TextInput from 'components/Input/Material/TextInput';
import SendMessage from 'components/Icon/Svg/SendMessage';

// Other
import { FormattedMessage } from 'react-intl';
import { messages } from 'containers/WebinarRoom/messages';

// Styles and Assets
import './WebinarRoomChatInput.scss';

const WebinarRoomChatInput = ({
  handleMessageSend,
  handleInputChange,
  handleKeyPress,
  disabled,
  message,
}) => (
  <div className="sk-webroom-chat-input">
    <div className="sk-webroom-chat-input__field">
      <FormattedMessage {...messages.typeSmth}>
        {msg => (
          <TextInput
            placeholder={msg}
            borderLess
            lightText
            disabled={disabled}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
            value={message}
            inputIsControlled={false}
          />
        )}
      </FormattedMessage>
    </div>
    <button
      type="button"
      className="sk-webroom-chat-input__button"
      onClick={handleMessageSend}
    >
      <SendMessage />
    </button>
  </div>
);

WebinarRoomChatInput.propTypes = {
  handleMessageSend: PropTypes.func,
  handleInputChange: PropTypes.func,
  handleKeyPress: PropTypes.func,
  message: PropTypes.string,
};

export default memo(WebinarRoomChatInput);
