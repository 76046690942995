import React from 'react';

import './index.scss';

const BlockquoteOnHover = () => (
  <svg
    className="hover"
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.692383" width="32" height="32" rx="2" fill="white" />
    <path
      d="M11.5955 20.7166C11.4393 20.8728 11.186 20.8728 11.0298 20.7166L6.55549 16.2423C6.39928 16.0861 6.39928 15.8328 6.55549 15.6766L11.0298 11.2023C11.186 11.0461 11.4393 11.0461 11.5955 11.2023L11.8698 11.4766C12.026 11.6328 12.026 11.8861 11.8698 12.0423L8.23549 15.6766C8.07928 15.8328 8.07928 16.0861 8.23549 16.2423L11.8698 19.8766C12.026 20.0328 12.026 20.2861 11.8698 20.4423L11.5955 20.7166Z"
      fill="#7145D8"
    />
    <path
      d="M22.9365 20.7161C22.7803 20.8723 22.527 20.8723 22.3708 20.7161L22.0965 20.4418C21.9403 20.2856 21.9403 20.0323 22.0965 19.8761L25.7308 16.2418C25.887 16.0856 25.887 15.8323 25.7308 15.6761L22.0965 12.0418C21.9403 11.8856 21.9403 11.6324 22.0965 11.4761L22.3708 11.2018C22.527 11.0456 22.7803 11.0456 22.9365 11.2018L27.4108 15.6761C27.567 15.8323 27.567 16.0856 27.4108 16.2418L22.9365 20.7161Z"
      fill="#7145D8"
    />
    <path
      d="M14.0214 24.4804C13.9163 24.7262 13.6364 24.8459 13.3861 24.752L13.2019 24.683C12.9356 24.5831 12.8061 24.2815 12.9172 24.0196L19.9258 7.48995C20.0384 7.22438 20.3517 7.10892 20.6097 7.23792L20.8323 7.34922C21.0689 7.46754 21.1724 7.74969 21.0685 7.99295L14.0214 24.4804Z"
      fill="#7145D8"
    />
  </svg>
);

const DefaultBlockquote = () => (
  <svg
    className="default"
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5955 20.7166C10.4393 20.8728 10.186 20.8728 10.0298 20.7166L5.55549 16.2423C5.39928 16.0861 5.39928 15.8328 5.55549 15.6766L10.0298 11.2023C10.186 11.0461 10.4393 11.0461 10.5955 11.2023L10.8698 11.4766C11.026 11.6328 11.026 11.8861 10.8698 12.0423L7.23549 15.6766C7.07928 15.8328 7.07928 16.0861 7.23549 16.2423L10.8698 19.8766C11.026 20.0328 11.026 20.2861 10.8698 20.4423L10.5955 20.7166Z"
      fill="#6D6E71"
    />
    <path
      d="M21.9365 20.7161C21.7803 20.8723 21.527 20.8723 21.3708 20.7161L21.0965 20.4418C20.9403 20.2856 20.9403 20.0324 21.0965 19.8761L24.7308 16.2418C24.887 16.0856 24.887 15.8324 24.7308 15.6761L21.0965 12.0418C20.9403 11.8856 20.9403 11.6324 21.0965 11.4761L21.3708 11.2018C21.527 11.0456 21.7803 11.0456 21.9365 11.2018L26.4108 15.6761C26.567 15.8324 26.567 16.0856 26.4108 16.2418L21.9365 20.7161Z"
      fill="#6D6E71"
    />
    <path
      d="M13.0214 24.4804C12.9163 24.7262 12.6364 24.8459 12.3861 24.752L12.2019 24.683C11.9356 24.5831 11.8061 24.2815 11.9172 24.0196L18.9258 7.48995C19.0384 7.22438 19.3517 7.10892 19.6097 7.23792L19.8323 7.34922C20.0689 7.46754 20.1724 7.74969 20.0685 7.99295L13.0214 24.4804Z"
      fill="#6D6E71"
    />
  </svg>
);

const ActiveBlockquote = () => (
  <svg
    className="active"
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5955 20.7166C10.4393 20.8728 10.186 20.8728 10.0298 20.7166L5.55549 16.2423C5.39928 16.0861 5.39928 15.8328 5.55549 15.6766L10.0298 11.2023C10.186 11.0461 10.4393 11.0461 10.5955 11.2023L10.8698 11.4766C11.026 11.6328 11.026 11.8861 10.8698 12.0423L7.23549 15.6766C7.07928 15.8328 7.07928 16.0861 7.23549 16.2423L10.8698 19.8766C11.026 20.0328 11.026 20.2861 10.8698 20.4423L10.5955 20.7166Z"
      fill="#7145D8"
    />
    <path
      d="M21.9365 20.7161C21.7803 20.8723 21.527 20.8723 21.3708 20.7161L21.0965 20.4418C20.9403 20.2856 20.9403 20.0323 21.0965 19.8761L24.7308 16.2418C24.887 16.0856 24.887 15.8323 24.7308 15.6761L21.0965 12.0418C20.9403 11.8856 20.9403 11.6324 21.0965 11.4761L21.3708 11.2018C21.527 11.0456 21.7803 11.0456 21.9365 11.2018L26.4108 15.6761C26.567 15.8323 26.567 16.0856 26.4108 16.2418L21.9365 20.7161Z"
      fill="#7145D8"
    />
    <path
      d="M13.0214 24.4804C12.9163 24.7262 12.6364 24.8459 12.3861 24.752L12.2019 24.683C11.9356 24.5831 11.8061 24.2815 11.9172 24.0196L18.9258 7.48995C19.0384 7.22438 19.3517 7.10892 19.6097 7.23792L19.8323 7.34922C20.0689 7.46754 20.1724 7.74969 20.0685 7.99295L13.0214 24.4804Z"
      fill="#7145D8"
    />
  </svg>
);

const Blockquote = ({ active }) => {
  const Icon = active ? ActiveBlockquote : DefaultBlockquote;

  return (
    <div className="editor-icon-svg">
      <Icon />
      <BlockquoteOnHover />
    </div>
  );
};

export default Blockquote;
