// Core
import React, { PureComponent } from 'react';
import classnames from 'classnames';

// Components
import ExpansionPanel from 'components/ExpansionPanel';
import CurriculumDetails from 'routes/CourseDetails/Curriculum/CurriculumDetails';
import CurriculumSummary from 'routes/CourseDetails/Curriculum/CurriculumSummary';
import ChevronDown from 'components/Icon/Svg/ChevronDown';
import AboutLecture from 'routes/CourseDetails/Curriculum/AboutLecture';

// Other
import { White, PurpleDark, } from 'components/Icon/color';
import { FormattedMessage } from 'react-intl';
import { messages } from 'routes/CourseDetails/Curriculum/messages';
import { compareSortNumber, isNotEmptyArray } from 'utils/helpers/index';

// Styles and Assets
import './Curriculum.scss';

const overrideClasses = {
  panel: {
    root: 'mui-override sk-lc-сurriculum-panel__panel',
  },
  summary: {
    root: 'mui-override sk-lc-сurriculum-panel__summary',
    content: 'mui-override sk-lc-сurriculum-panel__summary-content',
  },
  details: {
    root: 'mui-override sk-lc-сurriculum-panel__details',
  },
  iconBtn: {
    root: 'mui-override sk-lc-panel__btn',
  },
};
const hasPublicClassName = (materials) => {
  if (materials && materials !== null && materials.length > 0) {
    if (materials.some(({isPublic}) => isPublic === true)) {
      return 'has-public';
    }
  }
  return false;
}

// Poker face
const getSummaryClassName = (className) => {
  const summaryClassName = overrideClasses.summary;
  if (className) {
    return {
      ...summaryClassName,
      root: className ? `${summaryClassName.root} ${className}` : summaryClassName.root
    }
  }
  return summaryClassName;
}

const Curriculum = ({ lectures, displayLectureNameType }) => {
  return (
    <div className="mui-override sk-сurriculum">
      <h3 className="mui-override sk-сurriculum-title">
        <FormattedMessage {...messages.curriculumForThisCourse} />
      </h3>
      <div className="mui-override sk-сurriculum__lecture">
        {isNotEmptyArray(lectures) &&
          lectures.map((item, index) => (
            <ExpansionPanel
              key={item.id}
              panelSummaryClasses={
                getSummaryClassName(hasPublicClassName(item.materials))
              }
              panelDetailsClasses={overrideClasses.details}
              expandIcon={
                <div className={classnames("mui-override sk-сurriculum__lecture-icon", {
                  'active-icon': false,
                })}>
                  <ChevronDown color={true ? PurpleDark : White} />
                </div>
              }
              ExpansionPanelSummaryComponent={
                <CurriculumSummary
                  lecture={item}
                  orderNumber={index}
                  red
                  displayLectureNameType={displayLectureNameType}
                />
              }
              ExpansionDetailsComponent={
                <div className="mui-override sk-сurriculum__lecture__articles">
                  {item?.description && item?.description.length > 0 && (
                    <div className="mui-override second-overwrite sk-сurriculum-lecture-description">
                      <AboutLecture description={item?.description} />
                    </div>
                  )}
                  {item?.materials && item.materials.length > 0 && (
                    <CurriculumDetails materials={item.materials} />
                  )}
                </div>
              }
            />
          ))}
      </div>
    </div>
  );
}

export default Curriculum;
