export const LECTURE_ARTICLE = 'lecture-article';
export const LECTURE_VIDEO = 'lecture-video';
export const LECTURE_AUDIO = 'lecture-audio';
export const LECTURE_QUIZ = 'lecture-quiz';
export const COURSE_PREVIEW = 'course-preview';
export const LECTURE_HOMEWORK_TASK = 'homework-task';
export const LECTURE_HOMEWORK = 'homework';
export const LECTURE_HOMEWORK_REVIEW = 'homework-review';
export const USER_PROFILE_PHOTO = 'user-profile-image';
export const QUIZ_QUESTION_IMAGE = 'quiz-question';
export const QUIZ_ANSWER_IMAGE = 'quiz-answer';
export const TOPPING_PREVIEW_IMAGE = 'topping-preview-image';

export const PENDING = 'PENDING';
export const PROCESSING = 'PROCESSING';
export const UPLOADED = 'UPLOADED';
export const ERROR = 'ERROR';

export const theme = {
  success: {
    symbol: '100%',
    color: 'rgb(223, 105, 180)',
  },
  active: {
    color: '#663ACC',
  },
  default: {
    symbol: '0%',
    color: '#663ACC',
  },
};

export const stall = async (stallTime = 1000) => {
  await new Promise(resolve => setTimeout(resolve, stallTime));
};
