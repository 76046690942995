// Core
import React, { memo } from "react";
import { withRouter } from "react-router-dom";

// Components
import TextInput from "components/Input/Material/TextInput/TextInput";
import PasswordInput from "components/Input/Material/PasswordInput";
import Button from "components/Button";
import CountrySelect from "routes/Authenticator/CountrySelect";
import CitySelect from "routes/Authenticator/CitySelect";
import AutoComplete from "components/Select/AutoComplete";
import Checkbox from "components/Checkbox";
import Text from "components/Text";
import Link from "components/Link";
import CheckboxLabel from "components/CheckboxLabel";
import SocialLoginForm from "routes/Authenticator/SocialLoginForm";

import { FormattedMessage } from "react-intl";
import { messages } from "routes/Authenticator/SignUpForm/messages";

// Other
import { withModal } from "containers/ModalProvider/withModal";

const COMPANY_SIZE_OPTIONS = [
  { key: "1-10", name: "From 1 to 10" },
  { key: "10-20", name: "From 10 to 20" },
  { key: "20-50", name: "From 20 to 50" },
  { key: "50-100", name: "From 50 to 100" },
  { key: "100+", name: "More than 100 members" },
];
const SUBSCRIPTION_PLANS = [
  { key: "standard", name: "Standard Plan" },
  { key: "medium", name: "Medium Size" },
  { key: "enterprise", name: "Enterprise" },
];


 const CompanySignUpForm = memo((props) => {
  const {
    values: {
      terms,
      country,
      city,
      email,
      password,
      confirmPassword,
      firstName,
      lastName,
      companyName,
      companyPhone,
      companySize,
      companySubscription,
    },
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    handleBlur,
    isLoading,
  } = props;

  const handleInputChange = (name) => (inputValue, e) => {
    setFieldValue(name, inputValue);
    if (e) e.persist();
  };

  const handleInputBlur = (name) => (inputValue, e) => {
    if (e) {
      e.persist();
      handleBlur(e);
    }
    setFieldValue(name, inputValue);
  };
  const handleEnterPress = (params1, params2) => {
    const charCode = params1?.charCode || params2?.charCode;
    if (charCode == 13) handleSubmit(props.values); // eslint-disable-line
  };

  return (
    <form
      className="sk-auth-content_form"
      noValidate
      autoComplete="nope"
      onSubmit={handleSubmit}
    >
      <div className="sk-form__inner sk-auth-content_inner">
        <div className="sk-form__row">
          <FormattedMessage {...messages.firstName}>
            {(msg) => (
              <TextInput
                name="firstName"
                helperText={touched.firstName ? errors.firstName : ""}
                error={touched.firstName && Boolean(errors.firstName)}
                value={firstName}
                label={msg}
                placeholder={msg}
                onBlur={handleInputBlur("firstName")}
                onChange={handleInputChange("firstName")}
                onKeyPress={handleEnterPress}
              />
            )}
          </FormattedMessage>
          <FormattedMessage {...messages.lastName}>
            {(msg) => (
              <TextInput
                name="lastName"
                helperText={touched.lastName ? errors.lastName : ""}
                error={touched.lastName && Boolean(errors.lastName)}
                value={lastName}
                label={msg}
                placeholder={msg}
                onBlur={handleInputBlur("lastName")}
                onChange={handleInputChange("lastName")}
                onKeyPress={handleEnterPress}
              />
            )}
          </FormattedMessage>
        </div>
        <div className="sk-form__row">
          <FormattedMessage {...messages.email}>
            {(msg) => (
              <TextInput
                name="email"
                helperText={touched.email ? errors.email : ""}
                error={touched.email && Boolean(errors.email)}
                value={email}
                label={msg}
                placeholder={msg}
                onBlur={handleInputBlur("email")}
                onChange={handleInputChange("email")}
                onKeyPress={handleEnterPress}
              />
            )}
          </FormattedMessage>
        </div>
        <div className="sk-form__row">
          <FormattedMessage {...messages.companyName}>
            {(msg) => (
              <TextInput
                name="companyName"
                helperText={touched.companyName ? errors.companyName : ""}
                error={touched.companyName && Boolean(errors.companyName)}
                value={companyName}
                label={msg}
                placeholder={msg}
                onBlur={handleInputBlur("companyName")}
                onChange={handleInputChange("companyName")}
                onKeyPress={handleEnterPress}
              />
            )}
          </FormattedMessage>
        </div>
        <div className="sk-form__row">
          <FormattedMessage {...messages.companyPhone}>
            {(msg) => (
              <TextInput
                name="companyPhone"
                helperText={touched.companyPhone ? errors.companyPhone : ""}
                error={touched.companyPhone && Boolean(errors.companyPhone)}
                value={companyPhone}
                label={msg}
                placeholder={msg}
                onBlur={handleInputBlur("companyPhone")}
                onChange={handleInputChange("companyPhone")}
                onKeyPress={handleEnterPress}
              />
            )}
          </FormattedMessage>
        </div>
        <div className="sk-form__row">
          <FormattedMessage {...messages.companySize}>
            {(msg) => (
              <AutoComplete
                options={COMPANY_SIZE_OPTIONS}
                label={msg}
                labelKey="name"
                valueKey="key"
                value={companySize}
                placeholder={msg}
                handleBlur={handleInputBlur("companySize")}
                handleChange={handleInputChange("companySize")}
                handleSearch={handleInputChange("companySize")}
                onKeyPress={handleEnterPress}
              />
            )}
          </FormattedMessage>
        </div>
        <div className="sk-form__row">
          <FormattedMessage {...messages.companySubscription}>
            {(msg) => (
              <AutoComplete
                options={SUBSCRIPTION_PLANS}
                label={msg}
                labelKey="name"
                valueKey="key"
                placeholder={msg}
                value={companySubscription}
                handleBlur={handleInputBlur("companySubscription")}
                handleChange={handleInputChange("companySubscription")}
                handleSearch={handleInputChange("companySubscription")}
                onKeyPress={handleEnterPress}
              />
            )}
          </FormattedMessage>
        </div>
        <div className="sk-form__row">
          <FormattedMessage {...messages.password}>
            {(msg) => (
              <PasswordInput
                name="password"
                helperText={touched.password ? errors.password : ""}
                error={touched.password && Boolean(errors.password)}
                value={password}
                label={msg}
                placeholder={msg}
                onBlur={handleInputBlur("password")}
                onChange={handleInputChange("password")}
                onKeyPress={handleEnterPress}
              />
            )}
          </FormattedMessage>
          <FormattedMessage {...messages.confirmPassword}>
            {(msg) => (
              <PasswordInput
                name="confirmPassword"
                helperText={
                  touched.confirmPassword ? errors.confirmPassword : ""
                }
                error={
                  touched.confirmPassword && Boolean(errors.confirmPassword)
                }
                value={confirmPassword}
                label={msg}
                placeholder={msg}
                onBlur={handleInputBlur("confirmPassword")}
                onChange={handleInputChange("confirmPassword")}
                onKeyPress={handleEnterPress}
              />
            )}
          </FormattedMessage>
        </div>
        <div className="sk-form__row">
          <FormattedMessage {...messages.country}>
            {(msg) => (
              <CountrySelect
                helperText={touched.country ? errors.country : ""}
                error={touched.country && Boolean(errors.country)}
                name="country"
                label={msg}
                value={country}
                handleBlur={handleInputBlur("country")}
                handleChange={handleInputChange("country")}
                handleSearch={handleInputChange("country")}
                onKeyPress={handleEnterPress}
              />
            )}
          </FormattedMessage>
        </div>
        <div className="sk-form__row">
          <FormattedMessage {...messages.city}>
            {(msg) => (
              <CitySelect
                helperText={touched.city ? errors.city : ""}
                error={touched.city && Boolean(errors.city)}
                name="city"
                label={msg}
                value={city}
                disabled={!country}
                country={country}
                // skip={city?.length < 3}
                handleBlur={handleInputBlur("city")}
                handleChange={handleInputChange("city")}
                handleSearch={handleInputChange("city")}
                onKeyPress={handleEnterPress}
              />
            )}
          </FormattedMessage>
        </div>
      </div>

      <div className="sk-form_footer sk-auth-content_footer">
        <div className="sk-auth-content_terms">
          <Checkbox
            label={
              <CheckboxLabel
                errorText={touched.terms ? errors.terms : ""}
                error={touched.terms && Boolean(errors.terms)}
              >
                <Text classes="sk-auth-content_text">
                  <FormattedMessage {...messages.bySigningUp} />
                  <span className="sk-auth-content_links">
                    <Link
                      target="_blank"
                      to="/terms-of-use"
                      classes="sk-auth-content_link"
                    >
                      <FormattedMessage {...messages.termsOfUse} />
                    </Link>{" "}
                    <FormattedMessage {...messages.and} />{" "}
                    <Link
                      target="_blank"
                      to="/privacy-policy"
                      classes="sk-auth-content_link"
                    >
                      <FormattedMessage {...messages.privacyPolicy} />
                    </Link>
                  </span>
                </Text>
              </CheckboxLabel>
            }
            alignTop
            name="terms"
            checked={!!terms}
            onBlur={handleInputBlur("terms")}
            handleChange={handleInputChange("terms")}
            error={touched.terms && Boolean(errors.terms)}
            onKeyPress={handleEnterPress}
          />
        </div>
        <Button
          variant="text"
          color="secondary"
          size="large"
          type="submit"
          loading={isLoading}
        >
          <FormattedMessage {...messages.createAcc} />
        </Button>
      </div>
    </form>
  );
});

export default withRouter(withModal(CompanySignUpForm));
