import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  d: {
    id: 'app.components.CourseCard.d',
    defaultMessage: 'd',
  },
  h: {
    id: 'app.components.CourseCard.h',
    defaultMessage: 'h',
  },
  lect: {
    id: 'app.components.CourseCard.lect',
    defaultMessage: 'lect.',
  },
  sect: {
    id: 'app.components.CourseCard.sect',
    defaultMessage: 'sect.',
  },
  Alllevels: {
    id: 'app.components.CourseCard.Alllevels',
    defaultMessage: 'All levels',
  },
  Beginner: {
    id: 'app.components.CourseCard.Beginner',
    defaultMessage: 'Beginner',
  },
  Intermediate: {
    id: 'app.components.CourseCard.Intermediate',
    defaultMessage: 'Intermediate',
  },
  Advanced: {
    id: 'app.components.CourseCard.Advanced',
    defaultMessage: 'Advanced',
  },
  flex: {
    id: 'app.components.CourseCard.flex',
    defaultMessage: 'Flex'
  },
  assist: {
    id: 'app.components.CourseCard.assist',
    defaultMessage: 'Assist'
  },
  concierge: {
    id: 'app.components.CourseCard.concierge',
    defaultMessage: 'Concierge'
  },
  stars: {
    id: 'app.components.CourseCard.stars',
    defaultMessage: 'stars',
  },
  votes: {
    id: 'app.components.CourseCard.votes',
    defaultMessage: 'votes',
  }
});
