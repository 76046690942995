import React, { memo } from 'react';
import { compose } from 'react-apollo';

const Icon = ({ ...props }) => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M22.5296 12.4999C22.5296 17.6329 18.3685 21.794 13.2355 21.794C8.10252 21.794 3.94141 17.6329 3.94141 12.4999C3.94141 7.36693 8.10252 3.20581 13.2355 3.20581C18.3685 3.20581 22.5296 7.36693 22.5296 12.4999ZM23.5296 12.4999C23.5296 18.1852 18.9208 22.794 13.2355 22.794C7.55024 22.794 2.94141 18.1852 2.94141 12.4999C2.94141 6.81464 7.55024 2.20581 13.2355 2.20581C18.9208 2.20581 23.5296 6.81464 23.5296 12.4999ZM14.4261 13.7738C14.6591 13.9861 14.7755 14.2563 14.7755 14.5843C14.7755 14.9356 14.6461 15.2174 14.3873 15.4297C14.1328 15.642 13.7597 15.7481 13.268 15.7481C12.7676 15.7481 12.3794 15.6149 12.1033 15.3486C11.8316 15.0823 11.6957 14.7059 11.6957 14.2196H10.1234C10.1234 14.9838 10.3521 15.5976 10.8093 16.0608C11.2708 16.5239 11.9286 16.798 12.7827 16.8829V18.0062H13.8115V16.8771C14.6095 16.8038 15.2306 16.5645 15.6749 16.1592C16.1235 15.7539 16.3478 15.2251 16.3478 14.5728C16.3478 14.2254 16.2896 13.9224 16.1731 13.6638C16.0566 13.4051 15.8906 13.1774 15.6749 12.9806C15.4592 12.7798 15.1939 12.6023 14.8791 12.4479C14.5642 12.2935 14.1307 12.1256 13.5785 11.9442C13.0264 11.7628 12.6339 11.5717 12.401 11.371C12.1724 11.1664 12.058 10.8962 12.058 10.5604C12.058 10.186 12.1745 9.89842 12.4074 9.6977C12.6404 9.49699 12.9639 9.39663 13.378 9.39663C13.7964 9.39663 14.1264 9.53752 14.3679 9.81929C14.6138 10.0972 14.7367 10.4793 14.7367 10.9657H16.3025C16.3025 10.2091 16.0976 9.59542 15.6878 9.12451C15.2781 8.64974 14.6979 8.36411 13.9473 8.26761V6.99384H12.9121V8.25603C12.1659 8.33323 11.5749 8.57833 11.1393 8.99134C10.7036 9.40435 10.4858 9.93123 10.4858 10.572C10.4858 11.5176 10.9883 12.2317 11.9933 12.7142C12.2953 12.8609 12.718 13.0211 13.2615 13.1948C13.805 13.3646 14.1932 13.5576 14.4261 13.7738Z" fill="#20292F"/>
    </svg>
  );
};

const enhancer = compose(
  memo,
);

export default enhancer(Icon);
