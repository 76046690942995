import React from 'react';
import PropTypes from 'prop-types';
import { PurpleHeart, White } from 'components/Icon/color';

const WebinarVideoOff = ({ height, width, strokeColor, fillColor, active }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="25"
      cy="25"
      r="25"
      fill={fillColor}
      fillOpacity={active ? '1' : '0.8'}
    />
    <path
      d="M30.7 19V31.2C30.7 32 30 32.7 29.2 32.7H14"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5 28.5V19C12.5 18.2 13.2 17.5 14 17.5H28.2"
      stroke={strokeColor}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.1996 24.7L37.6996 19.7C37.9996 19.4 38.4996 19.7 38.4996
       20.1V30C38.4996 30.4 37.9996 30.6 37.6996 30.4L31.1996 25.4C30.8996
        25.3 30.8996 24.9 31.1996 24.7Z"
      stroke={strokeColor}
    />
    <path
      d="M10.0998 32.9C9.89977 33 9.89977 33.4 9.99977 33.6C10.1998 33.8 
      10.4998 33.9 10.6998 33.7L10.0998 32.9ZM34.0998 16.4C34.2998 16.2
       34.3998 15.9 34.1998 15.7C33.9998 15.5 33.6998 15.4 33.4998 15.6L34.0998 
       16.4ZM10.6998 33.7L34.0998 16.4L33.4998 15.6L10.0998 32.9L10.6998 33.7Z"
      fill={strokeColor}
    />
  </svg>
);

WebinarVideoOff.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  strokeColor: PropTypes.string,
  fillColor: PropTypes.string,
  active: PropTypes.bool,
};

WebinarVideoOff.defaultProps = {
  height: '50',
  width: '50',
  strokeColor: White,
  fillColor: PurpleHeart,
  active: false,
};

export default WebinarVideoOff;
