// Core
import React from 'react';
import { injectIntl, } from 'react-intl';

// Components
import Link from 'components/Link';
import Image from 'components/Image';
import Text from 'components/Text';

// Icons
import LectureIcon from 'routes/components/ToppingCard/icons/LectureIcon.js';
import MasterclassIcon from 'routes/components/ToppingCard/icons/MasterclassIcon.js';

// Other
import { messages } from 'routes/ExpertsCabinet/ToppingEdit/messages';
import { TOPPINGS_TYPES_OPTIONS, TOPPINGS_STATUSES_OPTIONS } from 'routes/ExpertsCabinet/ToppingEdit/enums.js';
import { isBlank, getCourseThumbnail } from 'utils/helpers';

// Styles
import './index.scss';

const TYPES_ICONS = {
  masterclass: MasterclassIcon,
  lecture: LectureIcon,
  podcast: LectureIcon,
}

const COMPONENT_PREFIX = 'sk-student-toppings_card';

const ToppingCard = ({
  isExpert,
  toppingPageLink,
  topping: { title, statusId, variants, coursePhoto, thumbnail },
  intl: { formatMassage },
  classes='',
}) => {
  const type = TOPPINGS_TYPES_OPTIONS.find(option => option.value == variants[0]?.name);
  const TypeIcon = type && TYPES_ICONS[type.value];
  const status = TOPPINGS_STATUSES_OPTIONS.find(option => option.value == statusId);

  return(
    <Link to={toppingPageLink}>
      <div className={`${COMPONENT_PREFIX} ${classes}`}>
        <div className={`${COMPONENT_PREFIX}__picture`}>
          <Image src={getCourseThumbnail(thumbnail, coursePhoto)} />
        </div>
        <Text className={`${COMPONENT_PREFIX}__title`}>
          {title}
        </Text>
        {type && (
          <div className={`${COMPONENT_PREFIX}__col ${COMPONENT_PREFIX}__type`}>
            <TypeIcon />
            <Text className={`${COMPONENT_PREFIX}__type_label`}>{type.label}</Text>
          </div>
        )}
        {isExpert && (
          <div className={`${COMPONENT_PREFIX}__col ${COMPONENT_PREFIX}__status`}>
            <Text className={`${COMPONENT_PREFIX}__status_label`}>{status.label}</Text>
          </div>
        )}
      </div>
    </Link>
  );
}

export default injectIntl(ToppingCard);
