import { defineMessages } from "react-intl";

export const sharedMessages = defineMessages({
  // UPDATED START
  // CATEGORY
  architectureAndSpaces: {
    id: "app.sharedMessages.architectureAndSpaces",
    defaultMessage: "Architecture & Spaces ",
  },
  craft: {
    id: "app.sharedMessages.craft",
    defaultMessage: "Craft ",
  },
  hr: {
    id: "app.sharedMessages.hr",
    defaultMessage: "HR",
  },
  it: {
    id: "app.sharedMessages.it",
    defaultMessage: "IT",
  },
  changeManagement: {
    id: "app.sharedMessages.changeManagement",
    defaultMessage: "Change Management",
  },
  operations: {
    id: "app.sharedMessages.operations",
    defaultMessage: "Operations",
  },
   sales: {
    id: "app.sharedMessages.sales",
    defaultMessage: "Sales",
  },
  corporateStrategy: {
    id: "app.sharedMessages.corporateStrategy",
    defaultMessage: "Corporate Strategy",
  },
  finance: {
    id: "app.sharedMessages.finance",
    defaultMessage: "Finance",
  },

  marketingAndBusiness: {
    id: "app.sharedMessages.marketingAndBusiness",
    defaultMessage: "Marketing & Business ",
  },
  technology: {
    id: "app.sharedMessages.technology",
    defaultMessage: "Technology ",
  },
  photographyAndVideo: {
    id: "app.sharedMessages.photographyAndVideo",
    defaultMessage: "Photography & Video ",
  },
  design: {
    id: "app.sharedMessages.design",
    defaultMessage: "Design ",
  },
  illustrations: {
    id: "app.sharedMessages.illustrations",
    defaultMessage: "Illustrations ",
  },
  "3dAndAnimation": {
    id: "app.sharedMessages.3dAndAnimation",
    defaultMessage: "3D & Animation ",
  },
  typography: {
    id: "app.sharedMessages.typography",
    defaultMessage: "Typography ",
  },
  // ACTIVE CATEGORY
  hrDesc: {
    id: "app.sharedMessages.hr",
    defaultMessage: "HR",
  },
  itDesc: {
    id: "app.sharedMessages.it",
    defaultMessage: "IT",
  },
  changeManagementDesc: {
    id: "app.sharedMessages.changeManagement",
    defaultMessage: "Change Management",
  },
  salesDesc: {
    id: "app.sharedMessages.sales",
    defaultMessage: "Sales",
  },
  corporateStrategyDesc: {
    id: "app.sharedMessages.corporateStrategy",
    defaultMessage: "Corporate Strategy",
  },
  financeDesc: {
    id: "app.sharedMessages.finance",
    defaultMessage: "Finance",
  },
  operationsDesc: {
    id: "app.sharedMessages.operations",
    defaultMessage: "Operations",
  },


  architectureAndSpacesDesc: {
    id: "app.sharedMessages.architectureAndSpaces",
    defaultMessage: "Architecture & Spaces ",
  },
  softwareDesc: {
    id: "app.sharedMessages.software",
    defaultMessage: "Software ",
  },
  craftDesc: {
    id: "app.sharedMessages.craft",
    defaultMessage: "Craft ",
  },
  marketingAndBusinessDesc: {
    id: "app.sharedMessages.marketingAndBusiness",
    defaultMessage: "Marketing & Business ",
  },
  technologyDesc: {
    id: "app.sharedMessages.technology",
    defaultMessage: "Technology ",
  },
  photographyAndVideoDesc: {
    id: "app.sharedMessages.photographyAndVideo",
    defaultMessage: "Photography & Video ",
  },
  designDesc: {
    id: "app.sharedMessages.design",
    defaultMessage: "Design ",
  },
  illustrationsDesc: {
    id: "app.sharedMessages.illustrations",
    defaultMessage: "Illustrations ",
  },
  "3dAndAnimationDesc": {
    id: "app.sharedMessages.3dAndAnimation",
    defaultMessage: "3D & Animation ",
  },
  typographyDesc: {
    id: "app.sharedMessages.typography",
    defaultMessage: "Typography ",
  },
  performingArts: {
    id: "app.sharedMessages.performingArts",
    defaultMessage: "Performing Arts ",
  },
  creativeCooking: {
    id: "app.sharedMessages.creativeCooking",
    defaultMessage: "Creative Cooking ",
  },
  // SUBCATEGORY
  indianCooking: {
    id: "app.sharedMessages.indianCooking",
    defaultMessage: "Indian Cooking ",
  },
  italianCooking: {
    id: "app.sharedMessages.italianCooking",
    defaultMessage: "Italian Cooking ",
  },
  japaneseCooking: {
    id: "app.sharedMessages.japaneseCooking",
    defaultMessage: "Japanese Cooking ",
  },
  chineseCooking: {
    id: "app.sharedMessages.chineseCooking",
    defaultMessage: "Chinese Cooking ",
  },
  veganCooking: {
    id: "app.sharedMessages.veganCooking",
    defaultMessage: "Vegan Cooking ",
  },
  healthCooking: {
    id: "app.sharedMessages.healthCooking",
    defaultMessage: "Health Cooking ",
  },
  thaiCooking: {
    id: "app.sharedMessages.thaiCooking",
    defaultMessage: "Thai Cooking ",
  },
  dessets: {
    id: "app.sharedMessages.dessets",
    defaultMessage: "Desserts ",
  },
  beverages: {
    id: "app.sharedMessages.beverages",
    defaultMessage: "Beverages ",
  },
  dancing: {
    id: "app.sharedMessages.dancing",
    defaultMessage: "Dancing",
  },
  speakingVoice: {
    id: "app.sharedMessages.speakingVoice",
    defaultMessage: "Speaking Voice ",
  },
  magic: {
    id: "app.sharedMessages.magic",
    defaultMessage: "Magic ",
  },
  interiorArchitecture: {
    id: "app.sharedMessages.interiorArchitecture",
    defaultMessage: "Interior Architecture ",
  },
  photography: {
    id: "app.sharedMessages.photography",
    defaultMessage: "Photography ",
  },
  drawing: {
    id: "app.sharedMessages.drawing",
    defaultMessage: "Drawing ",
  },
  canva: {
    id: "app.sharedMessages.canva",
    defaultMessage: "Canva ",
  },
  logotypeDesign: {
    id: "app.sharedMessages.logotypeDesign",
    defaultMessage: "Logotype Design ",
  },
  postProduction: {
    id: "app.sharedMessages.postProduction",
    defaultMessage: "Post-production ",
  },
  uiUx: {
    id: "app.sharedMessages.uiUx",
    defaultMessage: "UI / UX ",
  },
  handLettering: {
    id: "app.sharedMessages.handLettering",
    defaultMessage: "Hand Lettering ",
  },
  marketing: {
    id: "app.sharedMessages.marketing",
    defaultMessage: "Marketing ",
  },
  others: {
    id: "app.sharedMessages.others",
    defaultMessage: "Other ",
  },
  textileIllustration: {
    id: "app.sharedMessages.textileIllustration",
    defaultMessage: "Textile Illustration ",
  },
  interactiveDesign: {
    id: "app.sharedMessages.interactiveDesign",
    defaultMessage: "Interactive Design",
  },
  "2dAnimation": {
    id: "app.sharedMessages.2dAnimation",
    defaultMessage: "2D Animation ",
  },
  cinema4d: {
    id: "app.sharedMessages.cinema4d",
    defaultMessage: "Cinema 4D ",
  },
  characterDesign: {
    id: "app.sharedMessages.characterDesign",
    defaultMessage: "Character Design",
  },
  interiorDesign: {
    id: "app.sharedMessages.interiorDesign ",
    defaultMessage: "Interior Design ",
  },
  artDirection: {
    id: "app.sharedMessages.artDirection ",
    defaultMessage: "Art Direction ",
  },
  calligraphy: {
    id: "app.sharedMessages.calligraphy ",
    defaultMessage: "Calligraphy ",
  },
  portraitPhotography: {
    id: "app.sharedMessages.portraitPhotography ",
    defaultMessage: "Portrait Photography ",
  },
  interiorDecoration: {
    id: "app.sharedMessages.interiorDecoration ",
    defaultMessage: "Interior Decoration",
  },
  typographyDesign: {
    id: "app.sharedMessages.typographyDesign ",
    defaultMessage: "Typography Design ",
  },
  videoProduction: {
    id: "app.sharedMessages.videoProduction ",
    defaultMessage: "Video Production ",
  },
  motionGraphics: {
    id: "app.sharedMessages.motionGraphics ",
    defaultMessage: "Motion Graphics ",
  },
  embroidery: {
    id: "app.sharedMessages.embroidery ",
    defaultMessage: "Embroidery ",
  },
  adobeInDesign: {
    id: "app.sharedMessages.adobeInDesign ",
    defaultMessage: "Adobe InDesign ",
  },
  digitalPhotography: {
    id: "app.sharedMessages.digitalPhotography ",
    defaultMessage: "Digital Photography ",
  },
  adobePhotoshop: {
    id: "app.sharedMessages.adobePhotoshop ",
    defaultMessage: "Adobe Photoshop ",
  },
  archviz: {
    id: "app.sharedMessages.archviz ",
    defaultMessage: "ArchVIZ ",
  },
  lettering: {
    id: "app.sharedMessages.lettering ",
    defaultMessage: "Lettering ",
  },
  informationArchitecture: {
    id: "app.sharedMessages.informationArchitecture ",
    defaultMessage: "Information Architecture ",
  },
  digitalArchitecture: {
    id: "app.sharedMessages.digitalArchitecture ",
    defaultMessage: "Digital Architecture ",
  },
  graphicDesign: {
    id: "app.sharedMessages.graphicDesign ",
    defaultMessage: "Graphic Design ",
  },
  socialMedia: {
    id: "app.sharedMessages.socialMedia ",
    defaultMessage: "Social Media ",
  },
  adobeAfterEffects: {
    id: "app.sharedMessages.adobeAfterEffects ",
    defaultMessage: "Adobe After Effects ",
  },
  fiberArts: {
    id: "app.sharedMessages.fiberArts ",
    defaultMessage: "Fiber Arts ",
  },
  architecture: {
    id: "app.sharedMessages.architecture ",
    defaultMessage: "Architecture ",
  },
  communication: {
    id: "app.sharedMessages.communication ",
    defaultMessage: "Communication ",
  },
  brandingAndIdentity: {
    id: "app.sharedMessages.brandingAndIdentity ",
    defaultMessage: "Branding & Identity ",
  },
  fineArtPhotography: {
    id: "app.sharedMessages.fineArtPhotography ",
    defaultMessage: "Fine-art Photography ",
  },
  pencilDrawing: {
    id: "app.sharedMessages.pencilDrawing ",
    defaultMessage: "Pencil Drawing ",
  },
  digitalIllustration: {
    id: "app.sharedMessages.digitalIllustration ",
    defaultMessage: "Digital Illustration",
  },
  creativeConsulting: {
    id: "app.sharedMessages.creativeConsulting ",
    defaultMessage: "Creative Consulting ",
  },
  creativeWriting: {
    id: "app.sharedMessages.creativeWriting ",
    defaultMessage: "Creative Writing ",
  },
  strategyAndInsights: {
    id: "app.sharedMessages.strategyAndInsights ",
    defaultMessage: "Strategy & Insights ",
  },
  "3dModeling": {
    id: "app.sharedMessages.3dModeling ",
    defaultMessage: "3D Modeling ",
  },
  "3d": {
    id: "app.sharedMessages.3d ",
    defaultMessage: "3D ",
  },
  productDesign: {
    id: "app.sharedMessages.productDesign ",
    defaultMessage: "Product Design ",
  },
  studioPhotography: {
    id: "app.sharedMessages.studioPhotography ",
    defaultMessage: "Studio Photography ",
  },
  animation: {
    id: "app.sharedMessages.animation ",
    defaultMessage: "Animation ",
  },
  adobeIllustrator: {
    id: "app.sharedMessages.adobeIllustrator ",
    defaultMessage: "Adobe Illustrator ",
  },
  crafts: {
    id: "app.sharedMessages.crafts ",
    defaultMessage: "Crafts ",
  },
  fineArt: {
    id: "app.sharedMessages.fineArt ",
    defaultMessage: "Fine-art ",
  },
  fashion: {
    id: "app.sharedMessages.fashion ",
    defaultMessage: "Fashion ",
  },
  glyphs: {
    id: "app.sharedMessages.glyphs ",
    defaultMessage: "Glyphs ",
  },
  // UPDATED END
  showMore: {
    id: "app.sharedMessages.showMore",
    defaultMessage: "Show More",
  },
  showLess: {
    id: "app.sharedMessages.showLess",
    defaultMessage: "Show Less",
  },
  other: {
    id: "app.sharedMessages.other",
    defaultMessage: "Other",
  },
  bigDataFactory: {
    id: "app.sharedMessages.bigDataFactory",
    defaultMessage: "Big Data Factory ",
  },
  dataMiningVisualization: {
    id: "app.sharedMessages.dataMiningVisualization",
    defaultMessage: "Data mining & visualization",
  },
  databases: {
    id: "app.sharedMessages.databases",
    defaultMessage: "Databases",
  },
  dataProtection: {
    id: "app.sharedMessages.dataProtection",
    defaultMessage: "Data protection ",
  },
  bigDataComputation: {
    id: "app.sharedMessages.bigDataComputation",
    defaultMessage: "Big Data computation ",
  },
  statisticalPredictiveModelling: {
    id: "app.sharedMessages.statisticalPredictiveModelling",
    defaultMessage: "Statistical & predictive modelling ",
  },
  bigDataProgrammingLanguages: {
    id: "app.sharedMessages.bigDataProgrammingLanguages",
    defaultMessage: "Big Data programming languages",
  },
  neuralNetwork: {
    id: "app.sharedMessages.neuralNetwork",
    defaultMessage: "Neural network",
  },
  machineLearningArtificialIntelligenceAI: {
    id: "app.sharedMessages.machineLearningArtificialIntelligenceAI",
    defaultMessage: "Machine learning & Artificial Intelligence (AI)",
  },
  computerTechnologies: {
    id: "app.sharedMessages.computerTechnologies",
    defaultMessage: "Computer Technologies",
  },
  webDesign: {
    id: "app.sharedMessages.webDesign",
    defaultMessage: "Web design",
  },
  webDevelopment: {
    id: "app.sharedMessages.webDevelopment",
    defaultMessage: "Web development",
  },
  software: {
    id: "app.sharedMessages.software",
    defaultMessage: "Software",
  },
  mobileApplications: {
    id: "app.sharedMessages.mobileApplications",
    defaultMessage: "Mobile applications",
  },
  virtualRealityAugmentedReality: {
    id: "app.sharedMessages.virtualRealityAugmentedReality",
    defaultMessage: "Virtual Reality (VR) & Augmented Reality (AR)",
  },
  gaming: {
    id: "app.sharedMessages.gaming",
    defaultMessage: "Gaming",
  },
  cloudTechnologies: {
    id: "app.sharedMessages.cloudTechnologies",
    defaultMessage: "Cloud technologies",
  },
  programmingLanguages: {
    id: "app.sharedMessages.programmingLanguages",
    defaultMessage: "Programming languages",
  },
  operatingSystems: {
    id: "app.sharedMessages.operatingSystems",
    defaultMessage: "Operating systems",
  },
  cyberSecurity: {
    id: "app.sharedMessages.cyberSecurity",
    defaultMessage: "Cyber security",
  },
  maintenanceAdministration: {
    id: "app.sharedMessages.maintenanceAdministration",
    defaultMessage: "Maintenance & administration",
  },
  qualityAssurance: {
    id: "app.sharedMessages.qualityAssurance",
    defaultMessage: "Quality Assurance (QA)",
  },
  hardwareElectronics: {
    id: "app.sharedMessages.hardwareElectronics",
    defaultMessage: "Hardware & Electronics",
  },
  befriendTheMachines: {
    id: "app.sharedMessages.befriendTheMachines",
    defaultMessage: "Industry 4.0",
  },
  industrialControlSystems: {
    id: "app.sharedMessages.industrialControlSystems",
    defaultMessage: "Industrial control systems",
  },
  manufacturingProcessProduction: {
    id: "app.sharedMessages.manufacturingProcessProduction",
    defaultMessage: "Manufacturing process & production",
  },
  automationRobotics: {
    id: "app.sharedMessages.automationRobotics",
    defaultMessage: "Automation & robotics",
  },
  industrialSoftware: {
    id: "app.sharedMessages.industrialSoftware",
    defaultMessage: "Industrial software",
  },
  predictiveMaintenance: {
    id: "app.sharedMessages.predictiveMaintenance",
    defaultMessage: "Predictive maintenance",
  },
  internetOfThings: {
    id: "app.sharedMessages.internetOfThings",
    defaultMessage: "Internet of Things (IoT)",
  },
  mechanicalArtificialIntelligence: {
    id: "app.sharedMessages.mechanicalArtificialIntelligence",
    defaultMessage: "Mechanical Artificial Intelligence",
  },
  blockchainCategory: {
    id: "app.sharedMessages.blockchainCategory",
    defaultMessage: "Blockchain",
  },
  blockchain: {
    id: "app.sharedMessages.blockchain",
    defaultMessage: "Blockchain",
  },
  ico: {
    id: "app.sharedMessages.ico",
    defaultMessage: "ICO",
  },
  cryptoCurrencies: {
    id: "app.sharedMessages.cryptoCurrencies",
    defaultMessage: "Crypto currencies",
  },
  cryptoTrading: {
    id: "app.sharedMessages.cryptoTrading",
    defaultMessage: "Crypto trading",
  },
  reDesignMindset: {
    id: "app.sharedMessages.reDesignMindset",
    defaultMessage: "Re-design mindset",
  },
  creativeMindset: {
    id: "app.sharedMessages.creativeMindset",
    defaultMessage: "Creative mindset",
  },
  criticalThinking: {
    id: "app.sharedMessages.criticalThinking",
    defaultMessage: "Critical thinking",
  },
  cognitiveTechnologies: {
    id: "app.sharedMessages.cognitiveTechnologies",
    defaultMessage: "Cognitive technologies",
  },
  scribing: {
    id: "app.sharedMessages.scribing",
    defaultMessage: "Scribing",
  },
  disruptiveInnovations: {
    id: "app.sharedMessages.disruptiveInnovations",
    defaultMessage: "Disruptive innovations",
  },
  complexProblemSolving: {
    id: "app.sharedMessages.complexProblemSolving",
    defaultMessage: "Complex problem solving",
  },
  emotionalIntelligence: {
    id: "app.sharedMessages.emotionalIntelligence",
    defaultMessage: "Emotional Intelligence",
  },
  coachingMentoring: {
    id: "app.sharedMessages.coachingMentoring",
    defaultMessage: "Coaching & mentoring",
  },
  startups: {
    id: "app.sharedMessages.startups",
    defaultMessage: "Startups",
  },
  leanStartup: {
    id: "app.sharedMessages.leanStartup",
    defaultMessage: "Lean Startup",
  },
  businessPlan: {
    id: "app.sharedMessages.businessPlan",
    defaultMessage: "Business plan",
  },
  ideaEvaluation: {
    id: "app.sharedMessages.ideaEvaluation",
    defaultMessage: "Idea evaluation",
  },
  riskAssessment: {
    id: "app.sharedMessages.riskAssessment",
    defaultMessage: "Risk assessment",
  },
  elevatorPitch: {
    id: "app.sharedMessages.elevatorPitch",
    defaultMessage: "Elevator pitch",
  },
  setupBusinessAbroad: {
    id: "app.sharedMessages.setupBusinessAbroad",
    defaultMessage: "Setup business abroad",
  },
  paymentSystems: {
    id: "app.sharedMessages.paymentSystems",
    defaultMessage: "Payment systems",
  },
  industriesNiches: {
    id: "app.sharedMessages.industriesNiches",
    defaultMessage: "Industries & niches",
  },
  onlineBusinessECommerce: {
    id: "app.sharedMessages.onlineBusinessECommerce",
    defaultMessage: "Online business & e-commerce",
  },
  digitalMarketing: {
    id: "app.sharedMessages.digitalMarketing",
    defaultMessage: "Digital Marketing",
  },
  seo: {
    id: "app.sharedMessages.seo",
    defaultMessage: "SEO",
  },
  contentMarketing: {
    id: "app.sharedMessages.contentMarketing",
    defaultMessage: "Content marketing",
  },
  socialMediaMarketing: {
    id: "app.sharedMessages.socialMediaMarketing",
    defaultMessage: "Social Media Marketing (SMM)",
  },
  onlineAdsAndPromo: {
    id: "app.sharedMessages.onlineAdsAndPromo",
    defaultMessage: "Online ads and promo",
  },
  emailMarketing: {
    id: "app.sharedMessages.emailMarketing",
    defaultMessage: "Email marketing",
  },
  youTubeMarketing: {
    id: "app.sharedMessages.youTubeMarketing",
    defaultMessage: "YouTube marketing",
  },
  videoMobileMarketing: {
    id: "app.sharedMessages.videoMobileMarketing",
    defaultMessage: "Video & mobile marketing",
  },
  marketingAutomation: {
    id: "app.sharedMessages.marketingAutomation",
    defaultMessage: "Marketing automation",
  },
  googleAnalytics: {
    id: "app.sharedMessages.googleAnalytics",
    defaultMessage: "Google analytics",
  },
  onlinePR: {
    id: "app.sharedMessages.onlinePR",
    defaultMessage: "Online PR",
  },
  effectiveBusiness: {
    id: "app.sharedMessages.effectiveBusiness",
    defaultMessage: "Effective Business",
  },
  businessStrategy: {
    id: "app.sharedMessages.businessStrategy",
    defaultMessage: "Business strategy",
  },
  consumerCentricity: {
    id: "app.sharedMessages.consumerCentricity",
    defaultMessage: "Consumer centricity",
  },
  salesMarketing: {
    id: "app.sharedMessages.salesMarketing",
    defaultMessage: "Sales & marketing",
  },
  marketResearch: {
    id: "app.sharedMessages.marketResearch",
    defaultMessage: "Market research",
  },
  categoryManagement: {
    id: "app.sharedMessages.categoryManagement",
    defaultMessage: "Category management",
  },
  productManagement: {
    id: "app.sharedMessages.productManagement",
    defaultMessage: "Product management",
  },
  customerRelationshipManagement: {
    id: "app.sharedMessages.customerRelationshipManagement",
    defaultMessage: "Customer Relationship Management (CRM)",
  },
  projectManagementAgileScrum: {
    id: "app.sharedMessages.projectManagementAgileScrum",
    defaultMessage: "Project management (Agile, Scrum etc.)",
  },
  processManagement: {
    id: "app.sharedMessages.processManagement",
    defaultMessage: "Process management",
  },
  crisisManagement: {
    id: "app.sharedMessages.crisisManagement",
    defaultMessage: "Crisis management",
  },
  masterOfCommunication: {
    id: "app.sharedMessages.masterOfCommunication",
    defaultMessage: "Master of communication",
  },
  organizationalHealth: {
    id: "app.sharedMessages.organizationalHealth",
    defaultMessage: "Organizational health",
  },
  peopleDevelopment: {
    id: "app.sharedMessages.peopleDevelopment",
    defaultMessage: "People development",
  },
  strategicHiring: {
    id: "app.sharedMessages.strategicHiring",
    defaultMessage: "Strategic hiring",
  },
  financeAndAccounting: {
    id: "app.sharedMessages.financeAndAccounting",
    defaultMessage: "Finance and Accounting",
  },
  oneC: {
    id: "app.sharedMessages.oneC",
    defaultMessage: "1C",
  },
  legalTax: {
    id: "app.sharedMessages.legalTax",
    defaultMessage: "Legal & tax",
  },
  officeProductivity: {
    id: "app.sharedMessages.officeProductivity",
    defaultMessage: "Office productivity",
  },
  strategicProcurementSourcing: {
    id: "app.sharedMessages.strategicProcurementSourcing",
    defaultMessage: "Strategic procurement/sourcing",
  },
  effectiveNegotiation: {
    id: "app.sharedMessages.effectiveNegotiation",
    defaultMessage: "Effective negotiation",
  },
  logisticsWarehousing: {
    id: "app.sharedMessages.logisticsWarehousing",
    defaultMessage: "Logistics & warehousing",
  },
  transferableCompetencies: {
    id: "app.sharedMessages.transferableCompetencies",
    defaultMessage: "Transferable Competencies",
  },
  leadership: {
    id: "app.sharedMessages.leadership",
    defaultMessage: "Leadership",
  },
  goalSetting: {
    id: "app.sharedMessages.goalSetting",
    defaultMessage: "Goal setting",
  },
  teamWork: {
    id: "app.sharedMessages.teamWork",
    defaultMessage: "Team work",
  },
  conflictManagement: {
    id: "app.sharedMessages.conflictManagement",
    defaultMessage: "Conflict management",
  },
  artOfFeedback: {
    id: "app.sharedMessages.artOfFeedback",
    defaultMessage: "Art of feedback",
  },
  powerfulPresentation: {
    id: "app.sharedMessages.powerfulPresentation",
    defaultMessage: "Powerful presentation",
  },
  storytelling: {
    id: "app.sharedMessages.storytelling",
    defaultMessage: "Storytelling",
  },
  debateMe: {
    id: "app.sharedMessages.debateMe",
    defaultMessage: "Debate Me!",
  },
  timeManagement: {
    id: "app.sharedMessages.timeManagement",
    defaultMessage: "Time management",
  },
  analyticalSkills: {
    id: "app.sharedMessages.analyticalSkills",
    defaultMessage: "Analytical skills",
  },
  careerDevelopment: {
    id: "app.sharedMessages.careerDevelopment",
    defaultMessage: "Career development",
  },
  personalBrandBuilding: {
    id: "app.sharedMessages.personalBrandBuilding",
    defaultMessage: "Personal brand building",
  },
  backToSchool: {
    id: "app.sharedMessages.backToSchool",
    defaultMessage: "Back to school",
  },
  testPreparation: {
    id: "app.sharedMessages.testPreparation",
    defaultMessage: "Test preparation (GMAT, TOEFL etc.)",
  },
  finalExams: {
    id: "app.sharedMessages.finalExams",
    defaultMessage: "Final exams",
  },
  languages: {
    id: "app.sharedMessages.languages",
    defaultMessage: "Languages",
  },
  blogging: {
    id: "app.sharedMessages.blogging",
    defaultMessage: "Blogging",
  },
  academics: {
    id: "app.sharedMessages.academics",
    defaultMessage: "Academics",
  },
  interdisciplinaryKnowledge: {
    id: "app.sharedMessages.interdisciplinaryKnowledge",
    defaultMessage: "Interdisciplinary knowledge",
  },
  teachOnline: {
    id: "app.sharedMessages.teachOnline",
    defaultMessage: "Teach online",
  },
  teaching: {
    id: "app.sharedMessages.teaching",
    defaultMessage: "Teaching",
  },
  wellBeing: {
    id: "app.sharedMessages.wellBeing",
    defaultMessage: "Well-being",
  },
  sportFitness: {
    id: "app.sharedMessages.sportFitness",
    defaultMessage: "Sport & fitness",
  },
  generalHealth: {
    id: "app.sharedMessages.generalHealth",
    defaultMessage: "General health",
  },
  mentalHealth: {
    id: "app.sharedMessages.mentalHealth",
    defaultMessage: "Mental health",
  },
  childCareDevelopment: {
    id: "app.sharedMessages.childCareDevelopment",
    defaultMessage: "Child care & development",
  },
  safetyFirstAid: {
    id: "app.sharedMessages.safetyFirstAid",
    defaultMessage: "Self-defence & first aid",
  },
  stressRelief: {
    id: "app.sharedMessages.stressRelief",
    defaultMessage: "Stress relief",
  },
  massage: {
    id: "app.sharedMessages.massage",
    defaultMessage: "Massage",
  },
  nutrition: {
    id: "app.sharedMessages.nutrition",
    defaultMessage: "Nutrition",
  },
  makeUpHairstyling: {
    id: "app.sharedMessages.makeUpHairstyling",
    defaultMessage: "Make up & hairstyling",
  },
  cosmetology: {
    id: "app.sharedMessages.cosmetology",
    defaultMessage: "Cosmetology",
  },
  lifestyleHobbies: {
    id: "app.sharedMessages.lifestyleHobbies",
    defaultMessage: "Lifestyle & Hobbies",
  },
  styleDesign: {
    id: "app.sharedMessages.styleDesign",
    defaultMessage: "Style & design",
  },
  paintingDrawing: {
    id: "app.sharedMessages.paintingDrawing",
    defaultMessage: "Painting & drawing",
  },
  artsAntiques: {
    id: "app.sharedMessages.artsAntiques",
    defaultMessage: "Arts & antiques",
  },
  theatreFilm: {
    id: "app.sharedMessages.theatreFilm",
    defaultMessage: "Theatre & film arts",
  },
  musicVocal: {
    id: "app.sharedMessages.musicVocal",
    defaultMessage: "Music & vocal",
  },
  vocal: {
    id: "app.sharedMessages.vocal",
    defaultMessage: "Vocal",
  },
  music: {
    id: "app.sharedMessages.music",
    defaultMessage: "Music",
  },
  acting: {
    id: "app.sharedMessages.acting",
    defaultMessage: "Acting",
  },
  dance: {
    id: "app.sharedMessages.dance",
    defaultMessage: "Dance",
  },
  interiorDesignArchitecture: {
    id: "app.sharedMessages.interiorDesignArchitecture",
    defaultMessage: "Interior design & architecture",
  },
  homeImprovement: {
    id: "app.sharedMessages.homeImprovement",
    defaultMessage: "Home improvement",
  },
  gardeningLandscapeDesign: {
    id: "app.sharedMessages.gardeningLandscapeDesign",
    defaultMessage: "Gardening & landscape design",
  },
  culinaryArts: {
    id: "app.sharedMessages.culinaryArts",
    defaultMessage: "Culinary arts",
  },
  gamesEntertainment: {
    id: "app.sharedMessages.gamesEntertainment",
    defaultMessage: "Games & entertainment",
  },
  petCareTraining: {
    id: "app.sharedMessages.petCareTraining",
    defaultMessage: "Pet care & training",
  },
  investmentsPersonalFinance: {
    id: "app.sharedMessages.investmentsPersonalFinance",
    defaultMessage: "Investments & personal finance",
  },
  immigrationLivingAbroad: {
    id: "app.sharedMessages.immigrationLivingAbroad",
    defaultMessage: "Immigration & living abroad",
  },
  bigDataFactoryDesc: {
    id: "app.sharedMessages.bigDataFactoryDesc",
    defaultMessage:
      "Big Data Factory - Gain insights and deep understanding in situations of high complexity and uncertainty",
  },
  computerTechnologiesDesc: {
    id: "app.sharedMessages.computerTechnologiesDesc",
    defaultMessage: "Conquer computer world!",
  },
  befriendTheMachinesDesc: {
    id: "app.sharedMessages.befriendTheMachinesDesc",
    defaultMessage: "Learn to understand and rule the machines",
  },
  blockchainCategoryDesc: {
    id: "app.sharedMessages.blockchainCategoryDesc",
    defaultMessage: "Learn how Blockchain works and where to use it",
  },
  reDesignMindsetDesc: {
    id: "app.sharedMessages.reDesignMindsetDesc",
    defaultMessage:
      "Transform yourself!  Find new solutions!  Outperform your competition!",
  },
  startupsDesc: {
    id: "app.sharedMessages.startupsDesc",
    defaultMessage: "Be free!  Be your own boss!",
  },
  digitalMarketingDesc: {
    id: "app.sharedMessages.digitalMarketingDesc",
    defaultMessage:
      "Be on top of digital marketing! Make your business and brands go boom!",
  },
  effectiveBusinessDesc: {
    id: "app.sharedMessages.effectiveBusinessDesc",
    defaultMessage: "Make your business skills great again!",
  },
  transferableCompetenciesDesc: {
    id: "app.sharedMessages.transferableCompetenciesDesc",
    defaultMessage: "Master portable skills that are always in demand",
  },
  backToSchoolDesc: {
    id: "app.sharedMessages.backToSchoolDesc",
    defaultMessage:
      "Strengthen your knowledge.  Create new opportunities.  Unlimit yourself.",
  },
  performingArtsDesc: {
    id: "app.sharedMessages.performingArtsDesc",
    defaultMessage: " ",
  },
  creativeCookingDesc: {
    id: "app.sharedMessages.creativeCookingDesc",
    defaultMessage: " ",
  },
  wellBeingDesc: {
    id: "app.sharedMessages.wellBeingDesc",
    defaultMessage: "Take a good care of yourself",
  },
  lifestyleHobbiesDesc: {
    id: "app.sharedMessages.lifestyleHobbiesDesc",
    defaultMessage: "Live the real life",
  },
  undefined: {
    id: "app.sharedMessages.undefined",
    defaultMessage: "Undefined field",
  },
  Arabic: {
    id: "app.components.LanguageSelect.AR",
    defaultMessage: "Arabic",
  },
  Armenian: {
    id: "app.components.LanguageSelect.HY",
    defaultMessage: "Armenian",
  },
  Azerbaijani: {
    id: "app.components.LanguageSelect.AZ",
    defaultMessage: "Azerbaijani",
  },
  Bulgarian: {
    id: "app.components.LanguageSelect.BG",
    defaultMessage: "Bulgarian",
  },
  Catalan: {
    id: "app.components.LanguageSelect.CA",
    defaultMessage: "Catalan",
  },
  Chinese: {
    id: "app.components.LanguageSelect.ZH",
    defaultMessage: "Chinese",
  },
  Croatian: {
    id: "app.components.LanguageSelect.HR",
    defaultMessage: "Croatian",
  },
  Czech: {
    id: "app.components.LanguageSelect.CZ",
    defaultMessage: "Czech",
  },
  Danish: {
    id: "app.components.LanguageSelect.DA",
    defaultMessage: "Danish",
  },
  Dutch: {
    id: "app.components.LanguageSelect.NL",
    defaultMessage: "Dutch",
  },
  English: {
    id: "app.components.LanguageSelect.EN",
    defaultMessage: "English",
  },
  Estonian: {
    id: "app.components.LanguageSelect.ET",
    defaultMessage: "Estonian",
  },
  Farsi: {
    id: "app.components.LanguageSelect.FA",
    defaultMessage: "Farsi",
  },
  Finnish: {
    id: "app.components.LanguageSelect.FI",
    defaultMessage: "Finnish",
  },
  French: {
    id: "app.components.LanguageSelect.FR",
    defaultMessage: "French",
  },
  Georgian: {
    id: "app.components.LanguageSelect.KA",
    defaultMessage: "Georgian",
  },
  German: {
    id: "app.components.LanguageSelect.DE",
    defaultMessage: "German",
  },
  Greek: {
    id: "app.components.LanguageSelect.EL",
    defaultMessage: "Greek",
  },
  Hebrew: {
    id: "app.components.LanguageSelect.HE",
    defaultMessage: "Hebrew",
  },
  Hindu: {
    id: "app.components.LanguageSelect.HI",
    defaultMessage: "Hindu",
  },
  Hungarian: {
    id: "app.components.LanguageSelect.HU",
    defaultMessage: "Hungarian",
  },
  Italian: {
    id: "app.components.LanguageSelect.IT",
    defaultMessage: "Italian",
  },
  Japanese: {
    id: "app.components.LanguageSelect.JA",
    defaultMessage: "Japanese",
  },
  Kazakh: {
    id: "app.components.LanguageSelect.KK",
    defaultMessage: "Kazakh",
  },
  Latvian: {
    id: "app.components.LanguageSelect.LV",
    defaultMessage: "Latvian",
  },
  Lithuanian: {
    id: "app.components.LanguageSelect.LT",
    defaultMessage: "Lithuanian",
  },
  Norwegian: {
    id: "app.components.LanguageSelect.NO",
    defaultMessage: "Norwegian",
  },
  Polish: {
    id: "app.components.LanguageSelect.PL",
    defaultMessage: "Polish",
  },
  Portuguese: {
    id: "app.components.LanguageSelect.PT",
    defaultMessage: "Portuguese",
  },
  Romanian: {
    id: "app.components.LanguageSelect.RO",
    defaultMessage: "Romanian",
  },
  Russian: {
    id: "app.components.LanguageSelect.RU",
    defaultMessage: "Russian",
  },
  Serbian: {
    id: "app.components.LanguageSelect.SR",
    defaultMessage: "Serbian",
  },
  Slovak: {
    id: "app.components.LanguageSelect.SK",
    defaultMessage: "Slovak",
  },
  Slovene: {
    id: "app.components.LanguageSelect.SL",
    defaultMessage: "Slovene",
  },
  Spanish: {
    id: "app.components.LanguageSelect.ES",
    defaultMessage: "Spanish",
  },
  Swedish: {
    id: "app.components.LanguageSelect.SV",
    defaultMessage: "Swedish",
  },
  Tajik: {
    id: "app.components.LanguageSelect.TG",
    defaultMessage: "Tajik",
  },
  Turkish: {
    id: "app.components.LanguageSelect.TR",
    defaultMessage: "Turkish",
  },
  Turkmen: {
    id: "app.components.LanguageSelect.TK",
    defaultMessage: "Turkmen",
  },
  Ukranian: {
    id: "app.components.LanguageSelect.UK",
    defaultMessage: "Ukranian",
  },
  Uzbek: {
    id: "app.components.LanguageSelect.UZ",
    defaultMessage: "Uzbek",
  },
  hours: {
    id: "app.components.duration.hours",
    defaultMessage: "h",
  },
  min: {
    id: "app.components.duration.min",
    defaultMessage: "m",
  },
  nextButton: {
    id: "app.components.button.next",
    defaultMessage: "Next",
  },
  backButton: {
    id: "app.components.button.back",
    defaultMessage: "Back",
  },
  joinButton: {
    id: "app.components.button.join",
    defaultMessage: "Join",
  },
  blockButton: {
    id: "app.components.button.block",
    defaultMessage: "Block",
  },
  unblockButton: {
    id: "app.components.button.unblock",
    defaultMessage: "Restore",
  },
  deleteButton: {
    id: "app.components.button.delete",
    defaultMessage: "Delete",
  },
  cancelButton: {
    id: "app.components.button.cancel",
    defaultMessage: "Cancel",
  },
  applyButton: {
    id: "app.components.button.apply",
    defaultMessage: "Apply",
  },
  openButton: {
    id: "app.components.button.open",
    defaultMessage: "Open",
  },
  closeButton: {
    id: "app.components.button.close",
    defaultMessage: "Close",
  },
  addButton: {
    id: "app.components.button.add",
    defaultMessage: "Add",
  },
  saveButton: {
    id: "app.components.button.save",
    defaultMessage: "Save",
  },
  showButton: {
    id: "app.components.button.show",
    defaultMessage: "Show",
  },
  submitButton: {
    id: "app.components.button.submit",
    defaultMessage: "Submit",
  },
  uploadButton: {
    id: "app.components.button.upload",
    defaultMessage: "Upload",
  },
  understandButton: {
    id: "app.components.button.understand",
    defaultMessage: "Got it",
  },
  title: {
    id: "app.components.title",
    defaultMessage: "Title",
  },
  lecture: {
    id: "app.components.Lecture.lecture",
    defaultMessage: "Lecture",
  },
  section: {
    id: "app.components.Lecture.section",
    defaultMessage: "Section",
  },
  fileProcessing: {
    id: "app.components.Uploader.fileProcessing",
    defaultMessage: "File is processing...",
  },
  fileFailed: {
    id: "app.components.Uploader.fileFailed",
    defaultMessage:
      "Ooops, something went wrong. Please, reload your file one more time",
  },
  toppingTypeFilter: {
    id: "app.components.Filters.toppingType",
    defaultMessage: "Type",
  },
  toppingStatusFilter: {
    id: "app.components.Filters.toppingStatus",
    defaultMessage: "Status",
  },
  toppingsLabel: {
    id: "app.components.Label.toppingsLabel",
    defaultMessage: "Toppings",
  },
  coursesLabel: {
    id: "app.components.Label.coursesLabel",
    defaultMessage: "Courses",
  },
  draft: {
    id: "app.components.Status.draft",
    defaultMessage: "Draft",
  },
  firstReview: {
    id: "app.components.Status.firstReview",
    defaultMessage: "First Review",
  },
  mainFilling: {
    id: "app.components.Status.mainFilling",
    defaultMessage: "Main Filling",
  },
  finalReview: {
    id: "app.components.Status.finalReview",
    defaultMessage: "Final Review",
  },
  readyToPublishing: {
    id: "app.components.Status.readyToPublishing",
    defaultMessage: "Ready To Publishing",
  },
  active: {
    id: "app.components.Status.active",
    defaultMessage: "Active",
  },
  inactive: {
    id: "app.components.Status.inactive",
    defaultMessage: "Deactivated",
  },
  rejected: {
    id: "app.components.Status.rejected",
    defaultMessage: "Rejected",
  },
  searchCourse: {
    id: "app.components.Search.searchCourse",
    defaultMessage: "Find a course",
  },
  completed: {
    id: "app.components.Status.completed",
    defaultMessage: "Completed",
  },
});
