// Core
import React, { PureComponent } from 'react';
import { graphql } from 'react-apollo';

// GraphQl
import LIST_COUNTRIES_QUERY from 'queries/UserProfile/listCountries.gql';

// Components
import AutoComplete from 'components/Select/AutoComplete';

class CountrySelect extends PureComponent {
  render() {
    const {
      value,
      error,
      fetchError,
      loading,
      listCountries,
      ...props
    } = this.props;

    return (
      <AutoComplete
        options={listCountries?.countries}
        loading={loading}
        error={error}
        fetchError={fetchError}
        labelKey="name"
        valueKey="id"
        value={value}
        {...props}
      />
    );
  }
}

const listCountriesQuery = graphql(LIST_COUNTRIES_QUERY, {
  props: ({
    data: { loading, error: fetchError, listCountries, ...ownProps },
  }) => ({
    loading,
    listCountries,
    fetchError,
    ...ownProps,
  }),
  options: ({ value }) => ({
    fetchPolicy: 'cache-and-network',
    variables: {
      input: { name: value?.name ? value : '', size: 300 },
    },
  }),
});

CountrySelect.propTypes = {};

export default listCountriesQuery(CountrySelect);
