// Core
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Auth from '@aws-amplify/auth';
import { compose } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

// Components
import Card from 'components/Card';
import Heading from 'components/Heading';
import Text from 'components/Text';
import PasswordResetForm from 'routes/PasswordReset/PasswordResetForm/';
import ConfirmationModal from 'routes/PasswordReset/ConfirmationModal/';

// Other
import { parseIncomingError } from 'utils/errorHandling';
import { withModal } from 'containers/ModalProvider/withModal';
import { getLocaleFromPathname } from 'utils/helpers';

import { FormattedMessage } from 'react-intl';
import { messages } from 'routes/PasswordReset/messages';

// Styles and Assets
import './PasswordReset.scss';

class PasswordReset extends PureComponent {
  state = { email: '' };
  componentDidMount = () => {
    const { location, history } = this.props;
    const { email: userEmail, code: verificationCode } = queryString.parse(
      location.search
    );

    if (userEmail && verificationCode) {
      const locale = getLocaleFromPathname(location.pathname);
      history.replace({
        pathname: `/${locale}/pw-change`,
        search: location.search,
        state: { email: userEmail, code: verificationCode },
      });
    }
  };

  handleContinueClick = () => {
    const {
      modalContext: { hideModal },
      history,
      location,
    } = this.props;
    const { email } = this.state;
    const locale = getLocaleFromPathname(location.pathname);

    hideModal();
    history.push({
      pathname: `/${locale}/pw-change`,
      search: location.search,
      state: { email },
    });
  };

  handleSendCodeClick = async ({ email }, { setSubmitting, setErrors }) => {
    const {
      modalContext: { showModal },
    } = this.props;

    try {
      await Auth.forgotPassword(email.toLowerCase());

      setSubmitting(false);
      this.setState({ email }, () =>
        showModal(ConfirmationModal, {
          handleContinueClick: this.handleContinueClick,
        })
      );
    } catch (error) {
      setSubmitting(false);
      setErrors(parseIncomingError(error));
    }
  };

  render() {
    return (
      <div className="sk-pw-reset sk-background-spot__secondary">
        <Card classes="sk-pw-reset_container">
          <div className="sk-pw-reset_inner">
            <div className="sk-pw-reset_header">
              <Heading variant="h4" classes="sk-pw-reset_heading">
                <FormattedMessage {...messages.passReset} />
              </Heading>
              <Text classes="sk-pw-reset_subheading">
                <FormattedMessage {...messages.enterYourEmailAddress} />
              </Text>
            </div>
            <div className="sk-reset-content ">
              <PasswordResetForm
                handleSendCodeClick={this.handleSendCodeClick}
              />
            </div>
          </div>
        </Card>
      </div>
    );
  }
}

PasswordReset.propTypes = {
  history: PropTypes.object,
  modalContext: PropTypes.object,
  location: PropTypes.object,
};

const enhancer = compose(
  withModal,
  withRouter
);

export default enhancer(PasswordReset);
