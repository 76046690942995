import React from 'react';
import PropTypes from 'prop-types';
import { SidebarDefault, PurpleHeart } from 'components/Icon/color';

const Folder = ({ height, width, active }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 11 9"
    width={width}
    height={height}
  >
    <g fill="none" fillRule="evenodd">
      <path d="M-1-3h15v14H-1z" />
      <path
        fill={active ? PurpleHeart : SidebarDefault}
        fillRule="nonzero"
        d="M3.908 1l1.125 1.167H10V8H1V1h2.908zM4.4 0H1.1C.495 0 .005.506.005 1.125L0 
      7.875C0 8.494.495 9 1.1 9h8.8c.605 0 1.1-.506 1.1-1.125V2.25c0-.619-.495-1.125-1.1-1.125H5.5L4.4 0z"
      />
    </g>
  </svg>
);

Folder.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  active: PropTypes.bool,
};

Folder.defaultProps = {
  height: 9,
  width: 11,
  active: false,
};

export default Folder;
