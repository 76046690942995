// Core
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

// Components
import Text from 'components/Text';

// Other
import { DURATION_ZERO_BASED_DIGITS } from 'utils/enums';

const addZeroes = duration => {
  const repeatTimes = DURATION_ZERO_BASED_DIGITS - `${duration}`.length;

  return `${'0'.repeat(repeatTimes > 0 ? repeatTimes : 0)}${duration}`;
};

const parseDuration = (duration, durationUnits) => {
  if (!duration) return '00:00';

  const durationInMilliseconds = moment.duration(duration, durationUnits);
  const hours = durationInMilliseconds.hours();
  const minutes = durationInMilliseconds.minutes();

  return `${addZeroes(hours)}:${minutes === 0 ? '00' : addZeroes(minutes)}`;
};

export const DurationBlock = ({ duration, durationUnits, ceil, size = 'small' }) => (
  <Text size={size} semiBold>
    {`${parseDuration(duration, durationUnits, ceil)}`}
  </Text>
);
DurationBlock.defaultProps = {
  duration: 0,
  durationUnits: 'seconds',
};

DurationBlock.propTypes = {
  duration: PropTypes.number,
  durationUnits: PropTypes.string,
  ceil: PropTypes.bool,
};

export default memo(DurationBlock);
