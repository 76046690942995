// Core
import React from 'react';
import { injectIntl } from 'react-intl';

// Other
import { messages } from 'routes/Authenticator/AlertBox/messages';
import { messages as messagesAuth } from 'routes/Authenticator/messages';

// Styles
import './AlertBox.scss';

const LINKED_ERROR = ['ACCOUNT_LINKED', 'ACCOUNT_ALREADY_LINKED'];

const getErrorMessage = (error, formatMessage) => {
  try {
    if (error.includes(LINKED_ERROR[0]) || error.includes(LINKED_ERROR[1])) {
      return formatMessage(messagesAuth.wrongAuthenticateMethod);
    } else {
      return formatMessage(messages.facebookSignupAttributeError);
    }
  } catch(err) {
    return formatMessage(messages.facebookSignupAttributeError);
  }
}

const AlertBox = injectIntl(({
  intl: { formatMessage },
  error,
  errorDescription,
}) => {
  if (error && errorDescription !== null) {
    return (
      <div className="sk-authenticator-alert-box">
        {getErrorMessage(errorDescription, formatMessage)}
      </div>
    );
  }
  return null;
});

export default AlertBox;
