import { EditorState, SelectionState, Modifier, RichUtils, convertToRaw } from 'draft-js';

import { COLOR } from 'components/DraftEditor/enums.js';
import { isBlank } from 'utils/helpers/index';

const TEXT_COLOR = {
  '#444444': '#30d5c8',
  '#30d5c8': '#444444',
  default: '#30d5c8',
};

// TODO:
// get entity
// get next entity/create entity
// apply entity to selection

// Bad solution. New Entity is created on every text color change. As a result a huge amount of identical entities in entity map
export default ({ editorState }) => {
  const contentState = editorState.getCurrentContent();
  const selection = editorState.getSelection();

  const startKey = selection.getStartKey();
  const startOffset = selection.getStartOffset();
  const blockWithEntity = contentState.getBlockForKey(startKey);
  const entityKey = blockWithEntity.getEntityAt(startOffset);

  let nextTextColor = TEXT_COLOR.default;

  if (!isBlank(entityKey)) {
    const entityInstance = contentState.getEntity(entityKey);
    const { textColor } = entityInstance.getData();
    nextTextColor = TEXT_COLOR[textColor];
  }

  // create next entity
  const nextEntityInstance = contentState.createEntity(COLOR, 'IMMUTABLE', { textColor: nextTextColor });
  const nextEntityKey = contentState.getLastCreatedEntityKey();
  const nextContentState = Modifier.applyEntity(contentState, selection, nextEntityKey);

  // apply entity to selection
  const nextEditorState = EditorState.push(editorState, nextContentState);

  return nextEditorState;
};


// replaceEntityData - not work, need to debugg!
// export default ({ editorState }) => {
//   const contentState = editorState.getCurrentContent();
//   const selection = editorState.getSelection();
//
//   // get entity next color
//   const startKey = selection.getStartKey();
//   const startOffset = selection.getStartOffset();
//   const blockWithEntity = contentState.getBlockForKey(startKey);
//   const entityKey = blockWithEntity.getEntityAt(startOffset);
//
//   let nextTextColor = TEXT_COLOR.default;
//   let nextContentState = contentState;
//
//   // get next text color
//   if (isBlank(entityKey)) {
//     const nextEntityInstance = contentState.createEntity(COLOR, 'IMMUTABLE', {
//       textColor: nextTextColor,
//     });
//     const nextEntityKey = contentState.getLastCreatedEntityKey();
//     nextContentState = Modifier.applyEntity(contentState, selection, nextEntityKey);
//   } else {
//     const entityInstance = contentState.getEntity(entityKey);
//     const { textColor } = entityInstance.getData();
//     nextTextColor = TEXT_COLOR[textColor];
//
//     debugger;
//
//     nextContentState = nextContentState.replaceEntityData(entityKey, { textColor: nextTextColor });
//   }
//
//   // apply entity to selection
//   const nextEditorState = EditorState.push(editorState, nextContentState);
//
//   debugger;
//
//   return nextEditorState;
// };

// contentState.getEntityMap() method doesn't return a Map of entities as doc says. But it returns a list of methods
// to work with entity (pure fuctionality). As a result, we couldn't find the right entity.
// https://github.com/facebook/draft-js/issues/1264
//  export default ({ editorState }) => {
//   const contentState = editorState.getCurrentContent();
//   const selection = editorState.getSelection();
//
//   // get entity next color
//   const startKey = selection.getStartKey();
//   const startOffset = selection.getStartOffset();
//   const blockWithEntity = contentState.getBlockForKey(startKey);
//   const entityKey = blockWithEntity.getEntityAt(startOffset);
//
//   let nextEntityKey = null;
//   let nextEntityInstance = null;
//   let nextTextColor = TEXT_COLOR.default;
//
//   // get next text
//   if (!isBlank(entityKey)) {
//     const entityInstance = contentState.getEntity(entityKey);
//     const { textColor } = entityInstance.getData();
//     nextTextColor = TEXT_COLOR[textColor];
//   }
//
//   // get next entity/create entity
//   const entityValues = contentState.getEntityMap();
//   nextEntityInstance = entityValues.find((key value) => value.type == 'color' && value.data.textColor == nextTextColor);
//
//   debugger;
//
//   if (isBlank(nextEntityInstance)) {
//     nextEntityInstance = contentState.createEntity(COLOR, 'IMMUTABLE', { textColor: nextTextColor });
//     nextEntityKey = contentState.getLastCreatedEntityKey();
//   } else {
    // nextEntityKey = entityValues.findKey(entity => entity.type == 'color' && entity.data.textColor == nextTextColor);
//   }
//
//   // apply entity to selection
//   const nextContentState = Modifier.applyEntity(contentState, selection, nextEntityKey);
//   const nextEditorState = EditorState.push(editorState, nextContentState, 'apply-entity');
//
//   debugger;
//
//   return nextEditorState;
// };
