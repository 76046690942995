// Core
import React from 'react';
import PropTypes from 'prop-types';

// components
import UploadMenuList from 'containers/Header/UploadBlock/UploadMenu/UploadMenuList';

// Styles and Assets
import 'components/DropDown/DropDown.scss';
import './UploadMenu.scss';

// Other
import { FormattedMessage } from 'react-intl';
import { messages } from 'containers/Header/UploadBlock/UploadMenu/messages';

const UploadMenu = ({
  uploadingItems,
  progress,
  handleCartItemClick,
  handleMenuToggle,
}) => {
  return (
    <div className="sk-upload-menu__wrapper">
      {uploadingItems?.length ? (
        <UploadMenuList
          progress={progress}
          uploadingItems={uploadingItems}
          handleCartItemClick={handleCartItemClick}
          handleMenuToggle={handleMenuToggle}
        />
      ) : (
        <p className="sk-upload-menu__empty">
          <FormattedMessage {...messages.uploadBasketEmpty} />
        </p>
      )}
    </div>
  );
};

export default UploadMenu;
