// Core
import React from 'react';
import { Helmet } from 'react-helmet';

// Other
import { EMAIL_SUPPORT } from 'utils/enums';
const { BASE_URL } = process.env;

const OrganizationLinkedData = () => {
  const structuredData = {
    '@context': 'http://schema.org',
    '@type': 'Organization',
    url: BASE_URL,
    logo:
      'https://skilleton-assets.s3.eu-central-1.amazonaws.com/skilleton-1x1.png',
    contactPoint: [
      {
        '@type': 'ContactPoint',
        url: BASE_URL,
        email: EMAIL_SUPPORT,
        contactType: 'customer service',
      },
    ],
  };
  return (
    <Helmet>
      <script key="Organization" type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
    </Helmet>
  );
};

export default OrganizationLinkedData;
