import React from 'react';

const Cookie = () => (
  <svg className="sk-cookie-banner__image" width="137" height="126" viewBox="0 0 137 126" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M44.1893 90.3063C68.5943 90.3063 88.3785 70.4748 88.3785 46.0115C88.3785 21.5482 68.5943 1.7168 44.1893 1.7168C19.7842 1.7168 0 21.5482 0 46.0115C0 70.4748 19.7842 90.3063 44.1893 90.3063Z" fill="#D69D65"/>
    <g opacity="0.45">
      <path opacity="0.26" d="M110.973 64.7901C106.754 64.7901 103.251 61.3497 103.251 57.0492C103.251 56.9059 103.251 56.7625 103.251 56.6192C100.248 55.5441 98.1025 52.6771 98.1025 49.3801C98.1025 48.0183 98.46 46.7998 99.0321 45.653C96.4579 44.4346 94.6703 41.7826 94.6703 38.7006C94.6703 38.0556 94.7418 37.4822 94.8848 36.9088C91.0952 35.8336 87.0194 35.2603 82.8722 35.2603C58.561 35.2603 38.8975 54.9707 38.8975 79.34C38.8975 103.709 58.6325 123.348 82.9437 123.348C107.255 123.348 126.919 103.638 126.919 79.2683" fill="#444444"/>
    </g>
    <path d="M45.619 89.5895C70.024 89.5895 89.8082 69.758 89.8082 45.2947C89.8082 20.8314 70.024 1 45.619 1C21.2139 1 1.42969 20.8314 1.42969 45.2947C1.42969 69.758 21.2139 89.5895 45.619 89.5895Z" stroke="#444444" strokeMiterlimit="10"/>
    <path d="M125.131 70.8825C121.699 70.8825 118.767 68.5889 117.766 65.4352C116.551 66.2953 115.049 66.797 113.476 66.797C109.257 66.797 105.754 63.3567 105.754 59.0562C105.754 58.9129 105.754 58.7695 105.754 58.6262C102.751 57.551 100.605 54.6841 100.605 51.3871C100.605 50.0252 100.963 48.8068 101.535 47.66C98.9608 46.4415 97.1733 43.7896 97.1733 40.7076C97.1733 40.0625 97.2448 39.4891 97.3878 38.9157C93.5981 37.8406 89.5224 37.2672 85.3752 37.2672C61.0639 37.2672 41.4004 56.9776 41.4004 81.3469C41.4004 105.716 61.0639 125.427 85.3752 125.427C109.686 125.427 129.35 105.716 129.35 81.3469C129.35 77.5482 128.849 73.8928 127.991 70.3807C127.133 70.6674 126.204 70.8825 125.131 70.8825Z" fill="#DAA669"/>
    <path d="M126.562 70.8825C123.13 70.8825 120.198 68.5889 119.197 65.4352C117.981 66.2953 116.48 66.797 114.907 66.797C110.688 66.797 107.184 63.3567 107.184 59.0562C107.184 58.9129 107.184 58.7695 107.184 58.6262C104.181 57.551 102.036 54.6841 102.036 51.3871C102.036 50.0252 102.394 48.8068 102.966 47.66C100.392 46.4415 98.6039 43.7896 98.6039 40.7076C98.6039 40.0625 98.6754 39.4891 98.8184 38.9157C95.0287 37.8406 90.953 37.2672 86.8058 37.2672C62.4946 37.2672 42.8311 56.9776 42.8311 81.3469C42.8311 105.716 62.4946 125.427 86.8058 125.427C111.117 125.427 130.781 105.716 130.781 81.3469C130.781 77.5482 130.28 73.8928 129.422 70.3807C128.564 70.6674 127.634 70.8825 126.562 70.8825Z" stroke="#444444" strokeMiterlimit="10"/>
    <path d="M92.2396 54.469C92.2396 58.1244 88.7359 61.063 84.3742 61.063C80.0125 61.063 76.5088 59.9162 76.5088 56.2608C76.5088 52.6054 80.084 49.4518 84.3742 48.735C90.595 47.7316 92.2396 50.8136 92.2396 54.469Z" fill="#30d5c8"/>
    <path d="M116.408 95.8251C116.408 98.7637 113.619 101.129 110.116 101.129C106.612 101.129 103.823 100.197 103.823 97.2586C103.823 94.3199 106.683 91.7396 110.116 91.2379C115.121 90.3778 116.408 92.8864 116.408 95.8251Z" fill="#875CEB"/>
    <path d="M87.3776 88.2992C85.6615 91.2378 81.4428 91.9546 78.0106 89.876C74.5785 87.7975 72.2903 85.2172 74.0064 82.2785C75.7225 79.3399 80.0842 78.4798 83.8024 79.985C89.2367 82.1352 89.0937 85.3605 87.3776 88.2992Z" fill="#30d5c8"/>
    <path d="M76.3662 112.31C74.5071 112.095 73.1486 110.16 73.4346 107.938C73.6491 105.716 74.4356 103.996 76.2947 104.211C78.1538 104.426 79.5839 106.361 79.7269 108.583C79.8699 111.808 78.2253 112.453 76.3662 112.31Z" fill="#30d5c8"/>
    <path d="M97.7456 73.2477C96.673 73.1043 95.815 71.9575 95.958 70.6674C96.101 69.3772 96.53 68.3021 97.6741 68.4455C98.7466 68.5888 99.6047 69.7356 99.6762 71.0974C99.8192 72.961 98.8181 73.391 97.7456 73.2477Z" fill="#30d5c8"/>
    <path d="M64.0679 99.6954C62.9953 99.5521 62.1373 98.4053 62.2803 97.1151C62.4233 95.825 62.8523 94.7499 63.9964 94.8932C65.0689 95.0366 65.927 96.1834 65.9985 97.5452C66.1415 99.4087 65.1404 99.8388 64.0679 99.6954Z" fill="#875CEB"/>
    <path d="M56.5596 81.2035C56.5596 83.4254 54.772 86.7224 52.4839 86.7224C50.1958 86.7224 48.4082 83.4971 48.4082 81.2035C48.4082 78.9099 50.1958 77.118 52.4839 77.118C54.772 77.118 56.5596 78.9816 56.5596 81.2035Z" fill="#30d5c8"/>
    <path d="M65.0685 56.8342C65.0685 57.981 63.8529 59.6295 62.2799 59.6295C60.7068 59.6295 59.4912 57.981 59.4912 56.8342C59.4912 55.6874 60.7068 54.7556 62.2799 54.7556C63.8529 54.7556 65.0685 55.6874 65.0685 56.8342Z" fill="#875CEB"/>
    <path d="M94.2416 54.5407C94.2416 58.1961 90.7379 61.1348 86.3761 61.1348C82.0144 61.1348 78.5107 59.988 78.5107 56.3326C78.5107 52.6772 82.0859 49.5235 86.3761 48.8068C92.597 47.8034 94.2416 50.8854 94.2416 54.5407Z" stroke="#444444" strokeMiterlimit="10"/>
    <path d="M118.41 95.8969C118.41 98.8355 115.621 101.201 112.118 101.201C108.614 101.201 105.825 100.269 105.825 97.3304C105.825 94.3917 108.685 91.8115 112.118 91.3097C117.051 90.5213 118.41 92.9582 118.41 95.8969Z" stroke="#444444" strokeMiterlimit="10"/>
    <path d="M89.3799 88.3711C87.6638 91.3097 83.4451 92.0265 80.0129 89.9479C76.5092 87.941 74.2926 85.2891 76.0087 82.3504C77.7248 79.4118 82.0865 78.5517 85.8047 80.0569C91.239 82.2071 91.096 85.4324 89.3799 88.3711Z" stroke="#444444" strokeMiterlimit="10"/>
    <path d="M78.3682 112.382C76.5091 112.167 75.1505 110.232 75.4365 108.01C75.651 105.788 76.4376 104.068 78.2967 104.283C80.1558 104.498 81.5859 106.433 81.7289 108.655C81.8719 111.88 80.2273 112.597 78.3682 112.382Z" stroke="#444444" strokeMiterlimit="10"/>
    <path d="M99.6763 73.3194C98.6037 73.1761 97.7457 72.0293 97.8887 70.7392C98.0317 69.449 98.4607 68.3739 99.6048 68.5173C100.677 68.6606 101.535 69.8074 101.607 71.1692C101.75 73.0327 100.82 73.4628 99.6763 73.3194Z" stroke="#444444" strokeMiterlimit="10"/>
    <path d="M66.0698 99.8388C64.9973 99.6955 64.1392 98.5487 64.2822 97.2586C64.4252 95.9684 64.8543 94.8933 65.9983 95.0367C67.0709 95.18 67.9289 96.3268 68.0004 97.6886C68.1434 99.4805 67.1424 99.9105 66.0698 99.8388Z" stroke="#444444" strokeMiterlimit="10"/>
    <path d="M58.5616 81.2753C58.5616 83.4972 56.774 86.7942 54.4859 86.7942C52.2692 86.7942 50.4102 83.5688 50.4102 81.2753C50.4102 79.0534 52.1977 77.1898 54.4859 77.1898C56.7025 77.2615 58.5616 79.0534 58.5616 81.2753Z" stroke="#444444" strokeMiterlimit="10"/>
    <path d="M66.9992 56.9059C66.9992 58.0527 65.7836 59.7012 64.2105 59.7012C62.7089 59.7012 61.4219 58.0527 61.4219 56.9059C61.4219 55.7592 62.6374 54.8274 64.2105 54.8274C65.7836 54.8991 66.9992 55.7592 66.9992 56.9059Z" stroke="#444444" strokeMiterlimit="10"/>
    <path d="M22.0226 29.2397C20.3065 32.1784 16.7313 33.2535 14.0857 31.6766C11.4401 30.0998 9.79549 27.8779 11.5116 24.9393C13.2277 22.0006 16.9459 20.7821 19.8775 21.7856C24.1677 23.2907 23.7387 26.3011 22.0226 29.2397Z" fill="#30d5c8"/>
    <path d="M28.8157 68.9472C27.0996 71.8859 23.3814 72.8893 20.5212 71.2408C17.6611 69.5923 15.8735 67.227 17.5896 64.2884C19.3057 61.3497 23.1669 60.203 26.313 61.2781C30.8893 62.8549 30.5318 65.9369 28.8157 68.9472Z" fill="#30d5c8"/>
    <path d="M46.2627 41.926C44.6897 44.6496 41.5435 45.7247 39.3269 44.4346C37.0388 43.0728 35.7517 41.1376 37.3248 38.4856C38.8979 35.762 42.1155 34.5435 44.6897 35.332C48.2649 36.4787 47.8358 39.2024 46.2627 41.926Z" fill="#30d5c8"/>
    <path d="M53.6988 20.7104C51.3392 24.7242 46.5484 26.1577 42.9017 24.0075C39.255 21.8572 38.4685 19.5637 40.8281 15.5499C43.1877 11.5361 46.7629 9.17088 50.7671 10.5327C56.6304 12.6113 56.0584 16.7684 53.6988 20.7104Z" fill="#875CEB"/>
    <path d="M18.3044 47.445C17.2319 49.3086 14.8722 49.8819 13.0846 48.8068C11.297 47.7317 10.153 46.2982 11.2255 44.4347C12.2981 42.5712 14.7292 41.8544 16.7313 42.5712C19.5915 43.718 19.377 45.6532 18.3044 47.445Z" fill="#875CEB"/>
    <path d="M76.8659 26.5877C75.7934 28.4513 73.4338 29.0246 71.6462 27.9495C69.8586 26.8744 68.7145 25.4409 69.7871 23.5774C70.8596 21.7139 73.2908 20.9971 75.2929 21.7139C78.153 22.8607 77.9385 24.7959 76.8659 26.5877Z" fill="#30d5c8"/>
    <path d="M22.0226 30.5299C20.3065 33.4685 16.7313 34.5436 14.0857 32.9668C11.4401 31.39 9.79549 29.1681 11.5116 26.2294C13.2277 23.2908 16.9459 22.0723 19.8775 23.0757C24.1677 24.5809 23.7387 27.5912 22.0226 30.5299Z" stroke="#444444" strokeMiterlimit="10"/>
    <path d="M28.7434 70.1657C27.0273 73.1044 23.3091 74.1078 20.449 72.4593C17.5888 70.8108 15.8012 68.4455 17.5173 65.5069C19.2334 62.5682 23.0946 61.4215 26.2408 62.4966C30.817 64.1451 30.4595 67.2271 28.7434 70.1657Z" stroke="#444444" strokeMiterlimit="10"/>
    <path d="M46.1905 43.2162C44.6174 45.9398 41.4712 47.0149 39.2546 45.7248C36.9665 44.3629 35.6794 42.4277 37.2525 39.7758C38.8256 37.0522 42.0433 35.8337 44.6174 36.6221C48.2641 37.7689 47.7636 40.4925 46.1905 43.2162Z" stroke="#444444" strokeMiterlimit="10"/>
    <path d="M53.6988 22.0006C51.3392 26.0144 46.5484 27.4479 42.9017 25.2976C39.255 23.1474 38.4685 20.8538 40.8281 16.8401C43.1877 12.8263 46.7629 10.461 50.7671 11.8229C56.5589 13.9014 55.9869 17.9868 53.6988 22.0006Z" stroke="#444444" strokeMiterlimit="10"/>
    <path d="M18.3044 48.7352C17.2319 50.5987 14.8722 51.1721 13.0846 50.097C11.297 49.0219 10.153 47.5884 11.2255 45.7249C12.2981 43.8613 14.7292 43.1446 16.7313 43.8613C19.5915 44.9364 19.377 46.8716 18.3044 48.7352Z" stroke="#444444" strokeMiterlimit="10"/>
    <path d="M76.7946 27.8779C75.7221 29.7414 73.3625 30.3148 71.5749 29.2397C69.7873 28.1646 68.6432 26.7311 69.7158 24.8676C70.7883 23.004 73.2195 22.2873 75.2216 23.004C78.0817 24.0791 77.8672 26.0143 76.7946 27.8779Z" stroke="#444444" strokeMiterlimit="10"/>
    <path d="M110.974 50.8136C112.261 52.2471 112.118 51.6737 113.262 53.1072C114.406 54.5407 113.405 57.551 110.616 56.6909C107.827 55.8308 106.254 53.1789 108.042 51.5304C109.758 49.8102 110.974 50.8136 110.974 50.8136Z" fill="#DAA669"/>
    <path d="M112.332 41.7111C112.976 42.4278 112.904 42.1411 113.476 42.8578C114.048 43.5746 113.548 45.0797 112.118 44.6497C110.688 44.2197 109.973 42.8578 110.831 42.0694C111.689 41.281 112.332 41.7111 112.332 41.7111Z" fill="#DAA669"/>
    <path d="M124.13 51.1004C123.201 52.7489 122.772 51.5304 121.842 53.1072C120.913 54.6841 117.695 54.6841 117.623 51.7454C117.552 48.8068 120.412 47.3733 122.557 48.5201C124.631 49.6669 124.13 51.1004 124.13 51.1004Z" fill="#DAA669"/>
    <path d="M126.919 58.9845C128.349 60.418 126.848 60.418 128.206 61.8515C129.493 63.285 128.349 66.2953 125.132 65.4352C121.914 64.5751 121.485 61.3498 123.487 59.7012C125.489 58.0527 126.919 58.9845 126.919 58.9845Z" fill="#DAA669"/>
    <path d="M133.64 52.3905C134.141 52.8923 133.64 52.8923 134.069 53.394C134.498 53.8957 134.141 54.8991 132.997 54.6124C131.924 54.3257 131.781 53.1789 132.425 52.6772C133.14 52.0322 133.64 52.3905 133.64 52.3905Z" fill="#DAA669"/>
    <path d="M106.612 44.9364C105.969 45.2231 106.183 44.7214 105.54 45.0081C104.896 45.2231 104.11 44.5063 104.825 43.5746C105.54 42.6428 106.612 42.9295 106.827 43.7896C107.113 44.6497 106.612 44.9364 106.612 44.9364Z" fill="#DAA669"/>
    <path d="M112.403 50.957C113.69 52.3904 113.547 51.817 114.691 53.2505C115.835 54.684 114.834 57.6943 112.046 56.8342C109.257 55.9742 107.684 53.3222 109.472 51.6737C111.188 49.9535 112.403 50.957 112.403 50.957Z" stroke="#444444" strokeMiterlimit="10"/>
    <path d="M113.762 41.8544C114.405 42.5711 114.334 42.2844 114.906 43.0012C115.478 43.7179 114.978 45.2231 113.547 44.793C112.117 44.363 111.402 43.0012 112.26 42.2127C113.118 41.4243 113.762 41.8544 113.762 41.8544Z" stroke="#444444" strokeMiterlimit="10"/>
    <path d="M125.56 51.2437C124.63 52.8922 124.201 51.6737 123.272 53.2506C122.342 54.8274 119.125 54.8274 119.053 51.8887C118.982 48.9501 121.842 47.5166 123.987 48.6634C126.06 49.7385 125.56 51.2437 125.56 51.2437Z" stroke="#444444" strokeMiterlimit="10"/>
    <path d="M128.349 59.0562C129.779 60.4896 128.277 60.4896 129.636 61.9231C130.923 63.3566 129.779 66.3669 126.561 65.5068C123.344 64.6468 122.915 61.4214 124.917 59.7729C126.919 58.1244 128.349 59.0562 128.349 59.0562Z" stroke="#444444" strokeMiterlimit="10"/>
    <path d="M135.07 52.4622C135.571 52.9639 135.07 52.9639 135.499 53.4656C135.928 53.9673 135.571 54.9708 134.427 54.6841C133.283 54.3974 133.211 53.2506 133.855 52.7489C134.57 52.1038 135.07 52.4622 135.07 52.4622Z" stroke="#444444" strokeMiterlimit="10"/>
    <path d="M108.042 45.0797C107.398 45.3664 107.613 44.8647 106.969 45.1514C106.326 45.3664 105.539 44.6497 106.254 43.7179C106.969 42.7861 108.042 43.0728 108.256 43.9329C108.542 44.7213 108.042 45.0797 108.042 45.0797Z" stroke="#444444" strokeMiterlimit="10"/>
  </svg>
);

export default Cookie;
