import React, { useEffect, useState, createElement } from 'react';
import { compose } from 'react-apollo';
import { injectIntl } from 'react-intl';
import { parse } from 'html-parse-stringify';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

const enhancer = compose(
  injectIntl
);

export const withFormattedHtmlMessage = WrappedComponent => {
  const HOC = ({ intl, ...props }) => {
    // const parser = new DOMParser();
    const formatHtmlMessage = (id, values) => {
      const stringHtml = intl.formatMessage(id, values);
      const reactChild = ReactHtmlParser(stringHtml);

      return reactChild;
    }

    return <WrappedComponent formatHtmlMessage={formatHtmlMessage} intl={intl} {...props} />
  }

  return enhancer(HOC);
};
