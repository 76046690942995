import React from 'react';
import classnames from 'classnames';

// Components
import SwipeButton from 'components/SwipeButton';

// Styles
import './index.scss';
import { NetflixSwipeButton } from '../../../../routes/LandingPage/components/NetflixSwipeButton';

const BUTTONS_STYLES = {
  detail: 'slider-button-detail',
  prev: 'slider-button-prev',
  next: 'slider-button-next',
};

const SliderButton = ({ onClick, type, isOpen }) => (
  <div className={classnames(BUTTONS_STYLES[type], {'opened': isOpen})} onClick={onClick}>
    <NetflixSwipeButton direction={type} color='primary' />
  </div>
);

export default SliderButton;
