// Core
import React, { memo, useState, useEffect, useCallback } from 'react';
import { graphql, compose } from 'react-apollo';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';

// Components
import ContentHeader from 'containers/ContentHeader';
import Breadcrumbs from 'components/Breadcrumbs';
import ActiveSubsctiption from 'routes/ExpertsCabinet/ExpertSubscription/ActiveSubsctiption';
import ActiveCommission from 'routes/ExpertsCabinet/ExpertSubscription/ActiveCommission';
import ExpiredSubscription from 'routes/ExpertsCabinet/ExpertSubscription/ActiveSubsctiption/ExpiredSubscription';

// HOCs
// import { withSidebar } from 'containers/SidebarProvider/withSidebar';
import { withLoader } from 'containers/HOCs/withLoader';

// GraphQL
import CANCEL_SUBSCRIPTION from 'mutations/Subscriptions/cancelSubscription.gql';

// Other
import { isBlank } from 'utils/helpers';
import { sharedMessages } from 'services/i18n/sharedMessages/messages';
import { withAuthenticatedUser } from 'containers/AuthenticatedUserProvider/withAuthenticatedUser';

// Styles and Assets
import './index.scss';

const prefix = 'sk-experts-cabinet-subscription';
export const ExpertSubscription = ({
  userContext,
  cancelSubscription,
  history
}) => {
  const { subscription, totalActiveCourses } = userContext?.profile;


  let Component = null;

  if (subscription?.type == 'commission') {
    Component = ActiveCommission;
  } else if (subscription?.type == 'package' || subscription?.type == 'constructor') {
    Component = ActiveSubsctiption;
  };

  if (!isBlank(Component)) {
    return (
      <div className={prefix}>
        <ContentHeader way={<Breadcrumbs />} />
        <Component
          totalActiveCourses={totalActiveCourses}
          subscription={subscription}
          cancelSubscription={cancelSubscription}
        />
      </div>
    );
  };

  return (
    <ExpiredSubscription title='' />
  );
};

const cancelSubscriptionMutation = graphql(CANCEL_SUBSCRIPTION, {
  props: ({ mutate }) => ({
    cancelSubscription: values => mutate()
  }),
  options: ({history}) => ({
    refetchQueries: ['getProfile'],
    awaitRefetchQueries: true,
   })
});

const enhancer = compose(
  memo,
  injectIntl,
  // withSidebar,
  withRouter,
  withAuthenticatedUser,
  cancelSubscriptionMutation,
  withLoader
);

export default enhancer(ExpertSubscription);
