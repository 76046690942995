// Core
import React from 'react';

// Components
import Heading from 'components/Heading';
import Image from 'components/Image';

// Styles and Assets
import './NoCourses.scss';

// Other
import { FormattedMessage } from 'react-intl';
import { messages } from 'components/NoCourses/messages';

const NoCourses = () => (
  <div className="sk-no-courses">
    <Heading variant="h2" newLine classes="sk-no-courses__heading">
      <FormattedMessage {...messages.noCourses} />
    </Heading>
    <Image className="sk-no-courses" src={'/assets/images/no-courses.svg'} alt="No Courses" />
  </div>
);

export default NoCourses;
