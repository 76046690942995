// Core
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';

// Components
import StatusChangedModal from 'routes/Course/components/StatusChangedModal';

// GraphQL
import NOTIFY_ABOUT_COURSE_STATUS_CHANGE from 'mutations/Mailing/notifyAboutCourseStatusChange.gql';
import UPDATE_COURSE_STATUS from 'mutations/Courses/updateCourseStatus.gql';

// Other
import { withNotification } from 'containers/NotificationProvider/withNotification';
import { withModal } from 'containers/ModalProvider/withModal';
import { withSharedState } from 'containers/SharedStateProvider/withSharedState';
// import { COURSE_NEXT_STATUSES_EXPERT } from 'utils/enums';
import { keysToCamel, keysToSnake, } from 'utils/helpers';

const updateCourseStatusMutation = graphql(UPDATE_COURSE_STATUS, {
  props: ({ mutate }) => ({
    updateCourseStatus: input => mutate({ variables: { input } }),
  }),
  options: () => ({
    awaitRefetchQueries: true,
    refetchQueries: ['getCourse','listExpertCourses', 'getProfile'],
  })
});

const notifyAboutCourseStatusChange = graphql(
  NOTIFY_ABOUT_COURSE_STATUS_CHANGE,
  {
    props: ({ mutate }) => ({
      notifyAboutCourseStatusChange: input => mutate({ variables: { input } }),
    }),
    options: () => ({
      refetchQueries: ['getCourse'],
    })
  }
);

const enhancer = compose(
  withNotification,
  withModal,
  updateCourseStatusMutation,
  notifyAboutCourseStatusChange,
  withSharedState
);

export const withCourseStatusManager = (WrappedComponent) => {
  class HOC extends PureComponent {
    state = {
      isCourseStatusUpdating: false,
    };

    setCourseStatusUpdatingState = nextState => {
      const { sharedStateContext: { updateSharedState }} = this.props;

      this.setState(prevState => ({ isCourseStatusUpdating: nextState }));
      updateSharedState({ isCourseStatusUpdating: nextState });
    }

    handleCourseStatusUpdate = async (nextStatus, isAdmin, type) => {
      this.setCourseStatusUpdatingState(true);
      const {
        updateCourseStatus,
        course,
        modalContext: { showModal, hideModal },
      } = this.props;
      const { id: courseId, author, statusId: prevStatus } = keysToCamel(course);

      try {
        const input = keysToSnake({ courseId, nextStatus, });
        const recipientId = isAdmin ? 'eu-central-1:21d47638-0367-442a-9ee1-efcee741bd92' : author?.id;
        const notifyInput = { prevStatus, nextStatus, recipientId, courseId };

        await updateCourseStatus(input);
        await this.handleMailSend(notifyInput);

        const withModal = ['FIRST_REVIEW', 'FINAL_REVIEW', 'ACTIVE', 'REACTIVATED', 'DEACTIVATED'];
        if (withModal.includes(nextStatus)) showModal(StatusChangedModal, {
          status: nextStatus,
          handleContinueClick: () => hideModal(),
          size: 'sm',
          type: type
        });

        this.setCourseStatusUpdatingState(false);
      } catch (error) {
        this.setCourseStatusUpdatingState(false);
        throw Error(error);
      }
    };

    handleMailSend = async ({ prevStatus, nextStatus, recipientId, courseId }) => {
      const { notifyAboutCourseStatusChange, } = this.props;

      const input = {
        prevStatus,
        nextStatus,
        recipientId,
        courseId,
      };

      await notifyAboutCourseStatusChange(input);
    };

    render() {
      const { course, children } = this.props;
      const { isCourseStatusUpdating } = this.state;

      return <WrappedComponent {...this.props}
        isCourseStatusUpdating={isCourseStatusUpdating}
        handleCourseStatusUpdate={this.handleCourseStatusUpdate}
      />;
    }
  }

  return enhancer(HOC);
}
