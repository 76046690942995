import React from 'react';
import { PurpleHeart, Red } from 'components/Icon/color';

const Check = ({
  height = 9,
  width = 12,
  active = false,
  stroke = active ? PurpleHeart : Red }
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 12 9"
    width={width}
    height={height}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fillRule="nonzero"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M1 3.333L5 8l6-7"
      />
    </g>
  </svg>
);

export default Check;
