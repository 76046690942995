import React from 'react';

import './index.scss';

const IconOnHover = () => (
  <svg class='filled' width="18" height="7" viewBox="0 0 18 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="18" height="2" rx="1" fill="#7145D8"/>
    <rect y="5" width="18" height="2" rx="1" fill="#7145D8"/>
  </svg>
);

const IconDefault = () => (
  <svg class='outlined' width="18" height="7" viewBox="0 0 18 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="18" height="2" rx="1" fill="#C6C6C6"/>
    <rect y="5" width="18" height="2" rx="1" fill="#C6C6C6"/>
  </svg>
);

const Icon = () => {
  return (
    <div className="quiz-icon">
      <IconDefault />
      <IconOnHover />
    </div>
  );
};

export default Icon;
