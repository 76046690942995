import React from 'react';
import PropTypes from 'prop-types';
import { SidebarDefault, Grey, White } from 'components/Icon/color';

const themes = {
  default: (mode) => ({
    fill: getColor(mode, White, SidebarDefault),
    stroke: getColor(mode, Grey, SidebarDefault),
  }),
  orange: (mode) => ({
    fill: getColor(mode, White, Grey),
    stroke: Grey,
  })
}

const getColor = (active, color1, color2) => active ? color1 : color2;

const Question = ({ theme = 'default', height=17, width=17, active=false }) => {
  const { fill, stroke, } = themes[theme](active);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
    >
      <defs>
        <rect
          id={`question_icon-b-${active}`}
          width={width}
          height={height}
          rx={width / 2}
        />
        <filter
          id={`question_icon-a-${active}`}
          width="300%"
          height="300%"
          x="-100%"
          y="-76.5%"
          filterUnits="objectBoundingBox"
        >
          <feOffset dy="4" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="5"
          />
          <feComposite
            in="shadowBlurOuter1"
            in2="SourceAlpha"
            operator="out"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 1   0 0 0 0 0.364705882   0 0 0 0 0.274509804  0 0 0 0.01 0"
          />
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd">
        <use
          fill={Grey}
          filter="url(#question_icon-a-true)"
          xlinkHref="#question_icon-a-true"
        />
        <rect
          width={width * 0.94}
          height={height * 0.94}
          x=".5"
          y=".5"
          fill={active ? Grey : White}
          stroke={stroke}
          strokeLinejoin="square"
          rx={width * 0.47}
        />
        <text
          fill={fill}
          fontFamily="Roboto, sans-serif"
          fontSize={height * 0.64}
          fontWeight="bold"
          letterSpacing=".688"
        >
          <tspan x={width * 0.35} y={height * 0.7}>
            ?
          </tspan>
        </text>
      </g>
    </svg>
)};

export default Question;
