import React, { memo } from 'react';
import { compose } from 'react-apollo';
import { injectIntl } from 'react-intl';

// Components
import Button from 'components/Button';
import Link from 'components/Link';
import Image from 'components/Image';
import Text from 'components/Text';
import Warning from 'routes/ExpertsCabinet/ExpertSubscription/ActiveSubsctiption/icons/Warning.js';

// Other
import { isBlank } from 'utils/helpers';
import { withAuthenticatedUser } from 'containers/AuthenticatedUserProvider/withAuthenticatedUser';
import { messages } from 'components/Button/messages.js';
import { withLanguage } from 'containers/LanguageProvider/withLanguage';

//Styles
import './index.scss';

const PREFIX = 'sk-button-expert-plans';
const ExpertPlansButton = ({
  size = 'medium',
  userContext: { profile },
  intl: { formatMessage },
  languageContext: { language = 'en' },
  ...props
}) => {
  const hasExpertSubscription = !isBlank(profile?.subscription) && (profile?.subscription?.status === 'active' || profile?.subscription?.status == 'expired');
  const isSubscriptionExpired = !isBlank(profile?.subscription) && profile?.subscription?.status === 'expired' && profile?.subscription?.type !== 'commission';
  const path = hasExpertSubscription && profile?.subscription?.planId ? `/experts-cabinet/subscription/${profile?.subscription?.planId}` : '/experts-cabinet/subscription';

  const subscriptionImagePath =  (!isBlank(profile?.subscription) && profile?.subscription?.type === 'commission') ?
    '/assets/images/buy-commission.svg' : '/assets/images/buy-subscription.svg';

  return (
    <Link classes={PREFIX} to={path}>
      {hasExpertSubscription ? (
        <div className={`${PREFIX}__subscription-details`}>
          {isSubscriptionExpired ? (
            <Warning width={41} height={41} />) : (
            <Image src={subscriptionImagePath} />
          )}
          <Text>{formatMessage(messages.subscriptionBase)} {'\n'} {profile?.subscription?.plan?.title[language]}</Text>
        </div>
      ) : (
        <Button
          style={{ marginTop: 0 }}
          size={size}
          color="primary"
          transparent={true}
        >
          {formatMessage(messages.expertSubscriptionsButton)}
        </Button>
      )}
    </Link>
  );
};

const enhancer = compose(
  memo,
  injectIntl,
  withAuthenticatedUser,
  withLanguage
);

export default enhancer(ExpertPlansButton);
