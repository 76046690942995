import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  activeCourses: {
    id: 'app.components.StudentActiveCoursesList.activeCourses',
    defaultMessage: 'Active Courses',
  },
  homework: {
    id: 'app.components.StudentActiveCoursesList.homework',
    defaultMessage: 'Homework for Concierge level',
  },
  edit: {
    id: 'app.components.StudentActiveCoursesList.edit',
    defaultMessage: 'Edit',
  },
  signOut: {
    id: 'app.components.FlatDropDownMenu.signOut',
    defaultMessage: 'Sign Out',
  },
});
