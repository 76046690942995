import React from 'react';
import { injectIntl } from 'react-intl';

// Components
import Text from 'components/Text';

//Other
import { messages } from 'components/QuizPreview/messages.js';

// Styles
import './index.scss';

const QuizPreview = ({
  score,
  intl: { formatMessage }
}) => {
  return (
    <div className='sk-quiz-score'>
      <Text>{formatMessage(messages.quizYourMark)}</Text>
      <Text>{score}%</Text>
    </div>
  );
};

export default injectIntl(QuizPreview);
