import React from 'react';
import PropTypes from 'prop-types';

const FullScreen = ({ isFullScreen }) => {
  if (!isFullScreen) {
    return (
      <svg
        className="sk-video-controllers_icon"
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 0.571467V4.5717H1.14293V1.14293H4.5717V0H0.571467C0.255589 0 0 0.255589 0 0.571467H0Z"
          fill="white"
        />
        <path
          d="M15.4319 0H11.4316V1.14293H14.8604V4.5717H16.0033V0.571467C16.0033 0.255589 15.7478 0 15.4319 0V0Z"
          fill="white"
        />
        <path
          d="M14.8604 14.8545H11.4316V15.9975H15.4319C15.7478 15.9975 16.0033 15.7419 16.0033 15.426V11.4258H14.8604V14.8545Z"
          fill="white"
        />
        <path
          d="M1.14293 11.4258H0V15.426C0 15.7419 0.255589 15.9975 0.571467 15.9975H4.5717V14.8545H1.14293V11.4258Z"
          fill="white"
        />
      </svg>
    );
  }
  return (
    <svg
      className="sk-video-controllers_icon"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.99609 4.71358L5.99609 0.00168085L4.64983 0.00168073L4.64982 4.04045L0.611056 4.04045L0.611056 5.38672L5.32296 5.38672C5.69503 5.38672 5.99609 5.08566 5.99609 4.71358V4.71358Z"
        fill="white"
      />
      <path
        d="M10.7061 5.40625L15.418 5.40625L15.418 4.05998L11.3792 4.05998L11.3792 0.0212117L10.0329 0.0212116L10.0329 4.73311C10.0329 5.10519 10.334 5.40625 10.7061 5.40625V5.40625Z"
        fill="white"
      />
      <path
        d="M11.3792 11.9612L11.3792 16L10.0329 16L10.0329 11.2881C10.0329 10.916 10.334 10.615 10.7061 10.615L15.418 10.615L15.418 11.9612L11.3792 11.9612Z"
        fill="white"
      />
      <path
        d="M4.64982 15.9961L5.99609 15.9961L5.99609 11.2842C5.99609 10.9121 5.69503 10.6111 5.32296 10.6111L0.611055 10.6111L0.611055 11.9573L4.64982 11.9573L4.64982 15.9961Z"
        fill="white"
      />
    </svg>
  );
};

FullScreen.propTypes = {
  isFullScreen: PropTypes.bool,
};

export default FullScreen;
