// Core
import React, { useState, useEffect, useCallback } from "react";
import { useMutation } from "@apollo/react-hooks";
import { useFormik } from "formik";

// GraphQL
import APPLY_DISCUNT from "mutations/Order/applyDiscount.gql";

// Other
import { getYupSchema } from "services/yup";

export function useDiscount({
  orderItems: initialOrderItems,
  totalPrice,
  draftOrderId,
  apolloClient,
  ...props
}) {
  const formikBag = useFormik({
    enableReinitialize: true,
    initialValues: { code: "" },
    displayName: "ApplyDiscountForm",
    validationSchema: () => getYupSchema("discountApplyValidationSchema"),
    validateOnChange: true,
    validateOnBlur: true,
  });

  const [totalPriceWithDiscount, setTotalPriceWithDiscount] = useState(
    totalPrice
  );
  const [orderItems, setOrderItems] = useState(initialOrderItems);
  const [discountPayload, setDiscountPayload] = useState(null);

  useEffect(() => {
    setOrderItems(initialOrderItems);
    setTotalPriceWithDiscount(totalPrice);
  }, [initialOrderItems, totalPrice]);

  const [applyDiscountMutation, response] = useMutation(APPLY_DISCUNT, {
    options: { fetchPolicy: "no-cache" },
  });

  const applyDiscount = useCallback(async () => {
    if (formikBag?.isValid) {
      const mutationResponse = await applyDiscountMutation({
        variables: {
          input: { code: formikBag?.values.code, order_id: draftOrderId },
        },
      });
      const isDiscountValid =
        mutationResponse?.data?.applyDiscount?.status_code === "APPLIED";
      if (isDiscountValid) {
        const {
          courses,
          order_total_with_discount,
          discount_id,
          redemptions,
        } = mutationResponse?.data?.applyDiscount;

        const coursesWithDiscount = orderItems.map((course) => {
          const foundCourse = courses.find(
            (foundCourse) => foundCourse.id === course.courseId
          );

          return {
            ...course,
            priceWithDiscount: foundCourse
              ? foundCourse.price_with_discount
              : undefined,
          };
        });
        setDiscountPayload({ discount_id, redemptions });
        setOrderItems(coursesWithDiscount);
        setTotalPriceWithDiscount(order_total_with_discount);
      } else if (!isDiscountValid) {
        // formikBag.setFieldTouched('code', true, false);
        formikBag.setErrors({ code: "app.errors.invalidPromocode" });
        return;
      }
    }
  }, [
    applyDiscount,
    formikBag.isValid,
    formikBag.values,
    formikBag.setFieldTouched,
    formikBag.setErrors,
  ]);

  return {
    formikBag,
    applyDiscount,
    discountPayload,
    orderItems,
    totalPriceWithDiscount,
  };
}
