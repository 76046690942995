import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  goToCoursePage: {
    id: 'app.components.PurchasedCoursesHero.goToCoursePage',
    defaultMessage: 'Go to Course Page',
  },
  courseStudyMats: {
    id: 'app.components.PurchasedCoursesHero.courseStudyMats',
    defaultMessage: 'Course Study Materials',
  },
});
