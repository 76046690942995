// Core
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';

// Components
import WebinarMic from 'components/Icon/Svg/WebinarMic';
import WebinarMicOff from 'components/Icon/Svg/WebinarMicOff';

// Other
import withAction from 'components/Icon/withAction';

export const WebinarMicButton = ({ handleClick, action, onAir }) => (
  <button type="button" onClick={handleClick}>
    {onAir ? <WebinarMic active={action} /> : <WebinarMicOff active={action} />}
  </button>
);

WebinarMicButton.propTypes = {
  handleClick: PropTypes.func,
  action: PropTypes.bool,
  onAir: PropTypes.bool,
};

const enhancer = compose(
  memo,
  withAction
);

export default enhancer(WebinarMicButton);
