// Core
import React, { memo, useCallback } from 'react';
import { compose } from 'react-apollo';
import { injectIntl } from 'react-intl';

// components
import CheckoutMenuList from 'containers/Header/CheckoutBlock/CheckoutMenu/CheckoutMenuList';
import Text from 'components/Text';
import Button from 'components/Button';

// Other
import { getTotalPrice } from 'utils/helpers';
import { useConvertedPrice } from 'containers/CurrencyProvider/useConvertedPrice';
import { withShoppingCart } from 'containers/ShoppingCartProvider/withShoppingCart';

// Styles and Assets
import 'components/DropDown/DropDown.scss';
import './index.scss';

// Other
import { messages } from 'containers/Header/CheckoutBlock/CheckoutMenu/messages';

const PREFIX = 'sk-checkout-menu';
const CheckoutMenu = ({
  checkoutItems,
  shoppingCartContext: {
    handleProceedClick,
    handleCartItemClick,
    handleRemoveCourseFromCart,
  },
  handleMenuToggle,
  closeToggle,
  intl: { formatMessage },
}) => {
  const goToCheckout = useCallback(() => {
    closeToggle();
    handleProceedClick('courses');
  }, [closeToggle, handleProceedClick]);
  const handleCheckoutItemClick = useCallback((courseId) => () => {
    handleCartItemClick(courseId)();
    handleMenuToggle();
  }, [handleCartItemClick, handleMenuToggle]);

  const totalPrice = checkoutItems ? getTotalPrice(checkoutItems) : 0;
  const parsedTotalPrice = useConvertedPrice({ price: totalPrice, isBeautyPrice: true });


  return (
    <div className={PREFIX}>
      {checkoutItems?.length ? (
        <CheckoutMenuList
          checkoutItems={checkoutItems}
          handleCartItemClick={handleCartItemClick}
          handleRemoveCourseFromCart={handleRemoveCourseFromCart}
        />
      ) : (
        <p className={`${PREFIX}__empty`}>{formatMessage(messages.orderBasketEmpty)}</p>
      )}
      <div className={`${PREFIX}__footer`}>
        <Text classes={`${PREFIX}__footer__total`}>{formatMessage(messages.total)}</Text>
        <Text classes={`${PREFIX}__footer__total`}>{parsedTotalPrice}</Text>
        {checkoutItems?.length ? (
          <Button
            color='secondary'
            variant='text'
            size="small"
            onClick={goToCheckout}
            disabled={!checkoutItems?.length}
          >
            {formatMessage(messages.completeOrder)}
          </Button>
        ) : null}
      </div>
    </div>
  );
};

const enhancer = compose(
  memo,
  injectIntl,
  withShoppingCart
);

export default enhancer(CheckoutMenu);
