// Core
import React, { PureComponent } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { compose, graphql } from 'react-apollo';
import { injectIntl, FormattedMessage } from 'react-intl';

// Components
import FilterGroup from 'components/FilterDropDown/FilterGroup';
import DurationRangeSelect from 'components/DurationRangeSelect';
import Search from 'routes/Courses/Search';
import Complexity from 'routes/Course/components/ComplexitySelect';
import InteractivityLevelSelect from 'routes/Course/components/InteractivityLevelSelect';
import LanguageSelect from 'routes/Course/components/LanguageSelect';
import ChevronDown from 'components/Icon/Svg/ChevronDown';
import Modal from 'components/Modal';
import MetaData from 'components/MetaData';

// Styles and Assets
import 'routes/Courses/Filters/Filters.scss';
import FilterIcon from 'components/Icon/Svg/FilterIcon';

// GraphQl
import LIST_CATEGORIES_QUERY from 'queries/Courses/listCategories.gql';
import LIST_SUBCATEGORIES_QUERY from 'queries/Courses/listSubcategories.gql';
import GET_SUBCATEGORIES_WITH_COURSES_LIST from 'queries/Courses/getSubcategoriesWithCoursesList.gql';
import GET_LANGUAGES_WITH_COURSES_LIST from 'queries/Courses/getLanguagesWithCoursesList.gql';

// Other
import { sharedMessages } from 'services/i18n/sharedMessages/messages';
import { CATALOG_AMOUNT } from 'utils/enums';
import { messages } from 'routes/Courses/Filters/messages';

const VALUE_ALL = '0';

const CATEGORY_VALUE_ALL = {
  value: VALUE_ALL,
  label: <FormattedMessage {...messages.allCats} />
};

const SUBCATEGORY_ALL_VALUE = {
  value: VALUE_ALL,
  label: <FormattedMessage {...messages.allSubcats} />
};

class Filters extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      category: {
        data: [CATEGORY_VALUE_ALL]
      },
      subcategory: {
        data: [SUBCATEGORY_ALL_VALUE]
      },
      isModalOpen: false
    };
  }

  // TODO get rid getDerivedStateFromProps
  static getDerivedStateFromProps(props, state) {
    const {
      listCategories,
      listSubcategories,
      intl: { formatMessage }
    } = props;

    const dataTransform = (data, valueAll) => {
      let result = [];

      if (data?.items) {
        result = data.items
          .sort((prev, cur) => prev.order_number - cur.order_number)
          .map(({ id: value, localized, title }) => ({
            value,
            label:
              localized ||
              (sharedMessages[title] && formatMessage(sharedMessages[title])) ||
              formatMessage(sharedMessages.others)
          }));
      }

      result = [valueAll, ...result];

      return result;
    };

    return {
      ...state,
      category: {
        ...state.category,
        data: dataTransform(listCategories, CATEGORY_VALUE_ALL)
      },
      subcategory: {
        ...state.subcategory,
        data: dataTransform(listSubcategories, SUBCATEGORY_ALL_VALUE)
      }
    };
  }

  getSeoTitle = () => {
    const {
      category,
      subcategory,
      listCategories: categories,
      listSubcategories: subcategories,
      intl: { formatMessage }
    } = this.props;

    // Translation for active subcategories brings first
    if (subcategory && subcategories && subcategories.items) {
      const activeSubcategory = subcategories.items
        .filter(item => item.id === subcategory)
        .shift();
      if (activeSubcategory) {
        const translationKey = activeSubcategory.title;
        return formatMessage(sharedMessages[translationKey]);
      }
    }
    // Translation for active categories brings after
    if (category && categories && categories.items) {
      const activeCategory = categories.items
        .filter(item => item.id === category)
        .shift();
      if (activeCategory) {
        const translationKey = activeCategory.title;
        return formatMessage(sharedMessages[translationKey]);
      }
    }
    // Default translation
    return formatMessage(messages.allCats);
  };

  getSeoDescription = () => {
    const {
      category,
      listCategories: categories,
      intl: { formatMessage }
    } = this.props;

    // Translation for active categories brings after
    if (category && categories && categories.items) {
      const activeCategory = categories.items
        .filter(item => item.id === category)
        .shift();
      if (activeCategory) {
        const translationKey = `${activeCategory.title}Desc`;
        return formatMessage(sharedMessages[translationKey]);
      }
    }
    return null;
  };

  slugToCategoryName = slug => {
    if (slug.includes('other')) {
      return 'other';
    }
    return slug
      .replace(/(?:^\w|[A-Z]|\b\w)/g, (letter, index) =>
        index === 0 ? letter.toLowerCase() : letter.toUpperCase()
      )
      .replace(/\s+/g, '');
  };

  getFilterData = () => {
    const {
      category: { data: categoryData },
      subcategory: { data: subcategoryData }
    } = this.state;
    const {
      category: categoryValue = CATEGORY_VALUE_ALL.value,
      subcategory: subcategoryValue = SUBCATEGORY_ALL_VALUE.value,
      subcategoriesWithCourses,
      availableLanguages
    } = this.props;

    let subcategoryDisabled = false;
    let subcategoryValueNew = subcategoryValue;

    if (categoryValue === CATEGORY_VALUE_ALL.value) {
      subcategoryDisabled = true;
      subcategoryValueNew = SUBCATEGORY_ALL_VALUE.value;
    }

    subcategoryData.forEach((subcategory, index) => {
      const { value: subcategoryId } = subcategory;
      if (subcategoriesWithCourses && subcategoriesWithCourses !== null)
        /* eslint-disable no-param-reassign */
        subcategory.hasCourses = subcategoriesWithCourses.includes(
          subcategoryId
        );
      /* eslint-disable no-param-reassign */
      if (index === 0) subcategory.hasCourses = true;
    });

    return [
      {
        name: 'category',
        value: categoryData.filter(
          category => category.value === categoryValue
        )?.[0]?.value,
        placeholder: 'Choose Category',
        options: [...categoryData],
        disabled: false
      },
      {
        name: 'subcategory',
        value:
          subcategoryData.filter(
            subcategory => subcategory.value === subcategoryValueNew
          )?.[0]?.value || '',
        placeholder: 'Choose SubCategory',
        options: [...subcategoryData],
        disabled: subcategoryDisabled
      }
    ];
  };

  handleChangeCategorySubcategory = key => value => {
    const { handleFilterParamChange, category } = this.props;

    if (key === 'category' && category !== value) {
      // set 'All SubCategories'
      handleFilterParamChange('subcategory');
    }

    handleFilterParamChange(key)(value === VALUE_ALL ? undefined : value);
  };

  hideModal = () => {
    this.setState({ isModalOpen: false });
  };

  renderFilterBlock = () => {
    const {
      complexity,
      interactivityLevel,
      language,
      durationRange,
      handleFilterParamChange,
      searchString,
      availableLanguages
    } = this.props;

    return (
      <>
        <div className='sk-filters__main__additiona2'>
          <div className='sk-filters__main__additiona2 __filterBlock'>
            <Complexity
              type='filter'
              handleChange={handleFilterParamChange('complexity')}
              value={complexity}
            />
            <InteractivityLevelSelect
              type='filter'
              name='interactionLevel'
              label=''
              value={interactivityLevel}
              handleChange={handleFilterParamChange('interactivityLevel')}
            />
            <LanguageSelect
              type='filter'
              name='language'
              label=''
              value={language}
              filter={availableLanguages}
              handleChange={handleFilterParamChange('language')}
            />
            <DurationRangeSelect
              name='durationRange'
              label=''
              value={durationRange}
              handleChange={handleFilterParamChange('durationRange')}
            />
          </div>
          <div className='sk-filters__main__search'>
            <Search
              searchString={searchString}
              hideModal={this.hideModal}
              handleSearch={handleFilterParamChange('searchString')}
            />
          </div>
        </div>
      </>
    );
  };

  handleOpenModalFilter = () => {
    this.setState({ isModalOpen: true });
  };

  render() {
    const {
      className,
      fetchError,
      complexity,
      interactivityLevel,
      language,
      durationRange,
      handleFilterParamChange,
      searchString,
      availableLanguages
    } = this.props;

    const { isModalOpen } = this.state;

    if (fetchError) {
      throw Error(fetchError);
    }

    return (
      <div
        className={classNames('sk-filters', {
          className: className || undefined
        })}
      >
        <MetaData
          title={this.getSeoTitle()}
          description={this.getSeoDescription()}
        />

        <div className='sk-filters__mobile-tablet'>
          <button type='button' onClick={this.handleOpenModalFilter}>
            <FilterIcon />
            <div className='sk-filters-mobileFilter'>
              <span className='sk-filters__mobile-tablet__text'>
                <FormattedMessage {...messages.filter} />
              </span>
              <ChevronDown />
            </div>
          </button>
          {isModalOpen && (
            <Modal
              component={() => this.renderFilterBlock()}
              open
              hideModal={this.hideModal}
              size='md'
            />
          )}
        </div>
        <div className='sk-filters__main'>
          <FilterGroup
            filterData={this.getFilterData()}
            handleChange={this.handleChangeCategorySubcategory}
            valueKey='value'
            labelKey='label'
            isPlaceholderOptionHidden
          />
          <div className='sk-filters__main__additional'>
            <Complexity
              type='filter'
              handleChange={handleFilterParamChange('complexity')}
              value={complexity}
            />
            <InteractivityLevelSelect
              type='filter'
              name='interactionLevel'
              label=''
              value={interactivityLevel}
              handleChange={handleFilterParamChange('interactivityLevel')}
            />
            <LanguageSelect
              type='filter'
              filter={availableLanguages}
              name='language'
              label=''
              value={language}
              handleChange={handleFilterParamChange('language')}
            />
            <DurationRangeSelect
              name='durationRange'
              label=''
              value={durationRange}
              handleChange={handleFilterParamChange('durationRange')}
            />
          </div>
        </div>
        <div className='sk-filters__main__search'>
          <Search
            searchString={searchString}
            hideModal={this.hideModal}
            handleSearch={handleFilterParamChange('searchString')}
          />
        </div>
      </div>
    );
  }
}

const listCategoriesQuery = graphql(LIST_CATEGORIES_QUERY, {
  skip: props => !!props.skip,
  props: ({
    data: { loading, error: fetchError, listCategories, ...ownProps }
  }) => ({
    loading,
    listCategories,
    fetchError,
    ...ownProps
  })
});

const listSubcategoriesQuery = graphql(LIST_SUBCATEGORIES_QUERY, {
  skip: props => !!props.skip || !props.category,
  props: ({
    data: { loading, error: fetchError, listSubcategories, ...ownProps }
  }) => ({
    loading,
    listSubcategories,
    fetchError,
    ...ownProps
  }),
  options: ({ category }) => ({
    variables: {
      category,
      limit: CATALOG_AMOUNT
    }
  })
});

Filters.propTypes = {
  className: PropTypes.string,
  subcategory: PropTypes.string,
  category: PropTypes.string,
  complexity: PropTypes.string,
  interactivityLevel: PropTypes.string,
  handleFilterParamChange: PropTypes.func,
  fetchError: PropTypes.object,
  language: PropTypes.string,
  durationRange: PropTypes.string,
  searchString: PropTypes.string
};

const getSubcategoriesWithCoursesList = graphql(
  GET_SUBCATEGORIES_WITH_COURSES_LIST,
  {
    props: ({
      data: {
        loading,
        error: fetchError,
        /* eslint-disable no-shadow */
        getSubcategoriesWithCoursesList,
        ...ownProps
      }
    }) => ({
      loading,
      subcategoriesWithCourses: getSubcategoriesWithCoursesList,
      fetchError,
      ...ownProps
    })
  }
);

const getLanguagesWithCoursesList = graphql(GET_LANGUAGES_WITH_COURSES_LIST, {
  props: ({
    data: {
      loading,
      error: fetchError,
      /* eslint-disable no-shadow */
      getLanguagesWithCoursesList,
      ...ownProps
    }
  }) => ({
    loading,
    availableLanguages: getLanguagesWithCoursesList,
    fetchError,
    ...ownProps
  }),
  options: ({ subcategory, category, complexity }) => ({
    variables: {
      category,
      subcategory,
      complexity
    }
  })
});

const enhancer = compose(
  injectIntl,
  listCategoriesQuery,
  getSubcategoriesWithCoursesList,
  getLanguagesWithCoursesList,
  listSubcategoriesQuery
);

export default enhancer(Filters);
