import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { compose } from 'react-apollo';
import { useSwipeable } from 'react-swipeable'
import withWidth from 'containers/HOCs/withWidth';

import {
  DefaultSliderContext,
  DefaultSliderWrapper,
} from 'components/NetflixCarousel/modules';
import DefaultSliderButton from 'components/NetflixCarousel/modules/SliderButton';
import DefaultSliderItem from 'components/NetflixCarousel/modules/SliderItem';
import SliderItemConfiguration from 'components/NetflixCarousel/modules/SliderItemConfiguration';
import Content from 'components/NetflixCarousel/modules/Content';
import useSliding from 'components/NetflixCarousel/modules/useSliding.js'
import useSizeElement from 'components/NetflixCarousel/modules/useSizeElement.js';
import { isBlank } from 'utils/helpers';

// Styles
import './index.scss'
import SliderDots from './modules/SliderDots';

const Slider = ({
  SliderContext = DefaultSliderContext,
  SliderWrapper = DefaultSliderWrapper,
  SliderButton = DefaultSliderButton,
  SliderItem = DefaultSliderItem,
  items,
  children,
  activeSlide,
  width: mediaWidth,
}) => {
  const [currentSlide, setCurrentSlide] = useState(activeSlide);
  const [isMuted, setMuted] = useState(true);
  const [isFullSize, setFullSize] = useState(false);
  const [paddings, setPaddings] = useState(null);

  useEffect(()=>{
    if(mediaWidth === 'xs' || mediaWidth === 'sm'){
      setPaddings(20);
    } else{
      setPaddings(80);
    }
  }, [setPaddings, mediaWidth])

  const { width, elementRef } = useSizeElement();
  const {
    handlePrev,
    handleNext,
    slideProps,
    containerRef,
    hasNext,
    hasPrev,
    slideTo,
    containerWidth,
    totalInViewport,
    elementWidth,
    viewed,
  } = useSliding(width, items.length, paddings);

  const contentOpen = !isBlank(currentSlide);

  const handleSelect = topping => {
    setCurrentSlide(topping);
  };

  const handleClose = () => {
    setCurrentSlide(null);
  };

  const handleMuteVideo = () => {
    setMuted(!isMuted);
  }

  const contextValue = {
    onSelectSlide: handleSelect,
    onCloseSlide: handleClose,
    onMuteVideo: handleMuteVideo,
    elementRef,
    currentSlide,
    isMuted,
    contentOpen
  };

  const swipeHandlers = useSwipeable({
    onSwipedRight: () => hasPrev && handlePrev(),
    onSwipedLeft: () => hasNext && handleNext(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  })

  return (
    <SliderContext.Provider value={contextValue}>
      <SliderWrapper>
        <SliderDots items={items} currentSlide={viewed} slideTo={slideTo}/>
        <div className={cx('slider', { 'slider__open': contentOpen })} {...swipeHandlers}>
          <div ref={containerRef} className="slider__container" {...slideProps} >
            {items.map(item => (
              <SliderItemConfiguration
                item={item}
                SliderItem={SliderItem}
                key={item.id}
              />
            ))}
          </div>
        </div>
        {hasPrev && <SliderButton onClick={handlePrev} type="prev" isOpen={contentOpen} />}
        {hasNext && <SliderButton onClick={handleNext} type="next" isOpen={contentOpen} />}
      </SliderWrapper>
      {currentSlide && <Content
        topping={currentSlide}
        onClose={handleClose}
        handleMuteVideo={handleMuteVideo}
        isMuted={isMuted}
      />}
    </SliderContext.Provider>
  );
};

const enhancer = compose(
  withWidth()
)

export default enhancer(Slider);
