// Core
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';

// Components
import ContentNavigation from 'components/ContentNavigation';

// Other
import { getMaterials, getItemUrl, sortLectures, compareSortNumber } from 'utils/helpers';
import { getViewEditAccess } from 'utils/helpers/courseSettingHelpers';

const getModuleMaterialsList = memoize(({ lectures }) =>
  lectures.filter((item) => item.materials).flatMap((item) => item.materials.sort(compareSortNumber))
);

export const CourseModulePagination = ({
  history,
  match: {
    url,
    params: { lectureId }
  },
  course,
  handleSetResume,
  currentLecture = null,
  hasLectureHomeworkSettings = null,
  lecturesWithHomework = null,
  accessSettings = null,
  homeworks = null
}) => {
  const getNextMaterial = (list, index) => list.slice(index + 1, index + 2)[0];
  const getPrevMaterial = (list, index) => list.slice(index - 1, index)[0];

  const handleButtonPress = (dir) => async () => {
    const paginationList = getModuleMaterialsList(course);
    const studyMaterialId = getStudyMaterialId(url);
    const currentMaterialIndex = paginationList.findIndex((mat) => mat.id === studyMaterialId);
    const materialToGo =
      dir === 'next'
        ? getNextMaterial(paginationList, currentMaterialIndex)
        : getPrevMaterial(paginationList, currentMaterialIndex);

    if (!materialToGo) {
      return;
    }
    const lectureToGoId = materialToGo.lectureId;

    const materialToGoUrl = getItemUrl(url, materialToGo, lectureToGoId, lectureId);

    await handleSetResume(materialToGo)();

    return history.push(materialToGoUrl);
  };

  const isLectureAvailable = (dir) => {
    const paginationList = getModuleMaterialsList(course);
    const studyMaterialId = getStudyMaterialId(url);
    const currentMaterialIndex = paginationList.findIndex((mat) => mat.id === studyMaterialId);
    const materialToGo =
      dir === 'next'
        ? getNextMaterial(paginationList, currentMaterialIndex)
        : getPrevMaterial(paginationList, currentMaterialIndex);

    if (!materialToGo) {
      return false;
    }

    const lectureToGoId = materialToGo.lectureId;

    if (
      hasLectureHomeworkSettings !== null &&
      lecturesWithHomework !== null &&
      accessSettings !== null &&
      homeworks !== null
    ) {
      const isDisabled = getViewEditAccess({
        currentLecture,
        lecturesWithHomework,
        accessSettings,
        homeworks
      });
      if (isDisabled) {
        return false;
      }
    }
    return true;
  };

  const hasNext = isLectureAvailable('next');

  return (
    <ContentNavigation
      withLabel
      withoutSeparator={false}
      handleNextButtonPress={handleButtonPress('next')}
      handlePreviousButtonPress={handleButtonPress('prev')}
      hasNext={hasNext}
    />
  );
};

const getStudyMaterialId = (url) => url.split('/').pop();

CourseModulePagination.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  course: PropTypes.object
};

export default memo(CourseModulePagination);
