import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  logIn: {
    id: 'app.containers.Header.CatalogBlock.logIn',
    defaultMessage: 'Log in',
  },
  signUp: {
    id: 'app.containers.Header.CatalogBlock.singUp',
    defaultMessage: 'Sign up',
  },
});
