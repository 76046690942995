import React from 'react';
import PropTypes from 'prop-types';
import { PurpleHeart, White } from 'components/Icon/color';

const CheckRoundedTrasparent = ({ width, height, strokeWidth, strokeColor }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.6341 30.2683C23.7164 30.2683 30.2683 23.7164 30.2683 15.6341C30.2683 7.55193 23.7164 1 15.6341 1C7.55193 1 1 7.55193 1 15.6341C1 23.7164 7.55193 30.2683 15.6341 30.2683Z"
      stroke={strokeColor}
      strokeWidth="2"
    />
    <path
      d="M9 16.2331L13.9321 20.9756L22.9024 10"
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
  </svg>
);

CheckRoundedTrasparent.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  strokeWidth: PropTypes.string,
  strokeColor: PropTypes.number,
};

CheckRoundedTrasparent.defaultProps = {
  strokeWidth: 2,
  strokeColor: '#75C561',
  height: '23',
  width: '23',
};

export default CheckRoundedTrasparent;
