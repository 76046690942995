// Core
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import TablePagination from '@material-ui/core/TablePagination';
import { injectIntl, FormattedMessage } from 'react-intl';
import { compose } from 'react-apollo';

// Other
import { messages } from './messages';

// Styles
import './Pagination.scss';

const iconButtonClasses = {
  root: 'mui-override sk-pagination_icon-btn',
  label: 'mui-override sk-pagination_label',
};

const paginationClasses = {
  toolbar: 'mui-override sk-pagination_toolbar',
  caption: 'mui-override sk-pagination_caption',
  actions: 'mui-override sk-pagination_actions',
};

const getLabelDisplayedRows = ({ from, to, count }) => (
  <FormattedMessage {...messages.of} values={{ from, to, count }} />
);

const Pagination = ({
  handleChangePage,
  handleChangeRowsPerPage,
  count = 100,
  rowsPerPage = 10,
  page = 0,
}) => (
  <TablePagination
    rowsPerPageOptions={[]}
    labelRowsPerPage=""
    component="div"
    count={count}
    rowsPerPage={rowsPerPage}
    page={page}
    labelDisplayedRows={getLabelDisplayedRows}
    backIconButtonProps={{
      classes: iconButtonClasses,
    }}
    nextIconButtonProps={{
      classes: iconButtonClasses,
    }}
    onChangePage={handleChangePage}
    onChangeRowsPerPage={handleChangeRowsPerPage}
    classes={paginationClasses}
  />
);

const enhancer = compose(
  memo,
  injectIntl
);

export default enhancer(Pagination);
