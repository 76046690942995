// Core
import React, { memo, useState, useEffect } from 'react';
import { compose } from 'react-apollo';
import { withRouter } from 'react-router';

// Components
import Card from 'components/Card';
import Heading from 'components/Heading';
import AboutExpertInfo from 'routes/Onboarding/AboutExpertInfo';
import OnboardingPolicy from 'routes/Onboarding/OnboardingPolicy';

// Other
import { FormattedMessage } from 'react-intl';
import { messages } from 'routes/Onboarding/messages';
import { withAuthenticatedUser } from 'containers/AuthenticatedUserProvider/withAuthenticatedUser';
import { EXPERT, } from 'utils/enums';

const Onboarding = ({
  userContext: { profile, getProfile },
  history,
}) => {
  const [showConfig, setShowConfig] = useState(false);

  const handleSwitch = async () => {
    setShowConfig(!showConfig);
  };

  const handleRedirectToManageAccount = () => {
    console.log('redirect');
    history.push('/manage-account/personal-info');
  };

  useEffect(() => {
    const isExpert = profile?.role === EXPERT;
    if (isExpert) {
      handleRedirectToManageAccount();
    }
  }, []);

  return (
    <div className="sk-onboarding sk-background-spot__secondary">
      <div className="sk-onboarding_wrapper">
        <Card classes="sk-onboarding_card">
          <div className="sk-onboarding_content">
            <header className="sk-onboarding_header">
              <Heading classes="sk-onboarding_heading" variant="h4">
                <FormattedMessage {...messages.teachOnline} />
              </Heading>
            </header>
            <div className="sk-onboarding_inner">
              {showConfig ? (
                <AboutExpertInfo profile={profile} getProfile={getProfile} handleRedirectToManageAccount={handleRedirectToManageAccount} />
              ) : (
                <OnboardingPolicy handleSwitch={handleSwitch} />
              )}
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

const enhancer = compose(
  memo,
  withRouter,
  withAuthenticatedUser
)

export default enhancer(Onboarding);
