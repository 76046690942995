import React from 'react';
import PropTypes from 'prop-types';
import { PurpleHeart, White } from 'components/Icon/color';

const WebinarVideo = ({ height, width, strokeColor, fillColor, active }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="25"
      cy="25"
      r="25"
      fill={fillColor}
      fillOpacity={active ? '1' : '0.75'}
    />
    <rect
      x="11.5"
      y="17.5"
      width="18.2135"
      height="15.1798"
      rx="1.5"
      stroke={strokeColor}
    />
    <path
      d="M30.1996 25.4861C29.9396 25.2859 29.9396 24.8938 30.1996 
      24.6937L36.6613 19.7194C36.9901 19.4663 37.4663 19.7007 37.4663 
      20.1156L37.4663 30.0641C37.4663 30.4791 36.9901 30.7134 36.6613 
      30.4603L30.1996 25.4861Z"
      stroke={strokeColor}
    />
  </svg>
);

WebinarVideo.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  strokeColor: PropTypes.string,
  fillColor: PropTypes.string,
  active: PropTypes.bool,
};

WebinarVideo.defaultProps = {
  height: '50',
  width: '50',
  strokeColor: PurpleHeart,
  fillColor: White,
  active: false,
};

export default WebinarVideo;
