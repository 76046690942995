// Core
import React, { memo, useCallback, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { compose } from 'react-apollo';
import classNames from 'classnames';
import { withFormik } from 'formik';

// Components
import Text from 'components/Text';
import ToolTip from 'components/ToolTip';
import Button from 'components/Button';
import Heading from 'components/Heading';
import PricesConversionsModal from 'routes/ExpertsCabinet/CourseEdit/Details/CourseInformation/CourseVariantsForm/PricesConversionsModal';
import CourseVariant from 'routes/ExpertsCabinet/CourseEdit/Details/CourseInformation/CourseVariantsForm/CourseVariant';

// Other
import { withSharedState } from 'containers/SharedStateProvider/withSharedState';
import { withCourseEditContext } from 'routes/ExpertsCabinet/CourseEdit/Context';
import { withModal } from 'containers/ModalProvider/withModal';
import { messages } from 'routes/ExpertsCabinet/CourseEdit/Details/messages';
import { getYupSchema } from 'services/yup';
import {
  hasVariant,
  getVariant,
  formatPriceInputValue,
} from 'routes/ExpertsCabinet/CourseEdit/Details/CourseInformation/helpers.js';

// Styles
import './index.scss';

const ToolTipText = ({ children }) => {
  return (
    <Text
      classes="sk-course-intecation-level-tooltip__text"
      size="small"
    >
      {children}
    </Text>
  )
};

const getIntitalState = ({
  variants
}, org) => {
  const flexVariant = getVariant({ variants, variantName: 'flex' });
  const assistVariant = getVariant({ variants, variantName: 'assist' });
  const conciergeVariant = getVariant({ variants, variantName: 'concierge' });

  const initialState = {
    flex: {
      isChecked: !org ? !!flexVariant : false,
      price: formatPriceInputValue(flexVariant?.price),
    },
    assist: {
      isChecked: !org ? !!assistVariant : false,
      price: formatPriceInputValue(assistVariant?.price),
    },
    concierge: {
      isChecked: !org ? !!conciergeVariant : true,
      price: formatPriceInputValue(org ? 0 : conciergeVariant?.price),
    },
  };

  return initialState;
};


const classname = 'sk-course-variants';
const CourseVariantsForm = ({
  disabled,
  isValid,
  values,
  errors,
  touched,
  validateForm,
  handleBlur,
  setFieldValue,
  setErrors,
  setTouched,
  sharedStateContext: { updateSharedState, sharedState },
  courseEditContext: {
    course,
    handleCourseUpdate,
    bindCourseMethod,
  },
  modalContext: { showModal },
  intl: { formatMessage },
  org,
}) => {
  const isMainFillingBlocked = ['DRAFT', 'FIRST_REVIEW'].includes(course.statusId);

  if (isMainFillingBlocked) {
    return null;
  };

  const isOnlyOneVariantSelected = course.variants?.length == 1;

  useEffect(() => {
    let isValid = true;

    Object.keys(errors).forEach(error => {
      if (touched[error]) {
        isValid = false;
      }
    });

    const courseVariants = { values, isValid };
    updateSharedState({ courseVariants });
    return () => {
      updateSharedState({ courseVariants: { isValid: true } });
    };
  }, [values, errors, touched]);

  const updateCourseVariantPrice = useCallback((variantName) => async (inputValue, e) => {
    const newValue = { ...values[variantName] };
    newValue.price = inputValue;
    // setFieldValue(variantName, newValue, false);

    if (e) {
      handleBlur(e);
    };

    try {
      const errors = await validateForm({ ...values, [variantName]: newValue });
      const hasError = errors[variantName];
      const {
        courseVariants: {
          values: courseVariants
        }
      } = sharedState;
      if (hasError) return;

      // Merge course variant defaults and shared state
      let newVariants = [...course.variants].map(variant => ({
        ...variant,
        price: courseVariants[variant.name].price
      }));

      const isVariantAdded = hasVariant({ variants: newVariants, variantName });

      if (isVariantAdded) {
        const foundVariant = newVariants.find(
          variant => variant.name == variantName
        );
        foundVariant.price = inputValue;
        updateSharedState({ courseVariants: newVariants });
        await handleCourseUpdate({ variants: newVariants }, true);
      }
    } catch (e) {
      console.log('error', e);
    };
  }, [values, course, handleCourseUpdate, handleBlur, validateForm, sharedState.courseVariants]);

  const handleFormValidation = useCallback(async () => {
    const errors = await validateForm(values);
    const isValid = Object.keys(errors).length == 0;

    // debugger;

    if (!isValid) {
      const touched = {};

      for (const fieldName in errors) {
        touched[fieldName] = true;
      }

      setErrors(errors);
      setTouched(touched);
    }

    return isValid;
  }, [values, validateForm, setErrors]);
  bindCourseMethod({ method: 'validateCourseVariants', handler: handleFormValidation });


  const openPricesConversionsModal = () =>
    showModal(PricesConversionsModal, {
      size: 'lg',
      disabled,
      updateCourseVariantPrice,
    });

  const formikBag = { values, errors, touched, setFieldValue };

  return (
    <>
      <div className='sk-form__row'>
        <div className={classname}>
          { !org && (<div className={`${classname}__label`}>
            <Heading variant="h3" disabled={disabled}>
              {formatMessage(messages.pricePerLevel)}
            </Heading>
            <Button
              size="medium"
              color="primary"
              disabled={disabled}
              onClick={openPricesConversionsModal}
            >
              {formatMessage(messages.pricesConversions)}
            </Button>
          </div>)}
          <div className={`${classname}__fields ${org && 'hidden' }`}>
            <CourseVariant
              formikBag={formikBag}
              isOnlyOneVariantSelected={isOnlyOneVariantSelected}
              disabled={disabled}
              variantName='flex'
              updateCourseVariantPrice={updateCourseVariantPrice}
            />
            <CourseVariant
              formikBag={formikBag}
              isOnlyOneVariantSelected={isOnlyOneVariantSelected}
              disabled={disabled}
              variantName='assist'
              updateCourseVariantPrice={updateCourseVariantPrice}
            />
            <CourseVariant
              formikBag={formikBag}
              isOnlyOneVariantSelected={isOnlyOneVariantSelected}
              disabled={disabled}
              variantName='concierge'
              updateCourseVariantPrice={updateCourseVariantPrice}
            />
          </div>
          <ToolTip
            wide
            triggerClasses="sk-course-information-form__trigger"
            iconTheme="orange"
          >
            <div>
              <ToolTipText>{formatMessage(messages.flexText)}</ToolTipText>
              <ToolTipText>{formatMessage(messages.assistText)}</ToolTipText>
              <ToolTipText>{formatMessage(messages.conciergeText)}</ToolTipText>
            </div>
          </ToolTip>
        </div>
      </div>
    </>
  );
};

const formikStateManagement = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ courseEditContext: { course }, org }) =>  getIntitalState(course, org),
  displayName: 'CourseVariantsForm',
  validationSchema: () => getYupSchema('courseVariantsValidationSchema'),
  validateOnChange: false,
  validateOnBlur: false,
});

const enhancer = compose(
  memo,
  injectIntl,
  withModal,
  withSharedState,
  withCourseEditContext,
  formikStateManagement
);

export default enhancer(CourseVariantsForm);
