import React from 'react';

import './index.scss';

const BlockquoteOnHover = () => (
  <svg
    className="hover"
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.692383" width="32" height="32" rx="2" fill="white" />
    <path
      d="M23.074 22.74C23.144 22.908 23.179 23.048 23.179 23.16C23.179 23.426 23.067 23.65 22.843 23.832C22.633 24.014 22.395 24.105 22.129 24.105C21.947 24.105 21.772 24.056 21.604 23.958C21.45 23.846 21.331 23.692 21.247 23.496L19.861 20.346H12.175L10.789 23.496C10.705 23.692 10.579 23.846 10.411 23.958C10.243 24.056 10.068 24.105 9.88604 24.105C9.60604 24.105 9.35404 24.014 9.13004 23.832C8.92004 23.65 8.81504 23.426 8.81504 23.16C8.81504 23.048 8.85004 22.908 8.92004 22.74L14.842 9.783C14.94 9.559 15.094 9.384 15.304 9.258C15.528 9.132 15.759 9.069 15.997 9.069C16.235 9.069 16.459 9.132 16.669 9.258C16.893 9.384 17.054 9.559 17.152 9.783L23.074 22.74ZM19.105 18.624L16.018 11.673L12.952 18.624H19.105Z"
      fill="#444444"
    />
  </svg>
);

const DefaultBlockquote = () => (
  <svg
    className="default"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.074 22.74C23.144 22.908 23.179 23.048 23.179 23.16C23.179 23.426 23.067 23.65 22.843 23.832C22.633 24.014 22.395 24.105 22.129 24.105C21.947 24.105 21.772 24.056 21.604 23.958C21.45 23.846 21.331 23.692 21.247 23.496L19.861 20.346H12.175L10.789 23.496C10.705 23.692 10.579 23.846 10.411 23.958C10.243 24.056 10.068 24.105 9.88604 24.105C9.60604 24.105 9.35404 24.014 9.13004 23.832C8.92004 23.65 8.81504 23.426 8.81504 23.16C8.81504 23.048 8.85004 22.908 8.92004 22.74L14.842 9.783C14.94 9.559 15.094 9.384 15.304 9.258C15.528 9.132 15.759 9.069 15.997 9.069C16.235 9.069 16.459 9.132 16.669 9.258C16.893 9.384 17.054 9.559 17.152 9.783L23.074 22.74ZM19.105 18.624L16.018 11.673L12.952 18.624H19.105Z"
      fill="#444444"
    />
  </svg>
);

const ActiveBlockquote = () => (
  <svg
    className="active"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.074 22.74C23.144 22.908 23.179 23.048 23.179 23.16C23.179 23.426 23.067 23.65 22.843 23.832C22.633 24.014 22.395 24.105 22.129 24.105C21.947 24.105 21.772 24.056 21.604 23.958C21.45 23.846 21.331 23.692 21.247 23.496L19.861 20.346H12.175L10.789 23.496C10.705 23.692 10.579 23.846 10.411 23.958C10.243 24.056 10.068 24.105 9.88604 24.105C9.60604 24.105 9.35404 24.014 9.13004 23.832C8.92004 23.65 8.81504 23.426 8.81504 23.16C8.81504 23.048 8.85004 22.908 8.92004 22.74L14.842 9.783C14.94 9.559 15.094 9.384 15.304 9.258C15.528 9.132 15.759 9.069 15.997 9.069C16.235 9.069 16.459 9.132 16.669 9.258C16.893 9.384 17.054 9.559 17.152 9.783L23.074 22.74ZM19.105 18.624L16.018 11.673L12.952 18.624H19.105Z"
      fill="#30d5c8"
    />
  </svg>
);

const Blockquote = ({ active }) => {
  const Icon = active ? ActiveBlockquote : DefaultBlockquote;

  return (
    <div className="editor-icon-svg">
      <Icon />
      <BlockquoteOnHover />
    </div>
  );
};

export default Blockquote;
