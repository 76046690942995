import { capitalize } from 'utils/helpers';

const FONT_SIZES = [
  {
    value: 'unstyled',
    label: 'P',
  },
  {
    value: 'header-one',
    label: 'H1',
  },
  {
    value: 'header-two',
    label: 'H2',
  },
  {
    value: 'header-three',
    label: 'H3',
  },
  {
    value: 'header-four',
    label: 'H4',
  },
  {
    value: 'header-five',
    label: 'H5',
  },
  {
    value: 'header-six',
    label: 'H6',
  },
];

export default FONT_SIZES;
