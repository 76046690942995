// Core
import React, { useCallback } from 'react';
import { compose } from 'react-apollo';
import { injectIntl } from 'react-intl';

// Components
import Card from 'components/Card';
import TextInput from 'components/Input/Material/TextInput';

// Other
import { withSharedState } from 'containers/SharedStateProvider/withSharedState';
import { messages } from 'routes/ExpertsCabinet/ToppingEdit/messages';
import { withModal } from 'containers/ModalProvider/withModal';
import { withLectureFilesUploader } from 'routes/Course/components/LecturesList/LectureItem/HOCs/withLectureFilesUploader';
import withWidth from 'containers/HOCs/withWidth';

// Styles
import './index.scss';

const prefix = 'sk-topping-admin-recommendation';
const ToppingDetails = ({
  disabledEditing,
  formikBag,
  handleToppingUpdate,
  intl: { formatMessage },
}) => {

  const handleInputBlur = useCallback((type) => () => {
    handleToppingUpdate({ [type]: formikBag.values[type] });
  }, [formikBag.values]);

  return (
    <Card>
      <div className={prefix}>
        <TextInput
          name='recommendationFromAdmin'
          disabled={disabledEditing}
          value={formikBag.values.recommendationFromAdmin}
          label={formatMessage(messages.recomendationFromAdminLabel)}
          onChange={formikBag.handleChange('recommendationFromAdmin')}
          onBlur={handleInputBlur('recommendationFromAdmin')}
        />
      </div>
    </Card>
  );
};

const enhancer = compose(
  injectIntl,
  withModal,
  withLectureFilesUploader,
  withSharedState,
  withWidth()
);

export default enhancer(ToppingDetails);
