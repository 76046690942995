// Core
import React, { useState, useEffect, useCallback } from "react";
import { useMutation } from "@apollo/react-hooks";

// Components
import SuccessfulMessage from "routes/Checkout/components/SuccessfulMessage";
import FailedMessage from "routes/Checkout/components/FailedMessage";

// GraphQL
import CREATE_TRANSACTION from "mutations/Order/createTransaction.gql";
import UPDATE_DISCOUNT from "queries/AdminPanel/updateDiscount.gql";

// Other
import { getYupSchema } from "services/yup";
import gaTracking from "services/ga";

export function useTransaction({
  currency,
  orderItems,
  discountPayload,
  discountCode,
  draftOrderId,
  modalContext,
  history,
  clearShoppingCartInStateAndLocalStorage,
  ...props
}) {
  const [loading, setLoading] = useState(false);
  const [createTransactionMutation, response] = useMutation(
    CREATE_TRANSACTION,
    {
      options: {
        fetchPolicy: "no-cache",
        refetchQueries: ["getDraftOrderByUserId"],
      },
    }
  );
  const [updateDiscount] = useMutation(UPDATE_DISCOUNT);

  const handleModalClick = useCallback(() => {
    clearShoppingCartInStateAndLocalStorage();
    modalContext.hideModal();
    history.push(`/student-cabinet/courses`);
  }, [
    clearShoppingCartInStateAndLocalStorage,
    modalContext.hideModal,
    history.push,
  ]);

  const handleSuccesfulPayment = useCallback(
    (paymentProvider) => async (paymentOrderID) => {
      setLoading(true);
      const { showModal } = modalContext;

      if (!paymentOrderID || !paymentProvider) {
        setLoading(false);
        return;
      }

      try {
        await createTransactionMutation({
          variables: {
            input: {
              currency,
              discount: discountCode,
              provider: paymentProvider,
              order_id: draftOrderId,
              token: paymentOrderID,
            },
          },
        });

        if (discountPayload) {
          const { discount_id: id, redemptions } = discountPayload;
          const updatedRedemptions = redemptions - 1;
          const input = {
            id,
            redemptions: updatedRedemptions,
          };

          if (updatedRedemptions === 0) {
            input.end_time = Date.now();
          }

          await updateDiscount({
            variables: {
              input,
            },
          });
        }

        // Breakdown courses and submit events separately
        if (orderItems && orderItems.length > 0) {
          orderItems.forEach((orderItem) => {
            gaTracking.trackEvent({
              category: "course",
              action: "purchase",
              label: orderItem.course_id,
              value: orderItem.price_with_discount || orderItem.price,
            });
          });
        }

        showModal(SuccessfulMessage, {
          handleContinueClick: handleModalClick,
          hideModal: handleModalClick,
        });
        setLoading(false);
      } catch (error) {
        showModal(FailedMessage, {
          message: error.message,
        });
        setLoading(false);
      }
    },
    [
      loading,
      setLoading,
      discountCode,
      createTransactionMutation,
      modalContext.showModal,
      gaTracking,
      handleModalClick,
      discountPayload,
    ]
  );

  return { loading, handleSuccesfulPayment };
}
