import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  maxLengthOfStudentsTooltip: {
    id: 'app.components.AdditionalCourseDetails.maxLengthOfStudentsTooltip',
    defaultMessage:
      "When you create a course in Assist or Concierge modes, you should estimate your capabilities to handle a limited number of students at any given point of time. You will be hosting live sessions and providing feedback to your enrolled students. There's only so many you can handle. If your course is sold out at any given point of time, your course will be available only in Flex mode until a place is freed up. You may change the number of students later.",
  },
  timeEstimationTooltip: {
    id: 'app.components.AdditionalCourseDetails.timeEstimationTooltip',
    defaultMessage:
      "How many days would you like to give your student to complete your course in Assist/Concierge modes? The student's place on the course will be freed up as soon as he/she clicks 'Complete button' or the time allowed is up, whichever comes first. His/her place will then be available for sale. Please note the number of days here should allow your students to study at their own pace. We suggest you allow them no less than 60 days. Be generous! You cannot change this after course is activated.",
  },
  newLecture: {
    id: 'app.components.CourseEditTabs.newLecture',
    defaultMessage: 'Name',
  },
  courseInfo: {
    id: 'app.components.CourseEditTabs.courseInfo',
    defaultMessage: 'Course Information',
  },
  courseStructure: {
    id: 'app.components.CourseEditTabs.courseStructure',
    defaultMessage: 'Course Structure',
  },
  maxNumOfUsers: {
    id: 'app.components.AdditionalCourseDetails.maxNumOfUsers',
    defaultMessage: 'Maximum number of users',
  },
  courseSetting: {
    id: 'app.components.AdditionalCourseDetails.courseSetting',
    defaultMessage: 'Course settings',
  },
  timeEstimation: {
    id: 'app.components.AdditionalCourseDetails.timeEstimation',
    defaultMessage: 'Time estimation to complete the course',
  },
  pricePerLevel: {
    id: 'app.components.AdditionalCourseDetails.pricePerLevel',
    defaultMessage: 'Price per level',
  },
  flex: {
    id: 'app.components.AdditionalCourseDetails.flex',
    defaultMessage: 'Flex',
  },
  assist: {
    id: 'app.components.AdditionalCourseDetails.assist',
    defaultMessage: 'Assist',
  },
  concierge: {
    id: 'app.components.AdditionalCourseDetails.concierge',
    defaultMessage: 'Concierge',
  },
  flexText: {
    id: 'app.components.AdditionalCourseDetails.flexText',
    defaultMessage: 'Flex: Video content + supporting materials',
  },
  assistText: {
    id: 'app.components.AdditionalCourseDetails.assistText',
    defaultMessage: 'Assist : Flex + regular web classes',
  },
  conciergeText: {
    id: 'app.components.AdditionalCourseDetails.conciergeText',
    defaultMessage: 'Concierge: Assist + homework',
  },
  finalLetterToStudents: {
    id: 'app.components.AdditionalCourseDetails.finalLetterToStudents',
    defaultMessage: 'Final letter to students',
  },
  finalLetterHeading: {
    id: 'app.components.AdditionalCourseDetails.finalLetterHeading',
    defaultMessage: 'Final letter',
  },
  pleaseWriteFewWellWords: {
    id: 'app.components.AdditionalCourseDetails.pleaseWriteFewWellWords',
    defaultMessage:
      'Please write a few well-wishing words to students who have successfully completed your course. These will be delivered to them in an e-mail once they complete your course.',
  },
  addLecture: {
    id: 'app.components.CourseStructure.addLecture',
    defaultMessage: 'Add',
  },
  coursePreview: {
    id: 'app.components.PreviewUploader.coursePreview',
    defaultMessage: 'Course Preview',
  },
  uploadVideo: {
    id: 'app.components.PreviewUploader.uploadVideo',
    defaultMessage: 'Upload video',
  },
  videoReqs500Mb: {
    id: 'app.components.PreviewUploader.videoReqs500Mb',
    defaultMessage:
      'Video requirements: .mp4, .MOV or .mpg formats, maximum 500Mb size. Recommenations: HD, 720p or 1080p with 16:9 aspect ratio.',
  },
  apples: {
    id: 'app.components.AuthorRating.apples',
    defaultMessage: 'apples',
  },
  tomatoes: {
    id: 'app.components.AuthorRating.tomatoes',
    defaultMessage: 'tomatoes',
  },
  days: {
    id: 'app.components.PurchasedCoursesWebinar.days',
    defaultMessage: 'days',
  },
  pricesConversions: {
    id: 'app.components.AdditionalCourseDetails.pricesConversions',
    defaultMessage: 'Prices in other currencies',
  },
  pricesConversionsModalInfo: {
    id: 'app.components.AdditionalCourseDetails.pricesConversionsModalInfo',
    defaultMessage: "Converted at payment processor's rounded rate",
  },
  mediaHeading: {
    id: 'app.components.AdditionalCourseDetails.mediaHeading',
    defaultMessage: 'Media',
  },
  interactiveFunctionalityforAssist: {
    id:
      'app.components.AdditionalCourseDetails.interactiveFunctionalityforAssist',
    defaultMessage:
      'Interactive functionality for <b>Assist Level</b> (Concierge level  includes both)',
  },
  homeWorkIsAvailable: {
    id: 'app.components.AdditionalCourseDetails.homeWorkIsAvailable',
    defaultMessage: 'Homeworks are available',
  },
  lecturesIsAvailable: {
    id: 'app.components.AdditionalCourseDetails.lecturesIsAvailable',
    defaultMessage: 'Lectures are available',
  },
  interactivityOpptionsWebinars: {
    id: 'app.components.AdditionalCourseDetails.interactivityOpptionsWebinars',
    defaultMessage: 'Web Сlasses',
  },
  interactivityOpptionsHomeworks: {
    id: 'app.components.AdditionalCourseDetails.interactivityOpptionsHomeworks',
    defaultMessage: 'Homeworks',
  },
  homeworkAvailableAtAnyTime: {
    id: 'app.components.AdditionalCourseDetails.homeworkAvailableAtAnyTime',
    defaultMessage: 'At any time',
  },
  homeworkAvailableAfterHomework: {
    id: 'app.components.AdditionalCourseDetails.homeworkAvailableAfterHomework',
    defaultMessage: 'Once previous homework has been submitted',
  },
  availableAfterHomeworkMarkMore: {
    id: 'app.components.AdditionalCourseDetails.availableAfterHomeworkMarkMore',
    defaultMessage: 'Once previous homework has been evaluated for more than 60%',
  },
  messangerUrlHeading: {
    id: 'app.components.AdditionalCourseDetails.messangerUrlHeading',
    defaultMessage: 'Chat',
  },
  messangerUrlLable: {
    id: 'app.components.AdditionalCourseDetails.messangerUrlLable',
    defaultMessage: "Link to your messenger (WhatsApp, Telegram, Slack, etc)",
  },
});
