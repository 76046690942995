// Core
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';

// Components
import TextInput from 'components/Input/Material/TextInput';
import TextAreaInput from 'components/Input/Material/TextAreaInput/TextAreaInput';
import SimpleSelect from 'components/Select/SimpleSelect/SimpleSelect';
import Button from 'components/Button';
import TimePicker from 'components/TimePicker';
import DatePicker from 'components/DatePicker';

// Other
import { getTimeInterval } from 'utils/helpers';
import { FormattedMessage } from 'react-intl';
import { messages } from 'containers/Calendar/messages';

// Styles and Assets
import './EditEventForm.scss';

const checkFields = memoize((obj, excludeKeys = []) => {
  return Object.keys(obj).some(key => {
    if (excludeKeys.includes(key)) return false;
    const val = obj[key];
    return !val && val !== null;
  });
});

const EditEventForm = ({
  values,
  values: { description, title, startDate, courseId, startTime, eventDuration },
  listCourses,
  errors,
  touched,
  handleSubmit,
  handleBlur,
  setFieldValue,
  handleEventDelete
}) => {
  const handleInputChange = name => (inputValue, e) => {
    setFieldValue(name, inputValue);
    if (e) e.persist();
  };

  const handleInputBlur = name => (inputValue, e) => {
    if (e) {
      e.persist();
      handleBlur(e);
    }
    setFieldValue(name, inputValue);
  };

  const disabled = checkFields(values, ['courseId']);

  return (
    <form
      className='sk-edit-event-form'
      noValidate
      autoComplete='off'
      onSubmit={handleSubmit}
    >
      <div className='sk-calendar__input'>
        <FormattedMessage {...messages.addEventName}>
          {msg => (
            <TextInput
              name='title'
              label={msg}
              placeholder={msg}
              value={title}
              onChange={handleInputChange('title')}
              onBlur={handleInputBlur('title')}
              helperText={touched.title ? errors.title : ''}
              error={touched.title && Boolean(errors.title)}
            />
          )}
        </FormattedMessage>
      </div>
      <div className='sk-calendar__input'>
        <FormattedMessage {...messages.eventsDescription}>
          {msg => (
            <TextAreaInput
              name='description'
              label={msg}
              placeholder={msg}
              value={description}
              onChange={handleInputChange('description')}
              onBlur={handleInputBlur('description')}
              helperText={touched.description ? errors.description : ''}
              error={touched.description && Boolean(errors.description)}
            />
          )}
        </FormattedMessage>
      </div>
      <div className='sk-calendar__select'>
        <FormattedMessage {...messages.chooseYourCourse}>
          {msg => (
            <SimpleSelect
              value={courseId || ''}
              name='courseId'
              options={listCourses}
              label={msg}
              placeholder={msg}
              labelKey='label'
              valueKey='id'
              handleChange={handleInputBlur('courseId')}
              helperText={touched.courseId ? errors.courseId : ''}
              error={touched.courseId && Boolean(errors.courseId)}
            />
          )}
        </FormattedMessage>
      </div>
      <div className='sk-calendar__datePicker'>
        <div className='sk-calendar__selectMenu'>
          <FormattedMessage {...messages.startDay}>
            {msg => (
              <DatePicker
                label={msg}
                name='startDate'
                date={startDate}
                handlePickerChange={handleInputChange}
                helperText={touched.startDate ? errors.startDate : ''}
                error={touched.startDate && Boolean(errors.startDate)}
              />
            )}
          </FormattedMessage>
        </div>
        <div className='sk-calendar__selectMenu'>
          <FormattedMessage {...messages.startTime}>
            {msg => (
              <TimePicker
                label={msg}
                name='startTime'
                time={startTime}
                handlePickerChange={handleInputChange}
                helperText={touched.startTime ? errors.startTime : ''}
                error={touched.startTime && Boolean(errors.startTime)}
              />
            )}
          </FormattedMessage>
        </div>
        <div className='sk-calendar__selectMenu'>
          <FormattedMessage {...messages.duration}>
            {msg => (
              <SimpleSelect
                inlineStyles={{
                  form: { paddingTop: 10, marginTop: 10, marginBottom: 4 },
                  selector: { minHeight: 40 }
                }}
                name='eventDuration'
                options={getTimeInterval()}
                value={eventDuration}
                label={msg}
                placeholder={msg}
                handleChange={handleInputChange('eventDuration')}
                labelKey='label'
                valueKey='value'
                withValidation={false}
              />
            )}
          </FormattedMessage>
        </div>
      </div>
      <div className='sk-calendar__button'>
        <Button
          type='button'
          variant='text'
          color='primary'
          size='large'
          onClick={handleEventDelete}
        >
          <FormattedMessage {...messages.delete} />
        </Button>
        <Button
          variant='text'
          color='secondary'
          size='large'
          type='submit'
          disabled={disabled}
        >
          <FormattedMessage {...messages.saveChanges} />
        </Button>
      </div>
    </form>
  );
};

EditEventForm.propTypes = {
  values: PropTypes.object,
  handleSubmit: PropTypes.func,
  errors: PropTypes.object,
  touched: PropTypes.object,
  setFieldValue: PropTypes.func,
  handleEventDelete: PropTypes.func,
  listCourses: PropTypes.array,
  handleBlur: PropTypes.func
};

export default memo(EditEventForm);
