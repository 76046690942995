// Core
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { compose } from 'react-apollo';

// Components
import WebinarGrid from 'components/Webinar/WebinarGrid';
import WebinarRoomCreator from 'containers/WebinarRoom/WebinarRoomCreator';

// Styles and Assets
import './SubscriberWebinar.scss';

export const SubscriberWebinar = ({ match, location }) => {
  const { eventId, courseId } = match.params;
  const sessionId = location?.state?.sessionId;
  const mappingId = location?.state?.mappingId;

  // debugger;

  return (
    <div className="sk-sub-webinar">
      <WebinarGrid>
        <WebinarRoomCreator
          eventId={eventId}
          courseId={courseId}
          sessionId={sessionId}
          mappingId={mappingId}
          participantRole="subscriber"
        />
      </WebinarGrid>
    </div>
  );
};

SubscriberWebinar.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
};

const enhancer = compose(
  memo,
  withRouter
);
export default enhancer(SubscriberWebinar);
