import React from 'react';
import PropTypes from 'prop-types';
import { PurpleHeart, White } from 'components/Icon/color';

const WebinarMicOff = ({ height, width, strokeColor, fillColor, active }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx={width / 2}
      cy={height / 2}
      r={width / 2}
      fill={fillColor}
      fillOpacity={active ? '1' : '0.8'}
    />
    <g clipPath="url(#micclipoff0)">
      <path
        d="M32.5 25C32.2 25 32 25.2 32 25.5V28.3C32 31.9 29.1 34.8 25.5
         34.8C21.9 34.8 19 31.9 19 28.3V25.5C19 25.2 18.8 25 18.5 25C18.2 25
          18 25.2 18 25.5V28.3C18 32.2 21.1 35.5 25 35.7V38.1H22.7C22.4 38.1 22.2
           38.3 22.2 38.6C22.2 38.9 22.4 39.1 22.7 39.1H28.3C28.6 39.1 28.8 38.9
            28.8 38.6C28.8 38.3 28.6 38.1 28.3 38.1H26V35.7C29.9 35.5 33 32.2 33
             28.3V25.5C32.9 25.2 32.7 25 32.5 25Z"
        fill="white"
      />
      <path
        d="M36.9608 15.3194L13.5527 32.6078L14.1468 33.4122L37.5549 16.1238L36.9608 15.3194Z"
        fill={strokeColor}
      />
      <path
        d="M30.2001 22.5V28.4C30.2001 31 28.1001 33.1 25.5001 33.1C23.5001 33.1 21.7001 31.8 21.1001 30"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.7998 26V23.3V18.2C20.7998 15.6 22.8998 13.5 25.4998 13.5C28.0998 13.5 30.1998 15.6 30.1998 18.2"
        stroke={strokeColor}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="micclipoff0">
        <rect
          width="27"
          height="27"
          fill="white"
          transform="translate(12 12)"
        />
      </clipPath>
    </defs>
  </svg>
);

WebinarMicOff.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  strokeColor: PropTypes.string,
  fillColor: PropTypes.string,
  active: PropTypes.bool,
};

WebinarMicOff.defaultProps = {
  height: '50',
  width: '50',
  strokeColor: White,
  fillColor: PurpleHeart,
  active: false,
};

export default WebinarMicOff;
