import React from 'react';
import PropTypes from 'prop-types';
import { Grey } from 'components/Icon/color';

const WebinarScreen = ({
  strokeColor = Grey,
  height = 19,
  width = 20,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="0.5"
      width="19"
      height="12.7143"
      rx="1.5"
      stroke={strokeColor}
    />
    <line
      x1="10.0654"
      y1="13.3569"
      x2="10.0654"
      y2="17.4998"
      stroke={strokeColor}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="12.5439"
      y1="17.6431"
      x2="7.45699"
      y2="17.6431"
      stroke={strokeColor}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.7543 6.68244L13.0074 6.25456C13.4656 6.52556 13.4656 7.18858 13.0074 7.45958L9.25246 9.68037C8.78584 9.95634 8.19612 9.61999 8.19612 9.07785L8.19612 4.63629C8.19612 4.09416 8.78583 3.7578 9.25246 4.03378M12.7543 6.68244L9.25246 4.03378M12.7543 6.68244L13.0074 6.25456M12.7543 6.68244L13.0074 6.25456M9.25246 4.03378L13.0074 6.25456M9.25246 4.03378L13.0074 6.25456"
      stroke={strokeColor}
    />
  </svg>
);

WebinarScreen.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  strokeColor: PropTypes.string,
};

export default WebinarScreen;
