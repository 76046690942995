import React from 'react';
import PropTypes from 'prop-types';

import './Edit.scss';

const Edit = ({color = '#30d5c8'}) => {
  const index = Math.random();
  return(
    <svg class="edit-svg" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g className="outlined">
        <path d="M24.4008 4.06465L21.8657 1.58938C21.0841 0.826177 19.7954 0.826177 19.0137 1.61001L17.1547 3.50772L3.31738 16.9773C3.23288 17.0598 3.04275 17.2867 3.06388 17.3486L1.01469 24.5062C0.930187 24.795 1.22595 25.0426 1.50058 24.9394L7.52139 22.6292C7.64814 22.5879 7.90165 22.4229 8.04953 22.2991L22.4995 8.72641L24.4008 6.78745C25.1613 6.02424 25.1613 4.80723 24.4008 4.06465Z" stroke={`url(#paint${index}0_linear)`} stroke-miterlimit="10"/>
        <path d="M3.16772 17.1216L8.04775 22.299" stroke={`url(#paint${index}1_linear)`} stroke-miterlimit="10"/>
        <path d="M17.6616 2.97168L23.0698 8.14912" stroke={`url(#paint${index}2_linear)`} stroke-miterlimit="10"/>
      </g>
      <g className="filled">
      <path d="M24.4008 4.06465L21.8657 1.58938C21.0841 0.826177 19.7954 0.826177 19.0137 1.61001L17.1547 3.50772L3.31738 16.9773C3.23288 17.0598 3.04275 17.2867 3.06388 17.3486L1.01469 24.5062C0.930187 24.795 1.22595 25.0426 1.50058 24.9394L7.52139 22.6292C7.64814 22.5879 7.90165 22.4229 8.04953 22.2991L22.4995 8.72641L24.4008 6.78745C25.1613 6.02424 25.1613 4.80723 24.4008 4.06465Z" stroke="white" fill={`url(#paint${index}0_linear)`} stroke-miterlimit="10"/>
      <path d="M3.16772 17.1216L8.04775 22.299" stroke="white" fill={`url(#paint${index}1_linear)`} stroke-miterlimit="10"/>
      <path d="M17.6616 2.97168L23.0698 8.14912" stroke="white" fill={`url(#paint${index}2_linear)`} stroke-miterlimit="10"/>
      </g>
      <defs>
        <linearGradient id={`paint${index}0_linear`} x1="27.1416" y1="26.5749" x2="-2.85331" y2="22.8912" gradientUnits="userSpaceOnUse">
        <stop stop-color="#8F26AF"/>
        <stop offset="0.979167" stop-color="#141685"/>
        </linearGradient>
        <linearGradient id={`paint${index}1_linear`} x1="8.48961" y1="22.6475" x2="2.37295" y2="21.9403" gradientUnits="userSpaceOnUse">
        <stop stop-color="#8F26AF"/>
        <stop offset="0.979167" stop-color="#141685"/>
        </linearGradient>
        <linearGradient id={`paint${index}2_linear`} x1="23.5595" y1="8.49761" x2="16.8012" y2="7.63163" gradientUnits="userSpaceOnUse">
        <stop stop-color="#8F26AF"/>
        <stop offset="0.979167" stop-color="#141685"/>
        </linearGradient>
      </defs>
    </svg>
  )
}


export default Edit;
