// Core
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import { compose, graphql } from 'react-apollo';
import { injectIntl, intlShape } from 'react-intl';

// Components
import Apps from 'components/Icon/Svg/Apps';
import User from 'components/Icon/Svg/User';
import AuthBlock from 'containers/Header/AuthBlock';
import UserMenu from 'components/UserMenu';
import ExpandableSidebarMenu from '../ExpandableSidebarMenu';
import MobileSidebarMenuItem from './MobileSidebarMenuItem';

// GraphQl
import LIST_CATALOG from 'queries/Courses/listCatalog.gql';
import GET_SUBCATEGORIES_WITH_COURSES_LIST from 'queries/Courses/getSubcategoriesWithCoursesList.gql';

// Other
import { DarkGrey as iconColor } from 'components/Icon/color';
import { CATALOG_AMOUNT } from 'utils/enums';
import { getCatalogItems } from 'utils/reducers';

const mobileListClasses = {
  root: 'mui-override sk-mobile-sidebar-list',
};

class MobileSidebarMenu extends PureComponent {
  state = {
    openedListName: '',
  };

  handleListToggle = (listToOpenName, openedListName) =>
    this.setState({
      openedListName: listToOpenName === openedListName ? null : listToOpenName,
    });

  render() {
    const { openedListName } = this.state;
    const {
      user,
      categories,
      subcategories,
      subcategoriesWithCourses,
      intl: { formatMessage },
    } = this.props;
    const headerHeight = '77px';

    return (
      <div style={{ paddingTop: headerHeight }}>
        <List classes={mobileListClasses}>
          <MobileSidebarMenuItem
            icon={Apps}
            iconProps={{ color: iconColor }}
            expandable
            label="Catalog"
            open={openedListName === 'catalog'}
            handleClick={() => this.handleListToggle('catalog', openedListName)}
          >
            {openedListName === 'catalog' && (
              <ExpandableSidebarMenu
                items={getCatalogItems(
                  categories?.items,
                  subcategories?.items,
                  formatMessage,
                  subcategoriesWithCourses
                )}
              />
            )}
          </MobileSidebarMenuItem>

          {user ? (
            <MobileSidebarMenuItem
              icon={User}
              iconProps={{ fillColor: iconColor }}
              expandable
              label="Account"
              open={openedListName === 'account'}
              handleClick={() =>
                this.handleListToggle('account', openedListName)
              }
            >
              {openedListName === 'account' && <UserMenu role={user && user.role} />}
            </MobileSidebarMenuItem>
          ) : (
            <div className="sk-mobile-sidebar-menu_footer">
              <AuthBlock />
            </div>
          )}
        </List>
      </div>
    );
  }
}

MobileSidebarMenu.propTypes = {
  user: PropTypes.object,
  categories: PropTypes.object,
  subcategories: PropTypes.object,
  intl: intlShape,
};

const listCatalogQuery = graphql(LIST_CATALOG, {
  props: ({
    data: {
      loading,
      error: fetchError,
      categories,
      subcategories,
      ...ownProps
    },
  }) => ({
    loading,
    categories,
    subcategories,
    fetchError,
    ...ownProps,
  }),
  options: () => ({
    fetchPolicy: 'cache-first',
    variables: {
      limit: CATALOG_AMOUNT,
    },
  }),
});

const getSubcategoriesWithCoursesList = graphql(GET_SUBCATEGORIES_WITH_COURSES_LIST, {
  props: ({
    data: { loading, error: fetchError, getSubcategoriesWithCoursesList, ...ownProps},
  }) => ({
    loading,
    subcategoriesWithCourses: getSubcategoriesWithCoursesList,
    fetchError,
    ...ownProps,
  }),
});

const enhancer = compose(
  injectIntl,
  getSubcategoriesWithCoursesList,
  listCatalogQuery
);

export default enhancer(MobileSidebarMenu);
