import { createContext } from 'react';

export const AuthenticatedUserContext = createContext({
  userContext: {
    isProfileVerified: false,
    isUserAuthorithed: false,
    isAuthenticated: false,
    profile: {},
    verifyFederatedIdentity: () => {},
    getProfile: () => {},
  },
});
