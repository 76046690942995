import React from 'react';
import PropTypes from 'prop-types';

const DocApproved = ({ height, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 44 52"
  >
    <g fill="none" fillRule="evenodd">
      <g stroke="#444" strokeLinecap="round" strokeLinejoin="round">
        <path
          d="M33.659 41.887V14.324L21.39 1.422H7.467C4.14
          1.422 1.42 4.14 1.42 7.466v25.541"
        />
        <path
          d="M1.421 30.08V41.72c0 3.323 2.721 6.044
          6.046 6.044h20.147c3.325 0 6.045-2.72 6.045-6.044M21.57 
          1.76v10.352c0 1.05.852 1.902 1.901 1.902h9.862L21.57 1.76z"
        />
      </g>
      <path
        fill="#FFF"
        d="M43.455 38.39c.062 6.728-5.262 12.233-11.894
         12.296-6.631.063-12.058-5.34-12.12-12.07-.062-6.727
          5.263-12.234 11.895-12.297 6.63-.062 12.058 5.34 12.12 12.07"
      />
      <path
        stroke="#30d5c8"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M43.455 38.39c.062 6.728-5.262 12.233-11.894
         12.296-6.631.063-12.058-5.34-12.12-12.07-.062-6.727
          5.263-12.234 11.895-12.297 6.63-.062 12.058 5.34 12.12 12.07z"
      />
      <path fill="#FFF" d="M25.232 37.39l4.704 5.659 7.728-9.296" />
      <path
        stroke="#30d5c8"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M25.232 37.39l4.704 5.659 7.728-9.296"
      />
    </g>
  </svg>
);

DocApproved.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

DocApproved.defaultProps = {
  height: '44',
  width: '52',
};

export default DocApproved;
