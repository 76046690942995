import React from "react";
import PropTypes from "prop-types";

const AI = ({ height, width }) => (
  <svg
    height={height}
    version="1.1"
    viewBox="0 0 32 32"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fill="#157EFB">
        <path
          // eslint-disable-next-line max-len
          d="M8.00684834,10 C6.34621185,10 5,11.3422643 5,12.9987856 L5,20.0012144 C5,21.6573979 6.33599155,23 8.00684834,23 L24.9931517,23 C26.6537881,23 28,21.6577357 28,20.0012144 L28,12.9987856 C28,11.3426021 26.6640085,10 24.9931517,10 L8.00684834,10 L8.00684834,10 Z M7.99456145,11 C6.89299558,11 6,11.9001762 6,12.992017 L6,20.007983 C6,21.1081436 6.90234375,22 7.99456145,22 L25.0054385,22 C26.1070044,22 27,21.0998238 27,20.007983 L27,12.992017 C27,11.8918564 26.0976562,11 25.0054385,11 L7.99456145,11 L7.99456145,11 Z M19,14 L19,19 L18,19 L18,20 L21,20 L21,19 L20,19 L20,14 L21,14 L21,13 L18,13 L18,14 L19,14 L19,14 Z M15,17 L12,17 L12,20 L11,20 L11,16.5 L11,15 C11,13.8877296 11.897616,13 13.0048815,13 L13.9951185,13 C15.1061002,13 16,13.8954305 16,15 L16,17 L16,20 L15,20 L15,17 L15,17 L15,17 Z M12.9989566,14 C12.4472481,14 12,14.4438648 12,15 L12,16 L15,16 L15,15 C15,14.4477153 14.5573397,14 14.0010434,14 L12.9989566,14 L12.9989566,14 Z"
        />
      </g>
    </g>
  </svg>
);

AI.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

AI.defaultProps = {
  height: "32",
  width: "32",
};

export default AI;
