// Core
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

// Components
import UserAvatar from 'components/UserAvatar';
import Text from 'components/Text';

// Styles and Assets
import './WebinarRoomChatList.scss';

const classes = {
  list: {
    root: 'mui-override sk-webroom-chat-list',
  },
  item: {
    root: 'mui-override sk-webroom-chat-list__item',
  },
  itemText: {
    root: 'mui-override sk-webroom-chat-list__item-text',
    secondary: 'mui-override sk-webroom-chat-item-sec',
    primary: 'mui-override sk-webroom-chat-item-prim',
  },
};

const SecondaryText = memo(({ userName, time }) => (
  <>
    <Text size="caption" classes="sk-webroom-chat-item-sec__name">
      {userName}
    </Text>
    <Text size="caption" classes="sk-webroom-chat-item-sec__time">
      {time}
    </Text>
  </>
));

const getUserAvatarProps = signal => ({
  firstName: signal.userName.split(' ')[0],
  lastName: signal.userName.split(' ')[1],
  avatar: signal.photo,
});

const WebinarRoomChatList = ({ signals }) => (
  <List classes={classes.list}>
    {signals.map(signal => (
      <ListItem key={signal.timestamp} classes={classes.item}>
        <div className="sk-webroom-chat-list__avatar">
          <UserAvatar {...getUserAvatarProps(signal)} />
        </div>
        <ListItemText
          classes={classes.itemText}
          primary={signal.data}
          secondary={
            <SecondaryText userName={signal.userName} time={signal.time} />
          }
        />
      </ListItem>
    ))}
  </List>
);

WebinarRoomChatList.propTypes = {
  signals: PropTypes.array,
};

export default memo(WebinarRoomChatList);
