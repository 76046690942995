import { defineMessages } from "react-intl";

export const messages = defineMessages({
  defaultTitle: {
    id: "app.components.MetaData.defaultTitle",
    defaultMessage:
      "AVDO Education official site | Interactive Online Courses for Creative Minds",
  },
  defaultDescription: {
    id: "app.components.MetaData.defaultDescription",
    defaultMessage:
      "AVDO Education is the largest community for creatives. Online courses for creative minds. Learn with top professionals in the creative field. Join more than a million users who are already learning. Online courses. Enjoy Learning From Home. At Your Own Pace. The Creative Community. Courses: Illustration, Craft",
  },
  aboutUsTitle: {
    id: "app.components.MetaData.aboutUs.title",
    defaultMessage: "About Us",
  },
  signupTeachOnlineTitle: {
    id: "app.components.MetaData.signup.teachOnlineTitle",
    defaultMessage: "Teach online",
  },
  signupBecomeStudentTitle: {
    id: "app.components.MetaData.signup.becomeStudentTitle",
    defaultMessage: "Become a student",
  },
  companySignupTitle: {
    id: "app.components.MetaData.signup.companySignupTitle",
    defaultMessage: "Company Sign Up",
  },
  coursesOnlineCoursesTitle: {
    id: "app.components.MetaData.courses.title",
    defaultMessage: "Online courses",
  },
  contactUsTitle: {
    id: "app.components.MetaData.contactUs.title",
    defaultMessage: "Online courses",
  },
});
