import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import NoSsr from '@material-ui/core/NoSsr';

// components
import Text from 'components/Text';
import SuggestionsList from './SuggestionsList';
import SuggestionPopper from 'components/Select/AutoComplete/SuggestionPopper';
import AutoCompleteInput from './AutoCompleteInput';

// Styles
import './AutoComplete.scss';
import Suggestion from './Suggestion/Suggestion';

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control({
  innerRef,
  children,
  innerProps,
  selectProps: {
    textFieldProps,
    controlProps: { error, helperText, label, value, shrinkLabel },
  },
}) {
  return (
    <AutoCompleteInput
      fullWidth
      shrink={shrinkLabel || !!value}
      label={label}
      inputProps={{
        inputComponent,
        inputProps: {
          className: 'mui-override sk-rs-autocomplete_control',
          inputRef: innerRef,
          children,
          ...innerProps,
        },
      }}
      value={value}
      error={error}
      helperText={helperText}
      {...textFieldProps}
    />
  );
}

const Option = ({ innerRef, isFocused, innerProps, children }) => (
  <Suggestion
    buttonRef={innerRef}
    selected={isFocused}
    component="div"
    label={children}
    {...innerProps}
  />
);

const SingleValue = ({ innerProps, children }) => (
  <Text classes="sk-rs-autocomplete_single-value" {...innerProps}>
    {children}
  </Text>
);

const ValueContainer = ({ children }) => (
  <div className="sk-rs-autocomplete_value-container">{children}</div>
);

function Menu({ innerProps, children }) {
  return <SuggestionPopper {...innerProps}>{children}</SuggestionPopper>;
}

const components = {
  Control,
  Menu,
  MenuList: SuggestionsList,
  Option,
  SingleValue,
  ValueContainer,
};

let Select = () => <></>;

class AutoComplete extends PureComponent {
  state = {
    shrinkLabel: false,
    loaded: false,
  };

  async componentDidMount() {
    if (!window.isServer) {
      const component = await import('./Select');
      Select = component.default;
    }
    this.setState({ loaded: true });
  }

  handleChange = value => {
    const { handleSearch } = this.props;
    handleSearch(value || '');
  };

  handleSearch = query => {
    if (query.length < 1) this.setState({ shrinkLabel: false });
    if (query.length === 1) this.setState({ shrinkLabel: true });
    const { handleSearch } = this.props;
    if (query.length >= 1) {
      handleSearch(query);
    }
  };

  handleInputFocus = () => {
    const { value } = this.props;
    const { shrinkLabel } = this.state;
    if (!value && !shrinkLabel) this.setState({ shrinkLabel: !shrinkLabel });
  };

  getValue = (value, options) => {
    if (!value || typeof value === 'object') {
      return value;
    }
    const currentOption = options?.find(option => option.value === value);
    if (currentOption) {
      return currentOption;
    }
    return { value, label: value };
  };

  render() {
    const { shrinkLabel } = this.state;
    const {
      options,
      value,
      labelKey,
      valueKey,
      disabled,
      label,
      helperText,
      error,
      loading,
      fetchError,
      isSearchable,
      isClearable,
      placeholder = ""
    } = this.props;

    return (
      <NoSsr>
        <Select
          options={options || []}
          getOptionLabel={option => option[labelKey]}
          getOptionValue={option => option[valueKey]}
          components={components}
          value={this.getValue(value, options)}
          onChange={this.handleChange}
          onInputChange={this.handleSearch}
          onFocus={this.handleInputFocus}
          placeholder={placeholder}
          isClearable={isClearable}
          isSearchable={isSearchable}
          isDisabled={disabled}
          controlProps={{
            shrinkLabel,
            error,
            helperText,
            label,
            value: this.getValue(value, options)[labelKey],
          }}
          listCustomProps={{
            loading,
            fetchError,
          }}
        />
      </NoSsr>
    );
  }
}

AutoComplete.defaultProps = {
  isSearchable: true,
  isClearable: true,
};

AutoComplete.propTypes = {
  options: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  helperText: PropTypes.string,
  error: PropTypes.any,
  loading: PropTypes.bool,
  fetchError: PropTypes.object,
  handleSearch: PropTypes.func,
  isSearchable: PropTypes.bool,
  isClearable: PropTypes.bool,
};

ValueContainer.propTypes = {
  children: PropTypes.any,
};

export default AutoComplete;
