// Core
import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { compose } from 'react-apollo';

// Components
import Toggler from 'components/Toggler';
import Text from 'components/Text';
import Link from 'components/Link';

// Other
import withAction from 'components/Icon/withAction';

// Styles and Assets
import '../SidebarMenu.scss';
import './SidebarMenuItem.scss';

class SidebarMenuItem extends PureComponent {
  renderListItem = () => {
    const {
      icon: Icon,
      label,
      expandable,
      open,
      handleClick,
      children,
      iconProps,
      togglerProps = {},
      withoutBorder,
      linkTo,
      match,
      onChevronHandler,
    } = this.props;

    const active = match.url.includes(linkTo) || open;

    return (
      <li
        className={classNames('sk-sidebar-menu-item', {
          'sk-sidebar-menu__list-item_opened': open,
          'sk-sidebar-menu-item_expandable': expandable,
          'sk-sidebar-menu-item_withoutBorder': withoutBorder,
        })}
      >
        <div className={classNames('sk-sidebar-menu-item__btn', {
          'sk-sidebar-menu-item__btn_expandable': expandable && open,
        })}>
          <button
            type="button"
            onClick={handleClick}
          >
            <div className="sk-sidebar-menu-item__name">
              <span className="sk-sidebar-menu-item__icon">
                <Icon {...iconProps} />
              </span>
              <Text
                accent={active}
                classes="sk-sidebar-menu-item__label"
                semiBold
              >
                {label}
              </Text>
            </div>
          </button>

            {expandable && (
              <span onClick={onChevronHandler}>
                {
                  <Toggler
                    active={!!open}
                    chevronUpProps={{ width: '13', height: '8' }}
                    chevronDownProps={{ width: '13', height: '8' }}
                    {...togglerProps}
                  />
                }
              </span>
            )}
        </div>
        {expandable && (
          <div className="sk-sidebar-menu_expanded">{children}</div>
        )}
      </li>
    );
  };

  render() {
    const { linkTo, onClose } = this.props;

    return (
      <Fragment>
        {linkTo ? (
          <Link to={linkTo} onClick={onClose || undefined}>
            {this.renderListItem()}
          </Link>
        ) : (
          <Fragment>{this.renderListItem()}</Fragment>
        )}
      </Fragment>
    );
  }
}

SidebarMenuItem.propTypes = {
  icon: PropTypes.func,
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  expandable: PropTypes.bool,
  open: PropTypes.bool,
  action: PropTypes.bool,
  handleClick: PropTypes.func,
  children: PropTypes.any,
};

const enhacner = compose(
  withRouter,
  withAction
);

export default enhacner(SidebarMenuItem, true);
