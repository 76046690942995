// Core
import React from 'react';
import PropTypes from 'prop-types';

// Styles and Assets
import { PurpleHeart, White } from 'components/Icon/color';

const ArrowToTop = ({ height, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 65 65"
  >
    <g fill="none" fillRule="nonzero">
      <circle
        cx="32.5"
        cy="32.5"
        r="32.5"
        fill={PurpleHeart}
        fillOpacity=".343"
      />
      <path
        stroke={White}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M43.357 38.357l-10.95-10.812-10.952 10.812"
      />
    </g>
  </svg>
);

ArrowToTop.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

ArrowToTop.defaultProps = {
  height: '65',
  width: '65',
};

export default ArrowToTop;
