// Core
import React, { PureComponent, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { compose, graphql } from 'react-apollo';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

// Components
import TextInput from 'components/Input/Material/TextInput/TextInput';
import Text from 'components/Text';
import Pagination from 'components/Pagination';
import ContentHeader from 'containers/ContentHeader';
import CoursesTable from 'routes/AdminPanel/ToppingsList/CoursesTable/CoursesTable';
import Loader from 'components/Loader';
import SearchLoop from 'components/Icon/Svg/SearchLoop';

// GraphQl
import GET_ALL_COURSES from 'queries/AdminPanel/getListCourses.gql';

// Other
// import { hasLoader } from 'containers/HOCs/hasLoader';
import { Grey3 } from 'components/Icon/color';
import { messages } from 'routes/AdminPanel/messages.js';
import { isEmptyObject, isArraysEqual, isBlank } from  'utils/helpers/index';

// Styles and Assets
import './index.scss';

const COURSES_PER_PAGE_COUNT = 7;
const DEFAULT_GET_TOPPINGS_QUERY = { filter: [{ key: 'type', values: ['topping'], }], sort_by: 'createdAt', order: 'desc' };

class CoursesList extends PureComponent {
  nextPage = async () => {
    const { skip, limit, total, refetchCourses, query, } = this.props;
    const newSkip = skip + limit;
    const isCoursesExist = newSkip <= total;
    if (isCoursesExist) await refetchCourses({ input: { limit, skip: newSkip, query, }});
  };

  backPage = async () => {
    const { skip, limit, refetchCourses, query, } = this.props;
    const newSkip = skip - limit;
    const isCoursesExist = newSkip >= 0;

    if (isCoursesExist) await refetchCourses({ input: { limit, skip: newSkip, query, }});
  };

  handleSort = async ({ sortBy, sortOrder, filterKey, filterValue }) => {
    const { refetchCourses, limit, query: { sort_by, order, filter, search_key, search_value, } } = this.props;
    const filters = filter && filter !== null ? filter : [];

    let newFilters = [...filters];
    const oldFilter = newFilters.find(filter => filterKey === filter.key);

    const isNewFilter = !(!!oldFilter) && !isBlank(filterKey);
    const newFilter = isNewFilter && { key: filterKey, values: filterValue };

    const isFilterChanged = oldFilter && filterValue && !isArraysEqual(oldFilter.values, filterValue);

    if (isNewFilter) {
      newFilters.push(newFilter);
    } else if (isFilterChanged) {
      oldFilter.values = filterValue;
    };

    const input = {
      skip: 0, // [int]
      limit, // [int], value = 7
      query: {
        search_key, // search only by this field
        search_value,// [string]
        filter: newFilters,
        sort_by: sortBy ? sortBy : sort_by, // default
        order: sortOrder ? sortOrder : order, // desc
    }};

    await refetchCourses({ input });
  }

  handleSearchEnter = async (searchValue, evt) => {
    const isUserEnterClicked = evt.key === 'Enter';

    if (isUserEnterClicked) {
      const { refetchCourses, limit, query, } = this.props;

      const input = {
        skip: 0, // [int]
        limit, // [int], value = 7
        query: {
          ...query,
          search_key: 'title', // search only by this field
          search_value: searchValue // [string]
      }};

      await refetchCourses({ input })
    }
  };

  render() {
    const { error, loading, courses, total, skip, limit, query, } = this.props;

    if (loading) return (<Loader center />)

    const currentPage = skip / limit;

    return (
      <Fragment>
        <div className="sk-adminUsersListPanel__contentHeader">
          <div className="sk-coursesList__contentHeader__left">
            <ContentHeader />
            <Text size="small" className="sk-adminUsersListPanel__panelName">
              <FormattedMessage {...messages.masterclassesAndPodcasts} />
            </Text>
          </div>

          <div className="sk-adminUsersListPanel__contentHeader__right">
              <div className="sk-adminUsersListPanel__contentHeader__right__search">
                <TextInput
                  type='string'
                  defaultValue={query?.search_value}
                  placeholder='Search'
                  onKeyPress={this.handleSearchEnter}
                />
              </div>
              <div className="sk-adminUsersListPanel__contentHeader__right__icon"><SearchLoop height="17" width="18" color={Grey3} /></div>
            </div>
        </div>

        <CoursesTable
          sortBy={query?.sort_by || ''}
          sortOrder={query?.order || ''}
          filters={query?.filter || []}
          error={error}
          loading={loading}
          courses={courses}
          currentPage={currentPage}
          handleSort={this.handleSort}
        />
        {total > COURSES_PER_PAGE_COUNT && (
          <div className="sk-coursesList__pagination">
            <Pagination
              page={currentPage}
              count={total}
              handleChangePage={(e, selectedPage) => {
                if (currentPage > selectedPage) this.backPage()
                else if (currentPage < selectedPage) this.nextPage()
              }}
              rowsPerPage={COURSES_PER_PAGE_COUNT}
            />
          </div>
        )}
      </Fragment>
    );
  }
}

const getAllCourses = graphql(GET_ALL_COURSES, {
  props: ({ data: { error, loading, adminListCourses, refetch, ...ownProps } }) => {

    if (error) throw Error('Something went wrong, while receiving courses');
    if (loading) return { loading, courses: [], };

    const { items: courses, total, skip, limit, query, } = adminListCourses;

    return {
      error,
      loading,
      courses,
      total,
      skip,
      limit,
      query,
      refetchCourses: refetch,
      ...ownProps,
    }
  },
  options: () => ({
    fetchPolicy: 'cache-and-network',
    variables: {
      input: { skip: 0, limit: COURSES_PER_PAGE_COUNT, query: DEFAULT_GET_TOPPINGS_QUERY },
    },
  }),
});

const enhancer = compose(
  withRouter,
  getAllCourses
);

export default enhancer(CoursesList);
