// Core
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';

// Services
import { CurrencyContext } from './currency-context';
import { localStore } from 'services/localStorage';
import { roundNumberTo, isBlank } from 'utils/helpers';
import { withGeolocation } from 'utils/helpers/withGeolocation';

// GraphQL
import LIST_CURRENCIES_QUERY from './query/listCurrencies.gql';
import GET_GEO_COUNTRY_QUERY from './query/getGeoCountry.gql';
import GET_COUNTRY_CURRENCY_QUERY from './query/getCountryCurrency.gql';
import GET_USER_CURRENCY from './query/getUserInterfaceCurrency.gql';
import UPDATE_USER_INTERFACE_CURRENCY from './query/updateUserInterfaceCurrency.gql';

const DEFAULT_CURRENCY = 'gbp';
const storedCurrency = localStore.getItem('currency');

class CurrencyProvider extends PureComponent {
  state = {
    currency: storedCurrency || DEFAULT_CURRENCY,
    initLocalStoredCurrency: storedCurrency,
  };

  componentDidUpdate() {
    const { initLocalStoredCurrency } = this.state;
    const { currencies, userCurrency, countryCurrencyLabel } = this.props;
    const isUserSignedIn = !!userCurrency;
    const countryCurrency = countryCurrencyLabel
      ? currencies?.find(({ label }) => label === countryCurrencyLabel)
      : null;
    const countryCurrencyId = countryCurrency?.currency_id; // eslint-disable-line

    // console.log('countryCurrencyLabel', countryCurrencyLabel);

    if (
      initLocalStoredCurrency &&
      isUserSignedIn &&
      userCurrency !== initLocalStoredCurrency
    ) {
      localStore.setItem('currency', userCurrency);
      this.setState({ currency: userCurrency }); // eslint-disable-line
    } else if (!initLocalStoredCurrency && !isBlank(countryCurrency)) {
      localStore.setItem('currency', countryCurrencyId);
      this.setState({ // eslint-disable-line
        initLocalStoredCurrency: countryCurrencyId,
        currency: countryCurrencyId,
      });
    }
  }

  getCurrencyProperties = (currency) => {
    const { currencies } = this.props;
    const currentCurrencyProperties =
      currencies && currencies.find(c => c.currency_id === currency);

    return currentCurrencyProperties && currentCurrencyProperties;
  };

  changeDependOnCurrencyRate = (price, currency) => {
    if ((!price && price !== 0) || typeof price !== 'number') return '--';
    if (price === 0) return '0';

    const currencyProperties = this.getCurrencyProperties(currency);
    if (!currencyProperties) return '--';

    const rate = currencyProperties?.rate || 1;
    const changedPrice = rate * price;

    let roundedChangedPrice = roundNumberTo(changedPrice, 1);
    if (roundedChangedPrice >= 1) roundedChangedPrice = roundNumberTo(changedPrice, 0);

    return roundedChangedPrice || '--';
  }

  changeDependOnCurrentCurrencyRate = price => {
    if (typeof price !== 'number') return '--';
    return this.changeDependOnCurrencyRate(price, this.state.currency);
  };

  getCurrentCurrencySign = () => {
    const currentCurrencyProperties = this.getCurrencyProperties(this.state.currency);
    return currentCurrencyProperties && currentCurrencyProperties.icon;
  };

  updateCurrency = currency => {
    this.setState({ currency }, async () => {
      const { userCurrency, updateUserInterfaceCurrency } = this.props;
      const isUserSignedIn = !!userCurrency;

      try {
        if (isUserSignedIn)
          await updateUserInterfaceCurrency({ interface_currency: currency });
      } catch (error) {
        throw Error(error);
      }

      localStore.setItem('currency', currency);
    });
  };

  render() {
    const { currency } = this.state;
    const { children, currencies } = this.props;

    return (
      <CurrencyContext.Provider
        value={{
          currencyContext: {
            currency,
            currencies,
            currencyProperties: this.getCurrencyProperties(currency),
            updateCurrency: this.updateCurrency,
            changeDependOnCurrentCurrencyRate: this.changeDependOnCurrentCurrencyRate,
            getCurrentCurrencySign: this.getCurrentCurrencySign,
            changeDependOnCurrencyRate: this.changeDependOnCurrencyRate,
          },
        }}
      >
        {React.Children.only(children)}
      </CurrencyContext.Provider>
    );
  }
}

CurrencyProvider.propTypes = {
  children: PropTypes.element.isRequired,
  userCurrency: PropTypes.string,
  currencies: PropTypes.arrayOf(
    PropTypes.shape({
      currency_id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      rate: PropTypes.number.isRequired,
    })
  ),
};

const listCurerncies = graphql(LIST_CURRENCIES_QUERY, {
  props: ({
    data: { loading, error: fetchError, listCurrencies, ...ownProps },
  }) => ({
    loading,
    currencies: listCurrencies && listCurrencies.items,
    fetchError,
    ...ownProps,
  }),
  options: () => ({
    fetchPolicy: 'cache-and-network',
  }),
});

const getCountryCurrency = graphql(GET_COUNTRY_CURRENCY_QUERY, {
  skip: ({ countryName }) => isBlank(countryName),
  props: ({ data: { loading, error: fetchError, listCountries, ...ownProps }}) => {
    return {
      loading,
      countryCurrencyLabel: !loading && (listCountries?.countries[0]?.currency || 'USD'),
      fetchError,
      ...ownProps,
    }
  },
  options: ({ countryName }) => ({
    fetchPolicy: 'cache-and-network',
    variables: {
      input: { name: countryName || '', size: 1 },
    },
  }),
});

const getUserCurrency = graphql(GET_USER_CURRENCY, {
  props: ({
    data: { loading, error: fetchError, getProfile, ...ownProps },
  }) => ({
    loading,
    fetchError,
    userCurrency: getProfile?.interface_currency, // eslint-disable-line
    ...ownProps,
  }),
});

const getUserAgentGeo = graphql(GET_GEO_COUNTRY_QUERY, {
  skip: ({ coords }) => isBlank(coords),
  props: ({
    data: { loading, error: fetchError, getCountryFromLatLong, ...ownProps },
  }) => ({
    loading,
    countryName: getCountryFromLatLong?.countryName,
    fetchError,
    ...ownProps,
  }),
  options: ({ coords, ...props }) => {
    const latitude = coords ? coords.latitude : 29.749907;
    const longitude = coords ? coords.longitude : -95.358421;

    return {
      fetchPolicy: 'cache-and-network',
      variables: {
        latitude,
        longitude,
      },
    };
  },
});

const updateProfileMutation = graphql(UPDATE_USER_INTERFACE_CURRENCY, {
  props: ({ mutate }) => ({
    updateUserInterfaceCurrency: input => mutate({ variables: { input } }),
  }),
  options: {
    refetchQueries: ['getProfile'],
  },
});

const enhancer = compose(
  updateProfileMutation,
  listCurerncies,
  getUserCurrency,
  // withGeolocation,
  getUserAgentGeo,
  getCountryCurrency
);

export default enhancer(CurrencyProvider);
