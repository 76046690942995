import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  course: {
    id: 'app.components.NearestWebinarInfo.course',
    defaultMessage: 'Course.',
  },
  end: {
    id: 'app.components.NearestWebinarInfo.end',
    defaultMessage: 'End',
  },
  start: {
    id: 'app.components.NearestWebinarInfo.start',
    defaultMessage: 'Start',
  },
  webinar: {
    id: 'app.components.NearestWebinarInfo.webinar',
    defaultMessage: 'Web Class',
  },
});
