// Core
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import MenuList from '@material-ui/core/MenuList';

// Components
import UploadMenuItem from 'containers/Header/UploadBlock/UploadMenu/UploadMenuItem';

// Styles and Assets
const menuListClasses = {
  padding: 'mui-override sk-user-menu-padding sk-checkout-menu',
};

const handleCheckoutItemClick = (
  courseId,
  handleCartItemClick,
  handleMenuToggle
) => () => {
  handleCartItemClick(courseId)();
  handleMenuToggle();
};

export const UploadMenuList = ({
  uploadingItems,
  progress,
  handleCartItemClick,
  handleMenuToggle,
}) => (
  <MenuList classes={menuListClasses}>
    {uploadingItems.map(({ uploadId, type, s3UploadingVariables: { filename } }, index) => (
      <div
        className="mui-override sk-dropdown__group"
        key={uploadId}
      >
        <UploadMenuItem
          type={type}
          title={filename}
          progress={index === 0 ? progress : 0}
        />
      </div>
    ))}
  </MenuList>
);

UploadMenuList.propTypes = {
  checkoutItems: PropTypes.array,
  handleCartItemClick: PropTypes.func,
  handleMenuToggle: PropTypes.func,
};

export default memo(UploadMenuList);
