import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  signUp: {
    id: 'app.components.AuthTabs.signUp',
    defaultMessage: 'Sign Up',
  },
  logIn: {
    id: 'app.components.AuthTabs.logIn',
    defaultMessage: 'Log In',
  },
});
