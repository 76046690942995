import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';
import { injectIntl } from 'react-intl';

// Components
import Button from 'components/Button';
import Text from 'components/Text';

// Other
import { messages } from 'routes/Course/components/FileEditModals/CloseModalWarning/messages';
import { sharedMessages } from 'services/i18n/sharedMessages/messages';

import './index.scss';

const ConfirmModalClose = ({
  modalId,
  hideModal,
  confirmCloseFileModal,
  intl: { formatMessage, }
}) => {
  return (
    <div className='mui-override sk-experts-cabinet-confirm-modal-close'>
      <Text className='mui-override sk-experts-cabinet-confirm-modal-close__text'>
        {formatMessage(messages.yourChangesAreNotSaved)}
      </Text>
      <Text className='mui-override sk-experts-cabinet-confirm-modal-close__text'>
        {formatMessage(messages.areYouSureYouWant)}
      </Text>
      <div className='mui-override sk-experts-cabinet-confirm-modal-close__buttons'>
        <Button onClick={() => hideModal([])} size='small'variant="text"color="secondary" className='mui-override sk-experts-cabinet-confirm-modal-close__buttons__button'>
          {formatMessage(sharedMessages.closeButton)}
        </Button>
        <Button onClick={() => hideModal(modalId)} size='small'variant="text"color="secondary" className='mui-override sk-experts-cabinet-confirm-modal-close__buttons__button'>
          {formatMessage(messages.backToArticle)}
        </Button>
      </div>
    </div>
  )
};

const enhancer = compose(
  injectIntl
)

export default enhancer(ConfirmModalClose);
