import React, { PureComponent } from 'react';
import { SidebarContext } from 'containers/SidebarProvider/sidebar-context';

// Other
import withWidth from 'containers/HOCs/withWidth';

class SidebarProvider extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      openedSidebar: ['xs', 'sm'].includes(props.width) ? null : 'main',
    };
  }

  componentDidUpdate(prevProps) {
    const { width } = this.props;

    if (
      ['xs', 'sm'].includes(width) &&
      !['xs', 'sm'].includes(prevProps.width)
    ) {
      this.hideSidebar();
    }
  }

  showSidebar = (openedSidebar, props = {}) => {
    this.setState({
      openedSidebar,
      props,
    });
  };

  hideSidebar = () => {
    this.setState({
      openedSidebar: null,
      props: {},
    });
  };

  render() {
    const { children, width } = this.props;
    const { openedSidebar, props } = this.state;

    return (
      <SidebarContext.Provider
        value={{
          sidebarContext: {
            openedSidebar,
            props,
            width,
            showSidebar: this.showSidebar,
            hideSidebar: this.hideSidebar,
          },
        }}
      >
        {React.Children.only(children)}
      </SidebarContext.Provider>
    );
  }
}

export default withWidth()(SidebarProvider);
