import React, { memo, useCallback } from 'react';
import { compose } from 'react-apollo';
import { injectIntl } from 'react-intl';

// Components
import { IconText } from 'routes/Checkout/CheckoutLists/Subscriptions';
import Text from 'components/Text';
import Button from 'components/Button';

// Other
import { messages } from 'routes/ExpertsCabinet/ExpertSubscription/messages.js';
import { sharedMessages } from 'services/i18n/sharedMessages/messages';

//Styles
import './index.scss';

const PREFIX = 'sk-experts-cabinet-cancel-commission';
const CancelSubscriptionModal = ({
  intl: { formatMessage },
  cancelSubscription,
  hideModal,
  ...props
}) => {
  const handleCancelSubscription = useCallback(async () => {
    await cancelSubscription();
    hideModal();
  }, [hideModal, cancelSubscription]);

  return (
    <div className={PREFIX}>
      <Text>{formatMessage(messages.cancelCommissionTitle)}</Text>
      <Text size='small'>{formatMessage(messages.cancelCommissionSubtitle)}</Text>
      <div className={`${PREFIX}__actions`}>
        <Button
          size='large'
          color="secondary"
          onClick={hideModal}
        >
          {formatMessage(sharedMessages.closeButton)}
        </Button>
        <Button
          size='large'
          color="secondary"
          onClick={handleCancelSubscription}
        >
          {formatMessage(messages.cancelSubscription)}
        </Button>
      </div>
    </div>
  );
};

const enhancer = compose(
  memo,
  injectIntl
);

export default enhancer(CancelSubscriptionModal);
