// Core
import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import classNames from 'classnames';

import Image from 'components/Image';

// Styles
import './UserAvatar.scss';

const LetterAvatar = ({ firstName, lastName }) =>
  `${firstName[0]?.toUpperCase() || ''}${lastName[0]?.toUpperCase() || ''}`;

const UserAvatar = ({ avatar, firstName = 'Not', classes, lastName = 'Available' }) => {
  return (
    <div
      className={classNames('sk-avatar-user_wrapper', { [classes]: classes })}
    >
      <Avatar
        style={{ opacity: 1 }}
        className={classNames('mui-override', 'sk-avatar-user', {
          'sk-avatar-user_empty': !avatar,
        })}
      >
        {avatar ? (
          <Image src={avatar} alt={`${firstName}-avatar`} draggable="false" />
        ) : (
          <LetterAvatar lastName={lastName} firstName={firstName} />
        )}
      </Avatar>
    </div>
  );
};

export default UserAvatar;
