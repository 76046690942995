// Core
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withRouter, Redirect } from "react-router-dom";
import Auth from "@aws-amplify/auth";
import { compose } from "react-apollo";

// Components
import Card from "components/Card";
import Heading from "components/Heading";
import Text from "components/Text";
import PasswordUpdateForm from "routes/PasswordUpdate/PasswordUpdateForm";
import SuccessfulResetMessage from "routes/PasswordUpdate/SuccessfulResetMessage";

// Styles and Assets
import "./PasswordUpdate.scss";

// Other
import { withModal } from "containers/ModalProvider/withModal";
import { parseIncomingError } from "utils/errorHandling";
import { FormattedMessage } from "react-intl";
import { messages } from "routes/PasswordUpdate/messages";

class PasswordUpdate extends PureComponent {

  handleContinueClick = () => {
    const {
      modalContext: { hideModal },
      history,
    } = this.props;

    hideModal();
    history.push("/auth/signIn");
  };

  handlePasswordUpdate = async ({ password }, { setSubmitting, setErrors }) => {
    console.log(0);
    const {
      modalContext: { showModal },
      location: {
        state: { user },
      },
      history,
    } = this.props;
    console.log(2);
    try {
      // Completing the new password challenge
      await Auth.completeNewPassword(user, password);
      // Assuming `updatedUser` is now the signed-in user object
      showModal(SuccessfulResetMessage, {
        handleContinueClick: this.handleContinueClick,
      });
    } catch (error) {
      console.error("Update password error:", error);
      setSubmitting(false);
      setErrors(parseIncomingError(error));
    }
  };

  render() {
    const {
      location: { state },
    } = this.props;

    if (!state) return <Redirect to="/" />;

    const defaultFields = {
      password: "",
      confirmPassword: "",
    };

    return (
      <div className="sk-pw-change sk-background-spot__secondary">
        <Card classes="sk-pw-change_container">
          <div className="sk-pw-change_inner">
            <div className="sk-pw-change_header">
              <Heading variant="h4" classes="sk-pw-change_heading">
                <FormattedMessage {...messages.youHaveReqPas} />
              </Heading>
              <Text classes="sk-pw-change_subheading">
                <FormattedMessage {...messages.pleaseChooseNewPass} />
              </Text>
            </div>
            <div className="sk-change-content">
              <PasswordUpdateForm
                defaultFields={defaultFields}
                handlePasswordUpdate={this.handlePasswordUpdate}
              />
            </div>
          </div>
        </Card>
      </div>
    );
  }
}

PasswordUpdate.propTypes = {
  location: PropTypes.object,
  modalContext: PropTypes.object,
  history: PropTypes.object,
};

const enhancer = compose(withRouter, withModal);

export default enhancer(PasswordUpdate);
