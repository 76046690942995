import React, { memo } from 'react';
import { compose } from 'react-apollo';

const Icon = ({ ...props }) => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.6378 11.2427C15.6378 13.1787 14.0684 14.7481 12.1324 14.7481C10.1964 14.7481 8.62695 13.1787 8.62695 11.2427C8.62695 9.30673 10.1964 7.7373 12.1324 7.7373C14.0684 7.7373 15.6378 9.30673 15.6378 11.2427Z" stroke="url(#paint0_linear)"/>
    <path d="M13.4672 11.2433C13.4672 11.9807 12.8694 12.5785 12.132 12.5785C11.3946 12.5785 10.7969 11.9807 10.7969 11.2433C10.7969 10.506 11.3946 9.9082 12.132 9.9082" stroke="url(#paint1_linear)" stroke-linecap="round" stroke-linejoin="round"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.4675 2.34149C13.4675 2.53112 13.4279 2.71153 13.3566 2.8749C17.4467 3.46793 20.5885 6.98848 20.5885 11.2428C20.5885 14.6646 18.5561 17.6117 15.6326 18.9427C17.4593 19.4141 18.8088 21.073 18.8088 23.047C18.8088 23.164 18.7139 23.2589 18.5968 23.2589H5.6693C5.55225 23.2589 5.45737 23.164 5.45737 23.047C5.45737 21.0732 6.80655 19.4145 8.63299 18.9428C5.70932 17.6119 3.67676 14.6647 3.67676 11.2428C3.67676 6.98869 6.81831 3.46828 10.908 2.87499C10.8367 2.71159 10.7972 2.53116 10.7972 2.34149C10.7972 1.60411 11.3949 1.00635 12.1323 1.00635C12.8697 1.00635 13.4675 1.60411 13.4675 2.34149ZM19.5885 11.2428C19.5885 15.3606 16.2504 18.6987 12.1326 18.6987C8.01487 18.6987 4.67676 15.3606 4.67676 11.2428C4.67676 8.26678 6.42041 5.69798 8.94185 4.50228C9.64346 5.39779 10.8298 5.95786 12.1327 5.95786C13.4355 5.95786 14.6218 5.3978 15.3234 4.50229C17.8449 5.69799 19.5885 8.26679 19.5885 11.2428ZM14.329 4.11572C13.6347 3.902 12.8971 3.78696 12.1326 3.78696C11.3682 3.78696 10.6306 3.902 9.93632 4.11572C10.4478 4.61871 11.2277 4.95786 12.1327 4.95786C13.0376 4.95786 13.8175 4.61871 14.329 4.11572ZM9.69591 19.8084H14.5702C16.087 19.8084 17.3602 20.8512 17.7122 22.2589H6.55393C6.90589 20.8512 8.17913 19.8084 9.69591 19.8084Z" fill="url(#paint2_linear)"/>
    <defs>
    <linearGradient id="paint0_linear" x1="16.8631" y1="15.7874" x2="6.83889" y2="14.5577" gradientUnits="userSpaceOnUse">
    <stop stop-color="#8F26AF"/>
    <stop offset="0.979167" stop-color="#141685"/>
    </linearGradient>
    <linearGradient id="paint1_linear" x1="13.7089" y1="12.7582" x2="10.3675" y2="12.3483" gradientUnits="userSpaceOnUse">
    <stop stop-color="#8F26AF"/>
    <stop offset="0.979167" stop-color="#141685"/>
    </linearGradient>
    <linearGradient id="paint2_linear" x1="22.1198" y1="24.7567" x2="0.824156" y2="22.7714" gradientUnits="userSpaceOnUse">
    <stop stop-color="#8F26AF"/>
    <stop offset="0.979167" stop-color="#141685"/>
    </linearGradient>
    </defs>
    </svg>
    
  );
};

const enhancer = compose(
  memo,
);

export default enhancer(Icon);
