// Core
import React from 'react';
import { graphql, compose } from 'react-apollo';
import { injectIntl, FormattedMessage } from 'react-intl';
import { withFormik } from 'formik';
import { withRouter } from 'react-router-dom';

// Components
import Button from 'components/Button';
import Text from 'components/Text';
import TextInput from 'components/Input/Material/TextInput';
import RadioButtonGroup from 'components/RadioButtonGroup';
import TypeSelector from 'routes/ExpertsCabinet/ToppingEdit/components/TypeSelector';

// GraphQL
import CREATE_COURSE from 'mutations/Courses/createCourse.gql';

// Other
import { isNull, keysToSnake } from 'utils/helpers/index';
import { messages } from 'routes/ExpertsCabinet/ToppingEdit/messages';
import { sharedMessages } from 'services/i18n/sharedMessages/messages';
import { getYupSchema, } from 'services/yup';
import { DEFAULT_VARIANT_NAME, DEFAULT_VARIANTS } from 'routes/ExpertsCabinet/ToppingEdit/enums.js';

// Styles
import './index.scss';

const prefix = 'sk-create-topping-modal';
const CreateToppingModal = ({
  values,
  isValid=false,
  errors,
  touched,
  handleChange,
  setFieldValue,
  createCourse,
  hideModal,
  intl: { formatMessage },
  history,
}) => {
  const handleSelectToppingType = (val) => {
    setFieldValue('type', val);
  };
  const createTopping = async () => {
    const input = {
      title: values.title,
      type: 'topping',
      variants: [DEFAULT_VARIANTS[values.type]],
    };

    try {
      const {
        data: {
          createCourse: { id },
        },
      } = await createCourse(input);

      history.push(`/experts-cabinet/toppings/${id}`);
      hideModal();
    } catch (error) {
      throw Error(error);
    }
  };

  return (
    <div className={prefix}>
      <Text>{formatMessage(messages.createToppingModalTitle)}</Text>
      <TypeSelector
        type={values.type}
        handleSelectToppingType={handleSelectToppingType}
      />
      <TextInput
        value={values.title}
        name="title"
        label={formatMessage(messages.toppingTitleLabel)}
        helperText={touched.title ? errors.title : ''}
        error={touched.title && Boolean(errors.title)}
        onChange={(inputValue, e) => handleChange(e)}
      />
      <Button
        color="secondary"
        variant="text"
        size="large"
        loading={false}
        disabled={!isValid}
        onClick={createTopping}
      >
        {formatMessage(sharedMessages.nextButton)}
      </Button>
    </div>
  );
};

const formikStateManagement = withFormik({
  enableReinitialize: true,
  mapPropsToValues: () => ({ type: DEFAULT_VARIANT_NAME, title: '' }),
  displayName: 'CreateToppingModalForm',
  validationSchema: () => getYupSchema('createToppingModalValidationSchema'),
  validateOnChange: true,
  validateOnBlur: true,
  validateOnMount: true
});

const createCourseMutation = graphql(CREATE_COURSE, {
  props: ({ mutate }) => ({
    createCourse: input => mutate({ variables: { input } }),
  }),
});

const enhancer = compose(
  injectIntl,
  withRouter,
  formikStateManagement,
  createCourseMutation
);

export default enhancer(CreateToppingModal);
