// Core
import React, { useState, useEffect, useCallback } from 'react';
import { graphql, compose } from 'react-apollo';
import { injectIntl, FormattedMessage } from 'react-intl';
import { withFormik } from 'formik';
import { withRouter } from 'react-router-dom';

// Components
import Button from 'components/Button';
import Text from 'components/Text';
import TextInput from 'components/Input/Material/TextInput';
import RadioButtonGroup from 'components/RadioButtonGroup';

// GraphQL
import CREATE_COURSE from 'mutations/Courses/createCourse.gql';

// Other
import { isNull, keysToSnake } from 'utils/helpers/index';
import { messages } from 'routes/ExpertsCabinet/ToppingEdit/messages';
import { sharedMessages } from 'services/i18n/sharedMessages/messages';
import { getYupSchema, } from 'services/yup';
import { DEFAULT_OPTION, TOPPINGS_TYPES_OPTIONS } from 'routes/ExpertsCabinet/ToppingEdit/enums.js';

// Styles
import './index.scss';

const prefix = 'sk-topping-type-selector';
const TypeSelector = ({
  type,
  disabledEditing,
  handleSelectToppingType,
  intl: { formatMessage },
}) => {
  return (
    <RadioButtonGroup
      label={formatMessage(messages.materialType)}
      handleChange={handleSelectToppingType}
      name="topping-material-type"
      options={TOPPINGS_TYPES_OPTIONS}
      value={type}
      disabled={disabledEditing}
      classNameRadioGroup={`${prefix}__radio-button-group`}
      classNameRadioButton={`${prefix}__radio-button`}
    />
  );
};

const enhancer = compose(
  injectIntl,
);

export default enhancer(TypeSelector);
