import { defineMessages } from "react-intl";

export const messages = defineMessages({
  courseNotFound: {
    id: "app.routes.ExpertsCabinet.CourseEdit.courseNotFound",
    defaultMessage: "Course is missed or was deleted!",
  },
  youPhotoIsRequired: {
    id: "app.routes.ExpertsCabinet.CourseEdit.UploadUserPhotoModal.youPhotoIsRequired",
    defaultMessage: "Your photo is required",
  },
  pleaseUploadePhoto: {
    id: "app.routes.ExpertsCabinet.CourseEdit.UploadUserPhotoModal.pleaseUploadePhoto",
    defaultMessage: "Please upload your profile photo. *",
  },
  itWillBePartOf: {
    id: "app.routes.ExpertsCabinet.CourseEdit.UploadUserPhotoModal.itWillBePartOf",
    defaultMessage: "It will be part of your course landing page.",
  },
  photoRequirements: {
    id: "app.routes.ExpertsCabinet.CourseEdit.UploadUserPhotoModal.photoRequirements",
    defaultMessage: "*Photo Requirements – less than 1 GB, ratio is 16:9, length – 5 to 15 seconds, photo formats –.jpg and .png. ",
  },
  activateCourse: {
    id: "app.routes.ExpertsCabinet.CourseEdit.UploadUserPhotoModal.activateCourse",
    defaultMessage: "Activate course",
  },
  noSubscriptionWarning: {
    id: "app.routes.ExpertsCabinet.CourseEdit.BuySubscription.noSubscriptionWarning",
    defaultMessage: "Please select your preferred way of our cooperation",
  },
  subscriptionMaxCoursesWarningTitle: {
    id: "app.routes.ExpertsCabinet.CourseEdit.BuySubscription.subscriptionMaxCoursesWarningTitle",
    defaultMessage: "You have reached the limit on the number of active courses",
  },
  subscriptionMaxCoursesWarningText: {
    id: "app.routes.ExpertsCabinet.CourseEdit.BuySubscription.subscriptionMaxCoursesWarningText",
    defaultMessage: "You have subscribed for {maxCourses} active courses. Please deactivate one of your existing courses to activate a new one.",
  },
  subscriptionCommissionButton: {
    id: "app.routes.ExpertsCabinet.CourseEdit.BuySubscription.subscriptionCommissionButton",
    defaultMessage: "% of sales",
  },
  subscriptionPlanButton: {
    id: "app.routes.ExpertsCabinet.CourseEdit.BuySubscription.subscriptionPlanButton",
    defaultMessage: "Subscription",
  },
  attentionTitle: {
    id: "app.routes.ExpertsCabinet.CourseEdit.DeleteButton.attentionTitle",
    defaultMessage: "Attention",
  },
  courseHasNoStudentsWarning: {
    id: "app.routes.ExpertsCabinet.CourseEdit.DeleteButton.courseHasNoStudentsWarning",
    defaultMessage: "Material cannot be deleted due to active students",
  },
});
