import React from "react";
import * as Yup from "yup";
import Intl from "services/i18n/instance";
import { messages } from "./messages";
import { FormattedMessage } from "react-intl";

export const getSchemaFields = (schemaName) => {
  const intl = Intl.get();
  const { formatMessage } = intl;

  const DEFAULT_ERRORS = {
    requiredStringField: Yup.string()
      .required(formatMessage(messages.fieldIsRequired))
      .nullable(),
    requiredNumberField: Yup.number()
      .required(formatMessage(messages.fieldIsRequired))
      .nullable(),
    requiredDateField: Yup.date()
      .required(formatMessage(messages.fieldIsRequired))
      .nullable(),
    requiredSimplePriceField: Yup.number()
      .required(formatMessage(messages.fieldIsRequired))
      .nullable()
      .integer(formatMessage(messages.requireInteger)),
    requiredPriceField: Yup.object().shape({
      isChecked: Yup.boolean(),
      price: Yup.number()
        .nullable()
        .when("isChecked", {
          is: true,
          then: Yup.number()
            .required(formatMessage(messages.fieldIsRequired))
            .nullable()
            .integer(formatMessage(messages.requireInteger)),
        }),
    }),
  };

  const schemas = {
    definedSchemeFields: {
      password: DEFAULT_ERRORS.requiredStringField.matches(/^(.*){8,}$/, {
        message: formatMessage(messages.passwordShouldMatch),
      }),
      confirmPassword: DEFAULT_ERRORS.requiredStringField.oneOf(
        [Yup.ref("password")],
        formatMessage(messages.passwordMismatch)
      ),
      oldPassword: DEFAULT_ERRORS.requiredStringField.matches(/^(.*){8,}$/, {
        message: formatMessage(messages.passwordShouldMatch),
      }),
      email: DEFAULT_ERRORS.requiredStringField.email(
        formatMessage(messages.enterAValidEmail)
      ),
      terms: Yup.boolean().oneOf(
        [true],
        formatMessage(messages.mustAcceptTerms)
      ),
      isAgreeWithPolicy: Yup.boolean()
        .required(formatMessage(messages.fieldIsRequired))
        .oneOf([true], formatMessage(messages.isAgreeWithPolicy)),
      expertises: DEFAULT_ERRORS.requiredStringField.trim(),
      aboutMe: DEFAULT_ERRORS.requiredStringField.trim(),
      code: DEFAULT_ERRORS.requiredStringField.matches(/^(?=.*\d)[0-9]{4,}$/, {
        message: formatMessage(messages.minCodeLength, { min: 4 }),
      }),
      firstName: DEFAULT_ERRORS.requiredStringField.trim(),
      lastName: DEFAULT_ERRORS.requiredStringField.trim(),
      country: DEFAULT_ERRORS.requiredStringField,
      city: DEFAULT_ERRORS.requiredStringField,
      title: DEFAULT_ERRORS.requiredStringField.trim(),
      interactionLevel: DEFAULT_ERRORS.requiredStringField,
      category: DEFAULT_ERRORS.requiredStringField,
      // subcategory: DEFAULT_ERRORS.requiredStringField,
      complexity: DEFAULT_ERRORS.requiredStringField,
      requirements: DEFAULT_ERRORS.requiredStringField.trim(),
      language: DEFAULT_ERRORS.requiredStringField,
      description: DEFAULT_ERRORS.requiredStringField.trim(),
      skills: DEFAULT_ERRORS.requiredStringField.trim(),
      descriptionForAdmin: DEFAULT_ERRORS.requiredStringField.trim(),
      maxNumberOfUsers: DEFAULT_ERRORS.requiredNumberField
        .min(1, formatMessage(messages.minMark, { min: 1 }))
        .max(999999999),
      timeToCompletion: DEFAULT_ERRORS.requiredNumberField.min(
        1,
        formatMessage(messages.minMark, { min: 1 })
      ),
      flex: DEFAULT_ERRORS.requiredPriceField,
      assist: DEFAULT_ERRORS.requiredPriceField,
      concierge: DEFAULT_ERRORS.requiredPriceField,
      finalLetterToStudents: DEFAULT_ERRORS.requiredStringField,
      duration: DEFAULT_ERRORS.requiredNumberField.min(
        1,
        formatMessage(messages.minMark, { min: 1 })
      ),
      content: DEFAULT_ERRORS.requiredStringField,
      text: DEFAULT_ERRORS.requiredStringField,
      type: DEFAULT_ERRORS.requiredStringField,
      mark: DEFAULT_ERRORS.requiredNumberField
        .min(0, formatMessage(messages.minMark, { min: 0 }))
        .max(100, formatMessage(messages.maxMark, { max: 100 })),
      name: DEFAULT_ERRORS.requiredStringField,
      cardNumber: DEFAULT_ERRORS.requiredNumberField,
      expirationDate: DEFAULT_ERRORS.requiredNumberField,
      cvv: DEFAULT_ERRORS.requiredNumberField,
      startDate: DEFAULT_ERRORS.requiredStringField,
      courseId: DEFAULT_ERRORS.requiredStringField,
      startTime: DEFAULT_ERRORS.requiredStringField,
      coursePreview: Yup.string().nullable(),
      // .required(formatMessage(messages.previewIsRequired))
      paymentAccountEmail: Yup.string().email(
        formatMessage(messages.enterAValidEmail)
      ),
      payoneerPaymentAccountEmail: Yup.string().email(
        formatMessage(messages.enterAValidEmail)
      ),
      message: DEFAULT_ERRORS.requiredStringField.max(
        500,
        formatMessage(messages.maximumMessageLength, { max: 500 })
      ),
      reference: DEFAULT_ERRORS.requiredStringField,
      referenceId: DEFAULT_ERRORS.requiredStringField,
      discountType: DEFAULT_ERRORS.requiredStringField,
      messangerUrl: Yup.string().url(),
    },
    discountFields: {
      name: DEFAULT_ERRORS.requiredStringField,
      code: DEFAULT_ERRORS.requiredStringField
        .test("len", "No spaces allowed!", (val) => !/\s/.test(val))
        .test(
          "len",
          "Must be more than 10 characters",
          (val) => val?.length >= 10
        ),
      reference: DEFAULT_ERRORS.requiredStringField,
      referenceId: DEFAULT_ERRORS.requiredStringField,
      discountType: DEFAULT_ERRORS.requiredStringField,
      discountValue: DEFAULT_ERRORS.requiredNumberField.test(
        "discount-value",
        function(value) {
          const { path, createError } = this;
          const isPercent = this.resolve(Yup.ref("discountType")) === "percent";
          let isValid = false;
          let message = null;

          if (isPercent) {
            isValid = value <= 100 && value >= 1;
            message = formatMessage(messages.valueBetween, {
              min: 1,
              max: 100,
            });
          } else {
            isValid = value >= 1;
            message = formatMessage(messages.minMark, { min: 1 });
          }

          return isValid || createError({ path, message });
        }
      ),
      endTime: DEFAULT_ERRORS.requiredNumberField.test(
        "date-min",
        formatMessage(messages.beforeStartTime),
        function(val) {
          const { path, resolve, createError } = this;
          const startDate = resolve(Yup.ref("startTime"));
          const endDate = val;
          return (
            endDate >= startDate ||
            createError({
              path,
              message: formatMessage(messages.beforeStartTime),
            })
          );
        }
      ),
      startTime: DEFAULT_ERRORS.requiredNumberField.test(
        "date-min",
        formatMessage(messages.beforeStartTime),
        function(val) {
          const { path, resolve, createError } = this;
          const endDate = resolve(Yup.ref("endTime"));
          const startDate = val;
          return (
            startDate <= endDate ||
            createError({ path, message: formatMessage(messages.laterEndTime) })
          );
        }
      ),
      // endTime: Yup.number().min(Yup.ref('startTime'), formatMessage(messages.beforeStartTime)),
      // startTime: Yup.number().max(Yup.ref('endTime'), formatMessage(messages.laterEndTime)),
      redemptions: DEFAULT_ERRORS.requiredNumberField.min(
        0,
        formatMessage(messages.minMark, { min: 0 })
      ),
    },
    applyDiscountFields: {
      code: DEFAULT_ERRORS.requiredStringField
        .test("len", messages.invalidPromocode.id, (val) => !/\s/.test(val))
        .test("len", messages.invalidPromocode.id, (val) => val?.length >= 10),
    },
    toppingEditFields: {
      title: DEFAULT_ERRORS.requiredStringField,
      language: DEFAULT_ERRORS.requiredStringField,
      description: DEFAULT_ERRORS.requiredStringField,
      recommendationFromAdmin: Yup.string().nullable(),
      price: DEFAULT_ERRORS.requiredSimplePriceField,
      coursePreview: Yup.string().nullable(),
      // coursePreview: DEFAULT_ERRORS.requiredStringField,
      lectureVideo: DEFAULT_ERRORS.requiredStringField,
      coursePhoto: DEFAULT_ERRORS.requiredStringField,
    },
    planEditFields: {
      titleRU: DEFAULT_ERRORS.requiredStringField.strict(false).trim(),
      titleEN: DEFAULT_ERRORS.requiredStringField.strict(false).trim(),
      maxCourses: DEFAULT_ERRORS.requiredNumberField,
      monthlyPrice: DEFAULT_ERRORS.requiredSimplePriceField,
      commissionPrice: DEFAULT_ERRORS.requiredSimplePriceField
        .min(0, formatMessage(messages.minMark, { min: 0 }))
        .max(100, formatMessage(messages.maxMark, { max: 100 })),
      annualPrice: DEFAULT_ERRORS.requiredSimplePriceField,
      discountPerCourse: DEFAULT_ERRORS.requiredNumberField
        .min(0, "Min discount")
        .max(100, "Max discount"),
      discountCoursesLimit: DEFAULT_ERRORS.requiredNumberField,
    },
  };

  return schemas[schemaName]
    ? schemas[schemaName]
    : schemas.definedSchemeFields;
};
