import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  checkout: {
    id: 'app.routes.Checkout.checkout',
    defaultMessage: 'Checkout',
  },
  orderBasketEmpty: {
    id: 'app.components.ShoppingCart.orderBasketEmpty',
    defaultMessage: 'Shopping cart is empty.',
  },
  yourItems: {
    id: 'app.routes.Checkout.yourItems',
    defaultMessage: 'Your items',
  },
  author: {
    id: 'app.routes.Checkout.author',
    defaultMessage: 'Author',
  },
  payNow: {
    id: 'app.routes.Checkout.payNow',
    defaultMessage: 'Complete Checkout',
  },
  payment: {
    id: 'app.containers.CheckoutMenu.payment',
    defaultMessage: 'Payment*',
  },
  or: {
    id: 'app.routes.Checkout.or',
    defaultMessage: 'or',
  },
  cvv: {
    id: 'app.routes.Checkout.cvv',
    defaultMessage: 'The 3-digit number printed by the signature line on the back of the card',
  },
  cardNumber: {
    id: 'app.routes.Checkout.cardNumber',
    defaultMessage: 'Card Number',
  },
  experiedDate: {
    id: 'app.routes.Checkout.experiedDate',
    defaultMessage: 'Expiration',
  },
  experiedDatePlaceholder: {
    id: 'app.routes.Checkout.experiedDatePlaceholder',
    defaultMessage: 'MM/YY',
  },
  addDiscount: {
    id: 'app.routes.Checkout.addPromocode',
    defaultMessage: 'Add promocode',
  },
  promocode: {
    id: 'app.routes.Checkout.promocode',
    defaultMessage: 'Promo code',
  },
  getCourses: {
    id: 'app.routes.Checkout.getCourses',
    defaultMessage: 'Get material',
  },
  subscriptionMaxCourses: {
    id: 'app.routes.Checkout.subscriptionMaxCourses',
    defaultMessage: 'Number of courses: {maxCourses}',
  },
  successSubscriptionTitle: {
    id: 'app.routes.Checkout.successSubscriptionTitle',
    defaultMessage: 'Your subscription has been successful!',
  },
  successSubscriptionText1: {
    id: 'app.routes.Checkout.successSubscriptionText1',
    defaultMessage: 'You may have up to <span style="color: #20292F; font-weight: 500">{maxCourses}</span> active courses during a {billingPeriod}.',
  },
  successSubscriptionText2: {
    id: 'app.routes.Checkout.successSubscriptionText2',
    defaultMessage: 'You have successfully subscribed for the <span style="color: #20292F; font-weight: 500">"{title}"</span> plan for one {billingPeriod}. Your card will be <span style="color: #20292F; font-weight: 500">automatically</span> charged for the subscription cost on <span style="color: #20292F; font-weight: 500">{chargePeriod}</span>. You may cancel your subscription at any time.',
  },
  successSubscriptionText3: {
    id: 'app.routes.Checkout.successSubscriptionText3',
    defaultMessage: 'Your order summary will be emailed to you.',
  },
  flex: {
    id: 'app.routes.ManageAccount.ExpertSales.Table.courseTitle',
    defaultMessage: 'Course',
  },
  assist: {
    id: 'app.routes.ManageAccount.ExpertSales.Table.courseTitle',
    defaultMessage: 'Course',
  },
  concierge: {
    id: 'app.routes.ManageAccount.ExpertSales.Table.courseTitle',
    defaultMessage: 'Course',
  },
  lecture: {
    id: 'app.routes.ExpertsCabinet.ToppingEdit.lectureOption',
    defaultMessage: 'Lecture',
  },
  masterclass: {
    id: 'app.routes.ExpertsCabinet.ToppingEdit.masterClassOption',
    defaultMessage: 'Masterclass',
  },
  podcast: {
    id: 'app.routes.ExpertsCabinet.ToppingEdit.podcastOption',
    defaultMessage: 'Podcast',
  },
  lang: {
    id: 'app.components.BuyCourse.lang',
    defaultMessage: 'LANGUAGE',
  },
  complexity: {
    id: 'app.components.BuyCourse.complexity',
    defaultMessage: 'COMPLEXITY',
  },
  totalLectures: {
    id: 'app.components.BuyCourse.totalLectures',
    defaultMessage: 'lectures',
  },
  totalSections: {
    id: 'app.components.BuyCourse.totalSections',
    defaultMessage: 'lectures',
  },
  totalHrs: {
    id: 'app.components.BuyCourse.totalHrs',
    defaultMessage: 'Total Duration',
  },
  Alllevels: {
    id: 'app.components.CourseCard.Alllevels',
    defaultMessage: 'All levels',
  },
  Beginner: {
    id: 'app.components.CourseCard.Beginner',
    defaultMessage: 'Beginner',
  },
  Intermediate: {
    id: 'app.components.CourseCard.Intermediate',
    defaultMessage: 'Intermediate',
  },
  Advanced: {
    id: 'app.components.CourseCard.Advanced',
    defaultMessage: 'Advanced',
  },
  access: {
    id: 'app.components.CourseCard.access',
    defaultMessage: 'Lifetime access',
  },
  certificatesFeature: {
    id: 'app.components.CourseCard.certificatesFeature',
    defaultMessage: 'Certificate',
  },
  chatsFeature: {
    id: 'app.components.CourseCard.chatsFeature',
    defaultMessage: 'Chat',
  },
  homeworksFeature: {
    id: 'app.components.CourseCard.homeworksFeature',
    defaultMessage: 'Homework',
  },
  interectiveTestsFeature: {
    id: 'app.components.CourseCard.interectiveTestsFeature',
    defaultMessage: 'Quizzes',
  },
  studyMaterialsFeature: {
    id: 'app.components.CourseCard.studyMaterialsFeature',
    defaultMessage: 'Study materials',
  },
  videoLecturesFeature: {
    id: 'app.components.CourseCard.videoLecturesFeature',
    defaultMessage: 'Video lectures',
  },
  webinarsFeature: {
    id: 'app.components.CourseCard.webinarsFeature',
    defaultMessage: 'Web Сlasses',
  },
});
