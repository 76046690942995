// Core
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// Styles and Assets
import './PCSummary.scss';

// Components
import Text from 'components/Text';
import Duration from 'components/Duration/Duration';

// Other
import { getLectureDuration } from 'utils/helpers';

import { FormattedMessage } from 'react-intl';
import { messages } from 'routes/StudentsCabinet/PurchasedCourses/PurchasedCoursesLecture/PCSummary/messages';
import { sharedMessages } from 'services/i18n/sharedMessages/messages';

class PCSummary extends PureComponent {
  render() {
    const { lecture, index, displayLectureNameType } = this.props;

    return (
      <>
        <div className="sk-pc-summary">
          <div className="sk-pc-summary-title">
              {displayLectureNameType === 'section'
                ? <FormattedMessage {...sharedMessages.section} />
                : <FormattedMessage {...sharedMessages.lecture} />} {index + 1}.
            <Text>{lecture.title}</Text>
          </div>
          <div className="sk-pc-summary-clock">
            <Duration duration={getLectureDuration([lecture])} withClock={true} clockProps={{ width: "20", height:"20", red: false }} />
          </div>
        </div>
        <div className="sk-pc-summary-mobile">
          <div className="sk-pc-summary-mobile-title">
            <div>  
              {displayLectureNameType === 'section'
                ? <FormattedMessage {...sharedMessages.section} />
                : <FormattedMessage {...sharedMessages.lecture} />} {index + 1}.
              </div>
            <div className="sk-pc-summary-mobile-clock">
              <Duration duration={getLectureDuration([lecture])} withClock={true} clockProps={{ width: "20", height:"20", red: false }} />
            </div>
          </div>
          <Text>{lecture.title}</Text>
        </div>
      </>
    );
  }
}
PCSummary.propTypes = {
  lecture: PropTypes.object,
  index: PropTypes.number,
};

export default PCSummary;
