export const SidebarDefault = '#9b9b9b';
export const PurpleHeart = '#7145d8';
export const DarkGrey = '#444444';
export const Red = '#30d5c8';
export const Purple = '#9574e4';
export const Pink = '#AB8FEB';
export const MaterialGrey = '#646464';
export const Grey = '#A9B6C8';
export const Grey2 = '#979797';
export const Grey3 = '#b2b2b2';
export const Grey4 = '#767676';
export const Grey5 = '#e6e6e6';
export const OrangeyRed = '#30d5c8';
export const OrangeyNewRed = '#3ad1c5';
export const WarnRed = '#FA6060';
export const White = '#FFFFFF';
export const VeryLiteGrey = '#CCCCCC';
export const Transparent = 'transparent';
export const Green = '#33CB67';
export const Black = '#000000';
export const NotActiveGrey = '#BCBCBC';
export const LightGreen = '#5FDB98';
export const PurpleLight = '#D7B6FF';
export const PurpleDark = '#610FB6';

export const Concrete = '#B9BDBF';