// Core
import React, { memo } from 'react';
import PropTypes from 'prop-types';

// Components
import Button from 'components/Button';
import Text from 'components/Text';
import UploadController from 'routes/Course/components/UploadController';
import PDF from 'components/Icon/Svg/PDF';

// Other
import { SUPPORTED_FORMATS_HOMEWORK } from 'utils/enums';

import { FormattedMessage } from 'react-intl';
import { messages } from 'routes/StudentsCabinet/components/StudentHomework/messages';

// Styles and Assets
import './AnswerFulStep.scss';

const AnswerFulStep = ({
  fileName,
  disabled,
  handleUpdateHomeworkStep,
  handleAnswerFilePick,
}) => (
  <div className="sk-answerful-step">
    <div className="sk-answerful-step__header">
      <div className="sk-answerful-step__icon">
        <PDF />
      </div>
      <div className="sk-answerful-step__file">
        <Text size="caption" classes="sk-answerful-step__text">
          {fileName}
        </Text>
        <UploadController
          withDelete={false}
          uploaderId={`sk-lec-${fileName}}-hw-uploader-2`}
          disabled={disabled}
          supportedFormats={SUPPORTED_FORMATS_HOMEWORK}
          maxSize={500000}
          handleReload={handleAnswerFilePick}
        />
      </div>
    </div>
    <div className="sk-answerless-step__btn">
      <Button
        variant="text"
        color="secondary"
        size="small"
        disabled={disabled}
        onClick={() => handleUpdateHomeworkStep(3)}
      >
        <FormattedMessage {...messages.submit} />
      </Button>
    </div>
  </div>
);

AnswerFulStep.propTypes = {
  handleAnswerFileUpload: PropTypes.func,
  handleAnswerFilePick: PropTypes.func,
  fileName: PropTypes.string,
  disabled: PropTypes.bool,
};

export default memo(AnswerFulStep);
