// Core
import React, { memo } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { injectIntl, intlShape } from "react-intl";
import copy from "clipboard-copy";
import { withRouter } from "react-router-dom";
import { compose } from "react-apollo";

// Components
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Text from "components/Text";

// Other
import { sharedMessages } from "services/i18n/sharedMessages/messages";

// Styles and Assets
import "./CompaniesListTableRow.scss";

const classes = {
  tableCell: { root: "mui-override sk-admin-companies-table-row" },
};

const parseJson = (input) => {
  if (typeof input !== "string") {
    return null;
  }
  try {
    const parsed = JSON.parse(input);
    return parsed.key;
  } catch {
    return input;
  }
};

const dateFormat = ds => {
  try {
    return new Date(ds).toLocaleDateString();
  } catch {
    return '-';
  }
};

const CompaniesListTableRow = memo(
  ({ item, index, arr, intl: { formatMessage }, history }) => {
    const openCompanyPage = (event) => {
      event.preventDefault();
      event.stopPropagation();
      history.push(`/admin/panel/companies/edit/${item.id}`);
    };
    const isCompanyStarted = Date.now() > item.startTime;
    const isCompanyExpired = Date.now() > item.endTime;
    return (
      <TableRow
        key={item.id}
        className="mui-override sk-admin-companies-table-row"
        onClick={openCompanyPage}
      >
        <TableCell
          classes={classes.tableCell}
          className={`sk-admin-companies-table-row__tableBodyCellLeft ${index ===
            arr.length - 1 && "_last"}`}
          align="left"
        >
          <Text size="basic">{item.company_name}</Text>
        </TableCell>
        <TableCell
          classes={classes.tableCell}
          className={`sk-admin-companies-table-row__tableBodyCellLeft ${index ===
            arr.length - 1 && "_last"}`}
          align="left"
        >
          <Text size="basic">
            {item?.owner?.first_name} {item?.owner?.last_name}
          </Text>
        </TableCell>
        <TableCell
          classes={classes.tableCell}
          className={`sk-admin-companies-table-row__tableBodyCellLeft ${index ===
            arr.length - 1 && "_last"}`}
          align="left"
        >
          <Text size="basic">{new Date().toLocaleDateString()}</Text>
        </TableCell>
        <TableCell
          classes={classes.tableCell}
          className={`sk-admin-companies-table-row__tableBodyCellLeft ${index ===
            arr.length - 1 && "_last"}`}
          align="left"
        >
          <Text size="basic">-</Text>
        </TableCell>
        <TableCell
          classes={classes.tableCell}
          className={`sk-admin-companies-table-row__tableBodyCellLeft ${index ===
            arr.length - 1 && "_last"}`}
          align="left"
        >
          <Text size="basic">Yes</Text>
        </TableCell>
        <TableCell
          classes={classes.tableCell}
          className={`sk-admin-companies-table-row__tableBodyCellLeft ${index ===
            arr.length - 1 && "_last"}`}
          align="left"
        >
          <Text size="basic">Standard</Text>
        </TableCell>
        <TableCell
          classes={classes.tableCell}
          className={`sk-admin-companies-table-row__tableBodyCellLeft ${index ===
            arr.length - 1 && "_last"}`}
          align="left"
        >
          <Text size="basic">{item.company_phone}</Text>
        </TableCell>
        <TableCell
          classes={classes.tableCell}
          className={`sk-admin-companies-table-row__tableBodyCellLeft ${index ===
            arr.length - 1 && "_last"}`}
          align="left"
        >
          <Text size="basic">{item.email}</Text>
        </TableCell>
        <TableCell
          classes={classes.tableCell}
          className={`sk-admin-companies-table-row__tableBodyCellLeft ${index ===
            arr.length - 1 && "_last"}`}
          align="left"
        >
          <Text size="basic">{item?.country?.name}</Text>
        </TableCell>
        <TableCell
          classes={classes.tableCell}
          className={`sk-admin-companies-table-row__tableBodyCellLeft ${index ===
            arr.length - 1 && "_last"}`}
          align="left"
        >
          <Text size="basic">{parseJson(item.company_size)}</Text>
        </TableCell>
        <TableCell
          classes={classes.tableCell}
          className={`sk-admin-companies-table-row__tableBodyCellLeft ${index ===
            arr.length - 1 && "_last"}`}
          align="left"
        >
          <Text size="basic">{dateFormat(item.paid_till)}</Text>
        </TableCell>
      </TableRow>
    );
  }
);

CompaniesListTableRow.propTypes = {
  intl: intlShape.isRequired,
  item: PropTypes.object,
  index: PropTypes.number,
  arr: PropTypes.array,
};

const enhancer = compose(withRouter, injectIntl);

export default enhancer(CompaniesListTableRow);
