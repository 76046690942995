// Core
import React from "react";

// Components
import Image from "components/Image";
import VkIcon from "components/Icon/Svg/LandingPageIcons/Vk";
import LinkedinIcon from "components/Icon/Svg/LandingPageIcons/Linkedin";
import FacebookIcon from "components/Icon/Svg/LandingPageIcons/Facebook";
import Quote from "components/Icon/Svg/LandingPageIcons/Quote";
import UserAvatar from "components/UserAvatar";
import Text from "components/Text";

// Styles
import "./LandingPageCommentCard.scss";

// {isBlank(photo) ? (
//   <UserAvatar lastName={lastName} firstName={firstName} />
// ) : (
//   <Image src={photo} />
// )}

const LandingPageCommentCard = ({
  name,
  position,
  photo,
  comment,
  className,
  vk,
  facebook,
  linkedin,
}) => {
  const [firstName, lastName] = name.split(" ");
  return (
    <div className={`sk-landing-page__comments_card ${className}`}>
      <div className="sk-landing-page__comments_card__photo">
        <UserAvatar avatar={photo} lastName={lastName} firstName={firstName} />
      </div>
      <div className="sk-landing-page__comments_card__content">
        <Text>{comment}</Text>
        <div className="sk-landing-page__comments_card__content__user-block">
          <Quote />
          <div className="sk-landing-page__comments_card__content__user-block__user">
            {name && <Text>{name}</Text>}
            {position && <Text>{position}</Text>}
          </div>
        </div>
        {/*<div className="sk-landing-page__comments_card__content__social-links">
          {vk && (<a href={vk}><VkIcon /></a>)}
          {facebook && (<a href={facebook}><FacebookIcon /></a>)}
          {linkedin && (<a href={linkedin}><LinkedinIcon /></a>)}
  </div>*/}
      </div>
    </div>
  );
};

export default LandingPageCommentCard;
