import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  enterRecoveryCode: {
    id: 'app.components.PasswordChangeForm.enterRecoveryCode',
    defaultMessage: 'Recovery code',
  },
  newPassword: {
    id: 'app.components.PasswordChangeForm.newPassword',
    defaultMessage: 'New password',
  },
  reenterNewPassword: {
    id: 'app.components.PasswordChangeForm.reenterNewPassword',
    defaultMessage: 'Re-enter new password',
  },
  changePassword: {
    id: 'app.components.PasswordChangeForm.changePassword',
    defaultMessage: 'Change password',
  },
});
