import React from 'react';
import PropTypes from 'prop-types';
import MaterialCheckbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Text from 'components/Text';
import './Checkbox.scss';

const getCheckboxClasses = (label, smallLabel, alignTop) => ({
  root: `mui-override sk-checkbox ${label &&
    !smallLabel &&
    'sk-checkbox-label'} ${alignTop && 'sk-checkbox-label_top'}`,
  checked: 'sk-checkbox__checked checked',
});

const getLabelClasses = alignTop => ({
  root: `mui-override sk-checkbox ${alignTop && 'sk-checkbox_top'}`,
});

const Checkbox = ({
  handleChange,
  disabled,
  onBlur,
  value,
  checked,
  label,
  smallLabel,
  alignTop,
  name,
  title
}) => (
  <FormControlLabel
    classes={getLabelClasses(alignTop)}
    control={
      <MaterialCheckbox
        name={name}
        disabled={disabled}
        title={title}
        checked={checked}
        onChange={e => handleChange(e.target.checked, e)}
        onBlur={e => (onBlur ? onBlur(e.target.checked, e) : null)}
        value={value}
        classes={getCheckboxClasses(label, smallLabel, alignTop)}
      />
    }
    label={
      typeof label === 'string' ? (
        <Text size={smallLabel ? 'caption' : 'basic'}>{label}</Text>
      ) : (
        label
      )
    }
  />
);

Checkbox.defaultProps = {
  handleChange: () => {},
};

Checkbox.propTypes = {
  handleChange: PropTypes.func,
  value: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  checked: PropTypes.bool,
  onBlur: PropTypes.func,
  smallLabel: PropTypes.bool,
  alignTop: PropTypes.bool,
  name: PropTypes.string,
};

export default Checkbox;
