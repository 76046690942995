// Core
import React, { PureComponent, Fragment } from "react";
import classnames from "classnames";
import { compose, graphql } from "react-apollo";

// Components
import Loader from "components/Loader";
import Text from "components/Text";
import UserTableRow from "./UserTableRow";
import { ArrowToTop, ArrowToDown } from "components/Icon/Svg";
import { Grey4, Black } from "components/Icon/color";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

// GraphQl
import BLOCK_USER from "mutations/AdminPanel/blockUser.gql";

// Styles and Assets
import "./UsersTable.scss";

const classes = {
  tableCell: {
    root: "mui-override sk-usersTable",
  },
};
const ACSENDING = "asc";
const NEXT_SORT_ORDER = {
  asc: "desc",
  desc: "asc",
};
const SORT_TYPES = {
  first_name: "first_name",
  last_name: "last_name",
  email: "email",
  city: "city",
  country: "country",
  created_at: "created_at",
  status: "status",
};
const REFETCH_USERS_DELAY = 3000;

class UsersTable extends PureComponent {
  renderTableBody = (adminListUsers) => {
    const { userRole } = this.props;
    return (
      <Fragment>
        {!!adminListUsers &&
          adminListUsers !== null &&
          adminListUsers.map((item, index, arr) => (
            <UserTableRow
              key={item.id}
              item={item}
              index={index}
              arr={arr}
              userRole={userRole}
              handleSubmit={this.handleSubmit}
            />
          ))}
      </Fragment>
    );
  };

  handleSubmit = async (id, enabled, event) => {
    event.preventDefault();
    event.stopPropagation();
    const { adminSuspendRestoreUser, refetchUsers } = this.props;
    try {
      await adminSuspendRestoreUser({ id, enable: !enabled });
      // Updated information will appear in profile with sign delay due to
      // elasticsearch stream
      // todo: add loader (recommended but not critical)
      setTimeout(async () => {
        refetchUsers();
      }, REFETCH_USERS_DELAY);
    } catch (error) {
      throw Error(error);
    }
  };

  handleSort = (type) => async () => {
    const { handleSort, sortBy, sortOrder } = this.props;

    const newSortByFieldName = type;
    const newSortOrder =
      sortBy === type && NEXT_SORT_ORDER[sortOrder]
        ? NEXT_SORT_ORDER[sortOrder]
        : ACSENDING;

    await handleSort({ sortBy: newSortByFieldName, sortOrder: newSortOrder });
  };

  handleSortByName = this.handleSort(SORT_TYPES["first_name"]);
  handleSortByLastName = this.handleSort(SORT_TYPES["last_name"]);
  handleSortByEmail = this.handleSort(SORT_TYPES["email"]);
  handleSortByCity = this.handleSort(SORT_TYPES["city"]);
  handleSortByCountry = this.handleSort(SORT_TYPES["country"]);
  handleSortByRegisterDate = this.handleSort(SORT_TYPES["created_at"]);
  handleSortByStatus = this.handleSort(SORT_TYPES["status"]);

  render() {
    const {
      adminListUsers,
      error,
      loading,
      sortBy,
      sortOrder,
      userRole,
    } = this.props;

    if (error) return null;

    const headerHeight = 63;
    const rowHeight = 63;
    const rowsCount = +adminListUsers?.length || 0;

    const isSortOrderAscending = sortOrder === ACSENDING;

    const isSortedByName = sortBy === SORT_TYPES["first_name"];
    const isSortedByLastName = sortBy === SORT_TYPES["last_name"];
    const isSortedByEmail = sortBy === SORT_TYPES["email"];
    const isSortedByCity = sortBy === SORT_TYPES["city"];
    const isSortedByCountry = sortBy === SORT_TYPES["country"];
    const isSortedByCreatedAt = sortBy === SORT_TYPES["created_at"];
    const isSortedByStatus = sortBy === SORT_TYPES["status"];

    return (
      <Table
        className="mui-override sk-usersTable"
        classes={classes.tableCell}
        style={{ height: headerHeight + rowHeight * rowsCount }}
      >
        <TableHead>
          <TableRow>
            <TableCell
              classes={classes.tableCell}
              className="mui-override sk-usersTable__cell"
            />
            <TableCell
              classes={classes.table}
              className="mui-override sk-usersTable__cell"
              align="left"
            >
              <div className="mui-override sk-usersTable__cell__elements">
                <Text bold align="left" onClick={this.handleSortByName}>
                  NAME
                </Text>
                <span
                  className={classnames(
                    "mui-override sk-usersTable__cell__elements__arrow",
                    {
                      rotate: isSortedByName ? isSortOrderAscending : true,
                      show: isSortedByName,
                    }
                  )}
                >
                  <ArrowToDown
                    height="12"
                    width="13"
                    color={isSortedByName ? Black : Grey4}
                  />
                </span>
              </div>
            </TableCell>
            <TableCell
              classes={classes.table}
              className="mui-override sk-usersTable__cell"
              align="left"
            >
              <div className="mui-override sk-usersTable__cell__elements">
                <Text bold align="left" onClick={this.handleSortByLastName}>
                  LAST NAME
                </Text>
                <span
                  className={classnames(
                    "mui-override sk-usersTable__cell__elements__arrow",
                    {
                      rotate: isSortedByLastName ? isSortOrderAscending : true,
                      show: isSortedByLastName,
                    }
                  )}
                >
                  <ArrowToDown
                    height="12"
                    width="13"
                    color={isSortedByLastName ? Black : Grey4}
                  />
                </span>
              </div>
            </TableCell>
            <TableCell
              classes={classes.table}
              className="mui-override sk-usersTable__cell"
              align="left"
            >
              <div className="mui-override sk-usersTable__cell__elements">
                <Text bold align="left" onClick={this.handleSortByEmail}>
                  E-MAIL
                </Text>
                <span
                  className={classnames(
                    "mui-override sk-usersTable__cell__elements__arrow",
                    {
                      rotate: isSortedByEmail ? isSortOrderAscending : true,
                      show: isSortedByEmail,
                    }
                  )}
                >
                  <ArrowToDown
                    height="12"
                    width="13"
                    color={isSortedByEmail ? Black : Grey4}
                  />
                </span>
              </div>
            </TableCell>
            <TableCell
              classes={classes.table}
              className="mui-override sk-usersTable__cell"
              align="left"
            >
              <div className="mui-override sk-usersTable__cell__elements">
                <Text bold align="left" onClick={this.handleSortByCountry}>
                  COUNTRY
                </Text>
                <span
                  className={classnames(
                    "mui-override sk-usersTable__cell__elements__arrow",
                    {
                      rotate: isSortedByCountry ? isSortOrderAscending : true,
                      show: isSortedByCountry,
                    }
                  )}
                >
                  <ArrowToDown
                    height="12"
                    width="13"
                    color={isSortedByCountry ? Black : Grey4}
                  />
                </span>
              </div>
            </TableCell>
            <TableCell
              classes={classes.table}
              className="mui-override sk-usersTable__cell"
              align="left"
            >
              <div className="mui-override sk-usersTable__cell__elements">
                <Text bold align="left" onClick={this.handleSortByCity}>
                  CITY
                </Text>
                <span
                  className={classnames(
                    "mui-override sk-usersTable__cell__elements__arrow",
                    {
                      rotate: isSortedByCity ? isSortOrderAscending : true,
                      show: isSortedByCity,
                    }
                  )}
                >
                  <ArrowToDown
                    height="12"
                    width="13"
                    color={isSortedByCity ? Black : Grey4}
                  />
                </span>
              </div>
            </TableCell>
            {/* <TableCell
              classes={classes.table}
              className="mui-override sk-usersTable__cell"
              align="left"
            >
              <div className="mui-override sk-usersTable__cell__elements">
                <Text bold align="left" onClick={this.handleSortByRegisterDate}>
                  REGISTER DATE
                </Text>
                <span
                  className={classnames(
                    "mui-override sk-usersTable__cell__elements__arrow",
                    {
                      rotate: isSortedByCreatedAt ? isSortOrderAscending : true,
                      show: isSortedByCreatedAt,
                    }
                  )}
                >
                  <ArrowToDown
                    height="12"
                    width="13"
                    color={isSortedByCreatedAt ? Black : Grey4}
                  />
                </span>
              </div>
            </TableCell> */}
            <TableCell
              classes={classes.table}
              className="mui-override sk-usersTable__cell"
              align="left"
            >
              <div className="mui-override sk-usersTable__cell__elements">
                <Text bold align="left" onClick={this.handleSortByStatus}>
                  STATUS
                </Text>
                <span
                  className={classnames(
                    "mui-override sk-usersTable__cell__elements__arrow",
                    {
                      rotate: isSortedByStatus ? isSortOrderAscending : true,
                      show: isSortedByStatus,
                    }
                  )}
                >
                  <ArrowToDown
                    height="12"
                    width="13"
                    color={isSortedByStatus ? Black : Grey4}
                  />
                </span>
              </div>
            </TableCell>
            {!userRole && (
              <TableCell
                classes={classes.table}
                className="mui-override sk-usersTable__cell"
                align="left"
              >
                <div className="mui-override sk-usersTable__cell__elements">
                  <Text bold align="left" onClick={this.handleSortByStatus}>
                    ROLE
                  </Text>
                  <span
                    className={classnames(
                      "mui-override sk-usersTable__cell__elements__arrow"
                    )}
                  >
                    <ArrowToDown
                      height="12"
                      width="13"
                      color={isSortedByStatus ? Black : Grey4}
                    />
                  </span>
                </div>
              </TableCell>
            )}
            <TableCell
              classes={classes.tableCell}
              className="mui-override sk-usersTable__cell"
            />
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <TableRow>
              <TableCell
                classes={classes.tableCell}
                className="sk-UsersTable__cell sk-UsersTable__cell_loader"
                align="center"
              >
                <Loader center />
              </TableCell>
            </TableRow>
          ) : (
            <Fragment>{this.renderTableBody(adminListUsers)}</Fragment>
          )}
        </TableBody>
      </Table>
    );
  }
}
const blockUser = graphql(BLOCK_USER, {
  props: ({ mutate }) => ({
    adminSuspendRestoreUser: (input) => mutate({ variables: { input } }),
  }),
});

const enhancer = compose(blockUser);

export default enhancer(UsersTable);
