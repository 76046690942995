import React from 'react';
import PropTypes from 'prop-types';

const DocSearch = ({ height, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 44 58"
  >
    <g fill="none" fillRule="evenodd">
      <path
        stroke="#444"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M33.659 41.887V14.324L21.39 1.422H7.467C4.14 1.422 1.42 4.14 1.42 7.466v25.541"
      />
      <path
        stroke="#444"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M1.421 30.08V41.72c0 3.323 2.721 6.044
         6.046 6.044h20.147c3.325 0 6.045-2.72 6.045-6.044M21.57
          1.76v10.352c0 1.05.852 1.902 1.901 1.902h9.862L21.57 1.76z"
      />
      <path
        fill="#FFF"
        d="M43.316 39.214c0 6.768-5.486 12.254-12.253 12.254-6.768 
        0-12.254-5.486-12.254-12.254 0-6.767 5.486-12.253 12.254-12.253 
        6.767 0 12.253 5.486 12.253 12.253"
      />
      <path
        stroke="#30d5c8"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M43.316 39.214c0 6.768-5.486 12.254-12.253 12.254-6.768
         0-12.254-5.486-12.254-12.254 0-6.767 5.486-12.253 12.254-12.253 
         6.767 0 12.253 5.486 12.253 12.253z"
      />
      <path
        fill="#FFF"
        d="M16.795 54.058l-3.347
         2.81c-.373.27-1.04-.006-1.483-.615l-.419-.577c-.443-.61-.498-1.33-.125-1.6l9.292-7.806 
         2.41 2.476-3.446 2.893"
      />
      <path
        stroke="#30d5c8"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M16.795 54.058l-3.347 
        2.81c-.373.27-1.04-.006-1.483-.615l-.419-.577c-.443-.61-.498-1.33-.125-1.6l9.292-7.806
         2.41 2.476-3.446 2.893-2.882 2.42z"
      />
    </g>
  </svg>
);

DocSearch.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

DocSearch.defaultProps = {
  height: '44',
  width: '58',
};

export default DocSearch;
