import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  aboutUs: {
    id: 'app.routes.AboutUs.aboutUs',
    defaultMessage: 'About Us',
  },
  goToSkilleton: {
    id: 'app.routes.AboutUs.goToSkilleton',
    defaultMessage: 'Go to AVDO',
  },
});
