import React from 'react';
import PropTypes from 'prop-types';
import { Grey4, Red, White } from 'components/Icon/color';

const Tomato = ({ height, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 30 30"
  >
    <defs>
      <polygon
        id="tomato-a"
        points="0 .147 21.958 .147 21.958 22.094 0 22.094"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(2.982 5.099)">
        <mask id="tomato-b" fill={White}>
          <use xlinkHref="#tomato-a" />
        </mask>
        <path
          fill={Red}
          d="M9.83584315,5.3536527 C10.952812,5.15053369 12.1988191,4.52409535 12.1711548,3.52650993
           C12.1418195,2.46672628 10.952812,1.59780766 9.22667178,1.80074101 C7.50145985,2.00386002
            6.27810413,2.98436413 6.55140503,4.21440384 C6.82452026,5.44481489 8.71924559,5.55677171
             9.83584315,5.3536527 M20.3941327,6.67299794 C22.4880781,10.0940865 23.0682855,15.7801191
              18.3640566,19.6676016 C14.6893478,22.7041751 9.03952008,22.7934807 5.57220058,20.479892
               C2.87706753,18.6811562 0.57276219,15.5770001 0.0902152901,11.850676 C-0.404399924,8.03430377
                1.13236064,3.49940264 4.78720316,1.46858387 C8.44148869,-0.561120909 13.4368424,-0.0399554032
                 15.7240665,1.59780766 C17.6061665,2.94537419 19.2478285,4.79980991 20.3941327,6.67299794"
          mask="url(#tomato-b)"
        />
      </g>
      <g transform="translate(.807)">
        <path
          fill={Grey4}
          d="M23.5085669,9.34495311 C24.2971306,7.48663735 24.2971306,5.23403976 24.2408929,3.375312
           C22.8889223,4.27614504 21.4244764,5.23403976 20.3551363,6.75451738 C20.2982806,4.5013018
            18.2148081,2.47427297 20.3551363,0.109199998 C17.9739954,1.03022092 16.7991839,2.99957007
             16.5420973,4.90917955 C16.9056853,5.0989045 17.2340475,5.29707541 17.5037,5.50657626
              C19.5414408,7.09132553 21.50049,9.2192938 23.0193196,11.4984652 C23.2172845,11.7948976
               23.4063915,12.1733175 23.5860225,12.5937612 C25.5368317,12.7175665 27.6884899,12.1372677
                29.1403699,10.3582615 C27.0568974,10.9216684 25.5926574,9.57031587 23.5085669,9.34495311"
        />
        <path
          stroke={Grey4}
          d="M21.8940879,12.6374702 C24.1420395,16.3101742 24.7649203,22.4144053 19.714703,26.5878058
           C15.7697254,29.8477144 9.70436205,29.9435882 5.98202686,27.4598388 C3.08867116,25.5288089
            0.614887916,22.1963472 0.0968504776,18.1959581 C-0.43414288,14.0988978 1.21564391,9.23046213
             5.13929416,7.05027994 C9.06234643,4.87129369 14.4251003,5.43079007 16.8805459,7.18900794
              C18.9010713,8.63568579 20.663475,10.6265122 21.8940879,12.6374702"
        />
      </g>
    </g>
  </svg>
);

Tomato.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

Tomato.defaultProps = {
  height: '8',
  width: '9',
};

export default Tomato;
