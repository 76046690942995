import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TruncateMarkup from 'react-truncate-markup';
import { FormattedMessage } from 'react-intl';
import Text from 'components/Text';

import { sharedMessages } from 'services/i18n/sharedMessages/messages';

import './ReadMore.scss';

const ReadMore = ({ children, lines, textStyles, withToggle }) => {
  const [toogled, setToogled] = useState(true);
  const [isTruncated, setTruncated] = useState(false);

  const toggleLines = () => setToogled(!toogled);
  const handleTruncate = (wasTruncated) => {
    if (wasTruncated !== isTruncated) setTruncated(wasTruncated);
  }

  return (
    <>
      {toogled
        ? (
          <TruncateMarkup lines={lines} ellipsis=' ...' onTruncate={handleTruncate} tokenize='words'>
            <div style={textStyles}>{children}</div>
          </TruncateMarkup>
        ) : (
          <div style={textStyles}>{children}</div>
        )
      }
      {isTruncated && withToggle && (
        <button
            type='button'
            className='sk-read-more__button'
            onClick={toggleLines}
          >
            {toogled ? (
              <FormattedMessage {...sharedMessages.showMore} />
            ) : (
              <FormattedMessage {...sharedMessages.showLess} />
            )}
          </button>
      )}
    </>
  );
}

ReadMore.defaultProps = {
    lines: 3,
};

ReadMore.propTypes = {
    children: PropTypes.node.isRequired,
    lines: PropTypes.number,
};

export default ReadMore;
