import React, { PureComponent, Fragment } from 'react';
import MaterialTooltip from '@material-ui/core/Tooltip';
import classNames from 'classnames';

// Components
import Question from 'components/Icon/Svg/Question';

// Styles
import './ToolTip.scss';

const iconProps = {
  width: '25',
  height: '25',
};

class ToolTip extends PureComponent {
  state = {
    isOpen: false,
  };

  handleTransition = isOpen => this.setState({ isOpen });

  toogleToolTip = () => {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  };

  render() {
    const { iconTheme, trigger, children, label, triggerClasses, wide } = this.props;
    const { isOpen } = this.state;

    return (
      <MaterialTooltip
        open={isOpen}
        title={children}
        placement="right-start"
        classes={{
          tooltip: classNames('mui-override', 'sk-card sk-tooltip', {
            'sk-tooltip_wide': wide,
          }),
          popper: 'mui-override sk-popper',
        }}
        onClose={() => this.handleTransition(false)}
        onOpen={() => this.handleTransition(true)}
      >
        <div
          className={classNames('sk-tooltip-trigger', {
            [triggerClasses]: triggerClasses,
          })}
          onClick={this.toogleToolTip}
        >
          {trigger ? (
            <Fragment>{trigger}</Fragment>
          ) : (
            <Fragment>
              <div className="sk-tooltip-trigger_question">
                <Question {...iconProps} theme={iconTheme} active={isOpen} />
              </div>
              <div className="sk-tooltip-trigger_label">{label || null}</div>
            </Fragment>
          )}
        </div>
      </MaterialTooltip>
    );
  }
}

export default ToolTip;
