// Core
import React ,{ useCallback, useState} from 'react';
import classNames from 'classnames';
import { graphql, compose } from 'react-apollo';
import { Progress } from 'react-sweet-progress';
import slugify from '@sindresorhus/slugify';
import { FormattedMessage } from 'react-intl';

// Components
import IconWithModal from 'components/IconWithModal';
import Text from 'components/Text';
import Button from 'components/Button';
import Card from 'components/Card';
import FileUploader from 'components/FileUploader';
import UploadController from 'routes/Course/components/UploadController';
import ConfirmDelete from 'routes/ExpertsCabinet/components/ConfirmDelete/ConfirmDelete';
import Duration from 'components/Duration/Duration';
import { VideoPreview, QuizPreview, } from 'components/FilePreview';

// Icons
import Play from 'components/Icon/SvgCss/Play.js';
import Quiz from 'components/Icon/SvgCss/QuizAttachment.js';

// GraphQL
import GET_COURSE from 'queries/Courses/getCourse.gql';
import DELETE_ATTACHMENT from 'mutations/Courses/deleteAttachment.gql';

// HOCs
import { withModal } from 'containers/ModalProvider/withModal';
import { withUploadingStatuses } from  'containers/UploadManagerProvider/withUploadingStatuses.js';
import { withFormattedUntitled } from 'routes/ExpertsCabinet/QuizEdit/HOCs/withFormattedUntitled.js';
import { withLectureFilesUploader } from 'routes/Course/components/LecturesList/LectureItem/HOCs/withLectureFilesUploader';
import { withLanguage } from 'containers/LanguageProvider/withLanguage';
import { withAuthenticatedUser } from 'containers/AuthenticatedUserProvider/withAuthenticatedUser';

// Hooks
import useToppingMaterial from 'routes/ExpertsCabinet/ToppingEdit/ToppingDetails/ToppingMaterial/useToppingMaterial.js';
import useToppingMatterialsUploadMethods from 'routes/ExpertsCabinet/ToppingEdit/ToppingDetails/ToppingMaterial/useToppingMatterialsUploadMethods.js';

// Other
import { DarkGrey, White, NotActiveGrey } from 'components/Icon/color';
import { ADMIN, RECOMMENDED_VIDEO_FORMATS, DEFAULT_MAX_FILE_SIZE, } from 'utils/enums/index';
import { COURSE_PREVIEW, LECTURE_QUIZ, LECTURE_VIDEO, theme, } from 'containers/UploadManagerProvider/helpers';
import { getSeparatedFilename } from 'services/aws/amplify/helpers';
import { messages as toppingMessages } from 'routes/ExpertsCabinet/ToppingEdit/messages.js';
import { messages as quizMessages } from 'routes/ExpertsCabinet/QuizEdit/messages';
import { messages } from 'routes/ExpertsCabinet/CourseEdit/Details/messages';
import { sharedMessages } from 'services/i18n/sharedMessages/messages';

// Styles and Assets
import './index.scss';
import { withRouter } from 'react-router';

const MATERIALS_CONTROLLER = {
  [COURSE_PREVIEW]: {
    title: (<FormattedMessage {...toppingMessages.toppingPreviewLabel} />),
    uploadButton: (<FormattedMessage {...messages.uploadVideo} />),
    requirements: (<FormattedMessage {...messages.videoReqs500Mb} />),
    icon: Play,
    preview: VideoPreview,
  },
  [LECTURE_VIDEO]: {
    title: (<FormattedMessage {...toppingMessages.toppingLectureVideoLabel} />),
    uploadButton: (<FormattedMessage {...messages.uploadVideo} />),
    requirements: (<FormattedMessage {...messages.videoReqs500Mb} />),
    icon: Play,
    preview: VideoPreview,
  },
  [LECTURE_QUIZ]: {
    title: (<FormattedMessage {...toppingMessages.toppingLectureQuizLabel} />),
    uploadButton: (<FormattedMessage {...toppingMessages.addQuiz} />),
    requirements: '',
    icon: Quiz,
    preview: QuizPreview,
  },
}

const addedFileViewEnhancer = compose(
  withUploadingStatuses,
  withFormattedUntitled,
  withRouter,
  withLanguage,
  withAuthenticatedUser,
);
const AddedFileView = addedFileViewEnhancer(({
  disabled,
  file: { id, filepath, filename, title, duration, filetype, type, isPublic, url, courseId },
  fileUploadStatuses,
  fileUploadStatuses: {
    isFilePending,
    isFileUploaded,
    isFileInQueue,
    isFileInProgress,
    isFileUploadedAndProcessed,
    isFileProcessing,
    isFileProcessingFailed,
  },
  formatUntitled,
  handleAttachmentDelete,
  handleFileUpload,
  uploadManagerContext: { progress },
  modalContext: { showModal, hideModal },
  languageContext: { language },
  userContext: { profile },
  history
}) => {
  const handleDeleteButtonClick = async () => {
    try {
      showModal(ConfirmDelete, { handleDelete: handleFileDelete(id), close: hideModal });
    } catch (e) {
      throw new Error(e)
    }
  };

  const handleFileDelete = (id) => async () => {
    try {
      await handleAttachmentDelete(id);
    } catch (e) {
      throw new Error(e)
    }
  };

  const handleReloadQuiz = useCallback(() => {
    const isAdmin = profile?.role == ADMIN;
    const route = isAdmin ? 'admin/panel' : 'experts-cabinet';
    history.push(`/${language}/${route}/toppings/${courseId}/quiz/${filepath}`);
  }, [language, courseId, filepath, profile.role, history.push]);

  const handleReload = type == LECTURE_QUIZ ? handleReloadQuiz : handleFileUpload;
  const durationColor = (isFilePending || isFileProcessing) ? { color: NotActiveGrey } : { color: DarkGrey };

  return (
    <div className='sk-file-uploader__item'>
      <Card className="sk-file-uploader__item__data">
        <div className="sk-file-uploader__item__data__desc">
          <div className="sk-file-uploader__item__data__icon">
            <IconWithModal
              modal={MATERIALS_CONTROLLER[type].preview}
              modalProps={{ filepath, size: 'lg', fileType: filetype, isPublic, url, quizId: filepath, }}
              icon={MATERIALS_CONTROLLER[type].icon}
              disabled={isFilePending || isFileProcessing || disabled}
            />
          </div>
          <Text size="small" semiBold classes={classNames('sk-file-uploader__item__data__title', { 'inactive': isFilePending || isFileProcessing })}>
            {formatUntitled(title, toppingMessages.quizInfoUntitledTitle)}
          </Text>
        </div>
        <div className="sk-file-uploader__item__data__info">
          <div className="sk-file-uploader__item__data__info__controller">
            <UploadController
              uploaderId={`sk-topping-upload-${type}`}
              withS3Upload={type != LECTURE_QUIZ}
              isFilePending={isFilePending || isFileProcessing}
              isFileInQueue={isFileInQueue}
              disabled={disabled}
              supportedFormats={RECOMMENDED_VIDEO_FORMATS}
              maxSize={DEFAULT_MAX_FILE_SIZE}
              handleDelete={handleDeleteButtonClick}
              handleReload={handleReload}
            />
          </div>
          <div className={classNames('any-margin', { 'sk-file-uploader__item__data__info__duration': isFilePending || isFileProcessing })}>
            {(isFilePending || isFileProcessing) ? (
                <Duration duration={0} textStyles={durationColor} />
              ) : (
                <Duration duration={duration} textStyles={durationColor} />
            )}
          </div>
        </div>
      </Card>
      {(isFilePending && (isFileInQueue || isFileInProgress)) && (
        <Progress
          theme={theme}
          percent={isFileInProgress ? progress : 0}
          status={'active'} />
      )}
      {isFileUploaded && isFilePending && !isFileInProgress && (
        <Text className='sk-file-uploader__item__question inactive'><FormattedMessage {...sharedMessages.fileProcessing} /></Text>
      )}
      {isFileProcessing && (
        <Text className='sk-file-uploader__item__question inactive'><FormattedMessage {...sharedMessages.fileProcessing} /></Text>
      )}
    </div>
  );
});

const NoFileView = ({ type, disabled, buttonSize, handleFileUpload }) => {
  const [loading, setLoading] = useState(false)
  const buttonProps = type === LECTURE_QUIZ ? { onClick: (...props) =>{
    setLoading(true)
    handleFileUpload(...props)
  }} : {};

  return (
    <div className="sk-file-uploader__block">
      <div className="sk-file-uploader__btn">
        <Button size={buttonSize} disableRipple disabled={disabled} loading={loading} {...buttonProps}>
          <div>
            {type !== LECTURE_QUIZ && (
              <div className="sk-file-uploader__file">
                <FileUploader
                  absolute
                  supportedFormats=".mp4, .MOV, .mpg"
                  maxSize={DEFAULT_MAX_FILE_SIZE}
                  uploaderId={`sk-preview-upload-${type}`}
                  handleChange={handleFileUpload}
                  disabled={disabled}
                />
              </div>
            )}
            {MATERIALS_CONTROLLER[type]?.uploadButton}
          </div>
        </Button>
      </div>

      {MATERIALS_CONTROLLER[type]?.requirements && (
        <div className="sk-file-uploader__req">
          <Text
            classes="sk-lec-materials__sidenote"
            size="caption"
            light
            newLine
          >
            <span className="sk-lec-materials__asterix">* </span>
            <span>
              {MATERIALS_CONTROLLER[type]?.requirements}
            </span>
          </Text>
        </div>
      )}
    </div>
  );
};

const ToppingMaterial = ({
  type,
  topping,
  uploadFile,
  deleteAttachment,
  modalContext,
  buttonSize,
  disabledEditing,
  error,
}) => {
  const material = useToppingMaterial({ topping, type });
  const handleFileUpload = useToppingMatterialsUploadMethods({ type, topping, uploadFile, material });

  const isFileAdded = !!material?.id;

  return (
    <div className="sk-file-uploader">
      <Text classes="sk-file-uploader__header">{MATERIALS_CONTROLLER[type]?.title}</Text>
      <div>
        {isFileAdded ? (
          <AddedFileView
            file={material}
            modalContext={modalContext}
            handleAttachmentDelete={deleteAttachment}
            handleFileUpload={handleFileUpload}
            disabled={disabledEditing}
          />
        ) : (
          <NoFileView
            buttonSize={buttonSize}
            type={type}
            handleFileUpload={handleFileUpload}
            modalContext={modalContext}
            disabled={disabledEditing}
          />
        )}
      </div>
      <div className="sk-file-uploader__error">
        {error ? (
          <Text error size="caption" classes="sk-file-uploader__errorText">
            {error}
          </Text>
        ) : null}
      </div>
    </div>
  );
};

const deleteAttachmentMutation = graphql(DELETE_ATTACHMENT, {
  props: ({ mutate }) => ({
    deleteAttachment: id => mutate({ variables: { id }}),
  }),
  options: {
    refetchQueries: ['getCourse'],
  },
});

const enhancer = compose(
  withModal,
  deleteAttachmentMutation,
);

export default enhancer(ToppingMaterial);
