import React from 'react';

const Icon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.82349 12.3281V2.78192C2.82349 2.28242 3.22309 1.88281 3.7226 1.88281H9.36148C9.62788 1.88281 9.87208 1.99381 10.0497 2.20472L12.5694 5.14626C12.7026 5.31276 12.7803 5.51256 12.7803 5.73457V12.3503C12.7803 13.2716 12.0366 14.0153 11.1153 14.0153L4.47741 13.9931C3.5672 13.982 2.82349 13.2383 2.82349 12.3281Z" stroke="#A9B6C8" strokeMiterlimit="10"/>
      <path d="M9.62769 1.98242V4.94616C9.62769 5.21257 9.83859 5.42347 10.105 5.42347L12.6802 5.44567" stroke="#A9B6C8" strokeMiterlimit="10"/>
      <path d="M5.64697 8.32556L7.46328 10.3527L10.3529 7.05859" stroke="#A9B6C8" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default Icon;
