// Core
import React from 'react';

// Components
import Text from 'components/Text/Text';

// Styles and Assets
import './index.scss';

const RadioButtonLabel = ({
  withIcon,
  icon: Icon,
  text,
  disabled = false,
}) => {
  return (
    <div className="sk-radio-button-label">
      {withIcon ? (<Icon />) : (
        <Text
          size="caption"
          classes="sk-radio-button-label__name"
          disabled={disabled}
        >
          {text}
        </Text>
      )}
    </div>
  );
};

export default RadioButtonLabel;
