// Core
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import momentTimezone from 'moment-timezone';
import { Formik } from 'formik';

// Components
import EditEventForm from 'containers/Calendar/EditEventForm';

// Other
import { getYupSchema } from 'services/yup';
import { FormattedMessage } from 'react-intl';
import { messages } from 'containers/Calendar/messages';

// Styles and Assets
import './EditEventModal.scss';

class EditEventModal extends PureComponent {
  handleSubmit = async ({
    courseId,
    title,
    description,
    startTime,
    startDate,
    eventDuration,
  }) => {
    const {
      editEvent,
      item: { idEvent },
    } = this.props;

    const formattedDate =
      typeof startDate === 'string'
        ? startDate
        : startDate.format('YYYY-MM-DD');

    const parsedStartTime =
      typeof startTime === 'string' ? startTime : startTime.format('HH:mm');

    try {
      return await editEvent({
        courseId,
        title,
        description,
        startTime: parsedStartTime,
        startDate: formattedDate,
        eventDuration,
        idEvent,
      });
    } catch (error) {
      throw Error(error);
    }
  };

  handleEventDelete = async () => {
    const {
      handleDeleteEvent,
      item: { idEvent },
    } = this.props;
    try {
      await handleDeleteEvent(idEvent);
    } catch (error) {
      throw Error(error);
    }
  };

  render() {
    const {
      listCourses,
      item: { desc: description, title, end, start },
      courseId,
    } = this.props;

    const startDate = momentTimezone(start).format('YYYY-MM-DD');
    const startTime = momentTimezone(start);
    const eventDuration = end - start || '3600000';

    const fields = {
      description,
      title,
      startDate,
      courseId,
      startTime,
      eventDuration,
    };

    return (
      <div className="sk-calendar__modal">
        <h2>
          <FormattedMessage {...messages.editEvent} />
        </h2>
        <Formik
          onSubmit={async (values, formikBag) => {
            await this.handleSubmit(values, formikBag);
          }}
          render={props => (
            <EditEventForm
              {...props}
              listCourses={listCourses}
              handleEventDelete={this.handleEventDelete}
            />
          )}
          initialValues={fields}
          validateOnChange={false}
          validateOnBlur
          validationSchema={getYupSchema('editCalendarEventSchema')}
        />
      </div>
    );
  }
}

EditEventModal.propTypes = {
  listCourses: PropTypes.array,
  editEvent: PropTypes.func,
  deleteEvent: PropTypes.func,
  item: PropTypes.object,
};

export default EditEventModal;
