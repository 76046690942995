// Core
import React, { useState, useRef, useEffect } from 'react';
import ReactSwipe from 'react-swipe';
import classnames from 'classnames';

// Other
import { isBlank } from 'utils/helpers';

// Components
import CourseReviewCard from 'routes/CourseDetails/CourseReview/CourseReviewCard';

// Styles
import './index.scss';

const CARDS_ON_PAGE = 1;
const classNamePrefix = 'sk-course-review';

const CourseReviewCarousel = ({ rating }) => {
  const reactSwipeEl = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [commentsLenght, setCommentsLenght] = useState(0);

  useEffect(() => {
    if (reactSwipeEl?.current) {
      reactSwipeEl.current.swipe.setup();
    };
    const currentPosition = reactSwipeEl.current ? reactSwipeEl.current.getPos() : 0;
    const ratingSize = Array.isArray(rating) ? rating.length : 0;
    setCurrentSlide(currentPosition);
    setCommentsLenght(ratingSize);
  });

  const navClick = (ind) => {
    reactSwipeEl.current.slide(ind);
    setCurrentSlide(reactSwipeEl.current.getPos());
  }

  const getDots = () => {
    let buttons = [];
    if (rating && Array.isArray(rating)) {
      rating.forEach((elmt, index) => {
        if(index === currentSlide){
          buttons.push(<div className="carousel_dot active" onClick={() => navClick(index)}></div>);
        } else{
          buttons.push(<div className="carousel_dot" onClick={() => navClick(index)}></div>);
        }
      });
    }
    return buttons;
  }

  return(
    <div className={`${classNamePrefix}`}>
      <ReactSwipe
        childCount={commentsLenght}
        className={`${classNamePrefix}__swipe`}
        ref={reactSwipeEl}
        disabledScroll={true}
      >
        {!isBlank(rating) && rating.map((item, i) => {
          return (
            <div key={i} style={i == currentSlide ? { visibility: 'visible' } : { display: 'hidden' }} className={classnames(`${classNamePrefix}__slide`, {
              active: i == currentSlide,
            })}>
              <CourseReviewCard {...item} key={item.id} />
            </div>
          )
        })}
      </ReactSwipe>
      <div className="nav-controll">
        {getDots()}
      </div>
    </div>
  );
}

export default CourseReviewCarousel;
