// Core
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';

// Components
import FooterNavMenuItem from './FooterNavMenuItem';

// Other
import { messages } from './messages';

const getMenuItems = items => items.map(addFormattedTitle);

const addFormattedTitle = entity => {
  const title = `${entity.key}`;
  const { list } = entity;
  const listItems = list && {
    list: [...getMenuItems(list)],
  };

  return {
    ...entity,
    title: (messages[title] ? <FormattedMessage {...messages[title]} /> : title),
    ...listItems,
  };
};

const FooterNavMenu = memo(({ nav }) => (
  <>
    {getMenuItems(nav).map(({ key, title, list }) => (
      <FooterNavMenuItem title={title} list={list} key={key} />
    ))}
  </>
));

FooterNavMenu.propTypes = {
  nav: PropTypes.array,
};

export default injectIntl(FooterNavMenu);
