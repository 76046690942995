// Core
import React, { useState, useEffect, PureComponent } from 'react';
import PropTypes from 'prop-types';
import NoSsr from '@material-ui/core/NoSsr';
import { compose } from 'react-apollo';

// Components
import { withFileUrl } from 'containers/HOCs/withFileUrl';
import Loader from 'components/Loader';

// Styles and Assets
import './CourseModuleArticlePDF.scss';

let DocumentComponent = () => <></>;
let PageComponent = () => <></>;

const CourseModuleArticlePDF = ({ fileUrl, isLoaded }) => {
  const [numPages, setNumPages] = useState(1);
  const filePreviewWidth = document.getElementById('sk-content_main')
    ?.offsetWidth;

  const onDocumentLoadSuccess = document => {
    const { numPages } = document;
    setNumPages(numPages);
  };

  const handleError = errorType => error => {
    console.log(`An ${errorType} occured! See log below!`);
    console.error(error);
  };

  useEffect(() => {
    const fetchFileLink = async () => {
      if (!window.isServer) {
        const { Document, Page, pdfjs } = await import('react-pdf');
        DocumentComponent = Document;
        PageComponent = Page;
        pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
      }
    };
    fetchFileLink();
  });

  if (!isLoaded) return <Loader />;

  return (
    <NoSsr>
      <div className="sk-lec-article-pdf">
        <DocumentComponent
          file={fileUrl}
          externalLinkTarget="_blank"
          className="sk-lec-article-pdf__document"
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={handleError('ERROR WHILE LOADING')}
          onSourceError={handleError('SOURCE ERROR')}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <PageComponent
              className="sk-lec-article-pdf__page"
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              width={filePreviewWidth}
            />
          ))}
        </DocumentComponent>
      </div>
    </NoSsr>
  );
};

CourseModuleArticlePDF.propTypes = {
  filepath: PropTypes.string,
};

const enhancer = compose(withFileUrl);

export default enhancer(CourseModuleArticlePDF);
