import React, { memo } from 'react';

// Components
import MaterialTextField from 'components/Input/Material/MaterialTextField';
import InputWithAdornment from 'components/Input/Material/InputWithAdornment';

const TextInput = ({ type = 'text', adornment, ...rest }) => (
  <>
    {adornment ? (
      <InputWithAdornment type={type} adornment={adornment} {...rest} />
    ) : (
      <MaterialTextField type={type} {...rest} />
    )}
  </>
);

export default memo(TextInput);
