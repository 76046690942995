import React from 'react';

// Components
import Image from 'components/DraftEditor/ContentBlocks/Image';
import Video from 'components/DraftEditor/ContentBlocks/Video';

// Other
import { IMAGE, VIDEO } from 'components/DraftEditor/enums.js';
import { isNull } from 'utils/helpers/index';

const AtomicContentBlock = props => {
  const { contentState, block } = props;

  const entityKey = block.getEntityAt(0);
  const entity = contentState.getEntity(entityKey);
  const contentBlockProps = entity.getData();
  const type = entity.getType();

  let Component = null;

  if (type === IMAGE) {
    Component = Image;
  } else if (type === VIDEO) {
    Component = Video;
  }

  return !isNull(Component) ? <Component {...contentBlockProps} /> : null;
};

export default AtomicContentBlock;
