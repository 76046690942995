import React from 'react'
import { Route } from 'react-router'

const ScrollHandler = () => {
  if (window && !window.isServer) {
    window.scrollTo(0, 0);
  }
  return null;
};

export default () => <Route component={ScrollHandler} />;