// Core
import React, { memo } from 'react';
import FilledInput from '@material-ui/core/FilledInput';

// Styles and Assets
import '../FilterDropDown.scss';

const classes = {
  root: 'mui-override sk-filter-input sk-filter-input_major',
  focused: 'sk-filter-input_focused',
  disabled: 'mui-override sk-filter-input__disabled',
};

const getExtraInputClasses = value => ({
  root: `mui-override sk-filter-input sk-filter-input_major ${!value &&
    'sk-filter-input_major__empty'}`,
});

const mergeClasses = (basicClasses, extraclasses) => ({
  ...basicClasses,
  ...extraclasses,
});

export const MajorFilterInput = ({ ...rest }) => (
  <FilledInput
    {...rest}
    classes={{ ...mergeClasses(classes, getExtraInputClasses(rest.value)) }}
  />
);

MajorFilterInput.propTypes = {};

export default memo(MajorFilterInput);
