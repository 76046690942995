// Core
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose, graphql } from 'react-apollo';

// Components
import Button from 'components/Button';
import Loader from 'components/Loader';
import Link from 'components/Link';

// GraphQl
import MY_PROFILE_QUERY from 'queries/UserProfile/getProfile.gql';
import GET_EXPERT_CALENDAR_EVENT_QUERY from 'queries/Calendar/getExpertCalendarEvents.gql';

// Other
import { hasLoader } from 'containers/HOCs/hasLoader';
import { getCourseNearesetWebinar } from 'utils/reducers';

// Styles and Assets
import './CourseNearestWebinar.scss';

// Components
import Text from 'components/Text';
import Clock from 'components/Icon/Svg/Clock';
import Calendar from 'components/Icon/Svg/Calendar';

// Other
import { DarkGrey } from 'components/Icon/color';
import { FormattedMessage } from 'react-intl';
import { messages } from 'routes/ExpertsCabinet/CourseEdit/GeneralInfo/messages';

const CourseNearestWebinarDate = memo(
  ({ webinarStartDate, webinarStartTime }) => (
    <div className="sk-c-nearest-webinar-date">
      <div className="sk-c-nearest-webinar-date__row">
        <div className="sk-c-nearest-webinar-date__icon">
          <Clock width="16" height="16" color={DarkGrey} />
        </div>
        <Text size="small">{webinarStartTime}</Text>
      </div>
      <div className="sk-c-nearest-webinar-date__row">
        <div className="sk-c-nearest-webinar-date__icon">
          <Calendar width="20" height="18" color={DarkGrey} />
        </div>
        <Text size="small">{webinarStartDate}</Text>
      </div>
    </div>
  )
);

const CourseNearestWebinarContent = memo(({ title, webinarEvent }) => (
  <div className="sk-c-nearest-webinar-content">
    <header className="sk-c-nearest-webinar-content__header">
      <Text size="small" bold>
        {title}
      </Text>
    </header>
    <div className="sk-c-nearest-webinar__date">
      <CourseNearestWebinarDate
        webinarStartDate={webinarEvent?.date}
        webinarStartTime={webinarEvent?.time}
      />
    </div>
  </div>
));

const CourseNearestWebinar = ({ events, course, profile, loading }) => {
  const props = {
    events,
    course,
  };

  const nearestWebinar = events?.length
    ? getCourseNearesetWebinar('expert-course', profile.gmt_timezone, props)
    : null;

  if (loading) return <Loader small />;

  return (
    <div className="sk-c-nearest-webinar">
      {events?.length && nearestWebinar ? (
        <>
          <div className="sk-c-nearest-webinar__row">
            <Text size="caption" classes="sk-c-nearest-webinar__title">
              <FormattedMessage {...messages.webinar} />
            </Text>
            <CourseNearestWebinarContent
              title={course?.title}
              webinarEvent={nearestWebinar?.webinarEvent}
            />
            <div className="sk-c-nearest-webinar__actions">
              <Link
                to={`/publisher-webinar/${course?.id}/${
                  nearestWebinar?.webinarEvent?.id
                }`}
              >
                <Button color="secondary" size="medium" variant="text">
                  <FormattedMessage {...messages.startWebinar} />
                </Button>
              </Link>
            </div>
            <div className="sk-c-nearest-webinar__note">
              <Text
                classes="sk-lec-materials__sidenote"
                size="caption"
                light
                newLine
              >
                <span className="sk-c-nearest-webinar__asterix">* </span>
                <span>
                  <FormattedMessage {...messages.youCanProduct} />
                </span>
                <span>
                  <FormattedMessage {...messages.ifItIsNotInstalled} />{' '}
                  <a href="https://www.google.com/chrome/">
                    {' '}
                    <FormattedMessage {...messages.here} />
                  </a>
                  .
                </span>
              </Text>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

const getExpertCalendarEventsQuery = graphql(GET_EXPERT_CALENDAR_EVENT_QUERY, {
  props: ({
    data: { loading, error, expertListCalendarEvents, ...ownProps },
  }) => ({
    loading,
    events: expertListCalendarEvents,
    error,
    ...ownProps,
  }),
  options: () => ({
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        TimeFromUnixMs: new Date().getTime() - 100000000000,
        TimeToUnixMs: new Date().getTime() + 100000000000,
      },
    },
  }),
});

const myProfileQuery = graphql(MY_PROFILE_QUERY, {
  props: ({ data: { getProfile, error, ...ownProps } }) => ({
    profile: getProfile,
    error,
    ...ownProps,
  }),
  options: () => ({
    fetchPolicy: 'cache-only',
  }),
});

CourseNearestWebinar.propTypes = {
  events: PropTypes.array,
  course: PropTypes.object,
  loading: PropTypes.bool,
  profile: PropTypes.object,
};

CourseNearestWebinarContent.propTypes = {
  title: PropTypes.string,
  webinarEvent: PropTypes.object,
};

CourseNearestWebinarDate.propTypes = {
  webinarStartDate: PropTypes.string,
  webinarStartTime: PropTypes.string,
};

const enhancer = compose(
  getExpertCalendarEventsQuery,
  myProfileQuery
);

export default hasLoader(
  enhancer(CourseNearestWebinar),
  GET_EXPERT_CALENDAR_EVENT_QUERY
);
