import React from 'react';

const Icon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.0153 8.89337L16.0153 8.89341C16.1595 9.0375 16.2272 9.19968 16.2271 9.40881V9.40908V16.7728C16.2271 16.9822 16.1594 17.1445 16.0157 17.2885C15.8718 17.4323 15.7097 17.5 15.4997 17.5H3.22723C3.01756 17.5 2.85525 17.4323 2.71133 17.2885C2.56767 17.1446 2.5 16.9824 2.5 16.7728V9.40921C2.5 9.19907 2.56776 9.03697 2.71136 8.89347L2.7115 8.89334C2.85526 8.74957 3.01745 8.68189 3.22723 8.68189H3.63626H4.13626V8.18189V5.72721C4.13626 4.29375 4.64441 3.07389 5.67721 2.04108L5.67723 2.04106C6.70985 1.00832 7.92988 0.5 9.36347 0.5C10.7971 0.5 12.0168 1.0083 13.0496 2.04111L13.0496 2.04114C14.0824 3.07389 14.5905 4.29379 14.5905 5.72721V8.18189V8.68189H15.0905H15.4994C15.7094 8.68189 15.8715 8.74956 16.0153 8.89337ZM12.6361 8.68189H13.1361V8.18189V5.72721C13.1361 4.69117 12.7638 3.79261 12.0311 3.05985L12.031 3.05981C11.298 2.3269 10.3998 1.95468 9.3636 1.95468C8.32747 1.95468 7.4291 2.32694 6.69612 3.05981C5.96322 3.79261 5.59081 4.69113 5.59081 5.72721V8.18189V8.68189H6.09081H12.6361Z"
        stroke="#B9BDBF"
      />
    </svg>
  );
};

export default Icon;
