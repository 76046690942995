// Core
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router';
import { withSidebar } from 'containers/SidebarProvider/withSidebar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';

// Components
import ChevronDown from 'components/Icon/Svg/ChevronDown';
import ChevronUp from 'components/Icon/Svg/ChevronUp';

// Other
import { renderTrimmedText } from 'utils/helpers';

// Styles
import './ExpandableSidebarMenu.scss';
import 'components/DropDown/DropDown.scss';

const overrideClasses = {
  collapse: {
    container:
      'mui-override sk-expandable-sidebar-menu-content paper paper-shadow',
  },
  expandable: {
    root:
      'mui-override sk-expandable-sidebar-menu-item sk-dropdown-item sk-dropdown-item__gutters',
  },

  listItem: {
    gutters: 'mui-override sk-dropdown-item sk-dropdown-item__gutters',
  },

  listItemText: {
    primary: 'mui-override sk-expandable-sidebar-menu-item-text',
  },
  listItemIcon: {
    root: 'mui-override sk-expandable-sidebar-menu-item-icon',
  },
  fullWidth: {
    root: 'mui-override sk-fullWidth sk-expandable-sidebar-menu-list',
  },
};

//  FIXME: Merge this component with a src/components/DropDown/ExpandableDropDown/ExpandableMenu component

class ExpandableSidebarMenu extends PureComponent {
  state = {
    openedItemId: null,
  };

  handleClick = (openedItemId, itemIsCollapsed) => () => {
    this.setState({ openedItemId: itemIsCollapsed ? openedItemId : null });
  };

  getList = (listItems, rootList = false) => {
    const { fullWidth } = this.props;
    const listProps = {
      component: 'ul',
      disablePadding: rootList,
    };

    return (
      <List {...listProps} classes={fullWidth && overrideClasses.fullWidth}>
        {listItems.map(item => this.getListItem(item))}
      </List>
    );
  };

  navigate = ({ category, id }) => () => {
    const {
      history,
      sidebarContext: { hideSidebar },
    } = this.props;
    history.push(`/courses?category=${category}&subcategory=${id}`);
    hideSidebar();
  };

  getListItem = listItem => {
    const { title, subItems, id, name, hasCourses } = listItem;
    const { openedItemId } = this.state;
    const isItemCollapsed = openedItemId !== id;
    const itemHasSubItems = subItems?.length;
    const isItemSubcategory = name === 'subcategory';
    let isSubcategoryDisabled = false;

    if (isItemSubcategory) isSubcategoryDisabled = !hasCourses;

    return (
      <div key={id}>
        <ListItem
          disabled={isItemSubcategory && isSubcategoryDisabled}
          button
          onClick={
            itemHasSubItems
              ? this.handleClick(id, isItemCollapsed)
              : this.navigate(listItem)
          }
          classes={overrideClasses.expandable}
        >
          <ListItemText
            classes={overrideClasses.listItemText}
            primary={renderTrimmedText(title, 16)}
          />
          {isItemCollapsed ? (
            <ListItemIcon classes={overrideClasses.listItemIcon}>
              <i>
                <ChevronDown />
              </i>
            </ListItemIcon>
          ) : (
            <ListItemIcon classes={overrideClasses.listItemIcon}>
              <i>
                <ChevronUp active={!isItemCollapsed} />
              </i>
            </ListItemIcon>
          )}
        </ListItem>

        {subItems && (
          <Collapse
            in={!isItemCollapsed}
            timeout="auto"
            unmountOnExit
            classes={overrideClasses.collapse}
          >
            {this.getList(subItems)}
          </Collapse>
        )}
      </div>
    );
  };

  render() {
    const { items } = this.props;
    const list = this.getList(items, true);

    return list;
  }
}

export default withRouter(withSidebar(ExpandableSidebarMenu));
