// Core
import React, { memo } from 'react';

// Components
import HomeworkTableRow from 'routes/ExpertsCabinet/Homeworks/HomeworksTable/HomeworkTableRow';

export const StudentsList = ({ students, ...props }) => (
  <div className="sk-StudentsList">
    {students.map(item => (
      <HomeworkTableRow key={item.mappingId} item={item} {...props} />
    ))}
  </div>
);

export default memo(StudentsList);
