// Core
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { graphql, compose } from 'react-apollo'
import slugify from '@sindresorhus/slugify'

// Components
import ConfirmDelete from 'routes/ExpertsCabinet/components/ConfirmDelete/ConfirmDelete'

// GraphQL
import DELETE_ATTACHMENT from 'mutations/Courses/deleteAttachment.gql'
import UPDATE_ATTACHMENT from 'mutations/Courses/updateAttachment.gql'
import CREATE_QUIZ from 'mutations/Courses/quiz/createQuiz.gql'

// Other
import { withUploadManager } from 'containers/UploadManagerProvider/withUploadManager'
import { getSeparatedFilename } from 'services/aws/amplify/helpers'
import {
  COURSE_PREVIEW,
  LECTURE_ARTICLE,
  LECTURE_VIDEO,
  LECTURE_AUDIO,
  LECTURE_HOMEWORK_TASK,
  LECTURE_QUIZ,
} from 'containers/UploadManagerProvider/helpers'
import { ADMIN, QUIZ_ATTACHMENT } from 'utils/enums'
import { withLanguage } from 'containers/LanguageProvider/withLanguage'
import { withRouter } from 'react-router'
import { withSharedState } from 'containers/SharedStateProvider/withSharedState';
import { withAuthenticatedUser } from 'containers/AuthenticatedUserProvider/withAuthenticatedUser';

const deleteAttachmentMutation = graphql(DELETE_ATTACHMENT, {
  props: ({ mutate }) => ({
    deleteAttachment: id => mutate({ variables: { id } }),
  }),
  options: {
    refetchQueries: ['getCourseLectures'],
  },
})

const updateAttachmentMutation = graphql(UPDATE_ATTACHMENT, {
  props: ({ mutate }) => ({
    updateAttachment: input => mutate({ variables: { input } }),
  }),
  options: {
    refetchQueries: ['getCourseLectures'],
  },
})

const createQuizMutation = graphql(CREATE_QUIZ, {
  props: ({ mutate }) => ({
    createQuiz: input => mutate({ variables: { input: input || {} } }),
  }),
  // options: {
  //   refetchQueries: ['getCourseLectures'],
  // },
})

const enhancer = compose(
  withRouter,
  withSharedState,
  withUploadManager,
  deleteAttachmentMutation,
  createQuizMutation,
  updateAttachmentMutation,
  withLanguage,
  withAuthenticatedUser
);

export const withLectureFilesUploader = WrappedComponent => {
  class HOC extends PureComponent {
    handleAddLectureFileToQueue = type => ({
      id,
      file,
      title,
      duration = 0,
      lectureId,
      courseId,
      filetype,
      isPublic = false,
    }) => {
      const {
        uploadManagerContext: { addFileToQueue },
        refetchCourse,
        lecture,
      } = this.props
      const { fileName, ext } = getSeparatedFilename(file.name)
      const beautyFilename = `${slugify(fileName)}.${ext}`

      const totalMaterials = lecture?.materials?.length
      const nextSortNumber = totalMaterials + 1

      const params = {
        type,
        s3UploadingVariables: {
          filename: beautyFilename,
          file,
          courseId,
          lectureId,
        },
        createAttachmentMutationVariables: {
          id,
          type,
          duration,
          filetype,
          is_public: isPublic,
          filename: beautyFilename,
          title,
          course_id: courseId,
          lecture_id: lectureId,
          sort_number: nextSortNumber,
        },
        s3PostHook: refetchCourse,
      }

      addFileToQueue(params)
    }

    handleAddQuizToQueue = async ({
      lectureId,
      courseId,
      isPublic = false,
      referer = 'course'
    }) => {
      const {
        lecture,
        history,
        refetchCourse,
        createQuiz,
        languageContext: { language },
        uploadManagerContext: { addFileToQueue },
        sharedStateContext: { sharedState, updateSharedState, },
        userContext: { profile },
      } = this.props;
      updateSharedState({ isContentLoading: true });

      try {
        const isAdmin = profile?.role === ADMIN;

        const quizParams = {
          course_id: courseId
        }

        const { data: { createQuiz: createdQuiz }} = await createQuiz(isAdmin ? quizParams : null);

        const totalMaterials = lecture?.materials?.length
        const nextSortNumber = totalMaterials + 1

        const params = {
          type: LECTURE_QUIZ,
          createAttachmentMutationVariables: {
            is_public: isPublic,
            type: LECTURE_QUIZ,
            filetype: QUIZ_ATTACHMENT,
            status: 'UPLOADED',
            course_id: courseId,
            lecture_id: lectureId,
            filepath: createdQuiz?.id,
            sort_number: nextSortNumber,
          },
        };
        await addFileToQueue(params);

        const route = isAdmin ? 'admin/panel' : 'experts-cabinet';
        history.push(`/${language}/${route}/${referer}/${courseId}/quiz/${createdQuiz?.id}`);

        updateSharedState({ isContentLoading: false });
      } catch (e) {
        console.log('Error while creating quiz', e);
        updateSharedState({ isContentLoading: false });
      }
    }

    handleCancelMaterialAdding = id => {
      const {
        uploadManagerContext: { queue, removeFileFromQueue },
        deleteAttachment,
      } = this.props
      const isFileInQueue = queue.find(queueItem => queueItem.uploadId === id)

      if (isFileInQueue) removeFileFromQueue(id)
    }

    handleAttachmentDelete = id => {
      const {
        modalContext: { showModal, hideModal },
        deleteAttachment,
      } = this.props

      try {
        const remove = async () => {
          this.handleCancelMaterialAdding(id)
          await deleteAttachment(id)
        }
        showModal(ConfirmDelete, { close: hideModal, handleDelete: remove })
      } catch (error) {
        throw Error(error)
      }
    }

    handleAttachmentUpdate = async params => {
      const { updateAttachment } = this.props
      try {
        await updateAttachment(params)
      } catch (error) {
        throw Error(error)
      }
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          handleUploadPreview={this.handleAddLectureFileToQueue(COURSE_PREVIEW)}
          handleUploadVideo={this.handleAddLectureFileToQueue(LECTURE_VIDEO)}
          handleUploadAudio={this.handleAddLectureFileToQueue(LECTURE_AUDIO)}
          handleUploadArticle={this.handleAddLectureFileToQueue(
            LECTURE_ARTICLE
          )}
          handleUploadHomework={this.handleAddLectureFileToQueue(
            LECTURE_HOMEWORK_TASK
          )}
          handleUploadQuiz={this.handleAddQuizToQueue}
          handleAttachmentDelete={this.handleAttachmentDelete}
          handleAttachmentUpdate={this.handleAttachmentUpdate}
        />
      )
    }
  }

  return enhancer(HOC)
}
