import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  notMoreOnSale: {
    id: 'app.routes.CourseDetails.Error.notMoreOnSale',
    defaultMessage: 'Course is not available for sales',
  },
  goToSearch: {
    id: 'app.routes.CourseDetails.Error.goToSearch',
    defaultMessage: 'Search other courses',
  },
});
