import React from 'react';
import PropTypes from 'prop-types';

const Facebook = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.5798 31.7917C18.4465 31.8135 18.3127 31.8335 18.1785 31.8518C18.3127 31.8335 18.4465 31.8135 18.5798 31.7917Z"
      fill="white"
    />
    <path
      d="M18.8999 31.7371C18.8362 31.7488 18.7725 31.7595 18.7087 31.7705C18.7725 31.7595 18.8362 31.7488 18.8999 31.7371Z"
      fill="white"
    />
    <path
      d="M17.821 31.8962C17.665 31.9138 17.5081 31.9294 17.3506 31.9426C17.5081 31.9294 17.665 31.9138 17.821 31.8962Z"
      fill="white"
    />
    <path
      d="M18.1284 31.8586C18.0535 31.8687 17.978 31.8779 17.9028 31.8867C17.978 31.8779 18.0535 31.8687 18.1284 31.8586Z"
      fill="white"
    />
    <path
      d="M19.3354 31.6506C19.2793 31.6626 19.2229 31.6746 19.1665 31.686C19.2229 31.6746 19.2793 31.6626 19.3354 31.6506Z"
      fill="white"
    />
    <path
      d="M20.4165 31.3821C20.3711 31.395 20.3257 31.4072 20.2803 31.4199C20.3257 31.4072 20.3711 31.395 20.4165 31.3821Z"
      fill="white"
    />
    <path
      d="M20.0896 31.4714C20.0403 31.4844 19.9907 31.4976 19.9412 31.51C19.9907 31.4976 20.0403 31.4846 20.0896 31.4714Z"
      fill="white"
    />
    <path
      d="M19.6619 31.5784C19.6096 31.5906 19.5569 31.6021 19.5042 31.6138C19.5569 31.6021 19.6096 31.5906 19.6619 31.5784Z"
      fill="white"
    />
    <path
      d="M17.3455 31.9431C17.2612 31.9502 17.177 31.9565 17.0925 31.9622C17.177 31.9565 17.2612 31.9502 17.3455 31.9431Z"
      fill="white"
    />
    <path
      d="M32 16C32 7.16479 24.8352 0 16 0C7.16479 0 0 7.16479 0 16C0 24.8352 7.16479 32 16 32C16.094 32 16.1875 31.998 16.2812 31.9963V19.541H12.8438V15.5349H16.2812V12.5869C16.2812 9.16797 18.3684 7.30713 21.4182 7.30713C22.8787 7.30713 24.134 7.41602 24.5 7.4646V11.0378H22.397C20.7378 11.0378 20.4165 11.8264 20.4165 12.9834V15.5349H24.3838L23.8667 19.541H20.4165V31.3818C27.1042 29.4646 32 23.3032 32 16Z"
      fill="white"
    />
    <path
      d="M17.0571 31.9644C16.8906 31.9751 16.7234 31.9836 16.5557 31.9893C16.7234 31.9836 16.8906 31.9751 17.0571 31.9644Z"
      fill="white"
    />
    <path
      d="M16.5471 31.9897C16.4587 31.9927 16.3701 31.9949 16.2815 31.9963C16.3701 31.9949 16.4587 31.9927 16.5471 31.9897Z"
      fill="white"
    />
  </svg>
);

export default Facebook;
