import React from 'react';
import { Red, Grey2 } from 'components/Icon/color';

const CourseTitle = ({ height = 20, width = 20, fill = '#444444' }) => (
  <svg width={width} height={height} viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M1.5 2.65043C1.5 2.01724 2.01486 1.50392 2.63342 1.50392H15.3666C15.9851 1.50392 16.5 2.01724 16.5 2.65043V12.5001V12.7026V14.5945L16.3929 17.7416L16.3929 17.7417C16.3619 18.6455 15.6434 19.3644 14.7566 19.3945L14.7564 19.3945L11.5767 19.5035H2.63342C2.015 19.5035 1.5 18.99 1.5 18.3567V2.65043ZM17.5 2.65043V12.5001V12.7026V14.603L17.4997 14.62L17.3923 17.7757L17.3923 17.7758C17.3436 19.1978 16.2104 20.3458 14.7904 20.3939L11.6024 20.5032L11.5852 20.5035H2.63342C1.45486 20.5035 0.5 19.5344 0.5 18.3567V2.65043C0.5 1.47256 1.455 0.50392 2.63342 0.50392H15.3666C16.545 0.50392 17.5 1.47256 17.5 2.65043ZM4.4909 4.80647C4.21476 4.80647 3.9909 5.03032 3.9909 5.30647C3.9909 5.58261 4.21476 5.80647 4.4909 5.80647H13.2182C13.4943 5.80647 13.7182 5.58261 13.7182 5.30647C13.7182 5.03032 13.4943 4.80647 13.2182 4.80647H4.4909ZM3.9909 7.6029C3.9909 7.32676 4.21476 7.1029 4.4909 7.1029H13.2182C13.4943 7.1029 13.7182 7.32676 13.7182 7.6029C13.7182 7.87904 13.4943 8.1029 13.2182 8.1029H4.4909C4.21476 8.1029 3.9909 7.87904 3.9909 7.6029ZM4.4909 9.48318C4.21476 9.48318 3.9909 9.70704 3.9909 9.98318C3.9909 10.2593 4.21476 10.4832 4.4909 10.4832H13.2182C13.4943 10.4832 13.7182 10.2593 13.7182 9.98318C13.7182 9.70704 13.4943 9.48318 13.2182 9.48318H4.4909ZM3.9909 12.2796C3.9909 12.0035 4.21476 11.7796 4.4909 11.7796H13.2182C13.4943 11.7796 13.7182 12.0035 13.7182 12.2796C13.7182 12.5558 13.4943 12.7796 13.2182 12.7796H4.4909C4.21476 12.7796 3.9909 12.5558 3.9909 12.2796ZM4.4909 14.1422C4.21476 14.1422 3.9909 14.3661 3.9909 14.6422C3.9909 14.9183 4.21476 15.1422 4.4909 15.1422H8.85454C9.13068 15.1422 9.35454 14.9183 9.35454 14.6422C9.35454 14.3661 9.13068 14.1422 8.85454 14.1422H4.4909Z" fill={fill}/>
  </svg>

);

export default CourseTitle;
