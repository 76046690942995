// Core
import React, { memo, PureComponent } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { compose } from 'react-apollo';
// import { Formik } from 'formik';

// Components
// import TextInput from 'components/Input/Material/TextInput';
// import Text from 'components/Text';
// import AccountBankDetailsForm from 'routes/ManageAccount/AccountBankDetails/AccountBankDetailsForm';
// import Analytics from 'routes/AdminPanel/UserView/ExpertDetails/Analytics';
import Button from 'components/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

// Other
// import { messages } from 'routes/AdminPanel/messages';
import { isBlank } from 'utils/helpers';

// Styles and Assets
import './ExpertDetails.scss';

class ExpertDetails extends PureComponent {
  render() {
    const {
      intl: { formatMessage },
      profile
    } = this.props;
    const aboutExpert = !isBlank(profile.aboutMe)
      ? profile.aboutMe
      : profile.expertBio;

    const formFields = {
      paymentAccountEmail: profile.paymentAccountEmail || '',
      paymentSystem: profile?.paymentSystem || '',
      email: profile?.wireTransfer?.email || '',
      accountType: profile?.wireTransfer?.accountType || true,
      country: profile?.wireTransfer?.country?.id
        ? profile?.wireTransfer?.country?.name
        : '',
      city: profile?.wireTransfer?.city || '',
      accountNumber: profile?.wireTransfer?.accountNumber || '',
      recipientName: profile?.wireTransfer?.recipientName || '',
      address: profile?.wireTransfer?.address || '',
      postcode: profile?.wireTransfer?.postcode || '',
      swift: profile?.wireTransfer?.swift || ''
    };

    return (
      <></>
      // <Box sx={{ flexGrow: 1 }}>
      //   <Grid container spacing={4}>
      //     <Grid item xs={4}>
      //       <Card classes='dashboard__card'>
      //         <CardContent>
      //           <Typography
      //             sx={{ fontSize: 14 }}
      //             color='text.secondary'
      //             gutterBottom
      //           >
      //             Previous Period
      //           </Typography>
      //           <Typography variant='h5' component='div'>
      //             0.00
      //           </Typography>
      //           <Typography sx={{ mb: 1.5 }} color='text.secondary'>
      //             GBP
      //           </Typography>
      //         </CardContent>
      //       </Card>
      //     </Grid>
      //     <Grid item xs={4}>
      //       <Card classes='dashboard__card'>
      //         <CardContent>
      //           <Typography
      //             sx={{ fontSize: 14 }}
      //             color='text.secondary'
      //             gutterBottom
      //           >
      //             Current Period
      //           </Typography>
      //           <Typography variant='h5' component='div'>
      //             0.00
      //           </Typography>
      //           <Typography sx={{ mb: 1.5 }} color='text.secondary'>
      //             GBP
      //           </Typography>
      //         </CardContent>
      //       </Card>
      //     </Grid>
      //   </Grid>
      // </Box>
      // <div className="mui-override sk-user-view-expert-details">
      //   <Text
      //     size="small"
      //     className="mui-override sk-user-view-expert-details__section-text"
      //   >
      //     Expert’s Details
      //   </Text>
      //   <TextInput
      //     disabled
      //     name="expertise"
      //     label={<FormattedMessage {...messages.expertise} />}
      //     placeholder={formatMessage(messages.expertise)}
      //     value={profile.expertises}
      //   />
      //   <TextInput
      //     disabled
      //     name="aboutYou"
      //     label={<FormattedMessage {...messages.aboutYou} />}
      //     placeholder={formatMessage(messages.aboutYou)}
      //     value={aboutExpert}
      //   />
      //   <Text
      //     size="small"
      //     className="mui-override sk-user-view-expert-details__section-text"
      //   >
      //     Expert’s Payment Details
      //   </Text>
      //   <div className="mui-override sk-user-view-expert-details__payment">
      //     <Formik
      //       initialValues={formFields}
      //       enableReinitialize
      //       validateOnChange={false}
      //       validateOnBlur
      //       render={props => (
      //         <AccountBankDetailsForm disabled={true} {...props} />
      //       )}
      //     />
      //   </div>
      //   {!isBlank(profile?.subscription) && (
      //     <Analytics
      //       subscription={profile?.subscription}
      //       totalActiveCourses={profile?.totalActiveCourses}
      //     />
      //   )}
      // </div>
    );
  }
}

const enhancer = compose(memo, injectIntl);

export default enhancer(ExpertDetails);
