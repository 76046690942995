import React, { memo, useCallback } from 'react';
import { compose } from 'react-apollo';
import Slider from '@material-ui/core/Slider';
import { injectIntl } from 'react-intl';

// Components
import Text from 'components/Text';
import UnlimitedCourses from 'routes/ExpertsCabinet/ExpertsFee/SubscriptionSelect/MaxCoursesSelect/UnlimitedCourses';
import RoundIcon from 'components/Icon/RoundIcon';

// Other
import { isBlank } from 'utils/helpers';
import { messages } from 'routes/ExpertsCabinet/ExpertsFee/messages.js';

//Styles
import './index.scss';
import Plus from 'routes/ExpertsCabinet/ExpertsFee/SubscriptionSelect/MaxCoursesSelect/icons/Plus.js';
import Minus from 'routes/ExpertsCabinet/ExpertsFee/SubscriptionSelect/MaxCoursesSelect/icons/Minus.js';
// const classes = {
//   root: 'mui-override sk-slider',
//   thumb: 'mui-override sk-slider__thumb',
//   rail: 'mui-override sk-slider__rail',
//   track: 'mui-override sk-slider__track',
//   mark: 'mui-override sk-slider__mark',
//   markActive: 'mui-override sk-slider__mark__active',
//   markLabel: 'mui-override sk-slider__mark-label',
//   markLabelActive: 'mui-override sk-slider__mark-label__active',
// };

// const marks = [1, 2, 3, 4, 5].map(item => ({ value: item, label: item }));

const PREFIX = 'sk-subscription-select-max-course'
const SubscriptionSelect = ({
  maxCourses,
  updateSubscription,
  intl: { formatMessage },
  ...props
}) => {
  const handleChange = useCallback((type) => () => {
    if (type === 'increase') {
      maxCourses++;
    } else if (maxCourses > 1) {
      maxCourses--;
    }
    updateSubscription('maxCourses', maxCourses);
  }, [updateSubscription, maxCourses]);
  return (
    <div className={PREFIX}>
      <Text>{formatMessage(messages.maxCoursesTitle)}</Text>
      {!isBlank(maxCourses) ? (
        <div className={`${PREFIX}__counter`}>
          <RoundIcon handleClick={handleChange('decrease')} action={true} type="flat" icon={<Minus />} />
          <Text>{maxCourses}</Text>
          <RoundIcon handleClick={handleChange('increase')} action={true} type="flat" icon={<Plus />} />
        </div>
      ) : (
        <UnlimitedCourses />
      )}
    </div>
  );
};

const enhancer = compose(
  memo,
  injectIntl
);

export default enhancer(SubscriptionSelect);
