import React from 'react';
import PropTypes from 'prop-types';
import { PurpleHeart } from 'components/Icon/color';

const OpenMenu = ({ height, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 36 36"
    width={width}
    height={height}
  >
    <defs>
      <circle id="b" cx="15" cy="15" r="15" />
      <filter
        id="a"
        width="266.7%"
        height="266.7%"
        x="-83.3%"
        y="-66.7%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="5" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="7.5"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0.926419005 0 0 0 0 0.391547163 0 0 0 0 0.283218689 0 0 0 0.166893116 0"
        />
      </filter>
    </defs>
    <g fill={PurpleHeart}>
      <g transform="translate(3 3)">
        <use fill="#000" filter="url(#a)" xlinkHref="#b" />
        <use fill="#30d5c8" xlinkHref="#b" />
      </g>
      <path
        stroke="#FFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M21.333 25.5l-7.5-7.083 7.5-7.084"
      />
    </g>
  </svg>
);

OpenMenu.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

OpenMenu.defaultProps = {
  height: '36',
  width: '36',
};

export default OpenMenu;
