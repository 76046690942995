import React from 'react';

const Icon = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.41162 19.2612V4.34619C4.41162 3.56575 5.03597 2.94141 5.8164 2.94141H14.6266C15.0429 2.94141 15.4244 3.11484 15.7019 3.44435L19.6388 8.04024C19.8469 8.30039 19.9683 8.61256 19.9683 8.95942V19.2958C19.9683 20.7353 18.8063 21.8973 17.3668 21.8973L6.99573 21.8626C5.5736 21.8453 4.41162 20.6833 4.41162 19.2612Z" stroke="#A9B6C8" strokeMiterlimit="10"/>
      <path d="M15.043 3.09766V7.72823C15.043 8.14446 15.3725 8.47398 15.7887 8.47398L19.8123 8.50867" stroke="#A9B6C8" strokeMiterlimit="10"/>
      <path d="M12.8369 14.8784C12.8765 14.9633 12.8962 15.0387 12.8962 15.1047C12.8962 15.2366 12.8394 15.3497 12.7257 15.444C12.6169 15.5336 12.4908 15.5783 12.3474 15.5783C12.2535 15.5783 12.1645 15.5548 12.0804 15.5076C11.9964 15.4558 11.9321 15.3804 11.8876 15.2814L11.4945 14.4472H8.9804L8.58733 15.2814C8.54283 15.3804 8.47856 15.4558 8.39451 15.5076C8.3154 15.5548 8.2264 15.5783 8.12752 15.5783C7.98413 15.5783 7.85558 15.5336 7.74187 15.444C7.62815 15.3497 7.57129 15.2366 7.57129 15.1047C7.57129 15.0387 7.59107 14.9633 7.63062 14.8784L9.63304 10.8699C9.68743 10.7567 9.76901 10.6719 9.87778 10.6153C9.98655 10.5541 10.1027 10.5234 10.2263 10.5234C10.3549 10.5234 10.4736 10.5541 10.5823 10.6153C10.6911 10.6766 10.7752 10.7615 10.8345 10.8699L12.8369 14.8784ZM11.1015 13.6129L10.2412 11.7748L9.37347 13.6129H11.1015Z" fill="#A9B6C8"/>
      <path d="M16.6636 13.1605C16.7724 13.1605 16.8638 13.1958 16.938 13.2665C17.0121 13.3325 17.0492 13.4173 17.0492 13.521C17.0492 13.6247 17.0121 13.7119 16.938 13.7826C16.8638 13.8533 16.7724 13.8887 16.6636 13.8887H15.6253V14.8855C15.6253 14.9892 15.5882 15.0764 15.514 15.1471C15.4399 15.2131 15.3484 15.2461 15.2396 15.2461C15.1309 15.2461 15.0394 15.2131 14.9652 15.1471C14.8911 15.0764 14.854 14.9892 14.854 14.8855V13.8887H13.8157C13.7069 13.8887 13.6155 13.8533 13.5413 13.7826C13.4671 13.7119 13.43 13.6247 13.43 13.521C13.43 13.4173 13.4671 13.3325 13.5413 13.2665C13.6155 13.1958 13.7069 13.1605 13.8157 13.1605H14.854V12.1636C14.854 12.0599 14.8911 11.9727 14.9652 11.902C15.0394 11.8313 15.1309 11.796 15.2396 11.796C15.3484 11.796 15.4399 11.8313 15.514 11.902C15.5882 11.9727 15.6253 12.0599 15.6253 12.1636V13.1605H16.6636Z" fill="#A9B6C8"/>
    </svg>
  );
};

export default Icon;
