import React, { memo } from 'react';
import PropTypes from 'prop-types';
import './IconWithCounter.scss';

const IconWithCounter = ({ icon, count }) => (
  <div className="sk-icon-with-counter__wrapper">
    {icon}
    {!!count && (
      <div className="sk-icon-with-counter__count">
        <span> {count} </span>
      </div>
    )}
  </div>
);

IconWithCounter.propTypes = {
  icon: PropTypes.any.isRequired,
  count: PropTypes.number,
};

IconWithCounter.defaultProps = {
  count: 0,
};
export default memo(IconWithCounter);
