import React from 'react';
import { CurrencyContext } from './currency-context';

export function withCurrency(Component) {
  return function CurrencyComponent(props) {
    return (
      <CurrencyContext.Consumer>
        {contexts => <Component {...props} {...contexts} />}
      </CurrencyContext.Consumer>
    );
  };
}
