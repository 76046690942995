// Core
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Components
import ChevronRight from 'components/Icon/Svg/ChevronRight';
import { PurpleHeart, White } from 'components/Icon/color';
import withAction from 'components/Icon/withAction';
import RoundIcon from 'components/Icon/RoundIcon';

const NextButton = ({ handleClick, label, action, withoutSeparator }) => (
  <button
    className={classNames('sk-content-nav_button', {
      'sk-content-nav_button-next_withSeparator': !withoutSeparator,
    })}
    type="button"
    onClick={handleClick}
  >
    <span className="sk-content-nav_label">{label}</span>
    <span
      className={classNames('sk-content-nav_icon sk-content-nav_icon__right', {
        'sk-content-nav_icon_withSeparator': !withoutSeparator,
      })}
      style={{ transform: 'rotate(180deg)' }}
    >
      {!label ? (
        <RoundIcon
          type="nav"
          icon={<ChevronRight color={action ? White : PurpleHeart} />}
          chevron
        />
      ) : (
        <ChevronRight color={PurpleHeart} active={action} />
      )}
    </span>
  </button>
);

NextButton.propTypes = {
  handleClick: PropTypes.func,
  label: PropTypes.string,
  action: PropTypes.bool,
  withoutSeparator: PropTypes.bool,
};

export default withAction(NextButton);
