// Core
import React, { memo, } from 'react';
import { graphql, compose } from 'react-apollo';

// Components
import PaypalButton from 'components/PaypalButton';

// Other
import { messages } from 'routes/Checkout/messages';

// Styles and Assets
// import './index.scss';

const PayPalForm = ({
  amount,
  handleSuccesfulPayment,
}) => {
  return (
    <div className="sk-order-checkout__info__stripe__paypal">
      <PaypalButton
        amount={amount}
        handleSuccesfulPayment={handleSuccesfulPayment}
      />
    </div>
  )
};

const enhancer = compose(
  memo,
);

export default enhancer(PayPalForm);
