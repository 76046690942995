import React from 'react';

const DragAndDropBurger = ({ width, color = '#7145D8' }) => (
  <svg width={width} height="7" viewBox="0 0 18 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width={width} height="2" rx="1" fill={color} />
    <rect y="5" width={width} height="2" rx="1" fill={color} />
  </svg>
);

export default DragAndDropBurger;
