// Core
import React, { Component } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Chevron from '@material-ui/icons/ChevronRight';
import Collapse from '@material-ui/core/Collapse';
import ReactHoverObserver from 'react-hover-observer';
import { withRouter } from 'react-router';
import { compose } from 'react-apollo';
import classNames from 'classnames';
import queryString from 'query-string';
import { FormattedMessage } from 'react-intl';

// Components
import Text from 'components/Text';
import Link from 'components/Link';
import Cross from 'components/Icon/SvgCss/Cross';
import InputWithSearch from 'components/Input/Custom/InputWithSearch';
import {messages} from 'containers/Header/messages.js';

// Styles
import './HeaderCatalogExpandableMenu.scss';
import 'components/DropDown/DropDown.scss';

const overrideClasses = {
  collapse: {
    container:
      'mui-override sk-h-catalog-expandable-menu-content paper paper-shadow',
  },
  expandable: {
    root:
      'mui-override sk-h-catalog-expandable-menu-item sk-dropdown-item sk-dropdown-item__gutters',
  },

  listItem: {
    gutters: 'mui-override sk-dropdown-item sk-dropdown-item__gutters',
  },
};

const selector = qs => ({
  category: qs?.category,
  subcategory: qs?.subcategory,
  query: qs?.query,
  complexity: qs?.complexity || undefined,
  interactivityLevel: qs?.interactivityLevel || undefined,
  language: qs?.language || undefined,
  durationRange: qs?.durationRange || undefined,
  page: qs?.page || 0,
});

const parseSearch = location => {
  const qs = queryString.parse(location?.search);
  return selector(qs);
};

class HeaderCatalogExpandableMenu extends Component {
  state = {
    openedItemId: null,
    mobileOpenned: false,
    inputValue: parseSearch(this.props.location).query || '',
  };

  handleInputChange = e => {
    this.setState({ inputValue: e.target.value });
  };

  handleSearch = event => {
    const { history, location } = this.props;
    const { inputValue } = this.state;

    history.push({
      pathname: '/categories',
      search: `?${queryString.stringify({
        ...parseSearch(location),
        query: inputValue,
      })}`,
    });
  };

  handleSearchEnter = e => {
    if (e.key === 'Enter') {
      this.handleSearch(e);
    }
  };


  handleClick = openedItemId => () => this.setState({ openedItemId });

  getList = (listItems, rootList = false) => {
    const { history } = this.props;
    const listProps = {
      component: rootList ? 'nav' : 'div',
      disablePadding: rootList,
    };
    const { openedItemId } = this.state;
    const openSubcategory = openedItemId => this.setState({ openedItemId });
    const onCategoryClick = async () => {
       await history.push(`/categories`);
    };
    
    return (
      <List {...listProps} className="list">
        <ReactHoverObserver
          onMouseOver={({ e, setIsHovering, unsetIsHovering }) => {
            const hoveredItemId = e.target.id;
            if (hoveredItemId !== openedItemId) openSubcategory(hoveredItemId);
          }}
        >
          <div className="items-list">
            <div className="sk-h-catalog-list-categories">
              <div className={classNames("sk-h-catalog-list-categories_title")}>
                <Link
                   classes={classNames("sk-h-catalog-expandable-menu__link category-all")}
                   onClick={onCategoryClick}
                 >
                   <ListItem
                     classes={overrideClasses.expandable}
                   >
                    <>
                      <ListItemText
                        primary={
                          <Text size="caption">
                            <FormattedMessage {...messages.allCoursesLabel} />
                          </Text>
                        }
                        disableTypography
                      />
                     </>
                   </ListItem>
                </Link>
              </div>
            </div>
            {listItems.map(item => this.getListItem(item))}
          </div>
          <div className="subitems-list">
            {listItems.map(item => this.getListItemChild(item))}
          </div>
        </ReactHoverObserver>
      </List>
    );
  };

  getListItemChild = listItem => {
    const { subItems, id } = listItem;
    const { openedItemId } = this.state;
    const hoverId = `category-${id}`;
    const isItemCollapsed = openedItemId !== hoverId;

    if (subItems) {
      return (
        <div key={id} id={hoverId} className={classNames("subitems-list__subitem", {"show": !isItemCollapsed})}>
          {this.getSubcategoriesList(subItems, id, isItemCollapsed)}
        </div>
      );
    };
    return null;
  };

  getListItem = listItem => {
    const { history } = this.props;
    const { category, title, localized, id, expandable = true } = listItem;
    const hoverId = `category-${id}`;
    const onCategoryClick = async () => {
       await history.push(`/categories`);
       await history.replace(`/categories/${id}`);
    };
    const { openedItemId, mobileOpenned } = this.state;
    const openCategory = hoverId !== openedItemId;
    const openSubcategory = openedItemId => this.setState({
      openedItemId,
      mobileOpenned: !mobileOpenned
    });

    return (
      <div className="sk-h-catalog-list-categories" key={id} id={hoverId}>
        <div className="sk-h-catalog-list-categories_web">
          <div className={classNames("sk-h-catalog-list-categories_title", {"open": !openCategory})} id={hoverId}>
            <Link
               key={id}
               classes={classNames("sk-h-catalog-expandable-menu__link", {"open": !openCategory})}
               onClick={onCategoryClick}
             >
               <ListItem
                 classes={overrideClasses.expandable}
                 key={id}
                 id={hoverId}
               >
                <>
                   <ListItemText
                     primary={<Text id={hoverId} size="caption"> {localized || title} </Text>}
                     disableTypography
                     id={hoverId}
                   />
                   {expandable}
                 </>
               </ListItem>
            </Link>
            <div className={classNames("sk-h-catalog-list-categories_title__arrow", {"open": !openCategory})} id={hoverId} onClick={()=>openSubcategory(hoverId)}>
              <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" id={hoverId}>
                <path d="M12 21L18 15.0307L12 9" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </div>
          </div>
        </div>
        <div className={"sk-h-catalog-list-categories_mobile"}>
          <div className={classNames("sk-h-catalog-list-categories_title", {"open": !openCategory && mobileOpenned})}>
            <Link
              key={id}
              classes={classNames("sk-h-catalog-expandable-menu__link", {"open": !openCategory && mobileOpenned})}
              onClick={onCategoryClick}
            >
              <ListItem
                classes={overrideClasses.expandable}
                key={id}
              >
                <>
                  <ListItemText
                    primary={<Text id={hoverId} size="caption"> {title} </Text>}
                    disableTypography
                  />
                  {expandable}
                </>
              </ListItem>
            </Link>
            <div className="sk-h-catalog-list-categories_title__arrow" onClick={()=>openSubcategory(hoverId)}>
              <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 21L18 15.0307L12 9" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </div>
          </div>
          <div className={classNames("sk-h-catalog-list-categories_mobile__sub", {"open": !openCategory && mobileOpenned})}>
            {this.getListItemChild(listItem)}
          </div>
        </div>
      </div>
    );
  };

  getSubcategoriesList = (listItems, categoryId, isItemCollapsed) => {
    const { history } = this.props;
    const listProps = {
      component: false ? 'nav' : 'div',
      disablePadding: false,
    };

    return (
      <List
        {...listProps}
        className='subcategory'
        id={`category-${categoryId}`}
      >
        {listItems.map(({ category, id, title, hasCourses = false }) => {
          const disableListItem = {
            root: `${overrideClasses.expandable.root} sk-dropdown-item__disable`,
          };
          const listItemClasses = !hasCourses
            ? disableListItem
            : overrideClasses.expandable;
          const onSubategoryClick = async () => {
              if (hasCourses) {
                await history.push(`/categories`);
                await history.replace(`/categories/${category}/${id}`);
              }
          };

          return (
            <Link
              key={id}
              onClick={onSubategoryClick}
            >
              <ListItem
                button
                classes={listItemClasses}
                id={`category-${categoryId}`}
              >
                <ListItemText
                  id={`category-${categoryId}`}
                  primary={
                    <Text
                      classes="mui-override sk-h-catalog-expandable-menu-item_link"
                      size="caption"
                      id={`category-${categoryId}`}
                    >
                      {' '}
                      {title}{' '}
                    </Text>
                  }
                  disableTypography
                />
              </ListItem>
            </Link>
          );
        })}
      </List>
    );
  };

  render() {
    const { items, show, handlerClose, placeholder } = this.props;
    const list = this.getList(items, true);
    const { inputValue } = this.state;

    return(
      <div className={classNames("sk-category-overlay", {'show': show})}>
        <div className="button-close" onClick={handlerClose}>
          <Cross strokeWidth={2} />
        </div>
        <div className="sk-category-overlay__input-search">
          <InputWithSearch
            placeholder={placeholder}
            value={inputValue}
            handleChange={this.handleInputChange}
            onKeyPress={this.handleSearchEnter}
          />
        </div>
        {list}
      </div>
    );
  }
}

const enhancer = compose(
  withRouter
)
export default enhancer(HeaderCatalogExpandableMenu);
