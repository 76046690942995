// Core
import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';

// Styles and Assets
import './AboutCourse.scss';

// Components
import ReadMore from 'components/ReadMore';

// Other
import { FormattedMessage } from 'react-intl';
import { messages } from 'routes/CourseDetails/AboutCourse/messages';

const textStyles = {
  fontSize: '16px',
  lineHeight: '24px',
  fontFamily: 'Roboto',
  color: '#6D7C90',
  whiteSpace: 'break-spaces'
};

const AboutCourse = ({ description }) => {
  return (
    <div className="sk-aboutCourse">
      <h3 className="sk-aboutCourse-title">
        <FormattedMessage {...messages.aboutCourse} />
      </h3>
      <ReadMore lines={5} textStyles={textStyles} withToggle={true}>
        {description}
      </ReadMore>
    </div>
  );
};

AboutCourse.propTypes = {
  description: PropTypes.string,
};

export default memo(AboutCourse);
