import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  courseCreationPageTitle: {
    id: 'app.routes.courseCreation.courseCreationPageTitle',
    defaultMessage: "Let's start creating your course",
  },
  maxLevelOfInt: {
    id: 'app.components.GeneralInfo.maxLevelOfInt',
    defaultMessage: 'Max Level of interaction',
  },
  chooseCat: {
    id: 'app.components.GeneralInfo.chooseCat',
    defaultMessage: 'Choose Category',
  },
  chooseSubCat: {
    id: 'app.components.GeneralInfo.chooseSubCat',
    defaultMessage: 'Choose Subcategory',
  },
  courseTitle: {
    id: 'app.routes.CourseCreationForm.courseTitle',
    defaultMessage: 'Course title',
  },
  createCourse: {
    id: 'app.routes.CourseCreationForm.createCourse',
    defaultMessage: 'Create course',
  },
  howToCreateCourse: {
    id: 'app.routes.CourseCreationForm.howToCreateCourse',
    defaultMessage: 'How to create a course',
  },
});
