import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  popularCoursesTitle: {
    id: 'app.components.LandingPage.popularCoursesTitle',
    defaultMessage: 'Popular Courses',
  },
  newCoursesTitle: {
    id: 'app.components.LandingPage.newCoursesTitle',
    defaultMessage: 'New Courses',
  },
  interactionLevelsTitle: {
    id: 'app.components.LandingPage.interactionLevelsTitle',
    defaultMessage: 'Learning options available',
  },
  successStoriesTitle: {
    id: 'app.components.LandingPage.successStoriesTitle',
    defaultMessage: 'Success stories',
  },
  successStoriesDescription: {
    id: 'app.components.LandingPage.successStoriesDescription',
    defaultMessage:
      'Dare to do what others only dream of! Get the skills to achieve your goals.',
  },
  successStoriesButton: {
    id: 'app.components.LandingPage.successStoriesButton',
    defaultMessage: 'Select a course',
  },
  podcasts: {
    id: 'app.components.LandingPage.podcasts',
    defaultMessage: 'Podcasts',
  },
  masterclasses: {
    id: 'app.components.LandingPage.masterclasses',
    defaultMessage: 'Master Classes',
  },
  allCoursesTitle: {
    id: 'app.components.LandingPage.allCoursesTitle',
    defaultMessage: 'All categories',
  },
  courseCatalog: {
    id: 'app.components.LandingPage.courseCatalog',
    defaultMessage: 'Course catalog',
  },
});
