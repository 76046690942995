// Core
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

// Styles
import 'utils/styles/Materialized.scss';

const classes = {
  label: {
    focused:
      'mui-override sk-materialized-input_label sk-materialized-input_focused',
    error: 'mui-override sk-materialized-input_error labelWithError',
  },
  input: {
    root: 'mui-override sk-materialized-input',
    focused: 'mui-override sk-materialized-input_focused',
    error: 'mui-override sk-materialized-input_error withError',
    underline: 'mui-override sk-materialized-input_hover',
  },
  helperText: {
    error: 'mui-override sk-materialized-input_helperErrorText',
  },
};

const AutoCompleteInput = ({
  type,
  error,
  disabled,
  inputProps,
  shrink,
  ...rest
}) => (
  <TextField
    error={error}
    disabled={disabled}
    margin="dense"
    InputProps={{
      ...inputProps,
      classes: classes.input,
    }}
    InputLabelProps={{
      classes: classes.label,
      shrink,
    }}
    FormHelperTextProps={{ classes: classes.helperText }}
    {...rest}
  />
);

AutoCompleteInput.defaultProps = {
  fullWidth: true,
};

AutoCompleteInput.propTypes = {
  type: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ),
  ]),
  placeholder: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

export default memo(AutoCompleteInput);
