// Core
import React, { useCallback } from 'react';
import Cross from 'components/Icon/Svg/Cross';

// Components
import Chip from '@material-ui/core/Chip';

// Other
import { White } from 'components/Icon/color';

// Styles and Assets
import '../FilterDropDown.scss';

const chipClasses = {
  root: 'mui-override sk-filter-chip',
};

const FilterChip = ({ value, handleDelete }) => {
  // Pass in props to show cross icon
  const onDeleteHandler = useCallback(() => {}, []);

  const handleMouseDown = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();

    handleDelete(value);
  }, [value]);

  return (
    <Chip
      classes={chipClasses}
      label={value}
      onDelete={onDeleteHandler}
      deleteIcon={
        <button onMouseDown={handleMouseDown} type="button" className="sk-filter-chip delete-icon">
          <Cross width="10" height="10" color={White} strokeWidth={2} />
        </button>
      }
    />
  )
};

export default FilterChip;
