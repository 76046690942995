import React, { memo, useState } from 'react';
import { compose } from 'react-apollo';
import { injectIntl } from 'react-intl';
import classnames from 'classnames';

// Components
import Text from 'components/Text';
import Breadcrumbs from 'containers/Breadcrumbs';
import Heading from 'components/Heading';

// Other
import { messages } from 'routes/Checkout/messages';
import { isArray, isEmptyArray } from 'utils/helpers';

//Styles
import './index.scss';

const PREFIX = 'sk-checkout-layout';
const CheckoutLayout = ({
  breadcrumbs,
  children,
  intl: { formatMessage },
  ...props
}) => {
  const [isDiscountInputOpen, setDiscountInputState] = useState(false);
  const hasBreadcrumbs = isArray(breadcrumbs) && !isEmptyArray(breadcrumbs);
  return (
    <div className={`${PREFIX} landing grid`}>
      {hasBreadcrumbs && (<Breadcrumbs breadcrumbs={breadcrumbs} />)}
      <Text classes={classnames(`${PREFIX}__header`, { [`${PREFIX}__no-breadcrumbs`]: !hasBreadcrumbs })} variant="h2">Plans for Business</Text>
      {children}
    </div>
  );
};

const enhancer = compose(
  memo,
  injectIntl
);

export default enhancer(CheckoutLayout);
