import React from 'react';

const Icon = ({color = '#A9B6C8'}) => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.41177 19.2619V4.34603C4.41177 3.56555 5.03616 2.94116 5.81664 2.94116H14.6274C15.0436 2.94116 15.4252 3.1146 15.7027 3.44414L19.6398 8.04029C19.8479 8.30045 19.9693 8.61265 19.9693 8.95953V19.2965C19.9693 20.7361 18.8073 21.8981 17.3677 21.8981L6.99603 21.8635C5.57382 21.8461 4.41177 20.6841 4.41177 19.2619Z" stroke={color} strokeMiterlimit="10"/>
      <path d="M15.0435 3.09741V7.72826C15.0435 8.14451 15.3731 8.47405 15.7893 8.47405L19.8131 8.50874" stroke={color} strokeMiterlimit="10"/>
      <path d="M7.35294 9.79413C7.07679 9.79413 6.85294 10.018 6.85294 10.2941C6.85294 10.5703 7.07679 10.7941 7.35294 10.7941V9.79413ZM16.9118 10.7941C17.1879 10.7941 17.4118 10.5703 17.4118 10.2941C17.4118 10.018 17.1879 9.79413 16.9118 9.79413V10.7941ZM7.35294 10.7941H16.9118V9.79413H7.35294V10.7941Z" fill={color}/>
      <path d="M7.35294 12.7353C7.07679 12.7353 6.85294 12.9591 6.85294 13.2353C6.85294 13.5114 7.07679 13.7353 7.35294 13.7353V12.7353ZM16.9118 13.7353C17.1879 13.7353 17.4118 13.5114 17.4118 13.2353C17.4118 12.9591 17.1879 12.7353 16.9118 12.7353V13.7353ZM7.35294 13.7353H16.9118V12.7353H7.35294V13.7353Z" fill={color}/>
      <path d="M7.35294 15.6765C7.07679 15.6765 6.85294 15.9003 6.85294 16.1765C6.85294 16.4526 7.07679 16.6765 7.35294 16.6765V15.6765ZM11.0294 16.6765C11.3055 16.6765 11.5294 16.4526 11.5294 16.1765C11.5294 15.9003 11.3055 15.6765 11.0294 15.6765V16.6765ZM7.35294 16.6765H11.0294V15.6765H7.35294V16.6765Z" fill={color}/>
    </svg>
  );
};

export default Icon;
