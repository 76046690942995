import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  wrongAuthenticateMethod: {
    id: 'app.components.Authenticator.wrongAuthenticateMethod',
    defaultMessage: 'An account with the given email already exists',
  },
  orContinueWith: {
    id: 'app.components.Authenticator.orContinueWith',
    defaultMessage: 'or continue with',
  },
});
