import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  webinarSectionTitle: {
    id: 'app.containers.Calendar.title',
    defaultMessage: 'Web Сlasses',
  },
  howToCreateCourse: {
    id: 'app.components.CourseSidebar.howToCreateCourse',
    defaultMessage: 'How to create a course',
  },
  createCourse: {
    id: 'app.components.CourseSidebar.createCourse',
    defaultMessage: 'Create a course',
  },
  createTopping: {
    id: 'app.components.CourseSidebar.createTopping',
    defaultMessage: 'Create Master Class/Podcast',
  },
  drafts: {
    id: 'app.components.CourseSidebar.drafts',
    defaultMessage: 'Drafts',
  },
  toppings: {
    id: 'app.components.CourseSidebar.toppings',
    defaultMessage: 'Toppings',
  },
  toppingType: {
    id: 'app.components.CourseSidebar.toppingType',
    defaultMessage: 'Topping`s type',
  },
  expertTariffs: {
    id: 'app.components.FlatDropDownMenu.expertTariffs',
    defaultMessage: 'Tariffs',
  },
  masterclassesAndPodcasts: {
    id: 'app.components.FlatDropDownMenu.masterclassesAndPodcasts',
    defaultMessage: 'Master Classes/Podcasts',
  },
});
