// Core
import React, { PureComponent } from 'react';
import mapR from 'ramda/src/map';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router';

// Components
import Loader from 'components/Loader';
import SimpleSidebarMenu from 'containers/Sidebar/SidebarContent/SimpleSidebarMenu';
import SidebarMenuItem from 'containers/Sidebar/SidebarContent/SidebarMenu/SidebarMenuItem';
import MenuNote from 'components/Icon/Svg/MenuNote';
import SendMessageExpertModal from 'containers/SendMessageExpertModal';

// Icons
import Folder from 'components/Icon/Svg/Folder';
import Play from 'components/Icon/Svg/Play';

// Other
import { Grey2, Transparent } from 'components/Icon/color';
import { withModal } from 'containers/ModalProvider/withModal';
import { renderTrimmedText, getLocaleFromPathname } from 'utils/helpers';
import { messages } from 'containers/Sidebar/SidebarContent/StudentCabinetSidebar/StudentActiveCoursesList/messages';
import { COMPLETED_PURCHASED_COURSE } from 'utils/enums';

class StudentActiveCoursesList extends PureComponent {
  getURLofLecture = (id, mappingId, list) => {
    const lectureId = this.getLectureID(list, id);
    const type = this.getDataOfLecture(lectureId, list, id, 'type');
    const data = this.getLectureDataObject(lectureId, list, id);

    let position = 0;
    if (data.position) {
      position = data.position;
    }
    const itemId = this.getDataOfLecture(lectureId, list, id, 'id');
    if (type === 'not_found') {
      return {
        pathname: `/student-cabinet/courses/${mappingId}/materials`
      };
    }
    if (itemId) {
      return `/student-cabinet/courses/${mappingId}/lecture/${lectureId}/${type}/${itemId}?position=${position}`;
    }
    return `/student-cabinet/courses/${mappingId}/materials`;
  };

  getDataOfLecture = (lectureId, list, id, data) => {
    const timeStemp = this.getBigestTimeStemp(list, id);
    const course = list.find((item) => {
      if (item.courseId === id) {
        return true;
      }
      return false;
    });
    let type = 'not_found';
    course.viewData.map((item) => {
      if (lectureId === item.lectureId) {
        item.views.map((item2) => {
          if (item2.viewedOn === timeStemp) {
            type = item2[data];
          }
          return null;
        });
      }
      return null;
    });
    return type.replace('s', '');
  };

  getLectureDataObject = (lectureId, list, id) => {
    const timeStemp = this.getBigestTimeStemp(list, id);
    const course = list.find((item) => {
      if (item.courseId === id) {
        return true;
      }
      return false;
    });
    let data = {};
    course.viewData.map((item) => {
      if (lectureId === item.lectureId) {
        item.views.map((item2) => {
          if (item2.viewedOn === timeStemp) {
            if (item2.position) {
              data = item2;
            }
          }
        });
      }
    });
    return data;
  };

  getLectureID = (list, id) => {
    const timeStemp = this.getBigestTimeStemp(list, id);
    let lecture = '001';
    const getLectureId = (item2, item3) => {
      if (timeStemp === item3.viewedOn) {
        lecture = item2.lectureId;
      }
    };
    mapR(
      (item) => mapR((item2) => mapR((item3) => getLectureId(item2, item3), item2.views), item.viewData),
      list
    );
    return lecture;
  };

  getBigestTimeStemp = (list, id) => {
    let bigestTimeStemp = 0;
    const getTimeStemp = (item) => {
      if (item.viewedOn > bigestTimeStemp) {
        bigestTimeStemp = item.viewedOn;
      }
    };
    const funcMapR = (item) => {
      if (item.courseId === id) {
        mapR((item2) => mapR((item3) => getTimeStemp(item3), item2.views), item.viewData);
      }
    };
    mapR((item) => funcMapR(item), list);
    return bigestTimeStemp;
  };

  getSubItemsConciergeMenu = ({ mappingId, id, list, interactionLevel, completed }) => [
    {
      id: `${mappingId}-materials`,
      path: `/student-cabinet/courses/${mappingId}/materials`,
      label: <FormattedMessage {...messages.courseStudyMats} />,
      icon: () => <Folder width={10} height={12} />
    },
    {
      id: `${mappingId}-resume`,
      path: this.getURLofLecture(id, mappingId, list, interactionLevel),
      label: <FormattedMessage {...messages.resumeCourse}>{(msg) => `${msg}`}</FormattedMessage>,
      icon: () => <Play color={Transparent} strokeColor={Grey2} />,
      disabled: completed === COMPLETED_PURCHASED_COURSE
    }
    // {
    //   id: `${id}-send`,
    //   label: <FormattedMessage {...messages.sendMessageToExpert} />,
    //   icon: () => <Question width="14" height="14" />,
    //   handleCustomClick: this.handleSendMessageClick(id),
    //   disabled: completed === COMPLETED_PURCHASED_COURSE,
    // },
  ];

  getSubItemsMenu = ({ mappingId, id, list, interactionLevel, completed }) => [
    {
      id: `${mappingId}-materials`,
      path: `/student-cabinet/courses/${mappingId}/materials`,
      label: <FormattedMessage {...messages.courseStudyMats} />,
      icon: () => <Folder width={10} height={12} />
    },
    {
      id: `${mappingId}-resume`,
      path: this.getURLofLecture(id, mappingId, list, interactionLevel),
      label: <FormattedMessage {...messages.resumeCourse}>{(msg) => `${msg}`}</FormattedMessage>,
      icon: () => <Play color={Transparent} strokeColor={Grey2} />,
      disabled: completed === COMPLETED_PURCHASED_COURSE
    }
    // {
    //   id: `${id}-${interactionLevel}-send`,
    //   label: <FormattedMessage {...messages.sendMessageToExpert} />,
    //   icon: () => (
    //     <div style={{ height: '100%', position: 'relative', top: '3px', }}>
    //       <Question width="14" height="14" />
    //     </div>
    //   ),
    //   handleCustomClick: this.handleSendMessageClick(id),
    //   disabled: completed === COMPLETED_PURCHASED_COURSE,
    // },
  ];

  getMenuItems = (list) =>
    list.map(({ courseId: id, courseTitle, interactionLevel, mappingId, courseStatus }) => {
      const menuProps = {
        id,
        list,
        interactionLevel,
        mappingId,
        completed: courseStatus
      };

      return {
        id: `${mappingId}`,
        path: `/student-cabinet/courses/${mappingId}`,
        label: renderTrimmedText(courseTitle, 45),
        subItems:
          interactionLevel === 'flex'
            ? this.getSubItemsMenu(menuProps)
            : this.getSubItemsConciergeMenu(menuProps)
      };
    });

  renderSimpleSidebarMenu = ({ open, list, handleSubMenuTitleClick, openedSubItemId, openedItem }) => {
    const isShow = open && list?.length;

    return (
      isShow && (
        <SimpleSidebarMenu
          menuItems={this.getMenuItems(list)}
          withSubMenu
          handleSubMenuTitleClick={handleSubMenuTitleClick}
          openedSubItemId={openedSubItemId}
          openedItem={openedItem}
        />
      )
    );
  };

  handleSendMessageClick = (courseId) => () => {
    const {
      modalContext: { showModal, hideModal }
    } = this.props;

    showModal(SendMessageExpertModal, {
      courseId,
      handleCompletedClick: hideModal
    });
  };

  goToCourse = () => {
    const { location, history } = this.props;

    const locale = getLocaleFromPathname(location.pathname);

    history.replace({
      pathname: `/${locale}/student-cabinet/courses`
    });
  };

  render() {
    const { open, openedSubItemId, handleSubMenuTitleClick, handleClick, list, loading } = this.props;

    let openedItem = '';

    if (open && openedSubItemId) {
      const idArr = openedSubItemId.split('-');

      if (idArr.length > 0) {
        openedItem = idArr.slice(0, idArr.length - 1).join('-');
      }
    }

    return (
      <div>
        <FormattedMessage {...messages.activeCourses}>
          {(msg) => (
            <SidebarMenuItem
              icon={MenuNote}
              label={msg}
              expandable={!!list?.length}
              open={open}
              onChevronHandler={handleClick}
              handleClick={this.goToCourse}
            >
              {loading && open ? (
                <Loader small />
              ) : (
                this.renderSimpleSidebarMenu({
                  open,
                  list,
                  handleSubMenuTitleClick,
                  openedSubItemId,
                  openedItem
                })
              )}
            </SidebarMenuItem>
          )}
        </FormattedMessage>
      </div>
    );
  }
}

export default withRouter(withModal(StudentActiveCoursesList));
