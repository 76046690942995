// Core
import React from 'react';
import moment from 'moment';
import { injectIntl, FormattedMessage } from 'react-intl';

// Components
import Image from 'components/Image';
import Quote from 'components/Icon/Svg/LandingPageIcons/Quote';
import UserAvatar from 'components/UserAvatar';
import Text from 'components/Text';
import StarRating from 'components/StarRating';

// Others
import { messages } from 'routes/CourseDetails/CourseReview/messages';

// Styles
import './index.scss';

const classNamePrefix = 'sk-course-review-card';

const CourseReviewCard = ({ user, mark, date: millisecondsTimestamp, text }) => {
  const getTimeAgo = time => moment().diff(moment(time), 'days');;

  return(
    <div className={`${classNamePrefix}`}>
      <div className={`${classNamePrefix}__photo`}>
        <UserAvatar avatar={user?.photo} lastName={user?.lastName} firstName={user?.firstName} />
      </div>
      <div className={`${classNamePrefix}__content`}>
        <div className={`${classNamePrefix}__content__stars`}>
          <StarRating rate={mark} />
        </div>
        <div className={`${classNamePrefix}__content__time`}>
          {`${getTimeAgo(millisecondsTimestamp)} `}
          <FormattedMessage {...messages.daysAgo} />
        </div>
        <Text>{text}</Text>
        <div className={`${classNamePrefix}__content__author`}>
          <Quote />
          <Text>{user?.firstName} {user?.lastName}</Text>
        </div>
      </div>
    </div>
  )
}

export default injectIntl(CourseReviewCard);
