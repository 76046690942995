export function getTimeCode(ev, el, duration) {
  const progressBarLeft = el.current ? el.current.getBoundingClientRect().x : 0;
  const progressBarWidth = el.current ? el.current.offsetWidth : 0;
  let getTimeCode = (ev.clientX - progressBarLeft) / progressBarWidth;

  if (getTimeCode > 0) getTimeCode *= duration;
  else getTimeCode = 0;

  return getTimeCode;
}

export function converTime(time) {
  const hr = parseInt(time / 3600);
  const min = parseInt((time % 3600) / 60);
  const sec = parseInt(time % 60);
  let sec_min = '';
  if (hr > 0) sec_min += `${hr}.${min < 10 ? '0' : ''}`;
  sec_min += `${min}.${sec < 10 ? '0' : ''}`;
  sec_min += sec;
  return sec_min;
}

export function setVolumeLevelHandler(ev, el) {
  const volumeBarTop = el.current
    ? el.current.getBoundingClientRect().bottom
    : 0;
  const volumeBarHeight = el.current ? el.current.offsetHeight : 0;

  const getVolumeLevel = -((ev.clientY - volumeBarTop) / volumeBarHeight);

  return getVolumeLevel;
}
