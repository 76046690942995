import React from 'react';

const WP = () => {
  const randomFloatString = `${Math.random()}`;
  return  (
    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21 1C9.97205 1 1 9.97205 1 21C1 32.0271 9.97205 41 21 41C32.0271 41 41 32.0271 41 21C41 9.97205 32.0288 1 21 1Z" stroke="#610FB6"/>
      <mask id="path-3-inside-1" fill="white">
      <path d="M21.0027 10H20.9973C14.9321 10 10 14.9335 10 21C10 23.4062 10.7755 25.6365 12.0941 27.4474L10.7233 31.5339L14.9514 30.1823C16.6908 31.3345 18.7656 32 21.0027 32C27.0679 32 32 27.0651 32 21C32 14.9349 27.0679 10 21.0027 10ZM27.4034 25.5334C27.138 26.2827 26.0847 26.9042 25.2446 27.0857C24.6699 27.2081 23.9191 27.3057 21.3919 26.258C18.1592 24.9188 16.0775 21.6339 15.9152 21.4207C15.7599 21.2076 14.609 19.6814 14.609 18.1029C14.609 16.5244 15.4106 15.7558 15.7338 15.4258C15.9991 15.1549 16.4377 15.0311 16.8585 15.0311C16.9946 15.0311 17.117 15.038 17.227 15.0435C17.5501 15.0573 17.7124 15.0765 17.9255 15.5866C18.1909 16.226 18.8371 17.8045 18.9141 17.9668C18.9925 18.129 19.0709 18.349 18.9609 18.5621C18.8577 18.7821 18.767 18.8798 18.6047 19.0667C18.4425 19.2537 18.2885 19.3968 18.1262 19.5975C17.9777 19.7721 17.81 19.9591 17.997 20.2823C18.184 20.5985 18.8302 21.6531 19.7817 22.5001C21.0096 23.5932 22.0051 23.9425 22.3612 24.091C22.6266 24.201 22.9429 24.1749 23.1368 23.9686C23.3829 23.7033 23.6867 23.2633 23.9961 22.8301C24.2161 22.5194 24.4939 22.4809 24.7854 22.5909C25.0824 22.694 26.654 23.4709 26.9771 23.6317C27.3003 23.794 27.5134 23.871 27.5917 24.0071C27.6687 24.1433 27.6687 24.7826 27.4034 25.5334Z"/>
      </mask>
      <path d="M21.0027 10H20.9973C14.9321 10 10 14.9335 10 21C10 23.4062 10.7755 25.6365 12.0941 27.4474L10.7233 31.5339L14.9514 30.1823C16.6908 31.3345 18.7656 32 21.0027 32C27.0679 32 32 27.0651 32 21C32 14.9349 27.0679 10 21.0027 10ZM27.4034 25.5334C27.138 26.2827 26.0847 26.9042 25.2446 27.0857C24.6699 27.2081 23.9191 27.3057 21.3919 26.258C18.1592 24.9188 16.0775 21.6339 15.9152 21.4207C15.7599 21.2076 14.609 19.6814 14.609 18.1029C14.609 16.5244 15.4106 15.7558 15.7338 15.4258C15.9991 15.1549 16.4377 15.0311 16.8585 15.0311C16.9946 15.0311 17.117 15.038 17.227 15.0435C17.5501 15.0573 17.7124 15.0765 17.9255 15.5866C18.1909 16.226 18.8371 17.8045 18.9141 17.9668C18.9925 18.129 19.0709 18.349 18.9609 18.5621C18.8577 18.7821 18.767 18.8798 18.6047 19.0667C18.4425 19.2537 18.2885 19.3968 18.1262 19.5975C17.9777 19.7721 17.81 19.9591 17.997 20.2823C18.184 20.5985 18.8302 21.6531 19.7817 22.5001C21.0096 23.5932 22.0051 23.9425 22.3612 24.091C22.6266 24.201 22.9429 24.1749 23.1368 23.9686C23.3829 23.7033 23.6867 23.2633 23.9961 22.8301C24.2161 22.5194 24.4939 22.4809 24.7854 22.5909C25.0824 22.694 26.654 23.4709 26.9771 23.6317C27.3003 23.794 27.5134 23.871 27.5917 24.0071C27.6687 24.1433 27.6687 24.7826 27.4034 25.5334Z" stroke={`url(#paint0_linear_${randomFloatString})`} stroke-width="2" mask="url(#path-3-inside-1)"/>
      <defs>
      <linearGradient id={`paint0_linear_${randomFloatString}`} x1="33.992" y1="33.4808" x2="6.46262" y2="30.1038" gradientUnits="userSpaceOnUse">
      <stop stop-color="#8F26AF"/>
      <stop offset="0.979167" stop-color="#141685"/>
      </linearGradient>
      </defs>
    </svg>
  )
};

export default WP;
