import React, { memo } from 'react';
import { compose } from 'react-apollo';

const Icon = ({ ...props }) => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M5.81697 3.44116C5.31264 3.44116 4.91211 3.84169 4.91211 4.34603V19.2619C4.91211 20.4061 5.84931 21.3483 7.00037 21.3635L7.00246 21.3635L17.3681 21.3981H17.3686C18.5318 21.3979 19.4697 20.4598 19.4697 19.2965V9.00536L15.7871 8.97362C15.0958 8.97233 14.5437 8.41942 14.5437 7.72783V3.44116H5.81697ZM15.5437 4.02671L18.948 8.00083L15.7938 7.97364V7.97362H15.7895C15.6494 7.97362 15.5437 7.86794 15.5437 7.72783V4.02671ZM3.91211 4.34603C3.91211 3.2894 4.76035 2.44116 5.81697 2.44116H14.6277C15.1902 2.44116 15.7113 2.67867 16.0841 3.12038L20.0199 7.71502L20.0253 7.72139L20.0306 7.72795C20.3035 8.06908 20.4697 8.48965 20.4697 8.95953V19.2965C20.4697 21.0122 19.0838 22.3981 17.3681 22.3981L17.3664 22.3981L6.99469 22.3635L6.99027 22.3634C5.29787 22.3428 3.91211 20.9614 3.91211 19.2619V4.34603ZM7.35329 9.7941C7.07714 9.7941 6.85329 10.018 6.85329 10.2941C6.85329 10.5702 7.07714 10.7941 7.35329 10.7941H16.9121C17.1883 10.7941 17.4121 10.5702 17.4121 10.2941C17.4121 10.018 17.1883 9.7941 16.9121 9.7941H7.35329ZM6.85329 13.2353C6.85329 12.9591 7.07714 12.7353 7.35329 12.7353H16.9121C17.1883 12.7353 17.4121 12.9591 17.4121 13.2353C17.4121 13.5114 17.1883 13.7353 16.9121 13.7353H7.35329C7.07714 13.7353 6.85329 13.5114 6.85329 13.2353ZM7.35329 15.6765C7.07714 15.6765 6.85329 15.9003 6.85329 16.1765C6.85329 16.4526 7.07714 16.6765 7.35329 16.6765H11.0298C11.3059 16.6765 11.5298 16.4526 11.5298 16.1765C11.5298 15.9003 11.3059 15.6765 11.0298 15.6765H7.35329Z" fill="#20292F"/>
    </svg>
  );
};

const enhancer = compose(
  memo,
);

export default enhancer(Icon);
