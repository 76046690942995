// Core
import React from 'react';
import PropTypes from 'prop-types';

// Components
import SimpleSelect from 'components/Select/SimpleSelect';
import Toggler from 'components/Select/Toggler/Toggler';
import { withCurrency } from 'containers/CurrencyProvider/withCurrency';
import Input from 'components/Select/MaterialUI/Input';

// Styles and Assets
import * as materialUISelectInlineStyles from './Selector.style';
import './Selector.scss';

const UnderlinelessInput = (
  <Input
    classes={{
      underline: 'mui-override sk-materialized-input-underline_disable',
    }}
  />
);

const SelectorInputToggler = () => (
  <Toggler style={{ width: 8.5, right: 8.5, top: 15 }} color="white" />
);


const CurrencySelect = ({ currencyContext }) => {
  const { currency, currencies, updateCurrency } = currencyContext;
  const options =
    currencies &&
    currencies.map(({ currency_id, label }) => ({ value: currency_id, label })); // eslint-disable-line

  const classesList = {
    selector: {
      root: 'sk-footer-selector',
      select: 'sk-footer-selector_select_title'
    },
    paper: {
      root: 'sk-footer-selector_paper'
    },
    menuItem: {
      root: 'sk-footer-selector_item',
      selected: 'selected'
    }
  };

  return (
    <div className="">
      <SimpleSelect
        inlineStyles={materialUISelectInlineStyles}
        value={currency}
        options={options}
        handleChange={updateCurrency}
        input={UnderlinelessInput}
        iconComponent={SelectorInputToggler}
        classes={classesList}
      />
    </div>
  );
};

CurrencySelect.propTypes = {
  currencyContext: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    currencies: PropTypes.arrayOf(
      PropTypes.shape({
        currency_id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        rate: PropTypes.number.isRequired,
      })
    ),
    updateCurrency: PropTypes.func.isRequired,
  }),
};

export default withCurrency(CurrencySelect);
