import React from 'react';

const Icon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M16 4H8C7.44772 4 7 4.44772 7 5V17C7 17.5523 7.44772 18 8 18H16C16.5523 18 17 17.5523 17 17V5C17 4.44772 16.5523 4 16 4ZM8 3C6.89543 3 6 3.89543 6 5V17C6 18.1046 6.89543 19 8 19H16C17.1046 19 18 18.1046 18 17V5C18 3.89543 17.1046 3 16 3H8ZM8.5 6C8.22386 6 8 6.22386 8 6.5C8 6.77614 8.22386 7 8.5 7H15.5C15.7761 7 16 6.77614 16 6.5C16 6.22386 15.7761 6 15.5 6H8.5ZM8 9.5C8 9.22386 8.22386 9 8.5 9H15.5C15.7761 9 16 9.22386 16 9.5C16 9.77614 15.7761 10 15.5 10H8.5C8.22386 10 8 9.77614 8 9.5ZM8.5 12C8.22386 12 8 12.2239 8 12.5C8 12.7761 8.22386 13 8.5 13H15.5C15.7761 13 16 12.7761 16 12.5C16 12.2239 15.7761 12 15.5 12H8.5ZM8 15.5C8 15.2239 8.22386 15 8.5 15H15.5C15.7761 15 16 15.2239 16 15.5C16 15.7761 15.7761 16 15.5 16H8.5C8.22386 16 8 15.7761 8 15.5Z" fill="#6D7C90"/>
    </svg>
  );
};

export default Icon;
