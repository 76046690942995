// Core
import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { compose } from 'react-apollo';

// Components
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Text from 'components/Text';

// Other
import { sharedMessages } from 'services/i18n/sharedMessages/messages';
import { getTableCellContent } from 'routes/ManageAccount/helpers';
import { withCurrency } from 'containers/CurrencyProvider/withCurrency';
import { addSpacesBetweenEveryGroupOfThreeNumbers } from 'utils/helpers/index';
import { isNull } from 'utils/helpers/index';

// Styles and Assets
import './EarningsTableRow.scss';

const EarningsTableRow = ({
  currencyContext: { changeDependOnCurrentCurrencyRate, getCurrentCurrencySign },
  item, index, arr, intl: { formatMessage },
}) => {
  const signDependOnCurrentCurrency = getCurrentCurrencySign();

  const flexPrice = isNull(item.flex) ? '-' : signDependOnCurrentCurrency +
    addSpacesBetweenEveryGroupOfThreeNumbers(changeDependOnCurrentCurrencyRate(item.flex));
  const assistPrice = isNull(item.assist) ? '-' : signDependOnCurrentCurrency +
    addSpacesBetweenEveryGroupOfThreeNumbers(changeDependOnCurrentCurrencyRate(item.assist));
  const conciergePrice = isNull(item.concierge) ? '-' : signDependOnCurrentCurrency +
    addSpacesBetweenEveryGroupOfThreeNumbers(changeDependOnCurrentCurrencyRate(item.concierge));
  const totalPrice = (isNull(item.total) || (!item.total && item.total !== 0)) ? '' : signDependOnCurrentCurrency +
    addSpacesBetweenEveryGroupOfThreeNumbers(changeDependOnCurrentCurrencyRate(item.total));

  return (
    <TableRow key={item.id} className='mui-override sk-expert-analytics-earnings-table-row'>
      <TableCell className='mui-override sk-expert-analytics-earnings-table-row__cell'>
        <Text align="left" size="basic" className='mui-override sk-expert-analytics-earnings-table-row__title'>
          {item.title}
        </Text>
      </TableCell>
      <TableCell className='mui-override sk-expert-analytics-earnings-table-row__cell'>
        <Text align="right" size="basic">
          {flexPrice}
        </Text>
      </TableCell>
      <TableCell className='mui-override sk-expert-analytics-earnings-table-row__cell'>
        <Text align="right" size="basic">
          {assistPrice}
        </Text>
      </TableCell>
      <TableCell className='mui-override sk-expert-analytics-earnings-table-row__cell'>
        <Text align="right" size="basic">
          {conciergePrice}
        </Text>
      </TableCell>
      <TableCell className='mui-override sk-expert-analytics-earnings-table-row__cell'>
        <Text align="right" size="basic">{totalPrice}</Text>
      </TableCell>
    </TableRow>
  )
};

const enhancer = compose(
  memo,
  injectIntl,
  withCurrency
);

export default enhancer(EarningsTableRow);
