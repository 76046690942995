import React from 'react';

const Icon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="clock">
      <path id="Shape" fillRule="evenodd" clipRule="evenodd" d="M0 10C0 4.47716 4.47716 0 10 0C15.5199 0.00707851 19.9929 4.4801 20 10C20 15.5228 15.5228 20 10 20C4.47716 20 0 15.5228 0 10ZM9.57054 19.1205H10.4285C15.1342 18.8981 18.8996 15.1345 19.1244 10.4289L19.1201 9.57094C18.8955 4.8669 15.1326 1.10398 10.4285 0.879395H9.57054C4.8665 1.10398 1.10357 4.8669 0.87899 9.57094V10.4289C1.10357 15.133 4.8665 18.8959 9.57054 19.1205Z" fill="#A9B6C8"/>
      <path id="Shape_2" d="M14.413 13.7999L9.9303 9.48442V5.0001C9.9303 4.75466 9.72013 4.55566 9.46091 4.55566C9.20169 4.55566 8.99152 4.75466 8.99152 5.0001V9.66663C8.99204 9.78324 9.04092 9.89496 9.12764 9.97774L13.7418 14.4221C13.9233 14.5976 14.2206 14.6006 14.406 14.4287C14.5913 14.2569 14.5945 13.9753 14.413 13.7999Z" fill="#A9B6C8"/>
      </g>
    </svg>
  );
};

export default Icon;
