import { useState, useEffect, useCallback } from 'react';
import { isNull } from 'utils/helpers/index';

const PADDINGS = 80;

const useSliding = (elementWidth, countElements, padding) => {
  const [containerWidth, setContainerWidth] = useState(0);
  const [distance, setDistance] = useState(0);
  const [totalInViewport, setTotalInViewport] = useState(0)
  const [viewed, setViewed] = useState(0);

  const containerRef = useCallback(node => {
    if (!isNull(node) && !isNull(padding)) {
      setContainerWidth(node.getBoundingClientRect().width - padding);
      setTotalInViewport(Math.floor(containerWidth / elementWidth));
    }
  }, [setContainerWidth, setTotalInViewport, containerWidth, elementWidth, padding]);

  const handlePrev = () => {
    setViewed(viewed - totalInViewport);
    setDistance(distance + containerWidth);
  }

  const handleNext = () => {
    setViewed(viewed + totalInViewport);
    setDistance(distance - containerWidth)
  }

  const slideTo = to => {
    setViewed(to * totalInViewport);
    setDistance(-to * containerWidth);
  }

  const slideProps = {
    style: { transform: `translate3d(${distance}px, 0, 0)` }
  };

  const hasPrev = distance < 0;
  const hasNext = (viewed + totalInViewport) < countElements;

  return { containerWidth, totalInViewport, handlePrev, handleNext, slideProps, containerRef, hasPrev, hasNext, elementWidth, slideTo, viewed };
}

export default useSliding;
