// Core
import React from 'react';

// Styles and Assets
import './index.scss';

const StripeElement = ({
  label,
  stripeElement: StripeElement,
  styles = {},
  elmProps = {},
}) => {
  return (
    <div style={styles.wrapper} className="sk-stripe">
      <label className='sk-stripe__label'>{label}</label>
      <StripeElement className='sk-stripe__input' {...styles.input} {...elmProps.stripeElement} />
      <hr/>
    </div>
)};

export default StripeElement;
