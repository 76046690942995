// Core
import React from 'react';
import PropTypes from 'prop-types';

// Styles and Assets
import './Image.scss';

const Image = ({ alt, className, src, ...rest }) => (
  <img className={className || "sk-image"} {...rest} src={src} alt={alt} />
)

Image.defaultProps = {
  alt: 'AVDO Education Image',
};

Image.propTypes = {
  alt: PropTypes.string,
};

export default Image;
