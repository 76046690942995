import React from "react";
import Amplify from "@aws-amplify/core";
import { Analytics } from "aws-amplify";
import { BrowserRouter, StaticRouter } from "react-router-dom";

import SentryProvider from "containers/SentryProvider";
import AuthenticatedUserProvider from "containers/AuthenticatedUserProvider";
import ShoppingCartProvider from "containers/ShoppingCartProvider";
import UploadManagerProvider from "containers/UploadManagerProvider";
import Navigation from "./containers/Navigation/Navigation";
import LanguageProvider from "containers/LanguageProvider";
import CurrencyProvider from "containers/CurrencyProvider";
import ThemeProvider from "containers/ThemeProvider";
import ModalProvider from "containers/ModalProvider";
import LayerModalProvider from "containers/LayerModalProvider";
import SidebarProvider from "containers/SidebarProvider";
import AWSProvider from "containers/AWSProvider";
import SharedStateProvider from "containers/SharedStateProvider";
import NotificationProvider from "containers/NotificationProvider";
import MuiPickerProvider from "containers/MuiPickerProvider";
import "./index.scss";

import amplifyConfig from "services/aws/amplify/config";
Amplify.configure(amplifyConfig);
Analytics.disable();

const App = ({ serverLanguage, serverContext, apolloClient }) => {
  const Router = serverContext ? StaticRouter : BrowserRouter;
  const routerProps = serverContext || {};
  return (
    <SentryProvider>
      <Router {...routerProps}>
        <AWSProvider apolloClient={apolloClient}>
          <AuthenticatedUserProvider>
            <SharedStateProvider>
              <LanguageProvider serverLanguage={serverLanguage}>
                <CurrencyProvider>
                  <ThemeProvider>
                    <UploadManagerProvider>
                      <NotificationProvider>
                        <ShoppingCartProvider>
                          <SidebarProvider>
                            <ModalProvider>
                              <LayerModalProvider>
                                <MuiPickerProvider>
                                  <Navigation serverContext={serverContext} />
                                </MuiPickerProvider>
                              </LayerModalProvider>
                            </ModalProvider>
                          </SidebarProvider>
                        </ShoppingCartProvider>
                      </NotificationProvider>
                    </UploadManagerProvider>
                  </ThemeProvider>
                </CurrencyProvider>
              </LanguageProvider>
            </SharedStateProvider>
          </AuthenticatedUserProvider>
        </AWSProvider>
      </Router>
    </SentryProvider>
  );
};

export default App;
