import React from 'react';

import './index.scss';

const BlockquoteOnHover = () => (
  <svg
    className="hover"
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.692383" width="32" height="32" rx="2" fill="white" />
    <path
      d="M17.3805 24.3201C17.0882 24.3201 16.6497 24.3201 16.3573 24.174C14.4571 23.8816 12.8493 22.4199 12.4108 20.6659C12.4108 20.3736 12.2646 20.0812 12.2646 19.7889H13.5801C13.5801 19.9351 13.5801 20.2274 13.5801 20.3736C13.8725 21.6891 15.0418 22.7123 16.5035 22.8585C18.6961 23.297 20.3039 21.9814 20.7424 20.6659C21.0348 19.4966 20.5963 18.0349 18.5499 17.0117H11.2414V15.8423H15.4803C14.0186 15.1115 12.9955 14.0883 13.1416 12.1881C13.434 9.99556 15.4803 8.82621 17.3805 8.97238C19.2807 9.11854 21.1809 10.2879 21.1809 12.7728H19.8654C19.8654 11.1649 18.5499 10.2879 17.2344 10.2879C15.9188 10.1417 14.4571 10.8726 14.1648 12.3343C14.0186 13.9421 15.3342 14.673 17.6729 15.55L17.9652 15.6961C18.1114 15.8423 18.4037 15.8423 18.5499 15.9885H22.935V17.304H20.4501C21.7656 18.6195 22.0579 20.0812 21.7656 21.2506C21.6194 22.8584 19.8654 24.3201 17.3805 24.3201Z"
      fill="#7145D8"
    />
  </svg>
);

const DefaultBlockquote = () => (
  <svg
    className="default"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.3805 24.3202C16.0882 24.3202 15.6497 24.3201 15.3573 24.174C13.4571 23.8816 11.8493 22.42 11.4108 20.6659C11.4108 20.3736 11.2646 20.0812 11.2646 19.7889H12.5801C12.5801 19.9351 12.5801 20.2274 12.5801 20.3736C12.8725 21.6891 14.0418 22.7123 15.5035 22.8585C17.6961 23.297 19.3039 21.9814 19.7424 20.6659C20.0348 19.4966 19.5963 18.0349 17.5499 17.0117H10.2414V15.8423H14.4803C13.0186 15.1115 11.9955 14.0883 12.1416 12.1881C12.434 9.99556 14.4803 8.82621 16.3805 8.97238C18.2807 9.11855 20.1809 10.2879 20.1809 12.7728H18.8654C18.8654 11.1649 17.5499 10.2879 16.2344 10.2879C14.9188 10.1417 13.4571 10.8726 13.1648 12.3343C13.0186 13.9421 14.3342 14.673 16.6729 15.55L16.9652 15.6962C17.1114 15.8423 17.4037 15.8423 17.5499 15.9885H21.935V17.304H19.4501C20.7656 18.6195 21.0579 20.0812 20.7656 21.2506C20.6194 22.8585 18.8654 24.3202 16.3805 24.3202Z"
      fill="#6D6E71"
    />
  </svg>
);

const ActiveBlockquote = () => (
  <svg
    className="active"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.3805 24.3201C16.0882 24.3201 15.6497 24.3201 15.3573 24.174C13.4571 23.8816 11.8493 22.4199 11.4108 20.6659C11.4108 20.3736 11.2646 20.0812 11.2646 19.7889H12.5801C12.5801 19.9351 12.5801 20.2274 12.5801 20.3736C12.8725 21.6891 14.0418 22.7123 15.5035 22.8585C17.6961 23.297 19.3039 21.9814 19.7424 20.6659C20.0348 19.4966 19.5963 18.0349 17.5499 17.0117H10.2414V15.8423H14.4803C13.0186 15.1115 11.9955 14.0883 12.1416 12.1881C12.434 9.99556 14.4803 8.82621 16.3805 8.97238C18.2807 9.11854 20.1809 10.2879 20.1809 12.7728H18.8654C18.8654 11.1649 17.5499 10.2879 16.2344 10.2879C14.9188 10.1417 13.4571 10.8726 13.1648 12.3343C13.0186 13.9421 14.3342 14.673 16.6729 15.55L16.9652 15.6961C17.1114 15.8423 17.4037 15.8423 17.5499 15.9885H21.935V17.304H19.4501C20.7656 18.6195 21.0579 20.0812 20.7656 21.2506C20.6194 22.8584 18.8654 24.3201 16.3805 24.3201Z"
      fill="#7145D8"
    />
  </svg>
);

const Blockquote = ({ active }) => {
  const Icon = active ? ActiveBlockquote : DefaultBlockquote;

  return (
    <div className="editor-icon-svg">
      <Icon />
      <BlockquoteOnHover />
    </div>
  );
};

export default Blockquote;
