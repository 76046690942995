// Core
import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';

// Components
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Text from 'components/Text';

// Other
import { sharedMessages } from 'services/i18n/sharedMessages/messages';
import { getTableCellContent } from 'routes/ManageAccount/helpers';

// Styles and Assets
import './ActiveStudentsTableRow.scss';

const ActiveStudentsTableRow = memo(({ item, index, arr, intl: { formatMessage } }) => (
  <TableRow key={item.id} className='mui-override sk-expert-analytics-active-students-table-row'>
    <TableCell className='mui-override sk-expert-analytics-active-students-table-row__cell'>
      <Text align="left" size="basic" className='mui-override sk-expert-analytics-active-students-table-row__title'>
        {item.title}
      </Text>
    </TableCell>
    <TableCell className='mui-override sk-expert-analytics-active-students-table-row__cell'>
      <Text align="right" size="basic">{getTableCellContent(item.rating)}</Text>
    </TableCell>
    <TableCell className='mui-override sk-expert-analytics-active-students-table-row__cell'>
      <Text align="right" size="basic">
        {getTableCellContent(item.flex)}
      </Text>
    </TableCell>
    <TableCell className='mui-override sk-expert-analytics-active-students-table-row__cell'>
      <Text align="right" size="basic">
        {getTableCellContent(item.assist)}
      </Text>
    </TableCell>
    <TableCell className='mui-override sk-expert-analytics-active-students-table-row__cell'>
      <Text align="right" size="basic">
        {getTableCellContent(item.concierge)}
      </Text>
    </TableCell>
  </TableRow>
));

export default injectIntl(ActiveStudentsTableRow);
