// Core
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

// Components
import Heading from 'components/Heading';
import Button from 'components/Button';
import Text from 'components/Text';
import Done from 'components/Icon/Svg/Done';
import { messages } from './messages';

// Styles and Assets
import './index.scss';

export const SuccesfulMessage = ({ handleContinueClick }) => (
  <div className="sk-tr-success">
    <div className="sk-tr-success_header">
      <div className="sk-tr-success_icon">
        <Done width="51" height="51" />
      </div>
      <Heading variant="h4" classes="sk-tr-success_heading">
        <FormattedMessage {...messages.title} />
      </Heading>
      <Text classes="sk-tr-success_subheading">
        <FormattedMessage {...messages.body} />
      </Text>
    </div>
    <div className="sk-tr-success_footer">
      <Button
        variant="text"
        color="secondary"
        size="large"
        onClick={handleContinueClick}
      >
        <FormattedMessage {...messages.button} />
      </Button>
    </div>
  </div>
);

SuccesfulMessage.propTypes = {
  handleContinueClick: PropTypes.func,
};

export default memo(SuccesfulMessage);
