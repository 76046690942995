import React from 'react';
import PropTypes from 'prop-types';
import { OrangeyRed } from 'components/Icon/color';

const ReloadWheel = ({ color, height, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 18 16"
  >
    <g fill={color} fillRule="evenodd">
      <path
        d="M8.1 15.3C3.633 15.3 0 11.868 0 7.65S3.633 0 8.1 0s8.1
       3.432 8.1 7.65a.45.45 0 0 1-.463.437.45.45 0 0 1-.463-.437c0-3.736-3.219-6.776-7.175-6.776S.925 
       3.914.925 7.65c0 3.736 3.218 6.776 7.174 6.776 2.531 0 4.902-1.282 6.188-3.344a.478.478 0 0 1 
       .634-.155c.22.123.293.39.164.599-1.45 2.328-4.128 3.774-6.985 3.774z"
      />
      <path
        d="M15.786 9a.422.422 0 0 1-.223-.064l-2.747-1.708a.475.475 0 0
        1-.155-.632.426.426 0 0 1 .6-.162l2.398 1.49 1.543-2.327a.425.425 0
         0 1 .61-.114.477.477 0 0 1 .11.642l-1.777 2.677a.432.432 0 0 1-.36.198z"
      />
    </g>
  </svg>
);

ReloadWheel.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  color: PropTypes.string,
};

ReloadWheel.defaultProps = {
  color: OrangeyRed,
  height: '16',
  width: '18',
};

export default ReloadWheel;
