const selector = {
  height: 34,
  minHeight: 34,
  lineHeight: 'normal',
};

const menuItem = {
  height: 30,
  padding: 0,
  backgroundColor: '#ffffff',
  fontFamily: 'Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
  fontWeight: 'normal',
  fontSize: 15,
  paddingLeft: 10,
};

const form = {
  minWidth: 229,
  height: 34,
  marginTop: -14,
};

const displayValue = {
  fontSize: 24,
  fontWeight: 600,
  fontFamily: 'Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
  color: '#444444',
};

export { form, selector, menuItem, displayValue };
