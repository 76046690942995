import moment from 'moment-timezone';
import { sharedMessages } from 'services/i18n/sharedMessages/messages';

// TODO: Merge webinar's reducers

export const listCalendarEventsThatStartInProvidedPeriodOfTime = (
  type,
  timezone,
  hoursFromNow,
  { events, activeCourses }
) => {
  const timestampNow = moment().valueOf();
  const timestampInHours = moment()
    .add(hoursFromNow, 'h')
    .valueOf();

  const webinars = events
    .filter(event => {
      const eventStartTimestamp = moment(event.start).valueOf();
      const eventEndTimestamp = moment(event.end).valueOf();
      return (
        (eventStartTimestamp > timestampNow &&
          eventStartTimestamp < timestampInHours) ||
        eventEndTimestamp > timestampNow
      );
    })
    .sort(
      (prev, cur) =>
        moment(prev?.start).valueOf() - moment(cur?.start).valueOf()
    );

  let parsedWebinars = [];

  if (webinars && webinars.length > 0) {
    parsedWebinars = webinars.map(webinar => ({
      webinarEvent: {
        title: webinar?.title,
        id: webinar?.idEvent,
        date: moment(webinar.start)
          .tz(timezone)
          .format('DD.MM.Y'),
        time: moment(webinar.start)
          .tz(timezone)
          .format('HH:mm (UTC Z)'),
        sessionId: webinar?.sessionId,
        mappingId: webinar?.mappingId
      },
      course: {
        title: webinar?.course?.title,
        id: webinar?.course?.id
      }
    }));
  }

  return parsedWebinars;
};

export const getNearestWebinarReducer = (
  type,
  timezone,
  { events, course }
) => {
  const courseEvents = events.filter(evt => evt?.Course?.id === course.id);

  const nearestEvent = courseEvents
    .filter(
      event => moment(event.EventEndUnixMs).valueOf() > moment().valueOf()
    )
    .sort(
      (prev, cur) =>
        moment(prev?.EventStartUnixMs).valueOf() -
        moment(cur?.EventStartUnixMs).valueOf()
    )[0];

  if (!nearestEvent) return null;

  const actualStartDateTimestamp = moment.tz(
    moment(nearestEvent?.EventStartUnixMs),
    timezone
  );

  const MINUTES_BEFORE_COURSE_START = 15;

  const isWebinarAvailable =
    actualStartDateTimestamp &&
    moment().diff(actualStartDateTimestamp, 'minutes') >
      -MINUTES_BEFORE_COURSE_START;

  return {
    webinarEvent: {
      title: nearestEvent?.EventName,
      id: nearestEvent?.Id,
      date: actualStartDateTimestamp.format('DD.MM.Y'),
      time: actualStartDateTimestamp.format('HH:mm (UTC Z)'),
      isWebinarAvailable,
      sessionId: nearestEvent?.SessionId
    },
    course: {
      title: course?.title,
      id: course?.id
    }
  };
};

export const getCalendarNearesetWebinar = (
  type,
  timezone,
  { events, activeCourses }
) => {
  const nearestEvent = events
    .filter(event => moment(event.end).valueOf() > moment().valueOf())
    .sort(
      (prev, cur) =>
        moment(prev?.start).valueOf() - moment(cur?.start).valueOf()
    )[0];

  if (!nearestEvent) return null;

  const nearestWebinarCourse = activeCourses.find(
    ({ id }) => id === nearestEvent.id
  );

  const actualStartDateTimestamp = moment.tz(
    moment(nearestEvent?.start),
    timezone
  );

  return {
    webinarEvent: {
      title: nearestEvent?.title,
      id: nearestEvent.idEvent,
      date: actualStartDateTimestamp.format('DD.MM.Y'),
      time: actualStartDateTimestamp.format('hh:mm A')
    },
    course: {
      title: nearestWebinarCourse?.title,
      id: nearestWebinarCourse?.id
    }
  };
};

export const getCourseNearesetWebinar = (
  type,
  timezone,
  { events, course }
) => {
  const nearestEvent = events
    .filter(
      event => event?.Course?.id && course?.id && event.Course.id == course.id
    )
    .filter(
      event => moment(event.EventEndUnixMs).valueOf() > moment().valueOf()
    )
    .sort(
      (prev, cur) =>
        moment(prev?.EventStartUnixMs).valueOf() -
        moment(cur?.EventStartUnixMs).valueOf()
    )[0];

  if (!nearestEvent) return null;

  const actualStartDateTimestamp = moment.tz(
    moment(nearestEvent?.EventStartUnixMs),
    timezone
  );

  return {
    webinarEvent: {
      title: nearestEvent?.EventName,
      id: nearestEvent?.id,
      date: actualStartDateTimestamp.format('DD.MM.Y'),
      time: `${actualStartDateTimestamp.format(
        'hh:mm A '
      )}(GMT ${actualStartDateTimestamp.format('Z')})`
    },
    course: {
      title: course?.title,
      id: course?.id
    }
  };
};

export const getCatalogItems = (
  categories,
  subcategories,
  formatMessage,
  subcategoriesWithCourses = ['023e2400-5c40-11e9-a972-c1e676d48404']
) => {
  const sortedCategories = categories.sort(
    (prev, cur) => prev.orderNumber - cur.orderNumber
  );

  const sortedCategoriesWithSubcategories = sortedCategories.map(cat => ({
    title:
      cat?.localized ||
      (sharedMessages[cat?.title] &&
        formatMessage(sharedMessages[cat.title])) ||
      formatMessage(sharedMessages.others),
    id: cat.id,
    expandable: true,
    subItems: subcategories
      .filter(sub => sub.category === cat.id)
      .map(subcategory => {
        const { title, localized, id, ...item } = subcategory;
        return {
          name: 'subcategory',
          id,
          title:
            localized ||
            (sharedMessages[title] && formatMessage(sharedMessages[title])) ||
            formatMessage(sharedMessages.others),
          ...item,
          hasCourses:
            subcategoriesWithCourses && subcategoriesWithCourses?.includes(id),
          expandable: false
        };
      })
      .sort((prev, cur) => prev.orderNumber - cur.orderNumber)
  }));

  return sortedCategoriesWithSubcategories;
};
