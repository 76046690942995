// Core
import React, { memo, useCallback, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { withFormik } from 'formik';
import { compose } from 'react-apollo';

// Components
import CategorySelect from 'routes/Course/components/CategorySelect';
import SubcategorySelect from 'routes/Course/components/SubcategorySelect';
import InteractionLevelToolTip from 'routes/Course/components/InteractionLevelToolTip';

// Other
import { withCourseEditContext } from 'routes/ExpertsCabinet/CourseEdit/Context';
import { getYupSchema } from 'services/yup';
import { messages } from 'routes/ExpertsCabinet/CourseEdit/GeneralInfo/messages';
import { withSharedState } from 'containers/SharedStateProvider/withSharedState';

// Styles and Assets
import './GeneralInfoForm.scss';

const GeneralInfoForm = ({
  disableAll,
  isValid,
  values,
  errors,
  touched,
  validateForm,
  setErrors,
  handleBlur,
  setFieldValue,
  sharedStateContext: { updateSharedState },
  courseEditContext: { bindCourseMethod, handleCourseUpdate },
  intl: { formatMessage },
}) => {

  useEffect(() => {
    let isValid = true;

    Object.keys(errors).forEach(error => {
      if (touched[error]) {
        isValid = false;
      }
    });
    const courseInfo = { isValid };
    updateSharedState({ courseInfo });
    return () => {
      updateSharedState({ courseInfo: { isValid: true } });
    };
  }, [errors, touched]);

  const handleInputChange = name => (inputValue, e) => {
    setFieldValue(name, inputValue);
    if(name === 'category'){
      setFieldValue('subcategory', '')
    }
    if (e) e.persist();
  };

  const handleInputBlur = name => (inputValue, e) => {
    if (e) {
      e.persist();
      handleBlur(e);
    }
    handleCourseUpdate({ [name]: inputValue });
    setFieldValue(name, inputValue);
  };

  const handleFormValidation =  useCallback(async () => {
    const errors = await validateForm(values);
    const isValid = Object.keys(errors).length == 0;

    if (!isValid) {
      setErrors(errors);
    };

    return isValid;
  }, [values, validateForm, setErrors]);
  bindCourseMethod({ method: 'validateCourseInfo', handler: handleFormValidation });

  const handleChangeCategory = useCallback(handleInputChange('category'), [handleInputChange]);
  const handleBlurCategory = useCallback(handleInputBlur('category'), [handleInputBlur]);
  const handleChangeSubcategory = useCallback(handleInputChange('subcategory'), [handleInputChange]);
  const handleBlurSubcategory = useCallback(handleInputBlur('subcategory'), [handleInputBlur]);

  return (
    <form
      className="sk-course-general-info-form"
      noValidate
      autoComplete="off"
    >
      <div className="sk-course-general-info-form__inner">
        <div className="sk-form__row sk-course-general-info-form__row">
          <CategorySelect
            disabled={disableAll}
            helperText={touched.category ? errors.category : ''}
            error={touched.category && Boolean(errors.category)}
            name="category"
            label={formatMessage(messages.chooseCat)}
            value={values?.category}
            handleChange={handleChangeCategory}
            handleBlur={handleBlurCategory}
          />
        </div>
        <div className="sk-form__row sk-course-general-info-form__row">
          <SubcategorySelect
            helperText={touched.subcategory ? errors.subcategory : ''}
            error={touched.subcategory && Boolean(errors.subcategory)}
            name="subcategory"
            label={formatMessage(messages.chooseSubCat)}
            value={values?.subcategory}
            handleChange={handleChangeSubcategory}
            handleBlur={handleBlurSubcategory}
            disabled={!values?.category || disableAll}
            skip={!values?.category}
            category={values?.category}
          />
        </div>
      </div>
    </form>
  );
};

const formikStateManagement = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ initialValues }) => initialValues,
  displayName: 'CourseInformationForm',
  validationSchema: getYupSchema('courseGeneralInfoValidationSchema'),
  validateOnChange: true,
  validateOnBlur: true
});

const enhancer = compose(
  memo,
  injectIntl,
  formikStateManagement,
  withCourseEditContext,
  withSharedState
)

export default enhancer(GeneralInfoForm);
