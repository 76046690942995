// Core
import React, { PureComponent } from 'react';
import withSizes from 'react-sizes';

// Components
import Duration from 'components/Duration/Duration';
import Text from 'components/Text';

// Other
import { getLectureDuration } from 'utils/helpers';
import { FormattedMessage } from 'react-intl';
import { sharedMessages } from 'services/i18n/sharedMessages/messages';

// Styles and Assets
import './CurriculumSummary.scss';

const MobileCurriculumSummary = ({
  lecture,
  orderNumber,
  displayLectureNameType,
}) => {
  return (
    <div className="mui-override sk-lec-element-component">
      <div className="mui-override sk-lec-element-component__header">
        <span className="mui-override sk-lec-element-component__title">
          {displayLectureNameType === 'section'
            ? <FormattedMessage {...sharedMessages.section} />
            : <FormattedMessage {...sharedMessages.lecture} />} {orderNumber + 1}.
        </span>
        <span className="mui-override sk-lec-element-component__duration">
          <Duration duration={getLectureDuration([lecture])} />
        </span>
      </div>
      <span>{lecture?.title}</span>
    </div>
)}

const DesktopCurriculumSummary = ({
  lecture,
  orderNumber,
  displayLectureNameType,
}) => {
  return (
    <div className="mui-override sk-lec-element-component">
      <div className="mui-override sk-lec-element-component__header">
        <span className="mui-override sk-lec-element-component__header__title">
          {displayLectureNameType === 'section'
            ? <FormattedMessage {...sharedMessages.section} />
            : <FormattedMessage {...sharedMessages.lecture} />} {orderNumber + 1}.
        </span>
        <span>{lecture?.title}</span>
      </div>
      <span className="mui-override sk-lec-element-component__duration">
        <Duration duration={getLectureDuration([lecture])} />
      </span>
    </div>
  )
}

const CurriculumSummary = ({ isMobile, ...props }) =>
  isMobile ? <MobileCurriculumSummary {...props} /> : <DesktopCurriculumSummary {...props} />

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 480,
  width,
})

export default withSizes(mapSizesToProps)(CurriculumSummary);
