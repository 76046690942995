import React from 'react';

import './index.scss';

const IconOnHover = ({ linearId }) => (
  <svg class='filled' width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.89213 1.82671L15.6408 0.291992V13.2781C15.6408 14.3406 14.7983 15.167 13.5948 15.5212C12.1505 15.8753 10.8266 15.285 10.5859 14.2225C10.2249 13.2781 11.1877 12.0975 12.632 11.7434C13.2337 11.6253 13.8355 11.6253 14.4373 11.7434V3.95171L7.21603 5.25033V15.0489C7.21603 15.9934 6.37355 16.9378 5.17001 17.1739C3.72576 17.5281 2.28151 16.8198 2.0408 15.8753C1.8001 14.8128 2.64257 13.7503 4.08682 13.3962C4.68859 13.2781 5.29036 13.2781 5.89213 13.3962V1.82671Z" fill={`url(#paint0_linear_${linearId})`} />
    <defs>
    <linearGradient id={`paint0_linear_${linearId}`} x1="17.4972" y1="19.166" x2="-0.378788" y2="17.4038" gradientUnits="userSpaceOnUse">
    <stop stopColor="#8F26AF"/>
    <stop offset="0.979167" stopColor="#141685"/>
    </linearGradient>
    </defs>
  </svg>
);

const IconDefault = ({ linearId }) => (
  <svg class='outlined' width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.39093 17.2741C2.8958 17.0496 2.60637 16.7142 2.52884 16.4108C2.36623 15.6489 2.9352 14.7217 4.25296 14.3833C4.80104 14.2738 5.34718 14.2746 5.89529 14.3857L6.49462 14.5072V13.8956V2.05266L15.5 0.587898V13.7705C15.5 14.5963 14.869 15.3281 13.7659 15.6672C12.4351 15.9996 11.4615 15.3997 11.3004 14.6649L11.293 14.631L11.281 14.5984C11.1756 14.3137 11.2417 13.9321 11.5584 13.5341C11.8694 13.1433 12.3852 12.7949 13.0232 12.6307C13.5712 12.5212 14.1174 12.522 14.6654 12.6331L15.2648 12.7546V12.143V3.88077V3.27931L14.6734 3.38918L7.26204 4.76622L6.85338 4.84215V5.25781V15.6483C6.85338 16.3675 6.20275 17.199 5.15416 17.4116L5.14235 17.414L5.13068 17.4169C4.50326 17.5759 3.87805 17.4949 3.39093 17.2741Z" stroke={`url(#paint1_linear_${linearId})`} />
    <defs>
    <linearGradient id={`paint1_linear_${linearId}`} x1="17.2676" y1="19.2116" x2="-0.354268" y2="17.5303" gradientUnits="userSpaceOnUse">
    <stop stopColor="#6D7C90"/>
    <stop offset="0.979167" stopColor="#6D7C90"/>
    </linearGradient>
    </defs>
  </svg>
);

const Icon = () => {
  const randomFloatString = `${Math.random()}`
  return (
    <div className='icon-with-hover'>
      <IconDefault linearId={randomFloatString} />
      <IconOnHover linearId={randomFloatString} />
    </div>
  );
};

export default Icon;
