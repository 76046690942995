import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  searchCourse: {
    id: 'app.components.Search.searchCourse',
    defaultMessage: 'Find a course',
  },
  searchUser: {
    id: 'app.components.Search.searchUser',
    defaultMessage: 'Search User',
  },
  showCourses: {
    id: 'app.components.Search.showCourses',
    defaultMessage: 'Show courses',
  },
});
