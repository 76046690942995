import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  courseInfo: {
    id: 'app.components.CourseEditTabs.courseInfo',
    defaultMessage: 'Course Information',
  },
  courseStructure: {
    id: 'app.components.CourseEditTabs.courseStructure',
    defaultMessage: 'Course Structure',
  },
});
