import React from 'react';
import { LayerModalConsumer } from 'containers/LayerModalProvider/LayerModalConsumer';

export function withLayerModal(Component) {
  return function ModalComponent(props) {
    return (
      <LayerModalConsumer>
        {contexts => <Component {...props} {...contexts} />}
      </LayerModalConsumer>
    );
  };
}
