// Core
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { FormattedMessage } from 'react-intl';

// Components
import Button from 'components/Button';
import TextAreaInput from 'components/Input/Material/TextAreaInput';
import Clock from 'components/Icon/Svg/Clock';
import DurationBlock from 'routes/Course/components/DurationBlock';
import FileUploadModule from 'routes/Course/components/FileUploadModule';
import Duration from 'components/Duration/Duration';

// Other
import { getYupSchema } from 'services/yup';
import {
  VIDEO_FILE,
  RECOMMENDED_VIDEO_FORMATS,
  DEFAULT_MAX_FILE_SIZE,
} from 'utils/enums/index';
import { parseEntityDuration, isNull } from 'utils/helpers';
import { DarkGrey } from 'components/Icon/color';
import { sharedMessages } from 'services/i18n/sharedMessages/messages';
import { messages } from 'routes/Course/components/ComplexitySelect/messages';

// Styles and Assets
import './VideoFileEditModal.scss';

const VideoFileEditModal = ({
  entity,
  lectureId,
  courseId,
  editEntity,
  handleUploadVideo,
  handleModalClose,
  lecture,
}) => {
  const handleSubmit = async ({ title, file }) => {
    const isNewVideoFileAdded = file && !isNull(file) && file instanceof File;
    const isVideoFileUploadedInS3 = !!entity?.id;

    const params = {
      file,
      title,
      lectureId,
      courseId,
      filetype: VIDEO_FILE,
    };

    if (isVideoFileUploadedInS3) {
      params.id = entity?.id;
      if (!isNewVideoFileAdded) delete params.file;
    };

    const needToUpdateTitle = !isNewVideoFileAdded && isVideoFileUploadedInS3;
    const addOrUpdateVideoFile = isNewVideoFileAdded;

    if (addOrUpdateVideoFile) {
      handleUploadVideo(params);
    } else if (needToUpdateTitle) {
      await editEntity({
        id: entity?.id,
        title,
      });
    }
    handleModalClose();
  };

  const handleAddingNewFile = newVideoFile => {
    this.setState({ newVideoFile });
  };

  let formFields = { title: '', duration: null, file: entity };
  if (entity) {
    const { title, duration } = entity;
    formFields = {
      ...formFields,
      title,
      duration: parseEntityDuration(duration, 'seconds', 'asMinutes'),
    };
  }

  return (
    <div className="sk-lec-video-modal">
      <Formik
        initialValues={formFields}
        validateOnChange={false}
        validateOnBlur
        onSubmit={values => handleSubmit(values)}
        validationSchema={getYupSchema('videoModalValidationSchema')}
      >
        {props => {
          const {
            values: { title, duration, file },
            errors,
            touched,
            disabled,
            handleBlur,
            handleSubmit,
            setFieldValue,
          } = props;

          const handleInputChange = name => (inputValue, e) =>
            setFieldValue(name, inputValue);

          const setTitleFromFile = filename =>
            !title ? setFieldValue('title', filename) : null;

          return (
            <form
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
              className="sk-lec-video-modal__form"
            >
              <header className="sk-lec-video-modal__header">
                <div className="sk-form_row">
                  <FormattedMessage {...sharedMessages.title}>
                    {msg => (
                      <TextAreaInput
                        name="title"
                        value={title}
                        onChange={handleInputChange('title')}
                        label={msg}
                        error={touched.title && Boolean(errors.title)}
                        helperText={touched.title ? errors.title : ''}
                        disabled={disabled}
                      />
                    )}
                  </FormattedMessage>
                </div>
              </header>

              <div className="sk-lec-video-modal__content">
                <div className="sk-lec-video-modal__time sk-lec-video-modal-time">
                  <div className="sk-lec-video-modal-time__duration">
                    {entity !== null && (
                      <Duration
                        duration={duration}
                        textStyles={{ color: DarkGrey }}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="sk-lec-video-modal__inner">
                <FileUploadModule
                  supportedFormats={RECOMMENDED_VIDEO_FORMATS}
                  maxSize={DEFAULT_MAX_FILE_SIZE * 1000}
                  setTitle={setTitleFromFile}
                  handleFileAdd={handleInputChange('file')}
                  courseId={courseId}
                  lectureId={lectureId}
                  disabled={disabled}
                  file={file}
                />
              </div>
              <footer className="sk-lec-video-modal__footer">
                <Button
                  variant="text"
                  color="secondary"
                  size="large"
                  type="submit"
                  disabled={disabled}
                >
                  <FormattedMessage {...sharedMessages.saveButton} />
                </Button>
              </footer>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

VideoFileEditModal.propTypes = {
  video: PropTypes.object,
  disabled: PropTypes.bool,
  lectureId: PropTypes.string,
  courseId: PropTypes.string,
  videoIsUploading: PropTypes.bool,
};

export default VideoFileEditModal;
