// Core
import React, { memo, useState, useEffect } from 'react';
import { compose } from 'react-apollo';

// Components
import Text from 'components/Text';
import TextInput from 'components/Input/Material/TextInput';

// Other
// import { messages } from 'routes/AdminPanel/messages';

// Styles and Assets
import '../index.scss';

const getPaymentPrice = (prices, type) => {
  const paymentPrice = prices.find(price => price?.type == type);
  return paymentPrice ? paymentPrice : '';
}

// const PREFIX = 'sk-admin-plan-page-analytics';
const PlanPrice = ({
  values,
  touched,
  errors,
  handleInputChange,
  handleInputBlur,
  disabled,
  ...props
}) => {
  const [monthlyPayment, setMonthlyPayment] = useState({ month: values?.monthlyPrice, year: values?.monthlyPrice*12 });
  const [annualPayment, setAnnualPayment] = useState({ month: values?.annualPrice, year: values?.annualPrice*12 });

  useEffect(() => {
    setMonthlyPayment({ month: values?.monthlyPrice, year: values?.monthlyPrice*12 });
    setAnnualPayment({ month: values?.annualPrice, year: values?.annualPrice*12 });
  }, [values?.monthlyPrice, values?.annualPrice]);

  return (
    <div className='sk-admin-plan-page-form'>
      <TextInput
        adornment="$"
        position="start"
        name="monthlyPrice"
        type="number"
        value={monthlyPayment?.month}
        onChange={handleInputChange('monthlyPrice')}
        onBlur={handleInputBlur('monthlyPrice')}
        label='Monthly billing, per month'
        disabled={disabled}
        error={touched.monthlyPrice && Boolean(errors.monthlyPrice)}
        helperText={touched.monthlyPrice ? errors.monthlyPrice : ''}
      />
      <TextInput
        adornment="$"
        position="start"
        name="monthlyPriceForYear"
        placeholder={monthlyPayment?.year}
        label='Monthly billing, per year'
        disabled={true}
      />
      <TextInput
        adornment="$"
        position="start"
        name="annualPrice"
        type="number"
        value={annualPayment?.month}
        onChange={handleInputChange('annualPrice')}
        onBlur={handleInputBlur('annualPrice')}
        label='Annual billing, per month'
        disabled={disabled}
        error={touched.annualPrice && Boolean(errors.annualPrice)}
        helperText={touched.annualPrice ? errors.annualPrice : ''}
      />
      <TextInput
        adornment="$"
        position="start"
        name="annualPriceForYear"
        placeholder={annualPayment?.year}
        label='Annual billing, per year'
        disabled={true}
      />
    </div>
  );
}

const enhancer = compose(
  memo,
);

export default enhancer(PlanPrice);
