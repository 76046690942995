import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  email: {
    id: 'app.components.passResetForm.email',
    defaultMessage: 'Email Address',
  },
  send: {
    id: 'app.components.passResetForm.send',
    defaultMessage: 'Send',
  },
});
