// Core
import React, { useEffect, useState, memo } from 'react';
import { graphql, compose } from 'react-apollo';

// Components
import Card from 'components/Card';
import Loader from 'components/Loader';
import Text from 'components/Text';
import PublisherWebinarRoom from 'routes/PublisherWebinar/PublisherWebinarRoom';
import SubscriberWebinarRoom from 'routes/SubscriberWebinar/SubscriberWebinarRoom';

// GraphQL
import CREATE_WEBINAR_SESSION from 'mutations/Webinar/createWebinarToken.gql';
import MY_PROFILE_QUERY from 'queries/UserProfile/getProfile.gql';

// Other
import { isNull } from 'utils/helpers/index';
import { withAWS } from 'containers/AWSProvider/withAWS';
import { withAuthenticatedUser } from 'containers/AuthenticatedUserProvider/withAuthenticatedUser';

// Styles and Assets
import './WebinarRoomCreator.scss';

const WebinarRoomNotCreated = ({ error, loading }) => (
  <div className="sk-web-room-not-created">
    <Card classes="sk-web-room-not-created__card">
      {loading && !error ? (
        <Loader />
      ) : (
        <Text>
          {(error?.message || '').replace('Error: GraphQL error:', '')}
        </Text>
      )}
    </Card>
  </div>
);

const WebinarRoomCreator = ({
  eventId,
  sessionId,
  createWebinarToken,
  courseId,
  mappingId,
  participantRole = 'subscriber',
  userContext: { getProfile, profile },
}) => {

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [sessionToken, setSessionToken] = useState(null);
  const isSessionCreated = !error && !loading && !!sessionToken;
  const username = `${profile.firstName} ${profile.lastName}`;
  const photo = profile.photo;

  useEffect(() => {
    let isSubmitted = false;
    const keyFields = {
      publisher: {
        event_id: eventId,
      },
      subscriber: {
        mapping_id: mappingId,
        event_id: eventId,
      },
    };
    const createWebinarTokenHandler = async () => {
      try {
        if (isNull(sessionToken)) {
          const {data: { createWebinarToken: webinarSessionToken }} = await createWebinarToken({
            ...keyFields[participantRole],
            role: participantRole,
            username: `${username},photo=${photo}`,
          });
          setSessionToken(webinarSessionToken);
          return setLoading(false);
        }
      } catch(err) {
        setLoading(false);
        setError(new Error(err));      
      }
    }
    createWebinarTokenHandler();
  }, [eventId, mappingId, username, participantRole]);

  const webinarRoomProps = {
    participantRole,
    sessionToken,
    eventId,
    courseId,
    sessionId,
  };

  return (
    <div className="sk-web-room">
      {!isNull(sessionId) && !isNull(sessionToken) ? (
        <div className="sk-web-room__resovled">
          {participantRole === 'subscriber' ? (
            <SubscriberWebinarRoom {...webinarRoomProps} />
          ) : (
            <PublisherWebinarRoom {...webinarRoomProps} />
          )}
        </div>
      ) : (
        <WebinarRoomNotCreated error={error} loading={loading} />
      )}
    </div>
  );
};

const createWebinarTokenMutation = graphql(CREATE_WEBINAR_SESSION, {
  props: ({ mutate }) => ({
    createWebinarToken: input => mutate({ variables: { input } }),
  }),
});

const enhancer = compose(
  memo,
  withAWS,
  withAuthenticatedUser,
  createWebinarTokenMutation
);

export default enhancer(WebinarRoomCreator);
