// Core
import React from 'react';
import { graphql, compose } from 'react-apollo';
import { keysToCamel } from 'utils/helpers';

// Components
import CoursesCarousel from 'routes/LandingPage/components/CoursesCarousel';
import NoCoursesFound from 'routes/LandingPage/components/NoCoursesFound';
import Heading from 'components/Heading';
import Loader from 'components/Loader';

// GraphQl
import LIST_COURSES_QUERY from 'queries/Courses/listCourses.gql';

// Styles
import './LandingPageCourses.scss';

const LandingPageNewCourses = ({ listCourses, alreadyBoughtedCourses = [], title }) => {
  const courses = listCourses ? listCourses.courses : [];
  const total = listCourses?.total || null;
  // Intercept and hide empty results
  if (total === 0) {
    return null
  }

  return (
    <div className="sk-landing-page__courses new">
      <div className="sk-landing-page__courses_new">
        <Heading variant="h3" classes="sk-lp-category-item__heading grid landing">
          {title}
        </Heading>
        {courses?.length ? (
          <CoursesCarousel
            courses={courses}
            alreadyBoughtedCourses={alreadyBoughtedCourses}
          />
        ) : (
          <div className="sk-landing-page__courses_loader-wrapper">
            <Loader />
          </div>
        )}
      </div>
    </div>
  )
}

const listCoursesQuery = graphql(LIST_COURSES_QUERY, {
  props: ({ data: { error, loading, listCourses, ...ownProps } }) => {
    if (error) throw Error(error);
    if (loading) return { loading, error };

    return {
      loading,
      listCourses,
      error,
      ...ownProps,
    }
  },
  options: () => ({
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        complexity: '',
        duration_range: '',
        interaction_level: '',
        language: '',
        search_string: '',
        subcategory: '',
        from: 0,
        size: 12,
        sort_by: "recent",
        order: "desc",
        type: "course"
      },
    },
  }),
});

const enhancer = compose(
  listCoursesQuery
);

export default enhancer(LandingPageNewCourses);
