import React from 'react';
import { FormattedMessage } from 'react-intl';
import { sharedMessages } from 'services/i18n/sharedMessages/messages';
export const COURSES_STATUSES_OPTIONS = [
  {
    value: 'ACTIVE',
    label: <FormattedMessage {...sharedMessages.active} />,
  },
  {
    value: 'COMPLETED',
    label: <FormattedMessage {...sharedMessages.completed} />,
  },
];
