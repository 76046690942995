// Core
import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { compose, graphql } from 'react-apollo';

// Components
import Card from 'components/Card';
import WebinarContentHeader from 'routes/PublisherWebinar/WebinarContentHeader';
import WebinarInformation from 'containers/WebinarInformation';
import WebinarRoom from 'containers/WebinarRoom';

import Loader from 'components/Loader';

// GraphQL
import GET_EXPERT_CALENDAR_EVENT_QUERY from 'queries/Calendar/getExpertCalendarEvents.gql';

// Other
import { parseFetchedData, keysToCamel } from 'utils/helpers';
import { hasLoader } from 'containers/HOCs/hasLoader';

// Styles and Assets
import './index.scss';

const PublisherWebinarRoom = ({
  loading,
  sessionToken,
  sessionId,
  events,
  eventId,
  participantRole,
}) => {
  const [publishVideo, setPublishVideo] = useState(true);
  const [publishAudio, setPublishAudio] = useState(true);
  const [webinarStarted, setWebinarStarted] = useState(true);
  const [videoSource, setSource] = useState('camera');

  const currentWebinar = events?.length
    ? parseFetchedData(events.find(event => event.id === eventId))
    : {};
  const course = parseFetchedData(currentWebinar?.Course);

  const webinar = {
    title: currentWebinar?.EventName,
    description: currentWebinar?.EventDescription,
  };

  const toggleWebinar = status => () => {
    setWebinarStarted(status);
    if (!status) setPublishVideo(false);
  };

  const toggleVideo = () => setPublishVideo(!publishVideo);

  const toggleVideoSource = () => {
    if (videoSource !== 'screen') {
      setSource('screen');
    } else {
      setSource('camera');
    }
  };

  const toggleAudio = () => setPublishAudio(!publishAudio);

  if (loading) {
    return (
      <div className="sk-web-room-not-created">
        <Card classes="sk-web-room-not-created__card">
          <Loader />
        </Card>
      </div>
    );
  };

  if (!course || !currentWebinar) {
    return (
      <div className="sk-web-room-not-created">
        <Card classes="sk-web-room-not-created__card">
          No webinar found
        </Card>
      </div>
    );
  };

  console.log('webinar', currentWebinar?.Author);

  return (
    <div className="sk-pub-webinar-room">
      <header className="sk-pub-webinar-room__header">
        <WebinarContentHeader
          title={course?.title}
          toggleWebinar={toggleWebinar}
          onAir={webinarStarted}
        />
      </header>
      <div className="sk-pub-webinar-room__main">
        <WebinarRoom
          coursePhoto={course?.coursePhoto}
          sessionId={sessionId}
          token={sessionToken}
          publishVideo={publishVideo}
          videoSource={videoSource}
          publishAudio={publishAudio}
          handleVideoToggle={toggleVideo}
          handleVideoSourceToggle={toggleVideoSource}
          handleMicToggle={toggleAudio}
          participantRole={participantRole}
          webinarStarted={webinarStarted}
          toggleWebinar={toggleWebinar}
        />
      </div>

      <div className="sk-pub-webinar-room__footer">
        <WebinarInformation
          webinar={webinar}
          authorFirstName={currentWebinar?.Author?.firstName}
          authorLastName={currentWebinar?.Author?.lastName}
          authorAvatar={currentWebinar?.Author?.photo}
        />
      </div>
    </div>
  );
};

const getExpertCalendarEventsQuery = graphql(GET_EXPERT_CALENDAR_EVENT_QUERY, {
  props: ({
    data: { loading, error, expertListCalendarEvents, ...ownProps },
  }) => {
    if (error) throw new Error(error);
    return {
      loading,
      events: keysToCamel(expertListCalendarEvents),
      ...ownProps,
    }
  },
  options: () => ({
    fetchPolicy: 'network-only',
    variables: {
      input: {
        TimeFromUnixMs: new Date().getTime() - 100000000000,
        TimeToUnixMs: new Date().getTime() + 100000000000,
      },
    },
  }),
});

const enhancer = compose(
  memo,
  getExpertCalendarEventsQuery
);

export default enhancer(PublisherWebinarRoom);
