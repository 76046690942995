// Core
import React, { useState, useEffect, memo } from 'react';

// Components
import SummaryActionBlock from 'routes/Course/components/SummaryActionBlock';
import SummaryDurationBlock from 'routes/Course/components/SummaryDurationBlock';
import Text from 'components/Text';
import EditableText from 'components/EditableText';
import Duration from 'components/Duration/Duration';
import DragAndDropBurger from 'components/Icon/Svg/DragAndDropBurger';

// Other
import { getLectureDuration } from 'utils/helpers';
import { FormattedMessage } from 'react-intl';
import { messages } from 'routes/Course/components/ComplexitySelect/messages';
import { sharedMessages } from 'services/i18n/sharedMessages/messages';
import { Concrete } from 'components/Icon/color';

// Styles and Assets
import './LectureSummary.scss';

export const LectureSummary = ({
  lecture,
  orderNumber,
  lecture: { id, title, duration },
  isEditableFieldEnable,
  handleLectureDeleteClick,
  toggleEditableState,
  handleLectureUpdate,
  red = false,
  disableDeletion,
  displayLectureNameType,
}) => {
  const [lectureDuration, setLectureDuration] = useState(duration || 0);

  return (
    <>
      <div className="sk-lec-summary-component">
        <div className="sk-lec-summary-component__name">
          <div className="sk-lec-summary-component__header">
            <span className="sk-lec-summary-component__title">
              {displayLectureNameType === 'section'
                ? <FormattedMessage {...sharedMessages.section} />
                : <FormattedMessage {...sharedMessages.lecture} />} {orderNumber + 1}.
            </span>
            <EditableText
              editableClasses="sk-lec-summary-component__editable sk-subtitle"
              name={`lecture-${id}`}
              handleInputChange={handleLectureUpdate}
              inputKey="title"
              disabled={!isEditableFieldEnable}
              handleBlur={toggleEditableState}
              maxLength={50}
              html={title}
            />
          </div>
        </div>
        <div className="sk-lec-summary-component__controls">
          <div className="sk-lec-summary-component__action-block">
            <SummaryActionBlock
              disableDeletion={disableDeletion}
              lectureId={id}
              handleLectureDeleteClick={handleLectureDeleteClick}
              handleLectureEditClick={toggleEditableState}
            />
          </div>
        </div>
        <div className="sk-lec-summary-component__duration">
          <Duration duration={getLectureDuration([lecture])} withClock={false} clockProps={{ width: "20", height: "20", red: true }} />
        </div>
        <span className="sk-lec-item__burger">
          <DragAndDropBurger width={18} color={Concrete} />
        </span>
      </div>
      <div className="sk-lec-summary-component-mobile">
        <div className="sk-lec-summary-component-mobile__left">
          <div className="sk-lec-summary-component-mobile__top">
            <div className="sk-lec-summary-component-mobile__small">
              <span className="sk-lec-summary-component-mobile__title">
                {displayLectureNameType === 'section'
                  ? <FormattedMessage {...sharedMessages.section} />
                  : <FormattedMessage {...sharedMessages.lecture} />} {orderNumber + 1}.
              </span>
            </div>
            <div className="sk-lec-summary-component-mobile__small">
              <Duration duration={getLectureDuration([lecture])} withClock={false} clockProps={{ width: "20", height: "20", red: true }} />
            </div>
          </div>
          <div className="sk-lec-summary-component-mobile__description">
            <EditableText
              editableClasses="sk-lec-summary-component-mobile__editable sk-subtitle"
              name={`lecture-${id}`}
              handleInputChange={handleLectureUpdate}
              inputKey="title"
              disabled={!isEditableFieldEnable}
              handleBlur={toggleEditableState}
              maxLength={50}
              html={title}
            />
          </div>
        </div>
        <div className="sk-lec-summary-component-mobile__right">
          <SummaryActionBlock
            disableDeletion={disableDeletion}
            lectureId={id}
            handleLectureDeleteClick={handleLectureDeleteClick}
            handleLectureEditClick={toggleEditableState}
          />
        </div>
        <span className="sk-lec-item__burger">
          <DragAndDropBurger width={18} color={Concrete} />
        </span>
      </div>
    </>
  );
};

export default memo(LectureSummary);
