// Core
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose, graphql } from 'react-apollo';

// Components
import ExpansionPanel from 'components/ExpansionPanel';
import LectureSummary from 'routes/Course/components/LecturesList/LectureItem/LectureSummary';
import LectureDetails from 'routes/Course/components/LecturesList/LectureItem/LectureDetails';
import ConfirmDelete from 'routes/ExpertsCabinet/components/ConfirmDelete/ConfirmDelete';
import DragAndDropBurger from 'components/Icon/Svg/DragAndDropBurger';

// Other
import { withModal } from 'containers/ModalProvider/withModal';

// Services
import { withLectureFilesUploader } from 'routes/Course/components/LecturesList/LectureItem/HOCs/withLectureFilesUploader';

// Styles and Assets
import './LectureItem.scss';

const overrideClasses = {
  panel: {
    root: 'mui-override overwrite sk-lc-panel__panel',
  },
  summary: {
    root: 'mui-override overwrite sk-lc-panel__summary',
    content: 'mui-override overwrite sk-lc-panel__summary-content',
  },
  details: {
    root: 'mui-override overwrite sk-lc-panel__details',
  },
  iconBtn: {
    root: 'mui-override overwrite sk-lc-panel__btn',
  },
};

class LectureItem extends PureComponent {
  state = {
    editableField: '',
  };

  toggleEditableState = (key => () =>
    this.setState(({ editableField }) => ({
      editableField: editableField === key ? null : key,
    })))('lectureName');

  handleLectureUpdate = values => {
    const {
      handleLectureUpdate,
      lecture: { id },
    } = this.props;
    handleLectureUpdate(id, values);
  };

  handleLectureDelete = () => {
    const {
      handleLectureDelete,
      mody,
      lecture: { id },
      modalContext: { showModal, hideModal },
    } = this.props;
    showModal(ConfirmDelete, {
      close: hideModal,
      handleDelete: () => handleLectureDelete(id),
    });
  };

  render() {
    const { editableField, upload } = this.state;
    const {
      droppableId,
      orderNumber,
      lecture,
      lecture: { id },
      courseId,
      disabled,
      disableDeletion,
      courseMaxInterLevel,
      displayLectureNameType,
      courseFeatures,
      refetchCourse,
      handleUploadArticle,
      handleUploadVideo,
      handleUploadAudio,
      handleUploadHomework,
      handleUploadQuiz,
      handleAttachmentDelete,
      handleAttachmentUpdate
    } = this.props;

    return (
      <div>
        <ExpansionPanel
          id={id}
          ExpansionPanelSummaryComponent={
            <LectureSummary
              orderNumber={orderNumber}
              disableDeletion={disableDeletion}
              lecture={lecture}
              handleLectureDeleteClick={this.handleLectureDelete}
              handleLectureUpdate={this.handleLectureUpdate}
              isEditableFieldEnable={editableField === 'lectureName'}
              toggleEditableState={this.toggleEditableState}
              displayLectureNameType={displayLectureNameType}
            />
          }
          ExpansionDetailsComponent={
            <LectureDetails
              droppableId={droppableId}
              disableDeletion={disableDeletion}
              disabled={disabled}
              courseId={courseId}
              lecture={lecture}
              courseFeatures={courseFeatures}
              courseMaxInterLevel={courseMaxInterLevel}
              handleInputChange={this.handleLectureUpdate}
              handleUploadArticle={handleUploadArticle}
              handleUploadVideo={handleUploadVideo}
              handleUploadAudio={handleUploadAudio}
              handleUploadHomework={handleUploadHomework}
              handleUploadQuiz={handleUploadQuiz}
              handleAttachmentDelete={handleAttachmentDelete}
              refetchCourse={refetchCourse}
            />
          }
          panelClasses={overrideClasses.panel}
          panelSummaryClasses={overrideClasses.summary}
          panelDetailsClasses={overrideClasses.details}
          iconButtonClasses={overrideClasses.iconBtn}
        />
      </div>
    );
  }
}

LectureItem.propTypes = {
  modalContext: PropTypes.object,
  lecture: PropTypes.object,
  handleLectureUpdate: PropTypes.func,
  handleLectureDelete: PropTypes.func,
  orderNumber: PropTypes.number.isRequired,
  courseId: PropTypes.string,
  disabled: PropTypes.bool,
  disableDeletion: PropTypes.bool,
  courseMaxInterLevel: PropTypes.string,
};

const enhancer = compose(withModal, withLectureFilesUploader);

export default enhancer(LectureItem);
