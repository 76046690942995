import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  heading: {
    id: 'app.components.categories.heading',
    defaultMessage: 'Categories',
  },  title: {
    id: 'app.components.categories.title',
    defaultMessage: 'Alias',
  },
  localized: {
    id: 'app.components.categories.localized',
    defaultMessage: 'Title',
  },
  add: {
    id: 'app.components.categories.add',
    defaultMessage: 'Add',
  },
});
