import React from 'react';
import PropTypes from 'prop-types';
import { Grey3 } from 'components/Icon/color';

const StatusNotChecked = ({ height, width, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 20 20"
  >
    <g fill="none" fillRule="nonzero" stroke={color} transform="translate(1 1)">
      <circle cx="9" cy="9" r="9" />
      <path strokeLinecap="round" strokeLinejoin="round" d="M5 9.5h8" />
    </g>
  </svg>
);

StatusNotChecked.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  color: PropTypes.string,
};

StatusNotChecked.defaultProps = {
  height: '20',
  width: '20',
  color: Grey3,
};

export default StatusNotChecked;
