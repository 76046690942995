// Core
import React, { memo } from 'react';
import { withRouter } from 'react-router';
import { compose, graphql } from 'react-apollo';

// Components
import Loader from 'components/Loader';
import ContentHeader from 'containers/ContentHeader';
import HomeworksTable from 'routes/ExpertsCabinet/Homeworks/HomeworksTable';
import PageNotFound from 'routes/PageNotFound';

// HOC
import { withAuthenticatedUser } from 'containers/AuthenticatedUserProvider/withAuthenticatedUser';

// Other
import GET_COURSE from 'queries/Courses/getCourse.gql';
import GET_COURSE_LECTURES from 'queries/Courses/getCourseLectures.gql';

const Homeworks = ({
  course,
  lectures,
  error,
  loading,
  roles,
  userContext: {
    profile: {
      id: profileId
    }
  },
  ...props
}) => {
  if (error) throw Error(error);
  if (loading) return (<Loader />);

  const {
    title,
    id,
    author: {
      id: courseAuthorId
    }
  } = course;

  if (roles !== 'admin' && courseAuthorId !== profileId) {
    return (<PageNotFound />);
  }

  const hasCourseLectures = lectures && lectures !== null && lectures.length > 0;

  return (
    <div className="sk-exp-cab-homeworks">
      <ContentHeader breadcrumbs={`${title} / Homeworks`} />
      {hasCourseLectures
        ? <HomeworksTable
          displayLectureNameType={course?.display_lecture_name_type || 'lecture'}
          courseId={id}
          courseLectures={lectures}
          />
        : <div style={{display: 'flex', justifyContent: 'center' }}>This course has no lectures</div> }
    </div>
  );
}

const getCourseQuery = graphql(GET_COURSE, {
  props: ({ data: { getCourse, error, ...ownProps } }) => ({
    course: getCourse,
    error,
    ...ownProps,
  }),
  options: ({
    match: {
      params: { id },
    },
  }) => ({
    variables: { input: { id } },
    fetchPolicy: 'network-only',
  }),
});

const getCourseLecturesQuery = graphql(GET_COURSE_LECTURES, {
  skip: ({ course, match: { params: { id } }}) => !course?.id || !id,
  props: ({ data: { getCourse, error, loading, ...ownProps } }) => {
    let lectures = [];

    if (!error && !loading) lectures = getCourse?.lectures;

    return {
      lectures,
      loading,
      error,
      ...ownProps,
    };
  },

  options: ({
    match: {
      params: { id },
    },
  }) => ({
    pollInterval: 600000,
    fetchPolicy: 'network-only',
    variables: { input: { id } },
  }),
});

const enhancer = compose(
  withRouter,
  getCourseQuery,
  getCourseLecturesQuery,
  withAuthenticatedUser
);

export default enhancer(Homeworks);
