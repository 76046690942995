// Core
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Components
import ChevronLeft from 'components/Icon/Svg/ChevronLeft';
import { PurpleHeart, White } from 'components/Icon/color';
import withAction from 'components/Icon/withAction';
import RoundIcon from 'components/Icon/RoundIcon';

const PrevButton = ({ handleClick, label, action, withoutSeparator }) => (
  <button
    className={classNames('sk-content-nav_button', {
      'sk-content-nav_button-prev_withSeparator': !withoutSeparator,
    })}
    type="button"
    onClick={handleClick}
  >
    <span
      className={classNames('sk-content-nav_icon sk-content-nav_icon__left', {
        'sk-content-nav_icon_withSeparator': !withoutSeparator,
      })}
    >
      {!label ? (
        <RoundIcon
          type="nav"
          icon={<ChevronLeft color={action ? White : PurpleHeart} />}
          chevron
          expanded
        />
      ) : (
        <ChevronLeft color={PurpleHeart} active={action} />
      )}
    </span>
    <span className="sk-content-nav_label">{label}</span>
  </button>
);

PrevButton.propTypes = {
  handleClick: PropTypes.func,
  label: PropTypes.string,
  action: PropTypes.bool,
  withoutSeparator: PropTypes.bool,
};

export default withAction(PrevButton);
