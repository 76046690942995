import React from 'react';
import PropTypes from 'prop-types';
import { White } from 'components/Icon/color';

import './Burger.scss';

const Burger = ({ height=14, width=25, color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 25 14" className="sk-burgerIcon">
    <rect y="12" width="25" height="2" rx="1" />
    <rect y="6" width="25" height="2" rx="1" />
    <rect width="25" height="2" rx="1" />
  </svg>
);


export default Burger;
