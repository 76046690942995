// Core
import React from 'react';
import { compose, graphql } from 'react-apollo';

// Components
import Step1 from 'routes/StudentsCabinet/components/StudentHomework/Steps/Step1';
import Step2 from 'routes/StudentsCabinet/components/StudentHomework/Steps/Step2';
import Step3 from 'routes/StudentsCabinet/components/StudentHomework/Steps/Step3';
import Step4 from 'routes/StudentsCabinet/components/StudentHomework/Steps/Step4';

// Other
import { isBlank } from 'utils/helpers/index';

// Styles
import './ActiveStep.scss';

const ActiveStep = ({
  mark,
  activeStep,
  task,
  homeworkData,
  handleHomeworkAnswerUpload,
  handleAttachmentDelete,
  handleUpdateHomeworkStep,
  ...props
}) => {

  switch (activeStep) {
    case 2:
      return <Step2
        answer={homeworkData?.answer}
        handleAnswerFilePick={handleHomeworkAnswerUpload}
        handleHomeworkAnswerDelete={handleAttachmentDelete}
        handleUpdateHomeworkStep={handleUpdateHomeworkStep}
        {...props}
      />;
    case 3:
      return <Step3 {...props} />;
    case 4:
      return <Step4 filepath={homeworkData?.review?.attachment?.filepath} mark={mark} {...props} />;
    default:
      return <Step1
        filepath={isBlank(task) ? '' : task?.filepath}
        handleUpdateHomeworkStep={handleUpdateHomeworkStep}
        {...props}
      />;
  }
};

export default ActiveStep;
