// Core
import React, { useState, useEffect } from 'react';
import { compose, graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';

// Components
import Loader from 'components/Loader';
import Text from 'components/Text';
import FilterWithCheckbox from 'components/FilterWithCheckbox';
import ToppingCard from 'routes/components/ToppingCard';
import FlatDropDown from 'components/DropDown/FlatDropDown';
import FiltersIcon from 'components/Icon/Svg/FiltersIcon'
import ToppingTypeFilter from 'routes/components/Filters/ToppingTypeFilter';
import ContentHeader from 'containers/ContentHeader';
import Link from 'components/Link';

// Other
import { keysToCamel, parseFetchedData, isBlank, isArraysEqual } from 'utils/helpers';
import { withLoader } from 'containers/HOCs/withLoader';
import { messages } from 'routes/StudentsCabinet/ToppingDetails/messages.js';

// GraphQL
import GET_COURSE_LIST from 'queries/Courses/studentListCourses.gql';

// Styles
import './StudentToppings.scss';

const COMPONENT_PREFIX = 'sk-student-toppings';
const OPTIONS = [{
  id: 'id',
  label: 'LABEL'
}, {
  id: 'id1',
  label: 'LABEL2'
}];
const DEFAULT_LIST_TOPPINGS_QUERY = { filter: [{ key: 'type', values: ['topping'], }] };

const StudentToppings = ({
  toppings,
  query,
  refetchToppings,
  history,
  match: { params, url },
  intl: { formatMessage }
}) => {
  const searchToppings = ((query) => async ({ key, values }) => {
    const { filter } = query;

    let newFilters = !isBlank(filter) ? [...filter] : [];
    let oldFilter = newFilters.find(filter => filter.key === key);

    const isNewFilter = !(!!oldFilter);
    const isFilterChanged = oldFilter && values && !isArraysEqual(oldFilter.values, values);

    if (isNewFilter && values?.length > 0) {
      const newFilter = { key, values };
      newFilters.push(newFilter);
    } else if (isFilterChanged && values?.length > 0) {
      oldFilter.values = values;
    } else if (isFilterChanged && values?.length === 0) {
      oldFilter.values = [];
      newFilters = DEFAULT_LIST_TOPPINGS_QUERY.filter;
    };

    await refetchToppings({ input: { query: { filter: newFilters } }});
  })(query);

  return(
    <div className={COMPONENT_PREFIX}>
      <div className={`${COMPONENT_PREFIX}_top`}>
        <ContentHeader way={(
          <>
            <Link to="/student-cabinet">{formatMessage(messages.studentsCabinet)}</Link>
            <span>  / {formatMessage(messages.toppings)}</span>
          </>
        )} />
        <div className={`${COMPONENT_PREFIX}_filter`}>
          <ToppingTypeFilter filter={query.filter || []} searchToppings={searchToppings} />
        </div>
      </div>
      <div className={`${COMPONENT_PREFIX}_list`}>
        {toppings?.map(topping => (
          <ToppingCard
            topping={topping}
            toppingPageLink={`${url}/${topping.mappingId}`}
            isExpert={false}
          />
        ))}
      </div>
    </div>
  )
};

const getListCoursesQuery = graphql(GET_COURSE_LIST, {
  props: ({ data: { listStudentCoursesExtra, variables, refetch, ...ownProps } }) => {
    return {
      listStudentCoursesExtra,
      query: variables?.input?.query || {},
      refetchToppings: refetch,
      ...ownProps,
    }
  },
  options: () => ({
    variables: { input: { query: DEFAULT_LIST_TOPPINGS_QUERY } },
  }),
});

const getPurchasedToppings = (Component) => {
  return ({ listStudentCoursesExtra, ...props }) => {
    const { courses, purchased_courses } = listStudentCoursesExtra;
    const purchasedCourses = purchased_courses
      .filter(purchasedCourse => {
        const courseData = courses.find(course => course.id === purchasedCourse.course_id);
        purchasedCourse.title = courseData?.title || 'no title';
        purchasedCourse.variants = courseData?.variants || {};
        purchasedCourse.coursePhoto = courseData?.course_photo || {};
        return !!courseData && courseData?.type == 'topping';
      })
      .map(course => parseFetchedData(course));
    return <Component toppings={purchasedCourses} {...props} />
  }
}

const enhancer = compose(
  injectIntl,
  withRouter,
  getListCoursesQuery,
  withLoader,
  getPurchasedToppings
);

export default enhancer(StudentToppings);
