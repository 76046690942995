import { defineMessages } from "react-intl";

export const messages = defineMessages({
  breadCrumbs: {
    "id": "app.routes.ExpertsCabinet.ToppingEdit.breadCrumbs",
    "defaultMessage": "Expert's Cabinet/Create Master Class/Podcast"
  },
  createToppingModalTitle: {
    "id": "app.routes.ExpertsCabinet.ToppingEdit.createToppingModalTitle",
    "defaultMessage": "What would you like to create?",
  },
  toppingTitleLabel: {
    "id": "app.routes.ExpertsCabinet.ToppingEdit.toppingTitleLabel",
    "defaultMessage": "Name",
  },
  masterClassOption: {
    "id": "app.routes.ExpertsCabinet.ToppingEdit.masterClassOption",
    "defaultMessage": "Master class",
  },
  lectureOption: {
    "id": "app.routes.ExpertsCabinet.ToppingEdit.lectureOption",
    "defaultMessage": "Lecture",
  },
  podcastOption: {
    "id": "app.routes.ExpertsCabinet.ToppingEdit.podcastOption",
    "defaultMessage": "Podcast",
  },
  statusBarTitle: {
    "id": "app.routes.ExpertsCabinet.ToppingEdit.statusBarTitle",
    "defaultMessage": "Status",
  },
  draftLabel: {
    "id": "app.routes.ExpertsCabinet.ToppingEdit.draftLabel",
    "defaultMessage": "Creation",
  },
  inReviewLabel: {
    "id": "app.routes.ExpertsCabinet.ToppingEdit.inReviewLabel",
    "defaultMessage": "In review",
  },
  approvedLabel: {
    "id": "app.routes.ExpertsCabinet.ToppingEdit.approvedLabel",
    "defaultMessage": "Approved",
  },
  rejectedLabel: {
    "id": "app.routes.ExpertsCabinet.ToppingEdit.rejectedLabel",
    "defaultMessage": "Rejected",
  },
  activedLabel: {
    "id": "app.routes.ExpertsCabinet.ToppingEdit.activedLabel",
    "defaultMessage": "Activated",
  },
  deactivatedLabel: {
    "id": "app.routes.ExpertsCabinet.ToppingEdit.deactivatedLabel",
    "defaultMessage": "Deactivated",
  },
  showConvertedVariantsButton: {
    "id": "app.routes.ExpertsCabinet.ToppingEdit.showConvertedVariantsButton",
    "defaultMessage": "Prices in other currencies",
  },
  toppingDescriptionLabel: {
    "id": "app.routes.ExpertsCabinet.ToppingEdit.toppingDescriptionLabel",
    "defaultMessage": "Description",
  },
  toppingLanguageLabel: {
    "id": "app.routes.ExpertsCabinet.ToppingEdit.toppingLanguageLabel",
    "defaultMessage": "Language",
  },
  toppingPriceLabel: {
    "id": "app.routes.ExpertsCabinet.ToppingEdit.toppingPriceLabel",
    "defaultMessage": "Price",
  },
  toppingPreviewLabel: {
    "id": "app.routes.ExpertsCabinet.ToppingEdit.toppingPreviewLabel",
    "defaultMessage": "Preview",
  },
  toppingLectureVideoLabel: {
    "id": "app.routes.ExpertsCabinet.ToppingEdit.toppingLectureVideoLabel",
    "defaultMessage": "Video",
  },
  toppingLectureQuizLabel: {
    "id": "app.routes.ExpertsCabinet.ToppingEdit.toppingLectureQuizLabel",
    "defaultMessage": "Quiz",
  },
  uploadToppingPhoto: {
    "id": "app.routes.ExpertsCabinet.ToppingEdit.uploadToppingPhoto",
    "defaultMessage": "Upload topping preview photo",
  },
  toppingPhotoRequirement: {
    "id": "app.routes.ExpertsCabinet.ToppingEdit.toppingPhotoRequirement",
    "defaultMessage": "Here is topping preview photo requirements",
  },
  addQuiz: {
    "id": "app.routes.ExpertsCabinet.ToppingEdit.addQuiz",
    "defaultMessage": "Add quiz",
  },
  quizInfoUntitledTitle: {
    id: 'app.routes.ExpertsCabinet.QuizEdit.quizInfoUntitledTitle',
    defaultMessage: 'Title',
  },
  recomendationFromAdminLabel: {
    "id": "app.routes.ExpertsCabinet.ToppingEdit.recomendationFromAdminLabel",
    "defaultMessage": "Recommendation from AVDO",
  },
  materialType: {
    "id": "app.routes.ExpertsCabinet.ToppingEdit.materialType",
    "defaultMessage": "Material type",
  },
});
