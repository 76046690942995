// Core
import React, { useState, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';

// Components
import SimpleSelect from 'components/Select/SimpleSelect';
import FilterDropDown from 'components/FilterDropDown';
import AutoComplete from 'components/Select/AutoComplete/AutoComplete';

// GraphQL
import LIST_COURSES from 'services/graphql/queries/AdminPanel/listCoursesForSelector.gql';
import LIST_CATEGORIES from 'services/graphql/queries/Courses/listCategories.gql';

// Other
import { Grey } from 'components/Icon/color';
import { getObjFromArray } from 'utils/helpers/index';

// Styles and Assets
import * as materialUISelectInlineStyles from './Selector.style';
import './ReferenceSelect.scss';

const ReferenceTypeOptions = [
  { value: 'courses', label: 'For any course', disabled: true },
  { value: 'course', label: 'For specific course' },
  { value: 'category', label: 'For courses from category', disabled: true },
  { value: 'subcategory', label: 'For courses from subcategory', disabled: true },
];
const ReferenceIdLabels = {
  course:  'Course',
  category: 'Category',
  subcategory: 'Subcategory'
}

class ReferenceSelect extends PureComponent {
  handleOptionChange = type => value => this.props.handleInputChange(type)(value);

  handleReferenceTypeChange = this.handleOptionChange('reference');
  handleReferenceIdChange = this.handleOptionChange('referenceId');

  render() {
    const { reference, referenceId, errors, touched, referenceIdOptions, handleInputChange, handleInputBlur } = this.props;
    const isReferenceIdRequired = ['course', 'category', 'subcategory']
      .includes(reference);

    return (
      <div className="sk-discount-reference">
        <SimpleSelect
          helperText={touched.reference ? errors.reference : ''}
          error={touched.reference && Boolean(errors.reference)}
          name='reference'
          label='For such courses'
          inlineStyles={materialUISelectInlineStyles}
          value={reference}
          valueKey="value"
          labelKey="label"
          options={ReferenceTypeOptions}
          handleChange={(nextType) => this.handleReferenceTypeChange(nextType)}
          handleBlur={handleInputBlur('reference')}
        />
        {isReferenceIdRequired && (
          <SimpleSelect
            helperText={touched.referenceId ? errors.referenceId : ''}
            error={touched.referenceId && Boolean(errors.referenceId)}
            name='referenceId'
            label={ReferenceIdLabels[reference]}
            inlineStyles={materialUISelectInlineStyles}
            value={referenceId}
            valueKey="value"
            labelKey="label"
            options={referenceIdOptions}
            handleChange={(nextType) => this.handleReferenceIdChange(nextType)}
            handleBlur={handleInputBlur('referenceId')}
          />
        )}
      </div>
    );
  }
}

ReferenceSelect.propTypes = {
};

const listCourses = graphql(LIST_COURSES, {
  skip: ({ reference }) => !(reference === 'course'),
  props: ({ data: { error, loading, adminListCourses, refetch, ...ownProps } }) => {

    if (error) throw Error('Something went wrong, while receiving discount');
    if (loading) return { loading, options: [], };

    const referenceIdOptions = adminListCourses.items.map(({ id, title }) => ({ value: id, label: title }));

    return {
      error,
      loading,
      referenceIdOptions,
      ...ownProps,
    }
  },
  options: () => ({
    fetchPolicy: 'cache-and-network',
    variables: { input: { limit: 500, skip: 0,  query: { filter: [{key: "status", values: ["ACTIVE"]}, {key: "type", values: ["course"]}] } }},
  }),
});

// const listCategories = graphql(LIST_CATEGORIES, {
//   skip: ({ referenceType }) => !(referenceType === 'forAnyCourse'),
//   props: ({ data: { error, loading, listCategories, refetch, ...ownProps } }) => {
//
//     if (error) throw Error('Something went wrong, while receiving discount');
//     if (loading) return { loading, options: [], };
//
//     const referenceIdOptions = listCategories.items.map({ id, title } => ({ value: id, label: title }));
//
//     return {
//       error,
//       loading,
//       referenceIdOptions,
//       ...ownProps,
//     }
//   },
//   options: ({ id }) => ({
//     fetchPolicy: 'cache-and-network',
//   }),
// }

const enhancer = compose(
  listCourses
)

export default enhancer(ReferenceSelect);
