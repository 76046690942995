// Core
import React, { PureComponent, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { compose, graphql } from "react-apollo";
import { FormattedMessage } from "react-intl";
import { injectIntl } from "react-intl";

// Components
import ContentHeader from "containers/ContentHeader";
import Text from "components/Text";
import Loader from "components/Loader";
import Pagination from "components/Pagination";
import Button from "components/Button";
import CompaniesListTable from "routes/AdminPanel/Companies/CompaniesList/CompaniesListTable/";

// GraphQl
import LIST_COMPANIES_QUERY from "queries/AdminPanel/listCompanies.gql";

// Other
import { Grey3 } from "components/Icon/color";
import { messages } from "routes/AdminPanel/messages";
import { keysToCamel, isEmptyObject, isArraysEqual } from "utils/helpers/index";

// Styles and Assets
import "./CompaniesList.scss";

const COMPANIES_PAGE_COUNTER = 20;

class CompaniesList extends PureComponent {
  addCompany = (id) => {
    const { history } = this.props;
    history.push(`/admin/panel/companies/create`);
  };

  render() {
    const { error, fetchError, loading, companies = [], ...props } = this.props;

    // if (loading) return (<Loader center />)

    return (
      <Fragment>
        <div className="sk-admin-companies-list__header">
          <div className="sk-admin-companies-list__header__sidebarButton">
            <ContentHeader />
          </div>
          <Button
            className="sk-admin-companies-list__header__addPromocodeButton"
            onClick={this.addCompany}
          >
            Add Company
          </Button>
        </div>
        <CompaniesListTable
          error={error}
          loading={loading}
          companies={companies}
          currentPage={1}
        />
      </Fragment>
    );
  }
}

const listCompaniesQuery = graphql(LIST_COMPANIES_QUERY, {
  props: ({
    data: { loading, error: fetchError, companies, ...ownProps },
  }) => ({
    loading,
    companies,
    fetchError,
    ...ownProps,
  }),

  options: () => ({
    fetchPolicy: "cache-and-network",
  }),
});

const enhancer = compose(injectIntl, withRouter, listCompaniesQuery);

export default enhancer(CompaniesList);
