// Core
import React, { memo } from 'react';

// Components
import CompletedCourses from 'containers/Sidebar/SidebarContent/StudentCabinetSidebar/CompletedCourses';
import StudentActiveCoursesList from 'containers/Sidebar/SidebarContent/StudentCabinetSidebar/StudentActiveCoursesList';
import StudentWebinars from 'containers/Sidebar/SidebarContent/StudentCabinetSidebar/StudentWebinars';
import StudentToppings from 'containers/Sidebar/SidebarContent/StudentCabinetSidebar/StudentToppings';

export const CourseMaterialsContent = ({
  loading,
  openedItem,
  openedSubItem,
  activeCourses,
  toppings,
  handleListToggle,
  handleSubListToggle,
}) => (
  <div className="sk-sidebar-block">
    <StudentActiveCoursesList
      openedSubItemId={openedSubItem}
      loading={loading}
      list={activeCourses}
      open={openedItem === 'active'}
      handleClick={handleListToggle('active')}
      handleSubMenuTitleClick={handleSubListToggle}
    />
    <StudentWebinars />
    <StudentToppings
      openedSubItemId={openedSubItem}
      loading={loading}
      list={toppings}
      open={openedItem === 'topping'}
      handleClick={handleListToggle('topping')}
      handleSubMenuTitleClick={handleSubListToggle}
    />
  </div>
);

export default memo(CourseMaterialsContent);
