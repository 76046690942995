import React from 'react';
import { Map, merge } from 'immutable';
import { DefaultDraftBlockRenderMap } from 'draft-js';

// Components
import AtomicContentBlock from 'components/DraftEditor/ContentBlocks/AtomicContentBlock';
import LinkInlineToolbarComponent from 'components/DraftEditor/TollbarInline/components/LinkComponent';
import VideoInlineToolbarComponent from 'components/DraftEditor/TollbarInline/components/VideoComponent';

// Other
import { VIDEO, LINK, COLOR, SUGGESTIONS } from 'components/DraftEditor/enums';
import { isNull } from 'utils/helpers/index';

const addedRenderMap = new Map({
  left: {
    element: 'div',
  },
  center: {
    element: 'div',
  },
  right: {
    element: 'div',
  },
  justify: {
    element: 'div',
  },
});
export const extendedRenderMap = merge(
  DefaultDraftBlockRenderMap,
  addedRenderMap
);

// Custom overrides for "code" style.
export const styleMap = {
  CODE: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    fontFamily: 'Roboto, monospace',
    fontWeight: 'normal',
    fontStyle: 'normal',
    padding: 2,
  },
  CROSS: {
    textDecoration: 'line-through',
  },
};

export const getBlockStyle = block => {
  switch (block.getType()) {
    case 'code-block':
      return 'sk-editor-block-code-block';
    case 'blockquote':
      return 'sk-editor-block-blockquote';
    case 'left':
      return 'sk-editor-block-alignment-left';
    case 'center':
      return 'sk-editor-block-alignment-center';
    case 'right':
      return 'sk-editor-block-alignment-right';
    case 'justify':
      return 'sk-editor-block-alignment-justify';
    default:
      return null;
  }
};

export const blockRendererFn = block => {
  switch (block.getType()) {
    case 'atomic':
      return {
        component: AtomicContentBlock,
        editable: false,
      };
    // case 'unstyled': return {
    //   component: ({ children, block }) => {
    //
    //     return (<div> {block.getText()}</div>)
    //   },
    //   editable: true
    // }
    default:
      return null;
  }
};

export const findLinkEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity();
    return (
      !isNull(entityKey) && contentState.getEntity(entityKey).getType() === LINK
    );
  }, callback);
};

export const findColorEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity();
    return (
      !isNull(entityKey) &&
      contentState.getEntity(entityKey).getType() === COLOR
    );
  }, callback);
};

export const INLINE_TOOLBAR_COMPONENTS = {
  [SUGGESTIONS]: {
    component: LinkInlineToolbarComponent,
    submitHandler: () => {},
  },
  [LINK]: {
    component: LinkInlineToolbarComponent,
    submitHandler: () => {},
  },
  [VIDEO]: {
    component: VideoInlineToolbarComponent,
    submitHandler: () => {},
  },
};
