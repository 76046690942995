// Core
import React, { useCallback } from 'react';
import { compose } from 'react-apollo';
import { injectIntl, FormattedMessage } from 'react-intl';

// Components
import Button from 'components/Button';
import Link from 'components/Link';

// Styles and Assets
import './AuthBlock.scss';

// Other
import { messages } from './messages';
import { withSidebar } from 'containers/SidebarProvider/withSidebar';

const AuthBlock = ({ sidebarContext: { hideSidebar } }) => {
  const handleClose = useCallback(() => {
    hideSidebar();
  }, []);
  return (
    <div className="sk-auth-block ">
      <Link classes="sk-auth-block__item" to="/auth/signin">
        <Button size="small" onClick={handleClose}>
          <FormattedMessage {...messages.logIn} />
        </Button>
      </Link>
      <Link classes="sk-auth-block__item" to="/auth/signUp">
        <Button size="small" onClick={handleClose}>
          <FormattedMessage {...messages.signUp} />
        </Button>
      </Link>
    </div>
  );
};

const enhancer = compose(
  injectIntl,
  withSidebar
)

export default enhancer(AuthBlock);
