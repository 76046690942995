// Core
import React, { PureComponent } from 'react';
import { Formik } from 'formik';
import { injectIntl, FormattedMessage } from 'react-intl';
import { graphql, compose } from 'react-apollo';
import { withRouter } from 'react-router-dom';

// Components
import Card from 'components/Card';
import Heading from 'components/Heading';
import CourseCreationForm from 'routes/Course/CourseCreation/CourseCreationForm/';

// Other
import { getYupSchema } from 'services/yup';
import CREATE_COURSE from 'mutations/Courses/createCourse.gql';
import { keysToSnake } from 'utils/helpers';
import { withAuthenticatedUser } from "containers/AuthenticatedUserProvider/withAuthenticatedUser";
import { messages } from './messages';

// Styles and Assets
import './CourseCreation.scss';

const FIELDS = {
  title: '',
  category: '',
  subcategory: '',
};

class CourseCreation extends PureComponent {
  handleSubmit = async values => {
    const { createCourse, history, userContext: { profile: { org } } } = this.props;
    const valuesToUpdate = keysToSnake(values);
    if (org) {
      valuesToUpdate.company_id = org;
    }
    try {
      const {
        data: {
          createCourse: { id },
        },
      } = await createCourse(valuesToUpdate);

      history.push(`/experts-cabinet/course/${id}/edit`);
    } catch (error) {
      throw Error(error);
    }
  };

  render() {
    return (
      <div className="sk-course-creation">
        <div className="grid sk-course-creation__header">
          <div
            className="grid_item-xs-offset-1-24 grid_item-sm-offset-2-24
           grid_item-md-offset-5-24 grid_item-lg-offset-7-24"
          />
          <Heading
            variant="h1"
            classes="grid_item-xs-22-24 grid_item-sm-20-24 grid_item-md-14-24 grid_item-lg-10-24"
          >
            <FormattedMessage {...messages.courseCreationPageTitle} />
          </Heading>
          <div
            className="grid_item-xs-offset-1-24 grid_item-sm-offset-2-24
           grid_item-md-offset-5-24 grid_item-lg-offset-7-24"
          />
        </div>
        <div className="sk-course-creation__inner sk-background-spot__secondary">
          <div className="sk-course-creation__main grid">
            <div
              className="grid_item-xs-offset-1-24 grid_item-sm-offset-2-24
            grid_item-md-offset-5-24 grid_item-lg-offset-7-24 "
            />
            <Card
              classes="sk-course-creation__card grid_item-xs-22-24
             grid_item-sm-20-24 grid_item-md-14-24 grid_item-lg-10-24"
            >
              <Formik
                onSubmit={async (values, formikBag) => {
                  await this.handleSubmit(values, formikBag);
                }}
                render={props => <CourseCreationForm {...props} />}
                initialValues={FIELDS}
                validationSchema={getYupSchema('courseCreationValidationSchema')}
                validateOnChange={false}
                validateOnBlur
              />
            </Card>
            <div
              className="grid_item-xs-offset-1-24  grid_item-sm-offset-2-24
            grid_item-md-offset-5-24 grid_item-lg-offset-7-24"
            />
          </div>
        </div>
      </div>
    );
  }
}

CourseCreation.propTypes = {};

const createCourseMutation = graphql(CREATE_COURSE, {
  props: ({ mutate }) => ({
    createCourse: input => mutate({ variables: { input } }),
  }),
});

const enhancer = compose(
  injectIntl,
  withRouter,
  createCourseMutation,
  withAuthenticatedUser,
);

export default enhancer(CourseCreation);
