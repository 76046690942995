import React, { memo } from 'react';
import { compose } from 'react-apollo';

const Icon = ({ ...props }) => {
	return (
		<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect x="3.44141" y="4.17627" width="18.1176" height="15.1765" rx="3.5" stroke="#610FB6" />
			<path
				d="M11.1749 8.18535L15.7892 11.2136C15.956 11.3578 15.956 11.5741 15.7892 11.7183L11.3417 14.6023C11.0915 14.8186 10.6467 14.6744 10.6189 14.3619L10.4521 8.44972C10.4521 8.13728 10.8969 7.96905 11.1749 8.18535Z"
				stroke="#610FB6"
				stroke-miterlimit="10"
			/>
		</svg>
	);
};

const enhancer = compose(memo);

export default enhancer(Icon);
