import React from 'react';

import './index.scss';

const IconOnHover = () => (
  <svg class='filled' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.47219 15.4917C3.08791 15.8884 3.09796 16.5215 3.49463 16.9057C3.89131 17.29 4.52439 17.28 4.90867 16.8833L3.47219 15.4917ZM16.7153 4.6958C17.0996 4.29912 17.0895 3.66604 16.6929 3.28176C16.2962 2.89748 15.6631 2.90753 15.2788 3.3042L16.7153 4.6958ZM4.90867 16.8833L16.7153 4.6958L15.2788 3.3042L3.47219 15.4917L4.90867 16.8833Z" fill="#30d5c8"/>
    <path d="M4.6958 3.47219C4.29912 3.08791 3.66604 3.09796 3.28176 3.49463C2.89748 3.89131 2.90753 4.52439 3.3042 4.90867L4.6958 3.47219ZM15.4917 16.7153C15.8884 17.0996 16.5215 17.0895 16.9057 16.6929C17.29 16.2962 17.28 15.6631 16.8833 15.2788L15.4917 16.7153ZM3.3042 4.90867L15.4917 16.7153L16.8833 15.2788L4.6958 3.47219L3.3042 4.90867Z" fill="#30d5c8"/>
  </svg>
);

const IconDefault = () => (
  <svg class='outlined' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.47219 15.4917C3.08791 15.8884 3.09796 16.5215 3.49463 16.9057C3.89131 17.29 4.52439 17.28 4.90867 16.8833L3.47219 15.4917ZM16.7153 4.6958C17.0996 4.29912 17.0895 3.66604 16.6929 3.28176C16.2962 2.89748 15.6631 2.90753 15.2788 3.3042L16.7153 4.6958ZM4.90867 16.8833L16.7153 4.6958L15.2788 3.3042L3.47219 15.4917L4.90867 16.8833Z" fill="#30d5c8"/>
    <path d="M4.6958 3.47219C4.29912 3.08791 3.66604 3.09796 3.28176 3.49463C2.89748 3.89131 2.90753 4.52439 3.3042 4.90867L4.6958 3.47219ZM15.4917 16.7153C15.8884 17.0996 16.5215 17.0895 16.9057 16.6929C17.29 16.2962 17.28 15.6631 16.8833 15.2788L15.4917 16.7153ZM3.3042 4.90867L15.4917 16.7153L16.8833 15.2788L4.6958 3.47219L3.3042 4.90867Z" fill="#30d5c8"/>
  </svg>
);

const Icon = () => {
  return (
    <div className="quiz-icon">
      <IconDefault />
      <IconOnHover />
    </div>
  );
};

export default Icon;
