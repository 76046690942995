import { updateTitle, updatePrice } from 'routes/AdminPanel/PlanEdit/helpers.js';

export const MAPPING_INPUT = {
  title: ({ ru, en }) => ({ titleRU: ru, titleEN: en }),
  price: (prices) => ({ commissionPrice: prices.find(price => price?.type == 'commission')?.amount || '' }),
};

export const MAPPING_OUTPUT = {
  titleRU: updateTitle('ru'),
  titleEN: updateTitle('en'),
  commissionPrice: updatePrice('commission'),
};
