// Core
import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

// Components
import Collapse from "@material-ui/core/Collapse";
import SimpleSidebarMenuItem from "containers/Sidebar/SidebarContent/SimpleSidebarMenu/SimpleSidebarMenuItem";
import SimpleSidebarSubMenu from "containers/Sidebar/SidebarContent/SimpleSidebarMenu";

// Styles and Assets
import "./SimpleSidebarMenu.scss";

const overrideClasses = {
  collapse: {
    container: "mui-override sk-simple-sidebar-menu__subMenu",
  },
};

class SimpleSidebarMenu extends PureComponent {
  renderSubItems = (subItemsList, isSubMenuOpen, handleSubMenuTitleClick) => (
    <SimpleSidebarSubMenu
      menuItems={subItemsList}
      subMenu
      isSubMenuOpen={isSubMenuOpen}
      handleSubMenuTitleClick={handleSubMenuTitleClick}
    />
  );

  renderMenuItem = ({ id, path, label, subItems, icon, handleCustomClick }) => {
    const {
      subMenu,
      withSubMenu,
      handleSubMenuTitleClick,
      openedSubItemId,
      openedItem,
      darkSubmenu,
    } = this.props;

    const isSubMenuOpen =
      openedItem === id ||
      openedSubItemId === id ||
      (id.includes(openedSubItemId) && !!openedSubItemId);

    return (
      <Fragment>
        <SimpleSidebarMenuItem
          path={path}
          label={label}
          subMenu={subMenu}
          withSubMenu={withSubMenu}
          darkSubmenu={darkSubmenu}
          icon={icon}
          id={id}
          isSubMenuOpen={isSubMenuOpen}
          handleSubMenuTitleClick={handleCustomClick || handleSubMenuTitleClick}
        />
        {subItems?.length > 0 && (
          <Collapse
            in={isSubMenuOpen}
            timeout="auto"
            unmountOnExit
            classes={overrideClasses.collapse}
          >
            {this.renderSubItems(
              subItems,
              isSubMenuOpen,
              handleSubMenuTitleClick
            )}
          </Collapse>
        )}
      </Fragment>
    );
  };

  render() {
    const {
      menuItems,
      role,
      subMenu,
      withSubMenu,
      darkSubmenu,
      isSubMenuOpen,
    } = this.props;

    return (
      <ul
        className={classNames("sk-simple-sidebar-menu", {
          "sk-simple-sidebar-menu_sub": subMenu,
          "sk-simple-sidebar-menu_withSubMenu": withSubMenu,
          "sk-simple-sidebar-menu_darkSubmenu": darkSubmenu,
          "sk-simple-sidebar-menu_opened": isSubMenuOpen,
        })}
      >
        {menuItems?.map((item) => {
          // Check if `item.role` is defined and whether it includes the current `role`
          if (item.roles && !item.roles.includes(role)) {
            return null;
          }
          // Check if the item is disabled
          return item.disabled ? null : (
            <div key={item.id}>{this.renderMenuItem(item)}</div>
          );
        })}
      </ul>
    );
  }
}

SimpleSidebarMenu.defaultProps = {
  withSubMenu: false,
};

SimpleSidebarMenu.propTypes = {
  menuItems: PropTypes.array,
  subMenu: PropTypes.bool,
  withSubMenu: PropTypes.bool,
  handleSubMenuTitleClick: PropTypes.func,
  openedSubItemId: PropTypes.string,
  isSubMenuOpen: PropTypes.bool,
  darkSubmenu: PropTypes.bool,
  openedItem: PropTypes.string,
};

export default SimpleSidebarMenu;
