// Core
import React, { useState, useEffect, useCallback } from 'react';
import { injectIntl } from 'react-intl';

// Components
import Text from 'components/Text';
import RadioButton from 'components/RadioButton';
import Checkbox from 'components/Checkbox';
import UploadedImage from 'components/UploadedImage';

// Icons
import CorrectAnswerIcon from 'components/Icon/SvgCss/Quiz/CorrectAnswer';
import WrongAnswerIcon from 'components/Icon/SvgCss/Quiz/WrongAnswer';

// Other
import { withFormattedUntitled } from 'routes/ExpertsCabinet/QuizEdit/HOCs/withFormattedUntitled.js';
import { isTrue, isBlank, isEmptyArray } from 'utils/helpers';
import { messages } from 'routes/ExpertsCabinet/QuizEdit/messages';

// Styles and Assets
import './index.scss';

const QuizPreviewAnswer = ({
  isQuizCompleted,
  isSelected,
  questionId,
  answer,
  multiselect,
  setAnswer,
  formatUntitled,
}) => {
  const handleChooseAnswer = () =>
    setAnswer({ questionId, answerId: answer?.id, multiselect: isTrue(multiselect) });


  return (
    <div className='sk-quiz-preview-answer'>
      <div className='sk-quiz-preview-answer__general-info'>
        {isTrue(multiselect) ? (
          <Checkbox
            name='answer'
            checked={isSelected}
            handleChange={handleChooseAnswer}
          />
        ) : (
          <RadioButton
            name='answer'
            checked={isSelected}
            handleChange={handleChooseAnswer}
            classAdditional={`mui-override`}
            iconProps={{ width: 18, height: 18 }}
          />
        )}
        <Text>{formatUntitled(answer?.title, messages.quizAnswerUntitledTitle)}</Text>
        {(isQuizCompleted && isTrue(answer.correct)) && (<CorrectAnswerIcon />)}
        {(isQuizCompleted && !isTrue(answer.correct) && isSelected) && (<WrongAnswerIcon />)}
      </div>
      {(!isBlank(answer?.materials) && !isEmptyArray(answer?.materials)) && answer?.materials.map(image => {
        return (
          <UploadedImage
            filepath={image?.filepath}
            image={image}
            readOnly={true}
          />
        )
      })}
    </div>
  )
};

export default withFormattedUntitled(QuizPreviewAnswer);
