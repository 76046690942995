// Core
import React, { PureComponent } from 'react';
import { compose } from 'react-apollo';
import { FormattedMessage, injectIntl } from 'react-intl';

// Material Components
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';

// Components
import Text from 'components/Text';
import ActiveStudentsTableRow from 'routes/ManageAccount/ExpertActiveStudents/ActiveStudentsTable/ActiveStudentsTableRow';

// Other
import { messages } from 'routes/ManageAccount/ExpertSales/messages';
import { toUpperCase } from 'utils/helpers';

// Styles and Assets
import './ActiveStudentsTable.scss';

class ActiveStudentsTable extends PureComponent {
  renderTableBody = (courses, total, formatMessage) => (
    <>
      {courses.map((item, index, arr) => (
        <ActiveStudentsTableRow key={item.id} item={item} index={index} arr={arr} />
      ))}
      <ActiveStudentsTableRow total={true} key={'total'} item={{ ...total, title: formatMessage(messages.total), total: total.sum }} index={0} arr={[total]} />
    </>
  )

  render() {
    const { courses, total, intl: { formatMessage } } = this.props;

    const headerHeight = 63;
    const rowHeight = 63;
    const rowsCount = +courses?.length || 0;

    return (
      <Table
        className="mui-override sk-expert-analytics-active-students"
        style={{ height: headerHeight + (rowHeight*rowsCount) }}
      >
        <TableHead>
          <TableRow className="mui-override sk-expert-analytics-active-students__upper-header">
            <TableCell colSpan={2}>
            </TableCell>
            <TableCell colSpan={3} className="mui-override sk-expert-analytics-active-students__number_students">
              <Text align="center">
                <FormattedMessage {...messages.students} />
              </Text>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell className="mui-override sk-expert-analytics-active-students__cell">
              <Text bold align="left">
                <FormattedMessage {...messages.courseTitle} />
              </Text>
            </TableCell>
            <TableCell className="mui-override sk-expert-analytics-active-students__cell" >
              <Text bold align="right">
                <FormattedMessage {...messages.rating} />
              </Text>
            </TableCell>
            <TableCell className="mui-override sk-expert-analytics-active-students__cell">
                <Text bold align="right">
                  {toUpperCase(formatMessage(messages.flex))}
                </Text>
            </TableCell>
            <TableCell className="mui-override sk-expert-analytics-active-students__cell">
              <Text bold align="right">
                {toUpperCase(formatMessage(messages.assist))}
              </Text>
            </TableCell>
            <TableCell className="mui-override sk-expert-analytics-active-students__cell">
              <Text bold align="right">
                {toUpperCase(formatMessage(messages.concierge))}
              </Text>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {courses && courses.length > 0 ? this.renderTableBody(courses, total, formatMessage) : null}
        </TableBody>
      </Table>
    );
  }
}

const enhancer = compose(injectIntl);

export default enhancer(ActiveStudentsTable);
