import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ModalContext } from 'containers/ModalProvider//modal-context';
import { withModal } from 'containers/ModalProvider/withModal';
import Modal from 'components/Modal';

class ModalProvider extends PureComponent {
  state = {
    component: null,
    props: {},
  };

  showModal = (component, props = {}, modalProps) => {
    this.setState({
      component,
      props,
      modalProps,
    });
  };

  hideModal = () =>
    this.setState({
      component: null,
      props: {},
    });

  render() {
    const { children } = this.props;
    const { component, props, modalProps } = this.state;

    return (
      <ModalContext.Provider
        value={{
          modalContext: {
            component,
            props,
            showModal: this.showModal,
            hideModal: this.hideModal,
          },
        }}
      >
        {children}
        {component ? (
          <Modal
            component={component}
            open
            hideModal={this.hideModal}
            {...modalProps}
            {...props}
          />
        ) : null}
      </ModalContext.Provider>
    );
  }
}

ModalProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default withModal(ModalProvider);
