export const arrayPusher = (id, bool, fromArray, value) => {
  let arr = [...fromArray];
  arr.map((item, index) => {
    if(index<=id){
      arr[index][value] = bool;
    } else{
      arr[index][value] = 1;
    }
    return null;
  });
  return arr;
};

export const createdArray = index => {
  const arr = [];
  let numb = 0;
  while (numb < index) {
    arr.push({ id: numb, value: 1 });
    numb += 1;
  }
  return arr;
};
