import GET_COURSE from 'queries/Courses/getCourse.gql';

export const Student = {
  lectures: async (
    { homeworks_data: homeworks },
    { input: { course_id: courseId } },
    { cache }
  ) => {
    const lecturesWithHomeworkIdList = homeworks.map(hw => hw.lecture_id);

    const queryData = {
      query: GET_COURSE,
      variables: {
        input: {
          id: courseId,
        }
      },
    };

    try {
      const getCourseQuery = await cache.readQuery(queryData);

      const {
        getCourse: { lectures },
      } = getCourseQuery;

      const extendedLectures = lectures.map(
        ({ id, title, order_number: orderNumber }) => {
          const lecture = {
            id,
            title,
            orderNumber,
            homework: {
              lecture_id: id,
              answer: null,
              review: null,
            },
          };

          if (lecturesWithHomeworkIdList.includes(id)) {
            lecture.homework = homeworks.find(hw => hw.lecture_id === id);
          }

          return lecture;
        }
      );

      return extendedLectures;
    } catch (error) {
      return false;
    }
  },
};
