// Core
import React, { useState, useEffect } from 'react';
import { withApollo, compose, graphql } from 'react-apollo';
import { withRouter } from 'react-router';
import Auth from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
// import { Hub, Amplify } from "@aws-amplify/core";

// Components
import { AuthenticatedUserContext } from 'containers/AuthenticatedUserProvider/authenticatedUser-context.js';
import Loader from 'components/Loader';

// GraphQL
import CHECK_PROFILE from 'mutations/UserProfile/checkUserProfile.gql';
import PROFILE_QUERY from 'queries/UserProfile/getProfile.gql';
import DOMAIN_QUERY from 'queries/Settings/getDomainSettings.gql';

// Other
import { withAWS } from 'containers/AWSProvider/withAWS';
import { isBlank, keysToCamel, parseCookies } from 'utils/helpers';
import {
  STUDENT,
  MANAGER,
  EXPERT,
  ADMIN,
  OWNER,
  DEFAULT_LOCALE
} from 'utils/enums';

import amplifyConfig from 'services/aws/amplify/config';

// const checkUserProfile = graphql(CHECK_PROFILE, {
//   props: ({ mutate }) => ({
//     checkUserProfile: input => mutate({ variables: { input } }),
//   }),
// });

const wrapperEnhancer = compose(withApollo, withAWS, withRouter);

let wasAWSConfigUpdating = false;

const REQUIRED_ATTRIBUTES = [
  'custom:role',
  'custom:first_name',
  'custom:last_name'
];

const verifyCognitoUserAttributes = attributes => {
  if (attributes && attributes !== null) {
    const attributesKeys = Object.keys(attributes);
    const notDefinedAttributes = REQUIRED_ATTRIBUTES.filter(
      attribute => !attributesKeys.includes(attribute)
    );
    const isValid = notDefinedAttributes.length === 0;
    return isValid;
  }
  return false;
};

const getUserAuthStatus = WrappedComponent => {
  const HOC = ({
    client,
    awsContext: { updateAWSConfig },
    history,
    ...props
  }) => {
    const [userAuthStatus, setUserAuthStatus] = useState({
      isFederatedVerified: null,
      isUserAuthorized: null
    });

    let currentUserInfo = null;

    const getAuthenticatedUser = async () => {
      try {
        const userInfo = await Auth.currentUserInfo();

        if (
          userInfo !== null &&
          typeof userInfo === 'object' &&
          Object.keys(userInfo).length === 0
        ) {
          return null;
        }
        return userInfo;
      } catch (err) {
        console.log('err', err);
        return null;
      }
    };

    const verifyFederatedIdentity = async (withUpdateAwsConfig = false) => {
      let { isFederatedVerified, isUserAuthorized } = { ...userAuthStatus };

      currentUserInfo = await getAuthenticatedUser();
      isUserAuthorized = !isBlank(currentUserInfo);
      if (isUserAuthorized) {
        const { attributes } = currentUserInfo;
        isFederatedVerified = verifyCognitoUserAttributes(attributes);

        if (isFederatedVerified && withUpdateAwsConfig) {
          client.cache.reset();
          client.resetStore();

          try {
            await updateAWSConfig();
            handleRedirectAfterSignIn(attributes['custom:role']);
          } catch (error) {
            throw Error(error);
          }
        }
      }

      setUserAuthStatus(prev => ({ isUserAuthorized, isFederatedVerified }));
    };

    const handleRedirectAfterSignIn = cognitoUserRole => {
      const lang = parseCookies('language') || DEFAULT_LOCALE;
      const REDIRECT_ROUTES = {
        [EXPERT]: lang + '/courses',
        [STUDENT]: lang + '/courses',
        [ADMIN]: lang + '/admin/panel',
        [MANAGER]: lang + '/courses',
        [OWNER]: lang + '/courses'
      };
      return history.push(`/${REDIRECT_ROUTES[cognitoUserRole]}`);
    };

    const handleRedirectAfterSignOut = () => {
      return history.push(`/auth/signin`);
    };

    const { isUserAuthorized } = userAuthStatus;

    // const handleSignOut = async () => {
    //   // Reconfigure Amplify with the current subdomain's settings
    //   configureAmplify(amplifyConfig);  // Make sure this function adjusts Amplify to use the current subdomain

    //   try {
    //     await Auth.signOut();
    //     handleRedirectAfterSignOut();
    //   } catch (error) {
    //     console.error('Error during sign out:', error);
    //   }
    // };

    const clearAllCookies = () => {
      const cookies = document.cookie.split(';');
      for (let cookie of cookies) {
        const eqPos = cookie.indexOf('=');
        const name =
          eqPos > -1 ? cookie.substring(0, eqPos).trim() : cookie.trim();
        const domain = window.location.hostname;
        const baseDomain = domain.substring(domain.indexOf('.')); // Assuming cookies are set for base domain like .example.com,
        // Clear cookies for the full domain
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=${domain}; secure; samesite=strict`;
        // Clear cookies for the base domain if applicable
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=${baseDomain}; secure; samesite=strict`;
      }
    };

    useEffect(() => {
      Hub.listen('auth', async data => {
        const {
          payload,
          payload: { event, data: payloadData }
        } = data;
        console.log('event', event);
        switch (event) {
          case 'signIn':
            console.log('SignIn operation');
            verifyFederatedIdentity(true);
            break;
          case 'signUp':
            console.log('SignUp operation');
            break;
          case 'signOut':
            console.log('SignOut operation');
            clearInterval(scheduleProfileRefetch);
            client.cache.reset();
            client.resetStore();

            try {
              clearAllCookies();
              await updateAWSConfig();
              handleRedirectAfterSignOut();
            } catch (error) {
              throw Error(error);
            }
            break;
          case 'signIn_failure':
            console.log('user sign is failed');
            break;
          case 'configured':
            console.log('the Auth module is configured');
            break;
          default:
            console.log('Default', data);
        }
      });

      verifyFederatedIdentity();

      const scheduleProfileRefetch = setInterval(
        scheduleProfileRefetchCallback,
        60 * 1000
      );

      async function scheduleProfileRefetchCallback() {
        try {
          if (isUserAuthorized) {
            const userSession = await getAuthenticatedUser();
            if (userSession === null) {
              setUserAuthStatus(prev => ({
                isUserAuthorized: false,
                isFederatedVerified: false
              }));
              clearInterval(scheduleProfileRefetch);
              await updateAWSConfig();
              return handleRedirectAfterSignOut();
            }
          } else {
            clearInterval(scheduleProfileRefetch);
          }
        } catch (err) {
          console.warn('FatalError::scheduleProfileRefetchCallback/0', err);
        }
      }
    }, []);

    return (
      <WrappedComponent
        verifyFederatedIdentity={verifyFederatedIdentity}
        {...userAuthStatus}
        {...props}
      />
    );
  };

  return wrapperEnhancer(HOC);
};

const AuthenticatedUserProvider = ({
  children,
  loading,
  profile,
  refetchProfile,
  domainLoaded,
  domain,
  getDomainSettings,
  isFederatedVerified,
  isUserAuthorized,
  runVerifying,
  verifyFederatedIdentity,
  history
}) => {
  const [authCompany, setAuthCompany] = useState(null);
  const [authDomain, setAuthDomain] = useState(null);
  const isAuthenticated =
    isBlank(isUserAuthorized) && isBlank(isFederatedVerified)
      ? null
      : isUserAuthorized && isFederatedVerified;

  // useEffect(() => {
  //   if (profile) {
  //     const [profileCompany] = profile?.companies || [];
  //     if (profileCompany) {
  //       setAuthCompany(profileCompany);
  //     }
  //     if (domain) {
  //       setAuthDomain(domain);
  //     }
  //   }
  // }, [profile, domain]);

  // useEffect(() => {
  //   if (authCompany && authDomain) {
  //     if (authCompany.id === authDomain.id) {
  //       console.log('boomn');
  //       history.push('https://avdo.education');
  //     }
  //   }
  // }, [authCompany, authDomain]);

  return (
    <AuthenticatedUserContext.Provider
      value={{
        userContext: {
          isUserAuthorized,
          isFederatedVerified,
          isAuthenticated,
          isProfileLoading: loading,
          isDomainLoading: domainLoaded,
          profile,
          domain,
          verifyFederatedIdentity,
          getProfile: async () => await refetchProfile(),
          getDomainSettings: async () => await getDomainSettings()
        }
      }}
    >
      <>
        {(!(window && window.isServer) && isBlank(isAuthenticated)) ||
        (!(window && window.isServer) && loading && domainLoaded) ? (
          <Loader />
        ) : (
          children
        )}
      </>
    </AuthenticatedUserContext.Provider>
  );
};

const getProfile = graphql(PROFILE_QUERY, {
  skip: ({ isFederatedVerified, isUserAuthorized }) => {
    return !(isUserAuthorized && isFederatedVerified);
  },
  props: ({ data: { getProfile, error, loading, refetch, ...ownProps } }) => {
    return {
      error,
      loading,
      profile: keysToCamel(getProfile),
      refetchProfile: refetch,
      ...ownProps
    };
  },
  options: () => ({
    fetchPolicy: 'network-only'
  })
});

const getDomainSettings = graphql(DOMAIN_QUERY, {
  skip: !window?.location?.host,
  props: ({
    data: { getDomainSettings, error, loading, refetch, ...ownProps }
  }) => {
    return {
      error,
      domainLoaded: loading,
      domain: keysToCamel(getDomainSettings),
      refetchDomainSettings: refetch,
      ...ownProps
    };
  },

  options: () => ({
    variables: { fqdn: window.location.host || 'avdo.education' },
    fetchPolicy: 'cache-first',
  })
});

const enhancer = compose(getUserAuthStatus, getProfile, getDomainSettings);

export default enhancer(AuthenticatedUserProvider);
