import React, { createContext } from 'react';

export const CourseEditContext = createContext({
  courseEditContext: {
    course: {},
    handleCourseUpdate: () => {},
    handleCourseDelete: () => {},
    handleCourseSubmit: () => {},
    bindCourseMethod: () => {},
  },
});

export function withCourseEditContext(Component) {
  return function ContextComponent(props) {
    return (
      <CourseEditContext.Consumer>
        {contexts => <Component {...props} {...contexts} />}
      </CourseEditContext.Consumer>
    );
  };
};
