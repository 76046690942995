// Core
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Redirect } from 'react-router-dom';
import Auth from '@aws-amplify/auth';
import { compose } from 'react-apollo';

// Components
import Card from 'components/Card';
import Heading from 'components/Heading';
import Text from 'components/Text';
import PasswordChangeForm from 'routes/PasswordChange/PasswordChangeForm';
import SuccessfulResetMessage from 'routes/PasswordChange/SuccessfulResetMessage';

// Styles and Assets
import './PasswordChange.scss';

// Other
import { withModal } from 'containers/ModalProvider/withModal';
import { parseIncomingError } from 'utils/errorHandling';

import { FormattedMessage } from 'react-intl';
import { messages } from 'routes/PasswordChange/messages';

class PasswordChange extends PureComponent {
  handleContinueClick = () => {
    const {
      modalContext: { hideModal },
      history,
    } = this.props;

    hideModal();
    history.push('/auth/signIn');
  };

  handlePasswordChange = async (
    { code, password },
    { setSubmitting, setErrors }
  ) => {
    const {
      modalContext: { showModal },
      location: {
        state: { email },
      },
    } = this.props;

    try {
      await Auth.forgotPasswordSubmit(email, `${code}`, password);
      showModal(SuccessfulResetMessage, {
        handleContinueClick: this.handleContinueClick,
      });
    } catch (error) {
      setSubmitting(false);
      setErrors(parseIncomingError(error));
    }
  };

  render() {
    const {
      location: { state },
    } = this.props;

    if (!state) return <Redirect to="/" />;

    const defaultFields = {
      password: '',
      confirmPassword: '',
      code: state.code || '',
    };

    return (
      <div className="sk-pw-change sk-background-spot__secondary">
        <Card classes="sk-pw-change_container">
          <div className="sk-pw-change_inner">
            <div className="sk-pw-change_header">
              <Heading variant="h4" classes="sk-pw-change_heading">
                <FormattedMessage {...messages.youHaveReqPas} />
              </Heading>
              <Text classes="sk-pw-change_subheading">
                <FormattedMessage {...messages.pleaseChooseNewPass} />
              </Text>
            </div>
            <div className="sk-change-content">
              <PasswordChangeForm
                defaultFields={defaultFields}
                handlePasswordChange={this.handlePasswordChange}
              />
            </div>
          </div>
        </Card>
      </div>
    );
  }
}

PasswordChange.propTypes = {
  location: PropTypes.object,
  modalContext: PropTypes.object,
  history: PropTypes.object,
};

const enhancer = compose(
  withRouter,
  withModal
);

export default enhancer(PasswordChange);
