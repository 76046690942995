// Core
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { InlineTimePicker as MuiTimePicker } from 'material-ui-pickers';

// Components
import TimePickerInput from 'components/TimePicker/TimePickerInput';

export const TimePicker = ({
  time,
  label,
  name,
  handlePickerChange,
  autoOk,
  ...rest
}) => (
  <MuiTimePicker
    label={label}
    name={name}
    customType="picker"
    minutesStep={5}
    value={time}
    autoOk={autoOk}
    keyboard
    inputIsControlled={false}
    onChange={handlePickerChange(name)}
    TextFieldComponent={({ autoOk: autoOkProp, ...restProps }) => (
      <TimePickerInput {...restProps} picker {...rest} time={time} />
    )}
  />
);

TimePicker.defaultProps = {
  autoOk: true,
};

TimePicker.propTypes = {
  time: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  label: PropTypes.string,
  name: PropTypes.string,
  handlePickerChange: PropTypes.func,
  autoOk: PropTypes.bool,
};

export default memo(TimePicker);
