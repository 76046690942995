// Core
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';

// Components
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Text from 'components/Text';

// Other
import { sharedMessages } from 'services/i18n/sharedMessages/messages';

// Styles and Assets
import './CoursesTableRow.scss';

const classes = {
  tableCell: { root: 'mui-override sk-adminCoursesListTable' },
};

const CoursesTableRow = memo(({ item, index, arr, intl: { formatMessage } }) => (
  <TableRow key={item.id}>
    <TableCell
      classes={classes.tableCell}
      className={`sk-adminCoursesListTable__tableBodyCellLeft ${index ===
        arr.length - 1 && '_last'}`}
      align="left"
    >
      <Link to={`/admin/panel/course/${item.id}`}>
        <Text size="basic" className="sk-adminCoursesListTable__link">
          {item.title}
        </Text>
      </Link>
    </TableCell>
    <TableCell
      classes={classes.tableCell}
      className={`sk-adminCoursesListTable__tableBodyCellLeft ${index ===
        arr.length - 1 && '_last'}`}
      align="left"
    >
      <Text size="basic">
        {formatMessage(sharedMessages[item?.category?.title])}
      </Text>
    </TableCell>
    <TableCell
      classes={classes.tableCell}
      className={`sk-adminCoursesListTable__tableBodyCellLeft ${index ===
        arr.length - 1 && '_last'}`}
      align="left"
    >
      <Text size="basic">
        {formatMessage(sharedMessages[item?.subcategory?.title])}
      </Text>
    </TableCell>
    <TableCell
      classes={classes.tableCell}
      className={`sk-adminCoursesListTable__tableBodyCellLeft ${index ===
        arr.length - 1 && '_last'}`}
      align="left"
    >
      <Text size="basic">{item.status_id}</Text>
    </TableCell>
    <TableCell
      classes={classes.tableCell}
      className={`sk-adminCoursesListTable__tableBodyCellLeft ${index ===
        arr.length - 1 && '_last'}`}
      align="left"
    >
      <Text size="basic">{item.author && item.author !== null ? `${item.author.last_name} ${item.author.first_name}` : 'DELETED'}</Text>
    </TableCell>
  </TableRow>
));

CoursesTableRow.propTypes = {
  intl: intlShape.isRequired,
  item: PropTypes.object,
  index: PropTypes.number,
  arr: PropTypes.array,
};

export default injectIntl(CoursesTableRow);
