import React from 'react';

import './index.scss';

const IconOnHover = () => (
  <svg class='filled' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.9 2.8998H14.2C14 1.3998 12.8 0.299805 11.3 0.299805H9C7.5 0.299805 6.3 1.4998 6.1 2.8998H2.5C2.2 2.8998 2 3.0998 2 3.3998C2 3.6998 2.2 3.8998 2.5 3.8998H4.2V15.2998C4.2 17.0998 5.5 18.4998 7.2 18.4998H13.3C14.9 18.4998 16.3 17.0998 16.3 15.2998V3.8998H18C18.3 3.8998 18.5 3.6998 18.5 3.3998C18.5 3.0998 18.2 2.8998 17.9 2.8998ZM9 1.2998H11.3C12.2 1.2998 13 1.9998 13.2 2.8998H7.2C7.3 1.9998 8.1 1.2998 9 1.2998Z" fill="#7145D8"/>
    <path d="M10.1996 6.19971C9.89958 6.19971 9.69958 6.39971 9.69958 6.69971V14.9997C9.69958 15.2997 9.89958 15.4997 10.1996 15.4997C10.4996 15.4997 10.6996 15.2997 10.6996 14.9997V6.69971C10.6996 6.39971 10.3996 6.19971 10.1996 6.19971Z" fill="white"/>
    <path d="M7.59961 7.49976C7.29961 7.49976 7.09961 7.69976 7.09961 7.99976V13.6998C7.09961 13.9998 7.29961 14.1998 7.59961 14.1998C7.89961 14.1998 8.09961 13.9998 8.09961 13.6998V7.99976C8.09961 7.69976 7.89961 7.49976 7.59961 7.49976Z" fill="white"/>
    <path d="M12.7996 7.49976C12.4996 7.49976 12.2996 7.69976 12.2996 7.99976V13.6998C12.2996 13.9998 12.4996 14.1998 12.7996 14.1998C13.0996 14.1998 13.2996 13.9998 13.2996 13.6998V7.99976C13.2996 7.69976 13.0996 7.49976 12.7996 7.49976Z" fill="white"/>
  </svg>
);

const IconDefault = () => (
  <svg class='outlined' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.9171 3.67114V15.2905C15.9171 16.7807 14.7993 18.0002 13.4334 18.0002H7.33961C5.97328 18.0002 4.85547 16.7807 4.85547 15.2905V3.67114" stroke="#7145D8" strokeWidth="0.972" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M2.7002 3.63996H18.0728" stroke="#7145D8" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M10.3549 6.6875V14.9833" stroke="#7145D8" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M7.79434 8.00684V13.663" stroke="#7145D8" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M12.9799 8.00684V13.663" stroke="#7145D8" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M13.9547 3.46837C13.9547 2.1105 12.8764 1 11.5584 1H9.21331C7.89529 1 6.81738 2.1105 6.81738 3.46837" stroke="#7145D8" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const Icon = () => {
  return (
    <div className="quiz-icon">
      <IconDefault />
      <IconOnHover />
    </div>
  );
};

export default Icon;
