import React from 'react';

const VK = () => (
  <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21 1C9.97205 1 1 9.97205 1 21C1 32.0271 9.97205 41 21 41C32.0271 41 41 32.0271 41 21C41 9.97205 32.0288 1 21 1Z" stroke="#610FB6"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M30.9012 15.52C31.0505 15.017 30.9012 14.6504 30.1842 14.6504H27.8188C27.2186 14.6504 26.9395 14.9684 26.7903 15.3188C26.7903 15.3188 25.5865 18.2519 23.8831 20.1565C23.3315 20.7081 23.0817 20.8833 22.7799 20.8833C22.6307 20.8833 22.4133 20.7081 22.4133 20.2052V15.52C22.4133 14.9197 22.2381 14.6504 21.7384 14.6504H18.02C17.6436 14.6504 17.4165 14.9294 17.4165 15.1955C17.4165 15.7665 18.2699 15.8963 18.3575 17.5024V20.9872C18.3575 21.7497 18.2179 21.8892 17.9194 21.8892C17.118 21.8892 15.168 18.9431 14.0096 15.5719C13.7825 14.9165 13.5554 14.6536 12.9519 14.6536H10.5866C9.91167 14.6536 9.77539 14.9716 9.77539 15.322C9.77539 15.9482 10.5768 19.0566 13.51 23.1676C15.4665 25.9742 18.2179 27.4959 20.7261 27.4959C22.2283 27.4959 22.4165 27.1585 22.4165 26.5744V24.4524C22.4165 23.7776 22.5593 23.6413 23.0362 23.6413C23.3867 23.6413 23.9902 23.8165 25.3918 25.1695C26.9947 26.7724 27.2608 27.4927 28.1628 27.4927H30.5281C31.203 27.4927 31.5404 27.1552 31.3458 26.4868C31.1316 25.8217 30.3659 24.858 29.3503 23.7127C28.7987 23.0605 27.9713 22.3597 27.7215 22.0092C27.3711 21.5582 27.4717 21.3571 27.7215 20.958C27.7183 20.958 30.5995 16.8989 30.9012 15.52Z" stroke="#610FB6"/>
  </svg>
);

export default VK;
