import React, { useEffect, useState } from 'react';
import { compose, graphql } from 'react-apollo';

// GraphQL
import GET_FILE_URL_MUTATION from 'mutations/Courses/getFileUrl.gql';

// Other
import { isBlank } from 'utils/helpers';
import {
  PENDING,
  PROCESSING,
  UPLOADED,
  ERROR,
} from 'containers/UploadManagerProvider/helpers';
import { withUploadManager } from 'containers/UploadManagerProvider/withUploadManager';

const enhancer = compose(withUploadManager);

export const withUploadingStatuses = WrappedComponent => {
  const HOC = ({
    fileKey = 'file',
    uploadManagerContext,
    uploadManagerContext: {
      progress,
      getFileFromQueue,
      getFileFromUploadedFiles,
    },
    ...props
  }) => {
    const file = props[fileKey];

    let filepath = null;
    const isFileAdded = !isBlank(file);

    let isFilePending = false;
    let isFileUploaded = false;
    let isFileInQueue = false;
    let isFileInProgress = false;
    let isFileUploadedAndProcessed = false;
    let isFileProcessing = false;
    let isFileProcessingFailed = false;


    if (isFileAdded) {
      const { id } = file;
      isFilePending = file.status === PENDING;
      isFileProcessing = file.status === PROCESSING;
      isFileUploadedAndProcessed = file.status === UPLOADED;
      isFileProcessingFailed = file.status === ERROR;

      const {
        isFileInList: isFileInQueueTemp,
        index: fileIndexInQueue,
      } = getFileFromQueue(id);
      const { isFileInList: isFileUploadedTemp } = getFileFromUploadedFiles(id);

      isFileInQueue = isFileInQueueTemp;
      isFileUploaded = isFileUploadedTemp;
      isFileInProgress = isFileInQueue && fileIndexInQueue === 0;

      filepath = file?.filepath;
    };

    const fileUploadStatuses = {
      isFilePending,
      isFileUploaded,
      isFileInQueue,
      isFileInProgress,
      isFileUploadedAndProcessed,
      isFileProcessing,
      isFileProcessingFailed,
    };

    return <WrappedComponent
      fileUploadStatuses={fileUploadStatuses}
      filepath={filepath}
      uploadManagerContext={uploadManagerContext}
      {...props}
    />
  };

  return enhancer(HOC);
};
