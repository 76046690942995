import React from 'react';
import PropTypes from 'prop-types';
import { PurpleHeart, White } from 'components/Icon/color';

const WebinarAudio = ({ height, width, strokeColor, fillColor, active }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx={width / 2}
      cy={height / 2}
      r={width / 2}
      fill={fillColor}
      fillOpacity={active ? '1' : '0.75'}
    />
    <path
      d="M24.835 15.232a1.262 1.262 0 0 0-1.288.019.39.39 0 
      0 0-.054.039l-5.645 4.776h-4.431a.416.416 0 0 0-.417.417v7.916a.415.415
       0 0 0 .417.417l4.42-.002 5.643 5.183c.021.02.044.036.067.05.206.124.433.186.66.186a1.301
        1.301 0 0 0 1.293-1.316V16.381c0-.484-.249-.913-.665-1.149zm-.168 17.685c0
         .18-.09.338-.242.423a.437.437 0 0 1-.416.01l-5.592-5.136v-1.899a.416.416 
         0 1 0-.834 0v1.665l-3.75.002V20.9h3.75v1.666a.416.416 0 1 0 .834
          0v-1.89l5.585-4.725c.192-.1.359-.029.423.008.151.085.242.243.242.423v16.536zM34.678 
          24.44a8.116 8.116 0 0 0-5.512-7.685.417.417 0 0 0-.268.79 7.283 7.283 0 0 1 .01
           13.794.417.417 0 0 0 .268.788 8.121 8.121 0 0 0 5.502-7.686z"
      fill={strokeColor}
    />
    <path
      d="M31.522 14.656a.417.417 0 0 0-.325.768 9.78 9.78 0 0 1 5.97 9.017
       9.731 9.731 0 0 1-6.257 9.13.417.417 0 1 0 .302.778C35.335 32.75 38
        28.861 38 24.441c0-4.274-2.543-8.115-6.478-9.785z"
      fill={strokeColor}
    />
    <path
      d="M30.927 24.44a5.65 5.65 0 0 0-4.062-5.4.417.417 0 0 0-.23.8 4.812 
      4.812 0 0 1 3.46 4.6 4.812 4.812 0 0 1-3.46 4.6.417.417 0 0 0 .23.802
       5.65 5.65 0 0 0 4.062-5.401z"
      fill={strokeColor}
    />
  </svg>
);

WebinarAudio.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  strokeColor: PropTypes.string,
  fillColor: PropTypes.string,
  active: PropTypes.bool,
};

WebinarAudio.defaultProps = {
  height: '50',
  width: '50',
  strokeColor: PurpleHeart,
  fillColor: White,
  active: false,
};

export default WebinarAudio;
