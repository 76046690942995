// Core
import React, { memo, useCallback } from 'react';
import { compose } from 'react-apollo';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

// Components
import Button from 'components/Button';
import Text from 'components/Text';

// Other
import { withFormattedHtmlMessage } from 'containers/HOCs/withFormattedHtmlMessage';
import { messages } from 'routes/ExpertsCabinet/CourseEdit/messages';
import { sharedMessages } from 'services/i18n/sharedMessages/messages';

// Styles and Assets
import './index.scss';

const PREFIX = 'sk-subscription-max-courses-limit';
const BuySubscriptionModal = ({
  maxCourses,
  intl: { formatMessage },
  formatHtmlMessage,
  hideModal,
  ...props
}) => {

  return (
    <div className={PREFIX}>
      <Text>{formatMessage(messages.subscriptionMaxCoursesWarningTitle)}</Text>
      <Text size='small'>{formatHtmlMessage(messages.subscriptionMaxCoursesWarningText, { maxCourses })}</Text>
      <Button
        color="primary"
        onClick={hideModal}
      >
        {formatMessage(sharedMessages.understandButton)}
      </Button>
    </div>
  );
};

const enhancer = compose(
  memo,
  withFormattedHtmlMessage,
);

export default enhancer(BuySubscriptionModal);
