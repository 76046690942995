// Core
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { compose, graphql } from 'react-apollo';
import { injectIntl } from 'react-intl';
import { withAuthenticatedUser } from 'containers/AuthenticatedUserProvider/withAuthenticatedUser';
import { keysToCamel } from 'utils/helpers';

import LIST_COURSES from 'queries/Courses/studentListCourses.gql';

// Components
import LandingPageHero from 'routes/LandingPage/LandingPageHero';
import LandingPagePopularCourses from 'routes/LandingPage/LandingPagePopularCourses/';
import LandingPageNewCourses from 'routes/LandingPage/LandingPageNewCourses/';
// import LandingPageInteractionLevels from 'routes/LandingPage/LandingPageInteractionLevels';
import LandingPageComments from 'routes/LandingPage/LandingPageComments';
import LandingPageTopings from 'routes/LandingPage/LandingPageTopings';
import LandingPageAllCourses from 'routes/LandingPage/LandingPageAllCourses';

// Other
import MY_PROFILE_QUERY from 'queries/UserProfile/getProfile.gql';
import GET_STUDENT_COURSE_IDS_LIST from 'queries/Courses/studentListCoursesIds.gql';
import { messages } from './messages.js';

// Styles and Assets
import './LandingPage.scss';

const LandingPage = ({ userContext, history, intl: { formatMessage }, studentListCourses }) => {
  useEffect(() => {
    if (userContext?.profile?.role === 'admin') {
      history.push('/admin/panel');
    }
    if (userContext?.profile?.org) {
      history.push('/categories');
    }
  }, [userContext]);

  // const courses = studentListCourses ? studentListCourses?.purchasedCourses : [] 

  return (
    <div className="sk-landing-page">
      <div className="sk-landing-page__container">
        <LandingPageHero role={userContext?.profile?.role} />
        <LandingPageNewCourses
          org={userContext?.profile?.org}
          title={formatMessage(messages.newCoursesTitle)}
        />
        <LandingPageTopings />
        <LandingPagePopularCourses
          org={userContext?.profile?.org}
          title={formatMessage(messages.popularCoursesTitle)}
        />
        <LandingPageAllCourses
          org={userContext?.profile?.org}
          title={formatMessage(messages.allCoursesTitle)}
        />
{/* 
        <LandingPageInteractionLevels
          title={formatMessage(messages.interactionLevelsTitle)}
        /> */}

        <LandingPageComments
          title={formatMessage(messages.successStoriesTitle)}
          description={formatMessage(messages.successStoriesDescription)}
          button={formatMessage(messages.successStoriesButton)}
        />
      </div>
    </div>
  );
};

const studentListCoursesQuery = graphql(LIST_COURSES, {
  skip: ({ userContext }) => !userContext?.isUserAuthorized,
  props: ({ data: { listStudentCoursesExtra, purchased_courses, variables, refetch, loading, error, ...ownProps } }) => ({
    studentListCourses: keysToCamel(listStudentCoursesExtra),
    query: variables?.input?.query || {},
    refetchCourses: refetch,
    ...ownProps,
  }),
  options: () => ({
    variables: { input: {} }
  }),
});

LandingPage.propTypes = {
  role: PropTypes.string,
  history: PropTypes.object,
};

const enhancer = compose(
  withRouter,
  withAuthenticatedUser,
  // studentListCoursesQuery,
  injectIntl
);

export default enhancer(LandingPage);
