// Core
import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose, graphql } from 'react-apollo';
import { FormattedMessage } from 'react-intl';
import { injectIntl, } from 'react-intl';

// Components
import ContentHeader from 'containers/ContentHeader';
import Text from 'components/Text';
import Loader from 'components/Loader';
import Pagination from 'components/Pagination';
import Button from 'components/Button';
import DiscountsTable from 'routes/AdminPanel/Discounts/DiscountsList/DiscountsTable/';

// GraphQl
import LIST_DISCOUNTS from 'queries/AdminPanel/listDiscounts.gql';

// Other
import { Grey3 } from 'components/Icon/color';
import { messages } from 'routes/AdminPanel/messages';
import { keysToCamel, isEmptyObject, isArraysEqual } from  'utils/helpers/index';

// Styles and Assets
import './DiscountsList.scss';

const DISCOUNTS_PER_PAGE_COUNT = 7;

class DiscountsList extends PureComponent {
  nextPage = async () => {
    const { skip, limit, total, refetchDiscounts, } = this.props;
    const newSkip = skip + limit;
    const isCoursesExist = newSkip <= total;
    if (isCoursesExist) await refetchDiscounts({ input: { limit, skip: newSkip, }});
  };

  backPage = async () => {
    const { skip, limit, refetchDiscounts, } = this.props;
    const newSkip = skip - limit;
    const isCoursesExist = newSkip >= 0;

    if (isCoursesExist) await refetchDiscounts({ input: { limit, skip: newSkip, }});
  };

  addDiscount = (id) => {
    const { history } = this.props;
    history.push(`/admin/panel/discounts/create`)
  }

  render() {
    const { error, loading, discounts, total, skip, limit, intl: { formatMessage }, } = this.props;

    // if (loading) return (<Loader center />)

    const currentPage = skip / limit;

    return (
      <Fragment>
        <div className="sk-admin-discounts-list__header">
          <div className="sk-admin-discounts-list__header__sidebarButton">
            <ContentHeader />
          </div>
          <Button
            className="sk-admin-discounts-list__header__addPromocodeButton"
            onClick={this.addDiscount}
          >
            {formatMessage(messages.addDiscount)}
          </Button>
        </div>

        <DiscountsTable
          error={error}
          loading={loading}
          discounts={discounts}
          currentPage={currentPage}
        />
        {total > DISCOUNTS_PER_PAGE_COUNT && (
          <div className="sk-admin-discounts-list__pagination">
            <Pagination
              page={currentPage}
              count={total}
              handleChangePage={(e, selectedPage) => {
                if (currentPage > selectedPage) this.backPage()
                else if (currentPage < selectedPage) this.nextPage()
              }}
              rowsPerPage={DISCOUNTS_PER_PAGE_COUNT}
            />
          </div>
        )}
      </Fragment>
    );
  }
}

const listDiscounts = graphql(LIST_DISCOUNTS, {
  props: ({ data: { error, loading, listDiscounts, refetch, ...ownProps } }) => {

    if (error) throw Error('Something went wrong, while receiving discounts');
    if (loading) return { loading, discounts: [], };

    const { items: discounts, total, skip, limit, } = listDiscounts;

    return {
      error,
      loading,
      discounts: keysToCamel(discounts),
      total,
      skip,
      limit,
      refetchDiscounts: refetch,
      ...ownProps,
    }
  },
  options: () => ({
    fetchPolicy: 'cache-and-network',
    variables: {
      input: { skip: 0, limit: DISCOUNTS_PER_PAGE_COUNT },
    },
  }),
});

DiscountsList.propTypes = {
  error: PropTypes.object,
  loading: PropTypes.bool,
  match: PropTypes.object,
};

const enhancer = compose(
  injectIntl,
  withRouter,
  listDiscounts
);

export default enhancer(DiscountsList);
