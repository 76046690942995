import React from 'react';
import {White} from 'components/Icon/color.js';

const Volume = ({color=White}) => (
  <svg
    className="sk-video-controllers_icon"
    width="20"
    height="18"
    viewBox="0 0 21 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="outline"
      d="M9.8611 0.40332C9.65906 0.40332 9.45887 0.46141 9.28213 0.571894C9.26527 0.582145 9.24913 0.594294 9.23447 0.607203L4.26578 4.95936H0.366665C0.163899 4.95936 0 5.12907 0 5.33903V12.5528C0 12.6537 0.0384999 12.7502 0.107433 12.8212C0.176366 12.8922 0.269132 12.9324 0.366665 12.9324L4.25698 12.9305L6.73986 15.2921L9.22273 17.6536C9.24107 17.6711 9.26087 17.6863 9.28177 17.6992C9.4585 17.8097 9.6587 17.8678 9.86073 17.8678C10.4888 17.8678 10.9996 17.3301 10.9996 16.6695V1.60156C11 0.940933 10.4888 0.40332 9.8611 0.40332Z"
      stroke={color}
    />
    <path
      className="filled"
      d="M9.8611 0.40332C9.65906 0.40332 9.45887 0.46141 9.28213 0.571894C9.26527 0.582145 9.24913 0.594294 9.23447 0.607203L4.26578 4.95936H0.366665C0.163899 4.95936 0 5.12907 0 5.33903V12.5528C0 12.6537 0.0384999 12.7502 0.107433 12.8212C0.176366 12.8922 0.269132 12.9324 0.366665 12.9324L4.25698 12.9305L6.73986 15.2921L9.22273 17.6536C9.24107 17.6711 9.26087 17.6863 9.28177 17.6992C9.4585 17.8097 9.6587 17.8678 9.86073 17.8678C10.4888 17.8678 10.9996 17.3301 10.9996 16.6695V1.60156C11 0.940933 10.4888 0.40332 9.8611 0.40332Z"
      fill={color}
      stroke={color}
    />
    <path
      d="M14.2999 0.0298516C14.1137 -0.0510181 13.8984 0.0378245 13.8189 0.231076C13.74 0.424328 13.8269 0.647194 14.0136 0.729203C17.205 2.13132 19.2671 5.357 19.2671 8.94602C19.2671 12.658 17.106 15.9243 13.7617 17.2661C13.5728 17.342 13.479 17.5619 13.5523 17.7574C13.6084 17.9077 13.7474 18 13.8944 18C13.9384 18 13.9831 17.992 14.0268 17.9746C17.6556 16.5178 20.0005 12.9739 20.0005 8.94602C20.0005 5.05136 17.7627 1.55157 14.2999 0.0298516Z"
      fill={color}
    />
    <path
      d="M15.7755 8.94772C15.7755 6.68033 14.3055 4.65669 12.2008 4.02644C12.0065 3.96911 11.8034 4.08415 11.7469 4.28613C11.6908 4.48736 11.8026 4.69808 11.9977 4.75616C13.7903 5.29264 15.0425 7.01634 15.0425 8.94772C15.0425 10.8791 13.7903 12.6028 11.9977 13.1393C11.803 13.1974 11.6908 13.4081 11.7469 13.6093C11.7935 13.7756 11.9398 13.8838 12.0993 13.8838C12.133 13.8838 12.1671 13.8789 12.2008 13.869C14.3059 13.2384 15.7755 11.2147 15.7755 8.94772Z"
      fill={color}
    />
  </svg>
);

export default Volume;
