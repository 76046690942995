// Core
import React from 'react';
import PropTypes from 'prop-types';

// Components
import SidebarMenuItem from 'containers/Sidebar/SidebarContent/SidebarMenu/SidebarMenuItem';

// Styles and Assets
import './SidebarMenu.scss';

export const SidebarMenu = ({ menuItems }) => (
  <ul className="sk-sidebar-menu">
    {menuItems.map(({ ...itemProps }) => (
      <SidebarMenuItem key={itemProps.label} {...itemProps} />
    ))}
  </ul>
);

SidebarMenu.propTypes = {
  menuItems: PropTypes.array,
};

export default SidebarMenu;
