// Core
import React, { PureComponent } from 'react';

// Components
import RoundIcon from 'components/Icon/RoundIcon';
import Camera from 'components/Icon/Svg/Camera';
import FileUploader from 'components/FileUploader';

// Other
import withAction from 'components/Icon/withAction';

const DefaultIcon = ({ active, disabled, action }) => (
  <RoundIcon
    type={active ? 'flat_action' : 'flat'}
    icon={
      <Camera
        width="18.7"
        height="15.3"
        active={active && action}
        disabled={disabled}
      />
    }
  />
);

const PhotoUploader = ({
  icon,
  action,
  photo,
  supportedFormats,
  maxSize,
  uploaderId,
  handleChange,
  disabled,
}) => {
  const active = !!photo;
  return (
    <FileUploader
      supportedFormats={supportedFormats}
      maxSize={maxSize}
      uploaderId={uploaderId}
      handleChange={handleChange}
      disabled={disabled}
    >
      {icon ? icon : (<DefaultIcon active={active} disabled={disabled} action={action} />)}
    </FileUploader>
  );
}

export default withAction(PhotoUploader);
