const LECTURES_PERMISSIONS = 'lectures';
const HOMEWORKS_PERMISSIONS = 'homeworks';

const PREV_MORE_LEVEL_PERMISSION = 'prev_more_level';
const SUBMITTED_PREVIOUS_HOMEWORK_PERMISSION = 'submitted_previous_homework';

export const getPermissions = (type, permissions, newPermission) => {
  const permissionsCopy = { ...permissions };

  if (type === LECTURES_PERMISSIONS) {
    if (newPermission === PREV_MORE_LEVEL_PERMISSION) {
      permissionsCopy[LECTURES_PERMISSIONS] = PREV_MORE_LEVEL_PERMISSION;
      permissionsCopy[HOMEWORKS_PERMISSIONS] = PREV_MORE_LEVEL_PERMISSION;
    } else if (newPermission === SUBMITTED_PREVIOUS_HOMEWORK_PERMISSION) {
      permissionsCopy[LECTURES_PERMISSIONS] = SUBMITTED_PREVIOUS_HOMEWORK_PERMISSION;
      permissionsCopy[HOMEWORKS_PERMISSIONS] = SUBMITTED_PREVIOUS_HOMEWORK_PERMISSION;
    } else {
      permissionsCopy[LECTURES_PERMISSIONS] = newPermission;
    }
  } else if (type === HOMEWORKS_PERMISSIONS) {
    permissionsCopy[HOMEWORKS_PERMISSIONS] = newPermission;
  }

  return permissionsCopy;
}
