import React, { useState, useEffect, useRef } from 'react';
import { Editor, EditorState, RichUtils } from 'draft-js';
import classnames from 'classnames';

// Components
import { EditorViewControls } from 'components/DraftEditor/ControlsBar/Buttons';
import FontSizeControls from 'components/DraftEditor/ControlsBar/FontSizeControls';
import TextControls from 'components/DraftEditor/ControlsBar/TextControls';
import AlignControls from 'components/DraftEditor/ControlsBar/AlignControls';
import ListControls from 'components/DraftEditor/ControlsBar/ListControls';
import BlockControls from 'components/DraftEditor/ControlsBar/BlockControls';
import Separator from 'components/DraftEditor/ControlsBar/Separator';
import Magic from 'components/DraftEditor/ControlsBar/Magic';

import './ControlsBar.scss';

const ControlsBar = ({
  editorState,
  addResource,
  toggleInlineStyle,
  toggleBlockType,
  toggleAlignType,
  setFontSize,
  switchView,
  isFullscreen,
  ControlsBar,
  handleMagic,
}) => (
  <div className="sk-editor-controls-bar">
    <FontSizeControls
      editorState={editorState}
      name="fontSize"
      setFontSize={setFontSize}
    />
    <Separator />
    <TextControls
      editorState={editorState}
      addResource={addResource}
      onToggle={toggleInlineStyle}
    />
    <Separator />
    <AlignControls editorState={editorState} setAlign={toggleAlignType} />
    <Separator />
    <ListControls editorState={editorState} onToggle={toggleBlockType} />
    <Separator />
    <BlockControls
      editorState={editorState}
      onToggle={toggleBlockType}
      addResource={addResource}
    />
    <Separator />
    <EditorViewControls switchView={switchView} isFullscreen={isFullscreen} />
    <Separator />
    <Magic handleMagic={handleMagic} />
  </div>
);

export default ControlsBar;
