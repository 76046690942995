// Core
import React from 'react';
import { PayPalButton as PaypalExpressBtn } from 'react-paypal-button-v2';

// Other
import {
  SANDBOX_API_ID,
  PRODUCTION_API_ID,
} from 'services/paypal/exports';
import { withCurrency } from 'containers/CurrencyProvider/withCurrency';

export const PaypalButton = ({
  handleSuccesfulPayment,
  amount,
  currencyContext,
}) => {
  const onSuccess = (details, data) => handleSuccesfulPayment(data?.orderID);

  const onCancel = () => {
    // The user pressed "cancel" or closed the PayPal popup
    // You can bind the "data" object's value to your state
    // or props or whatever here, please see below for sample returned data
  };

  const onError = error => {
    console.log('error', error); // eslint-disable-line
    // The main Paypal script cou ld not be loaded or something blocked the script from loading
    // Because the Paypal's main script is loaded asynchronously from "https://www.paypalobjects.com/api/checkout.js"
    // => sometimes it may take about 0.5 second for everything to get set, or for the button to appear
  };

  const catchError = error => {
    console.log('catch error', error);// eslint-disable-line
  };

  // Document on Paypal's currency code: https://developer.paypal.com/docs/classic/api/currency_codes/

  const style = {
    label: 'paypal',
    tagline: false,
    shape: 'pill',
    color: 'silver',
    layout: 'horizontal',
    height: 43,
  };

  const clientId = process.env.NODE_ENV === 'production' ? PRODUCTION_API_ID : SANDBOX_API_ID;
  const currentCurrencyProperties = currencyContext.currencyProperties;

  return (
    <PaypalExpressBtn
      style={style}
      amount={amount}
      catchError={catchError}
      onError={onError}
      onSuccess={onSuccess}
      onCancel={onCancel}
      options={{
        clientId,
        currency: currentCurrencyProperties?.label,
        locale: 'ru_RU',
        // debug: true
      }}
    />
  );
};

export default withCurrency(PaypalButton);
