// Core
import React from "react";
import Avatar from "@material-ui/core/Avatar";
import classNames from "classnames";
import { Progress } from "react-sweet-progress";

// Other
import { theme } from "containers/UploadManagerProvider/helpers";
import { isNull } from "utils/helpers";

// Styles
import "./UserAvatarUpload.scss";

const DEFAULT_SIZES = {
  width: 47,
  height: 47,
};

const LetterAvatar = ({ firstName, lastName }) =>
  `${firstName[0]?.toUpperCase() || ""}${lastName[0]?.toUpperCase() || ""}`;

const UserAvatarUpload = ({
  imageUrl,
  firstName,
  lastName,
  progress,
  withProgress,
  sizes = DEFAULT_SIZES,
  type = "circle",
}) => {
  return (
    <div
      style={{ ...sizes }}
      className={`mui-override sk-avatar-upload-user sk-avatar-upload-user_empty style_${type}`}
    >
      {imageUrl ? (
        <>
          <img
            className={type}
            src={imageUrl}
            style={isNull(progress) || progress === 100 ? { opacity: 1 } : {}}
            alt={`${firstName}-avatar`}
            draggable="false"
          />
          {withProgress && progress > 0 && progress < 100 && (
            <div className="sk-user-profile-photo__photo__loader">
              <Progress
                type={type}
                theme={theme}
                percent={progress}
                status={"active"}
              />
            </div>
          )}
        </>
      ) : (
        <Avatar className="mui-override sk-avatar-user_empty">
          <LetterAvatar lastName={lastName} firstName={firstName} />
        </Avatar>
      )}
    </div>
  );
};

export default UserAvatarUpload;
