import React from 'react';

const Icon = ({color = '#A9B6C8'}) => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.41187 19.2621V4.34627C4.41187 3.56579 5.03625 2.94141 5.81673 2.94141H14.6275C15.0437 2.94141 15.4253 3.11485 15.7028 3.44438L19.6399 8.04054C19.848 8.3007 19.9694 8.61289 19.9694 8.95977V19.2968C19.9694 20.7363 18.8074 21.8984 17.3678 21.8984L6.99612 21.8637C5.57391 21.8464 4.41187 20.6843 4.41187 19.2621Z" stroke={color} strokeMiterlimit="10"/>
      <path d="M15.0435 3.09766V7.7285C15.0435 8.14476 15.373 8.47429 15.7892 8.47429L19.8131 8.50898" stroke={color} strokeMiterlimit="10"/>
      <path d="M8.82349 13.0089L11.6615 16.1764L16.1764 11.0293" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default Icon;
