// Core
import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { compose, withApollo } from "react-apollo";

// Components
import ManageAccountSidebar from "containers/Sidebar/SidebarContent/ManageAccountSidebar";
import CourseSidebar from "containers/Sidebar/SidebarContent/CourseSidebar";
import AdminSidebar from "containers/Sidebar/SidebarContent/AdminSidebar";
import StudentCabinetSidebar from "containers/Sidebar/SidebarContent/StudentCabinetSidebar";

// Other
import { signOutUser } from "services/aws/amplify/helpers";
import { withAWS } from "containers/AWSProvider/withAWS";
import MY_PROFILE_QUERY from "queries/UserProfile/getProfile.gql";
import { parseFetchedData } from "utils/helpers";

const { BASE_URL } = process.env;

const SIDEBAR_LIST = [
  {
    path: "/manage-account",
    sidebar: ManageAccountSidebar,
    passUserProfile: true,
  },
  {
    path: "/expert-analytics",
    sidebar: ManageAccountSidebar,
    passUserProfile: true,
  },
  {
    path: "/experts-cabinet",
    sidebar: CourseSidebar,
  },
  {
    path: "/admin/panel",
    sidebar: AdminSidebar,
    passUserProfile: true,
  },
  {
    path: "/student-cabinet",
    sidebar: StudentCabinetSidebar,
  },
];

class SidebarContent extends PureComponent {
  state = {
    user: null,
    loading: true,
  };

  async componentDidMount() {
    const { client } = this.props;
    try {
      const { data } = await client.query({ query: MY_PROFILE_QUERY });
      const user = parseFetchedData(data?.getProfile);
      this.setState({ user, loading: false });
    } catch (error) {
      console.error("Error fetching user profile:", error);
      this.setState({ loading: false });
    }
  }

  signOut = async () => {
    const {
      awsContext: { updateAWSConfig },
      history,
    } = this.props;

    try {
      await signOutUser();
      history.push(BASE_URL);
    } catch (error) {
      throw Error(error);
    }
  };

  getActiveRoute = (route) =>
    SIDEBAR_LIST.find((item) => route.includes(item.path));

  renderMenu = () => {
    const { route } = this.props;
    const { user, loading } = this.state;

    if (loading) {
      return <div>Loading...</div>; // or any loading UI
    }

    const activeRoute = this.getActiveRoute(route);
    const Component = activeRoute?.sidebar || null;

    return (
      <Fragment>
        {Component ? <Component signOut={this.signOut} user={user} /> : null}
      </Fragment>
    );
  };

  render() {
    return this.renderMenu();
  }
}

SidebarContent.propTypes = {
  route: PropTypes.string,
  client: PropTypes.object,
};

const enhancer = compose(withAWS, withApollo, withRouter);

export default enhancer(SidebarContent);
