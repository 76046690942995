// Core
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Styles
import './RoundIcon.scss';

const RoundIcon = ({
  icon,
  disabled,
  size,
  type,
  opacity,
  action,
  chevron,
  expanded,
  styles,
  handleClick,
}) => (
  <div
    className={classNames(
      `sk-round-icon sk-round-icon__${size}`,
      `sk-round-icon-type__${type}`,
      `sk-round-icon__opacity-${opacity}`,
      {
        'sk-round-icon_action': action,
      },
      {
        'sk-round-icon_chevron sk-round-icon_chevron_left': chevron && expanded,
      },
      {
        'sk-round-icon_chevron_right': !expanded && chevron,
      },
      {
        'sk-round-icon_disabled': disabled,
      }
    )}
    style={styles}
    onClick={handleClick}
  >
    {icon}
  </div>
);

RoundIcon.propTypes = {
  icon: PropTypes.any.isRequired,
  size: PropTypes.string,
  type: PropTypes.string,
  action: PropTypes.bool,
  chevron: PropTypes.bool,
  expanded: PropTypes.bool,
  disabled: PropTypes.bool,
};

RoundIcon.defaultProps = {
  size: 'md',
  type: 'dark',
};
export default RoundIcon;
