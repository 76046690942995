// Core
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Formik } from 'formik';

// Components
import TextInput from 'components/Input/Material/TextInput';
import Button from 'components/Button';

// Other
import { getYupSchema } from 'services/yup';

import { FormattedMessage } from 'react-intl';
import { messages } from 'routes/ExpertsCabinet/components/HWMarkForm/messages';

// Styles and Assets
import './HWMarkForm.scss';

const HWMarkForm = ({
  mark: reviewMark,
  disabledMark,
  disabledSubmit,
  scrolled,
  handleFormSubmit,
  isReviewSubmitted,
}) => (
  <Formik
    initialValues={{ mark: reviewMark }}
    validateOnChange
    validateOnBlur
    onSubmit={async values => {
      await handleFormSubmit(values);
    }}
    validationSchema={getYupSchema('expertReviewSchema')}
  >
    {props => {
      const {
        values: { mark },
        errors,
        touched,
        handleSubmit,
        setFieldValue,
        handleBlur,
      } = props;

      const handleInputChange = name => (inputValue, e) => {
        setFieldValue(name, inputValue);
        if (e) e.persist();
      };

      const handleInputBlur = name => (inputValue, e) => {
        if (e) {
          e.persist();
          handleBlur(e);
        }

        setFieldValue(name, inputValue);
      };
      return (
        <form noValidate autoComplete="off" className="sk-hw-mark-form">
          <div
            className={classNames('sk-hw-mark-form__mark', {
              'sk-hw-mark-form__mark_scrolled': scrolled,
            })}
          >
            <FormattedMessage {...messages.mark}>
              {msg => (
                <TextInput
                  alignText={`${mark}`?.length <= 2 ? 'center' : 'left'}
                  name="mark"
                  helperText={touched.mark ? errors.mark : ''}
                  error={touched.mark && Boolean(errors.mark)}
                  fullWidth
                  smallInput
                  shrink
                  type="number"
                  adornment="/ 100"
                  adornmentSize="caption"
                  value={mark}
                  label={msg}
                  onBlur={handleInputBlur('mark')}
                  onChange={handleInputChange('mark')}
                  disabled={disabledMark}
                />
              )}
            </FormattedMessage>
          </div>

          <div className="sk-hw-mark-form__btn">
            <Button
              size="small"
              disabled={disabledSubmit}
              onClick={handleSubmit}
            >
              {isReviewSubmitted ? (
                <FormattedMessage {...messages.resubmit} />
              ) : (
                <FormattedMessage {...messages.submit} />
              )}
            </Button>
          </div>
        </form>
      );
    }}
  </Formik>
);

HWMarkForm.propTypes = {
  values: PropTypes.object,
  handleFormSubmit: PropTypes.func,
  handleSubmit: PropTypes.func,
  disabledMark: PropTypes.bool,
  disabledSubmit: PropTypes.bool,
  scrolled: PropTypes.bool,
  errors: PropTypes.object,
  touched: PropTypes.object,
  setFieldValue: PropTypes.func,
  handleBlur: PropTypes.func,
  mark: PropTypes.number,
};

export default memo(HWMarkForm);
