import React from 'react';

const Flag = () => (
<svg width="20" height="25" viewBox="0 0 20 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.2308 2.06405C13.26 -1.58023 8.29002 5.50723 3.31925 3.04233V2.51799C3.31925 1.86679 2.7998 1.33847 2.15958 1.33847C1.51936 1.33847 1 1.86679 1 2.51799V22.8206C1 23.4717 1.51936 24 2.15958 24C2.7998 24 3.31925 23.4717 3.31925 22.8206V14.2195C8.04387 16.5615 12.7686 10.275 17.494 12.7767C17.7121 12.8927 17.9742 12.885 18.1847 12.7544C18.3954 12.6247 18.5238 12.3928 18.5238 12.1416C18.5238 8.97657 18.5238 5.81132 18.5238 2.64537C18.5238 2.41585 18.4142 2.1984 18.2308 2.06405Z" stroke="#A9B6C8"/>
</svg>

);

export default Flag;
