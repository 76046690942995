import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  head: {
    id: 'app.components.DeactivationWarningModal.head',
    defaultMessage: '<b>You are about to deactivate your course.</b>',
  },
  yourCourse: {
    id: 'app.components.DeactivationWarningModal.yourCourse',
    defaultMessage: '<b>Your course will not be available for new sales until you re-activate it.</b>',
  },
  ifYourAreTeaching: {
    id: 'app.components.DeactivationWarningModal.ifYourAreTeaching',
    defaultMessage: 'If you are teaching on <b>Assist/Concierge</b> and would like a break, just <b>lower</b> your interactivity level <b>to Flex</b> instead and continue selling! You can <b>change it back later</b> at any time.',
  },
  ifYouCurrentlyHave: {
    id: 'app.components.DeactivationWarningModal.ifYouCurrentlyHave',
    defaultMessage: 'If you <b>currently have active students</b> who paid for <b>Assist</b> or <b>Concierge</b> levels, you <b style=\'color: #30d5c8;\'>MUST</b> continue providing full support (Web Classes, homework etc.) to them until they complete the course.',
  },
  deactivateCourse: {
    id: 'app.components.DeactivationWarningModal.deactivateCourse',
    defaultMessage: 'Deactivate course',
  },
});
