// Core
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withApollo, compose, graphql } from 'react-apollo';
import classNames from 'classnames';

// Components
import ContentHeader from 'containers/ContentHeader';
import Card from 'components/Card';
import Button from 'components/Button';
import Text from 'components/Text';
import DeleteConfirmationModal from 'routes/ManageAccount/DeleteAccount/DeleteConfirmationModal';
import Modal from 'components/Modal';

// Other
import { signOutUser } from 'services/aws/amplify/helpers';
import { withAWS } from 'containers/AWSProvider/withAWS';
import DELETE_PROFILE from 'mutations/UserProfile/deleteProfile.gql';
import { withSidebar } from 'containers/SidebarProvider/withSidebar';

import { FormattedMessage } from 'react-intl';
import { messages } from 'services/yup/messages';

// Styles and Assets
import './DeleteAccount.scss';

class DeleteAccount extends PureComponent {
  state = {
    isModalOpen: false,
  };

  handleDeleteAttempt = () => {
    this.setState({ isModalOpen: true });
  };

  hideModal = () => {
    this.setState({ isModalOpen: false });
  };

  handleDelete = async () => {
    const {
      history,
      client,
      deleteProfile,
      awsContext: { updateAWSConfig },
    } = this.props;
    try {
      await deleteProfile();
      this.hideModal();
      await signOutUser();
      await updateAWSConfig();
      client.cache.reset();
      history.push('/');
    } catch (error) {
      throw Error(error);
    }
  };

  render() {
    const { isModalOpen } = this.state;
    const {
      sidebarContext: { openedSidebar },
    } = this.props;
    return (
      <div className="sk-ma-delete sk-background-spot__primary">
        <div className="sk-main-layout-wrapper">
          <ContentHeader />
          <Card classes="sk-ma-delete__card">
            <div className="sk-ma-delete__inner">
              <Text classes="sk-ma-delete__text">
                <FormattedMessage {...messages.whenYouDeleteAcc} />
              </Text>
            </div>
            <div className="sk-ma-delete__footer">
              <Button
                variant="text"
                color="secondary"
                size="large"
                onClick={this.handleDeleteAttempt}
              >
                <FormattedMessage {...messages.deleteAccount} />
              </Button>
            </div>
          </Card>
          {isModalOpen && (
            <Modal
              component={() => (
                <DeleteConfirmationModal
                  handleConfirmation={this.handleDelete}
                  handleCancel={this.hideModal}
                />
              )}
              open
              hideModal={this.hideModal}
              size="sm"
              cardClasses={classNames('sk-ma-delete__modal', {
                'sk-ma-delete__modal_shifted': openedSidebar,
              })}
              shrink={false}
            />
          )}
        </div>
      </div>
    );
  }
}

const deleteProfileMutation = graphql(DELETE_PROFILE, {
  props: ({ mutate }) => ({
    deleteProfile: mutate,
  }),
});

const enhancer = compose(
  withAWS,
  withApollo,
  withRouter,
  withSidebar,
  deleteProfileMutation
);

DeleteAccount.propTypes = {
  sidebarContext: PropTypes.object,
};

export default enhancer(DeleteAccount);
