import React from 'react';
import PropTypes from 'prop-types';
import { Red, PurpleHeart } from 'components/Icon/color';

const Play = ({ color, strokeColor, height, width, active }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 10 12"
    width={width}
    height={height}
  >
    <g fill="none" fillRule="evenodd">
      <path
        stroke={strokeColor || color}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={active ? PurpleHeart : color}
        d="M1 1.852V9.96a1 1 0 0 0 1.549.836l6.177-4.054a1 1 0 0 0 0-1.672L2.549 1.016A1 1 0 0 0 1 1.852z"
      />
    </g>
  </svg>
);

Play.propTypes = {
  active: PropTypes.bool,
  height: PropTypes.string,
  width: PropTypes.string,
  color: PropTypes.string,
  strokeColor: PropTypes.string,
};

Play.defaultProps = {
  strokeColor: Red,
  color: Red,
  active: false,
  height: '12',
  width: '10',
};

export default Play;
