// Core
import React, { memo } from 'react';
import PropTypes from 'prop-types';

// Styles and Assets
import './WebinarGrid.scss';

const WebinarGrid = ({ children }) => (
  <div className="sk-webinar-grid sk-background-spot__terniary">
    <div className="sk-webinar-grid__content">
      <div className="sk-webinar-grid__block">{children}</div>
    </div>
  </div>
);

WebinarGrid.propTypes = {
  children: PropTypes.element,
};

export default memo(WebinarGrid);
