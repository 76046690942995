// Core
import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';

// Components
import Text from 'components/Text';
import RadioButtonGroup from 'components/RadioButtonGroup';

// Other
import {
  AVAILABLE_HOMEWORK_ACCESS_OPTIONS,
  AVAILABLE_LECTURE_ACCESS_OPTIONS,
} from 'routes/ExpertsCabinet/CourseEdit/Details/CourseInformation/AdditionalCourseDetails/enums.js';
import { getHomeworkAccessSettingsOptions } from 'routes/ExpertsCabinet/CourseEdit/Details/CourseInformation/helpers.js';
import { messages } from 'routes/ExpertsCabinet/CourseEdit/Details/messages';

// Styles
import 'routes/ExpertsCabinet/CourseEdit/Details/CourseInformation/AdditionalCourseDetails/MaterialsAccessSettings/index.scss';

const AccessSettings = ({
  disabled,
  variant,
  handleCoureVariantsAccessSettingsUpdate,
  intl: { formatMessage }
}) => {

  const setupCorrectHomeworkAccess = (options, permission) => {
    const disabled = options.filter(option=>option.disabled === false);
    const isHaveValue = disabled.some(value=>value.value === permission?.homeworks);

    return !isHaveValue ? permission?.lectures : permission?.homeworks;
  }

  const lecturesAccess = variant?.permissions?.lectures;
  const availableHomeworksOptions = getHomeworkAccessSettingsOptions(lecturesAccess) || [...AVAILABLE_HOMEWORK_ACCESS_OPTIONS];

  const homeworksAccess = setupCorrectHomeworkAccess(availableHomeworksOptions, variant?.permissions);
  const handleChange = type => selectedAccessSetting => {
    handleCoureVariantsAccessSettingsUpdate({ permission: selectedAccessSetting, type })
  };

  // Redo: cause initial course update mutation firing
  // useEffect(()=>{
  //   handleCoureVariantsAccessSettingsUpdate({ permission: homeworksAccess, type: 'homeworks' });
  // }, [homeworksAccess]);

  return (
    <div className="sk-form__row">
      <div className='sk-course-details-setting-item'>
        <Text>{formatMessage(messages.lecturesIsAvailable)}</Text>
        <RadioButtonGroup
          handleChange={handleChange('lectures')}
          name="available-homework"
          value={lecturesAccess}
          options={AVAILABLE_LECTURE_ACCESS_OPTIONS}
          disabled={disabled}
          classNameRadioGroup="sk-course-details-setting__radiobutton"
          classNameRadioButton="sk-course-details-setting__radiobutton__item"
        />
      </div>
      <div
        className='sk-course-details-setting-item'>
        <Text>{formatMessage(messages.homeWorkIsAvailable)}</Text>
        <RadioButtonGroup
          handleChange={handleChange('homeworks')}
          name="available-homework"
          value={homeworksAccess}
          options={availableHomeworksOptions}
          disabled={disabled}
          classNameRadioGroup="sk-course-details-setting__radiobutton"
          classNameRadioButton="sk-course-details-setting__radiobutton__item"
        />
      </div>
    </div>
  );
}

export default injectIntl(AccessSettings);
