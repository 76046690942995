// Core
import React, { useState, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { withApollo, compose, graphql } from 'react-apollo';
import Auth from '@aws-amplify/auth';
import { generatePath } from 'react-router';
import { injectIntl } from 'react-intl';
import moment from 'moment';
// Components
import SignUpAdditionalInfoForm from 'routes/Authenticator/FederatedVerification/SignUpAdditionalInfoForm';
import Loader from 'components/Loader';

// Other
import { withAuthenticatedUser } from 'containers/AuthenticatedUserProvider/withAuthenticatedUser';
import { withModal } from 'containers/ModalProvider/withModal';
import { withAWS } from 'containers/AWSProvider/withAWS';
import { withLanguage } from 'containers/LanguageProvider/withLanguage';
import queryString from 'query-string';

// Styles
import './index.scss';

const parseUserPoolAttributes = (attributes) => ({
  firstName: attributes['custom:first_name'],
  lastName: attributes['custom:last_name'],
  role: attributes['custom:role'],
  futureRole: attributes['custom:future_role'],
  email: attributes?.email
});

const FederatedVerification = ({
  client,
  awsContext: { updateAWSConfig },
  history,
  intl: { locale },
  languageContext: { updateLanguage },
  userContext: { verifyFederatedIdentity, isFederatedVerified, isUserAuthorized }
}) => {
  const handleErrorRedirect = (error, errorDescription) => {
    history.replace({
      pathname: generatePath('/:locale/auth/signup', { locale }),
      state: {
        error,
        errorDescription
      }
    });
  };

  const [externalProfileData, setExternalProfileData] = useState(null);
  const [cognitoUser, setCognitoUser] = useState(null);
  const [isLoading, setLoadingStatus] = useState(true);

  const handleRedirectAfterSignIn = useCallback(async () => {
    client.cache.reset();
    client.resetStore();

    try {
      await updateAWSConfig();
    } catch (error) {
      console.log('error updating aws', error);
      throw Error(error);
    }

    history.replace(generatePath('/:locale/courses', { locale }));
  }, []);

  const handlerFunction = async () => {
    if (history.location.hash) {
      const { error, error_description: errorDescription } = queryString.parse(history.location.hash);
      if (error) {
        handleErrorRedirect(error, errorDescription);
      }
    }
    const getExternalProfileData = async () => {
      if (isUserAuthorized) {
        try {
          const awsCredentials = await Auth.currentAuthenticatedUser();
          const { attributes } = awsCredentials;

          const parsedAttributes = parseUserPoolAttributes(attributes);
          setExternalProfileData(parsedAttributes);
          setCognitoUser(awsCredentials);
          if (parsedAttributes.firstName && parsedAttributes.lastName && parsedAttributes.email) {
            const castArgs = {
              'custom:role': 'student',
              'custom:future_role': 'student',
              'custom:first_name': parsedAttributes.firstName,
              'custom:last_name': parsedAttributes.lastName,
              'custom:timezone': moment.tz.guess(),
              'custom:interface_language': 'en',
              'custom:interface_currency': 'usd'
            };

            if (!parsedAttributes.role) {
              castArgs['custom:role'] = 'student';
            }
            if (!parsedAttributes.futureRole) {
              castArgs['custom:future_role'] = 'student';
            }

            await Auth.updateUserAttributes(awsCredentials, castArgs);
            await handleRedirectAfterSignIn();
          } else {
            setLoadingStatus(false);
          }
        } catch (err) {
          throw Error(err);
        }
      }
    };

    getExternalProfileData();

    if (isFederatedVerified && isUserAuthorized) {
      handleRedirectAfterSignIn();
    }
  };

  useEffect(() => {
    handlerFunction();
  }, [isUserAuthorized]);

  if (isLoading) return <Loader />;

  return (
    <>
      <SignUpAdditionalInfoForm
        handleRedirectAfterSignIn={handleRedirectAfterSignIn}
        externalProfileData={externalProfileData}
        cognitoUser={cognitoUser}
      />
    </>
  );
};

const enhancer = compose(withApollo, withAWS, withRouter, withLanguage, injectIntl, withAuthenticatedUser);

export default enhancer(FederatedVerification);
