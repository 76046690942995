// Core
import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose, graphql } from 'react-apollo';

// Components
import Card from 'components/Card';
import Loader from 'components/Loader';
import Text from 'components/Text';
import WebinarRoomCreator from 'containers/WebinarRoom/WebinarRoomCreator';

// GraphQL
import CREATE_WEBINAR_SESSION from 'mutations/Webinar/createWebinarSession.gql';

// Styles and Assets
import './index.scss';

const SessionNotCreatedBlock = ({ error, loading }) => (
  <div className="sk-web-ses-not-created">
    <Card classes="sk-web-ses-not-created__card">
      {loading && !error ? (
        <Loader />
      ) : (
        <Text>
          {(error?.message || '').replace('Error: GraphQL error:', '')}
        </Text>
      )}
    </Card>
  </div>
);

const WebinarSessionCreator = ({
  eventId,
  createWebinarSession,
  courseId
}) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [sessionId, setSessionId] = useState(null);
  const isSessionCreated = !error && !loading && !!sessionId;
  let isSubmitted = false;
  useEffect(() => {
    // Working with a promises
    createWebinarSession({
      event_id: eventId,
    }).then(res => {
      if (!isSubmitted) {
        const { data: {createWebinarSession: webinarSessionId }} = res;
        setSessionId(webinarSessionId);
        setLoading(false);
      }
    }).catch(err => {
      setLoading(false);
      setError(new Error(err));
    });
    return () => {
      isSubmitted = true;
    };
  }, [eventId]);

  return (
    <div className="sk-web-ses-creator">
      {(sessionId !== null) ? (
        <div className="sk-web-ses-creator__resovled">
          <WebinarRoomCreator
            eventId={eventId}
            courseId={courseId}
            sessionId={sessionId}
            participantRole="publisher"
          />
        </div>
      ) : (
        <SessionNotCreatedBlock error={error} loading={loading} />
      )}
    </div>
  );
};

const createWebinarSessionMutation = graphql(CREATE_WEBINAR_SESSION, {
  props: ({ mutate }) => ({
    createWebinarSession: input => mutate({ variables: { input } }),
  }),
});

const enhancer = compose(
  memo,
  createWebinarSessionMutation,
);

export default enhancer(WebinarSessionCreator);
