import React, { memo } from 'react';
import { compose } from 'react-apollo';
import { injectIntl } from 'react-intl';

// Components
import Text from 'components/Text';
import Button from 'components/Button';
import CancelCommissionModal from 'routes/ExpertsCabinet/ExpertSubscription/ActiveCommission/CancelCommissionModal';
import PlansFeatures from 'routes/ExpertsCabinet/ExpertsFee/PlansFeatures';

// Other
import { withModal } from 'containers/ModalProvider/withModal';
import { messages } from 'routes/ExpertsCabinet/ExpertSubscription/messages.js';
import { messages as expertFeeMessages } from 'routes/ExpertsCabinet/ExpertsFee/messages.js';

//Styles
import './index.scss';

const PREFIX = 'sk-experts-cabinet-commission-active';
const ActiveSubsctiption = ({
  maxCourses,
  billingPeriod,
  price,
  cancelSubscription,
  modalContext: { showModal, hideModal },
  intl: { formatMessage },
  ...props
}) => {
  const openCancelSubscriptionModal = () => showModal(CancelCommissionModal, {
    size: 'md',
    cancelSubscription
  });

  return (
    <div className={PREFIX}>
      <Text>{formatMessage(expertFeeMessages.commissionTabTitle)}</Text>
      <Text size='small'>{formatMessage(expertFeeMessages.commissionTabSecondaryTitle)}</Text>
      <PlansFeatures />
      <Button
        color="secondary"
        variant='text'
        onClick={openCancelSubscriptionModal}
      >
        {formatMessage(messages.updatePaymentPlan)}
      </Button>
    </div>
  );
};

const enhancer = compose(
  memo,
  injectIntl,
  withModal,
);

export default enhancer(ActiveSubsctiption);
