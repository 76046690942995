// Core
import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';

// Components
import Text from 'components/Text';
import Card from 'components/Card';

// Other
import { sharedMessages } from 'services/i18n/sharedMessages/messages';
import { messages } from 'routes/ManageAccount/ExpertSales/messages';

// Styles and Assets
import './EmptyCourseList.scss';

const EmptyCourseList = ({
  intl: { formatMessage },
}) => (
  <Card className="sk-expert-analytics-empty">
    <Text className="sk-expert-analytics-empty__text">{formatMessage(messages.noCourses)}</Text>
    <Text className="sk-expert-analytics-empty__text">{formatMessage(messages.createCourse)}</Text>
  </Card>
)

export default injectIntl(EmptyCourseList);
