// Core
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Other
import { isNotEmptyArray } from 'utils/helpers/index';

// Styles
import './FileUploader.scss';

class FileUploader extends PureComponent {
  onChooseFile = ({ target: { files } }) => {
    const { handleChange, maxSize, supportedMimes } = this.props;

    const hasFiles = !!files.length;

    const isFileSizeValid = hasFiles && [...files].every(file => file.size < maxSize * 1024);
    const isFileFormatValid = isNotEmptyArray(supportedMimes) ? hasFiles && [...files].every(({ type: memo }) => supportedMimes.some(format => memo?.includes(format))) : true;

    if (isFileSizeValid && isFileFormatValid) {
      handleChange(files);
    }
  };

  render() {
    const {
      children,
      supportedFormats,
      uploaderId,
      disabled,
      absolute,
    } = this.props;
    const inputId = `sk-file-upload-${uploaderId}`;

    return (
      <label
        className={classNames('sk-file-uploader__label', {
          'sk-file-uploader__label_absolute': absolute,
        })}
        htmlFor={inputId}
      >
        <input
          className="sk-file-uploader__hidden-input"
          type="file"
          accept={supportedFormats}
          onChange={this.onChooseFile}
          name="uploadPhoto"
          disabled={disabled}
          id={inputId}
        />
        {children}
      </label>
    );
  }
}

FileUploader.defaultProps = {
  handleChange: () => {},
  supportedFormats: '*',
};

FileUploader.propTypes = {
  children: PropTypes.any,
  handleChange: PropTypes.func,
  supportedFormats: PropTypes.string,
  uploaderId: PropTypes.string,
  disabled: PropTypes.bool,
  absolute: PropTypes.bool,

  /* max size of the video ( in Kb ) */
  maxSize: PropTypes.number,
};

export default FileUploader;
