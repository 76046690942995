// Core
import React, { memo } from 'react';
import { compose } from 'react-apollo';
import moment from 'moment';

// Components
import Text from 'components/Text';

// Other
// import { messages } from 'routes/AdminPanel/messages';

// Styles and Assets
import './index.scss';

const PREFIX = 'sk-admin-plan-page-analytics';
const PlanAnalytics = ({
  subscribersCount,
  updatedAt,
  ...props
}) => {

  // if (loading) return (<Loader />)

  return (
    <div className={PREFIX}>
      <Text classes='sk-admin-plan-page-section'>Analytics</Text>
      <Text>
        Number of subscriptions <Text size='small' bold={true}>{`${subscribersCount} `}</Text> 
        as of <Text size='small' bold={true}>{moment(updatedAt).format('DD.MM.YYYY')}</Text>
      </Text>
    </div>
  );
}

const enhancer = compose(
  memo,
);

export default enhancer(PlanAnalytics);
