// Core
import React from 'react';
import PropTypes from 'prop-types';
import FormHelperText from '@material-ui/core/FormHelperText';

// Styles and Assets
import 'utils/styles/Materialized.scss';

const HelperText = ({ children, error }) => (
  <FormHelperText
    error={error}
    classes={{
      error:
        'mui-override sk-materialized-input_error  sk-materialized-input_focused sk-materialized-input_label',
    }}
  >
    {children}
  </FormHelperText>
);

HelperText.propTypes = { children: PropTypes.string, error: PropTypes.bool };

export default HelperText;
