// Core
import React, { PureComponent } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';

// Styles and Assets
import './BuyCourse.scss';

// Components
import Text from 'components/Text';
import Image from 'components/Image';
import PriceRadioButtonLabel from 'components/CourseCard/PriceRadioButtonLabel';
import PriceBoard from 'components/PriceBoard';
import BlockInfo from 'routes/CourseDetails/BuyCourse/BlockInfo';
import CourseActionBlock from 'routes/CourseDetails/BuyCourse/CourseActionBlock';
import CourseFeatures from 'routes/CourseDetails/BuyCourse/CourseFeatures';

// Icons
import GiftIcon from 'components/Icon/SvgCss/Gift';

// Other
import { VARIANTS_NAMES } from 'routes/ExpertsCabinet/CourseEdit/enums';
import { hasVariant, getVariant, } from 'routes/ExpertsCabinet/CourseEdit/Details/CourseInformation/helpers.js';
import { messages } from 'routes/CourseDetails/BuyCourse/messages';
import { sharedMessages } from 'services/i18n/sharedMessages/messages';
import { isBlank, capitalize, getLectureDuration, getCourseThumbnail } from 'utils/helpers';

class BuyCourse extends PureComponent {
  state = {
    styleBlock: false,
    styleBlock2: false,
    styleBlockStart: false,
    top: 0,
    selectedVariant: {},
    // selectedVariant: !isBlank(this.props?.course.variants) ? this.props?.course.variants[0] : {},
    // selectedVariant: getVariant({ variants: this.props?.course.variants, variantName: 'flex' }),
  };

  scrollIsEnabled = false;

  componentDidMount() {
    this.scrollIsEnabled = true;
    if (this.scrollIsEnabled) {
      window.addEventListener('scroll', e => this.handleScroll(e));
    }

    const { variants, activeStudents, maxNumberOfUsers } = this.props?.course;
    let selectedVariant = {};

    for (let i = 0; i < variants.length; i++) {
      const variant = variants[i];
      if (variant?.name == 'flex' || ((variant?.name == 'assist' || variant?.name == 'concierge') && activeStudents < maxNumberOfUsers)) {
        selectedVariant = variant;
        break;
      }
    }

    this.setState({ selectedVariant });
  }

  componentWillUnmount() {
    this.scrollIsEnabled = false;
    window.removeEventListener('scroll', e => this.handleScroll(e));
  }

  handleScroll = e => {
    try {
      const scroll =
        (e && e.path && e.path[1]?.pageYOffset) || window.pageYOffset;
      const element = e && document.getElementById('scroll');
      if (this.scrollIsEnabled && e.target.body.offsetWidth > 1025) {
        const left = e && element?.clientHeight && element?.clientHeight - 175;
        const { styleBlock, styleBlock2, styleBlockStart } = this.state;
        if (scroll > 50 && styleBlockStart !== true) {
          this.setState({ styleBlockStart: true });
        }
        if (scroll < 50 && styleBlockStart !== false) {
          this.setState({ styleBlockStart: false });
        }
        if (scroll < left - 180) {
          this.setState({ styleBlock: false, styleBlock2: false });
        } else if (scroll > left - 180) {
          if (e.target.body.offsetHeight > 2778) {
            if (scroll > left - 180) {
              this.setState({ styleBlock2: true });
            }
          } else {
            this.setState({ styleBlock: true });
          }
        }
        if (
          e &&
          !styleBlock &&
          !styleBlock2 &&
          scroll > left - 180 &&
          e.target.body.offsetWidth > 1025
        ) {
          this.setState({ top: left - 110 });
        }
      }
    } catch (err) {
      if (err.preventDefaulf) {
        err.preventDefaulf();
      }
    }
  };

  getInteractivityLevelData = interactionLevel => {
    const {
      course: { variants, activeStudents, maxNumberOfUsers },
    } = this.props;
    return VARIANTS_NAMES.map(level => {
      const hasCourseVariant = hasVariant({ variants, variantName: level });
      const courseVariant = getVariant({ variants, variantName: level });

      const isUsersLimitExceeded = (courseVariant?.name == 'assist' || courseVariant?.name == 'concierge') &&
        activeStudents >= maxNumberOfUsers;
      const disabled = !hasCourseVariant || isBlank(courseVariant?.price) || isUsersLimitExceeded;
      const isFree = hasCourseVariant ?  courseVariant.price === 0 : false;

      return {
        disabled,
        value: capitalize(level),
        label: (
          <PriceRadioButtonLabel
            withIcon={isFree}
            icon={GiftIcon}
            price={courseVariant?.price}
            name={<FormattedMessage {...messages[level]} />}
            disabled={disabled}
          />
        ),
      };
    });
  };

  totalLection = lecture => {
    const numb = 0;
    if (lecture !== null) {
      return lecture.length;
    }
    return numb;
  };

  styleTopBlock = () => {
    const { styleBlock, styleBlock2, top } = this.state;
    if (styleBlock2 || styleBlock) {
      return { top: `${top}px` };
    }
    return null;
  };

  handlePriceSelect = variantName => {
    const { course: { variants }, } = this.props;
    const selectedVariant = getVariant({ variants, variantName: variantName.toLowerCase() });

    this.setState({ selectedVariant });
  };

  getChatsAndQuiz = (courseData) => {
    const options = {
      quiz: false,
      chat: false,
    }
    if(courseData?.lectures?.some(({materials}) => materials?.some(({type}) => type === 'quiz' || type==='lecture-quiz'))){
      options.quiz = true
    }
    if (!isBlank(courseData.messengerUrl)) {
      options.chat = true;
    }
    return options
  }

  render() {
    const {
      course: {
        author,
        id,
        title,
        thumbnail,
        coursePhoto,
        lectures,
        interactionLevel,
        language,
        complexity,
        category,
        subcategory,
        settings,
        skills,
        timeToCompletion,
        alreadyInShoppingCart,
        description,
        displayLectureNameType,
      },
      intl: { formatMessage },
      boughted,
    } = this.props;

    const {
      styleBlock,
      styleBlock2,
      styleBlockStart,
      selectedVariant,
    } = this.state;

    return (
      <div
        className={classNames(
          'sk-buy-course',
          { _stop: styleBlock },
          { _start: styleBlockStart },
          { _stop2: styleBlock2 }
        )}
        style={this.styleTopBlock()}
      >
        <div className="sk-buy-course__description">
          <div className="sk-buy-course__description__image">
            <Image src={getCourseThumbnail(thumbnail, coursePhoto)} />
          </div>
          <div className='sk-buy-course__description__info'>
            <BlockInfo
              category={category?.title}
              subcategory={subcategory?.title}
              complexity={complexity?.title.replace(' ', '')}
              duration={getLectureDuration(lectures)}
              language={language?.title}
            />
            <PriceBoard
              boughted={boughted}
              value={selectedVariant?.name}
              options={this.getInteractivityLevelData(interactionLevel)}
              handleChange={this.handlePriceSelect}
            />
            <CourseFeatures selectedVariant={selectedVariant} quizAndChatData={this.getChatsAndQuiz(this.props.course)} />
            <CourseActionBlock
              id={id}
              title={title}
              coursePhoto={coursePhoto}
              description={description}
              timeToCompletion={timeToCompletion}
              author={author}
              selectedVariant={{ name: selectedVariant?.name, price: selectedVariant?.price }}
              disabled={alreadyInShoppingCart}
              boughted={boughted}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(BuyCourse);
