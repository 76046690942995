import React from 'react';
import PropTypes from 'prop-types';

const MenuTips = ({ height, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 21 22"
    width={width}
    height={height}
  >
    <defs>
      <path id="a" d="M.85.421h4.053v1.42H.85z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#FF9D8C"
        fillRule="nonzero"
        d="M8.363 19.273L17 9.68a2 2 0 0 0-1.062-3.293L3.227 3.627a2 
        2 0 0 0-2.324 2.58l4.074 12.354a2 2 0 0 0 3.386.712z"
        opacity=".566"
      />
      <path
        fill="#333"
        d="M17 15.609c0-2.123.79-3.215 1.552-4.268.743-1.025 1.443-1.992 
        1.443-4.01 0-3.31-2.693-6.02-6.005-6.038h-.03c-3.314.018-6.007 2.728-6.007 
        6.038 0 2.018.7 2.985 1.443 4.01.764 1.053 1.554 2.145 1.554 4.268a.5.5 0 0 1-.998 
        0c0-1.799-.631-2.674-1.364-3.682-.766-1.057-1.633-2.254-1.633-4.596 0-3.665 2.833-6.695 
        6.425-7.008l-.003-.028h1.29l-.01.035c3.55.354 6.336 3.366 6.336 7.001 0 2.342-.865 
        3.539-1.631 4.596-.733 1.008-1.365 1.883-1.365 3.682a.5.5 0 0 1-.997 0zM17.619 18.218h-7.222a.5.5 
        0 0 1 0-.998h7.222a.498.498 0 1 1 0 .998M16.666 20.176H11.35a.5.5 0 0 1 0-.998h5.317a.498.498 0 1 1 0 .998"
      />
      <g transform="translate(11.132 20.09)">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <path
          fill="#333"
          d="M4.404 1.84H1.349a.498.498 0 1 1 0-.997h3.055a.5.5 0 0 1 0 .997"
          mask="url(#b)"
        />
      </g>
    </g>
  </svg>
);

MenuTips.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

MenuTips.defaultProps = {
  height: '22',
  width: '21',
};

export default MenuTips;
