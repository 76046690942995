// Core
import React, { PureComponent, createContext } from "react";
import { graphql, compose } from "react-apollo";
import { withRouter } from "react-router-dom";

// Components
import Loader from "components/Loader";
import GeneralInfo from "routes/ExpertsCabinet/CourseEdit/GeneralInfo";
import Details from "routes/ExpertsCabinet/CourseEdit/Details";
import CourseHero from "routes/Course/components/CourseHero";
import CourseDeleteModal from "routes/Course/components/CourseDeleteModal";
import RecommendationBlock from "routes/ExpertsCabinet/CourseEdit/RecommendationBlock";
import FormErrorsProvider from "containers/FormErrorsProvider/FormErrorsProvider";
import NotFoundCourse from "routes/ExpertsCabinet/CourseEdit/NotFoundCourse";
import CourseHasStudentsWarning from "routes/ExpertsCabinet/CourseEdit/GeneralInfo/ActionPanel/DeleteCourseButton/CourseHasStudentsWarning";

// GraphQL
import NOTIFY_ABOUT_COURSE_STATUS_CHANGE from "mutations/Mailing/notifyAboutCourseStatusChange.gql";
import GET_COURSE from "queries/Courses/getCourse.gql";
import UPDATE_COURSE from "mutations/Courses/updateCourse.gql";
import DELETE_COURSE from "mutations/Courses/deleteCourse.gql";

// Other
import { withLoader } from "containers/HOCs/withLoader";
import { withNotification } from "containers/NotificationProvider/withNotification";
import { withAuthenticatedUser } from "containers/AuthenticatedUserProvider/withAuthenticatedUser";
import { hasLoader } from "containers/HOCs/hasLoader";
import {
  isBlank,
  keysToSnake,
  parseFetchedData,
  isNull,
  keysToCamel,
  isCourseAdmin,
} from "utils/helpers";
import { withModal } from "containers/ModalProvider/withModal";
import { withSharedState } from "containers/SharedStateProvider/withSharedState";
import { withCourseStatusManager } from "routes/ExpertsCabinet/CourseEdit/HOCs/withCourseStatusManager";
import { CourseEditContext } from "routes/ExpertsCabinet/CourseEdit/Context";

// Styles and Assets
import "./CourseEdit.scss";

class CourseEdit extends PureComponent {
  validateCourseInfo = null;
  validateCourseDescription = null;
  validateCourseVariants = null;
  validateAdditionalCourseDetails = null;
  validateCourseAdminInformation = null;

  state = {
    // activeTab: 'courseStructure',
    activeTab: "courseInfo",
  };

  handleCourseUpdate = async (values, withRefetch = false) => {
    const {
      updateCourse,
      showNotificationBar,
      course,
      refetchCourse,
    } = this.props;

    const input = keysToSnake(values);

    try {
      await updateCourse({ id: course.id, ...input });

      if (withRefetch) {
        await refetchCourse();
      }

      showNotificationBar("saved");
    } catch (error) {
      await refetchCourse();
      throw Error(error);
    }
  };

  handleCoursePhotoUpload = async (coursePhoto) => {
    await this.handleCourseUpdate({ coursePhoto }, true);
  };

  handleCourseDelete = async ({ reasons = {} }) => {
    const {
      course: { id },
      history,
      deleteCourse,
      modalContext: { showModal, hideModal },
    } = this.props;
    try {
      const {
        data: { deleteCourse: deletedCourseStatus },
      } = await deleteCourse({ id, reasons });

      if (deletedCourseStatus == "REFUSE") {
        showModal(CourseHasStudentsWarning, {
          size: "md",
        });
      } else {
        history.push("/experts-cabinet/courses");
      }
    } catch (error) {
      throw Error(error);
    }
  };

  handleCourseSubmit = async (nextStatus, isAdmin, type) => {
    // console.log('handle course submit', this.validateCourseInfo, this.validateCourseDetails);
    // await this.handleSwitch('courseInfo');
    const {
      handleCourseStatusUpdate,
      sharedStateContext: { updateSharedState },
    } = this.props;

    let isCourseInfoValid = true;
    let isCourseDescriptionValid = true;
    let isCourseVariantsValid = true;
    let isAdditionalCourseDetailsValid = true;
    let isCourseAdminInformation = true;

    if (!isBlank(this.validateCourseInfo)) {
      isCourseInfoValid = await this.validateCourseInfo();
    }

    if (!isBlank(this.validateCourseDescription)) {
      isCourseDescriptionValid = await this.validateCourseDescription();
    }

    if (!isBlank(this.validateCourseVariants)) {
      isCourseVariantsValid = await this.validateCourseVariants();
    }

    if (!isBlank(this.validateAdditionalCourseDetails)) {
      isAdditionalCourseDetailsValid = await this.validateAdditionalCourseDetails();
    }

    if (!isBlank(this.validateCourseAdminInformation)) {
      isCourseAdminInformation = await this.validateCourseAdminInformation();
    }

    const isValid =
      isCourseInfoValid &&
      isCourseDescriptionValid &&
      isCourseVariantsValid &&
      isAdditionalCourseDetailsValid &&
      isCourseAdminInformation;

    if (isValid) {
      handleCourseStatusUpdate(nextStatus, isAdmin, type);
    } else {
      updateSharedState({
        courseInfo: { isValid: isCourseInfoValid },
        courseDescription: { isValid: isCourseDescriptionValid },
        courseVariants: { isValid: isCourseVariantsValid },
        additionalCourseDetails: { isValid: isAdditionalCourseDetailsValid },
        courseAdminInformation: { isValid: isCourseAdminInformation },
      });
    }
  };

  bindCourseMethod = ({ method, handler }) => {
    if (!isBlank(method) && !isBlank(handler)) {
      this[method] = handler;
    }
  };

  handleSwitch = async (activeTab) => {
    if (this.state.activeTab !== activeTab) {
      await this.setState({ activeTab });
    }
  };

  handleAdminRecommendationUpdate = (input) => {
    this.handleCourseUpdate({ recomendationFromAdmin: input });
  };

  render() {
    const {
      course,
      roles = false,
      refetchCourse,
      isCourseStatusUpdating,
      handleCourseStatusUpdate,
      userContext: {
        profile: { id: profileId, org },
      },
    } = this.props;

    if (!course) return <NotFoundCourse />;

    const { activeTab } = this.state;
    const parsedCourse = parseFetchedData(course);
    const { statusId, recomendationFromAdmin } = parsedCourse;

    if (
      statusId == "DELETED" ||
      (profileId !== course?.author?.id && !isCourseAdmin(roles))
    ) {
      return <NotFoundCourse />;
    }

    const isEditDisabled =
      (!isCourseAdmin(roles) && !!statusId.includes("REVIEW")) ||
      ["FIRST_REJECT", "SECOND_REJECT"].includes(statusId);

    return (
      <CourseEditContext.Provider
        value={{
          courseEditContext: {
            course: parsedCourse,
            handleCourseUpdate: this.handleCourseUpdate,
            handleCourseDelete: this.handleCourseDelete,
            bindCourseMethod: this.bindCourseMethod,
            handleCourseSubmit: this.handleCourseSubmit,
            handleCourseStatusUpdate,
          },
        }}
      >
        <div className="sk-course-edit">
          <CourseHero
            photo={parsedCourse?.coursePhoto}
            title={parsedCourse?.title}
            id={parsedCourse?.id}
            disableAll={isEditDisabled}
            handleCoursePhotoUpload={this.handleCoursePhotoUpload}
            handleInputChange={this.handleCourseUpdate}
          />
          <div className="sk-course-edit__main ">
            <div className="sk-course-edit__content sk-background-spot__primary">
              <FormErrorsProvider>
                <div className="sk-course-edit__inner sk-main-layout-wrapper">
                  <div className="sk-course-edit__item">
                    <GeneralInfo
                      course={parsedCourse}
                      disableAll={isEditDisabled}
                      roles={roles}
                      handleMailSend={this.handleMailSend}
                    />
                  </div>
                  <div className="sk-course-edit__item">
                    <Details
                      activeTab={activeTab}
                      handleSwitch={this.handleSwitch}
                      course={parsedCourse}
                      disableAll={isEditDisabled}
                      isCourseStatusUpdating={isCourseStatusUpdating}
                      handleCourseSubmit={this.handleCourseSubmit}
                      refetchCourse={refetchCourse}
                      org={org}
                    />
                  </div>
                  {((isCourseAdmin(roles) &&
                    parsedCourse?.statusId !== "DRAFT") ||
                    (recomendationFromAdmin &&
                      !isCourseAdmin(roles) &&
                      parsedCourse?.statusId !== "DRAFT" &&
                      parsedCourse?.statusId !== "FIRST_REVIEW")) && (
                    <div className="sk-course-edit__item">
                      <RecommendationBlock
                        message={recomendationFromAdmin || ""}
                        roles={roles}
                        handleInputChange={this.handleAdminRecommendationUpdate}
                      />
                    </div>
                  )}
                </div>
              </FormErrorsProvider>
            </div>
          </div>
        </div>
      </CourseEditContext.Provider>
    );
  }
}

const getCourseQuery = graphql(GET_COURSE, {
  props: ({ data: { getCourse, error, loading, refetch, ...ownProps } }) => {
    if (error) throw Error(error);

    return {
      course: getCourse,
      loading,
      error,
      refetchCourse: refetch,
      ...ownProps,
    };
  },

  options: ({
    match: {
      params: { id },
    },
  }) => ({
    fetchPolicy: "network-only",
    pollInterval: 600000,
    variables: { input: { id } },
  }),
});

const updateCourseMutation = graphql(UPDATE_COURSE, {
  props: ({ mutate }) => ({
    updateCourse: (input) => mutate({ variables: { input } }),
  }),
});

const deleteCourseMutation = graphql(DELETE_COURSE, {
  props: ({ mutate }) => ({
    deleteCourse: (input) => mutate({ variables: { input } }),
  }),
  options: () => ({
    awaitRefetchQueries: true,
    refetchQueries: ["listExpertCourses"],
  }),
});

const enhancer = compose(
  withAuthenticatedUser,
  withNotification,
  withRouter,
  withModal,
  getCourseQuery,
  updateCourseMutation,
  deleteCourseMutation,
  withCourseStatusManager,
  withSharedState,
  withLoader
);

export default enhancer(hasLoader(CourseEdit, GET_COURSE));
