import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  webinarSectionTitle: {
    id: 'app.containers.Calendar.title',
    defaultMessage: 'Web Сlasses',
  },
  addEventName: {
    id: 'app.containers.CreateEventModal.addEventName',
    defaultMessage: 'Add Event Name',
  },
  eventsDescription: {
    id: 'app.containers.CreateEventModal.eventsDescription',
    defaultMessage: 'Event’s Description',
  },
  chooseYourCourse: {
    id: 'app.containers.CreateEventModal.chooseYourCourse',
    defaultMessage: 'Choose your Course',
  },
  startDay: {
    id: 'app.containers.CreateEventModal.startDay',
    defaultMessage: 'Start Day',
  },
  startTime: {
    id: 'app.containers.CreateEventModal.startTime',
    defaultMessage: 'Start Time',
  },
  duration: {
    id: 'app.containers.CreateEventModal.duration',
    defaultMessage: 'Duration',
  },
  createEvent: {
    id: 'app.containers.CreateEventModal.createEvent',
    defaultMessage: 'Create Event',
  },
  delete: {
    id: 'app.containers.CreateEventModal.delete',
    defaultMessage: 'Delete',
  },
  saveChanges: {
    id: 'app.containers.CreateEventModal.saveChanges',
    defaultMessage: 'Save Changes',
  },
  editEvent: {
    id: 'app.containers.CreateEventModal.editEvent',
    defaultMessage: 'Edit Event',
  },
  startWebinar: {
    id: 'app.components.CourseNearestWebinar.startWebinar',
    defaultMessage: 'Start Web Class',
  },
  joinWebinar: {
    id: 'app.components.CourseNearestWebinar.joinWebinar',
    defaultMessage: 'Join',
  },
  youCanProduct: {
    id: 'app.components.CourseNearestWebinar.youCanProduct',
    defaultMessage: 'You can conduct webinar only from Google Chrome browser.',
  },
  ifItIsNotInstalled: {
    id: 'app.components.CourseNearestWebinar.ifItIsNotInstalled',
    defaultMessage:
      'If it is not installed in your computer you can download it',
  },
  here: {
    id: 'app.components.CourseNearestWebinar.here',
    defaultMessage: 'here',
  },
  createNewEvent: {
    id: 'app.components.CreateEventModal.createNewEvent',
    defaultMessage: 'Create New Event',
  },
});
