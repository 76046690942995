import React from 'react';
import PropTypes from 'prop-types';
import { PurpleLight } from 'components/Icon/color';

import classNames from 'classnames';

import './Basket.scss';

const Basket = ({ color=PurpleLight, active, height=21, width=23 }) => {
  return(
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 23 21"
      width={width}
      height={height}
      className="sk-basketIcon"
    >
      <mask id="mask_basket0" style={{maskType: "alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="19" height="17">
        <path fillRule="evenodd" clipRule="evenodd" d="M0.224609 0.1875H18.6868V16.6112H0.224609V0.1875Z" fill="white"/>
      </mask>
      <g mask="url(#mask_basket0)">
        <path fillRule="evenodd" clipRule="evenodd" d="M18.1384 16.6112H8.87151C7.74458 16.6112 6.65362 15.7586 6.38831 14.6698L3.82154 4.14199C3.66254 3.49056 3.00216 2.64029 2.40817 2.32242L0.51315 1.30837C0.246446 1.16581 0.146635 0.835024 0.290316 0.570208L0.342311 0.474247C0.485992 0.209201 0.818619 0.11001 1.08509 0.252798L2.98011 1.26662C3.87098 1.74366 4.75558 2.8825 4.99374 3.85988L7.56074 14.3875C7.69374 14.9335 8.30654 15.4124 8.87151 15.4124H18.1384C18.4413 15.4124 18.6869 15.6562 18.6869 15.957V16.0661C18.6869 16.3671 18.4413 16.6112 18.1384 16.6112"/>
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M17.809 14.1026H6.79938V12.9036H17.809C18.3972 12.9036 19.0796 12.4183 19.2695 11.8651L20.9517 6.96623C21.0192 6.77039 21.0118 6.60084 20.9312 6.4885C20.8507 6.37685 20.6915 6.31526 20.4832 6.31526H4.90088V5.11621H20.4832C21.0826 5.11621 21.6037 5.36211 21.9124 5.79117C22.2211 6.21999 22.287 6.7893 22.0935 7.35354L20.4113 12.2524C20.055 13.29 18.912 14.1026 17.809 14.1026" />
      <mask id="mask_basket1" mask-type="alpha" maskUnits="userSpaceOnUse" x="7" y="16" width="5" height="5">
        <path fillRule="evenodd" clipRule="evenodd" d="M7.40771 16.8369H11.13V20.536H7.40771V16.8369Z" fill="white"/>
      </mask>
      <g mask="url(#mask_basket1)">
        <path fillRule="evenodd" clipRule="evenodd" d="M9.26877 18.0359C8.90783 18.0359 8.6142 18.3277 8.6142 18.6862C8.6142 19.0451 8.90783 19.3369 9.26877 19.3369C9.62972 19.3369 9.92335 19.0451 9.92335 18.6862C9.92335 18.3277 9.62972 18.0359 9.26877 18.0359M9.26884 20.536C8.24288 20.536 7.40771 19.7061 7.40771 18.6862C7.40771 17.6667 8.24288 16.8369 9.26884 16.8369C10.295 16.8369 11.13 17.6667 11.13 18.6862C11.13 19.7061 10.295 20.536 9.26884 20.536" />
      </g>
      <mask id="mask_basket2" mask-type="alpha" maskUnits="userSpaceOnUse" x="15" y="16" width="4" height="5">
        <path fillRule="evenodd" clipRule="evenodd" d="M15.1641 16.8369H18.8861V20.536H15.1641V16.8369Z" fill="white"/>
      </mask>
      <g mask="url(#mask_basket2)">
        <path fillRule="evenodd" clipRule="evenodd" d="M17.0252 18.0359C16.664 18.0359 16.3704 18.3277 16.3704 18.6862C16.3704 19.0451 16.664 19.3369 17.0252 19.3369C17.3859 19.3369 17.6798 19.0451 17.6798 18.6862C17.6798 18.3277 17.3859 18.0359 17.0252 18.0359M17.0252 20.536C15.999 20.536 15.1641 19.7061 15.1641 18.6862C15.1641 17.6667 15.999 16.8369 17.0252 16.8369C18.0512 16.8369 18.8861 17.6667 18.8861 18.6862C18.8861 19.7061 18.0512 20.536 17.0252 20.536" />
      </g>
    </svg>
  );
}

export default Basket;
