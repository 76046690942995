// Core
import React, { memo, useState, useEffect } from "react";
import { compose, graphql } from "react-apollo";
import { FormattedMessage } from "react-intl";

// Components
// import Card from 'components/Card';
import Heading from "components/Heading";
// import Loader from 'components/Loader';
// import TopingCard from 'routes/LandingPage/LandingPageTopings/TopingCard';
// Carousel 1
// import Carousel from 'components/Carousel';
// import { Item } from 'components/Carousel/modules.js';
// Carousel 2
// import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
// import 'pure-react-carousel/dist/react-carousel.es.css';
// Carousel 3
import Slider from "components/NetflixCarousel";

// GraphQl
import LIST_COURSES_QUERY from "queries/Courses/listToppings.gql";

// Other
import { isBlank, keysToCamel } from "utils/helpers";
import { messages } from "../messages";

// Styles
import "./index.scss";

const LandingPageCarousel = ({ listToppings, alreadyBoughtedCourses }) => {
  const toppings = !isBlank(listToppings) ? listToppings.courses : [];
  const masterClasses = toppings.filter(
    (topping) => topping?.variants?.[0]?.name === "masterclass"
  );
  const podcasts = toppings.filter(
    (topping) => topping?.variants?.[0]?.name === "podcast"
  );

  return (
    <div className="sk-topic">
      {masterClasses?.length > 0 && (
        <div className="sk-topic-master-classes">
          <div className="grid landing">
            <Heading variant="h2">
              <FormattedMessage {...messages.masterclasses} />
            </Heading>
          </div>
          <Slider items={masterClasses} />
        </div>
      )}
      {podcasts?.length > 0 && (
        <div className="sk-topic-podcasts">
          <div className="grid landing">
            <Heading variant="h2">
              <FormattedMessage {...messages.podcasts} />
            </Heading>
          </div>
          <Slider items={podcasts} />
        </div>
      )}
    </div>
  );
};

const listToppingsQuery = graphql(LIST_COURSES_QUERY, {
  skip: (props) => !!props.skip,
  props: ({ data: { error, loading, listCourses, ...ownProps } }) => ({
    loading,
    listToppings: keysToCamel(listCourses),
    error,
    ...ownProps,
  }),
  options: () => ({
    fetchPolicy: "cache-and-network",
    variables: {
      input: {
        type: "topping",
        size: 36,
      },
    },
  }),
});

const enhancer = compose(memo, listToppingsQuery);

export default enhancer(LandingPageCarousel);
