// Core
import React, { useState, useEffect, useCallback } from 'react';
import { compose, graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';

// Components
import Text from 'components/Text';
import FilterForm from 'routes/components/Filters/FilterForm';
import ToppingCard from 'routes/components/ToppingCard';
import FlatDropDown from 'components/DropDown/FlatDropDown';
import FiltersIcon from 'components/Icon/Svg/FiltersIcon'

// Other
import { keysToCamel } from 'utils/helpers';
import { TOPPINGS_STATUSES_OPTIONS } from 'routes/ExpertsCabinet/ToppingEdit/enums.js';
import { setCheckedOptions } from 'routes/components/Filters/helpers.js';

// Styles
import './index.scss';

const COMPONENT_PREFIX = 'sk-admin-filter-status';
const StatusFilter = ({
  children,
  filter,
  searchToppings
}) => {
  const type = 'status_id';
  const statusFilter = filter.find(filter => filter.key == type);
  const [filters, setFilters] = useState(statusFilter?.values || []);

  const setFiltersHandler = (name) => (isTypeChecked, event) => {
    let newChecked = [...filters];

    if (isTypeChecked) {
      newChecked = [...newChecked, name];
    } else {
      newChecked = newChecked.filter(checkboxName => checkboxName !== name);
    };

    setFilters(newChecked);
  };
  const handleApply = useCallback(() => searchToppings(type, filters), [filters, searchToppings]);

  return(
    <div className={COMPONENT_PREFIX}>
      <FlatDropDown
        type={type}
        placement='bottom-end'
        component={FilterForm}
        options={setCheckedOptions(TOPPINGS_STATUSES_OPTIONS, filters)}
        keyName='value'
        labelName='label'
        button="Apply"
        handleChange={setFiltersHandler}
        handleApply={handleApply}
      >
        {children}
      </FlatDropDown>
    </div>
  )
};


export default StatusFilter;
