// Core
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Components
import TextAreaInput from 'components/Input/Material/TextAreaInput';

// Other
import { FormattedMessage } from 'react-intl';
import { messages } from 'routes/Course/components/ComplexitySelect/messages';

const LectureDescription = ({ handleInputChange, description }) => {
  const [currnetDescription, setCurrentDescription] = useState(description);
  const handleOnChange = inputValue => setCurrentDescription(inputValue);

  return (
    <div className="sk-lec-details-desc">
      <FormattedMessage {...messages.lectureDesc}>
        {msg => (
          <TextAreaInput
            name="lectureDescription"
            value={currnetDescription}
            label={msg}
            onChange={handleOnChange}
            onBlur={handleInputChange('description')}
          />
        )}
      </FormattedMessage>
    </div>
  );
};

LectureDescription.defaultProsp = {
  handleInputChange: () => {},
  description: null,
};

LectureDescription.propTypes = {
  handleInputChange: PropTypes.func,
  description: PropTypes.string,
};

export default LectureDescription;
