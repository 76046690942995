// Core
import React from 'react';
import PropTypes from 'prop-types';

// Components
import Button from 'components/Button';
import Heading from 'components/Heading';
import Text from 'components/Text';

import { FormattedMessage } from 'react-intl';
import { messages } from 'routes/Authenticator/AuthModal/messages';

// Styles
import './AuthModal.scss';

const AuthModal = ({ handleClick }) => (
  <div className="sk-auth-modal">
    <div className="sk-auth-modal_header">
      <Heading bold classes="sk-auth-modal_heading" variant="h3">
        <FormattedMessage {...messages.confirmEmail} />
      </Heading>
    </div>
    <div className="sk-auth-modal_content sk-modal-content">
      <div className="mui-override sk-modal-content_block">
        <Text classes="mui-override sk-modal-content_text">
          <FormattedMessage {...messages.pleaseCheckInbox} />
        </Text>
      </div>
    </div>
    <div className="sk-auth-modal_footer">
      <Button color="secondary" size="large" onClick={handleClick}>
        <FormattedMessage {...messages.contToLogin} />
      </Button>
    </div>
  </div>
);

AuthModal.propTypes = {
  handleClick: PropTypes.func,
};

export default AuthModal;
