// Core
import React, { PureComponent } from 'react';
import { graphql, compose } from 'react-apollo';
import { injectIntl, intlShape } from 'react-intl';

// Components
import SimpleSelect from 'components/Select/SimpleSelect';

// GraphQl
import LIST_CATEGORIES_QUERY from 'queries/Courses/listCategories.gql';
import { sharedMessages } from 'services/i18n/sharedMessages/messages';

class CategorySelect extends PureComponent {
  parseCategoriesList = (categories = []) => {
    const {
      intl: { formatMessage }
    } = this.props;

    const sortedCategories = categories.sort(
      (prev, cur) => prev.order_number - cur.order_number
    );
    return sortedCategories.map(({ id, title, localized }) => ({
      id,
      title:
        localized ||
        (sharedMessages[title] && formatMessage(sharedMessages[title])) ||
        formatMessage(sharedMessages.others)
    }));
  };

  render() {
    const { error, fetchError, loading, listCategories, ...props } = this.props;
    console.log('listCategories', listCategories);
    if (fetchError) return null;

    return (
      <SimpleSelect
        inlineStyles={{ selector: { minHeight: 40 } }}
        options={this.parseCategoriesList(listCategories?.items)}
        loading={loading}
        error={error}
        valueKey='id'
        labelKey='title'
        {...props}
      />
    );
  }
}

const listCategoriesQuery = graphql(LIST_CATEGORIES_QUERY, {
  props: ({
    data: { loading, error: fetchError, listCategories, ...ownProps }
  }) => ({
    loading,
    listCategories,
    fetchError,
    ...ownProps
  }),
  options: () => ({
    fetchPolicy: 'cache-and-network'
  })
});

CategorySelect.propTypes = {
  intl: intlShape.isRequired
};

const enhacner = compose(injectIntl, listCategoriesQuery);

export default enhacner(CategorySelect);
