import React, { memo } from 'react';
import { compose } from 'react-apollo';

const Icon = ({ ...props }) => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="10.2939" y="11.7646" width="4.41177" height="1.47059" fill="white"/>
    <path d="M9.56431 17.7887L6.83321 20.4641C6.5601 20.7317 6.15044 20.531 6.15044 20.1966V17.9894C6.15044 17.8556 6.08216 17.7887 5.94561 17.7887H5.53594C4.10212 17.7887 2.94141 16.6516 2.94141 15.2471V6.95327C2.94141 5.54867 4.10212 4.41162 5.53594 4.41162H19.4645C20.8983 4.41162 22.0591 5.54867 22.0591 6.95327V15.2471C22.0591 16.6516 20.8983 17.7887 19.4645 17.7887H9.90569H9.56431Z" stroke="url(#paint0_linear)" stroke-miterlimit="10"/>
    <path d="M7.35352 7.58789C7.07737 7.58789 6.85352 7.81175 6.85352 8.08789C6.85352 8.36403 7.07737 8.58789 7.35352 8.58789L7.35352 7.58789ZM17.6476 8.58789C17.9238 8.58789 18.1476 8.36403 18.1476 8.08789C18.1476 7.81175 17.9238 7.58789 17.6476 7.58789L17.6476 8.58789ZM7.35352 8.58789L17.6476 8.58789L17.6476 7.58789L7.35352 7.58789L7.35352 8.58789Z" fill="url(#paint1_linear)"/>
    <path d="M7.35352 10.5293C7.07737 10.5293 6.85352 10.7532 6.85352 11.0293C6.85352 11.3054 7.07737 11.5293 7.35352 11.5293L7.35352 10.5293ZM17.6476 11.5293C17.9238 11.5293 18.1476 11.3054 18.1476 11.0293C18.1476 10.7532 17.9238 10.5293 17.6476 10.5293L17.6476 11.5293ZM7.35352 11.5293L17.6476 11.5293L17.6476 10.5293L7.35352 10.5293L7.35352 11.5293Z" fill="url(#paint2_linear)"/>
    <path d="M7.35352 13.4702C7.07737 13.4702 6.85352 13.6941 6.85352 13.9702C6.85352 14.2464 7.07737 14.4702 7.35352 14.4702L7.35352 13.4702ZM11.03 14.4702C11.3061 14.4702 11.53 14.2464 11.53 13.9702C11.53 13.6941 11.3061 13.4702 11.03 13.4702L11.03 14.4702ZM7.35352 14.4702L11.03 14.4702L11.03 13.4702L7.35352 13.4702L7.35352 14.4702Z" fill="url(#paint3_linear)"/>
    <defs>
    <linearGradient id="paint0_linear" x1="23.7901" y1="21.6769" x2="0.00734041" y2="18.2291" gradientUnits="userSpaceOnUse">
    <stop stop-color="#8F26AF"/>
    <stop offset="0.979167" stop-color="#141685"/>
    </linearGradient>
    <linearGradient id="paint1_linear" x1="18.5797" y1="9.1552" x2="13.5403" y2="2.79157" gradientUnits="userSpaceOnUse">
    <stop stop-color="#8F26AF"/>
    <stop offset="0.979167" stop-color="#141685"/>
    </linearGradient>
    <linearGradient id="paint2_linear" x1="18.5797" y1="12.0966" x2="13.5403" y2="5.73298" gradientUnits="userSpaceOnUse">
    <stop stop-color="#8F26AF"/>
    <stop offset="0.979167" stop-color="#141685"/>
    </linearGradient>
    <linearGradient id="paint3_linear" x1="11.3629" y1="15.0375" x2="7.48241" y2="13.2875" gradientUnits="userSpaceOnUse">
    <stop stop-color="#8F26AF"/>
    <stop offset="0.979167" stop-color="#141685"/>
    </linearGradient>
    </defs>
    </svg>
    
  );
};

const enhancer = compose(
  memo,
);

export default enhancer(Icon);
