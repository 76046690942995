// Core
import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';

// Components
import Overlay from 'components/Overlay';
import Loader from 'components/Loader';
import Image from 'components/Image';
import CourseContentHeader from 'routes/Course/components/CourseContentHeader';
import CourseEditableTitle from 'routes/Course/components/CourseHero/CourseEditableTitle';

// Styles and Assets
import './CourseHero.scss';

// Other
import { s3UploadPublicCoursePhoto } from 'services/aws/amplify/helpers';

const handleFileChange = (
  downloadCallback,
  setLoadingStatus,
  id
) => async file => {
  try {
    setLoadingStatus(true);
    const data = await s3UploadPublicCoursePhoto(file, id);
    await downloadCallback(data);
    setLoadingStatus(false);
  } catch (error) {
    setLoadingStatus(false);
    throw Error(error);
  }
};

const CourseHero = ({
  disableAll,
  title,
  photo,
  id,
  handleCoursePhotoUpload,
  handleInputChange,
}) => {
  const [photoUrl, setUrl] = useState(null);
  const [photoIsLoading, setLoadingStatus] = useState(false);

  if (photo !== photoUrl) {
    setUrl(photo);

    if (photoIsLoading) {
      setLoadingStatus(false);
    }
  }

  return (
    <div className="sk-course-hero">
      {photoIsLoading && <Loader overlay center />}

      <div className="sk-course-hero__image">
        {photoUrl && <Overlay />}
        <Image src={photoUrl || '/assets/images/сourse-photo-placeholder.jpg'} />
      </div>
      <div className="sk-course-hero__wrapper sk-main-layout-wrapper">
        <div className="sk-course-hero__header ">
          <CourseContentHeader
            id={id}
            photo={photoUrl}
            title={title}
            disabled={disableAll}
            handleFileChange={handleFileChange(
              handleCoursePhotoUpload,
              setLoadingStatus,
              id
            )}
          />
        </div>
        <div className="sk-course-hero__inner">
          <div className="sk-course-hero__title">
            {title && (
              <CourseEditableTitle
                handleInputChange={handleInputChange}
                title={title}
                editDisabled={disableAll}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

CourseHero.propTypes = {
  disableAll: PropTypes.bool,
  title: PropTypes.string,
  photo: PropTypes.string,
  id: PropTypes.string,
  handleInputChange: PropTypes.func,
  handleCoursePhotoUpload: PropTypes.func,
};

export default memo(CourseHero);
