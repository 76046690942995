// Core
import React from 'react';

// Components
import Checkbox from 'components/Checkbox';
import Button from 'components/Button';

// Styles
import './index.scss';

const FilterWithCheckbox = ({
  type,
  options,
  keyName,
  labelName,
  handleApply,
  handleChange,
  button
}) => {
  return(
    <div className="sk-expert-filter-status-form">
      {options && options.map(option=>(
        <Checkbox
          name={option[keyName]}
          value={option[keyName]}
          checked={option?.isChecked}
          label={option[labelName]}
          key={option[keyName]}
          handleChange={handleChange(option[keyName])}
        />
      ))}
      <Button
        color="secondary"
        transparent
        size='small'
        onClick={handleApply}
      >
        {button}
      </Button>
    </div>
  );
}

export default FilterWithCheckbox;
