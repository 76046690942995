// Core
import React from 'react';

// Components
import Card from 'components/Card';
import HowToCreateCourse from 'containers/HowToCreateCourse';

// Styles and Assets
import './CourseCreateFAQ.scss';

const CourseCreateFAQ = () => (
  <div className="sk-course-create-faq sk-background-spot__secondary">
    <div className="sk-course-create-faq_wrapper">
      <Card classes="sk-course-create-faq_card">
        <div className="sk-course-create-faq_content">
          <HowToCreateCourse />
        </div>
      </Card>
    </div>
  </div>
);

export default CourseCreateFAQ;
