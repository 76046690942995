// Core
import React, { useState, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

// Components
import Cookie from 'components/Icon/Svg/Cookie';
import Button from 'components/Button';
import Text from 'components/Text';

// Other
import { getCookieByKey, isBlank, setCookieByKey, isTrue } from 'utils/helpers';
import { messages } from './messages';
import { withFormattedHtmlMessage } from 'containers/HOCs/withFormattedHtmlMessage';

// Styles
import './CookieBanner.scss';

const CookieBanner = ({ formatHtmlMessage }) => {
  const [accept, setAccept] = useState(false);

  const handleCookieAction = useCallback(() => {
    if (isBlank(getCookieByKey('cookieAccept'))) {
      setCookieByKey('cookieAccept', true);
      setAccept(true);
    }
  }, []);

  useEffect(() => {
    setAccept(isTrue(getCookieByKey('cookieAccept')));
  }, []);

  return (
    <div
      className={classNames('sk-cookie-banner grid landing', { hide: accept })}
    >
      <div className="sk-cookie-banner__content">
        <Cookie />
        <Text className="sk-cookie-banner__content__description">
          {formatHtmlMessage(messages.cookieMessage)}
        </Text>
        <Button
          variant="text"
          color="primary"
          transparent
          type="button"
          onClick={handleCookieAction}
          className="sk-cookie-banner__content__button"
        >
          <FormattedMessage {...messages.accept} />
        </Button>
        <Button
          variant="text"
          color="secondary"
          transparent
          type="button"
          onClick={handleCookieAction}
          className="sk-cookie-banner__content__button"
        >
          <FormattedMessage {...messages.decline} />
        </Button>
      </div>
    </div>
  );
};

export default withFormattedHtmlMessage(CookieBanner);
