import React from 'react';
import classnames from 'classnames';

import './index.scss';

const IconOnHover = ({ linearId }) => (
  <svg className='filled' width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.9 8.5C15.8 8.2 15.6 7.8 15.1 7.4L5.7 0.3C5.4 0.1 5 0 4.7 0C4 0 3.4 0.2 3.2 0.8C3 1.5 3 1.6 3 1.7V16.3C3 16.4 3 16.8 3.2 17.2C3.4 17.7 3.9 18 4.7 18H4.8C5.1 18 5.4 18 5.8 17.7L15.2 10.6C15.7 10.2 15.9 9.8 16 9.5C16 9.2 16 8.8 15.9 8.5Z" fill={`url(#paint1_linear_${linearId})`} />
    <defs>
    <linearGradient id={`paint1_linear_${linearId}`} x1="17.1771" y1="19.2116" x2="0.793534" y2="17.7601" gradientUnits="userSpaceOnUse">
    <stop stopColor="#8F26AF"/>
    <stop offset="0.979167" stopColor="#141685"/>
    </linearGradient>
    </defs>
  </svg>
);

const IconDefault = () => (
  <svg className='outlined' width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.66424 17.0143L3.65651 16.995L3.64721 16.9764C3.50209 16.6861 3.5 16.3856 3.5 16.3V1.7C3.5 1.69848 3.49991 1.69689 3.49981 1.69502C3.49838 1.66935 3.49408 1.59191 3.67734 0.949355C3.77169 0.680515 4.05653 0.5 4.7 0.5C4.90881 0.5 5.2032 0.574045 5.41166 0.70881L14.7929 7.79461C15.2027 8.12411 15.3517 8.43622 15.4257 8.65811C15.4887 8.84722 15.4986 9.12224 15.4998 9.41423C15.4178 9.62912 15.2599 9.91028 14.8929 10.2054L5.5 17.3C5.49974 17.3002 5.49947 17.3004 5.49921 17.3006C5.35136 17.4113 5.23978 17.4546 5.15007 17.4753C5.05075 17.4982 4.95524 17.5 4.8 17.5H4.7C4.02518 17.5 3.76263 17.2603 3.66424 17.0143Z" stroke="#6D7C90"/>
  </svg>
);

const Icon = ({ disabled }) => {
  const randomFloatString = `${Math.random()}`
  return (
    <div className={classnames({ 'icon-with-hover': !disabled })}>
      <IconDefault />
      {!disabled && (<IconOnHover linearId={randomFloatString} />)}
    </div>
  );
};

export default Icon;
