// Core
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';
import copy from 'clipboard-copy';
import { withRouter } from 'react-router-dom';
import { compose, } from 'react-apollo';

// Components
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Text from 'components/Text';
import Button from 'components/Button';
import CrossRoundedTrasparent from 'components/Icon/Svg/CrossRoundedTrasparent';
import CheckRoundedTrasparent from 'components/Icon/Svg/CheckRoundedTrasparent';

// Other
import { sharedMessages } from 'services/i18n/sharedMessages/messages';

// Styles and Assets
import './DiscountsTableRow.scss';

const classes = {
  tableCell: { root: 'mui-override sk-admin-discounts-table-row' },
};

const DiscountsTableRow = memo(({ item, index, arr, intl: { formatMessage }, history }) => {
  const openDiscountPage = event => {
    event.preventDefault();
    event.stopPropagation();
    history.push(`/admin/panel/discounts/edit/${item.id}`);
  }
  const copyToClipboard = (event, text) => {
    event.preventDefault();
    event.stopPropagation();
    copy(item.code);
  }
  const isDiscountStarted = Date.now() > item.startTime;
  const isDiscountExpired = Date.now() > item.endTime;

  return (
    <TableRow
      key={item.id}
      className='mui-override sk-admin-discounts-table-row'
      onClick={openDiscountPage}
    >
      <TableCell
        classes={classes.tableCell}
        className={`sk-admin-discounts-table-row__tableBodyCellLeft ${index ===
          arr.length - 1 && '_last'}`}
        align="center"
      >
        {(item.status === 'ACTIVE' && isDiscountStarted && !isDiscountExpired) ? (
          <CheckRoundedTrasparent width={32} height={32} />
          ): (
          <CrossRoundedTrasparent width={32} height={32} />
        )}

      </TableCell>
      <TableCell
        classes={classes.tableCell}
        className={`sk-admin-discounts-table-row__tableBodyCellLeft ${index ===
          arr.length - 1 && '_last'}`}
        align="left"
      >
        <Text size="basic">
          {item.name}
        </Text>
      </TableCell>
      <TableCell
        classes={classes.tableCell}
        className={`sk-admin-discounts-table-row__tableBodyCellLeft ${index ===
          arr.length - 1 && '_last'}`}
        align="left"
      >
        <Text size="basic">
          {item.code}
        </Text>
      </TableCell>
      <TableCell
        classes={classes.tableCell}
        className={`sk-admin-discounts-table-row__tableBodyCellLeft ${index ===
          arr.length - 1 && '_last'}`}
        align="left"
      >
        <Button
          size='basic'
          onClick={(event) => copyToClipboard(event, item.code)}
        >
          Copy
        </Button>
      </TableCell>
    </TableRow>
)});

DiscountsTableRow.propTypes = {
  intl: intlShape.isRequired,
  item: PropTypes.object,
  index: PropTypes.number,
  arr: PropTypes.array,
};

const enhancer = compose(
  withRouter,
  injectIntl
)

export default enhancer(DiscountsTableRow);
