import { defineMessages } from "react-intl";

export const messages = defineMessages({
  passRecoveryCode: {
    id: "app.components.ConfModel.passRecoveryCode",
    defaultMessage: "Password recovery code has been sent to your email",
  },
  continue: {
    id: "app.components.ConfModel.continue",
    defaultMessage: "Log in",
  },
});
