// Core
import React, { PureComponent, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Components
import Link from 'components/Link';
import Toggler from 'components/Toggler';

// Other
import { PurpleHeart, SidebarDefault } from 'components/Icon/color';

class SimpleSidebarMenuItem extends PureComponent {
  handleClick = () => {
    const { handleSubMenuTitleClick, id } = this.props;
    return handleSubMenuTitleClick(id);
  };

  renderListItem = () => {
    const {
      withSubMenu,
      subMenu,
      icon: Icon,
      label,
      path,
      isSubMenuOpen,
      match: { url },
    } = this.props;
    const active = url.includes(path) || isSubMenuOpen;

    return (
      <li
        className={classNames(
          'sk-simple-sidebar-menu__item',
          { active },
          { subMenu },
          { 'sk-simple-sidebar-menu__subTitle': !Icon && withSubMenu }
        )}
      >
        {Icon && (
          <div className="sk-simple-sidebar-menu__icon" style={{ display: 'flex', width: 16 }}>
            <Icon
              width="12"
              height="12"
              color={active ? PurpleHeart : SidebarDefault}
            />
          </div>
        )}

        <span>{label}</span>
        {withSubMenu && (
          <span>
            {
              <Toggler
                active={isSubMenuOpen}
                chevronUpProps={{ width: '10', height: '6' }}
                chevronDownProps={{ width: '10', height: '6' }}
                activeColor={PurpleHeart}
              />
            }
          </span>
        )}
      </li>
    );
  };

  render() {
    const { withSubMenu, darkSubmenu, path } = this.props;

    return (
      <button
        type="button"
        onClick={this.handleClick}
        className={classNames('sk-simple-sidebar-menu__btn', {
          'sk-simple-sidebar-menu__btn_darkSubmenu': darkSubmenu,
        })}
      >
        {withSubMenu ? (
          <Fragment>{this.renderListItem()}</Fragment>
        ) : (
          <Link classes="sk-simple-sidebar-menu__link" to={path}>
            {this.renderListItem()}
          </Link>
        )}
      </button>
    );
  }
}

SimpleSidebarMenuItem.defaultProps = {
  subMenu: false,
  handleSubMenuTitleClick: () => {},
};

SimpleSidebarMenuItem.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  match: PropTypes.object,
  subMenu: PropTypes.bool,
  withSubMenu: PropTypes.bool,
  icon: PropTypes.func,
  handleSubMenuTitleClick: PropTypes.func,
  isSubMenuOpen: PropTypes.bool,
  id: PropTypes.string,
  darkSubmenu: PropTypes.bool,
};

export default withRouter(SimpleSidebarMenuItem);
