import { defineMessages } from "react-intl";

export const messages = defineMessages({
  subscriptionTitle: {
    id: "app.routes.ExpertsCabinet.ExpertsSubscription.subscriptionTitle",
    defaultMessage: "{title} plan is active",
  },
  availableCoursesTitle: {
    id: "app.routes.ExpertsCabinet.ExpertsSubscription.availableCoursesTitle",
    defaultMessage: "Number of allowed active courses",
  },
  availableCourses: {
    id: "app.routes.ExpertsCabinet.ExpertsSubscription.availableCourses",
    defaultMessage:
      "Active <span style='color: #20292F; font-weight: 500'>{totalActiveCourses}</span> courses out of <span style='color: #20292F; font-weight: 500'>{maxCourses}</span>",
  },
  totalPriceTitle: {
    id: "app.routes.ExpertsCabinet.ExpertsSubscription.totalPriceTitle",
    defaultMessage: "Subscription",
  },
  paymentDetailsTitle: {
    id: "app.routes.ExpertsCabinet.ExpertsSubscription.paymentDetailsTitle",
    defaultMessage: "Payment method",
  },
  cardName: {
    id: "app.routes.ExpertsCabinet.ExpertsSubscription.cardName",
    defaultMessage:
      "{brand}, ending with <span style='color: #20292F; font-weight: 500'>{last4}</span>",
  },
  cardExpiration: {
    id: "app.routes.ExpertsCabinet.ExpertsSubscription.cardExpiration",
    defaultMessage:
      "Expiration: <span style='color: #20292F; font-weight: 500'>{expiresAt}</span>",
  },
  expirationTitle: {
    id: "app.routes.ExpertsCabinet.ExpertsSubscription.expirationTitle",
    defaultMessage: "Subscription valid until / Next payment date",
  },
  cancelSubscriptionTitle: {
    id: "app.routes.ExpertsCabinet.ExpertsSubscription.cancelSubscriptionTitle",
    defaultMessage: "Subscription successfully canceled",
  },
  cancelSubscriptionDetails: {
    id:
      "app.routes.ExpertsCabinet.ExpertsSubscription.cancelSubscriptionDetails",
    defaultMessage:
      "Payment plan {title} will continue until <span style='color: #20292F; font-weight: 500'>{expiresAt}</span>.",
  },
  cancelSubscriptionConsequences: {
    id:
      "app.routes.ExpertsCabinet.ExpertsSubscription.cancelSubscriptionConsequences",
    defaultMessage:
      "Subscription will be canceled on {expiresAt}, and all active courses will be deactivated.",
  },
  unlimCoursesTitle: {
    id: "app.routes.ExpertsCabinet.ExpertsSubscription.unlimCoursesTitle",
    defaultMessage: "Unlimited course placement has been set up!",
  },
  unlimCoursesSubTitle: {
    id: "app.routes.ExpertsCabinet.ExpertsSubscription.unlimCoursesSubTitle",
    defaultMessage:
      "You may now publish an unlimited number of courses for 20% of sales.",
  },
  successCancel: {
    id: "app.routes.ExpertsCabinet.ExpertsSubscription.successCancel",
    defaultMessage: "Subscription successfully canceled",
  },
  expiredSubscriptionTitle: {
    id:
      "app.routes.ExpertsCabinet.ExpertsSubscription.expiredSubscriptionTitle",
    defaultMessage: "Subscription suspended",
  },
  expiredSubscriptionText: {
    id: "app.routes.ExpertsCabinet.ExpertsSubscription.expiredSubscriptionText",
    defaultMessage:
      "<span style='color: #20292F; font-weight: 500'>Unfortunately, the payment for your \"{title}\" plan subscription has not gone through. At the moment all courses have been deactivated.</span> Please update payment method to renew your subscription and activate your preferred courses.",
  },
  selectSubscriptionButton: {
    id:
      "app.routes.ExpertsCabinet.ExpertsSubscription.selectSubscriptionButton",
    defaultMessage: "Choose payment plan",
  },
  updatePaymentMethodButton: {
    id:
      "app.routes.ExpertsCabinet.ExpertsSubscription.updatePaymentMethodButton",
    defaultMessage: "Update payment method",
  },
  cancelSubscription: {
    id: "app.routes.ExpertsCabinet.ExpertsSubscription.cancelSubscription",
    defaultMessage: "Cancel subscription",
  },
  updatePaymentPlan: {
    id: "app.routes.ExpertsCabinet.ExpertsSubscription.updatePaymentPlan",
    defaultMessage: "Update payment plan",
  },
  cancelCommissionTitle: {
    id: "app.routes.ExpertsCabinet.ExpertsSubscription.cancelCommissionTitle",
    defaultMessage: "Are you sure you want to cancel subscription?",
  },
  updatePaymentMethodModal: {
    id:
      "app.routes.ExpertsCabinet.ExpertsSubscription.updatePaymentMethodModal",
    defaultMessage: "Payment plan update",
  },
  updatePaymentMethodModalSubtitle: {
    id:
      "app.routes.ExpertsCabinet.ExpertsSubscription.updatePaymentMethodModalSubtitle",
    defaultMessage:
      "If you currently have active courses, they will be automatically deactivated. Please re-activate your courses once your payment plan has been updated.",
  },
  cancelCommissionSubtitle: {
    id:
      "app.routes.ExpertsCabinet.ExpertsSubscription.cancelCommissionSubtitle",
    defaultMessage:
      "Subscription will be canceled and all active courses will be deactivated.",
  },
});
