import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  maxLevelOfInt: {
    id: 'app.components.GeneralInfo.maxLevelOfInt',
    defaultMessage: 'Max Level of interaction',
  },
  chooseCat: {
    id: 'app.components.GeneralInfo.chooseCat',
    defaultMessage: 'Choose Category',
  },
  chooseSubCat: {
    id: 'app.components.GeneralInfo.chooseSubCat',
    defaultMessage: 'Choose Subcategory',
  },
  courseStatus: {
    id: 'app.components.StatusInfo.courseStatus',
    defaultMessage: 'Course Status',
  },
  yourCourse: {
    id: 'app.components.StatusInfo.yourCourse',
    defaultMessage: 'Your course',
  },
  initialInformation: {
    id: 'app.components.StatusInfo.initialInformation',
    defaultMessage: 'Your course is saved in drafts. Next please fill in the Course Information section.',
  },
  theNextStepYou: {
    id: 'app.components.StatusInfo.theNextStepYou',
    defaultMessage:
      'The next step you should fill general information about your course',
  },
  congratsYourCourses: {
    id: 'app.components.StatusInfo.congratsYourCourses',
    defaultMessage: "Your submission is being reviewed. We will get back to you within 2 working days. Until then your material is saved as a draft.",
  },
  firstApprove: {
    id: 'app.components.StatusInfo.firstApproved',
    defaultMessage: 'Congratulations. Your course information has been approved. Please continue working on your course.',
  },
  secondApprove: {
    id: 'app.components.StatusInfo.secondApprove',
    defaultMessage: 'Congratulations! Your course is approved. Please now Activate it and Enjoy!',
  },
  rejected: {
    id: 'app.components.StatusInfo.rejected',
    defaultMessage: 'We regret your course has not been approved and you may now delete it.  You are welcome to create and submit a new course taking into account our recommendations.',
  },
  deactivatedText1: {
    id: 'app.components.StatusInfo.deactivatedText1',
    defaultMessage: 'Your course is deactivated and is not available for sale to new students.',
  },
  deactivatedTextTopping: {
    id: 'app.components.StatusInfo.deactivatedTextTopping',
    defaultMessage: 'Your material is deactivated and is not available for sale to new students.',
  },
  deactivatedText2: {
    id: 'app.components.StatusInfo.deactivatedText2',
    defaultMessage: 'Your active students on Assist or Concierge levels ',
  },
  deactivatedText3: {
    id: 'app.components.StatusInfo.deactivatedText3',
    defaultMessage: 'MUST',
  },
  deactivatedText4: {
    id: 'app.components.StatusInfo.deactivatedText4',
    defaultMessage: ' be provided with your full support (Web Classes, homework etc.) until they complete the course.',
  },
  active: {
    id: 'app.components.StatusInfo.active',
    defaultMessage: 'Your course is activated.',
  },
  thankYou: {
    id: 'app.components.StatusInfo.thankYou',
    defaultMessage:
      'Thank you! Your submission is being reviewed. We will get back to you within 2 working days. Until then your material is saved as a draft.',
  },
  webinar: {
    id: 'app.components.CourseNearestWebinar.webinar',
    defaultMessage: 'Webinar',
  },
  startWebinar: {
    id: 'app.components.CourseNearestWebinar.startWebinar',
    defaultMessage: 'Start Web Class',
  },
  youCanProduct: {
    id: 'app.components.CourseNearestWebinar.youCanProduct',
    defaultMessage: 'You can conduct webinar only from Google Chrome browser.',
  },
  ifItIsNotInstalled: {
    id: 'app.components.CourseNearestWebinar.ifItIsNotInstalled',
    defaultMessage:
      'If it is not installed in your computer you can download it',
  },
  here: {
    id: 'app.components.CourseNearestWebinar.here',
    defaultMessage: 'here',
  },
  block: {
    id: 'app.components.AdminButtonCroup.block',
    defaultMessage: 'Block',
  },
  reject: {
    id: 'app.components.AdminButtonCroup.reject',
    defaultMessage: 'Reject',
  },
  approve: {
    id: 'app.components.AdminButtonCroup.approve',
    defaultMessage: 'Approve',
  },
  submit: {
    id: 'app.components.ExpertButtonGroup.submit',
    defaultMessage: 'Submit',
  },
  activate: {
    id: 'app.components.ExpertButtonGroup.activate',
    defaultMessage: 'Activate',
  },
  deactivate: {
    id: 'app.components.ExpertButtonGroup.deactivate',
    defaultMessage: 'Deactivate',
  },
  declinedCourse: {
    id: 'app.components.ExpertButtonGroup.declinedCourse',
    defaultMessage: 'DECLINED',
  },
  formFieldsError: {
    id: 'app.components.ExpertButtonGroup.formFieldsError',
    defaultMessage: 'You didn’t fill required fields',
  },
  youAreAbout: {
    id: 'app.components.ExpertButtonGroup.youAreAbout',
    defaultMessage: 'You are about to delete your course',
  },
  tellUsWhy: {
    id: 'app.components.ExpertButtonGroup.tellUsWhy',
    defaultMessage: 'Please tell us why',
  },
  submitDelete: {
    id: 'app.components.ExpertButtonGroup.submitDelete',
    defaultMessage: 'Submit and delete',
  },
});
