// Core
import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FixedSizeList as List } from 'react-window';

import Suggestion from 'components/Select/AutoComplete/Suggestion';

const getHeight = (amount, rowHeight) =>
  amount <= 5 ? amount * rowHeight : 5 * rowHeight;

const getFallBackLabel = (loading, fetchError) => {
  if (fetchError) return 'Error. Something went wrong';
  if (loading) return 'Loading...';
  return 'No items found';
};

const renderFallbackUI = (loading, fetchError) => (
  <Suggestion label={getFallBackLabel(loading, fetchError)} />
);

class SuggestionsList extends PureComponent {
  renderItem = (children, index) => {
    const Component = children[index];
    return Component;
  };

  render() {
    const {
      options,
      children,
      getValue,
      selectProps: {
        listCustomProps: { loading, fetchError },
      },
    } = this.props;

    const listLength = children?.length || 0;

    const [value] = getValue();
    const initialOffset = Array.from(children).indexOf(value) * 57;
    return (
      <Fragment>
        {listLength ? (
          <List
            height={getHeight(listLength, 57)}
            itemCount={listLength}
            itemSize={57}
            itemData={options}
            initialScrollOffset={initialOffset}
          >
            {({ index, style }) => (
              <div style={style}>{this.renderItem(children, index)}</div>
            )}
          </List>
        ) : (
          renderFallbackUI(loading, fetchError)
        )}
      </Fragment>
    );
  }
}

SuggestionsList.propTypes = {
  options: PropTypes.array,
  children: PropTypes.any,
  getValue: PropTypes.func,
  selectProps: PropTypes.object,
};

export default SuggestionsList;
