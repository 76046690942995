// Core
import React, { memo, useState } from 'react';
import { compose, graphql } from 'react-apollo';

// Components
import Loader from 'components/Loader';
import Card from 'components/Card';
import SubscriberWebinarContentHeader from 'routes/SubscriberWebinar/SubscriberWebinarContentHeader';
import WebinarInformation from 'containers/WebinarInformation';
import WebinarRoom from 'containers/WebinarRoom';

// GraphQL
import GET_WEBINAR_DATA from 'queries/Webinar/getWebinarData.gql';

// Other
import { parseFetchedData, keysToCamel } from 'utils/helpers';

// Styles and Assets
import './SubscriberWebinarRoom.scss';

const SubscriberWebinarRoom = ({
  loading,
  sessionToken,
  sessionId,
  events,
  eventId,
  participantRole,
}) => {
  const [subscribeToVideo, setVideoSubcription] = useState(true);
  const [subscribeToAudio, setAudioSubscription] = useState(true);

  const currentWebinar = events?.length
    ? parseFetchedData(events.find(event => event.id === eventId))
    : {};
  const course = parseFetchedData(currentWebinar?.Course);


  const webinar = {
    title: currentWebinar?.EventName,
    description: currentWebinar?.EventDescription,
  };

  const toggleVideo = () => setVideoSubcription(!subscribeToVideo);
  const toggleAudio = () => setAudioSubscription(!subscribeToAudio);

  if (loading) {
    return (
      <div className="sk-web-room-not-created">
        <Card classes="sk-web-room-not-created__card">
          <Loader />
        </Card>
      </div>
    );
  };

  if (!course || !currentWebinar) {
    return (
      <div className="sk-web-room-not-created">
        <Card classes="sk-web-room-not-created__card">
          No webinar found
        </Card>
      </div>
    );
  };

  console.log('webinar', currentWebinar?.Author);

  return (
    <div className="sk-sub-webinar-room">
      <header className="sk-sub-webinar-room__header">
        <SubscriberWebinarContentHeader title={course?.title} />
      </header>
      <div className="sk-sub-webinar-room__main">
        <WebinarRoom
          coursePhoto={course?.coursePhoto}
          sessionId={sessionId}
          token={sessionToken}
          subscribeToVideo={subscribeToVideo}
          subscribeToAudio={subscribeToAudio}
          handleVideoSubscription={toggleVideo}
          handleAudioToggle={toggleAudio}
          participantRole={participantRole}
        />
      </div>

      <div className="sk-sub-webinar-room__footer">
        <WebinarInformation
          webinar={webinar}
          authorFirstName={currentWebinar?.Author?.firstName}
          authorLastName={currentWebinar?.Author?.lastName}
          authorAvatar={currentWebinar?.Author?.photo}
        />
      </div>
    </div>
  );
};

const getWebinarDataQuery = graphql(GET_WEBINAR_DATA, {
  props: ({
    data: { getCalendarEvents, error, loading, ...ownProps },
  }) => {
    if (error) throw new Error(error);

    return {
      loading,
      events: keysToCamel(getCalendarEvents),
      ...ownProps,
    };
  },
  options: ({ courseId }) => ({
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        CourseId: courseId,
        TimeFromUnixMs: new Date().getTime() - 100000000000,
        TimeToUnixMs: new Date().getTime() + 100000000000,
      },
    },
  }),
});

const enhancer = compose(
  memo,
  getWebinarDataQuery
);

export default enhancer(SubscriberWebinarRoom);
