// Core
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import { FormattedMessage } from 'react-intl';

// Components
import Toggler from 'components/Toggler';
import Text from 'components/Text';

// Other
import withAction from 'components/Icon/withAction';
import { cleanUpStyleString } from 'utils/helpers';
import { messages } from 'components/MobileSidebar/messages';

// Styles and Assets
import '../MobileSidebarMenu.scss';
import './MobileSidebarMenuItem.scss';

const getListItemClasses = open => ({
  root: `${cleanUpStyleString(
    `mui-override sk-mobile-sidebar-menu_list-item ${open &&
      'sk-mobile-sidebar-menu_list-item_opened'}`
  )}`,
});

class MobileSidebarMenuItem extends PureComponent {
  render() {
    const {
      icon: Icon,
      label,
      expandable,
      open,
      action,
      handleClick,
      children,
      iconProps,
    } = this.props;

    return (
      <ListItem classes={getListItemClasses(open)}>
        <button
          type="button"
          onClick={handleClick}
          className="sk-mobile-sidebar-menu-item_btn"
        >
          <div className="sk-mobile-sidebar-menu-item_name">
            <span className="sk-mobile-sidebar-menu-item_icon">
              <Icon active={open || action} {...iconProps} />
            </span>
            <FormattedMessage {...messages[label.toLowerCase()]}>
              {msg => (
                <Text
                  accent={!!open}
                  classes="sk-mobile-sidebar-menu-item_label"
                >
                  {msg}
                </Text>
              )}
            </FormattedMessage>
          </div>

          {expandable && (
            <span>
              {
                <Toggler
                  active={!!open}
                  chevronUpProps={{ width: '13', height: '8' }}
                  chevronDownProps={{ width: '13', height: '8' }}
                />
              }
            </span>
          )}
        </button>
        {expandable && (
          <div className="sk-mobile-sidebar-menu_expanded">{children}</div>
        )}
      </ListItem>
    );
  }
}

MobileSidebarMenuItem.propTypes = {
  icon: PropTypes.func,
  label: PropTypes.string,
  expandable: PropTypes.bool,
  open: PropTypes.bool,
  action: PropTypes.bool,
  handleClick: PropTypes.func,
  children: PropTypes.any,
};

export default withAction(MobileSidebarMenuItem);
