// Manual configuration
const {
  AWS_REGION: awsRegion,
  GRAPHQL_API_ENDPOINT_PRIVATE_URL: graphqlApiEndpointPrivateUrl,
  GRAPHQL_API_ENDPOINT_PUBLIC_URL: graphqlApiEndpointPublicUrl,
  PUBLIC_API_KEY: publicApiKey,
  IDENTITY_POOL_ID: identityPoolId,
  USER_POOL_ID: userPoolId,
  USER_POOL_CLIENT: userPoolClient,
  S3_BUCKET_USERFILES: s3BucketUserfiles,
  S3_BUCKET_VIDEOS: s3BucketVideos,
  S3_BUCKET_AUDIO: s3BucketAudios,
  S3_BUCKET_PREVIEWS: s3BucketPreviews,
  S3_BUCKET_HOMEWORKS: s3BucketHomeworks,
  S3_BUCKET_ARTICLES: s3BucketArticles,
  S3_BUCKET_QUIZES: s3BucketQuizes,
  SITE_DOMAIN: siteDomain,
  BASE_URL: baseUrl,
  COGNITO_APP_DOMAIN: cognitoAppDomain,
  NODE_ENV: nodeEnv
} = process.env;

export const AWS_REGION = awsRegion || 'us-east-2';
export const GRAPHQL_API_ENDPOINT_PRIVATE_URL =
  graphqlApiEndpointPrivateUrl ||
  'https://enr4sqz5f5ad7ous7svqxvmp7y.appsync-api.us-east-2.amazonaws.com/graphql';
export const GRAPHQL_API_ENDPOINT_PUBLIC_URL =
  graphqlApiEndpointPublicUrl ||
  'https://yymb4fszc5hzzen5xfc5tnk4mm.appsync-api.us-east-2.amazonaws.com/graphql';
export const PUBLIC_API_KEY = publicApiKey || 'da2-3al7utfrwjcvrgzage7vib6iei';
export const IDENTITY_POOL_ID = identityPoolId || 'us-east-2:9268e104-ce14-4ffc-ae58-0a7e824ff921';
export const USER_POOL_ID = userPoolId || 'us-east-2_2nZ7cYDeu';
export const USER_POOL_CLIENT = userPoolClient || '424macammc91qarvvbbvdg1qoh';
export const S3_BUCKET_USERFILES = s3BucketUserfiles || 'dev-skilleton-userfiles';
export const S3_BUCKET_VIDEOS = s3BucketVideos || 'dev-skilleton-videos';
export const S3_BUCKET_AUDIO = s3BucketAudios || 'dev-skilleton-audio';
export const S3_BUCKET_PREVIEWS = s3BucketPreviews || 'dev-skilleton-previews';
export const S3_BUCKET_HOMEWORKS = s3BucketHomeworks || 'dev-skilleton-homeworks-test';
export const S3_BUCKET_ARTICLES = s3BucketArticles || 'dev-skilleton-articles';
export const S3_BUCKET_QUIZES = s3BucketQuizes || 'dev-skilleton-quizes';
export const SITE_DOMAIN = siteDomain || 'avdo.education';
export const BASE_URL = baseUrl;
export const REDIRECT_SIGNIN_URL = `${baseUrl}/auth/federated-verification`;
export const REDIRECT_SIGNOUT_URL = `${baseUrl}/auth`;
export const COGNITO_APP_DOMAIN = cognitoAppDomain || 'skilleton.auth.eu-central-1.amazoncognito.com';
export const USE_SECURE_COOKIES = nodeEnv === 'production' ? true : false;
