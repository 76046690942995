import React from 'react';

const FB = () => {
  const randomFloatString = `${Math.random()}`;

  return (
    <svg
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 1C9.97205 1 1 9.97205 1 21C1 32.0271 9.97205 41 21 41C32.0271 41 41 32.0271 41 21C41 9.97205 32.0288 1 21 1Z"
        stroke="#610FB6"
      />
      <rect
        x="18.1816"
        y="20.1816"
        width="4.90909"
        height="1.63636"
        fill="white"
      />
      <path
        d="M17.3695 26.885L14.3305 29.862C14.0266 30.1597 13.5708 29.9365 13.5708 29.5643V27.1083C13.5708 26.9595 13.4948 26.885 13.3429 26.885H12.887C11.2916 26.885 10 25.6198 10 24.0569V14.8282C10 13.2652 11.2916 12 12.887 12H28.3857C29.9812 12 31.2727 13.2652 31.2727 14.8282V24.0569C31.2727 25.6198 29.9812 26.885 28.3857 26.885H17.7494H17.3695Z"
        stroke={`url(#paint0_linear_${randomFloatString})`}
        strokeMiterlimit="10"
      />
      <path
        d="M14.9092 15.5908C14.633 15.5908 14.4092 15.8147 14.4092 16.0908C14.4092 16.367 14.633 16.5908 14.9092 16.5908L14.9092 15.5908ZM26.3637 16.5908C26.6399 16.5908 26.8637 16.367 26.8637 16.0908C26.8637 15.8147 26.6399 15.5908 26.3637 15.5908L26.3637 16.5908ZM14.9092 16.5908L26.3637 16.5908L26.3637 15.5908L14.9092 15.5908L14.9092 16.5908Z"
        fill="#610FB6"
      />
      <path
        d="M14.9092 18.8633C14.633 18.8633 14.4092 19.0871 14.4092 19.3633C14.4092 19.6394 14.633 19.8633 14.9092 19.8633L14.9092 18.8633ZM26.3637 19.8633C26.6399 19.8633 26.8637 19.6394 26.8637 19.3633C26.8637 19.0871 26.6399 18.8633 26.3637 18.8633L26.3637 19.8633ZM14.9092 19.8633L26.3637 19.8633L26.3637 18.8633L14.9092 18.8633L14.9092 19.8633Z"
        fill="#610FB6"
      />
      <path
        d="M14.9092 22.1367C14.633 22.1367 14.4092 22.3606 14.4092 22.6367C14.4092 22.9129 14.633 23.1367 14.9092 23.1367L14.9092 22.1367ZM19.0001 23.1367C19.2762 23.1367 19.5001 22.9129 19.5001 22.6367C19.5001 22.3606 19.2762 22.1367 19.0001 22.1367L19.0001 23.1367ZM14.9092 23.1367L19.0001 23.1367L19.0001 22.1367L14.9092 22.1367L14.9092 23.1367Z"
        fill="#610FB6"
      />
      <defs>
        <linearGradient
          id={`paint0_linear_${randomFloatString}`}
          x1="33.1989"
          y1="31.2116"
          x2="6.73519"
          y2="27.3751"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8F26AF" />
          <stop offset="0.979167" stopColor="#141685" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default FB;
