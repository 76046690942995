import React from 'react';
import { FormattedMessage } from 'react-intl';
import { sharedMessages } from 'services/i18n/sharedMessages/messages.js';

export const COURSES_STATUSES_OPTIONS = [
  {
    value: 'DRAFT',
    label: <FormattedMessage {...sharedMessages.draft} />,
  },
  {
    value: 'FIRST_REVIEW',
    label: <FormattedMessage {...sharedMessages.firstReview} />,
  },
  {
    value: 'MAIN_FILLING',
    label: <FormattedMessage {...sharedMessages.mainFilling} />,
  },
  {
    value: 'FINAL_REVIEW',
    label: <FormattedMessage {...sharedMessages.finalReview} />,
  },
  {
    value: 'READY_TO_PUBLISHING',
    label: <FormattedMessage {...sharedMessages.readyToPublishing} />,
  },
  {
    value: 'ACTIVE',
    label: <FormattedMessage {...sharedMessages.active} />,
  },
  {
    value: 'DEACTIVATED',
    label: <FormattedMessage {...sharedMessages.inactive} />,
  },
  {
    value: 'FIRST_REJECT',
    label: <FormattedMessage {...sharedMessages.rejected} />,
  },
];
