// Core
import React, { Fragment } from 'react';
import { compose } from 'react-apollo';
import { generatePath, withRouter } from 'react-router';
import { injectIntl } from 'react-intl';

// Components
import Link from 'components/Link';

// Styles and Assets
import '../../FooterNavigation.scss';

const FooterNavMenuItem = ({ history, title, list, intl: {locale} }) => {
  const goToCoursesSearch = path => async () => {
    await history.push(`/`);
    await history.replace(path);
  };

  const getClassName = (className) => {
    const styleClasses = [
      'sk-footer-nav-menu_link'
    ]
    if (className) {
      styleClasses.push(className);
    }
    return styleClasses.join(' ');
  }

  const getLinkAttributes = ({path, href}) => {
    const attributes = {};
    if (path) {
      attributes.to = generatePath(path, {locale});
    } else if (href) {
      attributes.href = generatePath(href, {locale});
      attributes.target = '_BLANK';
    }
    return attributes
  }

  return (
    <div className="sk-footer-nav-menu sk-footer-nav-grid__item">
      <span className="sk-footer-nav-menu_title">{title}</span>
      <ul className="sk-footer-nav-menu_list">
        {list.map(({ key, title: itemTitle, path, className, href }) => (
          <li className={getClassName(className)} key={key}>
            <Link className={getClassName(className)} {...getLinkAttributes({path, href})}>
              {itemTitle}
            </Link>
          </li>
        ))}
      </ul>
    </div>
)};

const enhancer = compose(
  withRouter,
  injectIntl
);
export default enhancer(FooterNavMenuItem);
