// Core
import React, {useState} from 'react';
import { withRouter } from 'react-router-dom';
import { compose, graphql } from 'react-apollo';
import { FormattedMessage, injectIntl } from 'react-intl';

// Components
import Loader from 'components/Loader';
import ToppingPreview from './ToppingPreview';
import ToppingDescription from './ToppingDescription';
import ExpertCourses from 'routes/StudentsCabinet/ToppingDetails/ExpertCourses';
import RateTopping from './RateTopping';
import Link from 'components/Link';
import Button from 'components/Button';
import ContentHeader from 'containers/ContentHeader';
import PageNotFound from 'routes/PageNotFound';

// Other
import { LECTURE_QUIZ, LECTURE_VIDEO, } from 'containers/UploadManagerProvider/helpers';
import { MAPPING_COURSES_LIST_LIMIT } from 'utils/enums';
import { parseFetchedData, isBlank } from 'utils/helpers';
import { withLoader } from 'containers/HOCs/withLoader';
import { messages } from 'routes/StudentsCabinet/ToppingDetails/messages.js';

// GraphQL
import GET_STUDENT_COURSE from 'queries/Courses/getStudentCourse.gql';

// Styles
import './ToppingDetails.scss';

const PREFIX = 'sk-topping-details';

const ToppingDetails = ({
  studentCourse,
  breadcrumb,
  match
}) => {
  if (studentCourse === null) {
    return (<PageNotFound />);
  }
  if (!studentCourse) {
    return null;
  }
  const [ratingModal, setRatingModal] = useState(false);
  const finishVideo = () => setRatingModal(true);

  const parsedCourse = parseFetchedData(studentCourse.course);

  const {
    id,
    title,
    ratingTotal = {},
    author,
    description,
    coursePreview = {},
    lectures,
  } = parsedCourse;

  const toppingVideo = lectures[0]?.materials?.find(material => material?.type == LECTURE_VIDEO);
  const toppingQuiz = lectures[0]?.materials?.find(material => material?.type == LECTURE_QUIZ);

  return(
    <div className={`${PREFIX}`}>
      <div className={`${PREFIX}_breadcrumbs`}>
        <ContentHeader way={(
          <>
            <Link to="/student-cabinet">
              <FormattedMessage {...messages.studentsCabinet} />
            </Link>
            <Link to="/student-cabinet/toppings">
               <span> / <FormattedMessage {...messages.toppings} /></span>
            </Link>
            <span> / {title}</span>
          </>
        )} />
      </div>
      <ToppingPreview
        preview={toppingVideo}
        finishVideo={finishVideo}
      />
      <ToppingDescription
        title={title}
        author={author}
        ratingTotal={ratingTotal}
        description={description}
        quiz={toppingQuiz}
        button={<RateTopping isVideoFinished={ratingModal} courseId={id} />}
      />
      {author && (
        <ExpertCourses
          authorId={author.id}
        />
      )}
    </div>
  )
};

const getStudentCourseQuery = graphql(GET_STUDENT_COURSE, {
  props: ({ data: { getStudentCourse, error, ...ownProps } }) => {
    if (error) throw Error(error);
    return {
      studentCourse: getStudentCourse,
      ...ownProps,
    }
  },
  options: ({ match }) => ({
    variables: { id: match.params.id },
    fetchPolicy: 'cache-first',
  }),
});

const enhancer = compose(
  injectIntl,
  withRouter,
  getStudentCourseQuery,
  withLoader
);

export default enhancer(ToppingDetails);
