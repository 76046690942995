// Core
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Components
import TextInput from 'components/Input/Material/TextInput/TextInput';
import Button from 'components/Button';
import SearchLoop from 'components/Icon/Svg/SearchLoop';

// Styles and Assets
import 'routes/Courses/Search/Search.scss';
import { Grey3 } from 'components/Icon/color';

import { FormattedMessage } from 'react-intl';
import { messages } from 'routes/Courses/Search/messages';

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: props.searchString || '',
      /* eslint-disable react/no-unused-state */
      searchString: props.searchString,
    };
  }

  static getDerivedStateFromProps({ searchString }, state) {
    if (searchString !== state.searchString) {
      return { inputValue: searchString, searchString };
    }
    return null;
  }

  handleInputChange = inputValue => {
    this.setState({ inputValue });
  };

  handleSearch = event => {
    event.persist();
    const { handleSearch, hideModal } = this.props;
    const { inputValue } = this.state;
    handleSearch(inputValue);
    hideModal();
  };

  handleSearchEnter = (input, event) => {
    if (event.key === 'Enter') {
      this.handleSearch(event);
    }
  };

  render() {
    const { inputValue } = this.state;

    return (
      <div className="sk-search">
        <div className="sk-search__input-block">
          <FormattedMessage {...messages.searchCourse}>
            {msg => (
              <TextInput
                className="sk-search__input-block__input"
                value={inputValue}
                placeholder={msg}
                onChange={this.handleInputChange}
                onKeyPress={this.handleSearchEnter}
              />
            )}
          </FormattedMessage>
          <div className="sk-search__input-block__icon" onClick={this.handleSearch}>
            <SearchLoop height="22" width="23" color={Grey3} />
          </div>
        </div>
      </div>
    );
  }
}

Search.propTypes = {
  handleSearch: PropTypes.func,
  hideModal: PropTypes.func,
  searchString: PropTypes.string,
};

export default Search;
