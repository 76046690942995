import React from 'react';
import PropTypes from 'prop-types';
import { SidebarDefault, PurpleHeart } from 'components/Icon/color';

const User = ({
  height,
  width,
  fillColor,
  strokeColor,
  active,
  activeColor,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 13 18"
    width={width}
    height={height}
  >
    <g
      fill={active ? activeColor : fillColor}
      fillRule="nonzero"
      stroke={active ? activeColor : strokeColor}
    >
      <rect width="12" height="17" x=".5" y=".5" rx="2" />
      <path d="M3 0v18" />
    </g>
  </svg>
);

User.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  fillColor: PropTypes.string,
  strokeColor: PropTypes.string,
  active: PropTypes.bool,
  activeColor: PropTypes.string,
};

User.defaultProps = {
  height: '13',
  width: '18',
  fillColor: 'none',
  strokeColor: SidebarDefault,
  activeColor: PurpleHeart,
};

export default User;
