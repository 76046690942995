import React from 'react';
import PropTypes from 'prop-types';

import './Pencil.scss';

const Pencil = ({color = '#30d5c8'}) => (
  <svg class='pencil-svg' width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class='filled' fillRule="evenodd" clipRule="evenodd" d="M16.5405 3.19479L14.7027 1.39901C14.0901 0.80042 13.1712 0.900185 12.6607 1.39901L1.42943 12.3732C1.37072 12.4306 1.34577 12.455 1.33516 12.4843C1.32733 12.506 1.32733 12.5304 1.32733 12.5728L0 17.6608C0 17.8603 0.204204 18.0599 0.306306 17.9601L4.59459 16.3638C4.6967 16.3638 4.9009 16.2641 5.003 16.1643L15.2132 6.58682L16.5405 5.1901C17.1532 4.59151 17.1532 3.69362 16.5405 3.19479ZM4.49248 16.0646C4.39038 16.0646 4.18617 15.9648 4.08407 15.8651L1.73572 13.4707C1.53152 13.2712 1.53152 12.9719 1.73572 12.7723C1.93993 12.5728 2.24623 12.5728 2.45044 12.7723L4.79879 15.1667C5.00299 15.3662 5.00299 15.6655 4.79879 15.8651L4.79878 15.8651C4.69668 15.9648 4.59458 16.0646 4.49248 16.0646ZM14.6006 5.88847C14.7027 5.98823 14.9069 5.98823 15.009 5.98823C15.1111 5.98823 15.3153 5.88847 15.3153 5.88847C15.5195 5.68894 15.5195 5.38964 15.3153 5.19011L12.6606 2.69597C12.4564 2.49644 12.1501 2.49644 11.9459 2.69597C11.7417 2.8955 11.7417 3.1948 11.9459 3.39433L14.6006 5.88847Z" fill={color} />

    <path class='outlined' fillRule="evenodd" clipRule="evenodd" d="M12.6861 1.03862C13.3975 0.323548 14.568 0.323533 15.2799 1.02031L16.9756 2.68013C17.6748 3.36456 17.6687 4.47539 16.9816 5.16805L16.9802 5.16941L15.7043 6.47388L6.02158 15.5913L6.01107 15.6002C5.94232 15.6579 5.85737 15.7186 5.77774 15.7692C5.71223 15.8108 5.61195 15.8704 5.51211 15.9056L1.49308 17.4515L1.48982 17.4528C0.953231 17.6548 0.358384 17.1787 0.527864 16.5933L0.528341 16.5917L1.88451 11.8429C1.8885 11.8151 1.89395 11.7935 1.89755 11.7802C1.91168 11.7282 1.93152 11.6861 1.9449 11.6602C1.97236 11.607 2.00548 11.5593 2.03048 11.5255C2.08141 11.4566 2.14373 11.3855 2.19136 11.3389L11.4436 2.31013L12.6832 1.04164L12.6861 1.03862ZM12.1445 2.95873L12.1282 2.97535L3.10117 11.7842L5.71787 14.5673L15.0218 5.80645L15.0653 5.76192L12.1445 2.95873ZM12.8112 2.27641L15.7324 5.07993L16.2867 4.51327L16.2894 4.51054C16.6184 4.17955 16.6118 3.65922 16.2941 3.34821L14.5983 1.68839C14.2649 1.36203 13.7125 1.36159 13.378 1.69643L12.8112 2.27641ZM1.5837 16.402L2.64338 12.6914L4.92405 15.1171L1.5837 16.402Z" fill={color} />
  </svg>
);

export default Pencil;
