import React from 'react';
import PropTypes from 'prop-types';
import { PurpleHeart } from 'components/Icon/color';

const ChatBubble = ({ fillColor, height, width }) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.574 1.194a1.48 1.48 0 0 0-1.477 1.477v5.875a1.48
       1.48 0 0 0 1.477 1.477h6.62l1.865 2.16a.594.594 0 0 0 
       1.042-.386v-1.771h.422A1.48 1.48 0 0 0 16 8.549V2.67a1.48 
       1.48 0 0 0-1.477-1.477H4.574zm10.622 1.477v5.875a.678.678 0
        0 1-.676.676h-.82a.402.402 0 0 0-.402.402v1.61l-1.62-1.872a.397.397 
        0 0 0-.305-.137H4.574a.678.678 0 0 1-.676-.676V2.67c0-.372.304-.676.676-.676h9.946c.372
         0 .676.304.676.676z"
      fill={fillColor}
    />
    <path
      d="M2.284 14.767a.584.584 0 0 0 .653-.167l1.866-2.16h5.829a.402.402 0
       1 0 0-.803H4.62a.402.402 0 0 0-.304.137L2.7 13.653v-1.611a.402.402 0 0 
       0-.402-.402h-.82a.678.678 0 0 1-.677-.677V5.09c0-.373.304-.676.677-.676.222 
       0 .402-.18.402-.402a.399.399 0 0 0-.402-.399A1.48 1.48 0 0 0 0 5.089v5.874a1.48
        1.48 0 0 0 1.477 1.477h.421v1.771c0 .252.15.47.386.556zM9.547 6.226a.477.477 0 
        1 0 0-.954.477.477 0 0 0 0 .954zM7.95 6.226a.477.477 0 1 0 0-.954.477.477 0 0 0
         0 .954zM11.145 6.226a.477.477 0 1 0 0-.954.477.477 0 0 0 0 .954z"
      fill={fillColor}
    />
  </svg>
);

ChatBubble.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  fillColor: PropTypes.string,
};

ChatBubble.defaultProps = {
  fillColor: PurpleHeart,
  height: '16',
  width: '16',
};

export default ChatBubble;
