// Core
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { compose, graphql } from 'react-apollo';
import slugify from '@sindresorhus/slugify';
import { injectIntl, FormattedMessage } from 'react-intl';

// Components
import Text from 'components/Text';
import AddEntityButton from 'routes/Course/components/AddEntityButton';
import MaterialEntity from 'routes/Course/components/LectureMaterials/StudyMaterialsList/MaterialEntity';

// GraphQL

// Other
import { messages } from 'routes/Course/components/HomeworkUploader/messages';
import { getSeparatedFilename } from 'services/aws/amplify/helpers';
import { PDF_FILE, } from 'utils/enums/index';
import { withFileModal } from 'routes/Course/components/LecturesList/LectureItem/HOCs/withFileModal';

// Styles and Assets
import './HomeworkUploader.scss';

const HomeworkUploader = ({
  disabled, disableDeletion, courseId, lectureId, entity: homework,
  handleAttachmentDelete, handleUploadHomework, openFileModal,
  intl: { formatMessage },
}) => {
  const handleHomeworkReload = useCallback(() => openFileModal({ entityId: homework?.id, entityType: PDF_FILE, params: { withDuration: false } }), [])
  const isHomeworkAdded = homework && homework !== null;

  return (
    <div className="sk-homework-uploader">
      <header
        className={classNames('sk-homework-uploader__header', {
          withHomework: isHomeworkAdded,
        })}
      >
        <Text classes="sk-subtitle">
          <FormattedMessage {...messages.homework} />
        </Text>
      </header>
      {isHomeworkAdded ? (
        <div className="sk-homework-uploader__content">
          <MaterialEntity
            disableDeletion={disableDeletion}
            entity={homework}
            openFileModal={openFileModal}
            handleAttachmentDelete={handleAttachmentDelete}
          />
        </div>
      ) : (
        <AddEntityButton
          label={formatMessage(messages.addHW)}
          handleAddClick={handleHomeworkReload}
        />
      )}

    </div>
  );
}

HomeworkUploader.propTypes = {
  disabled: PropTypes.bool,
  homework: PropTypes.object,
  lectureId: PropTypes.string,
  handleHomeworkDelete: PropTypes.func,
  disableDeletion: PropTypes.bool,
};

const enhancer = compose(
  injectIntl,
  withFileModal
);

export default enhancer(HomeworkUploader);
