// Core
import React, { memo } from 'react';
import PropTypes from 'prop-types';

// Components
import Heading from 'components/Heading';
import Button from 'components/Button';
import Text from 'components/Text';
import Done from 'components/Icon/Svg/Done';

// Styles and Assets
import './SuccessfulResetMessage.scss';

// Other
import { FormattedMessage } from 'react-intl';
import { messages } from 'routes/PasswordChange/SuccessfulResetMessage/messages';

export const SuccessfulResetMessage = ({ handleContinueClick }) => (
  <div className="sk-reset-success">
    <div className="sk-reset-success_header">
      <div className="sk-reset-success_icon">
        <Done width="51" height="51" />
      </div>
      <Heading variant="h4" classes="sk-reset-success_heading">
        <FormattedMessage {...messages.success} />
      </Heading>
      <Text classes="sk-reset-success_subheading">
        <FormattedMessage {...messages.youPasswordUpdated} />
      </Text>
    </div>
    <div className="sk-reset-success_footer">
      <Button
        variant="text"
        color="secondary"
        size="large"
        onClick={handleContinueClick}
      >
        <FormattedMessage {...messages.goToLogin} />
      </Button>
    </div>
  </div>
);

SuccessfulResetMessage.propTypes = {
  handleContinueClick: PropTypes.func,
};

export default memo(SuccessfulResetMessage);
