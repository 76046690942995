import React, { memo, useEffect, useState, useCallback } from "react";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import { Menu, MenuItem } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

// Components
import { MaterializedTextField } from 'components/Input/Material/MaterialTextField';

// Other
import { withLanguage } from 'containers/LanguageProvider/withLanguage';
import { dateSlashedMask } from 'utils/enums';

// Assets
import "moment/locale/fr";
import "moment/locale/ru";

const DEFAULT_LOCALE = 'en';
moment.locale(DEFAULT_LOCALE); // it is required to select default locale manually

const localeMap = {
  en: "en",
  ru: "ru",
};
const defaultHandleChange = () => {};

const MyAwesomeTextField = ({ autoOk: autoOkProp, ...props }) => (
  <MaterializedTextField {...props} />
)

const formatDate = date => moment.isMoment(date) ? date.unix() : moment.unix(date);
const formatDisplayValue = date => moment.isMoment(date) ? date : formatDate(date);

const DatePicker = ({
  name,
  value,
  format = 'DD/MM/YYYY',
  autoOk = true,
  handleInputChange = defaultHandleChange,
  handleInputBlur = defaultHandleChange,
  languageContext: { language = DEFAULT_LOCALE },
  ...props
}) => {
  const [locale, setLocale] = useState(language);
  const [anchorEl, setAnchorEl] = useState(null);
  const [date, setDate] = useState({ value: formatDate(value), inputValue: formatDate(value) });

  useEffect(() => {
    setDate({ value: formatDate(value), inputValue: formatDate(value) });
  }, [value])

  const handleChange = (moment, inputValue) => {
    setDate({ value: inputValue, inputValue: moment });
  };

  const handleBlur = () => {
    const timestamp = formatDate(date.inputValue);
    if (timestamp) {
      handleInputChange(name)(date.inputValue)
    } else {
      // TODO: show error of wrong format
    }
  }

  const handleMenuOpen = useCallback(e => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  }, []);

  const selectLocale = useCallback(locale => {
    moment.locale(locale);

    setLocale(locale);
    setAnchorEl(null);
  }, []);

  return (
    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={locale}>
      <KeyboardDatePicker
        name={name}
        customType="picker"
        format={format}
        margin="normal"
        value={date.value}
        keyboard
        autoOk={autoOk}
        mask="__/__/____"
        inputIsControlled={false}
        onChange={handleChange}
        onBlur={handleBlur}
        onKeyPress={defaultHandleChange}
        onAccept={handleInputChange(name)}
        {...props}
        TextFieldComponent={MyAwesomeTextField}
      />

      <Menu
        id="locale-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {Object.keys(localeMap).map(localeItem => (
          <MenuItem
            key={localeItem}
            selected={localeItem === locale}
            onClick={() => selectLocale(localeItem)}
          >
            {localeItem}
          </MenuItem>
        ))}
      </Menu>
    </MuiPickersUtilsProvider>
  );
}

export default withLanguage(DatePicker);
