import React from 'react';
import PropTypes from 'prop-types';
import { Grey, Purple } from 'components/Icon/color';

const Gift = ({ color = Grey, activeColor = Purple, active, height=15, width=15 }) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.89957 2.04157C9.13828 1.78582 9.47928 1.64941 9.83734
      1.64941C10.1954 1.64941 10.5193 1.78582 10.7751 2.04157C11.3037
      2.57013 11.3037 3.42264 10.7751 3.9512C10.2465 4.49681 8.59267
      5.38342 7.74016 5.38342C7.56966 5.38342 7.5185 5.34932 7.5185
      5.34932C7.22865 5.02536 8.04706 2.87703 8.89957 2.04157Z"
      stroke={active ? activeColor : color}
      strokeWidth="0.8946"
      strokeMiterlimit="10"
    />
    <path
      d="M5.76058 2.04157C5.52188 1.78582 5.18087 1.64941 4.82282
      1.64941C4.46476 1.64941 4.14081 1.78582 3.88505 2.04157C3.3565
      2.57013 3.3565 3.42264 3.88505 3.9512C4.41361 4.49681 6.06749
      5.38342 6.92 5.38342C7.0905 5.38342 7.14165 5.34932 7.14165
      5.34932C7.43151 5.02536 6.61309 2.87703 5.76058 2.04157Z"
      stroke={active ? activeColor : color}
      strokeWidth="0.8946"
      strokeMiterlimit="10"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.49757 4.95215C0.761072 4.95215 0.162109 5.55111 0.162109
      6.28761V7.53228C0.162109 8.03008 0.57352 8.44148 1.07132
      8.44148H1.84576V12.9292C1.84576 13.5963 2.31923 14.2894
      3.07155 14.2894H8.12251H9.10011H11.7219C12.4742 14.2894
      12.9477 13.5963 12.9477 12.9292V8.44148H13.535C14.0328
      8.44148 14.4443 8.03008 14.4443 7.53228V6.28761C14.4443
      5.55111 13.8453 4.95215 13.1088 4.95215H10.7047H3.98691H1.49757ZM11.9477
      8.44148H8.13012H6.47624H2.84576V12.9292C2.84576 13.053 2.88859 13.1527
      2.94083 13.2146C2.99231 13.2756 3.04072 13.2894 3.07155
      13.2894H8.12251H9.10011H11.7219C11.7527 13.2894 11.8011 13.2756 11.8526
      13.2146C11.9048 13.1527 11.9477 13.053 11.9477 12.9292V8.44148ZM1.16211
      6.28761C1.16211 6.1034 1.31336 5.95215 1.49757
      5.95215H3.98691H10.7047H13.1088C13.293 5.95215 13.4443 6.1034 13.4443
      6.28761V7.44148H8.13012H6.47624H1.16211V6.28761Z"
      fill={active ? activeColor : color}
    />
  </svg>
);

export default Gift;
