// Core
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';
import { Progress } from 'react-sweet-progress';
import Truncate from 'react-truncate';

// Components
import MenuItem from '@material-ui/core/MenuItem';
import PlayIcon from 'components/Icon/Svg/Play';
import DocumentIcon from 'components/Icon/Svg/Document';
import { DarkGrey, Transparent } from 'components/Icon/color';
import Text from 'components/Text';

// Other
import {
  LECTURE_VIDEO,
  COURSE_PREVIEW,
} from 'containers/UploadManagerProvider/helpers';
import { renderTrimmedText } from 'utils/helpers';

// Styles and Assets
import "react-sweet-progress/lib/style.css";
import './UploadMenuItem.scss';

const theme = {
  success: {
    symbol: '100%',
    color: 'rgb(223, 105, 180)'
  },
  active: {
    color: '#663ACC'
  },
  default: {
    symbol: '0%',
    color: '#663ACC'
  }
};

const textStyles = {
  fontSize: '14px',
  lineHeight: '21px',
  margin: 0,
  padding: 0,
};

const UploadMenuItem = ({
  type,
  title,
  progress,
}) => {
  const uploadingStatus = 'active';
  const isVideo = type === LECTURE_VIDEO || type === COURSE_PREVIEW;

  return (
    <MenuItem>
      <div className='upload-menu-item'>
        <div className='upload-menu-item__info'>
          {isVideo ? (
            <PlayIcon
              color={Transparent}
              strokeColor={DarkGrey}
              width="22"
              height="22"
            />
          ) : (
            <DocumentIcon
              color={DarkGrey}
              strokeColor={DarkGrey}
              width="22"
              height="22"
            />
          )}
          <Text className='upload-menu-item__info__title'>
            {renderTrimmedText(title, 32)}
          </Text>
        </div>
        <Progress
          theme={theme}
          width={32}
          type="circle"
          percent={progress}
          status={uploadingStatus} />
      </div>
    </MenuItem>
  );
}

UploadMenuItem.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  progress: PropTypes.number.isRequired,
};

const enhancer = compose(
  memo
);

export default enhancer(UploadMenuItem);
