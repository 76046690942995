// Core
import React, { memo, useState, useCallback } from 'react';
import { graphql, compose } from 'react-apollo';

// Components
import { IconText } from 'routes/Checkout/CheckoutLists/Subscriptions';
import Text from 'components/Text';
import Link from 'components/Link';
import Button from 'components/Button';
import Card from 'components/Card';
import Loader from 'components/Loader';
import ContentHeader from 'containers/ContentHeader';
import Breadcrumbs from 'components/Breadcrumbs';
import Warning from 'routes/ExpertsCabinet/ExpertSubscription/ActiveSubsctiption/icons/Warning.js';

// Other
import { messages } from 'routes/ExpertsCabinet/ExpertSubscription/messages.js';
import { sharedMessages } from 'services/i18n/sharedMessages/messages';
import { withFormattedHtmlMessage } from 'containers/HOCs/withFormattedHtmlMessage';

// Styles and Assets
import './index.scss';

const PREFIX = 'sk-expert-subscription-expired';
const ExpiredSubscription = ({
  title,
  formatHtmlMessage,
  intl: { formatMessage },
  ...props
}) => {
  return (
    <Card classes={PREFIX}>
      <IconText className={`${PREFIX}__title`} icon={Warning} width={41} height={41}>{formatMessage(messages.expiredSubscriptionTitle)}</IconText>
      <Text size='small'>{formatHtmlMessage(messages.expiredSubscriptionText, { title, })}</Text>
      <Link to='/experts-cabinet/subscription'><Button color='primary' variant='text'>{formatMessage(messages.selectSubscriptionButton)}</Button></Link>
    </Card>
)};


const enhancer = compose(
  memo,
  withFormattedHtmlMessage,
);

export default enhancer(ExpiredSubscription);
