// Core
import React from 'react';
import PropTypes from 'prop-types';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';

// Components
import Toggler from 'components/Toggler';

// Styles and Assets
import './DropDown.scss';

const paperClasses = {
  root: 'mui-override paper paper-shadow',
};

class DropDown extends React.Component {
  state = {
    open: false,
  };

  handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handleMenuItemClick = event => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }

    this.setState({ open: false });
  };

  closeToggle = () => {
    this.setState({ open: false });
  };

  render() {
    const {
      children,
      component: MenuComponent,
      withToggler,
      disablePortal,
      togglerProps,
      placement,
      ...rest
    } = this.props;
    const { open } = this.state;

    return (
      <div className="mui-override sk-dropdown">
        <button
          className="mui-override sk-dropdown-toggler"
          type="button"
          aria-owns={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={this.handleToggle}
        >
          {withToggler ? (
            <Toggler
              active={open}
              chevronUpProps={togglerProps}
              chevronDownProps={togglerProps}
            >
              {children}
            </Toggler>
          ) : (
            children
          )}
        </button>
        <Popper
          open={open}
          transition
          disablePortal={disablePortal}
          placement={placement}
        >
          {({ TransitionProps, placement: growPlacement }) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{
                transformOrigin:
                  growPlacement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <MenuComponent
                handleMenuItemClick={this.handleMenuItemClick}
                handleMenuToggle={this.handleToggle}
                closeToggle={this.closeToggle}
                {...rest}
              />
            </Grow>
          )}
        </Popper>
      </div>
    );
  }
}

DropDown.defaultProps = {
  placement: 'bottom-start',
  disablePortal: false,
};

DropDown.propTypes = {
  disablePortal: PropTypes.bool,
  placement: PropTypes.string,
};

export default DropDown;
