// Core
import React, { memo } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'react-apollo';

// Components
import Text from 'components/Text';
import Card from 'components/Card';
import Button from 'components/Button';
import NearestWebinarInfo from 'containers/Calendar/NearestWebinarBlock/NearestWebinarInfo';
import Link from 'components/Link';

// Other
import { FormattedMessage } from 'react-intl';
import { messages } from 'containers/Calendar/messages';

// Styles and Assets
import './NearestWebinarBlock.scss';

/**
 * Prepare the webinar link according user roles
 * @param {object} event
 * @param {string} courseId
 * @param {string} role
 */
const getWebinarLink = (event, courseId, role) => {
  console.log('event', event);
  console.log('role', role);

  if (role === 'student') {
    return {
      pathname: `/subscriber-webinar/${courseId}/${event.id}`,
      state: {
        sessionId: event.sessionId,
        mappingId: event.mappingId,
      }
    }
  }
  return `/publisher-webinar/${courseId}/${event?.id}`;
};

export const NearestWebinarBlock = ({ webinars, role, history }) => {
  const joinWebinar = (event, courseId, role) => () =>{
    const webinarLink = getWebinarLink(event, courseId, role);
    history.push(webinarLink);
  };

  return (
    <>
      {webinars?.map(webinar => (
        <div key={webinar.webinarEvent.id} className="sk-nearest-webinar-block">
          <Card classes="sk-nearest-webinar-block__card">
            <div className="sk-nearest-webinar-block__inner">
              <div className="sk-nearest-webinar-block__info">
                <NearestWebinarInfo webinar={webinar} />
              </div>
              <div className="sk-nearest-webinar-block__actions">
                  <Button color="secondary" size="medium" variant="text" onClick={joinWebinar(webinar.webinarEvent, webinar.course.id, role)}>
                    <FormattedMessage {...(
                      (role === 'student')
                      ? messages.joinWebinar
                      : messages.startWebinar)}
                    />
                  </Button>
              </div>
            </div>
          </Card>
        </div>
      ))}
    </>
  )
};

const enhancer = compose(
  memo,
  withRouter
);

export default enhancer(NearestWebinarBlock);
