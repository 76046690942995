import React from 'react';
import PropTypes from 'prop-types';

const MenuNoteBook = ({ height, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 26"
    width={width}
    height={height}
  >
    <g fill="none" fillRule="evenodd">
      <g fillRule="nonzero" transform="translate(0 .617)">
        <circle cx="8.448" cy="16.719" r="8.448" fill="#FBC4BB" />
        <g stroke="#333" transform="translate(6)">
          <rect width="15" height="20.053" x=".5" y=".822" rx="2" />
          <path d="M3.79.322v21.053" />
        </g>
      </g>
    </g>
  </svg>
);

MenuNoteBook.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

MenuNoteBook.defaultProps = {
  height: '26',
  width: '22',
};

export default MenuNoteBook;
