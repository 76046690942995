// Core
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router';
import { compose, graphql } from 'react-apollo';
import { FormattedMessage } from 'react-intl';

// Styles and Assets
import './PurchasedCourseMaterials.scss';

// GraphQL
import GET_STUDENT_COURSE from 'queries/Courses/getStudentCourse.gql';

// Components
import PurchasedCoursesHero from 'routes/StudentsCabinet/PurchasedCourses/PurchasedCoursesHero';
import PurchasedCoursesWebinar from 'routes/StudentsCabinet/PurchasedCourses/PurchasedCoursesWebinar';
import PurchasedCoursesFlex from 'routes/StudentsCabinet/PurchasedCourses/PurchasedCoursesFlex';
import PurchasedCoursesLecture from 'routes/StudentsCabinet/PurchasedCourses/PurchasedCoursesLecture';
import PurchasedCoursesMessenger from 'routes/StudentsCabinet/PurchasedCourses/PurchasedCoursesMessenger';
import NotFoundCourse from 'routes/ExpertsCabinet/CourseEdit/NotFoundCourse';
import EndCourseModal from 'routes/StudentsCabinet/PurchasedCourses/EndCourseModal';
import Text from 'components/Text';
import Button from 'components/Button';
import Loader from 'components/Loader';

// Other
import { withModal } from 'containers/ModalProvider/withModal';
import {
  checkIfLevelBlocked,
  parseFetchedData,
  getActiveCourses,
  getCompletedCourses,
  isNull,
  isBlank
} from 'utils/helpers';
import { SUBMITTED_PREVIOUS_HOMEWORK, REVIEWED_MARK_MORE_THAN_60 } from 'utils/enums/index';
import {
  hasVariant,
  getVariant
} from 'routes/ExpertsCabinet/CourseEdit/Details/CourseInformation/helpers.js';
import { messages } from 'routes/StudentsCabinet/PurchasedCourses/PurchasedCourseMaterials/messages';
import { withNotification } from 'containers/NotificationProvider/withNotification';

const openFirstLecture = (history, studentCourses) => {
  let courseToProceed;

  const userActiveCourses = getActiveCourses(studentCourses);
  const hasStudentActiveCourses = userActiveCourses.length > 0;

  const userCompletedCourses = getCompletedCourses(studentCourses);
  const hasStudentCompletedCourses = userCompletedCourses.length > 0;

  if (hasStudentActiveCourses) {
    [courseToProceed] = getActiveCourses(studentCourses);
  } else if (hasStudentCompletedCourses && !courseToProceed) {
    [courseToProceed] = getCompletedCourses(studentCourses);
  }

  if (courseToProceed) return history.push(`/student-cabinet/courses/${studentCourses?.id}/materials/`);
};

const validateSubmittedHomework = (homework) => {
  return !isBlank(homework) && homework?.step >= 3;
};

const validateReviewedHomework = (homework) => {
  return !isBlank(homework) && homework?.step == 4 && homework?.review?.mark >= 60;
};

const listViewedMaterials = (viewData) => {
  let viewedMaterials = [];

  viewData.forEach((items) => {
    viewedMaterials = [...viewedMaterials, ...items.views];
  });

  return viewedMaterials;
};

const getLastSeen = (viewedMaterials) => {
  const lastSeen = viewedMaterials.reduce((lastSeenMaterial, viewedMaterial, viewedMaterialIndex) => {
    if (lastSeenMaterial?.viewedOn < viewedMaterial?.viewedOn) {
      return viewedMaterial;
    } else {
      return lastSeenMaterial;
    }
  }, viewedMaterials[0] || {});
  return lastSeen;
};

class PurchasedCourseMaterials extends PureComponent {
  componentDidMount() {
    const {
      location,
      history,
      purchasedCourse,
      purchasedCourse: { course },
      match
    } = this.props;

    if (match.params.id === ':id') {
      openFirstLecture(history, purchasedCourse);
    }

    if (location?.state === 'not_found' && !!course) {
      history.push(
        `/student-cabinet/courses/${purchasedCourse.id}/lecture/001/article/${course?.lectures?.[0].articles?.[0]?.id}`
      );
    }
  }

  getInteractionLevelOfStudent = () => {
    const { match } = this.props;
    return match.params?.interactionLevel;
  };

  getCourseForProvidedInteractionLevel = (coursesList, course, interactionLevel) =>
    coursesList?.find((item) => item.courseId === course?.id && item.interactionLevel === interactionLevel);

  setIndexOfChild = (index, arr) => {
    if (index === 0) {
      return true;
    }
    if (index === arr.length - 1) {
      return false;
    }
    return null;
  };

  renderCoursesWebinar = (courseStudyData) => {
    const {
      purchasedCourse: { course }
    } = this.props;
    const Component =
      courseStudyData?.variantName === 'flex' ? PurchasedCoursesFlex : PurchasedCoursesWebinar;

    return <Component course={course} purchasedCourse={courseStudyData} />;
  };

  checkIfCourseCompletedByStudent = (courseStudyData) => courseStudyData?.courseStatus === 'COMPLETED';

  handleOpenModal = (mappingId) => () => {
    const {
      purchasedCourse: { course },
      purchasedCourse,
      modalContext: { showModal, hideModal }
    } = this.props;
    showModal(() => (
      <EndCourseModal
        id={course.id}
        mappingId={mappingId}
        handleSuccesfulRate={this.handleSuccesfulRate(hideModal)}
      />
    ));
  };

  handleSuccesfulRate = (hideModal) => () => {
    const { showNotificationBar } = this.props;

    showNotificationBar('saved');
    hideModal();
  };

  getCourseStudyData = (purchasedCourse) => {
    return purchasedCourse;
    // let courseStudyData = {};
    // const course = purchasedCourse.course;

    // if (purchasedCourses && purchasedCourses.length > 0 && course) {
    //   const interactionLevel = this.getInteractionLevelOfStudent();
    //   courseStudyData = this.getCourseForProvidedInteractionLevel(
    //     purchasedCourse,
    //     course,
    //     interactionLevel
    //   );
    // }

    // return courseStudyData;
  };

  getViewEditAccess = ({ currentLecture, lecturesWithHomework, accessSettings, homeworks }) => {
    let isDisabled = false;
    const reversedLecturesWithHomework = [...lecturesWithHomework].reverse();

    if (accessSettings === SUBMITTED_PREVIOUS_HOMEWORK) {
      const lastSubmittedLectureWithHomework = reversedLecturesWithHomework.find((lectureWithHomework) => {
        const homework = homeworks.find((homework) => homework?.lectureId === lectureWithHomework?.id);
        const isValid = homework ? validateSubmittedHomework(homework) : false;
        return isValid;
      });

      const lastSubmittedLectureWithHomeworkIndex = lecturesWithHomework.indexOf(
        lastSubmittedLectureWithHomework
      );
      const nextAfterLastSubmittedLectureWithHomework =
        lecturesWithHomework[lastSubmittedLectureWithHomeworkIndex + 1];

      const isAnyHomeworkSubmitted = lastSubmittedLectureWithHomeworkIndex !== -1;
      const firstAvailableLectureWithHomeworkOrderNumber = isAnyHomeworkSubmitted
        ? nextAfterLastSubmittedLectureWithHomework?.orderNumber
        : lecturesWithHomework && lecturesWithHomework[0]
        ? lecturesWithHomework[0].orderNumber
        : null;

      isDisabled = firstAvailableLectureWithHomeworkOrderNumber < currentLecture?.orderNumber;
    } else if (accessSettings === REVIEWED_MARK_MORE_THAN_60) {
      const lastReviewedLectureWithHomework = reversedLecturesWithHomework.find((lectureWithHomework) => {
        const homework = homeworks.find((homework) => homework?.lectureId === lectureWithHomework?.id);
        const isValid = homework ? validateReviewedHomework(homework) : false;
        return isValid;
      });

      const lastReviewedLectureWithHomeworkIndex = lecturesWithHomework.indexOf(
        lastReviewedLectureWithHomework
      );
      const nextAfterLastReviewedLectureWithHomework =
        lecturesWithHomework[lastReviewedLectureWithHomeworkIndex + 1];

      const isAnyHomeworkReviewed = lastReviewedLectureWithHomeworkIndex !== -1;
      const firstAvailableLectureWithHomeworkOrderNumber = isAnyHomeworkReviewed
        ? nextAfterLastReviewedLectureWithHomework?.orderNumber
        : lecturesWithHomework[0].orderNumber;

      isDisabled = firstAvailableLectureWithHomeworkOrderNumber < currentLecture?.orderNumber;
    }

    return isDisabled;
  };

  render() {
    const {
      purchasedCourse,
      purchasedCourse: { course },
      loading,
      refetchStudentCourse
    } = this.props;
    if (loading) return <Loader />;
    if (!purchasedCourse || !course || loading) return <Loader />;

    // const course = parseFetchedData(course);

    // In this case, student should see deleted course
    // if (course?.statusId == 'DELETED') return (<NotFoundCourse />)

    const courseStudyData = this.getCourseStudyData(purchasedCourse, course);

    const { variants, messengerUrl, interactionLevel } = course;
    const variantName = courseStudyData?.interactionLevel;

    const purchasedCourseVariant = getVariant({ variants, variantName });
    const purchasedCoursePermissions = purchasedCourseVariant?.permissions;
    const purchasedCourseFeatures = purchasedCourseVariant?.features;

    const lecturesAccess =
      !isBlank(purchasedCoursePermissions) && !isBlank(purchasedCoursePermissions.lectures)
        ? purchasedCoursePermissions.lectures
        : 'all';
    const homeworksAccess =
      !isBlank(purchasedCoursePermissions) && !isBlank(purchasedCoursePermissions.homeworks)
        ? purchasedCoursePermissions.homeworks
        : 'all';

    const mappingId = purchasedCourse.id;

    if (!courseStudyData) return null;

    const isCourseCompleted = this.checkIfCourseCompletedByStudent(courseStudyData);

    const lecturesWithHomework = course?.lectures
      ?.map((lecture, index) => ({
        id: lecture?.id,
        index,
        homework: lecture?.homework,
        orderNumber: lecture?.orderNumber
      }))
      .filter((lecture) => lecture?.homework !== null);

    const isLectureHomeworkSettingsDefined =
      (variantName === 'assist' && purchasedCourseFeatures.includes('homeworks')) ||
      variantName === 'concierge';

    const viewedMaterials = listViewedMaterials(courseStudyData?.viewData);
    const lastSeenMaterial = getLastSeen(viewedMaterials);

    return (
      <div className="sk-purchased-course-materials">
        <div>
          <PurchasedCoursesHero course={course} />
          <div className="sk-purchased-course-materials__wrapper">
            {this.renderCoursesWebinar(courseStudyData)}
            <PurchasedCoursesMessenger messengerUrl={messengerUrl} />
            <div className="sk-purchased-course-materials__lectures">
              {course?.lectures?.map((item, index, arr) => {
                const isLectureDisabled =
                  isLectureHomeworkSettingsDefined &&
                  this.getViewEditAccess({
                    currentLecture: item,
                    lecturesWithHomework,
                    accessSettings: lecturesAccess,
                    homeworks: courseStudyData?.homeworksData
                  });
                const isHomeworkDisabled =
                  isLectureHomeworkSettingsDefined &&
                  this.getViewEditAccess({
                    currentLecture: item,
                    lecturesWithHomework,
                    accessSettings: homeworksAccess,
                    homeworks: courseStudyData?.homeworksData
                  });

                return (
                  <PurchasedCoursesLecture
                    courseStudyData={courseStudyData}
                    course={course}
                    child={this.setIndexOfChild(index, arr)}
                    key={item.id}
                    lecture={item}
                    index={index}
                    courseId={course.id}
                    getPermissionByLevel={checkIfLevelBlocked}
                    homeworkNumber={index}
                    displayLectureNameType={course?.displayLectureNameType}
                    refetchStudentCourse={refetchStudentCourse}
                    isLectureDisabled={isLectureDisabled}
                    isHomeworkDisabled={isHomeworkDisabled}
                    homeworksAccessSetting={homeworksAccess}
                    viewedMaterials={viewedMaterials}
                    lastSeenMaterial={lastSeenMaterial}
                  />
                );
              })}
            </div>
            {!isCourseCompleted && (
              <div className="sk-purchased-course-materials__completion">
                <div className="sk-purchased-course-materials__afterwords">
                  <Text>
                    <FormattedMessage {...messages.ifYouReachTheEnd} />
                  </Text>
                </div>
                <div>
                  <Button onClick={this.handleOpenModal(mappingId)}>
                    <FormattedMessage {...messages.completeCourse} />
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

// const getCourseQuery = graphql(GET_STUDENT_COURSE, {
//   skip: ({
//     match: {
//       params: { id },
//     },
//   }) => !id,
//   props: pr => {
//     const { getCourse, loading, error, refetch, ...ownProps } = pr.data;

//     return {
//       getCourse,
//       data: pr.data,
//       error,
//       loading,
//       refetchStudentCourse: refetch,
//       ...ownProps,
//     };
//   },
//   options: ({
//     match: {
//       params: { id },
//     },
//   }) => ({
//     variables: { input: { id, }},
//     pollInterval: 60000,
//     fetchPolicy: 'network-only',
//   }),
// });

const enhancer = compose(
  withRouter,
  withNotification,
  withModal
  // getCourseQuery
);

export default enhancer(PurchasedCourseMaterials);
