import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  firstName: {
    id: 'app.components.PasswordChangeForm.firstName',
    defaultMessage: 'First name',
  },
  lastName: {
    id: 'app.components.PasswordChangeForm.lastName',
    defaultMessage: 'Last name',
  },
  role: {
    id: 'app.components.PasswordChangeForm.role',
    defaultMessage: 'Role',
  },
  email: {
    id: 'app.components.PasswordChangeForm.email',
    defaultMessage: 'Email',
  },
  invite: {
    id: 'app.components.PasswordChangeForm.invite',
    defaultMessage: 'Invite',
  },
});
