// Core
import React, { Component, Fragment } from 'react';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

// Components
import RadioButton from 'components/RadioButton';

// Other
import { isBlank } from 'utils/helpers';

const RadioButtonGroup = ({
  style = null,
  options,
  name,
  label,
  value,
  classNameRadioGroup,
  classNameRadioButton,
  disabled: disabledGroup,
  handleChange,
}) => {
  return (
    <Fragment>
      {options?.length ? (
        <FormControl component="fieldset" style={{ width: 'inherit' }}>
          <FormLabel className='radio-button-group-label' component="legend">{label}</FormLabel>
          <RadioGroup
            aria-label={name}
            value={value}
            style={isBlank(style) ? { display: 'flex', justifyContent: 'space-around' } : style}
            className={`mui-override ${classNameRadioGroup}`}
          >
            {options.map(
              ({ value: optionValue, label: optionLabel, disabled }) => (
                <RadioButton
                  value={optionValue}
                  label={optionLabel}
                  disabled={disabled || disabledGroup}
                  key={optionValue}
                  name={name}
                  handleChange={handleChange}
                  classAdditional={`mui-override ${classNameRadioButton}`}
                />
              )
            )}
          </RadioGroup>
        </FormControl>
      ) : null}
    </Fragment>
  );
};

export default RadioButtonGroup;
