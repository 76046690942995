import Intl from 'services/i18n/instance';
import { messages } from './messages';

const getErrorMessage = code =>
  messages[code] ? Intl.get().formatMessage(messages[code]) : null;

export const parseIncomingError = ({ code, message }, flag) => {
  const errorMessage = getErrorMessage(code);
  if (!errorMessage) return {};

  if (code === 'UserNotConfirmedException') {
    return { email: errorMessage };
  }

  if (code === 'UsernameExistsException') {
    return { email: errorMessage };
  }

  if (code === 'CodeMismatchException') {
    return { code: errorMessage };
  }

  if (code === 'ExpiredCodeException') {
    return { code: errorMessage };
  }

  if (code === 'UserLambdaValidationException') {
    if (
      message &&
      (message.includes('ACCOUNT_ALREADY_LINKED') ||
        message.includes('ACCOUNT_LINKED'))
    ) {
      return { email: getErrorMessage('UsernameExistsException') };
    }
    if (message === 'PreAuthentication failed with error Company not found.') {
      return { email: getErrorMessage('DomainNotFound') };
    }
    if (
      message ===
      'PreAuthentication failed with error User does not belong to the company associated with the provided hostname.'
    ) {
      return { email: getErrorMessage('OrgUserNotFound') };
    }
  }

  if (code === 'NotAuthorizedException') {
    if (flag === 'Password') {
      return {
        oldPassword: getErrorMessage('NotAuthorizedExceptionPassword'),
      };
    }
    if (flag === 'status') {
      return {
        status: getErrorMessage('NotAuthorizedExceptionConfirmation'),
      };
    }

    return { email: errorMessage, password: errorMessage };
  }

  if (code === 'LimitExceededException') {
    if (flag === 'status') {
      return { status: errorMessage };
    }
    return { password: errorMessage };
  }

  if (code === 'UserNotFoundException') {
    if (flag === 'status') {
      return { status: errorMessage };
    }
    return { email: errorMessage };
  }

  return {};
};
