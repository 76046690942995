import React, { memo, useState, useCallback } from 'react';
import { compose } from 'react-apollo';
import { injectIntl } from 'react-intl';
import moment from 'moment';

// Components
import { IconText } from 'routes/Checkout/CheckoutLists/Subscriptions';
import Text from 'components/Text';
import Button from 'components/Button';
import SuccessIcon from 'routes/ExpertsCabinet/ExpertSubscription/ActiveSubsctiption/icons/Success.js';

// Other
import { withFormattedHtmlMessage } from 'containers/HOCs/withFormattedHtmlMessage';
import { messages } from 'routes/ExpertsCabinet/ExpertSubscription/messages.js';
import { sharedMessages } from 'services/i18n/sharedMessages/messages';

//Styles
import './index.scss';

const Initial = ({
  title,
  expiresAt,
  intl: { formatMessage },
  formatHtmlMessage,
  hideModal,
  handleCancelSubscription
}) => {
  return (
    <div className={PREFIX}>
      <Text>{formatMessage(messages.cancelCommissionTitle)}</Text>
      <Text size='small'>{formatHtmlMessage(messages.cancelSubscriptionDetails, { expiresAt: moment(expiresAt).format('DD.MM.YYYY'), title, })}</Text>
      <Text size='small'>{formatHtmlMessage(messages.cancelSubscriptionConsequences, { expiresAt: moment(expiresAt).add(1, 'days').format('DD.MM.YYYY') })}</Text>
      <div className={`${PREFIX}__actions`}>
        <Button
          size='large'
          color="secondary"
          onClick={hideModal}
        >
          {formatMessage(sharedMessages.closeButton)}
        </Button>
        <Button
          size='large'
          color="secondary"
          onClick={handleCancelSubscription}
        >
          {formatMessage(messages.cancelSubscription)}
        </Button>
      </div>
    </div>
  )
};
const Success = ({
  intl: { formatMessage }
}) => {
  return (
    <div className={PREFIX}>
      <SuccessIcon />
      <Text>{formatMessage(messages.successCancel)}</Text>
    </div>
  );
};

const PREFIX = 'sk-experts-cabinet-cancel-subscription';
const CancelSubscriptionModal = ({
  cancelSubscription,
  hideModal,
  ...props
}) => {
  const [status, setStatus] = useState('initial');
  const handleCancelSubscription = useCallback(async () => {
    await cancelSubscription();
    setStatus('success');
  }, [hideModal, cancelSubscription]);

  if (status == 'initial') {
    return <Initial {...props} handleCancelSubscription={handleCancelSubscription} hideModal={hideModal} />
  };

  if (status == 'success') {
    return <Success {...props} />
  };

  hideModal();
};

const enhancer = compose(
  memo,
  injectIntl,
  withFormattedHtmlMessage
);

export default enhancer(CancelSubscriptionModal);
