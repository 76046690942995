import React from 'react';

const FiltersIcon = () => (
  <svg className='sk-filters-icon' width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="active">
      <path d="M1 2.2666H5.07018M17 2.2666H9.98246" stroke="url(#paint_filter_0_linear)" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9.48232 2.4C9.48232 3.42548 8.59958 4.3 7.456 4.3C6.31242 4.3 5.42969 3.42548 5.42969 2.4C5.42969 1.37452 6.31242 0.5 7.456 0.5C8.59958 0.5 9.48232 1.37452 9.48232 2.4Z" stroke="url(#paint_filter_1_linear)"/>
      <path d="M1 7.8667H9.14035M17 7.8667H14.0526" stroke="url(#paint_filter_2_linear)" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M13.6933 8.0001C13.6933 9.02558 12.8105 9.9001 11.6669 9.9001C10.5234 9.9001 9.64062 9.02558 9.64062 8.0001C9.64062 6.97461 10.5234 6.1001 11.6669 6.1001C12.8105 6.1001 13.6933 6.97461 13.6933 8.0001Z" stroke="url(#paint_filter_3_linear)"/>
      <path d="M1 13.4668H5.07018M17 13.4668H9.84211" stroke="url(#paint_filter_4_linear)" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9.48232 13.6002C9.48232 14.6257 8.59958 15.5002 7.456 15.5002C6.31242 15.5002 5.42969 14.6257 5.42969 13.6002C5.42969 12.5747 6.31242 11.7002 7.456 11.7002C8.59958 11.7002 9.48232 12.5747 9.48232 13.6002Z" stroke="url(#paint_filter_5_linear)"/>
    </g>
    <g id="default">
      <path d="M1 2.2666H5.07018M17 2.2666H9.98246" stroke="#610FB6" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9.48232 2.4C9.48232 3.42548 8.59958 4.3 7.456 4.3C6.31242 4.3 5.42969 3.42548 5.42969 2.4C5.42969 1.37452 6.31242 0.5 7.456 0.5C8.59958 0.5 9.48232 1.37452 9.48232 2.4Z" stroke="#610FB6"/>
      <path d="M1 7.8667H9.14035M17 7.8667H14.0526" stroke="#610FB6" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M13.6933 8.0001C13.6933 9.02558 12.8105 9.9001 11.6669 9.9001C10.5234 9.9001 9.64062 9.02558 9.64062 8.0001C9.64062 6.97461 10.5234 6.1001 11.6669 6.1001C12.8105 6.1001 13.6933 6.97461 13.6933 8.0001Z" stroke="#610FB6"/>
      <path d="M1 13.4668H5.07018M17 13.4668H9.84211" stroke="#610FB6" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9.48232 13.6002C9.48232 14.6257 8.59958 15.5002 7.456 15.5002C6.31242 15.5002 5.42969 14.6257 5.42969 13.6002C5.42969 12.5747 6.31242 11.7002 7.456 11.7002C8.59958 11.7002 9.48232 12.5747 9.48232 13.6002Z" stroke="#610FB6"/>
    </g>
    <defs>
      <linearGradient id="paint_filter_0_linear" x1="-0.0666669" y1="3.00573" x2="15.7408" y2="-3.20203" gradientUnits="userSpaceOnUse">
      <stop stopColor="#30d5c8"/>
      <stop offset="1" stopColor="#55d5ca"/>
      <stop offset="1" stopColor="#3ad1c5"/>
      </linearGradient>
      <linearGradient id="paint_filter_1_linear" x1="4.59285" y1="3.54782" x2="10.3507" y2="3.39906" gradientUnits="userSpaceOnUse">
      <stop stopColor="#30d5c8"/>
      <stop offset="1" stopColor="#55d5ca"/>
      <stop offset="1" stopColor="#3ad1c5"/>
      </linearGradient>
      <linearGradient id="paint_filter_2_linear" x1="-0.0666669" y1="8.60583" x2="15.7408" y2="2.39807" gradientUnits="userSpaceOnUse">
      <stop stopColor="#30d5c8"/>
      <stop offset="1" stopColor="#55d5ca"/>
      <stop offset="1" stopColor="#3ad1c5"/>
      </linearGradient>
      <linearGradient id="paint_filter_3_linear" x1="8.80378" y1="9.14792" x2="14.5616" y2="8.99916" gradientUnits="userSpaceOnUse">
      <stop stopColor="#30d5c8"/>
      <stop offset="1" stopColor="#55d5ca"/>
      <stop offset="1" stopColor="#3ad1c5"/>
      </linearGradient>
      <linearGradient id="paint_filter_4_linear" x1="-0.0666669" y1="14.2059" x2="15.7408" y2="7.99817" gradientUnits="userSpaceOnUse">
      <stop stopColor="#30d5c8"/>
      <stop offset="1" stopColor="#55d5ca"/>
      <stop offset="1" stopColor="#3ad1c5"/>
      </linearGradient>
      <linearGradient id="paint_filter_5_linear" x1="4.59285" y1="14.748" x2="10.3507" y2="14.5993" gradientUnits="userSpaceOnUse">
      <stop stopColor="#30d5c8"/>
      <stop offset="1" stopColor="#55d5ca"/>
      <stop offset="1" stopColor="#3ad1c5"/>
      </linearGradient>
    </defs>
  </svg>
);

export default FiltersIcon;
