// Core
import React, { memo } from 'react';
import { compose, graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';

// Components
import Loader from 'components/Loader';
import Breadcrumbs from 'components/Breadcrumbs';
import Card from 'components/Card';
import ContentHeader from 'containers/ContentHeader';

// Other
import { messages } from 'routes/AdminPanel/messages';

// Styles and Assets
import './index.scss';

const PREFIX = 'sk-admin-plan-page-layout';
const Layout = ({
  children,
  ...props
}) => {

  // if (loading) return (<Loader />)

  return (
    <div className={PREFIX}>
      <ContentHeader way={<Breadcrumbs />} />
      <Card classes={`${PREFIX}__form`}>{children}</Card>
    </div>
  );
}

const enhancer = compose(
  memo,
);

export default enhancer(Layout);
