import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  webinars: {
    id: 'app.components.StudentSidebarContent.webinars',
    defaultMessage: 'Web Сlasses',
  },
  toppings: {
    id: 'app.components.CourseSidebar.toppings',
    defaultMessage: 'Toppings',
  },
});
