// Core
import React from 'react';
import { graphql, compose } from 'react-apollo';
import { keysToCamel } from 'utils/helpers';

// Components
import CoursesCarousel from 'routes/LandingPage/components/CoursesCarousel';
import NoCoursesFound from 'routes/LandingPage/components/NoCoursesFound';
import Heading from 'components/Heading';
import Loader from 'components/Loader';

// GraphQl
import LIST_COURSES_QUERY from 'queries/Courses/listCourses.gql';

// Styles
import './LandingPageCourses.scss';

const LandingPagePopularCourses = ({ listCourses, alreadyBoughtedCourses = [], title }) => {
  const courses = listCourses ? listCourses.courses : [];
  const total = listCourses?.total || null;
  if (total === 0) {
    return null
  }
  return(
    <div className="sk-landing-page__courses popular">
      <svg className="float-element" width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.5 29C22.9558 29 29 22.9558 29 15.5C29 8.04416 22.9558 2 15.5 2C8.04416 2 2 8.04416 2 15.5C2 22.9558 8.04416 29 15.5 29Z" stroke="#7145D8" strokeOpacity="0.238932" strokeWidth="4"/>
      </svg>
      <div className="sk-landing-page__courses_popular">
        <Heading variant="h2" classes="sk-lp-category-item__heading grid landing">
          {title}
        </Heading>
        {courses?.length ? (
          <CoursesCarousel
            courses={courses}
            alreadyBoughtedCourses={alreadyBoughtedCourses}
          />
        ) : (
          <div className="sk-landing-page__courses_loader-wrapper">
            <Loader />
          </div>
        )}
      </div>
    </div>
  )
}

const listCoursesQuery = graphql(LIST_COURSES_QUERY, {
  props: ({ data: { error, loading, listCourses, ...ownProps } }) => {
    if (error) throw Error(error);
    if (loading) return { loading, error };

    return {
      loading,
      listCourses,
      error,
      ...ownProps,
    }
  },
  options: ({ category }) => ({
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        category,
        complexity: '',
        duration_range: '',
        interaction_level: '',
        language: '',
        search_string: '',
        subcategory: '',
        from: 0,
        size: 12,
        sort_by: "rating",
        order: "desc",
        type: "course"
      },
    },
  }),
});

const enhancer = compose(
  listCoursesQuery
);

export default enhancer(LandingPagePopularCourses);
