import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  submittedOn: {
    id: 'app.components.HWLectureTitle.submittedOn',
    defaultMessage: 'Submitted on',
  },
  lecture: {
    id: 'app.components.Lecture.lecture',
    defaultMessage: 'Lecture',
  },
});
