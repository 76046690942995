import React from 'react';

const Icon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.82349 12.3281V2.78192C2.82349 2.28242 3.22309 1.88281 3.7226 1.88281H9.36148C9.62788 1.88281 9.87208 1.99381 10.0497 2.20472L12.5694 5.14626C12.7026 5.31276 12.7803 5.51256 12.7803 5.73457V12.3503C12.7803 13.2716 12.0366 14.0153 11.1153 14.0153L4.47741 13.9931C3.5672 13.982 2.82349 13.2383 2.82349 12.3281Z" stroke="#A9B6C8" strokeMiterlimit="10"/>
      <path d="M9.62769 1.9834V4.94714C9.62769 5.21354 9.83859 5.42445 10.105 5.42445L12.6802 5.44665" stroke="#A9B6C8" strokeMiterlimit="10"/>
      <path d="M4.70581 6.08789C4.42967 6.08789 4.20581 6.31175 4.20581 6.58789C4.20581 6.86403 4.42967 7.08789 4.70581 7.08789V6.08789ZM10.8235 7.08789C11.0996 7.08789 11.3235 6.86403 11.3235 6.58789C11.3235 6.31175 11.0996 6.08789 10.8235 6.08789V7.08789ZM4.70581 7.08789H10.8235V6.08789H4.70581V7.08789Z" fill="#A9B6C8"/>
      <path d="M4.70581 7.9707C4.42967 7.9707 4.20581 8.19456 4.20581 8.4707C4.20581 8.74685 4.42967 8.9707 4.70581 8.9707V7.9707ZM10.8235 8.9707C11.0996 8.9707 11.3235 8.74685 11.3235 8.4707C11.3235 8.19456 11.0996 7.9707 10.8235 7.9707V8.9707ZM4.70581 8.9707H10.8235V7.9707H4.70581V8.9707Z" fill="#A9B6C8"/>
      <path d="M4.70581 9.85254C4.42967 9.85254 4.20581 10.0764 4.20581 10.3525C4.20581 10.6287 4.42967 10.8525 4.70581 10.8525V9.85254ZM7.05875 10.8525C7.33489 10.8525 7.55875 10.6287 7.55875 10.3525C7.55875 10.0764 7.33489 9.85254 7.05875 9.85254V10.8525ZM4.70581 10.8525H7.05875V9.85254H4.70581V10.8525Z" fill="#A9B6C8"/>
    </svg>
  );
};

export default Icon;
