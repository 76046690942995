import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  courseReviews: {
    id: 'app.components.CourseReviews.courseReviews',
    defaultMessage: 'Course Reviews',
  },
  daysAgo: {
    id: 'app.components.CourseReviews.daysAgo',
    defaultMessage: 'days ago',
  },
});
