// Core
import React from 'react';

// Components
import Card from 'components/Card';
import TermsOfUseText from 'containers/TermsOfUseText';

// Styles and Assets
import './TermsOfUse.scss';

const TermsOfUse = () => (
  <div className="sk-terms-of-use">
    <div className="sk-terms-of-use__wrapper">
      <Card classes="sk-terms-of-use__card">
        <TermsOfUseText />
      </Card>
    </div>
  </div>
);

export default TermsOfUse;
