// Core
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Collapse from '@material-ui/core/Collapse';

// Components
import Card from 'components/Card';
import CourseEditTabs from 'routes/ExpertsCabinet/CourseEdit/CourseEditTabs';
import CourseInformation from 'routes/ExpertsCabinet/CourseEdit/Details/CourseInformation';
import CourseStructure from 'routes/ExpertsCabinet/CourseEdit/Details/CourseStructure';

// Other
import { COURSE_NEXT_STATUSES_EXPERT } from 'utils/enums';

// Styles and Assets
import './Details.scss';

class Details extends PureComponent {
  getNextStatus = () => {
    const {
      course: { statusId },
    } = this.props;
    return COURSE_NEXT_STATUSES_EXPERT[statusId];
  };

  render() {
    const {
      course,
      course: { statusId },
      disableAll,
      handleCourseSubmit,
      handleSwitch,
      activeTab,
      refetchCourse,
      isCourseStatusUpdating,
      org,
    } = this.props;

    const courseDeletionIsDisabled = false; //statusId === 'ACTIVE';

    return (
      <Card>
        <CourseEditTabs
          handleSwitch={handleSwitch}
          activeTab={activeTab}
          disabledTab={
            statusId === 'DRAFT' || disableAll ? 'courseStructure' : ''
          }
        />
        <div className="sk-course-details__content ">
          <Collapse
            in={activeTab === 'courseInfo'}
            timeout="auto"
            unmountOnExit
          >
            <CourseInformation
              isCourseStatusUpdating={isCourseStatusUpdating}
              course={course}
              refetchCourse={refetchCourse}
              handleCourseSubmit={handleCourseSubmit}
              disableAll={disableAll}
              org={org}
            />
          </Collapse>

          <Collapse
            in={activeTab === 'courseStructure'}
            timeout="auto"
            unmountOnExit
          >
            <CourseStructure
              disableDeletion={courseDeletionIsDisabled}
              course={course}
              disableAll={disableAll}
              refetchCourse={refetchCourse}
            />
          </Collapse>
        </div>
      </Card>
    );
  }
}

export default Details;
