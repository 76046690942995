// Core
import React, { memo } from 'react';
import { graphql, compose } from 'react-apollo';
import { injectIntl } from 'react-intl';

// Components
import SimpleSelect from 'components/Select/SimpleSelect';
import FilterDropDown from 'components/FilterDropDown';

// GraphQl
import LIST_COMPLEXITIES_QUERY from 'queries/Courses/listComplexities.gql';

// Other
import { messages } from 'routes/Course/components/ComplexitySelect/messages';

const ComplexitySelect = ({
  error,
  fetchError,
  loading,
  listComplexities,
  type = 'simple',
  intl: { formatMessage },
  ...props
}) => {
  const sortedListComplexities = listComplexities?.items.sort(
    (prev, cur) => prev.order_number - cur.order_number
  );

  // eslint-disable-next-line no-unused-expr
  sortedListComplexities &&
    sortedListComplexities.forEach(item => {
      const isMessageIdDefined = !!messages[item.title.replace(' ', '')]?.id;
      if (typeof item.title === 'string' && isMessageIdDefined) {
        item.title = formatMessage(messages[item.title.replace(' ', '')]);
      }
    });

  if (fetchError) return null;

  return (
    <>
      {type === 'simple' ? (
        <SimpleSelect
          inlineStyles={{ selector: { minHeight: 40 } }}
          options={listComplexities?.items}
          loading={loading}
          error={error}
          valueKey="id"
          labelKey="title"
          {...props}
        />
      ) : (
        <FilterDropDown
          minor={true}
          options={sortedListComplexities}
          placeholder={formatMessage(messages.courseComplexity)}
          valueKey="id"
          labelKey="title"
          isPlaceholderOptionHidden={true}
          {...props}
        />
      )}
    </>
  );
}

const listComplexitiesQuery = graphql(LIST_COMPLEXITIES_QUERY, {
  props: ({
    data: { loading, error: fetchError, listComplexities, ...ownProps },
  }) => ({
    loading,
    listComplexities,
    fetchError,
    ...ownProps,
  }),
  options: () => ({
    variables: { filter: { id: { between: [1, 4] } } },
    fetchPolicy: 'cache-and-network',
  }),
});

const enhancer = compose(
  memo,
  injectIntl,
  listComplexitiesQuery,
);

export default enhancer(ComplexitySelect);
