// Core
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const HomeworksGrid = ({
  triple,
  leftComponent,
  centerComponent,
  rightComponent,
  children,
  fullWidth,
}) => (
  <div
    className={classNames('grid grid_gapFree', { grid_fullWidth: fullWidth })}
  >
    <div className="grid_item-xs-offset-1-24 grid_item-md-offset-2-24" />

    {triple ? (
      <Fragment>
        <div className="grid_item-xs-8-24 grid_item-sm-7-24 grid_item-lg-6-24">
          {leftComponent}
        </div>
        <div className="grid_item-xs-4-24 grid_item-sm-7-24 grid_item-lg-8-24">
          {centerComponent}
        </div>
        <div className="grid_item-xs-8-24 grid_item-sm-6-24 grid_item-lg-6-24">
          {rightComponent}
        </div>
        <div className="grid_item-xs-offset-1-24  grid_item-lg-offset-2-24" />
      </Fragment>
    ) : (
      <Fragment>
        <div className="grid_item-xs-22-24 grid_item-md-21-24 grid_item-lg-20-24">
          {children}
        </div>
        <div className="grid_item-md-offset-1-24 grid_item-lg-offset-2-24" />
      </Fragment>
    )}
  </div>
);

HomeworksGrid.defaultProps = {
  fullWidth: true,
  triple: false,
  leftComponent: () => {},
  centerComponent: () => {},
  rightComponent: () => {},
};

HomeworksGrid.propTypes = {
  leftComponent: PropTypes.any,
  centerComponent: PropTypes.any,
  rightComponent: PropTypes.any,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  fullWidth: PropTypes.bool,
  triple: PropTypes.bool,
};

export default HomeworksGrid;
