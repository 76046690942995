import { Query } from 'services/apollo/localState/resolvers/queries';
import { Mutation } from 'services/apollo/localState/resolvers/mutations';
import {
  Student,
  Lecture,
  Course,
  expertListCoursesType,
} from './resolversByTypename';

const resolvers = {
  Mutation,
  Query,
  Lecture,
  Student,
  Course,
  expertListCoursesType,
};

export default resolvers;
