import React from 'react';
import PropTypes from 'prop-types';

import { MaterialGrey } from 'components/Icon/color';

const Visibility = ({ height, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 21 14"
  >
    <path
      fill={MaterialGrey}
      fillRule="nonzero"
      d="M10.083 0C5.5 0 1.586 2.85 0 6.875c1.586 4.024 5.5 6.875 10.083
       6.875 4.584 0 8.498-2.85 10.084-6.875C18.58 2.851 14.667 0 10.083
        0zm0 11.458A4.585 4.585 0 0 1 5.5 6.875a4.585 4.585 0 0 1 4.583-4.583
        4.585 4.585 0 0 1 4.584 4.583 4.585 4.585 0 0 1-4.584 4.583zm0-7.333a2.746
      2.746 0 0 0-2.75 2.75 2.746 2.746 0 0 0 2.75 2.75 2.746 2.746 0 0 0 2.75-2.75 2.746 2.746 0 0 0-2.75-2.75z"
    />
  </svg>
);

Visibility.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

Visibility.defaultProps = {
  height: '21',
  width: '14',
};

export default Visibility;
