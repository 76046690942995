import React, { memo } from 'react';
import { compose } from 'react-apollo';

const Icon = ({ ...props }) => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M4.94141 6.14697H20.0591C20.6113 6.14697 21.0591 6.59469 21.0591 7.14697V7.70425H3.94141V7.14697C3.94141 6.59469 4.38912 6.14697 4.94141 6.14697ZM22.0591 8.70425V7.70425V7.14697C22.0591 6.0424 21.1636 5.14697 20.0591 5.14697H4.94141C3.83684 5.14697 2.94141 6.0424 2.94141 7.14697V7.70425V8.70425V10.076V11.076V17.3771C2.94141 18.4816 3.83683 19.3771 4.9414 19.3771H20.0591C21.1636 19.3771 22.0591 18.4816 22.0591 17.3771V11.076V10.076V8.70425ZM3.94141 10.076V8.70425H21.0591V10.076H3.94141ZM21.0591 11.076V17.3771C21.0591 17.9294 20.6113 18.3771 20.0591 18.3771H4.9414C4.38912 18.3771 3.94141 17.9294 3.94141 17.3771V11.076H21.0591ZM5.69037 12.4478C5.41423 12.4478 5.19037 12.6716 5.19037 12.9478C5.19037 13.2239 5.41423 13.4478 5.69037 13.4478L10.3132 13.4478C10.5894 13.4478 10.8132 13.2239 10.8132 12.9478C10.8132 12.6716 10.5894 12.4478 10.3132 12.4478L5.69037 12.4478ZM5.19037 15.3195C5.19037 15.0434 5.41423 14.8195 5.69037 14.8195L10.3132 14.8195C10.5894 14.8195 10.8132 15.0434 10.8132 15.3195C10.8132 15.5957 10.5894 15.8195 10.3132 15.8195L5.69037 15.8195C5.41423 15.8195 5.19037 15.5957 5.19037 15.3195ZM18.0929 12.4478H17.0296C16.1498 12.4478 15.4366 13.1609 15.4366 14.0407C15.4366 14.9204 16.1498 15.6336 17.0296 15.6336H18.0929C18.9726 15.6336 19.6858 14.9204 19.6858 14.0407C19.6858 13.1609 18.9726 12.4478 18.0929 12.4478ZM16.4366 14.0407C16.4366 13.7132 16.7021 13.4478 17.0296 13.4478H18.0929C18.4203 13.4478 18.6858 13.7132 18.6858 14.0407C18.6858 14.3681 18.4203 14.6336 18.0929 14.6336H17.0296C16.7021 14.6336 16.4366 14.3681 16.4366 14.0407Z" fill="#20292F"/>
    </svg>
  );
};

const enhancer = compose(
  memo,
);

export default enhancer(Icon);
