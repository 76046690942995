import React from 'react';
import PropTypes from 'prop-types';
import { Red } from 'components/Icon/color';

const RedCross = ({ height=20, width=21, color=Red, hoverColor=Red, strokeWidth }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox='0 0 21 20'
  >
    <rect x="1" y="17.6777" width="25" height="2" rx="1" transform="rotate(-45 1 17.6777)" fill={color} />
    <rect x="2" width="25" height="2" rx="1" transform="rotate(45 2 0)" fill={color} />
  </svg>
);

export default RedCross;
