import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  flexText: {
    id: 'app.components.AdditionalCourseDetails.flexText',
    defaultMessage: 'Flex: Video content + supporting materials',
  },
  assistText: {
    id: 'app.components.AdditionalCourseDetails.assistText',
    defaultMessage: 'Assist : Flex + regular web classes',
  },
  conciergeText: {
    id: 'app.components.AdditionalCourseDetails.conciergeText',
    defaultMessage: 'Concierge: Assist + homework',
  },
});
