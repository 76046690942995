// Core
import React, { useState, useEffect, useCallback } from 'react';
import { graphql, compose } from 'react-apollo';
import { withRouter } from 'react-router';
import { FormattedMessage } from 'react-intl';

// Components
import Link from 'components/Link';
import Button from 'components/Button';
import SidebarMenuItem from 'containers/Sidebar/SidebarContent/SidebarMenu/SidebarMenuItem';
import ToppingsList from 'containers/Sidebar/SidebarContent/CourseSidebar/ToppingsList';
import DraftsCoursesList from 'containers/Sidebar/SidebarContent/CourseSidebar/DraftsCoursesList';
import ActiveCoursesList from 'containers/Sidebar/SidebarContent/CourseSidebar/ActiveCoursesList';
import MenuLightBulb from 'components/Icon/Svg/MenuLightBulb';
import MenuCalendar from 'components/Icon/Svg/MenuCalendar';
import CreateToppingModal from 'routes/ExpertsCabinet/ToppingEdit/CreateToppingModal';
import ExpertPlansButton from 'components/Button/ExpertPlansButton';

// GraphQL
import LIST_EXPERT_COURSES from 'queries/Courses/listExpertCourses.gql';

// HOCs
import { withSidebar } from 'containers/SidebarProvider/withSidebar';
import { hasLoader } from 'containers/HOCs/hasLoader';
import { withModal } from 'containers/ModalProvider/withModal';

// Other
import { messages } from 'containers/Sidebar/SidebarContent/CourseSidebar/messages';
import { isBlank } from 'utils/helpers';

// Styles and Assets
import './CourseSidebar.scss';

export const CourseSidebar = ({
  match,
  expertListCourses,
  error,
  sidebarContext,
  history,
  modalContext: { showModal, hideModal }
}) => {
  const [openedItem, setOpenedItem] = useState(null);
  const [openedSubItem, setOpenedSubItem] = useState(null);
  const [coursesList, setCourseList] = useState([]);
  const [toppingsList, setToppingsList] = useState([]);

  const onClose = useCallback(() => {
    const { hideSidebar, width } = sidebarContext;
    const isResponsive = ['xs', 'sm'].includes(width);

    if (isResponsive) hideSidebar();
  }, []);

  if (error) throw Error(error);
  useEffect(() => {
    const courses = !isBlank(expertListCourses) && expertListCourses.items.filter(item => item.type === "course");
    const toppings = !isBlank(expertListCourses) && expertListCourses.items.filter(item => item.type === "topping");

    setToppingsList(toppings);
    setCourseList(courses);

  }, [match.params?.id, setOpenedSubItem, setOpenedItem, setCourseList, setToppingsList, expertListCourses]);

  const handleListToggle = nextOpenedItem => (event) => {
    event.preventDefault();
    event.stopPropagation();
    setOpenedItem(nextOpenedItem === openedItem ? 'none' : nextOpenedItem);
  };

  const handleSubListToggle = nextOpenedSubItem => {
    setOpenedSubItem(
      openedSubItem === nextOpenedSubItem ? 'none' : nextOpenedSubItem
    );
  };
  const goToListPage = (path) => () => {
    history.push(path);
  };

  const showToppingCreateModal = useCallback(() => {
    showModal(CreateToppingModal, {
      size: 'md',
      hideModal,
    });
  }, [showModal, hideModal])

  return (
    <div className="sk-course-sidebar">
      <div className="sk-sidebar-block sk-course-sidebar__block">
        <ActiveCoursesList
          onClose={onClose}
          openedSubItem={openedSubItem}
          list={coursesList}
          open={openedItem === 'active'}
          goToListPage={goToListPage('/experts-cabinet/courses')}
          handleClick={handleListToggle('active')}
          handleSubMenuTitleClick={handleSubListToggle}
        />
        <ToppingsList
          onClose={onClose}
          openedSubItem={openedSubItem}
          list={toppingsList}
          open={openedItem === 'toppings'}
          goToListPage={goToListPage('/experts-cabinet/toppings')}
          handleClick={handleListToggle('toppings')}
          handleSubMenuTitleClick={handleSubListToggle}
        />
        <FormattedMessage {...messages.webinarSectionTitle}>
          {msg => (
            <SidebarMenuItem
              onClose={onClose}
              icon={MenuCalendar}
              label={msg}
              linkTo="/experts-cabinet/calendar"
            />
          )}
        </FormattedMessage>
      </div>
      <div className="sk-course-sidebar__action-block">
        <Link to="/course-creation" onClick={onClose}>
          <Button
            variant="outlined"
            color="secondary"
            size="large"
            type="button"
            transparent
          >
            <FormattedMessage {...messages.createCourse} />
          </Button>
        </Link>
        <Button
          variant="outlined"
          color="primary"
          size="large"
          transparent
          onClick={showToppingCreateModal}
        >
          <FormattedMessage {...messages.createTopping} />
        </Button>
        <ExpertPlansButton size='large' />
      </div>
    </div>
  );
};

const listExpertCoursesQuery = graphql(LIST_EXPERT_COURSES, {
  props: ({ data: { expertListCourses, active, drafts, toppings, loading, error, ...ownProps } }) => {
    return {
      drafts,
      active,
      expertListCourses,
      toppings,
      error,
      ...ownProps,
    }
  },
  options: () => ({
    // pollInterval: 60000,
    fetchPolicy: 'network-only',
    // fetchPolicy: 'cache-and-network',
  }),
});

const enhancer = compose(
  withSidebar,
  withRouter,
  withModal,
  listExpertCoursesQuery
);

export default hasLoader(enhancer(CourseSidebar), LIST_EXPERT_COURSES);
