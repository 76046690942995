// Core
import React, { useState, useEffect } from 'react';

// Components
import SimpleSelect from 'components/Select/SimpleSelect';

// Other
import FONT_SIZES from './options';

import './index.scss';

const defaultOption = 'unstyled';
const options = FONT_SIZES.map(fontSize => fontSize?.value);

const FontSizeControls = ({ error, editorState, setFontSize, ...props }) => {
  const [fontSizeOption, setFontSizeOption] = useState(defaultOption);

  useEffect(() => {
    const selection = editorState.getSelection();
    const currentFontSize = editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey())
      .getType();
    const initialSize = options.includes(currentFontSize)
      ? currentFontSize
      : defaultOption;
    setFontSizeOption(initialSize);
  }, [editorState]);

  const handleChange = chosenValue => {
    setFontSizeOption(chosenValue);
    setFontSize(chosenValue);
  };

  return (
    <div className="sk-editor-controls-bar-font-size-select">
      <SimpleSelect
        options={FONT_SIZES}
        error={error}
        valueKey="value"
        labelKey="label"
        value={fontSizeOption}
        handleChange={handleChange}
        {...props}
      />
    </div>
  );
};

export default FontSizeControls;
