// Core
import React, { useState, useEffect, useCallback } from 'react';
import { graphql, compose } from 'react-apollo';
import { withRouter, useHistory, useParams } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { withFormik } from 'formik';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

// Components
import Loader from 'components/Loader';
import Button from 'components/Button';
import QuizInfo from 'routes/ExpertsCabinet/QuizEdit/QuizInfo';
import QuizQuestion from 'routes/ExpertsCabinet/QuizEdit/QuizQuestion';
import AddEntityButton from 'routes/Course/components/AddEntityButton';
import PageNotFound from 'routes/PageNotFound';

// GraphQL
import GET_QUIZ from 'queries/Courses/Quiz/getQuiz.gql';
import CREATE_QUIZ_QUESTION from 'mutations/Courses/quiz/createQuizQuestion.gql';
import REORDER_QUIZ_ANSWER from 'mutations/Courses/quiz/reorderQuizAnswer.gql';
import REORDER_QUIZ_QUESTION from 'mutations/Courses/quiz/reorderQuizQuestion.gql';

// Other
import { ADMIN, } from 'utils/enums/index';
import { withFormattedUntitled } from 'routes/ExpertsCabinet/QuizEdit/HOCs/withFormattedUntitled.js';
import { withNotification } from 'containers/NotificationProvider/withNotification';
import {
  keysToCamel,
  keysToSnake,
  parseFetchedData,
  isBlank,
  isNull,
  compareSortNumber,
  findItemByKey,
  generateDraggableId,
  parseDraggableId,
} from 'utils/helpers';
import { withModal } from 'containers/ModalProvider/withModal';
import { withSharedState } from 'containers/SharedStateProvider/withSharedState';
import { messages } from 'routes/ExpertsCabinet/QuizEdit/messages';
import { withLoader } from 'containers/HOCs/withLoader';
import { withAuthenticatedUser } from 'containers/AuthenticatedUserProvider/withAuthenticatedUser';

// Styles and Assets
import './index.scss';

const QuizEdit = ({
  loading,
  quiz,
  refetchQuiz,
  createQuizQuestion,
  updateQuizQuestion,
  updateQuizAnswer,
  showNotificationBar,
  history,
  intl: { formatMessage },
  userContext: { profile },
}) => {
  const { courseId, quizParent } = useParams();
  const handleBack = useCallback(() => {
    const isAdmin = profile?.role == ADMIN;
    const route = isAdmin ? 'admin/panel' : 'experts-cabinet';

    if (profile.id !== quiz?.author && profile.role !== 'admin') {
      return (<PageNotFound />);
    }

    history.push(`/${route}/${quizParent}/${courseId}/${(quizParent === 'toppings' || isAdmin) ? '': 'edit'}`);
  }, [courseId, quizParent, profile.role, history.push]);

  const addQuestion = async () => {
    const input = {
      quiz_id: quiz?.id,
      sort_number: quiz.questions.length + 1,
    };

    await createQuizQuestion(input);
  };

  const onDragEnd = async (params, callback) => {
    console.log('on drag end', params);
    const { destination, source, draggableId, reason } = params;
    const isCanceled = reason === 'CANCEL';
    if (isCanceled || destination === null) return;

    const draggableItemParams = parseDraggableId(draggableId);
    const { type: dragType, questionId, answerId } = draggableItemParams;

    let handleMutation = null;
    let mutationParams = null;

    const newSortNumber = destination?.index + 1;

    if (dragType === 'question') {
      mutationParams = {
        input: {
          id: questionId,
          sortNumber: newSortNumber,
        },
        questionId,
        quizId: quiz?.id,
        sortNumber: newSortNumber,
      };
      handleMutation = updateQuizQuestion;
    } else if (dragType === 'answer') {
      mutationParams = {
        input: {
          id: answerId,
          sortNumber: newSortNumber,
        },
        answerId,
        questionId,
        quizId: quiz?.id,
        sortNumber: newSortNumber,
      };
      handleMutation = updateQuizAnswer;
    }

    console.log('drag end params', mutationParams, handleMutation);

    if (isNull(handleMutation) || isNull(mutationParams)) {
      console.error('Mutation or input are not defined!');
      return;
    }

    await handleMutation(mutationParams);
  };

  const sortedQuestions = !isBlank(quiz?.questions)
    ? quiz?.questions.sort(compareSortNumber)
    : [];

  return (
    <div className="sk-quiz">
      <QuizInfo
        quizId={quiz?.id}
        title={quiz?.title}
        duration={quiz?.duration}
      />

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable-question" type="question">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {sortedQuestions.map((question, index) => {
                const { id: questionId } = question;
                return (
                  <Draggable
                    type="question"
                    key={question.id}
                    draggableId={generateDraggableId({
                      questionId,
                      type: 'question',
                    })}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <QuizQuestion
                          key={question?.id}
                          questionsCount={sortedQuestions.length}
                          question={question}
                          refetchQuiz={refetchQuiz}
                          updateQuizQuestion={updateQuizQuestion}
                          updateQuizAnswer={updateQuizAnswer}
                        />
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <AddEntityButton
        label={formatMessage(messages.addQuestion)}
        handleAddClick={addQuestion}
      />

      <Button
        color="secondary"
        variant="text"
        size="large"
        className="sk-quiz__save-btn"
        onClick={handleBack}
      >
        {formatMessage(messages.saveAndClose)}
      </Button>
    </div>
  );
};

const getQuizQuery = graphql(GET_QUIZ, {
  skip: ({ match }) => !match?.params?.quizId,
  props: props => {
    const {
      data: { getQuiz, error, loading, refetch, ...ownProps },
      ownProps: { history },
    } = props;
    if (error) {
      throw new Error(error);
    }
    if (isBlank(getQuiz) && !loading) {
      history.replace('/');
    }

    const parsedQuiz = keysToCamel(getQuiz);

    console.log('parsed quiz', parsedQuiz);

    return {
      quiz: parsedQuiz,
      refetchQuiz: refetch,
      loading,
      error,
      ...ownProps,
    };
  },
  options: ({ match }) => {
    const { quizId } = match?.params || {};
    return {
      variables: { input: { id: quizId } },
      // fetchPolicy: 'network-only',
    };
  },
});

const createQuizQuestionMutation = graphql(CREATE_QUIZ_QUESTION, {
  props: ({ mutate }) => ({
    createQuizQuestion: input => mutate({ variables: { input } }),
  }),
  options: () => ({
    refetchQueries: ['getQuiz'],
  }),
});

const reorderQuizQuestionMutation = graphql(REORDER_QUIZ_QUESTION, {
  props: ({ mutate }) => ({
    updateQuizQuestion: ({ input, ...updateParams }) => {
      mutate({
        variables: { input: keysToSnake(input) },
        optimisticResponse: {},
        update: (cache, { data: { reorderAttachment } }) => {
          try {
            const data = cache.readQuery({
              query: GET_QUIZ,
              variables: { input: { id: updateParams.quizId } },
            });
            const dataCopy = JSON.parse(JSON.stringify(data));

            const quiz = dataCopy.getQuiz;
            const reorderedQuestion = findItemByKey(
              quiz.questions,
              'id',
              updateParams.questionId
            );

            if (isBlank(reorderedQuestion)) {
              return;
            }

            const initSortNumber = reorderedQuestion.sort_number;
            const nextSortNumber = updateParams.sortNumber;

            if (nextSortNumber < initSortNumber) {
              quiz.questions.forEach(question => {
                const questionOrderNumber = question.sort_number;
                if (
                  initSortNumber > questionOrderNumber &&
                  questionOrderNumber >= nextSortNumber
                ) {
                  question.sort_number = questionOrderNumber + 1;
                }
              });
            } else if (nextSortNumber > initSortNumber) {
              quiz.questions.forEach(question => {
                const questionOrderNumber = question.sort_number;
                if (
                  initSortNumber < questionOrderNumber &&
                  questionOrderNumber <= nextSortNumber
                )
                  question.sort_number = questionOrderNumber - 1;
              });
            }

            console.log('reordered question', reorderedQuestion);

            reorderedQuestion.sort_number = nextSortNumber;
            const response = cache.writeQuery({
              query: GET_QUIZ,
              data: dataCopy,
            });
          } catch (e) {
            console.log(e);
          }
        },
      });
    },
  }),
  options: () => ({
    refetchQueries: ['getQuiz'],
  }),
});

const reorderQuizAnswerMutation = graphql(REORDER_QUIZ_ANSWER, {
  props: ({ mutate }) => ({
    updateQuizAnswer: ({ input, ...updateParams }) => {
      mutate({
        variables: { input: keysToSnake(input) },
        optimisticResponse: {},
        update: (cache, { data: { reorderAttachment } }) => {
          try {
            const data = cache.readQuery({
              query: GET_QUIZ,
              variables: { input: { id: updateParams.quizId } },
            });
            const dataCopy = JSON.parse(JSON.stringify(data));

            const quiz = dataCopy.getQuiz;
            const question = findItemByKey(
              quiz.questions,
              'id',
              updateParams.questionId
            );

            if (isBlank(question)) {
              return;
            }

            const reorderedAnswer = findItemByKey(
              question.answers,
              'id',
              updateParams.answerId
            );

            if (isBlank(reorderedAnswer)) {
              return;
            }

            const initSortNumber = reorderedAnswer.sort_number;
            const nextSortNumber = updateParams.sortNumber;

            if (nextSortNumber < initSortNumber) {
              question.answers.forEach(answer => {
                const answerOrderNumber = answer.sort_number;
                if (
                  initSortNumber > answerOrderNumber &&
                  answerOrderNumber >= nextSortNumber
                ) {
                  answer.sort_number = answerOrderNumber + 1;
                }
              });
            } else if (nextSortNumber > initSortNumber) {
              question.answers.forEach(answer => {
                const answerOrderNumber = answer.sort_number;
                if (
                  initSortNumber < answerOrderNumber &&
                  answerOrderNumber <= nextSortNumber
                )
                  answer.sort_number = answerOrderNumber - 1;
              });
            }

            console.log('reordered answer', reorderedAnswer);

            reorderedAnswer.sort_number = nextSortNumber;
            const response = cache.writeQuery({
              query: GET_QUIZ,
              data: dataCopy,
            });
          } catch (e) {
            console.log(e);
          }
        },
      });
    },
  }),
  options: () => ({
    refetchQueries: ['getQuiz'],
  }),
});

const enhancer = compose(
  withNotification,
  withFormattedUntitled,
  withRouter,
  withModal,
  injectIntl,
  withAuthenticatedUser,
  getQuizQuery,
  createQuizQuestionMutation,
  reorderQuizQuestionMutation,
  reorderQuizAnswerMutation,
  withLoader,
);

export default enhancer(QuizEdit);
