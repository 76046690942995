// Core
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { compose, graphql } from 'react-apollo';

// Components
import CourseGrid from 'routes/Course/components/CourseGrid';
import LectureDescription from 'routes/Course/components/LectureDescription';
import LectureMaterials from 'routes/Course/components/LectureMaterials';
import HomeworkUploader from 'routes/Course/components/HomeworkUploader';

// Styles
import './LectureDetails.scss';

class LectureDetails extends PureComponent {
  handleInputChange = key => inputValue => {
    const { handleInputChange } = this.props;
    return handleInputChange({ [key]: inputValue });
  };

  render() {
    const {
      droppableId,
      courseMaxInterLevel,
      courseFeatures,
      lecture,
      lecture: { description, id },
      courseId,
      disabled,
      videoIsUploading,
      disableDeletion,
      refetchCourse,
      handleUploadArticle,
      handleUploadVideo,
      handleUploadAudio,
      handleUploadHomework,
      handleUploadQuiz,
      handleAttachmentDelete,
    } = this.props;

    const isHomeworkAvailable = courseFeatures.includes('homeworks');

    return (
      <CourseGrid>
        <div className="sk-lec-details-component grid grid_gapFree">
          <div
            className={classNames(
              'sk-lec-details-component__inner',
              'grid_item-xs-24-24',
              {
                'sk-lec-details-component__inner_withBorder': !isHomeworkAvailable,
              }
            )}
          >
            <div className="sk-lec-details-content">
              <div className="sk-lec-details-content__desc ">
                <LectureDescription
                  description={description}
                  handleInputChange={this.handleInputChange}
                />
              </div>
              <div className="sk-lec-details-content__materials">
                <LectureMaterials
                  droppableId={droppableId}
                  entities={lecture?.materials}
                  lecture={lecture}
                  lectureId={id}
                  courseId={courseId}
                  disableDeletion={disableDeletion}
                  handleUploadPdf={handleUploadArticle}
                  handleUploadVideo={handleUploadVideo}
                  handleUploadAudio={handleUploadAudio}
                  handleUploadText={handleUploadArticle}
                  handleUploadQuiz={handleUploadQuiz}
                  handleAttachmentDelete={handleAttachmentDelete}
                />
              </div>
            </div>
          </div>
          {isHomeworkAvailable ? (
            <div className="sk-lec-details-component__inner grid_item-xs-24-24">
              <HomeworkUploader
                lectureId={id}
                courseId={courseId}
                disableDeletion={disableDeletion}
                disabled={disabled}
                entity={lecture.homework}
                refetchCourse={refetchCourse}
                handleUploadPdf={handleUploadHomework}
                handleAttachmentDelete={handleAttachmentDelete}
              />
            </div>
          ) : null}
        </div>
      </CourseGrid>
    );
  }
}

LectureDetails.defaultProps = {
  lecture: {},
  handleAddLectureVideo: () => {},
  handleAddLectureAudio: () => {},
  handleAddLectureArticle: () => {},
  handleArticleReload: () => {},
};

LectureDetails.propTypes = {
  lecture: PropTypes.object,
  handleInputChange: PropTypes.func,
  handleAddLectureVideo: PropTypes.func,
  handleAddLectureAudio: PropTypes.func,
  handleAddLectureArticle: PropTypes.func,
  handleArticleReload: PropTypes.func,
  handleEntityDelete: PropTypes.func,
  handleVideoReload: PropTypes.func,
  handleAudioReload: PropTypes.func,
  handleHomeworkDelete: PropTypes.func,
  courseId: PropTypes.string,
  disabled: PropTypes.bool,
  videoIsUploading: PropTypes.bool,
  disableDeletion: PropTypes.bool,
  courseMaxInterLevel: PropTypes.string,
};

export default LectureDetails;
