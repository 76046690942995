// Core
import React, { PureComponent, useState, useEffect } from "react";
import Router from "containers/Navigation/Router";
import { graphql, compose } from "react-apollo";

// Components
import Loader from "components/Loader";

// Other
import { withAWS } from "containers/AWSProvider/withAWS";
import { withLanguage } from "containers/LanguageProvider/withLanguage";
import { withAuthenticatedUser } from "containers/AuthenticatedUserProvider/withAuthenticatedUser";
import { isBlank } from "utils/helpers";

const Navigation = ({
  userContext: { getProfile, profile, domain, isAuthenticated, isUserAuthorized },
  serverContext,
  languageContext,
}) => {
  const userRole = profile?.role || "student";
  // if (error) throw Error(error);
  return (
    <Router
      role={userRole}
      serverContext={serverContext}
      languageContext={languageContext}
      domain={domain}
      isAuthenticated={isAuthenticated}
      isUserAuthorized={isUserAuthorized}
    />
  );
};

const enhancer = compose(withAWS, withAuthenticatedUser, withLanguage);

export default enhancer(Navigation);
