import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  lang: {
    id: 'app.components.BuyCourse.lang',
    defaultMessage: 'LANGUAGE',
  },
  complexity: {
    id: 'app.components.BuyCourse.complexity',
    defaultMessage: 'COMPLEXITY',
  },
  totalLectures: {
    id: 'app.components.BuyCourse.totalLectures',
    defaultMessage: 'lectures',
  },
  totalSections: {
    id: 'app.components.BuyCourse.totalSections',
    defaultMessage: 'lectures',
  },
  totalHrs: {
    id: 'app.components.BuyCourse.totalHrs',
    defaultMessage: 'Total Duration',
  },
  Alllevels: {
    id: 'app.components.CourseCard.Alllevels',
    defaultMessage: 'All levels',
  },
  Beginner: {
    id: 'app.components.CourseCard.Beginner',
    defaultMessage: 'Beginner',
  },
  Intermediate: {
    id: 'app.components.CourseCard.Intermediate',
    defaultMessage: 'Intermediate',
  },
  Advanced: {
    id: 'app.components.CourseCard.Advanced',
    defaultMessage: 'Advanced',
  },
  access: {
    id: 'app.components.CourseCard.access',
    defaultMessage: 'Lifetime access',
  },
  certificatesFeature: {
    id: 'app.components.CourseCard.certificatesFeature',
    defaultMessage: 'Certificate',
  },
  chatsFeature: {
    id: 'app.components.CourseCard.chatsFeature',
    defaultMessage: 'Chat',
  },
  homeworksFeature: {
    id: 'app.components.CourseCard.homeworksFeature',
    defaultMessage: 'Homework',
  },
  interectiveTestsFeature: {
    id: 'app.components.CourseCard.interectiveTestsFeature',
    defaultMessage: 'Quizzes',
  },
  studyMaterialsFeature: {
    id: 'app.components.CourseCard.studyMaterialsFeature',
    defaultMessage: 'Study materials',
  },
  videoLecturesFeature: {
    id: 'app.components.CourseCard.videoLecturesFeature',
    defaultMessage: 'Video lectures',
  },
  webinarsFeature: {
    id: 'app.components.CourseCard.webinarsFeature',
    defaultMessage: 'Web Сlasses',
  },
  flex: {
    id: 'app.components.CourseCard.flex',
    defaultMessage: 'Flex'
  },
  assist: {
    id: 'app.components.CourseCard.assist',
    defaultMessage: 'Assist'
  },
  concierge: {
    id: 'app.components.CourseCard.concierge',
    defaultMessage: 'Concierge'
  },
});
