// Core
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';

// Components
import Card from 'components/Card';
import GeneralInfoForm from 'routes/ExpertsCabinet/CourseEdit/GeneralInfo/GeneralInfoForm';
import StatusInfo from 'routes/ExpertsCabinet/CourseEdit/GeneralInfo/StatusInfo';
import ActionPanel from 'routes/ExpertsCabinet/CourseEdit/GeneralInfo/ActionPanel';
import CourseGrid from 'routes/Course/components/CourseGrid';

// Other
// import { withFormErrors } from 'containers/FormErrorsProvider/withFormErrors';

// Styles and Assets
import './GeneralInfo.scss';

class GeneralInfo extends PureComponent {
  state = {};

  render() {
    const {
      course: {
        category,
        subcategory = null,
        statusId,
        id,
        title,
        author,
      },
      disableAll,
      roles,
      isCourseStatusUpdating,
      handleMailSend,
    } = this.props;

    const generalInfo = {
      category: category?.id,
      subcategory: subcategory?.id || null,
    };

    return (
      <Card classes="sk-course-general-info">
        <CourseGrid>
          <div className="sk-course-general-info__action-panel">
            <ActionPanel
              statusId={statusId}
              disabled={disableAll}
              roles={roles}
              id={id}
              author={author}
              isCourseStatusUpdating={isCourseStatusUpdating}
              handleMailSend={handleMailSend}
            />
          </div>
          <div className="sk-course-general-info__inner">
            <div className="sk-course-general-info__form">
              <GeneralInfoForm
                initialValues={generalInfo}
                disableAll={disableAll}
              />
            </div>
            <div className="sk-course-general-info__status">
              <StatusInfo status={statusId} course={{ id, title }} />
            </div>
          </div>
        </CourseGrid>
      </Card>
    );
  }
}

export default GeneralInfo;
