// Core
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// Components
import Loader from 'components/Loader';
import SimpleSidebarMenu from 'containers/Sidebar/SidebarContent/SimpleSidebarMenu';
import SidebarMenuItem from 'containers/Sidebar/SidebarContent/SidebarMenu/SidebarMenuItem';
import MenuPencil from 'components/Icon/Svg/MenuPencil';

// Other
import { FormattedMessage } from 'react-intl';
import { messages } from 'containers/Sidebar/SidebarContent/CourseSidebar/messages';
import { renderTrimmedText } from 'utils/helpers/index';

class DraftsCoursesList extends PureComponent {
  getMenuItems = (list, onClose, handleSubMenuTitleClick) =>
    list.map(({ id, title }) => ({
      id,
      path: `/experts-cabinet/course/${id}/edit`,
      label:  title,
      handleCustomClick: courseId => {
        handleSubMenuTitleClick(courseId);
        const isTabletScreenWidth = window.innerWidth <= 1024;

        if (isTabletScreenWidth) {
          onClose();
        }
      },
    }));

  renderSimpleSidebarMenu = (open, list, simpleSidebarProps, onClose) =>
    open && list?.length ? (
      <SimpleSidebarMenu
        menuItems={this.getMenuItems(
          list,
          onClose,
          simpleSidebarProps.handleSubMenuTitleClick
        )}
        {...simpleSidebarProps}
      />
    ) : null;

  render() {
    const {
      onClose,
      open,
      handleClick,
      list,
      loading,
      openedSubItem,
      handleSubMenuTitleClick,
      goToListPage,
    } = this.props;

    const simpleSidebarProps = {
      handleSubMenuTitleClick,
      openedSubItemId: openedSubItem,
    };

    return (
      <div>
        <FormattedMessage {...messages.drafts}>
          {msg => (
            <SidebarMenuItem
              icon={MenuPencil}
              label={msg}
              expandable={!!list?.length}
              handleClick={goToListPage}
              open={open}
              togglerProps={{ handleOnTogglerClick: handleClick }}
            >
              {loading && open ? (
                <Loader small />
              ) : (
                this.renderSimpleSidebarMenu(
                  open,
                  list,
                  simpleSidebarProps,
                  onClose
                )
              )}
            </SidebarMenuItem>
          )}
        </FormattedMessage>
      </div>
    );
  }
}

DraftsCoursesList.propTypes = {
  open: PropTypes.bool,
  handleClick: PropTypes.func,
  list: PropTypes.array,
  loading: PropTypes.bool,
};

export default DraftsCoursesList;
