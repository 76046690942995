// Core
import React from 'react';
import PropTypes from 'prop-types';

// Components
import DropDown from 'components/DropDown';

const FlatDropDown = ({ children, component, ...rest }) => (
  <DropDown component={component} {...rest}>
    {children}
  </DropDown>
);

FlatDropDown.propTypes = {
  children: PropTypes.any,
};

export default FlatDropDown;
