import { List } from 'immutable';
import {
  EditorState,
  ContentState,
  ContentBlock,
  genKey,
  RichUtils,
  AtomicBlockUtils,
} from 'draft-js';
import externalVideoParses from 'js-video-url-parser';
import queryString from 'query-string';
import { VIDEO } from 'components/DraftEditor/enums.js';
import { isBlank } from 'utils/helpers/index';

const EXTERNAL_SERVICES = {
  youtube: {
    getEmbededLink: videoId => `https://www.youtube.com/embed/${videoId}`,
  },
  vimeo: {
    getEmbededLink: videoId => `https://player.vimeo.com/video/${videoId}`,
  }
}

export default ({ editorState, src }) => {
  let parsedSrc = null;

  const externalService = externalVideoParses.parse(src);

  if (!isBlank(externalService) && !isBlank(externalService?.provider)) {
    const { provider, id } = externalService;
    const externalServiceController = EXTERNAL_SERVICES[provider];
    parsedSrc = externalServiceController.getEmbededLink(id);
  };

  // if (RichUtils.getCurrentBlockType(editorState) === types.ATOMIC) {
  //   return editorState;
  // }

  const contentState = editorState.getCurrentContent();
  const contentStateWithEntity = contentState.createEntity(VIDEO, 'IMMUTABLE', {
    src: parsedSrc,
  });
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  return AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, ' ');

  //
  //
  //
  //
  // const newBlock = new ContentBlock({
  //   key: genKey(),
  //   type: 'unstyled',
  //   text: 'new embed',
  //   characterList: List()
  // })
  //
  // const contentState = editorState.getCurrentContent()
  // const newBlockMap = contentState.getBlockMap().set(newBlock.key, newBlock)
  // let newContentState = ContentState.createFromBlockArray(newBlockMap.toArray())
  //   // .set('selectionBefore', contentState.getSelectionBefore())
  //   // .set('selectionAfter', contentState.getSelectionAfter())
  // // let newEditorState =  EditorState.push(
  // //   editorState,
  // //   newContentStateWithNewBlock
  // // );
  // newContentState = newContentState.createEntity(
  //   "EMBED",
  //   "IMMUTABLE",
  //   { src }
  // );
  // const entityKey = newContentState.getLastCreatedEntityKey();
  //
  // const newEditorState = EditorState.set(
  //   editorState,
  //   { currentContent: newContentState }
  // //   "create-entity"
  // );
  //
  // // return RichUtils.toggleLink(
  // //   newEditorStateWithEntity,
  // //   newEditorStateWithEntity.getSelection(),
  // //   entityKey
  // // );
  //
  //
  // return newEditorState;

  // return newEditorState;
  //
  // const contentState = editorState.getCurrentContent()
  //
  // const contentStateWithEntity = contentState.createEntity(
  //   "EMBED",
  //   "IMMUTABLE",
  //   { src }
  // );
  // const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  // const newEditorStateWithEntity = EditorState.set(
  //   editorState,
  //   { currentContent: contentStateWithEntity },
  //   "create-entity"
  // );
  //
  // console.log('new embed');
  //
  // return RichUtils.toggleLink(
  //   newEditorStateWithEntity,
  //   newEditorStateWithEntity.getSelection(),
  //   entityKey
  // );
};
