import React from 'react';
import { SentryConsumer } from 'containers/SentryProvider/SentryConsumer';

export function withSentry(Component) {
  return function SentryComponent(props) {
    return (
      <SentryConsumer>
        {contexts => <Component {...props} {...contexts} />}
      </SentryConsumer>
    );
  };
}
