// Core
import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';

// Components
import Heading from 'components/Heading';
// import Button from 'components/Button';
import Text from 'components/Text';
import Cross from 'components/Icon/Svg/Cross';
import { messages } from './messages';

// Styles and Assets
import './index.scss';

export const FailedMessage = ({ message }) => (
  <div className="sk-tr-failed">
    <div className="sk-tr-failed_header">
      <div className="sk-tr-failed_icon">
        <Cross width="51" height="51" />
      </div>
      <Heading variant="h4" classes="sk-tr-failed_heading">
        <FormattedMessage {...messages.title} />
      </Heading>
      <Text classes="sk-tr-failed_subheading">
        {message.replace('GraphQL error:', '')}
      </Text>
    </div>
  </div>
);

export default memo(FailedMessage);
