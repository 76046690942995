import React from 'react';
import { withApollo } from 'react-apollo';
import Loader from 'components/Loader';

import { hasBranch } from 'containers/HOCs/hasBranch';

export const hasLoader = (
  WrappedComponent,
  query,
  fullScreenStatus = false
) => {
  const HasLoader = ({ variables, loading, client, ...rest }) => {
    // check if this graphql query has cached data in the apollo store
    const { complete } = client.cache.diff({
      query: client.cache.transformDocument(query),
      variables,
      returnPartialData: true,
      optimistic: false,
    });
    // Show loader only on initial query fetch
    return hasBranch(
      !complete && loading,
      () => <Loader fullScreen={fullScreenStatus} />,
      WrappedComponent
    )(rest);
  };

  return withApollo(HasLoader);
};
