// Core
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components
// import SocialBlock from './SocialBlock';
import FooterNavigation from './FooterNavigation';
import Logo from 'components/Icon/Svg/Logo';
import Image from 'components/Image';
import CurrencySelector from 'components/CurrencySelect/Selector';
// import LanguageSelector from 'components/LanguageSelect/Selector';

// Other
import { isTrue } from 'utils/helpers/';

// Styles and Assets
import './Footer.scss';

// S3 External Resource
// const FooterBg = 'https://skilleton-assets.s3.eu-central-1.amazonaws.com/landing/footer-bg.svg';

const locationsForFooterHill = ['/', '/en', '/en/'];
// const defaultFooterStyle = {
//   backgroundImage: `url(${FooterBg})`
// }

const Footer = ({ user, locale, location }) => {
  const date = new Date();
  const year = date.getFullYear();
  const isLandingPage = false;
  // locationsForFooterHill.includes(location.pathname);
  // const footerStyle = isTrue(isLandingPage) ? defaultFooterStyle : defaultFooterStyle;

  return(
    <footer className={classnames('sk-footer', { 'landing': isLandingPage })} id="footer">
      <div className="sk-footer_top grid landing space-between vcenter sk-footer-top-grid">
        <div className="sk-footer_top__left">
          <div className="sk-footer_top__left_logo">
            <Logo />
          </div>
          <div className="sk-footer_top__left_copy">
            <span className="sk-footer-caption-text">© {year} AVDO Education</span>
          </div>
        </div>
        <div className="sk-footer_top__right">
          {/* <div className="sk-footer_top_env-settings sk-footer_top__right_socials">
            <SocialBlock />
          </div> */}
          <div className="grid_item-md-3-24  sk-footer_top_env-settings sk-footer-top-grid__selectors">
            <CurrencySelector />
          </div>
        </div>
      </div>
      <div className="sk-footer_bottom">
        <FooterNavigation role={user && user.role} locale={locale} />
      </div>
    </footer>
  )
};

Footer.propTypes = {
  user: PropTypes.object,
};

export default Footer;
