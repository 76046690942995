// Core
import React, { memo, useCallback } from 'react';
import { compose } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useFormik } from 'formik';

// Components
import Loader from 'components/Loader';
import Text from 'components/Text';
import Constructor from 'routes/AdminPanel/PlanEdit/Constructor';
import Package from 'routes/AdminPanel/PlanEdit/Package';

// GraphQL
import GET_SUBSCRIPTION_PLAN from 'queries/AdminPanel/getSubscriptionPlan.gql';
import UPDATE_SUBSCRIPTION_PLAN from 'queries/AdminPanel/updateSubscriptionPlan.gql';

// Other
// import { mappingAlgorithm } from 'routes/AdminPanel/PlanEdit/helpers.js';
// import { MAPPING_INPUT, MAPPING_OUTPUT } from 'routes/AdminPanel/PlanEdit/utils.js';
// import { keysToSnake, keysToCamel, isBlank } from  'utils/helpers/index';
// import { getYupSchema, } from 'services/yup';
//
import { usePlanForm } from 'routes/AdminPanel/PlanEdit/components/usePlanForm.js';
import { keysToCamel, isBlank } from  'utils/helpers/index';

const PLANS = {
  package: Package,
  constructor: Constructor,
};

const VALIDATION_SCHEMAS = {
  package: 'packagePlanEditValidationSchema',
  constructor: 'constructorPlanEditValidationSchema',
};

const PlanPage = ({
  match: { params: { id: planId }},
  ...props
}) => {
  const [handlePlanUpdate, response] = useMutation(UPDATE_SUBSCRIPTION_PLAN, { options: { fetchPolicy: 'no-cache', refetchQueries: ['getSubscriptionPlan'], } });
  const { data, loading, error } = useQuery(GET_SUBSCRIPTION_PLAN, {
    variables: { id: planId },
  });

  const plan = keysToCamel(data?.getSubscriptionPlan) || {};
  const { formikBag, analytics } = usePlanForm({ plan });

  if (error) throw new Error(error);
  if (loading) return (<Loader center={true} />);

  // const Component = Package;
  const Component = PLANS[plan?.type];

  if (!isBlank(Component) && !loading) {
    return <Component formikBag={formikBag} analytics={analytics} />
  };
}

const enhancer = compose(
  memo,
  withRouter,
);

export default enhancer(PlanPage);
