import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  youHaveReqPas: {
    id: 'app.components.ConfModel.youHaveReqPas',
    defaultMessage: 'You need to change your password',
  },
  pleaseChooseNewPass: {
    id: 'app.components.ConfModel.pleaseChooseNewPass',
    defaultMessage: 'Please choose a new password',
  },
});
