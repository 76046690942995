// Core
import React, {useEffect, useState} from 'react';
import { compose } from 'react-apollo';
import { injectIntl, } from 'react-intl';
import withWidth from 'containers/HOCs/withWidth';

// Components
import Link from 'components/Link';
import Image from 'components/Image';
import Text from 'components/Text';
import Edit from 'components/Icon/SvgCss/Edit';
import Homeworks from 'components/Icon/SvgCss/Homeworks';
import Button from 'components/Button';
import { PurpleDark } from 'components/Icon/color';

// Other
import { getCourseThumbnail, isBlank } from 'utils/helpers';
import { sharedMessages } from 'services/i18n/sharedMessages/messages.js';
import { messages } from 'containers/Sidebar/SidebarContent/CourseSidebar/ActiveCoursesList/messages';
import { ACTIVE_PURCHASED_COURSE, DEACTIVATED_PURCHASED_COURSE } from 'utils/enums';

// Styles
import './index.scss';

const COMPONENT_PREFIX = 'sk-expert-course_card';

const statusesDetails = {
  'DRAFT': 'draft',
  'FIRST_REVIEW': 'firstReview',
  'MAIN_FILLING': 'mainFilling',
  'FINAL_REVIEW': 'finalReview',
  'READY_TO_PUBLISHING': 'readyToPublishing',
  'ACTIVE': 'active',
  'DEACTIVATED': 'inactive',
  'FIRST_REJECT': 'rejected',
  'SECOND_REJECT': 'rejected',
}

const CourseCard = ({
  isExpert,
  coursePageLink,
  course: { title, statusId, coursePhoto, thumbnail, variants },
  intl: { formatMessage },
  classes='',
  width
}) => {
  const status = statusesDetails[statusId];
  const [isMobile, setMobile] = useState(false);
  const hasHomeworks = variants?.some(variant => variant.features.includes('homeworks')) && (statusId === DEACTIVATED_PURCHASED_COURSE || statusId === ACTIVE_PURCHASED_COURSE);

  useEffect(()=>{
    if(width === 'sm' || width === 'xs'){
      setMobile(true);
    } else{
      setMobile(false);
    }
  }, [width, setMobile]);

  return(
    <div className={`${COMPONENT_PREFIX} ${classes}`}>
      <div className={`${COMPONENT_PREFIX}__picture`}>
        <Image src={getCourseThumbnail(thumbnail, coursePhoto)} />
      </div>
      <div  className={`${COMPONENT_PREFIX}__row`}>
        <Text className={`${COMPONENT_PREFIX}__title`}>
          {title}
        </Text>
        {isMobile ? (
          <div className={`${COMPONENT_PREFIX}__action`}>
            <Link to={`${coursePageLink}/edit`}>
              <Button color="secondary" outlined>{formatMessage(messages.edit)}</Button>
            </Link>
            {hasHomeworks &&
              (<Link to={`${coursePageLink}/homeworks`}>
                  <Button>{formatMessage(messages.homework)}</Button>
                </Link>)
            }
          </div>
        ) : (
          <div className={`${COMPONENT_PREFIX}__action`}>
            <Link to={`${coursePageLink}/edit`}>
              <Edit color={PurpleDark} />
              <span className="tooltip">{formatMessage(messages.edit)}</span>
            </Link>
            {hasHomeworks &&
              (<Link to={`${coursePageLink}/homeworks`}>
                <Homeworks color={PurpleDark} />
                <span className="tooltip">{formatMessage(messages.homework)}</span>
              </Link>)
            }
          </div>
        )}
      </div>
      {isExpert && (
        <div className={`${COMPONENT_PREFIX}__status`}>
          <Text className={`${COMPONENT_PREFIX}__status_label`}>{formatMessage(sharedMessages[status])}</Text>
        </div>
      )}
    </div>
  );
}

const enhancer = compose(
  injectIntl,
  withWidth()
);

export default enhancer(CourseCard);
