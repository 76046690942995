import React from 'react';
import PropTypes from 'prop-types';
import { Purple, PurpleHeart } from 'components/Icon/color';

const Apps = ({ height, width, active, activeColor, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 17 17"
    width={width}
    height={height}
  >
    <path
      d="M0 4.25h4.25V0H0v4.25zM6.375 
      17h4.25v-4.25h-4.25V17zM0 17h4.25v-4.25H0V17zm0-6.375h4.25v-4.25H0v4.25zm6.375 
      0h4.25v-4.25h-4.25v4.25zM12.75 0v4.25H17V0h-4.25zM6.375 4.25h4.25V0h-4.25v4.25zm6.375 
      6.375H17v-4.25h-4.25v4.25zm0 6.375H17v-4.25h-4.25V17z"
      fill={active ? activeColor : color}
    />
  </svg>
);

Apps.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  active: PropTypes.bool,
  activeColor: PropTypes.string,
  color: PropTypes.string,
};

Apps.defaultProps = {
  height: '17',
  width: '17',
  color: Purple,
  activeColor: PurpleHeart,
};

export default Apps;
