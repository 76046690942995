// Core
import React, { useEffect, useState, memo } from 'react';
import { withRouter } from 'react-router';
import { compose } from 'react-apollo';
import queryString from 'query-string';
import PropTypes from 'prop-types';

// Components
import ActionStatusCard from 'containers/ActionStatusCard/ActionStatusCard';

// Other
import { confirmEmail } from 'services/aws/amplify/helpers';
import { parseIncomingError } from 'utils/errorHandling';
import { injectIntl } from 'react-intl';
import { messages } from 'routes/EmailVerification/messages';

const SUCCESS = 'success';

const handleEmailVerification = async (
  { userEmail, verificationCode },
  handleStatusChange
) => {
  try {
    await confirmEmail(userEmail, verificationCode);
    handleStatusChange(SUCCESS);
  } catch (error) {
    const errorText = parseIncomingError(error, 'status');
    handleStatusChange('error', errorText?.status);
  }
};

export const EmailVerification = ({ location, history, intl }) => {
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState(null);
  const [errorText, setErrorText] = useState('error');

  const successText = intl.formatMessage(messages.yourEmailVerified);

  const handleStatusChange = (currentStatus, currentErrorText) => {
    setLoading(false);
    setStatus(currentStatus);
    if (errorText) setErrorText(currentErrorText);
  };

  useEffect(() => {
    const { email: userEmail, code: verificationCode } = queryString.parse(
      location.search
    );

    if (!userEmail || !verificationCode) history.push('/auth');

    if (userEmail && verificationCode && loading) {
      handleEmailVerification(
        { userEmail, verificationCode },
        handleStatusChange
      );
    }
  }, []);

  const { role } = queryString.parse(location.search);
  return (
    <ActionStatusCard
      loading={loading}
      status={status}
      linkToProceed={`/auth/signin?role=${role}`}
      statusText={status === SUCCESS ? successText : errorText}
      statusHeading={
        status === SUCCESS
          ? intl.formatMessage(messages.success)
          : intl.formatMessage(messages.error)
      }
      proceedText={intl.formatMessage(messages.goToLoginPage)}
    />
  );
};

EmailVerification.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  intl: PropTypes.object,
};

const enhancer = compose(
  memo,
  withRouter,
  injectIntl
);

export default enhancer(EmailVerification);
