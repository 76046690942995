import React, { useState, useEffect, useRef } from "react";
import classnames from "classnames";

// Components
import FullScreen from "components/Icon/SvgCss/Editor/FullScreen";

// Styles
import "components/DraftEditor/ControlsBar/ControlsBar.scss";

export const StyleIconButton = ({
  onToggleHandler,
  style,
  label,
  active,
  icon: Icon,
}) => {
  const handleOnToggle = (e) => {
    e.preventDefault();
    onToggleHandler(style);
  };

  return (
    <span onMouseDown={handleOnToggle}>
      <Icon active={active} />
    </span>
  );
};

export const StyleButton = ({
  onToggleHandler,
  style,
  label,
  active,
  icon,
}) => {
  const handleOnToggle = (e) => {
    e.preventDefault();
    onToggleHandler(style);
  };

  return (
    <span
      className={classnames("sk-editor-control-button", {
        "sk-editor-control-button__active": active,
      })}
      onMouseDown={handleOnToggle}
    >
      {label}
    </span>
  );
};

export const RecourseIconButton = ({
  onClickHandler,
  noBlurOnClick = false,
  htmlFor,
  type,
  active,
  icon: Icon,
  tag = "span",
  children,
}) => {
  const handleOnClick = (event) => {
    event.preventDefault();
    if (onClickHandler) {
      onClickHandler({ type, event });
    }
  };

  const preventBlurOnClick = (event) => {
    event.preventDefault();
  };

  const Tag = tag;
  const attr = {
    className: "sk-editor-control-button",
    style: { cursor: 'pointer' },
  };

  if (noBlurOnClick) {
    attr.onMouseDown = preventBlurOnClick;
  }

  if (onClickHandler) {
    attr.onClick = handleOnClick;
  }

  if (htmlFor) {
    attr.htmlFor = htmlFor;
  }

  return (
    <Tag {...attr}>
      <Icon active={active} style={{ cursor: "pointer" }} />
      {children}
    </Tag>
  );
};

export const ResourceButton = ({
  onClickHandler,
  htmlFor,
  typeprop,
  type,
  children,
  active,
  tag = "span",
}) => {
  const handleOnClick = (event) => {
    event.preventDefault();
    if (onClickHandler) {
      onClickHandler({ type, event });
    }
  };

  const Tag = tag;
  const attr = {
    className: classnames("sk-editor-control-button", {
      "sk-editor-control-button__active": active,
    }),
  };

  if (onClickHandler) {
    attr.onClick = handleOnClick;
  }

  if (htmlFor) {
    attr.htmlFor = htmlFor;
  }

  return <Tag {...attr}>{children}</Tag>;
};

export const EditorViewControls = ({ switchView, isFullscreen }) => (
  <div className="sk-editor-view-switcher" onClick={switchView}>
    <FullScreen isFullscreen={isFullscreen} />
  </div>
);
