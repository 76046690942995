// Core
import React, { memo } from 'react';
import { injectIntl } from 'react-intl';
import { compose } from 'react-apollo';

// Components
import Text from 'components/Text';
import Card from 'components/Card';
import { messages } from 'routes/Checkout/messages';

// Styles and Assets
import './index.scss';

const PREFIX = 'sk-checkout-empty-list';
export const EmptyList = ({ intl: { formatMessage } }) => (
  <Card classes={PREFIX}>
    <Text classes={`${PREFIX}__content`}>{formatMessage(messages.orderBasketEmpty)}</Text>
  </Card>
);

const enhancer = compose(
  memo,
  injectIntl
)

export default enhancer(EmptyList);
