import React from 'react';

import './index.scss';

const BlockquoteOnHover = () => (
  <svg
    className="hover"
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.691406" width="32" height="32" rx="2" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0447 9.39899H17.4207C18.4959 9.39899 19.5711 9.83786 20.3391 10.5693C21.1071 11.3007 21.5679 12.3247 21.5679 13.3487C21.5679 14.3727 21.1071 15.3967 20.4927 15.9819L20.6463 16.1282C21.5679 17.0059 22.1823 18.1762 22.1823 19.4927C22.1823 20.8093 21.5679 21.9796 20.6463 22.8573C19.7247 23.735 18.4959 24.3201 17.1135 24.3201H12.0447H10.6623V23.0036V17.1521V15.8356V14.519V10.2767V8.96014H12.0447V9.39899ZM17.2671 11.8859H13.2735V14.8116H16.9599H17.2671C17.7279 14.8116 18.0351 14.6653 18.3423 14.3727C18.6495 14.0801 18.8031 13.7876 18.8031 13.3487C18.8031 12.9099 18.6495 12.6173 18.3423 12.3247C18.0351 12.0321 17.7279 11.8859 17.2671 11.8859ZM16.9599 17.2984H13.2735V21.8333H16.9599C17.5743 21.8333 18.1887 21.5407 18.6495 21.1019C19.1103 20.663 19.4175 20.0779 19.4175 19.4927C19.4175 18.9076 19.1103 18.3224 18.6495 17.8836C18.1887 17.591 17.5743 17.2984 16.9599 17.2984Z"
      fill="#7145D8"
    />
  </svg>
);

const DefaultBlockquote = () => (
  <svg
    className="default"
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0447 9.399H17.4207C18.4959 9.399 19.5711 9.83786 20.3391 10.5693C21.1071 11.3007 21.5679 12.3247 21.5679 13.3487C21.5679 14.3727 21.1071 15.3967 20.4927 15.9819L20.6463 16.1282C21.5679 17.0059 22.1823 18.1762 22.1823 19.4927C22.1823 20.8093 21.5679 21.9796 20.6463 22.8573C19.7247 23.735 18.4959 24.3202 17.1135 24.3202H12.0447H10.6623V23.0036V17.1522V15.8356V14.519V10.2767V8.96015H12.0447V9.399ZM17.2671 11.8859H13.2735V14.8116H16.9599H17.2671C17.7279 14.8116 18.0351 14.6653 18.3423 14.3727C18.6495 14.0802 18.8031 13.7876 18.8031 13.3487C18.8031 12.9099 18.6495 12.6173 18.3423 12.3247C18.0351 12.0321 17.7279 11.8859 17.2671 11.8859ZM16.9599 17.2985H13.2735V21.8333H16.9599C17.5743 21.8333 18.1887 21.5407 18.6495 21.1019C19.1103 20.663 19.4175 20.0779 19.4175 19.4927C19.4175 18.9076 19.1103 18.3224 18.6495 17.8836C18.1887 17.591 17.5743 17.2985 16.9599 17.2985Z"
      fill="#6D6E71"
    />
  </svg>
);

const ActiveBlockquote = () => (
  <svg
    className="active"
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0447 9.39899H17.4207C18.4959 9.39899 19.5711 9.83786 20.3391 10.5693C21.1071 11.3007 21.5679 12.3247 21.5679 13.3487C21.5679 14.3727 21.1071 15.3967 20.4927 15.9819L20.6463 16.1282C21.5679 17.0059 22.1823 18.1762 22.1823 19.4927C22.1823 20.8093 21.5679 21.9796 20.6463 22.8573C19.7247 23.735 18.4959 24.3201 17.1135 24.3201H12.0447H10.6623V23.0036V17.1521V15.8356V14.519V10.2767V8.96014H12.0447V9.39899ZM17.2671 11.8859H13.2735V14.8116H16.9599H17.2671C17.7279 14.8116 18.0351 14.6653 18.3423 14.3727C18.6495 14.0801 18.8031 13.7876 18.8031 13.3487C18.8031 12.9099 18.6495 12.6173 18.3423 12.3247C18.0351 12.0321 17.7279 11.8859 17.2671 11.8859ZM16.9599 17.2984H13.2735V21.8333H16.9599C17.5743 21.8333 18.1887 21.5407 18.6495 21.1019C19.1103 20.663 19.4175 20.0779 19.4175 19.4927C19.4175 18.9076 19.1103 18.3224 18.6495 17.8836C18.1887 17.591 17.5743 17.2984 16.9599 17.2984Z"
      fill="#7145D8"
    />
  </svg>
);

const Blockquote = ({ active }) => {
  const Icon = active ? ActiveBlockquote : DefaultBlockquote;

  return (
    <div className="editor-icon-svg">
      <Icon />
      <BlockquoteOnHover />
    </div>
  );
};

export default Blockquote;
