// Core
import React, { PureComponent } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { compose, graphql } from 'react-apollo';

// Components
import MaterialEntity from 'routes/Course/components/LectureMaterials/StudyMaterialsList/MaterialEntity';
import MaterialExtras from 'routes/Course/components/LectureMaterials/StudyMaterialsList/MaterialExtras';

// GraphQL
import REORDER_ATTACHMENT from 'services/graphql/mutations/Courses/reorderAttachment.gql';
import GET_COURSE_LECTURES from 'queries/Courses/getCourseLectures.gql';

// Other
import { compareSortNumber } from 'utils/helpers/index';

// Styles
import './index.scss';

class StudyMaterialsList extends PureComponent {
  getItemStyle = (isDragging, draggableStyle) => ({
    // styles we need to apply on draggables
    ...draggableStyle,
  });

  getListStyle = isDraggingOver => ({
    background: isDraggingOver ? 'lightblue' : 'lightgrey',
  });
  render() {
    const {
      materials,
      openFileModal,
      handleAttachmentDelete,
      disableDeletion,
      droppableId,
    } = this.props;
    return (
      <div className="sk-lec-study-materials">
        {materials.sort(compareSortNumber).map((entity, index) => (
          <Draggable
            type='material'
            key={entity.id}
            draggableId={`material-${entity.id}`}
            index={index}
          >
            {(provided, snapshot) => (
              <div
                className="sk-lec-study-materials__item"
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                {((entity.status === 'UPLOADED') && <div className="sk-lec-study-materials__item__mobile">
                    <MaterialExtras
                      entity={entity}
                    />
                  </div>
                )}
                <MaterialEntity
                  withDuration
                  withDragableButton
                  disableDeletion={disableDeletion}
                  entity={entity}
                  openFileModal={openFileModal}
                  handleAttachmentDelete={handleAttachmentDelete}
                />
                {((entity.status === 'UPLOADED') && <div className="sk-lec-study-materials__item__desktop">
                    <MaterialExtras
                      entity={entity}
                    />
                  </div>
                )}
              </div>
            )}
          </Draggable>
        ))}
      </div>
    );
  }
}

export default StudyMaterialsList;
