import React from 'react';
import PropTypes from 'prop-types';
import { SidebarDefault, PurpleHeart, Red } from 'components/Icon/color';

const Basket2 = ({
  height,
  width,
  color,
  strokeColor,
  active,
  activeColor,
}) => (
  /* eslint-disable max-len */
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 17"
    width={width}
    height={height}
  >
    <defs>
      <path id="a" d="M0 .149h15.07v13.41H0z" />
      <path id="c" d="M.18.182h3.038v3.02H.179z" />
      <path id="e" d="M.068.182h3.039v3.02H.068z" />
    </defs>
    <g fillRule="evenodd" stroke={active ? activeColor : strokeColor}>
      <g transform="translate(0 .04)">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <path
          fill={color}
          d="M14.623 13.56H7.058c-.92 0-1.81-.696-2.027-1.585L2.936 3.378c-.13-.532-.669-1.226-1.154-1.486L.235 1.064A.444.444 0 0 1 .054.46L.096.383A.449.449 0 0 1 .702.202l1.547.828c.727.39 1.45 1.32 1.644 2.117l2.095 8.597c.109.446.61.837 1.07.837h7.565c.247 0 .447.2.447.445v.089c0 .246-.2.445-.447.445"
          mask="url(#b)"
        />
      </g>
      <path
        fill={color}
        d="M14.354 11.551H5.367v-.979h8.987c.48 0 1.037-.396 1.192-.848l1.373-4c.055-.16.05-.299-.016-.39-.066-.092-.196-.142-.366-.142H3.817v-.979h12.72c.49 0 .914.2 1.166.551.252.35.306.815.148 1.276l-1.373 4c-.29.848-1.224 1.511-2.124 1.511"
      />
      <g transform="translate(5.684 13.602)">
        <mask id="d" fill="#fff">
          <use xlinkHref="#c" />
        </mask>
        <path
          fill={color}
          d="M1.699 1.16a.533.533 0 0 0-.535.532.533.533 0 0 0 1.069 0 .533.533 0 0 0-.534-.531m0 2.041c-.838 0-1.52-.678-1.52-1.51C.18.859.861.182 1.7.182c.837 0 1.519.677 1.519 1.51 0 .832-.682 1.51-1.52 1.51"
          mask="url(#d)"
        />
      </g>
      <g transform="translate(12.126 13.602)">
        <mask id="f" fill="#fff">
          <use xlinkHref="#e" />
        </mask>
        <path
          fill={color}
          d="M1.588 1.16a.533.533 0 0 0-.535.532.533.533 0 0 0 1.069 0 .533.533 0 0 0-.534-.531m0 2.041c-.838 0-1.52-.678-1.52-1.51 0-.833.682-1.51 1.52-1.51.837 0 1.519.677 1.519 1.51 0 .832-.682 1.51-1.52 1.51"
          mask="url(#f)"
        />
      </g>
    </g>
  </svg>
  /* eslint-enable max-len */
);

Basket2.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  strokeColor: PropTypes.string,
  active: PropTypes.bool,
  activeColor: PropTypes.string,
  color: PropTypes.string,
};

Basket2.defaultProps = {
  height: '18',
  width: '17',
  strokeColor: SidebarDefault,
  activeColor: PurpleHeart,
  color: Red,
};

export default Basket2;
