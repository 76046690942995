// Core
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

// Components
import Visibility from 'components/Icon/Svg/Visibility';
import VisibilityOff from 'components/Icon/Svg/VisibilityOff';

// Styles
import 'utils/styles/Materialized.scss';

const classes = {
  label: {
    focused:
      'mui-override sk-materialized-input_label sk-materialized-input_focused',
    error: 'mui-override sk-materialized-input_error withError',
  },
  input: {
    focused: 'mui-override sk-materialized-input_focused',
    error: 'mui-override sk-materialized-input_error withError',
    underline: 'mui-override sk-materialized-input_hover',
  },
  helperText: {
    root: 'mui-override  sk-materialized-input_helperErrorText',
    focused:
      'mui-override sk-materialized-input_label sk-materialized-input_focused',
    error:
      'mui-override sk-materialized-input_error withError sk-materialized-input_helperErrorText',
  },
};

const ICON_SIZES = {
  width: '14',
  height: '21',
};

class PasswordInput extends PureComponent {
  state = {
    showPassword: false,
  };

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  render() {
    const { showPassword } = this.state;
    const {
      value,
      label,
      helperText,
      error,
      onChange,
      fullWidth,
      onBlur,
      ...rest
    } = this.props;

    return (
      <FormControl margin="dense" fullWidth={fullWidth}>
        <InputLabel margin="dense" classes={classes.label} error={error}>
          {label}
        </InputLabel>
        <Input
          type={showPassword ? 'text' : 'password'}
          defaultValue={value}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="Toggle password visibility"
                onClick={this.handleClickShowPassword}
              >
                {showPassword ? (
                  <Visibility {...ICON_SIZES} />
                ) : (
                  <VisibilityOff {...ICON_SIZES} />
                )}
              </IconButton>
            </InputAdornment>
          }
          classes={classes.input}
          error={error}
          onChange={e => onChange(e.target.value, e)}
          onBlur={e => (onBlur ? onBlur(e.target.value, e) : null)}
          fullWidth={fullWidth}
          {...rest}
        />
        <FormHelperText classes={classes.helperText} error={error}>
          {helperText}
        </FormHelperText>
      </FormControl>
    );
  }
}

PasswordInput.defaultProps = {
  label: 'Password',
  fullWidth: true,
};

PasswordInput.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  handleChange: PropTypes.func,
  fullWidth: PropTypes.bool,
};

export default PasswordInput;
