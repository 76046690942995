import React from 'react';

const Mountain = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="63"
    height="62"
    viewBox="0 0 63 62"
  >
    <defs>
      <path id="a" d="M4 1.079h58.923v45.279H4z" />
      <path id="c" d="M.01.08h1.65v18.463H.01z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(0 15.342)">
        <circle cx="18" cy="18.658" r="18" fill="#FFCBC2" fillRule="nonzero" />
        <path
          fill="#444"
          d="M6.308 44.707h54.33L35.868 3.525 23.88 24.133a.826.826 
          0 0 1-1.036.345l-3.163-1.343L6.308 44.707zm55.79 1.65H4.825a.825.825 
          0 0 1-.701-1.26l14.528-23.434a.825.825 0 0 1 1.024-.324l3.144 1.335L35.142 
          1.489a.826.826 0 0 1 .708-.41h.006c.29 0 .558.151.707.4l26.242 43.628a.825.825 
          0 0 1-.707 1.25z"
        />
      </g>
      <path
        fill="#272735"
        d="M41.698 35.677a.826.826 0 0 1-.611-.27l-2.233-2.46-2.233 2.46a.826.826 
        0 0 1-1.223 0l-2.233-2.46-2.233 2.46a.826.826 0 0 1-1.222 0l-2.845-3.133a.826.826 
        0 0 1 1.223-1.11l2.233 2.46 2.233-2.46a.826.826 0 0 1 1.222 0l2.233 2.46 2.234-2.46a.826.826
         0 0 1 1.222 0l2.233 2.46 2.233-2.46a.826.826 0 0 1 1.223 1.11l-2.845 3.132a.826.826 0 0 
         1-.61.27M20.67 44.248a.825.825 0 0 1-.716-1.236l2.497-4.362a.825.825 0 1 1 1.433.82l-2.496
          4.363a.825.825 0 0 1-.718.415"
      />
      <g transform="translate(35.02 .006)">
        <path
          fill="#272735"
          d="M.835 18.543a.825.825 0 0 1-.825-.825V.906a.826.826 0 0 1 1.65 0v16.812c0 
          .456-.369.825-.825.825"
        />
      </g>
      <path
        fill="#444"
        d="M23.887 6.653H35.03V1.738H23.887l1.845 1.879a.825.825 0 0 1 0 1.157l-1.845 
        1.879zm11.969 1.65H21.919A.826.826 0 0 1 21.33 6.9l2.656-2.705-2.656-2.704a.826.826 
        0 0 1 .59-1.404h13.936c.456 0 .825.37.825.825v6.566c0 .456-.37.826-.825.826zM51.27
         54.935a.825.825 0 0 1-.703-.39l-3.93-6.321a.826.826 0 0 1 1.402-.872l3.93 6.321a.826.826 
         0 0 1-.7 1.262M15.196 53.693a.826.826 0 0 1-.7-1.262l2.842-4.57a.825.825 0 0 1 1.402.871l-2.842 
         4.571a.825.825 0 0 1-.702.39"
      />
    </g>
  </svg>
);

export default Mountain;
