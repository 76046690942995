// Core
import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { compose, graphql } from 'react-apollo';

// GraphQL
import REORDER_ATTACHMENT from 'services/graphql/mutations/Courses/reorderAttachment.gql';
import REORDER_LECTURES from 'services/graphql/mutations/Courses/reorderLecture.gql';
import GET_COURSE_LECTURES from 'queries/Courses/getCourseLectures.gql';

// Components
import LectureItem from 'routes/Course/components/LecturesList/LectureItem';

// Other
import { sortLectures, keysToCamel, isNull } from 'utils/helpers';

const getItemStyle = (isDragging, draggableStyle) => ({
  // styles we need to apply on draggables
  ...draggableStyle,
});

const LecturesList = ({
  lectures,
  reorderAttachment,
  reorderLecture,
  courseFeatures,
  ...rest
}) => {
  const onDragEnd = async (params, callback) => {
    const { destination, source, draggableId, reason } = params;
    const isCanceled = reason === 'CANCEL';
    if (isCanceled || destination === null) return;

    const dashIndex = draggableId.indexOf('-');
    const dragType = draggableId.slice(0, dashIndex);
    const entityId = draggableId.slice(dashIndex + 1);

    let handleMutation = null;
    let mutationParams = null;

    if (dragType === 'lecture') {
      mutationParams = {
        courseId: rest?.courseId,
        lectureId: entityId,
        orderNumber: destination?.index + 1,
      };
      handleMutation = reorderLecture;
    } else if (dragType === 'material') {
      mutationParams = {
        materialId: entityId,
        courseId: rest?.courseId,
        destinationLectureId: destination?.droppableId,
        sourceLectureId: source?.droppableId,
        sortNumber: destination?.index + 1,
      };
      handleMutation = reorderAttachment;
    }

    if (isNull(handleMutation) || isNull(mutationParams)) {
      console.error('Mutation or input are not defined!');
      return;
    }

    await handleMutation(mutationParams);
  };
  const camelCaseLectures = keysToCamel(lectures);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable-lecture" type='lecture'>
        {(provided, snapshot) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {sortLectures(camelCaseLectures).map((lecture, index) => (
              <Draggable
                type='lecture'
                key={lecture.id}
                draggableId={`lecture-${lecture.id}`}
                index={index}
                style={{ outline: 'none' }}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <LectureItem
                      droppableId={lecture.id}
                      orderNumber={index}
                      key={lecture.id}
                      lecture={lecture}
                      courseFeatures={courseFeatures}
                      {...rest}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const reorderAttachment = graphql(REORDER_ATTACHMENT, {
  props: ({ mutate }) => ({
    reorderAttachment: input =>
      mutate({
        variables: {
          input: {
            id: input.materialId,
            sort_number: input.sortNumber,
            ref_id: input.destinationLectureId,
          },
        },
        optimisticResponse: {},
        update: (cache, { data: { reorderAttachment } }) => {
          try {
            const data = cache.readQuery({
              query: GET_COURSE_LECTURES,
              variables: { input: { id: input.courseId } },
            });
            const dataCopy = JSON.parse(JSON.stringify(data));

            const moveInAnotherLecture = input.destinationLectureId !== input.sourceLectureId;
            const course = dataCopy.getCourse;

            const destinationLecture = course.lectures.find( lecture => lecture.id === input.destinationLectureId);
            const sourceLecture = course.lectures.find(lecture => lecture.id === input.sourceLectureId);
            const reorderedMaterial = sourceLecture?.materials.find(material => material.id === input.materialId);

            if (moveInAnotherLecture) {
              reorderedMaterial.lecture_id = input.destinationLectureId;
              sourceLecture.materials = sourceLecture?.materials.filter(material => material?.id !== reorderedMaterial?.id);
              destinationLecture.materials.push(reorderedMaterial);
            }

            const initSortNumber = reorderedMaterial.sort_number;
            const nextSortNumber = input.sortNumber;

            if (nextSortNumber < initSortNumber) {
              destinationLecture.materials.forEach(material => {
                const materialSortNumber = material.sort_number;
                if (
                  initSortNumber > materialSortNumber &&
                  materialSortNumber >= nextSortNumber
                ) {
                  material.sort_number = materialSortNumber + 1;
                }
              });
            } else if (nextSortNumber > initSortNumber) {
              destinationLecture.materials.forEach(material => {
                const materialSortNumber = material.sort_number;
                if (
                  initSortNumber < materialSortNumber &&
                  materialSortNumber <= nextSortNumber
                )
                  material.sort_number = materialSortNumber - 1;
              });
            }

            // debugger;

            reorderedMaterial.sort_number = nextSortNumber;
            const response = cache.writeQuery({
              query: GET_COURSE_LECTURES,
              data: dataCopy,
            });
            // debugger;
          } catch (e) {
            console.log(e);
          }
        },
      }),
  }),
  options: {
    awaitRefetchQueries: true,
    refetchQueries: ['getCourseLectures'],
  },
});

const reorderLecture = graphql(REORDER_LECTURES, {
  props: ({ mutate }) => ({
    reorderLecture: input =>
      mutate({
        variables: {
          input: {
            courseId: input.courseId,
            lectureId: input.lectureId,
            orderNumber: input.orderNumber,
          },
        },
        optimisticResponse: {},
        update: (cache, { data: { reorderAttachment } }) => {
          try {
            const data = cache.readQuery({
              query: GET_COURSE_LECTURES,
              variables: { input: { id: input.courseId } },
            });
            const dataCopy = JSON.parse(JSON.stringify(data));

            const course = dataCopy.getCourse;
            const lectures = course.lectures;
            const reorderedLecture = lectures.find(
              lecture => lecture.id === input.lectureId
            );

            const initSortNumber = reorderedLecture.order_number;
            const nextSortNumber = input.orderNumber;

            if (nextSortNumber < initSortNumber) {
              lectures.forEach(lecture => {
                const lectureOrderNumber = lecture.order_number;
                if (
                  initSortNumber > lectureOrderNumber &&
                  lectureOrderNumber >= nextSortNumber
                ) {
                  lecture.order_number = lectureOrderNumber + 1;
                }
              });
            } else if (nextSortNumber > initSortNumber) {
              lectures.forEach(lecture => {
                const lectureOrderNumber = lecture.order_number;
                if (
                  initSortNumber < lectureOrderNumber &&
                  lectureOrderNumber <= nextSortNumber
                )
                  lecture.order_number = lectureOrderNumber - 1;
              });
            }

            reorderedLecture.order_number = nextSortNumber;
            const response = cache.writeQuery({
              query: GET_COURSE_LECTURES,
              data: dataCopy,
            });
          } catch (e) {
            console.log(e);
          }
        },
      }),
  }),
  options: {
    refetchQueries: ['getCourseLectures'],
  },
});

const enhancer = compose(reorderAttachment, reorderLecture);

export default enhancer(LecturesList);
