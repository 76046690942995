// Core
import React, {
  memo,
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import ReactSwipe from 'react-swipe';
import withWidth from 'containers/HOCs/withWidth';
import { compose } from 'react-apollo';

// Components
import CourseCard from 'components/CourseCard';

// Styles
import './CoursesCarousel.scss';
import { NetflixSwipeButton } from '../NetflixSwipeButton';
import SliderDots from '../../../../components/NetflixCarousel/modules/SliderDots';
import { isEmptyArray } from '../../../../utils/helpers';

const coursesCountAtCarouselPageDependOnLandingScreen = {
  xlg: 4,
  lg: 3,
  md: 2,
  sm: 1,
  xs: 1,
};

const getEmptyPanes = length => {
  if (!length) {
    return [];
  }
  return Array.from(Array(length), (v, i) => ({ value: 0, id: i }));
};

const renderSwipe = (
  arr,
  width,
  courseCountAtCarouselDependOnLocation = coursesCountAtCarouselPageDependOnLandingScreen
) => {
  if (['xs', 'sm'].includes(width)) {
    return arr.map(item => (
      <div
        key={`course-carousel-swipe-row-xs${item.id}`}
        className="sk-course-carousel__swipe-row"
      >
        <div className="sk-swipe_include">
          {renderCourseCard(item, {
            max: 1,
            index: 0,
            courseIndex: 0,
          })}
        </div>
      </div>
    ));
  }

  const count = courseCountAtCarouselDependOnLocation[width] || 1;
  const realTotalItems = arr.length;
  const totalItems = realTotalItems <= count ? count + 1 : realTotalItems;
  const totalPanes = Math.ceil(totalItems / count);
  const emptyPanes = getEmptyPanes(totalPanes);

  const panes = emptyPanes.map((item, index) => (
    <div
      key={`course-carousel-swipe-row${item.id || index}`}
      className="sk-course-carousel__swipe-row  grid landing space-between"
    >
      {arr.map((course, courseIndex) =>
        renderCourseCard(course, {
          index,
          courseIndex,
          max: count,
        })
      )}
    </div>
  ));

  return panes;
};

const renderCourseCard = (
  course,
  { index, courseIndex, max }
) => {
  const isAllowed =
    (index + 1) * max >= courseIndex + 1 && index * max < courseIndex + 1;

  if (!isAllowed && max !== 1) {
    return null;
  }
  return (
    <div
      key={`course-carousel-${course.id}-${max || 0}`}
      className="sk-course-carousel__swipe-item"
    >
      <CourseCard
        course={course}
      />
    </div>
  );
};

const CoursesCarousel = ({
  courses,
  width,
  courseCountAtCarouselDependOnLocation = coursesCountAtCarouselPageDependOnLandingScreen,
}) => {
  const reactSwipeEl = useRef(null);
  const [slide, setPosition] = useState(0)
  useEffect(() => {
    if (reactSwipeEl?.current) {
      reactSwipeEl.current.swipe.setup();
    }
  }, [width]);

  const navClick = useCallback(ind => {
    reactSwipeEl.current.slide(ind);
  }, [reactSwipeEl])

  const swipeOptions = useMemo(() => ({
    transitionEnd() {
      setPosition(reactSwipeEl.current.getPos())
    }
  }), [reactSwipeEl]);

  const showSwipeButtons =
    courses.length > courseCountAtCarouselDependOnLocation[width];

  return (
    <div className="sk-course-carousel">
      {!isEmptyArray(courses) && courses.length > 1 && <SliderDots
        items={courses}
        slideTo={(i)=>navClick(i)}
        currentSlide={slide}
      />}
      {!isEmptyArray(courses) && courses.length === 1 ? (
        <div className="sk-course-carousel__monocard">
          <CourseCard
            course={courses[0]}
          />
        </div>
      ) : (
        <ReactSwipe
          childCount={courses.length}
          className="sk-course-carousel__swipe"
          ref={reactSwipeEl}
          key={courses.length}
          swipeOptions={swipeOptions}

        >
          {renderSwipe(
            courses,
            width,
            courseCountAtCarouselDependOnLocation
          )}
        </ReactSwipe>
      )}

      {showSwipeButtons ? (
        <>
          <div className="sk-course-carousel__nav-btn sk-course-carousel__nav-btn_prev">
            <NetflixSwipeButton
              size="small"
              color="primary"
              direction="prev"
              handleClick={() => reactSwipeEl.current.prev()}
            />
          </div>
          <div className="sk-course-carousel__nav-btn sk-course-carousel__nav-btn_next">
            <NetflixSwipeButton
              size="small"
              color="primary"
              direction="next"
              handleClick={() => reactSwipeEl.current.next()}
            />
          </div>
        </>
      ) : null}
    </div>
  );
};

const enhancer = compose(memo, withWidth());

export default enhancer(CoursesCarousel);
