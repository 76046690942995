// Core
import React from 'react';
import PropTypes from 'prop-types';

// Components
import Card from 'components/Card';

// Other
import { cleanUpStyleString } from 'utils/helpers';

// Styles
import './SuggestionPopper.scss';

const getPopperClasses = error =>
  cleanUpStyleString(
    `mui-override sk-suggestions sk-suggestions_popper ${error &&
      'sk-suggestions_popper__error'}`
  );

const SuggestionPopper = ({ children, error, ...rest }) => (
  <div className={getPopperClasses(error)}>
    <Card square {...rest}>
      {children}
    </Card>
  </div>
);

SuggestionPopper.propTypes = {
  children: PropTypes.element,
  error: PropTypes.bool,
};

export default SuggestionPopper;
