// Core
import React from "react";
import { FormattedMessage } from "react-intl";
import { compose } from "react-apollo";
import debounce from "lodash.debounce";

// Components
import Checkbox from "components/Checkbox";
import ToolTip from "components/ToolTip";

// Icons
import GiftIcon from "components/Icon/Svg/Gift";

// Other
import { messages } from "./messages";

// Services
import { withFileModal } from "routes/Course/components/LecturesList/LectureItem/HOCs/withFileModal";

// Styles and Assets
import "./MaterialExtras.scss";

export const MaterialExtras = (props) => {
  const {
    entity: { id: attachmentId, isPublic: defaultIsPublic, status },
    updateAttachment,
  } = props;

  const [isPublic, setIsPublic] = React.useState(defaultIsPublic);

  const handlePublicStateChange = debounce(async ({ id, isPublic }) => {
    setIsPublic(isPublic);

    await updateAttachment({
      id,
      is_public: isPublic,
    });
  }, 150);

  return (
    <div class="sk-lec-study-materials__public-state">
      <FormattedMessage {...messages.publicMaterialToolTip}>
        {(msg) => (
          <Checkbox
            name="isPublic"
            title={msg}
            checked={isPublic === true}
            disabled={status === "ERROR"}
            handleChange={(value) =>
              handlePublicStateChange({ id: attachmentId, isPublic: value })
            }
          />
        )}
      </FormattedMessage>
      <GiftIcon />

      <div className="extras-only-sm">
        <span className="sk-lec-study-materials__public-state__helper-title">
          <FormattedMessage {...messages.publicMaterial} />
        </span>
        <ToolTip>
          <p>
            <FormattedMessage {...messages.publicMaterialToolTip} />
          </p>
        </ToolTip>
      </div>
    </div>
  );
};

const enhancer = compose(withFileModal);

export default enhancer(MaterialExtras);
