// Core
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

// Components
import Text from 'components/Text';

// Other
import { messages } from 'routes/ExpertsCabinet/CourseEdit/GeneralInfo/messages';
import { isBlank } from 'utils/helpers';

const Inactive = ({ type }) => {
  const courseType = !isBlank(type) ? type.type : type;
  return(
    <span>
      {(isBlank(courseType) && type!='topping') ?
        (
        <span>
          <Text><FormattedMessage {...messages.deactivatedText1} /></Text>
          <Text>
            <FormattedMessage {...messages.deactivatedText2} />
            <u><b><FormattedMessage {...messages.deactivatedText3} /></b></u>
            <FormattedMessage {...messages.deactivatedText4} />
          </Text>
        </span>
      ): (<Text><FormattedMessage {...messages.deactivatedTextTopping} /></Text>)}
    </span>
  );
}

export default memo(Inactive);
