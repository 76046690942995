// Core
import React, { memo, useState, useCallback } from 'react';
import { compose, graphql } from 'react-apollo';
import { injectIntl, } from 'react-intl';
import Collapse from '@material-ui/core/Collapse';

// Components
import ContentHeader from 'containers/ContentHeader';
import Button from 'components/Button';
import Breadcrumbs from 'components/Breadcrumbs';
import PlansTable from 'routes/AdminPanel/Plans/PlansTable/';
import PlansTabs from 'routes/AdminPanel/Plans/Tabs';
import SalesPlan from 'routes/AdminPanel/Plans/SalesPlan';

// GraphQl
import LIST_SUBSCRIPTION_PLANS from 'queries/AdminPanel/listSubscriptionPlans.gql';
import UPDATE_SUBSCRIPTION_PLAN from 'queries/AdminPanel/updateSubscriptionPlan.gql';

// Other
import { keysToCamel, keysToSnake } from  'utils/helpers/index';
import { sharedMessages } from 'services/i18n/sharedMessages/messages';
import { withSharedState } from 'containers/SharedStateProvider/withSharedState';
import { withNotification } from 'containers/NotificationProvider/withNotification';
import { MAPPING_OUTPUT } from 'routes/AdminPanel/Plans/SalesPlan/utils.js';

// Styles and Assets
import './index.scss';

const PREFIX = 'sk-admin-plans';
const Plans = ({
  items,
  loading = false,
  handlePlanUpdate,
  showNotificationBar,
  sharedStateContext: { sharedState },
  intl: { formatMessage }
}) => {
  const [activeTab, setActiveTab] = useState('subscription');
  const handleTabSwitch = useCallback((nextTab) => {
    setActiveTab(nextTab);
  }, [setActiveTab]);

  const saveCommissionPlan = useCallback(async () => {
    const { validateForm, values } = sharedState?.commissionForm;

    const errors = await validateForm(values);
    const isValid = Object.keys(errors)?.length == 0;

    if (isValid) {
      let input = { id: commission?.id };

      for (const fieldName in values) {
        const mappedFieldName = MAPPING_OUTPUT[fieldName];
        if (typeof mappedFieldName == 'string') {
          input[mappedFieldName] = values[fieldName];
        } else if (typeof mappedFieldName == 'function') {
          input = { ...input, ...mappedFieldName(commission, values[fieldName]) };
        };
      };

      await handlePlanUpdate(input);
      showNotificationBar('saved');
    } else {
      showNotificationBar('failed');
    }
  }, [sharedState?.commissionForm, handlePlanUpdate, commission])

  const commission = items.find(plan => plan?.type == 'commission');
  const plans = items.filter(plan => plan?.type == 'package' || plan?.type == 'constructor');

  return (
    <div className={PREFIX}>
      <ContentHeader way={<Breadcrumbs />} />
      <div className={`${PREFIX}__navigation`}>
        <PlansTabs activeTab={activeTab} handleSwitch={handleTabSwitch} />
        {activeTab == 'sales' && (
          <Button color="secondary" onClick={saveCommissionPlan}>
            {formatMessage(sharedMessages.saveButton)}
          </Button>
        )}
      </div>

      <Collapse in={activeTab === 'subscription'} timeout="auto">
        <PlansTable loading={loading} items={plans} />
      </Collapse>

      <Collapse in={activeTab === 'sales'} timeout="auto">
        <SalesPlan loading={loading} plan={{ title: commission?.title || {}, price: commission?.price || [], id: commission?.id }} handlePlanUpdate={handlePlanUpdate} />
      </Collapse>

    </div>
  );
};

const updateSalesPlan = graphql(UPDATE_SUBSCRIPTION_PLAN, {
  props: ({ mutate }) => ({
    handlePlanUpdate: values => mutate({ variables: { input: keysToSnake(values) } })
  }),
  options: () => ({
    refetchQueries: ['listSubscriptionPlans'],
  })
});

const listSubscriptionPlans = graphql(LIST_SUBSCRIPTION_PLANS, {
  props: ({ data: { error, loading, listSubscriptionPlans, refetch, ...ownProps } }) => {

    if (error) throw Error('Something went wrong, while receiving discounts');
    if (loading) return { loading, items: [], };

    return {
      items: keysToCamel(listSubscriptionPlans?.items),
      loading,
      refetch,
      ...ownProps,
    }
  },
  options: () => ({
    fetchPolicy: 'network-only',
  }),
});

const enhancer = compose(
  memo,
  injectIntl,
  withNotification,
  listSubscriptionPlans,
  updateSalesPlan,
  withSharedState
);

export default enhancer(Plans);
