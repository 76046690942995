// Core
import React, { useEffect, useState } from 'react';
import { graphql, compose } from 'react-apollo';
import { injectIntl, intlShape } from 'react-intl';

// Components
import SimpleSelect from 'components/Select/SimpleSelect';

// GraphQl
import LIST_SUBCATEGORIES_QUERY from 'queries/Courses/listSubcategories.gql';

// Other
import { CATALOG_AMOUNT } from 'utils/enums';
import { sharedMessages } from 'services/i18n/sharedMessages/messages';

const SubcategorySelect = props => {
  const parseSubcategoriesList = (subcategories = []) => {
    const {
      intl: { formatMessage }
    } = props;

    const sortedCategories = subcategories.sort(
      (prev, cur) => prev.order_number - cur.order_number
    );

    return sortedCategories.map(({ id, title, localized }) => ({
      id,
      title:
        localized ||
        (sharedMessages[title] && formatMessage(sharedMessages[title])) ||
        formatMessage(sharedMessages.others)
    }));
  };

  useEffect(() => {
    if (!props.loading) {
      props.handleChange(
        props.value || props.listSubcategories?.items?.[0]?.id
      );
    }
  }, [props.listSubcategories, props.category, props.value]);

  const { error, fetchError, loading, listSubcategories, ...others } = props;

  if (fetchError) return null;

  return (
    <SimpleSelect
      options={parseSubcategoriesList(listSubcategories?.items)}
      inlineStyles={{ selector: { minHeight: 40 } }}
      loading={loading}
      error={error}
      valueKey='id'
      labelKey='title'
      {...others}
    />
  );
};

const listSubcategoriesQuery = graphql(LIST_SUBCATEGORIES_QUERY, {
  skip: props => !!props.skip,
  props: ({
    data: { loading, error: fetchError, listSubcategories, ...ownProps }
  }) => ({
    loading,
    listSubcategories,
    fetchError,
    ...ownProps
  }),
  options: ({ category }) => ({
    fetchPolicy: 'cache-first',
    variables: { category, limit: CATALOG_AMOUNT }
  })
});

SubcategorySelect.propTypes = {
  intl: intlShape.isRequired
};

const enhacner = compose(injectIntl, listSubcategoriesQuery);

export default enhacner(SubcategorySelect);
