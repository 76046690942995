import React from 'react';
import PropTypes from 'prop-types';

const PDF = ({ color, strokeColor, textColor, height, width }) => (
  /* eslint-disable max-len */
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 60 55"
  >
    <g fill="none" fillRule="evenodd">
      <path
        stroke={strokeColor || "#444"}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M39 18.732v-3.419L24.388 0H7.8C3.84 0 .6 3.228.6 7.174v4.203"
      />
      <path
        stroke={strokeColor || "#444"}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M.6 11.013v36.813C.6 51.772 3.84 55 7.8 55h24c3.96 0 7.2-3.228
         7.2-7.174M24.6.4v12.288a2.261 2.261 0 0 0 2.265 2.258h11.747L24.6.4z"
      />
      <path
        fill={color || "#FFF"}
        d="M33.24 20.28h19.741c3.785 0 6.88 3.085
        6.88 6.855v11.398c0 3.77-3.095 6.856-6.88 6.856h-19.89"
      />
      <path
        stroke={strokeColor || "#30d5c8"}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M33.24 20.28h19.741c3.785 0 6.88 3.085 6.88 6.855v11.398c0 3.77-3.095 6.856-6.88 6.856h-19.89"
      />
      <g>
        <path
          fill={color || "#FFF"}
          d="M33.84 45.389H20.54c-3.784 0-6.88-3.085-6.88-6.856V27.135c0-3.77 3.096-6.855 6.88-6.855h13.13"
        />
        <path
          stroke={strokeColor || "#30d5c8"}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M33.84 45.389H20.54c-3.784 0-6.88-3.085-6.88-6.856V27.135c0-3.77 3.096-6.855 6.88-6.855h13.13"
        />
      </g>
      <path
        fill={textColor || "#30d5c8"}
        d="M24.948 38c-.286 0-.516-.09-.689-.27-.173-.181-.259-.426-.259-.736v-9.05c0-.3.081-.531.244-.696.163-.165.388-.248.675-.248h3.644c1.116
         0 1.985.291 2.608.874.622.583.933 1.4.933 2.452s-.311 1.872-.933 2.46c-.623.588-1.492.882-2.608.882h-2.652v3.326c0
         .31-.086.555-.26.735-.172.18-.407.271-.703.271zm3.378-5.864c1.314 0
         1.97-.598 1.97-1.794 0-1.197-.656-1.795-1.97-1.795h-2.415v3.59h2.415zm6.385
         5.771c-.286 0-.511-.082-.674-.247-.163-.165-.245-.397-.245-.697v-9.02c0-.298.082-.53.245-.695.163-.165.388-.248.674-.248h2.933c1.71
         0 3.035.477 3.978 1.431.944.954 1.415 2.292 1.415 4.015 0 1.733-.471 3.076-1.415
         4.03-.943.954-2.269 1.431-3.978 1.431h-2.933zm2.815-1.624c2.4 0 3.6-1.28 3.6-3.837
          0-2.548-1.2-3.822-3.6-3.822h-1.822v7.659h1.822zm8.4 1.717c-.287 0-.516-.093-.69-.278-.172-.186-.259-.429-.259-.728v-9.05c0-.3.082-.531.245-.696.163-.165.388-.248.674-.248h5.2c.603 0 .904.268.904.805 0 .526-.301.789-.904.789H46.86v2.97h3.94c.603 0 .905.268.905.804 0 .527-.302.79-.904.79h-3.941v3.836c0 .3-.084.542-.252.728-.168.185-.395.278-.681.278z"
      />
    </g>
  </svg>
  /* eslint-enable max-len */
);

PDF.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

PDF.defaultProps = {
  height: '45',
  width: '50',
};

export default PDF;
