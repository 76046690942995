import React from 'react';
import { injectIntl } from 'react-intl';
import { compose, graphql } from 'react-apollo';
import classnames from 'classnames';
import { useFormikContext } from 'formik';

// Components
import Text from 'components/Text';
import UploadedImage from 'routes/ExpertsCabinet/ToppingEdit/ToppingInfo/ImageUploaderAndTypeSelector/UploadedImage';
import TypeSelector from 'routes/ExpertsCabinet/ToppingEdit/components/TypeSelector';
import RoundIcon from 'components/Icon/RoundIcon';
import PhotoUploader from 'components/PhotoUploader';

// Icons
import Camera from 'components/Icon/Svg/Camera';

// GraphQL
import DELETE_ATTACHMENT from 'mutations/Courses/deleteAttachment.gql';

// Other
import withWidth from 'containers/HOCs/withWidth';
import { DEFAULT_MAX_FILE_SIZE, SUPPORTED_FORMATS_IMAGE } from 'utils/enums/index';
import { PurpleDark } from 'components/Icon/color';
import { IMAGE_FILE, } from 'utils/enums';
import { TOPPING_PREVIEW_IMAGE, } from 'containers/UploadManagerProvider/helpers';
import { withUploadManager } from 'containers/UploadManagerProvider/withUploadManager';
import { isBlank } from 'utils/helpers';
import { messages } from 'routes/ExpertsCabinet/ToppingEdit/messages';
import { s3UploadPublicCoursePhoto } from 'services/aws/amplify/helpers';

// Styles\
import './index.scss';

const helperEnhancer = compose(
  // withWidth,
  injectIntl,
)

const EpmtyImageBackground = helperEnhancer(({ width = 'xs', uploaderId, handleImageUpload, intl: { formatMessage }}) => {
  const roundIconSize = width == 'xs' ? 'md' : 'xlg';
  const iconWeight = width == 'xs' ? 18 : 25;
  const iconHeight = width == 'xs' ? 21 : 30;

  const { touched, values } = useFormikContext();

  const isImageUploaded= !isBlank(values.coursePhoto);
  const isImageTouched = touched.coursePhoto;

  return (
    <div className={classnames('sk-topping-empty-image-background', { 'sk-topping-empty-image-background__error': (isImageTouched && !isImageUploaded) })}>
      <PhotoUploader
        supportedFormats={SUPPORTED_FORMATS_IMAGE}
        maxSize={DEFAULT_MAX_FILE_SIZE}
        uploaderId={uploaderId}
        handleChange={handleImageUpload}
        icon={<RoundIcon size={roundIconSize} type="flat" icon={<Camera height={iconHeight} width={iconWeight} color={PurpleDark} />} />}
      />
      <Text>{formatMessage(messages.uploadToppingPhoto)}</Text>
      <Text>{formatMessage(messages.toppingPhotoRequirement)}</Text>
    </div>
  )
});

const prefix = 'sk-topping-image-and-type';
const ImageUploaderAndTypeSelector = ({
  topping,
  disabledEditing,
  setFieldValue,
  updateTopping,
  handleToppingUpdate,
  deleteAttachment,
  refetchTopping,
  intl: { formatMessage },
  uploadManagerContext: { addFileToQueue, queue, removeFileFromQueue },
}) => {
  const toppingId = topping?.id;
  const toppingType = topping?.variants[0].name;

  // TODO: Handler to upload image like an attachemnt
  // const handleImageUpload = (id) => (filesList) => {
  //   const createAttachmentMutationVariables = {
  //     type: TOPPING_PREVIEW_IMAGE,
  //     filetype: IMAGE_FILE,
  //     filename: TOPPING_PREVIEW_IMAGE,
  //     ref_id: toppingId,
  //   };
  //
  //   if (!isBlank(id)) {
  //     createAttachmentMutationVariables.id = id;
  //     createAttachmentMutationVariables.filepath = null;
  //   };
  //
  //   const params = {
  //     type: TOPPING_PREVIEW_IMAGE,
  //     file: filesList[0],
  //     s3UploadingVariables: {
  //       file: filesList[0],
  //       refId: toppingId,
  //     },
  //     createAttachmentMutationVariables,
  //     s3PostHook: refetchTopping,
  //   };
  //
  //   addFileToQueue(params);
  // };
  const handleImageUpload = (id) => async (file) => {
    const imageLink = await s3UploadPublicCoursePhoto(file, id);
    await updateTopping({ coursePhoto: imageLink });
  };

  const handleCancelMaterialAdding = id => {
    const isFileInQueue = queue.find(queueItem => queueItem.uploadId === id);

    if (isFileInQueue) removeFileFromQueue(id);
  };

  // TODO: Handler to delete attachemnt that was uploaded like an attachemnt
  // const handleImageDelete = async id => {
  //   try {
  //     handleCancelMaterialAdding(id);
  //     await deleteAttachment(id);
  //   } catch (error) {
  //     throw Error(error);
  //   }
  // };
  const handleImageDelete = async () => {
    await updateTopping({ coursePhoto: null });
  };

  const handleSelectToppingType = (val) => {
    const newVariant = { ...topping.variants[0] };
    newVariant.name = val;
    handleToppingUpdate({ type: [newVariant] });
  };

  const uploaderId = 'topping-image';

  return (
    <div className={prefix}>
      <UploadedImage
        readOnly={disabledEditing}
        uploaderId={uploaderId}
        imageLink={topping.coursePhoto}
        handleImageUpload={handleImageUpload(topping?.id)}
        handleImageDelete={handleImageDelete}
        background={<EpmtyImageBackground uploaderId={uploaderId} handleImageUpload={handleImageUpload(topping?.id)} />}
      />
      <TypeSelector
        type={toppingType}
        disabledEditing={disabledEditing}
        handleSelectToppingType={handleSelectToppingType}
      />
    </div>
  );
};

const deleteAttachmentMutation = graphql(DELETE_ATTACHMENT, {
  props: ({ mutate }) => ({
    deleteAttachment: id => mutate({ variables: { id } }),
  }),
  options: {
    refetchQueries: ['getQuiz'],
  },
});

const enhancer = compose(
  injectIntl,
  withUploadManager,
  deleteAttachmentMutation,
)

export default enhancer(ImageUploaderAndTypeSelector);
