import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { IntlProvider, addLocaleData } from 'react-intl';
import { compose, graphql } from 'react-apollo';

import en from 'react-intl/locale-data/en';
import ru from 'react-intl/locale-data/ru';

import { LanguageContext } from './language-context';
import { withLanguage } from 'containers/LanguageProvider/withLanguage';
import translations from 'services/i18n/locales';
import { localStore } from 'services/localStorage';
import { getUserAgentLanguage, isArray, parseCookies } from 'utils/helpers';
import GlobalIntl from 'containers/HOCs/withGlobalIntl';
import RouteInstance from 'services/route';
import { withAuthenticatedUser } from 'containers/AuthenticatedUserProvider/withAuthenticatedUser';

import LIST_LANGUAGES_QUERY from 'query/listLanguages.gql';
import GET_USER_INTERFACE_LANGUAGE from 'query/getUserInterfaceLanguage.gql';
import UPDATE_PROFILE from 'mutations/UserProfile/updateProfile.gql';

addLocaleData([...en, ...ru]);

const DEFAULT_LANGUAGE = getUserAgentLanguage();
// const storedLanguage = localStore.getItem('language') || document.cookie.replace(/(?:(?:^|.*;\s*)language\s*=\s*([^;]*).*$)|^.*$/, "$1");

class LanguageProvider extends PureComponent {

  localeRegex = /^\/([a-z]{2})(\/|$)/;

  constructor(props) {
    super(props);
    this.state = {
      language: this.getInitialLocale(props),
    }
  }

  componentDidUpdate() {
    const { language } = this.state;

    const {
      userContext: { getProfile, profile, isAuthenticated },
    } = this.props;

    if (language && isAuthenticated && profile?.interface_language !== language) {
      this.setLanguage(profile?.interface_language || language);
    }
  }

  setLanguage = (language) => {
    this.setState({ language }, () => {
      document.cookie = `language=${language};path=\/`;
    });
  }

  updateLanguage = (language) => {
    this.setState({ language }, async () => {
      const {
        updateUserInterfaceLanguage,
        userContext: { getProfile, profile, isAuthenticated },
      } = this.props;

      try {
        if (isAuthenticated) {
          await updateUserInterfaceLanguage({ interface_language: language });
        }
        document.cookie = `language=${language};path=\/`;

        const {location: { pathname, search, state, }, history} = this.router.get();

        // Replace locale inside url context
        const localizedUrl = [language];
        if (pathname) {
          const routeLocale = this.getLocaleFromPathname();
          if (routeLocale && routeLocale !== language) {
            localizedUrl.push(pathname.replace(this.localeRegex, ''));
            history.replace({ state, search,
              pathname: '/' + localizedUrl.join('/'),
            });
          }
        }
      } catch (error) {
        throw Error(error);
      }
    });
  }

  getInitialLocale = (props) => {
    this.router = RouteInstance;
    const {location: { locale }} = this.router.get();
    const cookieLocale = parseCookies('language');
    const pathLocale = this.getLocaleFromPathname();
    return props.serverLanguage || locale || pathLocale || cookieLocale || DEFAULT_LANGUAGE;
  }

  setLocaleFromPathname = () => {
    const {language} = this.state;
    const pathLocale = this.getLocaleFromPathname();

    if (pathLocale !== language) {
      this.setLanguage(pathLocale);
    }
  }

  getLocaleFromPathname = () => {
    try {
      if (!window.isServer) {
        const {location: { pathname }} = window;
        const pathLocale = pathname.match(this.localeRegex);
        if (isArray(pathLocale) && pathLocale.length > 0 && pathLocale[1]) {
          return pathLocale[1];
        }        
      }

      const {location: { pathname }} = this.router.get();
      const pathLocale = pathname.match(this.localeRegex);
      if (isArray(pathLocale) && pathLocale.length > 0 && pathLocale[1]) {
        return pathLocale[1];
      }
      return false
    } catch(err) {
      return false;
    }
  }

  render() {
    const { language } = this.state;
    const { children, languages } = this.props;

    return (
      <LanguageContext.Provider
        value={{
          languageContext: {
            language,
            languages,
            updateLanguage: this.updateLanguage,
          },
        }}
      >
        <IntlProvider
          locale={language}
          key={language}
          messages={translations[language]}
        >
          <GlobalIntl>
            {React.Children.only(children)}
          </GlobalIntl>
        </IntlProvider>
      </LanguageContext.Provider>
    );
  }
}

LanguageProvider.propTypes = {
  children: PropTypes.element.isRequired,
  userInterfaceLanguage: PropTypes.string,
  languages: PropTypes.arrayOf(
    PropTypes.shape({
      language_id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
};

const listLanguagesQuery = graphql(LIST_LANGUAGES_QUERY, {
  props: ({
    data: { loading, error: fetchError, listInterfaceLanguages, ...ownProps },
  }) => ({
    loading,
    languages:
      listInterfaceLanguages &&
      listInterfaceLanguages.items.filter((x) => x.language_id !== "ru"), // TODO: temporal filter until the query will be changed
    fetchError,
    ...ownProps,
  }),
  options: () => ({
    fetchPolicy: 'cache-and-network',
  }),
});

// const getUserLanguage = graphql(GET_USER_INTERFACE_LANGUAGE, {
//   props: ({
//     data: { loading, error: fetchError, getProfile, ...ownProps },
//   }) => ({
//     loading,
//     fetchError,
//     userInterfaceLanguage: getProfile?.interface_language, // eslint-disable-line
//     ...ownProps,
//   }),
// });

const updateProfileMutation = graphql(UPDATE_PROFILE, {
  props: ({ mutate }) => ({
    updateUserInterfaceLanguage: input => mutate({ variables: { input } }),
  }),
  options: {
    refetchQueries: ['getProfile'],
  },
});

const enhancer = compose(
  withAuthenticatedUser,
  withLanguage,
  listLanguagesQuery,
  updateProfileMutation
);

export default enhancer(LanguageProvider);
