import React from 'react';

// Components
import { StyleIconButton } from 'components/DraftEditor/ControlsBar/Buttons';

// Icons
import UnorderedList from 'components/Icon/SvgCss/Editor/UnorderedList';
import OrderedList from 'components/Icon/SvgCss/Editor/OrderedList';

// Styles
import './ListControls.scss';

const BLOCK_TYPES = [
  { label: 'UL', style: 'unordered-list-item', icon: UnorderedList },
  { label: 'OL', style: 'ordered-list-item', icon: OrderedList },
];

const ListControls = ({ editorState, onToggle }) => {
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <div className="sk-editor-toolbar-controls-block">
      {BLOCK_TYPES.map(type => (
        <StyleIconButton
          key={type.label}
          active={type.style === blockType}
          onToggleHandler={onToggle}
          style={type.style}
          icon={type.icon}
        />
      ))}
    </div>
  );
};

export default ListControls;
