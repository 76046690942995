import React from 'react';
import { UploadManagerContext } from './UploadManagerContext';

export function withUploadManager(Component) {
  return function UploadManagerComponent(props) {
    return (
      <UploadManagerContext.Consumer>
        {contexts => <Component {...props} {...contexts} />}
      </UploadManagerContext.Consumer>
    );
  };
}
