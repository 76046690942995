// Core
import React, { PureComponent, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
// import withWidth from 'containers/HOCs/withWidth';

// Components
import CourseReviewCarousel from 'routes/CourseDetails/CourseReview/CourseReviewCarousel';

// Other
import { messages } from 'routes/CourseDetails/CourseReview/messages';

// Styles and Assets
import './index.scss';

const CourseReview = ({ rating }) => {
  const getTimeAgo = time => moment().format('DDDD') - moment(time).format('DDDD');

  return (
    <CourseReviewCarousel rating={rating} />
  )
}

export default CourseReview;
