// Core
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Components
import Text from 'components/Text';

// Styles and Assets
import './StepperIcon.scss';

const StepperIcon = ({ id, active, style }) => (
  <div
    style={style}
    className={classNames('sk-stepper-icon', {
      'sk-stepper-icon_active': active,
    })}
  >
    <Text
      classes={classNames('sk-stepper-icon__text', {
        'sk-stepper-icon__text_active': active,
      })}
      light
    >
      {id}
    </Text>
  </div>
);

StepperIcon.propTypes = {
  active: PropTypes.bool,
};

export default StepperIcon;
