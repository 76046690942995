// Core
import React, { memo, useCallback, useEffect } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { compose, graphql } from 'react-apollo';
import classNames from 'classnames';
import { withFormik } from 'formik';

// Components
import TextInput from 'components/Input/Material/TextInput';
import TextAreaInput from 'components/Input/Material/TextAreaInput';
import PreviewUploader from 'routes/ExpertsCabinet/CourseEdit/Details/CourseInformation/AdditionalCourseDetails/PreviewUploader';
import Text from 'components/Text';
import ToolTip from 'components/ToolTip';
import Button from 'components/Button';
import Heading from 'components/Heading';
import MaterialsAccessSettings from 'routes/ExpertsCabinet/CourseEdit/Details/CourseInformation/AdditionalCourseDetails/MaterialsAccessSettings';

// Other
import { handleTimeToCompletionChange, getTimeToCompetion, hasVariant } from
  'routes/ExpertsCabinet/CourseEdit/Details/CourseInformation/helpers.js';
import { withSharedState } from 'containers/SharedStateProvider/withSharedState';
import { withCourseEditContext } from 'routes/ExpertsCabinet/CourseEdit/Context';
import { isBlank } from 'utils/helpers';
import { withFormattedHtmlMessage } from 'containers/HOCs/withFormattedHtmlMessage';
import { withModal } from 'containers/ModalProvider/withModal';
import { messages } from 'routes/ExpertsCabinet/CourseEdit/Details/messages';
import { getYupSchema } from 'services/yup';

// Styles
import './index.scss';

const getIntitalState = ({
  maxNumberOfUsers,
  timeToCompletion,
  finalLetterToStudents,
  coursePreview,
  messengerUrl,
}) => {
  return {
    maxNumberOfUsers,
    timeToCompletion,
    finalLetterToStudents,
    coursePreview: coursePreview?.url,
    messengerUrl,
  };
};

const AdditionalCourseDetails = ({
  isValid,
  values,
  errors,
  touched,
  validateForm,
  handleBlur,
  setFieldValue,
  setErrors,
  setTouched,
  disabled,
  modalContext: { showModal },
  refetchCourse,
  sharedStateContext: { updateSharedState },
  courseEditContext: {
    course,
    handleCourseUpdate,
    bindCourseMethod,
  },
  intl: { formatMessage },
  // refetchCourse,
}) => {
  const isMainFillingBlocked = ['DRAFT', 'FIRST_REVIEW'].includes(course.statusId);

  if (isMainFillingBlocked) {
    return null;
  };

  useEffect(() => {
    let isValid = true;

    Object.keys(errors).forEach(error => {
      if (touched[error]) {
        isValid = false;
      }
    });

    const additionalCourseDetails = { isValid };
    updateSharedState({ additionalCourseDetails });
    return () => {
      updateSharedState({ additionalCourseDetails: { isValid: true } });
    };
  }, [errors, touched]);

  const handleInputChange = useCallback(name => (inputValue, e) => {
    setFieldValue(name, inputValue);
  }, [setFieldValue]);

  const handleInputBlur = useCallback((name, applyCurrentChange = false) => async (inputValue, e) => {
    if (applyCurrentChange) {
      setFieldValue(name, inputValue, false);
    }

    if (e) {
      handleBlur(e);
    }

    const errors = await validateForm({ ...values, [name]: inputValue });
    const hasError = errors[name];

    if (!hasError) handleCourseUpdate({ [name]: `${inputValue}` });
  }, [values, handleBlur, validateForm, handleCourseUpdate]);

  const handleRadioButtonChange = useCallback(name => async (inputValue, e) => {
    setFieldValue(name, inputValue);
    await handleCourseUpdate({ [name]: inputValue }, true);
  }, [setFieldValue, handleCourseUpdate]);

  const handleFormValidation = useCallback(async () => {
    const errors = await validateForm(values);
    const isValid = Object.keys(errors).length == 0;

    if (!isValid) {
      const touched = {};

      for (const fieldName in errors) {
        touched[fieldName] = true;
      }

      setErrors(errors);
      setTouched(touched);
    }

    return isValid;
  }, [values, validateForm, setErrors]);
  bindCourseMethod({ method: 'validateAdditionalCourseDetails', handler: handleFormValidation });


  const isFlexSelected = hasVariant({ variants: course?.variants, variantName: 'flex' });
  const isAssistSelected = hasVariant({ variants: course?.variants, variantName: 'assist' });
  const isConciergeSelected = hasVariant({ variants: course?.variants, variantName: 'concierge' });

  let timeToCompletionInDays = getTimeToCompetion(values.timeToCompletion);
  timeToCompletionInDays = timeToCompletionInDays > 0 ? timeToCompletionInDays : '';

  let numbersOfUser = values.maxNumberOfUsers || '';

  return (
    <>
      <Heading variant="h3" disabled={disabled}>
        <FormattedMessage {...messages.mediaHeading} />
      </Heading>
      <div className="sk-form__row">
        <PreviewUploader
          courseId={course.id}
          refetchCourse={refetchCourse}
          coursePreview={course?.coursePreview}
          link={values?.coursePreview}
          title={course?.coursePreview?.title}
          disabled={disabled}
          error={
            !values?.coursePreview?.url
              ? touched.coursePreview && Boolean(errors.coursePreview)
              : null
          }
          errorText={touched.coursePreview ? errors.coursePreview : ''}
        />
      </div>
      <div>
        <Heading
          variant="h3"
          disabled={disabled}
          classes="sk-course-details-heading"
        >
          <FormattedMessage {...messages.finalLetterHeading} />
        </Heading>
        <div className="sk-form__row">
          <div>
            <TextAreaInput
              name="finalLetterToStudents"
              value={values.finalLetterToStudents}
              onBlur={handleInputBlur('finalLetterToStudents', true)}
              label={formatMessage(messages.finalLetterToStudents)}
              error={
                touched.finalLetterToStudents &&
                Boolean(errors.finalLetterToStudents)
              }
              disabled={disabled}
              helperText={
                touched.finalLetterToStudents
                  ? errors.finalLetterToStudents
                  : ''
              }
            />
            <ToolTip
              wide
              triggerClasses="sk-course-information-form__trigger"
              iconTheme="orange"
            >
              <Text classes="sk-desc-for-admin__heading">
                <FormattedMessage {...messages.pleaseWriteFewWellWords} />
              </Text>
            </ToolTip>
          </div>
        </div>
      </div>
      <div>
        <Heading
          variant="h3"
          disabled={disabled}
          classes="sk-course-details-heading"
        >
          <FormattedMessage {...messages.messangerUrlHeading} />
        </Heading>
        <div className="sk-form__row sk-course-details__messenger-input">
          <TextAreaInput
            name="finalLetterToStudents"
            value={values.messengerUrl}
            onBlur={handleInputBlur('messengerUrl', true)}
            label={formatMessage(messages.messangerUrlLable)}
            error={touched.messengerUrl && Boolean(errors.messengerUrl)}
            disabled={disabled}
            helperText={touched.messengerUrl ? errors.messengerUrl : ''}
          />
        </div>
      </div>
      <div>
        <Heading variant="h3" classes="sk-course-details-heading">
          <FormattedMessage {...messages.courseSetting} />
        </Heading>
        <div className="sk-form__row sk-course-details-setting-item">
          <TextInput
            adornment={<FormattedMessage {...messages.days} />}
            type="number"
            name="timeToCompletion"
            value={timeToCompletionInDays}
            onChange={(inputValue, e) => handleTimeToCompletionChange(handleInputChange, inputValue, e)}
            onBlur={(inputValue, e) => handleTimeToCompletionChange(handleInputBlur, inputValue, e)}
            label={formatMessage(messages.timeEstimation)}
            error={touched.timeToCompletion && Boolean(errors.timeToCompletion)}
            disabled={disabled || (isFlexSelected && !isAssistSelected && !isConciergeSelected)}
            helperText={touched.timeToCompletion ? errors.timeToCompletion : ''}
          />
          <TextInput
            type="number"
            name="maxNumberOfUsers"
            value={numbersOfUser}
            onBlur={handleInputBlur('maxNumberOfUsers', true)}
            label={formatMessage(messages.maxNumOfUsers)}
            error={touched.maxNumberOfUsers && Boolean(errors.maxNumberOfUsers)}
            disabled={disabled || (isFlexSelected && !isAssistSelected && !isConciergeSelected)}
            helperText={touched.maxNumberOfUsers ? errors.maxNumberOfUsers : ''}
          />
        </div>
        <MaterialsAccessSettings
          disabled={disabled}
          variants={course.variants}
          handleRadioButtonChange={handleRadioButtonChange}
        />
      </div>
    </>
  );
};

const formikStateManagement = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ courseEditContext: { course } }) => {
    const initialValues = getIntitalState(course);

    const hasFlexVariant = hasVariant({ variants: course?.variants, variantName: 'flex' });
    const hasAssistVariant = hasVariant({ variants: course?.variants, variantName: 'assist' });
    const hasConciergeVariant = hasVariant({ variants: course?.variants, variantName: 'concierge' });

    if (hasFlexVariant && !hasAssistVariant && !hasConciergeVariant) {
      initialValues.maxNumberOfUsers = 10000;
      initialValues.timeToCompletion = 31536000000; // 365 days in milliseconds
    }

    return initialValues;
  },  displayName: 'AdditionalCourseDetailsForm',
  validationSchema: () => getYupSchema('additionalCourseDetailsValidationSchema'),
  validateOnChange: false,
  validateOnBlur: false,
});

const enhancer = compose(
  memo,
  injectIntl,
  withModal,
  withSharedState,
  withCourseEditContext,
  formikStateManagement
);

export default enhancer(AdditionalCourseDetails);
