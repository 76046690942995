import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BigCalendar from 'react-big-calendar';
import { accessor } from 'react-big-calendar/lib/utils/accessors';
import { withLanguage } from 'containers/LanguageProvider/withLanguage';
import moment from 'moment';
import 'moment-timezone';
import EventItem from './EventItem';  // Import EventItem

import 'moment/locale/ru';

// remember the browser's local timezone, as it might by used later on
BigCalendar.tz = moment.tz.guess();
// format all dates in BigCalendar as they would be rendered in browser's local timezone (even if later on they won't)
const m = (...args) => moment.tz(...args, BigCalendar.tz);
m.localeData = moment.localeData;

const localizer = BigCalendar.momentLocalizer(m);

export const convertDateTimeToDate = (datetime, timeZoneName) => {
  const currentMoment = moment.tz(datetime, timeZoneName);
  return new Date(
    currentMoment.year(),
    currentMoment.month(),
    currentMoment.date(),
    currentMoment.hour(),
    currentMoment.minute(),
    0
  );
};

export const convertDateToDateTime = (date, timeZoneName) => {
  const dateM = moment.tz(date, timeZoneName);
  return moment.tz(
    {
      year: dateM.year(),
      month: dateM.month(),
      date: dateM.date(),
      hour: dateM.hour(),
      minute: dateM.minute(),
    },
    timeZoneName
  );
};

class TimeZoneAgnosticBigCalendar extends Component {
  static propTypes = {
    events: PropTypes.array,
    onSelectSlot: PropTypes.func,
    onEventDrop: PropTypes.func,
    timeZoneName: PropTypes.string,
    startAccessor: PropTypes.string,
    endAccessor: PropTypes.string,
  };

  static defaultProps = {
    startAccessor: 'start',
    endAccessor: 'end',
  };

  startAccessor = event => {
    const { startAccessor, timeZoneName } = this.props;
    const start = accessor(event, startAccessor);
    return convertDateTimeToDate(start, timeZoneName);
  };

  endAccessor = event => {
    const { endAccessor, timeZoneName } = this.props;
    const end = accessor(event, endAccessor);
    return convertDateTimeToDate(end, timeZoneName);
  };

  render() {
    const {
      onSelectSlot,
      onEventDrop,
      timeZoneName,
      languageContext,
      ...props
    } = this.props;
    const bigCalendarProps = {
      ...props,
      startAccessor: this.startAccessor,
      endAccessor: this.endAccessor,
      onSelectSlot:
        onSelectSlot &&
        (({ start, end, slots }) => {
          onSelectSlot({
            start: convertDateToDateTime(start, timeZoneName),
            end: convertDateToDateTime(end, timeZoneName),
            slots: slots.map(date => convertDateToDateTime(date, timeZoneName)),
          });
        }),
      onEventDrop:
        onEventDrop &&
        (({ event, start, end }) => {
          onEventDrop({
            event,
            start: convertDateToDateTime(start, timeZoneName),
            end: convertDateToDateTime(end, timeZoneName),
          });
        }),
    };

    const isRuLanguage = languageContext?.language === 'ru';

    const calendarRuTranslation = {
      next: 'Вперёд',
      previous: 'Назад',
      today: 'Сегодня',
      month: 'Месяц',
      week: 'Неделя',
      day: 'День',
    };

    return (
      <BigCalendar
        {...bigCalendarProps}
        culture={languageContext?.language || 'en'}
        messages={isRuLanguage ? calendarRuTranslation : null}
        localizer={localizer}
        components={{
          eventWrapper: EventItem,
        }}
      />
    );
  }
}
export default withLanguage(TimeZoneAgnosticBigCalendar);
