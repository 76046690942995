// Core
import React, { useCallback } from 'react';
import { withRouter } from 'react-router';

// Components
import Text from 'components/Text';

// Other
import { isArray, isEmptyArray } from 'utils/helpers';

// Styles and Assets
import './index.scss';

const PREFIX = 'sk-breadcrumbs'
function Breadcrumbs ({
  actionBlock,
  breadcrumbs,
  history,
  ...props
}) {
  const goToPath = useCallback((path) => () => {
    if (!path?.lenght) {
      return;
    };
    history.replace(path);
  }, [history.replace]);

  return (
    <div className={`${PREFIX}`}>
      <div className={`${PREFIX}__link`}>
        {(isArray(breadcrumbs) && !isEmptyArray(breadcrumbs)) && breadcrumbs.map(breadcrumb => (
          <Text size="small" onClick={goToPath(breadcrumb?.path)}>{breadcrumb?.titel}</Text>
        ))}
      </div>
      {actionBlock || null}
    </div>
  );
};

export default withRouter(Breadcrumbs);
