import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  catalog: {
    id: 'app.components.MobileSidebar.catalog',
    defaultMessage: 'Catalog',
  },
  account: {
    id: 'app.components.MobileSidebar.account',
    defaultMessage: 'Account',
  },
});
