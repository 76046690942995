import React from 'react';
import { withSnackbar } from 'notistack';

import { FormattedMessage } from 'react-intl';
import { messages } from 'containers/NotificationProvider/messages';

const mgs = {
  saved: {
    text: <FormattedMessage {...messages.changesSaved} />,
    variant: 'success',
    autoHideDuration: 2000,
  },
  failed: {
    text: <FormattedMessage {...messages.changesNotSaved} />,
    variant: 'error',
    autoHideDuration: 2000,
  },
  sended: {
    text: <FormattedMessage {...messages.emailSent} />,
    variant: 'success',
    autoHideDuration: 2000,
  },
};

export const withNotification = WrappedComponent => {
  const HasNotifications = ({ enqueueSnackbar, ...props }) => {
    const showNotificationBar = msgKey => {
      if (!mgs[msgKey]) return null;
      return enqueueSnackbar(mgs[msgKey].text, {
        variant: mgs[msgKey].variant,
        autoHideDuration: mgs[msgKey].autoHideDuration,
      });
    };

    return (
      <WrappedComponent {...props} showNotificationBar={showNotificationBar} />
    );
  };

  return withSnackbar(HasNotifications);
};
