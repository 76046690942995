// Core
import React from 'react';
import { Formik } from 'formik';
import { compose, graphql } from 'react-apollo';

// Components
import TextInput from 'components/Input/Material/TextInput';
import Button from 'components/Button';
import Heading from 'components/Heading';

// Other
import { getYupSchema } from 'services/yup';
import { FormattedMessage } from 'react-intl';
import { messages } from './messages';
import CREATE_CATEGORY_MUTATION from 'mutations/AdminPanel/createCategory.gql';

// Styles and Assets
import './CategoriesForm.scss';

const defaultFields = {
  title: '',
  localized: '',
};

export const CategoryForm = ({
  handleInvitationSubmit,
  handleAddCategorySubmit,
  companyId,
}) => (
  <Formik
    onSubmit={async (values, formikBag) => {
      const { title, localized } = values;
      await handleAddCategorySubmit({
        title,
        localized,
        org: companyId,
      });
      return handleInvitationSubmit(values, formikBag);
    }}
    initialValues={defaultFields}
    validationSchema={getYupSchema('categorySchema')}
    validateOnChange={false}
    validateOnBlur
  >
    {props => {
      const {
        values: { title, localized },
        errors,
        touched,
        handleSubmit,
        handleBlur,
        setFieldValue,
      } = props;

      const handleInputChange = name => (inputValue, e) => {
        setFieldValue(name, inputValue);
        if (e) e.persist();
      };

      const handleInputBlur = name => (inputValue, e) => {
        if (e) {
          e.persist();
          handleBlur(e);
        }
        setFieldValue(name, inputValue);
      };

      return (
        <form
          className='sk-pw-change-form'
          noValidate
          autoComplete='off'
          onSubmit={handleSubmit}
        >
          <div className='sk-invite-form_inner'>
            <Heading variant='h2' newLine>
              <FormattedMessage {...messages.heading} />
            </Heading>
            <div className='sk-invite_row'>
              <FormattedMessage {...messages.title}>
                {msg => (
                  <TextInput
                    type='text'
                    name='title'
                    helperText={touched.title ? errors.title : ''}
                    error={touched.title && Boolean(errors.title)}
                    value={title}
                    label={msg}
                    onChange={handleInputChange('title')}
                    onBlur={handleInputBlur('title')}
                  />
                )}
              </FormattedMessage>
            </div>
            <div className='sk-invite_row'>
              <FormattedMessage {...messages.localized}>
                {msg => (
                  <TextInput
                    type='text'
                    name='localized'
                    helperText={touched.localized ? errors.localized : ''}
                    error={touched.localized && Boolean(errors.localized)}
                    value={localized}
                    label={msg}
                    onChange={handleInputChange('localized')}
                    onBlur={handleInputBlur('localized')}
                  />
                )}
              </FormattedMessage>
            </div>
          </div>
          <div className='sk-pw-change-form_footer'>
            <Button variant='text' color='secondary' size='large' type='submit'>
              <FormattedMessage {...messages.add} />
            </Button>
          </div>
        </form>
      );
    }}
  </Formik>
);

const inviteUserMutation = graphql(CREATE_CATEGORY_MUTATION, {
  props: ({ mutate }) => ({
    handleAddCategorySubmit: input => mutate({ variables: { input } }),
  }),
  options: ({ refetchQueries }) => ({
    refetchQueries,
  }),
});

const enhancer = compose(inviteUserMutation);

export default enhancer(CategoryForm);
