// Core
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { compose, graphql } from 'react-apollo';
import momentTimezone from 'moment-timezone';

import 'react-big-calendar/lib/css/react-big-calendar.css';

// Components
import Modal from 'components/Modal';
import CreateEventModal from 'containers/Calendar/CreateEventModal/CreateEventModal';
import EditEventModal from 'containers/Calendar/EditEventModal/EditEventModal';
import CalendarContentHeader from 'containers/Calendar/CalendarContentHeader';
import NearestWebinarBlock from 'containers/Calendar/NearestWebinarBlock';
import TimeZoneAgnosticBigCalendar from 'containers/TimeZoneAgnosticBigCalendar';
import EventDetailsModal from 'containers/Calendar/EventDetailsModal';

// GraphQl
import MY_PROFILE_QUERY from 'queries/UserProfile/getProfile.gql';
import CREATE_EVENT_MUTATION from 'mutations/Calendar/createCalendarEvent.gql';
import EDIT_EVENT_MUTATION from 'mutations/Calendar/updateCalendarEvent.gql';
import DELETE_EVENT_MUTATION from 'mutations/Calendar/deleteCalendarEvent.gql';
import LIST_EXPERT_COURSES from 'queries/Courses/listExpertCourses.gql';

// Styles and Assets
import './Calendar.scss';
import 'utils/styles/bigCalendar/agenda.scss';
import 'utils/styles/bigCalendar/event.scss';
import 'utils/styles/bigCalendar/month.scss';
import 'utils/styles/bigCalendar/reset.scss';
import 'utils/styles/bigCalendar/styles.scss';
import 'utils/styles/bigCalendar/time-column.scss';
import 'utils/styles/bigCalendar/time-grid.scss';
import 'utils/styles/bigCalendar/toolbar.scss';
import 'utils/styles/bigCalendar/variables.scss';

// Other
import { withModal } from 'containers/ModalProvider/withModal';
import {
  getCalendarNearesetWebinar,
  listCalendarEventsThatStartInProvidedPeriodOfTime
} from 'utils/reducers';
import { parseMomentTimeInAMPM, isBlank } from 'utils/helpers/index';

const getWebinarLink = (event, courseId, role) => {
  if (role === 'student') {
    // return /subscriber-webinar/${courseId}/${event?.id};
    return {
      pathname: `/subscriber-webinar/${courseId}/${event.idEvent}`,
      state: {
        sessionId: event.sessionId,
        mappingId: event.mappingId
      }
    };
  }
  return `/publisher-webinar/${courseId}/${event?.idEvent}`;
};

class Calendar extends PureComponent {
  state = {
    isModalOpen: false,
    events: [],
    modalProps: {},
    listCourses: [],
    isModalInEditMode: false,
    editEventItem: {}
  };

  eventsColorsCounters = {
    '#AE72E9': 0,
    '#7145D8': 0,
    '#3E66CD': 0,
    '#427DEE': 0,
    '#5596BA': 0
  };

  coursesEventsColors = {};

  componentDidMount = () => {
    const { match, history } = this.props;
    if (match.params?.id) {
      const studentInteractionLevel = match.params?.interactionLevel;
      const coursePageUrl = match.url.split('calendar')[0];

      if (studentInteractionLevel !== 'concierge')
        return history.push(`${coursePageUrl}`);
    }

    return null;
  };

  static getDerivedStateFromProps(props) {
    const { calendarEvents, expertListCalendarEvents } = props;
    const events = [];

    if (!isBlank(calendarEvents)) {
      calendarEvents.map(item => {
        const data = {
          author: {
            id: item.Author.id,
            firstName: item.Author.first_name,
            lastName: item.Author.last_name
          },
          course: {
            id: item.Course.id,
            title: item.Course.title
          },
          idEvent: item.Id,
          sessionId: item.SessionId,
          mappingId: item.UserCourseId,
          title: item.EventName,
          desc: item.EventDescription,
          end: momentTimezone(item.EventEndUnixMs).toDate(),
          start: momentTimezone(item.EventStartUnixMs).toDate()
        };

        events.push(data);
        return null;
      });
      return {
        events
      };
    }

    if (!isBlank(expertListCalendarEvents)) {
      expertListCalendarEvents.map(item => {
        const data = {
          author: {
            id: item.Author.id,
            firstName: item.Author.first_name,
            lastName: item.Author.last_name
          },
          course: {
            id: item?.Course?.id || null,
            title: item?.Course?.title || null
          },
          id: item?.Course?.id || null,
          idEvent: item.id,
          title: item.EventName,
          desc: item.EventDescription,
          end: momentTimezone(item.EventEndUnixMs).toDate(),
          start: momentTimezone(item.EventStartUnixMs).toDate()
        };
        events.push(data);
        return null;
      });
      return {
        events
      };
    }
    return null;
  }

  getEventColor = ({ event }) => {
    let eventColor = null;
    const courseId = event?.course?.id;
    const hasCourseColor = this.coursesEventsColors[courseId];

    if (hasCourseColor) {
      eventColor = hasCourseColor;
    } else {
      this.eventsColorsCounters = Object.fromEntries(
        Object.entries(this.eventsColorsCounters).sort((a, b) => a[1] - b[1])
      );
      eventColor = Object.keys(this.eventsColorsCounters)[0];
      this.coursesEventsColors[courseId] = eventColor;
      this.eventsColorsCounters[eventColor] =
        this.eventsColorsCounters[eventColor] + 1;
    }

    return eventColor;
  };

  getDayEventsCount = eventDate => {
    const dayEvents = this.state.events.filter(
      date =>
        new Date(date.start).toDateString() ===
        new Date(eventDate).toDateString()
    );
    return dayEvents.length;
  };

  onSelectSlot = ({ start, end }) => {
    const { isModalOpen } = this.state;
    this.setState({
      modalProps: { start, end, size: 'md', cardClasses: 'q' },
      isModalOpen: !isModalOpen,
      listCourses: [...this.getAvailableListOfCourses()]
    });
  };

  getAvailableListOfCourses = () => {
    const { active: { items = [] } = { active: { items: [] } } } = this.props;
    if (!items) {
      return [];
    }
    return items.map(item => ({
      value: item.id,
      label: item.title,
      id: item.id
    }));
  };

  handleModalEditEvent = item => {
    this.setState({
      listCourses: [...this.getAvailableListOfCourses()],
      isModalInEditMode: true,
      isModalOpen: true,
      editEventItem: item,
      modalProps: {
        start: item.start,
        end: item.end,
        size: 'md',
        cardClasses: 'q'
      }
    });
  };

  hideModal = () => {
    this.setState({
      isModalOpen: false,
      listCourses: [],
      isModalInEditMode: false
    });
  };

  handleEventCreate = async (
    courseId,
    title,
    desc,
    time,
    date,
    eventDuration
  ) => {
    const { isModalOpen } = this.state;
    const { createCalendarEvent } = this.props;
    const fullDate = momentTimezone(`${date} ${time}`).toDate();
    try {
      await createCalendarEvent({
        CourseId: courseId,
        EventName: title,
        EventDescription: desc,
        EventStartUnixMs: momentTimezone(fullDate).valueOf(),
        EventEndUnixMs: momentTimezone(fullDate).valueOf() + eventDuration
      });
    } catch (error) {
      throw Error(error);
    }
    if (title) this.setState({ isModalOpen: !isModalOpen });
  };

  handleEditEvent = async ({
    courseId = null,
    title,
    description,
    startTime,
    startDate,
    eventDuration,
    idEvent
  }) => {
    const { isModalOpen } = this.state;
    const { updateCalendarEvent, profile } = this.props;
    const fullDateForCurrentUser = momentTimezone(
      `${startDate} ${startTime}`
    ).format();

    const actualStartDateTimestamp = momentTimezone
      .tz(fullDateForCurrentUser, profile.gmt_timezone)
      .valueOf();

    try {
      await updateCalendarEvent({
        Course: courseId || '',
        Id: idEvent,
        EventName: title,
        EventDescription: description,
        EventStartUnixMs: actualStartDateTimestamp,
        EventEndUnixMs: actualStartDateTimestamp + eventDuration
      });
    } catch (error) {
      throw Error(error);
    }
    if (title) this.setState({ isModalOpen: !isModalOpen });
  };

  handleDeleteEvent = async id => {
    const { deleteCalendarEvent } = this.props;
    try {
      await deleteCalendarEvent(id);
    } catch (error) {
      throw Error(error);
    }
    this.setState({
      isModalInEditMode: false,
      isModalOpen: false,
      editEventItem: {},
      modalProps: {}
    });
  };

  renderEventInToCalendar = ({ event }) => {
    const {
      modalContext: { showModal, hideModal },
      history,
      role,
      profile
    } = this.props;

    const joinWebinar = () => {
      const webinarLink = getWebinarLink(event, event?.course?.id, role);
      history.push(webinarLink);
      hideModal();
    };
    const showEventDetails = () =>
      showModal(EventDetailsModal, {
        size: 'md',
        event,
        close: hideModal,
        joinWebinar
      });
    const eventClassName = ['sk-calendar__event'];

    if (this.getDayEventsCount(event.start) === 1) {
      eventClassName.push('alone');
    }

    const attributes = {
      style: { background: this.getEventColor({ event }) },
      className: eventClassName.join(' '),
      onClick: showEventDetails
    };

    if (event?.author?.id === profile?.id) {
      delete attributes.onClick;
    }

    return (
      <div {...attributes}>
        <strong>{parseMomentTimeInAMPM(momentTimezone(event.start))}</strong>
        {` ${event.title}`}
      </div>
    );
  };

  renderModalContent = (
    isModalInEditMode,
    listCourses,
    courseId,
    editEventItem,
    injectedProps
  ) => {
    if (isModalInEditMode) {
      return (
        <EditEventModal
          listCourses={listCourses}
          courseId={courseId}
          editEvent={this.handleEditEvent}
          item={editEventItem}
          handleDeleteEvent={this.handleDeleteEvent}
          momentTimezone={momentTimezone}
          {...injectedProps}
        />
      );
    }
    return (
      <CreateEventModal
        listCourses={listCourses}
        selectCourse={courseId}
        saveEvent={this.handleEventCreate}
        momentTimezone={momentTimezone}
        {...injectedProps}
      />
    );
  };

  checkIsCalendarEditable = match =>
    match.url.includes('experts-cabinet') ||
    match.url.includes('admin/panel/calendar');

  onOpenCreateEventModal = () => {
    this.setState({
      isModalOpen: true,
      isModalInEditMode: false,
      modalProps: { size: 'md', cardClasses: 'q' },
      listCourses: [...this.getAvailableListOfCourses()]
    });
  };

  render() {
    const {
      isModalOpen,
      events,
      modalProps,
      isModalInEditMode,
      listCourses,
      editEventItem
    } = this.state;

    const { profile, match, active, role } = this.props;

    const courseId = editEventItem?.id;
    momentTimezone.tz.setDefault(profile.gmt_timezone);

    const isCaledarEditable = this.checkIsCalendarEditable(match);
    const activeCourses = active?.items || [];

    const params = [
      'expert-calendar',
      profile.gmt_timezone,
      2,
      {
        events,
        activeCourses
      }
    ];

    const nearestWebinars = listCalendarEventsThatStartInProvidedPeriodOfTime(
      ...params
    );
    const haveNearestWebinars = nearestWebinars.length > 0;

    return (
      <div className='sk-calendar'>
        <div className='sk-calendar__container'>
          <CalendarContentHeader
            isThereWebinars={haveNearestWebinars}
            onOpenCreateEventModal={this.onOpenCreateEventModal} // Pass the prop
          />
          {haveNearestWebinars ? (
            <div className='sk-calendar__nearest-webinar'>
              <NearestWebinarBlock webinars={nearestWebinars} role={role} />
            </div>
          ) : null}
          {isModalOpen && (
            <Modal
              component={injectedProps =>
                this.renderModalContent(
                  isModalInEditMode,
                  listCourses,
                  courseId,
                  editEventItem,
                  injectedProps
                )
              }
              open
              hideModal={this.hideModal}
              {...modalProps}
            />
          )}
          <TimeZoneAgnosticBigCalendar
            selectable
            timeZoneName={profile.gmt_timezone}
            events={events}
            startAccessor='start'
            endAccessor='end'
            onSelectSlot={isCaledarEditable ? this.onSelectSlot : null}
            onSelectEvent={
              isCaledarEditable ? e => this.handleModalEditEvent(e) : null
            }
            views={{ month: true, week: true, day: true }}
            components={{
              event: this.renderEventInToCalendar
            }}
          />
        </div>
      </div>
    );
  }
}

const listExpertCoursesQuery = graphql(LIST_EXPERT_COURSES, {
  skip: ({ match }) => !match.url.includes('experts-cabinet'),
  props: ({
    data: { expertListCourses, variables, refetch, loading, error, ...ownProps }
  }) => ({
    active: expertListCourses,
    query: variables?.input?.query || {},
    ...ownProps
  }),
  options: () => ({
    pollInterval: 600000,
    fetchPolicy: 'network-only',
    variables: {
      input: {
        status_id: 'ACTIVE',
        query: {
          filter: [{ key: 'type', values: ['course', 'topping', 'podcast'] }]
        }
      }
    }
  })
});

const createEventMutation = graphql(CREATE_EVENT_MUTATION, {
  props: ({ mutate }) => ({
    createCalendarEvent: input => mutate({ variables: { input } })
  }),
  options: ({ refetchQueries }) => ({
    refetchQueries
  })
});

const editEventMutation = graphql(EDIT_EVENT_MUTATION, {
  props: ({ mutate }) => ({
    updateCalendarEvent: input => mutate({ variables: { input } })
  }),
  options: ({ refetchQueries }) => ({
    refetchQueries
  })
});

const deleteEventMutation = graphql(DELETE_EVENT_MUTATION, {
  props: ({ mutate }) => ({
    deleteCalendarEvent: Id => mutate({ variables: { Id } })
  }),
  options: ({ refetchQueries }) => ({
    refetchQueries
  })
});

const myProfileQuery = graphql(MY_PROFILE_QUERY, {
  props: ({ data: { getProfile: profile, error, loading, ...ownProps } }) => ({
    loading,
    profile,
    ...ownProps
  }),
  options: () => ({
    fetchPolicy: 'cache-only'
  })
});

const enhancer = compose(
  withRouter,
  withModal,
  createEventMutation,
  deleteEventMutation,
  editEventMutation,
  myProfileQuery,
  listExpertCoursesQuery
);

export default enhancer(Calendar);
