import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose, graphql } from 'react-apollo';
import NoSsr from '@material-ui/core/NoSsr';

// Components
import Loader from 'components/Loader';
import DocumentIcon from 'components/Icon/Svg/Document';
import Play from 'components/Icon/Svg/Play';
import VideoPlay from 'components/Icon/Svg/VideoPlay';
import VideoModule from 'components/VideoModule';
import QuizPreviewModule from 'components/QuizPreview';
import QuizScore from 'components/QuizPreview/QuizScore';

// Other
import { White } from 'components/Icon/color';
import { withFileUrl } from 'containers/HOCs/withFileUrl';
import {
  LECTURE_VIDEO,
  LECTURE_ARTICLE,
} from 'containers/UploadManagerProvider/helpers';
import { PDF_FILE, TEXT_FILE } from 'utils/enums';
import { withQuizData } from 'components/QuizPreview/withQuizData';
import { isBlank } from 'utils/helpers';

// Styles
import './FilePreview.scss';
import 'react-pdf/dist/Page/AnnotationLayer.css';

let DraftEditor = () => <></>;

export const VideoPreview = withFileUrl(({ fileUrl, isLoaded, fileType, ...props }) => {
  if (!isLoaded) return <Loader />;

  return (
    <div className="sk-video-preview__view">
      <VideoModule
        playButton={() => (
          <div className="sk-video-preview__icon">
            <Play strokeColor={White} />
          </div>
        )}
        fileUrl={fileUrl}
        filetype={fileType}
      />
    </div>
  );
});

export const TextPreview = withFileUrl(({ fileUrl, isLoaded }) => {
  const [text, setText] = useState('');

  useEffect(() => {
    const fetchFileLink = async () => {
      if (isLoaded) {
        const file = await fetch(fileUrl);
        const text = await file.text();
        setText(text);
      }
    };
    fetchFileLink();
  }, [fileUrl, isLoaded]);

  if (!isLoaded) return <Loader />;

  return <div className="sk-text-preview">{text}</div>;
});

export const EditorPreview = withFileUrl(({ fileUrl, isLoaded }) => {
  const [text, setText] = useState('');
  const [isDraftEditorMoudleLoaded, setDraftEditorModuleState] = useState(
    false
  );

  useEffect(() => {
    const fetchFileLink = async () => {
      if (isLoaded) {
        const file = await fetch(fileUrl);
        const text = await file.text();
        setText(text);
      }
    };
    fetchFileLink();
  }, [fileUrl, isLoaded]);

  useEffect(() => {
    const fetchFileLink = async () => {
      if (!window.isServer) {
        const Module = await import('components/DraftEditor/Editor');
        DraftEditor = Module?.default;
        setDraftEditorModuleState(true);
      }
    };
    fetchFileLink();
  }, []);

  if (!isLoaded || !isDraftEditorMoudleLoaded) return <Loader />;

  return (
    <NoSsr>
      <div className="sk-editor-preview">
        <DraftEditor readOnly initialContent={text} />
      </div>
    </NoSsr>
  );
});

let DocumentComponent = () => <></>;
let PageComponent = () => <></>;

export const PdfPreview = withFileUrl(({ fileUrl, file, isLoaded }) => {
  const [numPages, setNumPages] = useState(1);
  const [isReady, setReady] = useState(false);
  const filePreviewWidth = document.getElementById('sk-modal-content')?.offsetWidth;

  const onDocumentLoadSuccess = document => {
    const { numPages } = document;
    setNumPages(numPages);
  };

  const handleError = errorType => error => {
    console.log(`An ${errorType} occured! See log below!`);
    console.error('error', error);
  };

  useEffect(() => {
    const fetchFileLink = async () => {
      if (!window.isServer) {
        const { Document, Page, pdfjs } = await import('react-pdf');
        DocumentComponent = Document;
        PageComponent = Page;
        pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
      }
      setReady(true);
    };
    fetchFileLink();
  }, []);

  if (!isLoaded || !isReady) return <Loader />;

  return (
    <NoSsr>
      <div className="sk-pdf-preview">
        <DocumentComponent
          renderAnnotationLayer={true}
          externalLinkTarget='_blank'
          file={fileUrl || file}
          className="sk-pdf-preview__document"
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={handleError('ERROR WHILE LOADING')}
          onSourceError={handleError('SOURCE ERROR')}
        >
          {Array.from(
            new Array(numPages),
            (el, index) => (
              <PageComponent
                renderAnnotations={true}
                className='sk-pdf-preview__document__page'
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                width={filePreviewWidth}
              />
            ),
          )}
        </DocumentComponent>
      </div>
    </NoSsr>
  );
});

export const QuizPreview = withQuizData(({ quiz, readOnly }) => {
  const [score, setScore] = useState(null);

  const isQuizCompleted = !isBlank(score);
  const completeQuiz = useCallback((questionsWithAnswers) => () => {
    if (questionsWithAnswers && questionsWithAnswers.length > 0) {
      const quizScore = {
        options: quiz?.questions?.length,
        match: 0,
        unmatch: 0,
      };

      for(const qid in questionsWithAnswers) {
        const question = questionsWithAnswers[qid];
        quiz.questions.map(quizQuestion => {
          if (question.questionId === quizQuestion.id) {
            let wrong = 0;
            let correct = 0;
            question.answers.map(answer => {
              quizQuestion.answers.map(quizAnswer => {
                if (quizAnswer.id === answer) {
                  quizAnswer.selected = true;
                  if(quizAnswer.correct === true) {
                    correct++;
                  } else {
                    wrong++;
                  }
                }
              });
            });
            if (wrong > 0) {
              correct = 0;
            }
            if (correct > 0) {
              correct = 1;
            }
            quizScore.match += correct;
          }
        });
      };

      quiz.score = Math.floor(
        quizScore.match/quizScore.options*100
      );
    };

    setScore(quiz?.score || null);
  }, [quiz?.questions, setScore]);

  return (
    <div>
      <QuizPreviewModule quiz={quiz} isQuizCompleted={isQuizCompleted} completeQuiz={completeQuiz} />
      {isQuizCompleted && (<QuizScore score={score} />)}
    </div>
  )
});

export const EmptyPreview = () => (
  <div className="">Sorry, but this file has no content!</div>
);
