// Core
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// Components
import Text from 'components/Text';
import CourseNearestWebinar from 'routes/ExpertsCabinet/CourseEdit/GeneralInfo/CourseNearestWebinar';
import Stepper from 'components/Stepper';

// Icons
import Check from 'components/Icon/Svg/Check';
import Cross from 'components/Icon/Svg/Cross';

// Steps Components
import Draft from 'routes/ExpertsCabinet/CourseEdit/GeneralInfo/StatusInfo/Steps/Step1';
import FirstReview from 'routes/ExpertsCabinet/CourseEdit/GeneralInfo/StatusInfo/Steps/Step2';
import MainFilling from 'routes/ExpertsCabinet/CourseEdit/GeneralInfo/StatusInfo/Steps/Step3';
import FinalReview from 'routes/ExpertsCabinet/CourseEdit/GeneralInfo/StatusInfo/Steps/Step4';
import ReadyToPublishing from 'routes/ExpertsCabinet/CourseEdit/GeneralInfo/StatusInfo/Steps/Step5';
import Active from 'routes/ExpertsCabinet/CourseEdit/GeneralInfo/StatusInfo/Steps/Active';
import Inactive from 'routes/ExpertsCabinet/CourseEdit/GeneralInfo/StatusInfo/Steps/Inactive';
import Rejected from 'routes/ExpertsCabinet/CourseEdit/GeneralInfo/StatusInfo/Steps/Rejected';

// Styles and Assets
import { White, LightGreen, Grey2, PurpleHeart } from 'components/Icon/color';
import './StatusInfo.scss';

// Other
import { FormattedMessage } from 'react-intl';
import { messages } from 'routes/ExpertsCabinet/CourseEdit/GeneralInfo/messages';

const statusesDetails = {
  'DRAFT': Draft,
  'FIRST_REVIEW': FirstReview,
  'MAIN_FILLING': MainFilling,
  'FINAL_REVIEW': FinalReview,
  'READY_TO_PUBLISHING': ReadyToPublishing,
  'ACTIVE': Active,
  'DEACTIVATED': Inactive,
  'FIRST_REJECT': Rejected,
  'SECOND_REJECT': Rejected,
}

const creating = ['DRAFT', 'FIRST_REVIEW', 'MAIN_FILLING', 'FINAL_REVIEW', 'READY_TO_PUBLISHING'];
const rejecting = ['DRAFT', 'FIRST_REVIEW', 'FIRST_REJECT', 'FINAL_REVIEW', 'SECOND_REJECT'];
const active = ['ACTIVE'];
const inactive = ['DEACTIVATED'];

class StatusInfo extends PureComponent {
  state = {
    currentCourseStage: null,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let currentCourseStage = null;

    if (creating.includes(nextProps.status)) {
      currentCourseStage = 'creating';
    } else if (active.includes(nextProps.status)) {
      currentCourseStage = 'active';
    } else if (inactive.includes(nextProps.status)) {
      currentCourseStage = 'inactive';
    } else if (rejecting.includes(nextProps.status)) {
      currentCourseStage = 'rejecting';
    }

    if (prevState.currentCourseStage === currentCourseStage) return null;
    return { currentCourseStage };
  }

  getStepperSteps = status => {
    const { currentCourseStage } = this.state;
    let steps = [];

    if (currentCourseStage === 'creating') {
      const currentStatusIndex = creating.indexOf(status);

      steps = creating.map((elm, index) => {
        const id = index + 1;
        const isStatusPassed = index < currentStatusIndex;
        const icon = isStatusPassed ? (<Check stroke={LightGreen} />) : id;

        return { id, icon, }
      });
    } else if (currentCourseStage === 'active') {
      steps = active.map((elm, index) => ({ id: index + 1, icon: (<Check width={16} height={16} stroke={White} />), background: LightGreen, }));
    } else if (currentCourseStage === 'inactive') {
      steps = inactive.map((elm, index) => ({ id: index + 1, icon: (<Cross width={18} height={18} color={White} strokeWidth={3.5} />), background: Grey2 }));
    } else if (currentCourseStage === 'rejecting') {
      steps = rejecting.map((elm, index) => {
        const id = index + 1;
        const rejectedStepIndex = rejecting.indexOf(status);

        const isRejectedStep = rejectedStepIndex === index;
        const isCompletedStep = rejectedStepIndex > index;
        const isUncompletedStep = rejectedStepIndex < index;

        let icon = null;
        let background = null;

        if (isRejectedStep) {
          icon = (<Cross width={18} height={18} color={White} strokeWidth={3.5} />);
          background = PurpleHeart;
        } else if (isCompletedStep) {
          icon = (<Check width={16} height={16} stroke={LightGreen} />);
          // background = LightGreen;
        } else if (isUncompletedStep) {
          icon = id;
        }

        return { id, icon, background, };
      })
    }

    return steps;
  }

  getCurrentStep = status => {
    const { currentCourseStage } = this.state;
    let currentStep = null;

    if (currentCourseStage === 'creating') {
      currentStep = creating.indexOf(status) + 1;
    } else if (currentCourseStage === 'active' || currentCourseStage === 'inactive') {
      currentStep = 1;
    } else if (currentCourseStage === 'rejecting') {
      currentStep = rejecting.indexOf(status) + 1;
    }

    return currentStep;
  }

  render() {
    const { status, course, } = this.props;
    const CurrentStatus = statusesDetails[status];

    return (
      <div className="sk-course-status-info">
        <div className="sk-course-status-info__row">
          <div className="sk-student-hw__stepper">
            <Stepper
              steps={this.getStepperSteps(status)}
              activeStep={this.getCurrentStep(status)}
              disabled={false}
            />
          </div>
          <Text size="caption" classes="sk-course-status-info__title">
            <FormattedMessage {...messages.courseStatus} />
          </Text>
          <Text classes="sk-course-status-info__desc">
            <CurrentStatus />
          </Text>
        </div>

        {
          status === 'ACTIVE' ? <CourseNearestWebinar course={course} /> : null
        }
      </div>
    );
  }
}

StatusInfo.propTypes = {
  status: PropTypes.string,
  course: PropTypes.object,
};

export default StatusInfo;
