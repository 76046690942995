// Core
import React, { PureComponent } from 'react';

// Components
import TextInput from 'components/Input/Material/TextInput/TextInput';
import TextAreaInput from 'components/Input/Material/TextAreaInput/TextAreaInput';

// Styles and Assets
import './CategoriesInfo.scss';

const CategoriesInfo = (props) => {
  const { values, touched, errors, handleInputBlur, handleInputChange } = props;

  return (
    <div className='sk-admin-discount-info'>
      <div className='sk-admin-discount-info__input'>
        <TextInput
          helperText={touched.titla ? errors.titla : ''}
          error={touched.titla && Boolean(errors.titla)}
          placeholder="Localization Key"
          value={values.title}
          name="title"
          label="Localization Key"
          onBlur={handleInputBlur('title')}
          onChange={handleInputChange('title')}
          disabled
        />
      </div>
      <div className='sk-admin-discount-info__input'>
        <TextInput
          helperText={touched.localized ? errors.localized : ''}
          error={touched.localized && Boolean(errors.localized)}
          placeholder="Localized"
          value={values.localized}
          name="localized"
          label="Localized"
          onBlur={handleInputBlur('localized')}
          onChange={handleInputChange('localized')}
        />
      </div>
    </div>
  )
}

export default CategoriesInfo;
