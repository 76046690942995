import React from 'react';
import PropTypes from 'prop-types';
import { PurpleHeart } from 'components/Icon/color';

const SendMessage = ({ strokeColor, height, width }) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.864.315a.78.78 0 0 0-.079-.093l-.003-.004-.004-.003a.88.88 0 0 0-.634-.242c-.145
       0-.3.027-.462.08L.785 4.02c-.63.21-.744.576-.756.776-.013.2.056.577.654.863l2.988 1.427-1.337
        3.301a.334.334 0 0 0-.009.024c-.149.446-.089.847.164 1.1a.88.88 0 0 0 .64.244 1.461 1.461 
        0 0 0 .484-.089l3.301-1.337 1.427 2.987c.273.571.632.657.822.657.17 0
         .59-.074.817-.758l3.966-11.898c.13-.391.102-.747-.082-1.002zM.916 4.851a.954.954 0 0 1
          .128-.053l11.367-3.789-6.413 6.413a3.452 3.452 0 0 0-.71-.473l-4.251-2.03a.966.966 0 0
           1-.121-.068zm2.402 6.05c-.142.045-.222.037-.247.028-.009-.025-.017-.106.029-.247L4.413 
           7.44l.521.249c.497.237 1.14.88 1.377 1.377l.25.521L3.317 10.9zm5.884 2.055a.948.948 0 0 
           1-.053.128.935.935 0 0 1-.068-.121l-2.03-4.25a3.455 3.455 0 0 0-.473-.711l6.413-6.413-3.789 11.367z"
      fill={strokeColor}
    />
  </svg>
);

SendMessage.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  strokeColor: PropTypes.string,
};

SendMessage.defaultProps = {
  strokeColor: PurpleHeart,
  height: '14',
  width: '14',
};

export default SendMessage;
