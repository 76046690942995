// Core
import React, { memo, useState, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { compose, graphql } from 'react-apollo';
import { injectIntl } from 'react-intl';
import Collapse from '@material-ui/core/Collapse';
import moment from 'moment';

// Components
import Tabs from './Tabs';
import Button from 'components/Button';
import Card from 'components/Card';
import Text from 'components/Text';
import ContentHeader from 'containers/ContentHeader';
import Loader from 'components/Loader';

import StudentsTable from 'routes/ManageAccount/ExpertSales/StudentsTable';
import EarningsTable from 'routes/ManageAccount/ExpertSales/EarningsTable';
import DurationRangeFilter from 'routes/ManageAccount/ExpertSales/DurationRangeFilter';
import EmptyCourseList from 'routes/ManageAccount/ExpertSales/EmptyCourseList';

// GraphQl
import LIST_EXPERT_STATS from 'queries/UserProfile/listExpertStats.gql';

// Other
import { parseFetchedData } from 'utils/helpers';
import { capitalize, isNull } from 'utils/helpers/index';
import { sharedMessages } from 'services/i18n/sharedMessages/messages';

// Styles and Assets
import './ExpertSales.scss';

const ExpertSales = ({
  loading, intl: { formatMessage }, listExpertStats, refetchExpertAnalytics
}) => {
  const query = (!isNull(listExpertStats) && !!listExpertStats) ? listExpertStats.query : { type: 'students', filter: [] };
  const activeTab = (!isNull(listExpertStats) && !!listExpertStats && listExpertStats.type) || 'students';

  const handleSearch = async ({ startTime, endTime, }) => {
    const queryCopy = Object.assign({}, query);
    const durationFilter = queryCopy.filter.find(filter => filter.key === 'time_range') || [];

    durationFilter.values[0] = startTime;
    durationFilter.values[1] = endTime;

    await refetchExpertAnalytics({ input: { type: activeTab, query: queryCopy }})
  };

  const handleSwitchTab = useCallback(async nextTab => {
     await refetchExpertAnalytics({ input: { type: nextTab, query }})
  }, [refetchExpertAnalytics]);

  return (
    <div className="sk-expert-analytics-sales">
      <div className="sk-expert-analytics-sales__header">
          <ContentHeader />
      </div>
      {(!isNull(listExpertStats) && listExpertStats) ? (
        <>
          {loading ? (<Loader />) : (
            <div className="sk-expert-analytics-sales__query">
              <DurationRangeFilter query={query} handleSearch={handleSearch} />
            </div>
          )}
          <Card classes="sk-expert-analytics-sales__tabs">
            <Tabs
              handleSwitch={handleSwitchTab}
              activeTab={activeTab}
            />
              <Collapse
                classes={{ wrapperInner: 'sk-expert-analytics-sales__wrapperInner' }}
                in={activeTab === 'students'}
                timeout="auto"
                unmountOnExit
              >
                <StudentsTable loading={loading} courses={listExpertStats?.courses} total={listExpertStats?.total} />
              </Collapse>

              <Collapse
                classes={{ wrapperInner: 'sk-expert-analytics-sales__wrapperInner' }}
                in={activeTab === 'earnings'}
                timeout="auto"
                unmountOnExit
              >
                <EarningsTable loading={loading} courses={listExpertStats?.courses} total={listExpertStats?.total} />
              </Collapse>
          </Card>
        </>
      ) : (
        <EmptyCourseList />
      )}
    </div>
  );
}

const listExpertStats = graphql(LIST_EXPERT_STATS, {
  props: ({ data: { listExpertStats, error, loading, refetch, ...ownProps }}) => {

    if (loading) return { loading, listExpertStats: {} };
    if (error) throw new Error('Something went wrong while receiving LIST_EXPERT_STATS');

    return {
      loading,
      error,
      listExpertStats,
      refetchExpertAnalytics: refetch,
      ...ownProps,
  }},
  options: () => {
    const dayBeginTimestamp = moment().startOf('month').unix();
    const dayEndTimestamp = moment().endOf('month').unix();

    const filter =  [{
      key: 'time_range',
      values: [dayBeginTimestamp, dayEndTimestamp],
    }];

    return ({
      fetchPolicy: 'cache-and-network',
      variables: {
        input: {
          type: 'students',
          query: { filter }
        },
      },
    })
  }
});

const enhancer = compose(
  memo,
  injectIntl,
  withRouter,
  listExpertStats,
);

export default enhancer(ExpertSales);
