// Core
import React, { useState, useEffect, useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';

// Components
import SuccesfulSubscriptionPayment from 'routes/Checkout/components/SuccesfulSubscriptionPayment';
import FailedMessage from 'routes/Checkout/components/FailedMessage';

// GraphQL
import SUBMIT_SUBSCRIPTION from 'services/graphql/mutations/Subscriptions/submitSubscription.gql';

// Other
import { getYupSchema, } from 'services/yup';
import gaTracking from 'services/ga';
import { isBlank, keysToCamel } from 'utils/helpers';

export function useSubscription({
  currency,
  subscription,
  discountCode,
  modalContext,
  history,
  ...props
}) {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('initial');
  const [submitSubscriptionMutation, response] = useMutation(SUBMIT_SUBSCRIPTION, { options: { fetchPolicy: 'no-cache', refetchQueries: ['getDraftOrderByUserId'], } });

  const handleSuccesfulPayment = useCallback(paymentProvider => async paymentOrderID => {
    setLoading(true);
    if (!paymentOrderID || !paymentProvider) {
      setLoading(false);
      return;
    }
    const { showModal, hideModal } = modalContext;

    try {
      const { data: { submitSubscription: submittedSubscription }} = await submitSubscriptionMutation({ variables: { input: {
        currency,
        // discount: discountCode,
        id: subscription?.id,
        token: paymentOrderID,
      }}});

      const handleModalClick = () => {
        hideModal();
        history.push('/experts-cabinet/subscription');
      };

      // Breakdown courses and submit events separately
      // if (orderItems && orderItems.length > 0) {
      //   orderItems.forEach(orderItem => {
      //     gaTracking.trackEvent({
      //       category: 'course',
      //       action: 'purchase',
      //       label: orderItem.course_id,
      //       value: orderItem.price_with_discount || orderItem.price
      //     });
      //   })
      // };

      if (!isBlank(submittedSubscription)) {
        showModal(SuccesfulSubscriptionPayment, {
          subscription: keysToCamel(submittedSubscription),
          handleClick: handleModalClick,
        });
      } else {
        showModal(FailedMessage, {
          message: 'No subscription submitted',
        });
      };
      setLoading(false);
    } catch (error) {
      showModal(FailedMessage, {
        message: error.message,
      });
      setLoading(false);
    }
  }, [submitSubscriptionMutation, modalContext.showModal, modalContext.hideModal, history.push, setLoading]);
  return  { loading, status, handleSuccesfulPayment };
}
