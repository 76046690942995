// Core
import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl";

import { messages } from "routes/Authenticator/AuthTabs/messages";

// Components
import Tabs from "components/Tabs";

class AuthTabs extends PureComponent {
  handleChange = (e, value) => {
    const { handleSwitch } = this.props;
    handleSwitch(value);
  };

  render() {
    const { tabs, activeTab, isOrg } = this.props;
    return (
      <Tabs
        tabs={tabs.filter(t => !(t.value === "signUp" && isOrg))}
        value={activeTab}
        handleChange={this.handleChange}
      />
    );
  }
}

AuthTabs.propTypes = {
  tabs: PropTypes.array,
  handleSwitch: PropTypes.func,
  activeTab: PropTypes.string,
  isOrg: PropTypes.bool,
};

AuthTabs.defaultProps = {
  tabs: [
    { label: <FormattedMessage {...messages.signUp} />, value: "signUp" },
    { label: <FormattedMessage {...messages.logIn} />, value: "signIn" },
  ],
};

export default AuthTabs;
