import React, { useCallback } from 'react'
import './index.scss'
const SliderDots = ({items = [], currentSlide = 0, slideTo}) => {
  const slideToItem = useCallback(
    (i) => {
      slideTo(i)
    },
    [slideTo],
  )
  const buttons = items.map((item = {}, i) => (
    <div
      key={item.title + i}
      className={`slider_dot ${i === currentSlide ? 'active' : ''}`}
      onClick={() => slideToItem(i)}
    />
  ));

  return <div className="slider-dots">{buttons}</div>;
};

export default SliderDots