import React from 'react';
import { ModalConsumer } from 'containers/ModalProvider/ModalConsumer';

export function withModal(Component) {
  return function ModalComponent(props) {
    return (
      <ModalConsumer>
        {contexts => <Component {...props} {...contexts} />}
      </ModalConsumer>
    );
  };
}
