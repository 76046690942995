import React from 'react';
import PropTypes from 'prop-types';

import ChevronDown from 'components/Icon/Svg/ChevronDown/ChevronDown';
import { OrangeyRed, DarkGrey, Grey3 } from 'components/Icon/color';

const iconStyle = {
  position: 'absolute',
  right: 0,
  top: '50%',
  transform: 'translateY(-50%)',
  pointerEvents: 'none',
  height: 8.91,
  width: 14,
};

const getTogglerColor = theme => {
  switch (theme) {
    case 'light':
      return OrangeyRed;
    case 'dark':
      return Grey3;
    default:
      return DarkGrey;
  }
};

const Toggler = ({ style, color, theme, disabled, ...props }) => (
  <ChevronDown
    iconStyle={{ ...iconStyle, ...style }}
    {...props}
    color={color || getTogglerColor(disabled ? 'dark' : theme)}
  />
);

Toggler.defaultProps = {
  theme: 'dark',
  style: {},
};

Toggler.propTypes = {
  theme: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  style: PropTypes.object,
};

export default Toggler;
