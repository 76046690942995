// Core
import React, { memo, useEffect, useState, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { compose, graphql } from 'react-apollo';

// Components
import TextInput from 'components/Input/Material/TextInput/TextInput';
import Text from 'components/Text';
import Button from 'components/Button';
import ContentHeader from 'containers/ContentHeader';
import { withNotification } from 'containers/NotificationProvider/withNotification';

// GraphQl
import LIST_CURRENCIES_QUERY from 'containers/CurrencyProvider/query/listCurrencies.gql';
import UPDATE_CURRENCY_RATE from 'mutations/Courses/updateCurrencyRate.gql';

// Styles and Assets
import './CurrencyRates.scss';

const getCurrency = (currencies, currencyId) => {
  const currency = currencies?.find(currency => currency.currency_id === currencyId);
  return currency?.rate || 0;
};

const CurrencyRates = ({
  currencies,
  updateCurrencyRate,
  showNotificationBar,
  ...props
}) => {
  const [state, setState] = useState({
    eur: getCurrency(currencies, 'eur'),
    rub: getCurrency(currencies, 'rub'),
    uah: getCurrency(currencies, 'uah'),
    gbp: getCurrency(currencies, 'gbp'),
  });

  useEffect(() => {
    setState({
      eur: getCurrency(currencies, 'eur'),
      rub: getCurrency(currencies, 'rub'),
      uah: getCurrency(currencies, 'uah'),
      gbp: getCurrency(currencies, 'gbp'),
    })
  }, [currencies]);


  const handleInputChange = useCallback(name => inputValue => {
    const newState = { ...state, [name]: inputValue };
    setState(newState);
  }, [state]);

  const updCurrency = useCallback(async () => {
    const { eur, rub, uah, gbp } = state;

    const obj = {
      user_role: 'admin',
      eur: +eur,
      rub: +rub,
      uah: +uah,
      gbp: +gbp,
    };

    try {
      await updateCurrencyRate(obj);
      showNotificationBar('saved');
    } catch (e) {
      showNotificationBar('failed');
    }
  }, [state]);

  return (
    <>
      <div className="sk-currencyRates__contentHeader">
        <ContentHeader className="sk-currencyRates" />
        <Text size="small" className="sk-currencyRates__panelName">
          Currency rates
        </Text>
      </div>
      <div className="sk-form__inner  sk-auth-content_inner">
        <div className="sk-form__row">
          <TextInput
            type="number"
            name="eur"
            value={state.eur}
            label="EURO"
            placeholder="EURO"
            onChange={handleInputChange('eur')}
          />
        </div>
        <div className="sk-form__row">
          <TextInput
            type="number"
            name="gbp"
            value={state.gbp}
            label="GBP"
            placeholder="GBP"
            onChange={handleInputChange('gbp')}
          />
        </div>
        <div className="sk-form__row">
          <TextInput
            type="number"
            name="uah"
            value={state.uah}
            label="UAH"
            placeholder="UAH"
            onChange={handleInputChange('uah')}
          />
        </div>
      </div>

      <div className="sk-currencyRates__update-currency">
        <Button
          variant="text"
          color="secondary"
          size="large"
          onClick={updCurrency}
        >
          Update
        </Button>
      </div>
    </>
  );
}

const updateCurrencyRateMutation = graphql(UPDATE_CURRENCY_RATE, {
  props: ({ mutate }) => ({
    updateCurrencyRate: input => mutate({ variables: { input } }),
  }),
  options: () => ({
    refetchQueries: ['getCourse'],
  })
});

const listCurerncies = graphql(LIST_CURRENCIES_QUERY, {
  props: ({
    data: { loading, error: fetchError, listCurrencies, ...ownProps },
  }) => ({
    loading,
    currencies: listCurrencies && listCurrencies.items,
    fetchError,
    ...ownProps,
  }),
  options: () => ({
    fetchPolicy: 'cache-and-network',
  }),
});

const enhancer = compose(
  memo,
  withRouter,
  updateCurrencyRateMutation,
  listCurerncies,
  withNotification
);

export default enhancer(CurrencyRates);
