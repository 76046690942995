// Core
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// Components
import SimpleSelect from 'components/Select/SimpleSelect';
import MinorFilterInput from 'components/FilterDropDown/MinorFilterInput';
import MajorFilterInput from 'components/FilterDropDown/MajorFilterInput';

// Components
import FilterChip from './FilterChip';
import Toggler from 'components/Select/Toggler';

// Styles and Assets
import './FilterDropDown.scss';

class FilterDropDown extends PureComponent {
  getExtraSelectClasses = (value, minor) => ({
    root: `mui-override ${!value && 'sk-filter-select__empty'}`,
    selectMenu: `mui-override sk-filter sk-filter-select_menu__${minor} sk-materialized-select ${!value &&
      `sk-materialized-select_${minor}__empty`}`,
    icon: `mui-override  ${
      value ? 'sk-filter-select-icon__fullfield' : 'sk-filter-select-icon'
    }`,
  });

  handleDelete = () => {
    const { handleChange } = this.props;
    handleChange('');
  };

  renderValue = (value, minor, options, valueKey, labelKey) => selected => {
    const currentOption = options?.find(item => selected === item[valueKey]);
    const label = currentOption ? currentOption[labelKey] : '';

    return minor ? (
      <FilterChip
        selected={label}
        value={label}
        handleDelete={this.handleDelete}
      />
    ) : (
      <p> {label} </p>
    );
  };

  renderIconComponent = (minor, value) => {
    const iconStyle = {
      position: 'absolute',
      right: '10px',
      top: '14px',
      pointerEvents: 'none',
      height: '10px',
      width: '14px',
    }

    switch (minor) {
      case true:
        return value ? null : () => <Toggler theme={!value ? 'light' : ''} iconStyle={iconStyle} />;
      default:
        return () => <Toggler theme={!value ? 'dark' : ''} iconStyle={iconStyle} />;
    }
  };

  render() {
    const {
      minor,
      value,
      handleChange,
      options,
      valueKey,
      labelKey,
      ...rest
    } = this.props;
    const FilterInput = minor ? MinorFilterInput : MajorFilterInput;

    return (
      <SimpleSelect
        input={<FilterInput value={value} />}
        variant="filled"
        fullWidth={!(value && minor)}
        extraSelectClasses={this.getExtraSelectClasses(
          value,
          minor ? 'minor' : 'major'
        )}
        displayEmpty
        iconComponent={this.renderIconComponent(minor, value)}
        value={value}
        options={options}
        valueKey={valueKey}
        labelKey={labelKey}
        {...rest}
        renderValue={this.renderValue(
          value,
          minor,
          options,
          valueKey,
          labelKey
        )}
        handleChange={handleChange}
      />
    );
  }
}

FilterDropDown.defaultProps = {
  minor: false,
};

FilterDropDown.propTypes = {
  minor: PropTypes.bool,
};

export default FilterDropDown;
