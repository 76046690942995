import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  pleaseReadAgree: {
    id: 'app.components.Onboarding.pleaseReadAgree',
    defaultMessage:
      'Please read and agree to these Terms and Conditions to proceed with the service',
  },
  ihaveReadAgree: {
    id: 'app.components.Onboarding.ihaveReadAgree',
    defaultMessage: 'I have read and agree to these terms and conditions',
  },
  startTeaching: {
    id: 'app.components.Onboarding.startTeaching',
    defaultMessage: 'Start Teaching',
  },
});
