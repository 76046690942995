// Core
import React, { memo } from 'react';
import PropTypes from 'prop-types';

// Components
import PasswordInput from 'components/Input/Material/PasswordInput';
import Button from 'components/Button';

// Styles and Assets
import './ChangePasswordForm.scss';

import { FormattedMessage } from 'react-intl';
import { messages } from 'services/yup/messages';

export const ChangePasswordForm = ({
  values: { oldPassword, password, confirmPassword },
  errors,
  touched,
  handleSubmit,
  handleBlur,
  setFieldValue,
  disabled=false
}) => {
  const handleInputChange = name => (inputValue, e) => {
    setFieldValue(name, inputValue);
    if (e) e.persist();
  };

  const handleInputBlur = name => (inputValue, e) => {
    if (e) {
      e.persist();
      handleBlur(e);
    }
    setFieldValue(name, inputValue);
  };

  return (
    <form
      className="sk-ma-pw-change-form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <div className="sk-ma-pw-change-form_inner">
        <div className="sk-ma-pw-change-form_row">
          <FormattedMessage {...messages.oldPassword}>
            {msg => (
              <PasswordInput
                fullWidth
                name="oldPassword"
                helperText={touched.oldPassword ? errors.oldPassword : ''}
                error={touched.oldPassword && Boolean(errors.oldPassword)}
                value={oldPassword}
                label={msg}
                autoComplete="current-password"
                disabled={disabled}
                onChange={handleInputChange('oldPassword')}
                onBlur={handleInputBlur('oldPassword')}
              />
            )}
          </FormattedMessage>
        </div>

        <div className="sk-ma-pw-change-form_row sk-form__row">
          <FormattedMessage {...messages.newPassword}>
            {msg => (
              <PasswordInput
                fullWidth
                name="password"
                helperText={touched.password ? errors.password : ''}
                error={touched.password && Boolean(errors.password)}
                value={password}
                label={msg}
                autoComplete="new-password"
                disabled={disabled}
                onChange={handleInputChange('password')}
                onBlur={handleInputBlur('password')}
              />
            )}
          </FormattedMessage>
          <FormattedMessage {...messages.reEnterPw}>
            {msg => (
              <PasswordInput
                fullWidth
                name="confirmPassword"
                helperText={
                  touched.confirmPassword ? errors.confirmPassword : ''
                }
                error={
                  touched.confirmPassword && Boolean(errors.confirmPassword)
                }
                value={confirmPassword}
                label={msg}
                autoComplete="new-password"
                disabled={disabled}
                onChange={handleInputChange('confirmPassword')}
                onBlur={handleInputBlur('confirmPassword')}
              />
            )}
          </FormattedMessage>
        </div>
      </div>
      <div className="sk-ma-pw-change-form_footer">
        <Button variant="text" color="secondary" size="large" type="submit" disabled={disabled}>
          <FormattedMessage {...messages.save} />
        </Button>
      </div>
    </form>
  );
};

ChangePasswordForm.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  handleSubmit: PropTypes.func,
  handleBlur: PropTypes.func,
  setFieldValue: PropTypes.func,
};

export default memo(ChangePasswordForm);
