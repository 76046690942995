import React from 'react';
import { CurrencyContext } from './currency-context';

// Other
import { addSpacesBetweenEveryGroupOfThreeNumbers } from 'utils/helpers/index';

export function withConvertedPrice(Component) {
  return function HOC({ disabled = false, price, isBeautyPrice = true, ...props}) {
    return (
      <CurrencyContext.Consumer>
        {({ currencyContext: {
            changeDependOnCurrentCurrencyRate,
            getCurrentCurrencySign,
          }}) => {
          const priceDependOnRate = (!disabled && (price || price === 0) && changeDependOnCurrentCurrencyRate(price)) || '-';
          const signDependOnCurrentCurrency = getCurrentCurrencySign();
          const beautyPrice = isBeautyPrice ? addSpacesBetweenEveryGroupOfThreeNumbers(priceDependOnRate) : priceDependOnRate;

          const parsedPrice = `${signDependOnCurrentCurrency} ${beautyPrice}`;

          return (
            <Component {...props} disabled={disabled} price={parsedPrice} />
          )
        }}
      </CurrencyContext.Consumer>
    );
  };
}
