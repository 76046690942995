// Core
import React from 'react';
import PropTypes from 'prop-types';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import debounce from 'lodash.debounce';

// Components
import Toggler from 'components/Toggler';
import Burger from 'components/Icon/SvgCss/Burger';
import Cross from 'components/Icon/SvgCss/Cross';

// Styles and Assets
import './DropDown.scss';

const paperClasses = {
  root: 'mui-override paper paper-shadow user-dropdown',
};

class DropDown extends React.Component {
  state = {
    open: false,
  };

  handleToggle = debounce(() => {
    this.setState(state => ({ open: !state.open }));
  }, 150);

  handleMenuItemClick = event => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }

    this.setState({ open: false });
  };

  closeToggle = (e) => {
    if (this.anchorEl.contains(e.target)) {
      return;
    }
    this.setState({ open: false });
  };

  render() {
    const {
      children,
      component: MenuComponent,
      withToggler,
      disablePortal,
      togglerProps,
      placement,
      ...rest
    } = this.props;
    const { open } = this.state;

    const icon = open ? <Cross color="#D7B6FF" strokeWidth={2} /> : <Burger />;


    return (
      <div className="mui-override sk-dropdown">
        <button
          className="mui-override sk-dropdown-toggler"
          type="button"
          ref={node => {
            this.anchorEl = node;
          }}
          aria-owns={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={this.handleToggle}
        >
          {withToggler ? icon : children}
        </button>
        <Popper
          open={open}
          anchorEl={this.anchorEl}
          transition
          disablePortal={disablePortal}
          placement={placement}
        >
          {({ TransitionProps, placement: growPlacement }) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{
                transformOrigin:
                  growPlacement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <ClickAwayListener onClickAway={this.closeToggle}>
                <Paper classes={paperClasses}>
                  <MenuComponent
                    handleMenuItemClick={this.handleMenuItemClick}
                    handleMenuToggle={this.handleToggle}
                    closeToggle={this.closeToggle}
                    {...rest}
                  />
                </Paper>
              </ClickAwayListener>
            </Grow>
          )}
        </Popper>
      </div>
    );
  }
}

DropDown.defaultProps = {
  placement: 'bottom-start',
  disablePortal: false,
};

DropDown.propTypes = {
  disablePortal: PropTypes.bool,
  placement: PropTypes.string,
};

export default DropDown;
