import React from 'react';
import PropTypes from 'prop-types';
import { DarkGrey } from 'components/Icon/color';

const SingOut = ({ height, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 11 13"
    width={width}
    height={height}
  >
    <path
      fill={DarkGrey}
      d="M5.123 11.407H2.13c-.813 0-1.473-.699-1.473-1.554V2.247c0-.858.663-1.554 
    1.473-1.554h3.041A.337.337 0 0 0 5.5.347.337.337 0 0 0 5.171 0h-3.04C.953 0 0 1.01 
    0 2.247v7.606c0 1.24.957 2.247 2.13 2.247h2.993a.337.337 0 0 0 .328-.347.338.338 0 0 0-.328-.346z"
    />
    <path
      fill={DarkGrey}
      d="M10.895 5.784L8.631 3.41a.344.344 0 0 0-.504 0 .385.385 0 0 0 0 .528l1.657 
    1.738H2.556a.364.364 0 0 0-.356.374c0 .207.158.373.356.373h7.228L8.127 8.161a.385.385 
    0 0 0 0 .528.34.34 0 0 0 .501 0l2.265-2.374a.386.386 0 0 0 .002-.531z"
    />
  </svg>
);

SingOut.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

SingOut.defaultProps = {
  height: '13',
  width: '11',
};

export default SingOut;
