// Core
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Styles
import './Heading.scss';

const Heading = ({ variant, onClick, children, bold, newLine, classes }) => {
  const Tag = variant;
  return (
    <Tag
      onClick={onClick}
      className={classNames(
        `heading-sk heading-sk__${variant}`,
        {
          'heading-sk heading-sk__bold': bold,
          'heading-sk heading-sk_new-line': newLine,
        },
        { [classes]: classes }
      )}
    >
      {children}
    </Tag>
  );
};

Heading.defaultProps = {
  variant: 'h1',
};

Heading.propTypes = {
  variant: PropTypes.string,
  children: PropTypes.any,
  classes: PropTypes.string,
  bold: PropTypes.bool,
  newLine: PropTypes.bool,
};

export default Heading;
