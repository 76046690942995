// Core
import React, { memo } from 'react';

// Components
import IconWithModal from 'components/IconWithModal';

// Icons
import Pencil from 'components/Icon/SvgCss/Pencil';
import GarbageBucket from 'components/Icon/SvgCss/GarbageBucket';

// Other
import { PurpleDark } from 'components/Icon/color';

// Styles and Assets
import './SummaryActionBlock.scss';

const SummaryActionBlock = memo(
  ({ handleLectureDeleteClick, handleLectureEditClick, disableDeletion = false }) => {
    const handleInputEvents = handler => event => {
      event.stopPropagation();
      return handler(event);
    };

    const GarbageBucketIcon = () => (<GarbageBucket color={PurpleDark} />)
    const PencilIcon = () => (<Pencil color={PurpleDark} />)

    return (
      <div className="sk-summary-action-block">
        {!disableDeletion && (
          <IconWithModal
            customModalHandler={handleInputEvents(handleLectureDeleteClick)}
            icon={GarbageBucketIcon}
          />
        )}
        <IconWithModal
          customModalHandler={handleInputEvents(handleLectureEditClick)}
          icon={PencilIcon}
        />
      </div>
    );
  }
);

export default SummaryActionBlock;
