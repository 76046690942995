import { InMemoryCache, defaultDataIdFromObject } from 'apollo-cache-inmemory';

export const createCache = () =>
  new InMemoryCache({
    dataIdFromObject: object => {
      // eslint-disable-next-line no-underscore-dangle
      const typename = object.__typename;

      switch (typename) {
        case 'expertListCoursesByStatusType':
          return object.status; // use `key` as the primary key
        case 'CalendarEvent':
          return object.Id; // use `key` as the primary key
        default:
          return defaultDataIdFromObject(object); // fall back to default handling
      }
    },
  // eslint-disable-next-line no-underscore-dangle
  }).restore(window.__APOLLO_STATE__ || {});
