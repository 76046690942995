// Core
import React, { memo, useState } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { compose, graphql } from 'react-apollo';

// Components
import ExpandableDropDown from 'components/DropDown/ExpandableDropDown';
import HeaderCatalogExpandableMenu from 'containers/Header/CatalogBlock/HeaderCatalogExpandableMenu';
import Toggler from 'components/Toggler';
import SearchLoop from 'components/Icon/Svg/SearchLoop';

// GraphQl
import LIST_CATALOG from 'queries/Courses/listCatalog.gql';
import GET_SUBCATEGORIES_WITH_COURSES_LIST from 'queries/Courses/getSubcategoriesWithCoursesList.gql';

// Other
import { getCatalogItems } from 'utils/reducers';
import { messages } from './messages';
import { CATALOG_AMOUNT } from 'utils/enums';
import { White } from 'components/Icon/color';

import './CatalogBlock.scss';

const CatalogBlock = ({
  categories,
  subcategories,
  loading,
  fetchError,
  subcategoriesWithCourses,
  intl: { formatMessage },
  inputPlaceholder,
  org,
}) => {
  if (loading || fetchError) return null;

  const [isShow, setIsShow] = useState(false);

  const handlePopup = () => setIsShow(!isShow);

  return (
    <div className='category-popup'>
      <div className='sk-cat-block__label' onClick={handlePopup}>
        <div className='sk-cat-block__item title'>
          <FormattedMessage {...messages.title} />
        </div>
        <div className='sk-cat-block__item toggler'>
          <Toggler active={isShow} />
        </div>
      </div>
      <div className='sk-cat-block__label_mobile' onClick={handlePopup}>
        <SearchLoop />
      </div>
      <HeaderCatalogExpandableMenu
        show={isShow}
        handlerClose={() => setIsShow(!isShow)}
        placeholder={inputPlaceholder}
        items={getCatalogItems(
          categories?.items,
          subcategories?.items,
          formatMessage,
          subcategoriesWithCourses
        )}
      />
    </div>
  );
};

const listCatalogQuery = graphql(LIST_CATALOG, {
  props: ({
    data: { loading, error: fetchError, categories, subcategories, ...ownProps }
  }) => ({
    loading,
    categories,
    subcategories,
    fetchError,
    ...ownProps,
  }),
  options: ({ org = null }) => ({
    fetchPolicy: 'cache-first',
    variables: {
      limit: CATALOG_AMOUNT,
      org,
    },
  }),
});

const getSubcategoriesWithCoursesListQuery = graphql(
  GET_SUBCATEGORIES_WITH_COURSES_LIST,
  {
    props: ({
      data: {
        loading,
        error: fetchError,
        getSubcategoriesWithCoursesList,
        ...ownProps
      },
    }) => ({
      loading,
      subcategoriesWithCourses: getSubcategoriesWithCoursesList,
      fetchError,
      ...ownProps,
    }),
    options: ({ org = null }) => ({
      fetchPolicy: 'cache-first',
      variables: {
        org,
      },
    }),
  }
);


const enhancer = compose(
  memo,
  injectIntl,
  getSubcategoriesWithCoursesListQuery,
  listCatalogQuery
);

export default enhancer(CatalogBlock);
