import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  passReset: {
    id: 'app.components.NoCoursesFound.passReset',
    defaultMessage: `Password reset`,
  },
  enterYourEmailAddress: {
    id: 'app.components.NoCoursesFound.enterYourEmailAddress',
    defaultMessage: `Enter your email address, and we’ll send you a password reset email`,
  },
});
