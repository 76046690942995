// Core
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';
import { Droppable } from 'react-beautiful-dnd';
import { FormattedMessage, injectIntl } from 'react-intl';

// Components
// import Loader from 'components/Loader';
import Text from 'components/Text';
import StudyMaterialsList from 'routes/Course/components/LectureMaterials/StudyMaterialsList';
import AddEntityButton from 'routes/Course/components/AddEntityButton';
import ToolTip from 'components/ToolTip';

// Styles and Assets
import './LectureMaterials.scss';

// Other
import { messages } from 'routes/Course/components/ComplexitySelect/messages';
import { PDF_FILE, EDITOR_FILE, VIDEO_FILE, AUDIO_FILE } from 'utils/enums';
import { withUploadManager } from 'containers/UploadManagerProvider/withUploadManager';
import { withFileModal } from 'routes/Course/components/LecturesList/LectureItem/HOCs/withFileModal';

const LectureMaterials = ({
  droppableId,
  courseId,
  lectureId,
  lecture: { materials },
  openFileModal,
  handleAttachmentDelete,
  handleUploadQuiz,
  disableDeletion,
  intl: { formatMessage }
}) => {
  const handleAddLectureMaterial = useCallback(
    entityType => () =>
      openFileModal({ entityType, params: { withDuration: true } }),
    []
  );
  const handleAddQuiz = useCallback(
    async () => {
      await handleUploadQuiz({ courseId, lectureId });
    }, [handleUploadQuiz]
  );

  return (
    <div className="sk-lec-materials">
      <header className="sk-lec-materials__header">
        <div class="sk-lec-materials__header__title">
          <Text classes="sk-subtitle">
            <FormattedMessage {...messages.studyMats} />
          </Text>
        </div>
        <div class="sk-lec-materials__header__extras">
          <Text classes="sk-subtitle">
            <FormattedMessage {...messages.lecturePublicMaterial} />
          </Text>
          <ToolTip>
            <p>
              <FormattedMessage {...messages.lecturePublicMaterialToolTip} />
            </p>
          </ToolTip>
        </div>
      </header>
      <Droppable droppableId={droppableId} type='material' direction='vertical'>
        {(provided, snapshot) => {
          return (
            <div
              className="sk-lec-materials__content"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {materials.length ? (
                <StudyMaterialsList
                  droppableId={droppableId}
                  materials={materials}
                  courseId={courseId}
                  lectureId={lectureId}
                  disableDeletion={disableDeletion}
                  openFileModal={openFileModal}
                  handleAttachmentDelete={handleAttachmentDelete}
                />
              ) : (
                <Text disabled size="small">{formatMessage(messages.thereAreNoStudyMats)}</Text>
              )}
              {provided.placeholder}
            </div>
          )
        }}
      </Droppable>

      <div className="sk-lec-materials__footer">
        <div className="sk-lec-materials__actions">
          <div className="sk-lec-materials__btn">
            <FormattedMessage {...messages.addVideo}>
              {msg => (
                <AddEntityButton
                  label={msg}
                  handleAddClick={handleAddLectureMaterial(VIDEO_FILE)}
                />
              )}
            </FormattedMessage>
          </div>

          <div className="sk-lec-materials__btn">
            <FormattedMessage {...messages.addAudio}>
              {msg => (
                <AddEntityButton
                  label={msg}
                  handleAddClick={handleAddLectureMaterial(AUDIO_FILE)}
                />
              )}
            </FormattedMessage>
          </div>

          <div className="sk-lec-materials__btn">
            <FormattedMessage {...messages.addArticle}>
              {msg => (
                <AddEntityButton
                  label={msg}
                  handleAddClick={handleAddLectureMaterial(EDITOR_FILE)}
                />
              )}
            </FormattedMessage>
          </div>

          <div className="sk-lec-materials__btn">
            <FormattedMessage {...messages.addLectureQuiz}>
              {msg => (
                <AddEntityButton
                  label={msg}
                  handleAddClick={handleAddQuiz}
                />
              )}
            </FormattedMessage>
          </div>

          <div className="sk-lec-materials__btn">
            <FormattedMessage {...messages.addArticleAsPDF}>
              {msg => (
                <AddEntityButton
                  label={msg}
                  handleAddClick={handleAddLectureMaterial(PDF_FILE)}
                />
              )}
            </FormattedMessage>
          </div>
        </div>
        <Text classes="sk-lec-materials__sidenote" size="caption" light newLine>
          <span className="sk-lec-materials__asterix">* </span>
          <span>
            <FormattedMessage {...messages.videoReqs5Gb} />
          </span>
        </Text>
      </div>
    </div>
  );
};

LectureMaterials.defaultProps = {
  lecture: {
    articles: [],
    videos: [],
    audios: [],
  },
};

LectureMaterials.propTypes = {
  lecture: PropTypes.object,
  handleAddLectureVideo: PropTypes.func,
  handleVideoReload: PropTypes.func,
  handleAddLectureAudio: PropTypes.func,
  handleAudioReload: PropTypes.func,
  handleAddLectureArticle: PropTypes.func,
  handleArticleReload: PropTypes.func,
  handleAttachmentDelete: PropTypes.func,
  videoIsUploading: PropTypes.bool,
  disableDeletion: PropTypes.bool,
};

const enhancer = compose(
  injectIntl,
  withUploadManager,
  withFileModal
);

export default enhancer(LectureMaterials);
