import { updateTitle, updatePrice } from 'routes/AdminPanel/PlanEdit/helpers.js';

export const MAPPING_INPUT = {
  title: ({ ru, en }) => ({ titleRU: ru, titleEN: en }),
  price: (prices) => {
    const annualPrice = prices.find(price => price?.type == 'annual')?.amount || '';
    const monthlyPrice = prices.find(price => price?.type == 'monthly')?.amount || '';
    return { annualPrice, monthlyPrice };
  },
  maxCourses: 'maxCourses',
  discountPerCourse: 'discountPerCourse',
  discountCoursesLimit: 'discountCoursesLimit',
};

export const MAPPING_OUTPUT = {
  titleRU: updateTitle('ru'),
  titleEN: updateTitle('en'),
  monthlyPrice: updatePrice('monthly'),
  annualPrice: updatePrice('annual'),
  maxCourses: 'maxCourses',
  discountPerCourse: 'discountPerCourse',
  discountCoursesLimit: 'discountCoursesLimit',
};
