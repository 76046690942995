import React from 'react';

const Icon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ boxShadow: '0px 3px 22px rgba(91, 80, 126, 0.229506)', borderRadius: '50%', }}
    >
      <circle cx="9" cy="9" r="9" fill="white"/>
      <path d="M9.184 14.632C8.896 14.632 8.656 14.552 8.464 14.392C8.28267 14.2213 8.192 13.9813 8.192 13.672V7.496C8.192 7.18667 8.28267 6.952 8.464 6.792C8.656 6.632 8.896 6.552 9.184 6.552C9.472 6.552 9.712 6.632 9.904 6.792C10.096 6.952 10.192 7.18667 10.192 7.496V13.672C10.192 13.9813 10.096 14.2213 9.904 14.392C9.712 14.552 9.472 14.632 9.184 14.632ZM9.184 5.128C8.82133 5.128 8.53333 5.032 8.32 4.84C8.10667 4.63733 8 4.376 8 4.056C8 3.736 8.10667 3.48 8.32 3.288C8.53333 3.096 8.82133 3 9.184 3C9.536 3 9.81867 3.096 10.032 3.288C10.256 3.48 10.368 3.736 10.368 4.056C10.368 4.376 10.2613 4.63733 10.048 4.84C9.83467 5.032 9.54667 5.128 9.184 5.128Z" fill="#30d5c8"/>
    </svg>
  );
};

export default Icon;
