import React from 'react';

import './index.scss';

const BlockquoteOnHover = () => (
  <svg
    className="hover"
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.693359" width="32" height="32" rx="2" fill="white" />
    <path
      d="M25.0381 11V21C25.0381 22.3807 23.9188 23.5 22.5381 23.5H10.5381C9.15737 23.5 8.03809 22.3807 8.03809 21V11C8.03809 9.61929 9.15737 8.5 10.5381 8.5H17.0381H22.5381C23.9188 8.5 25.0381 9.61929 25.0381 11Z"
      stroke="#7145D8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.5381 20V12L19.5381 16L13.5381 20Z"
      stroke="#7145D8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const DefaultBlockquote = () => (
  <svg
    className="default"
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.0381 11V21C25.0381 22.3807 23.9188 23.5 22.5381 23.5H10.5381C9.15737 23.5 8.03809 22.3807 8.03809 21V11C8.03809 9.61929 9.15737 8.5 10.5381 8.5H17.0381H22.5381C23.9188 8.5 25.0381 9.61929 25.0381 11Z"
      stroke="#6D6E71"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.5381 20V12L19.5381 16L13.5381 20Z"
      stroke="#6D6E71"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ActiveBlockquote = () => (
  <svg
    className="active"
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.0381 11V21C25.0381 22.3807 23.9188 23.5 22.5381 23.5H10.5381C9.15737 23.5 8.03809 22.3807 8.03809 21V11C8.03809 9.61929 9.15737 8.5 10.5381 8.5H17.0381H22.5381C23.9188 8.5 25.0381 9.61929 25.0381 11Z"
      stroke="#7145D8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.5381 20V12L19.5381 16L13.5381 20Z"
      stroke="#7145D8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Blockquote = ({ active }) => {
  const Icon = active ? ActiveBlockquote : DefaultBlockquote;

  return (
    <div className="editor-icon-svg">
      <Icon />
      <BlockquoteOnHover />
    </div>
  );
};

export default Blockquote;
