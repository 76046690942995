// Core
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// Components
import Heading from 'components/Heading';
import Button from 'components/Button';

// Styles and Assets
import './DeleteConfirmationModal.scss';

import { FormattedMessage } from 'react-intl';
import { messages } from 'services/yup/messages';

class DeleteConfirmationModal extends PureComponent {
  state = {};

  render() {
    const { handleConfirmation, handleCancel } = this.props;

    return (
      <div className="sk-del-confirmation-modal">
        <div className="sk-del-confirmation-modal_header">
          <Heading variant="h3" classes="sk-del-confirmation-modal_heading">
            <FormattedMessage {...messages.areYouSureToDelete} />
          </Heading>
        </div>
        <div className="sk-del-confirmation-modal_content">
          <Button color="secondary" size="large" onClick={handleCancel}>
            <FormattedMessage {...messages.cancel} />
          </Button>

          <Button
            variant="text"
            color="secondary"
            size="large"
            onClick={handleConfirmation}
          >
            <FormattedMessage {...messages.delete} />
          </Button>
        </div>
      </div>
    );
  }
}

DeleteConfirmationModal.propTypes = {
  handleConfirmation: PropTypes.func,
  handleCancel: PropTypes.func,
};

export default DeleteConfirmationModal;
