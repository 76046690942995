// Core
import React, { memo } from 'react';
import { Formik } from 'formik';

// Components
import TextAreaInput from 'components/Input/Material/TextAreaInput';
import Button from 'components/Button';
import Heading from 'components/Heading';
// Other
import { getYupSchema } from 'services/yup';

import { FormattedMessage } from 'react-intl';
import { messages } from 'containers/SendMessageExpertModal/messages';

// Styles
import './SendMessageForm.scss';

export const SendMessageForm = memo(
  ({ handleFormSubmit, initialFormFields, courseTitle }) => (
    <div className="sk-send-msg-form-wrapper">
      <Heading variant="h4" classes="sk-send-msg-form-wrapper__heading">
        <FormattedMessage {...messages.sendMessage} />
        <strong>{courseTitle}</strong>{' '}
        <FormattedMessage {...messages.courseTeacher} />
      </Heading>
      <Formik
        onSubmit={async values => {
          await handleFormSubmit(values);
        }}
        initialValues={initialFormFields}
        validationSchema={getYupSchema('emailMessageValidationSchema')}
        validateOnChange={false}
        validFormikateOnBlur
      >
        {formikProps => {
          const {
            values: { message },
            errors,
            touched,
            handleSubmit,
            setFieldValue,
            handleBlur,
          } = formikProps;

          const handleInputChange = name => (inputValue, e) => {
            setFieldValue(name, inputValue);
            if (e) e.persist();
          };

          const handleInputBlur = name => (inputValue, e) => {
            if (e) {
              e.persist();
              handleBlur(e);
            }
            setFieldValue(name, inputValue);
          };

          return (
            <form
              className="sk-send-msg-form"
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <div className="sk-form__inner sk-send-msg-form__inner">
                <div className="sk-form__row">
                  <FormattedMessage {...messages.msgToExpert}>
                    {msg => (
                      <TextAreaInput
                        name="message"
                        helperText={touched.message ? errors.message : ''}
                        error={touched.message && Boolean(errors.message)}
                        value={message || ''}
                        inputIsControlled={false}
                        label={msg}
                        onChange={handleInputChange('message')}
                        onBlur={handleInputBlur('message')}
                      />
                    )}
                  </FormattedMessage>
                </div>
              </div>
              <div className="sk-form_footer sk-send-msg-form__footer">
                <Button
                  variant="text"
                  color="secondary"
                  size="large"
                  type="submit"
                >
                  <FormattedMessage {...messages.send} />
                </Button>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  )
);

export default SendMessageForm;
