import React from 'react';

import './index.scss';

const IconOnHover = ({ linearId, onClick, disabled }) => (
  <svg
    className='filled'
    onClick={onClick}
    style={disabled ? { pointerEvents: 'none' } : { cursor: 'pointer'}}
    width="43"
    height="43"
    viewBox="0 0 43 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="42" height="42" rx="21" stroke={`url(#paint2_linear_${linearId})`} />
    <path d="M25.7472 24.7293C25.0086 24.7293 24.3343 24.9778 23.7869 25.3873L17.4561 21.5964C17.4762 21.4509 17.4879 21.3007 17.4879 21.1469C17.4879 20.993 17.4762 20.8427 17.456 20.6972L23.7103 16.9487C24.2763 17.3822 24.9797 17.6444 25.7472 17.6444C27.6465 17.6444 29.167 16.0638 29.167 14.1463C29.167 12.2288 27.6465 10.6482 25.7472 10.6482C23.8479 10.6482 22.3274 12.2288 22.3274 14.1463C22.3274 14.3002 22.3392 14.4505 22.3594 14.596L16.1051 18.3445C15.5391 17.911 14.8357 17.6488 14.0682 17.6488C12.1689 17.6488 10.6484 19.2293 10.6484 21.1469C10.6484 23.0644 12.1689 24.645 14.0682 24.645C14.8358 24.645 15.5392 24.3827 16.1052 23.9492L22.4357 27.7399C22.4193 27.8709 22.4102 28.0054 22.4102 28.1424C22.4102 30.015 23.8917 31.5556 25.7472 31.5556C27.6027 31.5556 29.0842 30.015 29.0842 28.1424C29.0842 26.2699 27.6027 24.7293 25.7472 24.7293Z" fill="white" stroke={`url(#paint3_linear_${linearId})`}/>
    <rect x="0.5" y="0.5" width="42" height="42" rx="21" fill={`url(#paint4_linear_${linearId})`} stroke={`url(#paint5_linear_${linearId})`}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M25.7472 25.2293C25.0075 25.2293 24.3409 25.5241 23.8348 25.9988L16.9004 21.8464C16.9539 21.6216 16.9879 21.3867 16.9879 21.1469C16.9879 20.907 16.9539 20.6722 16.9004 20.4473L23.7618 16.3349C24.2825 16.8346 24.9783 17.1444 25.7472 17.1444C27.3579 17.1444 28.667 15.8003 28.667 14.1463C28.667 12.4923 27.3579 11.1482 25.7472 11.1482C24.1365 11.1482 22.8274 12.4923 22.8274 14.1463C22.8274 14.3861 22.8615 14.621 22.915 14.8459L16.0536 18.9583C15.5329 18.4586 14.8371 18.1488 14.0682 18.1488C12.4575 18.1488 11.1484 19.4929 11.1484 21.1469C11.1484 22.8008 12.4575 24.145 14.0682 24.145C14.8371 24.145 15.5329 23.8352 16.0536 23.3355L22.988 27.4879C22.9394 27.6977 22.9102 27.9176 22.9102 28.1424C22.9102 29.7514 24.1803 31.0556 25.7472 31.0556C27.3141 31.0556 28.5842 29.7514 28.5842 28.1424C28.5842 26.5335 27.3141 25.2293 25.7472 25.2293Z" fill="white" stroke="#FEFAF9"/>
    <defs>
    <linearGradient id={`paint2_linear_${linearId}`} x1="-2.86667" y1="31.7826" x2="46.138" y2="30.5798" gradientUnits="userSpaceOnUse">
    <stop stopColor="#30d5c8"/>
    <stop offset="1" stopColor="#55d5ca"/>
    <stop offset="1" stopColor="#3ad1c5"/>
    </linearGradient>
    <linearGradient id={`paint3_linear_${linearId}`} x1="9.98054" y1="25.8624" x2="29.9481" y2="25.4311" gradientUnits="userSpaceOnUse">
    <stop stopColor="#30d5c8"/>
    <stop offset="1" stopColor="#55d5ca"/>
    <stop offset="1" stopColor="#3ad1c5"/>
    </linearGradient>
    <linearGradient id={`paint4_linear_${linearId}`} x1="-2.86667" y1="31.7826" x2="46.138" y2="30.5798" gradientUnits="userSpaceOnUse">
    <stop stopColor="#30d5c8"/>
    <stop offset="1" stopColor="#55d5ca"/>
    <stop offset="1" stopColor="#3ad1c5"/>
    </linearGradient>
    <linearGradient id={`paint5_linear_${linearId}`} x1="0" y1="21" x2="43" y2="21" gradientUnits="userSpaceOnUse">
    <stop stopColor="#30d5c8"/>
    <stop offset="1" stopColor="#3ad1c5"/>
    </linearGradient>
    </defs>
  </svg>
);

const IconDefault = ({ linearId }) => (
  <svg
    className='outlined'
    width="43"
    height="43"
    viewBox="0 0 43 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="42" height="42" rx="21" stroke={`url(#paint0_linear_${linearId})`} />
    <path d="M25.7472 24.7293C25.0086 24.7293 24.3343 24.9778 23.7869 25.3873L17.4561 21.5964C17.4762 21.4509 17.4879 21.3007 17.4879 21.1469C17.4879 20.993 17.4762 20.8427 17.456 20.6972L23.7103 16.9487C24.2763 17.3822 24.9797 17.6444 25.7472 17.6444C27.6465 17.6444 29.167 16.0638 29.167 14.1463C29.167 12.2288 27.6465 10.6482 25.7472 10.6482C23.8479 10.6482 22.3274 12.2288 22.3274 14.1463C22.3274 14.3002 22.3392 14.4505 22.3594 14.596L16.1051 18.3445C15.5391 17.911 14.8357 17.6488 14.0682 17.6488C12.1689 17.6488 10.6484 19.2293 10.6484 21.1469C10.6484 23.0644 12.1689 24.645 14.0682 24.645C14.8358 24.645 15.5392 24.3827 16.1052 23.9492L22.4357 27.7399C22.4193 27.8709 22.4102 28.0054 22.4102 28.1424C22.4102 30.015 23.8917 31.5556 25.7472 31.5556C27.6027 31.5556 29.0842 30.015 29.0842 28.1424C29.0842 26.2699 27.6027 24.7293 25.7472 24.7293Z" fill="white" stroke={`url(#paint1_linear_${linearId})`} />
    <defs>
    <linearGradient id={`paint0_linear_${linearId}`} x1="-2.86667" y1="31.7826" x2="46.138" y2="30.5798" gradientUnits="userSpaceOnUse">
    <stop stopColor="#30d5c8"/>
    <stop offset="1" stopColor="#55d5ca"/>
    <stop offset="1" stopColor="#3ad1c5"/>
    </linearGradient>
    <linearGradient id={`paint1_linear_${linearId}`} x1="9.98054" y1="25.8624" x2="29.9481" y2="25.4311" gradientUnits="userSpaceOnUse">
    <stop stopColor="#30d5c8"/>
    <stop offset="1" stopColor="#55d5ca"/>
    <stop offset="1" stopColor="#3ad1c5"/>
    </linearGradient>
    </defs>
  </svg>
);

const Icon = (props) => {
  const randomFloatString = `${Math.random()}`

  return (
    <div className='icon-with-hover'>
      <IconDefault {...props} linearId={randomFloatString} />
      <IconOnHover {...props} linearId={randomFloatString} />
    </div>
  );
};

export default Icon;
