// Core
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';
import copy from 'clipboard-copy';
import { withRouter } from 'react-router-dom';
import { compose, } from 'react-apollo';

// Components
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Text from 'components/Text';
import Button from 'components/Button';
import CrossRoundedTrasparent from 'components/Icon/Svg/CrossRoundedTrasparent';
import CheckRoundedTrasparent from 'components/Icon/Svg/CheckRoundedTrasparent';

// Other
import { sharedMessages } from 'services/i18n/sharedMessages/messages';

// Styles and Assets
import './CategoriesListTableRow.scss';

const classes = {
  tableCell: { root: 'mui-override sk-admin-discounts-table-row' },
};

const CategoriesListTableRow = memo(({ item, index, arr, intl: { formatMessage }, history }) => {
  const openCategoryPage = event => {
    event.preventDefault();
    event.stopPropagation();
    history.push(`/admin/panel/categories/edit/${item.id}`);
  }
  const isDiscountStarted = Date.now() > item.startTime;
  const isDiscountExpired = Date.now() > item.endTime;

  return (
    <TableRow
      key={item.id}
      className='mui-override sk-admin-discounts-table-row'
      onClick={openCategoryPage}
    >
      <TableCell
        classes={classes.tableCell}
        className={`sk-admin-discounts-table-row__tableBodyCellLeft ${index ===
          arr.length - 1 && '_last'}`}
        align="left"
      >
        <Text size="basic">
          {item.localized}
        </Text>
      </TableCell>
      <TableCell
        classes={classes.tableCell}
        className={`sk-admin-discounts-table-row__tableBodyCellLeft ${index ===
          arr.length - 1 && '_last'}`}
        align="left"
      >
        <Text size="basic">
          {item.order_number}
        </Text>
      </TableCell>
    </TableRow>
)});

CategoriesListTableRow.propTypes = {
  intl: intlShape.isRequired,
  item: PropTypes.object,
  index: PropTypes.number,
  arr: PropTypes.array,
};

const enhancer = compose(
  withRouter,
  injectIntl
)

export default enhancer(CategoriesListTableRow);
