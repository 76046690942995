const { INTERCOM_ID } = process.env;

window.intercomSettings = {
  app_id: INTERCOM_ID,
};
(function() {
  var w = window;
  var ic = w.Intercom;
  if (typeof ic === "function") {
    ic("reattach_activator");
    ic("update", w.intercomSettings);
  } else {
    var d = document;
    var i = function() {
      i.c(arguments);
    };
    i.q = [];
    i.c = function(args) {
      i.q.push(args);
    };
    w.Intercom = i;
    var l = function() {
      var s = d.createElement("script");
      s.type = "text/javascript";
      s.async = true;
      s.src = "https://widget.intercom.io/widget/" + INTERCOM_ID;
      var x = d.getElementsByTagName("script")[0];
      x.parentNode.insertBefore(s, x);
    };
    if (document.readyState === "complete") {
      l();
    } else if (w.attachEvent) {
      w.attachEvent("onload", l);
    } else {
      w.addEventListener("load", l, false);
    }
  }
})();

// class IntercomService {
//   intercomId;

//   constructor() {
//     this.init();
//   }

//   // Initialise plugin here
//   init() {
//     this.intercomId = INTERCOM_ID;
//   }

//   // Pageview wrapper
//   boot(settings = {}) {
//     var app_id = this.intercomId;
//     var w = window;
//     var ic = w.Intercom;
//     if (typeof ic === "function") {
//       ic("reattach_activator");
//       ic("update", { app_id, ...settings });
//     } else {
//       var d = document;
//       var i = function() {
//         i.c(arguments);
//       };
//       i.q = [];
//       i.c = function(args) {
//         i.q.push(args);
//       };
//       w.Intercom = i;
//       var l = function() {
//         var s = d.createElement("script");
//         s.type = "text/javascript";
//         s.async = true;
//         s.src = "https://widget.intercom.io/widget/" + app_id;
//         var x = d.getElementsByTagName("script")[0];
//         x.parentNode.insertBefore(s, x);
//       };
//       if (document.readyState === "complete") {
//         l();
//       } else if (w.attachEvent) {
//         w.attachEvent("onload", l);
//       } else {
//         w.addEventListener("load", l, false);
//       }
//     }
//   }
// }

// export default new IntercomService();
