// Core
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { compose, withApollo } from 'react-apollo';

// Styles and Assets
import './PurchasedCoursesLecture.scss';

// Components
import ExpansionPanel from 'components/ExpansionPanel';
import PCDetails from 'routes/StudentsCabinet/PurchasedCourses/PurchasedCoursesLecture/PCDetails';
import PCSummary from 'routes/StudentsCabinet/PurchasedCourses/PurchasedCoursesLecture/PCSummary';

const style = {
  summary: {
    root: 'mui-override sk-purchased-courses-lecture__expansionPanelSummary',
  },
  summary_first: {
    root:
      'mui-override sk-purchased-courses-lecture__expansionPanelSummary_first',
  },
  summary_last: {
    root:
      'mui-override sk-purchased-courses-lecture__expansionPanelSummary_last',
  },
  details: {
    root: 'mui-override sk-purchased-courses-lecture__expansionDetails',
  },
  expansionPanel: {
    root: 'mui-override sk-purchased-courses-lecture__expansionPanel',
  },
};

class PurchasedCoursesLecture extends PureComponent {
  setChilds = child => {
    if (child === null) {
      return style.summary;
    }
    if (child) {
      return style.summary_first;
    }
    return style.summary_last;
  };

  render() {
    const {
      lecture,
      index,
      child,
      courseId,
      courseStudyData,
      course,
      refetchStudentCourse,
      isLectureDisabled,
      isHomeworkDisabled,
      homeworksAccessSetting,
      displayLectureNameType,
      viewedMaterials,
      lastSeenMaterial,
    } = this.props;

    return (
      <div key={lecture.id} className="sk-purchased-courses-lecture">
        <ExpansionPanel
          panelSummaryClasses={this.setChilds(child)}
          panelClasses={style.expansionPanel}
          panelDetailsClasses={style.details}
          id={lecture.id}
          ExpansionPanelSummaryComponent={
            <PCSummary
              lecture={lecture}
              index={index}
              displayLectureNameType={displayLectureNameType}
            />
          }
          ExpansionDetailsComponent={
            <PCDetails
              courseStudyData={courseStudyData}
              course={course}
              lecture={lecture}
              courseId={courseId}
              isHomeworkDisabled={isHomeworkDisabled}
              homeworksAccessSetting={homeworksAccessSetting}
              isLectureDisabled={isLectureDisabled}
              homeworkNumber={index}
              refetchStudentCourse={refetchStudentCourse}
              viewedMaterials={viewedMaterials}
              lastSeenMaterial={lastSeenMaterial}
            />
          }
        />
      </div>
    );
  }
}

const enhancer = compose(withApollo, withRouter);

export default enhancer(PurchasedCoursesLecture);
