import React from 'react';

const Icon = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.7637 4.76464C21.3207 4.16788 22.1164 3.84961 22.9518 3.84961C23.7873 3.84961 24.5432 4.16788 25.14 4.76464C26.3733 5.99794 26.3733 7.98714 25.14 9.22044C23.9067 10.4935 20.0476 12.5623 18.0584 12.5623C17.6606 12.5623 17.5412 12.4827 17.5412 12.4827C16.8649 11.7268 18.7745 6.71405 20.7637 4.76464Z" stroke="#6D7C90" strokeMiterlimit="10"/>
      <path d="M13.4384 4.76464C12.8815 4.16788 12.0858 3.84961 11.2503 3.84961C10.4148 3.84961 9.65895 4.16788 9.0622 4.76464C7.82889 5.99794 7.82889 7.98714 9.0622 9.22044C10.2955 10.4935 14.1545 12.5623 16.1437 12.5623C16.5416 12.5623 16.6609 12.4827 16.6609 12.4827C17.3373 11.7268 15.4276 6.71405 13.4384 4.76464Z" stroke="#6D7C90" strokeMiterlimit="10"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M3.49336 12.2246C2.14305 12.2246 1.04395 13.3237 1.04395 14.674V17.5782C1.04395 18.3716 1.70543 19.0331 2.49876 19.0331H4.97226V30.1715C4.97226 31.4706 5.88023 32.6786 7.16578 32.6786H18.9513H21.2324H27.3499C28.6354 32.6786 29.5434 31.4706 29.5434 30.1715V19.0331H31.5808C32.3741 19.0331 33.0356 18.3716 33.0356 17.5782V14.674C33.0356 13.3237 31.9365 12.2246 30.5862 12.2246H24.9767H9.30181H3.49336ZM28.5434 19.0331H18.9693H15.1103H5.97226V30.1715C5.97226 31.0843 6.585 31.6786 7.16578 31.6786H18.9513H21.2324H27.3499C27.9306 31.6786 28.5434 31.0843 28.5434 30.1715V19.0331ZM2.04395 14.674C2.04395 13.876 2.69533 13.2246 3.49336 13.2246H9.30181H24.9767H30.5862C31.3842 13.2246 32.0356 13.876 32.0356 14.674V17.5782C32.0356 17.8193 31.8218 18.0331 31.5808 18.0331H18.9693H15.1103H2.49876C2.25771 18.0331 2.04395 17.8193 2.04395 17.5782V14.674Z" fill="#6D7C90"/>
    </svg>
  );
};

export default Icon;
