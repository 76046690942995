// Core
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';

// Components
import ConfirmDelete from 'routes/ExpertsCabinet/components/ConfirmDelete/ConfirmDelete';
import {
  VideoFileEditModal,
  TextFileEditModal,
  PdfFileEditModal,
  AudioFileEditModal,
} from 'routes/Course/components/FileEditModals';
import { withLayerModal } from 'containers/LayerModalProvider/withLayerModal';

// GraphQL
import UPDATE_ATTACHMENT from 'mutations/Courses/updateAttachment.gql';

// Other
import {
  VIDEO_FILE,
  EDITOR_FILE,
  PDF_FILE,
  AUDIO_FILE,
  TEXT_FILE,
} from 'utils/enums/index';

const MODALS = {
  [VIDEO_FILE]: VideoFileEditModal,
  [AUDIO_FILE]: AudioFileEditModal,
  [EDITOR_FILE]: TextFileEditModal,
  [TEXT_FILE]: TextFileEditModal,
  [PDF_FILE]: PdfFileEditModal,
  [null]: () => <div>No Modal defined for this file type</div>,
};

const updateAttachmentMutation = graphql(UPDATE_ATTACHMENT, {
  props: ({ mutate }) => ({
    updateAttachment: input => mutate({ variables: { input } }),
  }),
  options: {
    awaitRefetchQueries: true,
    refetchQueries: ['getCourseLectures'],
  },
});

const enhancer = compose(withLayerModal, updateAttachmentMutation);

export const withFileModal = WrappedComponent => {
  class HOC extends PureComponent {
    openFileModal = ({ entityId, entityType = null, params }) => {
      const {
        entity,
        entities,
        updateAttachment,
        layerModalContext: { showModal, hideModal },
        ...props
      } = this.props;

      let foundEntity = entity;
      if (!foundEntity)
        foundEntity = entityId
          ? entities.find(({ id }) => id === entityId)
          : null;
      const modal = MODALS[entityType];

      // debugger;

      showModal(modal, {
        ...props,
        ...params,
        size: 'xlg',
        entity: foundEntity,
        type: entityType,
        editEntity: updateAttachment,
        handleModalClose: hideModal,
        disabledDefaultCloseButton: entityType === EDITOR_FILE,
      });
    };

    render() {
      return (
        <WrappedComponent {...this.props} openFileModal={this.openFileModal} />
      );
    }
  }

  return enhancer(HOC);
};
