// Core
import React, { PureComponent } from 'react';
import { graphql } from 'react-apollo';

// GraphQl
import LIST_CITIES_QUERY from 'queries/UserProfile/listCities.gql';

// Components
import AutoComplete from 'components/Select/AutoComplete';

class CitySelect extends PureComponent {
  render() {
    const {
      value,
      error,
      fetchError,
      loading,
      listCities,
      country,
      ...props
    } = this.props;
    return (
      <AutoComplete
        options={listCities?.cities}
        loading={loading}
        error={error}
        fetchError={fetchError}
        labelKey="name"
        valueKey="id"
        value={value}
        {...props}
      />
    );
  }
}

const listCitiesQuery = graphql(LIST_CITIES_QUERY, {
  skip: props => !!props.skip,
  props: ({
    data: { loading, error: fetchError, listCities, ...ownProps },
  }) => ({
    loading,
    listCities,
    ...ownProps,
  }),
  options: ({ value, country }) => ({
    fetchPolicy: 'cache-and-network',
    variables: {
      input: { country: country?.id || '', name: value?.name ? value : "", size: 5000 },
    },
  }),
});

CitySelect.propTypes = {};

export default listCitiesQuery(CitySelect);
