import moment from 'moment';
import path from 'ramda/src/path';
import { DEFAULT_LOCALE, LANGUAGES, CURRENCIES, COURSE_ADMIN_ROLES } from 'utils/enums';

const convertUnixInMillesonds = (unix) => unix * 1000;

export const isCourseAdmin = roles =>
  roles ? COURSE_ADMIN_ROLES.some(ca => roles.includes(ca)) : false;

export const getFQDN = () => window?.location?.host || "avdo.education"

export const timeout = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
export const sleep = async (fn, ...args) => {
  await timeout(3000);
  return fn(...args);
};

export const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const getCurrencyProperties = (currency) => {
  if (!isBlank(currency && CURRENCIES.some(({ code }) => code === currency))) {
    return CURRENCIES.find(({ code }) => code === currency);
  }
  return CURRENCIES.find(({ isDefault }) => isDefault === true);
};

export const getUserAgentLanguage = () => {
  const { navigator } = window;
  try {
    // User agent stores all information about supported languages
    // Note: best practice would be go through for(...of navigator.languages)
    // and pick from available languages in case if the first record is not supported
    // by skilleton platform.
    const languageString =
      (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage;
    const primaryLang = languageString.split(';').shift();
    const { code } = LANGUAGES.find(({ code }) => primaryLang.toLowerCase().includes(code));
    return code;
  } catch (err) {
    console.warn('Unsupported locale');
  }
  return DEFAULT_LOCALE;
};

export const renderTrimmedText = (text, length) => {
  if (text) {
    return text.length >= length ? `${text.substring(0, length)} ...` : text;
  }
  return '';
};

export const renderSmartTrimmedText = (text, lineLength, maxLines) => {
  if (!text) return '';

  /* eslint-disable no-control-regex */
  const words = text.replace(/[\r\n\x0B\x0C\u0085\u2028\u2029]+/g, ' ').split(/\s+/);

  const textDividedOnLines = [];
  let currentLine = [];
  let currentLineLength = 0;

  words.forEach((word, index, arr) => {
    const wordLength = word.length;
    const currentLineLengthWithNextWord = currentLineLength + wordLength;

    const isLineLengthExceeded = currentLineLengthWithNextWord / lineLength >= 1;
    const isLastElement = arr.length - 1 === index;

    if (isLastElement && !isLineLengthExceeded) {
      currentLine = [...currentLine, word];
      textDividedOnLines.push(currentLine);
    } else if (isLineLengthExceeded) {
      textDividedOnLines.push(currentLine);

      currentLineLength = wordLength;
      currentLine = [word];

      if (isLastElement) textDividedOnLines.push(currentLine);
    } else if (currentLineLength === 0) {
      currentLineLength = 0 + wordLength;
      currentLine.push(word);
    } else if (currentLineLength === 0) {
      currentLineLength = 0 + wordLength;
      currentLine.push(word);
    } else {
      const whitespaceLength = 1;
      currentLineLength = currentLineLength + whitespaceLength + wordLength;
      currentLine.push(word);
    }
  });
  const lines = textDividedOnLines.length;

  let trimmedText = text;

  if (lines > maxLines) {
    const textMaxLinesArr = textDividedOnLines.slice(0, maxLines);
    textMaxLinesArr[maxLines - 1].push(' ...');

    const textMaxLines = textMaxLinesArr.map((line) => line.join(' '));

    trimmedText = textMaxLines.join(' ');
  }

  return trimmedText;
};

export const cleanUpStyleString = (string) => (string ? string.split('undefined').join('') : '');

export const grabValuesByKeys = (keys, collection) =>
  keys.map((field) => ({ [field]: collection[field] })).reduce((prev, cur) => ({ ...prev, ...cur }), {});

export const isArray = (a) => Array.isArray(a);

export const isEmpty = (elmt) => !!elmt && elmt?.length == 0;

export const isEmptyArray = (arr) => isArray(arr) && isEmpty(arr);

export const isNotEmptyArray = (arr) => isArray(arr) && !!arr && arr?.length > 0;

export const isObject = (o) => o === Object(o) && !isArray(o) && typeof o !== 'function';

export const isEmptyObject = (obj) => Object.getOwnPropertyNames(obj).length === 0;

export const toCamelCase = (str) => {
  if (str.toLowerCase() !== str) {
    str.toLowerCase();
  }
  return str.replace(/_([a-z])/g, (m) => m.toUpperCase()).replace(/_/g, '');
};

export const toSnakeCase = (str) =>
  str
    .split(/(?=[A-Z])/)
    .join('_')
    .toLowerCase();

export const keysToCamel = (o) => {
  if (isObject(o)) {
    const n = {};

    Object.keys(o).forEach((k) => {
      n[toCamelCase(k)] = keysToCamel(o[k]);
    });
    return n;
  }
  if (isArray(o)) {
    return o.map((i) => keysToCamel(i));
  }

  return o;
};

export const keysToSnake = (o) => {
  if (isObject(o)) {
    const n = {};

    Object.keys(o).forEach((k) => {
      n[toSnakeCase(k)] = keysToSnake(o[k]);
    });

    return n;
  }
  if (isArray(o)) {
    return o.map((i) => keysToSnake(i));
  }

  return o;
};

export const toUpperCase = (str) => str.toUpperCase();

export const parseFetchedData = (fetchedData) => {
  if (!fetchedData) return null;
  const { __typename, ...rest } = fetchedData;
  const data = { ...keysToCamel(rest) };
  // Hotfix: SKIL-1216
  // Hide reviews without message
  if (data.rating && data.rating.length > 0) {
    data.rating = data.rating.filter((item) => item.text);
  }

  return data;
};

export const capitalize = (string) => string.charAt(0)?.toUpperCase() + string.slice(1);

export const parseMomentTimeInAMPM = (momentTime) => {
  const hours = momentTime.format('HH');
  const minutes = momentTime.format('MM');
  const isAM = hours < 12 || (hours === 12 && minutes === 0);
  const mode = isAM ? 'AM' : 'PM';

  return `${momentTime.format('hh:mm')} ${mode}`;
};

export const getTimeInterval = () => {
  const arr = [];
  for (let i = 0; i < 23; i += 1) {
    arr.push({
      key: i,
      label: `${i % 2 === 0 ? i / 2 + 1 : (i + 1) / 2}h ${i % 2 === 0 ? '' : '30min'}`,
      value: i % 2 === 0 ? (3600000 * (i + 2)) / 2 : 1800000 + (3600000 * (i + 1)) / 2
    });
  }
  return arr;
};

export const parseEntityDuration = (duration, durationUnits, parseType = 'asMilliseconds') =>
  Math.floor(moment.duration(duration, durationUnits)[parseType]());

export const getUniquesPrimitives = (arr1, arr2) =>
  arr1.filter((val1) => !arr2.some((val2) => val1 === val2));

export const removeZerros = (id) => id.replace(/['0']/g, '');

export const getDurationsSumFromEntity = (entity, keys, durationKey) =>
  Object.entries(entity)
    .filter((pair) => keys.includes(pair[0]) && pair[1]?.length)
    .flatMap((pair) => pair[1])
    .reduce((prev, cur) => prev + cur[durationKey], 0);

export const checkIfLevelBlocked = (maxInteractionLevel, interactionLevel) => {
  const permittedLevels = {
    flex: ['flex'],
    assist: ['flex', 'assist'],
    concierge: ['flex', 'assist', 'concierge']
  };

  return !permittedLevels[maxInteractionLevel]?.includes(interactionLevel);
};

export const getBlockedInteractionLevels = (interactionLevel) => ({
  assist: checkIfLevelBlocked(interactionLevel, 'assist'),
  concierge: checkIfLevelBlocked(interactionLevel, 'concierge')
});

export const getAverageValue = (arr, key) => {
  const acumObj = arr.reduce(
    (prev, cur) => {
      const nextValue = path(key, cur);
      const memo = { ...prev };

      if (nextValue) {
        memo.sum += nextValue;
        memo.amount += 1;
      }

      return memo;
    },
    { sum: 0, amount: 0 }
  );
  return Math.ceil(acumObj.sum / acumObj.amount) || 0;
};

export const getTotalPrice = (checkoutItems) =>
  checkoutItems.map(({ price }) => price).reduce((prev, cur) => (prev ? prev + cur : cur), 0);

export const sortLectures = (arr) =>
  arr?.length ? arr.sort((prev, cur) => prev.orderNumber - cur.orderNumber) : [];

export const getMaterials = (lectureMaterials, lecture) => {
  let materials = [];
  if (lecture) {
    if (lectureMaterials?.length) {
      materials = [
        ...lectureMaterials.map((material) => ({
          lectureId: lecture.id,
          ...material
        }))
      ];
    }
  } else if (lectureMaterials?.length) {
    materials = [...lectureMaterials];
  }
  return materials;
};

export const getItemUrl = (url, { id, link, type }, currentLectureId, lectureId) => {
  const urlArray = url.split('/');
  return `${urlArray.slice(0, urlArray.length - 2).join('/')}/${type}/${id}`.replace(
    lectureId,
    currentLectureId
  );
};

export const getActiveCourses = (coursesList) =>
  coursesList ? coursesList.filter(({ courseStatus }) => courseStatus === 'ACTIVE') : [];

export const getCompletedCourses = (coursesList) =>
  coursesList ? coursesList.filter(({ courseStatus }) => courseStatus === 'COMPLETED') : [];

export const isEnterKeyPressed = (e) => e.which === 13 || e.keyCode === 13;

export const findOneOf = (arrSearchIn, arrSearchFor) => {
  let element = null;

  arrSearchIn.forEach((item) => {
    const isElementFound = arrSearchFor.indexOf(item) >= 0;

    if (isElementFound) element = item;
  });

  return element;
};

export const findAllOf = (arrSearchIn, arrSearchFor) => {
  const element = [];

  arrSearchIn.forEach((item) => {
    const isElementFound = arrSearchFor.indexOf(item) >= 0;

    if (isElementFound) element.push(item);
  });

  return element;
};

export const mergeDeep = (target, source) => {
  let output = {};
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach((key) => {
      if (isObject(source[key])) {
        if (!(key in target)) Object.assign(output, { [key]: source[key] });
        else output[key] = mergeDeep(target[key], source[key]);
      } else {
        Object.assign(output, { [key]: source[key] });
      }
    });
  } else if (isObject(target) && !isObject(source)) {
    output = target;
  } else if (!isObject(target) && isObject(source)) {
    output = source;
  }

  return output;
};

export const roundNumberTo = (num, dec) => {
  if (typeof num !== 'number' || typeof dec !== 'number') return false;

  const numSign = num >= 0 ? 1 : -1;

  return (Math.round(num * 10 ** dec + numSign * 0.0001) / 10 ** dec).toFixed(dec);
};

export const isObjectsEqual = (obj1, obj2) => {
  if (!isObject(obj1) || !isObject(obj2)) return false;
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};

export const isArraysEqual = (arr1, arr2) => {
  if (!isArray(arr1) || !isArray(arr2)) return false;
  return JSON.stringify(arr1) === JSON.stringify(arr2);
};

export const addSpacesBetweenEveryGroupOfThreeNumbers = (str) => {
  const symbols = str.split('').reverse();
  const symbolsWithSpaces = [];

  symbols.forEach((symbol, index) => {
    const indexRealNumber = index + 1;
    const isEndOfGroupOfThree = indexRealNumber % 3 === 1;
    if (isEndOfGroupOfThree) symbolsWithSpaces.push(' ');
    symbolsWithSpaces.push(symbol);
  });

  return symbolsWithSpaces.reverse().join('');
};

export const getObjFromArray = (value, valueLabel, arr) => {
  if (!(isArray(arr) && arr.length > 0)) return '';
  const foundObj = arr.find((item) => item[valueLabel] === value);
  return foundObj || '';
};

export const compareSortNumber = (a, b) => {
  if (!a && !b) return 0;
  if (!a && b) return 1;
  if (a && !b) return -1;

  const camelCaseA = keysToCamel(a);
  const camelCaseB = keysToCamel(b);

  return camelCaseA.sortNumber - camelCaseB.sortNumber;
};

export const compareCreatedAt = (a, b) => {
  if (!a && !b) return 0;
  if (!a && b) return 1;
  if (a && !b) return -1;
  return new Date(a.createdAt) - new Date(b.createdAt);
};

export const deduplicate = (arr, keyType) => {
  const temp = {};
  const arrWithUniqueItems = arr.filter((item) => {
    const key = keyType ? item[keyType] : JSON.stringify(item);
    temp[key] = (temp[key] || 0) + 1;

    return temp[key] === 1;
  });

  return arrWithUniqueItems;
};

export const getLectureDuration = (lectures) => {
  if (!(isArray(lectures) && lectures.length > 0)) return 0;
  return lectures.reduce((total, item) => total + (item?.duration || 0), 0);
  //getDurationsSumFromEntity(current, ['materials'], 'duration')
};

export const parseCookies = (propName) => {
  const results = document.cookie.split(';').reduce((res, c) => {
    const [record, value] = c
      .trim()
      .split('=')
      .map(decodeURIComponent);
    const allNumbers = (str) => /^\d+$/.test(str);
    try {
      return Object.assign(res, {
        [record]: allNumbers(value) ? value : JSON.parse(value)
      });
    } catch (e) {
      return Object.assign(res, { [record]: value });
    }
  }, {});
  if (propName) {
    return results[propName] || null;
  }
  return results;
};

export const getLocaleFromPathname = (pathname) => {
  const locale = pathname.match(/^\/([\w]{2})\//, '');
  if (locale) {
    return locale.splice(1).shift();
  }
  return DEFAULT_LOCALE;
};

export const getCourseThumbnail = (thumbnail, coursePhoto) => {
  if (thumbnail) {
    return thumbnail;
  }
  // move asset to S3
  return coursePhoto || '/assets/images/сourse-photo-placeholder.jpg';
};

export const isNull = (entity) => entity === null || entity === 'null';

export const isBlank = (entity) => isNull(entity) || entity === undefined || entity === 'undefined';

export const isTrue = (entity) => entity === 'true' || entity === true;
export const isFalse = (entity) => entity === 'false' || entity === false;

export const setCookieByKey = (key, value, time) => {
  const expDate = new Date();
  expDate.setTime(expDate.getTime() + 180 * 24 * 60 * 60 * 1000); //6 months
  expDate.toUTCString();
  if (!isBlank(document) && !window.isServer) {
    document.cookie = `${key}=${value}; expires=${time ? time : expDate}`;
  }
};
export const getCookieByKey = (key) => {
  if (!isBlank(document) && !window.isServer) {
    const cookie = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)');
    return cookie ? cookie[2] : null;
  }
};

export const hasText = (str) => !isBlank(str) && typeof str === 'string' && str.length > 0;

export const findItemByKey = (arr, itemKey, value) => {
  return arr.find((item) => item[itemKey] == value);
};

export const generateDraggableId = (obj) => {
  let draggableId = '';
  Object.keys(obj).forEach((key) => {
    draggableId = draggableId + `${key}/${obj[key]}/`;
  });
  return draggableId;
};

export const parseDraggableId = (str) => {
  const arr = str.split('/');
  const obj = {};
  for (let i = 0; i < arr.length; i = i + 2) {
    const key = arr[i];
    const value = arr[i + 1];
    obj[key] = value;
  }
  return obj;
};

export const numberSplitter = (number) => number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

export const importObjValuesByKeys = (objWithKeys, objWithValues) => {
  const parsedObj = {};

  for (const key in objWithKeys) {
    parsedObj[key] = objWithValues[key] || undefined;
  }

  return parsedObj;
};

export const validateUserId = (id) => {
  const idPattern = /(([(eu|us)]+){2}-[(east|central|west)].*?)/;
  if (id.match(idPattern)) {
    return true;
  }
  return false;
};
