// Core
import React, { memo } from "react";
import { Formik } from "formik";
import PropTypes from "prop-types";

// Components
import TextInput from "components/Input/Material/TextInput";
import PasswordInput from "components/Input/Material/PasswordInput";
import Button from "components/Button";

// Other
import { getYupSchema } from "services/yup";
import { FormattedMessage } from "react-intl";
import { messages } from "routes/PasswordChange/PasswordChangeForm/messages";

// Styles and Assets
import "./PasswordChangeForm.scss";

export const PasswordChangeForm = ({ handlePasswordChange, defaultFields }) => (
  <Formik
    onSubmit={async (values, formikBag) => {
      await handlePasswordChange(values, formikBag);
    }}
    initialValues={defaultFields}
    validationSchema={getYupSchema("pwChangeConfirmSchema")}
    validateOnChange={false}
    validateOnBlur
  >
    {(props) => {
      const {
        values: { password, confirmPassword, code },
        errors,
        touched,
        handleSubmit,
        handleBlur,
        setFieldValue,
      } = props;

      const handleInputChange = (name) => (inputValue, e) => {
        setFieldValue(name, inputValue);
        if (e) e.persist();
      };

      const handleInputBlur = (name) => (inputValue, e) => {
        if (e) {
          e.persist();
          handleBlur(e);
        }
        setFieldValue(name, inputValue);
      };

      return (
        <form
          className="sk-pw-change-form"
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <div className="sk-pw-change-form_inner">
            <div className="sk-pw-change-form_row">
              <FormattedMessage {...messages.enterRecoveryCode}>
                {(msg) => (
                  <TextInput
                    type="number"
                    name="code"
                    helperText={touched.code ? errors.code : ""}
                    error={touched.code && Boolean(errors.code)}
                    value={code}
                    label={msg}
                    onChange={handleInputChange("code")}
                    onBlur={handleInputBlur("code")}
                  />
                )}
              </FormattedMessage>
            </div>

            <div
              className={`sk-pw-change-form_row ${
                Boolean(errors.password) &&
                Boolean(errors.confirmPassword) &&
                errors.password.indexOf("Password must contain") !== -1
                  ? "withMargin"
                  : ""
              }`}
            >
              <FormattedMessage {...messages.newPassword}>
                {(msg) => (
                  <PasswordInput
                    fullWidth
                    name="password"
                    helperText={touched.password ? errors.password : ""}
                    error={touched.password && Boolean(errors.password)}
                    value={password}
                    label={msg}
                    onChange={handleInputChange("password")}
                    onBlur={handleInputBlur("password")}
                  />
                )}
              </FormattedMessage>
            </div>
            <div className="sk-pw-change-form_row">
              <FormattedMessage {...messages.reenterNewPassword}>
                {(msg) => (
                  <PasswordInput
                    fullWidth
                    name="confirmPassword"
                    helperText={
                      touched.confirmPassword ? errors.confirmPassword : ""
                    }
                    error={
                      touched.confirmPassword && Boolean(errors.confirmPassword)
                    }
                    value={confirmPassword}
                    label={msg}
                    onChange={handleInputChange("confirmPassword")}
                    onBlur={handleInputBlur("confirmPassword")}
                  />
                )}
              </FormattedMessage>
            </div>
          </div>
          <div className="sk-pw-change-form_footer">
            <Button variant="text" color="secondary" size="large" type="submit">
              <FormattedMessage {...messages.changePassword} />
            </Button>
          </div>
        </form>
      );
    }}
  </Formik>
);

PasswordChangeForm.propTypes = {
  defaultFields: PropTypes.object,
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  handleSubmit: PropTypes.func,
  handleBlur: PropTypes.func,
  setFieldValue: PropTypes.func,
  handlePasswordChange: PropTypes.func,
};

export default memo(PasswordChangeForm);
