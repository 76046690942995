// Core
import React, { PureComponent } from 'react';
import { compose, graphql } from 'react-apollo';
import { FormattedMessage } from 'react-intl';

// Components
import Text from 'components/Text';
import Button from 'components/Button';
import TextInput from 'components/Input/Material/TextInput/TextInput';
import Star from 'components/Icon/Svg/Star';
import Applause from 'components/Icon/SvgCss/Applause';
import ToolTip from 'components/ToolTip';
import RadioButton from 'components/RadioButton';
import { Red, White } from 'components/Icon/color.js'

// GraphQL
import CREATE_RATE_COURSE from 'mutations/StudentCabinet/createStudentRate.gql';
import CREATE_RATE_EXPERT from 'mutations/StudentCabinet/studentRateCourseAuthor.gql';
import END_COURSE from 'mutations/Courses/studentCompletedCourse.gql';

// Styles and Assets
import './EndCourseModal.scss';

// Other
import { APPLE, TOMATO } from 'utils/enums';
import { messages } from 'routes/StudentsCabinet/PurchasedCourses/EndCourseModal/messages';

class EndCourseModal extends PureComponent {
  state = {
    starRating: [],
    radioButton: false,
    aboutCourse: '',
    aboutExpert: '',
    isLoading: false,
  };

  componentDidMount() {
    const { starRating } = this.state;
    if (starRating.length === 0) {
      this.setState({ starRating: this.createdArray(5) });
    }
  }

  getRatingError = () => {
    const { starRating } = this.state;
    let mark = 0;
    starRating.map(item => {
      if (item.value === 0) {
        mark += 1;
      }
      return null;
    });
    if (mark !== 0) {
      return false;
    }
    return true;
  };

  handleButtonClick = id => async () => {
    const {
      studentRateCourse,
      studentRateCourseAuthor,
      handleSuccesfulRate,
      completeCourse,
      mappingId,
    } = this.props;
    this.setState(prevState => ({ ...prevState, isLoading: true }));
    const { starRating, radioButton, aboutCourse, aboutExpert } = this.state;
    const entity = radioButton ? APPLE : TOMATO;
    let mark = 0;
    starRating.map(item => {
      if (item.value === 0) {
        mark += 1;
      }
      return null;
    });
    try {
      await studentRateCourse({
        course_id: id,
        mark,
        text: aboutCourse,
      });
      await studentRateCourseAuthor({
        course_id: id,
        entity,
        text: aboutExpert,
      });
      await completeCourse({
        mapping_id: mappingId,
      });
      handleSuccesfulRate();
      this.setState(prevState => ({ ...prevState, isLoading: false }));
    } catch (error) {
      this.setState(prevState => ({ ...prevState, isLoading: false }));
      throw Error(error);
    }
  };

  createdArray = index => {
    const arr = [];
    let numb = 0;
    while (numb < index) {
      arr.push({ id: numb, value: 1 });
      numb += 1;
    }
    return arr;
  };

  handleRatingStar = id => {
    const { starRating } = this.state;
    starRating.map((item, index) => {
      if (id === index && item.value === 1) {
        this.setState(() => ({ starRating: this.arrayPusher(id, 0) }));
      } else {
        this.setState({ starRating: this.arrayPusher(id, 1) });
      }
      return null;
    });
  };

  arrayPusher = (id, bool) => {
    const { starRating } = this.state;
    const arr = [...starRating];
    arr.map((item, index) => {
      if (index <= id) {
        arr[index].value = bool;
      } else {
        arr[index].value = 1;
      }
      return null;
    });
    return arr;
  };

  handleInputChange = name => inputValue => {
    this.setState({ [name]: inputValue });
  };

  renderStarRating = starRating =>
    starRating.map(item => (
      <button
        key={item.id}
        onClick={() => this.handleRatingStar(item.id)}
        type="button"
      >
        <Star active={starRating[item.id].value === 1} width="25" height="25" />
      </button>
    ));

  handleChangeRadioButton = () => {
    const { radioButton } = this.state;
    this.setState({ radioButton: !radioButton });
  };

  render() {
    const { starRating, radioButton, isLoading, clicked } = this.state;
    const { id } = this.props;
    return (
      <div className="sk-end-course-modal">
        <div className="sk-end-course-modal__title">
          <h3>
            <FormattedMessage {...messages.congrats} />
          </h3>
        </div>
        <div className="sk-end-course-modal__desc">
          <Text>
            <FormattedMessage {...messages.pleaseRateCourse} />
          </Text>
        </div>
        <div className="sk-end-course-modal__rating">
          <div className="sk-end-course-modal__rating-title">
            <Text>
              <FormattedMessage {...messages.rateCourse} />
            </Text>
          </div>
          <div className="sk-end-course-modal__rating-stars">
            {!!starRating.length && this.renderStarRating(starRating)}
          </div>
          <div>
            <FormattedMessage {...messages.pleaseLetUsKnow}>
              {msg => (
                <TextInput
                  onChange={this.handleInputChange('aboutCourse')}
                  name="aboutCourse"
                  label={msg}
                />
              )}
            </FormattedMessage>
          </div>
          <div />
        </div>
        <div className="sk-end-course-modal__rating">
          <div className="sk-end-course-modal__rating-title">
            <Text>
              <FormattedMessage {...messages.rateExpert} />
            </Text>
          </div>
          <div className="sk-end-course-modal__rating-table">
            <div className="sk-end-course-modal__rating-table-apply" onClick={this.handleChangeRadioButton}>
              <Applause width="30" height="30" fill={radioButton} />
            </div>
          </div>
          <div>
            <FormattedMessage {...messages.pleaseAddFeedback}>
              {msg => (
                <TextInput
                  onChange={this.handleInputChange('aboutExpert')}
                  name="aboutExpert"
                  label={msg}
                />
              )}
            </FormattedMessage>
          </div>
        </div>
        <Button
          loading={isLoading}
          color="secondary"
          onClick={this.handleButtonClick(id)}
          disabled={this.getRatingError() || isLoading}
        >
          <FormattedMessage {...messages.submit} />
        </Button>
      </div>
    );
  }
}

const createRateCourseMutation = graphql(CREATE_RATE_COURSE, {
  props: ({ mutate }) => ({
    studentRateCourse: input => mutate({ variables: { input } }),
  }),
});

const createRateExpertMutation = graphql(CREATE_RATE_EXPERT, {
  props: ({ mutate }) => ({
    studentRateCourseAuthor: input => mutate({ variables: { input } }),
  }),
});

const completeCourseMutation = graphql(END_COURSE, {
  props: ({ mutate }) => ({
    completeCourse: input => mutate({ variables: { input } }),
  }),
  // TODO: Redo how student course is fetched
  options: {
    refetchQueries: ['listStudentCourses', 'getStudentCourse']
  }
});

const enhancer = compose(
  completeCourseMutation,
  createRateCourseMutation,
  createRateExpertMutation
);

export default enhancer(EndCourseModal);
