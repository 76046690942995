// Core
import React from 'react';
import PropTypes from 'prop-types';

// Components
import SheetChecked from 'components/Icon/Svg/SheetChecked';
import SheetCrossed from 'components/Icon/Svg/SheetCrossed';
import Text from 'components/Text';

import { FormattedMessage } from 'react-intl';
import { messages } from 'routes/ExpertsCabinet/components/StatusBlock/messages';

const iconProps = {
  width: '20',
  height: '25',
};

export const StatusBlock = ({ allHomeworksChecked }) => (
  <div className="sk-hw-summary-status-block">
    <div className="sk-hw-summary-status-block__icon">
      {allHomeworksChecked ? (
        <SheetChecked {...iconProps} />
      ) : (
        <SheetCrossed {...iconProps} />
      )}
    </div>
    <div className="sk-hw-summary-status-block__text">
      <Text>
        {allHomeworksChecked ? (
          <FormattedMessage {...messages.done} />
        ) : (
          <FormattedMessage {...messages.toMark} />
        )}
      </Text>
    </div>
  </div>
);

StatusBlock.propTypes = {
  allHomeworksChecked: PropTypes.bool,
};

export default StatusBlock;
