import React, { useCallback } from 'react';
import { compose } from 'react-apollo';

// Components
import Loader from 'components/Loader';
import DocumentIcon from 'components/Icon/Svg/Document';

// Other
import { withModal } from 'containers/ModalProvider/withModal';

// Styles
import './IconWithModal.scss';

const IconWithModal = ({
  customModalHandler,
  modal: Modal,
  modalProps,
  icon: Icon,
  disabled,
  modalContext,
}) => {
  const defaultModalHandler = () =>
    modalContext.showModal(Modal, {
      hideModal: modalContext.hideModal,
      close: modalContext.hideModal,
      ...modalProps,
    });
  const showModal = customModalHandler || defaultModalHandler;

  return (
    <button
      type="button"
      className="sk-doc-preview"
      onClick={showModal}
      disabled={disabled}
    >
      <Icon disabled={disabled} />
    </button>
  );
};

const enhancer = compose(withModal);

export default enhancer(IconWithModal);
