// Core
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { graphql, compose } from 'react-apollo';
import { Progress } from 'react-sweet-progress';
import slugify from '@sindresorhus/slugify';
import { FormattedMessage } from 'react-intl';
import IconWithModal from 'components/IconWithModal';

// Components
import { VideoPreview, } from 'components/FilePreview';
import Text from 'components/Text';
import Button from 'components/Button';
import FileUploader from 'components/FileUploader';
import UploadController from 'routes/Course/components/UploadController';
import ConfirmDelete from 'routes/ExpertsCabinet/components/ConfirmDelete/ConfirmDelete';
import Play from 'components/Icon/SvgCss/Play.js';
import Duration from 'components/Duration/Duration';

// GraphQL
import GET_COURSE from 'queries/Courses/getCourse.gql';
import DELETE_ATTACHMENT from 'mutations/Courses/deleteAttachment.gql';

// Other
import { withModal } from 'containers/ModalProvider/withModal';
import { withUploadManager } from  'containers/UploadManagerProvider/withUploadManager';
import { DarkGrey, White, NotActiveGrey } from 'components/Icon/color';
import { RECOMMENDED_VIDEO_FORMATS, SUPPORTED_VIDEO_MIME, DEFAULT_MAX_FILE_SIZE, } from 'utils/enums/index';
import { COURSE_PREVIEW, theme, } from 'containers/UploadManagerProvider/helpers';
import { getSeparatedFilename } from 'services/aws/amplify/helpers';
import { messages } from 'routes/ExpertsCabinet/CourseEdit/Details/messages';
import { sharedMessages } from 'services/i18n/sharedMessages/messages';

// Styles and Assets
import './PreviewUploader.scss';

const defaultState = {
  isPreviewAdded: false,
  isPreviewPending: false,
  isPreviewUploaded: false,
  isPreviewInQueue: false,
  isPreviewInProgress: false,
  isPreviewUploadedAndProcessed: false,
  isPreviewProcessing: false,
}

class PreviewUploader extends PureComponent {

  state = {
    coursePreview: {},
    ...defaultState,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { courseId, lectureId, type, coursePreview, uploadManagerContext: { queue, getFileFromQueue, getFileFromUploadedFiles }} = nextProps;

    const isPreviewAdded = coursePreview && coursePreview !== null;
    let isPreviewPending = false;
    let isPreviewUploaded = false;
    let isPreviewInQueue = false;
    let isPreviewInProgress = false;
    let isPreviewUploadedAndProcessed = false;
    let isPreviewProcessing = false;

    if (isPreviewAdded) {
      const { status, id } = coursePreview;

      isPreviewPending = status === 'PENDING';
      isPreviewUploadedAndProcessed = status === 'UPLOADED';
      isPreviewProcessing = status === 'PROCESSING';

      const { isFileInList: isFileInQueue, index: fileIndexInQueue } = getFileFromQueue(id);
      const { isFileInList: isFileUploaded } = getFileFromUploadedFiles(id);
      isPreviewInQueue = isFileInQueue;
      isPreviewUploaded = isFileUploaded;
      isPreviewInProgress = isPreviewInQueue && fileIndexInQueue === 0;

    // console.log('get derived', { isPreviewAdded,
    //   isPreviewPending,
    //   isPreviewUploaded,
    //   isPreviewInQueue,
    //   isPreviewInProgress,
    //   isPreviewUploadedAndProcessed,
    //   isPreviewProcessing,
    // });

      return {
        ...prevState,
        coursePreview,
        isPreviewAdded,
        isPreviewPending,
        isPreviewUploaded,
        isPreviewInQueue,
        isPreviewInProgress,
        isPreviewUploadedAndProcessed,
        isPreviewProcessing,
      }
    }

    return {
      ...prevState,
      coursePreview,
      ...defaultState,
    };
  }

  parseVideoTitle = (title, link) => title || link.split('/').pop();

  handleDeleteButtonClick = async () => {
    const { modalContext: { showModal, hideModal }, } = this.props;

    try {
      showModal(ConfirmDelete, { handleDelete: this.handlePreviewVideoDelete, close: hideModal });
    } catch (e) {
      throw new Error(e)
    }
  }

  handlePreviewVideoDelete = async () => {
    const { isPreviewPending, coursePreview: { id } } = this.state;
    const { deleteAttachment, uploadManagerContext: { removeFileFromQueue }, } = this.props;

    try {
      await deleteAttachment(id);
      if (isPreviewPending) removeFileFromQueue(id);
    } catch (e) {
      throw new Error(e)
    }
  }

  handlePreviewUpload = async files => {
    const { courseId, uploadManagerContext: { addFileToQueue }, refetchCourse, coursePreview, } = this.props;
    const isFileUploaded = !!coursePreview?.id

    try {
      const file = files[0]
      const { fileName, ext } = getSeparatedFilename(file.name);
      const beautyFilename = slugify(fileName);

      const params = {
        type: COURSE_PREVIEW,
        s3UploadingVariables: {
          filename: `${beautyFilename}.${ext}`,
          file,
          courseId,
        },
      	createAttachmentMutationVariables: {
          type: COURSE_PREVIEW,
          filename: `${beautyFilename}.${ext}`,
          title: beautyFilename,
          course_id: courseId,
        },
        s3PostHook: refetchCourse,
      };

      if (isFileUploaded) params.createAttachmentMutationVariables.id = coursePreview?.id;

      await addFileToQueue(params);
    } catch (error) {
      throw Error(error);
    }
  };

  renderPreviewUploader = () => {
    const { disabled } = this.props;
    const totallyDisabled = disabled;

    return (
      <div className="sk-preview-uploader__block">
        <div className="sk-preview-uploader__btn">
          <Button size="large" disableRipple disabled={totallyDisabled}>
            <div>
              <div className="sk-preview-uploader__file">
                <FileUploader
                  absolute
                  supportedMimes={SUPPORTED_VIDEO_MIME}
                  supportedFormats={RECOMMENDED_VIDEO_FORMATS}
                  maxSize={500000}
                  uploaderId="sk-preview-upload"
                  handleChange={this.handlePreviewUpload}
                  disabled={totallyDisabled}
                />
              </div>
              <FormattedMessage {...messages.uploadVideo} />
            </div>
          </Button>
        </div>
        <div className="sk-preview-uploader__req">
          <Text
            classes="sk-lec-materials__sidenote"
            size="caption"
            light
            newLine
          >
            <span className="sk-lec-materials__asterix">* </span>
            <span>
              <FormattedMessage {...messages.videoReqs500Mb} />
            </span>
          </Text>
        </div>
      </div>
    );
  };

  renderPreview = () => {
    const { link, disabled, uploadManagerContext: { progress }} = this.props;
    const { isPreviewPending, isPreviewUploaded, isPreviewInQueue, isPreviewInProgress, isPreviewUploadedAndProcessed, isPreviewProcessing, coursePreview: { filepath, filename, duration, filetype } } = this.state;
    const durationColor = (isPreviewPending || isPreviewProcessing) ? { color: NotActiveGrey } : { color: DarkGrey };

    return (
      <div className='sk-preview-uploader__item'>
        <div className="sk-preview-uploader__item__data">
          <div className="sk-preview-uploader__item__data__desc">
            <div className="sk-preview-uploader__item__data__icon">
              <IconWithModal
                modal={VideoPreview}
                modalProps={{ url: link, size: 'lg', fileType: filetype, isPublic: true }}
                icon={Play}
                disabled={isPreviewPending || isPreviewProcessing}
              />
            </div>
            <Text size="small" semiBold classes={classNames('sk-preview-uploader__item__data__title', { 'inactive': isPreviewPending || isPreviewProcessing })}>
              {filename}
            </Text>
          </div>
          <div className="sk-preview-uploader__item__data__info">
            <div className="sk-preview-uploader__item__data__info__controller">
              <UploadController
                uploaderId="sk-preview-upload"
                withDelete={true}
                isFilePending={isPreviewPending || isPreviewProcessing}
                isFileInQueue={isPreviewInQueue}
                disabled={disabled}
                supportedMimes={SUPPORTED_VIDEO_MIME}
                supportedFormats={RECOMMENDED_VIDEO_FORMATS}
                maxSize={DEFAULT_MAX_FILE_SIZE}
                handleDelete={this.handleDeleteButtonClick}
                handleReload={this.handlePreviewUpload}
              />
            </div>
            <div className={classNames('any-margin', { 'sk-preview-uploader__item__data__info__duration': isPreviewPending || isPreviewProcessing })}>
              {(isPreviewPending || isPreviewProcessing) ? (
                  <Duration duration={0} textStyles={durationColor} />
                ) : (
                  <Duration duration={duration} textStyles={durationColor} />
              )}
            </div>
          </div>
        </div>
        {(isPreviewPending && (isPreviewInQueue || isPreviewInProgress)) && (
          <Progress
            theme={theme}
            percent={isPreviewInProgress ? progress : 0}
            status={'active'} />
        )}
        {isPreviewUploaded && isPreviewPending && !isPreviewInProgress && (
          <Text className='sk-preview-uploader__item__question inactive'><FormattedMessage {...sharedMessages.fileProcessing} /></Text>
        )}
        {isPreviewProcessing && (
          <Text className='sk-preview-uploader__item__question inactive'><FormattedMessage {...sharedMessages.fileProcessing} /></Text>
        )}
      </div>
    );
  }

  render() {
    const { error, errorText, } = this.props;
    const { isPreviewAdded, isPreviewUploaded, isPreviewInQueue, isPreviewUploadedAndProcessed, isPreviewProcessing } = this.state;

    return (
      <div className="sk-preview-uploader">
        <Text classes="sk-preview-uploader__header">
          <FormattedMessage {...messages.coursePreview} />
        </Text>
        <div>
          {isPreviewAdded && (isPreviewUploaded || isPreviewInQueue || isPreviewUploadedAndProcessed || isPreviewProcessing)
            ? this.renderPreview()
            : this.renderPreviewUploader()
          }
        </div>
        <div className="sk-preview-uploader__error">
          {error && errorText ? (
            <Text error size="caption" classes="sk-preview-uploader__errorText">
              {errorText}
            </Text>
          ) : null}
        </div>
      </div>
    );
  }
}

const deleteAttachmentMutation = graphql(DELETE_ATTACHMENT, {
  props: ({ mutate }) => ({
    deleteAttachment: id => mutate({ variables: { id }}),
  }),
  options: {
    refetchQueries: ['getCourse'],
  },
});

const enhancer = compose(
  deleteAttachmentMutation,
  withUploadManager,
  withModal
)

export default enhancer(PreviewUploader);
