// Core
import React, { memo } from 'react';
import { compose } from 'react-apollo';
import { withRouter } from 'react-router-dom';

// Components
import Text from 'components/Text';
import Loader from 'components/Loader';
import TextInput from 'components/Input/Material/TextInput';
import Layout from 'routes/AdminPanel/PlanEdit/components/Layout';
import PlanTitle from 'routes/AdminPanel/PlanEdit/components/Forms/PlanTitle';
import PlanPrice from 'routes/AdminPanel/PlanEdit/components/Forms/PlanPrice';
import Analytics from 'routes/AdminPanel/PlanEdit/components/Forms/Analytics';

// Other
import { usePlanForm } from 'routes/AdminPanel/PlanEdit/components/usePlanForm.js';
import { messages } from 'routes/AdminPanel/messages';
import { getYupSchema, } from 'services/yup';

// Styles and Assets
import './index.scss';

const PlanConstructor = ({
  formikBag,
  analytics,
  disabled,
  ...props
}) => {
  // if (loading) return (<Loader />)

  return (
    <Layout>
      <PlanTitle {...formikBag} />
      <Text classes='sk-admin-plan-page-section'>Price per course</Text>
      <PlanPrice {...formikBag} title='Package price' />
      <Text classes='sk-admin-plan-page-section'>Discount for each additional course</Text>
      <TextInput
        adornment="%"
        position="start"
        type="number"
        name="discountPerCourse"
        value={formikBag?.values?.discountPerCourse}
        onChange={formikBag?.handleInputChange('discountPerCourse')}
        onBlur={formikBag?.handleInputBlur('discountPerCourse')}
        label="Discount percentage"
        disabled={disabled}
        error={formikBag?.touched.discountPerCourse && Boolean(formikBag?.errors.discountPerCourse)}
        helperText={formikBag?.touched.discountPerCourse ? formikBag?.errors.discountPerCourse : ''}
      />
      <TextInput
        type="number"
        name="discountCoursesLimit"
        value={formikBag?.values?.discountCoursesLimit || ""}
        onChange={formikBag?.handleInputChange('discountCoursesLimit')}
        onBlur={formikBag?.handleInputBlur('discountCoursesLimit')}
        label='Stops at # of courses & holds thereafter'
        disabled={disabled}
        error={formikBag?.touched.discountCoursesLimit && Boolean(formikBag?.errors.discountCoursesLimit)}
        helperText={formikBag?.touched.discountCoursesLimit ? formikBag?.errors.discountCoursesLimit : ''}
        
      />
      <Analytics {...analytics} />
    </Layout>
  );
}

const enhancer = compose(
  memo,
  withRouter,
);

export default enhancer(PlanConstructor);
