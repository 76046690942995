// Core
import React, { memo } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'react-apollo';
import { FormattedMessage, injectIntl } from 'react-intl';

// Components
import TextInput from 'components/Input/Material/TextInput/TextInput';
import Button from 'components/Button';
import CountrySelect from 'routes/Authenticator/CountrySelect';
import CitySelect from 'routes/Authenticator/CitySelect';
import TextAreaInput from 'components/Input/Material/TextAreaInput';
import AvatarUploader from 'components/AvatarUploader/';

// Styles
import './PersonalInfoForm.scss';

import { messages } from 'routes/Authenticator/SignUpForm/messages';

const PersonalInfoForm = memo(props => {
  const {
    becomeExpert,
    isExpert,
    values: { country="", city="", email, firstName, lastName, aboutMe, expertises },
    errors,
    touched,
    handleSubmit,
    setFieldTouched,
    setFieldValue,
    handleBlur,
    intl: { formatMessage },
  } = props;

  const handleInputChange = name => inputValue => {
    if(name === 'country' && !inputValue){
      setFieldValue('city', '');
    }
    setFieldValue(name, inputValue);
    setFieldTouched(name, true, false);
  };

  const handleInputBlur = name => (inputValue, e) => {
    if (e) {
      e.persist();
      handleBlur(e);
    }
    setFieldValue(name, inputValue);
  };

  return (
    <form
      className="sk-pi-form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <header className="sk-pi-form_header">
        <div className="sk-pi-form_uploader">
          <AvatarUploader
            avatarClasses="sk-pi-form_avatar"
            uploaderId='user-profile-photo'
            // firstName={firstName}
            // lastName={lastName}
          />
        </div>
      </header>
      <div className="sk-form__inner sk-pi-form_inner">
        <div className="sk-form__row">
          <TextInput
            name="firstName"
            helperText={touched.firstName ? errors.firstName : ''}
            error={touched.firstName && Boolean(errors.firstName)}
            value={firstName}
            label={formatMessage(messages.firstName)}
            placeholder={formatMessage(messages.firstName)}
            onChange={handleInputChange('firstName')}
            onBlur={handleInputBlur('firstName')}
          />
          <TextInput
            name="lastName"
            helperText={touched.lastName ? errors.lastName : ''}
            error={touched.lastName && Boolean(errors.lastName)}
            value={lastName}
            label={formatMessage(messages.lastName)}
            placeholder={formatMessage(messages.lastName)}
            onChange={handleInputChange('lastName')}
            onBlur={handleInputBlur('lastName')}
          />
        </div>
        <div className="sk-form__row">
          <TextInput
            name="email"
            helperText={touched.email ? errors.email : ''}
            error={touched.email && Boolean(errors.email)}
            value={email}
            label={formatMessage(messages.email)}
            placeholder={formatMessage(messages.email)}
            onChange={handleInputChange('email')}
            disabled
          />
        </div>

        <div className="sk-form__row">
          <TextAreaInput
            helperText={touched.aboutMe ? errors.aboutMe : ''}
            error={touched.aboutMe && Boolean(errors.aboutMe)}
            name='aboutMe'
            value={aboutMe}
            onChange={handleInputChange('aboutMe')}
            onBlur={handleInputBlur('aboutMe')}
            placeholder={formatMessage(messages.aboutMe)}
            label={formatMessage(messages.aboutMe)}
            // rows={2}
            rowsMax={4}
          />
        </div>
        {(isExpert || becomeExpert) && (
          <>
            <div className="sk-form__row">
              <CountrySelect
                helperText={touched.country ? errors.country : ''}
                error={touched.country && Boolean(errors.country)}
                name="country"
                label={formatMessage(messages.country)}
                placeholder={formatMessage(messages.country)}
                value={country}
                handleChange={handleInputChange('country')}
                handleSearch={handleInputChange('country')}
                handleBlur={handleInputBlur('country')}
              />
            </div>
            <div className="sk-form__row">
              <CitySelect
                helperText={touched.city ? errors.city : ''}
                error={touched.city && Boolean(errors.city)}
                name="city"
                label={formatMessage(messages.city)}
                placeholder={formatMessage(messages.city)}
                value={city}
                disabled={!country}
                country={country}
                handleChange={handleInputChange('city')}
                handleSearch={handleInputChange('city')}
                handleBlur={handleInputBlur('city')}
              />
            </div>
            <div className="sk-form__row">
              <TextInput
                helperText={touched.expertises ? errors.expertises : ''}
                error={touched.expertises && Boolean(errors.expertises)}
                name="expertises"
                label={<FormattedMessage {...messages.expertise} />}
                placeholder={formatMessage(messages.chooseExp)}
                value={expertises}
                onChange={handleInputChange('expertises')}
                onBlur={handleInputBlur('expertises')}
              />
            </div>
          </>
        )}
      </div>
      <div className="sk-form_footer sk-pi-form_footer">
        <Button variant="text" color="secondary" size="large" type="submit">
          <FormattedMessage {...messages.save} />
        </Button>
      </div>
    </form>
  );
});

const enhancer = compose(
  withRouter,
  injectIntl
)

export default enhancer(PersonalInfoForm);
