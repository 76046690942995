// Core
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { MaterializedTextField } from 'components/Input/Material/MaterialTextField';

// Styles
// import 'utils/styles/Materialized.scss';
import { parseMomentTimeInAMPM } from 'utils/helpers/index';

export const TimePickerInput = props => {
  const { time } = props;
  const parsedTime = parseMomentTimeInAMPM(time);

  return (
    <MaterializedTextField
      {...props}
      value={parsedTime}
      defaultValue={parsedTime}
    />
  );
};

TimePickerInput.propTypes = {
  time: PropTypes.object,
};

export default memo(TimePickerInput);
