// Core
import React, { memo } from 'react';
import { compose } from 'react-apollo';

// Components
import TextInput from 'components/Input/Material/TextInput';

// Other
// import { messages } from 'routes/AdminPanel/messages';

// Styles and Assets
import '../index.scss';

// const PREFIX = 'sk-admin-plan-page-analytics';
const PlanTitle = ({
  values,
  errors,
  touched,
  handleInputChange,
  handleInputBlur,
  disabled,
  ...props
}) => {
  return (
    <div className='sk-admin-plan-page-form'>
      <TextInput
        name="titleRU"
        value={values?.titleRU || ""}
        onChange={handleInputChange('titleRU')}
        onBlur={handleInputBlur('titleRU')}
        label='Name (ru)'
        disabled={disabled}
        error={touched.titleRU && Boolean(errors.titleRU)}
        helperText={touched.titleRU ? errors.titleRU : ''}
      />
      <TextInput
        name="titleEN"
        value={values?.titleEN || ""}
        onChange={handleInputChange('titleEN')}
        onBlur={handleInputBlur('titleEN')}
        label='Name (en)'
        disabled={disabled}
        error={touched.titleEN && Boolean(errors.titleEN)}
        helperText={touched.titleEN ? errors.titleEN : ''}
      />
    </div>
  );
}

const enhancer = compose(
  memo,
);

export default enhancer(PlanTitle);
