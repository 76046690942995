// Core
import React from 'react';
import PropTypes from 'prop-types';
import MaterialInput from '@material-ui/core/Input';

// Styles and Assets
import 'utils/styles/Materialized.scss';
import './Input.scss';

// Used in SimpleInput
const defaultClasses = {
  focused: 'mui-override sk-materialized-input_focused',
  error: 'mui-override sk-materialized-input_error withError',
  underline: 'mui-override sk-materialized-input_hover',
};

const Input = ({ inlineStyles, classes, name, error, ...rest }) => (
  <MaterialInput
    name={name}
    classes={{ ...defaultClasses, ...classes }}
    error={error}
    {...rest}
  />
);

Input.defaultProps = {
  classes: {},
  inlineStyles: {},
};

Input.propTypes = {
  name: PropTypes.string,
  error: PropTypes.bool,
  classes: PropTypes.object,
  inlineStyles: PropTypes.object,
};

export default Input;
