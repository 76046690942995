import React from 'react';

import './index.scss';

export const DefaultSliderContext = React.createContext();

export const DefaultSliderWrapper = ({ children }) => (<div className='default-slider-wrapper'>{children}</div>);
export const DefaultMark = ({ children }) => (<div className='default-mark'>{children}</div>);

export const Content = null;
