import React from 'react';

import './index.scss';

const IconOnHover = () => (
  <svg class='filled' width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="21" height="17" rx="2.5" fill="#7145D8" stroke="#7145D8"/>
    <path d="M5.08029 7.49941C5.68512 7.49941 6.19912 7.28076 6.62249 6.84321C7.04574 6.40566 7.25743 5.87449 7.25743 5.24956C7.25743 4.62443 7.04562 4.09326 6.62249 3.65567C6.19912 3.21829 5.68508 2.99951 5.08029 2.99951C4.47554 2.99951 3.96158 3.21829 3.53821 3.65567C3.115 4.09322 2.90332 4.62439 2.90332 5.24956C2.90332 5.87449 3.11489 6.40578 3.53821 6.84321C3.96158 7.28063 4.47554 7.49941 5.08029 7.49941Z" fill="white"/>
    <path d="M9.06465 10.1323C8.67165 10.5385 8.0204 10.5385 7.62738 10.1324L7.2504 9.74279C6.85737 9.33665 6.20613 9.33667 5.81313 9.74283L3.18467 12.4593C3.00421 12.6458 2.90332 12.8951 2.90332 13.1547V14.0002C2.90332 14.5525 3.35104 15.0002 3.90332 15.0002H17.8687C18.421 15.0002 18.8687 14.5525 18.8687 14.0002V10.1547C18.8687 9.89522 18.7679 9.64587 18.5874 9.45937L14.8704 5.61774C14.4773 5.21155 13.8261 5.21154 13.433 5.61772L9.06465 10.1323Z" fill="white"/>
  </svg>
);

const IconDefault = () => {
  const randomFloatString = `${Math.random()}`;
  return (
    <svg class='outlined' width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id={`paint3_linear_${randomFloatString}`} fill="white">
      <path d="M9.16133 10.2573C8.76833 10.6635 8.11708 10.6635 7.72406 10.2574L7.34708 9.86779C6.95405 9.46165 6.30281 9.46167 5.90981 9.86783L3.28135 12.5843C3.10089 12.7708 3 13.0201 3 13.2797V14.1252C3 14.6775 3.44772 15.1252 4 15.1252H17.9654C18.5177 15.1252 18.9654 14.6775 18.9654 14.1252V10.2797C18.9654 10.0202 18.8645 9.77087 18.6841 9.58437L14.967 5.74274C14.574 5.33655 13.9227 5.33654 13.5297 5.74272L9.16133 10.2573Z"/>
      </mask>
      <path d="M9.16133 10.2573C8.76833 10.6635 8.11708 10.6635 7.72406 10.2574L7.34708 9.86779C6.95405 9.46165 6.30281 9.46167 5.90981 9.86783L3.28135 12.5843C3.10089 12.7708 3 13.0201 3 13.2797V14.1252C3 14.6775 3.44772 15.1252 4 15.1252H17.9654C18.5177 15.1252 18.9654 14.6775 18.9654 14.1252V10.2797C18.9654 10.0202 18.8645 9.77087 18.6841 9.58437L14.967 5.74274C14.574 5.33655 13.9227 5.33654 13.5297 5.74272L9.16133 10.2573Z" stroke="#7145D8" stroke-width="2" mask={`url(#paint3_linear_${randomFloatString})`}/>
      <rect x="0.5" y="0.5" width="21" height="17" rx="2.5" stroke="#7145D8"/>
      <path d="M6.85 5.25C6.85 6.23261 6.08423 7 5.175 7C4.26577 7 3.5 6.23261 3.5 5.25C3.5 4.26739 4.26577 3.5 5.175 3.5C6.08423 3.5 6.85 4.26739 6.85 5.25Z" stroke="#7145D8"/>
    </svg>
  )
};

const Icon = () => {
  return (
    <div className="quiz-icon">
      <IconDefault />
      <IconOnHover />
    </div>
  );
};

export default Icon;
