// Core
import React from 'react';
import PropTypes from 'prop-types';

// Other
import { Grey3, OrangeyRed } from 'components/Icon/color';

const DocDownload = ({
  height,
  width,
  accentColor,
  disabledColor,
  disabled,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 43 51"
  >
    <g fill="none" fillRule="evenodd">
      <g stroke="#444" strokeLinecap="round" strokeLinejoin="round">
        <path d="M33.659 42.887V15.324L21.39 2.422H7.467C4.14 2.422 1.42 5.14 1.42 8.466v25.541" />
        <path
          d="M1.421 31.08V42.72c0 3.323 2.721 6.044 6.046 6.044h20.147c3.325 
         0 6.045-2.72 6.045-6.044M21.57 2.76v10.352c0 1.05.852 1.902 1.901 1.902h9.862L21.57 2.76z"
        />
      </g>
      <path
        fill="#FFF"
        d="M42.346 38.602c.061 6.623-5.185 12.043-11.716
         12.105-6.532.062-11.877-5.257-11.938-11.88-.062-6.622
          5.184-12.042 11.716-12.104 6.532-.062 11.878 5.256 11.938 11.88"
      />
      <path
        stroke={disabled ? disabledColor : accentColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M42.346 38.602c.061 6.623-5.185 12.043-11.716
         12.105-6.532.062-11.877-5.257-11.938-11.88-.062-6.622 
         5.184-12.042 11.716-12.104 6.532-.062 11.878 5.256 11.938 11.88z"
      />
      <path fill="#FFF" d="M30.408 33.013l.095 10.299" />
      <path
        stroke={disabled ? disabledColor : accentColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M30.408 33.013l.095 10.299"
      />
      <path fill="#FFF" d="M34.83 39.501l-4.313 4.456" />
      <path
        stroke={disabled ? disabledColor : accentColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M34.83 39.501l-4.313 4.456"
      />
      <g>
        <path fill="#FFF" d="M26.123 39.584l4.394 4.372" />
        <path
          stroke={disabled ? disabledColor : accentColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M26.123 39.584l4.394 4.372"
        />
      </g>
    </g>
  </svg>
);

DocDownload.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  disabled: PropTypes.bool,
  accentColor: PropTypes.string,
  disabledColor: PropTypes.string,
};

DocDownload.defaultProps = {
  height: '43',
  width: '51',
  accentColor: OrangeyRed,
  disabledColor: Grey3,
};

export default DocDownload;
