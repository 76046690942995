import React from 'react';
import {White} from 'components/Icon/color.js';

const Muted = ({color=White}) => (
  <svg
    className="sk-video-controllers_icon"
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="outline"
      d="M9.8611 0.40332C9.65906 0.40332 9.45887 0.46141 9.28213 0.571894C9.26527 0.582145 9.24913 0.594294 9.23447 0.607203L4.26578 4.95936H0.366665C0.163899 4.95936 0 5.12907 0 5.33903V12.5528C0 12.6537 0.0384999 12.7502 0.107433 12.8212C0.176366 12.8922 0.269132 12.9324 0.366665 12.9324L4.25698 12.9305L6.73986 15.2921L9.22273 17.6536C9.24107 17.6711 9.26087 17.6863 9.28177 17.6992C9.4585 17.8097 9.6587 17.8678 9.86073 17.8678C10.4888 17.8678 10.9996 17.3301 10.9996 16.6695V1.60156C11 0.940933 10.4888 0.40332 9.8611 0.40332Z"
      stroke={color}
    />
    <path
      className="filled"
      d="M9.8611 0.40332C9.65906 0.40332 9.45887 0.46141 9.28213 0.571894C9.26527 0.582145 9.24913 0.594294 9.23447 0.607203L4.26578 4.95936H0.366665C0.163899 4.95936 0 5.12907 0 5.33903V12.5528C0 12.6537 0.0384999 12.7502 0.107433 12.8212C0.176366 12.8922 0.269132 12.9324 0.366665 12.9324L4.25698 12.9305L6.73986 15.2921L9.22273 17.6536C9.24107 17.6711 9.26087 17.6863 9.28177 17.6992C9.4585 17.8097 9.6587 17.8678 9.86073 17.8678C10.4888 17.8678 10.9996 17.3301 10.9996 16.6695V1.60156C11 0.940933 10.4888 0.40332 9.8611 0.40332Z"
      fill={color}
      stroke={color}
    />
    <path
      d="M16.8571 8.52184L19.5582 5.66805C19.7031 5.51505 19.7031 5.26775 19.5582 5.11475C19.4134 4.96175 19.1794 4.96175 19.0345 5.11475L16.3334 7.96853L13.6323 5.11475C13.4875 4.96175 13.2534 4.96175 13.1086 5.11475C12.9638 5.26775 12.9638 5.51505 13.1086 5.66805L15.8097 8.52184L13.1086 11.3756C12.9638 11.5286 12.9638 11.7759 13.1086 11.9289C13.1808 12.0052 13.2756 12.0436 13.3705 12.0436C13.4653 12.0436 13.5601 12.0052 13.6323 11.9289L16.3334 9.07514L19.0345 11.9289C19.1068 12.0052 19.2016 12.0436 19.2964 12.0436C19.3912 12.0436 19.486 12.0052 19.5582 11.9289C19.7031 11.7759 19.7031 11.5286 19.5582 11.3756L16.8571 8.52184Z"
      fill={color}
    />
  </svg>
);

export default Muted;
