// Core
import React, { useState, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { compose, graphql } from 'react-apollo';

// Components
import CourseMaterialsContent from 'containers/Sidebar/SidebarContent/StudentCabinetSidebar/CourseMaterialsContent';
import LectureMaterialsContent from 'containers/Sidebar/SidebarContent/StudentCabinetSidebar/LectureMaterialsContent';

// Other
import {
  keysToCamel,
  getActiveCourses,
  getCompletedCourses,
  parseFetchedData,
  deduplicate,
} from 'utils/helpers';
// import { MAPPING_COURSES_LIST_LIMIT } from 'utils/enums';

// GraphQL
import GET_COURSE_LIST from 'queries/Courses/studentListCourses.gql';

// Styles and Assets
import './StudentCabinetSidebar.scss';

export const StudentCabinetSidebar = ({
  match: { params, url },
  purchasedCourses,
  purchasedToppings,
  history,
  error,
  loading,
}) => {
  const [openedItem, setOpenedItem] = useState(null);
  const [openedSubItem, setOpenedSubItem] = useState(null);
  const [currentUrl, setCurrentUrl] = useState(url);

  if (error) throw Error(error);

  const activeCourses = getActiveCourses(purchasedCourses);
  const completedCourses = getCompletedCourses(purchasedCourses);

  const routeParams = {
    url,
    courseId: params.id,
    lectureId: params.lectureId,
    materialType: params.type,
    studyMaterialId: getStudyMaterialId(url),
    interactionLevel: params.interactionLevel,
    routeType: getRouteType(url),
  };

  useEffect(() => {
    if (routeParams.url !== currentUrl) {
      setInitialMenuState(
        { activeCourses, completedCourses, purchasedToppings },
        routeParams,
        setActiveItem(setOpenedItem, setOpenedSubItem)
      );
      setCurrentUrl(routeParams.url);
    }

    if (
      routeParams.courseId &&
      purchasedCourses &&
      purchasedToppings &&
      (!openedItem || !openedSubItem)
    ) {
      setInitialMenuState(
        { activeCourses, completedCourses, purchasedToppings },
        routeParams,
        setActiveItem(setOpenedItem, setOpenedSubItem)
      );
    }
  });

  const handleListToggle = nextOpenedItem => () =>
    setOpenedItem(nextOpenedItem === openedItem ? 'none' : nextOpenedItem);

  const handleSubListToggle = nextOpenedSubItem =>
    setOpenedSubItem(
      openedSubItem === nextOpenedSubItem ? 'none' : nextOpenedSubItem
    );

  const handleBackClick = useCallback(() => {
    const coursePageUrlArray = url.split('/');
    const coursePageUrl = coursePageUrlArray
      .slice(0, coursePageUrlArray.length - 4)
      .join('/');
    history.push(`${coursePageUrl}/materials`);
  }, [url, history.push]);

  const materialsContentProps = {
    loading,
    openedItem,
    openedSubItem,
    handleListToggle,
    handleSubListToggle,
  };

  return (
    <div className="sk-student-cabinet-sidebar">
      {routeParams.lectureId ? (
        <LectureMaterialsContent
          {...materialsContentProps}
          {...routeParams}
          handleBackClick={handleBackClick}
        />
      ) : (
        <CourseMaterialsContent
          {...materialsContentProps}
          activeCourses={purchasedCourses}
          toppings={purchasedToppings}
        />
      )}
    </div>
  );
};

const getListCoursesQuery = graphql(GET_COURSE_LIST, {
  props: ({ data: { listStudentCoursesExtra, loading, error, ...ownProps } }) => {
    if (error) throw Error(error);
    if (loading) return { loading, error };

    const { courses, purchased_courses } = listStudentCoursesExtra;

    const purchasedCourses = purchased_courses
      .filter(purchasedCourse => {
        const courseData = courses.find(course => course.id === purchasedCourse.course_id);
        purchasedCourse.course_title = courseData?.title || 'no title';
        return !!courseData && courseData?.type == 'course';
      })
      .map(course => parseFetchedData(course));

    const deduplicated = deduplicate(purchasedCourses, 'mappingId');

    const purchasedToppings = purchased_courses
      .filter(purchasedCourse => {
        const courseData = courses.find(course => course.id === purchasedCourse.course_id);
        purchasedCourse.title = courseData?.title || 'no title';
        return !!courseData && courseData?.type == 'topping';
      })
      .map(course => parseFetchedData(course));

    return {
      loading,
      purchasedCourses,
      purchasedToppings,
      ...ownProps,
    }
  },
  options: () => ({
    pollInterval: 600000,
    // variables: { input: { type: 'course' } },
  }),
});

const getStudyMaterialId = url => url.split('/').pop();
const getRouteType = url => url.split('/').pop();

const setInitialMenuState = (
  { activeCourses, completedCourses, purchasedToppings },
  { courseId, interactionLevel, lectureId, studyMaterialId, routeType },
  setActiveItemCallback
) => {
  if (lectureId) {
    return setActiveItemCallback(lectureId, studyMaterialId);
  }

  if (activeCourses.length) {
    if (
      activeCourses.some(
        item =>
          item.courseId === courseId &&
          item.interactionLevel === interactionLevel
      )
    )
      return setActiveItemCallback(
        'active',
        `${courseId}-${interactionLevel}-${routeType}`
      );
  }

  if (completedCourses.length) {
    if (
      completedCourses.some(
        item =>
          item.courseId === courseId &&
          item.interactionLevel === interactionLevel
      )
    )
      return setActiveItemCallback(
        'completed',
        `${courseId}-${interactionLevel}-${routeType}`
      );
  }

  if (purchasedToppings.length) {
    if (
      purchasedToppings.some(
        item =>
          item.courseId === courseId
      )
    )
      return setActiveItemCallback(
        'topping',
        `${courseId}-${routeType}`
      );
  }
  return null;
};

const setActiveItem = (setItem, setSubItem) => (
  itemName,
  subItemIdentifier
) => {
  setItem(itemName);
  setSubItem(subItemIdentifier);
};

const enhancer = compose(
  withRouter,
  getListCoursesQuery
);

export default enhancer(StudentCabinetSidebar);
