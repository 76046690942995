// Core
import React from 'react';
import withWidth from 'containers/HOCs/withWidth';

// Components
import Link from 'components/Link';
import Button from 'components/Button';
import TextInput from 'components/Input/Material/TextInput';
import CategorySelect from 'routes/Course/components/CategorySelect';
import SubcategorySelect from 'routes/Course/components/SubcategorySelect';
import InteractionLevelToolTip from 'routes/Course/components/InteractionLevelToolTip';

// Styles and Assets
import './CourseCreationForm.scss';

// Other
import { FormattedMessage } from 'react-intl';
import { messages } from 'routes/Course/CourseCreation/messages';

const CourseCreationForm = props => {
  const {
    values: { interactionLevel, category, subcategory, title },
    errors,
    touched,
    handleSubmit,
    handleBlur,
    setFieldValue,
    width,
  } = props;

  const handleInputChange = name => (inputValue, e) => {
    setFieldValue(name, inputValue);
    if(name === 'category'){
      setFieldValue('subcategory', '')
    }
    if (e) e.persist();
  };

  const handleInputBlur = name => (inputValue, e) => {
    if (e) {
      e.persist();
      handleBlur(e);
    }
    setFieldValue(name, inputValue);
  };

  const stretchButtons = width === 'xs';

  return (
    <form
      className="sk-course-creation-form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <div className="sk-course-creation-form__inner">
        <div className="sk-form__row">
          <FormattedMessage {...messages.courseTitle}>
            {msg => (
              <TextInput
                name="Course title"
                defaultValue={title}
                onChange={handleInputChange('title')}
                onBlur={handleInputBlur('title')}
                placeholder={msg}
                label={msg}
                rows={4}
                rowsMax={15}
                error={touched.title && Boolean(errors.title)}
                helperText={touched.title ? errors.title : ''}
              />
            )}
          </FormattedMessage>
        </div>
        <div className="sk-form__row">
          <FormattedMessage {...messages.chooseCat}>
            {msg => (
              <CategorySelect
                helperText={touched.category ? errors.category : ''}
                error={touched.category && Boolean(errors.category)}
                name="category"
                label={msg}
                value={category}
                handleChange={handleInputChange('category')}
                handleBlur={handleInputBlur('category')}
              />
            )}
          </FormattedMessage>
        </div>
        <div className="sk-form__row">
          <FormattedMessage {...messages.chooseSubCat}>
            {msg => (
              <SubcategorySelect
                helperText={touched.subcategory ? errors.subcategory : ''}
                error={touched.subcategory && Boolean(errors.subcategory)}
                name="subcategory"
                label={msg}
                value={subcategory}
                handleChange={handleInputChange('subcategory')}
                handleBlur={handleInputBlur('subcategory')}
                disabled={!category}
                category={category}
                skip={!category}
              />
            )}
          </FormattedMessage>
        </div>
      </div>

      <footer className="sk-course-creation-form__footer">
        <Button
          variant="text"
          color="secondary"
          size="large"
          type="submit"
          fullWidth={stretchButtons}
        >
          <FormattedMessage {...messages.createCourse} />
        </Button>
      </footer>
    </form>
  );
};

export default withWidth()(CourseCreationForm);
