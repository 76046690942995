import React from 'react';

import './index.scss';

const IconOnHover = () => (
  <svg class='filled' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.2343 4.16154L16.4756 2.40411C15.9333 1.86223 15.0393 1.86223 14.497 2.41875L13.2073 3.76612L3.60769 13.3295C3.54906 13.3881 3.41716 13.5492 3.43181 13.5931L2.01019 18.675C1.95157 18.8801 2.15675 19.0558 2.34728 18.9826L6.52421 17.3423C6.61215 17.313 6.78802 17.1959 6.89061 17.108L16.9153 7.47138L18.2343 6.09472C18.7619 5.55285 18.7619 4.68877 18.2343 4.16154Z" stroke="#FF523A" strokeMiterlimit="10"/>
    <path d="M3.504 13.432L6.88951 17.108" stroke="#FF523A" strokeMiterlimit="10"/>
    <path d="M13.5591 3.38525L17.3111 7.06122" stroke="#FF523A" strokeMiterlimit="10"/>
    <path d="M18.2 4.19968L16.4 2.39968C15.8 1.79968 14.9 1.89968 14.4 2.39968L13.1 3.69968L3.4 13.3997C3.3 13.4997 3.3 13.4997 3.3 13.5997L2 18.6997C2 18.8997 2.2 19.0997 2.3 18.9997L6.5 17.3997C6.6 17.3997 6.8 17.2997 6.9 17.1997L16.9 7.59968L18.2 6.19968C18.8 5.59968 18.8 4.69968 18.2 4.19968Z" fill="#7145D8" stroke="#7145D8"/>
    <path d="M6.3998 17.0997C6.2998 17.0997 6.0998 16.9997 5.9998 16.8997L3.6998 14.4997C3.4998 14.2997 3.4998 13.9997 3.6998 13.7997C3.8998 13.5997 4.1998 13.5997 4.3998 13.7997L6.6998 16.1997C6.8998 16.3997 6.8998 16.6997 6.6998 16.8997C6.5998 16.9997 6.4998 17.0997 6.3998 17.0997Z" fill="white"/>
    <path d="M16.6998 6.99956C16.5998 6.99956 16.3998 6.99956 16.2998 6.89956L13.6998 4.39956C13.4998 4.19956 13.4998 3.89956 13.6998 3.69956C13.8998 3.49956 14.1998 3.49956 14.3998 3.69956L16.9998 6.19956C17.1998 6.39956 17.1998 6.69956 16.9998 6.89956C16.9998 6.89956 16.7998 6.99956 16.6998 6.99956Z" fill="white"/>
  </svg>
);

const IconDefault = () => {
  const randomFloatString = `${Math.random()}`;
  return (
    <svg class='outlined' width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id={`paint3_linear_${randomFloatString}`} fill="white">
      <path d="M9.16133 10.2573C8.76833 10.6635 8.11708 10.6635 7.72406 10.2574L7.34708 9.86779C6.95405 9.46165 6.30281 9.46167 5.90981 9.86783L3.28135 12.5843C3.10089 12.7708 3 13.0201 3 13.2797V14.1252C3 14.6775 3.44772 15.1252 4 15.1252H17.9654C18.5177 15.1252 18.9654 14.6775 18.9654 14.1252V10.2797C18.9654 10.0202 18.8645 9.77087 18.6841 9.58437L14.967 5.74274C14.574 5.33655 13.9227 5.33654 13.5297 5.74272L9.16133 10.2573Z"/>
      </mask>
      <path d="M9.16133 10.2573C8.76833 10.6635 8.11708 10.6635 7.72406 10.2574L7.34708 9.86779C6.95405 9.46165 6.30281 9.46167 5.90981 9.86783L3.28135 12.5843C3.10089 12.7708 3 13.0201 3 13.2797V14.1252C3 14.6775 3.44772 15.1252 4 15.1252H17.9654C18.5177 15.1252 18.9654 14.6775 18.9654 14.1252V10.2797C18.9654 10.0202 18.8645 9.77087 18.6841 9.58437L14.967 5.74274C14.574 5.33655 13.9227 5.33654 13.5297 5.74272L9.16133 10.2573Z" stroke="#7145D8" stroke-width="2" mask={`url(#paint3_linear_${randomFloatString})`}/>
      <rect x="0.5" y="0.5" width="21" height="17" rx="2.5" stroke="#7145D8"/>
      <path d="M6.85 5.25C6.85 6.23261 6.08423 7 5.175 7C4.26577 7 3.5 6.23261 3.5 5.25C3.5 4.26739 4.26577 3.5 5.175 3.5C6.08423 3.5 6.85 4.26739 6.85 5.25Z" stroke="#7145D8"/>
    </svg>
  )
};

const Icon = () => {
  return (
    <div className="quiz-icon">
      <IconDefault />
      <IconOnHover />
    </div>
  );
};

export default Icon;
