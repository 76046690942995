// Core
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Components
import Card from 'components/Card';
import Text from 'components/Text';
import Heading from 'components/Heading';
import TextAreaInput from 'components/Input/Material/TextAreaInput/TextAreaInput';
import CourseGrid from 'routes/Course/components/CourseGrid';

// Styles and Assets
import './RecommendationBlock.scss';

// Other
import { FormattedMessage } from 'react-intl';
import { messages } from 'routes/ExpertsCabinet/CourseEdit/RecommendationBlock/messages';
import { isCourseAdmin } from "utils/helpers";

const RecommendationBlock = ({
  message,
  roles,
  handleInputChange,
  recommendationDescription,
}) => {
  const [recomendationFromAdmin, setRecomendationFromAdmin] = useState(
    recommendationDescription || message
  );

  return (
    <Card classes="sk-course-rec-block__card">
      <CourseGrid>
        <div className="sk-course-rec-block__content">
          <Heading variant="h3" classes="sk-course-rec-block__header">
            <FormattedMessage {...messages.recommendation} />
          </Heading>
          {isCourseAdmin(roles) ? (
            <FormattedMessage {...messages.recommendation}>
              {msg => (
                <TextAreaInput
                  name="recommendationDescription"
                  placeholder={msg}
                  value={recomendationFromAdmin}
                  onChange={newValue => setRecomendationFromAdmin(newValue)}
                  onBlur={handleInputChange}
                />
              )}
            </FormattedMessage>
          ) : (
            <Text>{message}</Text>
          )}
        </div>
      </CourseGrid>
    </Card>
  );
};

RecommendationBlock.propTypes = {
  message: PropTypes.string,
  roles: PropTypes.string,
  handleInputChange: PropTypes.func,
  recommendationDescription: PropTypes.string,
};

export default RecommendationBlock;
