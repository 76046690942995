// Core
import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';
import copy from 'clipboard-copy';
import { withRouter } from 'react-router-dom';
import { compose, } from 'react-apollo';

// Components
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Text from 'components/Text';
import CrossRoundedTrasparent from 'components/Icon/Svg/CrossRoundedTrasparent';
import CheckRoundedTrasparent from 'components/Icon/Svg/CheckRoundedTrasparent';

// Other
import { sharedMessages } from 'services/i18n/sharedMessages/messages';
import { isBlank } from 'utils/helpers';

// Styles and Assets
import './index.scss';

const classes = {
  tableCell: { root: 'mui-override sk-admin-plans-table-row' },
};

const PREFIX = 'sk-admin-plans-table-row';
const DiscountsTableRow = memo(({
  item,
  subscribersCount,
  type,
  index,
  arr,
  intl: { formatMessage },
  history
}) => {
  const openItemPage = useCallback(event => {
    event.preventDefault();
    event.stopPropagation();
    history.push(`/admin/panel/plans/edit/${item.id}`)
  }, [history.push]);

  return (
    <TableRow
      key={item.id}
      className='mui-override sk-admin-plans-table-row'
      onClick={openItemPage}
    >
      <TableCell
        className={`mui-override sk-admin-plans-table-row__cell`}
        align="left"
      >
        <Text size="basic">{item.titleEN}</Text>
      </TableCell>
      <TableCell
        className={`mui-override sk-admin-plans-table-row__cell`}
        align="left"
      >
        <Text size="basic">{item?.monthlyPrice}/{item?.annualPrice}</Text>
      </TableCell>
      <TableCell
        className={`mui-override sk-admin-plans-table-row__cell`}
        align="left"
      >
        <Text size="basic">{isBlank(subscribersCount) ? 0 : subscribersCount}</Text>
      </TableCell>
    </TableRow>
)});

const enhancer = compose(
  withRouter,
  injectIntl
)

export default enhancer(DiscountsTableRow);
