import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  nearestWebinar: {
    id: 'app.components.StudentNearestWebinar.nearestWebinar',
    defaultMessage: 'Nearest Webinar.',
  },
  course: {
    id: 'app.components.NearestWebinarInfo.course',
    defaultMessage: 'Course.',
  },
});
