// Core
import React, { memo, } from 'react';
import { injectIntl } from 'react-intl';
import { compose } from 'react-apollo';

// Components
import CourseDetail from 'routes/CourseDetails/BuyCourse/CourseDetail';

// Icons
import Globe from 'components/Icon/SvgCss/PublicCoursePage/Globe.js';
import Level from 'components/Icon/SvgCss/PublicCoursePage/Level.js';
import Clock from 'components/Icon/SvgCss/PublicCoursePage/Clock.js';
import Duration from 'components/Duration/Duration';

// Other
import { sharedMessages } from 'services/i18n/sharedMessages/messages';
import { messages } from 'routes/CourseDetails/BuyCourse/messages';

// Styles and Assets
import './index.scss';

const BlockInfo = ({
  category,
  subcategory,
  complexity,
  duration,
  language,
  intl: { formatMessage },
}) => {
  return (
    <div className="sk-buy-course-block-info-course-details">
      {(complexity && messages[complexity]) && (
        <CourseDetail icon={Level} text={formatMessage(messages[complexity])} />
      )}
      <CourseDetail icon={Clock} text={<Duration duration={duration} />} />
      <CourseDetail icon={Globe} text={formatMessage(sharedMessages[language])} />
    </div>
  )
};

const enhancer = compose(
  memo,
  injectIntl
);

export default enhancer(BlockInfo);
