import React, { memo } from 'react';
import { compose } from 'react-apollo';

const Icon = ({ ...props }) => {
  return (
    <svg width="12" height="2" viewBox="0 0 12 2" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0H12V2H0V0Z" fill="#610FB6"/>
    </svg>
  );
};

const enhancer = compose(
  memo,
);

export default enhancer(Icon);
