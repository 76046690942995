import GET_COURSE from 'queries/Courses/getCourse.gql';
import { localStore } from 'services/localStorage';

export const Mutation = {
  setVideoUploadindState: (
    _,
    { input: { courseId, lectureId, state } },
    { cache }
  ) => {
    const queryData = {
      query: GET_COURSE,
      variables: {
        input: {
          id: courseId,
        },
      },
    };

    const { getCourse } = cache.readQuery(queryData);

    const currentLection = getCourse.lectures.find(
      ({ id }) => id === lectureId
    );

    const lecturesWithoutCurrent = getCourse.lectures.filter(
      ({ id }) => id !== lectureId
    );

    currentLection.isVideoUploading = state;

    cache.writeQuery({
      ...queryData,
      data: {
        getCourse: {
          ...getCourse,
          lectures: [...lecturesWithoutCurrent, currentLection],
        },
      },
    });
    return null;
  },
  addCourseToCart: async (_, { input }) => {
    const previousState = localStore.getItem('ShoppingCart');

    let newState;
    const newItem = {
      ...input,
      __typename: 'ShoppingCartItem',
    };

    if (previousState) {
      const parsedState = JSON.parse(previousState);

      const items = [
        ...parsedState?.items.filter(({ id }) => id !== input?.id),
        newItem,
      ];

      const newTotalPrice = items
        .map(({ price }) => price)
        .reduce((prev, cur) => prev + cur);

      newState = {
        ...parsedState,
        items,
        __typename: 'ShoppingCart',
        totalPrice: newTotalPrice,
      };
    } else {
      newState = {
        items: [
          {
            ...newItem,
          },
        ],
        totalPrice: newItem.price,
        __typename: 'ShoppingCart',
      };
    }

    localStore.setItem('ShoppingCart', JSON.stringify(newState));
    return null;
  },
  removeCourseFromCart: async (_, { id: courseToRemoveId }) => {
    const previousState = localStore.getItem('ShoppingCart');
    const parsedState = JSON.parse(previousState);
    const items = parsedState?.items.filter(
      ({ id }) => id !== courseToRemoveId
    );

    const courseToRemove = parsedState?.items.find(
      ({ id }) => id === courseToRemoveId
    );

    const resultTotalPrice = parsedState.totalPrice - courseToRemove.price;

    const newState = {
      ...parsedState,
      items,
      totalPrice: resultTotalPrice,
      __typename: 'ShoppingCart',
    };

    localStore.setItem('ShoppingCart', JSON.stringify(newState));
    return null;
  },
  removeShoppingCart: async () => {
    try {
      await localStore.removeItem('ShoppingCart');
      return 'Succesfully removed';
    } catch (error) {
      throw Error(error);
    }
  },
};
