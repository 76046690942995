// Core
import React, { memo, useState } from 'react';
import { graphql, compose } from 'react-apollo';
import { injectIntl } from 'react-intl';

// Components
import Text from 'components/Text';
import PaypalButton from 'components/PaypalButton';
import StripeForm from 'routes/Checkout/components/Stripe';
import PayPalForm from 'routes/Checkout/components/PayPal';

// GraphQL
import CREATE_TRANSACTION from 'mutations/Order/createTransaction.gql';

// Other
import { isBlank } from 'utils/helpers';
import { messages } from 'routes/Checkout/messages';
import { sharedMessages } from 'services/i18n/sharedMessages/messages';

// Styles and Assets
import './index.scss';

const { STRIPE_PUBLIC_KEY } = process.env;

const PREFIX = 'sk-payment';
const Payment = ({
  loading,
  currency,
  currencyLabel,
  amount,
  disabled,
  strategy,
  withPayPal = false,
  handleSuccesfulPayment,
  intl: { formatMessage },
  ...props
}) => {
  const isPayPalSupportCurrency = withPayPal && !disabled && currency !== 'uah';

  return (
    <div className={`${PREFIX}`}>
      {!isBlank(props.header) && props.header}
      <StripeForm
        disabled={disabled}
        isLoading={loading}
        amount={amount}
        currency={currency}
        strategy={strategy}
        handleSuccesfulPayment={handleSuccesfulPayment('stripe')}
      />
      {isPayPalSupportCurrency && (
        <>
          <Text classes={`${PREFIX}__info__stripe__or`}>-- {formatMessage(messages.or)} --</Text>
          <PayPalForm
            amount={amount}
            handleSuccesfulPayment={handleSuccesfulPayment('paypal')}
          />
        </>
      )}
    </div>
  );
};

const enhancer = compose(
  memo,
  injectIntl,
);

export default enhancer(Payment);
