// Core
import React from 'react';

// Components
import Text from 'components/Text/Text';
import RadioButtonLabel from 'components/RadioButtonLabel';

// Other
import { withConvertedPrice } from 'containers/CurrencyProvider/withConvertedPrice';
import Price from './Price';
// Styles and Assets
import './index.scss';

const InlinePrice = ({
  withIcon = false,
  icon: Icon,
  price,
  disabled = false,
}) => {
  return (
    <div className='sk-inline-price sk-inline-price__price'>
      {withIcon ? (
        <Icon />
      ) : null
      // <Text classes="paragraph-sk__light" size="basic" disabled={disabled}>
      //   <Price value={price}/>
      // </Text>
      }
    </div>
  );
};

export default withConvertedPrice(InlinePrice);
