// Core
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// Components
import Image from 'components/Image';
import SearchLoop from 'components/Icon/Svg/SearchLoop';

// Styles and Assets
import './InputWithSearch.scss';

class InputWithSearch extends PureComponent {
  state = {
    isInputVisible: true,
  };

  render() {
    const { handleChange, value, placeholder, ...rest } = this.props;
    const { isInputVisible } = this.state;

    return (
      <div className="sk-input-custom-search__wrapper">
        <input
          className="sk-input-custom-search__field"
          value={value}
          placeholder={placeholder}
          onChange={handleChange}
          {...rest}
        />
        <div
          className="sk-input-custom-search__icon"
          onClick={this.toggleInputView}
          onKeyPress={() => {}}
          role="button"
          tabIndex="0"
        >
          <SearchLoop />
        </div>

      </div>
    );
  }
}

InputWithSearch.defaultProps = {
  value: '',
  handleChange: () => {},
  placeholder: '',
};

InputWithSearch.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  handleChange: PropTypes.func,
};

export default InputWithSearch;
