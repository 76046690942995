// Core
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose, } from 'react-apollo';

// Components
import Text from 'components/Text';
import DocApproved from 'components/Icon/Svg/DocApproved';
import Button from 'components/Button';

// Other
import { FormattedMessage } from 'react-intl';
import { messages } from 'routes/StudentsCabinet/components/StudentHomework/messages';
import { withFileUrl } from 'containers/HOCs/withFileUrl';

// Styles and Assets
import './Step4.scss';

const Step4 = ({ fileUrl, mark }) => {
  return (
    <div className="sk-step4">
      <div className="sk-step4__header">
        <div className="sk-step4__icon">
          <DocApproved />
        </div>
        <div className="sk-step4__text">
          <div>
            <Text size="caption" classes="sk-answerful-step__text">
              <FormattedMessage {...messages.yourHomeworkReviewed} />
            </Text>
          </div>
          <div>
            <Text size="caption" classes="sk-answerful-step__text">
              <FormattedMessage {...messages.yourMarkIs} />{' '}
              <Text bold size="caption">
                {mark}/100
              </Text>
            </Text>
          </div>
        </div>
      </div>
      <a
        href={fileUrl}
        download
        className="sk-step4__action"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button variant="text" color="secondary" size="small">
          <FormattedMessage {...messages.download} />
        </Button>
      </a>
    </div>
  );
}

Step4.propTypes = {
  fileUrl: PropTypes.string,
  mark: PropTypes.number,
};

const enhancer = compose(
  withFileUrl
)

export default enhancer(Step4);
