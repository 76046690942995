import React from 'react';

import './index.scss';

const BlockquoteOnHover = () => (
  <svg
    className="hover"
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.691406" width="32" height="32" rx="2" fill="white" />
    <path
      d="M23.7315 23.8593C23.7315 24.1138 23.5252 24.3201 23.2707 24.3201H10.1124C9.85786 24.3201 9.65155 24.1138 9.65155 23.8593C9.65155 23.6049 9.85786 23.3985 10.1124 23.3985H23.2707C23.5252 23.3985 23.7315 23.6049 23.7315 23.8593ZM20.9552 20.2753C20.9552 20.5298 20.7489 20.7361 20.4944 20.7361H12.8887C12.6342 20.7361 12.4279 20.5298 12.4279 20.2753C12.4279 20.0209 12.6342 19.8145 12.8887 19.8145H20.4944C20.7489 19.8145 20.9552 20.0209 20.9552 20.2753ZM21.8476 16.6401C21.8476 16.8664 21.6642 17.0497 21.438 17.0497H11.8459C11.6197 17.0497 11.4363 16.8664 11.4363 16.6401C11.4363 16.4139 11.6197 16.2305 11.8459 16.2305H21.438C21.6642 16.2305 21.8476 16.4139 21.8476 16.6401ZM20.9552 13.0049C20.9552 13.2594 20.7489 13.4657 20.4944 13.4657H12.8887C12.6342 13.4657 12.4279 13.2594 12.4279 13.0049C12.4279 12.7505 12.6342 12.5441 12.8887 12.5441H20.4944C20.7489 12.5441 20.9552 12.7505 20.9552 13.0049ZM23.7315 9.36974C23.7315 9.59596 23.5482 9.77934 23.3219 9.77934H10.0612C9.83493 9.77934 9.65155 9.59596 9.65155 9.36974C9.65155 9.14353 9.83493 8.96014 10.0612 8.96014H23.3219C23.5482 8.96014 23.7315 9.14353 23.7315 9.36974Z"
      fill="#7145D8"
    />
  </svg>
);

const DefaultBlockquote = () => (
  <svg
    className="default"
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.7315 23.8594C23.7315 24.1138 23.5252 24.3202 23.2707 24.3202H10.1124C9.85786 24.3202 9.65155 24.1138 9.65155 23.8594C9.65155 23.6049 9.85786 23.3986 10.1124 23.3986H23.2707C23.5252 23.3986 23.7315 23.6049 23.7315 23.8594ZM20.9552 20.2754C20.9552 20.5298 20.7489 20.7362 20.4944 20.7362H12.8887C12.6342 20.7362 12.4279 20.5298 12.4279 20.2754C12.4279 20.0209 12.6342 19.8146 12.8887 19.8146H20.4944C20.7489 19.8146 20.9552 20.0209 20.9552 20.2754ZM21.8476 16.6402C21.8476 16.8664 21.6642 17.0498 21.438 17.0498H11.8459C11.6197 17.0498 11.4363 16.8664 11.4363 16.6402C11.4363 16.4139 11.6197 16.2306 11.8459 16.2306H21.438C21.6642 16.2306 21.8476 16.4139 21.8476 16.6402ZM20.9552 13.005C20.9552 13.2594 20.7489 13.4658 20.4944 13.4658H12.8887C12.6342 13.4658 12.4279 13.2594 12.4279 13.005C12.4279 12.7505 12.6342 12.5442 12.8887 12.5442H20.4944C20.7489 12.5442 20.9552 12.7505 20.9552 13.005ZM23.7315 9.36975C23.7315 9.59597 23.5482 9.77935 23.3219 9.77935H10.0612C9.83493 9.77935 9.65155 9.59597 9.65155 9.36975C9.65155 9.14354 9.83493 8.96015 10.0612 8.96015H23.3219C23.5482 8.96015 23.7315 9.14354 23.7315 9.36975Z"
      fill="#6D6E71"
    />
  </svg>
);

const ActiveBlockquote = () => (
  <svg
    className="active"
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.7315 23.8593C23.7315 24.1138 23.5252 24.3201 23.2707 24.3201H10.1124C9.85786 24.3201 9.65155 24.1138 9.65155 23.8593C9.65155 23.6049 9.85786 23.3985 10.1124 23.3985H23.2707C23.5252 23.3985 23.7315 23.6049 23.7315 23.8593ZM20.9552 20.2753C20.9552 20.5298 20.7489 20.7361 20.4944 20.7361H12.8887C12.6342 20.7361 12.4279 20.5298 12.4279 20.2753C12.4279 20.0209 12.6342 19.8145 12.8887 19.8145H20.4944C20.7489 19.8145 20.9552 20.0209 20.9552 20.2753ZM21.8476 16.6401C21.8476 16.8664 21.6642 17.0497 21.438 17.0497H11.8459C11.6197 17.0497 11.4363 16.8664 11.4363 16.6401C11.4363 16.4139 11.6197 16.2305 11.8459 16.2305H21.438C21.6642 16.2305 21.8476 16.4139 21.8476 16.6401ZM20.9552 13.0049C20.9552 13.2594 20.7489 13.4657 20.4944 13.4657H12.8887C12.6342 13.4657 12.4279 13.2594 12.4279 13.0049C12.4279 12.7505 12.6342 12.5441 12.8887 12.5441H20.4944C20.7489 12.5441 20.9552 12.7505 20.9552 13.0049ZM23.7315 9.36974C23.7315 9.59596 23.5482 9.77934 23.3219 9.77934H10.0612C9.83493 9.77934 9.65155 9.59596 9.65155 9.36974C9.65155 9.14353 9.83493 8.96014 10.0612 8.96014H23.3219C23.5482 8.96014 23.7315 9.14353 23.7315 9.36974Z"
      fill="#7145D8"
    />
  </svg>
);

const Blockquote = ({ active }) => {
  const Icon = active ? ActiveBlockquote : DefaultBlockquote;

  return (
    <div className="editor-icon-svg">
      <Icon />
      <BlockquoteOnHover />
    </div>
  );
};

export default Blockquote;
