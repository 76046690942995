import React from 'react';

import './index.scss';

const BlockquoteOnHover = () => (
  <svg
    className="hover"
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.693359" width="32" height="32" rx="2" fill="white" />
    <path 
      d="M13.0143 14.2578L9.61566 17.4132C8.24991 18.6769 8.17142 20.8118 9.44298 22.1776C10.7067 23.5433 12.8417 23.6218 14.2074 22.3503L17.6061 19.1949C18.9718 17.9312 19.0503 15.7962 17.7788 14.4305"
      stroke="#7145D8"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.5257 16.9974L23.9244 13.842C25.2902 12.5783 25.3686 10.4433 24.0971 9.07757C22.8334 7.71182 20.7063 7.63333 19.3405 8.90489L15.9418 12.0602C14.5761 13.324 14.4976 15.4589 15.7691 16.8168"
      stroke="#7145D8"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const DefaultBlockquote = () => (
  <svg
    className="default"
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.0143 14.2578L9.61566 17.4132C8.24991 18.6769 8.17142 20.8118 9.44298 22.1776C10.7067 23.5433 12.8417 23.6218 14.2074 22.3503L17.6061 19.1949C18.9718 17.9312 19.0503 15.7962 17.7788 14.4305"
      stroke="#6D6E71"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.5257 16.9974L23.9244 13.842C25.2902 12.5783 25.3686 10.4433 24.0971 9.07757C22.8334 7.71182 20.7063 7.63333 19.3405 8.90489L15.9418 12.0602C14.5761 13.324 14.4976 15.4589 15.7691 16.8168"
      stroke="#6D6E71"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ActiveBlockquote = () => (
  <svg
    className="active"
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.0143 14.2578L9.61566 17.4132C8.24991 18.6769 8.17142 20.8118 9.44298 22.1776C10.7067 23.5433 12.8417 23.6218 14.2074 22.3503L17.6061 19.1949C18.9718 17.9312 19.0503 15.7962 17.7788 14.4305"
      stroke="#7145D8"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.5257 16.9974L23.9244 13.842C25.2902 12.5783 25.3686 10.4433 24.0971 9.07757C22.8334 7.71182 20.7063 7.63333 19.3405 8.90489L15.9418 12.0602C14.5761 13.324 14.4976 15.4589 15.7691 16.8168"
      stroke="#7145D8"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Blockquote = ({ active }) => {
  const Icon = active ? ActiveBlockquote : DefaultBlockquote;

  return (
    <div className="editor-icon-svg">
      <Icon />
      <BlockquoteOnHover />
    </div>
  );
};

export default Blockquote;
