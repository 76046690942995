import React from 'react';

import './index.scss';

const BlockquoteOnHover = () => (
  <svg
    className="hover"
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.691406" width="32" height="32" rx="2" fill="white" />
    <path
      d="M24.1766 8.4482H12.2726C12.0252 8.4482 11.8246 8.64877 11.8246 8.8962C11.8246 9.14362 12.0252 9.3442 12.2726 9.3442H24.1766C24.424 9.3442 24.6246 9.14362 24.6246 8.8962C24.6246 8.64877 24.424 8.4482 24.1766 8.4482Z"
      fill="#7145D8"
    />
    <path
      d="M24.1766 15.6164H12.2726C12.0252 15.6164 11.8246 15.817 11.8246 16.0644C11.8246 16.3118 12.0252 16.5124 12.2726 16.5124H24.1766C24.424 16.5124 24.6246 16.3118 24.6246 16.0644C24.6246 15.817 24.424 15.6164 24.1766 15.6164Z"
      fill="#7145D8"
    />
    <path
      d="M24.1766 21.8884H12.2726C12.0252 21.8884 11.8246 22.089 11.8246 22.3364C11.8246 22.5839 12.0252 22.7844 12.2726 22.7844H24.1766C24.424 22.7844 24.6246 22.5839 24.6246 22.3364C24.6246 22.089 24.424 21.8884 24.1766 21.8884Z"
      fill="#7145D8"
    />
    <path
      d="M7.98532 10.2405C8.69225 10.2405 9.26532 9.66744 9.26532 8.96051C9.26532 8.25359 8.69225 7.68051 7.98532 7.68051C7.2784 7.68051 6.70532 8.25359 6.70532 8.96051C6.70532 9.66744 7.2784 10.2405 7.98532 10.2405Z"
      fill="#7145D8"
    />
    <path
      d="M7.98532 17.2802C8.69225 17.2802 9.26532 16.7071 9.26532 16.0002C9.26532 15.2933 8.69225 14.7202 7.98532 14.7202C7.2784 14.7202 6.70532 15.2933 6.70532 16.0002C6.70532 16.7071 7.2784 17.2802 7.98532 17.2802Z"
      fill="#7145D8"
    />
    <path
      d="M7.98532 23.6802C8.69225 23.6802 9.26532 23.1072 9.26532 22.4002C9.26532 21.6933 8.69225 21.1202 7.98532 21.1202C7.2784 21.1202 6.70532 21.6933 6.70532 22.4002C6.70532 23.1072 7.2784 23.6802 7.98532 23.6802Z"
      fill="#7145D8"
    />
  </svg>
);

const DefaultBlockquote = () => (
  <svg
    className="default"
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.1766 8.4482H12.2726C12.0252 8.4482 11.8246 8.64877 11.8246 8.8962C11.8246 9.14362 12.0252 9.3442 12.2726 9.3442H24.1766C24.424 9.3442 24.6246 9.14362 24.6246 8.8962C24.6246 8.64877 24.424 8.4482 24.1766 8.4482Z"
      fill="#6D6E71"
    />
    <path
      d="M24.1766 15.6164H12.2726C12.0252 15.6164 11.8246 15.817 11.8246 16.0644C11.8246 16.3118 12.0252 16.5124 12.2726 16.5124H24.1766C24.424 16.5124 24.6246 16.3118 24.6246 16.0644C24.6246 15.817 24.424 15.6164 24.1766 15.6164Z"
      fill="#6D6E71"
    />
    <path
      d="M24.1766 21.8884H12.2726C12.0252 21.8884 11.8246 22.089 11.8246 22.3364C11.8246 22.5839 12.0252 22.7844 12.2726 22.7844H24.1766C24.424 22.7844 24.6246 22.5839 24.6246 22.3364C24.6246 22.089 24.424 21.8884 24.1766 21.8884Z"
      fill="#6D6E71"
    />
    <path
      d="M7.98532 10.2405C8.69225 10.2405 9.26532 9.66744 9.26532 8.96051C9.26532 8.25359 8.69225 7.68051 7.98532 7.68051C7.2784 7.68051 6.70532 8.25359 6.70532 8.96051C6.70532 9.66744 7.2784 10.2405 7.98532 10.2405Z"
      fill="#6D6E71"
    />
    <path
      d="M7.98532 17.2802C8.69225 17.2802 9.26532 16.7071 9.26532 16.0002C9.26532 15.2933 8.69225 14.7202 7.98532 14.7202C7.2784 14.7202 6.70532 15.2933 6.70532 16.0002C6.70532 16.7071 7.2784 17.2802 7.98532 17.2802Z"
      fill="#6D6E71"
    />
    <path
      d="M7.98532 23.6802C8.69225 23.6802 9.26532 23.1072 9.26532 22.4002C9.26532 21.6933 8.69225 21.1202 7.98532 21.1202C7.2784 21.1202 6.70532 21.6933 6.70532 22.4002C6.70532 23.1072 7.2784 23.6802 7.98532 23.6802Z"
      fill="#6D6E71"
    />
  </svg>
);

const ActiveBlockquote = () => (
  <svg
    className="active"
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.1766 8.44818H12.2726C12.0252 8.44818 11.8246 8.64876 11.8246 8.89618C11.8246 9.1436 12.0252 9.34418 12.2726 9.34418H24.1766C24.424 9.34418 24.6246 9.1436 24.6246 8.89618C24.6246 8.64876 24.424 8.44818 24.1766 8.44818Z"
      fill="#7145D8"
    />
    <path
      d="M24.1766 15.6164H12.2726C12.0252 15.6164 11.8246 15.817 11.8246 16.0644C11.8246 16.3118 12.0252 16.5124 12.2726 16.5124H24.1766C24.424 16.5124 24.6246 16.3118 24.6246 16.0644C24.6246 15.817 24.424 15.6164 24.1766 15.6164Z"
      fill="#7145D8"
    />
    <path
      d="M24.1766 21.8884H12.2726C12.0252 21.8884 11.8246 22.089 11.8246 22.3364C11.8246 22.5839 12.0252 22.7844 12.2726 22.7844H24.1766C24.424 22.7844 24.6246 22.5839 24.6246 22.3364C24.6246 22.089 24.424 21.8884 24.1766 21.8884Z"
      fill="#7145D8"
    />
    <path
      d="M7.98532 10.2405C8.69225 10.2405 9.26532 9.66744 9.26532 8.96051C9.26532 8.25359 8.69225 7.68051 7.98532 7.68051C7.2784 7.68051 6.70532 8.25359 6.70532 8.96051C6.70532 9.66744 7.2784 10.2405 7.98532 10.2405Z"
      fill="#7145D8"
    />
    <path
      d="M7.98532 17.2802C8.69225 17.2802 9.26532 16.7071 9.26532 16.0002C9.26532 15.2933 8.69225 14.7202 7.98532 14.7202C7.2784 14.7202 6.70532 15.2933 6.70532 16.0002C6.70532 16.7071 7.2784 17.2802 7.98532 17.2802Z"
      fill="#7145D8"
    />
    <path
      d="M7.98532 23.6802C8.69225 23.6802 9.26532 23.1072 9.26532 22.4002C9.26532 21.6933 8.69225 21.1202 7.98532 21.1202C7.2784 21.1202 6.70532 21.6933 6.70532 22.4002C6.70532 23.1072 7.2784 23.6802 7.98532 23.6802Z"
      fill="#7145D8"
    />
  </svg>
);

const Blockquote = ({ active }) => {
  const Icon = active ? ActiveBlockquote : DefaultBlockquote;

  return (
    <div className="editor-icon-svg">
      <Icon />
      <BlockquoteOnHover />
    </div>
  );
};

export default Blockquote;
