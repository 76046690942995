// Core
import React, { useEffect } from 'react';
import { compose, graphql } from 'react-apollo';
import { FormattedMessage } from 'react-intl';

//Components
import Button from 'components/Button';
import RateModal from './RateModal';

// Other
import { withModal } from 'containers/ModalProvider/withModal';
import { withNotification } from 'containers/NotificationProvider/withNotification';
import { messages } from 'routes/StudentsCabinet/ToppingDetails/messages.js';

// Styles
import './RateTopping.scss';

const COMPONENT_PREFIX = `sk-topping-details_rating`;

const RateTopping = ({
  isVideoFinished,
  courseId,
  showNotificationBar,
  modalContext: { showModal, hideModal }
}) => {
  const handleOpenModal = mappingId => () => {
    showModal(() => (<RateModal courseId={courseId} handleClose={hideModal} />), { handleClick: hideModal, size: 'lg' });
  };

  useEffect(()=>{
    if(isVideoFinished){
      showModal(() => (<RateModal courseId={courseId} handleClose={hideModal} />), { handleClick: hideModal, size: 'lg' });
    }
  }, [isVideoFinished, showModal, hideModal]);

  return(
    <div className={COMPONENT_PREFIX}>
      <Button
        onClick={handleOpenModal(courseId)}
        color="secondary"
        variant="text"
      >
        <FormattedMessage {...messages.evaluateButton} />
      </Button>
    </div>
  );
};

const enhancer = compose(
  withModal
);

export default enhancer(RateTopping);
