// Core
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';

// Components
import ChevronDown from 'components/Icon/Svg/ChevronDown';

class ExpansionPanel extends PureComponent {
  state = {
    expanded: null,
  };

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  render() {
    const { expanded } = this.state;
    const {
      id,
      ExpansionDetailsComponent,
      ExpansionPanelSummaryComponent,
      panelClasses,
      panelSummaryClasses,
      panelDetailsClasses,
      iconButtonClasses,
      expandIcon,
    } = this.props;
    return (
      <MuiExpansionPanel
        expanded={expanded === id}
        onChange={this.handleChange(id)}
        classes={panelClasses}
      >
        <MuiExpansionPanelSummary
          classes={panelSummaryClasses}
          expandIcon={expandIcon}
          IconButtonProps={{
            classes: iconButtonClasses,
          }}
        >
          {ExpansionPanelSummaryComponent}
        </MuiExpansionPanelSummary>
        <MuiExpansionPanelDetails classes={panelDetailsClasses}>
          {ExpansionDetailsComponent}
        </MuiExpansionPanelDetails>
      </MuiExpansionPanel>
    );
  }
}

ExpansionPanel.defaultProps = {
  ExpansionDetailsComponent: () => {},
  ExpansionPanelSummaryComponent: () => {},
  expandIcon: <ChevronDown width="15" height="9.55" />,
};

ExpansionPanel.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ExpansionDetailsComponent: PropTypes.element,
  ExpansionPanelSummaryComponent: PropTypes.element,
  panelClasses: PropTypes.object,
  panelSummaryClasses: PropTypes.object,
  panelDetailsClasses: PropTypes.object,
  iconButtonClasses: PropTypes.object,
  expandIcon: PropTypes.object,
};

export default ExpansionPanel;
