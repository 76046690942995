// Core
import React, { memo } from 'react';
import { injectIntl } from 'react-intl';
import { compose } from 'react-apollo';

// Components
import Text from 'components/Text';
import Card from 'components/Card';
import Button from 'components/Button';

// Icons
import FacebookIcon from 'routes/StudentsCabinet/PurchasedCourses/PurchasedCoursesMessenger/icons/facebook.js';
import ViberIcon from 'routes/StudentsCabinet/PurchasedCourses/PurchasedCoursesMessenger/icons/viber.js';
import SlackIcon from 'routes/StudentsCabinet/PurchasedCourses/PurchasedCoursesMessenger/icons/slack.js';
import WhatsappIcon from 'routes/StudentsCabinet/PurchasedCourses/PurchasedCoursesMessenger/icons/whatsapp.js';
import TelegramIcon from 'routes/StudentsCabinet/PurchasedCourses/PurchasedCoursesMessenger/icons/telegram.js';
import VkIcon from 'routes/StudentsCabinet/PurchasedCourses/PurchasedCoursesMessenger/icons/vkontakte.js';
import DefaultIcon from 'routes/StudentsCabinet/PurchasedCourses/PurchasedCoursesMessenger/icons/default.js';

// Styles and Assets
import './index.scss';

import { messages } from 'routes/StudentsCabinet/PurchasedCourses/messages';

const MESSENGERS = {
  FACEBOOK: {
    domainName: 'facebook.com',
    icon: FacebookIcon,
  },
  SLACK: {
    domainName: 'slack.com',
    icon: SlackIcon,
  },
  TELEGRAM: {
    domainName: 't.me',
    icon: TelegramIcon,
  },
  VIBER: {
    domainName: 'viber.com',
    icon: ViberIcon,
  },
  VKONTAKTE: {
    domainName: 'vk.com',
    icon: VkIcon,
  },
  WHATSAPP: {
    domainName: 'whatsapp.com',
    icon: WhatsappIcon,
  },
  DEFAULT: {
    domainName: '',
    icon: DefaultIcon,
  },
}

const getMessengerType = ({ messengerUrl }) => {
  for (const messengerType in MESSENGERS) {
    if (messengerUrl?.includes(MESSENGERS[messengerType]?.domainName)) {
      return MESSENGERS[messengerType];
    };
  }
  return MESSENGERS.DEFAULT;
};

const PurchasedCoursesMessenger = ({ messengerUrl, intl: { formatMessage }}) => {
  const hasUrl = messengerUrl?.length > 0;

  if (!hasUrl) return null;

  const messenger = getMessengerType({ messengerUrl });
  const Icon = messenger.icon;

  const condition = new RegExp(/^http(s)?:\/\/+/);
  const validatedUrl = condition.test(messengerUrl) ? messengerUrl : `https://${messengerUrl}`;

  return (
    <div className="sk-purchased-course-messenger">
      <Card>
        <Icon />
        <Text>{formatMessage(messages.chat)}</Text>
        <a href={validatedUrl} target="_blank">
          <Button
            color="primary"
            transparent
          >
            {formatMessage(messages.startChat)}
          </Button>
        </a>
      </Card>
    </div>
  )
};

const enhancer = compose(
  memo,
  injectIntl
)

export default enhancer(PurchasedCoursesMessenger);
