// Core
import React, { useState, useEffect, useCallback } from 'react';
import { compose } from 'react-apollo';
import { FormattedMessage } from 'react-intl';
import { Progress } from 'react-sweet-progress';
import ReactImage from 'react-image';
import Avatar from '@material-ui/core/Avatar';

// Components
import Button from 'components/Button';
import Text from 'components/Text';
import TextInput from 'components/Input/Material/TextInput';
import FileUploadModule from 'routes/Course/components/FileUploadModule';
import { EmptyPreview, } from 'components/FilePreview';
import PhotoUploader from 'components/PhotoUploader';
import UserProfilePhoto from 'routes/ExpertsCabinet/CourseEdit/GeneralInfo/ActionPanel/UploadUserPhotoModal/UserProfilePhoto';
import AvatarUploader from 'components/AvatarUploader/';

// Other
import { s3UploadAvatar } from 'services/aws/amplify/helpers';
import { DEFAULT_MAX_FILE_SIZE, SUPPORTED_FORMATS_IMAGE } from 'utils/enums/index';
import { isNull } from 'utils/helpers/index';
import { messages } from 'routes/ExpertsCabinet/CourseEdit/messages';
import { sharedMessages } from 'services/i18n/sharedMessages/messages';
import { withAuthenticatedUser } from 'containers/AuthenticatedUserProvider/withAuthenticatedUser';
import { getSeparatedFilename } from 'services/aws/amplify/helpers';
import slugify from '@sindresorhus/slugify';

// Styles and Assets
import './index.scss';

const UploadUserPhotoModal = ({
  activateCourse, userContext: { getProfile, profile }, close,
}) => {
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(null);
  const [loading, setLoading] = useState(false);

  const isFileAdded = !isNull(file);
  const progressCallback = progress => {
    setProgress(() => progress);
  };
  const uploadPhoto = async (file) => {
    if (isNull(file) || !file) {
      return 'No file added!';
    }

    setFile(() => file);

    setLoading(true);
    await s3UploadAvatar({ file, progressCallback });
    setTimeout(async() => {
      await getProfile();
      setLoading(false);
    }, 5000);
  };

  const handleActiveCourse = useCallback(() => {
    activateCourse();
    close();
  }, []);

  return (
    <div className="sk-upload-expert-photo-modal">
      <Text className="sk-upload-expert-photo-modal__title"><FormattedMessage {...messages.youPhotoIsRequired} /></Text>
      <Text className="sk-upload-expert-photo-modal__text"><FormattedMessage {...messages.pleaseUploadePhoto} /></Text>
      <Text className="sk-upload-expert-photo-modal__text"><FormattedMessage {...messages.itWillBePartOf} /></Text>
      {!isFileAdded ? (
        <>
          <FileUploadModule
            supportedMimetypes={['image/png', 'image/jpeg']}
            supportedFormats={SUPPORTED_FORMATS_IMAGE}
            maxSize={DEFAULT_MAX_FILE_SIZE}
            handleFileAdd={uploadPhoto}
            lectureId={'expert'}
            file={file}
          />
          <Text className="sk-upload-expert-photo-modal__requirements"><FormattedMessage {...messages.photoRequirements} /></Text>
        </>
      ) : (
        <UserProfilePhoto
          loading={loading}
          progress={progress}
          imageUrl={URL.createObjectURL(file)}
          profile={profile}
          handleFileChange={uploadPhoto}
          activateCourse={handleActiveCourse}
        />
      )}
    </div>
  );
};

const enhancer = compose(
  withAuthenticatedUser
);

export default enhancer(UploadUserPhotoModal);
