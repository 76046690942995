// Core
import React, { memo } from 'react';

// components
import Card from 'components/Card';
import Text from 'components/Text';
import Mountain from 'components/Icon/Svg/Mountain';

import { FormattedMessage } from 'react-intl';

import { messages } from 'routes/LandingPage/components/NoCoursesFound/messages';

// Styles
import './NoCoursesFound.scss';

export const NoCoursesFound = () => (
  <div className="sk-no-courses-found">
    <Card classes="sk-no-courses-found__card">
      <Text classes="sk-no-courses-found__heading" semiBold newLine>
        <FormattedMessage {...messages.noCourses} />
      </Text>
      <div className="sk-no-courses-found__icon">
        <Mountain />
      </div>
    </Card>
  </div>
);

export default memo(NoCoursesFound);
