// Core
import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

// Material Components
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
// import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';

// Components
import Text from 'components/Text';
import CoursesTableRow from 'routes/AdminPanel/UserView/UserCourses/CoursesTable/CoursesTableRow/CoursesTableRow';

// Styles and Assets
import './CoursesTable.scss';

const classes = {
  table: {
    root: 'mui-override sk-сoursesTable',
  },
};


class CoursesTable extends PureComponent {

  getCourseStatus = ({status_id}, purchasedCourse) => {
    if (purchasedCourse) {
      const {course_status} = purchasedCourse;
      return course_status;
    }
    return status_id;
  }

  renderTableBody = (courses, purchasedCourses) => {
    return courses.map((item, index, arr) => (
      <Fragment key={item.id}>
        <CoursesTableRow
          key={item.id}
          item={item}
          index={index}
          status={this.getCourseStatus(
            item,
            purchasedCourses.find(purchased => purchased && purchased.course_id === item.id)
          )}
          arr={arr}
        />
      </Fragment>
    ));
  };

  render() {
    const { courses, purchasedCourses = []} = this.props;

    const headerHeight = 63;
    const rowHeight = 63;
    const rowsCount = +courses?.length || 0;

    return (
      <Table
        className="mui-override sk-сoursesTable"
        classes={classes.table}
        style={{ height: headerHeight + (rowHeight*rowsCount) }}
      >
        <TableHead>
          <TableRow>
            <TableCell
              classes={classes.table}
              className="mui-override sk-сoursesTable__cell"
              align="left"
            >
              <div className='mui-override sk-сoursesTable__cell__elements'>
                <Text bold align="left" onClick={this.handleSortByTitle}>
                  COURSE
                </Text>
              </div>
            </TableCell>
            <TableCell
              classes={classes.table}
              className="mui-override sk-сoursesTable__cell"
            >
              <div className='mui-override sk-сoursesTable__cell__elements'>
                <Text bold align="left" onClick={this.handleSortByCategory}>
                  CATEGORY
                </Text>
              </div>
            </TableCell>
            <TableCell
              classes={classes.table}
              className="mui-override sk-сoursesTable__cell"
            >
              <div className='mui-override sk-сoursesTable__cell__elements'>
                <Text bold align="left" onClick={this.handleSortBySubcategory}>
                  SUBCATEGORY
                </Text>
              </div>
            </TableCell>
            <TableCell
              classes={classes.table}
              className="mui-override sk-сoursesTable__cell"
            >
              <div className='mui-override sk-сoursesTable__cell__elements'>
                <Text bold align="left" onClick={this.handleSortByStatus}>
                  STATUS
                </Text>
              </div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <Fragment>
            {courses && courses.length > 0 ? this.renderTableBody(courses, purchasedCourses) : null}
          </Fragment>
        </TableBody>
      </Table>
    );
  }
}

CoursesTable.propTypes = {
  courses: PropTypes.array,
  purchasedCourses: PropTypes.array,
};

export default CoursesTable;
