// Core
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Styles
import './Text.scss';

const Text = ({
  size,
  children,
  bold,
  semiBold,
  accent,
  classes,
  error,
  light,
  disabled,
  newLine,
  ...rest
}) => {
  const textClassNames = classNames(
    `paragraph-sk paragraph-sk_${size}`,
    {
      'paragraph-sk_accent': accent,
      'paragraph-sk_bold': bold,
      'paragraph-sk_light': light,
      'paragraph-sk_semiBold': semiBold,
      'paragraph-sk_error': error,
      'paragraph-sk_new-line': newLine,
    },
    { disabled },
    { [classes]: classes }
  );

  const Tag = size === 'basic' ? 'p' : 'span';

  return (
    <Tag className={textClassNames} {...rest}>
      {children}
    </Tag>
  );
};

Text.defaultProps = {
  size: 'basic',
};

Text.propTypes = {
  size: PropTypes.string,
  children: PropTypes.any,
  bold: PropTypes.bool,
  semiBold: PropTypes.bool,
  accent: PropTypes.bool,
  error: PropTypes.bool,
  classes: PropTypes.string,
};

export default Text;
