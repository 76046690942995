import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  evalYourExpert: {
    id: 'app.components.EndCourseModal.evalYourExpert',
    defaultMessage:
      'Evaluate your expert using apple or tomato. ' +
      'If you are satisfied with the level of studying and feel that ' +
      'this course was useful for you – rate your expert using apple. ' +
      'If you’re not happy with teaching level you can vote with tomato.',
  },
  submit: {
    id: 'app.components.EndCourseModal.submit',
    defaultMessage: 'Complete course',
  },
  congrats: {
    id: 'app.components.EndCourseModal.congrats',
    defaultMessage: "Congratulations! You've just finished your course",
  },
  pleaseRateCourse: {
    id: 'app.components.EndCourseModal.pleaseRateCourse',
    defaultMessage: 'Please rate the course and expert of it – Joshua Tallent',
  },
  rateCourse: {
    id: 'app.components.EndCourseModal.rateCourse',
    defaultMessage: 'Rate the course',
  },
  chooseRating: {
    id: 'app.components.EndCourseModal.chooseRating',
    defaultMessage: 'Сhoose rating',
  },
  pleaseLetUsKnow: {
    id: 'app.components.EndCourseModal.pleaseLetUsKnow',
    defaultMessage: 'Please, let us know, what do you think about the course',
  },
  rateExpert: {
    id: 'app.components.EndCourseModal.rateExpert',
    defaultMessage: 'Rate the expert',
  },
  pleaseAddFeedback: {
    id: 'app.components.EndCourseModal.pleaseAddFeedback',
    defaultMessage: 'Please, add feedback about the expert',
  },
});
