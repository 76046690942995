// Core
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';
import withWidth from 'containers/HOCs/withWidth';

// Components
import IconWithCounter from 'components/Icon/IconWithCounter';
import RoundIcon from 'components/Icon/RoundIcon';
import Upload from 'components/Icon/SvgCss/Upload';
import UploadMenu from 'containers/Header/UploadBlock/UploadMenu';
import FlatDropDown from 'components/DropDown/FlatDropDown';

import { withUploadManager } from 'containers/UploadManagerProvider/withUploadManager';

// Styles and Assets
import { White } from 'components/Icon/color';

const UploadMenuToggler = memo(({ count }) => (
  <IconWithCounter icon={<Upload color={White} />} count={count} />
));

export const UploadBlock = ({
  uploadManagerContext: { queue, progress },
  width,
}) => {
  const placement = 'bottom-end';

  return (
    <FlatDropDown
      placement={placement}
      component={UploadMenu}
      uploadingItems={queue}
      progress={progress}
      disablePortal
    >
      <UploadMenuToggler count={queue?.length} />
    </FlatDropDown>
  );
};


UploadBlock.propTypes = {
  width: PropTypes.string,
};

UploadBlock.propTypes = {
  count: PropTypes.number,
};

const enhancer = compose(
  memo,
  withWidth(),
  withUploadManager
);

export default enhancer(UploadBlock);
