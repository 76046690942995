// Core
import React, { memo } from "react";
import { Formik } from "formik";
import { compose, graphql } from "react-apollo";

// Components
// import TextInput from "components/Input/Material/TextInput/TextInput";
import TextInput from "components/Input/Material/TextInput";
import AutoComplete from "components/Select/AutoComplete";
import Button from "components/Button";
import Heading from "components/Heading";

// Other
import { getYupSchema } from "services/yup";
import { FormattedMessage } from "react-intl";
import { messages } from "routes/AdminPanel/UsersList/InviteForm/messages";
import INVITE_MUTATION from "mutations/AdminPanel/invite.gql";
import { getFQDN } from "utils/helpers";

// Styles and Assets
import "./InviteForm.scss";

const handleEnterPress = (params1, params2) => {
  const charCode = params1?.charCode || params2?.charCode;
  if (charCode == 13) handleSubmit(props.values); // eslint-disable-line
};

const ROLES = [
  { key: "manager", label: "Manager" },
  { key: "expert", label: "Expert" },
  { key: "student", label: "Student" },
];

const defaultFields = {
  email: "",
  role: "",
  firstName: "",
  lastName: "",
};

export const InviteForm = ({
  handleInvitationSubmit,
  sendInvitation,
  companyId,
}) => (
  <Formik
    onSubmit={async (values, formikBag) => {
      const { email, role, firstName, lastName } = values;
      await sendInvitation({
        email,
        role: role.key,
        org: companyId,
        firstName,
        lastName,
        fqdn: getFQDN(),
      });
      return handleInvitationSubmit(values, formikBag);
    }}
    initialValues={defaultFields}
    validationSchema={getYupSchema("inviteSchema")}
    validateOnChange={false}
    validateOnBlur
  >
    {(props) => {
      const {
        values: { email, role, firstName, lastName },
        errors,
        touched,
        handleSubmit,
        handleBlur,
        setFieldValue,
      } = props;

      const handleInputChange = (name) => (inputValue, e) => {
        setFieldValue(name, inputValue);
        if (e) e.persist();
      };

      const handleInputBlur = (name) => (inputValue, e) => {
        if (e) {
          e.persist();
          handleBlur(e);
        }
        setFieldValue(name, inputValue);
      };

      return (
        <form
          className="sk-pw-change-form"
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <div className="sk-invite-form_inner">
            <Heading variant="h2" newLine>
              <FormattedMessage {...messages.invite} />
            </Heading>
            <div className="sk-invite_row">
              <FormattedMessage {...messages.firstName}>
                {(msg) => (
                  <TextInput
                    type="text"
                    name="firstName"
                    helperText={touched.firstName ? errors.firstName : ""}
                    error={touched.firstName && Boolean(errors.firstName)}
                    value={firstName}
                    label={msg}
                    onChange={handleInputChange("firstName")}
                    onBlur={handleInputBlur("firstName")}
                  />
                )}
              </FormattedMessage>
            </div>
            <div className="sk-invite_row">
              <FormattedMessage {...messages.lastName}>
                {(msg) => (
                  <TextInput
                    type="text"
                    name="lastName"
                    helperText={touched.lastName ? errors.lastName : ""}
                    error={touched.lastName && Boolean(errors.lastName)}
                    value={lastName}
                    label={msg}
                    onChange={handleInputChange("lastName")}
                    onBlur={handleInputBlur("lastName")}
                  />
                )}
              </FormattedMessage>
            </div>
            <div className="sk-invite_row">
              <FormattedMessage {...messages.email}>
                {(msg) => (
                  <TextInput
                    type="email"
                    name="email"
                    helperText={touched.email ? errors.email : ""}
                    error={touched.email && Boolean(errors.email)}
                    value={email}
                    label={msg}
                    onChange={handleInputChange("email")}
                    onBlur={handleInputBlur("email")}
                  />
                )}
              </FormattedMessage>
              <FormattedMessage {...messages.role}>
                {(msg) => (
                  <AutoComplete
                    options={ROLES}
                    label={msg}
                    labelKey="label"
                    valueKey="key"
                    value={role}
                    handleBlur={handleInputChange("role")}
                    handleChange={handleInputChange("role")}
                    handleSearch={handleInputChange("role")}
                    onKeyPress={handleEnterPress}
                  />
                )}
              </FormattedMessage>
            </div>
          </div>
          <div className="sk-pw-change-form_footer">
            <Button variant="text" color="secondary" size="large" type="submit">
              <FormattedMessage {...messages.invite} />
            </Button>
          </div>
        </form>
      );
    }}
  </Formik>
);

const inviteUserMutation = graphql(INVITE_MUTATION, {
  props: ({ mutate }) => ({
    sendInvitation: (input) => mutate({ variables: { input } }),
  }),
  options: ({ refetchQueries }) => ({
    refetchQueries,
  }),
});

const enhancer = compose(inviteUserMutation);

export default enhancer(InviteForm);
