// Core
import React, { memo, useCallback } from 'react';
import { compose } from 'react-apollo';

// Components
import RoundIcon from 'components/Icon/RoundIcon';
import TrashBin from 'components/Icon/Svg/TrashBin';
import ConfirmDelete from 'routes/ExpertsCabinet/components/ConfirmDelete/ConfirmDelete';
import CourseDeletionSurvey from 'routes/ExpertsCabinet/CourseEdit/GeneralInfo/ActionPanel/DeleteCourseButton/CourseDeletionSurvey';

// Other
import withAction from 'components/Icon/withAction';
import { withModal } from 'containers/ModalProvider/withModal';

const DeleteCourseButton = ({
  action,
  disabled,
  askDeleteReasons,
  handleClick: handleDelete,
  modalContext: { showModal, hideModal },
  ...props
}) => {
  const confirmDelete = useCallback(async () => {
    if (!disabled) {
      showModal(ConfirmDelete, { handleDelete, close: hideModal });
    };
  }, [disabled, showModal, hideModal, handleDelete])

  const openDeleteReasonPoll = useCallback(() => {
    showModal(CourseDeletionSurvey, {
      size: 'md',
      handleDelete,
    });
  }, [handleDelete, showModal]);

  const handler = askDeleteReasons ? openDeleteReasonPoll : confirmDelete;
  const active = !disabled;

  return (
    <button type="button" onClick={handler}>
      <RoundIcon
        action={!disabled}
        type={active ? 'flat_action' : 'flat'}
        icon={
          <TrashBin
            disabled={disabled}
            width="17"
            height="19"
            active={active && action && !disabled}
          />
        }
      />
    </button>
  );
};

const enhancer = compose(
  memo,
  withModal,
  withAction
);

export default enhancer(DeleteCourseButton);
