// Core
import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';

// Other
import { cleanUpStyleString } from 'utils/helpers';

// Styles
import './Card.scss';

const getCardClasses = classes => ({
  root: `mui-override sk-card ${cleanUpStyleString(classes)}`,
});

const Card = ({ children, classes, ...rest }) => (
  <Paper classes={getCardClasses(classes)} {...rest}>
    {children}
  </Paper>
);

Card.propTypes = {
  children: PropTypes.any,
  classes: PropTypes.string,
};

export default Card;
