import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Red, Grey, Grey5, PurpleHeart } from 'components/Icon/color';

const getFactor = (width, strokeWidth) =>
  strokeWidth ? (width - strokeWidth) / 2 : width / 2;

const getStrokeWidth = checked => (checked ? 3 : 1);

const getStrokeColor = (info, checked, disabled) => {
  if (disabled) {
    return Grey5;
  }
  if (info) {
    return PurpleHeart;
  }
  if (checked) {
    return '#30d5c8';
  }
  return Grey;
};

const getFillColor = disabled => {
  if(disabled){
    return Grey5
  }
  return '#fff';
}

const Tor = memo(
  ({ height, width, checked, fill, info, disabled, ...rest }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      {...rest}
    >
      <defs>
        <circle id="a-tor" cx="0" cy="0" r={getFactor(width)} />
      </defs>
      <g
        fill="none"
        fillRule="evenodd"
        transform={`translate(${getFactor(width)} ${getFactor(width)})`}
      >
        <use fill={fill ? Grey5 : '#FFF'} xlinkHref="#a-tor" />
        <circle
          cx="0"
          cy="0"
          r={getFactor(width, getStrokeWidth(checked))}
          stroke={getStrokeColor(info, checked, disabled)}
          fill={getFillColor(disabled)}
          strokeLinejoin="square"
          strokeWidth={getStrokeWidth(checked)}
        />
      </g>
    </svg>
  )
);

Tor.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  strokeWidth: PropTypes.string,
};

Tor.defaultProps = {
  height: '12',
  width: '12',
};

export default Tor;
