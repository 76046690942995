// Core
import React, { memo } from 'react';
import timezones from 'google-timezones-json';
import memoize from 'memoize-one';
import PropTypes from 'prop-types';

// Components
import AutoComplete from 'components/Select/AutoComplete';

// Other

// Styles and Assets
// import './TimezonesSelect.scss';

const getParsedTimezonesList = memoize(list =>
  Object.entries(list).map(zone => ({
    id: zone[0],
    label: `${zone[1].split(' ')[0]}  ${zone[0]}`,
  }))
);

const getValue = memoize((list, id) => list.find(opt => opt.id === id));

export const TimezonesSelect = ({
  error,
  handleChange,
  value: id,
  ...props
}) => {
  const options = getParsedTimezonesList(timezones);
  return (
    <AutoComplete
      options={options}
      error={error}
      valueKey="id"
      labelKey="label"
      handleSearch={handleChange}
      isSearchable={false}
      isClearable={false}
      value={getValue(options, id) || options[0].id}
      {...props}
    />
  );
};

TimezonesSelect.propTypes = {
  handleChange: PropTypes.func,
};

export default memo(TimezonesSelect);
