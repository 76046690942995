import React from 'react';
import PropTypes from 'prop-types';
import { PurpleLight } from 'components/Icon/color';

const SearchLoop = ({ height=23, width=23, color=PurpleLight }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 23 23"
  >
  <circle cx="10.5" cy="10.5" r="7.75" stroke={color} strokeWidth="1.5" fill="transparent"/>
  <path d="M16 16.5L21 21.5" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);


export default SearchLoop;
