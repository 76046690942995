// Core
import React, { useState } from 'react';
import { compose } from 'react-apollo';

// Components
import UserAvatarUpload from 'components/UserAvatarUpload';
import PhotoUploader from 'components/PhotoUploader';

// Other
import { withAuthenticatedUser } from 'containers/AuthenticatedUserProvider/withAuthenticatedUser';
import { s3UploadAvatar } from 'services/aws/amplify/helpers';
import { DEFAULT_MAX_FILE_SIZE, SUPPORTED_FORMATS_IMAGE } from 'utils/enums/index';
import { isNull } from 'utils/helpers/index';

// Styles and Assets
import './AvatarUploader.scss';

const isNewFileAdded = file => file instanceof File;

const AvatarUploader = ({
  userContext: { profile, getProfile }, avatarClasses, uploaderId
}) => {
  const [file, setFile] = useState(profile?.photo);
  const [progress, setProgress] = useState(null);
  const progressCallback = progress => {
    setProgress(() => progress);
  };
  const uploadPhoto = async fileList => {
    const file = fileList[0];

    setFile(() => file);
    if ((isNull(file) || !file) && !isNewFileAdded(file)) return 'No file added!';

    await s3UploadAvatar({ file, progressCallback });
    setTimeout(async() => {
      await getProfile();
    }, 5000);
  };

  const fileUrl = isNewFileAdded(file) ? URL.createObjectURL(file) : profile?.photo;

  return (
    <div className="sk-avatar-uploader">
      <UserAvatarUpload
        sizes={{
          width: 140,
          height: 140,
        }}
        imageUrl={fileUrl}
        firstName={profile?.firstName}
        lastName={profile?.lastName}
        withProgress={true}
        progress={progress}
      />

      <div className="sk-avatar-uploader_trigger">
        <PhotoUploader
          supportedFormats={SUPPORTED_FORMATS_IMAGE}
          maxSize={DEFAULT_MAX_FILE_SIZE}
          photo={fileUrl}
          uploaderId={uploaderId}
          handleChange={uploadPhoto}
        />
      </div>
    </div>
  );
}

const enhancer = compose(
  withAuthenticatedUser
)

export default enhancer(AvatarUploader);
