import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  errorHeader: {
    id: 'app.containers.ErrorBoundary.ErrorMessage.header',
    defaultMessage: 'Opps... Something went wrong!',
  },
  errorBody: {
    id: 'app.containers.ErrorBoundary.ErrorMessage.body',
    defaultMessage: `We are working on it and will get it fixed as soon as possible. You can go back or write us with using ‘Contact us’ form or the following email support@avdo.education.`,
  },
  oops: {
    id: 'app.containers.ErrorBoundary.ErrorMessage.oops',
    defaultMessage: `Oops!`,
  },
  title: {
    id: 'app.containers.ErrorBoundary.ErrorMessage.title',
    defaultMessage: `Something went wrong.`,
  },
  description: {
    id: 'app.containers.ErrorBoundary.ErrorMessage.description',
    defaultMessage: `We are on it and will get it fixed shorly. Please contact us or go to the Main page.`,
  },
  contactUsButton: {
    id: 'app.containers.ErrorBoundary.ErrorMessage.contactUsButton',
    defaultMessage: `Contact Us`,
  },
  mainPageButton: {
    id: 'app.containers.ErrorBoundary.ErrorMessage.mainPageButton',
    defaultMessage: `Main Page`,
  },
});
