// Core
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';

// Components
import DraftsCoursesList from 'containers/Sidebar/SidebarContent/CourseSidebar/DraftsCoursesList';
import ActiveCoursesList from 'containers/Sidebar/SidebarContent/CourseSidebar/ActiveCoursesList';
import ToppingsList from 'containers/Sidebar/SidebarContent/CourseSidebar/ToppingsList';
import { withSidebar } from 'containers/SidebarProvider/withSidebar';
import CourseCard from 'routes/ExpertsCabinet/Courses/CourseCard';
import StatusFilter from 'routes/components/Filters/StatusFilter';
import TextInput from 'components/Input/Material/TextInput/TextInput';
import SearchLoop from 'components/Icon/Svg/SearchLoop';

// GrahpQL
import LIST_EXPERT_COURSES from 'queries/Courses/listExpertCourses.gql';

// Other
import { hasLoader } from 'containers/HOCs/hasLoader';
import { isBlank, keysToCamel, isArraysEqual } from 'utils/helpers';
import ContentHeader from 'containers/ContentHeader';
import { sharedMessages } from 'services/i18n/sharedMessages/messages';
import { Grey } from 'components/Icon/color';
import { COURSES_STATUSES_OPTIONS } from 'routes/ExpertsCabinet/Courses/enums.js';

// Styles and Assets
import './ExpertCourses.scss';

const prefix = 'sk-expert-cabinet-courses';

export const ExpertCourses = ({
  match,
  courses,
  query,
  sidebarContext,
  refetchCourses,
  intl: { formatMessage },
}) => {
  const searchCourses = ((query) => async ({ key, values }) => {
    const { filter } = query;



    const newFilters = !isBlank(filter) ? [...filter] : [];
    const oldFilter = newFilters.find(filter => filter.key === key);
    console.log(values)
 
    
    if((values?.includes("SECOND_REJECT") && !values?.includes("FIRST_REJECT"))||!values || !values.length){
      console.log(values)
      values = []
    }


    const isNewFilter = !(!!oldFilter);
    const isFilterChanged = oldFilter && values && !isArraysEqual(oldFilter.values, values);
    
  
    if (isNewFilter) {
      const newFilter = { key, values };
      if(key === 'status_id' && values.length === 0){
        newFilters.push({key: 'status_id', values: undefined});
      }else {
        newFilters.push(newFilter);
      }
    } else if (isFilterChanged) {
      oldFilter.values = values;
    };

    const keyIndex = newFilters.findIndex(element => element.key === 'status_id')
    
    if(newFilters[keyIndex]?.values?.includes('FIRST_REJECT')){
      newFilters[keyIndex].values.push("SECOND_REJECT")
    }

    await refetchCourses({ input: { query: { filter: newFilters } }});
  })(query);

  const handleSearchEnter = useCallback((searchValue, evt) => {
    const isUserEnterClicked = evt.key === 'Enter';
    const { filter } = query;

    if (isUserEnterClicked) {
      const filter = [{key: 'type', values: ['course'] }];
      refetchCourses({ input: { title: searchValue, query: { filter } }});
    }
  }, [query]);

  return (
    <div className={prefix}>
      <div className={`${prefix}__heading`}>
        <ContentHeader  way={formatMessage(sharedMessages.coursesLabel)} />
        <div className={`${prefix}__heading__filters`}>
          <div className={`${prefix}__heading__filters_search`}>
            <TextInput
              type='string'
              defaultValue={query?.search_value}
              placeholder={formatMessage(sharedMessages.searchCourse)}
              onKeyPress={handleSearchEnter}
            />
            <SearchLoop color={Grey} width={18} height={18} />
          </div>
          <StatusFilter filter={query.filter} searchToppings={searchCourses} options={COURSES_STATUSES_OPTIONS} />
        </div>
      </div>
      {!isBlank(courses) && courses.items.map(course=>(
        <CourseCard
          key={course?.id}
          course={course}
          coursePageLink={`/experts-cabinet/course/${course.id}`}
          isExpert={true}
        />
      ))}
    </div>
  );
};

const listExpertCoursesQuery = graphql(LIST_EXPERT_COURSES, {
  props: ({ data: { expertListCourses, variables, refetch, loading, error, ...ownProps } }) => ({
    courses: keysToCamel(expertListCourses),
    query: variables?.input?.query || {},
    refetchCourses: refetch,
    ...ownProps,
  }),
  options: () => ({
    fetchPolicy: 'network-only',
    variables: { input: { query: { filter: [{key: 'type', values: ['course'] }]}}}
  }),
});

const enhancer = compose(
  injectIntl,
  withSidebar,
  withRouter,
  listExpertCoursesQuery
);

export default hasLoader(enhancer(ExpertCourses), LIST_EXPERT_COURSES);
