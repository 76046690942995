// Core
import React, { memo } from 'react';
import PropTypes from 'prop-types';

// Components
import Text from 'components/Text';
import Button from 'components/Button';

// Styles and Assets
import './WebinarContentHeader.scss';

// Other
import { FormattedMessage } from 'react-intl';
import { messages } from 'routes/PublisherWebinar/messages';

export const WebinarContentHeader = ({
  title,
  onAir = false,
  toggleWebinar,
}) => (
  <div className="sk-web-content-header">
    <div className="sk-web-content-header__title">
      <Text>
        <FormattedMessage {...messages.course} /> <strong> {title}</strong>
      </Text>
    </div>
    <div className="sk-web-content-header__action">
      <Button
        color="secondary"
        size="large"
        variant="text"
        onClick={toggleWebinar(!onAir)}
      >
        {!onAir ? (
          <FormattedMessage {...messages.start} />
        ) : (
          <FormattedMessage {...messages.end} />
        )}
        <FormattedMessage {...messages.webinar} />
      </Button>
    </div>
  </div>
);

WebinarContentHeader.propTypes = {
  title: PropTypes.string,
  onAir: PropTypes.bool,
  toggleWebinar: PropTypes.func,
};

export default memo(WebinarContentHeader);
