// Core
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import { Formik } from 'formik';

// Components
import ContentHeader from 'containers/ContentHeader';
import Card from 'components/Card';
import PersonalInfoForm from 'routes/ManageAccount/PersonalInfo/PersonalInfoForm';
import Loader from 'components/Loader';

// GraphQL
import MY_PROFILE_QUERY from 'queries/UserProfile/getProfile.gql';
import UPDATE_PROFILE from 'mutations/UserProfile/updateProfile.gql';

// Other
import { parseFetchedData, keysToSnake } from 'utils/helpers';
import { getYupSchema } from 'services/yup';
import { hasLoader } from 'containers/HOCs/hasLoader';
import { withNotification } from 'containers/NotificationProvider/withNotification';

// Styles and Assets
import './PersonalInfo.scss';

class PersonalInfo extends PureComponent {
  handleSubmit = async (values, { setSubmitting }) => {
    const { updateProfile, showNotificationBar } = this.props;

    const { email, ...valuesToUpdate } = values;

    const {
      city: { id: city },
      country: { id: country },
      ...rest
    } = keysToSnake(valuesToUpdate);
    try {
      await updateProfile({ city, country, ...rest });
      setSubmitting(false);
      showNotificationBar('saved');
    } catch (error) {
      setSubmitting(false);
    }
  };

  render() {
    const { loading, error, profile, becomeExpert } = this.props;
    if (error) throw Error(error);
    if (loading || !profile) return <Loader />;

    const {
      firstName,
      email,
      lastName,
      country,
      city,
      aboutMe,
      photo,
      role,
      expertises,
    } = parseFetchedData(profile);

    const initialFields = {
      firstName,
      lastName,
      email,
      country,
      city,
      aboutMe,
      expertises,
    };

    const isExpert = role === 'expert';
    const validationSchema = isExpert ? 'expertAccountDataValidationSchema' : 'studentAccountDataValidationSchema';

    return (
      <div className="sk-personal-info">
        <ContentHeader />
        <Card classes="sk-personal-info__inner">
          <Formik
            onSubmit={async (values, formikBag) => {
              if (becomeExpert) {
                values.role = 'expert';
              }
              await this.handleSubmit(values, formikBag);
            }}
            render={props => (
              <PersonalInfoForm
                becomeExpert={becomeExpert}
                isExpert={isExpert}
                handleAvatarUpload={this.handleAvatarUpload}
                photo={photo}
                {...props}
              />
            )}
            initialValues={initialFields}
            validationSchema={getYupSchema(validationSchema)}
            validateOnChange={false}
            validateOnBlur
          />
        </Card>
      </div>
    );
  }
}

const myProfileQuery = graphql(MY_PROFILE_QUERY, {
  props: ({ data: { getProfile, error, loading, ...ownProps } }) => ({
    profile: getProfile,
    error,
    ...ownProps,
  }),
  options: () => ({
    fetchPolicy: 'network-and-cache',
  }),
});

const updateProfileMutation = graphql(UPDATE_PROFILE, {
  props: ({ mutate }) => ({
    updateProfile: input => mutate({ variables: { input } }),
  }),
  options: {
    refetchQueries: ['getProfile'],
  },
});


const enhancer = compose(
  withNotification,
  updateProfileMutation,
  myProfileQuery
);

export default hasLoader(enhancer(PersonalInfo), MY_PROFILE_QUERY);
