// Core
import React from 'react';
import { graphql, compose } from 'react-apollo';
import { keysToCamel } from 'utils/helpers';
import { FormattedMessage } from 'react-intl';

// Components
import Heading from 'components/Heading';
import CoursesCarousel from 'routes/LandingPage/components/CoursesCarousel';
import NoCoursesFound from 'routes/LandingPage/components/NoCoursesFound';

// Other
import { withLoader } from 'containers/HOCs/withLoader';
import { ACTIVE, } from 'routes/ExpertsCabinet/ToppingEdit/enums.js';
import { messages } from 'routes/StudentsCabinet/ToppingDetails/messages.js';

// GraphQl
import GET_ALL_COURSES from 'queries/Courses/listCourses.gql';

// Styles
import './index.scss';

const COMPONENT_PREFIX = `sk-topping-details__courses`;

const coursesCountAtCarouselPageDependOnScreen = {
  xlg: 3,
  lg: 2,
  md: 2,
  sm: 1,
  xs: 1,
};

const ExpertCourses = ({
  refetchCourses,
  courses,
  authorId
}) => {
  if(!courses?.length){
    return null;
  }
  return(
    <div className={`${COMPONENT_PREFIX}`}>
      <Heading variant='h4' className={`${COMPONENT_PREFIX}_title`}><FormattedMessage {...messages.expertCourses} /></Heading>
      <div className={`${COMPONENT_PREFIX}_carousel`}>
        {courses?.length > 0 ? (
          <CoursesCarousel
            courses={courses}
            className={`${COMPONENT_PREFIX}_carousel`}
            courseCountAtCarouselDependOnLocation={coursesCountAtCarouselPageDependOnScreen}
          />
        ) : (
          <NoCoursesFound />
        )}
      </div>
    </div>
  )
}

const getAllExpertCourses = graphql(GET_ALL_COURSES, {
  props: ({ data: { listCourses, refetch, ...ownProps } }) => {
    return {
      refetchCourses: refetch,
      courses: listCourses?.courses || [],
      ...ownProps,
    }
  },
  options: ({ authorId }) => ({
    fetchPolicy: 'cache-first',
    variables: { input: {
      author: authorId,
      size: 32,
      type: "course",
    }},
  }),
});

const enhancer = compose(
  getAllExpertCourses,
  withLoader
);

export default enhancer(ExpertCourses);
