// Core
import React, { useCallback } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { compose, graphql } from 'react-apollo';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

// Styles and Assets
import './index.scss';

// Components
import Text from 'components/Text';
import Button from 'components/Button';
import Duration from 'components/Duration/Duration';

// Icons
import Play from 'components/Icon/SvgCss/Play.js';
import Audio from 'components/Icon/SvgCss/Audio';
import Check from 'components/Icon/Svg/Check';
import Document from 'components/Icon/SvgCss/Document';
import Quiz from 'components/Icon/SvgCss/QuizAttachment.js';
import LockedMaterial from 'components/Icon/SvgCss/LockedMaterial.js';

// GraphQL
import UPDATE_RESUME from 'mutations/StudentCabinet/updateResume.gql';

// Other
import { White, Purple } from 'components/Icon/color';
import {
  LECTURE_ARTICLE,
  LECTURE_VIDEO,
  LECTURE_AUDIO,
  LECTURE_QUIZ,
} from 'containers/UploadManagerProvider/helpers';
import { messages } from 'routes/StudentsCabinet/PurchasedCourses/PurchasedCoursesLecture/PCDetails/messages';
import { compareSortNumber, isNull, checkIfLevelBlocked, isBlank, keysToSnake } from 'utils/helpers/index';


const MATERIALS_CONTROLLER = {
  [LECTURE_ARTICLE]: {
    icon: <Document />,
  },
  [LECTURE_VIDEO]: {
    icon: <Play strokeColor={Purple} color={White} width="15" height="15" />,
  },
  [LECTURE_AUDIO]: {
    icon: <Audio strokeColor={Purple} color={White} width="15" height="15" />,
  },
  [LECTURE_QUIZ]: {
    icon: <Quiz />,
  },
};

const renderMaterialIcon = ({ path, isBlocked, isActive, icon, handleSee, }) => {
  return (
    <Link to={path} onClick={handleSee}>
      {isBlocked ? (<Check active={isActive} />) : icon}
    </Link>
  );
};

const handleGoToMaterialViewPage = ({ history, path }) => () => {
  history.push(path);
};

const PCMaterial = ({
  material,
  purchasedCourseId,
  lectureId,
  courseId,
  interactionLevel,
  isLectureDisabled,
  history,
  studentSetResume,
  viewedMaterials,
  lastSeenMaterial,
}) => {
  const isBlocked = viewedMaterials?.some(viewedMaterial => material?.id == viewedMaterial?.id);
  const isActive = material?.id == lastSeenMaterial?.id;

  const handleSee = useCallback(async () => {
    const input = {
      id: material.id,
      type: material?.type,
      courseId,
      lectureId,
      purchasedCourseId
    };

    try {
      await studentSetResume(input);
    } catch (error) {
      throw Error(error);
    }
  }, [lectureId, material?.id, material?.type, studentSetResume, purchasedCourseId]);

  const { icon, className } = MATERIALS_CONTROLLER[material?.type]

  let pathToMaterialViewPage = `/student-cabinet/courses/${purchasedCourseId}/lecture/${lectureId.replace(/\s/g, '')}/${material?.type}/${material?.id}`;

  if (isActive && material?.type == LECTURE_VIDEO && lastSeenMaterial.position > 0) {
    pathToMaterialViewPage = `${pathToMaterialViewPage}?position=${lastSeenMaterial.position}`;
  }

  const iconWithLink = isLectureDisabled ? <LockedMaterial />
    : renderMaterialIcon({ path: pathToMaterialViewPage, isBlocked, isActive, handleSee, icon, });

  return (
    <div className={classNames('sk-pc-material-bg', { __resume: isActive && !isLectureDisabled })}>
      <div key={material.id} className={classNames('sk-pc-material', {
        'sk-pc-material__disabled': isLectureDisabled,
      })}>
        {iconWithLink}
        <div className='sk-pc-material__button'>
          <Text className={classNames({ 'sk-pc-material__disabled': isLectureDisabled })} size="small" semiBold>
            {material.title}
          </Text>
          {(isActive && !isLectureDisabled) && (
            <Button
              className="sk-pc-material__button"
              size="small"
              onClick={handleGoToMaterialViewPage({ history, path: pathToMaterialViewPage })}
            >
              <FormattedMessage {...messages.resume} />
            </Button>
          )}
        </div>
        <span className={classNames('sk-pc-material__duration', { 'sk-pc-material__disabled': isLectureDisabled })}>
          <Duration duration={material.duration} />
        </span>
      </div>

      <div className='sk-pc-material__button__mobile'>
        {(isActive && !isLectureDisabled) && (
          <Button
            className="sk-pc-material__button__mobile"
            size="small"
            onClick={handleGoToMaterialViewPage({ history, path: pathToMaterialViewPage })}
          >
            <FormattedMessage {...messages.resume} />
          </Button>
        )}
      </div>
    </div>
  );
}

const studentUpdateResume = graphql(UPDATE_RESUME, {
  props: ({ mutate }) => ({
    studentSetResume: input => mutate({ variables: { input: keysToSnake(input) } }),
  }),
  options: {
    refetchQueries: ['listStudentCourses']
  }
});

const enhancer = compose(
  withRouter,
  studentUpdateResume,
);

export default enhancer(PCMaterial);
