import React from 'react';

const Icon = ({color = '#A9B6C8'}) => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="3.5" y="4.5" width="19" height="15.9231" rx="3.5" stroke={color} />
      <path d="M11.6133 8.71817L16.4406 11.8861C16.6151 12.037 16.6151 12.2633 16.4406 12.4141L11.7877 15.4312C11.526 15.6575 11.0607 15.5067 11.0317 15.1798L10.8572 8.99474C10.8572 8.66788 11.3225 8.49189 11.6133 8.71817Z" stroke={color} strokeMiterlimit="10"/>
    </svg>
  );
};

export default Icon;
