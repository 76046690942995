// Core
import React, { PureComponent } from 'react';
import { graphql, compose } from 'react-apollo';
import slugify from '@sindresorhus/slugify';

// Components
import ConfirmDelete from 'routes/ExpertsCabinet/components/ConfirmDelete/ConfirmDelete';

// GraphQL
import DELETE_ATTACHMENT from 'mutations/Courses/deleteAttachment.gql';

// Other
import { withUploadManager } from 'containers/UploadManagerProvider/withUploadManager';
import { getSeparatedFilename } from 'services/aws/amplify/helpers';
import { LECTURE_HOMEWORK } from 'containers/UploadManagerProvider/helpers';
import { withModal } from 'containers/ModalProvider/withModal';

const deleteAttachmentMutation = graphql(DELETE_ATTACHMENT, {
  props: ({ mutate }) => ({
    deleteAttachment: id => mutate({ variables: { id }}),
  }),
  options: {
    refetchQueries: ['listExpertCourses', 'getStudentCourse'],
  },
});

const enhancer = compose(
  withModal,
  withUploadManager,
  deleteAttachmentMutation
);

export const withHomeworkUpload = (WrappedComponent) => {
  class HOC extends PureComponent {
    handleUploadHomework = async ({ id, file, studentId, purchasedCourseId, lectureId, courseId, refetchStudentCourse }) => {
      const { uploadManagerContext: { addFileToQueue }, refetchCourse } = this.props;

      const { fileName, ext } = getSeparatedFilename(file.name);
      const beautyFilename = slugify(fileName);

      try {
        const params = {
          type: LECTURE_HOMEWORK,
          s3UploadingVariables: {
            filename: beautyFilename,
            file,
            studentId,
            courseId,
            lectureId,
          },
          createAttachmentMutationVariables: {
            type: LECTURE_HOMEWORK,
            filename: `${beautyFilename}.${ext}`,
            title: beautyFilename,
            course_id: courseId,
            lecture_id: lectureId,
            purchased_course_id: purchasedCourseId,
          },
          s3PostHook: refetchStudentCourse,
        };

        await addFileToQueue(params);
      } catch (error) {
        throw Error(error);
      }
    }

    handleCancelMaterialAdding = id => {
      const { uploadManagerContext: { queue, removeFileFromQueue }, deleteAttachment } = this.props;
      const isFileInQueue = queue.find(queueItem => queueItem.uploadId === id);

      if (isFileInQueue) removeFileFromQueue(id);
    }

    handleAttachmentDelete = id => {
      const { modalContext: { showModal, hideModal }, deleteAttachment, } = this.props;

      try {
        const remove = async () => {
          this.handleCancelMaterialAdding(id);
          await deleteAttachment(id);
        }
        showModal(ConfirmDelete, { close: hideModal, handleDelete: remove, });
      } catch (error) {
        throw Error(error);
      }
    }

    render() {
      return <WrappedComponent {...this.props}
        handleUploadHomework={this.handleUploadHomework}
        handleAttachmentDelete={this.handleAttachmentDelete}
      />;
    }
  }

  return enhancer(HOC);
}
