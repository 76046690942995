// Core
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// Components
import MenuLightBulb from 'components/Icon/Svg/MenuLightBulb';
import Loader from 'components/Loader';
import SidebarMenuItem from 'containers/Sidebar/SidebarContent/SidebarMenu/SidebarMenuItem';
import SimpleSidebarMenu from 'containers/Sidebar/SidebarContent/SimpleSidebarMenu';

// Other
import { keysToCamel } from 'utils/helpers';
import { renderTrimmedText } from 'utils/helpers/index';
import { FormattedMessage } from 'react-intl';
import { messages } from 'containers/Sidebar/SidebarContent/StudentCabinetSidebar/CompletedCourses/messages';

// Styles and Assets
import './CompletedCourses.scss';

class CompletedCourses extends PureComponent {
  getMenuItems = list =>
    list.map(({ id, courseTitle, courseId, interactionLevel }) => ({
      id,
      path: `/:locale/student-cabinet/courses/${id}/${interactionLevel}/materials`,
      state: 'closed',
      label: renderTrimmedText(courseTitle, 45),
    }));

  renderSimpleSidebarMenu = (open, list, handleSubMenuTitleClick) => {
    if (open && list?.length) {
      return (
        <SimpleSidebarMenu
          menuItems={this.getMenuItems(keysToCamel(list))}
          handleSubMenuTitleClick={handleSubMenuTitleClick}
        />
      );
    }
    return null;
  };

  render() {
    const {
      open,
      handleClick,
      list,
      loading,
      handleSubMenuTitleClick,
    } = this.props;

    return (
      <div>
        <FormattedMessage {...messages.completedCourses}>
          {msg => (
            <SidebarMenuItem
              icon={MenuLightBulb}
              label={msg}
              expandable={!!list?.length}
              handleClick={handleClick}
              open={open}
            >
              {loading && open ? (
                <Loader small />
              ) : (
                this.renderSimpleSidebarMenu(
                  open,
                  list,
                  handleSubMenuTitleClick
                )
              )}
            </SidebarMenuItem>
          )}
        </FormattedMessage>
      </div>
    );
  }
}

CompletedCourses.propTypes = {
  open: PropTypes.bool,
  handleClick: PropTypes.func,
  list: PropTypes.array,
  loading: PropTypes.bool,
  handleSubMenuTitleClick: PropTypes.func,
};

export default CompletedCourses;
