// Core
import React, { memo, useState, useEffect, useCallback } from 'react';
import { withFormik } from 'formik';
import { graphql, compose } from 'react-apollo';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

// Components
import ToolTip from 'components/ToolTip';
import TextAreaInput from 'components/Input/Material/TextAreaInput';
import ComplexitySelect from 'routes/ExpertsCabinet/CourseEdit/Details/CourseInformation/CourseDescriptionForm/ComplexitySelect';
import LanguageSelect from 'routes/ExpertsCabinet/CourseEdit/Details/CourseInformation/CourseDescriptionForm/LanguageSelect';
import Heading from 'components/Heading';
import Loader from 'components/Loader';
import SuggestionsModal from 'components/SuggestionsModal';

// GraphQl
import LIST_LANGUAGES_CATEGORIES_QUERY from 'queries/Courses/listLanguageCategories.gql';
import LIST_COMPLEXITIES_QUERY from 'queries/Courses/listComplexities.gql';
import SUGGESTIONS_MUTATION from "mutations/LLM/suggestions.gql";

// Other
import { withSharedState } from 'containers/SharedStateProvider/withSharedState';
import { withCourseEditContext } from 'routes/ExpertsCabinet/CourseEdit/Context';
import { withModal } from "containers/ModalProvider/withModal";
import { messages } from 'routes/ExpertsCabinet/CourseEdit/Details/CourseInformation/messages';
import { getYupSchema } from 'services/yup';
import AIIcon from 'components/Icon/Svg/AI/AI';

// Styles and Assets
import './index.scss';

const getInitialState = ({
  complexity,
  language,
  requirements,
  skills,
  description,
}) => {
  return {
    complexity: complexity?.id,
    language: language?.id || '',
    requirements: requirements || '',
    description: description || '',
    skills: skills || '',
  };
};

const CourseDescriptionForm = ({
  isValid,
  values,
  errors,
  touched,
  validateForm,
  handleBlur,
  setFieldValue,
  setErrors,
  setTouched,
  setValues,
  disableAll,
  sharedStateContext: { updateSharedState },
  courseEditContext: {
    course,
    handleCourseUpdate,
    bindCourseMethod,
  },
  intl: { formatMessage },
  getSuggestions,
  modalContext,
  ...props
}) => {
  const [loading, setLoading] = useState(false);

  const handleSuggestionClose = useCallback(() => {
    const { hideModal } = modalContext;
    hideModal();
  }, [modalContext]);

  const handleShowSuggestionsModal = useCallback((suggestions) => {
    try {
      modalContext.showModal(SuggestionsModal, {
        handleSuggestionClose,
        suggestions,
      });
    } catch (error) {
      console.error(error);
    }
  }, [handleSuggestionClose, modalContext]);

  const handleAIIconClick = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getSuggestions({ type: 'description', prompt: values.description });
      if (data?.suggestions) {
        const updatedDescription = data.suggestions;
        console.log('Updated Description:', updatedDescription);
        handleShowSuggestionsModal(updatedDescription);
      }
    } catch (error) {
      console.error('Error fetching suggestions:', error);
    } finally {
      setLoading(false);
    }
  }, [getSuggestions, values.description, handleShowSuggestionsModal]);

  useEffect(() => {
    let isValid = true;

    Object.keys(errors).forEach(error => {
      if (touched[error]) {
        isValid = false;
      }
    });

    const courseDescription = { isValid };
    updateSharedState({ courseDescription });
    return () => {
      updateSharedState({ courseDescription: { isValid: true } });
    };
  }, [errors, touched, updateSharedState]);

  const handleInputChange = useCallback(name => (inputValue, e) => {
    setFieldValue(name, inputValue);
  }, [setFieldValue]);

  const handleInputBlur = useCallback((name, applyCurrentChange = false) => async (inputValue, e) => {
    if (applyCurrentChange) {
      setFieldValue(name, inputValue, false);
    }

    if (e) {
      handleBlur(e);
    }

    const errors = await validateForm({ ...values, [name]: inputValue });
    const hasError = errors[name];

    if (!hasError) handleCourseUpdate({ [name]: `${inputValue}` });
  }, [handleBlur, values, validateForm, handleCourseUpdate, setFieldValue]);

  const handleFormValidation = useCallback(async () => {
    const errors = await validateForm(values);
    const isValid = Object.keys(errors).length === 0;

    if (!isValid) {
      const touched = {};

      for (const fieldName in errors) {
        touched[fieldName] = true;
      }

      setErrors(errors);
      setTouched(touched);
    }

    return isValid;
  }, [values, validateForm, setErrors, setTouched]);

  bindCourseMethod({ method: 'validateCourseDescription', handler: handleFormValidation });

  return (
    <form className="sk-course-information-form" noValidate autoComplete="off" role="form" aria-labelledby="course-description-form">
      <div className="sk-course-information-form__inner">
        <Heading
          id="course-description-form"
          variant="h3"
          classes="sk-course-information-form-heading sk-course-information-form-heading__student"
        >
          {formatMessage(messages.descriptionForStudents)}
          <div onClick={handleAIIconClick} style={{ cursor: 'pointer', display: 'inline' }}>
            <AIIcon />
          </div>
          {loading && <Loader small />}
        </Heading>
        <div className="sk-form__row sk-course-information-form__row">
          <ComplexitySelect
            listComplexities={props?.listComplexities}
            disabled={disableAll}
            helperText={touched.complexity ? errors.complexity : ''}
            error={touched.complexity && Boolean(errors.complexity)}
            name="complexity"
            label={formatMessage(messages.courseComplexity)}
            value={values.complexity}
            handleChange={handleInputChange('complexity')}
            handleBlur={handleInputBlur('complexity')}
          />
          <LanguageSelect
            listLanguages={props?.listLanguages}
            disabled={disableAll}
            helperText={touched.language ? errors.language : ''}
            error={touched.language && Boolean(errors.language)}
            name="language"
            label={formatMessage(messages.chooseLang)}
            value={values.language}
            handleChange={handleInputChange('language')}
            handleBlur={handleInputBlur('language')}
          />
        </div>
        <div className="sk-form__row">
          <div>
            <TextAreaInput
              name="requirements"
              value={values?.requirements}
              onBlur={handleInputBlur('requirements', true)}
              placeholder={formatMessage(messages.courseReqs)}
              label={formatMessage(messages.courseReqs)}
              error={touched.requirements && Boolean(errors.requirements)}
              disabled={disableAll}
              helperText={touched.requirements ? errors.requirements : ''}
            />
            <ToolTip
              wide
              triggerClasses="sk-course-information-form__trigger"
              iconTheme="orange"
            >
              <div>{formatMessage(messages.whatsReqToTakeCourse)}</div>
            </ToolTip>
          </div>
        </div>
        <div className="sk-form__row">
          <TextAreaInput
            name="skills"
            value={values.skills}
            onBlur={handleInputBlur('skills', true)}
            placeholder={formatMessage(messages.skillsStudentWillGain)}
            label={formatMessage(messages.skillsStudentWillGain)}
            maxLength={350}
            error={touched.skills && Boolean(errors.skills)}
            disabled={disableAll}
            helperText={touched.skills ? errors.skills : ''}
          />
        </div>
        <div className="sk-form__row">
          <TextAreaInput
            name="description"
            value={values.description}
            onBlur={handleInputBlur('description', true)}
            placeholder={formatMessage(messages.courseDescription)}
            label={formatMessage(messages.courseDescription)}
            error={touched.description && Boolean(errors.description)}
            disabled={disableAll}
            helperText={touched.description ? errors.description : ''}
          />
        </div>
      </div>
    </form>
  );
};

CourseDescriptionForm.propTypes = {
  isValid: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  validateForm: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setErrors: PropTypes.func.isRequired,
  setTouched: PropTypes.func.isRequired,
  setValues: PropTypes.func.isRequired,
  disableAll: PropTypes.bool.isRequired,
  sharedStateContext: PropTypes.object.isRequired,
  courseEditContext: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  getSuggestions: PropTypes.func.isRequired,
  modalContext: PropTypes.object.isRequired,
};

const formikStateManagement = withFormik({
  enableReinitialize: false,
  mapPropsToValues: ({ courseEditContext: { course } }) =>  getInitialState(course),
  displayName: 'CourseDescriptionForm',
  validationSchema: () => getYupSchema('courseDescriptionValidationSchema'),
  validateOnChange: false,
  validateOnBlur: false,
});

const listComplexitiesQuery = graphql(LIST_COMPLEXITIES_QUERY, {
  props: ({
    data: { listComplexities, ...ownProps },
  }) => ({
    listComplexities,
    ...ownProps,
  }),
  options: () => ({
    variables: { filter: { id: { between: [1, 4] } } },
    fetchPolicy: 'cache-and-network',
  }),
});

const listLanguagesCategories = graphql(LIST_LANGUAGES_CATEGORIES_QUERY, {
  skip: props => !!props.skip,
  props: ({
    data: { listLanguages, ...ownProps },
  }) => ({
    listLanguages,
    ...ownProps,
  }),
  options: () => ({
    variables: { limit: 100 },
    fetchPolicy: 'cache-and-network',
  }),
});

const getSuggestionsMutation = graphql(SUGGESTIONS_MUTATION, {
  props: ({ mutate }) => ({
    getSuggestions: (variables) => mutate({ variables }),
  }),
});

const enhancer = compose(
  memo,
  injectIntl,
  listComplexitiesQuery,
  listLanguagesCategories,
  withModal,
  withSharedState,
  withCourseEditContext,
  formikStateManagement,
  getSuggestionsMutation
);

export default memo(enhancer(CourseDescriptionForm));
