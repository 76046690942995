// Core
import React, { PureComponent } from 'react';
import { compose, graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Collapse from '@material-ui/core/Collapse';
import { withFormik } from 'formik';
import moment from 'moment';

// Components
import Loader from 'components/Loader';
import Card from 'components/Card';
import ContentHeader from 'containers/ContentHeader';
import Text from 'components/Text';
import Button from 'components/Button';
import TextInput from 'components/Input/Material/TextInput/TextInput';
import TextAreaInput from 'components/Input/Material/TextAreaInput/TextAreaInput';
import DiscountTabs from 'routes/AdminPanel/Discounts/DiscountPage/DiscountTabs';
import DiscountDetails from 'routes/AdminPanel/Discounts/DiscountPage/DiscountDetails';
import DiscountInfo from 'routes/AdminPanel/Discounts/DiscountPage/DiscountInfo';
import DiscountAnalytics from 'routes/AdminPanel/Discounts/DiscountPage/DiscountAnalytics';

// GraphQl
import GET_DISCOUNT from 'queries/AdminPanel/getDiscount.gql';
import CREATE_DISCOUNT from 'queries/AdminPanel/createDiscount.gql';
import VALIDATE_DISCOUNT from 'queries/AdminPanel/validateDiscount.gql';
import UPDATE_DISCOUNT from 'queries/AdminPanel/updateDiscount.gql';
import DELETE_DISCOUNT from 'queries/AdminPanel/deleteDiscount.gql';

// Other
import { messages } from 'routes/AdminPanel/messages';
import { keysToSnake, keysToCamel } from  'utils/helpers/index';
import { getYupSchema, } from 'services/yup';

// Styles and Assets
import './DiscountPage.scss';

const INITIAL_FORM_VALUES = {
  activeTab: 'info',
  name: '',
  code: '',
  reference: '',
  referenceId: '',
  discountType: '',
  discountValue: '',
  startTime: moment().valueOf(),
  endTime: moment().valueOf(),
  redemptions: '',
};

class DiscountPage extends PureComponent {
  // handleDiscountUpdate = async values => {
  //   const { updateDiscount, discount, } = this.props;
  //   if (!discount) return;
  //
  //   const input = {
  //     id: discount.id,
  //     ...values
  //   }
  //
  //   await updateDiscount(input);
  // }

  handleDiscountCreate = async () => {
    const { isValid, errors, validateForm, validateField, setTouched, setFieldTouched, setErrors, validateDiscount, createDiscount, values, history, } = this.props;
    const { activeTab, ...input } = values;

    try {
      if (isValid) {
        const mutationResponse = await validateDiscount(input?.code);
        const isDiscountExist = mutationResponse?.data?.validateDiscountCode === 'true';

        if (!isDiscountExist) {
          const discount = await createDiscount(input);
          history.push(`/admin/panel/discounts/edit/${discount.id}`);
        } else if (isDiscountExist) {
          setFieldTouched('code', true, false);
          setErrors({ code: 'This promo code already exist! Please try another.' });
        }
      } else {
        validateForm();
        setTouched({ name: true, code: true, reference: true, referenceId: true, discountType: true, discountValue: true, startTime: true, endTime: true, redemptions: true, });
      }
    } catch (error) {
      throw Error(error);
    }
  };

  handleDiscountDelete = async () => {
    const { deleteDiscount, values, discount, history } = this.props;
    if (!values.id) return;

    try {
      await deleteDiscount({ id: values.id });
      history.push(`/admin/panel/discounts/`);
    } catch (error) {
      throw Error(error);
    }
  }

  handleSwitchTab = newTab => this.props.handleChange({ activeTab: newTab });

  handleInputChange = fieldName => inputValue => {
    const { handleChange, setFieldTouched, setFieldValue } = this.props;

    console.log('handleInputChange', fieldName, inputValue);

    if (fieldName === 'startTime' || fieldName === 'endTime') {

    } else {
      handleChange(fieldName)(inputValue);
    };

    setFieldTouched(fieldName, true, false);
  }

  handleDateChange = fieldName => async (inputValue, e) => {
    const { values, discount, setFieldValue, setFieldTouched, updateDiscount, validateForm } = this.props;
    const discountId = discount?.id;
    const isDiscountCreated = !!discountId;

    const value = inputValue.valueOf();

    setFieldValue(fieldName, value, false);
    setFieldTouched(fieldName, true, false);

    const newValues = { ...values, [fieldName]: value };
    const errors = await validateForm(newValues);

    const isValid = !(!!errors[fieldName]);

    if (isDiscountCreated && isValid) {
      await updateDiscount({ id: discountId, [fieldName]: value });
    }
  }

  handleInputBlur = fieldName => async (inputValue, e) => {
    const { updateDiscount, values, discount, setFieldTouched, validateForm } = this.props;
    const discountId = discount?.id;
    const isDiscountCreated = !!discountId;

    setFieldTouched(fieldName, true, false);

    const value = inputValue.valueOf();
    const newValues = { ...values, [fieldName]: value };
    const errors = await validateForm(newValues);
    const isValid = !(!!errors[fieldName]);

    if (isDiscountCreated && isValid) {
      await updateDiscount({ id: discountId, [fieldName]: values[fieldName] });
    }
  }

  getMutationParams = values => {
    const params = { ...values };
    return params;
  }

  render() {
    const { values, touched, errors, isNewDiscount, handleChange, handleBlur, loading, } = this.props;
    const { activeTab } = values;

    if (loading) return (<Loader />)

    return (
      <div className='sk-admin-discount-page'>
        <div className='sk-admin-discount-page__header'>
          <div className='sk-admin-discount-page__header__sidebar-button'><ContentHeader /></div>
          <div className='sk-admin-discount-page__header__content'>
            <div className='sk-admin-discount-page__header__content__buttons'>
              Promocode edit
              {isNewDiscount
                ? <Button onClick={this.handleDiscountCreate}>Create</Button>
                : <Button onClick={this.handleDiscountDelete}>Delete</Button>
              }
            </div>
            <DiscountInfo
              touched={touched}
              errors={errors}
              discount={values}
              handleInputChange={this.handleInputChange}
              handleInputBlur={this.handleInputBlur}
              // handleDiscountUpdate={this.handleDiscountUpdate}
            />
          </div>
        </div>
        <Card>
          <DiscountTabs
            activeTab={activeTab}
            handleSwitch={this.handleSwitchTab}
          />
          <div className="sk-admin-discount-page__content ">
            <Collapse
              in={activeTab === 'info'}
              timeout="auto"
              unmountOnExit
            >
              <DiscountDetails
                touched={touched}
                errors={errors}
                discount={values}
                handleInputChange={this.handleInputChange}
                handleInputBlur={this.handleInputBlur}
                handleDateChange={this.handleDateChange}
              />
            </Collapse>

            <Collapse
              in={activeTab === 'analytics'}
              timeout="auto"
              unmountOnExit
            >
              <DiscountAnalytics
                discount={values}
              />
            </Collapse>
          </div>
        </Card>
      </div>
    );
  }
}

const getDiscountQuery = graphql(GET_DISCOUNT, {
  skip: ({ isNewDiscount, match: { params: { id }} }) => (isNewDiscount || !id),
  props: ({ data: { error, loading, getDiscount, refetch, ...ownProps } }) => {

    if (error) throw Error('Something went wrong, while receiving discount');
    if (loading) return { loading, discount: {}, };

    const discount = keysToCamel(getDiscount);

    return {
      error,
      loading,
      discount,
      refetchDiscount: refetch,
      ...ownProps,
    }
  },
  options: ({ match: { params: { id }}}) => ({
    fetchPolicy: 'cache-and-network',
    variables: { id },
  }),
});

const createDiscountMutation = graphql(CREATE_DISCOUNT, {
  props: ({ mutate }) => ({
    createDiscount: async (values) => {
      const input = keysToSnake(values);
      const result = await mutate({ variables: { input } });

      return result?.data?.createDiscount;
    },
  }),
});

const updateDiscountMutation = graphql(UPDATE_DISCOUNT, {
  props: ({ mutate }) => ({
    updateDiscount: values => {
      const input = keysToSnake(values);
      mutate({ variables: { input } });
    },
  }),
  options: () => ({
    refetchQueries: ['getDiscount'],
  })
});

const deleteDiscountMutation = graphql(DELETE_DISCOUNT, {
  props: ({ mutate }) => ({
    deleteDiscount: ({ id }) => mutate({ variables: { id }}),
  }),
  options: () => ({
    refetchQueries: ['listDiscounts'],
  })
});

const validateDiscountMutation = graphql(VALIDATE_DISCOUNT, {
  props: ({ mutate }) => ({
    validateDiscount: code => mutate({ variables: { code }}),
  }),
});

const formikStateManagement = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ isNewDiscount, discount }) => {
    let initialValues = Object.assign({}, INITIAL_FORM_VALUES);
    if (!isNewDiscount) initialValues = Object.assign(initialValues, discount);
    return initialValues;
  },
  displayName: 'DiscountForm',
  validationSchema: () => getYupSchema('discountEditValidationSchema'),
  validateOnChange: true,
  validateOnBlur: false
});

const enhancer = compose(
  withRouter,
  getDiscountQuery,
  createDiscountMutation,
  updateDiscountMutation,
  deleteDiscountMutation,
  validateDiscountMutation,
  formikStateManagement
);

export default enhancer(DiscountPage);
