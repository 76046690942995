import React from 'react';
import PropTypes from 'prop-types';

const WorldWide = ({ height, width }) => (
  /* eslint-disable max-len */
  <svg
    version="1.1"
    width={width}
    height={height}
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 16 16"
    enableBackground="new 0 0 16 16"
    xmlSpace="preserve"
  >
    <g>
      <path
        className="st0"
        fill="#FFFFFF"
        d="M8,15.5c-4.1,0-7.5-3.4-7.5-7.5c0-4.1,3.4-7.5,7.5-7.5c4.1,0,7.5,3.4,7.5,7.5C15.5,12.1,12.1,15.5,8,15.5z"
      />
      <path
        className="st1"
        fill="#767676"
        d="M8,1c3.9,0,7,3.1,7,7s-3.1,7-7,7s-7-3.1-7-7S4.1,1,8,1 M8,0C3.6,0,0,3.6,0,8s3.6,8,8,8s8-3.6,8-8S12.4,0,8,0
  		L8,0z"
      />
    </g>
    <g>
      <line
        className="st2"
        fill="none"
        stroke="#767676"
        strokeMiterlimit="10"
        x1="1.7"
        y1="3.8"
        x2="14.5"
        y2="3.8"
      />
    </g>
    <g>
      <line
        className="st2"
        fill="none"
        stroke="#767676"
        strokeMiterlimit="10"
        x1="1"
        y1="8"
        x2="15.4"
        y2="8"
      />
    </g>
    <g>
      <line
        className="st3"
        fill="none"
        stroke="#767676"
        strokeLinecap="round"
        strokeMiterlimit="10"
        x1="2.4"
        y1="12.3"
        x2="13.8"
        y2="12.3"
      />
    </g>
    <g>
      <line
        className="st2"
        fill="none"
        stroke="#767676"
        strokeMiterlimit="10"
        x1="8"
        y1="0.7"
        x2="8"
        y2="15.5"
      />
    </g>
    <g>
      <path
        className="st4"
        fill="none"
        stroke="#767676"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M7.4,0.8c0,0-3.2,2.5-3.2,7.2s3,7.1,3,7.1"
      />
    </g>
    <g>
      <path
        className="st4"
        fill="none"
        stroke="#767676"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M8.7,0.8c0,0,3.2,2.5,3.2,7.2s-3,7.1-3,7.1"
      />
    </g>
  </svg>

  /* eslint-enable max-len */
);

WorldWide.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

WorldWide.defaultProps = {
  height: '16',
  width: '16',
};

export default WorldWide;
