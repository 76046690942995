// Core
import React, { memo, useState, useEffect } from 'react';
import { graphql, compose } from 'react-apollo';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useMutation } from '@apollo/react-hooks';
import { withFormik } from 'formik';

// Components
import TextInput from 'components/Input/Material/TextInput/TextInput';
import Button from 'components/Button';
import TextAreaInput from 'components/Input/Material/TextAreaInput';
import CountrySelect from 'routes/Authenticator/CountrySelect';
import CitySelect from 'routes/Authenticator/CitySelect';

// GraphQL
import UPDATE_PROFILE from 'mutations/UserProfile/updateProfile.gql';

// Other
import { withNotification } from 'containers/NotificationProvider/withNotification';
import { keysToSnake, keysToCamel } from  'utils/helpers/index';
import { getYupSchema, } from 'services/yup';
import { messages } from 'routes/Authenticator/SignUpForm/messages';

// Styles
import './index.scss';

const AboutExpertInfo = props => {
  const {
    values: { aboutMe, expertises, city, country },
    errors,
    touched,
    validateForm,
    setFieldTouched,
    setFieldValue,
    setErrors,
    handleBlur,
    handleChange,
    getProfile,
    intl: { formatMessage },
    handleRedirectToManageAccount,
  } = props;

  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const [updateProfile, updateProfileResponse] = useMutation(UPDATE_PROFILE);

  const handleInputChange = name => inputValue => {
    if(name === 'country' && !inputValue){
      setFieldValue('city', '');
    }
    setFieldValue(name, inputValue);
    setFieldTouched(name, true, false);
    setTimeout(async() => {
      const errors = await validateForm();
      await setErrors(errors);
    }, 100);
  };

  const handleInputBlur = name => (inputValue, e) => {
    if (e) {
      e.persist();
      handleBlur(e);
    }
    setFieldValue(name, inputValue);
  };

  const becomeExpert = async () => {
    setSubmitted(true);
    const errors = await validateForm();
    const isValid = Object.keys(errors).length === 0;

    if (isValid) {
      try {
        const input = { aboutMe, expertises, role: 'expert', city: city?.id || '', country: country?.id || '', };

        await updateProfile({ variables: { input: keysToSnake(input) }});
        await getProfile();
        handleRedirectToManageAccount();
      } catch (error) {
        console.log('become expert error', error)
        throw error;
      }
    } else {
      await setErrors(errors);
    };
  };

  console.log('updateProfileResponse', updateProfileResponse);

  return (
    <form
      className="sk-expert-onboarding-form"
      noValidate
      autoComplete="off"
    >
        <div className="sk-form__row">
          <TextAreaInput
            helperText={submitted || touched.aboutMe ? errors.aboutMe : ''}
            error={(submitted || touched.aboutMe) && Boolean(errors.aboutMe)}
            name='aboutMe'
            value={aboutMe}
            onBlur={handleInputBlur('aboutMe')}
            placeholder={formatMessage(messages.aboutMe)}
            label={formatMessage(messages.aboutMe)}
            rowsMax={4}
          />
        </div>
        <div className="sk-form__row">
          <TextInput
            helperText={submitted || touched.expertises ? errors.expertises : ''}
            error={(submitted || touched.expertises) && Boolean(errors.expertises)}
            name="expertises"
            label={<FormattedMessage {...messages.expertise} />}
            placeholder={formatMessage(messages.chooseExp)}
            value={expertises}
            onBlur={handleInputBlur('expertises')}
          />
      </div>
      <div className="sk-form__row">
        <CountrySelect
          helperText={submitted || touched.country ? errors.country : ''}
          error={(submitted || touched.country) && Boolean(errors.country)}
          name="country"
          label={formatMessage(messages.country)}
          value={country}
          handleChange={handleInputChange('country')}
          handleSearch={handleInputChange('country')}
          handleBlur={handleInputBlur('country')}
        />
      </div>
      <div className="sk-form__row">
        <CitySelect
          helperText={submitted || touched.city ? errors.city : ''}
          error={(submitted || touched.city) && Boolean(errors.city)}
          name="city"
          label={formatMessage(messages.city)}
          value={city}
          disabled={!country}
          country={country}
          handleChange={handleInputChange('city')}
          handleSearch={handleInputChange('city')}
          handleBlur={handleInputBlur('city')}
        />
      </div>

      <div className="sk-form_footer sk-expert-onboarding-form__footer">
        <Button
          variant="text"
          color="secondary"
          size="large"
          onClick={becomeExpert}
          disabled={updateProfileResponse?.loading || updateProfileResponse?.called}
          loading={updateProfileResponse?.loading}
        >
          <FormattedMessage {...messages.save} />
        </Button>
      </div>
    </form>
  );
};

const formikStateManagement = withFormik({
  enableReinitialize: true,
  isInitialValid: false,
  mapPropsToValues: ({ profile: { aboutMe, expertises, city, country }}) => {
    return { aboutMe, city: city?.id ? city : '' , country: country?.id ? country : '' , expertises };
  },
  displayName: 'AboutExpertInfoForm',
  validationSchema: () => getYupSchema('aboutExpertInfoFormValidationSchema'),
  validateOnChange: true,
  validateOnBlur: true
})

const enhancer = compose(
  memo,
  injectIntl,
  withNotification,
  formikStateManagement,
  // updateProfileMutation,
);

export default enhancer(AboutExpertInfo);
