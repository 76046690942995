import React from 'react';

const LanguageIcon = ({ width=18, height=18 }) => {
  const index = Math.random();
  return(
    <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id={`path-2-inside-1${index}`} fill="white">
        <path d="M8.9441 0.559006C13.5839 0.559006 17.3292 4.30435 17.3292 8.9441C17.3292 13.5839 13.5839 17.3292 8.9441 17.3292C4.30435 17.3292 0.559006 13.5839 0.559006 8.9441C0.559006 4.30435 4.30435 0.559006 8.9441 0.559006ZM8.9441 0C4.02484 0 0 4.02484 0 8.9441C0 13.8634 4.02484 17.8882 8.9441 17.8882C13.8634 17.8882 17.8882 13.8634 17.8882 8.9441C17.8882 4.02484 13.8634 0 8.9441 0Z"/>
      </mask>
      <path d="M8.9441 0.559006C13.5839 0.559006 17.3292 4.30435 17.3292 8.9441C17.3292 13.5839 13.5839 17.3292 8.9441 17.3292C4.30435 17.3292 0.559006 13.5839 0.559006 8.9441C0.559006 4.30435 4.30435 0.559006 8.9441 0.559006ZM8.9441 0C4.02484 0 0 4.02484 0 8.9441C0 13.8634 4.02484 17.8882 8.9441 17.8882C13.8634 17.8882 17.8882 13.8634 17.8882 8.9441C17.8882 4.02484 13.8634 0 8.9441 0Z" fill="#767676"/>
      <path d="M8.9441 1.55901C13.0316 1.55901 16.3292 4.85663 16.3292 8.9441H18.3292C18.3292 3.75206 14.1361 -0.440994 8.9441 -0.440994V1.55901ZM16.3292 8.9441C16.3292 13.0316 13.0316 16.3292 8.9441 16.3292V18.3292C14.1361 18.3292 18.3292 14.1361 18.3292 8.9441H16.3292ZM8.9441 16.3292C4.85663 16.3292 1.55901 13.0316 1.55901 8.9441H-0.440994C-0.440994 14.1361 3.75206 18.3292 8.9441 18.3292V16.3292ZM1.55901 8.9441C1.55901 4.85663 4.85663 1.55901 8.9441 1.55901V-0.440994C3.75206 -0.440994 -0.440994 3.75206 -0.440994 8.9441H1.55901ZM8.9441 -1C3.47256 -1 -1 3.47256 -1 8.9441H1C1 4.57713 4.57713 1 8.9441 1V-1ZM-1 8.9441C-1 14.4156 3.47256 18.8882 8.9441 18.8882V16.8882C4.57713 16.8882 1 13.3111 1 8.9441H-1ZM8.9441 18.8882C14.4156 18.8882 18.8882 14.4156 18.8882 8.9441H16.8882C16.8882 13.3111 13.3111 16.8882 8.9441 16.8882V18.8882ZM18.8882 8.9441C18.8882 3.47256 14.4156 -1 8.9441 -1V1C13.3111 1 16.8882 4.57713 16.8882 8.9441H18.8882Z" fill="#A9B6C8" mask={`url(#path-2-inside-1${index})`}/>
      <path d="M1.78857 4.52783H16.3227" stroke="#A9B6C8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.8"/>
      <path d="M1.71436 12.8569L16.2858 12.8569" stroke="#A9B6C8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.8"/>
      <path d="M0.111816 9H17.441" stroke="#A9B6C8" strokeMiterlimit="10" strokeWidth="0.8"/>
      <path d="M8.94434 0.279785V17.609" stroke="#A9B6C8" strokeMiterlimit="10" strokeWidth="0.8"/>
      <path d="M8.83248 1.13672C8.83248 1.13672 4.91943 3.07461 4.91943 8.66467C4.91943 15.3727 8.83248 17.6088 8.83248 17.6088" stroke="#A9B6C8" strokeMiterlimit="10" strokeWidth="0.8"/>
      <path d="M8.83252 1.13672C8.83252 1.13672 13.1369 2.4038 13.3046 8.66467C13.4164 14.7019 9.39153 17.6088 9.39153 17.6088" stroke="#A9B6C8" strokeMiterlimit="10" strokeWidth="0.8"/>
      <circle cx="9" cy="9" r="8.5" stroke="#A9B6C8" strokeWidth="0.8"/>
    </svg>
  )
};

export default LanguageIcon;
