// Core
import React, { memo, useCallback, Fragment } from 'react';
import { compose } from 'react-apollo';
import classnames from 'classnames';

// Components
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import Card from 'components/Card';
import Loader from 'components/Loader';
import Text from 'components/Text';
import PlansTableRow from 'routes/AdminPanel/Plans/PlansTable/PlansTableRow';


// Other
import { Grey4, Black } from 'components/Icon/color';
import { mappingAlgorithm } from 'routes/AdminPanel/PlanEdit/helpers.js';
import { MAPPING_INPUT, } from 'routes/AdminPanel/Plans/utils.js';

// Styles and Assets
import './index.scss';

const PREFIX = 'mui-override sk-admin-plans-table';
const PlansTable = ({
  items,
  loading,
  ...props
}) => {
  const renderTableBody = useCallback(items => {
    return items.map((item, index, arr) => {
      const parsedItem = mappingAlgorithm({ mapping: MAPPING_INPUT, initValues: item});
      return (
        <Fragment key={item.id}>
          <PlansTableRow key={item.id} item={parsedItem} subscribersCount={item?.subscribersCount} type={item?.type} index={index} arr={arr} />
        </Fragment>
      )
    });
  }, [items]);

  const headerHeight = 63;
  const rowHeight = 63;
  const rowsCount = +items?.length || 0;

  if (loading) return (<Loader center />);

  return (
    <Card>
      <Table
        className={`${PREFIX}`}
        style={{ height: headerHeight + (rowHeight*rowsCount) }}
      >
        <TableHead>
          <TableRow>
            <TableCell
              className={`${PREFIX}__cell`}
            >
              <Text className={`${PREFIX}__cell__elements`} bold align="left">
                TITLE
              </Text>
            </TableCell>
            <TableCell
              className={`${PREFIX}__cell`}
            >
              <Text className={`${PREFIX}__cell__elements`} bold align="left">
                PRICE, MONTH/YEAR
              </Text>
            </TableCell>
            <TableCell
              className={`${PREFIX}__cell`}
            >
              <Text className={`${PREFIX}__cell__elements`} bold align="left">
                SUBSCRIBERS
              </Text>
            </TableCell>
          </TableRow>
        </TableHead>
        {loading ? (
            <Loader center />
          ) : (
            <TableBody>
              <>
                {items && items.length > 0 ? renderTableBody(items) : null}
              </>
            </TableBody>
        )}
      </Table>
    </Card>
  );
};

const enhancer = compose(
  memo,
);

export default enhancer(PlansTable);
