// Core
import React, { memo } from 'react';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { compose } from 'react-apollo';
import RadioGroup from '@material-ui/core/RadioGroup';
import DatePicker from 'components/DatePicker';
import CountrySelect from 'routes/Authenticator/CountrySelect';
import CitySelect from 'routes/Authenticator/CitySelect';
// Components
import TextInput from 'components/Input/Material/TextInput/TextInput';
import Text from 'components/Text';
import RadioButton from 'components/RadioButton';
import Image from 'components/Image';

// Styles
import './AccountBankDetailsForm.scss';

import { messages } from 'routes/ManageAccount/AccountBankDetails/messages';
import ToolTip from '../../../../components/ToolTip/ToolTip';

const getOptions = selected => {
  const getSelectedStyles = isSelected =>
    isSelected ? { opacity: 1 } : { opacity: 0.5 };

  return [
    {
      value: 'paypal',
      label: (
        <Image
          style={getSelectedStyles(selected === 'paypal')}
          src="/assets/images/paypal-logo.png"
        />
      ),
    },
    {
      value: 'payoneer',
      label: (
        <Image
          style={getSelectedStyles(selected === 'payoneer')}
          src="/assets/images/payoneer-logo.png"
        />
      ),
    },
    {
      value: 'wire',
      label: (
        <div style={{ display: 'flex' }}>
          <Image
            style={getSelectedStyles(selected === 'wire')}
            src="/assets/images/wire-transfer-logo.png"
          />
          <ToolTip>
            <div className="sk-ac-bank-details-form__tooltip">
              <FormattedMessage {...messages.wireTooltip} />
            </div>
          </ToolTip>
        </div>
      ),
    },
  ];
};

const getAccountTypeOptions = selected => {
  const getSelectedStyles = isSelected =>
    isSelected
      ? { opacity: 1, color: '#6D7C90' }
      : { opacity: 0.5, color: '#6D7C90' };
  return [
    {
      value: true,
      label: <p style={getSelectedStyles(selected === true)}>Business</p>,
    },
    {
      value: false,
      label: <p style={getSelectedStyles(selected === false)}>Personal</p>,
    },
  ];
};

const AccountBankDetailsForm = memo(props => {
  const {
    values: {
      paymentAccountEmail,
      paymentSystem,
      country,
      city,
      accountNumber,
      recipientName,
      address,
      email,
      postcode,
      swift,
      accountType,
    },
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    handleBlur,
    disabled,
    intl: { formatMessage },
  } = props;

  const handleInputChange = name => (inputValue, e) => {
    setFieldValue(name, inputValue);
    if (e) e.persist();
  };

  const handleInputBlur = name => (inputValue, e) => {
    if (e) {
      e.persist();
      handleBlur(e);
    }
    setFieldValue(name, inputValue);
  };

  return (
    <div className="sk-ac-bank-details-form">
      <div className="sk-ac-bank-details-form__payment-system">
        <RadioGroup
          aria-label={paymentSystem}
          value={paymentSystem}
          style={{ display: 'flex' }}
          disabled={disabled}
        >
          {getOptions(paymentSystem).map(
            ({ value: optionValue, label: optionLabel }) => (
              <RadioButton
                value={optionValue}
                label={optionLabel}
                key={optionValue}
                name={paymentSystem}
                handleChange={
                  !disabled ? handleInputChange('paymentSystem') : () => {}
                }
              />
            )
          )}
        </RadioGroup>
      </div>

      <div className="sk-ac-bank-details-form__inputs">
        {paymentSystem === 'wire' ? (
          <>
            <div className="sk-ac-bank-details-form__payment-system">
              <RadioGroup
                aria-label="accountType"
                value={accountType}
                style={{ display: 'flex' }}
                disabled={disabled}
              >
                {getAccountTypeOptions(accountType).map(
                  ({ value: optionValue, label: optionLabel }) => (
                    <RadioButton
                      // classAdditional=""
                      value={optionValue}
                      label={optionLabel}
                      // disabled={disabled}
                      key={optionValue}
                      name={paymentSystem}
                      handleChange={
                        !disabled ? handleInputChange('accountType') : () => {}
                      }
                    />
                  )
                )}
              </RadioGroup>
            </div>
            <div className="sk-ac-bank-details-form__inputs-block sk-ac-bank-details-form__inputs-block-responsive">
              <TextInput
                name="recipientName"
                label={formatMessage(messages.recipientsName)}
                onChange={handleInputChange('recipientName')}
                onBlur={handleInputBlur('recipientName')}
                value={recipientName}
                disabled={disabled}
              />
            </div>
            <div className="sk-ac-bank-details-form__inputs-block sk-ac-bank-details-form__inputs-block-responsive">
              <TextInput
                name="address"
                label={formatMessage(messages.street)}
                helperText={touched.address ? errors.address : ''}
                error={touched.address && Boolean(errors.address)}
                onChange={handleInputChange('address')}
                onBlur={handleInputBlur('address')}
                disabled={disabled}
                value={address}
                className="sk-ac-bank-details-form__street"
              />
              <TextInput
                name="postcode"
                label={formatMessage(messages.postalCode)}
                onChange={handleInputChange('postcode')}
                helperText={touched.postcode ? errors.postcode : ''}
                error={touched.postcode && Boolean(errors.postcode)}
                onBlur={handleInputBlur('postcode')}
                disabled={disabled}
                value={postcode}
                className="sk-ac-bank-details-form__postcode"
              />
            </div>
            <div className="sk-ac-bank-details-form__inputs-block sk-ac-bank-details-form__inputs-block-responsive">
              {disabled ? (
                <>
                  <TextInput
                    disabled
                    name="country"
                    label={formatMessage(messages.country)}
                    placeholder={formatMessage(messages.country)}
                    value={country}
                    className="sk-ac-bank-details-form__half-width sk-ac-bank-details-form__half-width-responsive"
                  />
                  <TextInput
                    disabled
                    placeholder={formatMessage(messages.city)}
                    label={formatMessage(messages.city)}
                    name="city"
                    value={city}
                    className="sk-ac-bank-details-form__half-width sk-ac-bank-details-form__half-width-responsive"
                  />
                </>
              ) : (
                <>
                  <div className="sk-ac-bank-details-form__half-width sk-ac-bank-details-form__half-width-responsive">
                    <CountrySelect
                      helperText={touched.country ? errors.country : ''}
                      error={touched.country && Boolean(errors.country)}
                      name="country"
                      placeholder={formatMessage(messages.country)}
                      label={formatMessage(messages.country)}
                      value={country}
                      handleChange={handleInputChange('country')}
                      handleSearch={handleInputChange('country')}
                      handleBlur={handleInputBlur('country')}
                    />
                  </div>
                  <TextInput
                    placeholder={formatMessage(messages.city)}
                    label={formatMessage(messages.city)}
                    name="city"
                    value={city}
                    onChange={handleInputChange('city')}
                    helperText={touched.city ? errors.city : ''}
                    error={touched.city && Boolean(errors.city)}
                    onBlur={handleInputBlur('city')}
                    className="sk-ac-bank-details-form__half-width sk-ac-bank-details-form__half-width-responsive"
                  />
                </>
              )}
            </div>

            <TextInput
              name="email"
              label={formatMessage(messages.paymentAccountEmail)}
              helperText={touched.email ? errors.email : ''}
              error={touched.email && Boolean(errors.email)}
              onChange={handleInputChange('email')}
              onBlur={handleInputBlur('email')}
              value={email}
              disabled={disabled}
            />
            <div className="sk-ac-bank-details-form__inputs-block sk-ac-bank-details-form__inputs-block-responsive">
              <TextInput
                name="swift"
                label={formatMessage(messages.swift)}
                onChange={handleInputChange('swift')}
                helperText={touched.swift ? errors.swift : ''}
                error={touched.swift && Boolean(errors.swift)}
                onBlur={handleInputBlur('swift')}
                value={swift}
                disabled={disabled}
                className="sk-ac-bank-details-form__half-width sk-ac-bank-details-form__half-width-responsive"
              />
              <TextInput
                name="accountNumber"
                helperText={touched.accountNumber ? errors.accountNumber : ''}
                error={touched.accountNumber && Boolean(errors.accountNumber)}
                label={formatMessage(messages.accountNumber)}
                onChange={handleInputChange('accountNumber')}
                onBlur={handleInputBlur('accountNumber')}
                value={accountNumber}
                disabled={disabled}
                className="sk-ac-bank-details-form__half-width sk-ac-bank-details-form__half-width-responsive"
              />
            </div>
          </>
        ) : (
          <TextInput
            name="paymentAccountEmail"
            value={paymentAccountEmail}
            label={
              paymentSystem === 'paypal'
                ? formatMessage(messages.payPalEmail)
                : formatMessage(messages.payoneerEmail)
            }
            onChange={handleInputChange('paymentAccountEmail')}
            onBlur={handleInputBlur('paymentAccountEmail')}
            disabled={disabled}
          />
        )}
      </div>
    </div>
  );
});

const enhancer = compose(withRouter, injectIntl);

export default enhancer(AccountBankDetailsForm);
