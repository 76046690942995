import * as Yup from "yup";
import { grabValuesByKeys } from "utils/helpers";
import { getSchemaFields } from "services/yup/fields";

export const getYupSchema = (validationSchemaName) => {
  const DEFAULT_SCHEMAS = {
    authBasicScheme: grabValuesByKeys(
      ["password", "email"],
      getSchemaFields("definedSchemeFields")
    ),
  };

  const schemas = {
    authBasicScheme: grabValuesByKeys(
      ["password", "email"],
      getSchemaFields("definedSchemeFields")
    ),

    signInValidationSchema: Yup.object(DEFAULT_SCHEMAS.authBasicScheme),
    signUpValidationSchema: Yup.object({
      ...DEFAULT_SCHEMAS.authBasicScheme,
    }).shape({
      ...grabValuesByKeys(
        ["password", "confirmPassword", "firstName", "lastName"],
        getSchemaFields("definedSchemeFields")
      ),
    }),
    companySignUpValidationSchema: Yup.object({
      ...DEFAULT_SCHEMAS.authBasicScheme,
    }).shape({
      ...grabValuesByKeys(
        [
          "password",
          "confirmPassword",
          "firstName",
          "lastName",
          "companyName",
          "companySize",
          "companyPhone",
          "companySubscription",
          "country",
          "city",
        ],
        getSchemaFields("definedSchemeFields")
      ),
    }),
    socialLoginValidationSchema: Yup.object(
      grabValuesByKeys(
        ["email", "firstName", "lastName"],
        getSchemaFields("definedSchemeFields")
      )
    ),
    onboardingPolicySchema: Yup.object(
      grabValuesByKeys(
        ["isAgreeWithPolicy"],
        getSchemaFields("definedSchemeFields")
      )
    ),
    pwResetValidationSchema: Yup.object(
      grabValuesByKeys(["email"], getSchemaFields("definedSchemeFields"))
    ),
    // bankDetailsValidationSchema: Yup.object(
    //   grabValuesByKeys(['paymentAccountEmail', 'payoneerPaymentAccountEmail'], getSchemaFields('definedSchemeFields'))
    // ),
    codeConfirmSchema: grabValuesByKeys(
      ["code"],
      getSchemaFields("definedSchemeFields")
    ),
    pwChangeConfirmSchema: Yup.object(
      grabValuesByKeys(
        ["code", "confirmPassword", "password"],
        getSchemaFields("definedSchemeFields")
      )
    ),
    pwUpdateConfirmSchema: Yup.object(
      grabValuesByKeys(
        ["confirmPassword", "password"],
        getSchemaFields("definedSchemeFields")
      )
    ),
    inviteSchema: Yup.object(
      grabValuesByKeys(
        ["first_name", "last_name", "email", "role", "fqdn"],
        getSchemaFields("definedSchemeFields")
      )
    ),
    categorySchema: Yup.object(
      grabValuesByKeys(
        ["title", "localized"],
        getSchemaFields("definedSchemeFields")
      )
    ),
    subcategorySchema: Yup.object(
      grabValuesByKeys(
        ["title", "category", "localized"],
        getSchemaFields("definedSchemeFields")
      )
    ),
    studentAccountDataValidationSchema: Yup.object(
      grabValuesByKeys(
        ["firstName", "lastName", "aboutMe"],
        getSchemaFields("definedSchemeFields")
      )
    ),
    expertAccountDataValidationSchema: Yup.object(
      grabValuesByKeys(
        ["firstName", "lastName", "city", "country", "aboutMe", "expertises"],
        getSchemaFields("definedSchemeFields")
      )
    ),
    companyInfoSchema: Yup.object(
      grabValuesByKeys(
        [
          "companyName",
          "companyPhone",
          "companySize",
          "email",
          "country",
          "city",
          "logo",
          "primaryColour",
          "secondaryColour",
          "ownerId",
        ],
        getSchemaFields("definedSchemeFields")
      )
    ),
    aboutExpertInfoFormValidationSchema: Yup.object(
      grabValuesByKeys(
        ["aboutMe", "city", "country", "expertises"],
        getSchemaFields("definedSchemeFields")
      )
    ),
    changePasswordValidationSchema: Yup.object(
      grabValuesByKeys(
        ["confirmPassword", "oldPassword", "password"],
        getSchemaFields("definedSchemeFields")
      )
    ),
    courseCreationValidationSchema: Yup.object(
      grabValuesByKeys(
        ["title", "category", "subcategory"],
        getSchemaFields("definedSchemeFields")
      )
    ),

    courseGeneralInfoValidationSchema: Yup.object(
      grabValuesByKeys(
        ["category", "subcategory"],
        getSchemaFields("definedSchemeFields")
      )
    ),
    additionalCourseDetailsValidationSchema: Yup.object(
      grabValuesByKeys(
        [
          "maxNumberOfUsers",
          "timeToCompletion",
          "finalLetterToStudents",
          "coursePreview",
          "messangerUrl",
        ],
        getSchemaFields("definedSchemeFields")
      )
    ),
    courseAdminInformationValidationSchema: Yup.object(
      grabValuesByKeys(
        ["descriptionForAdmin"],
        getSchemaFields("definedSchemeFields")
      )
    ),
    courseDescriptionValidationSchema: Yup.object(
      grabValuesByKeys(
        ["complexity", "language", "requirements", "description", "skills"],
        getSchemaFields("definedSchemeFields")
      )
    ),
    courseVariantsValidationSchema: Yup.object(
      grabValuesByKeys(
        ["flex", "assist", "concierge"],
        getSchemaFields("definedSchemeFields")
      )
    ),

    editToppingValidationSchema: Yup.object(
      grabValuesByKeys(
        [
          "title",
          "price",
          "language",
          "description",
          "coursePhoto",
          "coursePreview",
          "lectureVideo",
          "recommendationFromAdmin",
        ],
        getSchemaFields("toppingEditFields")
      )
    ),
    articleModalValidationSchema: Yup.object(
      grabValuesByKeys(
        ["title", "text", "duration"],
        getSchemaFields("definedSchemeFields")
      )
    ),
    articlePDFModalValidationSchema: Yup.object(
      grabValuesByKeys(
        ["title", "duration"],
        getSchemaFields("definedSchemeFields")
      )
    ),
    fileModalWithoutModalValidationSchema: Yup.object(
      grabValuesByKeys(["title"], getSchemaFields("definedSchemeFields"))
    ),
    videoModalValidationSchema: Yup.object(
      grabValuesByKeys(["title"], getSchemaFields("definedSchemeFields"))
    ),
    expertReviewSchema: Yup.object(
      grabValuesByKeys(["mark"], getSchemaFields("definedSchemeFields"))
    ),
    orderPaySchema: Yup.object(
      grabValuesByKeys(
        ["name", "cardNumber", "expirationDate", "cvv"],
        getSchemaFields("definedSchemeFields")
      )
    ),
    editCalendarEventSchema: Yup.object(
      grabValuesByKeys(
        ["title", "startDate", "startTime", "description"],
        getSchemaFields("definedSchemeFields")
      )
    ),
    contactUsValidationSchema: Yup.object(
      grabValuesByKeys(
        ["email", "message"],
        getSchemaFields("definedSchemeFields")
      )
    ),
    emailMessageValidationSchema: Yup.object(
      grabValuesByKeys(["message"], getSchemaFields("definedSchemeFields"))
    ),
    discountEditValidationSchema: Yup.object(
      grabValuesByKeys(
        [
          "name",
          "code",
          "reference",
          "referenceId",
          "discountType",
          "discountValue",
          "startTime",
          "endTime",
          "redemptions",
        ],
        getSchemaFields("discountFields")
      )
    ),
    quizInfoValidationSchema: Yup.object(
      grabValuesByKeys(
        ["title", "duration"],
        getSchemaFields("definedSchemeFields")
      )
    ),
    expertAnalyticsQueryValidationSchema: Yup.object(
      grabValuesByKeys(
        ["startTime", "endTime"],
        getSchemaFields("discountFields")
      )
    ),
    discountApplyValidationSchema: Yup.object(
      grabValuesByKeys(["code"], getSchemaFields("applyDiscountFields"))
    ),
    quizAnswerEditValidationSchema: Yup.object(
      grabValuesByKeys(["title"], getSchemaFields("definedSchemeFields"))
    ),
    quizQuestionEditValidationSchema: Yup.object(
      grabValuesByKeys(["title"], getSchemaFields("definedSchemeFields"))
    ),
    createToppingModalValidationSchema: Yup.object(
      grabValuesByKeys(
        ["title", "type"],
        getSchemaFields("definedSchemeFields")
      )
    ),
    packagePlanEditValidationSchema: Yup.object(
      grabValuesByKeys(
        ["titleRU", "titleEN", "maxCourses", "monthlyPrice", "annualPrice"],
        getSchemaFields("planEditFields")
      )
    ),
    constructorPlanEditValidationSchema: Yup.object(
      grabValuesByKeys(
        [
          "titleRU",
          "titleEN",
          "discountPerCourse",
          "discountCoursesLimit",
          "monthlyPrice",
          "annualPrice",
        ],
        getSchemaFields("planEditFields")
      )
    ),
    commissionPlanEditValidationSchema: Yup.object(
      grabValuesByKeys(
        ["titleRU", "titleEN", "commissionPrice"],
        getSchemaFields("planEditFields")
      )
    ),
  };

  return schemas[validationSchemaName] || {};
};
