import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  goToLoginPage: {
    id: 'app.routes.EmailVerification.goToLoginPage',
    defaultMessage: 'Go to the login page',
  },
  success: {
    id: 'app.routes.EmailVerification.success',
    defaultMessage: 'Success',
  },
  error: {
    id: 'app.routes.EmailVerification.error',
    defaultMessage: 'Error',
  },
  yourEmailVerified: {
    id: 'app.routes.EmailVerification.yourEmailVerified',
    defaultMessage: 'Your email has been successfully verified',
  },
});
