// Core
import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

// Helpers
import { getCourseThumbnail } from 'utils/helpers';

// SEO Fragments
// import { CourseLinkedData } from 'seo/Course';

const { BASE_URL } = process.env;

const CourseMarketing = ({ course, location: { pathname } }) => {
  const { title, description, author, coursePhoto, thumbnail } = course;

  const seoFriendlyDescription = description
    // .replace(/[\r\n\x0B\x0C\u0085\u2028\u2029]+/g, ' ')
    .substring(0, 300);
  const canonicalUrl = `${BASE_URL}${pathname}`;
  const courseThumbnail = getCourseThumbnail(thumbnail, coursePhoto);
  const seo = {
    title: `${title.substring(0, 48)} | AVDO`,
    description: seoFriendlyDescription.substring(0, 160),
    og: {
      title: `${title} - ${author.firstName} ${author.lastName}`,
      description: `${seoFriendlyDescription}...`,
      url: canonicalUrl,
    },
  };
  const linkedData = {
    '@context': 'https://schema.org',
    '@type': 'Course',
    name: title,
    image: courseThumbnail,
    thumbnailUrl: courseThumbnail,
    description: seoFriendlyDescription.substring(0, 60),
    author: {
      '@type': 'Person',
      familyName: author.lastName,
      givenName: author.firstName,
    },
    provider: {
      '@type': 'Person',
      familyName: author.lastName,
      givenName: author.firstName,
      name: 'AVDO',
      image: author?.photo,
      sameAs: BASE_URL,
    },
  };

  return (
    <Helmet>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta property="og:title" content={seo.og.title} />
      <meta property="og:url" content={seo.og.url} />
      <meta property="og:description" content={seo.og.description} />
      <meta property="og:type" content="article" />
      <meta property="og:image" content={coursePhoto} />
      <meta name="twitter:card" content="summary" />
      <script type="application/ld+json">{JSON.stringify(linkedData)}</script>
    </Helmet>
  );
};

CourseMarketing.propTypes = {
  course: PropTypes.object,
  location: PropTypes.object,
};

export default CourseMarketing;
