// Core
import React, { PureComponent } from 'react';
import { compose } from 'react-apollo';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { generatePath } from 'react-router';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';

// Components
import Card from 'components/Card';
import Image from 'components/Image';
import StarRating from 'components/StarRating';
import IconWithDescription from './IconWithDescription';
import PriceRadioButtonLabel from 'components/CourseCard/PriceRadioButtonLabel';
import Text from 'components/Text/Text';
import { Grey4 } from 'components/Icon/color';
import Heading from 'components/Heading';
import PriceBoard from 'components/PriceBoard';
import CourseCardActionBlock from 'components/CourseCard/CourseCardActionBlock';

// Icons
import GiftIcon from 'components/Icon/SvgCss/Gift';
import User from 'components/Icon/Svg/User';
import Clock from 'components/Icon/Svg/Clock';
import Level from 'components/Icon/Svg/Level';
import Worldwide from 'components/Icon/Svg/Worldwide';
import Notebook from 'components/Icon/Svg/Notebook';

// Styles and Assets
import './CourseCard.scss';

// Other
import { VARIANTS_NAMES } from 'routes/ExpertsCabinet/CourseEdit/enums';
import {
  hasVariant,
  getVariant,
} from 'routes/ExpertsCabinet/CourseEdit/Details/CourseInformation/helpers.js';
import { numberSplitter } from 'utils/helpers';
import { messages } from 'components/CourseCard/messages';
import {
  isBlank,
  capitalize,
  parseFetchedData,
  parseEntityDuration,
  renderSmartTrimmedText,
} from 'utils/helpers';
import { sharedMessages } from 'services/i18n/sharedMessages/messages';

// SEO
import { CourseListItemLinkedData } from 'seo/Course/LinkedData';

class CourseCard extends PureComponent {
  state = {
    selectedVariant: !isBlank(this.props?.course.variants)
      ? this.props?.course.variants[0]
      : {},
    // selectedVariant: getVariant({ variants: this.props?.course.variants, variantName: 'flex' }),
  };

  getInteractivityLevelData = interactionLevel => {
    const {
      course: { variants },
      intl: { locale },
    } = this.props;

    return VARIANTS_NAMES.map(variantName => {
      const hasCourseVariant = hasVariant({ variants, variantName });
      const courseVariant = getVariant({ variants, variantName });

      const disabled = !hasCourseVariant || isBlank(courseVariant?.price);
      const isFree = hasCourseVariant ? courseVariant.price === 0 : false;

      return {
        disabled,
        value: capitalize(variantName),
        label: (
          <PriceRadioButtonLabel
            withIcon={isFree}
            icon={GiftIcon}
            price={courseVariant?.price}
            name={<FormattedMessage {...messages[variantName]} />}
            disabled={disabled}
          />
        ),
      };
    });
  };

  handlePriceSelect = variantName => {
    const {
      course: { variants },
    } = this.props;

    const selectedVariant = getVariant({
      variants,
      variantName: variantName.toLowerCase(),
    });
    this.setState({ selectedVariant });
  };

  parseCourseDuration = duration => {
    const courseDurationInHours = parseEntityDuration(
      duration,
      'seconds',
      'asHours'
    );
    return courseDurationInHours < 1 ? 1 : courseDurationInHours;
  };

  getCourseThumbnail = course => {
    const { thumbnail, coursePhoto } = course;

    if (thumbnail) {
      return thumbnail;
    }

    return coursePhoto || '/assets/images/сourse-photo-placeholder.jpg';
  };

  getTotalTime = duration => {
    const totalHours = +parseEntityDuration(duration, 'seconds', 'asHours');
    const totalMinutes = +parseEntityDuration(duration, 'seconds', 'asMinutes');

    return totalMinutes >= 60
      ? { totalHours, totalMinutes: totalMinutes - totalHours * 60 }
      : { totalHours: 0, totalMinutes };
  };

  render() {
    const {
      course,
      position,
      intl: { formatMessage, locale },
    } = this.props;

    const parsedCourse = parseFetchedData(course);
    const {
      id,
      slug,
      totalLectures,
      duration,
      author,
      complexity,
      description,
      language,
      subcategory = null,
      title,
      category,
      coursePhoto,
      ratingTotal,
      rating,
      timeToCompletion,
      displayLectureNameType,
      userCourseId,
      className,
    } = parsedCourse;

    const boughted = userCourseId ? true : false;

    const { selectedVariant } = this.state;

    const { totalHours, totalMinutes } = this.getTotalTime(duration);
    const durationValue =
      totalHours > 0
        ? `${totalHours}${formatMessage(
            sharedMessages.hours
          )} ${totalMinutes}${formatMessage(sharedMessages.min)}`
        : `${totalMinutes}${formatMessage(sharedMessages.min)}`;
    const authorName =
      author && author !== null
        ? `${author.firstName} ${author.lastName}`
        : 'DELETED';

    const courseUrl = subcategory?.id
      ? generatePath('/:locale/courses/:category/:subcategory/:slug', {
          locale,
          category: category?.id,
          subcategory: subcategory?.id || null,
          slug,
        })
      : generatePath('/:locale/courses/:category/:slug', {
          locale,
          category: category?.id,
          slug,
        });

    return (
      <div className={`sk-course-card ${className ? className : ''}`}>
        <CourseListItemLinkedData
          title={title}
          description={description}
          position={position}
          authorName={authorName}
          thumbnail={this.getCourseThumbnail(parsedCourse)}
          url={`/courses/${category?.id}/${subcategory?.id}/${slug}`}
          rating={{ rating }}
        />
        <Card className='sk-course-card__background' />
        <div className='sk-course-card__content'>
          <Link style={{ textDecoration: 'none' }} to={courseUrl}>
            <div className='sk-course-card__photo'>
              <p>{formatMessage(sharedMessages[category?.title])}</p>
              <Image src={this.getCourseThumbnail(parsedCourse)} />
            </div>
            <div className='sk-course-card__creator'>
              <IconWithDescription
                icon={User}
                iconWidth='16'
                iconHeight='18.5'
                iconStrokeColor={Grey4}
                description={authorName}
              />
            </div>
            <Heading classes='sk-course-card__name' variant='h5'>
              {renderSmartTrimmedText(title, 27, 2)}
            </Heading>
            <div className='sk-course-card__rating'>
              <div className='sk-course-card__rating__stars'>
                <StarRating rate={ratingTotal.averageScore} />
              </div>
              <Text size='small' className='sk-course-card__rating__value'>
                <div className='sk-course-card__rating__value_left'>
                  <span className='number'>{ratingTotal.averageScore}</span>
                </div>
                <div className='sk-course-card__rating__value_right'>
                  <span className='description'>&nbsp;(</span>
                  <span className='number'>
                    {numberSplitter(ratingTotal.totalVotes)}
                  </span>
                  <span className='description'>)</span>
                </div>
              </Text>
            </div>
          </Link>
          <div className='sk-course-card__price-border'>
            <PriceBoard
              boughted={boughted}
              handleChange={this.handlePriceSelect}
              tooltipIconTheme='orange'
              value={selectedVariant?.name}
              options={this.getInteractivityLevelData().filter(
                c => !c.disabled
              )}
            />
          </div>
          <CourseCardActionBlock
            id={id}
            title={title}
            coursePhoto={coursePhoto}
            description={description}
            selectedVariant={{
              name: selectedVariant?.name,
              price: selectedVariant?.price,
            }}
            timeToCompletion={timeToCompletion}
            author={author}
            boughted={boughted}
            courseUrl={courseUrl}
          />
        </div>
      </div>
    );
  }
}

const enhancer = compose(injectIntl);
export default enhancer(CourseCard);
