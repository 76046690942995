// Core
import React from 'react';

// Components
import Image from 'components/Image';
import UserAvatar from 'components/UserAvatar';
import Text from 'components/Text';
import ReadMore from 'components/ReadMore'

// Other
import { isBlank } from 'utils/helpers';

// Styles
import './Author.scss';

const PREFIX_COMPONENT = `sk-toppings-carousel_content_author`;
const PREFIX = "sk-toppings-carousel_content__area";

const Author = ({ author, expertLabel }) => {
  const {
    firstName,
    lastName,
    photo,
    expertBio,
    aboutMe
  } = author;

  const aboutExpert = !isBlank(aboutMe) ? aboutMe : expertBio;
  const isReadMore = aboutExpert.match(/\S+/g).length > 35;

  return(
    <div className={`${PREFIX_COMPONENT}`}>
      <div className={`${PREFIX_COMPONENT}__top`}>
        <div className={`${PREFIX_COMPONENT}__photo`}>
          <UserAvatar
            classes="sk-courseDetails-author__useravatar"
            avatar={photo}
            firstName={firstName}
            lastName={lastName}
          />
        </div>
        <div className={`${PREFIX_COMPONENT}__name`}>
          <Text className={`${PREFIX_COMPONENT}__name_string`}>{firstName} {lastName}</Text>
          <Text className={`${PREFIX_COMPONENT}__name_position`}>{expertLabel}</Text>
        </div>
      </div>
      <div className={`${PREFIX}__description`}>
      {isReadMore ? (
          <ReadMore lines={4} withToggle textStyles={{overflowY: "scroll", whiteSpace: 'pre-line'}} >
            {aboutExpert}
          </ReadMore>
        ): (
          <div style={{ overflowY: 'scroll', whiteSpace: 'pre-line' }}>
            {aboutExpert}
          </div>
        )}
      </div>
    </div>
  );
};

export default Author;
