import { AUTH_TYPE } from "aws-appsync";
import { getCurrentCredentials } from "services/aws/amplify/helpers";
import {
  AWS_REGION,
  GRAPHQL_API_ENDPOINT_PRIVATE_URL,
  GRAPHQL_API_ENDPOINT_PUBLIC_URL,
  PUBLIC_API_KEY,
  IDENTITY_POOL_ID,
  USER_POOL_ID,
  USER_POOL_CLIENT,
  S3_BUCKET_USERFILES,
  SITE_DOMAIN,
  REDIRECT_SIGNIN_URL,
  REDIRECT_SIGNOUT_URL,
  COGNITO_APP_DOMAIN,
  USE_SECURE_COOKIES,
} from "services/aws/amplify/exports";

// Before edit - read this https://serverless-stack.com/chapters/cognito-user-pool-vs-identity-pool.html
// How to use IAM Auth for AppSync - https://github.com/awslabs/aws-mobile-appsync-sdk-js/issues/11
const currentHostname = window?.location?.hostname;
const staticHosts = [SITE_DOMAIN, `www.${SITE_DOMAIN}`, 'localhost', 'localhost:3000'];

// Default domain is the main domain
let cookieDomain = SITE_DOMAIN;

if (currentHostname && !staticHosts.includes(currentHostname)) {
  cookieDomain = currentHostname;
}

const amplifyConfig = {
  Auth: {
    identityPoolId: IDENTITY_POOL_ID,
    region: AWS_REGION,
    userPoolId: USER_POOL_ID,
    userPoolWebClientId: USER_POOL_CLIENT,
    cookieStorage: {
      domain: cookieDomain,
      path: "/",
      expires: 30,
      secure: USE_SECURE_COOKIES,
    },
  },
  Analytics: {
    disabled: true,
  },
  Storage: {
    bucket: S3_BUCKET_USERFILES,
    region: AWS_REGION,
  },
};

// Hosted UI
if (window && !window.isServer) {
  amplifyConfig.Auth.oauth = {
    domain: COGNITO_APP_DOMAIN,
    scope: ["email", "profile", "openid", "aws.cognito.signin.user.admin"],
    redirectSignIn: REDIRECT_SIGNIN_URL,
    redirectSignOut: REDIRECT_SIGNOUT_URL,
    responseType: "token", // or 'token', note that REFRESH token will only be generated when the responseType is code
  };
}

export const AppSyncConfig = {
  region: AWS_REGION,
  complexObjectsCredentials: () => getCurrentCredentials(),
  private: {
    url: GRAPHQL_API_ENDPOINT_PRIVATE_URL,
    auth: {
      type: AUTH_TYPE.AWS_IAM,
      credentials: () => getCurrentCredentials(),
    },
  },
  public: {
    url: GRAPHQL_API_ENDPOINT_PUBLIC_URL,
    auth: {
      apiKey: PUBLIC_API_KEY,
      type: AUTH_TYPE.API_KEY,
    },
  },
};

export default amplifyConfig;
