// Core
import React, { memo } from 'react';
import { injectIntl } from 'react-intl';
import { compose } from 'react-apollo';

// Components
import Loader from 'components/Loader';
import SimpleSidebarMenu from 'containers/Sidebar/SidebarContent/SimpleSidebarMenu';

// Icons
import AudioIcon from 'components/Icon/Svg/StudentCabinet/SidebarLectureAudio.js';
import VideoIcon from 'components/Icon/Svg/StudentCabinet/SidebarLectureVideo.js';
import DocumentIcon from 'components/Icon/Svg/StudentCabinet/SidebarLectureDocument.js';
import QuizIcon from 'components/Icon/Svg/StudentCabinet/SidebarLectureQuiz.js';

// Other
import { getMaterials, getItemUrl, compareSortNumber } from 'utils/helpers';
import { Purple, Transparent } from 'components/Icon/color';
import { sharedMessages } from 'services/i18n/sharedMessages/messages';
import {
  LECTURE_ARTICLE,
  LECTURE_VIDEO,
  LECTURE_AUDIO,
  LECTURE_QUIZ,
} from 'containers/UploadManagerProvider/helpers';
import { renderTrimmedText } from 'utils/helpers';

const MODALS = {
  [LECTURE_VIDEO]: {
    icon: <VideoIcon />,
  },
  [LECTURE_AUDIO]: {
    icon: <AudioIcon />,
  },
  [LECTURE_ARTICLE]: {
    icon: <DocumentIcon />,
  },
  [LECTURE_QUIZ]: {
    icon: <QuizIcon />,
  },
  [null]: {
    icon: <DocumentIcon />,
  },
};

const getIcon = type => () => MODALS[type]?.icon || MODALS.null?.icon;

const getSubItemsMenu = (
  materials,
  url,
  currentLectureId,
  lectureId,
  { openedSubItem }
) =>
  materials.map(({ id, title, link, type }) => ({
    id,
    path: getItemUrl(url, { id, link, type }, currentLectureId, lectureId, type),
    label: renderTrimmedText(title, 55),
    icon: getIcon(type),
    openedSubItemId: openedSubItem,
  }));

const LecturesList = ({
  openedSubItem,
  handleListToggle,
  handleSubListToggle,
  list: lectures,
  loading,
  url,
  lectureId,
  studyMaterialId,
  displayLectureNameType,
  intl: { formatMessage },
}) => {
  if (!lectures) return <Loader />;
  const isLectureTypeDefined =
    displayLectureNameType && displayLectureNameType !== null;

  const menuItems = lectures
    .filter(item => item.materials)
    .map(({ id, title, materials }, index) => ({
      id,
      label: `${formatMessage(
        sharedMessages[
          isLectureTypeDefined ? displayLectureNameType : 'lecture'
        ]
      )} ${index + 1} ${renderTrimmedText(title, 35)}`,
      subItems: getSubItemsMenu(
        materials.sort(compareSortNumber),
        url,
        id,
        lectureId,
        { openedSubItem }
      ),
    }));

  return (
    <div>
      {!loading && lectures?.length ? (
        <SimpleSidebarMenu
          menuItems={menuItems}
          withSubMenu
          darkSubmenu
          handleClick={handleListToggle}
          handleSubMenuTitleClick={handleSubListToggle}
          openedSubItemId={openedSubItem}
          openedItem={lectureId}
        />
      ) : (
        <Loader small />
      )}
    </div>
  );
};

const enhancer = compose(memo, injectIntl);

export default enhancer(LecturesList);
