// Core
import React, { memo } from 'react';
import { graphql, compose } from 'react-apollo';
import { injectIntl } from 'react-intl';

// Components
import SimpleSelect from 'components/Select/SimpleSelect';
import FilterDropDown from 'components/FilterDropDown';

// Other
import { messages } from 'routes/Course/components/ComplexitySelect/messages';

const ComplexitySelect = ({
  listComplexities,
  type = 'simple',
  intl: { formatMessage },
  ...props
}) => {
  const sortedListComplexities = listComplexities?.items.sort(
    (prev, cur) => prev.order_number - cur.order_number
  );

  // eslint-disable-next-line no-unused-expr
  sortedListComplexities && sortedListComplexities.forEach(item => {
    const isMessageIdDefined = !!messages[item.title.replace(' ', '')]?.id;
    if (typeof item.title === 'string' && isMessageIdDefined) {
      item.title = formatMessage(messages[item.title.replace(' ', '')]);
    }
  });

  return (
    <SimpleSelect
      inlineStyles={{ selector: { minHeight: 40 } }}
      options={listComplexities?.items}
      // loading={loading}
      // error={error}
      valueKey="id"
      labelKey="title"
      {...props}
    />
  );
}



const enhancer = compose(
  memo,
  injectIntl,
);

export default enhancer(ComplexitySelect);
