// Core
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';

// Components
import Text from 'components/Text';
import Heading from 'components/Heading';

// Locales
import EN from 'services/i18n/howToCreateCourse/en.json';
import RU from 'services/i18n/howToCreateCourse/ru.json';

// Styles and Assets
import './HowToCreateCourse.scss';

import { withLanguage } from 'containers/LanguageProvider/withLanguage';

// Utils
import { findOneOf } from 'utils/helpers/';

const contentTypes = ['paragraphs', 'unordered_list', 'footer'];

let keyCounter = 0;

const renderList = list =>
  list.map(text => {
    keyCounter += 1;
    return (
      <Text
        key={keyCounter}
        size="caption"
        classes="sk-how-to-create-course__title"
      >
        <li>
          <Text size="caption" classes="sk-how-to-create-course__title">
            {text}
          </Text>
        </li>
      </Text>
    );
  });

const renderParagraph = paragraphs =>
  paragraphs.map(text => {
    keyCounter += 1;
    return (
      <Text
        key={keyCounter}
        size="caption"
        classes="sk-how-to-create-course__title"
      >
        {text}
      </Text>
    );
  });

const renderFooter = footer =>
  footer.map(text => {
    keyCounter += 1;
    return (
      <Text
        key={keyCounter}
        size="caption"
        classes="sk-how-to-create-course__footer"
      >
        {text}
      </Text>
    );
  });

const renderUnorderedList = content => (
  <ul style={{ listStyle: 'disc', padding: '0 16px' }}>
    {renderList(content)}
  </ul>
);

const contentRenderFunctions = {
  paragraphs: renderParagraph,
  footer: renderFooter,
  unordered_list: renderUnorderedList,
};

const HowToCreateCourse = ({ languageContext: { language } }) => {
  const currentLocaleConfig = language === 'en' ? EN : RU;

  return (
    <div className="sk-how-to-create-course">
      <div className="sk-how-to-create-course__block">
        <Text size="caption" classes="sk-how-to-create-course__title" />
      </div>
      <header className="sk-how-to-create-course__header">
        <Heading
          variant="h4"
          bold
          classes="sk-how-to-create-course__subtitle heading-sk__center"
        >
          {currentLocaleConfig.title}
        </Heading>
      </header>
      <div>
        {currentLocaleConfig.blocks.map(block => {
          const contentType = findOneOf(Object.keys(block), contentTypes);
          const isContentTypeDefined = !!contentType;
          const contentRenderFunction =
            isContentTypeDefined && contentRenderFunctions[contentType];
          keyCounter += 1;
          return (
            <div className="sk-how-to-create-course__block" key={keyCounter}>
              <Text
                size="caption"
                classes="sk-how-to-create-course__title sk-how-to-create-course__heading"
              >
                {block.title}
              </Text>
              {isContentTypeDefined
                ? contentRenderFunction(block[contentType])
                : null}
            </div>
          );
        })}
      </div>
    </div>
  );
};

HowToCreateCourse.defaultProps = {
  language: 'en',
};

HowToCreateCourse.propTypes = {
  languageContext: PropTypes.object,
};

const enhancer = compose(
  memo,
  withLanguage
);

export default enhancer(HowToCreateCourse);
