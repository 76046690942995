// Core
import React, { memo, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';
import ReactHtmlParser from 'react-html-parser';

// Components
import Text from 'components/Text';
import Heading from 'components/Heading';

// Locales
import EN from 'services/i18n/termsOfUse/en.json';
import RU from 'services/i18n/termsOfUse/ru.json';

// Styles and Assets
import './TermsOfUseText.scss';

import { withLanguage } from 'containers/LanguageProvider/withLanguage';

// Utils
import { findAllOf } from 'utils/helpers/';

const contentTypes = [
  'paragraphs',
  'ordered_list',
  'unordered_list',
  'ordered_list_title',
];

let keyCounter = 0;

const renderList = list =>
  list.map(text => {
    keyCounter += 1;
    return (
      <Text
        key={keyCounter}
        size="caption"
        classes="sk-privacy-policy-text__title"
      >
        <li>
          <Text
            size="caption"
            classes="sk-privacy-policy-text__title"
            style={{ padding: '6px 0', display: 'block', textAlign: 'justify' }}
          >
            {ReactHtmlParser(text)}
          </Text>
        </li>
      </Text>
    );
  });

const renderParagraph = paragraphs =>
  paragraphs.map(text => {
    keyCounter += 1;
    return (
      <Text
        key={keyCounter}
        size="caption"
        style={{ padding: '10px 0', display: 'block', textAlign: 'justify' }}
        classes="sk-privacy-policy-text__title"
      >
        {ReactHtmlParser(text)}
      </Text>
    );
  });

const renderOrderedList = content => (
  <ol style={{ padding: '0 12px', fontSize: '12px' }}>{renderList(content)}</ol>
);

const renderUnorderedList = content => (
  <ul style={{ listStyle: 'disc', padding: '0 16px' }}>
    {renderList(content)}
  </ul>
);

const renderPart = (block, currentTypes, counter) =>
  currentTypes.map((contentType, i) => {
    const contentRenderFunction = contentRenderFunctions[contentType];
    if (contentRenderFunctions) {
      return (
        <Fragment key={`${counter + i}`}>
          {contentRenderFunction(block[contentType])}
        </Fragment>
      );
    }
    return null;
  });

const contentRenderFunctions = {
  paragraphs: renderParagraph,
  ordered_list: renderOrderedList,
  unordered_list: renderUnorderedList,
  ordered_list_title: renderParagraph,
};

const TermsOfUseText = ({ languageContext: { language } }) => {
  const currentLocaleConfig = language === 'en' ? EN : RU;

  return (
    <div className="sk-how-to-create-course">
      <div className="sk-how-to-create-course__block">
        <Text size="caption" classes="sk-how-to-create-course__title">
          {currentLocaleConfig.updatedAt}
        </Text>
      </div>
      <header className="sk-how-to-create-course__header">
        <Heading
          variant="h4"
          bold
          classes="sk-how-to-create-course__subtitle heading-sk__center"
        >
          {currentLocaleConfig.title}
        </Heading>
      </header>
      <div>
        {currentLocaleConfig.blocks.map(block => {
          const currentTypes = findAllOf(Object.keys(block), contentTypes);
          const isContentTypeDefined = currentTypes.length > 0;
          keyCounter += 1;
          return (
            <div className="sk-how-to-create-course__block" key={keyCounter}>
              <Text
                size="caption"
                classes="sk-how-to-create-course__title sk-how-to-create-course__heading"
              >
                {block.title}
              </Text>
              {isContentTypeDefined
                ? renderPart(block, currentTypes, keyCounter)
                : null}
            </div>
          );
        })}
      </div>
    </div>
  );
};

TermsOfUseText.defaultProps = {
  language: 'en',
};

TermsOfUseText.propTypes = {
  languageContext: PropTypes.object,
};

const enhancer = compose(
  memo,
  withLanguage
);

export default enhancer(TermsOfUseText);
