// Core
import React, { PureComponent, Fragment } from 'react';
import { compose } from 'react-apollo';
import { FormattedMessage, injectIntl } from 'react-intl';

// Material Components
import Loader from 'components/Loader';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';

// Components
import Text from 'components/Text';
import EarningsTableRow from 'routes/ManageAccount/ExpertSales/EarningsTable/EarningsTableRow';

// Styles and Assets
import './EarningsTable.scss';

// Other
import { messages } from 'routes/ManageAccount/ExpertSales/messages';
import { toUpperCase } from 'utils/helpers';

class EarningsTable extends PureComponent {
  renderTableBody = (courses, total, formatMessage) => (
    <>
      {courses.map((item, index, arr) => (
        <Fragment key={item.id}>
          <EarningsTableRow key={item.id} item={item} index={index} arr={arr} />
        </Fragment>
      ))}
      <EarningsTableRow total={true} key={'total'} item={{ ...total, title: formatMessage(messages.total), }} index={0} arr={[total]} />
    </>
  )

  render() {
    const { loading, courses, total, intl: { formatMessage } } = this.props;

    const headerHeight = 63;
    const rowHeight = 63;
    const rowsCount = +courses?.length || 0;

    return (
      <Table
        className="mui-override sk-expert-analytics-earnings"
        style={{ height: headerHeight + (rowHeight*rowsCount) }}
      >
        <TableHead>
          <TableRow>
            <TableCell className="mui-override sk-expert-analytics-earnings__cell">
              <Text bold align="left">
                {formatMessage(messages.courseTitle)}
              </Text>
            </TableCell>
            <TableCell className="mui-override sk-expert-analytics-earnings__cell">
              <Text bold align="right">
                {toUpperCase(formatMessage(messages.flex))}
              </Text>
            </TableCell>
            <TableCell className="mui-override sk-expert-analytics-earnings__cell">
              <Text bold align="right">
                {toUpperCase(formatMessage(messages.assist))}
              </Text>
            </TableCell>
            <TableCell className="mui-override sk-expert-analytics-earnings__cell">
              <Text bold align="right">
                {toUpperCase(formatMessage(messages.concierge))}
              </Text>
            </TableCell>
            <TableCell className="mui-override sk-expert-analytics-earnings__cell">
              <Text bold align="right">
                {formatMessage(messages.total)}
              </Text>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading && (<Loader />)}
          {(courses && courses.length > 0 && !loading) ? this.renderTableBody(courses, total, formatMessage) : null}
        </TableBody>
      </Table>
    );
  }
}

const enhancer = compose(injectIntl);

export default enhancer(EarningsTable)
