// Core
import React, { memo } from 'react';
import PropTypes from 'prop-types';

// Components
import Loader from 'components/Loader';
import Heading from 'components/Heading';
import Button from 'components/Button';
import Text from 'components/Text';
import Done from 'components/Icon/Svg/Done';
import Cross from 'components/Icon/Svg/Cross';
import Link from 'components/Link';
import Card from 'components/Card';

// Other
import { OrangeyRed } from 'components/Icon/color';

// Styles and Assets
import './ActionStatusCard.scss';

export const ActionStatusCard = ({
  linkToProceed,
  status,
  statusText,
  statusHeading,
  proceedText,
  loading,
}) => (
  <Card classes="sk-action-status-card">
    {loading || !status || status === null ? (
      <Loader />
    ) : (
      <div className="sk-action-status-card__inner">
        <ActionStatusCardHeader
          status={status}
          statusText={statusText}
          statusHeading={statusHeading}
        />
        <ActionStatusCardFooter
          linkToProceed={linkToProceed}
          proceedText={proceedText}
        />
      </div>
    )}
  </Card>
);

const ActionStatusCardHeader = ({ status, statusText, statusHeading }) => (
  <div className="sk-action-status-card__header">
    <div className="sk-action-status-card__icon">
      {status === 'success' ? (
        <Done width="51" height="51" />
      ) : (
        <Cross width="51" height="51" color={OrangeyRed} />
      )}
    </div>
    <Heading variant="h4" classes="sk-action-status-card__heading">
      {statusHeading}
    </Heading>
    <Text classes="sk-action-status-card__subheading">{statusText}</Text>
  </div>
);

const ActionStatusCardFooter = ({ linkToProceed, proceedText }) => (
  <div className="sk-action-status-card__footer">
    <Link to={linkToProceed}>
      <Button variant="text" color="secondary" size="large">
        {proceedText}
      </Button>
    </Link>
  </div>
);

ActionStatusCard.defaultProps = {
  status: 'success',
};

ActionStatusCard.propTypes = {
  status: PropTypes.string,
  statusText: PropTypes.string.isRequired,
  statusHeading: PropTypes.string.isRequired,
  linkToProceed: PropTypes.string.isRequired,
  proceedText: PropTypes.string.isRequired,
  loading: PropTypes.bool,
};

ActionStatusCardHeader.propTypes = {
  status: PropTypes.string.isRequired,
  statusText: PropTypes.string.isRequired,
  statusHeading: PropTypes.string.isRequired,
};
ActionStatusCardFooter.propTypes = {
  linkToProceed: PropTypes.string.isRequired,
  proceedText: PropTypes.string.isRequired,
};

export default memo(ActionStatusCard);
