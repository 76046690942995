// Core
import React, { PureComponent } from 'react';
import { compose, graphql } from 'react-apollo';

// Components
import Calendar from 'containers/Calendar';

// GraphQl
import GET_EXPERT_CALENDAR_EVENT_QUERY from 'queries/Calendar/getExpertCalendarEvents.gql';

// Other
import { withAuthenticatedUser } from 'containers/AuthenticatedUserProvider/withAuthenticatedUser';

const ExpertCalendar = ({
  userContext: { profile },
  events,
  ...props
}) => {
  return (
    <Calendar
      role={profile?.role}
      courses="ACTIVE"
      refetchQueries={['expertListCalendarEvents']}
      expertListCalendarEvents={events}
    />
  );
}

const getExpertCalendarEventsQuery = graphql(GET_EXPERT_CALENDAR_EVENT_QUERY, {
  props: ({
    data: { loading, error, expertListCalendarEvents, ...ownProps },
  }) => ({
    loading,
    events: expertListCalendarEvents,
    error,
    ...ownProps,
  }),
  options: () => ({
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        TimeFromUnixMs: new Date().getTime() - 100000000000,
        TimeToUnixMs: new Date().getTime() + 100000000000,
      },
    },
  }),
});

const enhancer = compose(
  withAuthenticatedUser,
  getExpertCalendarEventsQuery,
);

export default enhancer(ExpertCalendar);
