// Core
import React from 'react';
import PropTypes from 'prop-types';

import { Formik } from 'formik';

// Components
import Text from 'components/Text';
import Checkbox from 'components/Checkbox';
import CheckboxLabel from 'components/CheckboxLabel';
import PolicyText from 'containers/PolicyText';
import Button from 'components/Button';

// Other
import { getYupSchema } from 'services/yup';
import { FormattedMessage } from 'react-intl';
import { messages } from 'routes/Onboarding/OnboardingPolicy/messages';

// Styles and Assets
import '../Onboarding.scss';

const POLICY_FIELDS = {
  isAgreeWithPolicy: false,
};

const OnboardingPolicy = ({ handleSwitch }) => (
  <Formik
    onSubmit={() => {
      handleSwitch();
    }}
    initialValues={POLICY_FIELDS}
    validationSchema={getYupSchema('onboardingPolicySchema')}
  >
    {props => {
      const {
        touched,
        errors,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        values: { isAgreeWithPolicy },
      } = props;

      const handleInputChange = name => inputValue => {
        setFieldValue(name, inputValue);
        setFieldTouched(name, true, false);
      };

      return (
        <form
          className="sk-onboarding_policy"
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <Text classes="sk-onboarding_policy_heading" bold size="small">
            <FormattedMessage {...messages.pleaseReadAgree} />
          </Text>
          <div className="sk-onboarding_policy_area">
            <PolicyText />
          </div>
          <footer className="sk-onboarding_footer">
            <div className="sk-onboarding_policy_terms">
              <Checkbox
                label={
                  <CheckboxLabel
                    errorText={
                      touched.isAgreeWithPolicy ? errors.isAgreeWithPolicy : ''
                    }
                    error={
                      touched.isAgreeWithPolicy &&
                      Boolean(errors.isAgreeWithPolicy)
                    }
                  >
                    <Text size="caption">
                      <FormattedMessage {...messages.ihaveReadAgree} />
                    </Text>
                  </CheckboxLabel>
                }
                name="isAgreeWithPolicy"
                checked={!!isAgreeWithPolicy}
                handleChange={handleInputChange('isAgreeWithPolicy')}
                error={
                  touched.isAgreeWithPolicy && Boolean(errors.isAgreeWithPolicy)
                }
              />
            </div>
            <div className="sk-onboarding_policy_button">
              <Button
                variant="text"
                color="secondary"
                size="large"
                type="submit"
              >
                <FormattedMessage {...messages.startTeaching} />
              </Button>
            </div>
          </footer>
        </form>
      );
    }}
  </Formik>
);

OnboardingPolicy.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.bool,
  handleSubmit: PropTypes.func,
  handleSwitch: PropTypes.func,
  setFieldTouched: PropTypes.func,
  setFieldValue: PropTypes.func,
};

export default OnboardingPolicy;
