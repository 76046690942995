// Core
import React from 'react';

import {
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  VKShareButton,
} from 'react-share';

// Icons
import RoundIcon from 'components/Icon/RoundIcon';
import Facebook from 'components/Icon/SvgCss/PublicCoursePage/SocialShare/Facebook.js';
import LinkedIn from 'components/Icon/SvgCss/PublicCoursePage/SocialShare/LinkedIn.js';
import Telegram from 'components/Icon/SvgCss/PublicCoursePage/SocialShare/Telegram.js';

// Intl
import { FormattedMessage } from 'react-intl';
import { messages } from 'components/ShareButton/ShareContainer/messages';

// Styles
import './ShareContainer.scss';

const ShareContainer = ({shareUrl}) => {
  const getShareUrl = () => {
    if (document && document.location) {
      const {location: {origin, href}} = document;
      if (shareUrl && origin) {
        return `${origin}${shareUrl}`;
      } else {
        if (href) {
          return href;
        }      
      }
    }
    return '/';
  }

  const SHARE_ELEMENTS = [
    {
      name: 'facebook',
      container: () => (
        <FacebookShareButton url={getShareUrl()}>
          <Facebook />
        </FacebookShareButton>
      )
    },
    {
      name: 'telegram',
      container: () => (
        <TelegramShareButton url={getShareUrl()}>
          <Telegram />
        </TelegramShareButton>
      )
    },
    {
      name: 'linkedin',
      container: () => (
        <LinkedinShareButton url={getShareUrl()}>
          <LinkedIn />
        </LinkedinShareButton>
      )
    },
  ];

  return (
    <>
      <h3 className="sk-social-share__title">
        <FormattedMessage {...messages.title} />
      </h3>
      <ul className="sk-social-share__list">
        {SHARE_ELEMENTS.map(item => (
          <li key={item.name} className={item.name}>{item.container()}</li>
        ))}
      </ul>
    </>
  );
}

export default ShareContainer;
