import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { PurpleHeart, SidebarDefault } from 'components/Icon/color';

const ChevronLeft = memo(({ height, width, active, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 7 12"
  >
    <path
      fill="none"
      fillRule="nonzero"
      stroke={color || (active ? PurpleHeart : SidebarDefault)}
      strokeWidth={active ? 2 : 1.3}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M6 10.125L1.437 5.562 6 1"
    />
  </svg>
));

ChevronLeft.propTypes = {
  active: PropTypes.bool,
  height: PropTypes.string,
  width: PropTypes.string,
  color: PropTypes.string,
};

ChevronLeft.defaultProps = {
  active: false,
  height: '12',
  width: '7',
};

export default ChevronLeft;
