import { isNull, isBlank } from 'utils/helpers';
import { LECTURE_QUIZ, LECTURE_VIDEO, } from 'containers/UploadManagerProvider/helpers';

export const getInitialState = ({
  title,
  lectures,
  description,
  coursePreview,
  coursePhoto,
  language,
  variants,
  recomendationFromAdmin
}) => {
  const lectureVideo = !isBlank(lectures) ? lectures[0].materials.find(material => material.type == LECTURE_VIDEO) : '';
  const lectureQuiz = !isBlank(lectures) ? lectures[0].materials.find(material => material.type == LECTURE_QUIZ) : '';
  const variant = !isBlank(variants) ? variants[0] : '';

  return {
    title,
    type: variant?.name,
    description,
    language: language?.id,
    coursePreview: coursePreview?.filepath,
    coursePhoto: coursePhoto,
    lectureVideo: lectureVideo?.filepath,
    lectureQuiz: lectureQuiz?.filepath,
    price: variant?.price,
    recommendationFromAdmin: recomendationFromAdmin,
  }
};
