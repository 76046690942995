// Core
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import { compose, graphql } from 'react-apollo';
import classNames from 'classnames';
import Hidden from '@material-ui/core/Hidden';

// Components
import MobileSidebarMenu from './MobileSidebarMenu';

// Other
import MY_PROFILE_QUERY from 'queries/UserProfile/getProfile.gql';
import { parseFetchedData } from 'utils/helpers';
import { withSidebar } from 'containers/SidebarProvider/withSidebar';

// Styles
import './MobileSidebar.scss';

class MobileSidebar extends PureComponent {
  render() {
    const {
      anchor,
      container,
      getProfile,
      sidebarContext: { openedSidebar, hideSidebar },
    } = this.props;
    const user = parseFetchedData(getProfile);

    const isOpen = openedSidebar === 'mobile';

    return (
      <Hidden lgUp implementation="js">
        <Drawer
          variant="temporary"
          anchor={anchor}
          open={isOpen}
          onClose={hideSidebar}
          transitionDuration={300}
          ModalProps={{
            keepMounted: true,
            container,
          }}
          classes={{
            root: classNames('mui-override sk-mobile-sidebar', {
              'sk-mobile-sidebar_open': isOpen,
            }),
            paperAnchorRight: 'mui-override sk-mobile-sidebar__paper',
          }}
          BackdropProps={{
            classes: {
              root: 'mui-override sk-mobile-sidebar__backDrop',
            },
          }}
        >
          <MobileSidebarMenu user={user} />
        </Drawer>
      </Hidden>
    );
  }
}

MobileSidebar.defaultProps = {
  anchor: 'right',
};

MobileSidebar.propTypes = {
  sidebarContext: PropTypes.object,
  container: PropTypes.object,
  getProfile: PropTypes.object,
  anchor: PropTypes.string,
};

const myProfileQuery = graphql(MY_PROFILE_QUERY, {
  props: ({ data: { getProfile, error, loading, ...ownProps } }) => ({
    loading,
    getProfile,
    ...ownProps,
  }),
  options: () => ({
    fetchPolicy: 'cache-only',
  }),
});

const enhancer = compose(
  myProfileQuery,
  withSidebar
);

export default enhancer(MobileSidebar);
