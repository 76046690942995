// Core
import React, { memo } from 'react';
import PropTypes from 'prop-types';

// Components
import Text from 'components/Text';

// Styles and Assets
import './CheckboxLabel.scss';

export const CheckboxLabel = ({ children, errorText, error }) => (
  <span className="sk-checkbox-label_text">
    {children}
    <Text classes="sk-checkbox-label_errorText" error size="caption">
      {error && <span> {errorText} </span>}
    </Text>
  </span>
);

CheckboxLabel.propTypes = {
  children: PropTypes.any,
  errorText: PropTypes.string,
  error: PropTypes.bool,
};

export default memo(CheckboxLabel);
