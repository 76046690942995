// Core
import React, { memo, useEffect } from "react";
import classNames from "classnames";
import { compose } from "react-apollo";

// Components
import MobileSidebar from "components/MobileSidebar";
import Loader from "components/Loader";
import Sidebar from "containers/Sidebar";

// Other
import gaTracking from "services/ga";
import HotJarService from "services/hotjar";
import { withSharedState } from "containers/SharedStateProvider/withSharedState";
import { isTrue } from "utils/helpers";
import "services/intercom";

// Styles
import "./MainContent.scss";

const MainContent = ({
  withoutSidebar,
  complexContent,
  withoutBg = false,
  fullWidth,
  children,
  sharedStateContext: { sharedState },
  ...props
}) => {
  // Google Analytics Service
  gaTracking.pageView();

  // HotJar Service
  HotJarService.render();

  useEffect(() => {
    if (sharedState?.isContentLoading) {
      window.scrollTo(0, 0);
    }
  }, [sharedState?.isContentLoading]);

  return (
    <div className="sk-content">
      {!withoutSidebar && (
        <Sidebar container={this} complexContent={complexContent} />
      )}

      <main
        id="sk-content_main"
        className={classNames("sk-content_main", {
          "sk-background-spot__primary": !withoutSidebar && !withoutBg,
        })}
      >
        <div
          className={classNames(
            "sk-content_main__inner",
            "sk-main-layout-wrapper",
            {
              "sk-main-layout-wrapper_wide": fullWidth,
            }
          )}
        >
          {isTrue(sharedState?.isContentLoading) ? (
            <Loader fullscreen={true} center={true} />
          ) : (
            children
          )}
        </div>
      </main>

      <MobileSidebar container={this} />
    </div>
  );
};

const enhancer = compose(memo, withSharedState);

export default enhancer(MainContent);
