import { localStore } from 'services/localStorage';
import GET_COURSE_LIST from 'queries/Courses/studentListCourses.gql';
import { MAPPING_COURSES_LIST_LIMIT } from 'utils/enums';

export const Course = {
  alreadyInShoppingCart: async ({ id }) => {
    const previousState = localStore.getItem('ShoppingCart');

    if (!previousState) return false;

    const shoppingCart = JSON.parse(previousState);

    if (!shoppingCart?.items) return false;

    return shoppingCart.items.some(({ id: existingId }) => existingId === id);
  },
  blockedLectures: async (course, _, { cache }) => {
    const queryData = {
      query: GET_COURSE_LIST,
      variables: {
        limit: MAPPING_COURSES_LIST_LIMIT,
      },
    };

    try {
      const data = await cache.readQuery(queryData);
      const currentCourseHWData = data.studentListCourses.items.find(
        ({ course_id: id }) => id === course.id
      ).homeworks_data;

      const lecturesWithHWDataIdList = currentCourseHWData.map(
        hw => hw.lecture_id
      );

      const lecturesWithHW = course.lectures.filter(lec => lec.homework);
      const lectureWithHWData = lecturesWithHW.filter(lec =>
        lecturesWithHWDataIdList.includes(lec.id)
      );

      const lecturesToBlock = getLecturesToBlock(
        lecturesWithHW,
        lectureWithHWData
      );

      return lecturesToBlock;
    } catch (error) {
      return true;
    }
  },
};

const getLecturesToBlock = (lecturesWithHW, lecturesWithHWData) =>
  lecturesWithHW.reduce((prev, cur) => {
    const lecOrder = cur.order_number;
    let prevLecOrder = cur.order_number - 1;
    if (lecOrder > 0) {
      const orderNumbers = lecturesWithHW
        .map(lec => lec.order_number)
        .filter(num => num < lecOrder);
      prevLecOrder = orderNumbers.pop();
    }

    if (
      lecOrder >= 2 &&
      !lecturesWithHWData.some(lec => lec.order_number === prevLecOrder)
    ) {
      return [...prev, { id: cur.id, __typename: 'BlockedLecture' }];
    }
    return prev;
  }, []);
