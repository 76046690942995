// Core
import React from 'react';

// Components
import Button from 'components/Button';
import Text from 'components/Text';
import Image from 'components/Image';

import { FormattedMessage } from 'react-intl';
import { messages } from 'routes/ExpertsCabinet/CourseEdit/GeneralInfo/ActionPanel/ExpertButtonGroup/DeactivationWarningModal/messages';
import { withFormattedHtmlMessage } from 'containers/HOCs/withFormattedHtmlMessage';

// Styles
import './DeactivationWarningModal.scss';

const DeactivationWarningModal = ({ deactivateCourse, isCourseStatusUpdating = false, formatHtmlMessage }) => (
  <div className="sk-deactivate-waning-modal">
    <Text className="sk-deactivate-waning-modal__head">{formatHtmlMessage(messages.head)}</Text>
    <Text className="sk-deactivate-waning-modal__text">{formatHtmlMessage(messages.yourCourse)}</Text>
    <Image className="sk-deactivate-waning-modal__image" src='/assets/images/idea.svg' />
    <Text className="sk-deactivate-waning-modal__text">{formatHtmlMessage(messages.ifYourAreTeaching)}</Text>
    <Image className="sk-deactivate-waning-modal__image" src='/assets/images/warning.svg' />
    <Text className="sk-deactivate-waning-modal__text">{formatHtmlMessage(messages.ifYouCurrentlyHave)}</Text>
    <Button
      onClick={deactivateCourse}
      color="secondary"
      variant="text"
      size="small"
      loading={isCourseStatusUpdating}
    >
      <FormattedMessage {...messages.deactivateCourse} />
    </Button>
  </div>
);

export default withFormattedHtmlMessage(DeactivationWarningModal);
