// Core
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

// Other
import { messages } from 'routes/ExpertsCabinet/CourseEdit/GeneralInfo/messages';

const Step1 = () => (
  <span>
    <FormattedMessage {...messages.secondApprove} />
  </span>
);

export default memo(Step1);
