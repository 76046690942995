// Core
import React, { PureComponent } from "react";
import { graphql, compose } from "react-apollo";
import { withRouter } from "react-router-dom";
import { withAuthenticatedUser } from "containers/AuthenticatedUserProvider/withAuthenticatedUser";
import { withModal } from "containers/ModalProvider/withModal";

// Styles and Assets
import "./CourseDetails.scss";

// Components
import Loader from "components/Loader";
import CourseDetailsHero from "routes/CourseDetails/CourseDetailsHero";
import Author from "routes/CourseDetails/Author";
import AboutCourse from "routes/CourseDetails/AboutCourse";
import Curriculum from "routes/CourseDetails/Curriculum";
import CoursePreview from "routes/CourseDetails/CoursePreview";
import CourseReview from "routes/CourseDetails/CourseReview";
import BuyCourse from "routes/CourseDetails/BuyCourse";
import Requirements from "routes/CourseDetails/Requirements";
import NotOnSale from "routes/CourseDetails/Error/NotOnSale";
import GainSkills from "routes/CourseDetails/GainSkills";
import SendMessageCourseExpertModal from "containers/SendMessageExpertModal/SendMessageCourseExpertModal";
import Button from "components/Button";

// GraphQL
import GET_PUBLIC_COURSE from "queries/Courses/getPublicCourse.gql";
import GET_STUDENT_COURSE_IDS_LIST from "queries/Courses/studentListCoursesIds.gql";
import LIST_COURSES from "queries/Courses/studentListCourses.gql";

// Others
import { parseFetchedData, isEmptyArray, keysToCamel } from "utils/helpers";
import { MAPPING_COURSES_LIST_LIMIT } from "utils/enums";
import { withAWS } from "containers/AWSProvider/withAWS";

// SEO
import { CourseMarketing } from "seo";
import { isBlank } from "../../utils/helpers";

class CourseDetails extends PureComponent {
  getCourseBouthedStatus = () => {
    const { list, course } = this.props;

    if (isEmptyArray(list?.purchasedCourses) || isBlank(list?.purchasedCourses))
      return null;

    const boughtedCoursesIds = list?.purchasedCourses.map(
      (mapping) => mapping.courseId
    );
    return boughtedCoursesIds.includes(course.id);
  };

  handleSendMessageClick() {
    const {
      course,
      userContext,
      modalContext: { showModal, hideModal },
    } = this.props;
    showModal(SendMessageCourseExpertModal, {
      courseId: course.id,
      email: userContext?.profile?.email,
      handleCompletedClick: hideModal,
    });
  };

  render() {
    const {
      error,
      course,
      loading,
      courseLoading,
      location,
      history,
      userContext
    } = this.props;

    // if (error) throw new Error(error);
    if (loading || courseLoading) return <Loader fullScreen={true} />;
    if (!course || (course && course === null)) return null;

    const parsedCourse = parseFetchedData(course);
    const {
      title,
      category,
      subcategory,
      coursePhoto,
      ratingTotal,
      author,
      description,
      lectures,
      rating,
      coursePreview,
      requirements,
      timeToCompletion,
      statusId,
      displayLectureNameType,
      maxNumberOfUsers,
      skills,
    } = parsedCourse;

    const goToSearch = () => history.replace("/courses");
    if (statusId !== "ACTIVE") return <NotOnSale goToSearch={goToSearch} />;

    const hasReviews = !isEmptyArray(rating);

    return (
      <div className="sk-courseDetails">
        <CourseMarketing course={parsedCourse} location={location} />
        <>
          <CourseDetailsHero
            title={title}
            category={category}
            subcategory={subcategory}
            photo={coursePhoto}
            rating={ratingTotal}
            maxUsers={maxNumberOfUsers}
          />
          <div className="sk-courseDetails-block">
            <div className="sk-courseDetails-block__left" id="scroll">
              <Author author={author} />

              {userContext?.isUserAuthorized && (
                <Button
                  variant="text"
                  color="primary"
                  transparent
                  type="button"
                  onClick={() => this.handleSendMessageClick(course.id)}
                  className="sk-cookie-banner__content__button"
                >
                  Contact Expert
                </Button>
              )}

              {coursePreview && <CoursePreview coursePreview={coursePreview} />}
              <GainSkills skills={skills} />
              <AboutCourse description={description} />
              <Curriculum
                lectures={lectures}
                displayLectureNameType={displayLectureNameType}
              />
              <Requirements requirements={requirements} />
              {hasReviews && <CourseReview rating={rating} />}
            </div>
            <div className="sk-courseDetails-block__right">
              <BuyCourse
                timeToCompletion={timeToCompletion}
                course={parsedCourse}
                boughted={this.getCourseBouthedStatus()}
              />
            </div>
          </div>
        </>
      </div>
    );
  }
}

const getPublicCourseQuery = graphql(GET_PUBLIC_COURSE, {
  props: ({ data: { course, list, error, loading, ...ownProps } }) => ({
    error,
    course,
    courseLoading: loading,
    list,
    ...ownProps,
  }),
  options: ({
    match: {
      params: { id, slug },
    },
  }) => ({
    fetchPolicy: "cache-and-network",
    variables: {
      input: { id, slug },
      limit: MAPPING_COURSES_LIST_LIMIT,
    },
  }),
});

const studentListCoursesQuery = graphql(LIST_COURSES, {
  skip: ({ userContext }) => !userContext?.isUserAuthorized,
  props: ({
    data: {
      listStudentCoursesExtra,
      purchased_courses,
      variables,
      refetch,
      loading,
      error,
      ...ownProps
    },
  }) => ({
    list: keysToCamel(listStudentCoursesExtra),
    query: variables?.input?.query || {},
    refetchCourses: refetch,
    ...ownProps,
  }),
  options: () => ({
    variables: {
      input: { query: { filter: [{ key: "type", values: ["course"] }] } },
    },
  }),
});

const enhancer = compose(
  withRouter,
  withAuthenticatedUser,
  withModal,
  getPublicCourseQuery,
  studentListCoursesQuery
);

export default enhancer(CourseDetails);
