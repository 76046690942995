import React from 'react';
import { SidebarDefault, PurpleHeart } from 'components/Icon/color';

const getColor = (active, activeColor) =>
  active ? activeColor : SidebarDefault;

const ChevronDown = ({
  height = 7,
  width = 12,
  active = false,
  iconStyle,
  color,
  activeColor = PurpleHeart,
  strokeWidth = 1.5,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    // Fix icons in student sidebar / active courses
    viewBox="0 0 11.5 8"
    // viewBox={`0 0 ${width} ${height}`}
    width={width}
    height={height}
    style={iconStyle}
  >
    <path
      fill="none"
      fillRule="nonzero"
      stroke={color || getColor(active, activeColor)}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m0.736842,1.280702l5.05,4.812l5.047,-4.812"
    />
  </svg>
);

export default ChevronDown;
