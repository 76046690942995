// Core
import React, { PureComponent, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { compose, graphql } from 'react-apollo';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

// Components
import TextInput from 'components/Input/Material/TextInput/TextInput';
import Text from 'components/Text';
import Pagination from 'components/Pagination';
import ContentHeader from 'containers/ContentHeader';
import CoursesTable from 'routes/AdminPanel/DeletedCoursesList/DeletedCoursesTable';
import Loader from 'components/Loader';

// GraphQl
import LIST_DELETED_COURSES from 'queries/AdminPanel/adminListDeletedCourses.gql';

// Other
// import { hasLoader } from 'containers/HOCs/hasLoader';
import { Grey3 } from 'components/Icon/color';
import { messages } from 'routes/Courses/Search/messages';
import { isEmptyObject, isArraysEqual, isNull } from  'utils/helpers/index';
// Styles and Assets
import './DeletedCoursesList.scss';

const COURSES_PER_PAGE_COUNT = 7;

let PAGED=1;
const PAGED_ITEMS=[];

const defaultCoursesList = [
  {
    id: 'reason',
    title: 'Reason',
    category: {
      id: 'blockchain',
      title: 'blockchain'
    },
    subcategory: {
      id: 'blockchain',
      title: 'blockchain'
    },
    author: {
      first_name: 'Andre',
      last_name: 'Andre',
    }
  }
]

class DeletedCoursesList extends PureComponent {
  state = {
    paged: 1
  };
  nextPage = async () => {
    const {refetch, nextToken} = PAGED_ITEMS[PAGED];
    PAGED++;

    this.setState({paged: PAGED});
    if (!PAGED_ITEMS[PAGED]) {
      await refetch({ input: { limit: COURSES_PER_PAGE_COUNT, nextToken }});
    }
  };

  backPage = async () => {
    PAGED = PAGED-1;
    this.setState({paged: PAGED});
  };

  componentWillUnmount() {
    PAGED=1;
  }

  render() {
    const { error, loading, total, skip, limit, } = this.props;
    const { paged } = this.state;

    if (loading || !PAGED_ITEMS[paged]) return (<Loader center />)

    const currentPage = PAGED-1;
    const coursesPage = PAGED_ITEMS[paged];
    const { courses } = coursesPage;
    return (
      <Fragment>
        <div className="sk-adminUsersListPanel__contentHeader">
          <ContentHeader />
        </div>

        <CoursesTable
          error={error}
          loading={loading}
          courses={courses}
          currentPage={currentPage}
        />

        {COURSES_PER_PAGE_COUNT >= courses.length && (
          <div className="sk-coursesList__pagination">
            <Pagination
              page={currentPage}
              count={total}
              handleChangePage={(e, selectedPage) => {
                if (currentPage > selectedPage) this.backPage()
                else if (currentPage < selectedPage) this.nextPage()
              }}
              rowsPerPage={COURSES_PER_PAGE_COUNT}
            />
          </div>
        )}
      </Fragment>
    );
  }
}

const countDeletedCourses = graphql(LIST_DELETED_COURSES, {
  // skip: ({total = 0}) => (total > 0),
  props: ({ data: { error, loading, adminListDeletedCourses, refetch, ...ownProps } }) => {

    if (error) throw Error('Something went wrong, while receiving courses count');
    if (loading || isNull(adminListDeletedCourses)) return { loading, courses: defaultCoursesList, };

    const { total } = adminListDeletedCourses;
    return {
      error,
      loading,
      total,
      ...ownProps,
    }
  },
  options: () => ({
    fetchPolicy: 'cache-and-network',
    variables: {
      input: { skip: 0, limit: 999 },
    },
  }),
});

const listDeletedCourses = graphql(LIST_DELETED_COURSES, {
  props: ({ data: { error, loading, adminListDeletedCourses, refetch, ...ownProps } }) => {

    if (error) throw Error('Something went wrong, while receiving courses');
    if (loading || isNull(adminListDeletedCourses)) return { loading, courses: defaultCoursesList, };

    const { items: courses, nextToken, total, skip, limit } = adminListDeletedCourses;

    PAGED_ITEMS[PAGED] = {
      courses,
      nextToken,
      total,
      refetch,
    };

    return {
      error,
      loading,
      courses: PAGED_ITEMS[PAGED].courses,
      nextToken,
      skip,
      limit,
      refetchCourses: refetch,
      ...ownProps,
    }
  },
  options: () => ({
    fetchPolicy: 'network-only',
    variables: {
      input: { skip: 0, limit: COURSES_PER_PAGE_COUNT },
    },
  }),
});

const enhancer = compose(
  withRouter,
  countDeletedCourses,
  listDeletedCourses
);

export default enhancer(DeletedCoursesList);
