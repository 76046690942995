import React, { useState, useEffect, useRef } from 'react';
import { Editor, EditorState, RichUtils } from 'draft-js';
import classnames from 'classnames';

// Components
import {
  StyleButton,
  StyleIconButton,
} from 'components/DraftEditor/ControlsBar/Buttons';

// Icons
import AlignLeft from 'components/Icon/SvgCss/Editor/AlignLeft';
import AlignCenter from 'components/Icon/SvgCss/Editor/AlignCenter';
import AlignRight from 'components/Icon/SvgCss/Editor/AlignRight';
import AlignJustify from 'components/Icon/SvgCss/Editor/AlignJustify';

// Styles
import './AlignControls.scss';

const INLINE_STYLES = [
  { label: 'AlingLeft', style: 'left', icon: AlignLeft },
  { label: 'AlingCenter', style: 'center', icon: AlignCenter },
  { label: 'AlingRight', style: 'right', icon: AlignRight },
  { label: 'AlingJustify', style: 'justify', icon: AlignJustify },
];

const AlignControls = ({ editorState, setAlign }) => {
  const onAlignClick = align => () => {
    setAlign(align);
  };

  const currentStyle = editorState.getCurrentInlineStyle();

  return (
    <div className="sk-editor-toolbar-controls-inline">
      {INLINE_STYLES.map(type => (
        <StyleIconButton
          icon={type.icon}
          key={type.label}
          active={currentStyle.has(type.style)}
          onToggleHandler={onAlignClick(type.style)}
          style={type.style}
        />
      ))}
    </div>
  );
};

export default AlignControls;

// // Core
// import React, { useState } from 'react';
//
// // Components
// import SimpleSelect from 'components/Select/SimpleSelect';
//
// // Icons
// import AlignLeft from 'components/Icon/SvgCss/Editor/AlignLeft';
// import AlignCenter from 'components/Icon/SvgCss/Editor/AlignCenter';
// import AlignRight from 'components/Icon/SvgCss/Editor/AlignRight';
// import AlignJustify from 'components/Icon/SvgCss/Editor/AlignJustify';
//
// import './AlignControls.scss';
//
// let currentOption = 'left';
// const options = [
//   { label: 'AlingLeft', style: 'left', icon: AlignLeft },
//   { label: 'AlingCenter', style: 'center', icon: AlignCenter },
//   { label: 'AlingRight', style: 'right', icon: AlignRight },
//   { label: 'AlingJustify', style: 'justify', icon: AlignJustify },
//   // {label: 'AlingLeft', style: 'left', icon: () => <AlignLeft /> },
//   // {label: 'AlingCenter', style: 'center', icon: () => <AlignCenter /> },
//   // {label: 'AlingRight', style: 'right', icon: () => <AlignRight /> },
//   // {label: 'AlingJustify', style: 'justify', icon: () => <AlignJustify /> },
// ];
//
// const AlignControls = ({
//   error, setAlign, ...props
// }) => {
//   const [fontSizeOption, setFontSizeOption] = useState(currentOption);
//
//   const handleChange = chosenValue => {
//     currentOption = chosenValue;
//     setFontSizeOption(chosenValue);
//     setAlign(chosenValue);
//   };
//
//   return (
//     <div className='sk-editor-controls-bar-align-select'>
//       <SimpleSelect
//         options={options}
//         error={error}
//         valueKey="style"
//         labelKey="icon"
//         value={fontSizeOption}
//         handleChange={handleChange}
//         {...props}
//       />
//     </div>
//   );
// };
//
// export default AlignControls;
