// Core
import React, { useState, useEffect, useCallback, useContext } from 'react';

// Components

// Other
import { CurrencyContext } from './currency-context';
import { addSpacesBetweenEveryGroupOfThreeNumbers } from 'utils/helpers/index';

export function useConvertedPrice({ disabled = false, price, isBeautyPrice = false }) {
  const { currencyContext } = useContext(CurrencyContext);
  const { changeDependOnCurrentCurrencyRate, getCurrentCurrencySign, } = currencyContext;

  const priceDependOnRate = (!disabled && (price || price === 0) && changeDependOnCurrentCurrencyRate(price)) || '-';
  const signDependOnCurrentCurrency = getCurrentCurrencySign();
  const beautyPrice = isBeautyPrice ? addSpacesBetweenEveryGroupOfThreeNumbers(priceDependOnRate) : priceDependOnRate;

  const parsedPrice = `${signDependOnCurrentCurrency} ${beautyPrice}`;

  return parsedPrice;
};
