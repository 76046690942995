import React from 'react';

const Icon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.22507 4.5C6.85489 4.5 6.5 4.83276 6.5 5.29991V17.6998C6.5 18.1671 6.85503 18.5 7.22507 18.5H13.9299L16.3121 18.414L16.3123 18.414C16.8895 18.3934 17.3975 17.8928 17.4197 17.2116L17.4197 17.2115L17.5 14.7283V6.98387V6.12931V5.29991C17.5 4.83276 17.1451 4.5 16.7749 4.5H7.22507ZM18.5 6.12931V5.29991C18.5 4.33724 17.7525 3.5 16.7749 3.5H7.22507C6.24751 3.5 5.5 4.33724 5.5 5.29991V17.6998C5.5 18.6623 6.24737 19.5 7.22507 19.5H13.9389C13.9449 19.5 13.951 19.4999 13.957 19.4997L16.348 19.4134L16.3482 19.4134C17.501 19.3721 18.3816 18.3989 18.4192 17.244L18.4192 17.2439L18.4997 14.7525L18.5 14.7363V6.98387V6.12931ZM7.98462 7.47266C7.98462 7.19651 8.20848 6.97266 8.48462 6.97266H15.6846C15.9608 6.97266 16.1846 7.19651 16.1846 7.47266C16.1846 7.7488 15.9608 7.97266 15.6846 7.97266H8.48462C8.20848 7.97266 7.98462 7.7488 7.98462 7.47266ZM8.48462 9.40496C8.20848 9.40496 7.98462 9.62882 7.98462 9.90496C7.98462 10.1811 8.20848 10.405 8.48462 10.405H15.6846C15.9608 10.405 16.1846 10.1811 16.1846 9.90496C16.1846 9.62882 15.9608 9.40496 15.6846 9.40496H8.48462ZM7.98462 12.6833C7.98462 12.4071 8.20848 12.1833 8.48462 12.1833H15.6846C15.9608 12.1833 16.1846 12.4071 16.1846 12.6833C16.1846 12.9594 15.9608 13.1833 15.6846 13.1833H8.48462C8.20848 13.1833 7.98462 12.9594 7.98462 12.6833ZM8.48462 14.9626C8.20848 14.9626 7.98462 15.1864 7.98462 15.4626C7.98462 15.7387 8.20848 15.9626 8.48462 15.9626H15.6846C15.9608 15.9626 16.1846 15.7387 16.1846 15.4626C16.1846 15.1864 15.9608 14.9626 15.6846 14.9626H8.48462Z" fill="#6D7C90"/>
    </svg>
  );
};

export default Icon;
