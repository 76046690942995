// Core
import React from 'react';
import PropTypes from 'prop-types';

// Components
import FilterDropDown from 'components/FilterDropDown';

// Styles and Assets
import './FilterGroup.scss';

const FilterGroup = ({ filterData, handleChange, labelKey, valueKey }) => (
  <div className="sk-filter-group">
    {filterData.map(({ name, value, options, disabled }) => (
      <div className="sk-filter-group_item" key={name}>
        <FilterDropDown
          options={options}
          value={value}
          disabled={disabled}
          handleChange={handleChange(name)}
          valueKey={valueKey}
          labelKey={labelKey}
          isSubcategoriesFilter={name === 'subcategory'}
        />
      </div>
    ))}
  </div>
);

FilterGroup.propTypes = {
  filterData: PropTypes.array.isRequired,
  handleChange: PropTypes.func,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
};

export default FilterGroup;
