import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  buyNow: {
    id: 'app.components.CourseActionBlock.buyNow',
    defaultMessage: 'Buy Now',
  },
  learnMore: {
    id: 'app.components.CourseActionBlock.learnMore',
    defaultMessage: 'Learn More',
  },
  youHaveAlreadyBought: {
    id: 'app.components.CourseActionBlock.youHaveAlreadyBought',
    defaultMessage: 'You have already bought this course',
  },
});
