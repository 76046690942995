import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  congrats: {
    id: 'app.components.AdditionalCourseDetails.congrats',
    defaultMessage: 'Congratulations!',
  },
  yourCourseActivated: {
    id: 'app.components.AdditionalCourseDetails.yourCourseActivated',
    defaultMessage: 'Your course is active',
  },
  thankYou: {
    id: 'app.components.AdditionalCourseDetails.thankYou',
    defaultMessage: 'Thank you!',
  },
  yourSubmittedInfo: {
    id: 'app.components.AdditionalCourseDetails.yourSubmittedInfo',
    defaultMessage: 'Your submission is being reviewed. We will get back to you within 2 working days. Until then your material is saved as a draft.',
  },
  gotIt: {
    id: 'app.components.AdditionalCourseDetails.gotIt',
    defaultMessage: 'Got it',
  },
});
