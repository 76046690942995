// Core
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { compose } from 'react-apollo';
import cx from 'classnames';
import ReactPlayer from 'react-player';

// Components
import DefaultSliderButton from 'components/NetflixCarousel/modules/SliderButton';
import FreeIcon from './FreeIcon'
import Price from './Price'
import StarRating from 'components/StarRating';
import ActionBlock from './ActionBlock';
import VideoModule from 'components/VideoModule';
import Image from 'components/Image';
import Star from 'components/Icon/Svg/Star';
import Text from 'components/Text'
// Other
import withWidth from 'containers/HOCs/withWidth';
import { withConvertedPrice } from 'containers/CurrencyProvider/withConvertedPrice';
import { getCourseThumbnail } from 'utils/helpers';
import {
  Volume,
  Muted,
} from 'components/Icon/SvgCss/VideoControllers';
import {PurpleDark} from 'components/Icon/color.js';

// HOOKs
import useEventListener from 'components/NetflixCarousel/useEventListener';

// Styles
import './index.scss';

const Item = ({
  elementRef,
  item,
  isActive,
  onSelectSlide,
  onCloseSlide,
  width,
  symbol,
  price,
  contentOpen,
}) => {
  const [isHover, setIsHover] = useState(false);
  const [isMuted, setMuted] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  const playerEl = useRef(null);

  const variantName = item.variants[0].name;
  const variantPrice = item.variants[0].price;
  const preview = item.coursePreview || {};

  const muteVideoHandler = () => setMuted(!isMuted);

  const handleSlide = () => {
    isActive ? onCloseSlide(item) : onSelectSlide(item);
  };

  const setHover = useCallback((isHovered) => () => {
    setIsHover(isHovered);
  }, [setIsHover]);

   useEffect(()=>{
     if(width === 'xs' || width === 'sm'){
       setIsMobile(true)
     }
   }, [setIsMobile, width])

   const boughted = item.userCourseId ? true : false;

  return(
    <div
      ref={elementRef}
      className={cx('slider-item', 'item', { 'item--open': isActive, })}
      onMouseEnter={setHover(true)}
      onMouseLeave={setHover(false)}
    >
      <div className={cx('picture', {'picture_open': contentOpen})}>
        <Image
          className={cx("photo", {'blur': (isHover && !isActive && !isMobile)})}
          src={getCourseThumbnail(item.thumbnail, item.coursePhoto)}
          alt=""
        />
        {(isHover && !isActive && !isMobile) && (
          <div className="sk-video-module">
            <ReactPlayer
              ref={playerEl}
              playing
              url={preview.url}
              width="100%"
              height="100%"
              controls={false}
              loop
              muted={isMuted}
              className="sk-video-module_video"
            />
            <div className="sk-video-module_video-muted" onClick={()=>muteVideoHandler()}>
              {isMuted ? <Muted color={PurpleDark} /> : <Volume color={PurpleDark} />}
            </div>
          </div>
        )}
        <div className="picture-overlay" onClick={handleSlide}>
          <div className="gift-label">
            {!variantPrice && (<FreeIcon />)}
          </div>
          <div className="description-block">
            <p className="title">{item.title}</p>
            <div className="col">
              <div className="rating">
                <Star active={false} width={13} height={13} />
                <Text>{item.ratingTotal?.averageScore} ({item.ratingTotal?.totalVotes})</Text>
              </div>
              <div className="price-block">
                <img src="/assets/images/price-icon.svg" alt="Price icon"/>
                {/* <span>{price}</span> */}
               <Price value={price}/>
              </div>
            </div>
          </div>
        </div>
        {contentOpen && (<span className="open-dropdown" onClick={handleSlide}>&#65088;</span>)}
      </div>
      <ActionBlock
        isActive={isActive}
        isOpen={contentOpen}
        topping={item}
        boughted={boughted}
        handleClick={handleSlide}
      />
      {isActive && (<div className="mark" />)}
    </div>
  );
}

const enhancer = compose(
  withConvertedPrice,
  withWidth()
);

export default enhancer(Item);
