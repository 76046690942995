// Core
import React, { memo, useMemo } from 'react';
import { injectIntl } from 'react-intl';
import { compose } from 'react-apollo';

// Styles and Assets
import './index.scss';

// Components
import CourseDetail from 'routes/CourseDetails/BuyCourse/CourseDetail';

// Other
import { FEATURES } from 'routes/CourseDetails/BuyCourse/CourseFeatures/enums.js';
import { sharedMessages } from 'services/i18n/sharedMessages/messages.js';
import { messages } from 'routes/CourseDetails/BuyCourse/messages.js';
import { isBlank, isEmptyArray } from 'utils/helpers';

const FEATURES_CONTROLLER = {
  flex: [FEATURES.videoLecturesFeature, FEATURES.studyMaterialsFeature],
  assist: [
    FEATURES.videoLecturesFeature,
    FEATURES.studyMaterialsFeature,
    FEATURES.certificatesFeature,
    FEATURES.webinarsFeature,
    FEATURES.homeworksFeature,
  ],
  concierge: [
    FEATURES.videoLecturesFeature,
    FEATURES.studyMaterialsFeature,
    FEATURES.certificatesFeature,
    FEATURES.webinarsFeature,
    FEATURES.homeworksFeature,
  ],
};

const CourseFeatures = ({
  selectedVariant,
  quizAndChatData,
  intl: { formatMessage },
  ...props
}) => {
  const variantName = selectedVariant?.name || 'flex';
  const features = [
    ...FEATURES_CONTROLLER[variantName],
    quizAndChatData.chat ? FEATURES.chatsFeature : { valid: false },
    quizAndChatData.quiz ? FEATURES.interectiveTestsFeature : { valid: false },
  ];

  return (
    <div className="sk-buy-course-course-features">
      {features
        .filter(({ id, dependOnSettings, valid }) => {
          let isValid = true;
          if (valid === false) {
            return false;
          }
          const isAssistSelected = selectedVariant?.name == 'assist';
          if (!isBlank(dependOnSettings) && isAssistSelected) {
            isValid = selectedVariant.features?.includes(id);
          }
          return isValid;
        })
        .map(({ icon, translationMessageId }) => {
          const translationMessage = messages[translationMessageId];
          return (
            <CourseDetail
              key={translationMessageId}
              icon={icon}
              text={formatMessage(messages[translationMessageId])}
            />
          );
        })}
    </div>
  );
};

const enhancer = compose(memo, injectIntl);

export default enhancer(CourseFeatures);
