// Core
import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';

// Components
import SendMessageForm from 'containers/SendMessageExpertModal/SendMessageForm';
import SendMessageSuccess from 'containers/SendMessageExpertModal/SendMessageSuccess';

// GraphQL
import NOTIFY_COURSE_EXPERT from 'mutations/Mailing/notifyCourseExpert.gql';
import GET_COURSE_AUTHOR from 'queries/LocalState/getCourseAuthor.gql';
import MY_PROFILE_QUERY from 'queries/UserProfile/getProfile.gql';

// Other
import { hasLoader } from 'containers/HOCs/hasLoader';

// Styles and Assets
import './SendMessageExpertModal.scss';

const SendMessageExpertModal = ({
  notifyExpert,
  courseId,
  email,
  handleCompletedClick,
  title,
}) => {
  const [success, setSuccess] = useState(false);

  const handleFormSubmit = async ({ message }) => {
    try {
      await notifyExpert({ message, course_id: courseId, email });
      setSuccess(true);
    } catch (error) {
      throw Error(error);
    }
  };
  return (
    <div className="sk-send-msg-modal">
      {success ? (
        <SendMessageSuccess handleContinueClick={handleCompletedClick} />
      ) : (
        <SendMessageForm
          initialFormFields={{ message: '' }}
          handleFormSubmit={handleFormSubmit}
          courseTitle={title}
        />
      )}
    </div>
  );
};

const notifyExpertMutation = graphql(NOTIFY_COURSE_EXPERT, {
  props: ({ mutate }) => ({
    notifyExpert: input => mutate({ variables: { input } }),
  }),
});

const getCourseAuthorEmailQuery = graphql(GET_COURSE_AUTHOR, {
  props: ({ data: { getCourseAuthor, loading, error, ...ownProps } }) => ({
    destination: getCourseAuthor?.email,
    title: getCourseAuthor?.title,
    error,
    loading,
    ...ownProps,
  }),
  options: ({ courseId }) => ({
    variables: { id: courseId },
    fetchPolicy: 'cache-first',
  }),
});

const myProfileQuery = graphql(MY_PROFILE_QUERY, {
  props: ({ data: { getProfile, error, loading, ...ownProps } }) => ({
    email: getProfile?.email,
    error,
    ...ownProps,
  }),
  options: () => ({
    fetchPolicy: 'cache-only',
  }),
});

SendMessageExpertModal.propTypes = {
  email: PropTypes.string,
  handleCompletedClick: PropTypes.func,
  notifyExpert: PropTypes.func,
//   destination: PropTypes.string,
  courseId: PropTypes.string,
  title: PropTypes.string,
};

const enhacner = compose(
  memo,
  getCourseAuthorEmailQuery,
  myProfileQuery,
  notifyExpertMutation
);

export default hasLoader(enhacner(SendMessageExpertModal), GET_COURSE_AUTHOR);
