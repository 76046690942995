// Core
import React from 'react';
import { FormattedMessage } from 'react-intl';

// Components
import Heading from 'components/Heading';
import Text from 'components/Text';
import StarRating from 'components/StarRating';
import ReadMore from 'components/ReadMore';
import Author from './Author';
import Attachments from 'routes/StudentsCabinet/ToppingDetails/Attachments';

// Others
import { isBlank } from 'utils/helpers';
import { messages } from 'routes/StudentsCabinet/ToppingDetails/messages.js';

//Styles
import './ToppingDescription.scss';

const COMPONENT_PREFIX = `sk-topping-details_description`;

const ToppingDescription = ({
  title,
  author,
  ratingTotal,
  description,
  quiz,
  button,
}) => {
  return(
    <div className={`${COMPONENT_PREFIX}`}>
      <div className={`${COMPONENT_PREFIX}__top`}>
        <Heading variant='h3'>{title}</Heading>
        {button}
      </div>
      <div className={`${COMPONENT_PREFIX}__star-raiting`}>
        <StarRating rate={ratingTotal?.averageScore} />
        <span className={`${COMPONENT_PREFIX}__star-raiting_title`}>
          {ratingTotal?.averageScore} / {ratingTotal?.totalVotes} <FormattedMessage {...messages.students} />
        </span>
      </div>
      <div className={`${COMPONENT_PREFIX}__bottom`}>
        <div className={`${COMPONENT_PREFIX}__bottom_col`}>
          <div className='text'>
            <ReadMore lines={5} withToggle={true}>
              {description}
            </ReadMore>
          </div>
          {!isBlank(quiz) && (<Attachments attachment={quiz}/>)}
        </div>
        {author && (
          <div className={`${COMPONENT_PREFIX}__bottom_col`}>
            <Author {...author} />
          </div>
        )}
      </div>
    </div>
  )
};

export default ToppingDescription;
