// Core
import React, { PureComponent } from 'react';

// Components
import TextInput from 'components/Input/Material/TextInput/TextInput';
import TextAreaInput from 'components/Input/Material/TextAreaInput/TextAreaInput';

// Styles and Assets
import './DiscountInfo.scss';

const DiscountInfo = (props) => {
  const { discount, touched, errors, handleInputBlur, handleInputChange } = props;

  return (
    <div className='sk-admin-discount-info'>
      <div className='sk-admin-discount-info__input'>
        <TextInput
          helperText={touched.name ? errors.name : ''}
          error={touched.name && Boolean(errors.name)}
          placeholder="Promo code name"
          value={discount.name}
          name="name"
          label="Promo code name"
          onBlur={handleInputBlur('name')}
          onChange={handleInputChange('name')}
        />
      </div>
      <div className='sk-admin-discount-info__input'>
        <TextInput
          helperText={touched.code ? errors.code : ''}
          error={touched.code && Boolean(errors.code)}
          placeholder="Promo Code "
          value={discount.code}
          name="code"
          label="Promo Code"
          onBlur={handleInputBlur('code')}
          onChange={handleInputChange('code')}
        />
      </div>
    </div>
  )
}

export default DiscountInfo;
