import React, { memo } from 'react';
import { compose } from 'react-apollo';
import { injectIntl } from 'react-intl';

// Components
import { IconText } from 'routes/Checkout/CheckoutLists/Subscriptions';
import Text from 'components/Text';
import Card from 'components/Card';

// Icons
import VideoLectures from 'routes/ExpertsCabinet/ExpertsFee/PlansFeatures/icons/VideoLectures.js';
import Homeworks from 'routes/ExpertsCabinet/ExpertsFee/PlansFeatures/icons/Homeworks.js';
import EducationalMaterials from 'routes/ExpertsCabinet/ExpertsFee/PlansFeatures/icons/EducationalMaterials.js';
import Chats from 'routes/ExpertsCabinet/ExpertsFee/PlansFeatures/icons/Chats.js';
import Tests from 'routes/ExpertsCabinet/ExpertsFee/PlansFeatures/icons/Tests.js';
import Toppings from 'routes/ExpertsCabinet/ExpertsFee/PlansFeatures/icons/Toppings.js';
import Podcasts from 'routes/ExpertsCabinet/ExpertsFee/PlansFeatures/icons/Podcasts.js';
import Masterclasses from 'routes/ExpertsCabinet/ExpertsFee/PlansFeatures/icons/Masterclasses.js';
import Webinars from 'routes/ExpertsCabinet/ExpertsFee/PlansFeatures/icons/Webinars.js';
import Analytics from 'routes/ExpertsCabinet/ExpertsFee/PlansFeatures/icons/Analytics.js';
import Support from 'routes/ExpertsCabinet/ExpertsFee/PlansFeatures/icons/Support.js';
import FreeUpdate from 'routes/ExpertsCabinet/ExpertsFee/PlansFeatures/icons/FreeUpdate.js';
import Traffic from 'routes/ExpertsCabinet/ExpertsFee/PlansFeatures/icons/Traffic.js';
import NoAdmin from 'routes/ExpertsCabinet/ExpertsFee/PlansFeatures/icons/NoAdmin.js';
import Crm from 'routes/ExpertsCabinet/ExpertsFee/PlansFeatures/icons/Crm.js';


// Other
import { messages } from 'routes/ExpertsCabinet/ExpertsFee/messages.js';

//Styles
import './index.scss';

const PREFIX = 'sk-plans-features-group';
const FeaturesList = ({ children, title, ...props }) => {
  return (
    <div className={`${PREFIX}`}>
      <Text className={`${PREFIX}__header`}>{title}</Text>
      <div className={`${PREFIX}__features`}>{children}</div>
    </div>
  )
};

const PREFIX_LIST = 'sk-plans-features';
const PlansFeatures = ({ intl: { formatMessage }, ...props }) => {
  return (
    <div className={PREFIX_LIST}>
      <Text>{formatMessage(messages.functionalityTitle)}</Text>
      <Card>
        <FeaturesList title={formatMessage(messages.creationBlockTitle)}>
          <IconText className={`${PREFIX}__features__feature`} icon={VideoLectures}>{formatMessage(messages.videoLectures)}</IconText>
          <IconText className={`${PREFIX}__features__feature`} icon={Homeworks}>{formatMessage(messages.homeworks)}</IconText>
          <IconText className={`${PREFIX}__features__feature`} icon={EducationalMaterials}>{formatMessage(messages.educationalMaterials)}</IconText>
          <IconText className={`${PREFIX}__features__feature`} icon={Chats}>{formatMessage(messages.chats)}</IconText>
          <IconText className={`${PREFIX}__features__feature`} icon={Tests}>{formatMessage(messages.quizes)}</IconText>
          <IconText className={`${PREFIX}__features__feature`} icon={Webinars}>{formatMessage(messages.webinars)}</IconText>
          <IconText className={`${PREFIX}__features__feature`} icon={Masterclasses}>{formatMessage(messages.masterclasses)}</IconText>
          <IconText className={`${PREFIX}__features__feature`} icon={Podcasts}>{formatMessage(messages.podcasts)}</IconText>
        </FeaturesList>

        <FeaturesList title={formatMessage(messages.usageBlockTitle)}>
          <IconText className={`${PREFIX}__features__feature`} icon={Analytics}>{formatMessage(messages.analytics)}</IconText>
          <IconText className={`${PREFIX}__features__feature`} icon={Traffic}>{formatMessage(messages.traffic)}</IconText>
          <IconText className={`${PREFIX}__features__feature`} icon={FreeUpdate}>{formatMessage(messages.freelifetimeUpdate)}</IconText>
          <IconText className={`${PREFIX}__features__feature`} icon={Crm}>{formatMessage(messages.crm)}</IconText>
          <IconText className={`${PREFIX}__features__feature`} icon={NoAdmin}>{formatMessage(messages.noAdministration)}</IconText>
          <IconText className={`${PREFIX}__features__feature`} icon={Support}>{formatMessage(messages.teckSupport)}</IconText>
        </FeaturesList>
      </Card>
    </div>
  );
};

const enhancer = compose(
  memo,
  injectIntl
);

export default enhancer(PlansFeatures);
