// Core
import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import Card from 'components/Card';
import Loader from 'components/Loader';

// Components
import Text from 'components/Text';
import DiscountsTableRow from 'routes/AdminPanel/Discounts/DiscountsList/DiscountsTable/DiscountsTableRow/';

// Other
import { Grey4, Black } from 'components/Icon/color';

// Styles and Assets
import './DiscountsTable.scss';

const classes = {
  table: {
    root: 'mui-override sk-admin-discounts-tablee',
  },
};
// const ACSENDING = 'asc';
// const NEXT_SORT_ORDER = {
//   'asc': 'desc',
//   'desc': 'asc'
// }
// const SORT_TYPES = {
//   'title': 'title',
//   'category': 'category',
//   'subcategory': 'subcategory',
//   'status': 'status',
//   'author': 'author',
// }

class DiscountsTable extends PureComponent {
  state = {
    anchorEl: null,
  }

  renderTableBody = discounts => {
    return discounts.map((item, index, arr) => (
      <Fragment key={item.id}>
        <DiscountsTableRow key={item.id} item={item} index={index} arr={arr} />
      </Fragment>
    ));
  };

  // handleSort = type => async () => {
  //   const { handleSort, sortBy, sortOrder, } = this.props;
  //
  //   const newSortByFieldName = type;
  //   const newSortOrder = sortBy === type && NEXT_SORT_ORDER[sortOrder] ? NEXT_SORT_ORDER[sortOrder] : ACSENDING;
  //
  //   await handleSort({ sortBy: newSortByFieldName, sortOrder: newSortOrder });
  // }
  //
  // handleSortByTitle = this.handleSort(SORT_TYPES['title'])
  // handleSortByCategory = this.handleSort(SORT_TYPES['category'])
  // handleSortBySubcategory = this.handleSort(SORT_TYPES['subcategory'])
  // handleSortByStatus = this.handleSort(SORT_TYPES['status'])
  // handleSortByAuthor = this.handleSort(SORT_TYPES['author'])


  render() {
    const { discounts, sortBy, sortOrder, filters, loading } = this.props;
    const { anchorEl } = this.state;

    const headerHeight = 63;
    const rowHeight = 63;
    const rowsCount = +discounts?.length || 0;

    // const isSortOrderAscending = sortOrder === ACSENDING;

    // const isSortedByTitle = sortBy === SORT_TYPES['title'];
    // const isSortedByCategory = sortBy === SORT_TYPES['category'];
    // const isSortedBySubcategory = sortBy === SORT_TYPES['subcategory'];
    // const isSortedByStatus = sortBy === SORT_TYPES['status'];
    // const isSortedByAuthor = sortBy === SORT_TYPES['author'];

    if (loading) return (<Loader center />);

    return (
      <Card>
        <Table
          className="mui-override sk-admin-discounts-tablee"
          classes={classes.table}
          style={{ height: headerHeight + (rowHeight*rowsCount) }}
        >
          <TableHead>
            <TableRow>
              <TableCell
                classes={classes.table}
                className="mui-override sk-admin-discounts-tablee__cell"
              >
                <div className='mui-override sk-admin-discounts-tablee__cell__elements' style={{ justifyContent: 'center' }}>
                  <Text bold align="center">
                    STATUS
                  </Text>
                </div>
              </TableCell>
              <TableCell
                classes={classes.table}
                className="mui-override sk-admin-discounts-tablee__cell"
              >
                <div className='mui-override sk-admin-discounts-tablee__cell__elements'>
                  <Text bold align="left">
                    NAME
                  </Text>
                </div>
              </TableCell>
              <TableCell
                classes={classes.table}
                className="mui-override sk-admin-discounts-tablee__cell"
              >
                <div className='mui-override sk-admin-discounts-tablee__cell__elements'>
                  <Text bold align="left">
                    PROMO CODE
                  </Text>
                </div>
              </TableCell>
              <TableCell
                classes={classes.table}
                className="mui-override sk-admin-discounts-tablee__cell"
              >
                <div className='mui-override sk-admin-discounts-tablee__cell__elements' />
              </TableCell>
            </TableRow>
          </TableHead>
          {loading ? (
              <Loader center />
            ) : (
              <TableBody>
                <Fragment>
                  {discounts && discounts.length > 0 ? this.renderTableBody(discounts) : null}
                </Fragment>
              </TableBody>
          )}
        </Table>
      </Card>
    );
  }
}

DiscountsTable.propTypes = {
  discounts: PropTypes.array,
};

export default DiscountsTable;
