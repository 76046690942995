// Core
import React, { memo } from 'react';
import { injectIntl,  } from 'react-intl';
import { compose } from 'react-apollo';
import moment from 'moment';

// Components
import Text from 'components/Text';

// Other
import { messages } from 'routes/AdminPanel/messages';
import { isBlank } from 'utils/helpers';
import { useConvertedPrice } from 'containers/CurrencyProvider/useConvertedPrice';

// Styles and Assets
import './index.scss';

const AnalyticProperty = ({ title, value }) => (
  <div className='sk-user-view-expert-details-analytics-item'>
    <Text>{title}</Text>
    <Text size='small'>{value}</Text>
  </div>
);

const MOMENT_PERIODS = {
  annual: 'years',
  monthly: 'months',
};
const BILLING_PERIODS = {
  annual: 'year',
  monthly: 'month',
}

const PREFIX = 'sk-user-view-expert-details-analytics';
const Analytics = (props) => {
  const Table = props?.subscription?.type == 'commission' ? ComissionSubscription : LimitedSubsctiption;
  return (
    <div className={PREFIX}>
      <Text>Subscription plan</Text>
      <Table {...props} />
    </div>
  )
};

const ComissionSubscription = ({
  subscription: { plan, price, updatedAt },
  totalActiveCourses,
  ...props
}) => {
  return (
    <div className={`${PREFIX}__table`}>
      <AnalyticProperty title='Title' value={plan?.title?.en} />
      <AnalyticProperty title='Billing period' value={moment(updatedAt).format('DD.MM.YYYY')} />
      <AnalyticProperty title='Payment' value={` Expert ${price}% / Platform ${100 - price}%`} />
      <AnalyticProperty title='Created courses' value={`${isBlank(totalActiveCourses) ? 0 : totalActiveCourses}`} />
    </div>
  )
};

const LimitedSubsctiption = ({
  subscription: { plan, price, maxCourses, billingPeriod, expiresAt, updatedAt },
  totalActiveCourses,
  ...props
}) => {
  totalActiveCourses = isBlank(totalActiveCourses) ? 0 : totalActiveCourses;

  const convertedPrice = useConvertedPrice({ price, isBeautyPrice: true });
  const totalCourses = isBlank(maxCourses) ? totalActiveCourses : `${totalActiveCourses}/${maxCourses}`;

  return (
    <div className={`${PREFIX}__table`}>
      <AnalyticProperty title='Title' value={plan?.title?.en} />
      <AnalyticProperty title='Billing period' value={`${moment(expiresAt).subtract(1, MOMENT_PERIODS[billingPeriod]).format('DD.MM.YYYY')} - ${moment(expiresAt).format('DD.MM.YYYY')}`} />
      <AnalyticProperty title='Payment' value={`${convertedPrice}/${BILLING_PERIODS[billingPeriod]}`} />
      <AnalyticProperty title='Created courses' value={totalCourses} />
    </div>
  )
};

const enhancer = compose(
  memo,
  injectIntl
);

export default enhancer(Analytics);
