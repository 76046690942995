import React from 'react';

const Icon = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="path-2-inside-1" fill="white">
      <path d="M9.93789 1.62112C15.0932 1.62112 19.2547 5.78261 19.2547 10.9379C19.2547 16.0932 15.0932 20.2547 9.93789 20.2547C4.78261 20.2547 0.621118 16.0932 0.621118 10.9379C0.621118 5.78261 4.78261 1.62112 9.93789 1.62112ZM9.93789 1C4.47205 1 0 5.47205 0 10.9379C0 16.4037 4.47205 20.8758 9.93789 20.8758C15.4037 20.8758 19.8758 16.4037 19.8758 10.9379C19.8758 5.47205 15.4037 1 9.93789 1Z"/>
      </mask>
      <path d="M9.93789 2.62112C14.5409 2.62112 18.2547 6.33489 18.2547 10.9379H20.2547C20.2547 5.23032 15.6455 0.621118 9.93789 0.621118V2.62112ZM18.2547 10.9379C18.2547 15.5409 14.5409 19.2547 9.93789 19.2547V21.2547C15.6455 21.2547 20.2547 16.6455 20.2547 10.9379H18.2547ZM9.93789 19.2547C5.33489 19.2547 1.62112 15.5409 1.62112 10.9379H-0.378882C-0.378882 16.6455 4.23032 21.2547 9.93789 21.2547V19.2547ZM1.62112 10.9379C1.62112 6.33489 5.33489 2.62112 9.93789 2.62112V0.621118C4.23032 0.621118 -0.378882 5.23032 -0.378882 10.9379H1.62112ZM9.93789 0C3.91976 0 -1 4.91976 -1 10.9379H1C1 6.02433 5.02433 2 9.93789 2V0ZM-1 10.9379C-1 16.956 3.91976 21.8758 9.93789 21.8758V19.8758C5.02433 19.8758 1 15.8514 1 10.9379H-1ZM9.93789 21.8758C15.956 21.8758 20.8758 16.956 20.8758 10.9379H18.8758C18.8758 15.8514 14.8514 19.8758 9.93789 19.8758V21.8758ZM20.8758 10.9379C20.8758 4.91976 15.956 0 9.93789 0V2C14.8514 2 18.8758 6.02433 18.8758 10.9379H20.8758Z" fill="#A9B6C8" mask="url(#path-2-inside-1)"/>
      <path d="M1.98755 6.03064H18.1366" stroke="#A9B6C8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M1.36649 15.348H18.1367" stroke="#A9B6C8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M0.124176 11H19.3788" stroke="#A9B6C8" strokeMiterlimit="10"/>
      <path d="M9.93793 1.31067V20.5653" stroke="#A9B6C8" strokeMiterlimit="10"/>
      <path d="M9.8138 1.31067C9.8138 1.31067 5.46597 4.41626 5.46597 10.6274C5.46597 18.0809 9.8138 20.5653 9.8138 20.5653" stroke="#A9B6C8" strokeMiterlimit="10"/>
      <path d="M9.81375 1.31067C9.81375 1.31067 14.5964 3.67092 14.7827 10.6274C14.9069 17.3355 10.4349 20.5653 10.4349 20.5653" stroke="#A9B6C8" strokeMiterlimit="10"/>
    </svg>
  );
};

export default Icon;
