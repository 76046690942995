import React from 'react';
import PropTypes from 'prop-types';

import './Homeworks.scss';

const Homeworks = ({color = '#30d5c8'}) => {
  const index = Math.random();
  return(
    <svg class="homeworks-svg" width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g className="outlined">
        <path d="M6 26.1949V5.9105C6 4.84911 6.84911 4 7.9105 4H19.8924C20.4585 4 20.9774 4.23586 21.3548 4.68401L26.7089 10.9344C26.9919 11.2882 27.1571 11.7128 27.1571 12.1845V26.242C27.1571 28.1997 25.5768 29.78 23.6191 29.78L9.51438 29.7328C7.58029 29.7092 6 28.1289 6 26.1949Z" stroke={color} stroke-miterlimit="10"/>
        <path d="M20.4585 4.21191V10.5095C20.4585 11.0756 20.9066 11.5237 21.4727 11.5237L26.9448 11.5709" stroke={color} stroke-miterlimit="10"/>
        <path d="M17.4579 20.2343C17.5117 20.3497 17.5386 20.4523 17.5386 20.542C17.5386 20.7215 17.4612 20.8753 17.3066 21.0035C17.1586 21.1253 16.9872 21.1862 16.7922 21.1862C16.6644 21.1862 16.5434 21.1541 16.4291 21.09C16.3148 21.0195 16.2274 20.917 16.1668 20.7824L15.6323 19.6478H12.213L11.6784 20.7824C11.6179 20.917 11.5305 21.0195 11.4162 21.09C11.3086 21.1541 11.1876 21.1862 11.0531 21.1862C10.8581 21.1862 10.6833 21.1253 10.5286 21.0035C10.374 20.8753 10.2966 20.7215 10.2966 20.542C10.2966 20.4523 10.3235 20.3497 10.3773 20.2343L13.1006 14.7827C13.1746 14.6288 13.2855 14.5134 13.4335 14.4365C13.5814 14.3532 13.7394 14.3115 13.9075 14.3115C14.0823 14.3115 14.2437 14.3532 14.3917 14.4365C14.5396 14.5198 14.6539 14.6352 14.7346 14.7827L17.4579 20.2343ZM15.0977 18.5132L13.9277 16.0134L12.7476 18.5132H15.0977Z" fill={color}/>
        <path d="M22.6621 17.8979C22.8101 17.8979 22.9345 17.946 23.0353 18.0421C23.1362 18.1319 23.1866 18.2472 23.1866 18.3882C23.1866 18.5293 23.1362 18.6479 23.0353 18.744C22.9345 18.8401 22.8101 18.8882 22.6621 18.8882H21.2501V20.2439C21.2501 20.3849 21.1996 20.5035 21.0988 20.5997C20.9979 20.6894 20.8735 20.7343 20.7256 20.7343C20.5777 20.7343 20.4533 20.6894 20.3524 20.5997C20.2515 20.5035 20.2011 20.3849 20.2011 20.2439V18.8882H18.789C18.6411 18.8882 18.5167 18.8401 18.4158 18.744C18.315 18.6479 18.2645 18.5293 18.2645 18.3882C18.2645 18.2472 18.315 18.1319 18.4158 18.0421C18.5167 17.946 18.6411 17.8979 18.789 17.8979H20.2011V16.5422C20.2011 16.4012 20.2515 16.2826 20.3524 16.1864C20.4533 16.0903 20.5777 16.0422 20.7256 16.0422C20.8735 16.0422 20.9979 16.0903 21.0988 16.1864C21.1996 16.2826 21.2501 16.4012 21.2501 16.5422V17.8979H22.6621Z" fill={color}/>
      </g>
      <g className="filled">
        <path d="M6 26.1949V5.9105C6 4.84911 6.84911 4 7.9105 4H19.8924C20.4585 4 20.9774 4.23586 21.3548 4.68401L26.7089 10.9344C26.9919 11.2882 27.1571 11.7128 27.1571 12.1845V26.242C27.1571 28.1997 25.5768 29.78 23.6191 29.78L9.51438 29.7328C7.58029 29.7092 6 28.1289 6 26.1949Z" fill={`url(#paint${index}0_linear)`} stroke="white" stroke-miterlimit="10"/>
        <path d="M20.4585 4.21191V10.5095C20.4585 11.0756 20.9066 11.5237 21.4727 11.5237L26.9448 11.5709" stroke="white" stroke-miterlimit="10"/>
        <path d="M17.4579 20.2343C17.5117 20.3497 17.5386 20.4523 17.5386 20.542C17.5386 20.7215 17.4612 20.8753 17.3066 21.0035C17.1586 21.1253 16.9872 21.1862 16.7922 21.1862C16.6644 21.1862 16.5434 21.1541 16.4291 21.09C16.3148 21.0195 16.2274 20.917 16.1668 20.7824L15.6323 19.6478H12.213L11.6784 20.7824C11.6179 20.917 11.5305 21.0195 11.4162 21.09C11.3086 21.1541 11.1876 21.1862 11.0531 21.1862C10.8581 21.1862 10.6833 21.1253 10.5286 21.0035C10.374 20.8753 10.2966 20.7215 10.2966 20.542C10.2966 20.4523 10.3235 20.3497 10.3773 20.2343L13.1006 14.7827C13.1746 14.6288 13.2855 14.5134 13.4335 14.4365C13.5814 14.3532 13.7394 14.3115 13.9075 14.3115C14.0823 14.3115 14.2437 14.3532 14.3917 14.4365C14.5396 14.5198 14.6539 14.6352 14.7346 14.7827L17.4579 20.2343ZM15.0977 18.5132L13.9277 16.0134L12.7476 18.5132H15.0977Z" fill="white"/>
        <path d="M22.6621 17.8979C22.8101 17.8979 22.9345 17.946 23.0353 18.0421C23.1362 18.1319 23.1866 18.2472 23.1866 18.3882C23.1866 18.5293 23.1362 18.6479 23.0353 18.744C22.9345 18.8401 22.8101 18.8882 22.6621 18.8882H21.2501V20.2439C21.2501 20.3849 21.1996 20.5035 21.0988 20.5997C20.9979 20.6894 20.8735 20.7343 20.7256 20.7343C20.5777 20.7343 20.4533 20.6894 20.3524 20.5997C20.2515 20.5035 20.2011 20.3849 20.2011 20.2439V18.8882H18.789C18.6411 18.8882 18.5167 18.8401 18.4158 18.744C18.315 18.6479 18.2645 18.5293 18.2645 18.3882C18.2645 18.2472 18.315 18.1319 18.4158 18.0421C18.5167 17.946 18.6411 17.8979 18.789 17.8979H20.2011V16.5422C20.2011 16.4012 20.2515 16.2826 20.3524 16.1864C20.4533 16.0903 20.5777 16.0422 20.7256 16.0422C20.8735 16.0422 20.9979 16.0903 21.0988 16.1864C21.1996 16.2826 21.2501 16.4012 21.2501 16.5422V17.8979H22.6621Z" fill="white"/>
      </g>
      <defs>
      <linearGradient id={`paint${index}0_linear`} x1="29.0727" y1="31.5153" x2="2.46939" y2="28.8371" gradientUnits="userSpaceOnUse">
      <stop stop-color="#8F26AF"/>
      <stop offset="0.979167" stop-color="#141685"/>
      </linearGradient>
      </defs>
    </svg>
  )
}

export default Homeworks;
