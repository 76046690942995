// Core
import React from 'react';
import { injectIntl } from 'react-intl';

import { Auth } from 'aws-amplify';

// Components
import GoogleLoginIcon from 'components/Icon/Svg/LoginGoogle';
import FacebookLoginIcon from 'components/Icon/Svg/LoginFacebook';
import Text from 'components/Text';

// Other
import { messages } from 'routes/Authenticator/messages';

// Styles
import './index.scss';

const SOCIALS = {
  google: {
    icon: GoogleLoginIcon,
    text: 'Google',
  },
  facebook: {
    icon: FacebookLoginIcon,
    text: 'Facebook',
  },
};

const SocialLoginButton = ({
  type = 'signIn',
  social = 'google',
  onClick,
}) => {
  const message = SOCIALS[social].text;
  const Icon = SOCIALS[social].icon;

  return (
    <button onClick={onClick} type='button'>
      <Icon />
      <Text>{message}</Text>
    </button>
  );
};

const SocialLoginForm = injectIntl(({ intl: { formatMessage }, }) => {
  return (
    <div className="sk-auth-social">
      <div className="sk-auth-social__breaker">
        <div />
        <div>{formatMessage(messages.orContinueWith)}</div>
      </div>
      <div className="sk-auth-social__buttons">
        <SocialLoginButton social="facebook" onClick={() => Auth.federatedSignIn({provider: 'Facebook'})} />
        <SocialLoginButton onClick={() => Auth.federatedSignIn({provider: 'Google'})} />
      </div>
    </div>
  );
});

export default SocialLoginForm;
