import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  chat: {
    id: 'app.components.WebinarRoom.chat',
    defaultMessage: 'Chat',
  },
  member: {
    id: 'app.components.WebinarRoom.member',
    defaultMessage: 'member(s)',
  },
  typeSmth: {
    id: 'app.components.WebinarRoom.typeSmth',
    defaultMessage: 'Type something...',
  },
});
