// Core
import React, { memo, useCallback, useEffect } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { compose } from "react-apollo";
import { withRouter } from "react-router-dom";

// Components
import TextInput from "components/Input/Material/TextInput/TextInput";
import PasswordInput from "components/Input/Material/PasswordInput";
import Button from "components/Button";
import Link from "components/Link";
import SocialLoginForm from "routes/Authenticator/SocialLoginForm";

import { messages } from "routes/Authenticator/SignInForm/messages";

import "./SignInForm.scss";

const SignInForm = memo(
  ({
    values,
    values: { email, password, orgId },
    errors,
    isLoading,
    touched,
    handleSubmit,
    setFieldValue,
    handleBlur,
    sso = false,
    intl: { formatMessage },
  }) => {
    const handleInputChange = (name) => (inputValue, e) => {
      setFieldValue(name, inputValue);
    };

    const handleInputBlur = (name) => (inputValue, e) => {
      if (e) {
        handleBlur(e);
      }
      setFieldValue(name, inputValue);
    };
    const handleEnterPress = (params1, params2) => {
      const charCode = params1?.charCode || params2?.charCode;
      if (charCode == 13) {
        handleSubmit(values); // eslint-disable-line
      }
    };

    return (
      <form
        className="sk-auth-content_form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <div className="sk-form__inner  sk-auth-content_inner">
          <div className="sk-form__row">
            <TextInput
              name="email"
              type="email"
              helperText={touched.email ? errors.email : ""}
              error={touched.email && Boolean(errors.email)}
              value={email}
              label={formatMessage(messages.email)}
              placeholder={formatMessage(messages.email)}
              onBlur={handleInputBlur("email")}
              onChange={handleInputChange("email")}
              onKeyPress={handleEnterPress}
            />
          </div>
          <div className="sk-form__row">
            <PasswordInput
              name="password"
              helperText={touched.password ? errors.password : ""}
              error={touched.password && Boolean(errors.password)}
              value={password}
              label={formatMessage(messages.password)}
              placeholder={formatMessage(messages.password)}
              onBlur={handleInputBlur("password")}
              onChange={handleInputChange("password")}
              onKeyPress={handleEnterPress}
            />
          </div>
        </div>
        <div className="sk-form_footer sk-auth-content_footer">
          <div className="sk-auth-content_footer__top">
            <Button
              variant="text"
              color="secondary"
              size="large"
              onClick={handleSubmit}
              loading={isLoading}
            >
              <FormattedMessage {...messages.logIn} />
            </Button>
          </div>
          <Link to="/pw-reset" classes="sk-auth-content_link__weak">
            <FormattedMessage {...messages.forgotPassword} />
          </Link>
          {!sso && <SocialLoginForm />}
        </div>
      </form>
    );
  }
);

const enhancer = compose(injectIntl, withRouter);

export default enhancer(SignInForm);
