// Core
import React, { memo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

// Components
import Button from 'components/Button';
import Text from 'components/Text';
import UploadController from 'routes/Course/components/UploadController';
import FileUploader from 'components/FileUploader';
import TrashBin from 'components/Icon/Svg/TrashBin';

// Other
import { SUPPORTED_FORMATS_HOMEWORK } from 'utils/enums';
import { PurpleHeart } from 'components/Icon/color';
import { renderTrimmedText } from 'utils/helpers';

import { messages } from 'routes/ExpertsCabinet/components/ExpHWReviewUploader/messages';
import { sharedMessages } from 'services/i18n/sharedMessages/messages';

// Styles and Assets
import './index.scss';

const ExpHWUploadedReview = ({
  title,
  lectureId,
  disabled,
  mappingId,
  isReviewProcessing,
  removeReview,
  handleReloadReview,
  ...props
}) => {
  return (
    <div className="sk-exp-hw-review-uploader">
      <div className="sk-exp-hw-review-uploader__main">
        <div className={classNames('sk-exp-hw-review-uploader__main__title', {'inactive': isReviewProcessing })}>
          {renderTrimmedText(title, 10)}
        </div>
        <div className="sk-exp-hw-review-uploader__main__loader">
          <UploadController
            withDelete={false}
            color="primary"
            uploaderId={`sk-student-${mappingId}-lec-${title}-${lectureId}}-hw-uploader-2`}
            disabled={disabled}
            supportedFormats={SUPPORTED_FORMATS_HOMEWORK}
            maxSize={500000}
            // handleDelete={removeReview}
            handleReload={handleReloadReview}
          />
        </div>
      </div>
      {isReviewProcessing && (
        <Text className='sk-exp-hw-review-uploader__processing inactive'>File is processing...</Text>
      )}
    </div>
  );
}

const ExpHWReviewUploader = ({
  reviewFile,
  lectureId,
  studentId,
  disabled,
  mappingId,
  isReviewUploadedAndProcessed,
  isReviewPending,
  isReviewUploaded,
  isReviewInQueue,
  isReviewProcessing,
  removeReview,
  handleAddHomeworkReview,
  ...props
}) => {
  const isFileInProcess = isReviewPending && (isReviewUploaded || isReviewInQueue);

  // if (lectureId == 'f10dc495-75a9-4bd2-a48d-31e5ef59b4bf' && studentId == 'us-east-2:bd467ab7-4771-438c-9a07-9fb40c8b0e43') {
  //   console.log('review file', reviewFile);
  //   console.log('review uploader', { isFileInProcess, isReviewProcessing, isReviewUploadedAndProcessed, });
  // }

  return (
    <div className="sk-exp-hw-review-uploader">
      {(isReviewUploadedAndProcessed || isReviewProcessing) ? (
        <ExpHWUploadedReview
          title={reviewFile.filename}
          lectureId={lectureId}
          disabled={disabled}
          mappingId={mappingId}
          isReviewProcessing={isReviewProcessing}
          removeReview={removeReview}
          handleReloadReview={handleAddHomeworkReview(reviewFile?.id)}
        />
      ) : (
        <div className="sk-exp-hw-review-uploader_main__btn">
          <Button fullWidth sidePadFree disabled={disabled || isFileInProcess} loading={isFileInProcess}>
            <FileUploader
              supportedFormats={SUPPORTED_FORMATS_HOMEWORK}
              maxSize={500000}
              uploaderId={`sk-student-${mappingId}-lec-${lectureId}}-hw-uploader-2`}
              handleChange={handleAddHomeworkReview()}
              disabled={disabled}
              absolute
            >
              <span className="sk-exp-hw-review-uploader__btn-text">
                <FormattedMessage {...sharedMessages.uploadButton} />
              </span>
            </FileUploader>
          </Button>
        </div>
      )}
    </div>
  );
}

export default ExpHWReviewUploader;
