import React, { useState, useEffect, useRef } from 'react';
import { Editor, EditorState, RichUtils } from 'draft-js';
import classnames from 'classnames';

// Components
import {
  RecourseIconButton,
  StyleIconButton,
} from 'components/DraftEditor/ControlsBar/Buttons';

// Icons
import Blockquote from 'components/Icon/SvgCss/Editor/Blockquote';
import UnorderedList from 'components/Icon/SvgCss/Editor/UnorderedList';
import OrderedList from 'components/Icon/SvgCss/Editor/OrderedList';
import CodeBlock from 'components/Icon/SvgCss/Editor/CodeBlock';
import Video from 'components/Icon/SvgCss/Editor/Video';
import Link from 'components/Icon/SvgCss/Editor/Link';
import Image from 'components/Icon/SvgCss/Editor/Image';

// Other
import { IMAGE, VIDEO, LINK } from 'components/DraftEditor/enums.js';

// Styles
import './BlockControls.scss';

const ImageUpload = ({ addResource }) => (
  <input
    style={{ display: 'none' }}
    id="editor-image-upload"
    name="editor-image-upload"
    type="file"
    accept={['jpg', 'png']}
    disabled={false}
    onChange={event => addResource({ type: IMAGE, event })}
  />
);

const BLOCK_TYPES = [
  { label: 'Code Block', style: 'code-block', icon: CodeBlock },
  { label: 'Blockquote', style: 'blockquote', icon: Blockquote },
];

const RESOURCES = [
  { type: LINK, icon: Link, noBlurOnClick: true },
  { type: IMAGE, icon: Image, Component: ImageUpload },
  { type: VIDEO, icon: Video },
];

const BlockControls = ({ editorState, onToggle, addResource }) => {
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <div className="sk-editor-toolbar-controls-block">
      {BLOCK_TYPES.map(type => (
        <StyleIconButton
          key={type.label}
          active={type.style === blockType}
          onToggleHandler={onToggle}
          style={type.style}
          icon={type.icon}
        />
      ))}
      {RESOURCES.map(({ type, icon, Component, noBlurOnClick }) => (
        <RecourseIconButton
          tag={type === IMAGE ? 'label' : 'span'}
          icon={icon}
          type={type}
          active={false}
          noBlurOnClick={noBlurOnClick}
          onClickHandler={!Component && addResource}
        >
          {Component ? <Component addResource={addResource} /> : null}
        </RecourseIconButton>
      ))}
    </div>
  );
};

export default BlockControls;
