// Core
import React, { memo } from "react";
import { compose } from "react-apollo";
import { injectIntl } from "react-intl";

// Components
import Text from "components/Text";
import Heading from "components/Heading";
import Button from "components/Button";

import { FEATURES } from "routes/CourseDetails/BuyCourse/CourseFeatures/enums.js";

// Other
import { messages } from "routes/Corporate/messages";

// Styles and Assets
import "./index.scss";

const PREFIX = "sk-plan";

const Option = ({ icon: Icon, text }) => (
  <div className="feature">
    <Icon />
    {typeof text === "string" ? <Text>{text}</Text> : text}
  </div>
);

const PlanDetails = ({ features, formatMessage }) => (
  <div className="features">
    {features.map((feature) => (
      <Option
        key={FEATURES[feature].translationMessageId}
        icon={FEATURES[feature].icon}
        text={formatMessage(messages[FEATURES[feature].translationMessageId])}
      />
    ))}
  </div>
);

const Plan = ({
  loading,
  title,
  currency,
  currencyLabel,
  amount,
  disabled,
  strategy,
  storage,
  features = [],
  handleSelect,
  intl: { formatMessage },
  ...props
}) => {
  return (
    <div className={`${PREFIX}`}>
      <div className="sk-card-plan mui-override sk-card">
        <Heading classes="sk-plan__title" variant="h5">
          {title}
        </Heading>
        <div className="price">
          {amount} <span>/ per user</span>
        </div>
        <p>{storage} private storage</p>
        {features && features.length && (
          <PlanDetails features={features} formatMessage={formatMessage} />
        )}
        <Button
          color="secondary"
          variant="text"
          onClick={() => handleSelect(title)}
        >
          Select
        </Button>
      </div>
    </div>
  );
};

const enhancer = compose(memo, injectIntl);

export default enhancer(Plan);
