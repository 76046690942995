// Core
import React, { memo, useCallback } from "react";
import { Formik } from "formik";
import { compose } from "react-apollo";
import { injectIntl } from "react-intl";

// Components
import Button from "components/Button";
import CourseDescriptionForm from "routes/ExpertsCabinet/CourseEdit/Details/CourseInformation/CourseDescriptionForm";
import CourseAdminInformationForm from "routes/ExpertsCabinet/CourseEdit/Details/CourseInformation/CourseAdminInformationForm";
import CourseVariantsForm from "routes/ExpertsCabinet/CourseEdit/Details/CourseInformation/CourseVariantsForm";
import AdditionalCourseDetails from "routes/ExpertsCabinet/CourseEdit/Details/CourseInformation/AdditionalCourseDetails";
import CourseGrid from "routes/Course/components/CourseGrid";

// Other
import { isFalse } from "utils/helpers/index";
import { messages as generalInfoMessages } from "routes/ExpertsCabinet/CourseEdit/GeneralInfo/messages";
import { messages } from "routes/ExpertsCabinet/CourseEdit/Details/CourseInformation/messages";
import { withSharedState } from "containers/SharedStateProvider/withSharedState";

// Styles and Assets
import "./index.scss";

const CourseInformation = ({
  course,
  bindCourseMethod,
  disableAll,
  isCourseStatusUpdating,
  handleCourseSubmit,
  refetchCourse,
  intl: { formatMessage },
  sharedStateContext: { sharedState },
  org,
}) => {
  const submitBtnIsVisible = course.statusId === "DRAFT";
  const submitDraft = useCallback(() => handleCourseSubmit("FIRST_REVIEW"), [
    handleCourseSubmit,
  ]);

  const hasErrors =
    isFalse(sharedState?.courseInfo?.isValid) ||
    isFalse(sharedState?.courseDescription?.isValid) ||
    isFalse(sharedState?.courseVariants?.isValid) ||
    isFalse(sharedState?.additionalCourseDetails?.isValid) ||
    isFalse(sharedState?.courseAdminInformation?.isValid);

  return (
    <CourseGrid>
      <CourseDescriptionForm
        disableAll={disableAll}
        course={course}
        isCourseStatusUpdating={isCourseStatusUpdating}
      />
      <CourseVariantsForm disabled={disableAll} org={org} />
      <AdditionalCourseDetails
        disabled={disableAll}
        refetchCourse={refetchCourse}
      />
      <CourseAdminInformationForm
        disableAll={disableAll}
        isCourseStatusUpdating={isCourseStatusUpdating}
      />
      {submitBtnIsVisible && (
        <footer className="sk-course-information">
          <Button
            variant="text"
            color="secondary"
            size="large"
            loading={isCourseStatusUpdating}
            onClick={submitDraft}
          >
            {formatMessage(messages.submit)}
          </Button>
          {hasErrors && (
            <div className="sk-course-action-panel__error">
              {" "}
              {formatMessage(generalInfoMessages.formFieldsError)}
            </div>
          )}
        </footer>
      )}
    </CourseGrid>
  );
};

const enhancer = compose(memo, injectIntl, withSharedState);

export default enhancer(CourseInformation);
