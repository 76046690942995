import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  organizationId: {
    id: 'app.components.SignUpForm.organizationId',
    defaultMessage: 'Organisation ID',
  },
  email: {
    id: 'app.components.SignUpForm.email',
    defaultMessage: 'Email Address',
  },
  password: {
    id: 'app.components.SignInForm.password',
    defaultMessage: 'Password',
  },
  signUpPassword: {
    id: 'app.components.SignUpForm.password',
    defaultMessage: 'Password',
  },
  logIn: {
    id: 'app.components.AuthTabs.logIn',
    defaultMessage: 'Log In',
  },
  forgotPassword: {
    id: 'app.components.SignInForm.forgotPassword',
    defaultMessage: 'Forgot your password?',
  },
});
