// TODO: Handle relation between initial and new values
export const mappingAlgorithm = ({ mapping, initValues = {}, newValues = {} }) => {
  let mappedObj = {};

  for (const key in initValues) {
    const newKey = mapping[key];

    if (typeof newKey == 'string') {
      mappedObj[newKey] = initValues[key];
    } else if (typeof newKey == 'function') {
      const keyValue = newKey(initValues[key], newValues[key]);
      mappedObj = { ...mappedObj, ...keyValue };
    };
  };

  return mappedObj;
};

export const updateTitle = (key) => (initValues, newValue) => ({ title: { ...initValues?.title, [key]: newValue } });

export const updatePrice = (type) => (initValues, newValue) => {
  const hasPrice = initValues?.price?.some(price => price?.type == type);
  let price = [];

  if (hasPrice) {
    price = initValues?.price?.map(price => {
      const newPrice = { ...price };
      if (newPrice?.type == type) {
        newPrice.amount = newValue;
      };
      return newPrice;
    });
  } else {
    price = [...initValues?.price, { type, amount: newValue }];
  };
  return { price };
};
