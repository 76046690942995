// Core
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';

// Components
import NextButton from './NextButton';
import PrevButton from './PrevButton';

// Other
import { messages } from './messages';

// Styles
import './ContentNavigation.scss';

const ContentNavigation = ({
  handleNextButtonPress,
  handlePreviousButtonPress,
  withLabel,
  withoutSeparator,
  hasNext = true
}) => (
  <div className="sk-content-nav">
    <div className="sk-content-nav_item">
      {withLabel ? (
        <FormattedMessage {...messages.prevButtonText}>
          {(label) => (
            <PrevButton
              handleClick={handlePreviousButtonPress}
              label={label}
              withoutSeparator={withoutSeparator}
            />
          )}
        </FormattedMessage>
      ) : (
        <PrevButton handleClick={handlePreviousButtonPress} withoutSeparator={withoutSeparator} />
      )}
    </div>

    {!withoutSeparator && hasNext === true && <span className="sk-content-nav_separator" />}

    {hasNext === false ? null : (
      <div className="sk-content-nav_item">
        {withLabel ? (
          <FormattedMessage {...messages.nextButtonText}>
            {(label) => (
              <NextButton
                handleClick={handleNextButtonPress}
                label={label}
                withoutSeparator={withoutSeparator}
              />
            )}
          </FormattedMessage>
        ) : (
          <NextButton handleClick={handleNextButtonPress} withoutSeparator={withoutSeparator} />
        )}
      </div>
    )}
  </div>
);

ContentNavigation.defaultProps = {
  withLabel: false,
  withoutSeparator: true,
  handleNextButtonPress: () => {},
  handlePreviousButtonPress: () => {}
};

ContentNavigation.propTypes = {
  handleNextButtonPress: PropTypes.func,
  handlePreviousButtonPress: PropTypes.func,
  withLabel: PropTypes.bool,
  withoutSeparator: PropTypes.bool
};

export default memo(injectIntl(ContentNavigation));
