import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';

// Strip __typename from variables
export const typenameStripMiddleWare = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    const omitTypename = (key, value) => ['__typename', 'typename', 'Typename'].includes(key) ? undefined : value;
    // eslint-disable-next-line no-param-reassign
    operation.variables = JSON.parse(
      JSON.stringify(operation.variables),
      omitTypename
    );
  }
  return forward(operation);
});

export const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) =>
      // eslint-disable-next-line no-console
      console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
  )};
  if (networkError) {
    // eslint-disable-next-line no-console
    console.error(`[Network error]: ${networkError}`);
  }
});
