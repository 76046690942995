import React, { memo } from 'react';
import { compose } from 'react-apollo';

const Icon = ({ ...props }) => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12.5002" cy="12.5002" r="9.05882" stroke="#6D7C90"/>
      <path d="M15.5298 12.1764C15.6866 12.1764 15.819 12.2304 15.9268 12.3382C16.0347 12.4362 16.0886 12.5637 16.0886 12.7206C16.0886 12.8774 16.0347 13.0098 15.9268 13.1176C15.819 13.2157 15.6866 13.2647 15.5298 13.2647H13.0445V15.75C13.0445 15.9068 12.9905 16.0392 12.8827 16.147C12.7847 16.2549 12.6572 16.3088 12.5003 16.3088C12.3435 16.3088 12.2111 16.2549 12.1033 16.147C12.0052 16.0392 11.9562 15.9068 11.9562 15.75V13.2647H9.47093C9.31407 13.2647 9.18172 13.2108 9.07387 13.1029C8.96603 12.9951 8.91211 12.8676 8.91211 12.7206C8.91211 12.5637 8.96603 12.4362 9.07387 12.3382C9.18172 12.2304 9.31407 12.1764 9.47093 12.1764H11.9562V9.69115C11.9562 9.53429 12.0052 9.40193 12.1033 9.29409C12.2111 9.18625 12.3435 9.13232 12.5003 9.13232C12.6572 9.13232 12.7847 9.18625 12.8827 9.29409C12.9905 9.40193 13.0445 9.53429 13.0445 9.69115V12.1764H15.5298Z" fill="#6D7C90"/>
    </svg>
  );
};

const enhancer = compose(
  memo,
);

export default enhancer(Icon);
