import React, { useState, useCallback, useEffect, } from 'react';
import PropTypes from 'prop-types';
import { compose, } from 'react-apollo';
import { FormattedMessage } from 'react-intl';

// Components
import Text from 'components/Text';
import ToolTip from 'components/ToolTip';
import Button from 'components/Button';
import Image from 'components/Image';

// Other
import { messages } from 'routes/ExpertsCabinet/CourseEdit/Details/messages';
import { withCurrency } from 'containers/CurrencyProvider/withCurrency';
import { addSpacesBetweenEveryGroupOfThreeNumbers } from 'utils/helpers/index';

// Styles
import './index.scss';

const FLAGS_PATHS = {
  usd: '/assets/images/flag-usa.png',
  rub: '/assets/images/flag-ru.png',
  uah: '/assets/images/flag-ua.png',
  eur: '/assets/images/flag-eu.png',
}

const PricesDependOnCurrency = ({
  currency,
  price,
}) => {
  return (
    <div className="sk-course-prices-conversions">
      <Image src={FLAGS_PATHS[currency.currency_id]} />
      <Text classes='sk-course-prices-conversions__prices__price'>{currency.icon} {addSpacesBetweenEveryGroupOfThreeNumbers(price)}</Text>
    </div>
  );
};

const enhancer = compose(
  withCurrency
)

export default enhancer(PricesDependOnCurrency);
