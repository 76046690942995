import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  videoUploadingModalText: {
    id: 'app.components.ComplexitySelect.videoUploadingModalText',
    defaultMessage:
      'Your video is processing. It can take up to 1h depend on file size!',
  },
  videoUploadingModalButton: {
    id: 'app.components.ComplexitySelect.videoUploadingModalButton',
    defaultMessage: 'Close',
  },
  courseComplexity: {
    id: 'app.components.ComplexitySelect.courseComplexity',
    defaultMessage: 'Complexity',
  },
  interactivityLevel: {
    id: 'app.components.InteractivityLevelSelect.interactivityLevel',
    defaultMessage: 'Interactivity level',
  },
  anyLanguage: {
    id: 'app.components.LanguageSelect.interactivityLevel',
    defaultMessage: 'Language',
  },
  articleTitle: {
    id: 'app.components.LectureArticleModal.articleTitle',
    defaultMessage: 'Article title',
  },
  estimatedReadingTime: {
    id: 'app.components.LectureArticleModal.estimatedReadingTime',
    defaultMessage: 'Estimated reading time',
  },
  articleText: {
    id: 'app.components.LectureArticleModal.articleText',
    defaultMessage: 'Article text',
  },
  saveArticle: {
    id: 'app.components.LectureArticleModal.saveArticle',
    defaultMessage: 'Save article',
  },
  lectureDesc: {
    id: 'app.components.LectureDescription.lectureDesc',
    defaultMessage: 'Description',
  },
  studyMats: {
    id: 'app.components.PCDetails.studyMats',
    defaultMessage: 'Study Materials',
  },
  lecturePublicMaterial: {
    id: 'app.components.PCDetails.publicMaterial',
    defaultMessage: 'Open material',
  },
  lecturePublicMaterialToolTip: {
    id: 'app.components.PCDetails.publicMaterialToolTip',
    defaultMessage: 'You can make some of your material free for potential students. It will work well as advertising for your course',
  },
  addVideo: {
    id: 'app.components.LectureMaterials.addVideo',
    defaultMessage: 'Add video *',
  },
  addAudio: {
    id: 'app.components.LectureMaterials.addAudio',
    defaultMessage: 'Add audio',
  },
  addArticle: {
    id: 'app.components.LectureMaterials.addArticle',
    defaultMessage: 'Add article',
  },
  addArticleAsPDF: {
    id: 'app.components.LectureMaterials.addArticleAsPDF',
    defaultMessage: 'Add article as PDF',
  },
  addLectureQuiz: {
    id: 'app.components.LectureMaterials.addLectureQuiz',
    defaultMessage: 'Add quiz',
  },
  videoReqs5Gb: {
    id: 'app.components.LectureMaterials.videoReqs5Gb',
    defaultMessage:
      'Video requirements: .mp4, .MOV or .mpg formats, maximum 5Gb size. Recommenations: HD, 720p or 1080p with 16:9 aspect ratio.',
  },
  thereAreNoStudyMats: {
    id: 'app.components.SummaryDurationBlock.thereAreNoStudyMats',
    defaultMessage: 'There are no study materials for this lecture...yet',
  },
  min: {
    id: 'app.components.PCDetails.min',
    defaultMessage: 'min',
  },
  createLectures: {
    id: 'app.components.LecturesCounter.createLectures',
    defaultMessage: 'Create Lectures',
  },
  zerohHzeromM: {
    id: 'app.components.LecturesCounter.zerohHzeromM',
    defaultMessage: '0h 0m',
  },
  fileRequirement: {
    id: 'app.components.LectureDetails.fileRequirement',
    defaultMessage: 'File requirements: PDF format, maximum 20Mb size.',
  },
  max100Words: {
    id: 'app.components.LectureSummary.max100Words',
    defaultMessage: '(max 100 words)',
  },
  enterVideoTitle: {
    id: 'app.components.LectureVideoModal.enterVideoTitle',
    defaultMessage: 'Enter video title first',
  },
  saveVideo: {
    id: 'app.components.LectureVideoModal.saveVideo',
    defaultMessage: 'Save video',
  },
  minDuration: {
    id: 'app.components.SummaryDurationBlock.minDuration',
    defaultMessage: 'min – duration',
  },
  previewFile: {
    id: 'app.components.VideoUploader.previewFile',
    defaultMessage: 'Preview File',
  },
  selectFiles: {
    id: 'app.components.VideoUploader.selectFiles',
    defaultMessage: 'Select Files',
  },
  dropFiles: {
    id: 'app.components.VideoUploader.dropFiles',
    defaultMessage: 'Drop files here or',
  },
  Alllevels: {
    id: 'app.components.CourseCard.Alllevels',
    defaultMessage: 'All levels',
  },
  Beginner: {
    id: 'app.components.CourseCard.Beginner',
    defaultMessage: 'Beginner',
  },
  Intermediate: {
    id: 'app.components.CourseCard.Intermediate',
    defaultMessage: 'Intermediate',
  },
  Advanced: {
    id: 'app.components.CourseCard.Advanced',
    defaultMessage: 'Advanced',
  },
  AR: {
    id: 'app.components.LanguageSelect.AR',
    defaultMessage: 'Arabic',
  },
  HY: {
    id: 'app.components.LanguageSelect.HY',
    defaultMessage: 'Armenian',
  },
  AZ: {
    id: 'app.components.LanguageSelect.AZ',
    defaultMessage: 'Azerbaijani',
  },
  BG: {
    id: 'app.components.LanguageSelect.BG',
    defaultMessage: 'Bulgarian',
  },
  CA: {
    id: 'app.components.LanguageSelect.CA',
    defaultMessage: 'Catalan',
  },
  ZH: {
    id: 'app.components.LanguageSelect.ZH',
    defaultMessage: 'Chinese',
  },
  HR: {
    id: 'app.components.LanguageSelect.HR',
    defaultMessage: 'Croatian',
  },
  CZ: {
    id: 'app.components.LanguageSelect.CZ',
    defaultMessage: 'Czech',
  },
  DA: {
    id: 'app.components.LanguageSelect.DA',
    defaultMessage: 'Danish',
  },
  NL: {
    id: 'app.components.LanguageSelect.NL',
    defaultMessage: 'Dutch',
  },
  EN: {
    id: 'app.components.LanguageSelect.EN',
    defaultMessage: 'English',
  },
  ET: {
    id: 'app.components.LanguageSelect.ET',
    defaultMessage: 'Estonian',
  },
  FA: {
    id: 'app.components.LanguageSelect.FA',
    defaultMessage: 'Farsi',
  },
  FI: {
    id: 'app.components.LanguageSelect.FI',
    defaultMessage: 'Finnish',
  },
  FR: {
    id: 'app.components.LanguageSelect.FR',
    defaultMessage: 'French',
  },
  KA: {
    id: 'app.components.LanguageSelect.KA',
    defaultMessage: 'Georgian',
  },
  DE: {
    id: 'app.components.LanguageSelect.DE',
    defaultMessage: 'German',
  },
  EL: {
    id: 'app.components.LanguageSelect.EL',
    defaultMessage: 'Greek',
  },
  HE: {
    id: 'app.components.LanguageSelect.HE',
    defaultMessage: 'Hebrew',
  },
  HI: {
    id: 'app.components.LanguageSelect.HI',
    defaultMessage: 'Hindu',
  },
  HU: {
    id: 'app.components.LanguageSelect.HU',
    defaultMessage: 'Hungarian',
  },
  IT: {
    id: 'app.components.LanguageSelect.IT',
    defaultMessage: 'Italian',
  },
  JA: {
    id: 'app.components.LanguageSelect.JA',
    defaultMessage: 'Japanese',
  },
  KK: {
    id: 'app.components.LanguageSelect.KK',
    defaultMessage: 'Kazakh',
  },
  LV: {
    id: 'app.components.LanguageSelect.LV',
    defaultMessage: 'Latvian',
  },
  LT: {
    id: 'app.components.LanguageSelect.LT',
    defaultMessage: 'Lithuanian',
  },
  NO: {
    id: 'app.components.LanguageSelect.NO',
    defaultMessage: 'Norwegian',
  },
  PL: {
    id: 'app.components.LanguageSelect.PL',
    defaultMessage: 'Polish',
  },
  PT: {
    id: 'app.components.LanguageSelect.PT',
    defaultMessage: 'Portuguese',
  },
  RO: {
    id: 'app.components.LanguageSelect.RO',
    defaultMessage: 'Romanian',
  },
  RU: {
    id: 'app.components.LanguageSelect.RU',
    defaultMessage: 'Russian',
  },
  SR: {
    id: 'app.components.LanguageSelect.SR',
    defaultMessage: 'Serbian',
  },
  SK: {
    id: 'app.components.LanguageSelect.SK',
    defaultMessage: 'Slovak',
  },
  SL: {
    id: 'app.components.LanguageSelect.SL',
    defaultMessage: 'Slovene',
  },
  ES: {
    id: 'app.components.LanguageSelect.ES',
    defaultMessage: 'Spanish',
  },
  SV: {
    id: 'app.components.LanguageSelect.SV',
    defaultMessage: 'Swedish',
  },
  TG: {
    id: 'app.components.LanguageSelect.TG',
    defaultMessage: 'Tajik',
  },
  TR: {
    id: 'app.components.LanguageSelect.TR',
    defaultMessage: 'Turkish',
  },
  TK: {
    id: 'app.components.LanguageSelect.TK',
    defaultMessage: 'Turkmen',
  },
  UK: {
    id: 'app.components.LanguageSelect.UK',
    defaultMessage: 'Ukranian',
  },
  UZ: {
    id: 'app.components.LanguageSelect.UZ',
    defaultMessage: 'Uzbek',
  },
  flex: {
    id: 'app.components.InteractivityLevelSelect.flex',
    defaultMessage: 'Flex'
  },
  assist: {
    id: 'app.components.InteractivityLevelSelect.assist',
    defaultMessage: 'Assist'
  },
  concierge: {
    id: 'app.components.InteractivityLevelSelect.concierge',
    defaultMessage: 'Concierge'
  },
});
