import React from 'react';
import PropTypes from 'prop-types';
import { PurpleHeart, White } from 'components/Icon/color';

const WebinarScreenSharing = ({
  strokeColor,
  fillColor,
  height,
  width,
  active,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx={width / 2}
      cy={height / 2}
      r={width / 2}
      fill={active ? strokeColor : fillColor}
      fillOpacity={active ? '1' : '0.75'}
    />
    <rect
      x="11.5"
      y="19.5"
      width="22"
      height="14"
      rx="1.5"
      stroke={active ? fillColor : strokeColor}
    />
    <rect
      x="20.5"
      y="33.5"
      width="4"
      height="3"
      stroke={active ? fillColor : strokeColor}
    />
    <rect
      x="17"
      y="36"
      width="11"
      height="1"
      rx="0.5"
      fill={active ? fillColor : strokeColor}
    />
    <path
      d="M17 18V15C17 14.4477 17.4477 14 18 14H37.5C38.0523 14 38.5 14.4477
            38.5 15V27C38.5 27.5523 38.0523 28 37.5 28H35"
      stroke={active ? fillColor : strokeColor}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

WebinarScreenSharing.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  strokeColor: PropTypes.string,
  fillColor: PropTypes.string,
  active: PropTypes.bool,
};

WebinarScreenSharing.defaultProps = {
  height: '50',
  width: '50',
  strokeColor: White,
  fillColor: PurpleHeart,
  active: false,
};

export default WebinarScreenSharing;
