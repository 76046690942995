import React from 'react';
import PropTypes from 'prop-types';
import { Red } from 'components/Icon/color';

const Star = ({ height = 24, width = 25, active = false }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width} height={height} viewBox="0 0 25 24"
  >
    <path d="M13.6104 1.69064L16.2045 6.948C16.3849 7.31365 16.7337 7.56702 17.1372 7.62556L22.9381 8.46868C23.9542 8.61648 24.3596 9.86507 23.6246 10.5815L19.4271 14.6738C19.1354 14.9583 19.0021 15.3686 19.0711 15.7702L20.0619 21.5487C20.2355 22.5608 19.1733 23.3324 18.2647 22.8549L13.0764 20.1269C12.7157 19.9374 12.2843 19.9374 11.9236 20.1269L6.73534 22.8549C5.82666 23.3329 4.76445 22.5608 4.93813 21.5487L5.92885 15.7702C5.99794 15.3686 5.86456 14.9583 5.57286 14.6738L1.37537 10.5815C0.640361 9.8646 1.04577 8.616 2.06192 8.46868L7.8628 7.62556C8.26628 7.56702 8.61507 7.31365 8.79547 6.948L11.3896 1.69064C11.8434 0.769787 13.1561 0.769787 13.6104 1.69064Z"
      fill={active ? 'none' : 'url(#paint11_linear)'}
      fillRule="nonzero"
      stroke={'url(#stroke_paint0_linear)'}
    />
    <defs>
      <linearGradient id="stroke_paint0_linear" x1="1" y1="11.7442" x2="24" y2="11.7442" gradientUnits="userSpaceOnUse">
        <stop stopColor="#30d5c8"/>
        <stop offset="1" stopColor="#3ad1c5"/>
      </linearGradient>
      <linearGradient id="paint11_linear" x1="-0.533334" y1="17.2609" x2="25.677" y2="16.5883" gradientUnits="userSpaceOnUse">
        <stop stopColor="#30d5c8"/>
        <stop offset="1" stopColor="#55d5ca"/>
        <stop offset="1" stopColor="#3ad1c5"/>
      </linearGradient>
    </defs>
  </svg>
);

export default Star;
