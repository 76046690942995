// Core
import React, { useState, useEffect } from 'react';
import { compose, graphql } from 'react-apollo';
import slugify from '@sindresorhus/slugify';
import { FormattedMessage, injectIntl } from 'react-intl';

// Components
import Card from 'components/Card';
import Text from 'components/Text';
import Stepper from 'components/Stepper';
import ActiveStep from 'routes/StudentsCabinet/components/StudentHomework/Steps/ActiveStep';

// Other
import { messages } from 'routes/StudentsCabinet/components/StudentHomework/messages';
import { isBlank } from 'utils/helpers';
import { SUBMITTED_PREVIOUS_HOMEWORK, REVIEWED_MARK_MORE_THAN_60 } from 'utils/enums/index';
import { withHomeworkUpload } from 'routes/StudentsCabinet/components/StudentHomework/HOCs/withHomeworkUpload';

// GraphQL
import UPDATE_HOMEWORK_STEP from 'mutations/StudentCabinet/updateHomeworkStep.gql';
import NOTIFY_ABOUT_HOMEWORK_STATUS_CHANGE from 'mutations/Homeworks/notifyAboutHomeworkStatusChange.gql';
import MY_PROFILE_QUERY from 'queries/UserProfile/getProfilePhoto.gql';

// Styles
import './StudentHomework.scss';

const getHomeworkDataByLectureId = (homeworksData, lectureId) =>
  homeworksData?.find(item => {
    if (item.lectureId === lectureId) {
      return true;
    }
    return false;
  });

const steps = [1, 2, 3, 4];

const StudentHomework = ({
  courseId,
  lectureId,
  purchasedCourseId,
  homework,
  homeworksData,
  isHomeworkAvailable,
  homeworksAccessSetting,
  profile,
  homeworkNumber,
  updateHomeworkStep,
  refetchStudentCourse,
  notifyAboutHomeworkStatusChange,
  handleUploadHomework,
  handleAttachmentDelete,
  intl: { formatMessage }
}) => {
  const homeworkData = getHomeworkDataByLectureId(homeworksData, lectureId);
  const isHomeworkTaskUploaded = homeworkData && homeworkData !== null;

  const [activeStep, setActiveStep] = useState(homeworkData?.step || 1);
  const [answerFile, setAnswerFile] = useState(null);
  const mark = homeworkData !== undefined && homeworkData?.review?.mark;

  const handleHomeworkAnswerUpload = async (files) => {
    const file = files[0];
    const isAnswerUploaded = !isBlank(homeworkData?.answer);
    const params = { file, studentId: profile?.id, purchasedCourseId, lectureId, courseId, refetchStudentCourse };
    if (isAnswerUploaded) params.id = homeworkData.answer.id;
    handleUploadHomework(params);
  };

  const handleUpdateHomeworkStep = async step => {
    try {
      await updateHomeworkStep({
        course_id: courseId,
        lecture_id: lectureId,
        step,
      });
      if (step === 3) {
        const input = {
          courseId,
          lectureId,
          prevStatus: 2,
          nextStatus: 3,
          studentId: profile.id,
          homeworkNumber,
        }
        await notifyAboutHomeworkStatusChange(input);
      }
    } catch (error) {
      throw Error(error);
    }
    setActiveStep(step);
  };

  const handleStepChange = step => () => {
    if ((activeStep === 1 && homeworkData?.step !== 2) || activeStep === 3 || !isHomeworkAvailable) return null;
    if (homeworkData?.review) {
      if (['2', '3'].includes(`${step}`)) {
        return null;
      }
      return setActiveStep(step);
    }

    if (homeworkData?.answer || (!homeworkData?.answer && !homeworkData?.review)) {
      if (step <= 2) {
        return setActiveStep(step);
      }
    }
    return null;
  };

  return (
    <div className="sk-student-hw">
      <div className="sk-student-hw__header">
        <Text classes="sk-subtitle">
          {formatMessage(messages.homeworks)}
        </Text>
      </div>
      <div className="sk-student-hw__main">
        <div className="sk-student-hw__stepper">
          <Stepper
            handleStepChange={e => handleStepChange(e)}
            steps={steps}
            activeStep={activeStep}
            disabled={!isHomeworkAvailable}
          />
        </div>
        <Card classes="sk-student-hw__step-block">
          <ActiveStep
            task={homework}
            homeworkData={homeworkData}
            disabled={!isHomeworkAvailable}
            mark={mark}
            activeStep={activeStep}
            lectureId={lectureId}
            courseId={courseId}
            studentId={profile.id}
            setActiveStep={setActiveStep}
            handleHomeworkAnswerUpload={handleHomeworkAnswerUpload}
            handleAttachmentDelete={handleAttachmentDelete}
            handleUpdateHomeworkStep={handleUpdateHomeworkStep}
          />
        </Card>
      </div>
      {(!isHomeworkAvailable && homeworksAccessSetting === SUBMITTED_PREVIOUS_HOMEWORK) && (
        <Text className='sk-student-hw__disable-homework-explanation'>{formatMessage(messages.disabledHomeworkExplanation1)}</Text>
      )}
      {(!isHomeworkAvailable && homeworksAccessSetting === REVIEWED_MARK_MORE_THAN_60) && (
        <Text className='sk-student-hw__disable-homework-explanation'>{formatMessage(messages.disabledHomeworkExplanation2)}</Text>
      )}
    </div>
  );
};

const mutateUpdateHomeworkStep = graphql(UPDATE_HOMEWORK_STEP, {
  props: ({ mutate }) => ({
    updateHomeworkStep: input => {
      mutate({ variables: { input } })
    },
  }),
  options: () => ({
    refetchQueries: ['listStudentCoursesExtra', 'getStudentCourse', 'listStudentCourses'],
  })
});

const mutateNotifyAboutHomeworkStatusChange = graphql(NOTIFY_ABOUT_HOMEWORK_STATUS_CHANGE, {
  props: ({ mutate }) => ({
    notifyAboutHomeworkStatusChange: input => mutate({ variables: { input } }),
  }),
});

const myProfileQuery = graphql(MY_PROFILE_QUERY, {
  props: ({ data: { getProfile: profile, error, loading, ...ownProps } }) => ({
    loading,
    profile,
    ...ownProps,
  }),
  options: () => ({
    fetchPolicy: 'cache-and-network',
  }),
});

const enhancer = compose(
  injectIntl,
  mutateUpdateHomeworkStep,
  mutateNotifyAboutHomeworkStatusChange,
  myProfileQuery,
  withHomeworkUpload
);


export default enhancer(StudentHomework);
