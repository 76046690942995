import React from 'react';
import { compose } from 'react-apollo';

// Components
import Loader from 'components/Loader';
import Play from 'components/Icon/Svg/Play';
import VideoModule from 'components/VideoModule';
import { withFileUrl } from 'containers/HOCs/withFileUrl';

const Preview = ({
  fileUrl,
  isLoaded,
  playButton,
  handleSetResume,
  videoStartTime,
  filetype,
}) => {
  if (!isLoaded) return <Loader />;

  return (
    <div className="sk-video-preview__view">
      <VideoModule
        playButton={playButton}
        fileUrl={fileUrl}
        onPauseHandler={handleSetResume}
        videoStartTime={videoStartTime || 0}
        filetype={filetype}
      />
    </div>
  );
};

const enhancer = compose(withFileUrl);

export default enhancer(Preview);
