// Core
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import MaterialRadioButton from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

// Components
import Tor from 'components/Icon/Svg/Tor';
import Text from 'components/Text';

// Styles and Assets
import './RadioButton.scss';

const getRadioButtonClasses = label => ({
  root: `mui-override sk-radio ${label && 'sk-radio-label'}`,
  checked: 'sk-radio__checked checked',
});

const getLabelClasses = (classAdditional = '') => ({
  root: `mui-override sk-radio ${classAdditional}`,
});

const getLabel = label => {
  if (label instanceof String) {
    return <Text>{label}</Text>;
  }
  return label;
};

const RadioButton = memo(
  ({
    value,
    label,
    handleChange,
    disabled,
    checked,
    name,
    classAdditional,
    iconProps,
  }) => (
    <FormControlLabel
      disabled={disabled}
      value={value}
      style={{ margin: 0, padding: 0 }}
      classes={getLabelClasses(classAdditional)}
      control={
        <MaterialRadioButton
          value={value}
          checked={checked}
          name={name}
          onChange={() => handleChange(value)}
          classes={getRadioButtonClasses(label)}
          icon={<Tor {...iconProps} disabled={disabled} />}
          checkedIcon={<Tor {...iconProps} checked disabled={disabled} />}
        />
      }
      label={getLabel(label)}
    />
  )
);

RadioButton.propTypes = {
  value: PropTypes.string,
  label: PropTypes.any,
  checked: PropTypes.bool,
  name: PropTypes.string,
};

export default RadioButton;
