import React, { memo } from 'react';
import { compose } from 'react-apollo';

const Icon = ({ ...props }) => {
  return (
    <svg width="30" height="14" viewBox="0 0 30 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M23.1409 0.0218223C22.9576 0.00970925 22.7742 0.00349201 22.636 0.00531433C22.5803 -0.000313409 16.7572 -0.375282 14.4258 6.37414C12.3879 12.2745 7.66697 12.0119 7.41737 11.9959C7.3242 11.9959 7.23047 11.9942 7.13669 11.9903C3.80798 11.8604 1.19998 9.46088 1.19998 6.52749C1.19998 3.51209 3.98903 1.05872 7.41737 1.05872C9.54256 1.05872 11.499 1.99877 12.6509 3.57341L13.6599 3.00238C12.2865 1.12438 9.95273 0.00322395 7.41737 0.00322395C3.32755 0.00327754 0 2.92997 0 6.52749C0 10.027 3.11128 12.8899 7.08282 13.0447C7.19355 13.0489 7.30427 13.0512 7.36405 13.0494C7.3699 13.0499 7.44022 13.0545 7.56331 13.0545C8.61034 13.0545 13.4877 12.7206 15.5742 6.68077C17.6121 0.780442 22.3342 1.04328 22.5826 1.05872C22.7403 1.05872 22.8973 1.06413 23.052 1.07421C26.2753 1.28549 28.8 3.68066 28.8 6.52749C28.8 9.54288 26.011 11.996 22.5826 11.996C20.4574 11.996 18.5015 11.056 17.3496 9.4813L16.3407 10.0523C17.7141 11.9303 20.0473 13.0515 22.5826 13.0515C26.6724 13.0515 30 10.1247 30 6.52754C30 3.13128 26.9871 0.27357 23.1409 0.0218223Z" fill="#30d5c8"/>
    </svg>
  );
};

const enhancer = compose(
  memo,
);

export default enhancer(Icon);
