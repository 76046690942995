import { PDF_FILE, TEXT_FILE } from 'utils/enums';

export const getArticleType = article => {
  if (!article) return null;

  const { filepath } = article;
  const dotBeforeExtIndex = filepath && filepath.lastIndexOf('.');
  if (dotBeforeExtIndex === -1) return;

  const nextToDotSymbolIndex = dotBeforeExtIndex + 1;
  const ext = filepath.slice(nextToDotSymbolIndex);
  const isTextFile = ext === TEXT_FILE;

  return isTextFile ? TEXT_FILE : PDF_FILE;
}
