// Core
import React from 'react';
import { Helmet } from 'react-helmet';

const { BASE_URL } = process.env;

const CourseListItemLinkedData = ({
  title,
  authorName,
  url,
  thumbnail,
  rating,
  position,
}) => {
  const canonicalUrl = `${BASE_URL}${url}`;
  const { averageScore, totalVotes } = rating;
  const structuredData = {
    '@type': 'ListItem',
    position,
    item: {
      '@type': 'Course',
      url: canonicalUrl,
      name: title,
      image: thumbnail,
      author: authorName,
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: averageScore,
        reviewCount: totalVotes,
      },
    },
  };

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
    </Helmet>
  );
};

export default CourseListItemLinkedData;
