// Core
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';
import { FormattedMessage, injectIntl } from 'react-intl';

// Components
import Heading from 'components/Heading';
import Text from 'components/Text';
import Button from 'components/Button';
import Link from 'components/Link';
import Decoration from 'routes/LandingPage/components/Decoration';
import InputSearch from 'routes/LandingPage/components/InputSearch';
import Suggestions from 'routes/LandingPage/components/Suggestions';

// Other
import { messages } from 'routes/LandingPage/components/LandingPageCTAText/messages';

// Styles and Assets
import './LandingPageCTAText.scss';

const getTeachOnlineLink = role => {
  if (role === 'expert') {
    return '/course-creation';
  }
  if (role === 'student') {
    return '/manage-account/personal-info';
  }
  return '/auth/signUp?role=expert';
};

const getBecomeStudentLink = role => {
  if (!role) {
    return '/auth/signUp?role=student';
  }
  return '/manage-account/personal-info';
};

const LandingPageCTAText = ({ role, intl: { formatMessage } }) => (
  <div className="sk-landing-page-cta-text">
    <div className="sk-landing-page-cta-text__header">
      <Heading variant="h1" classes="sk-landing-page-cta-text__heading">
        <FormattedMessage
          {...messages.skillsForFuture}
          values={{
            breakingLine: <br />,
          }}
        />
      </Heading>
    </div>
    <div className='sk-landing-page-cta-text__desc-block'>
      <Text classes='sk-landing-page-cta-text__desc'>
        <FormattedMessage
          {...messages.theWorldIs}
          values={{
            breakingLine: <br />,
          }}
        />
      </Text>
    </div>
    <div className="sk-landing-page-cta-text__action-block">
      <Suggestions buttonText={formatMessage(messages.buttonText)} placeholderText={formatMessage(messages.placeholderText)} />
      {/* <InputSearch buttonText={formatMessage(messages.buttonText)} placeholderText={formatMessage(messages.placeholderText)} /> */}
    </div>
  </div>
);

const enhancer = compose(injectIntl);

export default enhancer(LandingPageCTAText);
