// Core
import React from 'react';

// Components
import FlatDropDown from 'components/UserDropdown/FlatDropDown';
import UserMenu from 'components/UserMenu';
import AuthBlockMobile from './AuthBlockMobile';

// Other
import { withAuthenticatedUser } from 'containers/AuthenticatedUserProvider/withAuthenticatedUser';
import { PurpleLight } from 'components/Icon/color';
import { isBlank } from 'utils/helpers';

// Styles and assets
import './UserBlock.scss';

const UserBlock = ({
  userContext: { profile },
  user,
}) => {
  const defaultComponent = !isBlank(user) ? <UserMenu user={user} profile={profile} /> : <AuthBlockMobile />;

  return (
    <FlatDropDown
      withToggler={true}
      togglerProps={{
        color: PurpleLight,
      }}
      placement="bottom-end"
      component={()=>(defaultComponent)}
      disablePortal={true}
    />
  )
};

export default withAuthenticatedUser(UserBlock);
