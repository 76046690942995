// Core
import React, { memo } from 'react';
import PropTypes from 'prop-types';

// Components
import Text from 'components/Text';

// Styles and Assets
import './SubscriberWebinarContentHeader.scss';

export const SubscriberWebinarContentHeader = ({ title }) => (
  <div className="sk-sub-web-content-header">
    <div className="sk-sub-web-content-header__title">
      <Text>
        Course. <strong> {title}</strong>
      </Text>
    </div>
  </div>
);

SubscriberWebinarContentHeader.propTypes = {
  title: PropTypes.string,
};

export default memo(SubscriberWebinarContentHeader);
