// Core
import React, { memo } from 'react';
import PropTypes from 'prop-types';

// Components
import PhotoUploader from 'components/PhotoUploader';
import ContentHeader from 'containers/ContentHeader';

// Styles and Assets
import './CourseContentHeader.scss';

const CourseContentHeader = ({ handleFileChange, disabled, photo, id }) => (
  <ContentHeader
    actionBlock={
      <PhotoUploader
        disabled={disabled}
        supportedFormats=".jpg, .png"
        maxSize={5000000}
        photo={photo}
        uploaderId={`course-photo-${id}`}
        handleChange={handleFileChange}
      />
    }
  />
);

CourseContentHeader.propTypes = {
  photo: PropTypes.string,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  handleFileChange: PropTypes.func,
};

export default memo(CourseContentHeader);
