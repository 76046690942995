// Core
import React, { PureComponent } from "react";
import { compose, graphql } from "react-apollo";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import queryString from "query-string";
import * as R from "ramda";

// Components
import { withModal } from "containers/ModalProvider/withModal";
import CoursesList from "routes/Courses/CoursesList";
import ArrowToTop from "components/Icon/Svg/ArrowToTop";
import Filters from "routes/Courses/Filters";

// GraphQL
import LIST_COURSES from "queries/Courses/studentListCourses.gql";
import GET_STUDENT_COURSE_IDS_LIST from "queries/Courses/studentListCoursesIds.gql";

// Other
import { withAWS } from "containers/AWSProvider/withAWS";
import { hasLoader } from "containers/HOCs/hasLoader";
import { MAPPING_COURSES_LIST_LIMIT } from "utils/enums";
import { keysToCamel } from "utils/helpers";
import { withAuthenticatedUser } from "containers/AuthenticatedUserProvider/withAuthenticatedUser";

// Styles and Assets
import "./Courses.scss";

const selector = (qs) => ({
  category: qs?.category,
  subcategory: qs?.subcategory,
  query: qs?.query,
  complexity: qs?.complexity || undefined,
  interactivityLevel: qs?.interactivityLevel || undefined,
  language: qs?.language || undefined,
  durationRange: qs?.durationRange || undefined,
  page: qs?.page ? Number(qs?.page) : 0,
});

const parseSearch = (location) => {
  const qs = queryString.parse(location?.search);
  return selector(qs);
};

class Courses extends PureComponent {
  scrollIsEnabled = false;

  constructor(props) {
    super(props);
    this.state = {
      ...parseSearch(props.location),
      isArrowTopShow: false,
      styleArrowTop: false,
    };
  }

  componentDidMount() {
    this.scrollIsEnabled = true;
    window.addEventListener("scroll", this.handleScroll);
    this.scrollToFilters();

    // if (window.Intercom && window.intercomSettings && this.props.userContext) {
    //   const { userContext } = this.props;

    //   window.Intercom("boot", {
    //     app_id: window.intercomSettings.app_id,
    //     email: userContext?.profile?.email,
    //     user_id: userContext?.profile?.id,
    //     created_at: Date.now(),
    //   });
    // }
  }

  componentDidUpdate(prevProps) {
    const { location, match } = this.props;

    if (match?.params?.category) {
      this.setState({ category: match.params.category });
      if (match?.params?.subcategory) {
        this.setState({ subcategory: match.params.subcategory });
      }
    }

    const qs = parseSearch(location);
    if (!R.equals(qs, parseSearch(prevProps.location))) {
      this.setState(qs);
    }
  }

  componentWillUnmount() {
    this.scrollIsEnabled = false;
    window.removeEventListener("scroll", this.handleScroll);
  }

  scrollToFilters = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  handleFilterParamChange = (key) => (value) => {
    const { history } = this.props;
    const field = key === "searchString" ? "query" : key;
    const newFilter = { ...this.state, [field]: value, page: 0 };
    if (field === "category") newFilter.subcategory = undefined;
    history.replace(`/courses?${queryString.stringify(selector(newFilter))}`);
  };

  nextPage = (page) => {
    const { history } = this.props;
    const newFilter = { ...this.state, page };
    history.replace(`/courses?${queryString.stringify(selector(newFilter))}`);
  };

  handleScroll = (e) => {
    if (this.scrollIsEnabled) {
      const scroll = (e?.target || window).scrollY;
      const windowHeight = (e?.target || window).innerHeight;
      const elementHeight = document.getElementById("scroll-coursesList")
        ?.clientHeight;

      if (scroll > 100 && scroll < 400 && elementHeight > 651) {
        this.setState({ isArrowTopShow: true });
      } else if (scroll <= 100) {
        this.setState({ isArrowTopShow: false });
      } else if (scroll + windowHeight > 900 && elementHeight > 651) {
        this.setState({ styleArrowTop: true });
      } else {
        this.setState({ styleArrowTop: false });
      }
    }
  };

  getAlreadyBoughtedCourses = () => {
    const { studentListCourses } = this.props;
    return (
      studentListCourses?.purchasedCourses?.map(
        (mapping) => mapping.courseId
      ) || null
    );
  };

  render() {
    const { userContext } = this.props;
    const {
      category,
      subcategory,
      query: searchString,
      complexity,
      interactivityLevel,
      language,
      durationRange,
      isArrowTopShow,
      styleArrowTop,
      page,
    } = this.state;

    const searchParams = {
      category,
      subcategory,
      complexity,
      interactivityLevel,
      language,
      durationRange,
      searchString,
    };

    const org =
      userContext?.isUserAuthorized && userContext?.profile?.org
        ? userContext.profile.org
        : null;

    return (
      <div className="sk-courses" onScroll={this.handleScroll}>
        <Filters
          {...searchParams}
          className="sk-courses__filters"
          handleFilterParamChange={this.handleFilterParamChange}
          searchString={searchString}
        />
        <CoursesList
          alreadyBoughtedCourses={this.getAlreadyBoughtedCourses()}
          searchString={searchString}
          page={page}
          org={org}
          nextPage={this.nextPage}
          {...searchParams}
        />
        <div className="sk-courses__arrow">
          <button
            className={classNames(
              { "sk-courses__arrow-is-show": !isArrowTopShow },
              { " _fixed": styleArrowTop },
              { "sk-courses__arrow-to-top": isArrowTopShow }
            )}
            onClick={this.scrollToFilters}
            type="button"
            tabIndex="0"
            onKeyPress={this.scrollToFilters}
          >
            <ArrowToTop />
          </button>
        </div>
      </div>
    );
  }
}

const studentListCoursesQuery = graphql(LIST_COURSES, {
  skip: ({ userContext }) => !userContext?.isUserAuthorized,
  props: ({ data }) => ({
    studentListCourses: keysToCamel(data.listStudentCoursesExtra),
    query: data.variables?.input?.query || {},
    refetchCourses: data.refetch,
    ...data,
  }),
  options: () => ({
    variables: {
      input: { query: { filter: [{ key: "type", values: ["course"] }] } },
    },
  }),
});

const enhancer = compose(
  withModal,
  withAWS,
  withAuthenticatedUser,
  withRouter,
  studentListCoursesQuery
);

export default hasLoader(enhancer(Courses), GET_STUDENT_COURSE_IDS_LIST);
