import React, { useState, useEffect, useRef } from 'react';
import { Editor, EditorState, RichUtils } from 'draft-js';
import classnames from 'classnames';

// Components
import {
  RecourseIconButton,
  StyleIconButton,
} from 'components/DraftEditor/ControlsBar/Buttons';

// Icons
import TextBold from 'components/Icon/SvgCss/Editor/TextBold';
import TextItalic from 'components/Icon/SvgCss/Editor/TextItalic';
import TextColor from 'components/Icon/SvgCss/Editor/TextColor';
import OrangeText from 'components/Icon/SvgCss/Editor/OrangeText';
import BlackText from 'components/Icon/SvgCss/Editor/BlackText';
import TextCross from 'components/Icon/SvgCss/Editor/TextCross';
import TextUnderline from 'components/Icon/SvgCss/Editor/TextUnderline';
import Code from 'components/Icon/SvgCss/Editor/Code';

import { COLOR } from 'components/DraftEditor/enums.js';

// Styles
import './TextControls.scss';

const INLINE_STYLES = [
  { label: 'Bold', style: 'BOLD', icon: TextBold },
  { label: 'Italic', style: 'ITALIC', icon: TextItalic },
  { label: 'Underline', style: 'UNDERLINE', icon: TextUnderline },
  { label: 'Cross', style: 'CROSS', icon: TextCross },
  { label: 'Monospace', style: 'CODE', icon: Code },
];

const TextControls = ({ editorState, onToggle, addResource }) => {
  const currentStyle = editorState.getCurrentInlineStyle();
  const contentState = editorState.getCurrentContent();
  const selection = editorState.getSelection();

  const startKey = editorState.getSelection().getStartKey();
  const startOffset = editorState.getSelection().getStartOffset();
  const blockWithLink = contentState.getBlockForKey(startKey);

  const entityKey = blockWithLink.getEntityAt(startOffset);
  let TextColorIcon = OrangeText;

  if (entityKey) {
    const entity = contentState.getEntity(entityKey);
    const isTextHasColor = entity?.getType() === COLOR;
    const entityData = entity?.data;

    if (entity && isTextHasColor && entityData.textColor === '#30d5c8') {
      TextColorIcon = BlackText;
    }
  }

  return (
    <div className="sk-editor-toolbar-controls-inline">
      {INLINE_STYLES.map(type => (
        <StyleIconButton
          icon={type.icon}
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggleHandler={onToggle}
          style={type.style}
        />
      ))}
      <RecourseIconButton
        icon={TextColorIcon}
        type={COLOR}
        noBlurOnClick={true}
        onClickHandler={event => addResource({ type: COLOR, event })}
      />
    </div>
  );
};

export default TextControls;
