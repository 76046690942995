// Core
import React, { PureComponent } from 'react';

// Components
import FilterDropDown from 'components/FilterDropDown';

// Other
import { COURSE_DURATION_RANGES } from 'utils/enums';
import { FormattedMessage } from 'react-intl';
import { messages } from 'components/DurationRangeSelect/messages';

const options = COURSE_DURATION_RANGES.map(({ value }) => ({
  value,
  label: <FormattedMessage {...messages[value]} />,
}));

class DurationRangeSelect extends PureComponent {
  render() {
    const { ...props } = this.props;

    return (
      <FormattedMessage {...messages.anyDuration}>
        {msg => (
          <FilterDropDown
            minor
            options={options}
            placeholder={msg}
            valueKey="value"
            labelKey="label"
            isPlaceholderOptionHidden={true}
            {...props}
          />
        )}
      </FormattedMessage>
    );
  }
}

export default DurationRangeSelect;
