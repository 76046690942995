// Core
import React, { useState, useEffect, useCallback } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useFormik } from 'formik';

// GraphQL
import UPDATE_SUBSCRIPTION_PLAN from 'queries/AdminPanel/updateSubscriptionPlan.gql';

// Other
import { mappingAlgorithm } from 'routes/AdminPanel/PlanEdit/helpers.js';
import { MAPPING_INPUT, MAPPING_OUTPUT } from 'routes/AdminPanel/PlanEdit/utils.js';
import { keysToSnake, keysToCamel, isBlank } from  'utils/helpers/index';
import { getYupSchema, } from 'services/yup';

const VALIDATION_SCHEMAS = {
  package: 'packagePlanEditValidationSchema',
  constructor: 'constructorPlanEditValidationSchema',
};

export function usePlanForm({
  plan,
  ...props
}) {
  const [handlePlanUpdate, response] = useMutation(UPDATE_SUBSCRIPTION_PLAN, { options: { fetchPolicy: 'no-cache', refetchQueries: ['getSubscriptionPlan'], } });

  const { values, errors, touched, setFieldValue, validateForm, handleBlur} = useFormik({
    initialValues: mappingAlgorithm({ mapping: MAPPING_INPUT, initValues: plan}),
    validationSchema: getYupSchema(VALIDATION_SCHEMAS[plan?.type]),
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true
  });

  const handleInputChange = useCallback(name => (inputValue, e) => {
    setFieldValue(name, inputValue);
    if (e) e.persist();
  }, [setFieldValue]);

  const handleInputBlur = useCallback(name => async (inputValue, e) => {
    if (e) {
      e.persist();
      handleBlur(e);
    };

    const errors = await validateForm(values);
    const hasError = errors[name];

    if (!hasError) {
      let input = MAPPING_OUTPUT[name];

      if (typeof input == 'string') {
        input = { [input]: values[name] };
      } else if (typeof input == 'function') {
        input = input(plan, inputValue);
      };

      try {
        if (!isBlank(input) && !isBlank(plan?.id)) {
          handlePlanUpdate({ variables: { input: { id: plan?.id, ...keysToSnake(input) } }});
        };
      } catch(err) {
        throw err;
      };
    };
  }, [plan, errors, values, handleBlur, handlePlanUpdate]);

  return  {
    formikBag: {
      values,
      errors,
      touched,
      handleInputChange,
      handleInputBlur,
    },
    analytics: { subscribersCount: plan?.subscribersCount }
  };
}
