import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  min: {
    id: 'app.components.PCDetails.min',
    defaultMessage: 'min',
  },
  readMore: {
    id: 'app.components.PCDetails.readMore',
    defaultMessage: 'read more',
  },
  description: {
    id: 'app.components.PCDetails.description',
    defaultMessage: 'Description',
  },
  resume: {
    id: 'app.components.PCDetails.resume',
    defaultMessage: 'Resume',
  },
  studyMats: {
    id: 'app.components.PCDetails.studyMats',
    defaultMessage: 'Study Materials',
  },
});
