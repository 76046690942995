import React, { memo } from 'react';
import { compose } from 'react-apollo';

const Icon = ({ ...props }) => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.41211 19.2621V4.34627C4.41211 3.56579 5.03649 2.94141 5.81697 2.94141H14.6277C15.044 2.94141 15.4255 3.11485 15.703 3.44438L19.6401 8.04054C19.8483 8.3007 19.9697 8.61289 19.9697 8.95977V19.2968C19.9697 20.7363 18.8076 21.8984 17.3681 21.8984L6.99636 21.8637C5.57416 21.8464 4.41211 20.6843 4.41211 19.2621Z" stroke="#610FB6" stroke-miterlimit="10"/>
    <path d="M15.043 3.09717V7.72801C15.043 8.14427 15.3725 8.4738 15.7888 8.4738L19.8126 8.50849" stroke="#610FB6" stroke-miterlimit="10"/>
    <path d="M8.82324 13.0089L11.6612 16.1764L16.1762 11.0293" stroke="#610FB6" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M0 0V-1H-1V0H0ZM25 0H26V-1H25V0ZM25 25V26H26V25H25ZM0 25H-1V26H0V25ZM0 1H25V-1H0V1ZM24 0V25H26V0H24ZM25 24H0V26H25V24ZM1 25V0H-1V25H1Z" fill="white"/>
    </svg>
    
  );
};

const enhancer = compose(
  memo,
);

export default enhancer(Icon);
