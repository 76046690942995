// Core
import React, { useState, useEffect, useCallback } from 'react';
import { graphql, compose } from 'react-apollo';
import { injectIntl, FormattedMessage } from 'react-intl';

// Components
import Card from 'components/Card';
import Button from 'components/Button';
import Text from 'components/Text';
import TextInput from 'components/Input/Material/TextInput';
import TextAreaInput from 'components/Input/Material/TextAreaInput';
import RadioButtonGroup from 'components/RadioButtonGroup';
import PricesConversionsModal from 'routes/ExpertsCabinet/ToppingEdit/ToppingDetails/PricesConversionsModal';
import LanguageSelect from 'routes/Course/components/LanguageSelect';
import ToppingMaterial from 'routes/ExpertsCabinet/ToppingEdit/ToppingDetails/ToppingMaterial';

// Other
import { withSharedState } from 'containers/SharedStateProvider/withSharedState';
import { isNull } from 'utils/helpers/index';
import { messages } from 'routes/ExpertsCabinet/ToppingEdit/messages';
import { sharedMessages } from 'services/i18n/sharedMessages/messages';
import { hasVariant, getVariant } from 'routes/ExpertsCabinet/CourseEdit/Details/CourseInformation/helpers.js';
import { withModal } from 'containers/ModalProvider/withModal';
import { DEFAULT_VARIANTS, DEFAULT_VARIANT_NAME } from 'routes/ExpertsCabinet/ToppingEdit/enums';
import { COURSE_PREVIEW, LECTURE_QUIZ, LECTURE_VIDEO, } from 'containers/UploadManagerProvider/helpers';
import { withLectureFilesUploader } from 'routes/Course/components/LecturesList/LectureItem/HOCs/withLectureFilesUploader';
import withWidth from 'containers/HOCs/withWidth';

// Styles
import './index.scss';

const prefix = 'sk-topping-edit-details';
const ToppingDetails = ({
  width,
  disabledEditing,
  topping,
  formikBag,
  handleToppingUpdate,
  handleUploadVideo,
  handleUploadPreview,
  handleUploadQuiz,
  handleAttachmentDelete,
  intl: { formatMessage },
  modalContext: { showModal, hideModal },
  sharedStateContext: { updateSharedState },
}) => {

  useEffect(() => {
    const { values, errors, touched } = formikBag;
    updateSharedState({ toppingInformation: { values, errors, touched, handleUpdateVariant }});
  }, [formikBag.values, formikBag.errors, formikBag.touched]);

  const handleInputBlur = useCallback((type) => () => {
    handleToppingUpdate({ [type]: formikBag.values[type] });
  }, [formikBag.values]);
  const handleUpdateVariant = useCallback(() => {
    const newVariants = [...topping.variants];
    const hasToppingVariant = newVariants.length > 0;

    if (hasToppingVariant) {
      const toppingVariant = newVariants[0];
      toppingVariant.price = Number(formikBag.values.price);
    } else {
      const newVariant = getVariant({ variants: DEFAULT_VARIANTS, variantName: DEFAULT_VARIANT_NAME });
      newVariant.price = Number(formikBag.values.price);
      newVariants.push(newVariant);
    }
    handleToppingUpdate({ price: newVariants });
  }, [formikBag.values.price, topping.variants, handleToppingUpdate]);

  const showConvertedVariants = useCallback(() => {
    showModal(PricesConversionsModal, {
      size: 'sm',
      hideModal,
      disabled: disabledEditing,
      handlePriceChange: formikBag.handleChange('price'),
    })
  }, [hideModal, disabledEditing, formikBag.handleChange, handleUpdateVariant]);

  const buttonSize = width == 'xs' ? 'small' : 'large';

  return (
    <Card>
      <div className={prefix}>
        <TextInput
          disabled={disabledEditing}
          value={formikBag.values.title}
          name="title"
          label={formatMessage(messages.toppingTitleLabel)}
          helperText={formikBag.errors.title || ''}
          error={formikBag.touched.title && Boolean(formikBag.errors.title)}
          onChange={formikBag.handleChange('title')}
          onBlur={handleInputBlur('title')}
        />
        <TextAreaInput
          disabled={disabledEditing}
          value={formikBag.values.description}
          name="description"
          label={formatMessage(messages.toppingDescriptionLabel)}
          helperText={formikBag.errors.description || ''}
          error={formikBag.touched.description && Boolean(formikBag.errors.description)}
          onChange={formikBag.handleChange('description')}
          onBlur={handleInputBlur('description')}
        />
        <div className={`${prefix}__language`}>
          <LanguageSelect
            disabled={disabledEditing}
            helperText={formikBag.errors.language || ''}
            error={formikBag.touched.language && Boolean(formikBag.errors.language)}
            name="language"
            label={formatMessage(messages.toppingLanguageLabel)}
            value={formikBag.values.language}
            handleChange={formikBag.handleChange('language')}
            handleBlur={handleInputBlur('language')}
          />
        </div>
        <div className={`${prefix}__variant`}>
          <TextInput
            margin="none"
            shrink
            adornment="$"
            position="start"
            // type="number"
            name='price'
            value={(formikBag.values.price || formikBag.values.price == 0) ? `${formikBag.values.price}` : ''}
            onChange={formikBag.handleChange('price')}
            onBlur={handleUpdateVariant}
            label='Price'
            label={formatMessage(messages.toppingPriceLabel)}
            error={formikBag.touched.price && Boolean(formikBag.errors.price)}
            disabled={disabledEditing}
            helperText={formikBag.errors.price || ''}
          />
          <Button
            color="primary"
            variant="outlined"
            size={buttonSize}
            disabled={disabledEditing}
            onClick={showConvertedVariants}
          >
            {formatMessage(messages.showConvertedVariantsButton)}
          </Button>
        </div>
        <ToppingMaterial
          disabledEditing={disabledEditing}
          type={COURSE_PREVIEW}
          topping={topping}
          uploadFile={handleUploadPreview}
          handleAttachmentDelete={handleAttachmentDelete}
          buttonSize={buttonSize}
          error={formikBag?.touched?.coursePreview && formikBag?.errors?.coursePreview}
        />
        <ToppingMaterial
          disabledEditing={disabledEditing}
          type={LECTURE_VIDEO}
          topping={topping}
          uploadFile={handleUploadVideo}
          handleAttachmentDelete={handleAttachmentDelete}
          buttonSize={buttonSize}
          error={formikBag?.touched?.lectureVideo && formikBag?.errors?.lectureVideo}
        />
        <ToppingMaterial
          disabledEditing={disabledEditing}
          type={LECTURE_QUIZ}
          topping={topping}
          uploadFile={handleUploadQuiz}
          handleAttachmentDelete={handleAttachmentDelete}
          buttonSize={buttonSize}
          error={formikBag?.touched?.lectureQuiz && formikBag?.errors?.lectureQuiz}
        />
      </div>
    </Card>
  );
};

const enhancer = compose(
  injectIntl,
  withModal,
  withLectureFilesUploader,
  withSharedState,
  withWidth()
);

export default enhancer(ToppingDetails);
