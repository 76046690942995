// Core
import React from 'react';
import PropTypes from 'prop-types';
import MaterialButton from '@material-ui/core/Button';
import classNames from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress'

// Styles
import './index.scss';

const sbForceHoverClass = 'sbForceHover';
const sbForceActiveClass = 'sbForceActive';

const Spinner = props => (
  <CircularProgress
    className='button-sk-spinner'
    size={20}
  />
)

const getClasses = ({
  sbForceHover,
  sbForceActive,
  dark,
  transparent,
  sidePadFree,
  loading,
}) => ({
  root: `mui-override ${
    dark ? 'button-sk button-sk__dark' : 'button-sk'
  } ${classNames({ sidePadFree, 'button-sk__loading': loading })}`,
  outlinedPrimary: `button-sk__outlined button-sk__primary  ${sbForceHover &&
    sbForceHoverClass} ${sbForceActive && sbForceActiveClass}`,
  outlinedSecondary: classNames(
    'button-sk__outlined',
    'button-sk__secondary',
    { sbForceHover },
    { sbForceActive },
    { 'button-sk__transparent': transparent }
  ),
  textPrimary: `button-sk__primary ${sbForceHover &&
    sbForceHoverClass} ${sbForceActive && sbForceActiveClass}`,
  textSecondary: `button-sk__secondary ${sbForceHover &&
    sbForceHoverClass} ${sbForceActive && sbForceActiveClass}`,
  sizeSmall: 'button-sk__sm',
  sizeLarge: 'button-sk__lg',
  disabled: 'button-sk__disabled',
  containedPrimary: 'button-sk__primary',
  containedSecondary: 'button-sk__secondary',
  fullWidth: 'mui-overrride button-sk__fullWidth',
});

const Button = ({
  children,
  variant,
  disabled,
  size,
  primary,
  sbForceHover,
  sbForceActive,
  dark,
  transparent,
  sidePadFree,
  loading,
  ...rest
}) => (
  <MaterialButton
    classes={getClasses({
      sbForceHover,
      sbForceActive,
      dark,
      transparent,
      sidePadFree,
      loading,
    })}
    variant={disabled ? 'text' : variant}
    color={primary ? 'primary' : 'secondary'}
    size={size}
    disabled={disabled}
    {...rest}
  >
    {children}
    {loading && <Spinner />}
  </MaterialButton>
);

Button.defaultProps = {
  primary: true,
  size: 'medium',
  disabled: false,
  fullWidth: false,
  variant: 'outlined',
  sbForceHover: false,
  sbForceActive: false,
  sidePadFree: false,
};

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  disabled: PropTypes.bool,
  primary: PropTypes.bool,
  fullWidth: PropTypes.bool,
  sbForceHover: PropTypes.bool,
  sbForceActive: PropTypes.bool,
  size: PropTypes.string,
  variant: PropTypes.string,
  sidePadFree: PropTypes.bool,
};

export default Button;
