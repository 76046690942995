import React from 'react';

const Icon = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="3.44118" y="4.17645" width="18.1176" height="15.1765" rx="3.5" stroke="#A9B6C8"/>
      <path d="M11.1744 8.18566L15.7888 11.2139C15.9555 11.3581 15.9555 11.5744 15.7888 11.7186L11.3412 14.6026C11.091 14.8189 10.6462 14.6747 10.6184 14.3622L10.4517 8.45002C10.4517 8.13759 10.8964 7.96936 11.1744 8.18566Z" stroke="#A9B6C8" strokeMiterlimit="10"/>
    </svg>
  );
};

export default Icon;
