import React from 'react';
import PropTypes from 'prop-types';
import { DarkGrey } from 'components/Icon/color';

const SheetCrossed = ({ height, width, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 20 25"
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M17.835 20.346V8.653l-5.948-5.474H5.135c-1.612 0-2.93 1.154-2.93 2.564V16.58" />
      <path
        d="M2.204 15.337v4.938c0 1.41 1.32 2.564 2.931 2.564h9.769c1.612 0 2.93-1.154
       2.93-2.564M11.973 3.322v4.392c0 .446.413.807.922.807h4.782l-5.704-5.199z"
      />
      <g>
        <path d="M6.8 11l6.4 6.4M13.2 11l-6.4 6.4" />
      </g>
    </g>
  </svg>
);

SheetCrossed.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  color: PropTypes.string,
};

SheetCrossed.defaultProps = {
  height: '25',
  width: '20',
  color: DarkGrey,
};

export default SheetCrossed;
