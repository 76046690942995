import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { PurpleHeart, SidebarDefault } from 'components/Icon/color';

const getColor = (active, activeColor) =>
  active ? activeColor : SidebarDefault;

const ChevronUp = memo(
  ({ height, width, active, iconStyle, color, activeColor, strokeWidth }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 11.5 8"
      width={width}
      height={height}
      style={iconStyle}
    >
      <path
        fill="none"
        fillRule="nonzero"
        stroke={color || getColor(active, activeColor)}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M.72 7.058l5.05-4.813 5.048 4.813"
      />
    </svg>
  )
);

ChevronUp.propTypes = {
  active: PropTypes.bool,
  height: PropTypes.string,
  width: PropTypes.string,
  activeColor: PropTypes.string,
  color: PropTypes.string,
  iconStyle: PropTypes.object,
  strokeWidth: PropTypes.number,
};

ChevronUp.defaultProps = {
  active: false,
  height: '8',
  width: '11.5',
  activeColor: PurpleHeart,
  strokeWidth: 1.5,
};

export default ChevronUp;
