import mapR from 'ramda/src/map';

export const getURLofLecture = (mappingId, id, list, interactionLevel) => {
  const lectureId = getLectureID(list, id);
  const type = getDataOfLecture(lectureId, list, id, 'type');
  const data = getLectureDataObject(lectureId, list, id);

  let position = 0;
  if (data.position) {
    position = data.position;
  }
  const itemId = getDataOfLecture(lectureId, list, id, 'id');
  if (type === 'not_found') {
    return {
      pathname: `/student-cabinet/courses/${mappingId}/materials`
    };
  }
  if (itemId) {
    return `/student-cabinet/courses/${mappingId}/lecture/${lectureId}/${type}/${itemId}?position=${position}`;
  }
  return `/student-cabinet/courses/${mappingId}/materials`;
};

const getDataOfLecture = (lectureId, list, id, data) => {
  const timeStemp = getBigestTimeStemp(list, id);
  const course = list.find((item) => {
    if (item.courseId === id) {
      return true;
    }
    return false;
  });
  let type = 'not_found';
  course.viewData.map((item) => {
    if (lectureId === item.lectureId) {
      item.views.map((item2) => {
        if (item2.viewedOn === timeStemp) {
          type = item2[data];
        }
        return null;
      });
    }
    return null;
  });
  return type.replace('s', '');
};

const getLectureDataObject = (lectureId, list, id) => {
  const timeStemp = getBigestTimeStemp(list, id);
  const course = list.find((item) => {
    if (item.courseId === id) {
      return true;
    }
    return false;
  });
  let data = {};
  course.viewData.map((item) => {
    if (lectureId === item.lectureId) {
      item.views.map((item2) => {
        if (item2.viewedOn === timeStemp) {
          if (item2.position) {
            data = item2;
          }
        }
      });
    }
  });
  return data;
};

const getLectureID = (list, id) => {
  const timeStemp = getBigestTimeStemp(list, id);
  let lecture = '001';
  const getLectureId = (item2, item3) => {
    if (timeStemp === item3.viewedOn) {
      lecture = item2.lectureId;
    }
  };
  mapR(
    (item) => mapR((item2) => mapR((item3) => getLectureId(item2, item3), item2.views), item.viewData),
    list
  );
  return lecture;
};

const getBigestTimeStemp = (list, id) => {
  let bigestTimeStemp = 0;
  const getTimeStemp = (item) => {
    if (item.viewedOn > bigestTimeStemp) {
      bigestTimeStemp = item.viewedOn;
    }
  };
  const funcMapR = (item) => {
    if (item.courseId === id) {
      mapR((item2) => mapR((item3) => getTimeStemp(item3), item2.views), item.viewData);
    }
  };
  mapR((item) => funcMapR(item), list);
  return bigestTimeStemp;
};
