// Core
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose, graphql } from 'react-apollo';

// Components
import Button from 'components/Button';
import LecturesList from 'containers/Sidebar/SidebarContent/StudentCabinetSidebar/LecturesList';
import SidebarMenuItem from 'containers/Sidebar/SidebarContent/SidebarMenu/SidebarMenuItem';
import MenuNoteBook from 'components/Icon/Svg/MenuNoteBook';

// GraphQL
import GET_COURSE from 'queries/Courses/getStudentCourse.gql';
import GET_STUDENT_COURSE from 'queries/Courses/getStudentCourse.gql';

// Other
import { parseFetchedData } from 'utils/helpers';
import { renderTrimmedText } from 'utils/helpers';
import { FormattedMessage } from 'react-intl';
import { messages } from 'containers/Sidebar/SidebarContent/StudentCabinetSidebar/LectureMaterialsContent/messages';

// Styling
import './LectureMaterialsContent.scss';

export const LectureMaterialsContent = ({
  purchasedCourse,
  course,
  handleBackClick,
  ...rest
}) => {
  if (purchasedCourse === null) {
    return null;
  }
  return (
    <div className="sk-lec-mats-content">
      <header className="sk-lec-mats-content__header">
        <SidebarMenuItem icon={MenuNoteBook} label={renderTrimmedText(course?.title, 45)} />
      </header>
      <div className="sk-lec-mats-content__main">
        <LecturesList list={course?.lectures} displayLectureNameType={course?.displayLectureNameType} {...rest} />
      </div>
      <div className="sk-lec-mats-content__footer">
        <Button
          variant="outlined"
          color="secondary"
          size="large"
          type="button"
          transparent
          onClick={handleBackClick}
        >
          <FormattedMessage {...messages.backToCourse} />
        </Button>
      </div>
    </div>
  );
};

const getStudentCourseQuery = graphql(GET_STUDENT_COURSE, {
  props: ({ data: { getStudentCourse, loading, error, ...ownProps } }) => {
    if (error) throw new Error(error);
    if (loading) return { loading, error };

    return {
      loading,
      course: (getStudentCourse !== null) ? parseFetchedData(getStudentCourse.course) : null,
      purchasedCourse: getStudentCourse,
      ...ownProps,
    }
  },
  options: ({ courseId }) => ({
    variables: { id: courseId },
  }),

});


// const getCourseQuery = graphql(GET_COURSE, {
//   props: ({ data: { purchasedCourse, error, loading, ...ownProps } }) => ({
//     loading,
//     purchasedCourse,
//     coruse: parseFetchedData(purchasedCourse.course),
//     ...ownProps,
//   }),
//   options: ({ courseId }) => ({
//     variables: { input: { id: courseId } },
//     fetchPolicy: 'cache-first',
//   }),
// });

const enhancer = compose(
  memo,
  getStudentCourseQuery
);

export default enhancer(LectureMaterialsContent);
