// Core
import React, { useCallback, useEffect, useState } from "react";
import { Formik } from "formik";
import { graphql, compose } from "react-apollo";
import { FormattedMessage, injectIntl } from "react-intl";

// Components
import Markdown from "react-markdown";
import Button from "components/Button";
import TextInput from "components/Input/Material/TextInput";
import Loader from "components/Loader";
import CloseButton from "components/Modal/CloseButton";
import CloseModalWarning from "routes/Course/components/FileEditModals/CloseModalWarning";
import Heading from "components/Heading";
import SuggestionsModal from "components/SuggestionsModal";

// GraphQL
import GET_FILE_URL_MUTATION from "mutations/Courses/getFileUrl.gql";
import SUGGESTIONS_MUTATION from "mutations/LLM/suggestions.gql";

// Other
import { withLayerModal } from "containers/LayerModalProvider/withLayerModal";
import { withModal } from "containers/ModalProvider/withModal";
import { getYupSchema } from "services/yup";
import { parseEntityDuration, isBlank } from "utils/helpers";
import { EDITOR_FILE } from "utils/enums/index";
import { sharedMessages } from "services/i18n/sharedMessages/messages";
import { messages } from "routes/Course/components/ComplexitySelect/messages";

// Styles and Assets
import "./TextFileEditModal.scss";

let DraftEditor = () => <></>;
let editorText = null;

const TextFileEditModal = ({
  modalId,
  entity,
  lectureId,
  courseId,
  editEntity,
  handleUploadText,
  handleModalClose,
  getFileUrl,
  intl: { formatMessage },
  durationUnits = "min",
  layerModalContext: { showModal, hideModal },
  getSuggestions,
  modalContext,
}) => {
  const [initText, setInitText] = useState("init");
  const [isInternalModuleLoaded, setInternalLoadingStatus] = useState(false);
  const [suggestions, setSuggestions] = useState("");
  const [isDraftEditorMoudleLoaded, setDraftEditorModuleState] = useState(
    false
  );
  const [text, setText] = useState("Loading ...");
  const [isTextReady, setTextLoadingStatus] = useState(false);
  const filepath = entity === null ? null : entity?.filepath;

  useEffect(() => {
    const fetchFileLink = async () => {
      if (!window.isServer) {
        const Module = await import("components/DraftEditor/Editor");
        DraftEditor = Module?.default;
        setDraftEditorModuleState(true);
      }
    };
    fetchFileLink();
    return () => {
      editorText = null;
    };
  }, []);

  useEffect(() => {
    const fetchFileLink = async () => {
      if (filepath === null) {
        setText("");
        setTextLoadingStatus(true);
      } else {
        const fileUrl = await getFileUrl({ fileKey: filepath });
        const file = await fetch(fileUrl?.data?.getPresignedFile);
        const text = await file.text();
        if (text) {
          setText(text);
          setInitText(text);
          setTextLoadingStatus(true);
        }
      }
    };
    fetchFileLink();
  }, [filepath]);

  const createBlobFromText = (text, title) => {
    const blob = new Blob([text], { type: "text/plain;charset=utf-8" });

    blob.lastModifiedDate = new Date();
    blob.name = `${title}.txt`;
    return blob;
  };

  const handleSuggestionClose = useCallback(() => {
    const { hideModal } = modalContext;
    hideModal();
  }, [modalContext]);

  const handleShowSuggestionsModal = useCallback(
    (suggestions) => {
      try {
        modalContext.showModal(SuggestionsModal, {
          handleSuggestionClose,
          suggestions,
        });
      } catch (error) {
        console.error(error);
      }
    },
    [handleSuggestionClose, modalContext]
  );

  const handleSubmit = async ({ duration, title, text }) => {
    const file = text !== initText && createBlobFromText(text, "text-file");
    const isNewFileAdded = file && file?.name;
    const isTextFileUploadedInS3 = !!entity?.id;

    const durationInSeconds = parseEntityDuration(
      duration,
      "minutes",
      "asSeconds"
    );
    const params = {
      file,
      filetype: EDITOR_FILE,
      duration: durationInSeconds,
      title,
      lectureId,
      courseId,
    };

    if (isTextFileUploadedInS3) {
      params.id = entity?.id;
      if (!isNewFileAdded) delete params.file;
    }

    const needToUpdateDetails = !isNewFileAdded && isTextFileUploadedInS3;
    const addOrUpdateTextFile = isNewFileAdded;

    if (addOrUpdateTextFile) {
      await handleUploadText(params);
    } else if (needToUpdateDetails) {
      await editEntity({
        id: entity?.id,
        title,
        duration: durationInSeconds,
      });
    }
    hideModal(modalId);
  };

  const handleClose = () => {
    if (typeof editorText === "string" && editorText.length > 0) {
      showModal(CloseModalWarning);
    } else {
      hideModal(modalId);
    }
  };

  const handleAIButtonClick = async (inputText) => {
    setInternalLoadingStatus(true);
    try {
      const { data } = await getSuggestions({
        type: "text",
        prompt: JSON.stringify(inputText),
      });
      if (data?.suggestions) {
        const updatedText = data.suggestions;
        handleShowSuggestionsModal(updatedText);
      }
      setInternalLoadingStatus(false);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
      setInternalLoadingStatus(false);
    }
  };
  let formFields = { title: "", duration: null, text };
  if (entity) {
    const { title, duration } = entity;
    formFields = {
      ...formFields,
      title,
      duration: parseEntityDuration(duration, "seconds", "asMinutes"),
    };
  }

  if (!isTextReady || !isDraftEditorMoudleLoaded) return <Loader />;

  return (
    <>
      {isInternalModuleLoaded && (<Loader center fullScreen overlay />)}
      <CloseButton handleClick={handleClose} />
      <div className="sk-lec-article-modal">
        <Formik
          enableReinitialize
          initialValues={formFields}
          validateOnChange={false}
          validateOnBlur
          onSubmit={(values) => handleSubmit(values)}
          validationSchema={getYupSchema("articleModalValidationSchema")}
        >
          {(props) => {
            const {
              values: { title, duration, text },
              errors,
              touched,
              disabled,
              handleSubmit,
              handleBlur,
              setFieldValue,
            } = props;

            const handleInputChange = (name) => (inputValue, e) => {
              if (name === "text") editorText = inputValue;
              setFieldValue(name, inputValue);
            };

            return (
              <form
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit}
                className="sk-lec-article-modal__form"
              >
                <div className="sk-lec-article-modal__inner">
                  <div className="sk-lec-article-modal__inner__metadata">
                    <TextInput
                      helperText={touched.title ? errors.title : ""}
                      error={touched.title && Boolean(errors.title)}
                      value={title}
                      name="title"
                      label={formatMessage(sharedMessages.title)}
                      onChange={handleInputChange("title")}
                    />
                    <TextInput
                      type="number"
                      adornment={<FormattedMessage {...messages.min} />}
                      helperText={touched.duration ? errors.duration : ""}
                      error={touched.duration && Boolean(errors.duration)}
                      value={duration}
                      name="duration"
                      label={formatMessage(messages.estimatedReadingTime)}
                      onChange={handleInputChange("duration")}
                    />
                  </div>

                  <DraftEditor
                    readOnly={false}
                    initialContent={text}
                    onChange={handleInputChange("text")}
                    error={touched.text && Boolean(errors.text)}
                    helperText={touched.text ? errors.text : ""}
                    getSuggestions={handleAIButtonClick}
                  />
                </div>
                {suggestions && (
                  <>
                    <Heading
                      variant="h4"
                      classes="sk-course-information-form-heading sk-course-information-form-heading__student"
                    >
                      AI Suggestions
                    </Heading>
                    <Markdown>{suggestions}</Markdown>
                  </>
                )}
                <footer className="sk-lec-article-modal__footer">
                  <Button
                    variant="text"
                    color="secondary"
                    size="large"
                    type="submit"
                  >
                    <FormattedMessage {...sharedMessages.saveButton} />
                  </Button>
                </footer>
              </form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

const getFileUrlMutation = graphql(GET_FILE_URL_MUTATION, {
  props: ({ mutate }) => ({
    getFileUrl: (input) => mutate({ variables: { input } }),
  }),
});

const getSuggestionsMutation = graphql(SUGGESTIONS_MUTATION, {
  props: ({ mutate }) => ({
    getSuggestions: (variables) => mutate({ variables }),
  }),
});

const enhancer = compose(
  injectIntl,
  getFileUrlMutation,
  getSuggestionsMutation,
  withLayerModal,
  withModal
);

export default enhancer(TextFileEditModal);
