import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  addHW: {
    id: 'app.components.HomeworkUploader.addHW',
    defaultMessage: 'Add homework',
  },
  homework: {
    id: 'app.components.HomeworksTable.homework',
    defaultMessage: 'Homework for Concierge level',
  },
  download: {
    id: 'app.components.StudentHomework.download',
    defaultMessage: 'Open',
  },
  fileReq: {
    id: 'app.components.StudentHomework.fileReq',
    defaultMessage: 'File format - .PDF',
  },
});
