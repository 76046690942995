// Core
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import ContentEditable from "react-contenteditable";

class EditableText extends PureComponent {
  constructor(props) {
    super(props);
    this.contentEditable = React.createRef();
    this.prevValue = React.createRef();
    this.state = {
      width: window.innerWidth,
      html: props.html,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize);
    this.prevValue.current = this.props.html;
  }

  resize = () => this.setState({ width: window.innerWidth });

  componentDidUpdate = () => {
    const { disabled, html } = this.props;
    const isSafari = window.safari !== undefined;
    if (
      (!disabled && this.contentEditable && !isSafari) ||
      (isSafari && this.contentEditable.innerText === this.prevValue.current) // workaround to fix bug in safari where focus event is firing twice: focus -> blur -> focus ... (https://github.com/stripe/react-stripe-elements/issues/326)
    ) {
      console.log("focus");
      this.contentEditable.focus();
    }
  };

  handleBlur = ({ target: { innerText: inputValue, value } }) => {
    const {
      handleInputChange,
      handleBlur,
      html: defaultHtml,
      inputKey,
    } = this.props;
    console.log("handleBlur", inputKey, inputValue, value);
    const nextValue = (inputValue || value || "").trim();
    if (nextValue.length > 0) {
      return this.setState({ html: nextValue }, () => {
        handleInputChange({ [inputKey]: nextValue });
        handleBlur();
        this.prevValue.current = nextValue;
      });
    }
    return this.setState({ html: defaultHtml }, () => {
      handleInputChange({ [inputKey]: defaultHtml });

      handleBlur();
    });
  };

  handleClick = (e) => {
    const { disabled } = this.props;
    if (!disabled) {
      e.stopPropagation();
    }
  };

  handleOnKeyPress = (evt) => {
    console.log("handleOnKeyPress");
    const { maxLength } = this.props;
    const isMaxLengthDefined = !!maxLength;

    const {
      keyCode,
      target: { innerText: inputValue },
    } = evt;
    const isUserClickedOnDelete = keyCode === 8;
    const isUserClickedOnBackDelete = keyCode === 46;
    const isTextLongerThanAllowed = inputValue.length > maxLength - 1;

    if (isMaxLengthDefined)
      if (
        isTextLongerThanAllowed &&
        !(isUserClickedOnDelete || isUserClickedOnBackDelete)
      )
        evt.preventDefault();
  };

  innerRef = (node) => {
    this.contentEditable = node;
  };

  render() {
    const { html, width } = this.state;
    const { editableClasses, disabled } = this.props;
    if (
      width < 768 &&
      !disabled &&
      /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    ) {
      return (
        <input
          autoFocus
          innerRef={this.innerRef}
          className={editableClasses}
          defaultValue={html}
          onBlur={this.handleBlur}
          onClick={this.handleClick}
        />
      );
    }
    return (
      <ContentEditable
        innerRef={this.innerRef}
        className={editableClasses}
        html={html}
        disabled={disabled}
        onBlur={this.handleBlur}
        onClick={this.handleClick}
        onKeyDown={this.handleOnKeyPress}
        data-gramm_editor="false"
      />
    );
  }
}

EditableText.propTypes = {
  html: PropTypes.any,
  handleInputChange: PropTypes.func,
  handleBlur: PropTypes.func,
  inputKey: PropTypes.string,
  editableClasses: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  maxLength: PropTypes.number,
};

export default EditableText;
