import React, { memo, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Play,
  Volume,
  Muted,
  FullScreen,
  ListArrow,
} from 'components/Icon/SvgCss/VideoControllers';
import { converTime, getTimeCode, setVolumeLevelHandler } from './utils';

import './VideoControllers.scss';

const speedMap = [
  { label: '0.25', value: 0.25 },
  { label: '0.5', value: 0.5 },
  { label: '0.75', value: 0.75 },
  { label: 'Normal', value: 1 },
  { label: '1.25', value: 1.25 },
  { label: '1.5', value: 1.5 },
  { label: '1.75', value: 1.75 },
  { label: '2.0', value: 2 },
];

let prevVolumeLevel = null;

const VideoControllers = ({
  mediaType,
  volumeLevel,
  currentTime,
  loadedTime,
  duration,
  isPlay,
  isMuted,
  isFullScreen,
  playControll,
  muteVideo,
  setFullScreen,
  videoSeekTo,
  setPlaybackSpeedProp,
  setVolumeLevel,
}) => {
  const [playbackModal, setPlaybackModal] = useState(false);
  const [playbackLabel, setPlaybackLabel] = useState(false);
  const [playbackSpeed, setPlaybackSpeed] = useState(1);
  const [hideController, setHideController] = useState(false);

  let currentTimePosition = currentTime ? currentTime : 0;

  const volumeHandler = e => {
    const volume = e.target.value;

    prevVolumeLevel = volumeLevel;
    setVolumeLevel(volume);

    if(volume === 0) muteVideo();
  };

  const playbackLabelClickHandler = () => {
    setPlaybackModal(!playbackModal);
    setPlaybackLabel(!playbackLabel);
  };

  const playbackItemClickHandler = value => {
    setPlaybackSpeed(value);
    setPlaybackSpeedProp(value);
  };

  const getTimeCodeHandler = e => {
    const timeCode = e.target.value;
    currentTimePosition = currentTime ? currentTime : timeCode;
    videoSeekTo(timeCode);
  };

  const muteVideoHandler = () => {
    muteVideo();
    if(isMuted) {
      setVolumeLevel(prevVolumeLevel);
      prevVolumeLevel = volumeLevel;
    } else {
      prevVolumeLevel = volumeLevel;
      setVolumeLevel(0);
    }
  }

  return (
    <div
      className={classNames(
        'sk-video-module-controls',
        hideController ? 'hide' : '',
        mediaType
      )}
    >
      <div className="controls-row">
        <div className="left row">
          <div className="playControllButton" onClick={playControll}>
            <Play isPlay={isPlay} />
          </div>
          <div className="timestamp">
            <span>
              {converTime(currentTime)} / {converTime(duration)}
            </span>
          </div>
          <div className="playbackSpeed-container">
            <div
              className={classNames('label', playbackLabel ? 'open' : 'close')}
              onClick={playbackLabelClickHandler}
            >
              <span>
                {playbackSpeed !== 1 ? `${playbackSpeed} x` : 'Normal'}
              </span>
              <ListArrow isOpen={playbackLabel} />
            </div>
            <div
              className={classNames(
                'playbackSpeed-speeds',
                playbackModal ? 'open' : ''
              )}
            >
              <span>Speed</span>
              <ul>
                {speedMap.map(({ label, value }) => (
                  <li
                    key={label}
                    className={playbackSpeed === value ? 'selected' : ''}
                    onClick={() => playbackItemClickHandler(value)}
                  >
                    <span>x {label}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="right row">
          <div className="volume-settings">
            <div className="volume-bar">
              <input
                type="range"
                onChange={e=>volumeHandler(e)}
                className="volume"
                value={volumeLevel}
                step={0.01}
                min={0}
                max={1}
              />
            </div>
            <div className="icon" onClick={muteVideoHandler}>
              {isMuted ? <Muted /> : <Volume />}
            </div>
          </div>
          {mediaType === 'video' ? (
            <div className="fullscreen" onClick={setFullScreen}>
              <FullScreen isFullScreen={isFullScreen} />
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
      <input
        type="range"
        className="progressBar"
        onChange={e=>getTimeCodeHandler(e)}
        value={currentTime}
        step={0.01}
        min={0}
        max={duration}
      />
    </div>
  );
};

export default memo(VideoControllers);
