// Core
import React from 'react';
import { compose } from 'react-apollo';
import { injectIntl } from 'react-intl';

// Components
import Button from 'components/Button';
import Card from 'components/Card';
import Text from 'components/Text';
import Heading from 'components/Heading';
import Duration from 'components/Duration';
import Icon from 'components/Icon/SvgCss/QuizAttachment';

// Other
import {
  QUIZ_ATTACHMENT,
} from 'utils/enums/index';
import ENTITY_CONTROLLER from 'routes/Course/components/LectureMaterials/StudyMaterialsList/MaterialEntity/helpers';
import { withModal } from 'containers/ModalProvider/withModal';
import { messages } from 'routes/StudentsCabinet/ToppingDetails/messages.js';
import { QuizPreview, } from 'components/FilePreview';

// Styles
import './Attachments.scss';

const COMPONENT_PREFIX = `sk-topping-details_attachment`;

const Attachments = ({ attachment, modalContext, modalProps, intl: { formatMessage } }) => {
  const {
    title,
    filepath,
    duration,
  } = attachment;
  const Modal = () => <div className={`${COMPONENT_PREFIX}__modal`}>{<QuizPreview quizId={filepath} />}</div>

  const showModalHandler = () =>
    modalContext.showModal(Modal, {
      hideModal: modalContext.hideModal,
      close: modalContext.hideModal,
      ...modalProps,
    });

  return(
    <div className={COMPONENT_PREFIX}>
      <Heading>{formatMessage(messages.quiz)}</Heading>
      <Card>
        <div className={`${COMPONENT_PREFIX}_top`}>
          <Icon />
          <div className={`${COMPONENT_PREFIX}_text`}>
            <Text>{title}</Text>
            <Duration duration={duration} />
          </div>
        </div>
        <Button variant="outlined" transparent color="primary" onClick={showModalHandler}>
          {formatMessage(messages.takeTest)}
        </Button>
      </Card>
    </div>
  );
};

const enhancer = compose(withModal, injectIntl);

export default enhancer(Attachments);
