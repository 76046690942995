import React from 'react';

import './index.scss';

const BlockquoteOnHover = () => (
  <svg
    className="hover"
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.692383" width="32" height="32" rx="2" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.2339 8.83212H21.6739V10.1121H19.4339L16.7139 22.9121H18.4739V24.1921H13.0339V22.9121H15.4339L18.1539 10.1121H16.2339V8.83212Z"
      fill="#7145D8"
    />
  </svg>
);

const DefaultBlockquote = () => (
  <svg
    className="default"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.2339 8.83212H20.6739V10.1121H18.4339L15.7139 22.9121H17.4739V24.1921H12.0339V22.9121H14.4339L17.1539 10.1121H15.2339V8.83212Z"
      fill="#6D6E71"
    />
  </svg>
);

const ActiveBlockquote = () => (
  <svg
    className="active"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.2339 8.83212H20.6739V10.1121H18.4339L15.7139 22.9121H17.4739V24.1921H12.0339V22.9121H14.4339L17.1539 10.1121H15.2339V8.83212Z"
      fill="#7145D8"
    />
  </svg>
);

const Blockquote = ({ active }) => {
  const Icon = active ? ActiveBlockquote : DefaultBlockquote;

  return (
    <div className="editor-icon-svg">
      <Icon />
      <BlockquoteOnHover />
    </div>
  );
};

export default Blockquote;
