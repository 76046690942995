// Core
import React, { PureComponent } from 'react';
import classNames from 'classnames';

// Components
import FileUploader from 'components/FileUploader';
import ToolTip from 'components/ToolTip';
import IconWithModal from 'components/IconWithModal';

// Icons
import Pencil from 'components/Icon/SvgCss/Pencil';
import GarbageBucket from 'components/Icon/SvgCss/GarbageBucket';

// Other
import { PurpleHeart, PurpleDark } from 'components/Icon/color';
import { withModal } from 'containers/ModalProvider/withModal';
import { messages } from 'routes/Course/components/UploadController/messages';
import { FormattedMessage } from 'react-intl';

// Styles and Assets
import './UploadController.scss';

class UploadController extends PureComponent {
  renderTipsModal = ({ close }) => (
    <div>
      <div>Tips</div>
      <button onClick={close}>Close</button>
    </div>
  )

  showTipsModal = () => {
    const { modalContext: { showModal, hideModal }} = this.props;
    showModal(this.renderTipsModal, {
      close: hideModal,
    })
  }

  render() {
    const {
      disabled,
      handleDelete,
      supportedFormats,
      supportedMimes,
      maxSize,
      handleReload,
      uploaderId,
      withS3Upload = true,
      withDelete = true,
      isFilePending,
      isFileInQueue,
    } = this.props;

    return (
      <div className={classNames('sk-upload-controller', { disabled })}>
        {withDelete && (
          <IconWithModal
            disabled={disabled}
            customModalHandler={handleDelete}
            icon={() => (<GarbageBucket color={PurpleDark} />)}
          />
        )}
        {!isFilePending && (withS3Upload
          ? (<FileUploader
            uploaderId={uploaderId}
            supportedMimes={supportedMimes}
            supportedFormats={supportedFormats}
            maxSize={maxSize}
            handleChange={handleReload}
            disabled={disabled}
          >
            <Pencil width="17" height="17" color={PurpleDark} />
          </FileUploader>)
          : (<IconWithModal
            customModalHandler={handleReload}
            icon={() => <Pencil color={PurpleDark} />}
          />)
        )}
        {isFilePending && !isFileInQueue && (
          <ToolTip wide triggerClasses="sk-upload-controller__info" iconTheme='orange'>
            <FormattedMessage {...messages.toolTip} />
          </ToolTip>
        )}
      </div>
    );
  }
}

export default withModal(UploadController);
