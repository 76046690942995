import React from 'react';
import { FormattedMessage } from 'react-intl';
import { messages } from 'routes/ExpertsCabinet/CourseEdit/Details/messages';

export const AVAILABLE_HOMEWORK_ACCESS_OPTIONS = [
  {
    value: 'all',
    label: <FormattedMessage {...messages.homeworkAvailableAtAnyTime} />,
    disabled: false
  },
  {
    value: 'submitted_previous_homework',
    label: <FormattedMessage {...messages.homeworkAvailableAfterHomework} />,
    disabled: false
  },
  {
    value: 'prev_more_level',
    label: <FormattedMessage {...messages.availableAfterHomeworkMarkMore} />,
    // label: 'Once previous homework has been evaluated for more than 60%',
    disabled: false
  },
];

export const AVAILABLE_LECTURE_ACCESS_OPTIONS = [
  {
    value: 'all',
    label: <FormattedMessage {...messages.homeworkAvailableAtAnyTime} />,
  },
  {
    value: 'submitted_previous_homework',
    label: <FormattedMessage {...messages.homeworkAvailableAfterHomework} />,
  },
  {
    value: 'prev_more_level',
    label: <FormattedMessage {...messages.availableAfterHomeworkMarkMore} />,
  },
];

export const COURSE_FEATURES_OPTIONS = [
  {
    value: 'webinars',
    label: <FormattedMessage {...messages.interactivityOpptionsWebinars} />,
  },
  {
    value: 'homeworks',
    label: <FormattedMessage {...messages.interactivityOpptionsHomeworks} />,
  },
];
