import GoogleAnalytics from "react-ga"
/**
 * Dislcaimer: react-ga will not work properly with SSR
 * Website may loose some metrics.
 */
class gaTracking {

  constructor() {
    this.init();
  }

  // Initialise plugin here
  init() {
    // react-ga won't work with ssr properly
    if (window && !window.isServer) {
      GoogleAnalytics.initialize(process.env.GA_TRACKING_ID || 'UA-142469382-1');
      this.ga = GoogleAnalytics;
    }
  }

  // Pageview wrapper
  pageView(location) {
    if (this.ga) {
      if (!location && window && !window.isServer) {
        location = window.location.pathname + window.location.search
      }
      this.ga.pageview(location);
    }
  }

  // Events wrapper
  trackEvent(args) {
    if (this.ga) {
      this.ga.event(args);
    }
  }

  // Avanced ecommerce wrapper
  trackEC(action, args) {
    if (this.ga) {
      this.ga.plugin.execute('ec', action, args);
    }
  }
};

export default new gaTracking;
