// Core
import React, { memo } from 'react';

// Components
import Text from 'components/Text';

// Styles and Assets
import './index.scss';

const CourseDetail = ({ icon: Icon, text }) => (
  <div>
    <Icon />
    {typeof text == 'string' ? (
      <Text>{text}</Text>) : text
    }
  </div>
)

export default memo(CourseDetail);
