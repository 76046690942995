// Core
import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'react-apollo';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
// Components
import Heading from 'components/Heading';
import TextField from '@material-ui/core/TextField';
import Card from 'components/Card';
import Divider from '@material-ui/core/Divider';
// import Button from "components/Button";
// import Link from "components/Link";
import MetaData from 'components/MetaData';
import { Doughnut, Bar } from 'react-chartjs-2';
import { withAuthenticatedUser } from 'containers/AuthenticatedUserProvider/withAuthenticatedUser';
import { withModal } from 'containers/ModalProvider/withModal';

// Styles and Assets
import './Dashboard.scss';

// Other
// import { FormattedMessage } from "react-intl";
// import { messages } from "routes/Dashboard/messages";

const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June'];

const Dashboard = ({
  modalContext: { showModal },
  userContext: { profile }
}) => (
  <div className='ultrawide dashboard'>
    <MetaData titleKey='aboutUsTitle' />
    {/* {profile?.role} */}
    <div className='dashboard__wrapper  sk-background-spot__secondary'>
      <Box sx={{ flexGrow: 1 }}>
        <Card classes='dashboard__card'>
          <CardContent>
            <Box sx={{ flexGrow: 1, padding: '20px' }}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <TextField
                    id='standard-basic'
                    label={new Date().toLocaleString()}
                    variant='standard'
                    disabled
                  />

                  <TextField
                    id='standard-basic'
                    label={new Date().toLocaleString()}
                    variant='standard'
                    disabled
                  />
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Bar
              data={{
                type: 'bar',
                labels,
                datasets: [
                  {
                    label: 'Purchases',
                    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                  }
                ],
                options: {
                  plugins: {
                    title: {
                      display: true,
                      text: 'Purchases'
                    }
                  },
                  responsive: true,
                  scales: {
                    x: {
                      stacked: true
                    },
                    y: {
                      stacked: true
                    }
                  }
                }
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <Bar
              data={{
                type: 'bar',
                labels,
                datasets: [
                  {
                    label: 'Members',
                    data: [1, 1, 1, 1, 1, 1]
                  }
                ],
                options: {
                  plugins: {
                    title: {
                      display: true,
                      text: 'Members'
                    }
                  },
                  responsive: true,
                  scales: {
                    x: {
                      stacked: true
                    },
                    y: {
                      stacked: true
                    }
                  }
                }
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Card classes='dashboard__card'>
              <CardContent>
                <Typography
                  sx={{ fontSize: 14 }}
                  color='text.secondary'
                  gutterBottom
                >
                  Purchased Courses
                </Typography>
                <Typography variant='h5' component='div'>
                  -
                </Typography>
                <Typography sx={{ mb: 1.5 }} color='text.secondary'>
                  items
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={3}>
            <Card classes='dashboard__card'>
              <CardContent>
                <Typography
                  sx={{ fontSize: 14 }}
                  color='text.secondary'
                  gutterBottom
                >
                  Drafts
                </Typography>
                <Typography variant='h5' component='div'>
                  -
                </Typography>
                <Typography sx={{ mb: 1.5 }} color='text.secondary'>
                  courses
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={3}>
            <Card classes='dashboard__card'>
              <CardContent>
                <Typography
                  sx={{ fontSize: 14 }}
                  color='text.secondary'
                  gutterBottom
                >
                  Published
                </Typography>
                <Typography variant='h5' component='div'>
                  -
                </Typography>
                <Typography sx={{ mb: 1.5 }} color='text.secondary'>
                  courses
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={3}>
            <Card classes='dashboard__card'>
              <CardContent>
                <Typography
                  sx={{ fontSize: 14 }}
                  color='text.secondary'
                  gutterBottom
                >
                  Total storage
                </Typography>
                <Typography variant='h5' component='div'>
                  149.6 GB
                </Typography>
                <Typography sx={{ mb: 1.5 }} color='text.secondary'>
                  used
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card classes='dashboard__card'>
              <CardContent>
                <Typography
                  sx={{ fontSize: 14 }}
                  color='text.secondary'
                  gutterBottom
                >
                  Objects
                </Typography>
                <Typography variant='h5' component='div'>
                  2.8 k
                </Typography>
                <Typography sx={{ mb: 1.5 }} color='text.secondary'>
                  items
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card classes='dashboard__card'>
              <CardContent>
                <Typography
                  sx={{ fontSize: 14 }}
                  color='text.secondary'
                  gutterBottom
                >
                  Average object size
                </Typography>
                <Typography variant='h5' component='div'>
                  54.8 MB
                </Typography>
                <Typography sx={{ mb: 1.5 }} color='text.secondary'>
                  MB
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={3}>
            <Card classes='dashboard__card'>
              <div className='dashboard__content'>
                <Doughnut
                  data={{
                    labels: ['Ongoing', 'Completed'],
                    datasets: [
                      {
                        label: 'Progress',
                        data: [1, 1],
                        backgroundColor: [
                          'rgb(255, 99, 132)',
                          'rgb(255, 205, 86)'
                        ],
                        hoverOffset: 4
                      }
                    ]
                  }}
                />
              </div>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card classes='dashboard__card'>
              <div className='dashboard__content'>
                <Doughnut
                  data={{
                    labels: ['Experts', 'Student', 'Manager'],
                    position: 'bottom',

                    datasets: [
                      {
                        label: 'Users',
                        data: [1, 1, 1],
                        backgroundColor: [
                          'rgb(255, 99, 132)',
                          'rgb(54, 162, 235)',
                          'rgb(255, 205, 86)'
                        ],
                        hoverOffset: 4
                      }
                    ]
                  }}
                />
              </div>
              {/* <div className="dashboard__footer">
          <Link href="/">
            <Button color="secondary" size="large" variant="text">
              <FormattedMessage {...messages.goToSkilleton} />
            </Button>
          </Link>
        </div> */}
            </Card>
          </Grid>
          {profile?.role === 'owner' && (
            <Grid item xs={3}>
              <Card classes='dashboard__card'>
                <CardContent>
                  <Typography
                    sx={{ fontSize: 14 }}
                    color='text.secondary'
                    gutterBottom
                  >
                    Paid Due
                  </Typography>
                  <Typography variant='h5' component='div'>
                    30 June 2024
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color='text.secondary'>
                    30 days trial
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
      </Box>
    </div>
  </div>
);
const enhancer = compose(withRouter, withModal, withAuthenticatedUser);
export default enhancer(Dashboard);
