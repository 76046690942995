// Core
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
// import { graphql, compose } from 'react-apollo';

// Services
import { FormErrorsContext } from './formErrors-context';
import { isObjectsEqual } from 'utils/helpers/index';

class FormErrorsProvider extends PureComponent {
  state = {
    errors: {},
    hasErrors: false,
  };

  updateErrors = (newErrors) => {
    const { errors } = this.state;
    const formName = Object.keys(newErrors)[0];
    const hasErrors = Object.keys(newErrors[formName]).length > 0;
    const newErrorsState = { ...errors, }

    const isErrorDefined = !!newErrorsState[formName];
    const currentError = isErrorDefined ? newErrorsState[formName] : {};
    const isErrorsUpdated = !isObjectsEqual(currentError, newErrors[formName]);

    if (!isErrorsUpdated) return;

    if (!hasErrors) delete newErrorsState[formName]
    else if (hasErrors) newErrorsState[formName] = { ...newErrors[formName] };

    this.setState({ errors: newErrorsState, hasErrors: Object.keys(newErrorsState).length > 0 });
  }

  render() {
    const { hasErrors, errors } = this.state;
    const { children } = this.props;

    return (
      <FormErrorsContext.Provider
        value={{
          formErrorsContext: {
            hasErrors,
            errors,
            updateErrors: this.updateErrors,
          },
        }}
      >
        {React.Children.only(children)}
      </FormErrorsContext.Provider>
    );
  }
}

FormErrorsProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default FormErrorsProvider;
