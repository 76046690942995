// Core
import React from 'react';
import { Link } from 'react-router-dom';

// Components
import Text from 'components/Text';
import AddToCartIcon from 'components/CourseCard/AddToCartIcon';
import Button from 'components/Button';
import ShareButton from 'components/ShareButton';
import InlinePrice from 'components/CourseCard/InlinePrice';
import GiftIcon from 'components/Icon/SvgCss/Gift';

// Styles and Assets
import './CourseCardActionBlock.scss';

// Other
import { withShoppingCart } from 'containers/ShoppingCartProvider/withShoppingCart';
import { isBlank } from 'utils/helpers';
import { FormattedMessage } from 'react-intl';
import { messages } from 'components/CourseCard/CourseCardActionBlock/messages';

const CourseCardActionBlock = ({
  disabled,
  boughted,
  shoppingCartContext: {
    _isCourseAddedInShoppingCart,
    handleAddCourseToCart,
    handleBuyNowClick,
  },
  selectedVariant,
  courseUrl,
  ...rest
}) => {
  const isFree = selectedVariant?.price ? selectedVariant?.price === 0 : false;

  return (
    <div className='sk-course-card-action-block'>
      <div className='sk-course-card-action-block__actions'>
        <div className='sk-course-card-action-block__actions_icons'>
          {/* <div className="sk-course-card-action-block__actions_icons__price"> */}
          <InlinePrice
            withIcon={isFree}
            icon={GiftIcon}
            price={selectedVariant?.price}
          />
          {/* </div> */}
          {/* <ShareButton className="sk-course-card-action-block__scaled" iconProps={{ width: 43, height: 43 }} shareUrl={courseUrl} /> */}
          {(!isBlank(selectedVariant?.name) || boughted) && (
            <div
              className='sk-course-card-action-block__actions_icons__add-to-cart'
              onClick={
                !disabled &&
                !boughted &&
                handleAddCourseToCart({
                  interactionLevel: selectedVariant?.name,
                  price: selectedVariant?.price,
                  ...rest,
                })
              }
            >
              <AddToCartIcon
                disabled={
                  disabled ||
                  boughted ||
                  _isCourseAddedInShoppingCart({ id: rest?.id })
                }
              />
            </div>
          )}
        </div>
        <Link style={{ textDecoration: 'none' }} to={courseUrl}>
          <Button color='secondary' variant='text'>
            <FormattedMessage {...messages.learnMore} />
          </Button>
        </Link>
      </div>

      {boughted && (
        <div className='sk-course-card-action-block__message'>
          <Text size='caption' accent>
            <FormattedMessage {...messages.youHaveAlreadyBought} />
          </Text>
        </div>
      )}
    </div>
  );
};

export default withShoppingCart(CourseCardActionBlock);
