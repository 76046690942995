// Core
import React, { memo, useEffect, useState } from "react";
import { compose } from "react-apollo";
import { injectIntl, FormattedMessage } from "react-intl";
import { withAuthenticatedUser } from "containers/AuthenticatedUserProvider/withAuthenticatedUser";

// Components
import HeaderSearch from "./HeaderSearch";
import CatalogBlock from "./CatalogBlock";
import CheckoutBlock from "./CheckoutBlock";
import UploadBlock from "./UploadBlock";
import UserBlock from "./UserBlock";
import AuthBlock from "./AuthBlock";
import Link from "components/Link";
import Logo from "components/Icon/Svg/Logo";
import Image from "components/Image";

// Other
import { messages } from "./messages";
import { isBlank } from "utils/helpers/";
import useWindowDimensions from "./helpers";

// SEO
import { OrganizationLinkedData } from "seo/General";

// Styles and Assets
import "./Header.scss";

const prefix = "sk-page-header";

const transperentHeaderRoutes = [
  "/:locale?/courses/:category/:slug",
  "/courses/:category/:slug",
  "/:locale?/courses/:category/:subcategory/:slug",
  "/courses/:category/:subcategory/:slug",
  "/:locale?",
  "/",
];

const Header = memo(
  ({
    userContext,
    // user,
    settings,
    location,
    path,
    intl: { formatMessage },
  }) => {
    const [user, setUser] = useState();
    const [visible, setVisible] = useState(false);
    const [customLogo, setCustomLogo] = useState(null);
    const [customPrimaryColour, setCustomPrimaryColour] = useState(null);
    const [customSecondaryColour, setCustomSecondaryColour] = useState(null);
    const isScrollTopHeaderTransperent = transperentHeaderRoutes.includes(path);
    const { width: windowWidth } = useWindowDimensions();

    const setStickyHeader = () => {
      if (window.pageYOffset > 55) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    };

    const mobileModeHandler = (width) => {
      return width < 880;
    };

    useEffect(() => {
      if (userContext && userContext.isProfileLoading === false) {
        setUser(userContext.profile);
      }
    }, [userContext]);

    useEffect(() => {
      if (isScrollTopHeaderTransperent && typeof window !== "undefined") {
        window.addEventListener("scroll", setStickyHeader);
        return () => {
          window.removeEventListener("scroll", setStickyHeader);
        };
      }
    }, [isScrollTopHeaderTransperent]);

    useEffect(() => {
      if ((user && user.companies?.length) || settings) {
        const [firstCompany] = user?.companies || [{}];
        if (firstCompany?.logo || settings?.logo) {
          setCustomLogo(firstCompany?.logo || settings?.logo);
        }
        if (firstCompany?.primaryColour || settings?.primaryColour) {
          setCustomPrimaryColour(
            firstCompany?.primaryColour || settings?.primaryColour
          );
        }
        if (firstCompany?.secondaryColour || settings?.secondaryColour) {
          setCustomSecondaryColour(
            firstCompany?.secondaryColour || settings?.secondaryColour
          );
        }
      }
    }, [user, settings]);

    const renderCatalogBlock = () => {
      if (user?.role !== "admin") {
        return (
          <CatalogBlock
            org={user?.org}
            inputPlaceholder={formatMessage(messages.searchInputPlaceholder)}
          />
        );
      }
      return null;
    };
    const renderHeaderSearch = () => {
      if (user?.role !== "admin") {
        return (
          <FormattedMessage {...messages.searchInputPlaceholder}>
            {(msg) => <HeaderSearch placeholder={msg} />}
          </FormattedMessage>
        );
      }
      return null;
    };

    const renderMenuPanelActionBlock = () => {
      if (user?.role !== "admin" && user?.role !== "student" && user) {
        return (
          <div className="sk-menu-panel_action-block_item">
            <UploadBlock />
          </div>
        );
      }
      return null;
    };

    const renderCheckoutBlock = () => {
      if (user?.role !== "admin") {
        return <CheckoutBlock />;
      }
      return null;
    };

    const renderUserBlock = () => {
      if (user) {
        return <UserBlock user={user} />;
      }
      if (mobileModeHandler(windowWidth)) {
        return <UserBlock user={user} />;
      }
      return <AuthBlock />;
    };
    if (userContext.isProfileLoading === true) {
      return null;
    }
    if (
      userContext.isProfileLoading === undefined &&
      userContext.isDomainLoading === undefined
    ) {
      return null;
    }

    return (
      <header
        className={`${prefix} ${visible ? "scroll" : "unscroll"} ${
          isScrollTopHeaderTransperent ? "landing" : ""
        }`}
      >
        {customPrimaryColour && (
          <style>
            :root {"{"}
            --purple-gradient-from: {customPrimaryColour} !important;
            --purple-gradient-to: {customSecondaryColour} !important;
            {"}"}
          </style>
        )}
        <OrganizationLinkedData />
        <div className="grid landing space-between sk-page-header__inner mobile-header">
          <div className={`${prefix}_left-block`}>
            <div className={`${prefix}_logo grid_item`}>
              <Link to="/">
                {customLogo ? (
                  <Image
                    src={customLogo}
                    style={{ width: "auto", height: "40px" }}
                  />
                ) : (
                  <Logo />
                )}
              </Link>
            </div>
            <div className={`${prefix}_courses grid_item`}>
              {renderCatalogBlock()}
            </div>
          </div>
          <div className={`${prefix}_search-panel`}>{renderHeaderSearch()}</div>
          <div className={`${prefix}_menu-panel sk-menu-panel`}>
            <div className="sk-menu-panel_action-block">
              {renderMenuPanelActionBlock()}
              <div className="sk-menu-panel_action-block_item">
                {renderCheckoutBlock()}
              </div>
            </div>
            <div className="sk-menu-panel_toggle">{renderUserBlock()}</div>
          </div>
        </div>
        {!visible && isScrollTopHeaderTransperent && (
          <div className="landing-shadows" />
        )}
      </header>
    );
  }
);

const enhancer = compose(injectIntl, withAuthenticatedUser);

export default enhancer(Header);
