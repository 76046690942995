// Core
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// Components
import Text from 'components/Text';
import Button from 'components/Button';
import Inactive from 'routes/ExpertsCabinet/CourseEdit/GeneralInfo/StatusInfo/Steps/Inactive';

// Styles and Assets
import './StatusChangedModal.scss';

// Other
import { FormattedMessage } from 'react-intl';
import { messages } from 'routes/Course/components/StatusChangedModal/messages';

const STATUSES_MODAL_CONTENT = {
  FIRST_REVIEW: {
    title: () => (<FormattedMessage {...messages.thankYou} />),
    text: () => (<FormattedMessage {...messages.yourSubmittedInfo} />),
  },
  FINAL_REVIEW: {
    title: () => (<FormattedMessage {...messages.thankYou} />),
    text: () => (<FormattedMessage {...messages.yourSubmittedInfo} />),
  },
  REACTIVATED: {
    title: () => (<FormattedMessage {...messages.congrats} />),
    text: () => (<FormattedMessage {...messages.yourCourseActivated} />),
  },
  ACTIVE: {
    title: () => (<FormattedMessage {...messages.congrats} />),
    text: () => (<FormattedMessage {...messages.yourCourseActivated} />),
  },
  DEACTIVATED: {
    text: (type) => (<Inactive type={type} />),
  },
};

const StatusChangedModal = ({ status, handleContinueClick, type }) => {
  const Title = STATUSES_MODAL_CONTENT[status]?.title || '';
  const Description = STATUSES_MODAL_CONTENT[status]?.text || '';

  return (
    <div className="sk-status-changed-modal">
      {Title ? (
        <div className="sk-status-changed-modal__header">
          <Text bold classes="sk-status-changed-modal__heading"><Title /></Text>
        </div>) : null
      }
      {Description ? (
        <div className="sk-status-changed-modal__content">
          <Text><Description type={type} /></Text>
        </div>) : null
      }
      <div className="sk-status-changed-modal__footer">
        <Button
          variant="text"
          color="secondary"
          size="large"
          onClick={handleContinueClick}
        >
          <FormattedMessage {...messages.gotIt} />
        </Button>
      </div>
    </div>
  );
}

StatusChangedModal.propTypes = {
  handleContinueClick: PropTypes.func,
};

export default StatusChangedModal;
