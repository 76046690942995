// Core
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { compose, graphql } from 'react-apollo';
import { injectIntl } from 'react-intl';

// Components
import Collapse from '@material-ui/core/Collapse';
import { Doughnut } from 'react-chartjs-2';
import Tabs from './Tabs';
import Button from 'components/Button';
import Card from 'components/Card';
import UserAvatar from 'components/UserAvatar';
import Text from 'components/Text';
import ContentHeader from 'containers/ContentHeader';
import Loader from 'components/Loader';
import CompanyInfo from 'routes/AdminPanel/CompanyView/CompanyInfo/CompanyInfo';
import Billing from 'routes/AdminPanel/CompanyView/ExpertDetails/ExpertDetails';
import Team from 'routes/AdminPanel/CompanyView/UserCourses/CoursesList';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

// GraphQl
import BLOCK_USER from 'mutations/AdminPanel/blockUser.gql';
import DELETE_USER from 'mutations/AdminPanel/deleteUser.gql';
import GET_COMPANY from 'queries/Company/getCompany.gql';

// Other
import { parseFetchedData, capitalize, validateUserId } from 'utils/helpers';
import { EXPERT } from 'utils/enums';
import { sharedMessages } from 'services/i18n/sharedMessages/messages';

// Styles and Assets
import './CompanyView.scss';

class CompanyView extends PureComponent {
  state = {
    activeTab: 'userInfo'
  };

  handleSwitchTab = nextTab => {
    this.setState({ activeTab: nextTab });
  };

  updateUserProfileState = async () => {
    const {
      adminSuspendRestoreUser,
      profile: { enabled, id }
    } = this.props;
    try {
      const input = { id, enable: !enabled };
      await adminSuspendRestoreUser(input);
    } catch (error) {
      throw Error(error);
    }
  };

  goToUsersList = () => {
    const {
      history,
      match: { url, params }
    } = this.props;
    const userId = params?.id;
    const usersListUrl = url.replace(userId, '');
    history.push(usersListUrl);
  };

  deleteUser = async () => {
    const {
      deleteUser,
      company: { id }
    } = this.props;
    try {
      await deleteUser({ id });
      this.goToUsersList();
    } catch (e) {
      console.error(e);
      throw new Error(e);
    }
  };

  incorrectUserIdelement = () => (
    <div className='sk-admin-user-view'>
      <div className='sk-admin-user-view__content-header'>
        <div className='sk-admin-user-view__content-header__left'>
          <ContentHeader />
        </div>
      </div>
      <div className='sk-admin-user-view__content-body'>
        <Text className='sk-admin-user-view__content-header__left__panel-name'>
          Error occurred durring user profile view. Incorrect user id.
        </Text>
      </div>
    </div>
  );

  render() {
    const {
      match,
      match: { url, params }
    } = this.props;

    const userRole = url.includes('experts') ? 'expert' : 'student';

    const {
      loading,
      company,
      intl: { formatMessage }
    } = this.props;
    const { activeTab } = this.state;

    if (loading) return <Loader center />;
    const parsedProfile = parseFetchedData(company);
    console.log('parsedProfile', parsedProfile);
    const isExpert = false;
    return (
      <div className='sk-admin-user-view'>
        <div className='sk-admin-user-view__content-header'>
          <div className='sk-admin-user-view__content-header__left'>
            <ContentHeader />
            <Text
              size='small'
              className='sk-admin-user-view__content-header__left__panel-name'
            >
              {capitalize(`${parsedProfile.companyName}`)} profile view
            </Text>
          </div>

          <div className='sk-admin-user-view__content-header__right__action-buttons'>
            <div className='sk-admin-user-view__content-header__right__action-buttons__button'>
              <Button
                transparent
                size='small'
                color='primary'
                variant='outlined'
                onClick={this.deleteUser}
              >
                {formatMessage(sharedMessages.deleteButton)}
              </Button>
            </div>
          </div>
        </div>

        <div className='sk-admin-user-view__content-body'>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={10}>
                <div className='sk-admin-user-view__content-body__user-info'>
                  <UserAvatar
                    avatar={parsedProfile.logo}
                    firstName={parsedProfile.companyName}
                  />
                  <div className='sk-admin-user-view__content-body__user-info__text-group'>
                    <Text
                      size='small'
                      className='sk-admin-user-view__content-body__user-info__text-group__text'
                    >
                      {parsedProfile.companyName}
                    </Text>
                    <Text size='small'>
                      {parsedProfile.email} | {parsedProfile.companyPhone}
                    </Text>
                  </div>
                </div>
              </Grid>
              <Grid item xs={2}>
                <Doughnut
                  options={{
                    plugins: {
                      title: {
                        display: true,
                        text: 'Custom Chart Title'
                      }
                    }
                  }}
                  data={{
                    datasets: [
                      {
                        data: [1],
                        backgroundColor: ['rgb(200, 200, 200)']
                      }
                    ]
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ flexGrow: 1 }} className="summary">
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Card classes='dashboard__card'>
                  <CardContent>
                    <Typography
                      sx={{ fontSize: 14 }}
                      color='text.secondary'
                      gutterBottom
                    >
                      Country
                    </Typography>
                    <Typography variant='h5' component='div'>
                      {parsedProfile.country.name}
                    </Typography>
                    {parsedProfile.trialStartDate &&
                      parsedProfile.trialEndDate && (
                        <Typography sx={{ mb: 1.5 }} color='text.secondary'>
                          {new Date(
                            parsedProfile.trialStartDate
                          ).toLocaleDateString()}
                          -
                          {new Date(
                            parsedProfile.trialEndDate
                          ).toLocaleDateString()}
                        </Typography>
                      )}
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card classes='dashboard__card'>
                  <CardContent>
                    <Typography
                      sx={{ fontSize: 14 }}
                      color='text.secondary'
                      gutterBottom
                    >
                      Storage
                    </Typography>
                    <Typography variant='h5' component='div'>
                      0GB
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color='text.secondary'>
                      used
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card classes='sk-admin-user-view__content-body__card'>
                  <Tabs
                    handleSwitch={this.handleSwitchTab}
                    activeTab={activeTab}
                  />
                  <Collapse
                    in={activeTab === 'userInfo'}
                    timeout='auto'
                    unmountOnExit
                  >
                    <CompanyInfo role={userRole} profile={parsedProfile} />
                    <Billing role={userRole} profile={parsedProfile} />
                    {/* <CompanyInfo role={userRole} profile={parsedProfile} />
              {isExpert && (
                <ExpertDetails role={userRole} profile={parsedProfile} />
              )} */}
                  </Collapse>

                  <Collapse
                    in={activeTab === 'userCourses'}
                    timeout='auto'
                    unmountOnExit
                  >
                    <Team role={userRole} profile={parsedProfile} />
                  </Collapse>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </div>
      </div>
    );
  }
}

const getCompanyQuery = graphql(GET_COMPANY, {
  props: ({ data: { company, error, loading, ...ownProps } }) => {
    if (loading) return { loading, profile: {} };
    if (error)
      throw new Error('Something went wrong while receiving getCompany');

    return {
      loading,
      error,
      company,
      ...ownProps
    };
  },
  options: ({
    match: {
      params: { id }
    }
  }) => ({
    fetchPolicy: 'cache-and-network',
    variables: { id }
  })
});

const blockUser = graphql(BLOCK_USER, {
  props: ({ mutate }) => ({
    adminSuspendRestoreUser: input => mutate({ variables: { input } })
  }),
  options: {
    refetchQueries: ['getCompany']
  }
});

const deleteUser = graphql(DELETE_USER, {
  props: ({ mutate }) => ({
    deleteUser: input => mutate({ variables: { input } })
  }),
  options: {
    refetchQueries: ['getCompany']
  }
});

const enhancer = compose(
  injectIntl,
  withRouter,
  getCompanyQuery,
  blockUser,
  deleteUser
);

export default enhancer(CompanyView);
