import React from 'react';

import './index.scss';

const ImageOnHover = () => {
  const randomFloatString = `${Math.random()}`;
  
  return (
    <svg className="hover" width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path={`url(#clip0_linear_${randomFloatString})`}>
      <g filter={`url(#filter0_linear_${randomFloatString})`}>
      <rect width="32" height="32" rx="2" fill="white"/>
      </g>
      <rect x="6.5" y="8.5" width="18" height="15" rx="2.5" stroke="#7145D8"/>
      <path d="M10.3875 14.6659C10.9099 14.6659 11.3538 14.4716 11.7194 14.0826C12.085 13.6937 12.2678 13.2215 12.2678 12.6661C12.2678 12.1104 12.0849 11.6382 11.7194 11.2493C11.3538 10.8605 10.9098 10.666 10.3875 10.666C9.86524 10.666 9.42137 10.8605 9.05573 11.2493C8.69023 11.6382 8.50741 12.1104 8.50741 12.6661C8.50741 13.2215 8.69013 13.6938 9.05573 14.0826C9.42137 14.4715 9.86524 14.6659 10.3875 14.6659Z" stroke="#7145D8" stroke-width="1" />
      <path d="M13.9365 16.8904C13.5416 17.3105 12.8743 17.3105 12.4794 16.8904L12.3696 16.7738C11.9747 16.3537 11.3074 16.3537 10.9125 16.7738L8.77883 19.0434C8.60448 19.2288 8.50741 19.4738 8.50741 19.7283V20.3322C8.50741 20.8845 8.95513 21.3322 9.50741 21.3322H21.2957C21.848 21.3322 22.2957 20.8845 22.2957 20.3322V17.0617C22.2957 16.8072 22.1987 16.5622 22.0243 16.3768L18.9505 13.1071C18.5556 12.687 17.8882 12.687 17.4933 13.107L13.9365 16.8904Z" stroke="#7145D8" stroke-width="1" />
      </g>
      <defs>
      <filter id={`filter0_linear_${randomFloatString}`} x="-10" y="-5" width="52" height="52" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
      <feOffset dy="5"/>
      <feGaussianBlur stdDeviation="5"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.541412 0 0 0 0 0.508594 0 0 0 0 0.645833 0 0 0 0.15 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
      </filter>
      <clipPath id={`clip0_linear_${randomFloatString}`}>
      <rect width="33" height="32" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  )
};

const DefaultImage = () => (
  <svg className="default" width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="6.5" y="8.5" width="18" height="15" rx="2.5" stroke="#6D6E71"/>
    <path d="M10.3875 14.6659C10.9099 14.6659 11.3538 14.4716 11.7194 14.0826C12.085 13.6937 12.2678 13.2215 12.2678 12.6661C12.2678 12.1104 12.0849 11.6382 11.7194 11.2493C11.3538 10.8605 10.9098 10.666 10.3875 10.666C9.86524 10.666 9.42137 10.8605 9.05573 11.2493C8.69023 11.6382 8.50741 12.1104 8.50741 12.6661C8.50741 13.2215 8.69013 13.6938 9.05573 14.0826C9.42137 14.4715 9.86524 14.6659 10.3875 14.6659Z" stroke="#6D6E71" stroke-width="1" />
    <path d="M13.9365 16.8904C13.5416 17.3105 12.8743 17.3105 12.4794 16.8904L12.3696 16.7738C11.9747 16.3537 11.3074 16.3537 10.9125 16.7738L8.77883 19.0434C8.60448 19.2288 8.50741 19.4738 8.50741 19.7283V20.3322C8.50741 20.8845 8.95513 21.3322 9.50741 21.3322H21.2957C21.848 21.3322 22.2957 20.8845 22.2957 20.3322V17.0617C22.2957 16.8072 22.1987 16.5622 22.0243 16.3768L18.9505 13.1071C18.5556 12.687 17.8882 12.687 17.4933 13.107L13.9365 16.8904Z" stroke="#6D6E71" stroke-width="1" />
  </svg>

);

const ActiveImage = () => (
  <svg
    className="active"
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="6.5" y="8.5" width="18" height="15" rx="2.5" stroke="#7145D8"/>
    <path
      d="M10.3879 14.1659C10.769 14.1659 11.082 14.0311 11.3555 13.7402L10.3879 14.1659ZM10.3879 14.1659C10.007 14.1659 9.69392 14.031 9.42039 13.7401L10.3879 14.1659ZM11.7682 12.6661C11.7682 13.1 11.6301 13.448 11.3555 13.7402L11.3556 11.5918C11.63 11.884 11.7682 12.2321 11.7682 12.6661ZM10.3879 11.166C10.7688 11.166 11.0819 11.3009 11.3554 11.5916L9.42049 11.5917C9.69399 11.3009 10.0071 11.166 10.3879 11.166ZM9.00781 12.6661C9.00781 12.232 9.14591 11.8839 9.42036 11.5918L9.42038 13.7401C9.14589 13.4482 9.00781 13.1001 9.00781 12.6661Z"
      stroke="#7145D8"
    />
    <path
      d="M12.1155 17.2329C12.7078 17.863 13.7088 17.863 14.3012 17.2329L17.858 13.4495C18.0555 13.2395 18.3892 13.2395 18.5866 13.4495L21.6604 16.7193C21.7476 16.812 21.7961 16.9345 21.7961 17.0617V20.3322C21.7961 20.6083 21.5723 20.8322 21.2961 20.8322H9.50781C9.23167 20.8322 9.00781 20.6083 9.00781 20.3322V19.7283C9.00781 19.6011 9.05635 19.4786 9.14352 19.3859L11.2772 17.1163C11.4746 16.9062 11.8083 16.9062 12.0058 17.1162L12.1155 17.2329Z"
      stroke="#7145D8"
    />
  </svg>
);

const Image = ({ active }) => {
  const Icon = active ? ActiveImage : DefaultImage;

  return (
    <div className="editor-icon-svg">
      <Icon />
      <ImageOnHover />
    </div>
  );
};

export default Image;
