// Core
import React, { memo } from "react";
import { compose } from "react-apollo";
import { FormattedMessage } from "react-intl";

// Components
import DeleteCourseButton from "routes/ExpertsCabinet/CourseEdit/GeneralInfo/ActionPanel/DeleteCourseButton";
import AdminButtonGroup from "routes/ExpertsCabinet/CourseEdit/GeneralInfo/ActionPanel/AdminButtonGroup";
import ExpertButtonGroup from "routes/ExpertsCabinet/CourseEdit/GeneralInfo/ActionPanel/ExpertButtonGroup";
import Button from "components/Button";

// Other
import { isFalse, isCourseAdmin } from "utils/helpers/index";
import { withCourseEditContext } from "routes/ExpertsCabinet/CourseEdit/Context";
import { withSharedState } from "containers/SharedStateProvider/withSharedState";
import { messages } from "routes/ExpertsCabinet/CourseEdit/GeneralInfo/messages";

// Styles and Assets
import "./ActionPanel.scss";

const ActionPanel = ({
  disabled,
  roles,
  statusId,
  id,
  author,
  isCourseStatusUpdating,
  sharedStateContext: { sharedState },
  courseEditContext: {
    handleCourseSubmit,
    handleCourseDelete,
    handleCourseStatusUpdate,
  },
  handleMailSend,
}) => {
  const submitBtnIsVisible = ["MAIN_FILLING", "FINAL_REVIEW"].includes(
    statusId
  );

  const askDeleteReasons = statusId === "DEACTIVATED";
  const activeBtnIsVisible = ["READY_TO_PUBLISHING", "DEACTIVATED"].includes(
    statusId
  );
  const courseIsActive = statusId === "ACTIVE";
  const courseIsInReview =
    statusId === "FIRST_REVIEW" || statusId === "FINAL_REVIEW";
  const isCourseRejected =
    statusId === "FIRST_REJECT" || statusId === "SECOND_REJECT";

  if (
    isCourseRejected ||
    (!isCourseAdmin(roles) && statusId === "DEACTIVATED")
  ) {
    disabled = false;
  }

  const hasErrors =
    statusId !== "DRAFT" &&
    (isFalse(sharedState?.courseInfo?.isValid) ||
      isFalse(sharedState?.courseDescription?.isValid) ||
      isFalse(sharedState?.courseVariants?.isValid) ||
      isFalse(sharedState?.additionalCourseDetails?.isValid) ||
      isFalse(sharedState?.courseAdminInformation?.isValid));

  const showAdminButtonGroup = isCourseAdmin(roles);

  return (
    <div className="sk-course-action-panel">
      <div className="sk-course-action-panel__buttons">
        <div className="sk-course-action-panel__buttons__item">
          {showAdminButtonGroup ? (
            <AdminButtonGroup
              id={id}
              author={author}
              statusId={statusId}
              courseIsActive={courseIsActive}
              courseIsInReview={courseIsInReview}
              isCourseStatusUpdating={isCourseStatusUpdating}
              handleMailSend={handleMailSend}
              handleCourseStatusUpdate={handleCourseStatusUpdate}
            />
          ) : (
            <ExpertButtonGroup
              statusId={statusId}
              disabled={disabled}
              courseIsActive={courseIsActive}
              submitBtnIsVisible={submitBtnIsVisible}
              activeBtnIsVisible={activeBtnIsVisible}
              isCourseStatusUpdating={isCourseStatusUpdating}
              handleCourseSubmit={handleCourseSubmit}
              handleCourseStatusUpdate={handleCourseStatusUpdate}
            />
          )}
        </div>
        <div className="sk-course-action-panel__buttons__item">
          {!courseIsActive && (
            <DeleteCourseButton
              askDeleteReasons={askDeleteReasons}
              handleClick={handleCourseDelete}
              disabled={disabled}
            />
          )}
        </div>
      </div>
      {hasErrors && (
        <div className="sk-course-action-panel__error">
          <FormattedMessage {...messages.formFieldsError} />
        </div>
      )}
    </div>
  );
};

const enhancer = compose(memo, withSharedState, withCourseEditContext);

export default enhancer(ActionPanel);
