// Core
import React from 'react';
import { graphql, compose } from 'react-apollo';
import { injectIntl, FormattedMessage } from 'react-intl';

// Components
import RoundIcon from 'components/Icon/RoundIcon';
import Button from 'components/Button';
import Text from 'components/Text';
import TextInput from 'components/Input/Material/TextInput';
import RadioButtonGroup from 'components/RadioButtonGroup';
import ConfirmDelete from 'routes/ExpertsCabinet/components/ConfirmDelete/ConfirmDelete';
import IconWithModal from 'components/IconWithModal';

// Icons
import TrashBin from 'components/Icon/SvgCss/Quiz/TrashBin.js';

// Other
import { DRAFT, FIRST_REVIEW, FIRST_REJECT, READY_TO_PUBLISHING, DEACTIVATED, ACTIVE, REACTIVATED } from 'routes/ExpertsCabinet/ToppingEdit/enums.js';
import {TOPPING} from 'utils/enums';
import { isNull } from 'utils/helpers/index';
import { messages } from 'routes/ExpertsCabinet/CourseEdit/GeneralInfo/messages.js';
import withWidth from 'containers/HOCs/withWidth';

// Styles
import './index.scss';

const prefix = 'sk-topping-edit-actions-buttons';
const ActionButtons = ({
  width,
  status,
  isExpert,
  isAdmin,
  isValid,
  submitTopping,
  handleToppingDelete,
  intl: { formatMessage },
}) => {
  const buttonSize = width == 'xs' ? 'small' : 'large';
  const iconSize = width == 'xs' ? 'sm-button' : 'md';

  return (
    <div className={prefix}>
      {status != ACTIVE && (
        <IconWithModal
          modal={ConfirmDelete}
          modalProps={{ size: 'md', handleDelete: handleToppingDelete, }}
          icon={() => (<RoundIcon size={iconSize} type="flat" icon={<TrashBin />} />)}
        />
      )}
      {isAdmin && status == FIRST_REVIEW && (
        <>
          <Button
            color="secondary"
            variant="text"
            size={buttonSize}
            loading={false}
            // disabled={!isValid}
            onClick={submitTopping(FIRST_REJECT, true)}
          >
            {formatMessage(messages.reject)}
          </Button>
          <Button
            color="primary"
            variant="text"
            size={buttonSize}
            loading={false}
            // disabled={!isValid}
            onClick={submitTopping(READY_TO_PUBLISHING, true)}
          >
            {formatMessage(messages.approve)}
          </Button>
        </>
      )}
      {isExpert && status == DRAFT && (
        <Button
        color="secondary"
        variant="text"
        size={buttonSize}
        loading={false}
        // disabled={!isValid}
        onClick={submitTopping(FIRST_REVIEW)}
        >
          {formatMessage(messages.submit)}
        </Button>
      )}
      {isExpert && status == FIRST_REVIEW && (
        <Button
        color="secondary"
        variant="text"
        size={buttonSize}
        loading={false}
        disabled={true}
        >
          {formatMessage(messages.submit)}
        </Button>
      )}
      {isExpert && status == READY_TO_PUBLISHING && (
        <Button
        color="secondary"
        variant="text"
        size={buttonSize}
        loading={false}
        // disabled={!isValid}
        onClick={submitTopping(ACTIVE)}
        >
          {formatMessage(messages.activate)}
        </Button>
      )}
      {isExpert && status == DEACTIVATED && (
        <Button
        color="secondary"
        variant="text"
        size={buttonSize}
        loading={false}
        // disabled={!isValid}
        onClick={submitTopping(REACTIVATED)}
        >
          {formatMessage(messages.activate)}
        </Button>
      )}
      {isExpert && status == ACTIVE && (
        <Button
        color="primary"
        variant="text"
        size={buttonSize}
        loading={false}
        // disabled={!isValid}
        onClick={submitTopping(DEACTIVATED, TOPPING)}
        >
          {formatMessage(messages.deactivate)}
        </Button>
      )}
      {isAdmin && status == ACTIVE && (
        <Button
        color="primary"
        variant="text"
        size={buttonSize}
        loading={false}
        // disabled={!isValid}
        onClick={submitTopping(FIRST_REVIEW)}
        >
          {formatMessage(messages.block)}
        </Button>
      )}
    </div>
  );
};


const enhancer = compose(
  injectIntl,
  withWidth()
);

export default enhancer(ActionButtons);
