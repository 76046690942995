// Core
import React, { PureComponent } from 'react';
import { graphql } from 'react-apollo';

// Components
import Loader from 'components/Loader';
import Text from 'components/Text';
import Heading from 'components/Heading';
import Card from 'components/Card';
import HomeworksGrid from 'routes/ExpertsCabinet/components/HomeworksGrid';
import StudentsList from 'routes/ExpertsCabinet/Homeworks/HomeworksTable/StudentsList';

// Other
import LIST_STUDENTS_BY_COURSE from 'queries/Courses/expertListStudentsByCourse.gql';
import { hasLoader } from 'containers/HOCs/hasLoader';

import { FormattedMessage } from 'react-intl';
import { messages } from 'routes/ExpertsCabinet/Homeworks/HomeworksTable/messages';

// Styles and Assets
import './index.scss';

const TableHeading = ({ position }) => {
  const headings = {
    left: <FormattedMessage {...messages.name} />,
    center: <FormattedMessage {...messages.avrgMarks} />,
    right: <FormattedMessage {...messages.homework} />,
  };

  return (
    <Text size="caption" classes="sk-exp-cab-homeworks-table__heading" bold>
      {headings[position]}
    </Text>
  );
};

class HomeworksTable extends PureComponent {
  render() {
    const { studentList, error, loading, courseLectures, refetchStudentList, displayLectureNameType } = this.props;

    if (error) throw Error(error);
    const students = studentList?.items;

    return (
      <Card classes="sk-exp-cab-homeworks-table">
        <HomeworksGrid>
          <Heading variant="h3" classes="sk-exp-cab-homeworks-table__header">
            <span className="sk-exp-cab-homeworks-table__title">
              <FormattedMessage {...messages.students} />{' '}
            </span>
            <span className="sk-exp-cab-homeworks-table__counter">
              &ndash; {students?.length || 0}{' '}
              <FormattedMessage {...messages.people} />
            </span>
          </Heading>
        </HomeworksGrid>
        <div className="sk-exp-cab-homeworks-table__heading-group">
          <HomeworksGrid
            triple
            leftComponent={<TableHeading position="left" />}
            centerComponent={<TableHeading position="center" />}
            rightComponent={<TableHeading position="right" />}
          />
        </div>

        <div className="sk-exp-cab-homeworks-table__body">
          {(students?.length && !loading) ? (
            <StudentsList displayLectureNameType={displayLectureNameType} students={students} courseLectures={courseLectures} refetchStudentList={refetchStudentList} />
          ) : (
            <HomeworksGrid>
              {loading ? (
                <Loader />
              ) : (
                <Text>
                  <FormattedMessage {...messages.noStudentsFound} />
                </Text>
              )}
            </HomeworksGrid>
          )}
        </div>
      </Card>
    );
  }
}

const listStudentsByCourse = graphql(LIST_STUDENTS_BY_COURSE, {
  props: ({
    data: {
      expertListStudentsByCourse: studentList,
      refetch: refetchStudentList,
      ...ownProps
    },
  }) => ({
    studentList,
    refetchStudentList,
    ...ownProps,
  }),
  options: ({ courseId }) => ({
    pollInterval: 600000,
    variables: { input: { course_id: courseId } },
    fetchPolicy: 'network-only',
  }),
});

export default hasLoader(
  listStudentsByCourse(HomeworksTable),
  LIST_STUDENTS_BY_COURSE
);
