// Core
import React from 'react';
// import { Redirect } from 'react-router';

// Containers
import Authenticator from 'routes/Authenticator/';
// import AuthenticatorBusiness from 'routes/AuthenticatorBusiness/';
// import SSO from 'routes/Authenticator/SSO';
import Company from 'routes/Authenticator/Company';
import FederatedVerification from 'routes/Authenticator/FederatedVerification';
import PasswordReset from 'routes/PasswordReset/';
import PasswordChange from 'routes/PasswordChange/';
import PasswordUpdate from 'routes/PasswordUpdate/';
import Courses from 'routes/Courses/';
import CourseDetails from 'routes/CourseDetails/';
import EmailVerification from 'routes/EmailVerification/';
import LandingPage from 'routes/LandingPage/';
import TermsOfUse from 'routes/TermsOfUse/';
import PrivacyPolicy from 'routes/PrivacyPolicy/';
import AboutUs from 'routes/AboutUs/';
import ContactUs from 'routes/ContactUs/';
import ErrorMessage from 'containers/ErrorMessage/ErrorMessage';

// import Editor from 'routes/Editor';

import Corporate from 'routes/Corporate/Corporate';

import { ALL } from 'utils/enums';

const publicRoutes = [
  {
    path: '/:locale?',
    exact: true,
    // breadcrumb: 'Main',
    component: LandingPage,
    roles: ALL,
    subprivate: true,
    withoutBg: true,
    fullWidth: true,
  },
  {
    path: '/:locale/terms-of-use',
    exact: true,
    breadcrumb: 'Terms of Use.',
    component: TermsOfUse,
    withoutBg: true,
    fullWidth: true,
    roles: ALL,
  },
  {
    path: '/:locale/privacy-policy',
    exact: true,
    breadcrumb: 'Privacy Policy',
    component: PrivacyPolicy,
    withoutBg: true,
    fullWidth: true,
    roles: ALL,
  },
  {
    path: '/:locale/about-us',
    exact: true,
    breadcrumb: 'About us',
    component: AboutUs,
    withoutBg: true,
    fullWidth: true,
    roles: ALL,
  },
  {
    path: '/:locale/auth',
    exact: true,
    breadcrumb: 'Auth',
    component: Authenticator,
    roles: ALL,
  },
  {
    path: '/:locale/auth/federated-verification',
    exact: true,
    breadcrumb: 'Auth',
    component: FederatedVerification,
    roles: ALL,
  },
  {
    path: '/:locale/auth/signUp',
    exact: true,
    breadcrumb: 'Sign Up',
    component: () => <Authenticator activeTab="signUp" />,
    roles: ALL,
  },
  {
    path: '/:locale/auth/signin',
    exact: true,
    breadcrumb: 'Log In',
    component: () => <Authenticator activeTab="signIn" />,
    roles: ALL,
  },
  {
    path: '/:locale/auth/company/signIn',
    exact: true,
    breadcrumb: 'Log In',
    component: () => <Company activeTab="signIn" />,
    roles: ALL,
  },
  {
    path: '/:locale/auth/company/signUp',
    exact: true,
    breadcrumb: 'Log In',
    component: () => <Company activeTab="signUp" />,
    roles: ALL,
  },
  {
    path: '/:locale/auth/company',
    exact: true,
    breadcrumb: 'Log In',
    component: () => <Company activeTab="signUp" />,
    roles: ALL,
  },
  {
    path: '/:locale?/auth/verification',
    exact: true,
    breadcrumb: 'Email verification',
    component: EmailVerification,
    roles: ALL,
  },
  {
    path: '/:locale/pw-reset',
    exact: true,
    breadcrumb: 'Password reset',
    component: PasswordReset,
    roles: ALL,
  },
  {
    path: '/:locale/pw-change',
    exact: false,
    breadcrumb: 'Password change',
    component: PasswordChange,
    roles: ALL,
  },
  {
    path: '/:locale/pw-update',
    exact: false,
    breadcrumb: 'Password change',
    component: PasswordUpdate,
    roles: ALL,
  },
  {
    path: '/:locale/categories',
    exact: true,
    breadcrumb: 'Courses',
    component: Courses,
    roles: ALL,
  },
  {
    path: '/:locale/courses',
    exact: true,
    breadcrumb: 'Courses',
    component: Courses,
    roles: ALL,
  },
  {
    path: '/:locale?/courses/:category',
    exact: true,
    breadcrumb: 'Courses',
    component: Courses,
    roles: ALL,
  },
  {
    path: '/:locale?/categories/:category',
    exact: true,
    breadcrumb: 'Courses',
    component: Courses,
    roles: ALL,
  },
  {
    path: '/:locale?/categories/:category/:subcategory',
    exact: true,
    breadcrumb: 'Courses',
    component: Courses,
    roles: ALL,
  },
  {
    path: '/:locale?/courses/:category/:slug',
    exact: true,
    breadcrumb: 'Courses',
    component: CourseDetails,
    roles: ALL,
    fullWidth: true,
  },
  {
    path: '/:locale?/courses/:category/:subcategory/:slug',
    exact: true,
    breadcrumb: 'Courses',
    component: CourseDetails,
    roles: ALL,
    fullWidth: true,
  },
  {
    path: '/:locale/course-details/:id',
    exact: true,
    breadcrumb: 'Courses',
    component: CourseDetails,
    roles: ALL,
    fullWidth: true,
  },
  {
    path: '/:locale/contact-us',
    exact: true,
    breadcrumb: 'Contact Us',
    component: ContactUs,
    roles: ALL,
    fullWidth: true,
  },
  {
    path: '/:locale/something-goes-wrong',
    exact: true,
    breadcrumb: 'ErrorMessage',
    component: ErrorMessage,
    roles: ALL,
    fullWidth: true,
  },
  {
    path: '/:locale/corporate',
    exact: true,
    breadcrumb: 'Corporate',
    component: Corporate,
    roles: ALL,
    fullWidth: true,
  },
];

export default publicRoutes;
