// Core
import React from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

// Components
import FeaturesSettings from 'routes/ExpertsCabinet/CourseEdit/Details/CourseInformation/AdditionalCourseDetails/MaterialsAccessSettings/FeaturesSettings';
import AccessSettings from 'routes/ExpertsCabinet/CourseEdit/Details/CourseInformation/AdditionalCourseDetails/MaterialsAccessSettings/AccessSettings';

// Other
import { hasVariant, getVariant } from 'routes/ExpertsCabinet/CourseEdit/Details/CourseInformation/helpers.js';
import { isBlank } from 'utils/helpers';
import { messages } from 'routes/ExpertsCabinet/CourseEdit/Details/messages';
import { withCourseEditContext } from 'routes/ExpertsCabinet/CourseEdit/Context';
import { getPermissions } from 'routes/ExpertsCabinet/CourseEdit/Details/CourseInformation/AdditionalCourseDetails/MaterialsAccessSettings/helpers.js';

const MaterialsAccessSettings = ({
  disabled,
  variants,
  handleRadioButtonChange,
  courseEditContext: { handleCourseUpdate },
}) => {
  const hasAssistVariant = hasVariant({ variants, variantName: 'assist' });
  const hasConciergeVariant = hasVariant({ variants, variantName: 'concierge' });

  const handleCoureVariantsFeatureUpdate = async ({ feature }) => {
    let newVariants = [...variants];
    const hasAssistVariant = hasVariant({ variants: newVariants, variantName: 'assist' });

    if (hasAssistVariant) {
      const variant = getVariant({ variants: newVariants, variantName: 'assist' });
      variant.features = [feature];
    };

    await handleCourseUpdate({ variants: newVariants }, true);
  };

  const handleCoureVariantsAccessSettingsUpdate = async ({ permission, type }) => {
    let newVariants = [...variants];
    const hasAssistVariant = hasVariant({ variants: newVariants, variantName: 'assist'});
    const hasConciergeVariant = hasVariant({ variants: newVariants, variantName: 'concierge' });

    if (hasAssistVariant) {
      const variant = getVariant({ variants: newVariants, variantName: 'assist'});
      variant.permissions = getPermissions(type, variant.permissions, permission);
    };
    if (hasConciergeVariant) {
      const variant = getVariant({ variants: newVariants, variantName: 'concierge'});
      variant.permissions = getPermissions(type, variant.permissions, permission);
    };

    await handleCourseUpdate({ variants: newVariants }, true);
  };

  const assistVariant = getVariant({ variants, variantName: 'assist' });

  return (
    <>
      {hasAssistVariant && assistVariant?.features && (
        <FeaturesSettings
          disabled={disabled}
          feature={assistVariant.features[0]}
          handleCoureVariantsFeatureUpdate={handleCoureVariantsFeatureUpdate}
        />
      )}
      {(hasAssistVariant || hasConciergeVariant) && (
        <AccessSettings
          disabled={(hasAssistVariant && !hasConciergeVariant && assistVariant.features[0] == 'webinars') || disabled}
          variant={hasAssistVariant ? getVariant({ variants, variantName: 'assist' }) : getVariant({ variants, variantName: 'concierge' })}
          handleCoureVariantsAccessSettingsUpdate={handleCoureVariantsAccessSettingsUpdate}
        />
      )}
    </>
  );
};


export default withCourseEditContext(MaterialsAccessSettings);
