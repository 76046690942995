import React from 'react';

const Icon = ({color = '#A9B6C8'}) => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.56782 21.2591C5.04002 21.0198 4.72662 20.6599 4.64225 20.3289C4.46593 19.5045 5.08277 18.5146 6.48303 18.1553C7.06542 18.0388 7.64588 18.0396 8.2283 18.1577L8.82763 18.2791V17.6676V5.14307L18.3889 3.5879V17.5355C18.3889 18.4239 17.7099 19.2044 16.5381 19.5643C15.1248 19.9176 14.0759 19.2829 13.9011 18.4855L13.8936 18.4516L13.8816 18.4191C13.7659 18.1065 13.8407 17.6936 14.1788 17.2687C14.5112 16.851 15.0614 16.48 15.7405 16.3053C16.3228 16.1888 16.9033 16.1896 17.4857 16.3077L18.085 16.4292V15.8177V7.09636V6.49491L17.4937 6.60478L9.67053 8.05833L9.26187 8.13426V8.54991V19.5176C9.26187 20.2924 8.56321 21.1796 7.44599 21.4061L7.43419 21.4085L7.42251 21.4114C6.75392 21.5808 6.08754 21.4947 5.56782 21.2591Z" stroke={color} />
    </svg>
  );
};

export default Icon;
