import React, { memo, useEffect, useCallback } from 'react';
import { compose, graphql } from 'react-apollo';
import { useFormik } from 'formik';

// Components
import TextInput from 'components/Input/Material/TextInput';
import Card from 'components/Card';

// Other
import { mappingAlgorithm } from 'routes/AdminPanel/PlanEdit/helpers.js';
import { MAPPING_INPUT, MAPPING_OUTPUT } from 'routes/AdminPanel/Plans/SalesPlan/utils.js';
import { keysToSnake, isBlank } from  'utils/helpers/index';
import { getYupSchema, } from 'services/yup';
import { withSharedState } from 'containers/SharedStateProvider/withSharedState';

//Styles
import './index.scss';

const SalesTab = ({
  plan,
  disabled,
  handlePlanUpdate,
  sharedStateContext: { updateSharedState },
  ...props
}) => {
  const { values, errors, touched, handleBlur, handleChange, setFieldValue, validateForm, ...formikBag } = useFormik({
    initialValues: mappingAlgorithm({ mapping: MAPPING_INPUT, initValues: plan }),
    validationSchema: getYupSchema('commissionPlanEditValidationSchema'),
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true
  });

  useEffect(() => {
    updateSharedState({ commissionForm: { values, validateForm } });
  }, [values]);

  const handleInputChange = useCallback(name => (inputValue, e) => {
    setFieldValue(name, inputValue);
    if (e) e.persist();
  }, [setFieldValue]);

  const handleInputBlur = useCallback(name => async (inputValue, e) => {
    if (e) {
      e.persist();
      handleBlur(e);
    };

    const errors = await validateForm(values);
    const hasError = errors[name];

    if (!hasError) {
      let input = MAPPING_OUTPUT[name];

      if (typeof input == 'string') {
        input = { [input]: values[name] };
      } else if (typeof input == 'function') {
        input = input(plan, inputValue);
      };

      console.log('update plan input', input);

      try {
        if (!isBlank(input) && !isBlank(plan?.id)) {
          handlePlanUpdate({ id: plan?.id, ...keysToSnake(input) });
        };
      } catch(err) {
        throw err;
      };
    };
  }, [plan, errors, values, handleBlur, handlePlanUpdate]);

  return (
    <Card classes='sk-admin-plans-sale'>
      <TextInput
        name="titleRU"
        value={values?.titleRU || ""}
        onChange={handleInputChange('titleRU')}
        onBlur={handleInputBlur('titleRU')}
        label='Name (ru)'
        disabled={disabled}
        error={touched.titleRU && Boolean(errors.titleRU)}
        helperText={touched.titleRU ? errors.titleRU : ''}
      />
      <TextInput
        name="titleEN"
        value={values?.titleEN || ""}
        onChange={handleInputChange('titleEN')}
        onBlur={handleInputBlur('titleEN')}
        label='Name (en)'
        disabled={disabled}
        error={touched.titleEN && Boolean(errors.titleEN)}
        helperText={touched.titleEN ? errors.titleEN : ''}
      />
      <TextInput
        adornment='%'
        type="number"
        name="commissionPrice"
        value={values?.commissionPrice}
        onChange={handleInputChange('commissionPrice')}
        onBlur={handleInputBlur('commissionPrice')}
        label='Sales commission'
        disabled={disabled}
        error={touched.commissionPrice && Boolean(errors.commissionPrice)}
        helperText={touched.commissionPrice ? errors.commissionPrice : ''}
      />
    </Card>
  );
};
const enhancer = compose(
  memo,
  withSharedState
);

export default enhancer(SalesTab);
