// Core
import React from 'react';
import PropTypes from 'prop-types';

// Components
import DropDown from 'components/UserDropdown';

const FlatDropDown = ({ children, component, ...rest }) => (
  <DropDown component={component} {...rest}>
    {children}
  </DropDown>
);

export default FlatDropDown;
