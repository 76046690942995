import React, { useEffect,  } from 'react';
import { injectIntl } from 'react-intl';

// Components
import Loader from 'components/Loader';
import Intl from 'services/i18n/instance/index';

const GlobalIntl = ({children, intl}) => {
  const globalIntl = Intl.get();
  const isGlobalIntlCreated = !!globalIntl.locale;

  useEffect(() => {
    Intl.set(intl);
  }, [intl])

  return (window.isServer || isGlobalIntlCreated)
    ? children
    : (<div style={{ position: 'absolute', top: '45vh', left: '50vw' }}><Loader /></div>);
};

export default injectIntl(GlobalIntl);
