// Core
import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';

// Styles and Assets
import './Requirements.scss';

// Components
import ReadMore from 'components/ReadMore';

// Other
import { FormattedMessage } from 'react-intl';
import { messages } from 'routes/CourseDetails/Requirements/messages';

const textStyles = {
  fontSize: '16px',
  lineHeight: '24px',
  fontFamily: 'Roboto',
  color: '#6D7C90',
  margin: 0,
  padding: 0,
  whiteSpace: 'break-spaces'
};

const Requirements = ({ requirements }) => {
  return (
    <div className="sk-c-details-requirements">
      <h3 className="sk-c-details-requirements-title">
        <FormattedMessage {...messages.reqs} />
      </h3>
      <ReadMore lines={5} textStyles={textStyles} withToggle={true}>
        {requirements}
      </ReadMore>
    </div>
  );
};

Requirements.propTypes = {
  requirements: PropTypes.string,
};

export default memo(Requirements);
