import React from 'react';

const StarIcon = () => {
  const index = Math.random();
  return(
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id={`path-2-outside-1${index}`} maskUnits="userSpaceOnUse" x="-0.143066" y="0.856445" width="20" height="19" fill="black">
    <rect fill="white" x="-0.143066" y="0.856445" width="20" height="19"/>
    <path d="M5.63942 12.2705L4.77966 17.549C4.71113 17.9683 5.15831 18.283 5.52813 18.0771L9.99995 15.6019L14.4718 18.0778C14.8382 18.2816 15.2895 17.9731 15.2202 17.5497L14.3605 12.2712L17.9976 8.53737C18.286 8.24174 18.1205 7.74018 17.7106 7.67766L12.7041 6.91174L10.46 2.12455C10.2924 1.76708 9.7068 1.76708 9.5392 2.12455L7.29582 6.91106L2.28929 7.67698C1.87807 7.74018 1.71386 8.24106 2.00225 8.53669L5.63942 12.2705Z"/>
    </mask>
    <path d="M5.63942 12.2705L4.77966 17.549C4.71113 17.9683 5.15831 18.283 5.52813 18.0771L9.99995 15.6019L14.4718 18.0778C14.8382 18.2816 15.2895 17.9731 15.2202 17.5497L14.3605 12.2712L17.9976 8.53737C18.286 8.24174 18.1205 7.74018 17.7106 7.67766L12.7041 6.91174L10.46 2.12455C10.2924 1.76708 9.7068 1.76708 9.5392 2.12455L7.29582 6.91106L2.28929 7.67698C1.87807 7.74018 1.71386 8.24106 2.00225 8.53669L5.63942 12.2705Z" stroke="#A9B6C8" stroke-width="2" mask={`url(#path-2-outside-1${index})`}/>
    </svg>

  )
};

export default StarIcon;
