import { defineMessages } from "react-intl";

export const messages = defineMessages({
  architectureAndSpaces: {
    id: "app.containers.Footer.FooterNavigation.architectureAndSpaces",
    defaultMessage: "Architecture & Spaces ",
  },
  software: {
    id: "app.containers.Footer.FooterNavigation.software",
    defaultMessage: "Software ",
  },
  craft: {
    id: "app.containers.Footer.FooterNavigation.craft",
    defaultMessage: "Craft ",
  },
  marketingAndBusiness: {
    id: "app.containers.Footer.FooterNavigation.marketingAndBusiness",
    defaultMessage: "Marketing & Business ",
  },
  technology: {
    id: "app.containers.Footer.FooterNavigation.technology",
    defaultMessage: "Technology ",
  },
  photographyAndVideo: {
    id: "app.containers.Footer.FooterNavigation.photographyAndVideo",
    defaultMessage: "Photography & Video ",
  },
  design: {
    id: "app.containers.Footer.FooterNavigation.design",
    defaultMessage: "Design ",
  },
  illustrations: {
    id: "app.containers.Footer.FooterNavigation.illustrations",
    defaultMessage: "Illustrations ",
  },

  hr: {
    id: "app.containers.Footer.FooterNavigation.hr",
    defaultMessage: "HR",
  },
  it: {
    id: "app.containers.Footer.FooterNavigation.it",
    defaultMessage: "IT",
  },
  changeManagement: {
    id: "app.containers.Footer.FooterNavigation.changeManagement",
    defaultMessage: "Change Management",
  },
  sales: {
    id: "app.containers.Footer.FooterNavigation.sales",
    defaultMessage: "Sales",
  },
  corporateStrategy: {
    id: "app.containers.Footer.FooterNavigation.corporateStrategy",
    defaultMessage: "Corporate Strategy",
  },
  finance: {
    id: "app.containers.Footer.FooterNavigation.finance",
    defaultMessage: "Finance",
  },

  "3dAndAnimation": {
    id: "app.containers.Footer.FooterNavigation.3dAndAnimation",
    defaultMessage: "3D & Animation ",
  },
  typography: {
    id: "app.containers.Footer.FooterNavigation.typography",
    defaultMessage: "Typography ",
  },
  marketingPage: {
    id: "app.containers.Footer.FooterNavigation.marketingPage",
    defaultMessage: "Marketing Page",
  },
  marketingPageSecondCol: {
    id: "app.containers.Footer.FooterNavigation.marketingPageSecondCol",
    defaultMessage: "Legal & Privacy",
  },
  mainPage: {
    id: "app.containers.Footer.FooterNavigation.mainPage",
    defaultMessage: "Main Page",
  },
  aboutUs: {
    id: "app.containers.Footer.FooterNavigation.aboutUs",
    defaultMessage: "About Us",
  },
  contactUs: {
    id: "app.containers.Footer.FooterNavigation.contactUs",
    defaultMessage: "Contact Us",
  },
  privacyAndPolicy: {
    id: "app.containers.Footer.FooterNavigation.privacyAndPolicy",
    defaultMessage: "Privacy Policy",
  },
  coursesCatalog: {
    id: "app.containers.Footer.FooterNavigation.coursesCatalog",
    defaultMessage: "Course Catalog",
  },
  coursesCatalogSecCol: {
    id: "app.containers.Footer.FooterNavigation.coursesCatalogSecCol",
    defaultMessage: " ",
  },
  coursesCatalogThirdCol: {
    id: "app.containers.Footer.FooterNavigation.coursesCatalogThirdCol",
    defaultMessage: " ",
  },
  coursesCatalogForthCol: {
    id: "app.containers.Footer.FooterNavigation.coursesCatalogForthCol",
    defaultMessage: " ",
  },
  coursesCatalogFifthCol: {
    id: "app.containers.Footer.FooterNavigation.coursesCatalogFifthCol",
    defaultMessage: " ",
  },
  analyzeThat: {
    id: "app.containers.Footer.FooterNavigation.analyzeThat",
    defaultMessage: "Analyze that",
  },
  webBootcamp: {
    id: "app.containers.Footer.FooterNavigation.webBootcamp",
    defaultMessage: "Web Bootcamp",
  },
  designMindset: {
    id: "app.containers.Footer.FooterNavigation.designMindset",
    defaultMessage: "Design Mindset",
  },
  handsOnEnabler: {
    id: "app.containers.Footer.FooterNavigation.handsOnEnabler",
    defaultMessage: "Hands-on Enabler",
  },
  blockChainCryptoWorld: {
    id: "app.containers.Footer.FooterNavigation.blockChainCryptoWorld",
    defaultMessage: "Blockchain Crypto-World",
  },
  backToSchool: {
    id: "app.containers.Footer.FooterNavigation.backToSchool",
    defaultMessage: "Back to School",
  },
  wellBeing: {
    id: "app.containers.Footer.FooterNavigation.wellBeing",
    defaultMessage: "Well-Being",
  },
  bigDataFactory: {
    id: "app.containers.Footer.FooterNavigation.bigDataFactory",
    defaultMessage: "Big Data Factory",
  },
  computerTechnologies: {
    id: "app.containers.Footer.FooterNavigation.computerTechnologies",
    defaultMessage: "Computer Technologies",
  },
  lifestyleNHobbies: {
    id: "app.containers.Footer.FooterNavigation.lifestyleNHobbies",
    defaultMessage: "Lifestyle & Hobbies",
  },
  befriendTheMachinesIndustry: {
    id: "app.containers.Footer.FooterNavigation.befriendTheMachinesIndustry",
    defaultMessage: "Industry 4.0",
  },
  blockchain: {
    id: "app.containers.Footer.FooterNavigation.blockchain",
    defaultMessage: "Blockchain",
  },
  redesignMindset: {
    id: "app.containers.Footer.FooterNavigation.redesignMindset",
    defaultMessage: "Re-design mindset",
  },
  startups: {
    id: "app.containers.Footer.FooterNavigation.startups",
    defaultMessage: "Startups",
  },
  digitalMarketing: {
    id: "app.containers.Footer.FooterNavigation.digitalMarketing",
    defaultMessage: "Digital Marketing",
  },
  effectiveBusiness: {
    id: "app.containers.Footer.FooterNavigation.effectiveBusiness",
    defaultMessage: "Effective Business",
  },
  transferableCompetencies: {
    id: "app.containers.Footer.FooterNavigation.transferableCompetencies",
    defaultMessage: "Transferable Competencies",
  },
  performingArts: {
    id: "app.containers.FooterFooterNavigation.performingArts",
    defaultMessage: "Performing Arts ",
  },
  creativeCooking: {
    id: "app.containers.FooterFooterNavigation.creativeCooking",
    defaultMessage: "Creative Cooking ",
  },
  courseCreation: {
    id: "app.containers.Footer.FooterNavigation.courseCreation",
    defaultMessage: "Course creation",
  },
  startCourseCreation: {
    id: "app.containers.Footer.FooterNavigation.startCourseCreation",
    defaultMessage: "Start Course Creation",
  },
  draft: {
    id: "app.containers.Footer.FooterNavigation.draft",
    defaultMessage: "Draft",
  },
  calendar: {
    id: "app.containers.Footer.FooterNavigation.calendar",
    defaultMessage: "Calendar",
  },
  howToCreateCourse: {
    id: "app.containers.Footer.FooterNavigation.howToCreateCourse",
    defaultMessage: "How to create course",
  },
  studentAccount: {
    id: "app.containers.Footer.FooterNavigation.studentAccount",
    defaultMessage: "Student's Manage Account",
  },
  personalInfo: {
    id: "app.containers.Footer.FooterNavigation.personalInfo",
    defaultMessage: "Personal information",
  },
  changePassword: {
    id: "app.containers.Footer.FooterNavigation.changePassword",
    defaultMessage: "Change Password",
  },
  deleteAccount: {
    id: "app.containers.Footer.FooterNavigation.deleteAccount",
    defaultMessage: "Delete Account",
  },
  becomeAnExpert: {
    id: "app.containers.Footer.FooterNavigation.becomeAnExpert",
    defaultMessage: "Become an Expert",
  },
  expertAccount: {
    id: "app.containers.Footer.FooterNavigation.expertAccount",
    defaultMessage: "Expert's Manage Account",
  },
  expertsProfile: {
    id: "app.containers.Footer.FooterNavigation.expertsProfile",
    defaultMessage: "Expert's Profile",
  },
  policy: {
    id: "app.containers.Footer.FooterNavigation.policy",
    defaultMessage: "Policy",
  },
  bankDetails: {
    id: "app.containers.Footer.FooterNavigation.bankDetails",
    defaultMessage: "Bank Details",
  },
  siteMap: {
    id: "app.containers.Footer.FooterNavigation.siteMap",
    defaultMessage: "Site Map",
  },
  termsOfUse: {
    id: "app.containers.Footer.FooterNavigation.termsOfUse",
    defaultMessage: "Terms of Use",
  },
  forExpert: {
    id: "app.containers.Footer.FooterNavigation.forExpert",
    defaultMessage: "For Expert",
  },
  teachOnline: {
    id: "app.containers.Footer.FooterNavigation.teachOnline",
    defaultMessage: "Teach Online",
  },
});
