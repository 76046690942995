// Core
import React, { memo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { compose } from 'react-apollo';

// Components
import Button from 'components/Button';
import DeactivationWarningModal from 'routes/ExpertsCabinet/CourseEdit/GeneralInfo/ActionPanel/ExpertButtonGroup/DeactivationWarningModal';
import UploadUserPhotoModal from 'routes/ExpertsCabinet/CourseEdit/GeneralInfo/ActionPanel/UploadUserPhotoModal';
import BuySubscriptionModal from 'routes/ExpertsCabinet/CourseEdit/GeneralInfo/ActionPanel/BuySubscriptionModal';
import SubscripitonCourseLimit from 'routes/ExpertsCabinet/CourseEdit/GeneralInfo/ActionPanel/SubscripitonCourseLimit';

// Other
import { COURSE_NEXT_STATUSES_EXPERT } from 'utils/enums';
import { messages } from 'routes/ExpertsCabinet/CourseEdit/GeneralInfo/messages';
import { withModal } from 'containers/ModalProvider/withModal';
import { withAuthenticatedUser } from 'containers/AuthenticatedUserProvider/withAuthenticatedUser';
import { isNull, isBlank } from 'utils/helpers/index';
import { withLanguage } from 'containers/LanguageProvider/withLanguage'

import './ExpertButtonGroup.scss';

const getNextCourseStatus = statusId => COURSE_NEXT_STATUSES_EXPERT[statusId];

const ExpertButtonGroup = ({
  statusId,
  submitBtnIsVisible,
  disabled,
  activeBtnIsVisible,
  courseIsActive,
  isCourseStatusUpdating,
  handleCourseSubmit,
  handleCourseStatusUpdate,
  modalContext: { showModal, hideModal },
  userContext: { profile, getProfile, },
  languageContext: { language }
}) => {
  const submitCourse = useCallback(() => {
    const nextStatus = getNextCourseStatus(statusId);
    handleCourseSubmit(nextStatus);
  }, [statusId, handleCourseSubmit]);

  const deactivateCourse = async () => {
    hideModal();
    await handleCourseStatusUpdate('DEACTIVATED', '');
  };
  const showDeactivateWarning = () => showModal(DeactivationWarningModal, { size: 'lg', deactivateCourse, isCourseStatusUpdating });


  const activateCourse = useCallback(() => {
    const hasNotExpertUploadPhoto = (isNull(profile?.photo) || !profile?.photo) && activeBtnIsVisible && profile.role === 'expert';

    const validateExpertSubscriptionMaxCourses = () => {
      const isMaxCoursesLimitExceeded = !isBlank(profile?.subscription) && profile?.totalActiveCourses >= profile?.subscription?.maxCourses && profile?.subscription.type == 'constructor';

      if (isMaxCoursesLimitExceeded) {
        showModal(SubscripitonCourseLimit, {
          maxCourses: profile?.subscription?.maxCourses,
          size: 'md',
        });
      } else {
        submitCourse();
      };
    };

    const validateExpertSubscriptionExist = () => {
      const hasExpertBoughtSubscription = !isBlank(profile?.subscription) && profile?.subscription?.status == 'active';

      if (!hasExpertBoughtSubscription) {
        showModal(BuySubscriptionModal, {
          size: 'md',
        });
      } else {
        validateExpertSubscriptionMaxCourses();
      };
    };

    if (hasNotExpertUploadPhoto) {
      showModal(UploadUserPhotoModal, {
        size: 'md',
        activateCourse: validateExpertSubscriptionExist,
        profile,
        getProfile,
        close: hideModal,
      });
    } else {
      validateExpertSubscriptionExist();
    };
  }, [submitCourse, profile?.subscription, profile?.photo, profile?.totalActiveCourses, profile?.role, activeBtnIsVisible]);

  return (
    <div className="sk-expert-button-group">
      {submitBtnIsVisible && (
        <Button
          color="secondary"
          variant="text"
          disabled={disabled}
          loading={isCourseStatusUpdating}
          onClick={submitCourse}
        >
          <FormattedMessage {...messages.submit} />
        </Button>
      )}
      {activeBtnIsVisible && (
        <Button
          color="secondary"
          variant="text"
          disabled={disabled}
          loading={isCourseStatusUpdating}
          onClick={activateCourse}
        >
          <FormattedMessage {...messages.activate} />
        </Button>
      )}
      {courseIsActive && (
        <div className="sk-course-action-panel__item_button">
          <Button
            color="primary"
            variant="text"
            size="small"
            loading={isCourseStatusUpdating}
            onClick={showDeactivateWarning}
          >
            <FormattedMessage {...messages.deactivate} />
          </Button>
        </div>
      )}
    </div>
)};

const enhancer = compose(
  memo,
  withModal,
  withAuthenticatedUser,
  withLanguage
);

export default enhancer(ExpertButtonGroup);
