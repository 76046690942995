// Core
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { compose } from 'react-apollo';

// Components
import WebinarSessionCreator from 'routes/PublisherWebinar/WebinarSessionCreator/';

import WebinarGrid from 'components/Webinar/WebinarGrid';

// Styles and Assets
import './PublisherWebinar.scss';

const PublisherWebinar = ({ match }) => (
  <div className="sk-pub-webinar ">
    <WebinarGrid>
      <WebinarSessionCreator
        eventId={match.params?.eventId}
        courseId={match.params?.courseId}
      />
    </WebinarGrid>
  </div>
);

PublisherWebinar.propTypes = {
  match: PropTypes.object,
};

const enhancer = compose(
  memo,
  withRouter
);

export default enhancer(PublisherWebinar);
