// Core
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';

// Components
import WebinarScreenSharing from 'components/Icon/Svg/WebinarScreenSharing';
// import WebinarScreenSharingOff from 'components/Icon/Svg/WebinarScreenSharing';

// Other
import withAction from 'components/Icon/withAction';

const WebinarScreenSharingButton = ({ handleClick, source }) => (
  <button type="button" onClick={handleClick}>
    {source === 'screen' ? (
      <WebinarScreenSharing active />
    ) : (
      <WebinarScreenSharing active={false} />
    )}
  </button>
);

WebinarScreenSharingButton.propTypes = {
  handleClick: PropTypes.func,
  source: PropTypes.string,
};

const enhancer = compose(memo, withAction);

export default enhancer(WebinarScreenSharingButton);
