// Core
import React, { useState } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

// Components
import Text from 'components/Text';
import StudentHomework from 'routes/StudentsCabinet/components/StudentHomework';
import Duration from 'components/Duration/Duration';
import ReadMore from 'components/ReadMore';
import PCMaterial from 'routes/StudentsCabinet/PurchasedCourses/PurchasedCoursesLecture/PCDetails/PCMaterial';

// GraphQL
import LIST_INTERACTION_LEVELS from 'queries/Courses/listInteractionLevels.gql';

// Other
import { DarkGrey, White, Purple } from 'components/Icon/color';
import { COURSE_DESC_LENGTH, ACTIVE_PURCHASED_COURSE } from 'utils/enums';
import { messages } from 'routes/StudentsCabinet/PurchasedCourses/PurchasedCoursesLecture/PCDetails/messages';
import { compareSortNumber, isBlank } from 'utils/helpers/index';

// Styles and Assets
import './PCDetails.scss';

const textStyles = {
  fontSize: '16px',
  lineHeight: '21px',
  margin: 0,
  padding: 0,
  display: 'flex',
  color: '#444444',
  whiteSpace: 'pre-line'
};

const PCDetails = ({
  lecture,
  isLectureDisabled,
  isHomeworkDisabled,
  homeworksAccessSetting,
  courseId,
  course: {
    variants
  },
  courseStudyData,
  courseStudyData: {
    id: purchasedCourseId,
    courseStatus,
    homeworksData,
    variantName,
  },
  homeworkNumber,
  refetchStudentCourse,
  viewedMaterials,
  lastSeenMaterial,
}) => {
  const [isFullExpertBioVisible, setFullExpertBioVisible] = useState(false)

  const variantSettings = variants.find(({ name }) => name === 'assist');

  const isReadMoreBtnVisible =
    !!lecture.description &&
    !isFullExpertBioVisible &&
    lecture.description.length >= COURSE_DESC_LENGTH;

  const isHomeworkAvailable = !isBlank(lecture?.homework)
    && (variantName === 'concierge'
    || variantSettings?.features.includes('homeworks'));

  return (
    <div className="sk-pc-details">
      <div
        className={classNames('sk-pc-details__article', {
          '__no-homework': !isHomeworkAvailable,
        })}
      >
        <div className="sk-pc-details__article__desc">
          <ReadMore lines={2} textStyles={textStyles} withToggle>
            {lecture?.description}
          </ReadMore>
        </div>
        <div className="sk-pc-details__article__item">
          <Text classes="sk-subtitle">
            <FormattedMessage {...messages.studyMats} />
          </Text>
          {lecture.materials.sort(compareSortNumber)?.map((item, index) => (
            <PCMaterial
              key={`pcmaterial-${index}`}
              material={item}
              lectureId={lecture?.id}
              isLectureDisabled={isLectureDisabled}
              courseId={courseId}
              purchasedCourseId={purchasedCourseId}
              interactionLevel={variantName}
              viewedMaterials={viewedMaterials}
              lastSeenMaterial={lastSeenMaterial}
            />
          ))}
        </div>
      </div>
      {isHomeworkAvailable && (
        <div className="sk-pc-details__homework">
          <StudentHomework
            homework={lecture.homework}
            lectureId={lecture.id}
            purchasedCourseId={purchasedCourseId}
            courseId={courseId}
            homeworksData={homeworksData}
            homeworkNumber={homeworkNumber}
            isHomeworkAvailable={!isHomeworkDisabled}
            homeworksAccessSetting={homeworksAccessSetting}
            refetchStudentCourse={refetchStudentCourse}
          />
        </div>
      )}
    </div>
  );
}

export default PCDetails;
