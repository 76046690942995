import React from 'react';
import { Red, Grey2 } from 'components/Icon/color';

const Author = ({ height = 22, width = 19, stroke = '#444444' }) => (
  <svg width={width} height={height} viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M9.08494 9.54343C10.2967 9.54343 11.3159 9.11213 12.1754 8.23438C13.0377 7.35393 13.4631 6.30546 13.4631 5.05822C13.4631 3.81119 13.0376 2.76265 12.1755 1.88207C11.3157 1.00444 10.2965 0.573242 9.08494 0.573242C7.87329 0.573242 6.85394 1.00449 5.99462 1.88198C5.13229 2.76264 4.70683 3.81111 4.70683 5.05822C4.70683 6.30551 5.13229 7.35395 5.99456 8.23434C6.85436 9.11214 7.8735 9.54343 9.08494 9.54343Z" stroke={stroke}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M17.5409 16.4096C17.5122 15.988 17.4545 15.5293 17.3677 15.0348C17.2818 14.5459 17.1736 14.0939 17.0435 13.6787C16.9177 13.2774 16.7483 12.8852 16.5349 12.5019C16.329 12.1317 16.0956 11.8204 15.8354 11.5661C15.5891 11.3255 15.2849 11.1312 14.9185 10.9829C14.5543 10.8358 13.6409 10.7567 13.6957 10.7614C14.3113 10.8135 13.639 10.8367 13.4706 10.9487C13.1899 11.1356 12.8699 11.3463 12.5063 11.5834C12.1044 11.8452 11.5966 12.0756 10.983 12.2779C10.3548 12.4853 9.72142 12.5895 9.08448 12.5895C8.44771 12.5895 7.81424 12.4853 7.18644 12.278C6.57278 12.0756 6.06499 11.8453 5.66251 11.5832C5.30079 11.3472 4.98048 11.1364 4.6988 10.9489C4.53009 10.8366 4.38612 10.7614 4.47345 10.7614C4.61231 10.7614 3.61488 10.8358 3.25104 10.9829C2.88446 11.1311 2.58014 11.3255 2.33405 11.5659C2.07371 11.8205 1.8403 12.1319 1.63444 12.5019C1.42133 12.8849 1.25191 13.2773 1.12605 13.6786C0.996024 14.0937 0.887905 14.5457 0.801947 15.0349C0.715073 15.5288 0.657442 15.9869 0.628756 16.4092C0.599601 16.839 0.585022 17.2801 0.585022 17.7326C0.585022 18.6395 0.843064 19.3235 1.35399 19.8203C1.8693 20.3211 2.55893 20.5732 3.45609 20.5732H14.7139C15.611 20.5732 16.3005 20.3211 16.8159 19.8203C17.3271 19.3235 17.585 18.6398 17.585 17.7327C17.5849 17.2786 17.5703 16.8377 17.5409 16.4096Z" stroke={stroke}/>
  </svg>
);

export default Author;
