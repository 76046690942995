import { SUBMITTED_PREVIOUS_HOMEWORK, REVIEWED_MARK_MORE_THAN_60 } from 'utils/enums';
import { isBlank } from 'utils/helpers';

const validateReviewedHomework = (homework) => {
  return !isBlank(homework) && homework?.step == 4 && homework?.review?.mark >= 60;
};

const validateSubmittedHomework = (homework) => {
  return !isBlank(homework) && homework?.step >= 3;
};

export const getViewEditAccess = ({ currentLecture, lecturesWithHomework, accessSettings, homeworks }) => {
  let isDisabled = false;
  const reversedLecturesWithHomework = [...lecturesWithHomework].reverse();

  if (accessSettings === SUBMITTED_PREVIOUS_HOMEWORK) {
    const lastSubmittedLectureWithHomework = reversedLecturesWithHomework.find((lectureWithHomework) => {
      const homework = homeworks.find((homework) => homework?.lectureId === lectureWithHomework?.id);
      const isValid = homework ? validateSubmittedHomework(homework) : false;
      return isValid;
    });
    const lastSubmittedLectureWithHomeworkIndex = lecturesWithHomework.indexOf(
      lastSubmittedLectureWithHomework
    );
    const nextAfterLastSubmittedLectureWithHomework =
      lecturesWithHomework[lastSubmittedLectureWithHomeworkIndex + 1];
    const isAnyHomeworkSubmitted = lastSubmittedLectureWithHomeworkIndex !== -1;
    const firstAvailableLectureWithHomeworkOrderNumber = isAnyHomeworkSubmitted
      ? nextAfterLastSubmittedLectureWithHomework?.orderNumber
      : lecturesWithHomework && lecturesWithHomework[0]
      ? lecturesWithHomework[0].orderNumber
      : null;
    return firstAvailableLectureWithHomeworkOrderNumber < currentLecture?.orderNumber;
  } else if (accessSettings === REVIEWED_MARK_MORE_THAN_60) {
    const lastReviewedLectureWithHomework = reversedLecturesWithHomework.find((lectureWithHomework) => {
      const homework = homeworks.find((homework) => homework?.lectureId === lectureWithHomework?.id);
      const isValid = homework ? validateReviewedHomework(homework) : false;
      return isValid;
    });
    const lastReviewedLectureWithHomeworkIndex = lecturesWithHomework.indexOf(
      lastReviewedLectureWithHomework
    );
    const nextAfterLastReviewedLectureWithHomework =
      lecturesWithHomework[lastReviewedLectureWithHomeworkIndex + 1];
    const isAnyHomeworkReviewed = lastReviewedLectureWithHomeworkIndex !== -1;
    const firstAvailableLectureWithHomeworkOrderNumber = isAnyHomeworkReviewed
      ? nextAfterLastReviewedLectureWithHomework?.orderNumber
      : lecturesWithHomework[0].orderNumber;

    return firstAvailableLectureWithHomeworkOrderNumber < currentLecture?.orderNumber;
  }
  return isDisabled;
};
