// Core
import React, { memo } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

// Components
import Text from 'components/Text';
import StudentNearestWebinar from 'routes/StudentsCabinet/components/StudentNearestWebinar';
import Image from 'components/Image';
import Link from 'components/Link';
import Flag from 'components/Icon/Svg/Flag';

// Styles and Assets
import './PurchasedCoursesWebinar.scss';

import { FormattedMessage } from 'react-intl';
import { messages } from 'routes/StudentsCabinet/PurchasedCourses/PurchasedCoursesWebinar/messages';
import { sharedMessages } from 'services/i18n/sharedMessages/messages';
import { getCourseThumbnail } from 'utils/helpers';
import { withLanguage } from 'containers/LanguageProvider/withLanguage';

const getCourseFinalDate = purchasedCourse => {
  const currentMoment = Date.now();
  const timeLeft = purchasedCourse.finalDate - currentMoment;

  const days = Math.floor(moment.duration(timeLeft, 'milliseconds').asDays());
  return <Text>{days}</Text>;
};

const VARIANTS_TRANSLATION = {
  en: {
    assist: 'Assist',
    concierge: 'Concierge'
  },
  ru: {
    assist: 'Средний',
    concierge: 'Максимум'
  },
}

const CourseFinalDateTimer = withLanguage(({
  course: {
    category,
    subcategory,
    coursePhoto,
    thumbnail
  },
  purchasedCourse,
  formatMessage,
  languageContext: { language = 'en' },
}) => {
  const variantName = purchasedCourse?.variantName;

  return (
    <div className="sk-stud-web-panel-timer">
      <div className="sk-stud-web-panel-timer-preview">
        <Image src={getCourseThumbnail(thumbnail, coursePhoto)} />
      </div>
      <div className="sk-stud-web-panel-timer-description">
        <div className="sk-stud-web-panel-timer__breadcrumbs">
          <Text>
          <Link to={`/courses/${category.id}`}>{formatMessage(sharedMessages[category.title])}</Link>/
          <Link to={`/courses/${category.id}/${subcategory.id}`}>{formatMessage(sharedMessages[subcategory.title])}</Link>
          </Text>
        </div>
        <div className="sk-stud-web-panel-timer-title">
          <div className="sk-stud-web-panel-timer-title-row">
            <Flag  />
            <Text>{formatMessage(messages.timeToEndConcierge, { variantName: VARIANTS_TRANSLATION[language][variantName] })} </Text>
          </div>
          <div className="sk-stud-web-panel-timer-time">
            {getCourseFinalDate(purchasedCourse)}
            <FormattedMessage {...messages.days} />
          </div>
        </div>
      </div>
    </div>
  )
});

export const PurchasedCoursesWebinar = ({
  course,
  intl: { formatMessage },
  purchasedCourse
 }) => (
  <div className="sk-course-webinar-panel">
    <div className="sk-course-webinar-panel__webinar">
      <StudentNearestWebinar
        course={{
          id: purchasedCourse?.courseId,
          title: purchasedCourse?.courseTitle,
        }}
        mappingId={purchasedCourse?.id}
      />
    </div>
    <div className="sk-course-webinar-panel__timer">
      <CourseFinalDateTimer purchasedCourse={purchasedCourse} course={course} formatMessage={formatMessage} />
    </div>
  </div>
);

PurchasedCoursesWebinar.propTypes = {
  purchasedCourse: PropTypes.object,
};

CourseFinalDateTimer.propTypes = {
  course: PropTypes.object,
};

export default memo(injectIntl(PurchasedCoursesWebinar));
