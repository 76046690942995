import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ListArrow = ({ isOpen }) => (
  <svg
    className={classNames(
      'sk-video-controllers_list-arrow',
      isOpen ? 'open' : ''
    )}
    width="12"
    height="7"
    viewBox="0 0 12 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="close"
      d="M1.35355 0.646447C1.15829 0.451184 0.841709 0.451184 0.646447 0.646447C0.451184 0.841709 0.451184 1.15829 0.646447 1.35355L1.35355 0.646447ZM6 6L5.64645 6.35355C5.84171 6.54882 6.15829 6.54882 6.35355 6.35355L6 6ZM11.3536 1.35355C11.5488 1.15829 11.5488 0.841709 11.3536 0.646447C11.1583 0.451184 10.8417 0.451184 10.6464 0.646447L11.3536 1.35355ZM0.646447 1.35355L5.64645 6.35355L6.35355 5.64645L1.35355 0.646447L0.646447 1.35355ZM6.35355 6.35355L11.3536 1.35355L10.6464 0.646447L5.64645 5.64645L6.35355 6.35355Z"
      fill="white"
    />
    <path
      className="open"
      d="M10.2929 6.70711C10.6834 7.09763 11.3166 7.09763 11.7071 6.70711C12.0976 6.31658 12.0976 5.68342 11.7071 5.29289L10.2929 6.70711ZM6 1L6.70711 0.292893C6.31658 -0.0976314 5.68342 -0.0976314 5.29289 0.292893L6 1ZM0.292893 5.29289C-0.0976305 5.68342 -0.0976305 6.31658 0.292893 6.70711C0.683417 7.09763 1.31658 7.09763 1.70711 6.70711L0.292893 5.29289ZM11.7071 5.29289L6.70711 0.292893L5.29289 1.70711L10.2929 6.70711L11.7071 5.29289ZM5.29289 0.292893L0.292893 5.29289L1.70711 6.70711L6.70711 1.70711L5.29289 0.292893Z"
      fill="white"
    />
    />
  </svg>
);

ListArrow.propTypes = {
  isOpen: PropTypes.bool,
};

export default ListArrow;
