import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  goBack: {
    id: 'app.components.CourseDetailsHero.goBack',
    defaultMessage: 'Go back',
  },
  stars: {
    id: 'app.components.CourseCard.stars',
    defaultMessage: 'stars',
  },
  votes: {
    id: 'app.components.CourseCard.votes',
    defaultMessage: 'votes',
  }
});
