// Core
import React, { memo } from 'react';

// Components
import Text from 'components/Text';
import DocSearch from 'components/Icon/Svg/DocSearch';

import { FormattedMessage } from 'react-intl';
import { messages } from 'routes/StudentsCabinet/components/StudentHomework/messages';

// Styles and Assets
import './Step3.scss';

const Step3 = () => (
  <div className="sk-step3">
    <div className="sk-step1__header">
      <div className="sk-step1__icon">
        <DocSearch width="60" height="50" />
      </div>
      <Text size="caption" classes="sk-answerful-step__text">
        <FormattedMessage {...messages.homeworksInReview} />
      </Text>
    </div>
  </div>
);

export default memo(Step3);
