// Core
import React, { memo } from 'react';

// Components
import RoundIcon from 'components/Icon/RoundIcon';
import BasketOutline from 'components/Icon/SvgCss/BasketOutline';
import Check from 'components/Icon/Svg/Check';
import withAction from 'components/Icon/withAction';
import { Red, White, Grey2 } from 'components/Icon/color';

const DefaultStyles = {
  width: 43,
  height: 43
}

const AddToCartIcon = memo(({ action, disabled, styles=DefaultStyles }) => (
  <RoundIcon
    // disabled={disabled}
    type={disabled ? 'flat-secondary' : 'outline'}
    icon={disabled ? (
      <Check width={23} height={18} stroke={White} />
    ) : (
      <BasketOutline isHover={action} />
    )}
    styles={styles}
  />
));

export default withAction(AddToCartIcon);
