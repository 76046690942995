// Core
import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Components
import EditableText from 'components/EditableText';
import Pencil from 'components/Icon/Svg/Pencil';

// Other

// Styles and Assets
import './CourseEditableTitle.scss';

const CourseEditableTitle = memo(
  ({ title, handleInputChange, editDisabled }) => {
    const [disabled, setIsOpen] = useState(true);
    const toggleEditMode = () => setIsOpen(!disabled);

    const isFieldDisabled = disabled || editDisabled;

    return (
      <div className="sk-course-title-edit">
        <EditableText
          editableClasses={classNames('sk-course-title-edit__field', {
            disabled: isFieldDisabled,
          })}
          maxLength={115}
          name="CourseEditableTitle"
          placeholder="Enter a coure title"
          handleInputChange={handleInputChange}
          inputKey="title"
          disabled={isFieldDisabled}
          handleBlur={toggleEditMode}
          html={title}
        />
        <button
          className="sk-course-title-edit__btn"
          type="button"
          onClick={editDisabled ? null : toggleEditMode}
        >
          <Pencil width="27" height="27" />
        </button>
      </div>
    );
  }
);

CourseEditableTitle.propTypes = {
  editDisabled: PropTypes.bool,
  handleInputChange: PropTypes.func,
  title: PropTypes.string,
};

export default CourseEditableTitle;
