import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  areUSureToDeleteCourse: {
    id: 'app.components.CourseDeleteModal.areUSureToDeleteCourse',
    defaultMessage: 'Are you sure you want to delete course ',
  },
  cancel: {
    id: 'app.components.DeleteConfirmationModal.cancel',
    defaultMessage: 'Cancel',
  },
  delete: {
    id: 'app.components.DeleteConfirmationModal.delete',
    defaultMessage: 'Delete',
  },
});
