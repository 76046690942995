// Core
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Route } from 'react-router-dom';
import { compose, graphql } from 'react-apollo';
import classNames from 'classnames';

// Components
import Footer from 'containers/Footer';
import Header from 'containers/Header';
import CookieBanner from 'containers/CookieBanner';
import MainContent from 'containers/MainContent';

// Other
import privateRoutes from 'containers/Navigation/Router/routes/privateRoutes';
import publicRoutes from 'containers/Navigation/Router/routes/publicRoutes';
import MY_PROFILE_QUERY from 'queries/UserProfile/getProfile.gql';
import DOMAIN_QUERY from "queries/Settings/getDomainSettings.gql";
import { parseFetchedData } from 'utils/helpers';
import { withErrorBoundary } from 'containers/ErrorBoundary/withErrorBoundary.js';

// Styles
import './Layout.scss';

class Layout extends PureComponent {
  render() {
    const {
      role: myRole,
      withoutSidebar,
      withoutBg,
      fullWidth,
      complexContent,
      getProfile,
      getDomainSettings,
      error,
      onlyWithMainContent,
      role,
      location,
      match: { path, params: { locale } },
      ...props
    } = this.props;
    if (error) return null;

    const user = parseFetchedData(getProfile);
    const settings = parseFetchedData(getDomainSettings);

    const showOnlyMainContent = onlyWithMainContent || role === 'admin';

    return (
      <div className="sk-layout">
        <div className={classNames('sk-layout__inner')}>
          <Header user={user} settings={settings} handleMenuToggleClick={this.handleDrawerToggle} location={location} path={path} />
          <CookieBanner/>
          <MainContent
            handleDrawerToggle={this.handleDrawerToggle}
            withoutSidebar={withoutSidebar}
            withoutBg={withoutBg}
            fullWidth={fullWidth}
            complexContent={complexContent}
          >
            {publicRoutes.map(({ path, exact, component }) => (
              <Route
                key={path}
                path={path}
                exact={exact}
                component={component}
              />
            ))}

            {privateRoutes.map(
              ({ path, exact, component }) => (
                <Route
                  key={path}
                  path={path}
                  exact={exact}
                  component={component}
                />
              )
            )}
          </MainContent>
        </div>
        {!showOnlyMainContent && <Footer user={user} locale={locale} location={location} />}
      </div>
    );
  }
}

const myProfileQuery = graphql(MY_PROFILE_QUERY, {
  props: ({ data: { getProfile, error, ...ownProps } }) => ({
    getProfile,
    error,
    ...ownProps,
  }),
  options: () => ({
    fetchPolicy: 'cache-only',
  }),
});

const getDomainSettingsQuery = graphql(DOMAIN_QUERY, {
  skip: !window?.location?.host,
  props: ({ data: { getDomainSettings, error, ...ownProps } }) => ({
    getDomainSettings,
    errorSettings: error,
    ...ownProps,
  }),
  options: () => ({
    variables: { fqdn: window.location.host || "avdo.education" },
    fetchPolicy: 'cache-only',
  }),
});

Layout.propTypes = {
  error: PropTypes.object,
  getProfile: PropTypes.object,
  getDomainSettings: PropTypes.object,
  role: PropTypes.string,
  withoutBg: PropTypes.bool,
  fullWidth: PropTypes.bool,
  withoutSidebar: PropTypes.bool,
  onlyWithMainContent: PropTypes.bool,
  children: PropTypes.any,
  complexContent: PropTypes.bool,
};

const enhancer = compose(
  withRouter,
  withErrorBoundary,
  myProfileQuery,
  getDomainSettingsQuery,
);

export default enhancer(Layout);
