// Core
import React from 'react';
// import PropTypes from 'prop-types';

// Components
import Card from 'components/Card';
import PolicyText from 'containers/PolicyText/PolicyText';
import ContentHeader from 'containers/ContentHeader';

// Other

// Styles and Assets
import './ExpertAgreement.scss';

const ExpertAgreement = () => (
  <div className="sk-exp-agreement">
    <ContentHeader />
    <Card classes="sk-exp-agreement__card">
      <PolicyText />
    </Card>
  </div>
);

ExpertAgreement.propTypes = {};

export default ExpertAgreement;
