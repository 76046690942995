// Core
import React from 'react';
import { compose } from 'react-apollo';

// Components
import Heading from 'components/Heading';
import Text from 'components/Text';
import Button from 'components/Button';
import Image from 'components/Image';
import LandingPageCommentsCarousel from './LandingPageCommentsCarousel';
import Link from 'components/Link';

// styles
import './LandingPageComments.scss';

const styles = {
  backgroundImage: `url(${bgVector})`
}

// S3 External Resource
const bgVector = 'https://skilleton-assets.s3.eu-central-1.amazonaws.com/landing/testimonials-background.svg';

const LandingPageComments = ({ title, description, button }) => {
  return(
    <div className="sk-landing-page__comments">
      <Image src={bgVector} className="sk-landing-page__comments_background" />
      <div className="sk-landing-page__comments_container grid landing space-between vcenter">
        <div className="row">
          <div className="col">
            <Heading variant="h2">{title}</Heading>
            <Text>{description}</Text>
            <div className="sk-landing-page__comments_button">
              <Link to="/courses">
                <Button
                  color="secondary"
                  variant="text"
                >
                  {button}
                </Button>
              </Link>
            </div>
          </div>
          <div className="col">
              <LandingPageCommentsCarousel />
              <div className="sk-landing-page__comments_button-mobile">
                <Link to="/courses">
                  <Button
                    color="secondary"
                    variant="text"
                  >
                    {button}
                  </Button>
                </Link>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingPageComments;
