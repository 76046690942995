// Core
import React, { useCallback, useState } from 'react';
import { graphql, compose } from 'react-apollo';

// Services
import { SharedStateContext } from './SharedStateContext';

const SharedStateProvider = ({ children }) => {
  const [sharedState, setSharedState] = useState({});

  const updateSharedState = useCallback((keyValue) => {
     setSharedState(prevState => {
       const nextState = { ...prevState, ...keyValue };
       const isModified = JSON.stringify(prevState) !== JSON.stringify(nextState);
       if (isModified) {
         return nextState;
       }
       return prevState;
     });
   }, [setSharedState]);

  const cleanUp = useCallback((key) => {
    setSharedState(prevState => {
      const nextState = { ...prevState };
      delete nextState[key];
      const isModified = JSON.stringify(prevState) !== JSON.stringify(nextState);
      if (isModified) {
        return nextState;
      }
      return prevState;
    });
  }, [setSharedState]);

  return (
    <SharedStateContext.Provider
      value={{
        sharedStateContext: {
          sharedState,
          updateSharedState,
          cleanUp
        },
      }}
    >
    {React.Children.only(children)}
    </SharedStateContext.Provider>
  );
}

export default SharedStateProvider;
