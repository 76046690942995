// export const getVisibleSelectionRect = (editorState) => {
//   const selection = global.getSelection();
//   if (!selection.rangeCount) {
//     return null;
//   }
//
//   const range = selection.getRangeAt(0);
//   const boundingRect = getRangeBoundingClientRect(range);
//   const {top, right, bottom, left} = boundingRect;
//
//   // When a re-render leads to a node being removed, the DOM selection will
//   // temporarily be placed on an ancestor node, which leads to an invalid
//   // bounding rect. Discard this state.
//   if (top === 0 && right === 0 && bottom === 0 && left === 0) {
//     return null;
//   }
//
//   return boundingRect;
// };

export const getHasSelection = editorState => {
  const selection = editorState?.getSelection();
  const startKey = selection?.getStartKey();
  const startOffset = selection?.getStartOffset();
  const endKey = selection?.getEndKey();
  const endOffset = selection?.getEndOffset();

  const hasSelection = !(startKey === endKey && startOffset === endOffset);
  return hasSelection;
};

export const getSelectionContent = editorState => {
  const selectionState = editorState.getSelection();
  const anchorKey = selectionState.getAnchorKey();
  const currentContent = editorState.getCurrentContent();
  const currentContentBlock = currentContent.getBlockForKey(anchorKey);
  const start = selectionState.getStartOffset();
  const end = selectionState.getEndOffset();

  const selectedText = currentContentBlock.getText().slice(start, end);
  return selectedText;
};

const createStore = initialState => {
  let state = initialState || {};
  const listeners = {};

  const subscribeToItem = (key, callback) => {
    listeners[key] = listeners[key] || [];
    listeners[key].push(callback);
  };

  const unsubscribeFromItem = (key, callback) => {
    listeners[key] = listeners[key].filter(listener => listener !== callback);
  };

  const updateItem = (key, item) => {
    state = {
      ...state,
      [key]: item,
    };
    if (listeners[key]) {
      listeners[key].forEach(listener => listener(state[key]));
    }
  };

  const getItem = key => state[key];

  return {
    subscribeToItem,
    unsubscribeFromItem,
    updateItem,
    getItem,
  };
};

export default createStore;
