import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  ifYouReachTheEnd: {
    id: 'app.components.PurchasedCourseMaterials.ifYouReachTheEnd',
    defaultMessage:
      'If you reach the end of the course please tap Complete course button for finalizing the course',
  },
  completeCourse: {
    id: 'app.components.PurchasedCourseMaterials.completeCourse',
    defaultMessage: 'Complete course',
  },
});
