import { localStore } from 'services/localStorage';
import GET_STUDENT_COURSE from 'queries/Courses/getStudentCourse.gql';

export const Query = {
  shoppingCart: async () => {
    const previousState = localStore.getItem('ShoppingCart');
    return previousState
      ? JSON.parse(localStore.getItem('ShoppingCart'))
      : null;
  },
  getCourseAuthor: async (_, { id: courseId }, { cache }) => {
    const queryData = {
      query: GET_STUDENT_COURSE,
      variables: {
        input: { id: courseId },
      },
    };

    try {
      const getCourseQuery = await cache.readQuery(queryData);

      const { email } = getCourseQuery?.getCourse?.author;
      const { title } = getCourseQuery?.getCourse;

      return {
        email,
        title,
        __typename: 'CourseAuthor',
      };
    } catch (error) {
      return false;
    }
  },
};
