import React from 'react';
import { LanguageContext } from './language-context';

export function withLanguage(Component) {
  return function LanguageComponent(props) {
    return (
      <LanguageContext.Consumer>
        {contexts => <Component {...props} {...contexts} />}
      </LanguageContext.Consumer>
    );
  };
}
