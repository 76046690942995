import { EditorState, SelectionState, Modifier, RichUtils } from 'draft-js';
// import { getSelectionContent, getHasSelection } from 'components/DraftEditor/TollbarInline/helpers';

import { LINK } from 'components/DraftEditor/enums.js';

export default ({ editorState, nextHref, text }) => {
  const contentState = editorState.getCurrentContent();
  const selection = editorState.getSelection();

  let newEditorState = null;
  // let href = '';

  if (!selection.isCollapsed()) {
    // Needed to replace existed link, not working code
    // const startKey = editorState.getSelection().getStartKey();
    // const startOffset = editorState.getSelection().getStartOffset();
    // const blockWithLink = contentState.getBlockForKey(startKey);
    //
    // let entityKey = blockWithLink.getEntityAt(startOffset);;
    // let contentStateWithEntity = contentState;
    //
    // if (entityKey) {
    //   contentStateWithEntity = contentState.replaceEntityData(
    //     entityKey,
    //     { href: nextHref }
    //   );
    // };

    const contentStateWithEntity = contentState.createEntity(LINK, 'MUTABLE', {
      href: nextHref,
    });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    const newEditorStateWithEntity = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });

    newEditorState = RichUtils.toggleLink(
      newEditorStateWithEntity,
      newEditorStateWithEntity.getSelection(),
      entityKey
    );
  } else {
    const textWithSpace = text.concat(' ');

    // create new content with text
    const newContent = Modifier.insertText(
      contentState,
      selection,
      textWithSpace
    );

    // create new link entity
    const newContentWithEntity = newContent.createEntity(
      LINK,
      'MUTABLE',
      { href: nextHref },
      false
    );
    const entityKey = newContentWithEntity.getLastCreatedEntityKey();

    // create new selection with the inserted text
    const anchorOffset = selection.getAnchorOffset();
    const newSelection = new SelectionState({
      anchorOffset,
      anchorKey: selection.getAnchorKey(),
      focusKey: selection.getAnchorKey(),
      focusOffset: anchorOffset + textWithSpace.length,
    });

    // and aply link entity to the inserted text
    const newContentWithLink = Modifier.applyEntity(
      newContentWithEntity,
      newSelection,
      entityKey
    );

    // create new state with link text
    const withLinkText = EditorState.push(
      editorState,
      newContentWithLink,
      'insert-characters'
    );

    // now lets add cursor right after the inserted link
    newEditorState = EditorState.forceSelection(
      withLinkText,
      newContent.getSelectionAfter()
    );
  }

  return newEditorState;
};
