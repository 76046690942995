import React from 'react';

export const NetflixRight = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M44.1969 22.8812L26.1993 2.35938C26.0083 2.14152 25.7313 2.01637 25.4403 2.01635L23.7741 2.01625C22.99 2.01619 22.5094 2.86678 22.9186 3.53017L34.937 23.0119C35.1298 23.3244 35.1341 23.7163 34.9483 24.0323L22.865 44.5853C22.4749 45.249 22.959 46.0829 23.7345 46.0829L25.4267 46.0829C25.7256 46.0829 26.0088 45.951 26.1993 45.723L44.2131 24.1693C44.5265 23.7943 44.5197 23.2492 44.1969 22.8812Z"
        fill="url(#paint0_linear)"
        stroke="url(#paint1_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="24.15"
          y1="51.8642"
          x2="-0.250647"
          y2="13.6443"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8F26AF" />
          <stop offset="0.979167" stopColor="#141685" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="24.15"
          y1="51.8642"
          x2="-0.250647"
          y2="13.6443"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8F26AF" />
          <stop offset="0.979167" stopColor="#141685" />
        </linearGradient>
      </defs>
    </svg>
  );
};
