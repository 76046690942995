// Core
import React, { PureComponent } from "react";
import { withRouter } from "react-router-dom";
import { compose, graphql } from "react-apollo";
import { injectIntl } from "react-intl";
import Collapse from "@material-ui/core/Collapse";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { Doughnut } from "react-chartjs-2";

// Components
import Tabs from "./Tabs";
import Button from "components/Button";
import Card from "components/Card";
import UserAvatar from "components/UserAvatar";
import Text from "components/Text";
import ContentHeader from "containers/ContentHeader";
import Loader from "components/Loader";
import UserInfo from "routes/AdminPanel/UserView/UserInfo/UserInfo";
import ExpertDetails from "routes/AdminPanel/UserView/ExpertDetails/ExpertDetails";
import CoursesList from "routes/AdminPanel/UserView/UserCourses/CoursesList";

// GraphQl
import BLOCK_USER from "mutations/AdminPanel/blockUser.gql";
import DELETE_USER from "mutations/AdminPanel/deleteUser.gql";
import ADMIN_GET_USER from "queries/UserProfile/adminGetUser.gql";

// Other
import { parseFetchedData, capitalize, validateUserId } from "utils/helpers";
import { EXPERT } from "utils/enums";
import { sharedMessages } from "services/i18n/sharedMessages/messages";

// Styles and Assets
import "./UserView.scss";

class UserView extends PureComponent {
  state = {
    activeTab: "userInfo",
  };

  handleSwitchTab = (nextTab) => {
    this.setState({ activeTab: nextTab });
  };

  updateUserProfileState = async () => {
    const {
      adminSuspendRestoreUser,
      profile: { enabled, id },
    } = this.props;
    try {
      const input = { id, enable: !enabled };
      await adminSuspendRestoreUser(input);
    } catch (error) {
      throw Error(error);
    }
  };

  goToUsersList = (role) => {
    const {
      history,
      match: { url, params },
    } = this.props;
    const userId = params?.id;
    const usersListUrl = url.replace(userId, "");
    history.push(usersListUrl);
  };

  deleteUser = async () => {
    const {
      deleteUser,
      profile: { id, role },
    } = this.props;
    try {
      await deleteUser({ id });
      this.goToUsersList(role);
    } catch (e) {
      console.error(e);
      throw new Error(e);
    }
  };

  incorrectUserIdelement = () => (
    <div className="sk-admin-user-view">
      <div className="sk-admin-user-view__content-header">
        <div className="sk-admin-user-view__content-header__left">
          <ContentHeader />
        </div>
      </div>
      <div className="sk-admin-user-view__content-body">
        <Text className="sk-admin-user-view__content-header__left__panel-name">
          Error occurred durring user profile view. Incorrect user id.
        </Text>
      </div>
    </div>
  );

  render() {
    const {
      match,
      match: { url, params },
    } = this.props;

    if (!validateUserId(params?.id)) {
      return this.incorrectUserIdelement();
    }

    const userRole = url.includes("experts") ? "expert" : "student";

    const {
      loading,
      profile,
      profile: { role },
      intl: { formatMessage },
    } = this.props;
    const { activeTab } = this.state;

    if (loading) return <Loader center />;
    const parsedProfile = parseFetchedData(profile);

    const isExpert = userRole === EXPERT;
    return (
      <div className="sk-admin-user-view">
        <div className="sk-admin-user-view__content-header">
          <div className="sk-admin-user-view__content-header__left">
            <ContentHeader />
            <Text
              size="small"
              className="sk-admin-user-view__content-header__left__panel-name"
            >
              {capitalize(`${userRole}'s`)} profile view
            </Text>
          </div>

          <div className="sk-admin-user-view__content-header__right__action-buttons">
            <div className="sk-admin-user-view__content-header__right__action-buttons__button">
              <Button
                transparent
                size="small"
                color="primary"
                variant="outlined"
                onClick={this.updateUserProfileState}
              >
                {profile?.enabled
                  ? formatMessage(sharedMessages.blockButton)
                  : formatMessage(sharedMessages.unblockButton)}
              </Button>
            </div>
            <div className="sk-admin-user-view__content-header__right__action-buttons__button">
              <Button
                transparent
                size="small"
                color="primary"
                variant="outlined"
                onClick={this.deleteUser}
              >
                {formatMessage(sharedMessages.deleteButton)}
              </Button>
            </div>
          </div>
        </div>
        <div className="sk-admin-user-view__content-body">
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={10}>
                <div className="sk-admin-user-view__content-body__user-info">
                  <UserAvatar
                    avatar={parsedProfile.photo}
                    firstName={parsedProfile.firstName}
                    lastName={parsedProfile.lastName}
                  />
                  <div className="sk-admin-user-view__content-body__user-info__text-group">
                    <Text
                      size="small"
                      className="sk-admin-user-view__content-body__user-info__text-group__text"
                    >
                      {parsedProfile.firstName} {parsedProfile.lastName}
                    </Text>
                    <Text size="small">{parsedProfile.email}</Text>
                  </div>
                </div>
              </Grid>
              <Grid item xs={2}>
                <Doughnut
                  options={{
                    plugins: {
                      title: {
                        display: true,
                        text: "Custom Chart Title",
                      },
                    },
                  }}
                  data={{
                    datasets: [
                      {
                        data: [1],
                        backgroundColor: ["rgb(200, 200, 200)"],
                      },
                    ],
                  }}
                />
              </Grid>
            </Grid>
          </Box>
          <Card classes="sk-admin-user-view__content-body__card">
            <Tabs handleSwitch={this.handleSwitchTab} activeTab={activeTab} />
            <Collapse
              in={activeTab === "userInfo"}
              timeout="auto"
              unmountOnExit
            >
              <UserInfo role={userRole} profile={parsedProfile} />
              {isExpert && (
                <ExpertDetails role={userRole} profile={parsedProfile} />
              )}
            </Collapse>

            <Collapse
              in={activeTab === "userCourses"}
              timeout="auto"
              unmountOnExit
            >
              <CoursesList role={userRole} profile={parsedProfile} />
            </Collapse>
          </Card>
        </div>
      </div>
    );
  }
}

const getUser = graphql(ADMIN_GET_USER, {
  skip: (props) => {
    const {
      match: { params },
    } = props;
    return !validateUserId(params?.id);
  },
  props: ({ data: { adminGetUser, error, loading, ...ownProps } }) => {
    if (loading) return { loading, profile: {} };
    if (error)
      throw new Error("Something went wrong while receiving adminGetUser");

    const { profile, cognito } = adminGetUser;

    return {
      loading,
      error,
      profile: { ...profile, ...cognito },
      ...ownProps,
    };
  },
  options: ({
    match: {
      params: { id },
    },
  }) => ({
    fetchPolicy: "cache-and-network",
    variables: { input: { id } },
  }),
});

const blockUser = graphql(BLOCK_USER, {
  props: ({ mutate }) => ({
    adminSuspendRestoreUser: (input) => mutate({ variables: { input } }),
  }),
  options: {
    refetchQueries: ["adminGetUser"],
  },
});

const deleteUser = graphql(DELETE_USER, {
  props: ({ mutate }) => ({
    deleteUser: (input) => mutate({ variables: { input } }),
  }),
  options: {
    refetchQueries: ["adminGetUser"],
  },
});

const enhancer = compose(
  injectIntl,
  withRouter,
  getUser,
  blockUser,
  deleteUser
);

export default enhancer(UserView);
