/**
 * Dislcaimer: hotjar may not work properly with SSR
 */
const {
  HOTJAR_ID,
  HOTJAR_VERSION,
} = process.env;

class HotJarService {
  hjId;
  hjVersion;

  constructor() {
    this.init();
  }

  // Initialise plugin here
  init() {
    this.hjId = HOTJAR_ID;
    this.hjVersion = HOTJAR_VERSION || 6;
  }

  // Pageview wrapper
  render() {
    if (window && !window.isServer && document && !window.hj) {

      if (!this.hjId)  {
        console.warn('HotJar disabled due to missing <hjid>.');
        return false;
      }

      const hotjar = window;
      if (!hotjar.hj) {
        hotjar.hj = () => {
          (window.hj.q=window.hj.q||[]).push(arguments)
        };
      }

      // Do we need to keep hotjar settings in window?
      hotjar._hjSettings={
        hjid: this.hjId,
        hjsv: this.hjVersion,
      };
      try {
        const documentHeader = document.getElementsByTagName('head')[0];
        const hjScript=document.createElement('script');
        hjScript.async=1;
        hjScript.src=`https://static.hotjar.com/c/hotjar-${this.hjId}.js?sv=${this.hjVersion}`;
        documentHeader.appendChild(hjScript);
        return true;
      } catch (err) {
        console.warn('HotJarService render error', err);
      }
    }
  }
};

export default new HotJarService;
