// Core
import React, { memo } from "react";
import PropTypes from "prop-types";

// Components
import FooterNavMenu from "./FooterNavMenu";

// Other
import { ALL, GUEST } from "utils/enums";

// Styles
import "./FooterNavigation.scss";

const navigationData = [
  {
    key: "marketingPage",
    list: [
      { key: "mainPage", path: "/:locale/" },
      { key: "aboutUs", path: "/:locale/about-us" },
      { key: "contactUs", path: "/:locale/contact-us" },
    ],
    roles: ALL,
  },
  {
    key: "marketingPageSecondCol",
    list: [
      { key: "privacyAndPolicy", path: "/:locale/privacy-policy" },
      { key: "termsOfUse", path: "/:locale/terms-of-use" },
      {
        key: "teachOnline",
        href:
          "https://skilleton-assets.s3.eu-central-1.amazonaws.com/public/legal/expert-demo-:locale.pdf",
        className: "sk-footer-nav-menu_link_alt",
      },
    ],
    roles: [GUEST],
  },
  {
    key: "coursesCatalog",
    list: [
      {
        key: "Operations",
        path: "/:locale/categories/operations",
      },
      {
        key: "changeManagement",
        path: "/:locale/categories/change-management",
      },
      {
        key: "hr",
        path: "/:locale/categories/hr",
      },
    ],
    roles: [GUEST],
  },
  {
    key: "coursesCatalogSecCol",
    list: [
      {
        key: "finance",
        path: "/:locale/categories/finance",
      },
      {
        key: "sales",
        path: "/:locale/categories/sales",
      },

    ],
    roles: [GUEST],
  },
  {
    key: "coursesCatalogThirdCol",
    list: [
      {
        key: "marketingAndBusiness",
        path: "/:locale/categories/marketing-and-business",
      },
      {
        key: "corporateStrategy",
        path: "/:locale/categories/corporate-strategy",
      },
    ],
    roles: [GUEST],
  },
  {
    key: "coursesCatalogForthCol",
    list: [
      {
        key: "it",
        path: "/:locale/categories/technology",
      },
    ],
    roles: [GUEST],
  },
  // {
  //   key: "courseCreation",
  //   list: [
  //     { key: "startCourseCreation", path: "/" },
  //     { key: "draft", path: "/" },
  //     { key: "calendar", path: "/" },
  //     { key: "howToCreateCourse", path: "/" }
  //   ],
  //   roles: EXPERT
  // },
  // {
  //   key: "studentAccount",
  //   list: [
  //     { key: "personalInfo", path: "/" },
  //     { key: "changePassword", path: "/" },
  //     { key: "deleteAccount", path: "/" },
  //     { key: "becomeAnExpert", path: "/" }
  //   ],
  //   roles: USERS
  // },
  // {
  //   key: "expertAccount",
  //   list: [
  //     { key: "expertsProfile", path: "/" },
  //     { key: "policy", path: "/" },
  //     { key: "bankDetails", path: "/" }
  //   ],
  //   roles: EXPERT
  // }
];
const getNavData = (role, list) =>
  list.filter(({ roles }) =>
    role ? roles.includes(role) : roles.includes(GUEST)
  );

const FooterNavigation = memo(({ role }) => (
  <div className="sk-footer-nav grid landing space-between sk-footer-nav-grid">
    <div className="sk-footer-nav-grid__menu">
      <FooterNavMenu nav={getNavData(role, navigationData)} />
    </div>
  </div>
));

FooterNavigation.defaultProps = {
  role: GUEST,
};

FooterNavigation.propTypes = {
  user: PropTypes.object,
};

export default FooterNavigation;
