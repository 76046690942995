// Core
import React, { memo, useState, useCallback } from 'react';
import { compose } from 'react-apollo';
import { injectIntl } from 'react-intl';
import { withFormik } from 'formik';
import classnames from 'classnames';
import { SelectionState } from 'draft-js';

// Components
import TextInput from 'components/Input/Material/TextInput/TextInput';
import Button from 'components/Button';

// Other
import {
  getSelectionContent,
  getHasSelection,
} from 'components/DraftEditor/TollbarInline/helpers';
// import { messages } from 'routes/Checkout/messages';
// import { sharedMessages } from 'services/i18n/sharedMessages/messages';
// import { getYupSchema, } from 'services/yup';

// Styles and Assets
import './index.scss';

const VideoInlineToolbarComponent = ({
  isValid,
  values,
  errors,
  touched,
  history,
  setFieldTouched,
  setErrors,
  handleChange,
  handleContentSubmit,
  getEditorState,
  closeToolbar,
  intl: { formatMessage },
}) => {
  const editorState = getEditorState();
  const handleAddClick = event => {
    handleContentSubmit(values);
    closeToolbar(event);
  };

  const onChangeHandler = fieldName => value => {
    handleChange(fieldName)(value);
  };

  return (
    <div className="sk-editor-inline-toolbar-link-content">
      <TextInput
        // helperText={touched.href ? errors.href : ''}
        // error={touched.href && Boolean(errors.href)}
        placeholder="Link"
        label="Link"
        value={values.href}
        name="href"
        onChange={onChangeHandler('href')}
        onBlur={() => setFieldTouched('href', true, false)}
      />
      <Button onClick={handleAddClick}>Add</Button>
    </div>
  );
};

const formikStateManagement = withFormik({
  enableReinitialize: true,
  mapPropsToValues: props => ({ href: '' }),
  displayName: 'VideoInlineToolbarComponentForm',
  // validationSchema: () => getYupSchema('discountApplyValidationSchema'),
  // validateOnChange: true,
  // validateOnBlur: true,
});

const enhancer = compose(memo, injectIntl, formikStateManagement);

export default enhancer(VideoInlineToolbarComponent);
