import { parseEntityDuration, isBlank } from 'utils/helpers';
import { AVAILABLE_HOMEWORK_ACCESS_OPTIONS, AVAILABLE_LECTURE_ACCESS_OPTIONS } from 'routes/ExpertsCabinet/CourseEdit/Details/CourseInformation/AdditionalCourseDetails/enums.js';

export const getTimeToCompetion = timeToCompletion =>
  parseEntityDuration(timeToCompletion, 'milliseconds', 'asDays');

export const handleTimeToCompletionChange = (handler, inputValue, evt) => {
  const durationInMs = parseEntityDuration(
    inputValue,
    'days',
    'asMilliseconds'
  );

  const value = durationInMs && durationInMs > 0 ? durationInMs : '';
  return handler('timeToCompletion')(value, evt);
};

export const getHomeworkAccessSettingsOptions = value => {
  const newAvailableHomeworksOptions = [...AVAILABLE_HOMEWORK_ACCESS_OPTIONS];
  newAvailableHomeworksOptions.forEach(option=>{
    option.disabled = false;
  })
  const chooseArrayItem = AVAILABLE_LECTURE_ACCESS_OPTIONS.filter(item=>item.value === value)[0];
  const indexOfItem = AVAILABLE_LECTURE_ACCESS_OPTIONS.indexOf(chooseArrayItem);
  newAvailableHomeworksOptions.forEach((option, index)=>{
    if(index < indexOfItem){
      option.disabled = true;
    }
  })

  return newAvailableHomeworksOptions;
};

export const hasVariant = ({ variants = [], variantName }) =>
  !isBlank(variants) && variants.some(variant => variant.name === variantName);

export const getVariant = ({ variants = [], variantName }) =>
  !isBlank(variants) && variants.find(variant => variant.name === variantName);

export const formatPriceInputValue = (price) => {
  if (Number.isInteger(price)) {
    return price;
  }
  return null;
}
