// Core
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

// Components
import Text from 'components/Text';

// Other
import { renderTrimmedText } from 'utils/helpers';

import { FormattedMessage } from 'react-intl';
import { messages } from 'routes/ExpertsCabinet/components/HWLectureTitle/messages';
import { sharedMessages } from 'services/i18n/sharedMessages/messages';

// Styles and Assets
import './HWLectureTitle.scss';

const parseSubmittedDate = date => moment(date).format('DD.MM.YYYY');

const HWLectureTitle = ({ hwSubmitDate, orderNumber, title, displayLectureNameType }) => (
  <div className="sk-hw-lecture-title">
    <div className="sk-hw-lecture-title__name">
      <Text size="small">
        {displayLectureNameType === 'section'
          ? <FormattedMessage {...sharedMessages.section} />
          : <FormattedMessage {...sharedMessages.lecture} />}{' '}
      </Text>
      <Text size="small">{`${orderNumber + 1}.`} </Text>
      <Text size="small" bold>
        {renderTrimmedText(title, 15)}
      </Text>
    </div>
    <div className="sk-hw-lecture-title__submitted">
      <Text light size="caption" classes="sk-hw-lecture-title__submitted-text">
        <FormattedMessage {...messages.submittedOn} />{' '}
        {hwSubmitDate
          ? parseSubmittedDate(hwSubmitDate)
          : `${String.fromCharCode(8211)}`}
      </Text>
    </div>
  </div>
);

HWLectureTitle.propTypes = {
  hwSubmitDate: PropTypes.number,
  title: PropTypes.string,
  orderNumber: PropTypes.number,
};

export default memo(HWLectureTitle);
