import React, { memo } from 'react';
import { compose } from 'react-apollo';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';
import moment from 'moment';

// Components
import { IconText } from 'routes/Checkout/CheckoutLists/Subscriptions';
import Text from 'components/Text';
import Button from 'components/Button';
import Card from 'components/Card';
import Link from 'components/Link';
import CancelSubscriptionModal from 'routes/ExpertsCabinet/ExpertSubscription/ActiveSubsctiption/CancelSubscriptionModal';
import ExpiredSubscription from 'routes/ExpertsCabinet/ExpertSubscription/ActiveSubsctiption/ExpiredSubscription';

// Icons
import AvailableCourses from 'routes/ExpertsCabinet/ExpertSubscription/ActiveSubsctiption/icons/AvailableCourses.js';
import TotalPrice from 'routes/ExpertsCabinet/ExpertSubscription/ActiveSubsctiption/icons/TotalPrice.js';
import PaymentType from 'routes/ExpertsCabinet/ExpertSubscription/ActiveSubsctiption/icons/PaymentType.js';
import BillingPeriod from 'routes/ExpertsCabinet/ExpertSubscription/ActiveSubsctiption/icons/BillingPeriod.js';
import Warning from 'routes/ExpertsCabinet/ExpertSubscription/ActiveSubsctiption/icons/Warning.js';

// Other
import { withModal } from 'containers/ModalProvider/withModal';
import { withLanguage } from 'containers/LanguageProvider/withLanguage';
import { messages } from 'routes/ExpertsCabinet/ExpertSubscription/messages.js';
import { withFormattedHtmlMessage } from 'containers/HOCs/withFormattedHtmlMessage';
import { useConvertedPrice } from 'containers/CurrencyProvider/useConvertedPrice';
import { capitalize, isBlank, addSpacesBetweenEveryGroupOfThreeNumbers, getCurrencyProperties } from 'utils/helpers';

//Styles
import './index.scss';

const CANCELED_PREFIX = 'sk-expert-subscription-canceled-warning';
const CanceledSubscriptionWarning = memo(({
  title,
  expiresAt,
  formatHtmlMessage,
  formatMessage,
  ...props
}) => {
  return (
    <IconText className={`${CANCELED_PREFIX}`} icon={Warning}>
      <div className={`${CANCELED_PREFIX}__text`}>
        <Text>{formatMessage(messages.cancelSubscriptionTitle)}</Text>
        <Text size='small'>{formatHtmlMessage(messages.cancelSubscriptionDetails, { expiresAt: moment(expiresAt).format('DD.MM.YYYY'), title, })}</Text>
        <Text size='small'>{formatHtmlMessage(messages.cancelSubscriptionConsequences, { expiresAt: moment(expiresAt).add(1, 'days').format('DD.MM.YYYY') })}</Text>
      </div>
    </IconText>
  )
})

const PREFIX = 'sk-experts-cabinet-subscription-active-detail';
const SubscriptionDetail = ({ children, title, ...props }) => {
  return (
    <div className={`${PREFIX}`}>
      {title}
      <div className={`${PREFIX}__info`}>{children}</div>
    </div>
  )
};

export const TRANSLATIONS = {
  billingPeriod: {
    en: {
      annual: 'Annual commitment',
      monthly: 'Month-to-month',
    },
    ru: {
      annual: 'Оформление на год',
      monthly: 'Оформление на месяц',
    },
  }
}

const PREFIX_LIST = 'sk-experts-cabinet-subscription-active';
const ActiveSubsctiption = ({
  totalActiveCourses,
  subscription: { type, status, autorenew, maxCourses, billingPeriod, price, currency, expiresAt, plan, card },
  modalContext: { showModal, hideModal },
  match: { url },
  languageContext: { language },
  intl: { formatMessage },
  formatHtmlMessage,
  cancelSubscription,
  ...props
}) => {
  if (status == 'expired') {
    return (
      <ExpiredSubscription title={plan?.title[language]} />
    );
  };

  const openCancelSubscriptionModal = () => showModal(CancelSubscriptionModal, {
    size: 'md',
    expiresAt,
    title: plan?.title[language],
    cancelSubscription,
  });

  // Currency provider may mutate currency rate, so we have to show
  // the currency subscriber used paid to.
  const convertedPrice = addSpacesBetweenEveryGroupOfThreeNumbers(parseFloat(price).toFixed(0));
  const { sign: currencySign } = getCurrencyProperties(currency);
  const subtotalString = `${currencySign} ${convertedPrice}`;

  return (
    <Card className={PREFIX_LIST}>
      <Text>{formatMessage(messages.subscriptionTitle, { title: plan?.title[language] })}</Text>
        <SubscriptionDetail title={<IconText className={`${PREFIX}__title`} icon={AvailableCourses}>{formatMessage(messages.availableCoursesTitle)}</IconText>}>
          <Text>{formatHtmlMessage(messages.availableCourses, { totalActiveCourses: totalActiveCourses || 0, maxCourses: type == 'package' ? 'N' : maxCourses, })}</Text>
        </SubscriptionDetail>
        <SubscriptionDetail title={<IconText className={`${PREFIX}__title`} icon={TotalPrice}>{formatMessage(messages.totalPriceTitle)}</IconText>}>
          <Text>{TRANSLATIONS.billingPeriod[language][billingPeriod]} <Text size='small' bold={true}>{subtotalString}</Text></Text>
        </SubscriptionDetail>
        {!isBlank(card) && (
          <SubscriptionDetail title={<IconText className={`${PREFIX}__title`} icon={PaymentType}>{formatMessage(messages.paymentDetailsTitle)}</IconText>}>
            <Text>{formatHtmlMessage(messages.cardName, { brand: capitalize(card?.brand), last4: card?.last4, })}</Text>
            <Text>{formatHtmlMessage(messages.cardExpiration, { expiresAt: `${card?.expMonth}.${card?.expYear}` })}</Text>
            <Link to={`${url}/update-payment-details`}><Button>{formatMessage(messages.updatePaymentMethodButton)}</Button></Link>
          </SubscriptionDetail>
        )}
        <SubscriptionDetail title={<IconText className={`${PREFIX}__title`} icon={BillingPeriod}>{formatMessage(messages.expirationTitle)}</IconText>}>
          <Text bold={true}>{moment(expiresAt).format('DD.MM.YYYY')}</Text>
        </SubscriptionDetail>
        {autorenew ? (
          <Link onClick={openCancelSubscriptionModal}>{formatMessage(messages.cancelSubscription)}</Link>) : (
          <CanceledSubscriptionWarning
            title={plan?.title[language]}
            expiresAt={expiresAt}
            formatMessage={formatMessage}
            formatHtmlMessage={formatHtmlMessage}
          />
        )}
      </Card>
  );
};

const enhancer = compose(
  memo,
  withFormattedHtmlMessage,
  withRouter,
  withModal,
  withLanguage,
);

export default enhancer(ActiveSubsctiption);
