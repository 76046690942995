import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  confirmEmail: {
    id: 'app.components.AuthModal.confirmEmail',
    defaultMessage: 'Confirm your email',
  },
  pleaseCheckInbox: {
    id: 'app.components.AuthModal.pleaseCheckInbox',
    defaultMessage:
      'Please check your inbox for a confirmation email. Click the link in the email to confirm your email address',
  },
  afterClickCont: {
    id: 'app.components.AuthModal.afterClickCont',
    defaultMessage: 'After you confirm click continue',
  },
  contToLogin: {
    id: 'app.components.AuthModal.contToLogin',
    defaultMessage: 'Continue to log in',
  },
});
