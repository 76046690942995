import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  invoices: {
    id: 'app.routes.AdminPanel.CompanyView.invoices',
    defaultMessage: 'Invoices',
  },
  team: {
    id: 'app.routes.AdminPanel.CompanyView.team',
    defaultMessage: 'Team',
  },
});
