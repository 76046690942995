import { defineMessages } from "react-intl";

export const messages = defineMessages({
  cancelSubscriptionButton: {
    id: "app.routes.ExpertsCabinet.ExpertsFee.cancelSubscriptionButton",
    defaultMessage: "Cancel subscription",
  },
  selectCommissionPlanButton: {
    id: "app.routes.ExpertsCabinet.ExpertsFee.selectCommissionPlanButton",
    defaultMessage: "Get a complete access",
  },
  commissionTabTitle: {
    id: "app.routes.ExpertsCabinet.ExpertsFee.commissionTabTitle",
    defaultMessage: "Unlimited course placement",
  },
  commissionTabSecondaryTitle: {
    id: "app.routes.ExpertsCabinet.ExpertsFee.commissionTabSecondaryTitle",
    defaultMessage: "Standard split: Expert 80% / Platform 20%",
  },
  successCommissionSubscriptionTitle: {
    id:
      "app.routes.ExpertsCabinet.ExpertsFee.successCommissionSubscriptionTitle",
    defaultMessage: "Unlimited course placement has been set up!",
  },
  successCommissionSubscriptionText: {
    id:
      "app.routes.ExpertsCabinet.ExpertsFee.successCommissionSubscriptionText",
    defaultMessage:
      "You may now publish an unlimited number of courses for {price}% of sales.",
  },
  subscribeButton: {
    id: "app.routes.ExpertsCabinet.ExpertsFee.subscribeButton",
    defaultMessage: "Subscribe",
  },
  priceInMonth: {
    id: "app.routes.ExpertsCabinet.ExpertsFee.priceInMonth",
    defaultMessage: " / month",
  },
  priceInYear: {
    id: "app.routes.ExpertsCabinet.ExpertsFee.priceInYear",
    defaultMessage: " / year",
  },
  maxCoursesTitle: {
    id: "app.routes.ExpertsCabinet.ExpertsFee.maxCoursesTitle",
    defaultMessage: "Max courses",
  },
  unlimitedCourses: {
    id: "app.routes.ExpertsCabinet.ExpertsFee.unlimitedCourses",
    defaultMessage: "Unlimited",
  },
  billingPeriodTitle: {
    id: "app.routes.ExpertsCabinet.ExpertsFee.billingPeriodTitle",
    defaultMessage: "Billing period",
  },
  billingPeriodYear: {
    id: "app.routes.ExpertsCabinet.ExpertsFee.billingPeriodYear",
    defaultMessage: "Year",
  },
  billingPeriodMonth: {
    id: "app.routes.ExpertsCabinet.ExpertsFee.billingPeriodMonth",
    defaultMessage: "Month",
  },
  subscriptionTab: {
    id: "app.routes.ExpertsCabinet.ExpertsFee.subscriptionTab",
    defaultMessage: "Subscription",
  },
  commissionTab: {
    id: "app.routes.ExpertsCabinet.ExpertsFee.commissionTab",
    defaultMessage: "% of sales",
  },
  functionalityTitle: {
    id: "app.routes.ExpertsCabinet.ExpertsFee.functionalityTitle",
    defaultMessage: "Functionality",
  },
  videoLectures: {
    id: "app.routes.ExpertsCabinet.ExpertsFee.videoLectures",
    defaultMessage: "Video lectures",
  },
  homeworks: {
    id: "app.routes.ExpertsCabinet.ExpertsFee.homeworks",
    defaultMessage: "Homeworks",
  },
  educationalMaterials: {
    id: "app.routes.ExpertsCabinet.ExpertsFee.educationalMaterials",
    defaultMessage: "Study materials",
  },
  chats: {
    id: "app.routes.ExpertsCabinet.ExpertsFee.chats",
    defaultMessage: "Chats",
  },
  quizes: {
    id: "app.routes.ExpertsCabinet.ExpertsFee.quizes",
    defaultMessage: "Quizzes",
  },
  toppings: {
    id: "app.routes.ExpertsCabinet.ExpertsFee.toppings",
    defaultMessage: "Master Classes / Podcasts",
  },
  masterclasses: {
    id: "app.routes.ExpertsCabinet.ExpertsFee.masterclasses",
    defaultMessage: "Master Classes",
  },
  podcasts: {
    id: "app.routes.ExpertsCabinet.ExpertsFee.podcasts",
    defaultMessage: "Podcasts",
  },
  webinars: {
    id: "app.routes.ExpertsCabinet.ExpertsFee.webinars",
    defaultMessage: "Web Classes",
  },
  analytics: {
    id: "app.routes.ExpertsCabinet.ExpertsFee.analytics",
    defaultMessage: "Analytics",
  },
  crm: {
    id: "app.routes.ExpertsCabinet.ExpertsFee.crm",
    defaultMessage: "CRM",
  },
  teckSupport: {
    id: "app.routes.ExpertsCabinet.ExpertsFee.teckSupport",
    defaultMessage: "Fast & free technical support",
  },
  freelifetimeUpdate: {
    id: "app.routes.ExpertsCabinet.ExpertsFee.freelifetimeUpdate",
    defaultMessage: "Free lifetime update ",
  },
  traffic: {
    id: "app.routes.ExpertsCabinet.ExpertsFee.traffic",
    defaultMessage: "Traffic",
  },
  noAdministration: {
    id: "app.routes.ExpertsCabinet.ExpertsFee.noAdministration",
    defaultMessage: "Zero administration",
  },
  creationBlockTitle: {
    id: "app.routes.ExpertsCabinet.ExpertsFee.creationBlockTitle",
    defaultMessage: "You will be able to create",
  },
  usageBlockTitle: {
    id: "app.routes.ExpertsCabinet.ExpertsFee.usageBlockTitle",
    defaultMessage: "You will be able to use",
  },
});
