// Core
import React from 'react';
import PropTypes from 'prop-types';

// Components
import Text from 'components/Text';
import Clock from 'components/Icon/Svg/Clock';
import DurationBlock from 'routes/Course/components/DurationBlock';

// Styles and Assets
import './SummaryDurationBlock.scss';

// Other
import { FormattedMessage } from 'react-intl';
import { sharedMessages } from 'services/i18n/sharedMessages/messages';

const SummaryDurationBlock = ({ duration }) => (
  <div className="sk-summary-duration-block">
    <div className="sk-summary-duration-block__icon">
      <Clock width="20" height="20" red />
    </div>
    <div className='sk-summary-duration-block__time'>
      <DurationBlock duration={duration} ceil />
      <span>{' '}</span>
      <FormattedMessage {...sharedMessages.hours} />
    </div>
  </div>
);

SummaryDurationBlock.defaultProps = {
  duration: 0,
};

SummaryDurationBlock.propTypes = {
  red: PropTypes.bool,
  duration: PropTypes.number,
};

export default SummaryDurationBlock;
