// Core
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import queryString from 'query-string';

// Components
import InputWithSearch from 'components/Input/Custom/InputWithSearch';

const selector = qs => ({
  category: qs?.category,
  subcategory: qs?.subcategory,
  query: qs?.query,
  complexity: qs?.complexity || undefined,
  interactivityLevel: qs?.interactivityLevel || undefined,
  language: qs?.language || undefined,
  durationRange: qs?.durationRange || undefined,
  page: qs?.page || 0,
});

const parseSearch = location => {
  const qs = queryString.parse(location?.search);
  return selector(qs);
};

class HeaderSearch extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: parseSearch(props.location).query || '',
    };
  }

  handleInputChange = e => {
    this.setState({ inputValue: e.target.value });
  };

  handleSearch = event => {
    event.persist();
    const { history, location } = this.props;
    const { inputValue } = this.state;
    history.push({
      pathname: '/courses',
      search: `?${queryString.stringify({
        ...parseSearch(location),
        query: inputValue,
      })}`,
    });
  };

  componentDidUpdate = prevProps => {
    const { location } = this.props;
    const qs = parseSearch(location);
    if (qs.query !== parseSearch(prevProps.location).query) {
      this.setState({ inputValue: qs.query });
    }
  };

  handleSearchEnter = e => {
    if (e.key === 'Enter') {
      this.handleSearch(e);
    }
  };

  render() {
    const { placeholder } = this.props;
    const { inputValue } = this.state;

    return (
      <InputWithSearch
        placeholder={placeholder}
        value={inputValue}
        handleChange={this.handleInputChange}
        onKeyPress={this.handleSearchEnter}
      />
    );
  }
}

HeaderSearch.propTypes = {
  history: PropTypes.object,
  placeholder: PropTypes.string,
  location: PropTypes.object,
};

export default withRouter(HeaderSearch);
