// Core
import React, { useState, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

// Components
import Tabs from 'components/Tabs';

// Other

const TABS = [
  {
    label: 'Subscription',
    value: 'subscription',
  },
  {
    label: 'Sales',
    value: 'sales',
  },
];

const AdminPlansTabs = ({
  activeTab,
  disabled = false,
  handleSwitch,
  ...props
}) => {
  const handleChange = useCallback((e, value) => {
    handleSwitch(value);
  }, [handleSwitch]);

  return (
    <Tabs
      tabs={TABS}
      value={activeTab}
      handleChange={handleChange}
      disabledTab={disabled}
    />
  );
}

export default AdminPlansTabs;
