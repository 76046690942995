// Core
import React, { memo } from 'react';
import { compose } from 'react-apollo';

// Components
import Text from 'components/Text';
import Clock from 'components/Icon/Svg/Clock';
import Calendar from 'components/Icon/Svg/Calendar';
import WebinarTitle from 'components/Icon/Svg/WebinarTitle';
import CourseTitle from 'components/Icon/Svg/CourseTitle';

// Other
import withWidth from 'containers/HOCs/withWidth';
import { DarkGrey } from 'components/Icon/color';
import { FormattedMessage } from 'react-intl';
import { messages } from 'containers/Calendar/NearestWebinarBlock/NearestWebinarInfo/messages';
import { renderTrimmedText } from 'utils/helpers/index';

// Styles and Assets
import './NearestWebinarInfo.scss';

const TITLE_lENGTH_CONTORLLER = {
  xlg: 70,
  lg: 25,
  md: 35,
  sm: 35,
  xs: 60,
};

const NearestWebinarRow = ({
  icon: Icon, content, textSize, textBold
}) => (
  <div className="sk-nearest-webinar-info__row">
    <Icon />
    <Text size={textSize} bold={textBold}>{content}</Text>
  </div>
);

const NearestWebinarInfo = ({
  webinar: { course, webinarEvent },
  width: mediaWidth,
}) => {
  return (
    <div className="sk-nearest-webinar-info">
      <NearestWebinarRow
        icon={() => <WebinarTitle width="25" height="19" color={DarkGrey} />}
        content={renderTrimmedText(webinarEvent?.title, TITLE_lENGTH_CONTORLLER[mediaWidth])}
        textSize='small'
        textBold={true}
      />
      <NearestWebinarRow
        icon={() => <Calendar width="20" height="18" color={DarkGrey} />}
        content={webinarEvent?.date}
        textSize='small'
      />
      <NearestWebinarRow
        icon={() => <CourseTitle width="20" height="22" color={DarkGrey} />}
        content={course?.title}
        textSize='small'
      />
      <NearestWebinarRow
        icon={() => <Clock width="20" height="18" stroke={DarkGrey} />}
        content={webinarEvent?.time}
        textSize='small'
      />
    </div>
  )
}

const enhancer = compose(
  memo,
  withWidth()
)

export default enhancer(NearestWebinarInfo);
