import React from 'react';

import './index.scss';

const Link = props => {
  const { href } = props.contentState.getEntity(props.entityKey).getData();

  // <a href={`https://${href}`} className='sk-editor-link' target="_blank">
  return (
    <a href={href} className="sk-editor-link" target="_blank">
      {props.children}
    </a>
  );
};

export default Link;
