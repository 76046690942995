// Core
import React from 'react';
import { injectIntl } from 'react-intl';

// Components
import Text from 'components/Text';
import RadioButtonGroup from 'components/RadioButtonGroup';

// Other
import { COURSE_FEATURES_OPTIONS, } from 'routes/ExpertsCabinet/CourseEdit/Details/CourseInformation/AdditionalCourseDetails/enums.js';
import { withFormattedHtmlMessage } from 'containers/HOCs/withFormattedHtmlMessage';
import { messages } from 'routes/ExpertsCabinet/CourseEdit/Details/messages';

// Styles
import 'routes/ExpertsCabinet/CourseEdit/Details/CourseInformation/AdditionalCourseDetails/MaterialsAccessSettings/index.scss';

const FeaturesSettings = ({
  disabled,
  feature,
  formatHtmlMessage,
  handleCoureVariantsFeatureUpdate,
}) => {
  const handleChange = selectedFeature => {
    handleCoureVariantsFeatureUpdate({ feature : selectedFeature })
  };

  return (
    <div
      className='sk-course-details-setting-item'>
      <Text className="sk-course-details-setting-item_description">
        {formatHtmlMessage(messages.interactiveFunctionalityforAssist)}
      </Text>
      <RadioButtonGroup
        style={{ display: 'flex', flexDirection: 'row' }}
        disabled={disabled}
        handleChange={handleChange}
        name="assist-level-interactivity"
        options={COURSE_FEATURES_OPTIONS}
        value={feature}
        classNameRadioGroup="sk_radio-button-group"
      />
    </div>
  );
}

export default withFormattedHtmlMessage(FeaturesSettings);
