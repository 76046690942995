// Core
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

// Components
import TextInput from 'components/Input/Material/TextInput/TextInput';
import TextAreaInput from 'components/Input/Material/TextAreaInput/TextAreaInput';
import SimpleSelect from 'components/Select/SimpleSelect/SimpleSelect';
import Button from 'components/Button';
import TimePicker from 'components/TimePicker';
import DatePicker from 'components/DatePicker';

// Other
import { getTimeInterval } from 'utils/helpers';
import { FormattedMessage } from 'react-intl';
import { messages } from 'containers/Calendar/messages';

// Styles and Assets
import './CreateEventModal.scss';

class CreateEventModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      desc: '',
      title: '',
      courseId: '',
      eventDuration: 3600000,
      date: moment(props.start).format('YYYY-MM-DD'),
      time: moment()
    };
  }

  handleInputChange = name => inputValue =>
    this.setState({ [name]: inputValue });

  handleEventCreate = async () => {
    const { saveEvent } = this.props;
    const { courseId, title, desc, time, date, eventDuration } = this.state;

    const formattedDate =
      typeof date === 'string' ? date : date.format('YYYY-MM-DD');

    await saveEvent(
      courseId,
      title,
      desc,
      time.format('HH:mm'),
      formattedDate,
      eventDuration
    );
  };

  render() {
    const { listCourses } = this.props;
    const { desc, title, courseId, eventDuration, time, date } = this.state;

    const submittionIsDisabled = [desc, title, eventDuration, time, date].some(
      item => !item
    );

    return (
      <div className='sk-calendar__modal'>
        <h2>
          <FormattedMessage {...messages.createNewEvent} />
        </h2>
        <div className='sk-calendar__input'>
          <FormattedMessage {...messages.addEventName}>
            {msg => (
              <TextInput
                name='eventName'
                label={msg}
                placeholder={msg}
                value={title}
                onChange={this.handleInputChange('title')}
              />
            )}
          </FormattedMessage>
        </div>
        <div className='sk-calendar__input'>
          <FormattedMessage {...messages.eventsDescription}>
            {msg => (
              <TextAreaInput
                name='eventDescription'
                label={msg}
                placeholder={msg}
                value={desc}
                onChange={this.handleInputChange('desc')}
              />
            )}
          </FormattedMessage>
        </div>
        <div className='sk-calendar__select'>
          <FormattedMessage {...messages.chooseYourCourse}>
            {msg => (
              <SimpleSelect
                name='courseId'
                options={listCourses}
                label={msg}
                placeholder={msg}
                value={courseId}
                valueKey='id'
                labelKey='label'
                handleChange={this.handleInputChange('courseId')}
              />
            )}
          </FormattedMessage>
        </div>
        <div className='sk-calendar__datePicker'>
          <div className='sk-calendar__selectMenu'>
            <FormattedMessage {...messages.startDay}>
              {msg => (
                <DatePicker
                  label={msg}
                  name='date'
                  date={date}
                  handlePickerChange={this.handleInputChange}
                />
              )}
            </FormattedMessage>
          </div>
          <div className='sk-calendar__selectMenu'>
            <FormattedMessage {...messages.startTime}>
              {msg => (
                <TimePicker
                  label={msg}
                  name='time'
                  time={time}
                  handlePickerChange={this.handleInputChange}
                />
              )}
            </FormattedMessage>
          </div>
          <div className='sk-calendar__selectMenu'>
            <FormattedMessage {...messages.duration}>
              {msg => (
                <SimpleSelect
                  inlineStyles={{
                    form: { paddingTop: 10, marginTop: 10, marginBottom: 4 },
                    selector: { minHeight: 40 }
                  }}
                  name=''
                  options={getTimeInterval()}
                  value={eventDuration}
                  label={msg}
                  placeholder={msg}
                  handleChange={this.handleInputChange('eventDuration')}
                  labelKey='label'
                  withValidation={false}
                />
              )}
            </FormattedMessage>
          </div>
        </div>
        <div className='sk-calendar__button'>
          <Button
            variant='text'
            color='secondary'
            size='large'
            onClick={this.handleEventCreate}
            disabled={submittionIsDisabled}
          >
            <FormattedMessage {...messages.createEvent} />
          </Button>
        </div>
      </div>
    );
  }
}

CreateEventModal.propTypes = {
  listCourses: PropTypes.array,
  saveEvent: PropTypes.func,
  start: PropTypes.any
};

export default CreateEventModal;
