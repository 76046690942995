// Core
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';

// Components
import App from './App';

// Styles
import './index.scss';

// Others
import * as serviceWorker from './serviceWorker';

const isProduction = process.env.NODE_ENV === 'production';
if (isProduction) Sentry.init({ dsn: 'https://54f2d9f41de04781af83e29a83cea8bf@sentry.io/1458084' });

const root = document.getElementById('root');
if (root.hasChildNodes()) {
  try {
    ReactDOM.hydrate(<App />, root);
  } catch (err) {
    console.log(err);
  }
} else {
  ReactDOM.render(<App />, root);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
