// Core
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

// Components
import Text from 'components/Text';
import Card from 'components/Card';
import Loader from 'components/Loader';
// import OrderDetails from 'routes/Checkout/OrderDetails';
import Payment from 'routes/Checkout/Payment';
import CheckoutSubscription from 'routes/Checkout/CheckoutLists/Subscriptions';
import DiscountForm from 'routes/Checkout/components/DiscountForm';
import OrderTotal from 'routes/Checkout/components/OrderTotal';
import CheckoutLayout from 'routes/Checkout/Lauout';
import EmptyList from 'routes/Checkout/components/EmptyList';

// Other
import { useDiscount } from 'routes/Checkout/components/useDiscount.js';
import { withCurrency } from 'containers/CurrencyProvider/withCurrency';
import { messages } from 'routes/Checkout/messages';
import { withModal } from 'containers/ModalProvider/withModal';
import { useSubscription } from 'routes/Checkout/components/useSubscription.js';
import { keysToCamel, keysToSnake, } from 'utils/helpers';
import { withAuthenticatedUser } from 'containers/AuthenticatedUserProvider/withAuthenticatedUser';
import { isBlank } from 'utils/helpers';

// Styles and Assets
import './index.scss';

const PREFIX = 'sk-checkout';
const Checkout = ({
  userContext,
  // subscription = keysToCamel(DEFAULT_SUBSCRIPTION),
  currencyContext: { currency, getCurrentCurrencySign, changeDependOnCurrentCurrencyRate },
  intl: { formatMessage },
  modalContext,
  history,
  ...props
}) => {
  if (isBlank(userContext?.profile?.subscription)) {
    return (<Loader fullscreen={true} center={true} />);
  };
  const { subscription } = userContext?.profile;

  const orderItems = !isBlank(subscription) ? [subscription] : [];
  const totalPrice = (!isBlank(subscription) && subscription?.status !== 'active') ? subscription?.price : 0;

  // const { formikBag, applyDiscount, orderItems: courses, totalPriceWithDiscount } = useDiscount({ orderItems, totalPrice });
  const { loading, handleSuccesfulPayment } = useSubscription({
    currency,
    subscription,
    // discountCode: formikBag?.values?.code,
    modalContext,
    history,
  });
  // TODO: Add discount in subscriptions
  // const hasDiscount = totalPrice > totalPriceWithDiscount;
  // const isFree = totalPriceWithDiscount == 0;

  const totalPriceDependOnCurrency = changeDependOnCurrentCurrencyRate(totalPrice);
  // const totalPriceWithDiscountDependOnCurrency = changeDependOnCurrentCurrencyRate(totalPriceWithDiscount);
  const breadcrumbs = [];

  const hasSubscription = !isBlank(subscription?.type) && (subscription?.status == 'initial' || subscription?.status == 'expired');

  return (
    <CheckoutLayout breadcrumbs={breadcrumbs}>
      <div className={`${PREFIX}`}>
        {hasSubscription ? (
          <CheckoutSubscription {...subscription} />
        ) : (
          <EmptyList />
        )}
        <div className={`${PREFIX}__total`}>
          <DiscountForm
            disabled={true}
            formikBag={{}}
            applyDiscount={() => {}}
          />
          <OrderTotal
            hasDiscount={false}
            totalPrice={totalPriceDependOnCurrency}
            totalPriceWithDiscount={totalPriceDependOnCurrency}
            currencySign={getCurrentCurrencySign()}
          />
        </div>
      </div>
      <Payment
        disabled={!hasSubscription}
        loading={loading}
        currency={currency}
        strategy="subscription"
        amount={totalPriceDependOnCurrency}
        withPayPal={false}
        handleSuccesfulPayment={handleSuccesfulPayment}
      />
    </CheckoutLayout>
)};

const enhancer = compose(
  memo,
  injectIntl,
  withModal,
  withRouter,
  withCurrency,
  withAuthenticatedUser,
);

export default enhancer(Checkout);
