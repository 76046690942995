import React from 'react';
import PropTypes from 'prop-types';
import { Black } from 'components/Icon/color';

const WebinarNoEvents = () => (
  <svg width="121" height="77" viewBox="0 0 121 77" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M77.2743 68.5768H41.0463C37.9621 68.5768 35.459 66.1511 35.459 63.1914V35.9752C35.459 32.9932 37.9621 30.5898 41.0463 30.5898H47.7957C48.4215 30.5898 48.9132 31.0794 48.9132 31.7025C48.9132 32.3256 48.4215 32.8152 47.7957 32.8152H41.0463C39.2136 32.8152 37.6939 34.2394 37.6939 35.9752V63.2136C37.6939 64.9494 39.1913 66.3736 41.0463 66.3736H77.2967C79.1293 66.3736 80.649 64.9494 80.649 63.2136V43.3189C80.649 42.6958 81.1407 42.2062 81.7665 42.2062C82.3923 42.2062 82.884 42.6958 82.884 43.3189V63.2136C82.8616 66.1734 80.3585 68.5768 77.2743 68.5768Z" fill="#6D7C90"/>
<path d="M73.8327 58.5181C73.2069 58.5181 72.7152 58.0285 72.7152 57.4054C72.7152 49.9727 66.6362 43.9197 59.1716 43.9197C51.707 43.9197 45.628 49.9727 45.628 57.4054C45.628 58.0285 45.1363 58.5181 44.5105 58.5181C43.8847 58.5181 43.3931 58.0285 43.3931 57.4054C43.3931 48.7487 50.4778 41.6943 59.1716 41.6943C67.8654 41.6943 74.9501 48.7487 74.9501 57.4054C74.9501 58.0062 74.4361 58.5181 73.8327 58.5181Z" fill="#6D7C90"/>
<path d="M59.1489 40.8037C54.2321 40.8037 50.2539 36.8203 50.2539 31.9468C50.2539 27.0732 54.2544 23.0898 59.1489 23.0898C64.0434 23.0898 68.0439 27.0732 68.0439 31.9468C68.0439 36.8203 64.0657 40.8037 59.1489 40.8037ZM59.1489 25.293C55.4836 25.293 52.4888 28.2749 52.4888 31.9245C52.4888 35.5741 55.4836 38.5561 59.1489 38.5561C62.8141 38.5561 65.8089 35.5741 65.8089 31.9245C65.8089 28.2749 62.8365 25.293 59.1489 25.293Z" fill="#6D7C90"/>
<path d="M43.7058 20.3528C43.5047 20.3528 43.3036 20.3083 43.1024 20.1748L35.973 15.7241C35.459 15.3903 35.3026 14.7004 35.6154 14.1886C35.9507 13.6768 36.6435 13.521 37.1575 13.8325L44.2869 18.2832C44.801 18.6171 44.9574 19.3069 44.6445 19.8187C44.4434 20.1748 44.0634 20.3528 43.7058 20.3528Z" fill="#6D7C90"/>
<path d="M59.149 11.496C58.5455 11.496 58.0315 11.0064 58.0315 10.3833L57.9868 1.74891C57.9868 1.12581 58.4785 0.63623 59.1043 0.63623C59.7301 0.63623 60.2217 1.12581 60.2217 1.74891L60.2888 10.3833C60.2888 11.0064 59.7747 11.496 59.149 11.496C59.1713 11.496 59.1713 11.496 59.149 11.496Z" fill="#6D7C90"/>
<path d="M50.6115 13.9661C50.2316 13.9661 49.874 13.7658 49.6505 13.432L46.7898 8.71427C46.4769 8.18018 46.6334 7.51258 47.1697 7.17877C47.7061 6.86722 48.3766 7.023 48.7118 7.55708L51.5725 12.2748C51.8854 12.8089 51.729 13.4765 51.1926 13.8103C51.0138 13.9216 50.8127 13.9661 50.6115 13.9661Z" fill="#6D7C90"/>
<path d="M74.95 20.3529C74.5701 20.3529 74.2125 20.1526 73.989 19.8188C73.6761 19.2847 73.8326 18.6171 74.369 18.2833L81.7889 13.8326C82.3253 13.521 82.9957 13.6768 83.331 14.2109C83.6439 14.745 83.4874 15.4126 82.951 15.7464L75.5311 20.1971C75.3523 20.3084 75.1512 20.3529 74.95 20.3529Z" fill="#6D7C90"/>
<path d="M67.8657 13.6765C67.6422 13.6765 67.3964 13.6097 67.1952 13.454C66.7035 13.0979 66.5918 12.3858 66.9717 11.8962L70.6146 7.00042C70.9722 6.51084 71.6874 6.39958 72.1791 6.77789C72.6708 7.13395 72.7825 7.84606 72.4026 8.33564L68.7597 13.2314C68.5362 13.5207 68.2009 13.6765 67.8657 13.6765Z" fill="#6D7C90"/>
<path d="M88.3821 40.8045H74.4586C73.2293 40.8045 72.2236 39.8031 72.2236 38.5791V31.4357C72.2236 26.8292 75.9783 23.0684 80.6269 23.0684H88.3598C93.2766 23.0684 97.2771 27.0518 97.2771 31.9475C97.2771 36.8211 93.2989 40.8045 88.3821 40.8045ZM80.6269 25.2937C77.2299 25.2937 74.4586 28.0532 74.4586 31.4357V38.5791H88.3821C92.0474 38.5791 95.0198 35.6194 95.0198 31.9698C95.0198 28.298 92.025 25.316 88.3374 25.316H80.6269V25.2937Z" fill="#6D7C90"/>
<path d="M80.7608 33.2159C81.5137 33.2159 82.1241 32.6082 82.1241 31.8584C82.1241 31.1087 81.5137 30.501 80.7608 30.501C80.0078 30.501 79.3975 31.1087 79.3975 31.8584C79.3975 32.6082 80.0078 33.2159 80.7608 33.2159Z" fill="url(#webinarNoEventLinear1)"/>
<path d="M84.5152 33.2159C85.2681 33.2159 85.8785 32.6082 85.8785 31.8584C85.8785 31.1087 85.2681 30.501 84.5152 30.501C83.7622 30.501 83.1519 31.1087 83.1519 31.8584C83.1519 32.6082 83.7622 33.2159 84.5152 33.2159Z" fill="url(#webinarNoEventLinear2)"/>
<path d="M88.2925 33.2159C89.0454 33.2159 89.6558 32.6082 89.6558 31.8584C89.6558 31.1087 89.0454 30.501 88.2925 30.501C87.5396 30.501 86.9292 31.1087 86.9292 31.8584C86.9292 32.6082 87.5396 33.2159 88.2925 33.2159Z" fill="url(#webinarNoEventLinear3)"/>
<path d="M81.7442 62.3904H36.5764C35.9507 62.3904 35.459 61.9008 35.459 61.2777C35.459 60.6546 35.9507 60.165 36.5764 60.165H81.7442C82.3699 60.165 82.8616 60.6546 82.8616 61.2777C82.8616 61.9008 82.3699 62.3904 81.7442 62.3904Z" fill="#6D7C90"/>
<path d="M71.0166 74.051H48.0193C47.3935 74.051 46.9019 73.5615 46.9019 72.9384C46.9019 72.3153 47.3935 71.8257 48.0193 71.8257H71.0166C71.6424 71.8257 72.1341 72.3153 72.1341 72.9384C72.1341 73.5615 71.6424 74.051 71.0166 74.051Z" fill="#6D7C90"/>
<path d="M51.8186 73.3635C51.1929 73.3635 50.7012 72.9821 50.7012 72.4966V68.1097C50.7012 67.6241 51.1929 67.2427 51.8186 67.2427C52.4444 67.2427 52.9361 67.6241 52.9361 68.1097V72.4966C52.9361 72.9647 52.4444 73.3635 51.8186 73.3635Z" fill="#6D7C90"/>
<path d="M66.8182 73.3636C66.0546 73.3636 65.4546 72.967 65.4546 72.4623V67.9014C65.4546 67.3966 66.0546 67 66.8182 67C67.5819 67 68.1819 67.3966 68.1819 67.9014V72.4623C68.1819 72.967 67.5546 73.3636 66.8182 73.3636Z" fill="#6D7C90"/>
<circle cx="12.2727" cy="22.909" r="11.2727" stroke="#A9B6C8" strokeWidth="2"/>
<path d="M12.8184 17.9092C12.8184 17.3569 12.3706 16.9092 11.8184 16.9092C11.2661 16.9092 10.8184 17.3569 10.8184 17.9092H12.8184ZM11.8184 24.2728H10.8184C10.8184 24.8251 11.2661 25.2728 11.8184 25.2728V24.2728ZM17.2729 25.2728C17.8252 25.2728 18.2729 24.8251 18.2729 24.2728C18.2729 23.7205 17.8252 23.2728 17.2729 23.2728V25.2728ZM10.8184 17.9092V24.2728H12.8184V17.9092H10.8184ZM11.8184 25.2728H17.2729V23.2728H11.8184V25.2728Z" fill="#A9B6C8"/>
<path d="M104.545 52.3638C103.993 52.3638 103.545 52.8115 103.545 53.3638C103.545 53.9161 103.993 54.3638 104.545 54.3638V52.3638ZM105.052 55.7501L105.855 56.3468L105.052 55.7501ZM104.638 59.5629C105.05 59.9316 105.682 59.8971 106.051 59.4859C106.419 59.0746 106.385 58.4424 105.973 58.0737L104.638 59.5629ZM104.545 54.3638C104.447 54.3638 104.374 54.3462 104.339 54.336C104.301 54.3249 104.277 54.3137 104.267 54.3089C104.249 54.2999 104.253 54.2998 104.275 54.3175C104.319 54.3533 104.373 54.4148 104.41 54.4897C104.444 54.558 104.46 54.6293 104.453 54.7106C104.445 54.7905 104.41 54.9387 104.25 55.1535L105.855 56.3468C106.584 55.3657 106.582 54.3672 106.202 53.6014C106.028 53.2503 105.786 52.9708 105.545 52.7723C105.424 52.6727 105.289 52.5821 105.145 52.5118C105.021 52.4514 104.806 52.3638 104.545 52.3638V54.3638ZM104.25 55.1535C103.166 56.6108 103.512 58.5526 104.638 59.5629L105.973 58.0737C105.579 57.7204 105.417 56.9354 105.855 56.3468L104.25 55.1535Z" fill="#A9B6C8"/>
<path d="M109.091 50.5454C108.539 50.5454 108.091 50.9931 108.091 51.5454C108.091 52.0977 108.539 52.5454 109.091 52.5454V50.5454ZM110 54.7272L110.707 55.4343L110 54.7272ZM109.9 59.6502C110.359 59.9565 110.98 59.8324 111.286 59.3729C111.593 58.9133 111.469 58.2925 111.009 57.9861L109.9 59.6502ZM109.091 52.5454C109.004 52.5454 108.98 52.5268 109.037 52.5475C109.08 52.5631 109.145 52.5929 109.223 52.6402C109.383 52.7381 109.53 52.8711 109.622 53.0092C109.707 53.1368 109.73 53.2411 109.718 53.3405C109.705 53.4457 109.636 53.677 109.293 54.0202L110.707 55.4343C111.273 54.8684 111.621 54.2474 111.703 53.5855C111.785 52.9178 111.58 52.3403 111.286 51.8998C111 51.4697 110.617 51.1482 110.266 50.9336C109.952 50.7419 109.515 50.5454 109.091 50.5454V52.5454ZM109.293 54.0202C108.398 54.9156 108.052 56.0257 108.216 57.0953C108.377 58.14 109.01 59.0567 109.9 59.6502L111.009 57.9861C110.536 57.6705 110.259 57.2237 110.193 56.7912C110.13 56.3836 110.239 55.9028 110.707 55.4343L109.293 54.0202Z" fill="#A9B6C8"/>
<path d="M100.091 62.6362C100.091 62.0839 100.539 61.6362 101.091 61.6362H113.454C114.007 61.6362 114.454 62.0839 114.454 62.6362V71.9999C114.454 74.209 112.664 75.9999 110.454 75.9999H104.091C101.882 75.9999 100.091 74.209 100.091 71.9999V62.6362Z" stroke="#A9B6C8" strokeWidth="2"/>
<path d="M115 64.7275C116.391 64.3653 119.791 63.9797 119.976 66.29C120.323 70.6368 116.846 70.6366 115.455 70.6366" stroke="#A9B6C8" strokeWidth="2"/>
<circle cx="106.5" cy="11.5" r="5.5" fill="url(#webinarNoEventLinear4)"/>
<circle cx="11" cy="58" r="5" fill="url(#webinarNoEventLinear5)"/>
<defs>
<linearGradient id="webinarNoEventLinear1" x1="82.3709" y1="33.3987" x2="78.9595" y2="32.9784" gradientUnits="userSpaceOnUse">
<stop stopColor="#8F26AF"/>
<stop offset="0.979167" stopColor="#141685"/>
</linearGradient>
<linearGradient id="webinarNoEventLinear2" x1="86.1253" y1="33.3987" x2="82.7139" y2="32.9784" gradientUnits="userSpaceOnUse">
<stop stopColor="#8F26AF"/>
<stop offset="0.979167" stopColor="#141685"/>
</linearGradient>
<linearGradient id="webinarNoEventLinear3" x1="89.9027" y1="33.3987" x2="86.4912" y2="32.9784" gradientUnits="userSpaceOnUse">
<stop stopColor="#8F26AF"/>
<stop offset="0.979167" stopColor="#141685"/>
</linearGradient>
<linearGradient id="webinarNoEventLinear4" x1="100.267" y1="14.1304" x2="112.803" y2="13.8227" gradientUnits="userSpaceOnUse">
<stop stopColor="#30d5c8"/>
<stop offset="1" stopColor="#55d5ca"/>
<stop offset="1" stopColor="#3ad1c5"/>
</linearGradient>
<linearGradient id="webinarNoEventLinear5" x1="16.9055" y1="63.6731" x2="4.3921" y2="62.1381" gradientUnits="userSpaceOnUse">
<stop stopColor="#8F26AF"/>
<stop offset="0.979167" stopColor="#141685"/>
</linearGradient>
</defs>
</svg>

);

export default WebinarNoEvents;
