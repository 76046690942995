import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  timeToEndConcierge: {
    id: 'app.components.PurchasedCoursesWebinar.timeToEndConcierge',
    defaultMessage: "Time before the end of the {variantName} level",
  },
  days: {
    id: 'app.components.PurchasedCoursesWebinar.days',
    defaultMessage: 'days',
  },
});
