// Core
import React, { memo, useState, useCallback, } from 'react';
import { compose } from 'react-apollo';
import { injectIntl } from 'react-intl';
import classnames from 'classnames';

// Components
import TextInput from 'components/Input/Material/TextInput/TextInput';
import Button from 'components/Button';

// Other
import { messages } from 'routes/Checkout/messages';
import { sharedMessages } from 'services/i18n/sharedMessages/messages';

// Styles and Assets
import './index.scss';

const PREFIX = 'sk-order-checkout-discount';
const DiscountForm = ({
  formikBag: {
    values,
    errors,
    touched,
    setFieldTouched,
    handleChange,
  },
  disabled,
  applyDiscount,
  intl: { formatMessage },
}) => {
  const [openDiscountInput, setDiscountInputOpen] = useState(false);

  console.log(errors)
  return (
    <div className={classnames(PREFIX, { [`${PREFIX}__open`]: openDiscountInput })}>
      {openDiscountInput ? (
          <>
            <TextInput
              helperText={!!touched.code && Boolean(errors.code) && formatMessage({id: errors.code})}
              error={!!touched.code && Boolean(errors.code)}
              placeholder={formatMessage(messages.promocode)}
              label={formatMessage(messages.promocode)}
              value={values.code}
              name="code"
              onChange={handleChange('code')}
              onBlur={() => setFieldTouched('code', true, false)}
            />
            <Button size='large' onClick={applyDiscount}>
              {formatMessage(sharedMessages.applyButton)}
            </Button>
          </>
        ) : (
          <Button disabled={disabled} size='large' onClick={() => setDiscountInputOpen(true)}>{formatMessage(messages.addDiscount)}</Button>
      )}
    </div>
  );
};

const enhancer = compose(
  memo,
  injectIntl,
);

export default enhancer(DiscountForm);
