// Core
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';

// Styles and Assets
import './EventItem.scss';

const EventItem = ({ event, children }) => {
  const start = new Date(event.start);
  const end = new Date(event.end);
  const durationInMinutes = (end - start) / (1000 * 60);
  const height = durationInMinutes;
  return (
    <Tooltip
      title={`${event.title}: ${
        event.desc
      }, starts ${event.start.toLocaleString()} - ${event.end.toLocaleString()} by @${
        event.author.firstName
      }`}
      arrow
    >
      <Paper className='sk-event-item' style={{ height: `${height}px` }}>
        {children}
      </Paper>
    </Tooltip>
  );
};

export default EventItem;
