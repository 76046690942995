// Core
import React from 'react';
import PropTypes from 'prop-types';

// Styles and Assets
import './IconWithDescription.scss';
import { Grey4 } from 'components/Icon/color';

const IconWithDescription = ({
  icon: Icon,
  iconWidth,
  iconHeight,
  iconStrokeColor,
  description,
}) => (
  <div className="sk-icon-with-description">
    <span>
      <Icon width={iconWidth} height={iconHeight} strokeColor={iconStrokeColor ? iconStrokeColor : Grey4} red />
    </span>
    <span className="sk-icon-with-description__description">{description}</span>
  </div>
);

IconWithDescription.propTypes = {
  icon: PropTypes.func,
  iconWidth: PropTypes.string,
  iconHeight: PropTypes.string,
  description: PropTypes.string,
};

export default IconWithDescription;
