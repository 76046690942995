// Core
import React, { memo } from 'react';

// Components
import Text from 'components/Text';
import MenuManageAccount from 'components/Icon/Svg/MenuManageAccount';
import SimpleSidebarMenu from 'containers/Sidebar/SidebarContent/SimpleSidebarMenu';
import SidebarMenuItem from 'containers/Sidebar/SidebarContent/SidebarMenu/SidebarMenuItem';
import SignOut from 'components/Icon/Svg/SignOut';

// Styles and Assets
import './AdminSidebar.scss';

import { FormattedMessage } from 'react-intl';
import { messages } from 'containers/Sidebar/SidebarContent/CourseSidebar/ActiveCoursesList/messages';

// Other
import { ADMIN, OWNER, MANAGER } from 'utils/enums';

const MENU_ITEMS = [
  {
    id: '101',
    path: '/admin/panel/dashboard',
    label: 'Dashboard',
    roles: [ADMIN, OWNER, MANAGER],
  },
  {
    id: '100',
    path: '/admin/panel/team',
    label: 'Team',
    roles: [ADMIN, OWNER, MANAGER],
  },
  {
    id: '001',
    path: '/admin/panel/students',
    label: 'Students',
    roles: [ADMIN, OWNER, MANAGER],
  },
  {
    id: '002',
    path: '/admin/panel/experts',
    label: 'Experts',
    roles: [ADMIN, OWNER, MANAGER],
  },
  {
    id: '003',
    path: '/admin/panel/courses',
    label: 'Courses',
    roles: [ADMIN, OWNER, MANAGER],
  },
  {
    id: '004',
    path: '/admin/panel/deleted-courses',
    label: 'Deleted Courses',
    roles: [ADMIN],
  },
  {
    id: '005',
    path: '/admin/panel/toppings',
    label: 'Master Classes / Podcasts',
    roles: [ADMIN],
  },
  {
    id: '120',
    path: '/admin/panel/calendar',
    label: 'Calendar',
    roles: [ADMIN, OWNER, MANAGER],
  },
  {
    id: '006',
    path: '/admin/panel/currency-rates',
    label: 'Currency rates',
    roles: [ADMIN],
  },
  {
    id: '007',
    path: '/admin/panel/discounts',
    label: 'Promo Codes',
    roles: [ADMIN],
  },
  {
    id: '008',
    path: '/admin/panel/plans',
    label: 'Payment plans',
    roles: [ADMIN],
  },
  {
    id: '009',
    path: '/admin/panel/categories',
    label: 'Categories',
    roles: [ADMIN, OWNER, MANAGER],
  },
  {
    id: '010',
    path: '/admin/panel/companies',
    label: 'Companies',
    roles: [ADMIN],
  },

];

const AdminSidebar = memo(({ signOut, user: { role, firstName, lastName } }) => (
  <div className="sk-manage-admin-account-sidebar">
    <div className="sk-sidebar-block">
      <SidebarMenuItem
        role={role}
        icon={MenuManageAccount}
        label={`${firstName} ${lastName}`}
      />
      <SimpleSidebarMenu role={role} menuItems={MENU_ITEMS} />
    </div>
    <div className="sk-manage-admin-account-sidebar_footer">
      <button
        type="button"
        className="sk-manage-admin-account-sidebar_btn"
        onClick={signOut}
      >
        <div className="sk-sidebar-block_icon">
          <SignOut />
        </div>
        <Text size="caption">
          <FormattedMessage {...messages.signOut} />
        </Text>
      </button>
    </div>
  </div>
));

export default AdminSidebar;
