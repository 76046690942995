import React from 'react';
import PropTypes from 'prop-types';
import { SidebarDefault, PurpleHeart } from 'components/Icon/color';

const User = ({
  height,
  width,
  fillColor,
  strokeColor,
  active,
  activeColor,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 17 16"
    width={width}
    height={height}
  >
    <g
      fill={active ? activeColor : fillColor}
      fillRule="nonzero"
      stroke={active ? activeColor : strokeColor}
    >
      <rect width="4" height="7" x=".5" y="8.5" rx="1" />
      <rect width="4" height="12" x="6.5" y="3.5" rx="1" />
      <rect width="4" height="15" x="12.5" y=".5" rx="1" />
    </g>
  </svg>
);

User.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  fillColor: PropTypes.string,
  strokeColor: PropTypes.string,
  active: PropTypes.bool,
  activeColor: PropTypes.string,
};

User.defaultProps = {
  height: '17',
  width: '16',
  fillColor: 'none',
  strokeColor: SidebarDefault,
  activeColor: PurpleHeart,
};

export default User;
