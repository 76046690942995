import React, { useState, useCallback, useEffect, } from 'react';
import { compose, } from 'react-apollo';
import { FormattedMessage } from 'react-intl';
import { injectIntl, } from 'react-intl';

// Components
import TextInput from 'components/Input/Material/TextInput';
import TextAreaInput from 'components/Input/Material/TextAreaInput';
import Text from 'components/Text';
import Button from 'components/Button';
import Image from 'components/Image';
import PricesDependOnCurrency from 'routes/ExpertsCabinet/CourseEdit/Details/CourseInformation/CourseVariantsForm/PricesConversionsModal/PricesDependOnCurrency';

// Other
import { messages } from 'routes/ExpertsCabinet/CourseEdit/Details/messages';
import { withCurrency } from 'containers/CurrencyProvider/withCurrency';
import { withSharedState } from 'containers/SharedStateProvider/withSharedState';

// Styles
import './PricesConversionsModal.scss';

const PricesConversionsModal = (props) => {
  const {
    disabled,
    updateCourseVariantPrice,
    currencyContext: { currencies, changeDependOnCurrencyRate, },
    sharedStateContext: { sharedState, },
    intl: { formatMessage },
  } = props;
  const { values = {}, errors = {}, touched = {}, } = sharedState?.courseVariants || {};

  return (
    <div className="sk-course-prices-conversions-modal">
      <Text classes='sk-course-prices-conversions-modal__title'><FormattedMessage {...messages.pricesConversions}/></Text>
      <Text classes='sk-course-prices-conversions-modal__subtitle'><FormattedMessage {...messages.pricesConversionsModalInfo}/></Text>
      <div className="sk-course-prices-conversions-modal__fields">
        <Image src='/assets/images/flag-usa.png' />
        <div>
          <TextInput
            margin="none"
            shrink
            adornment="$"
            position="start"
            type="number"
            name="flex"
            value={values?.flex?.isChecked ? values?.flex?.price : ''}
            onBlur={updateCourseVariantPrice('flex')}
            label={formatMessage(messages.flex)}
            error={values?.flex?.isChecked && touched.flex && Boolean(errors.flex)}
            disabled={disabled || !values?.flex?.isChecked}
            helperText={values?.flex?.isChecked && touched.flex ? errors.flex : ''}
          />
          <TextInput
            margin="none"
            shrink
            adornment="$"
            position="start"
            type="number"
            name="assist"
            isCleanedWhenDisabled={!values?.assist?.isChecked}
            value={values?.assist?.isChecked ? values?.assist?.price : ''}
            onBlur={updateCourseVariantPrice('assist')}
            label={formatMessage(messages.assist)}
            error={values?.assist?.isChecked && touched.assist && Boolean(errors.assist)}
            disabled={disabled || !values?.assist?.isChecked}
            helperText={values?.assist?.isChecked && touched.assist ? errors.assist : ''}
          />
          <TextInput
            margin="none"
            shrink
            adornment="$"
            position="start"
            type="number"
            name="concierge"
            isCleanedWhenDisabled={!values?.concierge?.isChecked}
            value={values?.concierge?.isChecked ? values?.concierge?.price : ''}
            onBlur={updateCourseVariantPrice('concierge')}
            label={formatMessage(messages.concierge)}
            error={values?.concierge?.isChecked && touched.concierge && Boolean(errors.concierge)}
            disabled={disabled || !values?.concierge?.isChecked}
            helperText={values?.concierge?.isChecked && touched.concierge ? errors.concierge : ''}
          />
        </div>
      </div>
      <div className='sk-course-prices-conversions-modal__line'/>
      {currencies.filter(currency => currency.currency_id !== 'usd').map(currency => (
        <PricesDependOnCurrency
          currency={currency}
          flex={changeDependOnCurrencyRate(values?.flex?.price, currency.currency_id)}
          assist={changeDependOnCurrencyRate(values?.assist?.price, currency.currency_id)}
          concierge={changeDependOnCurrencyRate(values?.concierge?.price, currency.currency_id)}
        />
      ))}
    </div>
  );
};

const enhancer = compose(
  injectIntl,
  withCurrency,
  withSharedState
)

export default enhancer(PricesConversionsModal);
