import React, { memo } from 'react';
import { compose } from 'react-apollo';
import { injectIntl } from 'react-intl';

// Components
import Text from 'components/Text';

// Other
import { isBlank } from 'utils/helpers';
import { messages } from 'routes/ExpertsCabinet/ExpertsFee/messages.js';
import { useConvertedPrice } from 'containers/CurrencyProvider/useConvertedPrice';

//Styles
import './index.scss';

// [цена за курс] * [количество курсов] * ((100 -[скидка за курс]*([количество курсов] - 1)) / 100)
export const getPriceWithDiscount = (price, maxCourses, discount, limit) => {
  if (isBlank(maxCourses)) return price;
  limit = maxCourses > limit ? limit : maxCourses;
  return Math.round(price * maxCourses * (100 - discount * (limit - 1)) / 100);
};

const PREFIX = 'sk-subscription-select-price';
const TotalPrice = ({
  price, maxCourses, billingPeriod, discountPerCourse, discountCoursesLimit,
  intl: { formatMessage },
  ...props
}) => {
  let monthlyPrice = price?.find(price => price?.type == billingPeriod)?.amount;

  if (!isBlank(maxCourses) && !isBlank(discountPerCourse) && !isBlank(discountCoursesLimit)) {
    monthlyPrice = getPriceWithDiscount(monthlyPrice, maxCourses, discountPerCourse, discountCoursesLimit);
  };

  const convertedMonthlyPrice = useConvertedPrice({ price: monthlyPrice, isBeautyPrice: true });
  const convertedAnnualPrice = useConvertedPrice({ price: monthlyPrice*12, isBeautyPrice: true });

  return (
    <div className={PREFIX}>
      <Text><Text size='small'>{convertedMonthlyPrice}</Text>{formatMessage(messages.priceInMonth)}</Text>
      <Text>{convertedAnnualPrice}{formatMessage(messages.priceInYear)}</Text>
    </div>
  );
};

const enhancer = compose(
  memo,
  injectIntl
);

export default enhancer(TotalPrice);
