// Core
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import withWidth from 'containers/HOCs/withWidth';
import Drawer from '@material-ui/core/Drawer';
import classNames from 'classnames';
import { compose } from 'react-apollo';
import { withRouter } from 'react-router';

// Components
import SidebarContent from 'containers/Sidebar/SidebarContent';
import SidebarToggleButton from 'containers/Sidebar/SidebarToggleButton';

// Other
import { withSidebar } from 'containers/SidebarProvider/withSidebar';
import { withSharedState } from 'containers/SharedStateProvider/withSharedState';

// Styles
import './Sidebar.scss';

const backDropClasses = {
  root: 'mui-override sk-sidebar_backDrop',
};

class Sidebar extends PureComponent {
  scrollIsEnabled = false;

  componentDidMount() {
    this.scrollIsEnabled = true;

    if (this.scrollIsEnabled) {
      window.addEventListener('scroll', this.handleScroll);
      this.handleScroll(true);
    }

    const {
      complexContent,
      sidebarContext: { openedSidebar },
      sharedStateContext: { updateSharedState, }
    } = this.props;
    updateSharedState({ sidebar: { complexContent, openedSidebar }});
  }

  componentWillUnmount() {
    this.scrollIsEnabled = false;
    window.removeEventListener('scroll', this.handleScroll);
  }

  componentDidUpdate(prevProps) {
    const {
      complexContent,
      sidebarContext: { openedSidebar },
      sharedStateContext: { updateSharedState, }
    } = this.props;

    if (complexContent != prevProps?.complexContent || openedSidebar != prevProps?.sidebarContext?.openedSidebar) {
      updateSharedState({ sidebar: { complexContent, openedSidebar }});
    }
  }

  handleScroll = e => {
    const { width, complexContent } = this.props;
    const isMobile = ['xs', 'sm'].includes(width);
    try {
      const pageHeight = document.body.scrollHeight;
      const scroll = window.scrollY || window.pageYOffset;
      const element = e && document.getElementById('footer');
      const footerHeight = element.getBoundingClientRect().height;
      const footerWidth = element.getBoundingClientRect().width;

      if(!isMobile) {
        const heightBottom = footerHeight - (pageHeight - window.innerHeight - scroll);
        // document.querySelector("#sk-drawer").firstChild.style.height =
        //   `calc(100vh - ${Math.max(heightBottom, 0) + 77}px)`;
      } else {
        document.querySelector("#sk-drawer").firstChild.style.height = `calc(100vh - 0px)`;
      }

    } catch (err) {
      if (err.preventDefaulf) {
        err.preventDefaulf();
      }
    }
  };
  render() {
    const {
      anchor,
      width,
      container,
      complexContent,
      sidebarContext: { openedSidebar, hideSidebar },
      location: { pathname },
    } = this.props;

    const isOpen = openedSidebar === 'main';
    const isItTemporary = complexContent
      ? ['xs', 'sm', 'md'].includes(width)
      : width === 'xs' || width === 'sm';

    return (
      <Drawer
        variant={isItTemporary ? 'temporary' : 'permanent'}
        anchor={anchor}
        open={isOpen}
        onClose={hideSidebar}
        id="sk-drawer"
        ModalProps={{
          keepMounted: true,
          container,
        }}
        classes={{
          root: classNames('mui-override sk-sidebar', {
            'sk-sidebar_open': isOpen,
            'sk-sidebar_complexContent': complexContent,
          }),
          paper: 'mui-override sk-sidebar_paper',
          paperAnchorDockedLeft:
            'mui-override sk-sidebar_paperAnchorDockedLeft sk-sidebar_fixed',
        }}
        BackdropProps={{
          classes: backDropClasses,
        }}
      >
        <SidebarContent route={pathname} />
      </Drawer>
    );
  }
}
// {complexContent && width === 'md' && (<SidebarToggleButton />)}

Sidebar.defaultProps = {
  anchor: 'left',
};

Sidebar.propTypes = {
  sidebarContext: PropTypes.object,
  container: PropTypes.object,
  location: PropTypes.object,
  anchor: PropTypes.string,
  width: PropTypes.string,
  complexContent: PropTypes.bool,
};

const enhancer = compose(
  withRouter,
  withSidebar,
  withSharedState,
  withWidth()
);

export default enhancer(Sidebar);
