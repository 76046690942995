// Core
import React, { memo } from 'react';
import { compose } from 'react-apollo';
// import { withRouter } from 'react-router-dom';

// Components
// import Loader from 'components/Loader';
import Text from 'components/Text';
import TextInput from 'components/Input/Material/TextInput';
import Layout from 'routes/AdminPanel/PlanEdit/components/Layout';
import PlanTitle from 'routes/AdminPanel/PlanEdit/components/Forms/PlanTitle';
import PlanPrice from 'routes/AdminPanel/PlanEdit/components/Forms/PlanPrice';
import Analytics from 'routes/AdminPanel/PlanEdit/components/Forms/Analytics';

// Other
// import { usePlanForm } from 'routes/AdminPanel/PlanEdit/components/usePlanForm.js';
// import { messages } from 'routes/AdminPanel/messages';
// import { getYupSchema, } from 'services/yup';

// Styles and Assets
import './index.scss';

const PlanPackage = ({
  formikBag,
  analytics,
  disabled,
  ...props
}) => {
  return (
    <Layout>
      <PlanTitle {...formikBag} />
      <Text classes='sk-admin-plan-page-section'>Price per package</Text>
      <PlanPrice {...formikBag} title='Package price' />
      <Analytics {...analytics} />
    </Layout>
  );
}

const enhancer = compose(
  memo,
  // withRouter,
);

export default enhancer(PlanPackage);
