import React from 'react';

import './index.scss';

const IconOnHover = ({ linearId }) => (
  <svg class='filled' width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.6111 5.32544C16.6111 5.11243 16.6111 5.00592 16.5048 4.89941L11.7196 0.106509C11.6133 0.106509 11.5069 0 11.4006 0H5.87109C4.27604 0 3 1.1716 3 2.76923V10.9704V12.142V15.2308C3 16.8284 4.27604 18 5.87109 18H13.74C15.3351 18 16.6111 16.7219 16.6111 15.3373V5.32544ZM15.8668 5.53846C15.7604 5.64497 15.7604 5.64497 15.6541 5.64497H11.826C11.2943 5.64497 10.8689 5.21894 10.8689 4.68639V0.852071C10.8689 0.745562 10.9753 0.639053 11.0816 0.639053C11.1879 0.639053 11.2943 0.639053 11.4006 0.745562L15.9731 5.11243C15.9731 5.32544 15.9731 5.43195 15.8668 5.53846Z" fill={`url(#paint1_linear_${linearId})`} />
    <defs>
    <linearGradient id={`paint1_linear_${linearId}`} x1="17.8435" y1="19.2116" x2="0.702657" y2="17.6216" gradientUnits="userSpaceOnUse">
    <stop stopColor="#8F26AF"/>
    <stop offset="0.979167" stopColor="#141685"/>
    </linearGradient>
    </defs>
  </svg>
);

const IconDefault = ({ linearId }) => (
  <svg class='outlined' width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.1322 5.21205C16.1331 5.24011 16.1331 5.27436 16.1331 5.32544V15.3373C16.1331 16.4213 15.104 17.5 13.7574 17.5H5.87574C4.52912 17.5 3.5 16.5278 3.5 15.2308V12.142V10.9704V2.76923C3.5 1.47222 4.52912 0.5 5.87574 0.5H11.3494C11.3497 0.500154 11.35 0.50031 11.3504 0.500468C11.3517 0.501157 11.3532 0.501906 11.3548 0.502708C11.3786 0.514622 11.4261 0.538437 11.4781 0.557947L16.1322 5.21205Z" stroke={`url(#paint0_linear_${linearId})`} />
    <defs>
    <linearGradient id={`paint0_linear_${linearId}`} x1="17.8676" y1="19.2116" x2="0.699414" y2="17.6165" gradientUnits="userSpaceOnUse">
    <stop stopColor="#6D7C90"/>
    <stop offset="0.979167" stopColor="#6D7C90"/>
    </linearGradient>
    </defs>
  </svg>
);

const Icon = () => {
  const randomFloatString = `${Math.random()}`

  return (
    <div className='icon-with-hover'>
      <IconDefault linearId={randomFloatString} />
      <IconOnHover linearId={randomFloatString} />
    </div>
  );
};

export default Icon;
