// Core
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';

// Components
import Image from 'components/Image';
import Text from 'components/Text';
import Cross from 'components/Icon/SvgCss/Cross';

// Other
import { renderTrimmedText, isBlank } from 'utils/helpers';
import { useConvertedPrice } from 'containers/CurrencyProvider/useConvertedPrice';
import {Grey, PurpleLight} from 'components/Icon/color';

// Styles and Assets
import 'components/DropDown/DropDown.scss';
import './index.scss';
import '../index.scss';

const CloseButton = ({ handleClick }) => (
  <button
    type="button"
    onClick={handleClick}
  >
    <Cross width="10" height="10" color={Grey} hoverColor={PurpleLight} />
  </button>
);

const PREFIX = 'sk-checkout-menu-item';
const CheckoutMenuItem = ({
  title,
  photo,
  price,
  handleItemClick,
  handleItemRemove,
  interactionLevel
}) => {
  const parsedPrice = useConvertedPrice({ price, isBeautyPrice: true });
  // const parsedPrice = (!isBlank(price) || price == 0) ? useConvertedPrice({ price, isBeautyPrice: true }) : '--';

  return (
    <div className={`${PREFIX}`}  onClick={!(['podcast', 'lecture', 'masterclass'].includes(interactionLevel)) && handleItemClick}>
      <Image src={photo} />
      <div className={`${PREFIX}__details`}>
        <Text>{renderTrimmedText(title, 25)}</Text>
        <Text>{parsedPrice}</Text>
      </div>
      <CloseButton handleClick={handleItemRemove} />
    </div>
  );
};

const enhancer = compose(
  memo,
);

export default enhancer(CheckoutMenuItem);
