// Core
import React, { memo } from 'react';
import { compose } from 'react-apollo';

// Components
// import Loader from 'components/Loader';
import AnswerNoFileUploaded from 'routes/StudentsCabinet/components/StudentHomework/Steps/Step2/AnswerLessStep';
import AnswerUploaded from 'routes/StudentsCabinet/components/StudentHomework/Steps/Step2/AnswerFulStep';
import AnswerUploading from 'routes/StudentsCabinet/components/StudentHomework/Steps/Step2/AnswerUploading';

// Other
import { withUploadManager } from 'containers/UploadManagerProvider/withUploadManager';
import { renderTrimmedText } from 'utils/helpers';
import { LECTURE_HOMEWORK } from 'containers/UploadManagerProvider/helpers';

// Styles and Assets
import './Step2.scss';

const Step2 = ({
  answer,
  lectureId,
  studentId,
  courseId,
  disabled,
  uploadManagerContext: { progress, getFileFromQueue, getFileFromUploadedFiles, },
  handleUpdateHomeworkStep,
  handleAnswerFilePick,
  handleHomeworkAnswerDelete,
}) => {

  // const isFileAdded = true;
  const file = answer && answer !== null && answer.attachment;
  const isFileAdded = !!file && file !== null;

  let status = null;
  let id = null;
  let title = '';
  if (isFileAdded) {
    status = file.status;
    id = file.id;
    title = file.filename;
  }

  const isFilePending = status === 'PENDING';
  const isFileProcessing = status === 'PROCESSING';
  const isFileUploadedAndProcessed = status === 'UPLOADED';

  const { isFileInList: isFileInQueue, index: fileIndexInQueue } = getFileFromQueue(id);
  const { isFileInList: isFileUploaded } = getFileFromUploadedFiles(id);
  const isFileInProgress = isFileInQueue && fileIndexInQueue === 0;
  const uploadingStatus = isFileUploaded ? 'success' : 'active';

  return (
    <div className="sk-step2">
      {(!isFileAdded || (isFilePending && !isFileInQueue && !isFileUploaded)) && (
        <AnswerNoFileUploaded
          handleAnswerFilePick={handleAnswerFilePick}
          lectureId={lectureId}
          disabled={disabled}
          // disabled={hwStatusIsUpdating || disabled}
        />
      )}

      {((isFilePending && (isFileInQueue || isFileUploaded)) || isFileProcessing) && (
        <AnswerUploading
          progress={progress}
          uploadingStatus={uploadingStatus}
          isFilePending={isFilePending}
          isFileInQueue={isFileInQueue}
          isFileUploaded={isFileUploaded}
          isFileInProgress={isFileInProgress}
          isFileProcessing={isFileProcessing}
          handleAnswerFilePick={handleAnswerFilePick}
          removeAnswer={() => handleHomeworkAnswerDelete(file.id)}
          fileName={renderTrimmedText(title, 7)}
        />
      )}

      {isFileUploadedAndProcessed && (
        <AnswerUploaded
          fileName={title}
          handleUpdateHomeworkStep={handleUpdateHomeworkStep}
          handleAnswerFilePick={handleAnswerFilePick}
          disabled={disabled}
          // disabled={hwStatusIsUpdating || disabled}
        />
      )}
    </div>
  );
};

const enhancer = compose(
  memo,
  withUploadManager
);

export default enhancer(Step2);
