import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

// Components
import Image from 'components/Image';
import Button from 'components/Button';
import Text from 'components/Text';

// Other
import { messages } from 'containers/ErrorMessage/messages.js';

// Styles
import './ProductionMessage.scss';

const ProductionMessage = ({ history, formatMessage }) => {
  return (
    <div className='error-message-production'>
      <div className='error-message-production__text'>
        <Text className='error-message-production__text__oops'>{formatMessage(messages.oops)}</Text>
        <Text className='error-message-production__text__title'><b>{formatMessage(messages.title)}</b></Text>
        <Text className='error-message-production__text__description'>{formatMessage(messages.description)}</Text>
        <div className='error-message-production__text__button'>
          <Button onClick={() => history.push('/contact-us')}>{formatMessage(messages.contactUsButton)}</Button>
          <Button onClick={() => history.push('/')} style={{ marginLeft: 30 }}>{formatMessage(messages.mainPageButton)}</Button>
        </div>
      </div>
      <div className='error-message-production__icons__line'>
        <Image src={'/assets/images/error-icon-1.svg'} style={{ width: '27px', heigth: 'auto' }}/>
      </div>
      <div className='error-message-production__icons__exclamation'>
        <Image src={'/assets/images/error-icon-2.svg'} style={{ width: '15px', height: 'auto' }}/>
      </div>
      <div className='error-message-production__icons__round'>
        <Image src={'/assets/images/error-icon-3.svg'} style={{ width: '30px', height: 'auto' }}/>
      </div>
      <div className='error-message-production__icons__cross'>
        <Image src={'/assets/images/error-icon-4.svg'} style={{ width: '15px', height: 'auto' }}/>
      </div>
      <div className='error-message-production__icons__question'>
        <Image src={'/assets/images/error-icon-5.svg'} style={{ width: '20px', height: 'auto' }}/>
      </div>
      <div className='error-message-production__icons__round2'>
        <Image src={'/assets/images/error-icon-3.svg'} style={{ width: '15px', height: 'auto' }}/>
      </div>
      <div className='error-message-production__icons__line2'>
        <Image src={'/assets/images/error-icon-1.svg'} style={{ width: '32px', heigth: 'auto' }}/>
      </div>
      <div className='error-message-production__background-image'>
        <Image src={'/assets/images/error-image.svg'} style={{ width: '90%', heigth: 'auto' }}/>
      </div>
    </div>
)};

export default ProductionMessage;
