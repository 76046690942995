// Core
import React, { useCallback } from 'react';
import { injectIntl } from 'react-intl';
import { compose } from 'react-apollo';
import classNames from 'classnames';
import { useFormikContext } from 'formik';

// Components
import Text from 'components/Text';
import TextInput from 'components/Input/Material/TextInput';
import Checkbox from 'components/Checkbox';

// Other
import { hasVariant, getVariant } from 'routes/ExpertsCabinet/CourseEdit/Details/CourseInformation/helpers.js';
import { withModal } from 'containers/ModalProvider/withModal';
import { withCourseEditContext } from 'routes/ExpertsCabinet/CourseEdit/Context';
import { messages } from 'routes/ExpertsCabinet/CourseEdit/Details/messages';
import { DEFAULT_VARIANTS } from 'routes/ExpertsCabinet/CourseEdit/enums';

// Styles
import './index.scss';

const VARIANTS_ORDER = {
  flex: 1,
  assist: 2,
  concierge: 3
};

const classname = 'sk-course-variant-price';
const CourseVariant = ({
  disabled,
  variantName,
  formikBag: { values, errors, touched, setFieldValue },
  isOnlyOneVariantSelected,
  updateCourseVariantPrice,
  intl: { formatMessage },
  courseEditContext: { course, handleCourseUpdate },
}) => {
  const variantValue = values[variantName];

  const handlePriceChange = useCallback((inputValue, e) => {
    const newValue = { ...values[variantName] };
    newValue.price = inputValue;

    setFieldValue(variantName, newValue, false);
  }, [variantName, values]);
  updateCourseVariantPrice = updateCourseVariantPrice(variantName, true);

  const updateCouseVariantSelect = async () => {
    let newVariants = [...course.variants];

    if (!variantValue.isChecked) {
      const newVariant = Object.assign({}, DEFAULT_VARIANTS[variantName]);

      if (variantName == 'assist' && hasVariant({ variants: newVariants, variantName: 'concierge' })) {
        const conciergeVariant = getVariant({ variants: newVariants, variantName: 'concierge' });
        newVariant.permissions = { ...conciergeVariant.permissions };
      } else if (variantName == 'concierge' && hasVariant({ variants: newVariants, variantName: 'assist' })) {
        const assistVariant = getVariant({ variants: newVariants, variantName: 'assist' });
        newVariant.permissions = { ...assistVariant.permissions };
      };

      newVariants = [...newVariants, newVariant];
    } else if (variantValue.isChecked) {
      newVariants = newVariants.filter(variant => variant.name !== variantName);
    };

    const sortedVariants = newVariants.sort((var1, var2) => {
      return VARIANTS_ORDER[var1.name] - VARIANTS_ORDER[var2.name];
    });

    await handleCourseUpdate({ variants: newVariants }, true);
  };

  return (
    <div className={classname}>
      <div className={`${classname}__checkbox`}>
        <Checkbox
          disabled={disabled || (variantValue.isChecked && isOnlyOneVariantSelected)}
          name={`${variantName}Variant`}
          checked={variantValue.isChecked}
          handleChange={updateCouseVariantSelect}
        />
        <Text>{formatMessage(messages[variantName])}</Text>
      </div>

      <TextInput
        margin="none"
        shrink
        adornment="$"
        position="start"
        type="number"
        name={variantName}
        value={variantValue.isChecked ? variantValue.price : null}
        onChange={handlePriceChange}
        onBlur={updateCourseVariantPrice}
        label='Price'
        error={
          variantValue.isChecked &&
          touched[variantName] &&
          Boolean(errors[variantName])
        }
        disabled={!variantValue.isChecked || disabled}
        helperText={
          (variantValue.isChecked && touched[variantName] && errors[variantName])
            ? errors[variantName]?.price
            : ''
        }
      />
    </div>
  );
};

const enhancer = compose(
  injectIntl,
  withCourseEditContext
);

export default enhancer(CourseVariant);
