// Core
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import InputAdornment from '@material-ui/core/InputAdornment';

// Components
import MaterialTextField from 'components/Input/Material/MaterialTextField';

// Styles
import 'utils/styles/Materialized.scss';

class InputWithAdornment extends PureComponent {
  getAdornment = (position, adornment, adornmentSize) => ({
    [`${position}Adornment`]: (
      <InputAdornment
        position={position}
        classes={{
          positionEnd: classNames(
            'mui-override sk-input-adornment',
            `sk-input-adornment_${adornmentSize}`
          ),
        }}
      >
        {adornment}
      </InputAdornment>
    ),
  });

  render() {
    const { adornment, adornmentSize, position, ...rest } = this.props;
    return (
      <MaterialTextField
        {...rest}
        inputProps={this.getAdornment(position, adornment, adornmentSize)}
      />
    );
  }
}

InputWithAdornment.defaultProps = {
  adornment: '$',
  position: 'end',
};

InputWithAdornment.propTypes = {
  adornment: PropTypes.any,
  position: PropTypes.string,
};

export default InputWithAdornment;
