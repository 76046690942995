import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  whoWeAre: {
    id: 'app.containers.AboutUsText.whoWeAre',
    defaultMessage: 'Who we are',
  },
  weAre: {
    id: 'app.containers.AboutUsText.weAre',
    defaultMessage:
      'We are a community of professionals passionate about sharing their skills with those who want to learn them.',
  },
  at: {
    id: 'app.containers.AboutUsText.at',
    defaultMessage: 'at'
  },
  skilleton: {
    id: 'app.containers.AboutUsText.skilleton',
    defaultMessage: 'AVDO'
  },
  weBelieveAnyone: {
    id: 'app.containers.AboutUsText.weBelieveAnyone',
    defaultMessage:
      'we believe anyone can teach.  Whether it is a trade, skill or craft that you love, it is yours to share with the world. AVDO is an audience the size of a planet!',
  },
  isAnAudience: {
    id: 'app.containers.AboutUsText.isAnAudience',
    defaultMessage:
      'is an audience the size of a planet!',
  },
  isYourGateway: {
    id: 'app.containers.AboutUsText.isYourGateway',
    defaultMessage:
      'is your gateway to top quality skills required for your success now and in the future.  This is the place where you create opportunities for yourself through learning new cutting-edge skills. AVDO is where anyone from anywhere can learn and empower themselves.',
  },
  isWhereAnyone: {
    id: 'app.containers.AboutUsText.isWhereAnyone',
    defaultMessage: 'is where anyone from anywhere can learn and empower themselves.',
  },
  buildTheSkills: {
    id: 'app.containers.AboutUsText.buildTheSkills',
    defaultMessage: 'Build the skills you need to land your dream job today! Empower your career with AVDO!',
  },
  whySkilleton: {
    id: 'app.containers.AboutUsText.whySkilleton',
    defaultMessage: 'Why AVDO',
  },
  whySkilletonOne: {
    id: 'app.containers.AboutUsText.whySkilletonOne',
    defaultMessage:
      '1. We at AVDO believe best teachers are not always found at classrooms. Professionals and ordinary people, just like you, have a huge variety of skills. Something you learned on the job, something you have talent for, something you just love doing. There’re plenty of people who want to learn what you are good at! Teach what you love!',
  },
  whySkilletonOneTeachWhatYouLove: {
    id: 'app.containers.AboutUsText.whySkilletonOneTeachWhatYouLove',
    defaultMessage: 'Teach what you love!',
  },
  whySkilletonTwo: {
    id: 'app.containers.AboutUsText.whySkilletonTwo',
    defaultMessage:
      '2. Talent is universal. Opportunities are not. AVDO is where you will create new and exciting opportunities for yourself. Through online learning anyone, anywhere, any time will gain new high in-demand skills. Be free of what’s been holding you back. Leap forward in your career, change your path, bring your idea to life and be your own boss. Possibilities are truly limitless. Get skills for your future!',
  },
  whySkilletonTwoGetSkills: {
    id: 'app.containers.AboutUsText.whySkilletonTwoGetSkills',
    defaultMessage: 'Gain skills for your future!'
  },
  whySkilletonThree: {
    id: 'app.containers.AboutUsText.whySkilletonThree',
    defaultMessage:
      '3. It is said everyone needs 5 streams of income to be independent. AVDO is one of them! Experts share the skills they already have with people who want to learn them. And there are plenty! This is your opportunity to generate extra income for you and your family. Thanks to online format you get maximum benefits with minimal efforts when vs conventional approach to education.',
  },
  whySkilletonThreeGenerateExtraIncome: {
    id: 'app.containers.AboutUsText.whySkilletonThreeGenerateExtraIncome',
    defaultMessage: 'generate extra income'
  },
  whySkilletonThreeForYourFamily: {
    id: 'app.containers.AboutUsText.whySkilletonThreeForYourFamily',
    defaultMessage:
      'for you and your family. Thanks to online format you get maximum benefits with minimal efforts when vs conventional approach to education.'
  },
  whySkilletonFour: {
    id: 'app.containers.AboutUsText.whySkilletonFour',
    defaultMessage:
      '4. AVDO is all about your comfort. Teach and learn A-N-Y W-A-Y you want! Seriously! In the park, on the go, with friends, day or night. Connect with your instructor and fellow students as much as you want. Choose a challenging difficulty level, learn at any pace you like. AVDO is here for you in any way you want it. Choose a course and make it yours!',
  },
  whySkilletonChooseACourseAndMakeItYours: {
    id: 'app.containers.AboutUsText.whySkilletonChooseACourseAndMakeItYours',
    defaultMessage: 'Choose a course and make it yours!',
  },
  whySkilletonFive: {
    id: 'app.containers.AboutUsText.whySkilletonFive',
    defaultMessage:
      '5. Feedback is precious. Constructive feedback is priceless. Only on AVDO you get personalized feedback from your experts. Share your projects with the community of fellow students and get their opinions and suggestions. Exchange ideas and insights with people who appreciate your views.',
  },
  whySkilletonFiveExchangeIdeas: {
    id: 'app.containers.AboutUsText.whySkilletonFiveExchangeIdeas',
    defaultMessage: 'Exchange ideas and insights with people who appreciate your views.'
  },
  whySkilletonSix: {
    id: 'app.containers.AboutUsText.whySkilletonSix',
    defaultMessage:
      '6. Sharing is a wonderful thing. Sharing your skills and knowledge is a powerful and generous way to give back and change the world for the better. AVDO is the place to do just that. Build a following, teach people eager to expand their skillset. Enrich your community with AVDO!',
  },
  whySkilletonSixEnrichYourCommunity: {
    id: 'app.containers.AboutUsText.whySkilletonSixEnrichYourCommunity',
    defaultMessage: 'Enrich your community with AVDO!',
  },
  ourPurpose: {
    id: 'app.containers.AboutUsText.ourPurpose',
    defaultMessage: 'Our purpose',
  },
  skIsHereToProvide: {
    id: 'app.containers.AboutUsText.skIsHereToProvide',
    defaultMessage:
      'AVDO is here to provide access to the skills of the future for anyone and anywhere.',
  },
});
