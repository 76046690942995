// Core
import React, { useState } from 'react';

import Card from 'components/Card';
import CloseButton from 'components/Modal/CloseButton';
import ShareContainer from 'components/ShareButton/ShareContainer';

// Icons
import ShareButtonIcon from 'components/Icon/SvgCss/ShareButton';

// Styles
import './ShareButton.scss';

const ShareButton = ({ iconProps, shareUrl, ...props }) => {
  const [bounds, setBounds] = useState(0);
  const [collapsed, setCollapsed] = useState(true);

  const getShareButtonNode = (el) => {
    if (!el || el === null) {
      return false;
    }
    const domBounds = el.getBoundingClientRect();
    if (window && !window.isServer) {
      if (domBounds.bottom > window.innerHeight) {
        setBounds(window.innerHeight-(domBounds.bottom+(domBounds.height/2)));
      }
    }
  }

  const toggleDropdown = () => {
    return setCollapsed(!collapsed);
  };

  return (
    <div
      className='sk-social-share'
      onClick={toggleDropdown}
      style={{ cursor: 'pointer'}}
    >
      {!collapsed && (
        <div
          ref={getShareButtonNode}
          className='sk-social-share-wrapper'
          style={{
            top: bounds
          }}
      >
          <Card
            classes='sk-social-share-popover-content'
          >
            <CloseButton handleClick={() => setCollapsed(true)} />
            <ShareContainer shareUrl={shareUrl} />
          </Card>
        </div>
      )}
      <ShareButtonIcon {...iconProps} active={!collapsed} />
    </div>
  );
}

export default ShareButton;
