import React from 'react';

import './index.scss';

const BlockquoteOnHover = () => (
  <svg
    className="hover"
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.691986" width="32" height="32" rx="2" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0328 22.895H22.6423V24.3201H12.0328V22.895ZM23.2757 8.96014V15.4525C23.2757 17.036 22.6423 18.6195 21.5338 19.728C20.4254 20.8364 19.0002 21.4698 17.2583 21.4698C15.6748 21.4698 14.0913 20.8364 12.9829 19.728C11.8744 18.6195 11.241 17.1944 11.241 15.4525V8.96014H12.6662V15.4525C12.6662 16.7193 13.1412 17.8278 14.0913 18.7779C14.8831 19.5696 16.1499 20.203 17.4167 20.203C18.6835 20.203 19.792 19.728 20.7421 18.7779C21.5338 17.9861 22.1672 16.7193 22.1672 15.4525V8.96014H23.2757Z"
      fill="#7145D8"
    />
  </svg>
);

const DefaultBlockquote = () => (
  <svg
    className="default"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.0328 22.895H21.6423V24.3202H11.0328V22.895ZM22.2757 8.96015V15.4525C22.2757 17.036 21.6423 18.6195 20.5338 19.728C19.4254 20.8364 18.0002 21.4698 16.2583 21.4698C14.6748 21.4698 13.0913 20.8364 11.9829 19.728C10.8744 18.6195 10.241 17.1944 10.241 15.4525V8.96015H11.6662V15.4525C11.6662 16.7193 12.1412 17.8278 13.0913 18.7779C13.8831 19.5696 15.1499 20.203 16.4167 20.203C17.6835 20.203 18.792 19.728 19.7421 18.7779C20.5338 17.9861 21.1672 16.7193 21.1672 15.4525V8.96015H22.2757Z"
      fill="#6D6E71"
    />
  </svg>
);

const ActiveBlockquote = () => (
  <svg
    className="active"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.0328 22.895H21.6423V24.3201H11.0328V22.895ZM22.2757 8.96014V15.4525C22.2757 17.036 21.6423 18.6195 20.5338 19.728C19.4254 20.8364 18.0002 21.4698 16.2583 21.4698C14.6748 21.4698 13.0913 20.8364 11.9829 19.728C10.8744 18.6195 10.241 17.1944 10.241 15.4525V8.96014H11.6662V15.4525C11.6662 16.7193 12.1412 17.8278 13.0913 18.7779C13.8831 19.5696 15.1499 20.203 16.4167 20.203C17.6835 20.203 18.792 19.728 19.7421 18.7779C20.5338 17.9861 21.1672 16.7193 21.1672 15.4525V8.96014H22.2757Z"
      fill="#7145D8"
    />
  </svg>
);

const Blockquote = ({ active }) => {
  const Icon = active ? ActiveBlockquote : DefaultBlockquote;

  return (
    <div className="editor-icon-svg">
      <Icon />
      <BlockquoteOnHover />
    </div>
  );
};

export default Blockquote;
