// Core
import React from 'react';
import { FormattedMessage } from 'react-intl';

// Other
import { messages } from 'routes/ExpertsCabinet/CourseEdit/messages';

const NotFoundCourse = (props) => {
  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <FormattedMessage {...messages.courseNotFound} />
  </div>
)}

export default NotFoundCourse;
