// Core
import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';

// Styles and Assets
import './Duration.scss';

// Components
import Clock from 'components/Icon/Svg/Clock';

// Other
import { FormattedMessage } from 'react-intl';
import { sharedMessages } from 'services/i18n/sharedMessages/messages';

import {
  getDurationsSumFromEntity,
  parseEntityDuration,
} from 'utils/helpers';

const getTotalTime = duration => {
  const totalHours = +parseEntityDuration(duration, 'seconds', 'asHours');
  const totalMinutes = +parseEntityDuration(duration, 'seconds', 'asMinutes');

  return totalMinutes >= 60
    ? { totalHours, totalMinutes: totalMinutes - (totalHours*60) } : { totalHours: 0, totalMinutes: totalMinutes };
};

const Duration = ({ duration, textStyles = {}, withClock = false, intl: { formatMessage }, clockProps, }) => {
  const { totalHours, totalMinutes } = getTotalTime(duration);

  return (
      <>
      {withClock &&( <span style={{ width: '25%'}} className='sk-duration-component__clock'><Clock {...clockProps} /></span>)}
      <span style={{ width: withClock ? '70%' : '100%', ...textStyles }} className='sk-duration-component__duration'>
          {totalHours > 0
            ? `${totalHours}${formatMessage(sharedMessages.hours)} ${totalMinutes}${formatMessage(sharedMessages.min)}`
            : `${totalMinutes}${formatMessage(sharedMessages.min)}`
          }
        </span>
      </>
  );
}

export default injectIntl(Duration);
