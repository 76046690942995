// Core
import React, { memo } from "react";
import { compose, graphql } from "react-apollo";
import classNames from "classnames";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router";

// Components
import Text from "components/Text";
import Button from "components/Button";
import Loader from "components/Loader";
// import OrderDetails from 'routes/Checkout/OrderDetails';

import Plan from "routes/Corporate/Plan";
import Payment from "routes/Corporate/Payment";

import CoursesList from "routes/Checkout/CheckoutLists/Courses";
import DiscountForm from "routes/Checkout/components/DiscountForm";
import EmptyList from "routes/Checkout/components/EmptyList";
import OrderTotal from "routes/Checkout/components/OrderTotal";
import CheckoutLayout from "routes/Corporate/Lauout";
import Card from "components/Card";

// Other
import { useDiscount } from "routes/Checkout/components/useDiscount.js";
import { useTransaction } from "routes/Checkout/components/useTransaction.js";
import { withCurrency } from "containers/CurrencyProvider/withCurrency";
import { withModal } from "containers/ModalProvider/withModal";
import { messages } from "routes/Checkout/messages";
import { withShoppingCart } from "containers/ShoppingCartProvider/withShoppingCart";

// Styles and Assets
import "./index.scss";

const isDraftOrderDefined = (draftOrder) =>
  draftOrder?.id && draftOrder.id !== null;
const hasOrderCourses = (draftOrder) =>
  draftOrder?.id && draftOrder.id !== null && draftOrder.courses.length > 0;

const PREFIX = "sk-checkout";
const Corporate = ({
  shoppingCartContext: {
    draftOrder,
    isOrderLoading,
    clearShoppingCartInStateAndLocalStorage,
  },
  currencyContext: {
    currency,
    currencyProperties,
    getCurrentCurrencySign,
    changeDependOnCurrentCurrencyRate,
  },
  intl: { formatMessage },
  modalContext,
  history,
}) => {

  const { totalPrice, id: draftOrderId } = draftOrder;
  const {
    formikBag,
    applyDiscount,
    orderItems: courses,
    discountPayload,
    totalPriceWithDiscount,
  } = useDiscount({
    orderItems: draftOrder?.courses,
    totalPrice,
    draftOrderId,
  });

  const { loading, handleSuccesfulPayment } = useTransaction({
    currency,
    orderItems: courses,
    discountPayload,
    discountCode: formikBag?.values?.code,
    draftOrderId,
    modalContext,
    history,
    clearShoppingCartInStateAndLocalStorage,
  });

  const isFree = totalPriceWithDiscount == 0;

  const totalPriceDependOnCurrency = changeDependOnCurrentCurrencyRate(
    totalPrice
  );
  const totalPriceWithDiscountDependOnCurrency = changeDependOnCurrentCurrencyRate(
    totalPriceWithDiscount
  );

  const hasCourses = hasOrderCourses(draftOrder);

  const handleSelect = planId => history.push(`/en/auth/sso?plan=${planId}`);

  return (
    <CheckoutLayout breadcrumbs="breadcrumbs">
      <Plan
        features={['studyMaterialsFeature', 'videoLecturesFeature']}
        title="Standard"
        disabled={!hasCourses}
        loading={loading}
        amount={8.99}
        currency={currency}
        currencyLabel={currencyProperties?.label}
        handleSelect={handleSelect}
        storage={'5gb'}
      />
      <Plan
        features={['studyMaterialsFeature', 'videoLecturesFeature', 'certificatesFeature', 'interectiveTestsFeature']}
        title="Business"
        disabled={!hasCourses}
        loading={loading}
        amount={19.99}
        currency={currency}
        storage={'50gb'}
        currencyLabel={currencyProperties?.label}
        handleSelect={handleSelect}
      />
      <Plan
        features={['studyMaterialsFeature', 'videoLecturesFeature', 'certificatesFeature', 'interectiveTestsFeature', 'webinarsFeature', 'homeworksFeature']}
        title="Enterprise"
        disabled={!hasCourses}
        loading={loading}
        amount={28.99}
        currency={currency}
        storage={'200gb'}
        currencyLabel={currencyProperties?.label}
        handleSelect={handleSelect}
      />

    </CheckoutLayout>
  );
};

const enhancer = compose(
  memo,
  injectIntl,
  withShoppingCart,
  withModal,
  withRouter,
  withCurrency
);

export default enhancer(Corporate);
