// Core
import React from 'react';
import { compose, } from 'react-apollo';
import { injectIntl } from 'react-intl';
import moment from 'moment';

// Components
import Text from 'components/Text';
import Button from 'components/Button';

// Icons
import Clock from 'components/Icon/Svg/Clock';
import Calendar from 'components/Icon/Svg/Calendar';
import Author from 'components/Icon/Svg/Author';
import CourseTitle from 'components/Icon/Svg/CourseTitle';

// Other
import { messages } from 'containers/Calendar/messages';
import { sharedMessages } from 'services/i18n/sharedMessages/messages';
import { DarkGrey } from 'components/Icon/color';

// Styles and Assets
import './EventDetailsModal.scss';

const EventDetailsModalRow = ({
  icon: Icon, content, textSize, textBold
}) => (
  <div className="sk-nearest-webinar-info__row">
    <Icon />
    <Text size={textSize} bold={textBold}>{content}</Text>
  </div>
);

const EventDetailsModal = ({
  intl: { formatMessage }, event: { course, author, start, title, }, close, joinWebinar,
}) => {
  const startDate = moment(start).format('DD.MM.Y');
  const startTime = moment(start).format('HH:mm (UTC Z)');

  return (
    <div className='sk-webinar-details-modal'>
      <Text classes='sk-webinar-details-modal__header'>{title}</Text>
      <div className='sk-webinar-details-modal__info'>
        <EventDetailsModalRow
          icon={() => <CourseTitle width="20" height="22" color={DarkGrey} />}
          content={course?.title}
          textSize='small'
          textBold={true}
        />
        <EventDetailsModalRow
          icon={() => <Calendar width="20" height="18" color={DarkGrey} />}
          content={startDate}
          textSize='small'
          textBold={true}
        />
        <EventDetailsModalRow
          icon={() => <Author />}
          content={`${author?.firstName} ${author?.lastName}`}
          textSize='small'
          textBold={true}
        />
        <EventDetailsModalRow
          icon={() => <Clock width="20" height="18" stroke={DarkGrey} />}
          content={startTime}
          textSize='small'
          textBold={true}
        />
      </div>

      <div className='sk-webinar-details-modal__actions'>
        <Button color="secondary" className='sk-why-delete-course__button' onClick={close}>
          {formatMessage(sharedMessages.backButton)}
        </Button>
        <Button color="secondary" className='sk-why-delete-course__button' onClick={joinWebinar}>
          {formatMessage(sharedMessages.joinButton)}
        </Button>
      </div>
    </div>
  );
}

const enhancer = compose(
  injectIntl,
);

export default enhancer(EventDetailsModal);
