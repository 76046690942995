// Core
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Progress } from 'react-sweet-progress';
// import classNames from 'classnames';

// Components
// import Button from 'components/Button';
import Text from 'components/Text';
import UploadController from 'routes/Course/components/UploadController';
import PDF from 'components/Icon/Svg/PDF';

// Other
import { SUPPORTED_FORMATS_HOMEWORK } from 'utils/enums';
import { theme } from 'containers/UploadManagerProvider/helpers';
import { White, NotActiveGrey } from 'components/Icon/color';

import { FormattedMessage } from 'react-intl';
import { sharedMessages } from 'services/i18n/sharedMessages/messages';


// Styles and Assets
import './AnswerUploading.scss';

const AnswerUploading = ({
  fileName,
  isFilePending,
  isFileInProgress,
  isFileInQueue,
  isFileUploaded,
  isFileProcessing,
  uploadingStatus,
  progress,
  disabled,
  handleAnswerFilePick,
  handleAnswerFileUpload,
  removeAnswer,
}) => {
  const iconProps = isFileProcessing || isFilePending ? { strokeColor: NotActiveGrey, color: White, textColor: NotActiveGrey } : {};
  return (
    <div className="sk-answerful-step">
      <div className="sk-answerful-step__header">
        <div className="sk-answerful-step__icon">
          <PDF {...iconProps} />
        </div>
        <div className="sk-answerful-step__file">
          <Text size="caption" classes='sk-answerful-step__text inactive'>
            {fileName}
          </Text>
          <UploadController
            withDelete={isFilePending || isFileProcessing}
            handleDelete={removeAnswer}
            uploaderId={`sk-lec-${fileName}}-hw-uploader-2`}
            disabled={disabled}
            supportedFormats={SUPPORTED_FORMATS_HOMEWORK}
            maxSize={500000}
            handleReload={handleAnswerFilePick}
            isFilePending={isFileUploaded || isFileInQueue}
            isFileInQueue={isFileInQueue}
          />
        </div>
      </div>
      {isFileInQueue && isFilePending && (
        <Progress
          theme={theme}
          percent={isFileInProgress ? progress : 0}
          status={uploadingStatus} 
        />
      )}
      {((isFileUploaded && isFilePending) || isFileProcessing) && (
          <Text className="sk-lec-material-entity__question inactive">
            <FormattedMessage {...sharedMessages.fileProcessing} />
          </Text>
      )}
    </div>
)};

AnswerUploading.propTypes = {
  handleAnswerFileUpload: PropTypes.func,
  handleAnswerFilePick: PropTypes.func,
  fileName: PropTypes.string,
  disabled: PropTypes.bool,
};

export default memo(AnswerUploading);
