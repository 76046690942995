import React from 'react';

import './index.scss';

const BlockquoteOnHover = () => (
  <svg
    className="hover"
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.69043" width="32" height="32" rx="2" fill="white" />
    <path
      d="M19.3754 23.8592C19.3754 24.1137 19.1691 24.32 18.9146 24.32H9.65025C9.39576 24.32 9.18945 24.1137 9.18945 23.8592C9.18945 23.6047 9.39576 23.3984 9.65025 23.3984H18.9146C19.1691 23.3984 19.3754 23.6047 19.3754 23.8592ZM21.8993 20.736H9.65025C9.39576 20.736 9.18945 20.5297 9.18945 20.2752C9.18945 20.0207 9.39576 19.8144 9.65025 19.8144H21.5287C21.7831 19.8144 21.9895 20.0207 21.9895 20.2752V20.6458C21.9895 20.6956 21.9491 20.736 21.8993 20.736ZM21.8993 17.0496H9.59905C9.37284 17.0496 9.18945 16.8662 9.18945 16.64C9.18945 16.4137 9.37284 16.2304 9.59905 16.2304H21.5799C21.8061 16.2304 21.9895 16.4137 21.9895 16.64V16.9594C21.9895 17.0092 21.9491 17.0496 21.8993 17.0496ZM21.8993 13.4656H9.65025C9.39576 13.4656 9.18945 13.2593 9.18945 13.0048C9.18945 12.7503 9.39576 12.544 9.65025 12.544H21.5287C21.7831 12.544 21.9895 12.7503 21.9895 13.0048V13.3754C21.9895 13.4252 21.9491 13.4656 21.8993 13.4656ZM21.8993 9.77916H9.59905C9.37284 9.77916 9.18945 9.59578 9.18945 9.36956C9.18945 9.14335 9.37284 8.95996 9.59905 8.95996H21.5799C21.8061 8.95996 21.9895 9.14335 21.9895 9.36956V9.68902C21.9895 9.7388 21.9491 9.77916 21.8993 9.77916Z"
      fill="#7145D8"
    />
  </svg>
);

const DefaultBlockquote = () => (
  <svg
    className="default"
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.3754 23.8592C19.3754 24.1137 19.1691 24.32 18.9146 24.32H9.65025C9.39576 24.32 9.18945 24.1137 9.18945 23.8592C9.18945 23.6047 9.39576 23.3984 9.65025 23.3984H18.9146C19.1691 23.3984 19.3754 23.6047 19.3754 23.8592ZM21.8993 20.736H9.65025C9.39576 20.736 9.18945 20.5297 9.18945 20.2752C9.18945 20.0207 9.39576 19.8144 9.65025 19.8144H21.5287C21.7831 19.8144 21.9895 20.0207 21.9895 20.2752V20.6458C21.9895 20.6956 21.9491 20.736 21.8993 20.736ZM21.8993 17.0496H9.59905C9.37284 17.0496 9.18945 16.8662 9.18945 16.64C9.18945 16.4137 9.37284 16.2304 9.59905 16.2304H21.5799C21.8061 16.2304 21.9895 16.4137 21.9895 16.64V16.9594C21.9895 17.0092 21.9491 17.0496 21.8993 17.0496ZM21.8993 13.4656H9.65025C9.39576 13.4656 9.18945 13.2593 9.18945 13.0048C9.18945 12.7503 9.39576 12.544 9.65025 12.544H21.5287C21.7831 12.544 21.9895 12.7503 21.9895 13.0048V13.3754C21.9895 13.4252 21.9491 13.4656 21.8993 13.4656ZM21.8993 9.77916H9.59905C9.37284 9.77916 9.18945 9.59578 9.18945 9.36956C9.18945 9.14335 9.37284 8.95996 9.59905 8.95996H21.5799C21.8061 8.95996 21.9895 9.14335 21.9895 9.36956V9.68902C21.9895 9.7388 21.9491 9.77916 21.8993 9.77916Z"
      fill="#6D6E71"
    />
  </svg>
);

const ActiveBlockquote = () => (
  <svg
    className="active"
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.3754 23.8592C19.3754 24.1137 19.1691 24.32 18.9146 24.32H9.65025C9.39576 24.32 9.18945 24.1137 9.18945 23.8592C9.18945 23.6047 9.39576 23.3984 9.65025 23.3984H18.9146C19.1691 23.3984 19.3754 23.6047 19.3754 23.8592ZM21.8993 20.736H9.65025C9.39576 20.736 9.18945 20.5297 9.18945 20.2752C9.18945 20.0207 9.39576 19.8144 9.65025 19.8144H21.5287C21.7831 19.8144 21.9895 20.0207 21.9895 20.2752V20.6458C21.9895 20.6956 21.9491 20.736 21.8993 20.736ZM21.8993 17.0496H9.59905C9.37284 17.0496 9.18945 16.8662 9.18945 16.64C9.18945 16.4137 9.37284 16.2304 9.59905 16.2304H21.5799C21.8061 16.2304 21.9895 16.4137 21.9895 16.64V16.9594C21.9895 17.0092 21.9491 17.0496 21.8993 17.0496ZM21.8993 13.4656H9.65025C9.39576 13.4656 9.18945 13.2593 9.18945 13.0048C9.18945 12.7503 9.39576 12.544 9.65025 12.544H21.5287C21.7831 12.544 21.9895 12.7503 21.9895 13.0048V13.3754C21.9895 13.4252 21.9491 13.4656 21.8993 13.4656ZM21.8993 9.77916H9.59905C9.37284 9.77916 9.18945 9.59578 9.18945 9.36956C9.18945 9.14335 9.37284 8.95996 9.59905 8.95996H21.5799C21.8061 8.95996 21.9895 9.14335 21.9895 9.36956V9.68902C21.9895 9.7388 21.9491 9.77916 21.8993 9.77916Z"
      fill="#7145D8"
    />
  </svg>
);

const Blockquote = ({ active }) => {
  const Icon = active ? ActiveBlockquote : DefaultBlockquote;

  return (
    <div className="editor-icon-svg">
      <Icon />
      <BlockquoteOnHover />
    </div>
  );
};

export default Blockquote;
