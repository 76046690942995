import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  firstName: {
    id: 'app.components.SignUpForm.firstName',
    defaultMessage: 'First Name',
  },
  lastName: {
    id: 'app.components.SignUpForm.lastName',
    defaultMessage: 'Last Name',
  },
  email: {
    id: 'app.components.SignUpForm.email',
    defaultMessage: 'Email Address',
  },
  companyEmail: {
    id: 'app.components.SignUpForm.companyEmail',
    defaultMessage: 'Company Email Address',
  },

  password: {
    id: 'app.components.SignUpForm.password',
    defaultMessage: 'Password',
  },
  confirmPassword: {
    id: 'app.components.SignUpForm.confirmPassword',
    defaultMessage: 'Confirm Password',
  },
  country: {
    id: 'app.components.SignUpForm.country',
    defaultMessage: 'Country',
  },
  city: {
    id: 'app.components.SignUpForm.city',
    defaultMessage: 'City',
  },
  companyName: {
    id: 'app.components.SignUpForm.companyName',
    defaultMessage: 'Company Name',
  },
  companyPhone: {
    id: 'app.components.SignUpForm.companyPhone',
    defaultMessage: 'Company Phone',
  },
  companySize: {
    id: 'app.components.SignUpForm.companySize',
    defaultMessage: 'Company Size',
  },
  companySubscription: {
    id: 'app.components.SignUpForm.companySubscription',
    defaultMessage: 'Subscription Plan',
  },
  bySigningUp: {
    id: 'app.components.SignUpForm.bySigningUp',
    defaultMessage: 'By creating an account via E-mail or social networks, you agree to the',
  },
  termsOfUse: {
    id: 'app.components.SignUpForm.termsOfUse',
    defaultMessage: 'Terms of Use',
  },
  privacyPolicy: {
    id: 'app.components.SignUpForm.privacyPolicy',
    defaultMessage: 'Privacy Policy',
  },
  createAcc: {
    id: 'app.components.SignUpForm.createAcc',
    defaultMessage: 'Create Account',
  },
  and: {
    id: 'app.components.SignUpForm.and',
    defaultMessage: 'and',
  },
  save: {
    id: 'app.components.SignUpForm.save',
    defaultMessage: 'Save',
  },
  chooseExp: {
    id: 'app.components.ExpertsProfileForm.chooseExp',
    defaultMessage: 'Choose your expertises',
  },
  aboutMe: {
    id: 'app.components.ExpertsProfileForm.aboutMe',
    defaultMessage: 'About me ...',
  },
  aboutYou: {
    id: 'app.components.ExpertsProfileForm.aboutYou',   
    defaultMessage: 'About you (it will be a part of your course page)',
  },
  fqdn: {
    id: 'app.components.SignUpForm.fqdn',
    defaultMessage: 'Domain Name',
  },
  expertise: {
    id: 'app.components.ExpertsProfileForm.expertise',
    defaultMessage: 'Expertise',
  },
});
