import React from 'react';
import classnames from 'classnames';

import './index.scss';

const IconOnHover = ({ linearId }) => (
  <svg className='filled' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.5293 15.4091V3.47643C3.5293 2.85205 4.0288 2.35254 4.65319 2.35254H11.7018C12.0348 2.35254 12.34 2.49129 12.562 2.75492L15.7117 6.43184C15.8782 6.63997 15.9753 6.88973 15.9753 7.16723V15.4368C15.9753 16.5885 15.0457 17.5181 13.8941 17.5181L5.5967 17.4904C4.45893 17.4765 3.5293 16.5469 3.5293 15.4091Z" fill={`url(#paint1_linear_${linearId})`} stroke={`url(#paint1_linear_${linearId})`} strokeMiterlimit="10"/>
    <path d="M12.0352 2.47754V6.18222C12.0352 6.51522 12.2988 6.77885 12.6318 6.77885L15.8508 6.8066" fill="white"/>
    <path d="M7.05859 10.407L9.32898 12.9409L12.9409 8.82324" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
    <defs>
    <linearGradient id={`paint1_linear_${linearId}`} x1="17.1023" y1="18.5389" x2="1.45235" y2="16.9634" gradientUnits="userSpaceOnUse">
    <stop stopColor="#8F26AF"/>
    <stop offset="0.979167" stopColor="#141685"/>
    </linearGradient>
    <linearGradient id={`paint1_linear_${linearId}`} x1="17.1023" y1="18.5389" x2="1.45235" y2="16.9634" gradientUnits="userSpaceOnUse">
    <stop stopColor="#8F26AF"/>
    <stop offset="0.979167" stopColor="#141685"/>
    </linearGradient>
    </defs>
  </svg>
);

const IconDefault = ({ linearId }) => (
  <svg className='outlined' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.5293 15.4091V3.47643C3.5293 2.85205 4.0288 2.35254 4.65319 2.35254H11.7018C12.0348 2.35254 12.34 2.49129 12.562 2.75492L15.7117 6.43184C15.8782 6.63997 15.9753 6.88973 15.9753 7.16723V15.4368C15.9753 16.5885 15.0457 17.5181 13.8941 17.5181L5.5967 17.4904C4.45893 17.4765 3.5293 16.5469 3.5293 15.4091Z" stroke="#6D7C90" strokeMiterlimit="10"/>
    <path d="M12.0352 2.47754V6.18222C12.0352 6.51522 12.2988 6.77885 12.6318 6.77885L15.8508 6.8066" stroke="#6D7C90" strokeMiterlimit="10"/>
    <path d="M7.05859 10.407L9.32898 12.9409L12.9409 8.82324" stroke={`url(#paint0_linear_${linearId})`} strokeLinecap="round" strokeLinejoin="round"/>
    <defs>
    <linearGradient id={`paint0_linear_${linearId}`} x1="13.4736" y1="13.218" x2="6.22462" y2="11.9477" gradientUnits="userSpaceOnUse">
    <stop stopColor="#6D7C90"/>
    <stop offset="0.979167" stopColor="#6D7C90"/>
    </linearGradient>
    </defs>
  </svg>
);

const Icon = ({ disabled }) => {
  const randomFloatString = `${Math.random()}`
  return (
    <div className={classnames({ 'icon-with-hover': !disabled })}>
      <IconDefault linearId={randomFloatString} />
      {!disabled && (<IconOnHover linearId={randomFloatString} />)}
    </div>
  );
};

export default Icon;
