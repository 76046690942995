import { createContext } from 'react';

export const SidebarContext = createContext({
  sidebarContext: {
    openedSidebar: null,
    props: {},
    showSidebar: () => {},
    hideSidebar: () => {},
  },
});
