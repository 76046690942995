// Core
import React from 'react';

// Components
import Text from 'components/Text';
import Heading from 'components/Heading';

// Styles
import './AboutUsText.scss';

// Other
import { FormattedMessage } from 'react-intl';
import { messages } from 'containers/AboutUsText/messages';

const AboutUsText = () => (
  <>
    <Heading variant="h3" bold classes="sk-about-us-text__preheading">
      <FormattedMessage {...messages.whoWeAre} />
    </Heading>
    <div className="sk-about-us-text__weAre">
      <Text classes="sk-about-us-text__paragraph">
        <FormattedMessage {...messages.weAre} />
      </Text>
      <Text classes="sk-about-us-text__paragraph">
        <FormattedMessage {...messages.at} />
        <b> <FormattedMessage {...messages.skilleton} /> </b>
        <FormattedMessage {...messages.weBelieveAnyone} />
        <b> <FormattedMessage {...messages.skilleton} /> </b>
        <FormattedMessage {...messages.isAnAudience} />
      </Text>
      <Text classes="sk-about-us-text__paragraph">
        <b> <FormattedMessage {...messages.skilleton} /> </b>
        <FormattedMessage {...messages.isYourGateway} />
        <b> <FormattedMessage {...messages.skilleton} /> </b>
        <FormattedMessage {...messages.isWhereAnyone} />
      </Text>
      <Text classes="sk-about-us-text__paragraph">
        <FormattedMessage {...messages.buildTheSkills} />
      </Text>
    </div>
    <Heading variant="h3" bold classes="sk-about-us-text__preheading">
      <FormattedMessage {...messages.whySkilleton} />
    </Heading>
    <div className="sk-about-us-text__whyList">
      <Text classes="sk-about-us-text__paragraph">
        <FormattedMessage {...messages.whySkilletonOne} />
        <b> <FormattedMessage {...messages.whySkilletonOneTeachWhatYouLove} /> </b>
      </Text>
      <Text classes="sk-about-us-text__paragraph">
        <FormattedMessage {...messages.whySkilletonTwo} />
        <b> <FormattedMessage {...messages.whySkilletonTwoGetSkills} /> </b>
      </Text>
      <Text classes="sk-about-us-text__paragraph">
        <FormattedMessage {...messages.whySkilletonThree} />
        <b> <FormattedMessage {...messages.whySkilletonThreeGenerateExtraIncome} /> </b>
        <FormattedMessage {...messages.whySkilletonThreeForYourFamily} />
      </Text>
      <Text classes="sk-about-us-text__paragraph">
        <FormattedMessage {...messages.whySkilletonFour} />
        <b> <FormattedMessage {...messages.whySkilletonChooseACourseAndMakeItYours} /> </b>
      </Text>
      <Text classes="sk-about-us-text__paragraph">
        <FormattedMessage {...messages.whySkilletonFive} />
        <b> <FormattedMessage {...messages.whySkilletonFiveExchangeIdeas} /> </b>
      </Text>
      <Text classes="sk-about-us-text__paragraph">
        <FormattedMessage {...messages.whySkilletonSix} />
        <b> <FormattedMessage {...messages.whySkilletonSixEnrichYourCommunity} /> </b>
      </Text>
    </div>
    <Heading variant="h3" bold classes="sk-about-us-text__preheading">
      <FormattedMessage {...messages.ourPurpose} />
    </Heading>
    <Text classes="sk-about-us-text__paragraph">
      <b><FormattedMessage {...messages.skIsHereToProvide} /></b>
    </Text>
  </>
);

export default AboutUsText;
