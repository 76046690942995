import React from 'react';
import PropTypes from 'prop-types';
import Cross from 'components/Icon/SvgCss/Cross';
import classnames from 'classnames';

import {Grey, PurpleLight} from 'components/Icon/color';

import '../Modal.scss';

const CloseButton = ({ disabled, handleClick }) => (
  <button
    type="button"
    className={classnames('sk-modal__close-btn', {
      'sk-modal__close-btn__disabled': disabled,
    })}
    onClick={handleClick}
  >
    <Cross width="22" height="22" color={Grey} hoverColor={PurpleLight} />
  </button>
);

CloseButton.propTypes = {
  handleClick: PropTypes.func,
};

export default CloseButton;
