import React from 'react';

import 'components/Icon/SvgCss/index.scss';

const IconOnHover = ({ linearId }) => (
  <svg class='filled' width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.5 1C10.1963 1 1 10.1963 1 21.5C1 32.8028 10.1963 42 21.5 42C32.8028 42 42 32.8028 42 21.5C42 10.1963 32.8045 1 21.5 1Z" fill={`url(#paint0_linear_${linearId})`} stroke={`url(#paint1_linear_${linearId})`} />
    <path d="M25.6485 22.008H22.9341C22.9341 26.4869 22.9341 32 22.9341 32H18.912C18.912 32 18.912 26.5403 18.912 22.008H17V18.4765H18.912V16.1923C18.912 14.5563 19.6647 12 22.9711 12L25.9516 12.0118V15.4399C25.9516 15.4399 24.1405 15.4399 23.7883 15.4399C23.4362 15.4399 22.9355 15.6217 22.9355 16.4019V18.4772H26L25.6485 22.008Z" fill="white"/>
    <defs>
    <linearGradient id={`paint0_linear_${linearId}`} x1="45.7124" y1="44.7597" x2="-5.5924" y2="38.4662" gradientUnits="userSpaceOnUse">
    <stop stopColor="#8F26AF"/>
    <stop offset="0.979167" stopColor="#141685"/>
    </linearGradient>
    <linearGradient id={`paint1_linear_${linearId}`} x1="45.7124" y1="44.7597" x2="-5.5924" y2="38.4662" gradientUnits="userSpaceOnUse">
    <stop stopColor="#8F26AF"/>
    <stop offset="0.979167" stopColor="#141685"/>
    </linearGradient>
    </defs>
  </svg>
);

const IconDefault = ({ linearId }) => (
  <svg class='outlined' width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.5 1C10.1963 1 1 10.1963 1 21.5C1 32.8028 10.1963 42 21.5 42C32.8028 42 42 32.8028 42 21.5C42 10.1963 32.8045 1 21.5 1Z" stroke={`url(#paint3_linear_${linearId})`} />
    <path d="M25.6485 22.008H22.9341C22.9341 26.4869 22.9341 32 22.9341 32H18.912C18.912 32 18.912 26.5403 18.912 22.008H17V18.4765H18.912V16.1923C18.912 14.5563 19.6647 12 22.9711 12L25.9516 12.0118V15.4399C25.9516 15.4399 24.1405 15.4399 23.7883 15.4399C23.4362 15.4399 22.9355 15.6217 22.9355 16.4019V18.4772H26L25.6485 22.008Z" stroke="#610FB6"/>
    <defs>
    <linearGradient id={`paint3_linear_${linearId}`} x1="45.7124" y1="44.7597" x2="-5.5924" y2="38.4662" gradientUnits="userSpaceOnUse">
    <stop stopColor="#8F26AF"/>
    <stop offset="0.979167" stopColor="#141685"/>
    </linearGradient>
    </defs>
  </svg>
);

const Icon = () => {
  const randomFloatString = `${Math.random()}`
  return (
    <div className='icon-with-hover'>
      <IconDefault linearId={randomFloatString} />
      <IconOnHover linearId={randomFloatString} />
    </div>
  );
};

export default Icon;
