// Core
import React, {useEffect, useState} from 'react';
import { compose } from 'react-apollo';
import { injectIntl, } from 'react-intl';
import withWidth from 'containers/HOCs/withWidth';

// Components
import Link from 'components/Link';
import Image from 'components/Image';
import Text from 'components/Text';
import Materials from 'components/Icon/SvgCss/Materials';
import Resume from 'components/Icon/SvgCss/Resume';
import Button from 'components/Button';
import { PurpleDark } from 'components/Icon/color';

// Other
import { getCourseThumbnail, isBlank } from 'utils/helpers';
import { sharedMessages } from 'services/i18n/sharedMessages/messages.js';
import { messages } from 'containers/Sidebar/SidebarContent/StudentCabinetSidebar/StudentActiveCoursesList/messages';
import { getURLofLecture } from 'routes/StudentsCabinet/StudentCourses/CourseCard/helpers.js';

// Styles
import './index.scss';

const COMPONENT_PREFIX = 'sk-student-course_card';

const statusesDetails = {
  'ACTIVE': 'active',
  'COMPLETED': 'completed',
}

const CourseCard = ({
  isExpert,
  coursePageLink,
  mappingId,
  course: { title, coursePhoto, thumbnail, id },
  purchasedCourses,
  intl: { formatMessage },
  classes='',
  width
}) => {
  const courseDetails = purchasedCourses.find(course => course.courseId === id);

  const status = statusesDetails[courseDetails.courseStatus];
  const [isMobile, setMobile] = useState(false);
  const isCourseCompleted = courseDetails.courseStatus === "COMPLETED";

  useEffect(()=>{
    if(width === 'sm' || width === 'xs'){
      setMobile(true);
    } else{
      setMobile(false);
    }
  }, [width, setMobile]);

  return(
    <div className={`${COMPONENT_PREFIX} ${classes}`}>
      <div className={`${COMPONENT_PREFIX}__picture`}>
        <Image src={getCourseThumbnail(thumbnail, coursePhoto)} />
      </div>
      <div  className={`${COMPONENT_PREFIX}__row`}>
        <Text className={`${COMPONENT_PREFIX}__title`}>
          {title}
        </Text>
        {
          isMobile ? (
          <div className={`${COMPONENT_PREFIX}__action`}>
            <Link to={`/student-cabinet/courses/${mappingId}/materials`}>
              <Button color="secondary" outlined>{formatMessage(messages.courseStudyMats)}</Button>
            </Link>
            {!isCourseCompleted &&
              (<Link to={getURLofLecture(mappingId, courseDetails?.courseId, purchasedCourses, courseDetails?.variantName)}>
                  <Button>{formatMessage(messages.resumeCourse)}</Button>
                </Link>)
            }
          </div>
        ) : (
          <div className={`${COMPONENT_PREFIX}__action`}>
            <Link to={`/student-cabinet/courses/${mappingId}/materials`}>
              <Materials color={PurpleDark} />
              <span className="tooltip">{formatMessage(messages.courseStudyMats)}</span>
            </Link>
            {!isCourseCompleted &&
              (<Link to={getURLofLecture(mappingId, courseDetails?.courseId, purchasedCourses, courseDetails?.variantName)}>
                <Resume color={PurpleDark} />
                <span className="tooltip">{formatMessage(messages.resumeCourse)}</span>
              </Link>)
            }
          </div>
        )
      }
      </div>
      <div className={`${COMPONENT_PREFIX}__status`}>
        <Text className={`${COMPONENT_PREFIX}__status_label`}>{formatMessage(sharedMessages[status])}</Text>
      </div>
    </div>
  );
}

const enhancer = compose(
  injectIntl,
  withWidth()
);

export default enhancer(CourseCard);
