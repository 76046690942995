import React, { memo } from 'react';
import { compose } from 'react-apollo';

const Icon = ({ ...props }) => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="-0.595585" y="0.381155" width="3.96935" height="6.54371" rx="1.98467" transform="matrix(-0.976739 -0.21443 -0.21443 0.976739 21.3341 12.6058)" stroke="url(#paint0_linear)"/>
    <rect x="3.53699" y="13.1058" width="3.96935" height="6.54371" rx="1.98467" transform="rotate(-12.3821 3.53699 13.1058)" stroke="url(#paint1_linear)"/>
    <rect x="10.7646" y="19.8994" width="3.95034" height="1.85019" rx="0.925097" stroke="url(#paint2_linear)"/>
    <path d="M6.98569 12.5006C6.72337 10.4338 6.93673 4.61784 12.7378 4.42959C18.5388 4.24134 18.9902 10.2908 18.7504 12.5006" stroke="url(#paint3_linear)" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.6967 2.20605L12.6967 2.70605L12.6967 2.20605ZM20.6382 13.0694C20.6132 13.3444 20.816 13.5876 21.091 13.6125C21.366 13.6375 21.6092 13.4348 21.6341 13.1598L20.6382 13.0694ZM5.07162 12.8958C4.89413 11.2232 5.03233 8.6426 6.10518 6.50341C6.63787 5.44126 7.39566 4.49773 8.45192 3.81819C9.50634 3.13984 10.8869 2.70597 12.6967 2.70605L12.6967 1.70605C10.7203 1.70596 9.14667 2.18216 7.91088 2.9772C6.67693 3.77105 5.80855 4.86422 5.21129 6.05511C4.02424 8.422 3.88742 11.2128 4.07721 13.0013L5.07162 12.8958ZM12.6967 2.70605C14.6025 2.70615 16.0488 3.13817 17.1476 3.81585C18.2468 4.49382 19.0273 5.43572 19.5729 6.50071C20.6722 8.64678 20.8019 11.2658 20.6382 13.0694L21.6341 13.1598C21.8062 11.2628 21.6836 8.42775 20.4629 6.0448C19.8484 4.84528 18.9521 3.75392 17.6725 2.96472C16.3925 2.17523 14.7582 1.70616 12.6967 1.70605L12.6967 2.70605Z" fill="url(#paint4_linear)"/>
    <path d="M14.7314 21.1287C16.6965 21.1287 17.5881 19.8369 18.1703 18.6724" stroke="url(#paint5_linear)"/>
    <defs>
    <linearGradient id="paint0_linear" x1="5.4193" y1="8.05148" x2="-0.851646" y2="7.54474" gradientUnits="userSpaceOnUse">
    <stop stop-color="#8F26AF"/>
    <stop offset="0.979167" stop-color="#141685"/>
    </linearGradient>
    <linearGradient id="paint1_linear" x1="8.3607" y1="20.7761" x2="2.08976" y2="20.2694" gradientUnits="userSpaceOnUse">
    <stop stop-color="#8F26AF"/>
    <stop offset="0.979167" stop-color="#141685"/>
    </linearGradient>
    <linearGradient id="paint2_linear" x1="15.6632" y1="22.4415" x2="9.6485" y2="21.16" gradientUnits="userSpaceOnUse">
    <stop stop-color="#8F26AF"/>
    <stop offset="0.979167" stop-color="#141685"/>
    </linearGradient>
    <linearGradient id="paint3_linear" x1="19.8747" y1="13.0442" x2="5.25892" y2="10.4061" gradientUnits="userSpaceOnUse">
    <stop stop-color="#8F26AF"/>
    <stop offset="0.979167" stop-color="#141685"/>
    </linearGradient>
    <linearGradient id="paint4_linear" x1="22.7076" y1="13.8488" x2="2.23033" y2="10.0057" gradientUnits="userSpaceOnUse">
    <stop stop-color="#8F26AF"/>
    <stop offset="0.979167" stop-color="#141685"/>
    </linearGradient>
    <linearGradient id="paint5_linear" x1="18.4817" y1="21.294" x2="14.2389" y2="20.5654" gradientUnits="userSpaceOnUse">
    <stop stop-color="#8F26AF"/>
    <stop offset="0.979167" stop-color="#141685"/>
    </linearGradient>
    </defs>
    </svg>
    
  );
};

const enhancer = compose(
  memo,
);

export default enhancer(Icon);
