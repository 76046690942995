import React from 'react';

const CloseButton = () => (
  <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.71436 1.85791L15.812 16.5195" stroke="#A9B6C8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15.812 1.85791L1.71436 16.5195" stroke="#A9B6C8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
);

export default CloseButton;
