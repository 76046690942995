// Core
import React, { useState, useEffect, useCallback } from 'react';
import { graphql, compose } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { withFormik } from 'formik';

// Components
import Card from 'components/Card';
import TextInput from 'components/Input/Material/TextInput';
import TextAreaInput from 'components/Input/Material/TextAreaInput';

// GraphQL
import UPDATE_QUIZ from 'mutations/Courses/quiz/updateQuiz.gql';

// Other
import { withFormattedUntitled } from 'routes/ExpertsCabinet/QuizEdit/HOCs/withFormattedUntitled.js';
import { withNotification } from 'containers/NotificationProvider/withNotification';
import { keysToSnake, parseFetchedData, isBlank } from 'utils/helpers';
import { withModal } from 'containers/ModalProvider/withModal';
import { messages } from 'routes/ExpertsCabinet/QuizEdit/messages';
import { getYupSchema } from 'services/yup';
import { sharedMessages } from 'services/i18n/sharedMessages/messages';

// Styles and Assets
import './index.scss';

const INITIAL_FORM_VALUES = {
  title: '',
  duration: 0,
}

const QuizInfo = (props) => {
  const {
    quizId,
    isValid,
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    validateForm,
    intl: { formatMessage },
    updateQuiz,
    showNotificationBar,
  } = props;

  const handleInputChange = fieldName => inputValue => {
    setFieldValue(fieldName, inputValue);
    setFieldTouched(fieldName);
  }

  const handleInputBlur = useCallback(fieldName => async (inputValue, e) => {
    // setFieldTouched(fieldName);
    setFieldValue(fieldName, inputValue, false);

    // const isValid = isBlank(errors[fieldName]);

    const errors = await validateForm({ ...values, [fieldName]: inputValue });
    const hasError = errors[fieldName];

  console.log('errors', errors);

    const isDefaultTitle = fieldName === 'title' && !isBlank(values[fieldName])
      && formatMessage(messages.quizInfoUntitledTitle) === values[fieldName].replace(/\s+/g, '');

    if (!hasError && !isDefaultTitle) {
      const input = {
        id: quizId,
        [fieldName]: values[fieldName],
      }
      await updateQuiz(input);
      showNotificationBar('saved');
    };
  }, [values, quizId]);

  // const durationValue = values?.duration === '' ? '' : +values?.duration;

  return (
    <Card classes="sk-quiz-general-info">
      <TextInput
        name="title"
        label={formatMessage(messages.quizInfoTitle)}
        placeholder={formatMessage(messages.quizTitlePlaceholder)}
        value={values.title}
        error={touched.title && Boolean(errors.title)}
        helperText={touched.title ? errors.title : ''}
        onBlur={handleInputBlur('title')}
        onChange={handleInputChange('title')}
      />
      <TextInput
        name="duration"
        type='number'
        label={formatMessage(messages.quizInfoDuration)}
        value={values?.duration}
        adornment={formatMessage(sharedMessages.min)}
        helperText={touched.duration ? errors.duration : ''}
        error={touched.duration && Boolean(errors.duration)}
        onChange={handleInputChange('duration')}
        onBlur={handleInputBlur('duration')}
      />
    </Card>
  )
}

const updateQuizMutation = graphql(UPDATE_QUIZ, {
  props: ({ mutate }) => ({
    updateQuiz: input => {
      mutate({ variables: { input }});
    },
  }),
  options: () => ({
    refetchQueries: ['getQuiz'],
  }),
});

const formikStateManagement = withFormik({
  enableReinitialize: false,
  mapPropsToValues: ({ title, duration, formatUntitled }) => {
    const formatedTitle = formatUntitled(title, messages.quizInfoUntitledTitle);

    return { ...INITIAL_FORM_VALUES, title: formatedTitle, duration, };
  },
  displayName: 'QuizInfoForm',
  validationSchema: () => getYupSchema('quizInfoValidationSchema'),
  validateOnChange: false,
  validateOnBlur: false
})

const enhancer = compose(
  withFormattedUntitled,
  injectIntl,
  formikStateManagement,
  updateQuizMutation,
  withNotification
);

export default enhancer(QuizInfo);
