// Core
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// Components
import Heading from 'components/Heading';
import Button from 'components/Button';

import { FormattedMessage } from 'react-intl';
import { messages } from 'routes/PasswordReset/ConfirmationModal/messages';

// Styles and Assets
import './ConfirmationModal.scss';

class ConfirmationModal extends PureComponent {
  render() {
    const { handleContinueClick } = this.props;

    return (
      <div className="sk-confirmation-modal">
        <div className="sk-confirmation-modal_header">
          <Heading variant="h4" classes="sk-confirmation-modal_heading">
            <FormattedMessage {...messages.passRecoveryCode} />
          </Heading>
        </div>
        <div className="sk-confirmation-modal_content">
          <Button
            variant="text"
            color="secondary"
            size="large"
            onClick={handleContinueClick}
          >
            <FormattedMessage {...messages.continue} />
          </Button>
        </div>
      </div>
    );
  }
}

ConfirmationModal.propTypes = {
  handleContinueClick: PropTypes.func,
};

export default ConfirmationModal;
