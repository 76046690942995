// Core
import React, { PureComponent, Fragment } from 'react';

import PropTypes from 'prop-types';

// Styles and Assets
import './CurriculumDetails.scss';

// Components
import Text from 'components/Text';
import Duration from 'components/Duration/Duration';
import CurriculumMaterialIcon from 'routes/CourseDetails/Curriculum/CurriculumDetails/CurriculumMaterialIcon';

// Icons
import GiftIcon from 'components/Icon/Svg/Gift';
import Audio from 'components/Icon/SvgCss/PublicCoursePage/AudioMaterial.js';
import Quiz from 'components/Icon/SvgCss/PublicCoursePage/QuizMaterial.js';
import Document from 'components/Icon/SvgCss/PublicCoursePage/DocumentMaterial.js';
import Video from 'components/Icon/SvgCss/PublicCoursePage/VideoMaterial.js';

// Other
import { Red, Transparent } from 'components/Icon/color';
import { FormattedMessage } from 'react-intl';
import { sharedMessages } from 'services/i18n/sharedMessages/messages';
import {
  LECTURE_VIDEO,
  LECTURE_AUDIO,
  LECTURE_ARTICLE,
  LECTURE_QUIZ
} from 'containers/UploadManagerProvider/helpers';
import { compareSortNumber } from 'utils/helpers/index';
import classNames from 'classnames';

const classNamePrefix = 'sk-cur-details';

class CurriculumDetails extends PureComponent {
  renderMaterial = (icon, material) => {
    return (
      <div key={material.id} className={classNamePrefix}>
        <div className={`${classNamePrefix}__icon`}>
          <CurriculumMaterialIcon icon={icon} material={material} />
        </div>
        <div className={classNames({
          [`${classNamePrefix}__title`]: true,
          'is-active': material.isPublic
        })}>
          <Text>{material.title}</Text>
        </div>
        <div className={`${classNamePrefix}__duration`}>
          <Duration duration={material?.duration || 0} />
        </div>
        <div className={`${classNamePrefix}__gift`}>
          {(material.isPublic === true) && (
            <GiftIcon active={true} width={18.71} height={16.69} />
          )}
        </div>
      </div>
    );
  }

  render() {
    const { materials } = this.props;
    const sortedMaterials =
      materials !== null && materials.length > 0
        ? materials.sort(compareSortNumber)
        : [];

    return (
      <>
        {sortedMaterials.map(material => {
          const { type, } = material;
          switch (type) {
            case LECTURE_VIDEO:
              return this.renderMaterial(
                Video,
                material
              );
              break;
            case LECTURE_AUDIO:
              return this.renderMaterial(
                Audio,
                material
              );
              break;
            case LECTURE_ARTICLE:
              return this.renderMaterial(
                Document,
                material
              );
              break;
            case LECTURE_QUIZ:
              return this.renderMaterial(
                Quiz,
                material
              );
              break;
            default:
              return this.renderMaterial(
                Document,
                material
              );
              break;
          }
        })}
      </>
    );
  }
}

export default CurriculumDetails;
