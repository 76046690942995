// Core
import React, { memo } from "react";
import PropTypes from "prop-types";

// Components
import Markdown from 'react-markdown'
import Button from "components/Button";
import Heading from "components/Heading";

// Styles and Assets
import "./SuggestionsModal.scss";

const SuggestionsModal = memo(({ handleSuggestionClose, suggestions }) => {
    return (
        <div role="dialog" aria-labelledby="ai-suggestions-title">
            <Heading id="ai-suggestions-title" variant="h2" newLine>AI Suggestions</Heading>
            <Markdown>
                {suggestions}
            </Markdown>
            <Button variant="text" color="secondary" size="large" type="button" onClick={handleSuggestionClose}>
                Close
            </Button>
        </div>
    );
});

SuggestionsModal.propTypes = {
    handleSuggestionClose: PropTypes.func.isRequired,
    suggestions: PropTypes.string.isRequired,
};

export default SuggestionsModal;
