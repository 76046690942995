import React, { memo } from 'react';
import { compose } from 'react-apollo';

const Icon = ({ ...props }) => {
  const randomFloatString = `${Math.random()}`
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.5 19.4034V7.65117C2.5 7.00128 3.00827 6.5 3.59795 6.5H5C5.27614 6.5 5.5 6.72386 5.5 7V20.0546C5.5 20.3308 5.27614 20.5546 5 20.5546H3.59795C3.00828 20.5546 2.5 20.0534 2.5 19.4034Z" stroke="#610FB6"/>
    <path d="M8.36426 6.5H21.3486C21.8946 6.5 22.4998 7.08223 22.4998 8.01448C22.4998 8.28262 22.2824 8.5 22.0143 8.5H8.36426C8.08812 8.5 7.86426 8.27614 7.86426 8V7C7.86426 6.72386 8.08812 6.5 8.36426 6.5Z" stroke="#610FB6"/>
    <path d="M7.86426 20.0545V10.6899C7.86426 10.4138 8.08812 10.1899 8.36426 10.1899H21.9998C22.276 10.1899 22.4998 10.4138 22.4998 10.6899V19.4033C22.4998 20.0376 21.983 20.5545 21.3486 20.5545H8.36426C8.08812 20.5545 7.86426 20.3306 7.86426 20.0545Z" stroke="#610FB6"/>
    <path d="M12.2992 15.9873C12.2772 16.3015 12.1649 16.5488 11.9622 16.7293C11.7607 16.9098 11.4947 17 11.1641 17C10.8026 17 10.5178 16.8743 10.3099 16.623C10.1033 16.3703 10 16.0241 10 15.5842V15.4057C10 15.125 10.0478 14.8777 10.1433 14.6638C10.2389 14.4499 10.3751 14.2861 10.552 14.1725C10.7302 14.0575 10.9369 14 11.1719 14C11.4973 14 11.7594 14.0902 11.9583 14.2707C12.1572 14.4512 12.2721 14.7045 12.3031 15.0307H11.722C11.7078 14.8422 11.6568 14.7059 11.569 14.6217C11.4824 14.5361 11.3501 14.4933 11.1719 14.4933C10.9782 14.4933 10.8329 14.5655 10.7361 14.7099C10.6405 14.8529 10.5914 15.0755 10.5888 15.3777V15.5983C10.5888 15.9138 10.6347 16.1444 10.7264 16.2901C10.8193 16.4358 10.9653 16.5087 11.1641 16.5087C11.3436 16.5087 11.4773 16.4666 11.5651 16.3824C11.6542 16.2968 11.7052 16.1651 11.7181 15.9873H12.2992Z" fill="url(#paint0_linear)"/>
    <path d="M14.6339 15.891H14.171V16.9599H13.5899V14.0401H14.6378C14.9709 14.0401 15.2279 14.117 15.4087 14.2707C15.5895 14.4245 15.6799 14.6417 15.6799 14.9225C15.6799 15.1217 15.6379 15.2881 15.554 15.4218C15.4713 15.5541 15.3454 15.6598 15.1763 15.7386L15.7864 16.9318V16.9599H15.1627L14.6339 15.891ZM14.171 15.4037H14.6397C14.7856 15.4037 14.8986 15.3656 14.9787 15.2894C15.0588 15.2119 15.0988 15.1056 15.0988 14.9706C15.0988 14.8329 15.0607 14.7246 14.9845 14.6457C14.9096 14.5668 14.794 14.5274 14.6378 14.5274H14.171V15.4037Z" fill="url(#paint1_linear)"/>
    <path d="M17.7918 14.0401L18.5163 16.1578L19.2368 14.0401H20V16.9599H19.417V16.1618L19.4751 14.7841L18.7138 16.9599H18.3148L17.5555 14.7861L17.6136 16.1618V16.9599H17.0325V14.0401H17.7918Z" fill="url(#paint2_linear)"/>
    <defs>
    <linearGradient id="paint0_linear" x1="20.9055" y1="17.2019" x2="10.0233" y2="12.7522" gradientUnits="userSpaceOnUse">
    <stop stop-color="#8F26AF"/>
    <stop offset="0.979167" stop-color="#141685"/>
    </linearGradient>
    <linearGradient id="paint1_linear" x1="20.9055" y1="17.2019" x2="10.0233" y2="12.7522" gradientUnits="userSpaceOnUse">
    <stop stop-color="#8F26AF"/>
    <stop offset="0.979167" stop-color="#141685"/>
    </linearGradient>
    <linearGradient id="paint2_linear" x1="20.9055" y1="17.2019" x2="10.0233" y2="12.7522" gradientUnits="userSpaceOnUse">
    <stop stop-color="#8F26AF"/>
    <stop offset="0.979167" stop-color="#141685"/>
    </linearGradient>
    </defs>
    </svg>
    
    
  );
};

const enhancer = compose(
  memo,
);

export default enhancer(Icon);
