// Core
import React from 'react';
import PropTypes from 'prop-types';

// Components
import Button from 'components/Button';
import ShareButton from 'components/ShareButton';
import AddToCartIcon from 'components/CourseCard/AddToCartIcon';
import Text from 'components/Text';
import InlinePrice from "components/CourseCard/InlinePrice";
import GiftIcon from "components/Icon/SvgCss/Gift";

// Other
import { withShoppingCart } from 'containers/ShoppingCartProvider/withShoppingCart';
import { isBlank } from 'utils/helpers';

// Styles and Assets
import './CourseActionBlock.scss';

import { FormattedMessage } from 'react-intl';
import { messages } from 'routes/CourseDetails/BuyCourse/CourseActionBlock/messages';

const CourseActionBlock = ({
  disabled,
  boughted,
  shoppingCartContext: { _isCourseAddedInShoppingCart, handleAddCourseToCart, handleBuyNowClick },
  selectedVariant,
  courseUrl,
  ...rest
}) => (
  <>
    <div className="sk-course-action-block">
      <div className="sk-course-action-block__icons">
        <InlinePrice
          withIcon={selectedVariant?.price && selectedVariant?.price === 0}
          icon={GiftIcon}
          price={selectedVariant?.price}
        />
        <ShareButton iconProps={{ width: 43, height: 43 }} />
        {(!isBlank(selectedVariant?.name) || boughted) && (
          <div
            onClick={
              !boughted &&
              !disabled &&
              handleAddCourseToCart({
                interactionLevel: selectedVariant?.name,
                price: selectedVariant?.price,
                ...rest
              })
            }
          >
            <AddToCartIcon
              disabled={
                disabled ||
                isBlank(selectedVariant?.name) ||
                boughted ||
                _isCourseAddedInShoppingCart({ id: rest?.id })
              }
            />
          </div>
        )}
      </div>
      <Button
        disabled={boughted || disabled || isBlank(selectedVariant?.name)}
        primary={false}
        variant="text"
        onClick={handleBuyNowClick({
          interactionLevel: selectedVariant?.name,
          price: selectedVariant?.price,
          ...rest
        })}
      >
        {selectedVariant?.price === 0 ? (
          <FormattedMessage {...messages.join} />
        ) : (
          <FormattedMessage {...messages.buyNow} />
        )}
      </Button>
    </div>
    {boughted && (
      <div className="sk-course-action-block__message">
        <Text size="caption" accent>
          <FormattedMessage {...messages.youHaveAlreadyBought} />
        </Text>
      </div>
    )}
  </>
);

export default withShoppingCart(CourseActionBlock);
