// Core
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';
import { injectIntl } from 'react-intl';

// Components
import Text from 'components/Text';
import Button from 'components/Button';

// Other
import { messages } from 'routes/CourseDetails/Error/messages';

// Styles and Assets
import './NotOnSale.scss';

const NotOnSale = ({ goToSearch, intl: { formatMessage, }}) => {
  return (
    <div className='mui-override sk-course-details-error-container'>
      <div className='mui-override sk-course-details-error-container__error'>
        <Text className='mui-override sk-course-details-error-container__error__text'>
          {formatMessage(messages.notMoreOnSale)}
        </Text>
        <Button onClick={goToSearch} size='small'variant="text"color="secondary" className='mui-override sk-course-details-error-container__error__button'>
          {formatMessage(messages.goToSearch)}
        </Button>
      </div>
    </div>
  );
}


NotOnSale.propTypes = {
  course: PropTypes.object,
};

const enhancer = compose(
  injectIntl
)

export default enhancer(NotOnSale);
