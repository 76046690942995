import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  enterTempPassword: {
    id: 'app.components.PasswordUpdateForm.enterTempPassword',
    defaultMessage: 'Old password',
  },
  newPassword: {
    id: 'app.components.PasswordUpdateForm.newPassword',
    defaultMessage: 'New password',
  },
  reenterNewPassword: {
    id: 'app.components.PasswordUpdateForm.reenterNewPassword',
    defaultMessage: 'Re-enter new password',
  },
  changePassword: {
    id: 'app.components.PasswordUpdateForm.changePassword',
    defaultMessage: 'Update password',
  },
});
