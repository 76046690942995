import React from 'react';
import PropTypes from 'prop-types';
import { Red, SidebarDefault } from 'components/Icon/color';

const TrashBin = ({ color, height, width, disabled }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 19 21"
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke={disabled ? SidebarDefault : color}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path
        strokeWidth=".972"
        d="M16.177 3.35v13.616c0 1.747-1.35 3.176-2.999 3.176H5.822c-1.65 0-3-1.43-3-3.176V3.351"
      />
      <path
        d="M.221 3.314H18.78M9.463 6.886v9.72M6.37 8.432v6.628M12.63 8.432v6.628M13.808
       3.113A2.9 2.9 0 0 0 10.915.221h-2.83a2.9 2.9 0 0 0-2.893 2.892"
      />
    </g>
  </svg>
);

TrashBin.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
};

TrashBin.defaultProps = {
  height: '21',
  width: '19',
  color: Red,
};

export default TrashBin;
