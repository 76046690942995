// Core
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'react-apollo';

// Components
import Button from 'components/Button';
import UserAvatar from 'components/UserAvatar';
import Text from 'components/Text';
// import { Doughnut } from 'react-chartjs-2';

// Other
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { parseFetchedData } from 'utils/helpers';

// Styles and Assets
import './UserTableRow.scss';

const getClasses = () => ({
  root: 'mui-override sk-usersTableRow'
});

class UserTableRow extends PureComponent {
  handleClickOnUser = user => {
    const userCatalogue = userRole ? `${userRole}s` : 'team';
    const { history, userRole } = this.props;
    history.push(`/admin/panel/${userCatalogue}/${user.id}`);
  };

  render() {
    const { item, index, arr, handleSubmit, userRole } = this.props;
    const user = parseFetchedData(item);

    return (
      <TableRow
        key={user.id}
        className='mui-override sk-usersTableRow__tableBodyRow'
        onClick={() => this.handleClickOnUser(user)}
      >
        <TableCell
          classes={getClasses()}
          className={`sk-usersTableRow__tableBodyCellLeft ${index ===
            arr.length - 1 && '_last'}`}
          align='left'
        >
          <UserAvatar
            avatar={user.photo}
            classes=''
            firstName={user.firstName}
            lastName={user.lastName}
          />
        </TableCell>
        <TableCell
          classes={getClasses()}
          className={`sk-usersTableRow__tableBodyCellLeft ${index ===
            arr.length - 1 && '_last'}`}
          align='left'
        >
          <Text size='basic'>{user.firstName}</Text>
        </TableCell>
        <TableCell
          classes={getClasses()}
          className={`sk-usersTableRow__tableBodyCellLeft ${index ===
            arr.length - 1 && '_last'}`}
          align='left'
        >
          <Text size='basic'>{user.lastName}</Text>
        </TableCell>
        <TableCell
          classes={getClasses()}
          className={`sk-usersTableRow__tableBodyCellLeft ${index ===
            arr.length - 1 && '_last'}`}
          align='left'
        >
          <Text style={{ maxWidth: 200, wordWrap: 'break-word' }} size='basic'>
            {user.email}
          </Text>
        </TableCell>
        <TableCell
          classes={getClasses()}
          className={`sk-usersTableRow__tableBodyCellLeft ${index ===
            arr.length - 1 && '_last'}`}
          align='left'
        >
          <Text size='basic'>{user?.country?.name || '-'}</Text>
        </TableCell>
        <TableCell
          classes={getClasses()}
          className={`sk-usersTableRow__tableBodyCellLeft ${index ===
            arr.length - 1 && '_last'}`}
          align='left'
        >
          <Text size='basic'>{user?.city?.name || '-'}</Text>
        </TableCell>
        {/* <TableCell
          classes={getClasses()}
          className={`sk-usersTableRow__tableBodyCellLeft ${index ===
            arr.length - 1 && "_last"}`}
          align="left"
        >
          <Text size="basic">{moment(user.createdAt).format("l")}</Text>
        </TableCell> */}
        <TableCell
          classes={getClasses()}
          className={`sk-usersTableRow__tableBodyCellLeft ${index ===
            arr.length - 1 && '_last'}`}
          align='left'
        >
          <Text size='basic'>{user.enabled ? 'Enable' : 'Blocked'}</Text>
        </TableCell>
        {!userRole && (
          <TableCell
            classes={getClasses()}
            className={`sk-usersTableRow__tableBodyCellLeft ${index ===
              arr.length - 1 && '_last'}`}
            align='left'
          >
            <Text size='basic'>{user.roleName}</Text>
          </TableCell>
        )}
        <TableCell classes={getClasses()}>0/0</TableCell>
        <TableCell
          classes={getClasses()}
          className={`sk-usersTableRow__tableBodyCellLeft ${index ===
            arr.length - 1 && '_last'}`}
          align='left'
        >
          <Button
            variant='outlined'
            color={user.enabled ? 'secondary' : 'primary'}
            size='medium'
            onClick={e => handleSubmit(user.id, user.enabled, e)}
          >
            {user.enabled ? 'Block' : 'Restore'}
          </Button>
        </TableCell>
      </TableRow>
    );
  }
}

UserTableRow.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  arr: PropTypes.array,
  history: PropTypes.any,
  handleSubmit: PropTypes.func,
  userRole: PropTypes.any,
};

const enhancer = compose(withRouter);

export default enhancer(UserTableRow);
