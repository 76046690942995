// Core
import React, { PureComponent } from 'react';
import { compose } from 'react-apollo';
import classNames from 'classnames';

// Components
import SidebarToggleButton from 'containers/Sidebar/SidebarToggleButton';
import Text from 'components/Text';

// Other
import { withSharedState } from 'containers/SharedStateProvider/withSharedState';
import withWidth from 'containers/HOCs/withWidth';

// Styles and Assets
import './ContentHeader.scss';

const ContentHeader = ({
  actionBlock = null,
  way,
  colorWay,
  classes,
  width,
  sharedStateContext: { sharedState, },
  ...props
}) => {
  const isMedium = width === 'md';
  const complexContent = sharedState?.sidebar?.complexContent;
  const isSidebarOpened = sharedState?.sidebar?.openedSidebar === 'main';

  return (
    <div
      className={classNames(
        'sk-content-header', {
          'sk-content-header_withAction': actionBlock,
          [classes]: classes
      })}
    >
      <div className={classNames("sk-content-header__sidebar-toggle", {
        "sk-content-header__complex-content": isMedium && complexContent && isSidebarOpened
      })}>
        <SidebarToggleButton />
        <Text
          size="small"
          classes={classNames('sk-content-header__breadcrumbs', {
            [`${colorWay}`]: colorWay,
          })}
        >
          {way}
        </Text>
      </div>

      {actionBlock || null}
    </div>
  );
};

const enhancer = compose(
  withSharedState,
  withWidth()
)

export default enhancer(ContentHeader);
