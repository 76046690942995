// Core
import React from 'react';

// Components
import Star from 'components/Icon/Svg/Star';

// Styles
import './StarRating.scss';

const getArrayOfZeroes = length =>
  Array.from(Array(length), (v, i) => ({ value: 0, id: i }));

const StarRating = ({ rate=0 }) => (
  <div className="sk-star-rating">
    {getArrayOfZeroes(5).map((a, i) => (
      <span key={a.id} className="sk-star-rating_item">
        <Star active={Math.floor(rate) <= i} />
      </span>
    ))}
  </div>
);

export default StarRating;
