import React, { useState, useEffect } from 'react';

// Other
import { COURSE_PREVIEW, LECTURE_QUIZ, LECTURE_VIDEO, } from 'containers/UploadManagerProvider/helpers';


const getPreview = ({ topping }) => topping?.coursePreview;
const getLectureMaterial = ({ topping, type }) => {
  const hasLectureMaterial = topping?.lectures[0]?.materials?.some(material => material.type === type);
  const lectureMaterial = topping?.lectures[0]?.materials?.find(material => material.type === type);
  return hasLectureMaterial ? lectureMaterial : null;
};

const GET_MATERIALS_METHODS = {
  [COURSE_PREVIEW]: getPreview,
  [LECTURE_VIDEO]: getLectureMaterial,
  [LECTURE_QUIZ]: getLectureMaterial,
};

export default function useToppingMaterial({ type, topping, }) {
  const getMaterial = GET_MATERIALS_METHODS[type];
  const material = getMaterial({ topping, type });

  return material;
}
