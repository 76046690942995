import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  subscriptionBase: {
    id: 'app.components.Button.subscriptionBase',
    defaultMessage: 'Active payment plan:',
  },
  expertSubscriptionsButton: {
    id: 'app.components.Button.expertSubscriptionsButton',
    defaultMessage: 'Payment plans',
  },
});
