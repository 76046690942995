import React from 'react';
import PropTypes from 'prop-types';

import { MaterialGrey } from 'components/Icon/color';

const VisibilityOff = ({ height, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 22 18"
  >
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h22v22H0V0zm0 0h22v22H0V0zm0 0h22v22H0V0zm0 0h22v22H0V0z" />
      <path
        fill={MaterialGrey}
        fillRule="nonzero"
        d="M11 3.667a4.585 4.585 0 0 1 4.583 4.583c0 .596-.119
         1.155-.33 1.678l2.677 2.676a10.832 10.832 0 0 0
          3.144-4.354c-1.586-4.024-5.5-6.875-10.083-6.875-1.284 0-2.512.23-3.648.642l1.98
           1.98A4.449 4.449 0 0 1 11 3.667zM1.833 1.164l2.09 2.09.422.422A10.82 10.82 0 0 0
            .917 8.25c1.585 4.024 5.5 6.875 10.083 6.875 1.42 0 2.777-.275 4.015-.77l.385.385
             2.686 2.677 1.164-1.165L2.997 0 1.833 1.164zm5.07 5.07l1.42 1.42a2.586 2.586 0 0
              0-.073.596A2.746 2.746 0 0 0 11 11c.202 0 .403-.027.596-.073l1.42 1.42a4.544 4.544 
              0 0 1-2.016.486A4.585 4.585 0 0 1 6.417 8.25c0-.724.183-1.402.486-2.017zm3.95-.716l2.888
               2.888.018-.147a2.746 2.746 0 0 0-2.75-2.75l-.156.01z"
      />
    </g>
  </svg>
);

VisibilityOff.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

VisibilityOff.defaultProps = {
  height: '21',
  width: '14',
};

export default VisibilityOff;
