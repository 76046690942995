// Core
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { captureException } from '@sentry/browser';

import { SentryContext } from 'containers/SentryProvider/sentry-context.js';

class SentryProvider extends Component {
  state = {
    hasError: false,
    error: null,
    errorInfo: null,
  };

  throwError = (error, errorInfo) => {
    this.setState(prevState => ({ hasError: true, error, errorInfo }));
    const enviroment = process.env.NODE_ENV;
    const isPoduction = enviroment === 'production';

    if (isPoduction) captureException(error);
  };

  render() {
    const { error, errorInfo, hasError, } = this.state;
    const { children } = this.props;

    return (
      <SentryContext.Provider
        value={{
          sentryContext: {
            hasError,
            error,
            errorInfo,
            throwError: this.throwError,
          },
        }}
      >
        {React.Children.only(children)}
      </SentryContext.Provider>
    );
  }
}

SentryProvider.propTypes = {
  children: PropTypes.element,
};

export default SentryProvider;
