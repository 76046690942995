import React from 'react';

const FB = () => {
  const randomFloatString = `${Math.random()}`;
  return (
    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21 1C9.97205 1 1 9.97205 1 21C1 32.0271 9.97205 41 21 41C32.0271 41 41 32.0271 41 21C41 9.97205 32.0288 1 21 1Z" stroke={`url(#paint0_linear_${randomFloatString})`} />
      <path d="M25.047 21.4953H22.3988C22.3988 25.865 22.3988 31.2436 22.3988 31.2436H18.4747C18.4747 31.2436 18.4747 25.9171 18.4747 21.4953H16.6094V18.05H18.4747V15.8215C18.4747 14.2254 19.209 11.7314 22.4348 11.7314L25.3427 11.743V15.0874C25.3427 15.0874 23.5757 15.0874 23.2321 15.0874C22.8886 15.0874 22.4001 15.2648 22.4001 16.026V18.0506H25.3899L25.047 21.4953Z" stroke="#610FB6"/>
      <defs>
      <linearGradient id={`paint0_linear_${randomFloatString}`} x1="44.6218" y1="43.6924" x2="-5.43161" y2="37.5524" gradientUnits="userSpaceOnUse">
      <stop stopColor="#8F26AF"/>
      <stop offset="0.979167" stopColor="#141685"/>
      </linearGradient>
      </defs>
    </svg>
  )
};

export default FB;
