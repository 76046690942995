import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  addToCart: {
    id: 'app.components.CourseActionBlock.addToCart',
    defaultMessage: 'Add to cart'
  },
  buyNow: {
    id: 'app.components.CourseActionBlock.buyNow',
    defaultMessage: 'Buy now'
  },
  join: {
    id: 'app.components.CourseActionBlock.join',
    defaultMessage: 'Join'
  },
  youHaveAlreadyBought: {
    id: 'app.components.CourseActionBlock.youHaveAlreadyBought',
    defaultMessage: 'You have already bought this course'
  }
});
