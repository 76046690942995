// Core
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { compose } from 'react-apollo';

// Components
import Heading from 'components/Heading';
import LectureTitleTypeSelector from 'routes/Course/components/LecturesCounter/LectureTitleTypeSelector/LectureTitleTypeSelector';
import Duration from 'components/Duration/Duration';

// Styles and Assets
import './LecturesCounter.scss';

// Other
import { injectIntl, FormattedMessage } from 'react-intl';
import { messages } from 'routes/Course/components/LecturesCounter/messages';
import { getLectureDuration, } from 'utils/helpers';

const parseTotalDuration = (lectures, props) => {
  const totalDuration = lectures
    .map(({ duration }) => duration)
    .reduce((prev, cur) => prev + cur, 0);

  const duration = moment.duration(totalDuration, 'seconds');
  const res = (duration.days() > 0 ? `${duration.days()}${props.intl.formatMessage(messages.d)} ` : '') +
  `${duration.hours()}${props.intl.formatMessage(messages.h)}
  ${duration.minutes()}${props.intl.formatMessage(messages.m)}`;

  return res;
};

const renderLectureCounter = (lectures, displayLectureNameType, props) => {
  const lectureTitleMessage = displayLectureNameType === 'section' ? messages.section : messages.lecture;
  return `${lectures.length} ${props.intl.formatMessage(lectureTitleMessage)} / ${parseTotalDuration(lectures, props)}`;
}

const LecturesCounter = ({ lectures, displayLectureNameType, refetchCourse, ...props }) => (
    <div className='sk-course-lectures-counter'>
      <div className='sk-course-lectures-counter__title'>
        <Heading variant="h3">
          <FormattedMessage {...messages.create} />
          <span className='sk-course-lectures-counter__duration__mobile'><Duration duration={getLectureDuration(lectures)} /></span>
        </Heading>
        <LectureTitleTypeSelector
          value={displayLectureNameType}
          refetchCourse={refetchCourse}
        />
      </div>
      <span className='sk-course-lectures-counter__duration'><Duration duration={getLectureDuration(lectures)} /></span>
    </div>
);

LecturesCounter.propTypes = {
  lectures: PropTypes.array,
};

const enhancer = compose(injectIntl);

export default enhancer(LecturesCounter);
