// Components
import {
  VideoPreview,
  PdfPreview,
  TextPreview,
  EmptyPreview,
  EditorPreview,
  QuizPreview,
} from 'components/FilePreview';

// Other
import {
  VIDEO_FILE,
  AUDIO_FILE,
  TEXT_FILE,
  PDF_FILE,
  EDITOR_FILE,
  QUIZ_ATTACHMENT,
  FAILED_ATTACHMENT,
} from 'utils/enums/index';
import { DarkGrey, WarnRed } from 'components/Icon/color';

// Icons
import Play from 'components/Icon/SvgCss/Play.js';
import Document from 'components/Icon/SvgCss/Document';
import Quiz from 'components/Icon/SvgCss/QuizAttachment.js';
import Audio from 'components/Icon/SvgCss/Audio';
import RedCross from 'components/Icon/SvgCss/RedCross';

const defaultDurationColor = { color: DarkGrey };

// Idea for entities controller
const ENTITY_CONTROLLER = {
  [VIDEO_FILE]: {
    preview: {
      icon: Play,
      component: VideoPreview,
    },
    getDurationColor: (duration) => {
      return defaultDurationColor;
    }
  },
  [AUDIO_FILE]: {
    preview: {
      icon: Audio,
      component: VideoPreview,
    },
    getDurationColor: (duration) => {
      return defaultDurationColor;
    }
  },
  [TEXT_FILE]: {
    preview: {
      icon: Document,
      component: TextPreview,
    },
    getDurationColor: (duration) => {
      return defaultDurationColor;
    }
  },
  [EDITOR_FILE]: {
    preview: {
      icon: Document,
      component: EditorPreview,
    },
    getDurationColor: (duration) => {
      return defaultDurationColor;
    }
  },
  [PDF_FILE]: {
    preview: {
      icon: Document,
      component: PdfPreview,
    },
    getDurationColor: (duration) => {
      return defaultDurationColor;
    }
  },
  [QUIZ_ATTACHMENT]: {
    preview: {
      icon: Quiz,
      component: QuizPreview,
    },
    getDurationColor: (duration) => {
      if (duration == 0) {
        return { color: WarnRed }
      } else {
        return defaultDurationColor;
      }
    }
  },
  [FAILED_ATTACHMENT]: {
    preview: {
      icon: RedCross,
      component: EmptyPreview,
    },
    getDurationColor: (duration) => {
      return defaultDurationColor;
    }
  },
  [null]: {
    preview: {
      icon: Play,
      component: EmptyPreview,
    },
    getDurationColor: (duration) => {
      return defaultDurationColor;
    }
  },
};

export default ENTITY_CONTROLLER;
