// Core
import React from 'react';
import { compose } from 'react-apollo';
import { injectIntl } from 'react-intl';

// Components
import Button from 'components/Button';
import Text from 'components/Text';
import AddToCartIcon from 'components/CourseCard/AddToCartIcon';

//Others
import { withConvertedPrice } from 'containers/CurrencyProvider/withConvertedPrice';
import {messages} from 'components/NetflixCarousel/messages.js';

// Styles
import './ActionBlock.scss';

const COMPONENT_PREFIX = 'sk-toppings-carousel_content__action-block';

const ActionBlock = ({
  toppingId,
  price,
  handleBuyNow,
  handleAddToCart,
  purchasedId,
  intl: { formatMessage },
  isFree,
  isInShoppingCart
}) => {
  const isPurchased = purchasedId ? true : false;
  return(
    <div className={COMPONENT_PREFIX}>
      <div className={`${COMPONENT_PREFIX}__price`}>
        <Text className={`${COMPONENT_PREFIX}__price_label`}>
          <span>
            <sup>{price[0]}</sup>
            {price.slice(2)}
          </span>
        </Text>
      </div>
      <div className={`${COMPONENT_PREFIX}_col`}>
        <div className={`${COMPONENT_PREFIX}_add_to_cart`} onClick={!isPurchased && handleAddToCart}>
          <AddToCartIcon disabled={isInShoppingCart || isPurchased}/>
        </div>
        {!isPurchased && (
          <Button variant="text" color="secondary" onClick={handleBuyNow}>
            {!isFree ? formatMessage(messages.buyNow) : formatMessage(messages.joinButton)}
          </Button>
        )}
      </div>
    </div>
  )
};

const enhancer = compose(
  withConvertedPrice,
  injectIntl,
);
export default enhancer(ActionBlock);
