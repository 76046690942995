import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  quiz: {
    id: 'app.routes.ExpertsCabinet.QuizEdit.quiz',
    defaultMessage: 'Quiz',
  },
  quizInfoTitle: {
    id: 'app.routes.ExpertsCabinet.QuizEdit.quizInfoTitle',
    defaultMessage: 'Title',
  },
  quizTitlePlaceholder: {
    id: 'app.routes.ExpertsCabinet.QuizEdit.quizTitlePlaceholder',
    defaultMessage: 'Type quiz title',
  },
  quizInfoDuration: {
    id: 'app.routes.ExpertsCabinet.QuizEdit.quizInfoDuration',
    defaultMessage: 'Estimated time min',
  },
  addQuestion: {
    id: 'app.routes.ExpertsCabinet.QuizEdit.addQuestion',
    defaultMessage: 'Add question',
  },
  questionTitlePlaceholder: {
    id: 'app.routes.ExpertsCabinet.QuizEdit.questionTitlePlaceholder',
    defaultMessage: 'Type question title',
  },
  confirmModalcancelButton: {
    id: 'app.routes.ExpertsCabinet.QuizEdit.confirmModalCancelButton',
    defaultMessage: 'Back to the question',
  },
  addAnswer: {
    id: 'app.routes.ExpertsCabinet.QuizEdit.addAnswer',
    defaultMessage: 'Add answer',
  },
  answerTitlePlaceholder: {
    id: 'app.routes.ExpertsCabinet.QuizEdit.answerTitlePlaceholder',
    defaultMessage: 'Type your answer',
  },
  explanationToAnswer: {
    id: 'app.routes.ExpertsCabinet.QuizEdit.explanationToAnswer',
    defaultMessage: 'Explanation of correct answer',
  },
  oneRightAnswer: {
    id: 'app.routes.ExpertsCabinet.QuizEdit.QuestionType.oneRightAnswer',
    defaultMessage: 'Single correct answer',
  },
  severalRightAnswer: {
    id: 'app.routes.ExpertsCabinet.QuizEdit.QuestionType.severalRightAnswer',
    defaultMessage: 'Multiple correct answer',
  },
  quizInfoUntitledTitle: {
    id: 'app.routes.ExpertsCabinet.QuizEdit.quizInfoUntitledTitle',
    defaultMessage: 'Title',
  },
  quizQuestionUntitledTitle: {
    id: 'app.routes.ExpertsCabinet.QuizEdit.quizQuestionUntitledTitle',
    defaultMessage: 'Question',
  },
  quizAnswerUntitledTitle: {
    id: 'app.routes.ExpertsCabinet.QuizEdit.quizAnswerUntitledTitle',
    defaultMessage: 'Answer lol',
  },
  selectAllThatApply: {
    id: 'app.routes.ExpertsCabinet.QuizEdit.selectAllThatApply',
    defaultMessage: 'Select all that apply',
  },

  saveAndClose: {
    id: 'app.routes.ExpertsCabinet.QuizEdit.saveAndClose',
    defaultMessage: 'Save & Close',
  },
});
