// Core
import React from 'react';

export const hasBranch = (test, ComponentOnPass, ComponentOnFail) => props => {
  if (test) {
    return <ComponentOnPass {...props} />;
  }

  return ComponentOnFail ? <ComponentOnFail {...props} /> : null;
};

// usage
// branch(testFunction, Component1, Component2)
