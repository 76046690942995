// Core
import React from 'react';
import Divider from '@material-ui/core/Divider';
import { Scrollbars } from 'react-custom-scrollbars';

// Components
import WebinarRoomChatInput from 'containers/WebinarRoom/WebinarRoomChat/WebinarRoomChatInput';
import WebinarRoomChatHeader from 'containers/WebinarRoom/WebinarRoomChat/WebinarRoomChatHeader';
import WebinarRoomChatList from 'containers/WebinarRoom/WebinarRoomChat/WebinarRoomChatList';

// Styles and Assets
import './WebinarRoomChat.scss';

const WebinarRoomChat = ({
  handleMessageSend,
  handleInputChange,
  handleKeyPress,
  message,
  messages,
  isConnected,
  webinarStarted,
  numberOfConnections,
}) => (
  <div className="sk-web-room-chat">
    <header className="sk-web-room-chat__header">
      <WebinarRoomChatHeader numberOfConnections={numberOfConnections} />
    </header>

    <Divider />

    <div className="sk-web-room-chat__list">
      {messages?.length ? (
        <Scrollbars className="sk-web-room-chat__scrollbar" style={{ height: '100%', width: '100%' }}>
          <WebinarRoomChatList signals={messages} />
        </Scrollbars>
      ) : null}
    </div>

    <Divider />

    <div className="sk-web-room-chat__input">
      <WebinarRoomChatInput
        handleMessageSend={handleMessageSend}
        handleKeyPress={handleKeyPress}
        disabled={!isConnected}
        handleInputChange={handleInputChange}
        message={message}
      />
    </div>
  </div>
);

export default WebinarRoomChat;
