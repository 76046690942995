import React from 'react';
import PropTypes from 'prop-types';
import { White, Red, Grey } from 'components/Icon/color';

const getColor = (disabled, color) => (disabled ? Grey : color);

const Camera = ({ height, width, active, activeColor, color, disabled }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 21 18"
    width={width}
    height={height}
  >
    <path
      fill="none"
      fillRule="nonzero"
      stroke={active ? activeColor : getColor(disabled, color)}
      d="M3.375 3.286h2.27l.738-1.524C6.553 1.382 7.234 1 7.83 1h5.237c.594 0 1.275.382 1.445.762l.739 
      1.524h2.375C19.516 3.286 20 4.81 20 4.81v10.666S18.812 17 17.625 17H3.375C2.113 17 1 15.476 1 
      15.476V4.81s.399-1.524 2.375-1.524zM10.5 14.715c2.623 0 4.75-2.047 4.75-4.572 
      0-2.524-2.127-4.572-4.75-4.572-2.624 0-4.75 2.048-4.75 4.572 0 2.525 2.126 4.572 4.75 4.572z"
    />
  </svg>
);

Camera.propTypes = {
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  height: PropTypes.string,
  width: PropTypes.string,
  activeColor: PropTypes.string,
  color: PropTypes.string,
};

Camera.defaultProps = {
  active: false,
  height: '18',
  width: '21',
  activeColor: White,
  color: Red,
};

export default Camera;
