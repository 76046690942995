// Core
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';

// Components
import DraftsCoursesList from 'containers/Sidebar/SidebarContent/CourseSidebar/DraftsCoursesList';
import ActiveCoursesList from 'containers/Sidebar/SidebarContent/CourseSidebar/ActiveCoursesList';
import ToppingsList from 'containers/Sidebar/SidebarContent/CourseSidebar/ToppingsList';
import { withSidebar } from 'containers/SidebarProvider/withSidebar';
import CourseCard from 'routes/StudentsCabinet/StudentCourses/CourseCard';
import StatusFilter from 'routes/components/Filters/StatusFilter';
import TextInput from 'components/Input/Material/TextInput/TextInput';
import SearchLoop from 'components/Icon/Svg/SearchLoop';
import { COURSES_STATUSES_OPTIONS } from 'routes/StudentsCabinet/StudentCourses/enums.js';
// GrahpQL
import LIST_COURSES from 'queries/Courses/studentListCourses.gql';
// import LIST_COURSES from 'queries/Courses/listExpertCourses.gql';

// Other


import { hasLoader } from 'containers/HOCs/hasLoader';
import { isBlank, keysToCamel, isArray, isEmptyArray, isArraysEqual, parseFetchedData } from 'utils/helpers';
import ContentHeader from 'containers/ContentHeader';
import { sharedMessages } from 'services/i18n/sharedMessages/messages';
import { Grey } from 'components/Icon/color';

// Styles and Assets
import './index.scss';

const prefix = 'sk-students-cabinet-courses';

export const StudentCourses = ({
  match,
  courses,
  query,
  sidebarContext,
  refetchCourses,
  purchasedCourses,
  intl: { formatMessage },
}) => {
  const searchCourses = ((query) => async ({ key, values }) => {
    const { filter } = query;

    const newFilters = !isBlank(filter) ? [...filter] : [];
    const oldFilter = newFilters.find(filter => filter.key === key);

    const isNewFilter = !(!!oldFilter);
    const isFilterChanged = oldFilter && values && !isArraysEqual(oldFilter.values, values);

    if (isNewFilter) {
      const newFilter = { key, values };
      newFilters.push(newFilter);
    } else if (isFilterChanged) {
      oldFilter.values = values;
    };

    await refetchCourses({ input: { query: { filter: newFilters } }});
  })(query);

  const getMappingId = (id) => {
    if (purchasedCourses) {
      const {mappingId} = purchasedCourses.find(({courseId}) => id === courseId)
      return mappingId
    }
    return null;
  }

  const handleSearchEnter = ((query) => async (searchValue, evt) => {
    const isUserEnterClicked = evt.key === 'Enter';
    const { filter } = query;

    if (isUserEnterClicked) {
      let newFilters = !isBlank(filter) ? [...filter] : [];
      const oldFilter = newFilters.find(filter=>filter.key === 'title');

      const isNewFilter = !(!!oldFilter);
      const isFilterChanged = oldFilter && searchValue && !isArraysEqual(oldFilter.values, searchValue);

      if (isNewFilter) {
        newFilters.push({key: "title", values: searchValue});
      } else if (isFilterChanged) {
        oldFilter.values = searchValue;
      } else if(!searchValue){
        newFilters = [{key: 'type', values: ['course'] }];
      };

      await refetchCourses({ input: { query: { filter: newFilters } }});
    }
  })(query);

  return (
    <div className={prefix}>
      <div className={`${prefix}__heading`}>
        <ContentHeader  way={formatMessage(sharedMessages.coursesLabel)} />
        <StatusFilter filter={query?.filter || []} searchToppings={searchCourses} options={COURSES_STATUSES_OPTIONS} />
      </div>
      {isArray(courses) && !isEmptyArray(courses)  && courses.map(course=>(
        <CourseCard
          course={course}
          purchasedCourses={purchasedCourses}
          mappingId={getMappingId(course.id)}
          coursePageLink={`/student-cabinet/courses/${getMappingId(course.id)}/`}
        />
      ))}
    </div>
  );
};

const listExpertCoursesQuery = graphql(LIST_COURSES, {
  props: ({ data: { listStudentCoursesExtra, variables, refetch, loading, error, ...ownProps } }) => ({
    courses: keysToCamel(listStudentCoursesExtra?.courses),
    purchasedCourses: keysToCamel(listStudentCoursesExtra?.purchased_courses),
    query: variables?.input?.query || {},
    refetchCourses: refetch,
    ...ownProps,
  }),
  options: () => ({
    variables: { input: { query: { filter: [{key: 'type', values: ['course'] }]}}},
    fetchPolicy: 'network-only',
  }),
});

const enhancer = compose(
  injectIntl,
  withSidebar,
  withRouter,
  listExpertCoursesQuery
);

export default enhancer(StudentCourses);
