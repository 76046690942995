// Core
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// Components
import Text from 'components/Text';
import TextInput from 'components/Input/Material/TextInput/TextInput';
import TextAreaInput from 'components/Input/Material/TextAreaInput/TextAreaInput';
import SimpleSelect from 'components/Select/SimpleSelect/SimpleSelect';
import TimePicker from 'components/TimePicker';
import DatePicker from 'components/DatePicker';
import ReferenceSelect from 'routes/AdminPanel/Discounts/DiscountPage/DiscountDetails/ReferenceSelect';
import DiscountTypeSelect from 'routes/AdminPanel/Discounts/DiscountPage/DiscountDetails/DiscountTypeSelect';
import DurationSelect from 'routes/AdminPanel/Discounts/DiscountPage/DiscountDetails/DurationSelect';

// Other

// Styles and Assets
import './DiscountDetails.scss';

const DiscountDetails = (props) => {
  const { discount, errors, touched, handleInputBlur, handleInputChange, handleDateChange, } = props;

  return (
    <div className='sk-admin-discount-details'>
      <ReferenceSelect
        touched={touched}
        errors={errors}
        reference={discount.reference}
        referenceId={discount.referenceId}
        handleInputChange={handleInputChange}
        handleInputBlur={handleInputBlur}
      />
      <DiscountTypeSelect
        touched={touched}
        errors={errors}
        discountType={discount.discountType}
        discountValue={discount.discountValue}
        handleInputChange={handleInputChange}
        handleInputBlur={handleInputBlur}
      />
      <DurationSelect
        touched={touched}
        errors={errors}
        startTime={discount.startTime}
        endTime={discount.endTime}
        handleInputChange={handleDateChange}
        // handleInputBlur={handleInputBlur}
      />
      <div className='sk-admin-discount-details__redumption'>
        <div className='sk-admin-discount-details__redumption__input'>
        <TextInput
          helperText={touched.redemptions ? errors.redemptions : ''}
          error={touched.redemptions && Boolean(errors.redemptions)}
          // placeholder="Limit"
          value={discount.redemptions}
          name="redemptions"
          label="Limit for usage"
          onBlur={handleInputBlur('redemptions')}
          onChange={handleInputChange('redemptions')}
        />
        </div>
      </div>
    </div>
  );
}

DiscountDetails.propTypes = {
  handleInputChange: PropTypes.func,
  handleInputBlur: PropTypes.func,
  discount: PropTypes.object,
};

export default DiscountDetails;
