import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  manageAccount: {
    id: 'app.components.FlatDropDownMenu.manageAccount',
    defaultMessage: 'Manage Account',
  },
  myCourses: {
    id: 'app.components.FlatDropDownMenu.myCourses',
    defaultMessage: 'Student`s Cabinet',
  },
  createdCourses: {
    id: 'app.components.FlatDropDownMenu.createdCourses',
    defaultMessage: 'Expert`s Cabinet',
  },
  dashboard: {
    id: 'app.components.FlatDropDownMenu.dashboard',
    defaultMessage: 'Dashboard',
  },
  swap: {
    id: 'app.components.FlatDropDownMenu.swap',
    defaultMessage: 'Swap account',
  },
  expertsCalendar: {
    id: 'app.components.FlatDropDownMenu.expertsCalendar',
    defaultMessage: "Web Сlasses",
  },
  createNewCourse: {
    id: 'app.components.FlatDropDownMenu.createNewCourse',
    defaultMessage: 'Create a New Course',
  },
  createNewTopping: {
    id: 'app.components.FlatDropDownMenu.createNewTopping',
    defaultMessage: 'Create Master Class/Podcast',
  },
  signOut: {
    id: 'app.components.FlatDropDownMenu.signOut',
    defaultMessage: 'Sign Out',
  },
  becomeAnExpert: {
    id: 'app.components.FlatDropDownMenu.becomeAnExpert',
    defaultMessage: 'Become an expert',
  },
  expertTariffs: {
    id: 'app.components.FlatDropDownMenu.expertTariffs',
    defaultMessage: 'Tariffs',
  },
});
