import React from 'react';

export const NetflixLeft = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.80305 22.8812L21.8007 2.35938C21.9917 2.14152 22.2687 2.01637 22.5597 2.01635L24.2259 2.01625C25.01 2.01619 25.4906 2.86678 25.0814 3.53017L13.063 23.0119C12.8702 23.3244 12.8659 23.7163 13.0517 24.0323L25.135 44.5853C25.5251 45.249 25.041 46.0829 24.2655 46.0829L22.5733 46.0829C22.2744 46.0829 21.9912 45.951 21.8007 45.723L3.78687 24.1693C3.47346 23.7943 3.48031 23.2492 3.80305 22.8812Z"
        fill="url(#paint0_linear)"
        stroke="url(#paint1_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="23.85"
          y1="51.8642"
          x2="48.2506"
          y2="13.6443"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8F26AF" />
          <stop offset="0.979167" stopColor="#141685" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="23.85"
          y1="51.8642"
          x2="48.2506"
          y2="13.6443"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8F26AF" />
          <stop offset="0.979167" stopColor="#141685" />
        </linearGradient>
      </defs>
    </svg>
  );
};
