// Core
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// Styles and Assets
import './CoursePreview.scss';

// Components
import Play from 'components/Icon/Svg/Play';
import VideoModule from 'components/VideoModule';

// Other
import { White } from 'components/Icon/color';
import { FormattedMessage } from 'react-intl';
import { messages } from 'routes/CourseDetails/CoursePreview/messages';

const PlayButton = () => (
  <div className="sk-course-preview__icon">
    <Play strokeColor={White} />
  </div>
);

const CoursePreview = ({
  coursePreview,
}) => {
  return (
    <div className="sk-course-preview">
      <h3 className="sk-course-preview__title">
        <FormattedMessage {...messages.coursePreview} />
      </h3>
      <div className="sk-course-preview__video">
        <VideoModule
          isPublic={true}
          url={coursePreview.url}
          playButton={PlayButton}
          filetype={coursePreview.filetype}
        />
      </div>
    </div>
  );
}

CoursePreview.propTypes = {
  coursePreview: PropTypes.object,
};

export default CoursePreview;
