import React from 'react';
import PropTypes from 'prop-types';

import './GarbageBucket.scss';

const GarbageBucket = ({color = '#7145D8'}) => (
  <svg class='garbage-svg' width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class='filled' fillRule="evenodd" clipRule="evenodd" d="M12.5697 2.57143H16.3818C16.6909 2.57143 17 2.76923 17 3.06593C17 3.36263 16.7939 3.56044 16.4848 3.56044H14.7333V14.8352C14.7333 16.6154 13.2909 18 11.6424 18H5.35758C3.60606 18 2.26667 16.6154 2.26667 14.8352V3.56044H0.515152C0.20606 3.56044 0 3.36263 0 3.06593C0 2.76923 0.20606 2.57143 0.515152 2.57143H4.22424C4.4303 1.18681 5.66667 0 7.21212 0H9.58182C11.1273 0 12.3636 1.08791 12.5697 2.57143ZM9.58182 0.989011H7.21212C6.28485 0.989011 5.46061 1.68132 5.35758 2.57143H11.5394C11.3333 1.68132 10.5091 0.989011 9.58182 0.989011ZM7.93333 6.32967C7.93333 6.03297 8.13939 5.83516 8.44848 5.83516C8.65455 5.83516 8.96364 6.03297 8.96364 6.32967V14.5385C8.96364 14.8352 8.75757 15.033 8.44848 15.033C8.13939 15.033 7.93333 14.8352 7.93333 14.5385V6.32967ZM5.7697 7.12088C5.46061 7.12088 5.25454 7.31868 5.25454 7.61538V13.2527C5.25454 13.5494 5.46061 13.7472 5.7697 13.7472C6.07879 13.7472 6.28485 13.5494 6.28485 13.2527V7.61538C6.28485 7.31868 6.07879 7.12088 5.7697 7.12088ZM10.6121 7.61538C10.6121 7.31868 10.8182 7.12088 11.1273 7.12088C11.4364 7.12088 11.6424 7.31868 11.6424 7.61538V13.2527C11.6424 13.5494 11.4364 13.7472 11.1273 13.7472C10.8182 13.7472 10.6121 13.5494 10.6121 13.2527V7.61538Z" fill={color} />

    <path class='outlined' fillRule="evenodd" clipRule="evenodd" d="M5.34052 2.64195H11.658C11.4994 1.70458 10.6748 1.00077 9.71653 1.00077H7.28162C6.32342 1.00077 5.49908 1.70451 5.34052 2.64195ZM12.7053 2.64195H16.4808C16.7676 2.64195 17 2.86598 17 3.14233C17 3.41869 16.7676 3.64272 16.4808 3.64272H14.7474V14.8018C14.7474 16.5211 13.4013 18 11.6638 18H5.33655C3.59876 18 2.2526 16.5211 2.2526 14.8018V3.64272H0.51916C0.232436 3.64272 0 3.41869 0 3.14233C0 2.86598 0.232436 2.64195 0.51916 2.64195H4.29319C4.45932 1.17113 5.72618 0 7.28162 0H9.71653C11.2719 0 12.5392 1.17105 12.7053 2.64195ZM13.7382 3.64272H3.26185V14.8018C3.26185 16.0653 4.23697 17.0272 5.33655 17.0272H11.6638C12.763 17.0272 13.7382 16.0653 13.7382 14.8018V3.64272ZM8.46738 5.69191C8.75411 5.69191 8.98654 5.91594 8.98654 6.1923V14.4945C8.98654 14.7708 8.75411 14.9949 8.46738 14.9949C8.18066 14.9949 7.94822 14.7708 7.94822 14.4945V6.1923C7.94822 5.91594 8.18066 5.69191 8.46738 5.69191ZM6.32718 7.51253C6.32718 7.23617 6.09474 7.01214 5.80802 7.01214C5.52129 7.01214 5.28886 7.23617 5.28886 7.51253V13.1731C5.28886 13.4494 5.52129 13.6735 5.80802 13.6735C6.09474 13.6735 6.32718 13.4494 6.32718 13.1731V7.51253ZM11.1925 7.01214C11.4792 7.01214 11.7117 7.23617 11.7117 7.51253V13.1731C11.7117 13.4494 11.4792 13.6735 11.1925 13.6735C10.9058 13.6735 10.6734 13.4494 10.6734 13.1731V7.51253C10.6734 7.23617 10.9058 7.01214 11.1925 7.01214Z" fill={color} />
  </svg>
);

export default GarbageBucket;
