import React from 'react';

import 'components/Icon/SvgCss/index.scss';

const IconOnHover = ({ linearId }) => (
  <svg class='filled' width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.5119 1C10.2017 1 1 10.2017 1 21.5119C1 32.8213 10.2017 42.0238 21.5119 42.0238C32.8213 42.0238 42.0238 32.8213 42.0238 21.5119C42.0238 10.2017 32.8229 1 21.5119 1Z" fill={`url(#paint0_linear_${linearId})`} stroke={`url(#paint1_linear_${linearId})`} />
    <path d="M32 22.1616V29.5558H27.7134V22.6568C27.7134 20.9238 27.0933 19.741 25.5417 19.741C24.3572 19.741 23.6524 20.5381 23.3423 21.3092C23.2292 21.5848 23.2001 21.9685 23.2001 22.3543V29.5558H18.912C18.912 29.5558 18.9697 17.8713 18.912 16.6607H23.1997V18.4885C23.191 18.5022 23.1797 18.5169 23.1715 18.5301H23.1997V18.4885C23.7693 17.6111 24.7867 16.3577 27.0638 16.3577C29.885 16.3577 32 18.2007 32 22.1616ZM14.4265 10.4446C12.9595 10.4446 12 11.4068 12 12.6721C12 13.9099 12.9317 14.9013 14.3695 14.9013H14.3982C15.8935 14.9013 16.8235 13.9101 16.8235 12.6721C16.7953 11.4068 15.8935 10.4446 14.4265 10.4446ZM12.2547 29.5558H16.5411V16.6607H12.2547V29.5558Z" fill="white"/>
    <defs>
    <linearGradient id={`paint0_linear_${linearId}`} x1="45.7383" y1="44.7851" x2="-5.59622" y2="38.488" gradientUnits="userSpaceOnUse">
    <stop stopColor="#8F26AF"/>
    <stop offset="0.979167" stopColor="#141685"/>
    </linearGradient>
    <linearGradient id={`paint1_linear_${linearId}`} x1="45.7383" y1="44.7851" x2="-5.59622" y2="38.488" gradientUnits="userSpaceOnUse">
    <stop stopColor="#8F26AF"/>
    <stop offset="0.979167" stopColor="#141685"/>
    </linearGradient>
    </defs>
  </svg>
);

const IconDefault = ({ linearId }) => (
  <svg class='outlined' width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.5119 1C10.2017 1 1 10.2017 1 21.5119C1 32.8213 10.2017 42.0238 21.5119 42.0238C32.8213 42.0238 42.0238 32.8213 42.0238 21.5119C42.0238 10.2017 32.8229 1 21.5119 1Z" stroke="#610FB6"/>
    <path d="M23.1997 18.4882V16.6604H18.912C18.9697 17.871 18.912 29.5555 18.912 29.5555H23.2001V22.3541C23.2001 21.9682 23.2292 21.5846 23.3423 21.3089C23.6524 20.5379 24.3572 19.7408 25.5417 19.7408C27.0933 19.7408 27.7134 20.9235 27.7134 22.6566V29.5555H32V22.1614C32 18.2005 29.885 16.3574 27.0638 16.3575C24.7867 16.3575 23.7693 17.6109 23.1997 18.4882ZM23.1997 18.4882V18.4414M14.4265 10.4443C12.9595 10.4443 12 11.4065 12 12.6719C12 13.9096 12.9317 14.901 14.3695 14.901H14.3982C15.8935 14.901 16.8235 13.9098 16.8235 12.6719C16.7953 11.4065 15.8935 10.4443 14.4265 10.4443ZM12.2547 29.5555H16.5411V16.6604H12.2547V29.5555Z" stroke="#610FB6"/>
  </svg>
);

const Icon = () => {
  const randomFloatString = `${Math.random()}`
  return (
    <div className='icon-with-hover'>
      <IconDefault linearId={randomFloatString} />
      <IconOnHover linearId={randomFloatString} />
    </div>
  );
};

export default Icon;
