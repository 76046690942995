import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  publicMaterial: {
    id: 'app.components.LectureMaterials.StudyMaterialsList.MaterialExtras.publicMaterial',
    defaultMessage:
      'Open material',
  },
  publicMaterialToolTip: {
    id: 'app.components.LectureMaterials.StudyMaterialsList.MaterialExtras.ToolTip',
    defaultMessage:
      'You can make some of your material free for potential students. It will work well as advertising for your course',
  },
});
