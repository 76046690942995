import React from 'react';

const ClockIcon = ({ width=18, height=18 }) => {
  const index = Math.random();
  return(
    <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width={width} height={height} fill="#F2F2F2"/>
      <rect x="-4316" y="-1234" width="11024" height="3778" stroke="black" stroke-width="4"/>
      <g clip-path={`url(#clip0${index})`}>
        <rect x="-279" y="-503" width="1366" height="1325" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 9C0 4.02944 4.02944 0 9 0C13.9679 0.00637066 17.9936 4.03209 18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9ZM8.61349 17.2085H9.38569C13.6208 17.0083 17.0097 13.6211 17.2119 9.3861L17.2081 8.6139C17.006 4.38026 13.6193 0.993627 9.38569 0.791504H8.61349C4.37985 0.993627 0.993223 4.38026 0.791099 8.6139V9.3861C0.993223 13.6197 4.37985 17.0064 8.61349 17.2085Z" fill="#A9B6C8" stroke-width="1" />
        <path d="M9 4.5C9 4.22386 8.77614 4 8.5 4C8.22386 4 8 4.22386 8 4.5H9ZM8.5 8.5H8C8 8.63261 8.05268 8.75979 8.14645 8.85355L8.5 8.5ZM12.1464 12.8536C12.3417 13.0488 12.6583 13.0488 12.8536 12.8536C13.0488 12.6583 13.0488 12.3417 12.8536 12.1464L12.1464 12.8536ZM8 4.5V8.5H9V4.5H8ZM8.14645 8.85355L12.1464 12.8536L12.8536 12.1464L8.85355 8.14645L8.14645 8.85355Z" fill="#A9B6C8"/>
      </g>
      <defs>
        <clipPath id={`clip0${index}`}>
          <rect x="-279" y="-503" width="1366" height="1325" fill="white"/>
        </clipPath>
      </defs>
    </svg>

  )
};

export default ClockIcon;
