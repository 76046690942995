// Core
import React, { memo } from "react";
import { compose, graphql } from "react-apollo";
import classNames from "classnames";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router";

// Components
import Text from "components/Text";
import Button from "components/Button";
import Loader from "components/Loader";
// import OrderDetails from 'routes/Checkout/OrderDetails';
import Payment from "routes/Checkout/Payment";
import CoursesList from "routes/Checkout/CheckoutLists/Courses";
import DiscountForm from "routes/Checkout/components/DiscountForm";
import EmptyList from "routes/Checkout/components/EmptyList";
import OrderTotal from "routes/Checkout/components/OrderTotal";
import CheckoutLayout from "routes/Checkout/Lauout";

// Other
import { useDiscount } from "routes/Checkout/components/useDiscount.js";
import { useTransaction } from "routes/Checkout/components/useTransaction.js";
import { withCurrency } from "containers/CurrencyProvider/withCurrency";
import { withModal } from "containers/ModalProvider/withModal";
import { messages } from "routes/Checkout/messages";
import { withShoppingCart } from "containers/ShoppingCartProvider/withShoppingCart";

// Styles and Assets
import "./index.scss";

const isDraftOrderDefined = (draftOrder) =>
  draftOrder?.id && draftOrder.id !== null;
const hasOrderCourses = (draftOrder) =>
  draftOrder?.id && draftOrder.id !== null && draftOrder.courses.length > 0;

const PREFIX = "sk-checkout";
const Checkout = ({
  shoppingCartContext: {
    draftOrder,
    isOrderLoading,
    clearShoppingCartInStateAndLocalStorage,
  },
  currencyContext: {
    currency,
    currencyProperties,
    getCurrentCurrencySign,
    changeDependOnCurrentCurrencyRate,
  },
  intl: { formatMessage },
  modalContext,
  history,
}) => {
  if (
    !draftOrder?.isLoaded ||
    !isDraftOrderDefined(draftOrder) ||
    isOrderLoading
  )
    return <Loader center />;

  const { totalPrice, id: draftOrderId } = draftOrder;
  const {
    formikBag,
    applyDiscount,
    orderItems: courses,
    discountPayload,
    totalPriceWithDiscount,
  } = useDiscount({
    orderItems: draftOrder?.courses,
    totalPrice,
    draftOrderId,
  });

  const { loading, handleSuccesfulPayment } = useTransaction({
    currency,
    orderItems: courses,
    discountPayload,
    discountCode: formikBag?.values?.code,
    draftOrderId,
    modalContext,
    history,
    clearShoppingCartInStateAndLocalStorage,
  });

  const isFree = totalPriceWithDiscount == 0;

  const totalPriceDependOnCurrency = changeDependOnCurrentCurrencyRate(
    totalPrice
  );
  const totalPriceWithDiscountDependOnCurrency = changeDependOnCurrentCurrencyRate(
    totalPriceWithDiscount
  );

  const hasCourses = hasOrderCourses(draftOrder);

  return (
    <CheckoutLayout breadcrumbs="breadcrumbs">
      <div className={`${PREFIX}`}>
        {hasCourses ? (
          <CoursesList items={courses} withDiscount={true} />
        ) : (
          <EmptyList />
        )}
        <div className={`${PREFIX}__total`}>
          <DiscountForm
            disabled={!hasCourses}
            formikBag={formikBag}
            applyDiscount={applyDiscount}
          />
          <OrderTotal
            withDiscount={true}
            totalPrice={totalPriceDependOnCurrency}
            totalPriceWithDiscount={totalPriceWithDiscountDependOnCurrency}
            currencySign={getCurrentCurrencySign()}
          />
        </div>
      </div>
      {isFree && hasCourses ? (
        <Button
          loading={loading}
          color="secondary"
          variant="text"
          onClick={handleSuccesfulPayment("none")}
        >
          {formatMessage(messages.getCourses)}
        </Button>
      ) : (
        <Payment
          disabled={!hasCourses}
          loading={loading}
          currency={currency}
          currencyLabel={currencyProperties?.label}
          amount={totalPriceWithDiscountDependOnCurrency}
          handleSuccesfulPayment={handleSuccesfulPayment}
        />
      )}
    </CheckoutLayout>
  );
};

const enhancer = compose(
  memo,
  injectIntl,
  withShoppingCart,
  withModal,
  withRouter,
  withCurrency
);

export default enhancer(Checkout);
