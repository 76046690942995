// Core
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import classNames from 'classnames';

// Styles
import 'utils/styles/Materialized.scss';

const classes = {
  label: {
    focused:
      'mui-override sk-materialized-input_label sk-materialized-input_focused',
    error: 'mui-override sk-materialized-input_error labelWithError',
  },
  input: {
    root: 'mui-override sk-materialized-input',
    focused: 'mui-override sk-materialized-input_focused',
    error: 'mui-override sk-materialized-input_error withError',
    underline: 'mui-override sk-materialized-input_hover',
  },
  helperText: {
    error: 'mui-override sk-materialized-input_helperErrorText',
  },
};

const handleInputEvents = (type, handleChange, customType) => e => {
  const inputValue = (type === 'number' && e.target.value !== '')
    ? Number(e.target.value) : e.target.value;

  if (customType === 'picker') return handleChange(e);

  return handleChange ? handleChange(inputValue, e) : null;
};

export const MaterializedTextField = ({
  type,
  value,
  placeholder,
  onChange,
  error,
  disabled,
  required,
  label,
  onBlur,
  inputProps,
  InputProps,
  shrink,
  margin,
  smallInput,
  isCleanedWhenDisabled,
  alignText,
  picker,
  inputIsControlled,
  max,
  customType,
  borderLess,
  lightText,
  onKeyPress,
  maxLength,
  defaultValue = {},
  ...rest
}) => {
  // const inputValue = onChange ? { value: (value !== null ) ? value : '' } : {};
  const inputValue = { [onChange ? 'value' : 'defaultValue']: (value !== null ) ? value : '' };

  return (
    <TextField
      type={type}
      label={label}
      placeholder={placeholder}
      onChange={handleInputEvents(type, onChange, customType, max)}
      onBlur={handleInputEvents(type, onBlur, customType, max)}
      onKeyPress={handleInputEvents(type, onKeyPress, customType, max)}
      error={error}
      disabled={disabled}
      required={required}
      margin={margin}
      autoComplete="off"
      InputProps={{
        // eslint-disable-line
        classes: {
          ...classes.input,
          root: classNames(
            classes.input.root,
            {
              'sk-materialized-input_picker': picker,
            },
            { 'mui-override sk-materialized-input_borderLess': borderLess }
          ),
          input: classNames(
            {
              'mui-override sk-materialized-input_caption': smallInput,
            },

            {
              'mui-override sk-materialized-input_isCleaned disabled':
                isCleanedWhenDisabled && disabled,
            },

            {
              'mui-override sk-materialized-input_lightText': lightText,
            },

            `mui-override sk-materialized-input_${alignText}`
          ),
        },
        ...inputProps,
        ...InputProps,
      }}
      InputLabelProps={{
        classes: classes.label,
        shrink,
      }}
      FormHelperTextProps={{ classes: classes.helperText }}
      {...rest}
      {...inputValue}
    />
  );
};

MaterializedTextField.defaultProps = {
  fullWidth: true,
  inputIsControlled: true,
  margin: 'dense',
  alignText: 'left',
};

export default memo(MaterializedTextField);
