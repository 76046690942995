import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'app.routes.FailedMessage.title',
    defaultMessage: 'Operation Failed',
  },
  button: {
    id: 'app.routes.FailedMessage.button',
    defaultMessage: 'Close',
  },
});
