// Core
import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';

// Styles and Assets
import './GainSkills.scss';

// Components
import ReadMore from 'components/ReadMore';

// Other
import { FormattedMessage } from 'react-intl';
import { messages } from 'routes/CourseDetails/GainSkills/messages';

const textStyles = {
  fontSize: '16px',
  lineHeight: '24px',
  fontFamily: 'Roboto',
  color: '#6D7C90',
  margin: 0,
  padding: 0,
  whiteSpace: 'break-spaces'
};

const GainSkills = ({ skills }) => {
  return (
    <div className="sk-GainSkills">
      <h3 className="sk-GainSkills-title">
        <FormattedMessage {...messages.gainSkills} />
      </h3>
      <ReadMore lines={5} textStyles={textStyles} withToggle={true}>
        {skills}
      </ReadMore>
    </div>
  );
};

export default memo(GainSkills);
