// Core
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Material Components
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import Button from 'components/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

// Styles and Assets
import './StatusFilter.scss';

const findStatusCallback = checkboxName => checkbox => checkbox === checkboxName;

const StatusFilter = ({ anchorEl, handleFilter, statusFilters }) => {
  const [checked, setCheck] = useState(statusFilters);
  const handleChange = type => () => {
    const isTypeChecked = checked.find(findStatusCallback(type));
    let newChecked = [...checked];

    if (isTypeChecked) newChecked = newChecked.filter(checkboxName => checkboxName !== type)
    else newChecked = [...newChecked, type]


    setCheck(newChecked)
  }

  return (
    <Popper id='no-transition-popper' open={Boolean(anchorEl)} anchorEl={anchorEl} placement='bottom'>
      <Paper className='mui-override sk-admin-coourses-list-filter'>
        <div className='mui-override sk-admin-coourses-list-filter__checkboxes-group'>
          <FormControlLabel control={<Checkbox checked={checked.find(findStatusCallback('ACTIVE'))} onChange={handleChange('ACTIVE')} value="ACTIVE" />} label="Active" />
          <FormControlLabel control={<Checkbox checked={checked.find(findStatusCallback('DRAFT'))} onChange={handleChange('DRAFT')} value="DRAFT" />} label="Draft" />
          <FormControlLabel control={<Checkbox checked={checked.find(findStatusCallback('MAIN_FILLING'))} onChange={handleChange('MAIN_FILLING')} value="MAIN_FILLING" />} label="Main filling" />
          <FormControlLabel control={<Checkbox checked={checked.find(findStatusCallback('FIRST_REJECT'))} onChange={handleChange('FIRST_REJECT')} value="FIRST_REJECT" />} label="First Reject" />
          <FormControlLabel control={<Checkbox checked={checked.find(findStatusCallback('SECOND_REJECT'))} onChange={handleChange('SECOND_REJECT')} value="SECOND_REJECT" />} label="Second Reject" />
          <FormControlLabel control={<Checkbox checked={checked.find(findStatusCallback('DEACTIVATED'))} onChange={handleChange('DEACTIVATED')} value="DEACTIVATED" />} label="Deactivated" />
          <FormControlLabel control={<Checkbox checked={checked.find(findStatusCallback('FIRST_REVIEW'))} onChange={handleChange('FIRST_REVIEW')} value="FIRST_REVIEW" />} label="First review" />
          <FormControlLabel control={<Checkbox checked={checked.find(findStatusCallback('FINAL_REVIEW'))} onChange={handleChange('FINAL_REVIEW')} value="FINAL_REVIEW" />} label="Final review" />
          <FormControlLabel control={<Checkbox checked={checked.find(findStatusCallback('READY_TO_PUBLISHING'))} onChange={handleChange('READY_TO_PUBLISHING')} value="READY_TO_PUBLISHING" />} label="Ready to publishing" />
          <Button onClick={handleFilter('status', checked)} className='mui-override sk-admin-coourses-list-filter__checkboxes-group__button'>Apply</Button>
        </div>
      </Paper>
    </Popper>
  );
}

StatusFilter.propTypes = {
  anchorEl: PropTypes.object,
};

export default StatusFilter;
