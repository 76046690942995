// Core
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { graphql } from 'react-apollo';
import { FormattedMessage } from 'react-intl';

// Components
import Button from 'components/Button';
import TextInput from 'components/Input/Material/TextInput';
import FileUploadModule from 'routes/Course/components/FileUploadModule';
import {
  VideoPreview,
  PdfPreview,
  TextPreview,
  EmptyPreview,
} from 'components/FilePreview';

// Other
import { getYupSchema } from 'services/yup';
import { parseEntityDuration } from 'utils/helpers';
import { messages } from 'routes/Course/components/ComplexitySelect/messages';
import {
  PDF_FILE,
  SUPPORTED_FORMATS_ARTICLE,
  DEFAULT_MAX_FILE_SIZE,
} from 'utils/enums/index';
import { sharedMessages } from 'services/i18n/sharedMessages/messages';

// Styles and Assets
import './PdfFileEditModal.scss';

const getFormikValidationSchema = ({ withDuration }) =>
  withDuration
    ? getYupSchema('articlePDFModalValidationSchema')
    : getYupSchema('fileModalWithoutModalValidationSchema');

const PdfFileEditModal = ({
  entity,
  lectureId,
  courseId,
  editEntity,
  handleUploadPdf,
  handleModalClose,
  withDuration = true,
}) => {
  const handleSubmit = async ({ duration, title, file }) => {
    const isNewFileAdded = file && file?.name;
    const isPDFFileUploadedInS3 = !!entity?.id;

    const durationInSeconds = parseEntityDuration(
      duration,
      'minutes',
      'asSeconds'
    );
    const params = {
      file,
      filetype: PDF_FILE,
      duration: durationInSeconds,
      title,
      lectureId,
      courseId,
    };

    if (isPDFFileUploadedInS3) {
      params.id = entity?.id;
      if (!isNewFileAdded) delete params.file;
    }

    const needToUpdateDetails = !isNewFileAdded && isPDFFileUploadedInS3;
    const addOrUpdatePDFFile = isNewFileAdded;

    if (addOrUpdatePDFFile) {
      await handleUploadPdf(params);
    } else if (needToUpdateDetails) {
      await editEntity({
        id: entity?.id,
        title,
        duration: durationInSeconds,
      });
    }
    handleModalClose();
  };

  let formFields = { title: '', duration: null, file: entity };

  if (entity) {
    const { title, duration } = entity;
    formFields = {
      ...formFields,
      title,
      duration: parseEntityDuration(duration, 'seconds', 'asMinutes'),
    }};

  const editorOptions = {};
  const [editorText, setEditorText] = useState('');
  const handlerEditorTextChange = (html, medium) => {
    console.log('editor handler args', html, medium);
  };

  return (
    <div className="sk-lec-article-modal">
      <Formik
        enableReinitialize
        initialValues={formFields}
        validateOnChange={false}
        validateOnBlur
        onSubmit={values => handleSubmit(values)}
        validationSchema={getFormikValidationSchema({ withDuration })}
      >
        {props => {
          const {
            values: { title, duration, text, file },
            errors,
            touched,
            disabled,
            handleSubmit,
            handleBlur,
            setFieldValue,
          } = props;

          const handleInputChange = name => (inputValue, e) =>
            setFieldValue(name, inputValue);

          const setTitleFromFile = filename =>
            !title ? setFieldValue('title', filename) : null;

          return (
            <form
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
              className="sk-lec-article-modal__form"
            >
              <div className="sk-lec-article-modal__inner">
                <div className="sk-lec-article-modal__item sk-form_row">
                  <FormattedMessage {...sharedMessages.title}>
                    {msg => (
                      <TextInput
                        helperText={touched.title ? errors.title : ''}
                        error={touched.title && Boolean(errors.title)}
                        value={title}
                        name="title"
                        label={msg}
                        onChange={handleInputChange('title')}
                      />
                    )}
                  </FormattedMessage>
                </div>
                {withDuration && (
                  <div className="sk-lec-article-modal__item sk-lec-article-modal__time">
                    <div className="sk-form_row">
                      <FormattedMessage {...messages.estimatedReadingTime}>
                        {msg => (
                          <TextInput
                            type="number"
                            adornment={<FormattedMessage {...messages.min} />}
                            helperText={touched.duration ? errors.duration : ''}
                            error={touched.duration && Boolean(errors.duration)}
                            value={duration}
                            name="duration"
                            label={msg}
                            onChange={handleInputChange('duration')}
                          />
                        )}
                      </FormattedMessage>
                    </div>
                  </div>
                )}
                <div className="sk-lec-article-modal__item sk-form__row">
                  <FileUploadModule
                    supportedMimetypes={['application/pdf']}
                    supportedFormats={SUPPORTED_FORMATS_ARTICLE}
                    maxSize={DEFAULT_MAX_FILE_SIZE}
                    setTitle={setTitleFromFile}
                    handleFileAdd={handleInputChange('file')}
                    courseId={courseId}
                    lectureId={lectureId}
                    disabled={disabled}
                    file={file}
                    preview={PdfPreview}
                  />
                </div>
              </div>
              <footer className="sk-lec-article-modal__footer">
                <Button
                  variant="text"
                  color="secondary"
                  size="large"
                  type="submit"
                >
                  <FormattedMessage {...sharedMessages.saveButton} />
                </Button>
              </footer>
            </form>
          );
        }}
      </Formik>
    </div>
  );
}

PdfFileEditModal.defaultProps = {
  durationUnits: 'minutes',
};

PdfFileEditModal.propTypes = {
  article: PropTypes.object,
  durationUnits: PropTypes.string,
  handleArticleSave: PropTypes.func,
};

export default PdfFileEditModal;
