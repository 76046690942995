import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  sendMessage: {
    id: 'app.components.SendMessageExpertModal.sendMessage',
    defaultMessage: 'Send message to ',
  },
  courseTeacher: {
    id: 'app.components.SendMessageExpertModal.courseTeacher',
    defaultMessage: 'course teacher',
  },
  send: {
    id: 'app.components.SendMessageExpertModal.send',
    defaultMessage: 'Send',
  },
  msgToExpert: {
    id: 'app.components.SendMessageExpertModal.msgToExpert',
    defaultMessage: 'Message to Expert (max 500 words)',
  },
  successful: {
    id: 'app.components.SendMessageExpertModal.successful',
    defaultMessage: 'Successful',
  },
  soonYouWillReceiveRes: {
    id: 'app.components.SendMessageExpertModal.soonYouWillReceiveRes',
    defaultMessage: 'Soon you weill receive a response',
  },
  back: {
    id: 'app.components.SendMessageExpertModal.back',
    defaultMessage: 'Back',
  },
});
