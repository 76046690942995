// Core
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

// Components
import Tabs from 'components/Tabs';

// Other
import { messages } from 'routes/ExpertsCabinet/CourseEdit/CourseEditTabs/messages';

class CourseEditTabs extends PureComponent {
  handleChange = (e, value) => {
    const { handleSwitch } = this.props;
    handleSwitch(value);
  };

  render() {
    const { tabs, activeTab, disabledTab } = this.props;
    return (
      <Tabs
        tabs={tabs}
        value={activeTab}
        handleChange={this.handleChange}
        disabledTab={disabledTab}
      />
    );
  }
}

CourseEditTabs.propTypes = {
  tabs: PropTypes.array,
  handleSwitch: PropTypes.func,
  activeTab: PropTypes.string,
  disabledTab: PropTypes.string,
};

CourseEditTabs.defaultProps = {
  tabs: [
    {
      label: <FormattedMessage {...messages.courseInfo} />,
      value: 'courseInfo',
    },
    {
      label: <FormattedMessage {...messages.courseStructure} />,
      value: 'courseStructure',
    },
  ],
};

export default CourseEditTabs;
