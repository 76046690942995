import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  changesSaved: {
    id: 'app.components.NotificationProvider.changesSaved',
    defaultMessage: 'The changes have been successfully saved',
  },
  changesNotSaved: {
    id: 'app.components.NotificationProvider.changesSaved',
    defaultMessage: 'The changes haven`t been successfully saved',
  },
  emailSent: {
    id: 'app.components.NotificationProvider.emailSent',
    defaultMessage: 'Your email has been sent',
  },
});
