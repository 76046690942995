// Core
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Components
import ChevronRight from 'components/Icon/Svg/ChevronRight';
import ChevronLeft from 'components/Icon/Svg/ChevronLeft';

// Other
import { Red, PurpleDark } from 'components/Icon/color';

// Styles and Assets
import './SwipeButton.scss';

const SwipeButton = ({ handleClick, direction, color, size, btnTag: Tag }) => {
  const iconColor = color === 'primary' ? PurpleDark : Red;

  return (
    <Tag
      onClick={handleClick}
      className={classNames(
        'sk-swipe-btn',
        `sk-swipe-btn__${size}`,
        `sk-swipe-btn__${color}`
      )}
      type="button"
    >
      <div
        className={classNames(
          'sk-swipe-btn__icon',
          `sk-swipe-btn__icon_${direction}`
        )}
      >
        {direction === 'prev' ? (
          <ChevronLeft color={iconColor} width="12" height="16" />
        ) : (
          <ChevronRight color={iconColor} width="12" height="16" />
        )}
      </div>
    </Tag>
  );
};

SwipeButton.defaultProps = {
  direction: 'prev',
  size: 'medium',
  btnTag: 'button',
};

SwipeButton.propTypes = {
  handleClick: PropTypes.func,
  direction: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  btnTag: PropTypes.string,
};

export default memo(SwipeButton);
