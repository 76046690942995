import React from 'react';

import './index.scss';

const BlockquoteOnHover = () => (
  <svg
    className="hover"
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.693176" width="31.9221" height="32" rx="2" fill="white" />
    <path 
      d="M19.3974 20.1155L19.3974 20.1155L19.3995 20.1146C20.4609 19.6637 21.2835 19.0661 21.8437 18.3039L21.8438 18.3039L21.8464 18.3002C22.5249 17.3594 22.8725 16.1409 22.9014 14.6828L22.9115 14.1729L22.4015 14.1729L19.8215 14.1729C19.6448 14.1729 19.4981 14.0263 19.4981 13.8495L19.4981 8.45633C19.4981 8.27961 19.6448 8.13295 19.8215 8.13295L25.2147 8.13295C25.3914 8.13295 25.5381 8.27961 25.5381 8.45633L25.5381 12.6693C25.5381 14.6886 25.3477 16.2395 25.0108 17.3544C24.6807 18.433 24.0708 19.4157 23.1562 20.2786L23.1561 20.2786L23.1488 20.2857C22.4044 21.0173 21.458 21.6297 20.3401 22.1031L20.3401 22.1031L20.3362 22.1048C20.1734 22.1754 19.9924 22.1073 19.9155 21.9493L19.2448 20.5395C19.167 20.371 19.2485 20.1779 19.3974 20.1155Z"
      stroke="#7145D8"
    />
    <path
      d="M9.70892 20.1156L9.70893 20.1156L9.71105 20.1147C10.7725 19.6638 11.5951 19.0662 12.1553 18.3038L12.1554 18.3038L12.158 18.3001C12.8363 17.3594 13.1841 16.1409 13.2129 14.6828L13.223 14.1729L12.713 14.1729L10.1194 14.1729C9.9426 14.1729 9.79599 14.0263 9.79599 13.8495L9.79599 8.45631C9.79599 8.27959 9.94266 8.13292 10.1194 8.13292L15.5126 8.13292C15.6893 8.13292 15.8359 8.27959 15.8359 8.45631L15.8359 12.6693C15.8359 14.7077 15.6584 16.2556 15.3232 17.3382L15.3228 17.3398C14.9937 18.4153 14.3715 19.3999 13.4521 20.2805C12.6892 21.0047 11.7522 21.6179 10.6497 22.0904L10.6497 22.0903L10.6479 22.0911C10.4847 22.1619 10.3034 22.0933 10.2267 21.9348C10.2267 21.9347 10.2266 21.9347 10.2266 21.9346L9.55608 20.539C9.47886 20.3705 9.56043 20.1779 9.70892 20.1156Z"
      stroke="#7145D8"
    />
  </svg>
);

const DefaultBlockquote = () => (
  <svg
    className="default"
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M19.3974 20.1155L19.3974 20.1155L19.3995 20.1146C20.4609 19.6637 21.2835 19.0661 21.8437 18.3039L21.8438 18.3039L21.8464 18.3002C22.5249 17.3594 22.8725 16.1409 22.9014 14.6828L22.9115 14.1729L22.4015 14.1729L19.8215 14.1729C19.6448 14.1729 19.4981 14.0263 19.4981 13.8495L19.4981 8.45633C19.4981 8.27961 19.6448 8.13295 19.8215 8.13295L25.2147 8.13295C25.3914 8.13295 25.5381 8.27961 25.5381 8.45633L25.5381 12.6693C25.5381 14.6886 25.3477 16.2395 25.0108 17.3544C24.6807 18.433 24.0708 19.4157 23.1562 20.2786L23.1561 20.2786L23.1488 20.2857C22.4044 21.0173 21.458 21.6297 20.3401 22.1031L20.3401 22.1031L20.3362 22.1048C20.1734 22.1754 19.9924 22.1073 19.9155 21.9493L19.2448 20.5395C19.167 20.371 19.2485 20.1779 19.3974 20.1155Z" stroke="#6D6E71"/>
    <path d="M9.70892 20.1156L9.70893 20.1156L9.71105 20.1147C10.7725 19.6638 11.5951 19.0662 12.1553 18.3038L12.1554 18.3038L12.158 18.3001C12.8363 17.3594 13.1841 16.1409 13.2129 14.6828L13.223 14.1729L12.713 14.1729L10.1194 14.1729C9.9426 14.1729 9.79599 14.0263 9.79599 13.8495L9.79599 8.45631C9.79599 8.27959 9.94266 8.13292 10.1194 8.13292L15.5126 8.13292C15.6893 8.13292 15.8359 8.27959 15.8359 8.45631L15.8359 12.6693C15.8359 14.7077 15.6584 16.2556 15.3232 17.3382L15.3228 17.3398C14.9937 18.4153 14.3715 19.3999 13.4521 20.2805C12.6892 21.0047 11.7522 21.6179 10.6497 22.0904L10.6497 22.0903L10.6479 22.0911C10.4847 22.1619 10.3034 22.0933 10.2267 21.9348C10.2267 21.9347 10.2266 21.9347 10.2266 21.9346L9.55608 20.539C9.47886 20.3705 9.56043 20.1779 9.70892 20.1156Z" stroke="#6D6E71"/>
  </svg>
);

const ActiveBlockquote = () => (
  <svg
    className="active"
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
  <path
    d="M19.3974 20.1155L19.3974 20.1155L19.3995 20.1146C20.4609 19.6637 21.2835 19.0661 21.8437 18.3039L21.8438 18.3039L21.8464 18.3002C22.5249 17.3594 22.8725 16.1409 22.9014 14.6828L22.9115 14.1729L22.4015 14.1729L19.8215 14.1729C19.6448 14.1729 19.4981 14.0263 19.4981 13.8495L19.4981 8.45633C19.4981 8.27961 19.6448 8.13295 19.8215 8.13295L25.2147 8.13295C25.3914 8.13295 25.5381 8.27961 25.5381 8.45633L25.5381 12.6693C25.5381 14.6886 25.3477 16.2395 25.0108 17.3544C24.6807 18.433 24.0708 19.4157 23.1562 20.2786L23.1561 20.2786L23.1488 20.2857C22.4044 21.0173 21.458 21.6297 20.3401 22.1031L20.3401 22.1031L20.3362 22.1048C20.1734 22.1754 19.9924 22.1073 19.9155 21.9493L19.2448 20.5395C19.167 20.371 19.2485 20.1779 19.3974 20.1155Z"
    stroke="#7145D8"
  />
  <path
    d="M9.70892 20.1156L9.70893 20.1156L9.71105 20.1147C10.7725 19.6638 11.5951 19.0662 12.1553 18.3038L12.1554 18.3038L12.158 18.3001C12.8363 17.3594 13.1841 16.1409 13.2129 14.6828L13.223 14.1729L12.713 14.1729L10.1194 14.1729C9.9426 14.1729 9.79599 14.0263 9.79599 13.8495L9.79599 8.45631C9.79599 8.27959 9.94266 8.13292 10.1194 8.13292L15.5126 8.13292C15.6893 8.13292 15.8359 8.27959 15.8359 8.45631L15.8359 12.6693C15.8359 14.7077 15.6584 16.2556 15.3232 17.3382L15.3228 17.3398C14.9937 18.4153 14.3715 19.3999 13.4521 20.2805C12.6892 21.0047 11.7522 21.6179 10.6497 22.0904L10.6497 22.0903L10.6479 22.0911C10.4847 22.1619 10.3034 22.0933 10.2267 21.9348C10.2267 21.9347 10.2266 21.9347 10.2266 21.9346L9.55608 20.539C9.47886 20.3705 9.56043 20.1779 9.70892 20.1156Z"
    stroke="#7145D8"
  />
  </svg>
);

const Blockquote = ({ active }) => {
  const Icon = active ? ActiveBlockquote : DefaultBlockquote;

  return (
    <div className="editor-icon-svg">
      <Icon />
      <BlockquoteOnHover />
    </div>
  );
};

export default Blockquote;
