// Core
import React from 'react';
import { withRouter } from 'react-router-dom';
import withBreadcrumbs, { getBreadcrumbs } from 'react-router-breadcrumbs-hoc';

// Components
import Link from 'components/Link';

// Other
import publicRoutes from 'containers/Navigation/Router/routes/publicRoutes';
import privateRoutes from 'containers/Navigation/Router/routes/privateRoutes';

// Styles and assets
import './Breadcrumbs.scss';

const DEFAULT_LOCAITON = { pathname: '/' };
export const BreadcrumbsComponent = ({ location = DEFAULT_LOCAITON, ...props }) => {
  const breadcrumbs = getBreadcrumbs({ location, routes: privateRoutes, options: { disableDefaults: true } });
  return (
    <div>
      {breadcrumbs.map(({ breadcrumb, match }, index) => (
        <span className="sk-breadcrumbs_item" key={breadcrumb.key}>
          <Link to={match.url}>{breadcrumb}</Link>
          {index < breadcrumbs.length - 1 && (
            <i className="sk-breadcrumbs_separator">{' / '}</i>
          )}
        </span>
      ))}
    </div>
  )
};

export default withRouter(BreadcrumbsComponent);
