// Core
import React, { useState } from 'react';
import { compose, graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import Collapse from '@material-ui/core/Collapse';
import { withFormik } from 'formik';
import { injectIntl } from 'react-intl';
import moment from 'moment';

// Components
import Text from 'components/Text';
import Loader from 'components/Loader';
import Card from 'components/Card';
import ContentHeader from 'containers/ContentHeader';
import Button from 'components/Button';
// import DatePicker from 'components/DatePicker';
import LocalizedDatePicker from 'components/DatePicker/LocalizedDatePicker.js';

// Other
import { isNull, isBlank } from  'utils/helpers/index';
import { getYupSchema, } from 'services/yup';
import { sharedMessages } from 'services/i18n/sharedMessages/messages';
import { messages } from 'routes/ManageAccount/ExpertSales/messages';

// Styles and Assets
import './DurationRangeFilter.scss';

const getInitialValues = ({ startTime = null, endTime = null }) => ({
  startTime: !isNull(startTime) ? formatDate(startTime) : formatDate(moment().startOf('month')),
  endTime: !isNull(endTime) ? formatDate(endTime) : formatDate(moment().endOf('month')),
});

const formatDate = date => moment.isMoment(date) ? date.unix() : moment.unix(date);
const formatDisplayValue = date => moment.isMoment(date) ? date : formatDate(date);


const DurationRangeFilter = ({
  values, touched, isValid, errors, setFieldValue, setFieldTouched, handleChange, handleBlur, handleSearch, intl: { formatMessage },
  validateForm, }) => {

  const handleInputChange = fieldName => (date) => {
    const formatedValue = formatDate(date);
    // debugger;
    setFieldValue(fieldName, formatedValue);
    setFieldTouched(fieldName, true, false);
  }

  const handleSubmit = async () => {
    const { startTime, endTime } = values;
    const validationResult = await validateForm(values);

    if (isValid) {
      await handleSearch({ startTime, endTime });
    };
  };

  return (
    <Card>
      <div className="mui-override sk-expert-analytics-duration">
        <Text className="mui-override sk-expert-analytics-duration__text">{formatMessage(messages.queryTitle)}</Text>

        <div className="mui-override sk-expert-analytics-duration__selectors-group">
          <div className="mui-override sk-expert-analytics-duration__selectors-group__selector">
            <Text className="mui-override sk-expert-analytics-duration__text">{formatMessage(messages.queryFrom)}</Text>
            <LocalizedDatePicker
              name="startTime"
              value={values.startTime}
              handleInputChange={handleInputChange}
              helperText={touched.startTime ? errors.startTime : ''}
              error={touched.startTime && Boolean(errors.startTime)}
            />
          </div>
          <div className="mui-override sk-expert-analytics-duration__selectors-group__selector">
            <Text className="mui-override sk-expert-analytics-duration__text">{formatMessage(messages.queryTo)}</Text>
            <LocalizedDatePicker
              name="endTime"
              value={values.endTime}
              handleInputChange={handleInputChange}
              helperText={touched.endTime ? errors.endTime : ''}
              error={touched.endTime && Boolean(errors.endTime)}
            />
          </div>
        </div>
        <Button className="mui-override sk-expert-analytics-duration__button" onClick={handleSubmit} disabled={!isValid}>
          {formatMessage(sharedMessages.showButton)}
        </Button>
      </div>
    </Card>
  );
}

const formikStateManagement = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ query: { filter } }) => {
    const durationFilter = filter.find(filter => filter.key === 'time_range');
    const startTime = durationFilter ? formatDate(durationFilter.values[0]) : null;
    const endTime = durationFilter ? formatDate(durationFilter.values[1]) : null;

    let initialValues = getInitialValues({ startTime, endTime });
    return initialValues;
  },
  displayName: 'DurationRangeFilterForm',
  validationSchema: () => getYupSchema('expertAnalyticsQueryValidationSchema'),
  validateOnChange: true,
  validateOnBlur: true,
  validateOnMount: true,
})

const enhancer = compose(
  injectIntl,
  formikStateManagement
);

export default enhancer(DurationRangeFilter);
