// Core
import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import classNames from 'classnames';
import { compose, graphql } from 'react-apollo';
import NoSsr from '@material-ui/core/NoSsr';
import { FormattedMessage } from 'react-intl';

// Components
import Text from 'components/Text';
import Button from 'components/Button';
import FileUploader from 'components/FileUploader';
import Loader from 'components/Loader';

// Other
import GET_FILE_URL_MUTATION from 'mutations/Courses/getFileUrl.gql';
import { SUPPORTED_FORMATS_ARTICLE, DEFAULT_MAX_FILE_SIZE, } from 'utils/enums';
import { messages } from 'routes/Course/components/ComplexitySelect/messages';
import { sharedMessages } from 'services/i18n/sharedMessages/messages';
import { getSeparatedFilename } from 'services/aws/amplify/helpers';

// Styles and Assets
import './FileUploadModule.scss';

class FileUploadModule extends PureComponent {
  state = {
    isPreviewOpen: false,
  }

  togglePreview = () => this.setState(prevState => ({ isPreviewOpen: !prevState.isPreviewOpen }));

  chooseFile = (fileList, fileListFromDrag) => {
    const { handleFileAdd, setTitle, supportedMimetypes, supportedFormats, maxSize } = this.props;
    const file = fileList[0] || fileListFromDrag[0];

    if (!file) return null;

    const isFileTypeAllowed = supportedMimetypes ? supportedMimetypes.includes(file.type) : true;
    const isFileSizeAllowed = file.size < maxSize;
    const { fileName, ext } = getSeparatedFilename(file.name);
    const isFileFormatAllowed = supportedFormats.toLocaleLowerCase().includes(ext);

    if (isFileTypeAllowed && isFileFormatAllowed && isFileSizeAllowed) {
      if (setTitle) {
        setTitle(fileName);
      };
      handleFileAdd(file);
      return;
    }

    return null;
  };

  handleClick = evt => {
    const { handleClick } = this.props;
    if (handleClick) {
      handleClick(evt);
    } else {
      evt.preventDefault();
    }
  };

  render() {
    const {
      entity,
      disabled,
      lectureId,
      preview: Preview = null,
      file,
      supportedFormats,
      maxSize,
    } = this.props;
    const { isPreviewOpen, fileSource } = this.state;
    const isFileAdded = file && file !== null;
    const filename = isFileAdded && (file?.name || file?.filename);
    const withPreview = isFileAdded && Preview;

    return (
      <Dropzone maxSize={DEFAULT_MAX_FILE_SIZE} onDrop={this.chooseFile}>
        {({ getRootProps }) => (
          <div
            id='sk-lec-article-uploader'
            className={classNames('sk-lec-article-uploader', { disabled })}
            {...getRootProps()}
          >
            <header className="sk-lec-article-uploader__header">
              <Text newLine>
                {isFileAdded ?
                  filename : (<FormattedMessage {...messages.dropFiles} />)
                }
              </Text>
            </header>
            <div className='sk-lec-article-uploader__actions-with-file'>
              <Button size="large" disabled={disabled} className='sk-lec-article-uploader__select-file-button'>
                <FileUploader
                  absolute
                  supportedFormats={supportedFormats}
                  maxSize={maxSize}
                  uploaderId={`sk-lec-${lectureId}}-article-uploader-1`}
                  handleChange={this.chooseFile}
                  disabled={disabled}
                >
                  <div>
                    <FormattedMessage {...messages.selectFiles} />
                  </div>
                </FileUploader>
              </Button>
              {withPreview ? (
                <Button size="large" disabled={disabled} className='sk-lec-article-uploader__select-file-button' onClick={this.togglePreview}>
                  {isPreviewOpen ? (<FormattedMessage {...sharedMessages.closeButton} />) : (<FormattedMessage {...sharedMessages.openButton} />)}
                </Button>) :
                null
              }
            </div>
            {withPreview && isPreviewOpen && (
              <Preview
                filepath={isFileAdded && file?.filepath}
                file={file}
               />
            )}
          </div>
        )}
      </Dropzone>
    );
  }
}

FileUploadModule.propTypes = {
  handleFileAdd: PropTypes.func,
  disabled: PropTypes.bool,
  lectureId: PropTypes.string,
  disabledTitle: PropTypes.string,
  handleClick: PropTypes.func,
};

const getFileUrlMutation = graphql(GET_FILE_URL_MUTATION, {
  props: ({ mutate }) => ({
    getFileUrl: input => mutate({ variables: { input } }),
  }),
});

const enhancer = compose(
  getFileUrlMutation
)

export default enhancer(FileUploadModule);
