import React from 'react';
import PropTypes from 'prop-types';
import { White } from 'components/Icon/color';

const Pencil = ({ color, height, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 31 31"
  >
    <path
      fill={color}
      d="M28.0943837,8.02942806 L26.044477,10.0793661 L20.9197101,4.94819405
       L22.9696168,2.89825603 C23.3871904,2.48067606 24.1147808,2.48067606 
       24.5323544,2.89825603 L28.0943837,6.46666666 C28.5246111,6.89690057
        28.5246111,7.59919415 28.0943837,8.02942806 Z M10.9675393,25.1755146
         L5.84277244,20.0443426 L19.0912439,6.77668815 L24.2160108,11.9078602 
         L10.9675393,25.1755146 Z M4.65964725,22.5181875 L8.4937321,26.3586579 
         L3.15385154,27.8644765 L4.65964725,22.5181875 Z M29.9228499,4.63817256
          L26.3608206,1.06976192 C25.6648646,0.373795312 23.1530961,-0.942214284 
          21.1474775,1.06976192 L3.09690969,19.133259 C2.93873787,19.2914332 
          2.82485416,19.4812423 2.76791231,19.6963593 L0.0473570502,29.3829491 
          C-0.0791804037,29.832164 0.0536839229,30.3130136 0.37635443,30.648343 
          C0.705351811,30.9836723 1.36334657,31.0406151 1.64172897,30.9773454 
          L11.3218442,28.2504216 C11.5369579,28.1934789 11.726764,28.0795935 
          11.8849359,27.9214193 L29.9228499,9.85792216 C31.35905,8.42170015 
          31.35905,6.08072154 29.9228499,4.63817256 Z"
    />
  </svg>
);

Pencil.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  color: PropTypes.string,
};

Pencil.defaultProps = {
  color: White,
  height: '31',
  width: '31',
};

export default Pencil;
