import React from 'react';

import './index.scss';

const BlockquoteOnHover = () => (
  <svg
    className="hover"
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 18.5V21C7 22.6569 8.34315 24 10 24H22C23.6569 24 25 22.6569 25 21V11C25 9.34315 23.6569 8 22 8H16.5"
      stroke="#7145D8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.55279 15.7764C7.42929 16.0234 7.5294 16.3237 7.77639 16.4472C8.02338 16.5707 8.32372 16.4706 8.44721 16.2236L7.55279 15.7764ZM12.4472 8.22361C12.5707 7.97662 12.4706 7.67628 12.2236 7.55279C11.9766 7.42929 11.6763 7.5294 11.5528 7.77639L12.4472 8.22361ZM8.44721 16.2236L12.4472 8.22361L11.5528 7.77639L7.55279 15.7764L8.44721 16.2236Z"
      fill="#7145D8"
    />
    <path
      d="M6.87987 9.51176C7.07513 9.31649 7.07513 8.99991 6.87987 8.80465C6.68461 8.60939 6.36802 8.60939 6.17276 8.80465L6.87987 9.51176ZM4 11.6845L3.64645 11.331C3.45118 11.5262 3.45118 11.8428 3.64645 12.0381L4 11.6845ZM6.17276 14.5644C6.36802 14.7597 6.68461 14.7597 6.87987 14.5644C7.07513 14.3691 7.07513 14.0525 6.87987 13.8573L6.17276 14.5644ZM6.17276 8.80465L3.64645 11.331L4.35355 12.0381L6.87987 9.51176L6.17276 8.80465ZM3.64645 12.0381L6.17276 14.5644L6.87987 13.8573L4.35355 11.331L3.64645 12.0381Z"
      fill="#7145D8"
    />
    <path
      d="M13.1201 13.8574C12.9249 14.0526 12.9249 14.3692 13.1201 14.5645C13.3154 14.7598 13.632 14.7598 13.8272 14.5645L13.1201 13.8574ZM16 11.6846L16.3536 12.0382C16.5488 11.8429 16.5488 11.5263 16.3536 11.3311L16 11.6846ZM13.8272 8.80475C13.632 8.60949 13.3154 8.60949 13.1201 8.80475C12.9249 9.00001 12.9249 9.3166 13.1201 9.51186L13.8272 8.80475ZM13.8272 14.5645L16.3536 12.0382L15.6464 11.3311L13.1201 13.8574L13.8272 14.5645ZM16.3536 11.3311L13.8272 8.80475L13.1201 9.51186L15.6464 12.0382L16.3536 11.3311Z"
      fill="#7145D8"
    />
  </svg>
);

const DefaultBlockquote = () => (
  <svg
    className="default"
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 18.5V21C7 22.6569 8.34315 24 10 24H22C23.6569 24 25 22.6569 25 21V11C25 9.34315 23.6569 8 22 8H16.5"
      stroke="#6D6E71"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.55279 15.7764C7.42929 16.0234 7.5294 16.3237 7.77639 16.4472C8.02338 16.5707 8.32372 16.4706 8.44721 16.2236L7.55279 15.7764ZM12.4472 8.22361C12.5707 7.97662 12.4706 7.67628 12.2236 7.55279C11.9766 7.42929 11.6763 7.5294 11.5528 7.77639L12.4472 8.22361ZM8.44721 16.2236L12.4472 8.22361L11.5528 7.77639L7.55279 15.7764L8.44721 16.2236Z"
      fill="#6D6E71"
    />
    <path
      d="M6.87987 9.51176C7.07513 9.31649 7.07513 8.99991 6.87987 8.80465C6.68461 8.60939 6.36802 8.60939 6.17276 8.80465L6.87987 9.51176ZM4 11.6845L3.64645 11.331C3.45118 11.5262 3.45118 11.8428 3.64645 12.0381L4 11.6845ZM6.17276 14.5644C6.36802 14.7597 6.68461 14.7597 6.87987 14.5644C7.07513 14.3691 7.07513 14.0525 6.87987 13.8573L6.17276 14.5644ZM6.17276 8.80465L3.64645 11.331L4.35355 12.0381L6.87987 9.51176L6.17276 8.80465ZM3.64645 12.0381L6.17276 14.5644L6.87987 13.8573L4.35355 11.331L3.64645 12.0381Z"
      fill="#6D6E71"
    />
    <path
      d="M13.1201 13.8574C12.9249 14.0526 12.9249 14.3692 13.1201 14.5645C13.3154 14.7598 13.632 14.7598 13.8272 14.5645L13.1201 13.8574ZM16 11.6846L16.3536 12.0382C16.5488 11.8429 16.5488 11.5263 16.3536 11.3311L16 11.6846ZM13.8272 8.80475C13.632 8.60949 13.3154 8.60949 13.1201 8.80475C12.9249 9.00001 12.9249 9.3166 13.1201 9.51186L13.8272 8.80475ZM13.8272 14.5645L16.3536 12.0382L15.6464 11.3311L13.1201 13.8574L13.8272 14.5645ZM16.3536 11.3311L13.8272 8.80475L13.1201 9.51186L15.6464 12.0382L16.3536 11.3311Z"
      fill="#6D6E71"
    />
  </svg>
);

const ActiveBlockquote = () => (
  <svg
    className="active"
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 18.5V21C7 22.6569 8.34315 24 10 24H22C23.6569 24 25 22.6569 25 21V11C25 9.34315 23.6569 8 22 8H16.5"
      stroke="#7145D8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.55279 15.7764C7.42929 16.0234 7.5294 16.3237 7.77639 16.4472C8.02338 16.5707 8.32372 16.4706 8.44721 16.2236L7.55279 15.7764ZM12.4472 8.22361C12.5707 7.97662 12.4706 7.67628 12.2236 7.55279C11.9766 7.42929 11.6763 7.5294 11.5528 7.77639L12.4472 8.22361ZM8.44721 16.2236L12.4472 8.22361L11.5528 7.77639L7.55279 15.7764L8.44721 16.2236Z"
      fill="#7145D8"
    />
    <path
      d="M6.87987 9.51176C7.07513 9.31649 7.07513 8.99991 6.87987 8.80465C6.68461 8.60939 6.36802 8.60939 6.17276 8.80465L6.87987 9.51176ZM4 11.6845L3.64645 11.331C3.45118 11.5262 3.45118 11.8428 3.64645 12.0381L4 11.6845ZM6.17276 14.5644C6.36802 14.7597 6.68461 14.7597 6.87987 14.5644C7.07513 14.3691 7.07513 14.0525 6.87987 13.8573L6.17276 14.5644ZM6.17276 8.80465L3.64645 11.331L4.35355 12.0381L6.87987 9.51176L6.17276 8.80465ZM3.64645 12.0381L6.17276 14.5644L6.87987 13.8573L4.35355 11.331L3.64645 12.0381Z"
      fill="#7145D8"
    />
    <path
      d="M13.1201 13.8574C12.9249 14.0526 12.9249 14.3692 13.1201 14.5645C13.3154 14.7598 13.632 14.7598 13.8272 14.5645L13.1201 13.8574ZM16 11.6846L16.3536 12.0382C16.5488 11.8429 16.5488 11.5263 16.3536 11.3311L16 11.6846ZM13.8272 8.80475C13.632 8.60949 13.3154 8.60949 13.1201 8.80475C12.9249 9.00001 12.9249 9.3166 13.1201 9.51186L13.8272 8.80475ZM13.8272 14.5645L16.3536 12.0382L15.6464 11.3311L13.1201 13.8574L13.8272 14.5645ZM16.3536 11.3311L13.8272 8.80475L13.1201 9.51186L15.6464 12.0382L16.3536 11.3311Z"
      fill="#7145D8"
    />
  </svg>
);

const Blockquote = ({ active }) => {
  const Icon = active ? ActiveBlockquote : DefaultBlockquote;

  return (
    <div className="editor-icon-svg">
      <Icon />
      <BlockquoteOnHover />
    </div>
  );
};

export default Blockquote;
