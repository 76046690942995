import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  requireInteger: {
    id: 'app.errors.requireInteger',
    defaultMessage: 'Please enter only numbers',
  },
  fieldIsRequired: {
    id: 'app.errors.fieldIsRequired',
    defaultMessage: 'Field is required',
  },
  previewIsRequired: {
    id: 'app.errors.previewIsRequired',
    defaultMessage: 'Course preview is required',
  },
  email: {
    id: 'app.errors.email',
    defaultMessage: 'Email',
  },
  enterAValidEmail: {
    id: 'app.errors.enterAValidEmail',
    defaultMessage: 'Enter a valid email address',
  },
  password: {
    id: 'app.errors.password',
    defaultMessage: 'Password',
  },
  passwordShouldMatch: {
    id: 'app.errors.passwordShouldMatch',
    defaultMessage:
      'Password must contain at least 8 characters, 1 uppercase letter, 1 lowercase letter, and 1 number',
  },
  firstName: {
    id: 'app.errors.firstName',
    defaultMessage: 'First name',
  },
  lastName: {
    id: 'app.errors.lastName',
    defaultMessage: 'Last name',
  },
  passwordConfirmation: {
    id: 'app.errors.passwordConfirmation',
    defaultMessage: 'Password confirmation',
  },
  passwordMismatch: {
    id: 'app.errors.passwordMismatch',
    defaultMessage: 'Password does not match',
  },
  mustAcceptTerms: {
    id: 'app.errors.mustAcceptTerms',
    defaultMessage: 'Must Accept Terms and Conditions',
  },
  country: {
    id: 'app.errors.country',
    defaultMessage: 'Country',
  },
  city: {
    id: 'app.errors.city',
    defaultMessage: 'City',
  },
  isAgreeWithPolicy: {
    id: 'app.errors.isAgreeWithPolicy',
    defaultMessage: 'You have to agree',
  },
  minExpertiseAmount: {
    id: 'app.errors.minExpertiseAmount',
    defaultMessage: 'Choose minimum { min } expertise',
  },
  code: {
    id: 'app.errors.code',
    defaultMessage: 'Code',
  },
  minCodeLength: {
    id: 'app.errors.minCodeLength',
    defaultMessage:
      'Length of the confirmation code should be at least { min } numbers',
  },
  aboutMe: {
    id: 'app.errors.aboutMe',
    defaultMessage: 'About me',
  },
  policy: {
    id: 'app.errors.policy',
    defaultMessage: 'Policy',
  },
  oldPassword: {
    id: 'app.errors.oldPassword',
    defaultMessage: 'Old Password',
  },
  reEnterPw: {
    id: 'app.errors.reEnterPw',
    defaultMessage: 'Re-enter new password',
  },
  save: {
    id: 'app.errors.save',
    defaultMessage: 'Save',
  },
  newPassword: {
    id: 'app.errors.newPassword',
    defaultMessage: 'New Password',
  },
  minMark: {
    id: 'app.errors.minMark',
    defaultMessage: 'Min. { min } ',
  },
  maxMark: {
    id: 'app.errors.maxMark',
    defaultMessage: 'Max. { max } ',
  },
  maximumMessageLength: {
    id: 'app.errors.maximumMessageLength',
    defaultMessage: 'Max. { max } words',
  },
  successful: {
    id: 'app.components.SendMessageExpertModal.successful',
    defaultMessage: 'Successful',
  },
  yourPwChanged: {
    id: 'app.components.SuccessfulChangeMessage.yourPwChanged',
    defaultMessage: 'Your password was changed',
  },
  deleteAccount: {
    id: 'app.components.ManageAccountSidebar.deleteAccount',
    defaultMessage: 'Delete Account',
  },
  whenYouDeleteAcc: {
    id: 'app.components.DeleteAcc.whenYouDeleteAcc',
    defaultMessage:
      'When you delete your account, your profile, courses, marks, comment, and rates will be permanently removed.',
  },
  backToPersonalCabinet: {
    id: 'app.components.SuccessfulChangeMessage.backToPersonalCabinet',
    defaultMessage: 'Back to personal cabinet',
  },
  delete: {
    id: 'app.components.DeleteConfirmationModal.delete',
    defaultMessage: 'Delete',
  },
  cancel: {
    id: 'app.components.DeleteConfirmationModal.cancel',
    defaultMessage: 'Cancel',
  },
  areYouSureToDelete: {
    id: 'app.components.DeleteConfirmationModal.areYouSureToDelete',
    defaultMessage: 'Are you sure you want to delete your account?',
  },
  beforeStartTime: {
    id: 'app.errors.beforeStartTime',
    defaultMessage: 'Start date cannot be later than its end date!',
  },
  laterEndTime: {
    id: 'app.errors.laterEndTime',
    defaultMessage: 'End date cannot be before start date!',
  },
  valueBetween: {
    id: 'app.errors.valueBetween',
    defaultMessage: 'Value should be more then {min}, but less then {max}',
  },
  minDiscountCodeLength: {
    id: 'app.errors.minDiscountCodeLength',
    defaultMessage: 'Code should include numbers and letters not more than 10',
  },
  invalidPromocode: {
    id: 'app.errors.invalidPromocode',
    defaultMessage: 'This promo is not valid',
  },
});
