import GET_COURSE_LECTURES from 'queries/Courses/getCourseLectures.gql';

export const Lecture = {
  isVideoUploading: async ({ id: lectureId }, { id: courseId }, { cache }) => {
    const queryData = {
      query: GET_COURSE_LECTURES,
      variables: {
        input: {
          id: courseId,
        }
      },
    };

    try {
      const getCourseQuery = await cache.readQuery(queryData);

      const currentLection = getCourseQuery.getCourse.lectures.find(
        ({ id }) => id === lectureId
      );

      return currentLection.isVideoUploading;
    } catch (error) {
      return false;
    }
  },
};
