// Core
import React, { PureComponent } from 'react';

import { compose, graphql } from 'react-apollo';
import PropTypes from 'prop-types';

// Components
import Calendar from 'containers/Calendar';

// GraphQl
import GET_CALENDAR_EVENTS from 'queries/Calendar/getCalendarEvents.gql';

// Other
import { withAuthenticatedUser } from 'containers/AuthenticatedUserProvider/withAuthenticatedUser';

const StudentCalendar = ({
  userContext: { profile },
  calendarEvents,
  ...props
}) => {
  return (
    <Calendar
      profile={profile}
      role="student"
      courses="ACTIVE"
      calendarEvents={calendarEvents}
    />
  );
}

const getCalendarEventsQuery = graphql(GET_CALENDAR_EVENTS, {
  props: ({ data: { loading, error, calendarEvents, ...ownProps } }) => ({
    loading,
    calendarEvents,
    error,
    ...ownProps,
  }),
  options: () => ({
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        CourseId: 'ALL',
        TimeFromUnixMs: new Date().getTime() - 100000000000,
        TimeToUnixMs: new Date().getTime() + 100000000000,
      },
    },
  }),
});

const enhancer = compose(
  withAuthenticatedUser,
  getCalendarEventsQuery,
);

export default enhancer(StudentCalendar);
