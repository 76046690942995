import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  students: {
    id: 'app.components.HomeworksTable.students',
    defaultMessage: 'Students',
  },
  people: {
    id: 'app.components.HomeworksTable.people',
    defaultMessage: 'people',
  },
  noStudentsFound: {
    id: 'app.components.HomeworksTable.noStudentsFound',
    defaultMessage: 'No students found',
  },
  name: {
    id: 'app.components.HomeworksTable.name',
    defaultMessage: 'Name',
  },
  avrgMarks: {
    id: 'app.components.HomeworksTable.avrgMarks',
    defaultMessage: 'Average Marks',
  },
  homework: {
    id: 'app.components.HomeworksTable.homework',
    defaultMessage: 'Homework for Concierge level',
  },
});
