// Core
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import { Formik } from 'formik';
import { FormattedMessage } from 'react-intl';

// Components
import AccountBankDetailsForm from 'routes/ManageAccount/AccountBankDetails/AccountBankDetailsForm';
import ContentHeader from 'containers/ContentHeader';
import Card from 'components/Card';
import Button from 'components/Button';
import Text from 'components/Text';
import { withAuthenticatedUser } from 'containers/AuthenticatedUserProvider/withAuthenticatedUser';

// GraphQL
import MY_PROFILE_QUERY from 'queries/UserProfile/getProfile.gql';
import UPDATE_PROFILE from 'mutations/UserProfile/updateProfile.gql';

// Other
import { keysToSnake } from 'utils/helpers';
import { getYupSchema } from 'services/yup';
import { hasLoader } from 'containers/HOCs/hasLoader';
import { withNotification } from 'containers/NotificationProvider/withNotification';
import { messages as yupMessages } from 'services/yup/messages';
import { messages } from 'routes/ManageAccount/AccountBankDetails/messages';

// Styles and Assets
import './AccountBankDetails.scss';

const AccountBankDetails = ({
  userContext: { profile = {} },
  updateProfile,
  showNotificationBar,
}) => {

  const handleSubmit = async values => {
    try {
      const {
        paymentAccountEmail,
        paymentSystem,
        country,
        city,
        accountType,
        postcode,
        address,
        email,
        accountNumber,
        swift,
        recipientName,
      } = values;
      const input = {};
      if (paymentAccountEmail && paymentAccountEmail.length > 0)
        input.paymentAccountEmail = paymentAccountEmail;
      if (paymentSystem && paymentSystem.length > 0)
        input.paymentSystem = paymentSystem;

      if (paymentSystem === 'wire') {
        input.wireTransfer = {};
        input.wireTransfer.accountType = accountType;
        if (recipientName) input.wireTransfer.recipientName = recipientName;
        if (postcode) input.wireTransfer.postcode = postcode;
        if (address) input.wireTransfer.address = address;
        if (email) input.wireTransfer.email = email;
        if (swift) input.wireTransfer.swift = swift;
        if (accountNumber) input.wireTransfer.accountNumber = accountNumber;
        if (city) input.wireTransfer.city = city;
        if (country) input.wireTransfer.country = country?.id;
      }

      await updateProfile({ ...keysToSnake(input) });
      showNotificationBar('saved');
    } catch (error) {
      throw Error(error);
    }
  };

  const formFields = {
    paymentAccountEmail: profile?.paymentAccountEmail || '',
    paymentSystem: profile?.paymentSystem || '',
    accountType: profile?.wireTransfer?.accountType || true,
    country: profile?.wireTransfer?.country?.id
      ? profile?.wireTransfer?.country
      : '',
    city: profile?.wireTransfer?.city || '',
    accountNumber: profile?.wireTransfer?.accountNumber || '',
    recipientName: profile?.wireTransfer?.recipientName || '',
    email: profile?.wireTransfer?.email || '',
    address: profile?.wireTransfer?.address || '',
    postcode: profile?.wireTransfer?.postcode || '',
    swift: profile?.wireTransfer?.swift || '',
  };

  return (
    <div className="sk-ac-bank-details">
      <ContentHeader />
      <Card classes="sk-ac-bank-details__card">
        <Formik
          initialValues={formFields}
          validateOnChange={false}
          validateOnBlur
          render={props => (
            <>
              <Text className="sk-ac-bank-details__card__title">
                <FormattedMessage {...messages.pleaseAddYourBankAccount} />
              </Text>
              <AccountBankDetailsForm {...props} />
              <Button
                variant="text"
                className="sk-ac-bank-details__btn"
                color="secondary"
                size="large"
                onClick={async () => await handleSubmit(props.values)}
              >
                <FormattedMessage {...yupMessages.save} />
              </Button>
            </>
          )}
        />
      </Card>
    </div>
  );
};

const updateProfileMutation = graphql(UPDATE_PROFILE, {
  props: ({ mutate }) => ({
    updateProfile: input => mutate({ variables: { input } }),
  }),
  options: {
    refetchQueries: ['getProfile'],
  },
});

const enhancer = compose(
  memo,
  withAuthenticatedUser,
  updateProfileMutation,
  withNotification
);

export default hasLoader(enhancer(AccountBankDetails), MY_PROFILE_QUERY);
