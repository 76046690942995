import React from 'react';

import './index.scss';

const BlockquoteOnHover = () => (
  <svg
    className="hover"
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.692383" width="32" height="32" rx="2" fill="white" />
    <path
      d="M24.074 22.74C24.144 22.908 24.179 23.048 24.179 23.16C24.179 23.426 24.067 23.65 23.843 23.832C23.633 24.014 23.395 24.105 23.129 24.105C22.947 24.105 22.772 24.056 22.604 23.958C22.45 23.846 22.331 23.692 22.247 23.496L20.861 20.346H13.175L11.789 23.496C11.705 23.692 11.579 23.846 11.411 23.958C11.243 24.056 11.068 24.105 10.886 24.105C10.606 24.105 10.354 24.014 10.13 23.832C9.92004 23.65 9.81504 23.426 9.81504 23.16C9.81504 23.048 9.85004 22.908 9.92004 22.74L15.842 9.783C15.94 9.559 16.094 9.384 16.304 9.258C16.528 9.132 16.759 9.069 16.997 9.069C17.235 9.069 17.459 9.132 17.669 9.258C17.893 9.384 18.054 9.559 18.152 9.783L24.074 22.74ZM20.105 18.624L17.018 11.673L13.952 18.624H20.105Z"
      fill="#30d5c8"
    />
  </svg>
);

const DefaultBlockquote = () => (
  <svg
    className="default"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.074 22.74C24.144 22.908 24.179 23.048 24.179 23.16C24.179 23.426 24.067 23.65 23.843 23.832C23.633 24.014 23.395 24.105 23.129 24.105C22.947 24.105 22.772 24.056 22.604 23.958C22.45 23.846 22.331 23.692 22.247 23.496L20.861 20.346H13.175L11.789 23.496C11.705 23.692 11.579 23.846 11.411 23.958C11.243 24.056 11.068 24.105 10.886 24.105C10.606 24.105 10.354 24.014 10.13 23.832C9.92004 23.65 9.81504 23.426 9.81504 23.16C9.81504 23.048 9.85004 22.908 9.92004 22.74L15.842 9.783C15.94 9.559 16.094 9.384 16.304 9.258C16.528 9.132 16.759 9.069 16.997 9.069C17.235 9.069 17.459 9.132 17.669 9.258C17.893 9.384 18.054 9.559 18.152 9.783L24.074 22.74ZM20.105 18.624L17.018 11.673L13.952 18.624H20.105Z"
      fill="#30d5c8"
    />
  </svg>
);

const ActiveBlockquote = () => (
  <svg
    className="active"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.074 22.74C24.144 22.908 24.179 23.048 24.179 23.16C24.179 23.426 24.067 23.65 23.843 23.832C23.633 24.014 23.395 24.105 23.129 24.105C22.947 24.105 22.772 24.056 22.604 23.958C22.45 23.846 22.331 23.692 22.247 23.496L20.861 20.346H13.175L11.789 23.496C11.705 23.692 11.579 23.846 11.411 23.958C11.243 24.056 11.068 24.105 10.886 24.105C10.606 24.105 10.354 24.014 10.13 23.832C9.92004 23.65 9.81504 23.426 9.81504 23.16C9.81504 23.048 9.85004 22.908 9.92004 22.74L15.842 9.783C15.94 9.559 16.094 9.384 16.304 9.258C16.528 9.132 16.759 9.069 16.997 9.069C17.235 9.069 17.459 9.132 17.669 9.258C17.893 9.384 18.054 9.559 18.152 9.783L24.074 22.74ZM20.105 18.624L17.018 11.673L13.952 18.624H20.105Z"
      fill="#30d5c8"
    />
  </svg>
);

const Blockquote = ({ active }) => {
  const Icon = active ? ActiveBlockquote : DefaultBlockquote;

  return (
    <div className="editor-icon-svg">
      <Icon />
      <BlockquoteOnHover />
    </div>
  );
};

export default Blockquote;
