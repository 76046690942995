import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const SCREENS = {
  xlg: '(min-width: 1366px)',
  lg: '(min-width: 1024px) and (max-width: 1366px)',
  md: '(min-width: 767px) and (max-width: 1023px)',
  sm: '(min-width: 480px) and (max-width: 767px)',
  xs: '(max-width: 480px)',
};

export default (size = 'xlg') => WrappedComponent => {
  if (window.isServer) {
    return props => <WrappedComponent {...props} width={size} />;
  };

  return props => {
    Object.keys(SCREENS).forEach((resolution, i) => {
      const isMatched = useMediaQuery(SCREENS[resolution]);
      if (isMatched) {
        size = resolution;
      };
    });
    return <WrappedComponent {...props} width={size} />;
  };
};
