import React from 'react';

import './index.scss';

const Video = ({ src }) => (
  <div className="sk-editor-video-block">
    <embed src={src} />
  </div>
);

export default Video;
