import React from 'react';
import { FormattedMessage } from 'react-intl';
import { messages } from 'routes/ExpertsCabinet/ToppingEdit/messages';

export const TOPPINGS_TYPES_OPTIONS = [
  {
    value: 'masterclass',
    label: <FormattedMessage {...messages.masterClassOption} />,
  },
  {
    value: 'podcast',
    label: <FormattedMessage {...messages.podcastOption} />,
  },
];

export const DEFAULT_VARIANTS = {
  masterclass: {
    name: 'masterclass',
    features: [],
    permissions: null
  },
  lecture: {
    name: 'lecture',
    features: [],
    permissions: null
  },
  podcast: {
    name: 'podcast',
    features: [],
    permissions: null
  },
};
export const DEFAULT_VARIANT_NAME = Object.keys(DEFAULT_VARIANTS)[0];

export const DEFAULT_TOPPING = {
  title: '',
  type: 'topping',
	coursePhoto: '',
	coursePreview: '',
	lectureVideo: '',
	lectureQuiz: '',
	description: '',
	language: 'EN',
	price: DEFAULT_VARIANTS[DEFAULT_VARIANT_NAME].price,
};

export const TOPPINGS_STATUSES_OPTIONS = [
  {
    value: 'DRAFT',
    label: <FormattedMessage {...messages.draftLabel} />,
  },
  {
    value: 'FIRST_REVIEW',
    label: <FormattedMessage {...messages.inReviewLabel} />,
  },
  {
    value: 'READY_TO_PUBLISHING',
    label: <FormattedMessage {...messages.approvedLabel} />,
  },
  {
    value: 'FIRST_REJECT',
    label: <FormattedMessage {...messages.rejectedLabel} />,
  },
  {
    value: 'DEACTIVATED',
    label: <FormattedMessage {...messages.deactivatedLabel} />,
  },
  {
    value: 'ACTIVE',
    label: <FormattedMessage {...messages.activedLabel} />,
  },
];

export const DRAFT = 'DRAFT';
export const FIRST_REVIEW = 'FIRST_REVIEW';
export const READY_TO_PUBLISHING = 'READY_TO_PUBLISHING';
export const FIRST_REJECT = 'FIRST_REJECT';
export const DEACTIVATED = 'DEACTIVATED';
export const DEACTIVATEDTOPPING = 'DEACTIVATEDTOPPING';
export const ACTIVE = 'ACTIVE';
export const REACTIVATED = 'REACTIVATED';

export const TOPPING_NEXT_STATUSES_EXPERT = {
  [DRAFT]: FIRST_REVIEW,
  [READY_TO_PUBLISHING]: ACTIVE,
  [ACTIVE]: DEACTIVATED,
  [DEACTIVATED]: REACTIVATED,
  [DEACTIVATEDTOPPING]: REACTIVATED,
};

export const TOPPING_NEXT_STATUSES_ADMIN = {
  [FIRST_REVIEW]: READY_TO_PUBLISHING,
};

export const TOPPING_NEXT_REJECTED_STATUSES_ADMIN = {
  [FIRST_REVIEW]: FIRST_REJECT,
};

export const FIELDS_MAPPING = {
  title: 'title',
  description: 'description',
  coursePreview: 'coursePreview',
  coursePhoto: 'coursePhoto',
  language: 'language',
  price: 'variants',
  type: 'variants',
  recommendationFromAdmin: 'recomendationFromAdmin',
};
