// Core
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid';
import { graphql, compose } from 'react-apollo';
import { withRouter } from 'react-router-dom';

// Components
import Loader from 'components/Loader';
import LecturesCounter from 'routes/Course/components/LecturesCounter';
import LecturesList from 'routes/Course/components/LecturesList';
import AddEntityButton from 'routes/Course/components/AddEntityButton';
import CourseGrid from 'routes/Course/components/CourseGrid';

// GraphQL
import GET_COURSE_LECTURES from 'queries/Courses/getCourseLectures.gql';
import CREATE_LECTURE from 'mutations/Lecture/createLecture.gql';
import UPDATE_LECTURE from 'mutations/Lecture/updateLecture.gql';
import DELETE_LECTURE from 'mutations/Lecture/deleteLecture.gql';

// Other
import { isNull, isBlank, isArray, isEmptyArray } from 'utils/helpers/index';
import { withNotification } from 'containers/NotificationProvider/withNotification';
import { FormattedMessage, injectIntl } from 'react-intl';
import { messages } from 'routes/ExpertsCabinet/CourseEdit/Details/messages';

// Styles and Assets
import './CourseStructure.scss';

const getCourseRefetchOptions = () => ({
  match: {
    params: { id },
  },
}) => ({
  refetchQueries: [
    {
      query: GET_COURSE_LECTURES,
      variables: {
        input: { id },
      },
    },
  ],
});

class CourseStructure extends PureComponent {
  state = {
    addLectureLock: false,
    removeLecutreLock: false,
    counter: 0,
  };

  getLastLectureOrderNumber = lectures => {
    const lastLecture = lectures[lectures.length - 1];
    return lastLecture.orderNumber;
  };

  handleAddingNewLecture = () => {
    const { addLectureLock, counter: cachedCounter } = this.state;
    if (addLectureLock) {
      return;
    }
    const {
      createLecture,
      intl,
      course: { id },
      lectures,
      refetchCourse,
    } = this.props;

    const nextOrderNumber = lectures?.length
      ? this.getLastLectureOrderNumber(lectures) + 1
      : 0;
    const actualOrderNumber =
      nextOrderNumber > cachedCounter ? nextOrderNumber : cachedCounter + 1;

    this.setState(
      { addLectureLock: true, counter: actualOrderNumber },
      async () => {
        const { counter } = this.state;
        const newLectureId = uuid();

        try {
          await createLecture({
            title: intl.formatMessage(messages.newLecture),
            course_id: id,
            id: newLectureId,
            order_number: isNull(lectures) ? 1 : lectures.length + 1,
          });
          await refetchCourse();
          this.setState({ addLectureLock: false });
        } catch (error) {
          this.setState({ addLectureLock: false });
          throw Error(error);
        }
      }
    );
  };

  handleLectureUpdate = async (lectureId, input) => {
    const {
      updateLecture,
      showNotificationBar,
      course: { id },
    } = this.props;

    try {
      await updateLecture({
        course_id: id,
        lecture_id: lectureId,
        ...input,
      });
      showNotificationBar('saved');
    } catch (error) {
      throw Error(error);
    }
  };

  handleLectureDelete = async lectureId => {
    const {
      deleteLecture,
      course: { id },
      refetchCourse,
    } = this.props;
    const { removeLecutreLock } = this.state;

    if (removeLecutreLock) return;
    this.setState({ removeLecutreLock: true });

    try {
      await deleteLecture({
        course_id: id,
        lecture_id: lectureId,
      });
      await refetchCourse();
      this.setState({ removeLecutreLock: false });
    } catch (error) {
      this.setState({ removeLecutreLock: false });
      throw Error(error);
    }
  };

  render() {
    const {
      course: { id, displayLectureNameType, variants },
      lectures,
      disableAll,
      disableDeletion,
      refetchCourse,
      loading,
    } = this.props;

    const courseFeatures = (isArray(variants) && !isEmptyArray(variants)) ? variants[variants.length - 1]?.features : [];

    if (loading) return <Loader center />;

    return (
      <div className="sk-course-structure">
        <div className="sk-course-structure__content">
          <CourseGrid>
            <header className="sk-course-structure__header">
              <LecturesCounter
                lectures={lectures}
                displayLectureNameType={displayLectureNameType}
                refetchCourse={refetchCourse}
              />
            </header>
          </CourseGrid>

          {lectures?.length ? (
            <div className="sk-course-structure__content">
              <LecturesList
                disableDeletion={disableDeletion}
                disabled={disableAll}
                courseId={id}
                lectures={lectures}
                courseFeatures={courseFeatures}
                displayLectureNameType={displayLectureNameType}
                refetchCourse={refetchCourse}
                handleLectureUpdate={this.handleLectureUpdate}
                handleLectureDelete={this.handleLectureDelete}
              />
            </div>
          ) : null}

          <CourseGrid>
            <div className="sk-course-structure__footer">
              <FormattedMessage {...messages.addLecture}>
                {msg => (
                  <AddEntityButton
                    label={msg}
                    handleAddClick={this.handleAddingNewLecture}
                  />
                )}
              </FormattedMessage>
            </div>
          </CourseGrid>
        </div>
      </div>
    );
  }
}

const getCourseLecturesQuery = graphql(GET_COURSE_LECTURES, {
  skip: ({ course }) => !course?.id,
  props: ({ data: { getCourse, error, loading, ...ownProps } }) => {
    let lectures = [];

    if (!error && !loading) lectures = getCourse?.lectures;

    return {
      lectures,
      loading,
      error,
      ...ownProps,
    };
  },

  options: ({
    match: {
      params: { id },
    },
  }) => ({
    pollInterval: 600000,
    variables: { input: { id } },
    fetchPolicy: 'network-only'
  }),
});

const createLectureMutation = graphql(CREATE_LECTURE, {
  props: ({ mutate }) => ({
    createLecture: input => mutate({ variables: { input } }),
  }),
  options: getCourseRefetchOptions(),
});

const updateLectureMutation = graphql(UPDATE_LECTURE, {
  props: ({ mutate }) => ({
    updateLecture: input => mutate({ variables: { input } }),
  }),
  options: getCourseRefetchOptions(),
});

const deleteLectureMutation = graphql(DELETE_LECTURE, {
  props: ({ mutate }) => ({
    deleteLecture: input => mutate({ variables: { input } }),
  }),
  options: getCourseRefetchOptions(),
});

CourseStructure.propTypes = {
  course: PropTypes.object,
  deleteLecture: PropTypes.func,
  disableAll: PropTypes.bool,
  disableDeletion: PropTypes.bool,
  createLecture: PropTypes.func,
  intl: PropTypes.object,
};

const enhancer = compose(
  withRouter,
  withNotification,
  getCourseLecturesQuery,
  updateLectureMutation,
  createLectureMutation,
  deleteLectureMutation,
  injectIntl
);

export default enhancer(CourseStructure);
