import Auth from "@aws-amplify/auth";
import Storage from "@aws-amplify/storage";
import {
  AWS_REGION,
  S3_BUCKET_USERFILES,
  S3_BUCKET_PREVIEWS,
  S3_BUCKET_HOMEWORKS,
  S3_BUCKET_VIDEOS,
  S3_BUCKET_AUDIO,
  S3_BUCKET_ARTICLES,
  S3_BUCKET_QUIZES,
} from "services/aws/amplify/exports";

const getTimeStamp = () => new Date().getTime();

export const getSeparatedFilename = (name) => {
  const fileNameSubs = name.split(".");

  return {
    ext: fileNameSubs.pop().toLowerCase(),
    fileName: fileNameSubs.join("."),
  };
};

export const getCurrentSession = async () => {
  const session = await Auth.currentSession();
  return session;
};

export const getCurrentCredentials = async () => {
  const credentials = await Auth.currentCredentials();
  return credentials;
};

export const signOutUser = async () => {
  try {
    await Auth.signOut();
  } catch (error) {
    throw Error(error);
  }
};

export const confirmEmail = async (username, code) => {
  await Auth.confirmSignUp(username, code);
};

// export async function s3UploadAvatar(fileList) {
export async function s3UploadAvatar({ file, progressCallback = () => {} }) {
  const fileType = file.type === "image/jpeg" ? "jpg" : "png";

  const stored = await Storage.put(`images/avatar.${fileType}`, file, {
    contentType: file.type,
    level: "protected",
    bucket: S3_BUCKET_USERFILES,
    progressCallback: (progress) =>
      progressCallback(Math.ceil((progress.loaded * 100) / progress.total)),
  });

  return stored.key;
}

// export async function s3UploadAvatar(fileList) {
export async function s3UploadCompanyImage({
  file,
  companyId,
  progressCallback = () => {},
}) {
  const fileType = file.type === "image/jpeg" ? "jpg" : "png";

  const stored = await Storage.put(`images/company:${companyId}.${fileType}`, file, {
    contentType: file.type,
    level: "protected",
    bucket: S3_BUCKET_USERFILES,
    progressCallback: (progress) =>
      progressCallback(Math.ceil((progress.loaded * 100) / progress.total)),
  });

  return stored.key;
}

// Get and chop S3 URL by filekey and bucket name
export async function getStorageVanillaUrl({ key, bucket }) {
  try {
    const objectUrl = await Storage.get(key, {
      bucket: bucket,
    });

    return objectUrl.split("?").shift();
  } catch (err) {
    return null;
  }
}

export async function s3UploadPublicCoursePhoto(fileList, id) {
  const file = fileList[0];
  const fileType = file.type === "image/jpeg" ? "jpg" : "png";

  const stored = await Storage.put(
    `courses/course-photo/${id}-${getTimeStamp()}.${fileType}`,
    file,
    {
      contentType: file.type,
      level: "public",
      bucket: S3_BUCKET_USERFILES,
    }
  );

  return await getStorageVanillaUrl({
    key: stored.key,
    bucket: S3_BUCKET_USERFILES,
  });
}

export async function s3UploadCoursePreview({
  uploadId,
  file,
  filename,
  courseId,
  progressCallback = () => {},
}) {
  const { type } = file;
  const path = `courses/${courseId}/preview/${uploadId}/${filename}`;

  const stored = await Storage.vault.put(path, file, {
    bucket: S3_BUCKET_PREVIEWS,
    contentType: type,
    progressCallback: (progress) =>
      progressCallback(
        Math.ceil((progress.loaded * 100) / progress.total),
        uploadId
      ),
  });

  return `https://s3.eu-central-1.amazonaws.com/${S3_BUCKET_PREVIEWS}/public/${stored.key}`;
}

export async function s3UploadHomework({
  uploadId,
  file,
  filename,
  courseId,
  lectureId,
  progressCallback = () => {},
}) {
  const { type } = file;
  const path = `courses/${courseId}/lectures/${lectureId}/homeworks/${uploadId}/${filename}`;

  const stored = await Storage.put(path, file, {
    bucket: S3_BUCKET_HOMEWORKS,
    contentType: type,
    progressCallback: (progress) =>
      progressCallback(
        Math.ceil((progress.loaded * 100) / progress.total),
        uploadId
      ),
    level: "protected",
  });

  return stored.key;
}

export async function s3UploadLectureArticle({
  uploadId,
  file,
  filename,
  courseId,
  lectureId,
  progressCallback,
}) {
  const { type } = file;
  const path = `courses/${courseId}/lectures/${lectureId}/articles/${uploadId}/${filename}`;

  const stored = await Storage.put(path, file, {
    bucket: S3_BUCKET_ARTICLES,
    contentType: type,
    progressCallback: (progress) =>
      progressCallback(
        Math.ceil((progress.loaded * 100) / progress.total),
        uploadId
      ),
    level: "private",
  });

  return stored.key;
}

export async function s3UploadLectureVideo({
  uploadId,
  file,
  filename,
  courseId,
  lectureId,
  progressCallback,
}) {
  const { type } = file;
  const path = `courses/${courseId}/lectures/${lectureId}/videos/${uploadId}/${filename}`;

  const stored = await Storage.vault.put(path, file, {
    bucket: S3_BUCKET_VIDEOS,
    progressCallback: (progress) =>
      progressCallback(
        Math.ceil((progress.loaded * 100) / progress.total),
        uploadId
      ),
  });

  return stored.key;
}

export async function s3UploadLectureAudio({
  uploadId,
  file,
  filename,
  courseId,
  lectureId,
  progressCallback,
}) {
  const { type } = file;
  const path = `courses/${courseId}/lectures/${lectureId}/audios/${uploadId}/${filename}`;

  const stored = await Storage.vault.put(path, file, {
    bucket: S3_BUCKET_AUDIO,
    progressCallback: (progress) =>
      progressCallback(
        Math.ceil((progress.loaded * 100) / progress.total),
        uploadId
      ),
  });

  return stored.key;
}

export async function s3UploadHomeworkAnswer({
  uploadId,
  file,
  filename,
  studentId,
  courseId,
  lectureId,
  progressCallback,
}) {
  const { type } = file;
  const path = `courses/${courseId}/lectures/${lectureId}/homeworks/${studentId}/answer/${uploadId}/${filename}`;

  const stored = await Storage.vault.put(path, file, {
    bucket: S3_BUCKET_HOMEWORKS,
    contentType: type,
    progressCallback: (progress) =>
      progressCallback(
        Math.ceil((progress.loaded * 100) / progress.total),
        uploadId
      ),
    level: "protected",
  });

  return stored.key;
}

export async function s3UploadHomeworkReview({
  uploadId,
  file,
  filename,
  courseId,
  lectureId,
  studentId,
  progressCallback,
}) {
  const { type } = file;
  const path = `courses/${courseId}/lectures/${lectureId}/homeworks/${studentId}/review/${uploadId}/${filename}`;

  const stored = await Storage.put(path, file, {
    bucket: S3_BUCKET_HOMEWORKS,
    contentType: type,
    level: "protected",
    progressCallback: (progress) =>
      progressCallback(
        Math.ceil((progress.loaded * 100) / progress.total),
        uploadId
      ),
  });

  return stored.key;
}

export async function s3UploadQuizQuestionImage({
  uploadId,
  file,
  quizId,
  refId,
  progressCallback,
}) {
  const { type } = file;
  const path = `quiz/${quizId}/questions/${refId}/${uploadId}`;

  const payload = {
    bucket: S3_BUCKET_QUIZES,
    contentType: type,
    level: "protected",
    progressCallback: (progress) =>
      progressCallback(
        Math.ceil((progress.loaded * 100) / progress.total),
        uploadId
      ),
  };

  const stored = await Storage.put(path, file, payload);

  return stored.key;
}

export async function s3UploadQuizAnswerImage({
  uploadId,
  file,
  quizId,
  questionId,
  refId,
  progressCallback,
}) {
  const { type } = file;
  const path = `quiz/${quizId}/questions/${questionId}/answers/${refId}/${uploadId}`;

  const payload = {
    bucket: S3_BUCKET_QUIZES,
    contentType: type,
    level: "protected",
    progressCallback: (progress) =>
      progressCallback(
        Math.ceil((progress.loaded * 100) / progress.total),
        uploadId
      ),
  };

  const stored = await Storage.put(path, file, payload);

  return stored.key;
}
