// Core
import React, { memo, useState, useCallback } from "react";
import { withRouter } from "react-router";

// Components
import Text from "components/Text";
import MenuManageAccount from "components/Icon/Svg/MenuManageAccount";
import SimpleSidebarMenu from "containers/Sidebar/SidebarContent/SimpleSidebarMenu";
import SidebarMenuItem from "containers/Sidebar/SidebarContent/SidebarMenu/SidebarMenuItem";
import SidebarMenu from "containers/Sidebar/SidebarContent/SidebarMenu";
import SignOut from "components/Icon/Svg/SignOut";
import Image from "components/Image";

// Styles and Assets
import "./ManageAccountSidebar.scss";

// Other
import { ADMIN, EXPERT, OWNER, MANAGER } from "utils/enums";
import { isNull } from "utils/helpers/index";

import { FormattedMessage } from "react-intl";
import { messages } from "containers/Sidebar/SidebarContent/ManageAccountSidebar/messages";

const sortSubItems = (item1, item2) => {
  return item1.sortNumber - item2.sortNumber;
};

const SUB_MENU_ITEMS = [
  {
    sortNumber: 1,
    id: "sub-000",
    path: "/manage-account/company",
    roles: [OWNER, ADMIN, MANAGER],
    label: <FormattedMessage {...messages.company} />,
  },
  {
    sortNumber: 1,
    id: "sub-001",
    path: "/manage-account/personal-info",
    label: <FormattedMessage {...messages.personalInfo} />,
  },
  {
    sortNumber: 4,
    id: "sub-002",
    path: "/manage-account/pw-change",
    label: <FormattedMessage {...messages.changePassword} />,
    userProviders: [undefined, "email"],
  },
  {
    sortNumber: 5,
    id: "sub-003",
    path: "/manage-account/account-delete",
    label: <FormattedMessage {...messages.deleteAccount} />,
  },
];
const MANAGE_ACCOUNT_SUB_MENU_ITEMS_EXPERT = [
  {
    sortNumber: 2,
    id: "sub-006",
    path: "/manage-account/exp-agreement",
    roles: [EXPERT],
    label: <FormattedMessage {...messages.expertsAgreement} />,
  },
  {
    sortNumber: 3,
    id: "sub-007",
    path: "/manage-account/bank-details",
    label: <FormattedMessage {...messages.bankDetails} />,
  },
];

const ANALYTICS_SUB_MENU_ITEMS_EXPERT = [
  {
    sortNumber: 0,
    id: "sub-008",
    path: "/expert-analytics/active-students",
    label: <FormattedMessage {...messages.activeStudents} />,
  },
  {
    sortNumber: 0,
    id: "sub-009",
    path: "/expert-analytics/sales",
    label: <FormattedMessage {...messages.sales} />,
  },
];

const MENU_ITEMS = [
  {
    id: "menu-007",
    icon: MenuManageAccount,
    path: "/manage-account",
    label: <FormattedMessage {...messages.manageAccount} />,
    withoutBorder: true,
  },
];

const EXPERT_MENU_ITEMS = [
  {
    id: "menu-008",
    path: "/expert-analytics",
    icon: () => <Image src="/assets/images/account-analytics.svg" />,
    label: <FormattedMessage {...messages.accountAnalytics} />,
    withoutBorder: true,
  },
];

const getProviderBasedSubitems = (subitem, userProvider) => {
  const { userProviders } = subitem;

  if (!userProviders) {
    return true;
  }
  if (Array.isArray(userProviders) && userProviders.includes(userProvider)) {
    return true;
  }
  return false;
};

const getItems = ({ userRole, userProvider }) => {
  let menuItems = MENU_ITEMS;
  let manageAccountSubItems = SUB_MENU_ITEMS;

  if (!userRole || isNull(userRole)) return menuItems;

  if (userRole === EXPERT || userRole === OWNER || userRole === MANAGER) {
    menuItems = [...MENU_ITEMS, ...EXPERT_MENU_ITEMS];
    manageAccountSubItems = [
      ...SUB_MENU_ITEMS,
      ...MANAGE_ACCOUNT_SUB_MENU_ITEMS_EXPERT,
    ];
  }

  menuItems = menuItems.map((menuItem) => {
    if (menuItem.id === "menu-007") {
      menuItem.subItems = manageAccountSubItems
        .filter((subitem) => getProviderBasedSubitems(subitem, userProvider))
        .sort(sortSubItems);
    }
    if (menuItem.id === "menu-008")
      menuItem.subItems = ANALYTICS_SUB_MENU_ITEMS_EXPERT;
    return menuItem;
  });

  return menuItems;
};

const PATH_MENU_ID = {
  "manage-account": "menu-007",
  "expert-analytics": "menu-008",
};
const getOpenedMenuId = ({ pathname }) => {
  let initialOpennedMenu = "menu-007";
  Object.keys(PATH_MENU_ID).forEach((menuPath) => {
    if (pathname.includes(menuPath))
      initialOpennedMenu = PATH_MENU_ID[menuPath];
  });

  return initialOpennedMenu;
};

const ManageAccountSidebar = memo(
  ({ history: { location }, signOut, user }) => {
    const initialOpennedMenu = getOpenedMenuId(location);
    const [opennedMenuId, setOpenedMenuId] = useState(initialOpennedMenu);
    const menuItems = getItems({
      userRole: user?.role,
      userProvider: user?.provider,
    });

    const onMenuClick = useCallback(
      (menuItemId) => () => {
        const nextOpennedItem = opennedMenuId === menuItemId ? "" : menuItemId;
        setOpenedMenuId(nextOpennedItem);
      },
      [opennedMenuId, setOpenedMenuId]
    );

    return (
      <div className="sk-manage-account-sidebar">
        <div className="sk-sidebar-block">
          {menuItems.map((menuItem) => {
            return (
              <div className="sk-sidebar-block__item">
                <SidebarMenuItem
                  icon={menuItem.icon}
                  label={menuItem.label}
                  expandable={true}
                  handleClick={onMenuClick(menuItem.id)}
                  open={menuItem.id === opennedMenuId}
                  togglerProps={{
                    handleOnTogglerClick: onMenuClick(menuItem.id),
                  }}
                >
                  {menuItem.id === opennedMenuId && (
                    <SimpleSidebarMenu
                      menuItems={menuItem.subItems}
                      role={user.role}
                    />
                  )}
                </SidebarMenuItem>
              </div>
            );
          })}
        </div>

        <div className="sk-manage-account-sidebar_footer">
          <button
            type="button"
            className="sk-manage-account-sidebar_btn"
            onClick={signOut}
          >
            <div className="sk-sidebar-block_icon">
              <SignOut />
            </div>
            <Text size="caption">
              <FormattedMessage {...messages.signOut} />
            </Text>
          </button>
        </div>
      </div>
    );
  }
);

export default withRouter(ManageAccountSidebar);
