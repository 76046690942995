// Core
import React, { memo } from 'react';
import { withRouter } from 'react-router-dom';

// Components
import TextInput from 'components/Input/Material/TextInput/TextInput';
import PasswordInput from 'components/Input/Material/PasswordInput';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import Text from 'components/Text';
import Link from 'components/Link';
import CheckboxLabel from 'components/CheckboxLabel';
import SocialLoginForm from 'routes/Authenticator/SocialLoginForm';
import { FormattedMessage } from 'react-intl';

import { messages } from 'routes/Authenticator/SignUpForm/messages';

// Other
import { withModal } from 'containers/ModalProvider/withModal';

const SignUpForm = memo(props => {
  const {
    values: {
      terms,
      email,
      password,
      confirmPassword,
      firstName,
      lastName,
    },
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    handleBlur,
    isLoading,
  } = props;

  const handleInputChange = name => (inputValue, e) => {
    setFieldValue(name, inputValue);
    if (e) e.persist();
  };

  const handleInputBlur = name => (inputValue, e) => {
    if (e) {
      e.persist();
      handleBlur(e);
    }
    setFieldValue(name, inputValue);
  };

  const handleEnterPress = (params1, params2) => {
    const charCode = params1?.charCode || params2?.charCode;
    if (charCode == 13) handleSubmit(props.values); // eslint-disable-line
  };

  return (
    <form
      className="sk-auth-content_form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <div className="sk-form__inner sk-auth-content_inner">
        <div className="sk-form__row">
          <FormattedMessage {...messages.firstName}>
            {msg => (
              <TextInput
                name="firstName"
                helperText={touched.firstName ? errors.firstName : ''}
                error={touched.firstName && Boolean(errors.firstName)}
                value={firstName}
                label={msg}
                placeholder={msg}
                onBlur={handleInputBlur('firstName')}
                onChange={handleInputChange('firstName')}
                onKeyPress={handleEnterPress}
              />
            )}
          </FormattedMessage>
          <FormattedMessage {...messages.lastName}>
            {msg => (
              <TextInput
                name="lastName"
                helperText={touched.lastName ? errors.lastName : ''}
                error={touched.lastName && Boolean(errors.lastName)}
                value={lastName}
                label={msg}
                placeholder={msg}
                onBlur={handleInputBlur('lastName')}
                onChange={handleInputChange('lastName')}
                onKeyPress={handleEnterPress}
              />
            )}
          </FormattedMessage>
        </div>
        <div className="sk-form__row">
          <FormattedMessage {...messages.email}>
            {msg => (
              <TextInput
                name="email"
                type="email"
                helperText={touched.email ? errors.email : ''}
                error={touched.email && Boolean(errors.email)}
                value={email}
                label={msg}
                placeholder={msg}
                onBlur={handleInputBlur('email')}
                onChange={handleInputChange('email')}
                onKeyPress={handleEnterPress}
              />
            )}
          </FormattedMessage>
        </div>

        <div className="sk-form__row">
          <FormattedMessage {...messages.password}>
            {msg => (
              <PasswordInput
                name="password"
                helperText={touched.password ? errors.password : ''}
                error={touched.password && Boolean(errors.password)}
                value={password}
                label={msg}
                placeholder={msg}
                autoComplete="new-password"
                onBlur={handleInputBlur('password')}
                onChange={handleInputChange('password')}
                onKeyPress={handleEnterPress}
              />
            )}
          </FormattedMessage>
          <FormattedMessage {...messages.confirmPassword}>
            {msg => (
              <PasswordInput
                name="confirmPassword"
                helperText={
                  touched.confirmPassword ? errors.confirmPassword : ''
                }
                error={
                  touched.confirmPassword && Boolean(errors.confirmPassword)
                }
                value={confirmPassword}
                label={msg}
                placeholder={msg}
                autoComplete="new-password"
                onBlur={handleInputBlur('confirmPassword')}
                onChange={handleInputChange('confirmPassword')}
                onKeyPress={handleEnterPress}
              />
            )}
          </FormattedMessage>
        </div>
      </div>

      <div className="sk-form_footer sk-auth-content_footer">
        <div className="sk-auth-content_terms">
          <Text classes="sk-auth-content_text">
            <FormattedMessage {...messages.bySigningUp} />
            <span className="sk-auth-content_links">
              <Link
                target="_blank"
                to="/:locale/terms-of-use"
                classes="sk-auth-content_link"
              >
                <FormattedMessage {...messages.termsOfUse} />
              </Link>{' '}
              <FormattedMessage {...messages.and} />{' '}
              <Link
                target="_blank"
                to="/:locale/privacy-policy"
                classes="sk-auth-content_link"
              >
                <FormattedMessage {...messages.privacyPolicy} />
              </Link>
            </span>
          </Text>
        </div>
        <Button
          variant="text"
          color="secondary"
          size="large"
          type="submit"
          loading={isLoading}
        >
          <FormattedMessage {...messages.createAcc} />
        </Button>

        <SocialLoginForm />
      </div>
    </form>
  );
});

export default withRouter(withModal(SignUpForm));
