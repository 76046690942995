// Core
import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';

// Components
import Heading from 'components/Heading';
import Text from 'components/Text';
import Done from 'components/Icon/Svg/Done';
import { messages } from './messages';

// Styles and Assets
import './index.scss';

export const SuccesfulMessage = ({ ...props }) => (
  <div className="sk-tr-success">
    <div className="sk-tr-success_header">
      <div className="sk-tr-success_icon">
        <Done width="51" height="51" />
      </div>
      <Heading variant="h4" classes="sk-tr-success_heading">
        <FormattedMessage {...messages.title} />
      </Heading>
    </div>
  </div>
);

export default memo(SuccesfulMessage);
