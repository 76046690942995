import React, { memo } from 'react';
import { compose } from 'react-apollo';
import { injectIntl } from 'react-intl';

// Components
import Text from 'components/Text';
import Button from 'components/Button';

// Other
import { withFormattedHtmlMessage } from 'containers/HOCs/withFormattedHtmlMessage';
import { messages } from 'routes/ExpertsCabinet/ExpertSubscription/messages.js';
import { sharedMessages } from 'services/i18n/sharedMessages/messages';

//Styles
import './index.scss';

const PREFIX = 'sk-experts-cabinet-success-commission-subscription';
const SuccessCommissionSubscription = ({
  price,
  handleClick,
  intl: { formatMessage },
  formatHtmlMessage,
}) => {
  return (
    <div className={PREFIX}>
      <Text>{formatMessage(messages.unlimCoursesTitle)}</Text>
      <Text size='small'>{formatHtmlMessage(messages.unlimCoursesSubTitle)}</Text>
      <Button
        size='large'
        color="secondary"
        onClick={handleClick}
      >
        {formatMessage(sharedMessages.understandButton)}
      </Button>
    </div>
  )
};

const enhancer = compose(
  memo,
  injectIntl,
  withFormattedHtmlMessage
);

export default enhancer(SuccessCommissionSubscription);
