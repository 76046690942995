// Core
import React, { useState, useEffect } from "react";
import { compose, graphql } from "react-apollo";

// Components
import Text from "components/Text";
import Star from "components/Icon/Svg/Star";

// Other
import {
  createdArray,
  arrayPusher,
} from "routes/StudentsCabinet/ToppingDetails/helper.js";
import { withNotification } from "containers/NotificationProvider/withNotification";

// GraphQL
import CREATE_RATE_COURSE from "mutations/StudentCabinet/createStudentRate.gql";
import END_COURSE from "mutations/Courses/studentCompletedCourse.gql";

// Styles

const COMPONENT_PREFIX = `sk-topping-details_rating-modal`;

const RateModal = ({
  prefix,
  courseId,
  studentRateCourse,
  showNotificationBar,
  completeCourse,
  handleClose,
}) => {
  const [starRating, setStarRating] = useState([]);

  useEffect(() => {
    if (starRating.length === 0) {
      const setEmptyStarRating = createdArray(5);
      setStarRating(setEmptyStarRating);
    }
  }, [starRating.length, setStarRating, createdArray]);

  const handleRatingStarMutattion = async (id, mark) => {
    try {
      await studentRateCourse({
        course_id: id,
        mark,
      });
    } catch (error) {
      throw Error(error);
    }
  };

  const mouseOverHandler = (id) => {
    const setHandledStarRating = arrayPusher(id, 0, starRating, "value");
    setStarRating(setHandledStarRating);
  };
  const mouseLeaveHandler = () => {
    const setHandledStarRating = arrayPusher(4, 1, starRating, "value");
    setStarRating(setHandledStarRating);
  };

  const handleRatingStar = async (id) => {
    const starRating = id + 1;
    await handleRatingStarMutattion(courseId, starRating);
    handleClose();
    showNotificationBar("saved");
  };

  const renderStarRating = (starRating) =>
    starRating.map((item) => (
      <button
        key={item.id}
        onClick={() => handleRatingStar(item.id)}
        onMouseOver={() => mouseOverHandler(item.id)}
        onMouseLeave={mouseLeaveHandler}
        type="button"
      >
        <Star active={starRating[item.id].value === 1} width="48" height="46" />
      </button>
    ));

  return (
    <div className={COMPONENT_PREFIX}>
      <Text className={`${COMPONENT_PREFIX}__text`}>
        Please rate the course
      </Text>
      <div className={`${COMPONENT_PREFIX}__stars-container`}>
        {!!starRating.length && renderStarRating(starRating)}
      </div>
    </div>
  );
};

const createRateCourseMutation = graphql(CREATE_RATE_COURSE, {
  props: ({ mutate }) => ({
    studentRateCourse: (input) => mutate({ variables: { input } }),
  }),
});

const completeCourseMutation = graphql(END_COURSE, {
  props: ({ mutate }) => ({
    completeCourse: (input) => mutate({ variables: { input } }),
  }),
  // TODO: Redo how student course is fetched
  // options: {
  //   refetchQueries: ['listStudentCoursesExtra']
  // }
});

const enhancer = compose(
  withNotification,
  createRateCourseMutation,
  completeCourseMutation
);

export default enhancer(RateModal);
