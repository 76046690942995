// Core
import React from 'react';

// Components
import Heading from 'components/Heading';
import Card from 'components/Card';
import Button from 'components/Button';
import Link from 'components/Link';
import AboutUsText from 'containers/AboutUsText';
import MetaData from 'components/MetaData';

// Styles and Assets
import './AboutUs.scss';

// Other
import { FormattedMessage } from 'react-intl';
import { messages } from 'routes/AboutUs/messages';

const AboutUs = () => (
  <div className="sk-about-us sk-main-layout-wrapper">
    <MetaData titleKey="aboutUsTitle" />
    <div className="sk-about-us__wrapper  sk-background-spot__secondary">
      <Card classes="sk-about-us__card">
        <div className="sk-about-us__content">
          <header className="sk-about-us__header">
            <Heading classes="sk-about-us__heading" variant="h2">
              <FormattedMessage {...messages.aboutUs} />
            </Heading>
          </header>
          <AboutUsText />
        </div>
        <div className="sk-about-us__footer">
          <Link href="/">
            <Button color="secondary" size="large" variant="text">
              <FormattedMessage {...messages.goToSkilleton} />
            </Button>
          </Link>
        </div>
      </Card>
    </div>
  </div>
);

export default AboutUs;
