import React from 'react';
import PropTypes from 'prop-types';
import { OrangeyRed } from 'components/Icon/color';

const Done = ({ height, width, color, strokeWidth }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 53 54"
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke={color}
      strokeWidth={strokeWidth}
      transform="translate(1 1.841)"
    >
      <circle cx="25.5" cy="25.5" r="25.5" />
      <path strokeLinecap="round" d="M16 26.79L24.514 35 40 16" />
    </g>
  </svg>
);

Done.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  color: PropTypes.string,
  strokeWidth: PropTypes.number,
};

Done.defaultProps = {
  height: '24',
  width: '24',
  strokeWidth: 2,
  color: OrangeyRed,
};

export default Done;
