// Core
import React, { memo, useState, useEffect } from 'react';
import { graphql, compose } from 'react-apollo';
import PropTypes from 'prop-types';

// Components
import Loader from 'components/Loader';
import TimezonesSelect from 'components/TimezonesSelect';

// GraphQL
import MY_PROFILE_QUERY from 'queries/UserProfile/getProfile.gql';
import UPDATE_PROFILE from 'mutations/UserProfile/updateProfile.gql';

// Other
import { hasLoader } from 'containers/HOCs/hasLoader';

// Styles
import './CalendarTimezonesSelect.scss';

export const handleTimeZoneChange = (
  updateProfile,
  setProfileLoadingState
) => async timezone => {
  if (!timezone) return;
  try {
    setProfileLoadingState(true);
    await updateProfile({ gmt_timezone: timezone?.id });
    setProfileLoadingState(false);
  } catch (error) {
    setProfileLoadingState(false);
    throw Error(error);
  }
};

export const CalendarTimezonesSelect = memo(
  ({ updateProfile, profile, loading }) => {
    const [timeZone, setTimeZone] = useState('');
    const [profileLoadingState, setProfileLoadingState] = useState(false);

    useEffect(() => {
      if (timeZone !== profile.gmt_timezone) {
        setTimeZone(profile.gmt_timezone);
        setProfileLoadingState(false);
      }
    });

    return (
      <div className="sk-calendar-timezones-select">
        <TimezonesSelect
          handleChange={handleTimeZoneChange(
            updateProfile,
            setProfileLoadingState
          )}
          value={timeZone}
          loading={loading}
        />

        {profileLoadingState && <Loader small overlay />}
      </div>
    );
  }
);

const updateProfileMutation = graphql(UPDATE_PROFILE, {
  props: ({ mutate }) => ({
    updateProfile: input => mutate({ variables: { input } }),
  }),
  options: {
    refetchQueries: ['getProfile'],
  },
});

const myProfileQuery = graphql(MY_PROFILE_QUERY, {
  props: ({ data: { getProfile: profile, error, loading, ...ownProps } }) => ({
    loading,
    profile,
    ...ownProps,
  }),
  options: () => ({
    fetchPolicy: 'cache-and-network',
  }),
});

const enhancer = compose(
  memo,
  myProfileQuery,
  updateProfileMutation
);

CalendarTimezonesSelect.propTypes = {
  getProfile: PropTypes.object,
  updateProfile: PropTypes.func,
};

export default hasLoader(enhancer(CalendarTimezonesSelect), MY_PROFILE_QUERY);
