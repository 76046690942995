// Core
import React from 'react';
import { graphql, compose } from 'react-apollo';
import { injectIntl, FormattedMessage } from 'react-intl';

// Components
import StatusBar from 'routes/ExpertsCabinet/ToppingEdit/ToppingInfo/StatusBar';
import ImageUploaderAndTypeSelector from 'routes/ExpertsCabinet/ToppingEdit/ToppingInfo/ImageUploaderAndTypeSelector';

import Button from 'components/Button';
import Card from 'components/Card';
import Text from 'components/Text';
import TextInput from 'components/Input/Material/TextInput';
import RadioButtonGroup from 'components/RadioButtonGroup';

// Other
import { isNull } from 'utils/helpers/index';
import { messages } from 'routes/ExpertsCabinet/ToppingEdit/messages';
import { sharedMessages } from 'services/i18n/sharedMessages/messages';
import { withCourseStatusManager } from 'routes/ExpertsCabinet/CourseEdit/HOCs/withCourseStatusManager';

// Styles
import './index.scss';

const prefix = 'sk-topping-edit-info';
const ToppingInfo = ({
  topping,
  disabledEditing,
  refetchTopping,
  handleToppingUpdate,
  updateTopping,
  intl: { formatMessage },
}) => {
  return (
    <Card>
      <div className={prefix}>
        <div className={`${prefix}__vertical-line`} />
        <StatusBar statusId={topping.statusId} type="topping" />
        <ImageUploaderAndTypeSelector
          disabledEditing={disabledEditing}
          topping={topping}
          updateTopping={updateTopping}
          handleToppingUpdate={handleToppingUpdate}
          refetchTopping={refetchTopping}
        />
      </div>
    </Card>
  );
};

const enhancer = compose(
  injectIntl,
  withCourseStatusManager,
);

export default enhancer(ToppingInfo);
