export const DEFAULT_VARIANTS = {
  flex: {
    name: 'flex',
    features: [],
    permissions: null
  },
  assist: {
    name: 'assist',
    features: ['webinars'],
    permissions: {
      lectures: 'all',
      homeworks: 'all',
    }
  },
  concierge: {
    name: 'concierge',
    features: ['webinars', 'homeworks'],
    permissions: {
      lectures: 'all',
      homeworks: 'all',
    }
  },
};
export const VARIANTS_NAMES = Object.keys(DEFAULT_VARIANTS);
