// Core
import React, { PureComponent } from 'react';
import { compose, graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Collapse from '@material-ui/core/Collapse';
import { withFormik } from 'formik';
import moment from 'moment';

// Components
import Loader from 'components/Loader';
import Card from 'components/Card';
import ContentHeader from 'containers/ContentHeader';
import Text from 'components/Text';
import Button from 'components/Button';
import TextInput from 'components/Input/Material/TextInput/TextInput';
import TextAreaInput from 'components/Input/Material/TextAreaInput/TextAreaInput';
import CategoriesTabs from 'routes/AdminPanel/Categories/CategoriesPage/CategoriesTabs';
import CategoriesDetails from 'routes/AdminPanel/Categories/CategoriesPage/CategoriesDetails';
import CategoriesInfo from 'routes/AdminPanel/Categories/CategoriesPage/CategoriesInfo';
import CategoriesAnalytics from 'routes/AdminPanel/Categories/CategoriesPage/CategoriesAnalytics';

// GraphQl
import GET_CATEGORY from 'queries/AdminPanel/getCategory.gql';
import CREATE_DISCOUNT from 'queries/AdminPanel/createDiscount.gql';
import VALIDATE_DISCOUNT from 'queries/AdminPanel/validateDiscount.gql';
import UPDATE_CATEGORY from 'mutations/AdminPanel/updateCategory.gql';
import DELETE_CATEGORY from 'mutations/AdminPanel/deleteCategory.gql';
import LIST_SUBCATEGORIES_QUERY from 'queries/Courses/listSubcategories.gql';

// Other
import { messages } from 'routes/AdminPanel/messages';
import { keysToSnake, keysToCamel } from 'utils/helpers/index';
import { getYupSchema } from 'services/yup';

// Styles and Assets
import './CategoriesPage.scss';

const INITIAL_FORM_VALUES = {
  activeTab: 'info'
};

class DiscountPage extends PureComponent {
  handleDiscountCreate = async () => {
    const {
      isValid,
      errors,
      validateForm,
      validateField,
      setTouched,
      setFieldTouched,
      setErrors,
      validateDiscount,
      createDiscount,
      values,
      history
    } = this.props;
    const { activeTab, ...input } = values;

    try {
      if (isValid) {
        const mutationResponse = await validateDiscount(input?.code);
        const isDiscountExist =
          mutationResponse?.data?.validateDiscountCode === 'true';

        if (!isDiscountExist) {
          const discount = await createDiscount(input);
          history.push(`/admin/panel/discounts/edit/${discount.id}`);
        } else if (isDiscountExist) {
          setFieldTouched('code', true, false);
          setErrors({
            code: 'This promo code already exist! Please try another.'
          });
        }
      } else {
        validateForm();
        setTouched({
          name: true,
          code: true,
          reference: true,
          referenceId: true,
          discountType: true,
          discountValue: true,
          startTime: true,
          endTime: true,
          redemptions: true
        });
      }
    } catch (error) {
      throw Error(error);
    }
  };

  handleDiscountDelete = async () => {
    const { deleteCategory, category, history } = this.props;
    if (!category.id) return;

    try {
      await deleteCategory({ id: category.id });
      history.push(`/admin/panel/categories/`);
    } catch (error) {
      throw Error(error);
    }
  };

  handleSwitchTab = newTab => this.props.handleChange({ activeTab: newTab });

  handleInputChange = fieldName => inputValue => {
    const { handleChange, setFieldTouched, setFieldValue } = this.props;

    handleChange(fieldName)(inputValue);

    setFieldTouched(fieldName, true, false);
  };

  handleInputBlur = fieldName => async (inputValue, e) => {
    const {
      updateCategory,
      values,
      category,
      setFieldTouched,
      validateForm
    } = this.props;
    const categoryId = category?.id;
    const isCategoryCreated = !!categoryId;

    setFieldTouched(fieldName, true, false);

    const value = inputValue.valueOf();
    const newValues = { ...values, [fieldName]: value };
    const errors = await validateForm(newValues);
    const isValid = !!!errors[fieldName];

    if (isCategoryCreated && isValid) {
      await updateCategory({ id: categoryId, [fieldName]: values[fieldName] });
    }
  };

  getMutationParams = values => {
    const params = { ...values };
    return params;
  };

  render() {
    const {
      values,
      listSubcategories,
      touched,
      errors,
      isNewCategory,
      handleChange,
      handleBlur,
      loading
    } = this.props;
    const { activeTab } = values;

    if (loading) return <Loader />;

    return (
      <div className='sk-admin-discount-page'>
        <div className='sk-admin-discount-page__header'>
          <div className='sk-admin-discount-page__header__sidebar-button'>
            <ContentHeader />
          </div>
          <div className='sk-admin-discount-page__header__content'>
            <div className='sk-admin-discount-page__header__content__buttons'>
              Categories
              {isNewCategory ? (
                <Button onClick={this.handleDiscountCreate}>Create</Button>
              ) : null
              // <Button onClick={this.handleDiscountDelete}>Delete</Button>
              }
            </div>
            <CategoriesInfo
              touched={touched}
              errors={errors}
              values={values}
              handleInputChange={this.handleInputChange}
              handleInputBlur={this.handleInputBlur}
              // handleDiscountUpdate={this.handleDiscountUpdate}
            />
          </div>
        </div>
        <Card>
          <CategoriesTabs
            activeTab={activeTab}
            handleSwitch={this.handleSwitchTab}
          />
          <div className='sk-admin-discount-page__content '>
            <Collapse in={activeTab === 'info'} timeout='auto' unmountOnExit>
              <CategoriesDetails
                touched={touched}
                errors={errors}
                categories={listSubcategories}
                values={values}
                handleInputChange={this.handleInputChange}
                handleInputBlur={this.handleInputBlur}
                handleDateChange={this.handleDateChange}
              />
            </Collapse>
          </div>
        </Card>
      </div>
    );
  }
}

const getCategoryQuery = graphql(GET_CATEGORY, {
  skip: ({
    isNewCategory,
    match: {
      params: { id }
    }
  }) => isNewCategory || !id,
  props: ({ data: { error, loading, getCategory, refetch, ...ownProps } }) => {
    if (error) throw Error('Something went wrong, while receiving discount');
    if (loading) return { loading, discount: {} };

    const category = keysToCamel(getCategory);

    return {
      error,
      loading,
      category,
      refetchCategory: refetch,
      ...ownProps
    };
  },
  options: ({
    match: {
      params: { id }
    }
  }) => ({
    fetchPolicy: 'cache-and-network',
    variables: { id }
  })
});

const createDiscountMutation = graphql(CREATE_DISCOUNT, {
  props: ({ mutate }) => ({
    createDiscount: async values => {
      const input = keysToSnake(values);
      const result = await mutate({ variables: { input } });

      return result?.data?.createDiscount;
    }
  })
});

const updateCategoryMutation = graphql(UPDATE_CATEGORY, {
  props: ({ mutate }) => ({
    updateCategory: values => {
      const input = keysToSnake(values);
      mutate({ variables: { input } });
    }
  }),
  options: () => ({
    refetchQueries: ['getCategory']
  })
});

const deleteCategoryMutation = graphql(DELETE_CATEGORY, {
  props: ({ mutate }) => ({
    deleteCategory: ({ id }) => mutate({ variables: { id } })
  }),
  options: () => ({
    refetchQueries: ['listCategories']
  })
});

const validateDiscountMutation = graphql(VALIDATE_DISCOUNT, {
  props: ({ mutate }) => ({
    validateDiscount: code => mutate({ variables: { code } })
  })
});

const formikStateManagement = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ isNewCategory, category }) => {
    let initialValues = Object.assign({}, INITIAL_FORM_VALUES);
    if (!isNewCategory) initialValues = Object.assign(initialValues, category);
    return initialValues;
  },
  displayName: 'DiscountForm',
  validationSchema: () => getYupSchema('discountEditValidationSchema'),
  validateOnChange: true,
  validateOnBlur: false
});

const listSubCategoriesQuery = graphql(LIST_SUBCATEGORIES_QUERY, {
  props: ({
    data: { loading, error: fetchError, listSubcategories, ...ownProps }
  }) => ({
    loading,
    listSubcategories: listSubcategories?.items || [],
    fetchError,
    ...ownProps
  }),
  options: ({
    match: {
      params: { id }
    }
  }) => ({
    fetchPolicy: 'cache-and-network',
    variables: { category: id }
  })
});

const enhancer = compose(
  withRouter,
  getCategoryQuery,
  createDiscountMutation,
  updateCategoryMutation,
  deleteCategoryMutation,
  validateDiscountMutation,
  formikStateManagement,
  listSubCategoriesQuery
);

export default enhancer(DiscountPage);
