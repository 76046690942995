import React from 'react';
import { injectIntl } from 'react-intl';

// Components
import Text from 'components/Text';
import Stepper from 'components/Stepper';

// Icons
import Check from 'components/Icon/Svg/Check';
import Cross from 'components/Icon/Svg/Cross';

// Steps Components
import Draft from 'routes/ExpertsCabinet/CourseEdit/GeneralInfo/StatusInfo/Steps/Step1';
import FirstReview from 'routes/ExpertsCabinet/CourseEdit/GeneralInfo/StatusInfo/Steps/Step2';
import ReadyToPublishing from 'routes/ExpertsCabinet/CourseEdit/GeneralInfo/StatusInfo/Steps/Step5';
import Active from 'routes/ExpertsCabinet/CourseEdit/GeneralInfo/StatusInfo/Steps/Active';
import Inactive from 'routes/ExpertsCabinet/CourseEdit/GeneralInfo/StatusInfo/Steps/Inactive';
import Rejected from 'routes/ExpertsCabinet/CourseEdit/GeneralInfo/StatusInfo/Steps/Rejected';

// Other
import { White, LightGreen, Grey2, PurpleHeart } from 'components/Icon/color';
import { messages } from 'routes/ExpertsCabinet/ToppingEdit/messages';

// Styles
import './index.scss';

const STATUSES = {
  'DRAFT': {
    intlKey: 'draftLabel',
    ExplanationComponent: Draft,
  },
  'FIRST_REVIEW': {
    intlKey: 'inReviewLabel',
    ExplanationComponent: FirstReview,
  },
  'READY_TO_PUBLISHING': {
    intlKey: 'approvedLabel',
    ExplanationComponent: ReadyToPublishing,
  },
  'FIRST_REJECT': {
    intlKey: 'rejectedLabel',
    ExplanationComponent: Rejected,
  },
  'ACTIVE': {
    intlKey: 'activedLabel',
    ExplanationComponent: Active,
  },
  'DEACTIVATED': {
    intlKey: 'deactivatedLabel',
    ExplanationComponent: Inactive,
  },
};

const creating = ['DRAFT', 'FIRST_REVIEW',  'READY_TO_PUBLISHING'];
const rejecting = ['DRAFT', 'FIRST_REVIEW', 'FIRST_REJECT'];
const active = ['ACTIVE'];
const inactive = ['DEACTIVATED'];

export const getStepperSteps = status => {
  let currentCourseStage = 'rejecting';

  if (creating.includes(status)) {
    currentCourseStage = 'creating';
  } else if (active.includes(status)) {
    currentCourseStage = 'active';
  } else if (inactive.includes(status)) {
    currentCourseStage = 'inactive';
  };

  let steps = [];

  if (currentCourseStage === 'creating') {
    const currentStatusIndex = creating.indexOf(status);

    steps = creating.map((elm, index) => {
      const id = elm;
      const isStatusPassed = index < currentStatusIndex;
      const icon = isStatusPassed ? (<Check stroke={LightGreen} />) : index + 1;

      return { id, icon, }
    });
  } else if (currentCourseStage === 'active') {
    steps = active.map((elm, index) => ({ id: elm, icon: (<Check width={16} height={16} stroke={White} />), background: LightGreen, }));
  } else if (currentCourseStage === 'inactive') {
    steps = inactive.map((elm, index) => ({ id: elm, icon: (<Cross width={18} height={18} color={White} strokeWidth={3.5} />), background: Grey2 }));
  } else if (currentCourseStage === 'rejecting') {
    steps = rejecting.map((elm, index) => {
      const id = elm;
      const rejectedStepIndex = rejecting.indexOf(status);

      const isRejectedStep = rejectedStepIndex === index;
      const isCompletedStep = rejectedStepIndex > index;
      const isUncompletedStep = rejectedStepIndex < index;

      let icon = null;
      let background = null;

      if (isRejectedStep) {
        icon = (<Cross width={18} height={18} color={White} strokeWidth={3.5} />);
        background = PurpleHeart;
      } else if (isCompletedStep) {
        icon = (<Check width={16} height={16} stroke={LightGreen} />);
        // background = LightGreen;
      } else if (isUncompletedStep) {
        icon = index + 1;
      }

      return { id, icon, background, };
    })
  }

  return steps;
}

const prefix = 'sk-topping-status-bar';
const StatusBar = ({
  statusId,
  disabledEditing,
  intl: { formatMessage },
  type
}) => {
  const { intlKey, ExplanationComponent } = STATUSES[statusId];
  const steps = getStepperSteps(statusId);

  return (
    <div className={prefix}>
      <Stepper
        steps={steps}
        activeStep={statusId}
        disabled={disabledEditing}
      />
      <div className={`${prefix}__label`}>
        <Text>{formatMessage(messages.statusBarTitle)}</Text>
        <Text>{formatMessage(messages[intlKey])}</Text>
      </div>
      <ExplanationComponent type={type} />
    </div>
  );
}

export default injectIntl(StatusBar);
