import React from 'react';
import PropTypes from 'prop-types';

const MenuNote = ({ height, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 27 28"
    width={width}
    height={height}
  >
    <g fill="none" fillRule="evenodd">
      <g transform="translate(0 1)">
        <circle
          cx="8.448"
          cy="16.719"
          r="8.448"
          fill="#FBC4BB"
          fillRule="nonzero"
        />
        <path
          stroke="#333"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M23.537 13.158v2.39l-.123 3.586a2.515 2.515 0 0 1-2.428 2.427l-3.653.124H7.075a1.877 1.877 0 0 
          1-1.871-1.871V1.964c0-1.03.842-1.871 1.871-1.871h14.59c1.03 0 1.872.842 1.872 1.87v2.425M8.463 
          4.981H19.87M8.463 7.833h6.926"
        />
        <path
          stroke="#333"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M26.536 7.397l-1.16-1.403a.991.991 0 0 0-1.396-.127l-10.19 9.17-1.445 3.27a.337.337 0 0 
          0 .384.465l3.484-.811 10.188-9.17a.992.992 0 0 0 .135-1.394z"
        />
        <path fill="#F0FF87" d="M14.037 15.304l1.99 2.401" />
        <path
          stroke="#333"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M14.037 15.304l1.99 2.401"
        />
      </g>
    </g>
  </svg>
);

MenuNote.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

MenuNote.defaultProps = {
  height: '28',
  width: '27',
};

export default MenuNote;
