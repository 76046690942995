// Core
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import { captureException } from '@sentry/browser';

// Services
import { UploadManagerContext } from './UploadManagerContext';
// import { localStore } from 'services/localStorage';
import { isBlank } from 'utils/helpers';
import {
  LECTURE_VIDEO,
  LECTURE_AUDIO,
  LECTURE_ARTICLE,
  LECTURE_QUIZ,
  LECTURE_HOMEWORK_TASK,
  LECTURE_HOMEWORK,
  LECTURE_HOMEWORK_REVIEW,
  COURSE_PREVIEW,
  USER_PROFILE_PHOTO,
  QUIZ_QUESTION_IMAGE,
  QUIZ_ANSWER_IMAGE,
  TOPPING_PREVIEW_IMAGE,
} from './helpers';
import {
  s3UploadLectureVideo,
  s3UploadLectureAudio,
  s3UploadLectureArticle,
  s3UploadCoursePreview,
  s3UploadHomework,
  s3UploadHomeworkReview,
  s3UploadHomeworkAnswer,
  s3UploadAvatar,
  s3UploadQuizQuestionImage,
  s3UploadQuizAnswerImage,
  s3UploadPublicCoursePhoto,
} from 'services/aws/amplify/helpers';

// GraphQl
import CREATE_ATTACHMENT from 'mutations/Courses/createAttachment.gql';
import UPDATE_ATTACHMENT from 'mutations/Courses/updateAttachment.gql';
import UPDATE_LECTURE from 'mutations/Lecture/updateLecture.gql';

// save files when use was gone
// const saveCoursesInLocalStore = courses => {
//   localStore.setItem('ShoppingCart', JSON.stringify({ items: courses }));
// };

const minDurationToUpdateUploadingProgress = 1000; // in milliseconds

class UploadManagerProvider extends PureComponent {
  queue = [];

  uploaded = [];

  state = {
    status: 'READY', // READY|UPLOADING|ERROR,
    totalCount: 0, // integer
    progress: 0, // [0-100]
    isVisible: true,
    lastUpdated: Date.now(), // timestamp in milliseconds
  };

  uploadingHandlers = {
    [LECTURE_VIDEO]: {
      uploader: s3UploadLectureVideo,
      preSaveHook: this.props.createAttachment,
      postSaveHook: this.props.updateAttachment,
    },
    [LECTURE_AUDIO]: {
      uploader: s3UploadLectureAudio,
      preSaveHook: this.props.createAttachment,
      postSaveHook: this.props.updateAttachment,
    },
    [LECTURE_ARTICLE]: {
      uploader: s3UploadLectureArticle,
      preSaveHook: this.props.createAttachment,
      postSaveHook: this.props.updateAttachment,
    },
    [LECTURE_QUIZ]: {
      uploader: null,
      preSaveHook: this.props.createAttachment,
      postSaveHook: null,
    },
    [COURSE_PREVIEW]: {
      uploader: s3UploadCoursePreview,
      preSaveHook: this.props.createAttachment,
      postSaveHook: this.props.updateAttachment,
    },
    [LECTURE_HOMEWORK_TASK]: {
      uploader: s3UploadHomework,
      preSaveHook: this.props.createAttachment,
      postSaveHook: this.props.updateAttachment,
    },
    [LECTURE_HOMEWORK]: {
      uploader: s3UploadHomeworkAnswer,
      preSaveHook: this.props.createAttachment,
      postSaveHook: this.props.updateAttachment,
    },
    [LECTURE_HOMEWORK_REVIEW]: {
      uploader: s3UploadHomeworkReview,
      preSaveHook: this.props.createAttachment,
      postSaveHook: this.props.updateAttachment,
    },
    [USER_PROFILE_PHOTO]: {
      uploader: s3UploadAvatar,
      preSaveHook: this.props.createAttachment,
      postSaveHook: this.props.updateAttachment,
    },
    [QUIZ_QUESTION_IMAGE]: {
      uploader: s3UploadQuizQuestionImage,
      preSaveHook: this.props.createAttachment,
      postSaveHook: this.props.updateAttachment,
    },
    [QUIZ_ANSWER_IMAGE]: {
      uploader: s3UploadQuizAnswerImage,
      preSaveHook: this.props.createAttachment,
      postSaveHook: this.props.updateAttachment,
    },
    [TOPPING_PREVIEW_IMAGE]: {
      uploader: s3UploadPublicCoursePhoto,
      preSaveHook: this.props.createAttachment,
      postSaveHook: this.props.updateAttachment,
    },
  };

  async componentDidUpdate(prevProps, prevState) {
    const {
      totalCount: currentTotalCount,
      status: currentStatus,
      progress,
      lastUpdated,
    } = this.state;

    const isManagerReadyToUpload = currentStatus === 'READY';
    const hasQueueFiles =
      this.queue.length === currentTotalCount && currentTotalCount > 0;

    if (isManagerReadyToUpload && hasQueueFiles)
      this._uploadFile(this.queue[0]);
  }

  componentWillUnmount() {}

  _uploadFile = async queueItem => {
    // console.log('start uploading file');
    const { totalCount, status } = this.state;
    if (status !== 'UPLOADING') this.setState(() => ({ status: 'UPLOADING' }));

    const { type, s3UploadingVariables, s3PostHook, uploadId } = queueItem;
    const { uploader, preSaveHook, postSaveHook } = this.uploadingHandlers[
      type
    ];

    try {
      const uploadingResult = await uploader({
        uploadId,
        progressCallback: this._updateUploadingProgress,
        ...s3UploadingVariables,
      });
      const fileInProgress = this.queue[0];
      const wasFileRemovedWhileUploading =
        fileInProgress?.uploadId !== uploadId;

      if (wasFileRemovedWhileUploading) return;

      // console.log('begin postSaveHook');
      if (postSaveHook)
        await postSaveHook({ id: uploadId, status: 'PROCESSING' });
      // console.log('begin s3PostHook');
      if (s3PostHook) s3PostHook();
      // console.log('done s3PostHook');
    } catch (error) {
      console.log('error', error);
    }

    this.uploaded.push(this.queue[0]);
    this.queue.shift();
    const updatedTotalCount = this.queue.length;
    const newState = { totalCount: updatedTotalCount, progress: 0 };
    if (updatedTotalCount === 0) newState.status = 'READY';

    // console.log('files uploaded');

    this.setState(newState, () => {
      const hasQueueFiles = updatedTotalCount > 0;
      if (hasQueueFiles) this._uploadFile(this.queue[0]);
    });
  };

  _updateUploadingProgress = (currentProgress = 0, uploadId) => {
    const { lastUpdated, isVisible, status } = this.state;
    const fileInProgress = this.queue[0];

    const durationFromLastUpdate = Date.now() - lastUpdated;
    const isDurationFromLastUpdateExceeded =
      durationFromLastUpdate > minDurationToUpdateUploadingProgress;
    const isFilesUploading = status === 'UPLOADING';
    const isFileInProgress = uploadId === fileInProgress.uploadId;

    if (
      !isVisible ||
      !isDurationFromLastUpdateExceeded ||
      !isFilesUploading ||
      !isFileInProgress
    )
      return;

    this.setState({
      lastUpdated: Date.now(),
      progress: currentProgress,
    });
  };

  addFileToQueue = async fileParams => {
    // console.log('+ file in queue', fileParams);
    const { preSaveHook } = this.uploadingHandlers[fileParams.type];

    // console.log('pre save hook', preSaveHook);

    if (preSaveHook) {
      try {
        const savedAttachment = await preSaveHook(
          fileParams.createAttachmentMutationVariables
        );
        const uploadId = savedAttachment?.data?.createAttachment?.id;

        // console.log('saved attachment', savedAttachment);

        if (!uploadId) {
          throw new Error('Id was not returned after method "createAttachment"!');
        };

        if (isBlank(fileParams?.s3UploadingVariables)) {
          // console.log('attachment with s3 uploading');
          return uploadId;
        }

        this.queue.push({ uploadId, ...fileParams });

        // console.log('updated queue', this.queue, this.queue.length);

        this.setState(({ totalCount: prevTotalCount }) => ({
          totalCount: prevTotalCount + 1,
        }));
      } catch (e) {
        throw e;
      }
    }
  };

  removeFileFromQueue = uploadId => {
    const updatedQueue = this.queue.filter(item => item.uploadId !== uploadId);

    this.queue = updatedQueue;
    this.setState(() => ({
      status: 'READY',
      totalCount: updatedQueue.length,
      progress: 0,
    }));
  };

  getFileFromList = type => uploadId => {
    const queue = this.queue;
    const uploaded = this.uploaded;
    const list = { queue, uploaded };

    // console.log('file from list', uploadId, list);

    const file = list[type].find(file => file.uploadId === uploadId);
    const isFileInList = !!file;
    const index = this.queue.indexOf(file);

    return { isFileInList, index };
  };

  render() {
    const { progress, status } = this.state;
    const { children, updateVideoTitle, updateArticle } = this.props;

    return (
      <UploadManagerContext.Provider
        value={{
          uploadManagerContext: {
            progress,
            status,
            queue: this.queue,
            uploaded: this.uploaded,
            addFileToQueue: this.addFileToQueue,
            removeFileFromQueue: this.removeFileFromQueue,
            listFilesFromQueue: this.listFilesFromQueue,
            getFileFromQueue: this.getFileFromList('queue'),
            getFileFromUploadedFiles: this.getFileFromList('uploaded'),
          },
        }}
      >
        {React.Children.only(children)}
      </UploadManagerContext.Provider>
    );
  }
}

UploadManagerProvider.defaultProps = {
  draftOrder: {
    isLoaded: false,
    id: null,
    courses: [],
  },
};

UploadManagerProvider.propTypes = {
  children: PropTypes.element.isRequired,
  match: PropTypes.object,
  history: PropTypes.object,
};

const updateLecture = graphql(UPDATE_LECTURE, {
  props: ({ mutate }) => ({
    updateLecture: input => mutate({ variables: { input } }),
  }),
  options: {
    refetchQueries: ['getCourseLectures'],
  },
});

const createAttachmentMutation = graphql(CREATE_ATTACHMENT, {
  props: ({ mutate }) => ({
    createAttachment: input => mutate({ variables: { input } }),
  }),
  options: {
    refetchQueries: [
      'getQuiz',
      'getCourseLectures',
      'getCourse',
      'getStudentCourse',
      'listStudentCourses',
      'listExpertCourses',
    ],
  },
  fetchPolicy: 'no-cache',
});

const updateAttachmentMutation = graphql(UPDATE_ATTACHMENT, {
  props: ({ mutate }) => ({
    updateAttachment: input => mutate({ variables: { input } }),
  }),
  options: {
    refetchQueries: [
      'getCourseLectures',
      'getCourse',
      'getStudentCourse',
      'listStudentCourses',
      'listExpertCourses',
    ],
    fetchPolicy: 'no-cache',
  },
});

const enhancer = compose(
  updateLecture,
  createAttachmentMutation,
  updateAttachmentMutation
);

export default enhancer(UploadManagerProvider);
