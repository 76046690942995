// Core
import React, { memo, useState, useCallback } from 'react';
import { graphql, compose } from 'react-apollo';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

// Components
import Text from 'components/Text';
import Link from 'components/Link';
import Loader from 'components/Loader';
import Payment from 'routes/Checkout/Payment';
import CheckoutLayout from 'routes/Checkout/Lauout';
import SuccesfulPaymentDetailsUpdate from 'routes/ExpertsCabinet/ExpertSubscription/ExpertPaymentDetails/SuccesfulPaymentDetailsUpdate';
import ContentHeader from 'containers/ContentHeader';
import Breadcrumbs from 'components/Breadcrumbs';

// GraphQL
import UPDATE_PAYMENT_METHOD from 'mutations/Subscriptions/updateSubscriptionPaymentMethod.gql';

// Other
import { keysToSnake } from 'utils/helpers';
// import { useTransaction } from 'routes/Checkout/components/useTransaction.js';
import { withModal } from 'containers/ModalProvider/withModal';
// import { hasLoader } from 'containers/HOCs/hasLoader';
import { messages } from 'routes/ExpertsCabinet/ExpertSubscription/messages.js';
import { sharedMessages } from 'services/i18n/sharedMessages/messages';

// Styles and Assets
import './index.scss';

const PREFIX = 'sk-expert-payment-details';
const ExpertPaymentDetails = ({
  updatePaymentMethod,
  intl: { formatMessage },
  modalContext: { showModal },
  history,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const handleSuccesfulPayment = useCallback(provider => async customerId => {
    setLoading(true);
    const input = {
      token: customerId,
      provider
    };
    const subscription = await updatePaymentMethod(input);
    setLoading(false);

    // showModal(SuccesfulPaymentDetailsUpdate, {
    //   size: 'md',
    //   subscription,
    // });
  }, [updatePaymentMethod]);

  return (
    <div className={PREFIX}>
      <ContentHeader way={<Breadcrumbs />} />
      <Text>{formatMessage(messages.paymentDetailsTitle)}</Text>
      <Payment
        loading={loading}
        withPayPal={false}
        strategy='update-payment'
        handleSuccesfulPayment={handleSuccesfulPayment}
      />
      <Link to='/experts-cabinet/subscription'>{formatMessage(sharedMessages.cancelButton)}</Link>
    </div>
)};

const updatePaymentMethodMutation = graphql(UPDATE_PAYMENT_METHOD, {
  props: ({ mutate }) => ({
    updatePaymentMethod: values => mutate({ variables: { input: keysToSnake(values) } })
  }),
  options: () => ({
    refetchQueries: ['getProfile'],
  })
});

const enhancer = compose(
  memo,
  injectIntl,
  withModal,
  withRouter,
  updatePaymentMethodMutation,
);

export default enhancer(ExpertPaymentDetails);
