// Core
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';

// Components
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Text from 'components/Text';

// Other
import { sharedMessages } from 'services/i18n/sharedMessages/messages';

// Styles and Assets
import './CoursesTableRow.scss';

const classes = {
  tableCell: { root: 'mui-override sk-adminCoursesListTable' },
};

const CoursesTableRow = memo(({ item, index, arr, status, intl: { formatMessage } }) => (
  <TableRow key={item.id}>
    <TableCell
      classes={classes.tableCell}
      className={`sk-adminCoursesListTable__tableBodyCellLeft ${index ===
        arr.length - 1 && '_last'}`}
      align="left"
    >
      <Link to={`/admin/panel/course/${item.id}`}>
        <Text size="basic" className="sk-adminCoursesListTable__link">
          {item.title}
        </Text>
      </Link>
    </TableCell>
    <TableCell
      classes={classes.tableCell}
      className={`sk-adminCoursesListTable__tableBodyCellLeft ${index ===
        arr.length - 1 && '_last'}`}
      align="left"
    >
      {item?.category?.title ? (
        <Text size="basic">
          {formatMessage(sharedMessages[item.category.title])}
        </Text>
      ) : "-"}
    </TableCell>
    <TableCell
      classes={classes.tableCell}
      className={`sk-adminCoursesListTable__tableBodyCellLeft ${index ===
        arr.length - 1 && '_last'}`}
      align="left"
    >
      {item?.subcategory?.title ? (
        <Text size="basic">
          {formatMessage(sharedMessages[item.subcategory.title])}
        </Text>
      ) : "-"}
    </TableCell>
    <TableCell
      classes={classes.tableCell}
      className={`sk-adminCoursesListTable__tableBodyCellLeft ${index ===
        arr.length - 1 && '_last'}`}
      align="left"
    >
      <Text size="basic">{status}</Text>
    </TableCell>
  </TableRow>
));

CoursesTableRow.propTypes = {
  intl: intlShape.isRequired,
  item: PropTypes.object,
  status: PropTypes.string,
  index: PropTypes.number,
  arr: PropTypes.array,
};

export default injectIntl(CoursesTableRow);
