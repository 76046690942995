// Core
import React, { useEffect } from 'react';

// Components
import Loader from 'components/Loader';

export const withLoader = WrappedComponent => {
  const HOC = ({ loading, error, ...props }) => {
    
      if (loading) return (<Loader fullScreen={true} />);
      if (error) throw Error(error);

    return <WrappedComponent {...props} />;
  };

  return HOC;
};
