import React from 'react';

const Icon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M16.0211 5C16.2973 5 16.5211 5.22386 16.5211 5.5V6.16504H17.2941C18.3987 6.16504 19.2941 7.06047 19.2941 8.16504V16.7993C19.2941 17.9039 18.3987 18.7993 17.2941 18.7993H6C4.89543 18.7993 4 17.9039 4 16.7993V8.16504C4 7.06047 4.89543 6.16504 6 6.16504H6.35095V5.5C6.35095 5.22386 6.57481 5 6.85095 5C7.12709 5 7.35095 5.22386 7.35095 5.5V6.16504H8.6427V5.5C8.6427 5.22386 8.86656 5 9.1427 5C9.41884 5 9.6427 5.22386 9.6427 5.5V6.16504H10.9359V5.5C10.9359 5.22386 11.1598 5 11.4359 5C11.7121 5 11.9359 5.22386 11.9359 5.5V6.16504H13.2279V5.5C13.2279 5.22386 13.4518 5 13.7279 5C14.004 5 14.2279 5.22386 14.2279 5.5V6.16504H15.5211V5.5C15.5211 5.22386 15.745 5 16.0211 5ZM6 7.16504H6.35095V7.84704C6.35095 8.12318 6.57481 8.34704 6.85095 8.34704C7.12709 8.34704 7.35095 8.12318 7.35095 7.84704V7.16504H8.6427V7.84704C8.6427 8.12318 8.86656 8.34704 9.1427 8.34704C9.41884 8.34704 9.6427 8.12318 9.6427 7.84704V7.16504H10.9359V7.84704C10.9359 8.12318 11.1598 8.34704 11.4359 8.34704C11.7121 8.34704 11.9359 8.12318 11.9359 7.84704V7.16504H13.2279V7.84704C13.2279 8.12318 13.4518 8.34704 13.7279 8.34704C14.004 8.34704 14.2279 8.12318 14.2279 7.84704V7.16504H15.5211V7.84704C15.5211 8.12318 15.745 8.34704 16.0211 8.34704C16.2973 8.34704 16.5211 8.12318 16.5211 7.84704V7.16504H17.2941C17.8464 7.16504 18.2941 7.61275 18.2941 8.16504V9.70605H5V8.16504C5 7.61275 5.44771 7.16504 6 7.16504ZM5 10.7061V16.7993C5 17.3516 5.44771 17.7993 6 17.7993H17.2941C17.8464 17.7993 18.2941 17.3516 18.2941 16.7993V10.7061H5Z" fill="#6D7C90"/>
    </svg>
  );
};

export default Icon;
