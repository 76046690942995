import React from 'react';

import './index.scss';

const IconOnHover = () => (
  <svg class='filled' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.71824 8.72356C1.33396 8.32689 0.700878 8.31684 0.304205 8.70111C-0.0924684 9.08539 -0.102517 9.71848 0.28176 10.1151L1.71824 8.72356ZM7.375 16L6.65676 16.6958C6.84513 16.8902 7.10428 17 7.375 17C7.64572 17 7.90487 16.8902 8.09324 16.6958L7.375 16ZM19.7182 4.6958C20.1025 4.29912 20.0925 3.66604 19.6958 3.28176C19.2991 2.89748 18.666 2.90753 18.2818 3.3042L19.7182 4.6958ZM0.28176 10.1151L6.65676 16.6958L8.09324 15.3042L1.71824 8.72356L0.28176 10.1151ZM8.09324 16.6958L19.7182 4.6958L18.2818 3.3042L6.65676 15.3042L8.09324 16.6958Z" fill="#05D04A"/>
  </svg>
);

const IconDefault = () => (
  <svg class='outlined' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.71824 8.72356C1.33396 8.32689 0.700878 8.31684 0.304205 8.70111C-0.0924684 9.08539 -0.102517 9.71848 0.28176 10.1151L1.71824 8.72356ZM7.375 16L6.65676 16.6958C6.84513 16.8902 7.10428 17 7.375 17C7.64572 17 7.90487 16.8902 8.09324 16.6958L7.375 16ZM19.7182 4.6958C20.1025 4.29912 20.0925 3.66604 19.6958 3.28176C19.2991 2.89748 18.666 2.90753 18.2818 3.3042L19.7182 4.6958ZM0.28176 10.1151L6.65676 16.6958L8.09324 15.3042L1.71824 8.72356L0.28176 10.1151ZM8.09324 16.6958L19.7182 4.6958L18.2818 3.3042L6.65676 15.3042L8.09324 16.6958Z" fill="#05D04A"/>
  </svg>
);

const Icon = () => {
  return (
    <div className="quiz-icon">
      <IconDefault />
      <IconOnHover />
    </div>
  );
};

export default Icon;
