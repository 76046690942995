import React from 'react';
import PropTypes from 'prop-types';

const MenuManageAccount = ({ height, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 26 26"
  >
    <g fill="none" fillRule="evenodd">
      <g fillRule="nonzero" transform="translate(-1)">
        <path d="M0 0h27v26H0z" />
        <rect width="14" height="16" x="1" y="9.5" fill="#FBC4BB" rx="1" />
        <circle cx="16.5" cy="5" r="4.5" stroke="#333" />
        <path
          stroke="#333"
          d="M17.55 24.5c4.289 0 8.45-.424 8.45-3.568S21.97 11.5 17 11.5s-9 6.288-9 9.432 5.26 3.568 9.55 3.568z"
        />
      </g>
    </g>
  </svg>
);

MenuManageAccount.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

MenuManageAccount.defaultProps = {
  height: '26',
  width: '26',
};

export default MenuManageAccount;
