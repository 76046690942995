// Core
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';
import ReactHtmlParser from 'react-html-parser';

// Components
import Text from 'components/Text';
import Heading from 'components/Heading';

// Locales
import EN from 'services/i18n/privacyPolicy/en.json';
import RU from 'services/i18n/privacyPolicy/ru.json';

// Styles and Assets
import './PrivacyPolicyText.scss';

import { withLanguage } from 'containers/LanguageProvider/withLanguage';

// Utils
import { findOneOf } from 'utils/helpers/';

const contentTypes = ['paragraphs', 'ordered_list', 'ordered_list_letters', 'unordered_list'];

let keyCounter = 0;

const renderList = list =>
  list.map(text => {
    keyCounter += 1;
    return (
      <Text
        key={keyCounter}
        size="caption"
        style={{ padding: '6px 0', display: 'block', textAlign: 'justify' }}
        classes="sk-privacy-policy-text__title"
      >
        <li>
          <Text size="caption" classes="sk-privacy-policy-text__title">
            {ReactHtmlParser(text)}
          </Text>
        </li>
      </Text>
    );
  });
const renderParagraph = ( paragraphs ) =>
  paragraphs.map(text => {
    keyCounter += 1;
    return (
      <Text
        key={keyCounter}
        size="caption"
        style={{ padding: '10px 0', display: 'block', textAlign: 'justify' }}
        classes="sk-privacy-policy-text__title"
      >
        {ReactHtmlParser(text)}
      </Text>
    );
  });
const renderOrderedList = content => (
  <ol style={{ paddingLeft: 12, fontSize: '12px' }}>{renderList(content)}</ol>
);
const renderOrderedListLetters = content => (
  <ol style={{ paddingLeft: 12, fontSize: '12px' }} type="a">{renderList(content)}</ol>
);
const renderUnorderedList = content => (
  <ul style={{ listStyle: 'disc', paddingLeft: 16 }}>{renderList(content)}</ul>
);

const contentRenderFunctions = {
  paragraphs: renderParagraph,
  ordered_list: renderOrderedList,
  ordered_list_letters: renderOrderedListLetters,
  unordered_list: renderUnorderedList,
};

const PrivacyPolicyText = ({ languageContext: { language } }) => {
  const currentLocaleConfig = language === 'en' ? EN : RU;

  return (
    <div className="sk-privacy-policy-text">
      <div className="sk-privacy-policy-text__block">
        <Text size="caption" classes="sk-privacy-policy-text__title">
          {currentLocaleConfig.updatedAt}
        </Text>
      </div>
      <header className="sk-privacy-policy-text__header">
        <Heading
          variant="h4"
          bold
          classes="sk-privacy-policy-text__subtitle heading-sk__center"
        >
          {currentLocaleConfig.title}
        </Heading>
      </header>
      <ol style={{ fontWeight: 'bold', fontSize: 13 }}>
        {currentLocaleConfig.blocks.map(block => {
          const contentType = findOneOf(Object.keys(block), contentTypes);
          const isContentTypeDefined = !!contentType;
          const contentRenderFunction =
            isContentTypeDefined && contentRenderFunctions[contentType];
          keyCounter += 1;
          const isHeaderDefined = block?.header;
          const isFooterDefined = block?.footer;

          return (
            <li className="sk-privacy-policy-text__block" key={keyCounter}>
              <Text
                size="caption"
                classes="sk-privacy-policy-text__title sk-privacy-policy-text__heading"
              >
                {block.title}
                {isHeaderDefined && renderParagraph(block.header)}
              </Text>
              {isContentTypeDefined
                ? contentRenderFunction(block[contentType])
                : null}

              {isFooterDefined && renderParagraph(block.footer)}
            </li>
          );
        })}
      </ol>
    </div>
  );
};

PrivacyPolicyText.defaultProps = {
  language: 'en',
};

PrivacyPolicyText.propTypes = {
  languageContext: PropTypes.object,
};

const enhancer = compose(
  memo,
  withLanguage,
);

export default enhancer(PrivacyPolicyText);
