// Core
import { location } from 'global';
import { withRouter } from 'react-router-dom';
import React, { PureComponent } from 'react';
import { compose } from 'react-apollo';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  CardElement,
  injectStripe,
  useElements,
} from 'react-stripe-elements';
import { injectIntl } from 'react-intl';

// Components
import StripeElement from 'routes/Checkout/components/Stripe/CardForm/StripeElement';
import Card from 'components/Card';
import ToolTip from 'components/ToolTip';
import FailedMessage from 'routes/Checkout/components/FailedMessage';
import SuccessfulUpdatePaymentDetailsMessage from 'routes/Checkout/components/SuccessfulUpdatePaymentDetailsMessage';
import Button from 'components/Button';
import Image from 'components/Image';

// Other
import { withModal } from 'containers/ModalProvider/withModal';
import { messages } from 'routes/Checkout/messages';
import { sharedMessages } from 'services/i18n/sharedMessages/messages';

// Styles and Assets
import './index.scss';

const requires3ds = ['required'];

const createOptions = (fontSize, padding) => ({
  style: {
    base: {
      fontSize: '16px',
      lineHeight: '22px',
      // letterSpacing: '0.446667px',
      color: '#9B9B9B',
      letterSpacing: '0.025em',
      fontFamily: 'Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
      '::placeholder': {
        color: '#9B9B9B',
      },
    },
    invalid: {
      color: 'red',
    },
  },
});

const styles = {
  info: {
    input: createOptions(),
  },
  number: {
    wrapper: {
      width: '100%',
    },
    input: createOptions(),
  },
};

class CardForm extends PureComponent {
  handleFallback = async () => {
    const query = new URLSearchParams(location.search);
    if (query.has('source')) {
      const {
        stripe: { retrieveSource },
        handleSuccesfulPayment,
        history,
      } = this.props;

      retrieveSource({
        id: query.get('source'),
        client_secret: query.get('client_secret'),
      }).then(res => {
        const { source } = res;
        if (source.status === 'pending') {
          setTimeout(this.handleFallback, 1000);
        } else if (source.status === 'chargeable') {
          if (window && !window.isServer) {
            handleSuccesfulPayment(source.id);
            history.replace(location.pathname);
          }
        }
      });
    }
  };

  handleCreateSource = async () => {
    const { createSource } = this.props.stripe;
    const {
      amount,
      currency,
      strategy,
      modalContext: { showModal },
    } = this.props;
    try {
      createSource({
        type: 'card',
        currency: currency.toLowerCase(),
        amount: amount * 100,
      }).then(res => {
        if (res.error) {
          showModal(FailedMessage, {
            message: res.error.message,
          });
        } else {
          const { source } = res;
          if (
            !requires3ds.includes(source.card.three_d_secure) &&
            source.status === 'chargeable'
          ) {
            this.props.handleSuccesfulPayment(source.id);
          } else if (requires3ds.includes(source.card.three_d_secure)) {
            createSource({
              type: 'three_d_secure',
              currency: source.currency,
              amount: source.amount,
              three_d_secure: {
                card: source.id,
              },
              redirect: {
                return_url: location.href,
              },
            }).then(payload => {
              if (payload.error) {
                this.setState({ messageError: payload.error.message });
              } else {
                const { source } = payload;
                if (window) {
                  window.location.href = source.redirect.url;
                }
              }
            });
          }
        }
      });
    } catch (error) {
      throw Error(error);
    }
  };

  handleCreatePaymentMethod = async (_) => {
    const { createPaymentMethod } = this.props.stripe;

    const {
      amount,
      currency,
      strategy,
      modalContext: { showModal },
    } = this.props;
    try {
      // new api
      // const paymentMethod = await createPaymentMethod({
      //   type: 'card',
      // });

      // old api
      const {paymentMethod} = await createPaymentMethod('card');
      console.log('paymentMethod', paymentMethod);
      const {id: paymentMethodId, error} = paymentMethod;
      if (error) {
        showModal(FailedMessage, {
          message: error.message,
        });
      } else {
        this.props.handleSuccesfulPayment(paymentMethodId);

        // and nobody knows how to deal with 3ds in this case
        // damn
        // if (
        //   !requires3ds.includes(source.card.three_d_secure) &&
        //   source.status === 'chargeable'
        // ) {
        //   this.props.handleSuccesfulPayment(source.id);
        // } else if (requires3ds.includes(source.card.three_d_secure)) {
        //   createSource({
        //     type: 'three_d_secure',
        //     currency: source.currency,
        //     amount: source.amount,
        //     three_d_secure: {
        //       card: source.id,
        //     },
        //     redirect: {
        //       return_url: location.href,
        //     },
        //   }).then(payload => {
        //     if (payload.error) {
        //       this.setState({ messageError: payload.error.message });
        //     } else {
        //       const { source } = payload;
        //       if (window) {
        //         window.location.href = source.redirect.url;
        //       }
        //     }
        //   });
        // }
      }
    } catch (error) {
      throw Error(error);
    }
  };

  handleUpdatePaymentMethod = async (_) => {
    const { createPaymentMethod } = this.props.stripe;

    const {
      amount,
      currency,
      strategy,
      modalContext: { showModal },
      history,
    } = this.props;
    try {
      const {paymentMethod} = await createPaymentMethod('card');
      const {id: paymentMethodId, error} = paymentMethod;
      if (error) {
        showModal(FailedMessage, {
          message: error.message,
        });
      } else {
        this.props.handleSuccesfulPayment(paymentMethodId);
        showModal(SuccessfulUpdatePaymentDetailsMessage, {
          size: 'md'
        });
        history.push('/experts-cabinet/subscription');
      }
    } catch (error) {
      throw Error(error);
    }
  };

  handleSubmit = async (ev) => {
    ev.preventDefault();

    const { strategy } = this.props;

    if (strategy === 'subscription') {
      await this.handleCreatePaymentMethod();
    } else if (strategy === 'update-payment') {
      await this.handleUpdatePaymentMethod();
    } else {
      await this.handleCreateSource();
    }
  };

  componentDidMount = async () => {
    await this.handleFallback();
  };

  render() {
    const {
      intl: { formatMessage },
      isLoading,
      disabled,
      strategy,
    } = this.props;

    const submitButtonTranslation = strategy == 'update-payment' ? sharedMessages.saveButton : messages.payNow;

    return (
      <Card className="sk-card-stripe">
        <div className="sk-form-row__images">
          <Image
            src="/assets/images/visa_logo.png"
            className="visa_logo"
            alt="visa_logo"
          />
          <Image
            src="/assets/images/mastercard_logo.png"
            className="mastercard_logo"
            alt="mastercard_logo"
          />
          <Image
            src="/assets/images/discover_card_logo.png"
            className="discover_card_logo"
            alt="discover_card_logo"
          />
          <Image
            src="/assets/images/american_express_logo.png"
            className="american_express_logo"
            alt="mastercard_logo"
          />
        </div>
        <form className="sk-checkout-form" onSubmit={this.handleSubmit}>
          <StripeElement
            stripeElement={CardNumberElement}
            label={formatMessage(messages.cardNumber)}
            styles={styles.number}
            elmProps={{
              stripeElement: {
                placeholder: 'xxxx xxxx xxxx xxxx',
                iconStyle: 'solid',
              },
            }}
          />
          <div className="sk-checkout-form__card-info">
            <StripeElement
              styles={styles.info}
              stripeElement={CardExpiryElement}
              label={formatMessage(messages.experiedDate)}
              elmProps={{
                stripeElement: {
                  placeholder: formatMessage(messages.experiedDatePlaceholder),
                },
              }}
            />
            <ToolTip iconTheme="orange">{formatMessage(messages.cvv)}</ToolTip>
            <StripeElement
              styles={styles.info}
              stripeElement={CardCvcElement}
              label="CVV"
              elmProps={{
                stripeElement: {
                  placeholder: 'CVV',
                },
              }}
            />
          </div>
          <Button
            color="secondary"
            variant="text"
            type="submit"
            className="sk-checkout-form__button"
            loading={isLoading}
            disabled={disabled || isLoading}
          >
            {formatMessage(submitButtonTranslation)}
          </Button>
        </form>
      </Card>
    );
  }
}

const enhancer = compose(
  injectStripe,
  injectIntl,
  withRouter,
  withModal,
);

export default enhancer(CardForm);
