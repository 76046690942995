// Core
import React from 'react';
import PropTypes from 'prop-types';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import classNames from 'classnames';

// Components
import Toggler from 'components/Toggler';

// Other
import { isBlank } from 'utils/helpers/index';

// Styles and Assets
import './DropDown.scss';

const paperClasses = {
  root: 'mui-override paper paper-shadow',
};

class DropDown extends React.Component {
  state = {
    open: !isBlank(this.props.isOpen) ? this.props.isOpen : false,
  };

  handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handleMenuItemClick = event => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }

    this.setState({ open: false });
  };

  closeToggle = () => {
    this.setState({ open: false });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.isOpen !== prevProps.isOpen && !isBlank(this.props.isOpen)) {
      this.setState({ open: this.props.isOpen });
    };
  };

  render() {
    const {
      children,
      component: MenuComponent,
      withToggler,
      disablePortal,
      togglerProps,
      placement,
      handleToggle,
      isOpen,
      ...rest
    } = this.props;
    const { open } = this.state;

    return (
      <div className="mui-override sk-dropdown">
        <button
          className={classNames('mui-override sk-dropdown-toggler', {'open': open})}
          type="button"
          ref={node => {
            this.anchorEl = node;
          }}
          aria-owns={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle || this.handleToggle}
        >
          {withToggler ? (
            <Toggler
              active={isOpen || open}
              chevronUpProps={togglerProps}
              chevronDownProps={togglerProps}
            >
              {children}
            </Toggler>
          ) : (
            children
          )}
        </button>
        <Popper
          open={isOpen || open}
          anchorEl={this.anchorEl}
          transition
          disablePortal={disablePortal}
          placement={placement}
          style={{ zIndex: 100 }}
        >
          {({ TransitionProps, placement: growPlacement }) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{
                transformOrigin:
                  growPlacement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper classes={paperClasses}>
                <ClickAwayListener onClickAway={this.handleMenuItemClick}>
                  <MenuComponent
                    handleMenuItemClick={this.handleMenuItemClick}
                    handleMenuToggle={this.handleToggle}
                    closeToggle={this.closeToggle}
                    {...rest}
                  />
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  }
}

export default DropDown;
