import React from "react";
import { AWSConsumer } from "containers/AWSProvider/AWSConsumer";

export function withAWS(Component) {
  return function AWSComponent(props) {
    return (
      <AWSConsumer>
        {(contexts) => {
          if (!contexts) {
            console.error("AWSContext is undefined or null");
            return <Component {...props} />; // Without AWS contexts
          }
          return <Component {...props} {...contexts} />;
        }}
      </AWSConsumer>
    );
  };
}
