// Core
import React, { memo } from 'react';
import { compose, graphql } from 'react-apollo';
import { injectIntl } from 'react-intl';

// Components
import SimpleSelect from 'components/Select/SimpleSelect';
import FilterDropDown from 'components/FilterDropDown';

// Other
import { messages } from 'routes/Course/components/ComplexitySelect/messages';

const LanguageSelect = ({
  listLanguages,
  filter,
  intl: { formatMessage },
  ...props
}) => {
  const langOptions = listLanguages?.items
    .map(({ id }) => ({
      label: formatMessage(messages[id]),
      value: id,
      disabled: filter ? !filter.includes(id.toUpperCase()) : false
    }))
    .sort((a,b) => a.disabled === false ? -1 : 0);
  const sortedLangOptions = langOptions?.sort((a, b) =>
    a.label.localeCompare(b.label)
  );

  return (
    <SimpleSelect
      options={sortedLangOptions}
      // error={error} 
      {...props}
    />
  );
};

const enhancer = compose(
  memo,
  injectIntl
);

export default enhancer(LanguageSelect);
