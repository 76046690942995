// Core
import React, { memo } from 'react';
import MenuList from '@material-ui/core/MenuList';

// Components
import CheckoutMenuItem from 'containers/Header/CheckoutBlock/CheckoutMenu/CheckoutMenuItem';

// Styles and Assets
import './index.scss';

export const CheckoutMenuList = ({
  checkoutItems,
  handleCartItemClick,
  handleRemoveCourseFromCart,
}) => (
  <div className='sk-checkout-menu-list'>
    {checkoutItems.map(({ courseId, coursePhoto, title, price,interactionLevel }, i, arr) => (
      <CheckoutMenuItem
        photo={coursePhoto || '/assets/images/сourse-photo-placeholder.jpg'}
        title={title}
        price={price}
        interactionLevel={interactionLevel}
        handleItemClick={handleCartItemClick(courseId)}
        handleItemRemove={handleRemoveCourseFromCart(arr[i])}
      />
    ))}
  </div>
);

export default memo(CheckoutMenuList);
