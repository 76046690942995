// Core
import React, { PureComponent, } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose, graphql } from 'react-apollo';

// Components
import CoursesTable from 'routes/AdminPanel/UserView/UserCourses/CoursesTable/CoursesTable';
import Loader from 'components/Loader';

// GraphQl
import GET_ALL_STUDENT_COURSES from 'queries/AdminPanel/listStudentCoursesExtra.gql';
import GET_ALL_EXPERT_COURSES from 'queries/AdminPanel/expertListCourses.gql';

// Other
// Styles and Assets
import './CoursesList.scss';

const DEFAULT_GET_COURSES_QUERY = { filter: [{ key: 'type', values: ['course'] }]};

class CoursesList extends PureComponent {
  render() {
    const { error, loading, courses, purchasedCourses = []} = this.props;

    if (loading) return (<Loader center />)
    if (courses && courses !== null && courses.length ===0)
      return (<div className='sk-user-courses-list'>This user has any courses!</div>)

    return (
      <CoursesTable
        error={error}
        loading={loading}
        courses={courses}
        purchasedCourses={purchasedCourses}
      />
    );
  }
}

const getAllStudentCourses = graphql(GET_ALL_STUDENT_COURSES, {
  skip: ({ role, profile, }) => !(!!profile),
  props: ({ data: { error, listStudentCoursesExtra, loading, refetch, ...ownProps } }) => {

    if (error) throw Error('Something went wrong, while receiving student courses');
    if (loading) return { loading, courses: [], };
    return {
      error,
      loading,
      refetchCourses: refetch,
      courses: listStudentCoursesExtra?.courses || [],
      purchasedCourses: listStudentCoursesExtra?.purchased_courses || [],
      ...ownProps,
    }
  },
  options: ({ profile }) => ({
    fetchPolicy: 'cache-first',
    variables: { input: {id: profile?.id || '', type: 'course'}},
  }),
});

const getAllExpertCourses = graphql(GET_ALL_EXPERT_COURSES, {
  skip: ({ role, profile, }) => role !== 'expert' || !(!!profile),
  props: ({ data: { error, loading, expertListCourses, refetch, ...ownProps } }) => {

    if (error) throw Error('Something went wrong, while receiving expert courses');
    if (loading) return { loading, courses: [], };

    return {
      error,
      loading,
      refetchCourses: refetch,
      courses: expertListCourses?.items || [],
      ...ownProps,
    }
  },
  options: ({ profile: { id }}) => ({
    fetchPolicy: 'cache-first',
    variables: { input: { id, query: DEFAULT_GET_COURSES_QUERY }},
  }),
});

CoursesList.propTypes = {
  error: PropTypes.object,
  loading: PropTypes.bool,
  match: PropTypes.object,
};

const enhancer = compose(
  withRouter,
  getAllStudentCourses,
  getAllExpertCourses
);

export default enhancer(CoursesList);
