import { createContext } from 'react';
export const ShoppingCartContext = createContext({
  shoppingCartContext: {
    shoppingCart: [],
    updateShoppingCart: () => {},
    createShoppingCart: () => {},
    handleRemoveCourseFromCart: () => {},
    handleAddCourseToCart: () => {},
    handleBuyNowClick: () => {},
    handleProceedClick: () => {},
    handleCartItemClick: () => {},
  },
});
