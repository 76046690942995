// Core
import React, { useState, useEffect, useCallback } from 'react';

// Components
import RoundIcon from 'components/Icon/RoundIcon';
import PhotoUploader from 'components/PhotoUploader';
import IconWithModal from 'components/IconWithModal';
import ConfirmDelete from 'routes/ExpertsCabinet/components/ConfirmDelete/ConfirmDelete';

// Icons
import ImageUpload from 'components/Icon/SvgCss/Quiz/ImageUpload.js';
import TrashBin from 'components/Icon/SvgCss/Quiz/TrashBin.js';
import DragAndDrop from 'components/Icon/SvgCss/Quiz/DragAndDrop.js';

// Other
import { messages } from 'routes/ExpertsCabinet/QuizEdit/messages';
import { isBlank } from 'utils/helpers';
import { DEFAULT_MAX_FILE_SIZE, SUPPORTED_FORMATS_IMAGE } from 'utils/enums/index';

// Styles and Assets
import './index.scss';

const IconGroup = ({
  uploaderId,
  handleImageUpload,
  handleDelete,
  withRound,
}) => {
  let ImageUploadIcon = null;
  let TrashBinIcon = null;
  let DragAndDropIconIcon = null;

  if (withRound) {
    ImageUploadIcon = (<RoundIcon type="flat" icon={<ImageUpload />} />);
    TrashBinIcon = () => (<RoundIcon type="flat" icon={<TrashBin />} />);
    DragAndDropIconIcon = () => (<RoundIcon type="flat" icon={<DragAndDrop />} />);
  } else {
    ImageUploadIcon = <ImageUpload />;
    TrashBinIcon = () => (<TrashBin />);
    DragAndDropIconIcon = () => (<DragAndDrop />);
  };

  return (
    <div className='sk-quiz-icons-group'>
      <PhotoUploader
        supportedFormats={SUPPORTED_FORMATS_IMAGE}
        maxSize={DEFAULT_MAX_FILE_SIZE}
        uploaderId={uploaderId}
        handleChange={handleImageUpload}
        icon={ImageUploadIcon}
      />
      <IconWithModal
        modal={ConfirmDelete}
        modalProps={{ size: 'md', handleDelete, customCancelButtonKey: messages.confirmModalcancelButton }}
        icon={TrashBinIcon}
      />
      <DragAndDropIconIcon />
    </div>
  )
};

export default IconGroup;
