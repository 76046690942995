// Core
import React, { PureComponent } from 'react';

// Components
import HomeworksGrid from 'routes/ExpertsCabinet/components/HomeworksGrid';
import LectureHwRow from 'routes/ExpertsCabinet/components/HWDetailsComponent/LectureHwRow';

// Other
import { sortLectures } from 'utils/helpers';

const mapCourseLectureWithHomeworks = (courseLectures, userHomeworks) => {
  const courseLecturesCopy = [ ...courseLectures ];
  const userHomeworksCopy = [ ...userHomeworks ];

  courseLecturesCopy.forEach(courseLecture => {
    const { id, homework } = courseLecture;
    const foundLectureHomework = userHomeworksCopy.find(({ lectureId }) => `${lectureId}` === `${id}`);
    const hasCourseLectureHomework = foundLectureHomework && homework && homework !== null && foundLectureHomework;

    if (!hasCourseLectureHomework) {
      courseLecture.homework = { lectureId: id, };
      return;
    }

    const { mappingId, answer, review, step } = foundLectureHomework;

    courseLecture.mappingId = mappingId;
    courseLecture.lectureId = id;
    courseLecture.homework = { answer, review, lectureId: id, step };
  })

  return courseLecturesCopy;
}

class HWDetailsComponent extends PureComponent {
  render() {
    const { lectures: userHomeworks, courseLectures, ...rest } = this.props;
    const courseLecturesWithHomework = mapCourseLectureWithHomeworks(courseLectures, userHomeworks);

    return (
      <HomeworksGrid>
        {sortLectures(courseLecturesWithHomework).map(({ ...props }, index) => (
          <LectureHwRow key={props.id} homeworkNumber={index} orderNumber={index} {...props} {...rest} />
        ))}
      </HomeworksGrid>
    );
  }
}

export default HWDetailsComponent;
