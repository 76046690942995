// Core
import React from 'react';
import PropTypes from 'prop-types';
import { OTPublisher, OTStreams, OTSubscriber } from 'opentok-react';

// Components
import WebinarVideoButton from 'containers/WebinarRoom/WebinarRoomVideo/WebinarVideoButton';
import WebinarMicButton from 'containers/WebinarRoom/WebinarRoomVideo/WebinarMicButton';
import WebinarAudioButton from 'containers/WebinarRoom/WebinarRoomVideo/WebinarAudioButton';
import WebinarScreenSharingButton from 'containers/WebinarRoom/WebinarRoomVideo/WebinarScreenSharingButton';

// Other
import { isBlank } from 'utils/helpers';
import classNames from 'classnames';

// Styles and Assets
import './WebinarRoomVideo.scss';
let publisher = null
let publisherAudio = null

const handleCycleVideo = () => {
  if (publisher) {
    publisher.state.publisher.cycleVideo().then(console.log);
  }
}

const VideoChat = ({
  participantRole,
  publisherProperties,
  eventHandlers,
  handleErrors,
  subscriberProperties,
}) => {
  const { videoSource, publishVideo } = publisherProperties;
  const isScreenCast = (videoSource === 'screen');
  const overrideVideoPublishing = isScreenCast || publishVideo;
  return(
    <>
      {participantRole === 'publisher' ? (
        <>
          <OTPublisher
            properties={{
              ...publisherProperties,
              publishVideo: overrideVideoPublishing
            }}
            eventHandlers={eventHandlers.publisher}
            onError={handleErrors}
            ref={instance => {
              publisher = instance;
            }}
          />
          {(isScreenCast) && (
            <aside
              className={classNames('sk-pip-widget', {
                'sk-pip-widget__hidden': !publishVideo
              })}>
              <OTPublisher
                properties={{
                  ...publisherProperties,
                  videoSource: undefined,
                  width: 320,
                  height: 240
                }}
              />
            </aside>
          )}
        </>
      ) : (
        <OTStreams>
          <OTSubscriber properties={subscriberProperties} />
        </OTStreams>
      )}
    </>
  )
};

const VideoChatPlaceholder = ({
  coursePhoto,
  showPlaceholder,
  component: Component,
  ...rest
}) => (
  <>
    {showPlaceholder ? (
      <Component {...rest} />
    ) : (
      <div
        className="sk-image"
        {...rest}
        style={{ backgroundImage: `url(${coursePhoto})` }}
      />
    )}
  </>
);

const WebinarRoomVideo = ({
  publishVideo=false,
  publishAudio=false,
  videoSource='camera',
  handleVideoToggle,
  handleMicToggle,
  subscribeToVideo,
  subscribeToAudio,
  handleAudioToggle,
  handleVideoSourceToggle,
  handleVideoSubscription,
  participantRole,
  webinarStarted,
  publisherIsOnAir,
  handleErrors,
  coursePhoto,
}) => {
  const sharedVideoProps = {
    audioFallbackEnabled: false,
    showControls: false,
    width: '100%',
    height: '100%',
    fitMode: 'contain',
    layout: {
      type: 'pip'
    }
  };

  const publisherProperties = {
    ...sharedVideoProps,
    audioFallbackEnabled: false,
    videoSource: (videoSource === 'camera') ? undefined : videoSource,
    publishAudio,
    publishVideo,
    cameraPosition: 'front',
    resolution: '1280x720',
  };

  const subscriberProperties = {
    ...sharedVideoProps,
    subscribeToVideo,
    subscribeToAudio,
    preferredResolution: { width: '1280', height: '720' },
    mirror: false,
  };

  const eventHandlers = {
    publisher: {
      mediaStopped: (event) => {
        console.log('event:mediaStopped', event);
      },
      videoElementCreated: (event) => {
        console.log('event:videoElementCreated', event);
      },
      streamCreated: (event) => {
        console.log('event:streamCreated', event);
      },
      streamDestroyed: (event) => {
        console.log('event:streamDestroyed', event);
      },
      accessDialogOpened: (event) => {
        console.log('event:accessDialogOpened', event);
      },
      accessDialogClosed: (event) => {
        console.log('event:accessDialogClosed', event);
      }
    }
  }

  const videoButtonProps = {
    publisher: {
      handleClick: handleVideoToggle,
      onAir: publishVideo,
    },
    subscriber: {
      handleClick: handleVideoSubscription,
      onAir: subscribeToVideo,
    },
  };

  const controlsIsVisible =
    participantRole === 'subscriber' ? publisherIsOnAir : true;
  return (
    <div className="sk-web-room-video">
      <div className="sk-web-room-video__container">
        {(webinarStarted || publisherIsOnAir) ? (
          <VideoChat
            participantRole={participantRole}
            publisherProperties={publisherProperties}
            subscriberProperties={subscriberProperties}
            eventHandlers={eventHandlers}
            handleErrors={handleErrors}
          />
        ) : (
          <VideoChatPlaceholder
            coursePhoto={coursePhoto}
            showPlaceholder={!isBlank(coursePhoto) && (publishVideo || publisherIsOnAir)}
            component={
              participantRole === 'subscriber' ? OTSubscriber : OTPublisher
            }
            properties={
              participantRole === 'subscriber'
                ? subscriberProperties
                : publisherProperties
            }
          />
        )}
      </div>
      {controlsIsVisible ? (
        <div className="sk-web-room-video__controls">
          <div className="sk-web-room-video__control-btn">
            <WebinarVideoButton {...videoButtonProps[participantRole]} />
          </div>
          {participantRole === 'publisher' && (
            <div className="sk-web-room-video__control-btn">
              <WebinarScreenSharingButton
                handleClick={handleVideoSourceToggle}
                source={videoSource}
              />
            </div>
          )}
          <div className="sk-web-room-video__control-btn">
            {participantRole === 'subscriber' ? (
              <WebinarAudioButton
                handleClick={handleAudioToggle}
                onAir={subscribeToAudio}
              />
            ) : (
              <WebinarMicButton
                handleClick={handleMicToggle}
                onAir={publishAudio}
              />
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};

WebinarRoomVideo.defaultProps = {
  publishVideo: true,
  publishAudio: true,
  videoSource: 'camera',
};

WebinarRoomVideo.propTypes = {
  publishVideo: PropTypes.bool,
  publishAudio: PropTypes.bool,
  handleVideoToggle: PropTypes.func,
  handleVideoSourceToggle: PropTypes.func,
  handleMicToggle: PropTypes.func,
  videoSource: PropTypes.string,
};

export default WebinarRoomVideo;
