// Core
import React, { memo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';
import { injectIntl } from 'react-intl';

// Components
import ContentHeader from 'containers/ContentHeader';
import CalendarTimezonesSelect from 'containers/Calendar/CalendarContentHeader/CalendarTimezonesSelect';
import Button from 'components/Button'; // Import Button

// Styles and Assets
import './CalendarContentHeader.scss';
import { messages } from '../messages';

const CalendarContentHeader = ({
  isThereWebinars,
  intl,
  onOpenCreateEventModal
}) => (
  <ContentHeader
    classes={classNames('sk-calendar-content-header', {
      'sk-calendar-content-header_short': isThereWebinars
    })}
    way={intl.formatMessage(messages.webinarSectionTitle)}
    actionBlock={
      <>
        <CalendarTimezonesSelect />
        <Button
          variant='contained'
          color='primary'
          onClick={onOpenCreateEventModal}
        >
          Create Event
        </Button>
      </>
    }
  />
);

CalendarContentHeader.propTypes = {
  isThereWebinars: PropTypes.bool,
  intl: PropTypes.object,
  // onOpenCreateEventModal: PropTypes.func.isRequired,
};

const enhancer = compose(memo, injectIntl);

export default enhancer(CalendarContentHeader);
