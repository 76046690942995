import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  done: {
    id: 'app.components.StatusBlock.done',
    defaultMessage: 'Done',
  },
  toMark: {
    id: 'app.components.StatusBlock.toMark',
    defaultMessage: 'To mark',
  },
});
