import React, { useEffect, useState } from 'react';
import { compose, graphql } from 'react-apollo';
import { injectIntl } from 'react-intl';

// Other
import { isBlank } from 'utils/helpers';

const enhancer = compose(injectIntl);

export const withFormattedUntitled = WrappedComponent => {
  const HOC = ({
    intl,
    intl: { formatMessage },
    ...props
  }) => {
    const formatUntitled = (untitled, untitledKey) => {
      if (isBlank(untitled) || (!isBlank(untitled) && untitled.toLowerCase() === 'untitled')) {
        return formatMessage(untitledKey);
      } else {
        return untitled;
      }
    };

    return <WrappedComponent {...props} intl={intl} formatUntitled={formatUntitled} />;
  };

  return enhancer(HOC);
};
