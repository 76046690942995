// Core
import React, { memo, useState, useRef, useEffect, useCallback } from 'react';
import ReactPlayer from 'react-player';
import classNames from 'classnames';
import { findDOMNode } from 'react-dom'
import screenfull from 'screenfull'
import { AUDIO_FILE } from 'utils/enums/index.js';
import { isBlank } from 'utils/helpers';
import { compose } from 'react-apollo';

// Components
import VideoControllers from './VideoControllers';

// HOCs
import { withFileUrl } from 'containers/HOCs/withFileUrl';

// Styles
import './VideoModule.scss';

export const VideoModule = ({
  fileUrl,
  isLoaded,
  posterLink,
  videoStartTime = 0,
  onPauseHandler = null,
  filetype,
  customClasses,
  autoplay,
  handleMediaEnd,
  withControl = true,
  isLoop = false,
  muted=false,
}) => {
  const [isVideoReady, setVideoReady] = useState(false);
  const [isPlaying, setPlay] = useState(false);
  const [isMuted, setMuted] = useState(muted);
  const [isFullScreen, setFullScreen] = useState(false);
  const [currentVideoTime, setCurrentVideoTime] = useState(0);
  const [loadedVideoTime, setLoadedVideoTime] = useState(0);
  const [durrationVideoTime, setDurrationVideoTime] = useState(0);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [volumeLevel, setVolumeLevel] = useState(1);

  const fileType = filetype === AUDIO_FILE ? 'audio' : 'video';

  const playerEl = useRef(null);
  const videoModuleEl = useRef(null);
  const handleVideo = useCallback(() => {
    setPlay(!isPlaying);
  }, [isPlaying, setPlay, playerEl.current]);

  useEffect(() => {
    if (isVideoReady && autoplay) {
      setPlay(true);
    }
  }, [isVideoReady, autoplay, setPlay]);

  useEffect(() => {
    if (isVideoReady && videoStartTime > 0 && playerEl.current instanceof ReactPlayer) {
      playerEl.current.seekTo(videoStartTime, 'seconds');
      setCurrentVideoTime(videoStartTime);
    }
  }, [isVideoReady, videoStartTime, setCurrentVideoTime, playerEl.current]);

  const handleOnReady = useCallback(() => {
    if (isLoaded) {
      setDurrationVideoTime(playerEl.current.getDuration());
      setVideoReady(true);
    }
  }, [isLoaded, setDurrationVideoTime, setVideoReady, playerEl?.current?.getDuration]);

  const pauseHandler = useCallback(async (event) => {
    if (!isBlank(onPauseHandler) && isVideoReady) {
      await onPauseHandler(currentVideoTime);
    }
  }, [isPlaying, isVideoReady, onPauseHandler, currentVideoTime, setPlay]);

  const handleOnEnded = useCallback(() => {
    setPlay(false);
    if (handleMediaEnd) {
      handleMediaEnd();
    }
  }, [setPlay]);

  const muteVideoHandler = () => setMuted(!isMuted);
  const setFullScreenHandler = async () => {
    screenfull.toggle(findDOMNode(videoModuleEl.current));
    setFullScreen(!isFullScreen);
  };
  const onPlayHandler = useCallback(({ playedSeconds, loadedSeconds }) => {
    setCurrentVideoTime(playedSeconds);
    setLoadedVideoTime(loadedSeconds);
  }, [setCurrentVideoTime, setLoadedVideoTime]);
  const videoSeekToHandler = time => playerEl.current.seekTo(time, 'seconds');
  const setPlaybackSpeedHandler = speed => setPlaybackRate(speed);

  return (
    <div
      ref={videoModuleEl}
      className={classNames(
        'sk-video-module',
        fileType,
        customClasses
      )}
    >
      <ReactPlayer
        ref={playerEl}
        playing={isPlaying}
        url={fileUrl}
        width="100%"
        height="100%"
        playbackRate={playbackRate}
        controls={false}
        onEnded={handleOnEnded}
        onReady={handleOnReady}
        onPause={pauseHandler}
        onProgress={onPlayHandler}
        loop={isLoop}
        muted={isMuted}
        volume={volumeLevel}
        className="react-player"
        config={{
          file: {
            attributes: {
              poster: posterLink,
            },
          },
        }}
      />

      {withControl && <VideoControllers
          mediaType={fileType}
          volumeLevel={volumeLevel}
          isMuted={isMuted}
          isPlay={isPlaying}
          isFullScreen={isFullScreen}
          currentTime={currentVideoTime}
          loadedTime={loadedVideoTime}
          duration={durrationVideoTime}
          playControll={handleVideo}
          muteVideo={muteVideoHandler}
          setFullScreen={setFullScreenHandler}
          videoSeekTo={time => videoSeekToHandler(time)}
          setPlaybackSpeedProp={speed => setPlaybackSpeedHandler(speed)}
          setVolumeLevel={setVolumeLevel}
        />
      }
    </div>
  );
};

const enhancer = compose(
  memo,
  withFileUrl,
);

export default enhancer(VideoModule);
