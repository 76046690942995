import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  evaluateButton: {
    id: 'app.components.ToppingDetails.evaluateButton',
    defaultMessage: "Evaluate",
  },
  students: {
    id: 'app.components.masterClassCarousel.students',
    defaultMessage: "students",
  },
  studentsCabinet: {
    id: 'app.components.ToppingDetails.studentsCabinet',
    defaultMessage: "Student`s Cabinet",
  },
  toppings: {
    id: "app.components.CourseSidebar.toppings",
    defaultMessage: "Toppings"
  },
  quiz: {
    id: "app.routes.ExpertsCabinet.QuizEdit.quiz",
    defaultMessage: "Quiz"
  },
  takeTest: {
    id: "app.routes.ExpertsCabinet.QuizEdit.takeTest",
    defaultMessage: "Solving quiz"
  },
  expertCourses: {
    id: 'app.components.masterClassCarousel.expertCourses',
    defaultMessage: "Expert's courses"
  },
  author: {
    id: 'app.components.Author.author',
    defaultMessage: 'Expert',
  }
});
