import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  youDontHaveAnyCourses: {
    id: 'app.components.PurchasedCourses.youDontHaveAnyCourses',
    defaultMessage: "You don't have any courses yet."
  },
  goToCoursesCatalog: {
    id: 'app.components.PurchasedCourses.goToCoursesCatalog',
    defaultMessage: 'Courses'
  },
  lecture: {
    id: 'app.components.CurriculumSummary.lecture',
    defaultMessage: 'Lecture'
  },
  chat: {
    id: 'app.components.PurchasedCourses.chat',
    defaultMessage: 'Chat'
  },
  startChat: {
    id: 'app.components.PurchasedCourses.startChat',
    defaultMessage: 'Start chat'
  },
  materialLockedDuePolicy: {
    id: 'app.components.PurchasedCourses.materialLockedDuePolicy',
    defaultMessage: 'Study materials will be available after previous Homework is submitted'
  }
});
