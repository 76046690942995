// Core
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Components
import ChevronLeft from 'components/Icon/Svg/ChevronLeft';
import RoundIcon from 'components/Icon/RoundIcon';

// Other
import { White as iconColor } from 'components/Icon/color';
import { withSidebar } from 'containers/SidebarProvider/withSidebar';

// Styles and Assets
import './SidebarToggleButton.scss';

class SidebarToggleButton extends PureComponent {
  handleTogglerClick = () => {
    const {
      sidebarContext: { showSidebar, hideSidebar, openedSidebar },
    } = this.props;

    return openedSidebar === 'main' ? hideSidebar() : showSidebar('main');
  };

  render() {
    const {
      sidebarContext: { openedSidebar },
    } = this.props;
    const expanded = openedSidebar === 'main';

    const iconProps = {
      color: iconColor,
      width: '13',
      height: '23',
    };

    return (
      <button
        type="button"
        onClick={this.handleTogglerClick}
        className={classNames('sk-sidebar-toggle-btn', {
          'sk-sidebar-toggle-btn_expanded': expanded,
        })}
      >
        <RoundIcon
          type="accent"
          icon={<ChevronLeft {...iconProps} />}
          expanded={expanded}
          chevron
        />
      </button>
    );
  }
}

SidebarToggleButton.propTypes = {
  sidebarContext: PropTypes.object,
};

export default withSidebar(SidebarToggleButton);
