import React, { memo } from 'react';
import { compose } from 'react-apollo';

const Icon = ({ ...props }) => {
  return (
<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.81697 3.44141C5.31264 3.44141 4.91211 3.84193 4.91211 4.34627V19.2621C4.91211 20.4063 5.84931 21.3485 7.00037 21.3637L7.00246 21.3637L17.3681 21.3984H17.3686C18.5318 21.3981 19.4697 20.46 19.4697 19.2968V9.00561L15.7871 8.97386C15.0958 8.97258 14.5437 8.41966 14.5437 7.72807V3.44141H5.81697ZM15.5437 4.02695L18.948 8.00107L15.7938 7.97388V7.97386H15.7895C15.6494 7.97386 15.5437 7.86818 15.5437 7.72807V4.02695ZM3.91211 4.34627C3.91211 3.28965 4.76035 2.44141 5.81697 2.44141H14.6277C15.1902 2.44141 15.7113 2.67892 16.0841 3.12062L20.0199 7.71526L20.0253 7.72164L20.0306 7.72819C20.3035 8.06933 20.4697 8.48989 20.4697 8.95977V19.2968C20.4697 21.0125 19.0838 22.3984 17.3681 22.3984L17.3664 22.3984L6.99469 22.3637L6.99027 22.3637C5.29787 22.343 3.91211 20.9616 3.91211 19.2621V4.34627ZM7.35329 9.79435C7.07714 9.79435 6.85329 10.0182 6.85329 10.2943C6.85329 10.5705 7.07714 10.7943 7.35329 10.7943H16.9121C17.1883 10.7943 17.4121 10.5705 17.4121 10.2943C17.4121 10.0182 17.1883 9.79435 16.9121 9.79435H7.35329ZM6.85329 13.2355C6.85329 12.9594 7.07714 12.7355 7.35329 12.7355H16.9121C17.1883 12.7355 17.4121 12.9594 17.4121 13.2355C17.4121 13.5117 17.1883 13.7355 16.9121 13.7355H7.35329C7.07714 13.7355 6.85329 13.5117 6.85329 13.2355ZM7.35329 15.6767C7.07714 15.6767 6.85329 15.9006 6.85329 16.1767C6.85329 16.4528 7.07714 16.6767 7.35329 16.6767H11.0298C11.3059 16.6767 11.5298 16.4528 11.5298 16.1767C11.5298 15.9006 11.3059 15.6767 11.0298 15.6767H7.35329Z" fill="#610FB6"/>
</svg>

  );
};

const enhancer = compose(
  memo,
);

export default enhancer(Icon);
