// Core
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose, graphql } from 'react-apollo';

// Components
import SimpleSelect from 'components/Select/SimpleSelect';
import FilterDropDown from 'components/FilterDropDown';

// Other
import { FormattedMessage, injectIntl } from 'react-intl';
import { messages } from 'routes/Course/components/ComplexitySelect/messages';

// GraphQl
import LIST_LANGUAGES_CATEGORIES_QUERY from 'queries/Courses/listLanguageCategories.gql';

class LanguageSelect extends PureComponent {
  render() {
    const { error, type, listLanguages, intl, filter, ...props } = this.props;
    const langOptions = listLanguages?.items
      .map(({ id }) => ({
        label: intl.formatMessage(messages[id]),
        value: id,
        disabled: filter ? !filter.includes(id.toUpperCase()) : false
      }))
      .sort((a,b) => a.disabled === false ? -1 : 0);
    const sortedLangOptions = langOptions?.sort((a, b) =>
      a.label.localeCompare(b.label)
    );

    return (
      <>
        {type === 'simple' ? (
          <SimpleSelect options={sortedLangOptions} error={error} {...props} />
        ) : (
          <FormattedMessage {...messages.anyLanguage}>
            {msg => (
              <FilterDropDown
                minor
                options={langOptions}
                placeholder={msg}
                valueKey="value"
                labelKey="label"
                {...props}
                isPlaceholderOptionHidden={true}
              />
            )}
          </FormattedMessage>
        )}
      </>
    );
  }
}

const listLanguagesCategories = graphql(LIST_LANGUAGES_CATEGORIES_QUERY, {
  skip: props => !!props.skip,
  props: ({
    data: { loading, error: fetchError, listLanguages, ...ownProps },
  }) => ({
    loading,
    listLanguages,
    fetchError,
    ...ownProps,
  }),
  options: () => ({
    variables: { limit: 100 },
  }),
});

LanguageSelect.propTypes = {
  type: PropTypes.string,
};

LanguageSelect.defaultProps = {
  type: 'simple',
};

const enhancer = compose(
  listLanguagesCategories,
  injectIntl
);

export default enhancer(LanguageSelect);
