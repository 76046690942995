// Core
import React from 'react';

// Components
import Heading from 'components/Heading';
import Button from 'components/Button';
import Text from 'components/Text';
import Done from 'components/Icon/Svg/Done';
import Link from 'components/Link';
import Card from 'components/Card';

import { FormattedMessage } from 'react-intl';
import { messages } from 'services/yup/messages';

// Styles and Assets
import './SuccessfulChangeMessage.scss';

const SuccessfulChangeMessage = () => (
  <Card classes="sk-pw-change-card">
    <div className="sk-change-success">
      <div className="sk-change-success_header">
        <div className="sk-change-success_icon">
          <Done width="51" height="51" />
        </div>
        <Heading variant="h4" classes="sk-change-success_heading">
          <FormattedMessage {...messages.successful} />
        </Heading>
        <Text classes="sk-change-success_subheading">
          <FormattedMessage {...messages.yourPwChanged} />
        </Text>
      </div>
      <div className="sk-change-success_footer">
        <Link to="/manage-account/personal-info">
          <Button variant="text" color="secondary" size="large">
            <FormattedMessage {...messages.backToPersonalCabinet} />
          </Button>
        </Link>
      </div>
    </div>
  </Card>
);

export default SuccessfulChangeMessage;
