import React from 'react';

const Icon = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="2.94118" y="2.20587" width="19.1176" height="14.1612" rx="3" stroke="#A9B6C8"/>
      <path d="M6.32002 6.66516C6.04388 6.66516 5.82002 6.88902 5.82002 7.16516C5.82002 7.4413 6.04388 7.66516 6.32002 7.66516L6.32002 6.66516ZM18.4835 7.66516C18.7596 7.66516 18.9835 7.4413 18.9835 7.16516C18.9835 6.88902 18.7596 6.66516 18.4835 6.66516L18.4835 7.66516ZM6.32002 7.66516L18.4835 7.66516L18.4835 6.66516L6.32002 6.66516L6.32002 7.66516Z" fill="#A9B6C8"/>
      <path d="M6.32002 9.39227C6.04388 9.39227 5.82002 9.61613 5.82002 9.89227C5.82002 10.1684 6.04388 10.3923 6.32002 10.3923L6.32002 9.39227ZM9.69877 10.3923C9.97491 10.3923 10.1988 10.1684 10.1988 9.89227C10.1988 9.61613 9.97491 9.39227 9.69877 9.39227L9.69877 10.3923ZM6.32002 10.3923L9.69877 10.3923L9.69877 9.39227L6.32002 9.39227L6.32002 10.3923Z" fill="#A9B6C8"/>
      <path d="M14.2242 19.0169V15.7118V15.6116C14.2242 15.3355 14.4481 15.1116 14.7242 15.1116H16.1569C16.433 15.1116 16.6569 15.3355 16.6569 15.6116V19.0169C16.6569 19.4608 16.1216 19.6846 15.8057 19.3728L15.7918 19.3591C15.597 19.1669 15.2841 19.1669 15.0894 19.3591L15.0754 19.3728C14.7595 19.6846 14.2242 19.4608 14.2242 19.0169Z" fill="white" stroke="#A9B6C8"/>
      <path d="M17.9824 13.2872C17.9824 14.6905 16.8448 15.8281 15.4415 15.8281C14.0382 15.8281 12.9006 14.6905 12.9006 13.2872C12.9006 11.8839 14.0382 10.7463 15.4415 10.7463C16.8448 10.7463 17.9824 11.8839 17.9824 13.2872Z" fill="white" stroke="#A9B6C8"/>
    </svg>
  );
};

export default Icon;
