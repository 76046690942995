// Core
import React, { PureComponent } from 'react';
import { compose, graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';

// Styles and Assets
import './PurchasedCourses.scss';

// GraphQL
import GET_COURSE_LIST from 'queries/Courses/studentListCourses.gql';
import GET_STUDENT_COURSE from 'queries/Courses/getStudentCourse.gql';

// Components
import PurchasedCourseMaterials from 'routes/StudentsCabinet/PurchasedCourses/PurchasedCourseMaterials';
import Loader from 'components/Loader';
import Button from 'components/Button';
import Text from 'components/Text';
import Link from 'components/Link';

// Other
import { FormattedMessage } from 'react-intl';
import { messages } from 'routes/StudentsCabinet/PurchasedCourses/messages';
import { parseFetchedData, } from 'utils/helpers';

class PurchasedCourses extends PureComponent {
  render() {
    const { purchasedCourse, loading, match, refetchStudentCourse } = this.props;
    if (loading) return <Loader />;

    if (!purchasedCourse && !purchasedCourse.course)
      return (
        <div className="sk-purchasedCourses__empty">
          <div className="sk-purchasedCourses-empty-box">
            <div className="sk-purchasedCourses-empty-box__item">
              <Text>
                <FormattedMessage {...messages.youDontHaveAnyCourses} />
              </Text>
            </div>
            <div className="sk-purchasedCourses-empty-box__item">
              <Link to="/courses">
                <Button>
                  <FormattedMessage {...messages.goToCoursesCatalog} />
                </Button>
              </Link>
            </div>
          </div>
        </div>
      );
    return <PurchasedCourseMaterials
      purchasedCourse={purchasedCourse}
      refetchStudentCourse={refetchStudentCourse}
    />;
  }
}

const getStudentCourse = graphql(GET_STUDENT_COURSE, {
  props: ({ data: { getStudentCourse, loading, error, refetch, ...ownProps } }) => {
    if (error) throw Error(error);
    if (loading) return { loading, error };
    const { course } = getStudentCourse;

    // Merge purchased and course data
    // const purchasedCourses = getStudentCourse
    //   .filter(purchasedCourse => {
    //     const courseData = courses.find(course => course.id === purchasedCourse.course_id);
    //     purchasedCourse.course_title = courseData?.title || 'no title';
    //     purchasedCourse.messenger_url = courseData?.messenger_url || '';
    //     return !!courseData && courseData?.type == 'course';
    //   })
    //   .map(course => parseFetchedData(course));

    const courseExtra = {};

    if (getStudentCourse !== null) {
      courseExtra.messenger_url = getStudentCourse?.course?.messenger_url || '';
      courseExtra.course_title = getStudentCourse?.course?.title || 'UNTITLED';
    }

    return {
      loading,
      purchasedCourse: parseFetchedData({...getStudentCourse, ...courseExtra}),
      refetchStudentCourse: refetch,
      ...ownProps,
    }
  },
  options: ({ match }) => ({
    // pollInterval: 60000,
    variables: { id: match.params.id },
    fetchPolicy: 'network-only',
  }),

});

const enhancer = compose(
  withRouter,
  getStudentCourse
);

export default enhancer(PurchasedCourses);
