import React from 'react';

const Icon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="10.8057" width="4.84582" height="8.35331" rx="0.5" stroke="#A9B6C8"/>
      <rect x="7.51451" y="4.95996" width="4.84582" height="14.1991" rx="0.5" stroke="#A9B6C8"/>
      <rect x="14.5295" y="1.45239" width="4.84582" height="17.7066" rx="0.5" stroke="#A9B6C8"/>
    </svg>
  );
};

export default Icon;
