// Core
import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

// Components
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import Card from "components/Card";
import Loader from "components/Loader";

// Components
import Text from "components/Text";
import CategoriesListTableRow from "routes/AdminPanel/Categories/CategoriesList/CategoriesListTable/CategoriesListTableRow/";

// Other
import { Grey4, Black } from "components/Icon/color";

// Styles and Assets
import "./CategoriesDetails.scss";

const classes = {
  table: {
    root: "mui-override sk-admin-discounts-tablee",
  },
};

class DiscountsTable extends PureComponent {
  state = {
    anchorEl: null,
  };

  renderTableBody = (items) => {
    return items
      .sort((a, b) => a?.order_number - b?.order_number)
      .map((item, index, arr) => (
        <Fragment key={item.id}>
          <CategoriesListTableRow
            key={item.id}
            item={item}
            index={index}
            arr={arr}
          />
        </Fragment>
      ));
  };

  // handleSort = type => async () => {
  //   const { handleSort, sortBy, sortOrder, } = this.props;
  //
  //   const newSortByFieldName = type;
  //   const newSortOrder = sortBy === type && NEXT_SORT_ORDER[sortOrder] ? NEXT_SORT_ORDER[sortOrder] : ACSENDING;
  //
  //   await handleSort({ sortBy: newSortByFieldName, sortOrder: newSortOrder });
  // }
  //
  // handleSortByTitle = this.handleSort(SORT_TYPES['title'])
  // handleSortByCategory = this.handleSort(SORT_TYPES['category'])
  // handleSortBySubcategory = this.handleSort(SORT_TYPES['subcategory'])
  // handleSortByStatus = this.handleSort(SORT_TYPES['status'])
  // handleSortByAuthor = this.handleSort(SORT_TYPES['author'])

  render() {
    const { categories, values, sortBy, sortOrder, filters, loading } = this.props;
    const { anchorEl } = this.state;
    console.log('categories', categories)
    console.log('calues', values)
    const headerHeight = 63;
    const rowHeight = 63;
    const rowsCount = +categories?.length || 0;

    if (loading) return <Loader center />;

    return (
      <Card>
        <Table
          className="mui-override sk-admin-discounts-tablee"
          classes={classes.table}
          style={{ height: headerHeight + rowHeight * rowsCount }}
        >
          <TableHead>
            <TableRow>
              <TableCell
                classes={classes.table}
                className="mui-override sk-admin-discounts-tablee__cell"
              >
                <div
                  className="mui-override sk-admin-discounts-tablee__cell__elements"
                  style={{ justifyContent: "center" }}
                >
                  <Text bold align="center">
                    Default Name
                  </Text>
                </div>
              </TableCell>
              <TableCell
                classes={classes.table}
                className="mui-override sk-admin-discounts-tablee__cell"
              >
                <div className="mui-override sk-admin-discounts-tablee__cell__elements">
                  <Text bold align="left">
                    Localization Key
                  </Text>
                </div>
              </TableCell>
              <TableCell
                classes={classes.table}
                className="mui-override sk-admin-discounts-tablee__cell"
              >
                <div className="mui-override sk-admin-discounts-tablee__cell__elements">
                  <Text bold align="left">
                    Order
                  </Text>
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
          {loading ? (
            <Loader center />
          ) : (
            <TableBody>
              <Fragment>
                {categories && categories.length > 0
                  ? this.renderTableBody(categories)
                  : null}
              </Fragment>
            </TableBody>
          )}
        </Table>
      </Card>
    );
  }
}

DiscountsTable.propTypes = {
  categories: PropTypes.array,
};

export default DiscountsTable;
