// Core
import React, { useState, useEffect, useCallback } from 'react';
import { compose, graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';

// Components
import Text from 'components/Text';
import FilterForm from 'routes/components/Filters/FilterForm';
import ToppingCard from 'routes/components/ToppingCard';
import FlatDropDown from 'components/DropDown/FlatDropDown';
import FiltersIcon from 'components/Icon/Svg/FiltersIcon'

// Other
import { keysToCamel } from 'utils/helpers';
import { setCheckedOptions } from 'routes/components/Filters/helpers.js';
import { sharedMessages } from 'services/i18n/sharedMessages/messages';

// Styles
import './index.scss';

const COMPONENT_PREFIX = 'sk-expert-filter-status';
const StatusFilter = ({
  filter,
  searchToppings,
  options = [],
  intl: { formatMessage },
}) => {
  const type = 'status_id';
  const statusFilter = filter.find(filter => filter.key == type);
  if(statusFilter?.values.includes("SECOND_REJECT")&& !statusFilter?.values.includes("FIRST_REJECT")){
    statusFilter.values = [];
  }
  const [filters, setFilters] = useState(statusFilter?.values || []);
  const [isOpen, setOpen] = useState(false);

  const setFiltersHandler = (name) => (isTypeChecked, event) => {
    let newChecked = [...filters];

    if (isTypeChecked) {
      newChecked = [...newChecked, name];
    } else {
      newChecked = newChecked.filter(checkboxName => checkboxName !== name);
    };

    setFilters(newChecked);
  };
  const handleApply = useCallback(() => {
    if((filters?.includes("SECOND_REJECT")&& !filters?.includes("FIRST_REJECT")) || !filters || !filters?.length){
      setFilters([])
      searchToppings({
        key: type,
        values: undefined
      });
    }else {
      searchToppings({
        key: type,
        values: filters.length ? filters : options.map(opt => opt.value),
      });
    }
   
    toggle();
  }, [filters, searchToppings]);

  const toggle = () => {
    setOpen(!isOpen);
  }

  return(
    <div className={COMPONENT_PREFIX}>
      <FlatDropDown
        type={type}
        placement='bottom-end'
        component={FilterForm}
        options={setCheckedOptions(options, filters)}
        keyName='value'
        labelName='label'
        handleChange={setFiltersHandler}
        handleApply={handleApply}
        button={formatMessage(sharedMessages.applyButton)}
        handleToggle={toggle}
        isOpen={isOpen}
      >
        <FiltersIcon />
        <Text>{formatMessage(sharedMessages.toppingStatusFilter)}</Text>
      </FlatDropDown>
    </div>
  )
};

export default injectIntl(StatusFilter);
