// Core
import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, } from 'react-intl';
import { compose } from 'react-apollo';

// Components
import SimpleSelect from 'components/Select/SimpleSelect';
import Toggler from 'components/Select/Toggler/Toggler';
import Input from 'components/Select/MaterialUI/Input';

// Other
import { Grey } from 'components/Icon/color';
import { messages } from 'routes/Course/components/LecturesCounter/messages';
import { withCourseEditContext } from 'routes/ExpertsCabinet/CourseEdit/Context';

// Styles and Assets
import * as materialUISelectInlineStyles from './Selector.style';
import './LectureTitleTypeSelector.scss';

const LectureTitleTypeSelector = ({
  value,
  refetchCourse,
  intl: { formatMessage },
  courseEditContext: { handleCourseUpdate },
  ...props
}) => {
  const [type, setType] = useState(value);
  const updateOnBlur = async () => {
    await handleCourseUpdate({ displayLectureNameType: type })
    await refetchCourse()
  }

  const options = [
    { value: 'lecture', label: formatMessage(messages.lectureOption) },
    { value: 'section', label: formatMessage(messages.sectionOption) },
  ]

  return (
    <div className="sk-simple-selector">
      <SimpleSelect
        inlineStyles={materialUISelectInlineStyles}
        value={type}
        valueKey="value"
        labelKey="label"
        options={options}
        handleChange={(nextType) => setType(nextType)}
        handleBlur={updateOnBlur}
      />
    </div>
  );
};

LectureTitleTypeSelector.propTypes = {
};

const enhancer = compose(
  memo,
  injectIntl,
  withCourseEditContext
)
export default enhancer(LectureTitleTypeSelector);
