// Core
import React from 'react';
import { injectIntl } from 'react-intl';

// Components
import Image from 'components/Image';
import UserAvatar from 'components/UserAvatar';
import Text from 'components/Text';
import ReadMore from 'components/ReadMore'

// Other
import { messages } from 'routes/StudentsCabinet/ToppingDetails/messages.js';
import { isBlank } from 'utils/helpers';

// Styles
import './Author.scss';

const PREFIX_COMPONENT = `sk-topping-details_author`;

const Author = ({
  firstName,
  lastName,
  photo,
  expertBio,
  aboutMe,
  intl: { formatMessage },
}) => {
  const aboutExpert = !isBlank(aboutMe) ? aboutMe : expertBio;
  return(
    <div className={`${PREFIX_COMPONENT}`}>
      <div className={`${PREFIX_COMPONENT}__top`}>
        <div className={`${PREFIX_COMPONENT}__photo`}>
          <UserAvatar
            avatar={photo}
            classes="sk-courseDetails-author__useravatar"
            firstName={firstName}
            lastName={lastName}
          />
        </div>
        <div className={`${PREFIX_COMPONENT}__name`}>
          <Text className={`${PREFIX_COMPONENT}__name_string`}>{firstName} {lastName}</Text>
          <Text className={`${PREFIX_COMPONENT}__name_position`}>{formatMessage(messages.author)}</Text>
        </div>
      </div>
      <div className='text'>
        {aboutExpert}
      </div>
    </div>
  );
};

export default injectIntl(Author);
