import React from 'react';
import { ShoppingCartContext } from './shoppingCart-context';

export function withShoppingCart(Component) {
  return function ShoppingCartComponent(props) {
    return (
      <ShoppingCartContext.Consumer>
        {contexts => <Component {...props} {...contexts} />}
      </ShoppingCartContext.Consumer>
    );
  };
}
