// Core
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';
import ReactHtmlParser from 'react-html-parser';

// Components
import Text from 'components/Text';
import Heading from 'components/Heading';

// Locales
import EN from 'services/i18n/expertAgreement/en.json';
import RU from 'services/i18n/expertAgreement/ru.json';

// Styles and Assets
import './PolicyText.scss';

import { withLanguage } from 'containers/LanguageProvider/withLanguage';

// Utils
import { findOneOf } from 'utils/helpers/';

const contentTypes = ['paragraphs', 'ordered_list_letters', 'unordered_list'];

let keyCounter = 0;

const renderList = list =>
  list.map(text => {
    keyCounter += 1;
    return (
      <Text
        key={keyCounter}
        size="caption"
        style={{ padding: '6px 0', display: 'block', textAlign: 'justify' }}
        classes="sk-privacy-policy-text__title"
      >
        <li>
          <Text size="caption" classes="sk-privacy-policy-text__title">
            {ReactHtmlParser(text)}
          </Text>
        </li>
      </Text>
    );
  });

const renderParagraph = paragraphs =>
  paragraphs.map(text => {
    keyCounter += 1;
    return (
      <Text
        key={keyCounter}
        size="caption"
        classes="sk-privacy-policy-text__title"
        style={{ padding: '10px 0', display: 'block', textAlign: 'justify' }}
      >
        {ReactHtmlParser(text)}
      </Text>
    );
  });

const renderUnorderedList = content => (
  <ul style={{ listStyle: 'disc', padding: '0 16px' }}>
    {renderList(content)}
  </ul>
);
const renderOrderedListLetters = content => (
  <ol style={{ paddingLeft: 12, fontSize: '12px' }} type="1">{renderList(content)}</ol>
);

const contentRenderFunctions = {
  paragraphs: renderParagraph,
  unordered_list: renderUnorderedList,
  ordered_list_letters: renderOrderedListLetters,
};

const PolicyText = ({ languageContext: { language } }) => {
  const currentLocaleConfig = language === 'en' ? EN : RU;
  return (
    <div className="sk-privacy-policy-text">
      <div className="sk-privacy-policy-text__block">
        <Text size="caption" classes="sk-privacy-policy-text__title">
          {currentLocaleConfig.updatedAt}
        </Text>
      </div>
      <header className="sk-privacy-policy-text__header">
        <Heading
          variant="h4"
          bold
          classes="sk-privacy-policy-text__subtitle heading-sk__center"
        >
          {currentLocaleConfig.title}
        </Heading>
      </header>
      <div>
        {currentLocaleConfig.blocks.map(block => {
          const contentType = findOneOf(Object.keys(block), contentTypes);
          const isContentTypeDefined = !!contentType;
          const contentRenderFunction =
            isContentTypeDefined && contentRenderFunctions[contentType];
          keyCounter += 1;
          return (
            <div className="sk-privacy-policy-text__block" key={keyCounter}>
              <Text
                size="caption"
                classes="sk-privacy-policy-text__title sk-privacy-policy-text__heading"
              >
                {block.title}
              </Text>
              {isContentTypeDefined
                ? contentRenderFunction(block[contentType])
                : null}
            </div>
          );
        })}
      </div>
    </div>
  );
};

PolicyText.defaultProps = {
  language: 'en',
};

PolicyText.propTypes = {
  languageContext: PropTypes.object,
};

const enhancer = compose(
  memo,
  withLanguage
);

export default enhancer(PolicyText);
