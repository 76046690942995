// Core
import React, { memo } from 'react';
import PropTypes from 'prop-types';

// Components
import MaterializedSelect from 'components/Select/MaterialUI/MaterializedSelect';
import Input from 'components/Select/MaterialUI/Input';
import Text from 'components/Text';

const selectClasses = {
  selectMenu: 'mui-override sk-materialized-select',
};

const getValueLabel = (value, options, valueKey, labelKey) => {
  const currentOption = options?.find(item => value === item[valueKey]);
  return currentOption ? currentOption[labelKey] : '';
};

const renderValue = (
  selected,
  options,
  valueKey,
  labelKey,
  disabled,
  style
) => (
  <Text disabled={disabled} style={style}>
    {getValueLabel(selected, options, valueKey, labelKey)}
  </Text>
);

const SimpleSelect = ({
  name,
  extraSelectClasses,
  error,
  input,
  renderValue: customValueRenderer,
  options,
  valueKey,
  labelKey,
  disabled,
  classes,
  inlineStyles,
  ...rest
}) => {
  const classesMmergedWithExtraSelectAndSelectClasses = {
    ...selectClasses,
    ...extraSelectClasses,
    ...classes,
  };

  return (
    <MaterializedSelect
      selectClasses={{
        ...selectClasses,
        ...extraSelectClasses
      }}
      classes={classesMmergedWithExtraSelectAndSelectClasses}
      inlineStyles={inlineStyles}
      renderValue={selected =>
        customValueRenderer
          ? customValueRenderer(selected)
          : renderValue(
            selected,
            options,
            valueKey,
            labelKey,
            disabled,
            inlineStyles.displayValue
          )
      }
      input={
        input || (
          <Input
            classes={{
              formControl: 'mui-override materialized-select-input-formControl',
            }}
            name={name}
            error={error}
          />
        )
      }
      error={error}
      options={options}
      valueKey={valueKey}
      labelKey={labelKey}
      disabled={disabled}
      {...rest}
    />
  );
};

SimpleSelect.defaultProps = {
  valueKey: 'value',
  labelKey: 'label',
  inlineStyles: {},
  classes: {},
};

SimpleSelect.propTypes = {
  name: PropTypes.string,
  error: PropTypes.bool,
  input: PropTypes.element,
  inlineStyles: PropTypes.shape({
    form: PropTypes.object,
    selector: PropTypes.object,
    menuItem: PropTypes.object,
    displayValue: PropTypes.object,
  }),
  classes: PropTypes.shape({
    form: PropTypes.object,
    selector: PropTypes.object,
    menuItem: PropTypes.object,
  }),
};

export default memo(SimpleSelect);
