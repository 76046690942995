import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  cookieMessage: {
    id: 'app.containers.CookieBanner.cookieMessage',
    defaultMessage: "By using our website, you consent to the use of cookies on your device. We will process collected information it in accordance with our <b><a href='https://avdo.education/en/privacy-policy' target='_blank'>Privacy Policy</a></b> and <b><a href='https://avdo.education/en/terms-of-use' target='_blank'>Terms of Use</a></b>.",
  },
  accept: {
    id: 'app.containers.CookieBanner.accept',
    defaultMessage: 'Accept',
  },
  decline: {
    id: 'app.containers.CookieBanner.decline',
    defaultMessage: 'Decline',
  },
});
