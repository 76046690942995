import React from 'react';

const QuizIcon = () => {
  return(
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 15.0473V2.20918C2 1.53741 2.53741 1 3.20918 1H10.7926C11.1509 1 11.4793 1.14928 11.7182 1.43291L15.1069 5.38886C15.286 5.61278 15.3905 5.88149 15.3905 6.18005V15.0772C15.3905 16.3162 14.3903 17.3164 13.1513 17.3164L4.22429 17.2865C3.00018 17.2716 2 16.2714 2 15.0473Z" stroke="#A9B6C8" strokeMiterlimit="10"/>
      <path d="M11.1504 1.13428V5.12008C11.1504 5.47835 11.434 5.76199 11.7923 5.76199L15.2556 5.79184" stroke="#A9B6C8" strokeMiterlimit="10"/>
      <path d="M5.79785 9.6658L8.24052 12.392L12.1266 7.96191" stroke="#A9B6C8" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
};

export default QuizIcon;
