import React from 'react';
import { Red, Grey } from 'components/Icon/color';

const Clock = ({ height = 20, width = 20, red = false, fill = '#fff', stroke = Grey }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 18 18"
  >
    <g fill={fill} stroke={red ? Red : stroke} transform="translate(1 1)">
      <circle cx="8" cy="8" r="8" />
      <polyline
        strokeLinecap="round"
        strokeLinejoin="round"
        points="10.947 10.947 7.579 8.421 7.579 3.368"
      />
    </g>
  </svg>
);

export default Clock;
