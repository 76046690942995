import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  UserNotConfirmedException: {
    id: 'app.errors.incoming.UserNotConfirmedException',
    defaultMessage: "User's email is not confirmed",
  },
  NotAuthorizedException: {
    id: 'app.errors.incoming.NotAuthorizedException',
    defaultMessage: 'Incorrect email or password',
  },
  NotAuthorizedExceptionPassword: {
    id: 'app.errors.incoming.NotAuthorizedExceptionPassword',
    defaultMessage: 'Incorrect password',
  },
  UserNotFoundException: {
    id: 'app.errors.incoming.UserNotFoundException',
    defaultMessage: 'There is no registered user with such email or user was registered using Facebook or Google',
  },
  LimitExceededException: {
    id: 'app.errors.incoming.LimitExceededException',
    defaultMessage: 'Attempt limit exceeded, please try after some time',
  },
  UsernameExistsException: {
    id: 'app.errors.incoming.UsernameExistsException',
    defaultMessage: 'An account with the given email already exists',
  },
  CodeMismatchException: {
    id: 'app.errors.incoming.CodeMismatchException',
    defaultMessage: 'Invalid verification code provided, please try again.',
  },
  ExpiredCodeException: {
    id: 'app.errors.incoming.ExpiredCodeException',
    defaultMessage: 'Invalid code provided, please request a code again.',
  },
  NotAuthorizedExceptionConfirmation: {
    id: 'app.errors.incoming.NotAuthorizedExceptionConfirmation',
    defaultMessage: 'You have already verified your account.',
  },
  UserLambdaValidationException: {
    id: 'app.errors.incoming.UserLambdaValidationException',
    defaultMessage: 'User validation failed.',
  },
  DomainNotFound: {
    id: 'app.errors.incoming.DomainNotFound',
    defaultMessage: 'Inactive organisation domain. Try to signin from main site avdo.education.',
  },
  OrgUserNotFound: {
    id: 'app.errors.incoming.DomainNotFound',
    defaultMessage: 'Email and password does not found for given company.',
  },
});
