// Core
import React from 'react';

// Components
import VideoModule from 'components/VideoModule';

// Styles
import './ToppingPreview.scss';

const ToppingPreview = ({
  preview,
  finishVideo
}) => (
  <div className={`sk-topping-details_preview`}>
    <VideoModule {...preview} handleMediaEnd={finishVideo} />
  </div>
);

export default ToppingPreview;
