// Core
import React, { memo, useState, useCallback } from 'react';
import { compose, graphql } from 'react-apollo';
import { injectIntl } from 'react-intl';

// Components
import Card from 'components/Card';
import ContentHeader from 'containers/ContentHeader';
import Loader from 'components/Loader';

import EmptyCourseList from 'routes/ManageAccount/ExpertSales/EmptyCourseList';
import ActiveStudentsTable from 'routes/ManageAccount/ExpertActiveStudents/ActiveStudentsTable';

// GraphQl
import LIST_EXPERT_STATS from 'queries/UserProfile/listExpertStats.gql';

// Other
import { parseFetchedData } from 'utils/helpers';
import { capitalize, isNull } from 'utils/helpers/index';
import { sharedMessages } from 'services/i18n/sharedMessages/messages';

// Styles and Assets
import './ExpertActiveStudents.scss';

const ExpertActiveStudents = ({
  loading, intl: { formatMessage }, listExpertStats,
}) => {
  if (loading) return (<Loader center />);
  return (
    <div className="sk-admin-user-view">
      <div className="sk-admin-user-view__content-header">
          <ContentHeader />
      </div>
      <Card>
        <div className="sk-admin-user-view__content-body">
          {(!isNull(listExpertStats) && listExpertStats) ? (
            <ActiveStudentsTable courses={listExpertStats.courses} total={listExpertStats.total} />
          ) : (
            <EmptyCourseList />
          )}
        </div>
      </Card>
    </div>
  );
}

const listExpertStats = graphql(LIST_EXPERT_STATS, {
  props: ({ data: { listExpertStats, error, loading, ...ownProps }}) => {

    if (loading) return { loading, listExpertStats: {} };
    if (error) throw new Error('Something went wrong while receiving LIST_EXPERT_STATS');

    return {
      loading,
      error,
      listExpertStats,
      ...ownProps,
  }},
  options: () => {
    const filter =  [{
      key: 'course_status',
      values: ['ACTIVE'],
    }];

    return ({
      fetchPolicy: 'cache-and-network',
      variables: {
        input: {
          type: 'students',
          query: { filter }
        },
      },
    })
  }
});

const enhancer = compose(
  memo,
  injectIntl,
  listExpertStats,
);

export default enhancer(ExpertActiveStudents);
