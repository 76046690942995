// Core
import React, { memo } from 'react';
import PropTypes from 'prop-types';

// Components
import ChevronDown from 'components/Icon/Svg/ChevronDown';
import ChevronUp from 'components/Icon/Svg/ChevronUp';

// Other
import { SidebarDefault } from 'components/Icon/color';

// Styles and Assets
import './Toggler.scss';

const Toggler = memo(
  ({ children, active, activeColor, chevronUpProps, chevronDownProps, handleOnTogglerClick, }) => (
    <div className="sk-toggler__wrapper" onClick={handleOnTogglerClick}>
      {children}
      <span className="sk-toggler__icon">
        {active ? (
          <ChevronUp
            active={active}
            {...chevronUpProps}
            activeColor={activeColor}
            strokeWidth={1}
          />
        ) : (
          <ChevronDown
            active={active}
            activeColor={activeColor}
            strokeWidth={1}
            {...chevronDownProps}
          />
        )}
      </span>
    </div>
  )
);

Toggler.defaultProps = {
  chevronUpProps: {},
  chevronDownProps: {},
  activeColor: SidebarDefault,
};

Toggler.propTypes = {
  children: PropTypes.any,
  active: PropTypes.bool,
  chevronUpProps: PropTypes.object,
  chevronDownProps: PropTypes.object,
  activeColor: PropTypes.string,
};

export default Toggler;
