import React from 'react';

const Icon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.63774 13.4418C3.33384 13.304 3.18404 13.1111 3.14633 12.9692C3.06385 12.5716 3.35401 12.0109 4.18919 11.7949C4.53687 11.726 4.88264 11.7268 5.23037 11.7972L5.74761 11.9021V12.4912C5.74761 12.8855 5.37751 13.3921 4.72968 13.5234L4.71788 13.5258L4.70621 13.5288C4.31925 13.6268 3.9345 13.5763 3.63774 13.4418ZM6.15627 4.98028L5.82969 5.04096V3.44458L11.5889 2.50782V3.97089L11.1631 4.05001L6.15627 4.98028ZM11.1551 10.6133L11.5889 10.7012V11.2226C11.5889 11.6833 11.2379 12.1306 10.5361 12.3476C9.68696 12.5581 9.15358 12.1618 9.07251 11.7921L9.06508 11.7582L9.05304 11.7257C9.0078 11.6034 9.02384 11.4045 9.21527 11.164C9.4011 10.9304 9.71665 10.7141 10.114 10.6109C10.4617 10.542 10.8074 10.5428 11.1551 10.6133Z" stroke="#A9B6C8"/>
    </svg>
  );
};

export default Icon;
