import React from 'react';
import PropTypes from 'prop-types';
import { SidebarDefault, PurpleHeart } from 'components/Icon/color';

const Share = ({ height, width, active, activeColor, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 21"
    width={width}
    height={height}
  >
    <path
      d="M15 14.64c-.76 0-1.445.289-1.965.754L5.91 11.322c.055-.22.09-.45.09-.685 
      0-.236-.035-.466-.09-.687l7.05-4.032a3.01 3.01 0 0 0 2.04.794c1.655 
      0 3-1.318 3-2.94 0-1.622-1.345-2.94-3-2.94s-3 1.318-3 2.94c0 
      .235.035.465.09.686L5.04 8.49A3.01 3.01 0 0 0 3 7.697c-1.655 
      0-3 1.318-3 2.94 0 1.621 1.345 2.94 3 2.94a3.01 3.01 0 0 0 2.04-.794l7.125 
      4.072c-.05.205-.08.421-.08.642 0 1.577 1.305 2.856 2.915 2.856s2.915-1.279 
      2.915-2.857c0-1.577-1.305-2.856-2.915-2.856z"
      fill={active ? activeColor : color}
    />
  </svg>
);

Share.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  active: PropTypes.bool,
  activeColor: PropTypes.string,
  color: PropTypes.string,
};

Share.defaultProps = {
  height: '21',
  width: '18',
  color: SidebarDefault,
  activeColor: PurpleHeart,
};

export default Share;
