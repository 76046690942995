// Core
import React from 'react';
import { injectIntl } from 'react-intl';

// Components
import Text from 'components/Text';
import Image from 'components/Image';
import Link from 'components/Link';

// Others
import { getCourseThumbnail } from 'utils/helpers';
import { sharedMessages } from 'services/i18n/sharedMessages/messages';

// Styles
import './PurchasedCoursesFlex.scss';

const PurchasedCoursesFlex = ({
  course: {
    coursePhoto,
    thumbnail,
    title: courseTitle,
    category,
    subcategory,
  },
  intl: { formatMessage },
}) => {
  return(
    <div className="sk-student-course__flex">
      <Text>
        <Link to={`/courses/${category.id}`}>{formatMessage(sharedMessages[category.title])}</Link>/
        <Link to={`/courses/${category.id}/${subcategory.id}`}>{formatMessage(sharedMessages[subcategory.title])}</Link>/
        {courseTitle}
      </Text>
      <div className="sk-student-course__flex_preview">
        <Image src={getCourseThumbnail(thumbnail, coursePhoto)} />
      </div>
    </div>
  )
};

export default injectIntl(PurchasedCoursesFlex);
