// Core
import React from 'react';

// Components
import RoundIcon from 'components/Icon/RoundIcon';
import ConfirmDelete from 'routes/ExpertsCabinet/components/ConfirmDelete/ConfirmDelete';
import PhotoUploader from 'components/PhotoUploader';
import IconWithModal from 'components/IconWithModal';
import ImageWithLoader from 'components/ImageWithLoader';

// Icons
import ImageUpload from 'components/Icon/SvgCss/Quiz/EditImageUpload.js';
import TrashBin from 'components/Icon/SvgCss/Quiz/TrashBin.js';

// Other
import { DEFAULT_MAX_FILE_SIZE, SUPPORTED_FORMATS_IMAGE } from 'utils/enums/index';

// Styles and Assets
import './index.scss';

const EditImageIcon = <RoundIcon type="flat" opacity='75' icon={<ImageUpload />} />;
const DeleteImageIcon = () => <RoundIcon type="flat" opacity='75' icon={<TrashBin />} />;

const UploadedImage = ({
  uploaderId,
  image = {},
  readOnly,
  handleImageUpload,
  handleImageDelete,
  background,
}) => {
  const handleDelete = async () => await handleImageDelete(image?.id);
  const hasImage = image?.id;

  return (
    <div className='sk-uploaded-image'>
      <ImageWithLoader
        file={image}
        fileKey='file'
        withLoader={!readOnly}
        background={background}
      />
      {(hasImage && !readOnly) && (
        <div className='sk-uploaded-image__actions-button'>
          <PhotoUploader
            supportedFormats={SUPPORTED_FORMATS_IMAGE}
            maxSize={DEFAULT_MAX_FILE_SIZE}
            uploaderId={uploaderId}
            handleChange={handleImageUpload}
            icon={EditImageIcon}
          />
          <IconWithModal
            modal={ConfirmDelete}
            modalProps={{ size: 'md', handleDelete, }}
            icon={DeleteImageIcon}
          />
        </div>
      )}
    </div>
  )
};

export default UploadedImage;
