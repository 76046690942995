import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  contactUs: {
    id: 'app.routes.ContactUs.contactUs',
    defaultMessage: 'Contact Us',
  },
  inCaseOfAnyQuestions: {
    id: 'app.routes.ContactUs.inCaseOfAnyQuestions',
    defaultMessage:
      'For any inquiries or just to say hello',
  },
  emailAddress: {
    id: 'app.routes.ContactUs.emailAddress',
    defaultMessage: 'Your email address',
  },
  submit: {
    id: 'app.routes.ContactUs.submit',
    defaultMessage: 'Submit',
  },
  writeMessage: {
    id: 'app.routes.ContactUs.writeMessage',
    defaultMessage: 'Write a message',
  },
});
