// Core
import React, { memo, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { compose, graphql } from 'react-apollo';
import moment from 'moment';

// Components
import Button from 'components/Button';
import Text from 'components/Text';
import Clock from 'components/Icon/Svg/Clock';
import WebinarNoEvents from 'components/Icon/Svg/WebinarNoEvents';
import WebinarScreen from 'components/Icon/Svg/WebinarScreen';
import Calendar from 'components/Icon/Svg/Calendar';
import Link from 'components/Link';
import Loader from 'components/Loader';
import Card from 'components/Card';

// GraphQL
import GET_CALENDAR_EVENTS from 'queries/Calendar/getCalendarEvents.gql';
import MY_PROFILE_QUERY from 'queries/UserProfile/getProfile.gql';

// Other
import { hasLoader } from 'containers/HOCs/hasLoader';
import { getNearestWebinarReducer } from 'utils/reducers';

import { FormattedMessage } from 'react-intl';
import { messages } from 'routes/StudentsCabinet/components/StudentNearestWebinar/messages';

// Styles and Assets
import './StudentNearestWebinar.scss';

const NoEventsBlock = ({ loading }) => (
  <Card classes="sk-web-no-events">
    {loading ? (
      <Loader small overlay />
    ) : (
      <>
        <Text>
          <FormattedMessage {...messages.noEvents} />
        </Text>
        <WebinarNoEvents />
      </>
    )}
  </Card>
);

const StudentNearestWebinar = ({
  events,
  error,
  profile,
  course,
  loading,
  mappingId,
  history,
}) => {
  const props = {
    events,
    course,
  };

  const nearestWebinar = events?.length
    ? getNearestWebinarReducer('student', profile.gmt_timezone, props)
    : null;

  if (error || !events?.length || !nearestWebinar)
    return <NoEventsBlock loading={loading} />;

  const joinWebinar = useCallback(() =>{
    const webinarLink = {
      pathname: `/subscriber-webinar/${nearestWebinar?.course?.id}/${nearestWebinar?.webinarEvent?.id}`,
      state: {
        sessionId: nearestWebinar?.webinarEvent?.sessionId,
        mappingId,
      }
    };
    history.push(webinarLink);
  }, [nearestWebinar?.webinarEvent?.id, nearestWebinar?.webinarEvent?.sessionId, mappingId, nearestWebinar?.course?.id]);

  return (
    <Card classes="sk-stud-near-webinar">
      <div className="sk-stud-near-webinar-title">
        <div className="sk-stud-near-webinar-title__icon">
          <WebinarScreen />
        </div>
        <div className="sk-stud-near-webinar-title__header">
          <Text size="small" light classes="sk-stud-near-webinar-title__title">
            <FormattedMessage {...messages.nearestWebinar} />
          </Text>
          <Text size="small" classes="sk-stud-near-webinar-title__value">
            {nearestWebinar?.webinarEvent?.title}
          </Text>
        </div>
      </div>
      <div className="sk-stud-near-webinar-clock">
        <div className="sk-stud-near-webinar-clock__calendar">
          <div className="sk-web-panel-date">
            <div className="sk-web-panel-date__icon">
              <Clock />
            </div>

            <Text size="small" classes="sk-web-panel-date__text">
              {nearestWebinar?.webinarEvent?.time}
            </Text>
          </div>
          <div className="sk-web-panel-date">
            <div className="sk-web-panel-date__icon">
              <Calendar />
            </div>

            <Text size="small" classes="sk-web-panel-date__text">
              {nearestWebinar?.webinarEvent?.date}
            </Text>
          </div>
        </div>
      </div>
      <div className="sk-stud-near-webinar-clock__actions">
        {nearestWebinar?.webinarEvent?.isWebinarAvailable ? (
          <Button color="secondary" variant="text" size="large" onClick={joinWebinar}>
            <FormattedMessage {...messages.startWatching} />
          </Button>
        ) : (
          <Button disabled color="secondary" variant="text" size="large">
            <FormattedMessage {...messages.startWatching} />
          </Button>
        )}
      </div>
    </Card>
  );
};

const getCalendarEventsQuery = graphql(GET_CALENDAR_EVENTS, {
  skip: ({ course }) => !course?.id,
  props: ({ data: { loading, error, calendarEvents, ...ownProps } }) => ({
    loading,
    events: calendarEvents,
    error,
    ...ownProps,
  }),
  options: ({ course: { id: CourseId } }) => ({
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        CourseId,
        TimeFromUnixMs: new Date().getTime() - 100000000000,
        TimeToUnixMs: new Date().getTime() + 100000000000,
      },
    },
  }),
});

const myProfileQuery = graphql(MY_PROFILE_QUERY, {
  props: ({ data: { getProfile, error, ...ownProps } }) => ({
    profile: getProfile,
    error,
    ...ownProps,
  }),
  options: () => ({
    fetchPolicy: 'cache-only',
  }),
});

const enhancer = compose(memo, withRouter, myProfileQuery, getCalendarEventsQuery);

export default hasLoader(enhancer(StudentNearestWebinar), GET_CALENDAR_EVENTS);
