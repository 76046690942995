// Core
import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
// import { BrowserRouter, StaticRouter, Switch, Route, Redirect } from 'react-router-dom';

// Components
import Layout from 'containers/Layout';
import ScrollHandler from 'containers/Navigation/Router/ScrollHandler';
import MetaData from 'components/MetaData';

// Other
import publicRoutes from 'containers/Navigation/Router/routes/publicRoutes';
import privateRoutes from 'containers/Navigation/Router/routes/privateRoutes';
import PageNotFound from 'routes/PageNotFound';

import RoutePrivate from 'containers/Navigation/Router/RoutePrivate';
import RouteGuest from 'containers/Navigation/Router/RouteGuest';

import { parseCookies } from 'utils/helpers';

import { LANGUAGES, DEFAULT_LOCALE } from 'utils/enums/index';

// Global instasnce
import Intl from 'services/i18n/instance/index';
import RouteInstance from 'services/route';

const SimpleLayout = props => <Layout withoutSidebar {...props} />;

const Router = ({ role, serverContext, languageContext, domain }) => {
  const routerProps = serverContext || {};
  const getRedirectUrl = (location) => {
    const { pathname, search, hash, state } = location;
    const intl = Intl.get();
    const currentLocale = (intl && intl.locale && LANGUAGES.some(i => (i.code === intl.locale)))
      ? intl.locale
      : DEFAULT_LOCALE;
    return {
      pathname: `/${currentLocale}${pathname}`,
      search,
      hash,
      state
    };
  }

  /**
   * Validate and switch localisation
   * @param {string} locale ISO2 code
   * return {bool}
   */
  const validateLocale = (locale, location, history) => {
    // Update global route instance
    RouteInstance.set({location, history, locale});

    if (location.pathname && location.pathname === '/') {
      return true;
    }

    const cookies = parseCookies();
    const { updateLanguage } = languageContext;
    if (LANGUAGES.some(item => (item.code === locale))) {
      if (cookies.language !== locale) {
        languageContext.updateLanguage(locale);
      }
      return true;
    }
    return false;
  }

  return (
    <>
      <MetaData location={routerProps.location} />
      <ScrollHandler/>
      <Route
        path="/:locale?"
        render={ ({match: {params: { locale }}, location, history}) => (
          validateLocale(locale, location, history) ?
          <Switch>
            {publicRoutes.map(route => (
              <RouteGuest
                key={route.path}
                {...route}
                role={role}
                component={SimpleLayout}
                domain={domain}
              />
            ))}
            {privateRoutes.map(route => (
              <RoutePrivate
                key={route.path}
                {...route}
                route={route}
                role={role}
                domain={domain}
              />
            ))}
            <Route component={PageNotFound} />
          </Switch> : <Redirect to={getRedirectUrl(location)} />
        )}
      />
    </>
  );
};

Router.defaultProps = {
  role: 'student',
};

Router.propTypes = {
  role: PropTypes.string,
  languageContext: PropTypes.object,
};

export default Router;
