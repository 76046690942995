// Core
import React from 'react';
import PropTypes from 'prop-types';

// Components
import MaterialMenuItem from '@material-ui/core/MenuItem';

const menuItemClasses = {
  gutters: 'mui-override sk-materialized-select sk-materialized-select-item',
  selected:
    'mui-override sk-materialized-select  sk-materialized-select-item selected',
};

const Suggestion = ({ label, ...rest }) => (
  <MaterialMenuItem classes={menuItemClasses} {...rest}>
    {label}
  </MaterialMenuItem>
);

Suggestion.propTypes = {
  highlightedIndex: PropTypes.number,
  index: PropTypes.number,
  itemProps: PropTypes.object,
  labelKey: PropTypes.string,
  selectedItem: PropTypes.string,
};

export default Suggestion;
