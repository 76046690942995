import React, { memo } from 'react';
import { compose } from 'react-apollo';

const Icon = (props) => {
  return (
    <svg width="84" height="85" viewBox="0 0 84 85" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M42 82.6348C64.0914 82.6348 82 64.7262 82 42.6348C82 20.5434 64.0914 2.63477 42 2.63477C19.9086 2.63477 2 20.5434 2 42.6348C2 64.7262 19.9086 82.6348 42 82.6348Z" stroke="#75C561" stroke-width="4"/>
      <path d="M25.2524 42.8309C24.4562 42.0654 23.1901 42.0902 22.4246 42.8864C21.659 43.6826 21.6838 44.9487 22.48 45.7143L25.2524 42.8309ZM37.3473 57.2354L35.9611 58.677C36.3636 59.0641 36.9096 59.2652 37.467 59.2318C38.0244 59.1983 38.5425 58.9334 38.8959 58.501L37.3473 57.2354ZM63.4148 28.501C64.1138 27.6457 63.9871 26.3858 63.1319 25.6868C62.2766 24.9878 61.0166 25.1144 60.3176 25.9697L63.4148 28.501ZM22.48 45.7143L35.9611 58.677L38.7335 55.7937L25.2524 42.8309L22.48 45.7143ZM38.8959 58.501L63.4148 28.501L60.3176 25.9697L35.7987 55.9697L38.8959 58.501Z" fill="#75C561"/>
    </svg>
  );
};

const enhancer = compose(
  memo,
);

export default enhancer(Icon);
