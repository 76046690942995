// Core
import React, { memo, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose, graphql } from 'react-apollo';

// Components
import Button from 'components/Button';

// Styles and Assets
import '../ActionPanel.scss';

// Other
import { COURSE_NEXT_STATUSES_ADMIN, COURSE_NEXT_REJECTED_STATUSES_ADMIN } from 'utils/enums';

import { FormattedMessage } from 'react-intl';
import { messages } from 'routes/ExpertsCabinet/CourseEdit/GeneralInfo/messages';

const getNextCourseStatus = statusId => COURSE_NEXT_STATUSES_ADMIN[statusId];
const getNextCourseRejectedStatus = statusId => COURSE_NEXT_REJECTED_STATUSES_ADMIN[statusId];

const AdminButtonCroup = ({
  // handleCourseStatusApprove,
  statusId,
  courseIsInReview,
  courseIsActive,
  id,
  author,
  // updateCourseStatus,
  isCourseStatusUpdating,
  handleMailSend,
  handleCourseStatusUpdate
}) => {
  const rejectCourse = () => handleCourseStatusUpdate(getNextCourseRejectedStatus(statusId));
  const approveCourse = () => handleCourseStatusUpdate(getNextCourseStatus(statusId));
  const blockCourse = () => handleCourseStatusUpdate('FINAL_REVIEW');

  return (
    <Fragment>
      {courseIsActive && (
        <Button
          onClick={blockCourse}
          color="secondary"
          variant="text"
          size="small"
          loading={isCourseStatusUpdating}
        >
          <FormattedMessage {...messages.block} />
        </Button>
      )}
      {courseIsInReview && (
        <div>
          <Button
            onClick={rejectCourse}
            color="primary"
            variant="text"
            size="small"
            loading={isCourseStatusUpdating}
          >
            <FormattedMessage {...messages.reject} />
          </Button>
          <Button
            onClick={approveCourse}
            color="secondary"
            variant="text"
            loading={isCourseStatusUpdating}
            size="small"
          >
            <FormattedMessage {...messages.approve} />
          </Button>
        </div>
      )}
    </Fragment>
  );
};

const enhancer = compose(
  memo
);

AdminButtonCroup.propTypes = {
  statusId: PropTypes.string,
  courseIsInReview: PropTypes.bool,
  courseIsActive: PropTypes.bool,
  id: PropTypes.string,
};

export default enhancer(AdminButtonCroup);
