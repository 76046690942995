import React from 'react';

import './index.scss';

const BlockquoteOnHover = () => (
  <svg
    className="hover"
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.691408"
      y="-4.76837e-06"
      width="32"
      height="32"
      rx="2"
      fill="white"
    />
    <path
      d="M9.15126 8.14065C9.15126 7.88616 9.35757 7.67985 9.61206 7.67985L22.7705 7.67985C23.025 7.67985 23.2313 7.88616 23.2313 8.14065C23.2313 8.39514 23.025 8.60145 22.7705 8.60145L9.61206 8.60145C9.35757 8.60145 9.15126 8.39514 9.15126 8.14065ZM13.1175 11.7247C13.1175 11.4702 13.3238 11.2639 13.5783 11.2639L22.7705 11.2639C23.025 11.2639 23.2313 11.4702 23.2313 11.7247C23.2313 11.9791 23.025 12.1855 22.7705 12.1855L13.5783 12.1855C13.3238 12.1855 13.1175 11.9791 13.1175 11.7247ZM10.8369 15.3599C10.8369 15.1336 11.0203 14.9503 11.2465 14.9503L22.8217 14.9503C23.0479 14.9503 23.2313 15.1336 23.2313 15.3599C23.2313 15.5861 23.0479 15.7695 22.8217 15.7695L11.2465 15.7695C11.0203 15.7695 10.8369 15.5861 10.8369 15.3599ZM13.1175 18.9951C13.1175 18.7406 13.3238 18.5343 13.5783 18.5343L22.7705 18.5343C23.0249 18.5343 23.2313 18.7406 23.2313 18.9951C23.2313 19.2495 23.0249 19.4559 22.7705 19.4559L13.5783 19.4559C13.3238 19.4559 13.1175 19.2495 13.1175 18.9951ZM9.15126 22.6303C9.15126 22.404 9.33464 22.2207 9.56086 22.2207L22.8217 22.2207C23.0479 22.2207 23.2313 22.404 23.2313 22.6303C23.2313 22.8565 23.0479 23.0399 22.8217 23.0399L9.56086 23.0399C9.33464 23.0399 9.15126 22.8565 9.15126 22.6303Z"
      fill="#7145D8"
    />
  </svg>
);

const DefaultBlockquote = () => (
  <svg
    className="default"
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.15126 8.14065C9.15126 7.88616 9.35757 7.67985 9.61206 7.67985L22.7705 7.67985C23.025 7.67985 23.2313 7.88616 23.2313 8.14065C23.2313 8.39514 23.025 8.60145 22.7705 8.60145L9.61206 8.60145C9.35757 8.60145 9.15126 8.39514 9.15126 8.14065ZM13.1175 11.7246C13.1175 11.4702 13.3238 11.2638 13.5783 11.2638L22.7705 11.2638C23.025 11.2638 23.2313 11.4702 23.2313 11.7246C23.2313 11.9791 23.025 12.1854 22.7705 12.1854L13.5783 12.1854C13.3238 12.1854 13.1175 11.9791 13.1175 11.7246ZM10.8369 15.3598C10.8369 15.1336 11.0203 14.9502 11.2465 14.9502L22.8217 14.9502C23.0479 14.9502 23.2313 15.1336 23.2313 15.3598C23.2313 15.5861 23.0479 15.7694 22.8217 15.7694L11.2465 15.7694C11.0203 15.7694 10.8369 15.5861 10.8369 15.3598ZM13.1175 18.995C13.1175 18.7406 13.3238 18.5342 13.5783 18.5342L22.7705 18.5342C23.0249 18.5342 23.2313 18.7406 23.2313 18.995C23.2313 19.2495 23.0249 19.4558 22.7705 19.4558L13.5783 19.4558C13.3238 19.4558 13.1175 19.2495 13.1175 18.995ZM9.15126 22.6302C9.15126 22.404 9.33464 22.2206 9.56086 22.2206L22.8217 22.2206C23.0479 22.2206 23.2313 22.404 23.2313 22.6302C23.2313 22.8565 23.0479 23.0398 22.8217 23.0398L9.56086 23.0398C9.33464 23.0398 9.15126 22.8565 9.15126 22.6302Z"
      fill="#6D6E71"
    />
  </svg>
);

const ActiveBlockquote = () => (
  <svg
    className="active"
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.15126 8.14066C9.15126 7.88616 9.35757 7.67986 9.61206 7.67986L22.7705 7.67986C23.025 7.67986 23.2313 7.88616 23.2313 8.14066C23.2313 8.39515 23.025 8.60146 22.7705 8.60146L9.61206 8.60146C9.35757 8.60146 9.15126 8.39515 9.15126 8.14066ZM13.1175 11.7247C13.1175 11.4702 13.3238 11.2639 13.5783 11.2639L22.7705 11.2639C23.025 11.2639 23.2313 11.4702 23.2313 11.7247C23.2313 11.9791 23.025 12.1855 22.7705 12.1855L13.5783 12.1855C13.3238 12.1855 13.1175 11.9791 13.1175 11.7247ZM10.8369 15.3599C10.8369 15.1336 11.0203 14.9503 11.2465 14.9503L22.8217 14.9503C23.0479 14.9503 23.2313 15.1336 23.2313 15.3599C23.2313 15.5861 23.0479 15.7695 22.8217 15.7695L11.2465 15.7695C11.0203 15.7695 10.8369 15.5861 10.8369 15.3599ZM13.1175 18.9951C13.1175 18.7406 13.3238 18.5343 13.5783 18.5343L22.7705 18.5343C23.0249 18.5343 23.2313 18.7406 23.2313 18.9951C23.2313 19.2495 23.0249 19.4559 22.7705 19.4559L13.5783 19.4559C13.3238 19.4559 13.1175 19.2495 13.1175 18.9951ZM9.15126 22.6303C9.15126 22.404 9.33464 22.2207 9.56086 22.2207L22.8217 22.2207C23.0479 22.2207 23.2313 22.404 23.2313 22.6303C23.2313 22.8565 23.0479 23.0399 22.8217 23.0399L9.56086 23.0399C9.33464 23.0399 9.15126 22.8565 9.15126 22.6303Z"
      fill="#7145D8"
    />
  </svg>
);

const Blockquote = ({ active }) => {
  const Icon = active ? ActiveBlockquote : DefaultBlockquote;

  return (
    <div className="editor-icon-svg">
      <Icon />
      <BlockquoteOnHover />
    </div>
  );
};

export default Blockquote;
