export const EXPERT = 'expert';
export const STUDENT = 'student';
export const MANAGER = 'manager';
export const OWNER = 'owner';
export const VIEWER = 'viewer';
export const DEMO = 'demo';
export const ADMIN = 'admin';
export const GUEST = undefined;
export const COURSE_ADMIN_ROLES = [MANAGER, ADMIN, OWNER];
export const USERS = [EXPERT, STUDENT, MANAGER, DEMO, VIEWER];
export const ALL = [EXPERT, STUDENT, GUEST, MANAGER, DEMO, VIEWER];
export const NOT_STUDENT = [EXPERT, ADMIN, MANAGER, DEMO, VIEWER];
export const ROWS_PER_PAGE = 7;
export const ITEMS_PER_PAGE = 6;
export const LECTURES_ZERO_BASED_DIGITS = 3;
export const DURATION_ZERO_BASED_DIGITS = 2;
export const SUPPORTED_FORMATS_IMAGE = '.png, .jpg';
export const SUPPORTED_VIDEO_MIME = ['video'];
export const RECOMMENDED_VIDEO_FORMATS = '.MP4, .mp4, .MOV, .mov, .mpg, .MPG';
export const SUPPORTED_FORMATS_AUDIO = '.MP3, .mp3, .mp4, .MP4';
export const SUPPORTED_FORMATS_HOMEWORK = '.pdf';
export const SUPPORTED_FORMATS_ARTICLE = '.pdf';
export const PDF_FILE = 'pdf';
export const TEXT_FILE = 'txt';
export const EDITOR_FILE = 'editor';
export const VIDEO_FILE = 'video';
export const AUDIO_FILE = 'audio';
export const QUIZ_ATTACHMENT = 'quiz';
export const IMAGE_FILE = 'img';
export const FAILED_ATTACHMENT = 'failed';
export const COURSE_DESC_LENGTH = 260;
export const COURSE_DESC_PARAGRAPHS_LENGTH = 5;
export const WEBINAR_DESC_LENGTH = 120;
export const DEFAULT_CALENDAR_EVENTS_AMOUNT = 1000;
export const CATALOG_AMOUNT = 1000;
export const APPLE = 'apple';
export const TOMATO = 'tomato';
export const MAPPING_COURSES_LIST_LIMIT = 20;
export const DEFAULT_LOCALE = 'en';
export const DEFAULT_MAX_FILE_SIZE = 50000 * 1024;
export const TOPPING = "topping";

export const SUBMITTED_PREVIOUS_HOMEWORK = 'submitted_previous_homework';
export const REVIEWED_MARK_MORE_THAN_60 = 'prev_more_level';

export const ACTIVE_PURCHASED_COURSE = 'ACTIVE';
export const DEACTIVATED_PURCHASED_COURSE = 'DEACTIVATED';
export const COMPLETED_PURCHASED_COURSE = 'COMPLETED';

export const LANGUAGES = [
  { value: "EN", label: "English", code: "en", iso: "en_US" },
];

export const CURRENCIES = [
  { code: 'usd', sign: '$', isDefault: true },
  { code: 'eur', sign: '€', isDefault: false },
  { code: 'uah', sign: '₴', isDefault: false },
  { code: 'rub', sign: '₽', isDefault: false },
];

export const COURSE_NEXT_STATUSES_EXPERT = {
  DRAFT: 'FIRST_REVIEW',
  MAIN_FILLING: 'FINAL_REVIEW',
  READY_TO_PUBLISHING: 'ACTIVE',
  ACTIVE: 'DEACTIVATED',
  DEACTIVATED: 'REACTIVATED',
};

export const COURSE_NEXT_STATUSES_ADMIN = {
  FIRST_REVIEW: 'MAIN_FILLING',
  FINAL_REVIEW: 'READY_TO_PUBLISHING',
};

export const COURSE_NEXT_REJECTED_STATUSES_ADMIN = {
  FIRST_REVIEW: 'FIRST_REJECT',
  FINAL_REVIEW: 'SECOND_REJECT',
};

export const COURSE_PREV_STATUSES_ADMIN = {
  FIRST_REVIEW: 'DRAFT',
  FINAL_REVIEW: 'MAIN_FILLING',
};

export const COURSE_DURATION_RANGES = [
  { value: 'short', label: '<2h' },
  { value: 'average', label: '2h-5h' },
  { value: 'long', label: '>5h' },
];

export const timeMask = [/\d/, /\d/, ':', /\d/, /\d/, ' ', /a|p/i, 'M'];

export const dateSlashedMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/,];

export const EMAIL_SUPPORT = 'support@avdo.education';
